import { put } from 'redux-saga/effects';
import registry from 'app-registry';
import { handleServiceDown, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';

// eslint-disable-next-line import/prefer-default-export
export function* searchTags(action) {
  const { rowCount = MASTERDATA_SEARCH_LIMIT, position = 0, searchText } = action;
  try {
    let url = `/v1/masterdata/tags?offset=${position}&numberOfResults=${rowCount}`;
    url = `${url}&search=name=${encodeURIComponent(searchText)}`;
    const response = yield registry.get('request')
      .get(url, null, {});

    switch (response.status) {
      case 200: {
        yield put({
          type: `TAGS:SELECTOR:LIST:SEARCH:SUCCESS`,
          items: response.body,
          searchText
        });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: `TAGS:SELECTOR:LIST:SEARCH:FAIL`, error: err.message });
  }
}

