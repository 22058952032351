import { takeEvery } from 'redux-saga/effects';
import fetchTranslation from '@packages/translation/sagas';
import showNotification from '@packages/features/notifier/sagas';
import initApp from './actions';

export default [
  // All root sagas needs to go in here
  [takeEvery, 'APP:INIT', initApp],
  // Translation
  [takeEvery, 'TRANSLATION:INIT', fetchTranslation],
  // Notofications
  [takeEvery, 'NOTIFIER:NOTIFY', showNotification]
];
