import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import styles from '@packages/ui/styles';

class DropDownSelector extends React.Component {
  render() {
    const { fields, items, selectedItems, style, onSelect } = this.props;

    // Get value or id if selectedItem is an object, or else the selectedItem itself which would be a string
    const getSelectedValue = () =>
      selectedItems &&
      (selectedItems[0]?.value || selectedItems[0]?.id || selectedItems[0]); 

    return (
      <div>
        <Select
          classes={{
            outlined: 'outlined-select'
          }}
          id="selected_field"
          value={getSelectedValue()}
          style={Object.assign(
            {},
            styles.textField,
            styles.textBox,
            { paddingLeft: 0 },
            style
          )}
          input={<OutlinedInput />}
          onChange={(event) => {
            fields.removeAll();
            if (event.target.value !== '') {
              fields.push(event.target.value);
            }
            if (onSelect) {
              const selectedItem = items.find(
                (item) => item.id === event.target.value
              );
              onSelect(selectedItem);
            }
          }}
          underlineStyle={{ display: 'none' }}
        >
          {items.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

DropDownSelector.propTypes = {
  fields: PropTypes.shape({
    removeAll: PropTypes.func,
    push: PropTypes.func
  }),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.shape({}),
  onSelect: PropTypes.func
};

DropDownSelector.defaultProps = {
  fields: {
    removeAll: (e) => e,
    push: (e) => e
  },
  style: {},
  onSelect: undefined
};

export default DropDownSelector;
