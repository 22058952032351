import { put, select } from 'redux-saga/effects';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getTransformedDiffObject } from '../../saga-utils';
import { transformResponseData } from '../utils/processingUtils';

export default function* initializeProcessingDiff(action) {
  const { processingId } = getParameterValuesFromHash('/processing/:processingId/compare');
  yield put({ type: 'PROCESSING:DIFF:FETCH', processingId });

  try {
    const userState = yield select(state => state.user);
    const currentUser = userState ? userState.get('profile') : null;

    // Find json diff between the versions
    const data = yield getTransformedDiffObject('processings', processingId);
    const transformedData = yield transformResponseData(data, false, currentUser, action.formatMessage);

    yield put({ type: 'PROCESSING:DIFF:FETCH:SUCCESS', data: transformedData });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'PROCESSING:DIFF:FETCH:FAIL', error: err.message });
  }
}
