import { connect } from 'react-redux';
import DataImportersSelector from './component';

const mapStateToProps = state => ({
  dataImporters: state.dataImporters.get('dataImporters'),
  position: state.dataImporters.get('position'),
  error: state.dataImporters.get('error'),
  searchResults: state.dataImporters.get('items')
});

const mapDispatchToProps = dispatch => ({
  initDataImporters: () => {
    dispatch({ type: 'DATA_IMPORTERS:LIST:REQUEST:INIT' });
    dispatch({ type: 'DATA_IMPORTERS:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'DATA_IMPORTERS:LIST:REQUEST', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'DATA_IMPORTERS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataImportersSelector);
