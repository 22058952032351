/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  addTranslations,
  editTranslations
} from '@packages/utils/actionTranslations';

import RetentionTerm from '.';

const RetentionTermDialog = (props) => {
  useEffect(
    () => () => {
      props.onOffsetSearch(null);
    },
    []
  );
  const handleOnSubmit = (selectedItem, closeDialog) => {
    const item = selectedItem;
    if (props.handleSelectedItem) {
      item.key = selectedItem.name;
      props.handleSelectedItem({ value: item }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="retention-term-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={
        !props.isEdit
          ? addTranslations('addRetentionTerm')
          : editTranslations('editRetentionTerm')
      }
    >
      <RetentionTerm
        id="retention-term"
        isEdit={props.isEdit}
        dataSourceFilter={props.dataSourceFilter}
        onSaveRetentionTerm={props.onSaveRetentionTerm}
        onOffsetSearch={props.onOffsetSearch}
        filteredRetentionTerms={props.filteredRetentionTerms}
        offsetDatasource={props.offsetDatasource}
        onScrollStop={props.onScrollStop}
        masterOffsets={props.masterOffsets}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        filterTagValue={props.filterTagValue}
        onChooseFilter={props.onChooseFilter}
        tagSearch={props.tagSearch}
        tagSearchResult={props.tagSearchResult}
        getNextTags={props.getNextTags}
        tagsPosition={props.tagsPosition}
        hideTags={props.isGlobal}
        createNewMenuItem={props.createNewMenuItem}
      />
    </CommonDialog>
  );
};

RetentionTermDialog.propTypes = {
  dataItemId: PropTypes.string,
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSaveRetentionTerm: PropTypes.func,
  onScrollStop: PropTypes.func,
  offsetDatasource: PropTypes.shape({}),
  filteredRetentionTerms: PropTypes.shape({}),
  onOffsetSearch: PropTypes.func,
  dataSourceFilter: PropTypes.func,
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  inputValue: PropTypes.shape({}),
  masterOffsets: PropTypes.shape({}),
  onChooseFilter: PropTypes.func,
  tagSearch: PropTypes.func,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  filterTagValue: PropTypes.instanceOf(Immutable.List),
  isGlobal: PropTypes.bool,
  createNewMenuItem: PropTypes.bool
};

RetentionTermDialog.defaultProps = {
  open: false,
  isEdit: false,
  onRequestClose: (e) => e,
  onOffsetSearch: (e) => e,
  offsetDatasource: {},
  onSaveRetentionTerm: (e) => e,
  onScrollStop: (e) => e,
  handleSelectedItem: (e) => e,
  inputValue: {},
  masterOffsets: {},
  dataSourceFilter: (e) => e,
  filteredRetentionTerms: {},
  dataItemId: '',
  filterTagValue: Immutable.List(),
  tagSearchResult: Immutable.List(),
  onChooseFilter: (e) => e,
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  isGlobal: false,
  createNewMenuItem: false
};

export default RetentionTermDialog;
