import Immutable from 'immutable';
import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';
import { getSearchItems } from '../record-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  recordType: '',
  sortAndFilterParams: Immutable.Map({
    all: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    },
    processing: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    },
    assessment: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    },
    breach: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    },
    tia: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    },
    document_records: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      sortKey: 'lastChanged',
      sortOrder: 'DESC',
      filterParams: Immutable.Map(),
      pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
    }
  }),
  bulkItems: Immutable.Map({
    all: {
      selectedRecords: []
    },
    processing: {
      selectedRecords: []
    },
    assessment: {
      selectedRecords: []
    },
    breach: {
      selectedRecords: []
    },
    tia: {
      selectedRecords: []
    },
    document_records: {
      selectedRecords: []
    }
  }),
  searchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  usage: undefined,
  items: Immutable.List(),
  controllers: Immutable.List(),
  executingEntities: Immutable.List(),
  error: {
    message: '',
    isError: false
  },
  isTemplateMode: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY_RECORDS:LIST:INIT':
      return state
        .set('sortAndFilterParams', state.get('sortAndFilterParams'))
        .set('searchText', defaultState.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('items', defaultState.get('items'))
        .set('recordType', defaultState.get('recordType'))
        .set('position', 0);

    case 'PRIVACY_RECORDS:CUSTOM:FILTER:INIT': {
      const modifiedfilters = {};
      const modifiedBulkItems = {};
      action.customRecordTypes.forEach((item) => {
        item.subMenu.forEach((subItem) => {
          modifiedfilters[subItem.key] = {
            filteredOn: Immutable.Map(),
            filterColumn: {},
            sortKey: 'lastChanged',
            sortOrder: 'DESC',
            filterParams: Immutable.Map(),
            pageSearchText: Immutable.Map({ allFields: Immutable.Map() })
          };
          modifiedBulkItems[subItem.key] = {
            selectedRecords: []
          };
        });
      });
      return state
        .set(
          'sortAndFilterParams',
          Immutable.Map({
            ...state.get('sortAndFilterParams').toJS(),
            ...modifiedfilters
          })
        )
        .set(
          'bulkItems',
          Immutable.Map({
            ...state.get('bulkItems').toJS(),
            ...modifiedBulkItems
          })
        );
    }

    case 'PRIVACY_RECORDS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('recordType', action.recordType)
        .set('error', defaultState.get('error'));
    case 'PRIVACY_RECORDS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('controllers', Immutable.List(action.controllers))
        .set('executingEntities', Immutable.List(action.executingEntities))
        .set('position', state.get('position') + action.items.length);
    case 'PRIVACY_RECORDS:LIST:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'PRIVACY_RECORDS:LIST:UPDATE':
      return state
        .set('items', action.items)
        .set(
          'position',
          action.position ? action.position : state.get('position')
        );

    case 'PRIVACY_RECORDS:LIST:FILTER': {
      const { recordType = 'all', filterParams, appendFilter = true } = action;
      const sortAndFilterParams = state.get('sortAndFilterParams');
      const newFilterParams = {
        [filterParams.filterKey]: filterParams.filterKey
      };
      const currentRecordTypeParams = sortAndFilterParams.get(recordType);
      currentRecordTypeParams.filterParams = Immutable.Map(filterParams);
      currentRecordTypeParams.filterColumn = appendFilter
        ? Object.assign({}, currentRecordTypeParams.filterColumn, {
          [filterParams.filterKey]: filterParams.filterKey
        })
        : newFilterParams;
      currentRecordTypeParams.sortOrder = filterParams.sortOrder;
      currentRecordTypeParams.sortKey = filterParams.sortOn;
      currentRecordTypeParams.filteredOn = getFilteredOn(
        Object.assign({}, filterParams, {
          filterObj: appendFilter
            ? currentRecordTypeParams.filteredOn
            : undefined
        })
      );
      sortAndFilterParams.set(recordType, currentRecordTypeParams);

      return state
        .set('items', Immutable.List())
        .set('position', 0)
        .set('sortAndFilterParams', sortAndFilterParams);
    }

    case 'PRIVACY_RECORDS:LIST:PAGE_SEARCH': {
      const { searchParameters } = action;
      const recordType = state.get('recordType') || 'all';
      const sortAndFilterParams = state.get('sortAndFilterParams');
      const currentRecordTypeParams = sortAndFilterParams.get(recordType);
      currentRecordTypeParams.pageSearchText = Immutable.Map(searchParameters);
      sortAndFilterParams.set(recordType, currentRecordTypeParams);
      return state
        .set('isFetching', true)
        .set('sortAndFilterParams', sortAndFilterParams)
        .set('error', defaultState.get('error'));
    }
    case 'PRIVACY_RECORDS:LIST:PAGE_SEARCH:SUCCESS': {
      const { searchParameters } = action;
      const recordType = state.get('recordType') || 'all';
      const sortAndFilterParams = state.get('sortAndFilterParams');
      const currentRecordTypeParams = sortAndFilterParams.get(recordType);
      currentRecordTypeParams.pageSearchText = Immutable.Map(searchParameters);
      sortAndFilterParams.set(recordType, currentRecordTypeParams);
      return state
        .set('isFetching', false)
        .set('sortAndFilterParams', sortAndFilterParams)
        .set('items', Immutable.List(action.items));
    }
    case 'PRIVACY_RECORDS:LIST:PAGE_SEARCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'PRIVACY_RECORDS:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'PRIVACY_RECORDS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set(
          'searchText',
          getSearchText(
            Object.assign({}, action, {
              searchObj: state.get('searchText').toJS()
            })
          )
        )
        .set('searchItems', getSearchItems(action));
    case 'PRIVACY_RECORDS:LIST:SEARCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'PRIVACY_RECORDS:FILTER:RESET': {
      const { recordType = 'all' } = action;
      const filter = {
        ...state.get('sortAndFilterParams').get(recordType),
        filteredOn: Immutable.Map(),
        filterColumn: {},
        sortKey: 'lastChanged',
        sortOrder: 'DESC',
        filterParams: Immutable.Map()
      };

      return state
        .set('searchItems', defaultState.get('searchItems'))
        .set('searchText', defaultState.get('searchText'))
        .updateIn(['sortAndFilterParams', `${recordType}`], () => filter);
    }
    case 'PRIVACY_RECORDS:USAGE:FETCH:SUCCESS':
      return state.set('usage', action.usage);
    case 'PRIVACY_RECORDS:USAGE:RESET':
    case 'PRIVACY_RECORDS:USAGE:INIT':
      return state.set('usage', defaultState.get('usage'));
    case 'RECORD:BULK_PDF:DOWNLOAD:INIT':
      return state.set('isDownloading', true);
    case 'RECORD:BULK_PDF:DOWNLOAD:SUCCESS':
    case 'RECORD:BULK_PDF:DOWNLOAD:FAIL':
      return state.set('isDownloading', false);
    case 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD': {
      const { recordType, selectedRecords } = action;
      let bulkItems = state.get('bulkItems').toJS();
      const currentBulkItems = { ...bulkItems[recordType] };
      if (currentBulkItems) {
        currentBulkItems.selectedRecords = selectedRecords;
        bulkItems = { ...bulkItems, [recordType]: currentBulkItems };
      }
      const newState = state.set('bulkItems', Immutable.Map(bulkItems));
      return newState;
    }
    case 'PRIVACY_RECORDS:BULK_UPDATE:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'RECORDS:MODE:TOGGLE':
      return state.set('isTemplateMode', action.checked);
    default:
      return state;
  }
};
