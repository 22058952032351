import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import dsrTranslations from '../../../../dsr/dsrTranslations';

export const BulkUpdateValidationErrors = (props) => {
  const {
    open,
    requestClose,
    validationErrors,
    selectedRecords,
    updateItems,
    updateType,
    bulkUpdate
  } = props;

  const renderError = (item) => {
    const errorRecords = selectedRecords.filter((record) =>
      item.recordIds.includes(record.id)
    );
    const recordNames = errorRecords
      .map((record) => record.privacyRecordNumber)
      .join(', ');
    return (
      <div key={item.error} style={{ marginTop: 20 }}>
        <div style={{ color: 'red' }}>{item.error}:</div>
        <div style={{ marginTop: 10, marginLeft: 20 }}>{recordNames}</div>
      </div>
    );
  };

  const handleSubmit = () => {
    bulkUpdate(updateType, updateItems, recordsToUpdate);
    requestClose();
  };

  const getValidatedRecordIds = () => {
    const errorRecordIds = validationErrors
      .map((errorItem) => errorItem.recordIds)
      .reduce((arr, item) => [...arr, ...item]);
    const recordIds = selectedRecords.map((item) => item.id);
    const recordIdsToUpdate = recordIds.filter(
      (id) => !errorRecordIds.includes(id)
    );
    return recordIdsToUpdate;
  };

  const recordsToUpdate = getValidatedRecordIds();

  return (
    <div>
      {open && (
        <CommonDialog
          id="bulk-update-dialog"
          maxWidth="md"
          show={open}
          onCancel={requestClose}
          title={
            <div>
              {recordTranslations.bulkUpdate} - {dsrTranslations.validation}
            </div>
          }
          fullWidth={true}
        >
          <div>
            {recordsToUpdate.length > 0
              ? recordTranslations.cannotUpdate
              : recordTranslations.updateNone}
            {validationErrors &&
              validationErrors.map((error) => renderError(error))}
          </div>
          <div style={{ width: '100%', display: 'flex', marginTop: 12 }}>
            {recordsToUpdate.length > 0 && (
              <Button
                id="continue_button"
                style={{ marginRight: 17 }}
                onClick={handleSubmit}
              >
                {recordTranslations.skipAndContinue}
              </Button>
            )}
            <Button
              id="cancel_bulkUpdate"
              variant="text"
              style={{ marginRight: 17 }}
              onClick={requestClose}
            >
              {commonTranslations.Cancel}
            </Button>
          </div>
        </CommonDialog>
      )}
    </div>
  );
};

BulkUpdateValidationErrors.propTypes = {
  open: PropTypes.bool,
  requestClose: PropTypes.func,
  bulkUpdate: PropTypes.func,
  updateType: PropTypes.string,
  updateItems: PropTypes.shape({}),
  validationErrors: PropTypes.arrayOf(PropTypes.shape({})),
  selectedRecords: PropTypes.arrayOf(PropTypes.string)
};

BulkUpdateValidationErrors.defaultProps = {
  open: false,
  requestClose: (e) => e,
  bulkUpdate: (e) => e,
  updateType: '',
  updateItems: {},
  validationErrors: [],
  selectedRecords: []
};

export default BulkUpdateValidationErrors;
