import { connect } from 'react-redux';
import BreachGroundSelector from './component';
import reducers from './reducers';

const mapStateToProps = state => ({
  filteredOn: state.breachGrounds.get('filteredOn').toJS()
});

const mapDispatchToProps = dispatch => ({
  removeFilters: () => {
    dispatch({ type: 'BREACH_GROUNDS:LIST:FILTER:REMOVE' });
    dispatch({ type: 'BREACH_GROUNDS:LIST:INIT' });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(BreachGroundSelector);
export { reducers };
