/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import DataSource from './dataSource';

const mapStateToProps = (state) => ({
  dataSourceData: state.environment.dataSources.get('dataSourceData'),
  actionError: state.environment.dataSources.get('error').toJS(),
  searchResults: state.environment.dataSources.get('searchResults'),
  globalUsage: state.environment.dataSources.get('globalUsage'),
  isGlobal: state.home.get('isGlobal'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT,
  tags: state.environment.simpleEntities?.tags.get('items'),
  tagsPosition: state.environment.simpleEntities?.tags.get('position'),
  tagSearchText: state.environment.simpleEntities?.tags.get('searchText')
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'DATASOURCES:ITEM:FETCH', id });
    dispatch({ type: 'MASTERDATA:TAGS:LIST:REQUEST:INIT' });
  },
  updateDataSources: (data, isEdit, source, isUsed) => {
    if (isUsed) {
      dispatch({
        type: 'DATASOURCES:ITEM:CREATE:JOB',
        data: data.value,
        actionType: 'Edit'
      });
    } else {
      dispatch({ type: 'DATASOURCES:LIST:UPSERT', data, isEdit, source });
    }
  },
  resetDataSources: () => {
    dispatch({ type: 'MASTERDATA:DATASOURCES:ITEM:FETCH:INIT' });
  },
  getTagItems: () => {
    dispatch({
      type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
      masterDataType: 'tags'
    });
  },
  onTagSearch: (searchKey, searchParams) => {
    dispatch({
      type: 'SIMPLE_ENTITY:ITEM:SEARCH_INIT',
      searchKey,
      searchParams,
      masterDataType: 'tags'
    });
  },
  getNextTagItems: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      position,
      isPersistedData: true,
      masterDataType: 'tags'
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSource);
