import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  data: {},
  isEdit: false,
  tenantId: undefined,
  unSavedData: false,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  tenantUsageDetails: Immutable.Map({}),
  allFrameworkTemplates: [],
  allLibraryLayouts: [],
  allLibraryTemplates: [],
  allLibraryRecords: [],
  isFetching: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TENANT:DETAIL:INIT':
    case 'TENANT:DETAIL:RESET':
      return state
        .set('tenantId', defaultState.get('tenantId'))
        .set('isEdit', defaultState.get('isEdit'))
        .set('data', defaultState.get('data'))
        .set('actionError', defaultState.get('actionError'))
        .set('unSavedData', defaultState.get('unSavedData'));

    case 'TENANT:DETAIL:FETCH':
      return state
        .set('tenantId', action.tenantId)
        .set('isEdit', true)
        .set('isFetching', true);
    case 'TENANT:DETAIL:FETCH:SUCCESS':
      return state
        .set('data', action.data)
        .set('isFetching', false);
    case 'TENANT:DETAIL:FETCH:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      ).set('isFetching', false);
    case 'TENANT:DETAIL:UNSAVED:SET':
      return state.set('unSavedData', action.value);
    case 'TENANT:DETAIL:UPSERT':
      return state.set('actionError', defaultState.get('actionError'));
    case 'TENANT:DETAIL:UPSERT:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );

    case 'TENANT:PRICING:PLAN:FETCH':
      return state.set(
        'tenantUsageDetails',
        defaultState.get('tenantUsageDetails')
      );
    case 'TENANT:PRICING:PLAN:FETCH:SUCCESS':
      return state.set('tenantUsageDetails', Immutable.Map(action.data));
    case 'TEMPlATES:FRAMEWORKS:FETCH:SUCCESS':
      return state.set('allFrameworkTemplates', action?.data);
    case 'TENANT:LIBRARY_LAYOUT:FETCH:SUCCESS':
      return state.set('allLibraryLayouts', action.data);
    case 'TENANT:LIBRARY_TEMPLATES:FETCH:SUCCESS':
      return state.set('allLibraryTemplates', action.data);
    case 'TENANT:LIBRARY_RECORDS:FETCH:SUCCESS':
      return state.set('allLibraryRecords', action.data);
    default:
      return state;
  }
};
