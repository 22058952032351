import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Column, Cell } from 'fixed-data-table';
import { FormattedMessage } from 'react-intl';

import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell
} from '@packages/components/responsive-table';
import {
  commonTranslations,
  recordTranslations,
  tenantTranslations
} from '@packages/utils/commontranslations';
import {
  viewTranslations,
  removeTranslations,
  editTranslations
} from '@packages/utils/actionTranslations';
import { settingsTranslations } from '@packages/features/settings/settingsTranslations';
import styles from '@packages/ui/styles';
import { getContainerHeight, getRowCount } from '@packages/utils/common-utils';

import TenantDialog from '../tenant-dialog';

const actionItems = [
  {
    action: 'edit',
    primaryText: editTranslations('editTenant')
  },
  {
    action: 'delete',
    primaryText: removeTranslations('removeTenant')
  },
  {
    action: 'view',
    primaryText: viewTranslations('view-tenant')
  }
];

const tenantStatus = [
  {
    label: tenantTranslations.Activated,
    value: 'Activated'
  },
  {
    label: tenantTranslations.Created,
    value: 'Created'
  },
  {
    label: tenantTranslations.Failed,
    value: 'Failed'
  },
  {
    label: tenantTranslations.Expired,
    value: 'Expired'
  },
  {
    label: tenantTranslations.Deleted,
    value: 'Deleted'
  }
];

// Count cell
const CountCell = ({ items, rowIndex, columnKey, ...props }) => {
  let count = 0;
  let maxCount = 0;
  if (columnKey === 'users') {
    const { users, billingPlan } = items.get(rowIndex);
    count = users;
    maxCount = billingPlan.maxUsers;
  } else if (columnKey === 'subTenants') {
    const { subTenantCount, globalInfo } = items.get(rowIndex);
    count = subTenantCount;
    maxCount = globalInfo.maxSubTenants;
  }
  const style = count > maxCount ? { color: 'red' } : {};
  return (
    <Cell {...props}>
      <span style={style}>
        {count}/{maxCount}
      </span>
    </Cell>
  );
};

CountCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number,
  columnKey: PropTypes.string
};

CountCell.defaultProps = {
  items: Immutable.List(),
  rowIndex: 0,
  columnKey: ''
};

// Status cell
const StatusCell = ({ items, rowIndex, ...props }) => {
  const { status } = items.get(rowIndex);
  let style = {};
  if (status === 'Failed') {
    style = { color: 'red', fontWeight: 'bold' };
  } else if (status === 'Expired') {
    style = { color: '#f76b13', fontWeight: 'bold' };
  }

  return (
    <Cell {...props}>
      {status && <span style={style}>{tenantTranslations[status]}</span>}
    </Cell>
  );
};

StatusCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number
};

StatusCell.defaultProps = {
  items: Immutable.List(),
  rowIndex: 0
};

const TenantList = (props, context) => {
  const partnersFilterValues = (partners) =>
    partners.map((item) => ({ label: item.name, value: item.id }));
  const [openDialog, setOpenDialog] = useState(false);
  const [tenantData, setTenantData] = useState(undefined);
  const [isView, setIsView] = useState(true);

  useEffect(() => {
    const rowCount = getRowCount();
    if (props.onMount) {
      props.onMount(rowCount);
    }
    return () => props.resetPartner();
  }, []);

  useEffect(() => {
    props.toggleLoader(props.isFetching);
  }, [props.isFetching]);


  const handleScrollEnd = (scrollX, scrollY) => {
    const rowCount = getRowCount();
    if (
      props.items.size >= rowCount &&
      40 * props.items.size - getContainerHeight() <= scrollY
    ) {
      props.getNextData(props.position, rowCount);
    }
  };

  const handleTenantActions = (e, index, action) => {
    const tenantId = props.items.get(index).id;
    if (action === 'edit') {
      if (props.isHolding)
        context.router.history.push(`/holdingTenant/${tenantId}/edit`);
      else context.router.history.push(`/tenant/${tenantId}/edit`);
    } else if (action === 'delete' || action === 'view') {
      setIsView(action === 'view');
      setOpenDialog(true);
      setTenantData(props.items.get(index));
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setTenantData(undefined);
  };

  const handleMenuHidden = (menu, rowIndex, action) => {
    const items = props.items.get(rowIndex);
    const isDeleted = items.status === 'Deleted';
    if (action === 'delete' || action === 'edit') {
      return isDeleted;
    }
    if (action === 'view') {
      return !isDeleted;
    }
    return false;
  };

  const filterData = (filterKey, filterParams) => {
    props.onChooseFilter(
      Object.assign({}, filterParams, { filterKey }),
      getRowCount()
    );
  };

  const searchData = (sortOn, searchParams) => {
    props.onSearch(
      Object.assign({}, searchParams, { sortOn: 'name', sortOrder: 'ASC' })
    );
  };

  const searchPartnerData = (sortOn, searchParams) => {
    const modifiedSearchParams = { ...searchParams, searchKey: 'name' };
    props.partnerSearch(
      Object.assign({}, modifiedSearchParams, {
        sortOn: 'name',
        sortOrder: 'ASC'
      })
    );
  };

  const disableActions = (menu, rowIndex, action) => {
    const item = props.items.get(rowIndex);
    return item.status === 'Created'
      ? {
        edit: tenantTranslations.disableEdit,
        delete: tenantTranslations.disableDelete
      }[action]
      : false;
  };

  const {
    isFetching,
    items,
    locale,
    hasCreateTenantPermission,
    pageSearchText,
    filteredOn,
    sortKey,
    sortOrder,
    filterColumn,
    partners,
    isSuperAdmin,
    isHolding
  } = props;
  const partnerItems =
    partners.searchItems && partnersFilterValues(partners.searchItems);

  const modifiedItems = items.map((item) => ({
    ...item,
    subscriptionStatus:
      item.subscriptionInfo &&
      settingsTranslations[item.subscriptionInfo.status]
  }));

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div style={styles.tableNoContentStyle}>
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="tenant_list"
        rowHeight={40}
        headerHeight={45}
        rowsCount={items.size}
        showActionSelector={hasCreateTenantPermission || isSuperAdmin}
        actionChooserStyle={styles.actionChooserStyle}
        actionItems={actionItems}
        onActionHandler={handleTenantActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        isDisabled={disableActions}
        {...props}
      >
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              enableSorting={true}
              enablePageSearch={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText}
              onSearch={searchData}
              onChooseFilter={filterData}
            >
              <FormattedMessage
                id="TenantList.name"
                description="Tenant List Column Name"
                defaultMessage="Tenant"
              />
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={1.5}
          width={120}
        />
        <Column
          columnKey="users"
          visible={!isHolding}
          header={
            <SortHeaderCell enableSorting={false}>
              {commonTranslations.users}
            </SortHeaderCell>
          }
          cell={<CountCell items={items} />}
          flexGrow={0.5}
          width={120}
        />
        <Column
          columnKey="partner"
          id="partner"
          visible={!isHolding}
          header={
            <SortHeaderCell
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filteredOn={filteredOn}
              enableSearch={true}
              enableFilter={true}
              showSelectAll={false}
              searchKey="name"
              searchText={partners.searchText}
              filterValues={partnerItems?.length === 0 ? [] : partnerItems}
              filterColumn={filterColumn && filterColumn.partner}
              onSearch={searchPartnerData}
              onChooseFilter={filterData}
            >
              <FormattedMessage
                id="TenantDetail.Partner"
                description="Partner"
                defaultMessage="Partner"
              />
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={0.5}
          width={120}
        />
        <Column
          columnKey="subTenants"
          visible={isHolding}
          header={
            <SortHeaderCell enableSorting={false}>
              {tenantTranslations.subTenants}
            </SortHeaderCell>
          }
          cell={<CountCell items={items} />}
          flexGrow={0.6}
          width={60}
        />
        <Column
          columnKey="startDate"
          header={
            <SortHeaderCell enableSorting={false}>
              {recordTranslations.startDate}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD/MM/YYYY" items={items} locale={locale} />
          }
          flexGrow={0.5}
          width={70}
        />
        <Column
          columnKey="endDate"
          header={
            <SortHeaderCell
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              {recordTranslations.endDate}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD/MM/YYYY" items={items} locale={locale} />
          }
          flexGrow={0.5}
          width={70}
        />
        <Column
          id="tenantType"
          columnKey="tenantType"
          visible={!isHolding}
          header={
            <SortHeaderCell enableSorting={false}>
              {tenantTranslations.tenantType}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={0.8}
          width={80}
        />
        <Column
          columnKey="status"
          header={
            <SortHeaderCell
              enableSorting={false}
              filteredOn={filteredOn}
              enableFilter={true}
              filterValues={tenantStatus}
              filterColumn={filterColumn && filterColumn.status}
              onChooseFilter={filterData}
            >
              <FormattedMessage
                id="TenantList.status"
                description="Status"
                defaultMessage="Status"
              />
            </SortHeaderCell>
          }
          cell={<StatusCell items={items} />}
          flexGrow={0.5}
          width={60}
        />
        <Column
          id="subscriptionStatus"
          columnKey="subscriptionStatus"
          visible={!isHolding}
          header={
            <SortHeaderCell enableSorting={false}>
              {tenantTranslations.subscriptionStatus}
            </SortHeaderCell>
          }
          cell={<DataCell items={modifiedItems} />}
          flexGrow={1.5}
          width={120}
        />
      </ResponsiveTableWrapper>
      {openDialog && (
        <TenantDialog
          show={openDialog}
          requestClose={handleClose}
          isView={isView}
          data={tenantData}
        />
      )}
    </div>
  );
};

TenantList.displayName = 'TenantList';

TenantList.propTypes = {
  locale: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  hasCreateTenantPermission: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  resetPartner: PropTypes.func,
  position: PropTypes.number,
  onMount: PropTypes.func,
  onSearch: PropTypes.func,
  partnerSearch: PropTypes.func,
  partners: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  partnerSearchItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.array),
  filterColumn: PropTypes.shape({
    size: PropTypes.number,
    partner: PropTypes.instanceOf(Immutable.List),
    status: PropTypes.instanceOf(Immutable.List)
  }),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  onChooseFilter: PropTypes.func,
  getNextData: PropTypes.func,
  filterParams: PropTypes.shape({
    get: PropTypes.func
  }),
  items: PropTypes.instanceOf(Immutable.List),
  isHolding: PropTypes.bool,
  toggleLoader: PropTypes.func
};

TenantList.defaultProps = {
  isFetching: false,
  isSuperAdmin: true,
  hasCreateTenantPermission: true,
  position: 0,
  partners: Immutable.List(),
  partnerSearchItems: Immutable.List(),
  onMount: (e) => e,
  onSearch: (e) => e,
  resetPartner: (e) => e,
  partnerSearch: (e) => e,
  pageSearchText: Immutable.Map(),
  filteredOn: {},
  filterColumn: {},
  sortKey: '',
  sortOrder: '',
  onChooseFilter: (e) => e,
  getNextData: (e) => e,
  filterParams: {
    get: (e) => e
  },
  items: Immutable.List(),
  isHolding: false,
  toggleLoader: (e) => e
};

TenantList.contextTypes = {
  router: PropTypes.object.isRequired
};

export default TenantList;
