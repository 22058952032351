import React, { useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import CountrySelector from '@packages/components/country-selector';
import MultipleSelector from '@packages/components/multiple-selector/multipleSelector';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

const SupervisoryMultiselector = (props) => {
  const [filterValues, setFilterValues] = useState(props.filteredData);
  const [country, setCountry] = useState('');

  const handleCountryChange = (selectedItem) => {
    setCountry(selectedItem || '');

    if (selectedItem) {
      // in backend, the id for United Kingdom is 'UK' (Only for supervisory authorities)
      filterSupervisoryAuthorities(selectedItem === 'GB' ? 'UK' : selectedItem);
    } else {
      setFilterValues(props.filteredData);
    }
  };

  const handleOnSubmit = (selectedItems) => {
    if (props.handleMultipleItems) {
      const modifiedItems = selectedItems.map(item => ({ value: item }));
      props.handleMultipleItems(modifiedItems);
    }
    requestClose();
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const filterSupervisoryAuthorities = (searchByJurisdiction) => {
    const newFilterValues = props.filteredData.filter(authority =>
      authority.jurisdiction === searchByJurisdiction);
    setFilterValues(newFilterValues);
  };

  const { multiValue, title, open, key } = props;

  return (
    <CommonDialog
      id="supervisory-dialog"
      show={open}
      onCancel={requestClose}
      fullWidth={true}
      maxWidth="sm"
      title={title}
    >
      <div style={{ display: 'flex', marginBottom: '15px', marginTop: '5px' }}>
        <div style={{ width: '75%' }} >
          <CountrySelector
            defaultValue={country}
            hintTextLabel={<FormattedMessage
              id="SupervisoryAuthorities.filterByJurisdiction"
              description="Select jurisdiction"
              defaultMessage="Select jurisdiction"
            />}
            onChange={handleCountryChange}
          />
        </div>
        <FontAwesome
          name="times-circle"
          style={{ lineHeight: '1em',
            fontSize: '20px',
            marginTop: 10,
            marginLeft: 15,
            color: 'black',
            cursor: 'pointer' }}
          onClick={() => handleCountryChange()}
        />
      </div>
      <div>
        <MultipleSelector
          key={key}
          filteredData={filterValues}
          multiValue={multiValue}
          onSave={handleOnSubmit}
          onCancel={requestClose}
        />
      </div>
    </CommonDialog>
  );
};

SupervisoryMultiselector.propTypes = {
  filteredData: PropTypes.instanceOf(Immutable.List),
  key: PropTypes.string,
  title: PropTypes.node,
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  multiValue: PropTypes.bool,
  open: PropTypes.bool
};

SupervisoryMultiselector.defaultProps = {
  key: '',
  open: false,
  multiValue: true,
  title: null,
  filteredData: Immutable.List(),
  onRequestClose: e => e,
  handleMultipleItems: e => e
};
export default SupervisoryMultiselector;
