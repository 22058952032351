import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import {
  customTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';

import CreateRecordDialog from '../../../privacy-records/components/header/component/createRecordDialog';
import {
  getCreateDialogHintTextLabel,
  getCreateDialogSubTitle
} from '../../common-utils';

const CreateCustomRecord = (props) => {
  const {
    open,
    onRequestClose,
    actionError,
    isUpdating,
    data,
    toggleLoader,
    isVendor,
    currentFilter,
    filterMenuData,
    subRecordTypes,
    isCustomFromAll,
    recordTypeId,
    saveCustomRecord,
    onMount,
    locale,
    tenantLocale,
    layoutId,
    isTemplateMode,
    onUnMount,
    isGlobal
  } = props;

  const shouldShowCustomLabel = isCustomFromAll && filterMenuData?.label;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      onUnMount();
    };
  }, []);

  const handleCreateCustomRecord = (data, templateId, recordType, layoutId) => {
    const modifiedData = {
      ...data,
      recordTypeData: {
        id: isCustomFromAll ? recordTypeId : filterMenuData.recordTypeId
      },
      recordLayoutData: {
        id: isCustomFromAll ? layoutId : filterMenuData.recordLayoutId
      }
    };
    saveCustomRecord(
      modifiedData,
      templateId,
      locale,
      tenantLocale,
      isTemplateMode,
      isGlobal
    );
  };

  const getCreateDialogTitle = () =>
    customTranslation(
      isTemplateMode ? 'createNewTemplateHeader' : 'createNewRecordHeader',
      filterMenuData.recordLabel
    );

  return (
    <CreateRecordDialog
      id="create-custom-record"
      open={open}
      title={getCreateDialogTitle()}
      subTitle={getCreateDialogSubTitle(
        shouldShowCustomLabel,
        isTemplateMode,
        filterMenuData?.label
      )}
      label={
        filterMenuData?.label
          ? customTranslation('emptyRecord', filterMenuData?.label)
          : recordTranslations.emptyRecord
      }
      recordType="custom"
      currentFilter={currentFilter}
      hintTextLabel={getCreateDialogHintTextLabel(
        shouldShowCustomLabel,
        isTemplateMode
      )}
      hintTextCustomValue={filterMenuData.label}
      data={data}
      isUpdating={isUpdating}
      actionError={actionError}
      createRecord={handleCreateCustomRecord}
      onRequestClose={onRequestClose}
      toggleLoader={toggleLoader}
      isVendor={isVendor}
      filterMenuData={filterMenuData}
      subRecordTypes={subRecordTypes}
      isCustomFromAll={isCustomFromAll}
      layoutId={layoutId}
    />
  );
};

CreateCustomRecord.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({}),
  isUpdating: PropTypes.bool,
  onMount: PropTypes.func,
  saveCustomRecord: PropTypes.func,
  onRequestClose: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  toggleLoader: PropTypes.func,
  isVendor: PropTypes.bool,
  currentFilter: PropTypes.string,
  filterMenuData: PropTypes.shape({
    label: PropTypes.string,
    recordTypeId: PropTypes.string,
    recordLayoutId: PropTypes.string,
    recordLabel: PropTypes.string
  }),
  locale: PropTypes.string,
  tenantLocale: PropTypes.string,
  isCustomFromAll: PropTypes.bool,
  recordTypeId: PropTypes.string,
  subRecordTypes: PropTypes.shape({}),
  layoutId: PropTypes.string,
  isTemplateMode: PropTypes.bool,
  onUnMount: PropTypes.func,
  isGlobal: PropTypes.bool
};

CreateCustomRecord.defaultProps = {
  data: {},
  open: false,
  isUpdating: false,
  onMount: (e) => e,
  saveCustomRecord: (e) => e,
  onRequestClose: (e) => e,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  toggleLoader: (e) => e,
  isVendor: false,
  currentFilter: '',
  filterMenuData: {},
  locale: '',
  tenantLocale: '',
  isCustomFromAll: false,
  recordTypeId: '',
  subRecordTypes: {},
  layoutId: '',
  isTemplateMode: false,
  onUnMount: (e) => e,
  isGlobal: false
};

export default CreateCustomRecord;
