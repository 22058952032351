import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import {
  handleServiceDown,
  getErrorMessage
} from '@packages/utils/common-utils';
import notificationTranslation from '@packages/utils/notificationtranslations';

const defaultData = {
  bannerType: 'Information',
  isActive: true,
  message: ''
};

export function* fetchBannerSettings() {
  try {
    const request = registry.get('request');
    const response = yield request.get('/v1/banner', null, {});
    switch (response.status) {
      case 200: {
        const items = response.body;
        yield put({
          type: 'ADMIN:BANNER:FETCH:SUCCESS',
          messageDetails: items
        });
        yield put(initialize('MessageBanner', items));
        break;
      }
      case 204: {
        yield put({
          type: 'ADMIN:BANNER:FETCH:SUCCESS',
          messageDetails: defaultData
        });
        yield put(initialize('MessageBanner', defaultData));
        break;
      }
      case 403: {
        const error = response.body.msg;

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'ADMIN:BANNER:FETCH:FAIL', error: err.message });
  }
}

export function* updateBannerSettings(action) {
  const { data } = action;
  const request = registry.get('request');
  try {
    const response = yield request.post('/v1/banner', data);
    switch (response.status) {
      case 200: {
        const items = response.body;
        yield put({
          type: 'ADMIN:BANNER:UPSERT:SUCCESS',
          messageDetails: items
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationTranslation.bannerMessageUpdateSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        const error = response.body ? response.body.msg : '';
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'ADMIN:BANNER:UPSERT:FAIL', error });
  }
}
