import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ActionHelp from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import ArrowTooltip from '@packages/components/tooltip';
import {
  commonTranslations,
  eventTypeTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';

const listItemStyle = {
  paddingBottom: 5,
  fontSize: 16,
  fontWeight: 500
};

const ImpactAnalysisPage = (props) => {
  const {
    handleEditWithJob,
    hasNoLimit,
    isDelete,
    isJobActive,
    label,
    type,
    usage
  } = props;

  const getModifiedList = () => {
    const { usage } = props;
    const isUsedKeys = Object.keys(usage);
    const items = [];
    isUsedKeys.forEach((key) => {
      items.push(
        <li key={key} style={listItemStyle}>
          {' '}
          {usage[key]} {eventTypeTranslations[key]}{' '}
        </li>
      );
    });
    return items;
  };

  const getModifiedListforMerge = (item) => {
    const { usage } = props;
    const items = [];
    const currentUsage = usage.filter(
      (value) => value.masterDataEntityId === item.id
    );
    if (currentUsage.length > 0) {
      const currentUsageKeys = Object.keys(currentUsage[0].usage);
      currentUsageKeys.forEach((key) => {
        items.push(
          <li style={listItemStyle}>
            {' '}
            {currentUsage[0].usage[key]} {eventTypeTranslations[key]}{' '}
          </li>
        );
      });
      return (
        <div>
          {currentUsageKeys.length > 0 && item.name}
          <div style={{ marginLeft: 50, marginTop: 10 }}>{items}</div>
        </div>
      );
    }
    return <div> </div>;
  };

  const [isChecked, setIsChecked] = useState(true);

  const handleChange = () => {
    setIsChecked(!isChecked);
    handleEditWithJob(!isChecked);
  };

  const { mergeDestination, mergeItems } = props;
  const modifiedMergeItems =
    mergeItems.size > 0 &&
    mergeItems.filter((item) => item.id !== mergeDestination);
  const entitiesWithJob = [
    'personaldata',
    'DataRecipientCategory',
    'datasource',
    'Document'
  ];
  const isEditableWithoutJob =
    !isDelete && hasNoLimit && !isJobActive && !entitiesWithJob.includes(type);

  return (
    <div>
      {hasNoLimit ? (
        <div style={{ marginBottom: 0, fontSize: 16, fontWeight: 500 }}>
          {commonTranslations.note}:{' '}
          <FormattedMessage
            id="EditMasterDataDialog.confirmHeader"
            description="This master data item is used in"
            defaultMessage="This {type} is used in"
            values={{ type: recordTranslations[type] || label }}
          />
        </div>
      ) : (
        <div>
          {isDelete ? (
            <div>
              <FormattedMessage
                id="EditMasterDataDialog.UsageDeleteHeader"
                description="Insufficient rights"
                defaultMessage={
                  'You have insufficient rights to delete this entity ' +
                  'as it is being used elsewhere'
                }
              />
            </div>
          ) : (
            <div>
              <FormattedMessage
                id="EditMasterDataDialog.UsageHeader"
                description="Insufficient rights"
                defaultMessage={
                  'You have insufficient rights to edit this entity ' +
                  'as it is being used elsewhere'
                }
              />
            </div>
          )}
          <div style={{ paddingTop: 20 }}>
            <FormattedMessage
              id="EditMasterDataDialog.confirmHeader"
              description="Note: This master data item is used in"
              defaultMessage="This {type} is used in"
              values={{ type: recordTranslations[type] || label }}
            />
          </div>
        </div>
      )}
      <div>
        {modifiedMergeItems.size > 0 ? (
          <div>
            {modifiedMergeItems.map((item) => (
              <ul key={item.id}> {getModifiedListforMerge(item)} </ul>
            ))}
          </div>
        ) : (
          <div>
            {/* usage.tenantUsage field is there only for organisation and masterdata of global solution  */}
            {!usage.tenantUsage ? (
              <div>
                <ul>{getModifiedList()}</ul>
              </div>
            ) : (
              <div>
                <ul>
                  {usage.tenantUsage.map((item) => (
                    <li style={{ marginTop: '5px' }}>{item.tenant.name}</li>
                  ))}
                  {usage.others > 0 && (
                    <div>
                      <li style={{ marginTop: '5px' }}>
                        <FormattedMessage
                          id="EditMasterDataDialog.otherSubtenantCount"
                          defaultMessage="{count} other subtenants"
                          values={{ count: `${usage.others}` }}
                        />
                      </li>
                    </div>
                  )}
                </ul>
              </div>
            )}
            {isEditableWithoutJob && (
              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  style={{
                    alignItems: 'center'
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <div style={{ fontWeight: 'bold', marginTop: 3 }}>
                      {type === 'dataItemGroup' ? (
                        <FormattedMessage
                          id="Masterdata.updateRecordgroups"
                          description="Remove data items from records"
                          defaultMessage="If the change involves removal of data items
                        from group should those items be removed from records too?"
                        />
                      ) : (
                        <FormattedMessage
                          id="Masterdata.updateRecordChangelogs"
                          description="Update record changelogs"
                          defaultMessage="Update record changelogs"
                        />
                      )}
                    </div>
                  }
                />
                <div>
                  <ArrowTooltip
                    title={
                      type === 'dataItemGroup' ? (
                        <FormattedMessage
                          id="Masterdata.updateRecordGroupHint"
                          description="Update record group hint text"
                          defaultMessage="Selecting the check box would also update this group in records and might
                take sometime. However, if unchecked, do consider that the update will not
                remove data items from records"
                        />
                      ) : (
                        <FormattedMessage
                          id="Masterdata.updateRecordHintText"
                          description="Update record hint text"
                          defaultMessage="Selecting the check box would also update the change log version of the item and
                    might take sometime. However, if unchecked, do consider that the update (change) will not be
                    registered in the changelogs of the privacy-records"
                        />
                      )
                    }
                  >
                    <IconButton>
                      <ActionHelp color="primary" />
                    </IconButton>
                  </ArrowTooltip>
                </div>
              </div>
            )}
            {isChecked && isEditableWithoutJob && (
              <div style={{ display: 'flex', color: 'red', marginTop: 10 }}>
                <div>{commonTranslations.note}:</div>
                <div style={{ fontSize: 15, paddingLeft: 7, paddingTop: 2 }}>
                  {type === 'dataItemGroup' ? (
                    <FormattedMessage
                      id="Masterdata.updateRecordDataItemsNotification"
                      description="Awareness message for update record data item"
                      defaultMessage="If another group in the record
                    uses these data item the data will remain in the record"
                    />
                  ) : (
                    <FormattedMessage
                      id="Masterdata.updateRecordNotification"
                      description="Awareness message for update record changelogs"
                      defaultMessage="Be aware that update effects a lot of records that can
                    take a long time and will prevent any changes to the system until complete"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {isJobActive && (
          <div style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id="Masterdata.jobStatusNotification"
              description="Awareness message for job status"
              defaultMessage="This item is currently being edited by another user. Please try again later."
            />
          </div>
        )}
      </div>
    </div>
  );
};

ImpactAnalysisPage.propTypes = {
  hasNoLimit: PropTypes.bool,
  type: PropTypes.string,
  isDelete: PropTypes.bool,
  usage: PropTypes.shape({
    filter: PropTypes.func,
    tenantUsage: PropTypes.arrayOf(PropTypes.shape({})),
    others: PropTypes.number
  }),
  mergeItems: PropTypes.shape({
    map: PropTypes.func,
    size: PropTypes.number,
    filter: PropTypes.func
  }),
  mergeDestination: PropTypes.string,
  handleEditWithJob: PropTypes.func,
  isJobActive: PropTypes.bool
};

ImpactAnalysisPage.defaultProps = {
  hasNoLimit: false,
  isDelete: false,
  usage: {},
  mergeItems: {},
  type: '',
  mergeDestination: '',
  handleEditWithJob: (e) => e,
  isJobActive: false
};

export default ImpactAnalysisPage;
