import { takeEvery } from 'redux-saga/effects';

import { resetAndfetchOrganisationsEntities, fetchOrganisationEntities, upsertOrganisationEntityDetail,
  deleteOrganisationEntity, searchOrganisationEntities, filterOrganisationsEntities,
  resetAndfetchImportOrganisations, importHoldingOrganisations, searchImportOrganisations, fetchParentOrgDetails,
  fetchHoldingOrganisations }
  from './sagas';

export default [
  [takeEvery, 'ORGANISATION_ENTITY:LIST:REQUEST', resetAndfetchOrganisationsEntities],
  [takeEvery, 'ORGANISATION_ENTITY:LIST:NEXT_FETCH', fetchOrganisationEntities],
  [takeEvery, 'ORGANISATION_ENTITY:DETAIL:UPSERT', upsertOrganisationEntityDetail],
  [takeEvery, 'ORGANISATION_ENTITY:ITEM:DELETE', deleteOrganisationEntity],
  [takeEvery, 'ORGANISATION_ENTITY:LIST:SEARCH_INIT', searchOrganisationEntities],
  [takeEvery, 'SUBTENANT:ORGANISATION:LIST:FETCH', resetAndfetchImportOrganisations],
  [takeEvery, 'SUBTENANT:ORGANISATION:DETAILS:IMPORT', importHoldingOrganisations],
  [takeEvery, 'SUBTENANT:ORGANISATION:LIST:SEARCH_INIT', searchImportOrganisations],
  [takeEvery, 'PARENT:ORGANISATION_ENTITY:DETAIL:INIT', fetchParentOrgDetails],
  [takeEvery, 'SUBTENANT:ORGANISATION_ENTITY:LIST:NEXT_FETCH', fetchHoldingOrganisations],
  [takeEvery, 'ORGANISATIONS_ENTITY:LIST:FILTER_INIT', filterOrganisationsEntities]
]
