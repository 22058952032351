/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import LegalEntitySelector from './component';

const mapStateToProps = (state) => ({
  legalEntities: state.environment.organisations.get('items'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  error: state.legalEntities.get('error'),
  entityDetails: state.legalEntitySelector
    ? state.legalEntitySelector.get('entityDetails')
    : [],
  searchResults: state.legalEntities.get('itemSelectorSearch').toJS(),
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  fetchDetails: (entityId) => {
    dispatch({ type: 'ORGANISATION_ENTITY:DETAIL:INIT', entityId });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalEntitySelector);
