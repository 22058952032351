import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import actionTranslations from '@packages/utils/actionTranslations';
import {
  customTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';

import CreateRecordDialog from '../../../privacy-records/components/header/component/createRecordDialog';
import {
  getCreateDialogHintTextLabel,
  getCreateDialogSubTitle
} from '../../common-utils';

const CreateDocuments = (props) => {
  const {
    open,
    onRequestClose,
    actionError,
    isUpdating,
    data,
    toggleLoader,
    isVendor,
    subRecordTypes,
    layoutId,
    currentRecordType,
    filterMenuData,
    saveDocument,
    createFromEdit,
    onMount,
    isTemplateMode
  } = props;

  const shouldShowCustomLabel =
    currentRecordType !== 'all' && filterMenuData?.label;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  const CreateDocument = (data, templateId, __, subRecordId) => {
    const modifiedData = {
      ...data,
      name: data.name.trim(),
      ...(subRecordId && { layoutId: subRecordId })
    };
    saveDocument(modifiedData, templateId, createFromEdit, isTemplateMode);
  };
  const getCreateDialogTitle = () =>
    actionTranslations(
      isTemplateMode ? 'createDocumentTemplate' : 'createDocument'
    );

  return (
    <CreateRecordDialog
      id="create-document"
      open={open}
      title={getCreateDialogTitle()}
      subTitle={getCreateDialogSubTitle(
        shouldShowCustomLabel,
        isTemplateMode,
        filterMenuData?.label
      )}
      hintTextLabel={getCreateDialogHintTextLabel(
        shouldShowCustomLabel,
        isTemplateMode
      )}
      hintTextCustomValue={filterMenuData.label}
      data={data}
      recordType={
        currentRecordType === 'all' ? 'document-records' : currentRecordType
      }
      label={
        shouldShowCustomLabel
          ? customTranslation('emptyRecord', filterMenuData?.label)
          : recordTranslations.emptyRecord
      }
      currentFilter={currentRecordType}
      isUpdating={isUpdating}
      actionError={actionError}
      createRecord={CreateDocument}
      onRequestClose={onRequestClose}
      toggleLoader={toggleLoader}
      isVendor={isVendor}
      subRecordTypes={subRecordTypes}
      layoutId={layoutId}
    />
  );
};

CreateDocuments.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({ name: PropTypes.string }),
  isUpdating: PropTypes.bool,
  onMount: PropTypes.func,
  saveDocument: PropTypes.func,
  createFromEdit: PropTypes.bool,
  onRequestClose: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  toggleLoader: PropTypes.func,
  isVendor: PropTypes.bool,
  subRecordTypes: PropTypes.shape([]),
  layoutId: PropTypes.string,
  currentRecordType: PropTypes.string,
  filterMenuData: PropTypes.shape({ label: PropTypes.string }),
  isTemplateMode: PropTypes.bool
};

CreateDocuments.defaultProps = {
  data: {},
  open: false,
  isUpdating: false,
  createFromEdit: false,
  onMount: (e) => e,
  saveDocument: (e) => e,
  onRequestClose: (e) => e,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  toggleLoader: (e) => e,
  isVendor: false,
  subRecordTypes: [],
  layoutId: '',
  currentRecordType: '',
  filterMenuData: {},
  isTemplateMode: false
};

export default CreateDocuments;
