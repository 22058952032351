import Immutable from 'immutable';

export const getClaimTaskUrl = (taskId, type) => ((type === 'claim') ?
  `/v1/tasks/${taskId}/Claim` : `/v1/tasks/${taskId}/UnClaim`);

export const getURLWithParams = (filterParams, position, searchParams, rowCount = 15, currentUser, currentSubmenu) => {
  let url = `/v1/tasks?numberOfResults=${rowCount}&offset=${position}`;
  if (currentSubmenu === 'unclaimedTasks') {
    url = `${url}&filter=assignee=`;
  } else if (currentUser !== '') {
    url = `${url}&filter=assignee=${currentUser}`;
  }
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText = (typeof (x) === 'object') ? x.props.defaultMessage : x;
          (url = `${url}&filter=${filterKey}%3D${encodeURIComponent(filterText)}`);
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

export const getSearchItems = ({ searchKey, items, searchObj }) => {
  const searchItemObj = searchObj || {};
  searchItemObj[searchKey] = Array.from(new Set(items.map(item => item.name)));
  return Immutable.Map(searchItemObj);
};

export const assigneeMapper = assignee => (assignee ? `${assignee.firstName} ${assignee.lastName || ''}` : '');
