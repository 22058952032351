import React from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  addTranslations,
  editTranslations
} from '@packages/utils/actionTranslations';
import PersonalDataItem from '.';

const PersonalDataItemDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.onSavePersonalDataItem) {
      const modifiedItem = {
        ...selectedItem,
        key: selectedItem.name,
        categories: selectedItem.categories
      };
      props.onSavePersonalDataItem({ value: modifiedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="personal-data-item-dialog"
      show={props.open}
      fullWidth={true}
      maxWidth="sm"
      onCancel={requestClose}
      title={
        !props.isEdit
          ? addTranslations('addPersonalDataItem')
          : editTranslations('editPersonalDataItem')
      }
    >
      <PersonalDataItem
        id="personal-data-item"
        dataItemId={props.dataItemId}
        value={props.inputValue}
        isEdit={props.isEdit}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        isNewItem={props.isNewItem}
        source={props.source}
        isUsed={props.isUsed}
        usage={props.usage}
        isTagEdit={props.isTagEdit}
        refreshList={props.refreshList}
        hideTags={props.hideTags}
      />
    </CommonDialog>
  );
};

PersonalDataItemDialog.propTypes = {
  source: PropTypes.string,
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  isNewItem: PropTypes.bool,
  inputValue: PropTypes.shape({}),
  onRequestClose: PropTypes.func,
  onSavePersonalDataItem: PropTypes.func,
  dataItemId: PropTypes.string,
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

PersonalDataItemDialog.defaultProps = {
  source: 'master',
  open: false,
  isEdit: false,
  isNewItem: false,
  onRequestClose: (e) => e,
  onSavePersonalDataItem: (e) => e,
  dataItemId: '',
  inputValue: {},
  isUsed: false,
  usage: {},
  isTagEdit: false,
  hideTags: false
};

export default PersonalDataItemDialog;
