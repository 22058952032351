import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  breachGrounds: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'BREACH_GROUNDS:RECORDS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('breachGrounds', defaultState.get('breachGrounds'));
    case 'BREACH_GROUNDS:RECORDS:LIST:FETCH:SUCCESS':
      return state.set('breachGrounds', Immutable.List(action.items));
    case 'BREACH_GROUNDS:RECORDS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
