import { connect } from 'react-redux';
import GroupSelector from './component';

const mapStateToProps = state => ({
  linkGroups: state.environment.linkGroup.get('items'),
  error: state.linkGroups.get('error'),
  searchResults: state.linkGroups.get('items'),
  position: state.environment.linkGroup.get('position')
});

const mapDispatchToProps = dispatch => ({
  initlinkGroups: () => {
    dispatch({ type: 'LINK_GROUP:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'LINK_GROUP:LIST:SEARCH_INIT', searchParams });
  },
  getNextData: (position) => {
    dispatch({ type: 'LINK_GROUP:LIST:NEXT_FETCH', position });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector);
