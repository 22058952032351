import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';
import IntlPolyfill from 'intl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { RadioGroup } from '@material-ui/core';
import FontAwesome from 'react-fontawesome';
import ActionHelp from '@material-ui/icons/Help';
import { injectIntl } from 'react-intl';

import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import CountrySelector, {
  MultipleCountrySelector
} from '@packages/components/country-selector';
import { commonTranslations } from '@packages/utils/commontranslations';
import { setLastActivityTime } from '@packages/utils/common-utils';
import EntitySelector from '@packages/features/organisation-entities/components/entity-selector';
import { renderLabelWithRisk } from '@packages/features/privacy-record-detail/renderers/renderers';
import EntityImport from '@packages/features/organisation-entities/components/entity-import';
import { localeValue } from '@packages/utils/date-utils';
import ItemList from '@packages/features/privacy-record-detail/components/item-list';

import ItemCollections from './itemCollection';

const rootStyle = {
  width: '90%'
};

const dateSubHeaderStyle = {
  ...styles.labelField,
  display: 'flex',
  height: 'auto',
  paddingRight: '0px',
  position: 'inherit'
};
const subheaderStyle = { ...styles.labelField, paddingRight: '0px' };

export const renderTextField = injectIntl(
  ({
    input,
    label,
    helpNotes,
    multiLine,
    rows,
    meta: { error, submitFailed },
    disabled,
    adjustLabelPosition,
    errorLabelStyle,
    hintTextCustomValue,
    labelStyle,
    style,
    textFieldStyle,
    hintStyle,
    helperText,
    disabledStyle,
    showTextField,
    updateData,
    risks,
    locale,
    ignoredRuleIds,
    intl: { formatMessage },
    classes,
    inputProps,
    isView,
    ...custom
  }) => {
    const hintTextLabel = custom.hintTextLabel
      ? formatMessage(custom.hintTextLabel.props, {
        value: hintTextCustomValue
      })
      : '';
    const showDefaultSubHeader =
      label && !adjustLabelPosition && showTextField && !isView;

    return (
      <div style={style || rootStyle}>
        {showDefaultSubHeader &&
          renderLabelWithRisk({
            headerLabel: label,
            helpNotes,
            risks,
            disabled,
            locale,
            ignoredRuleIds
          })}
        {adjustLabelPosition && (
          <div style={labelStyle || { width: '20%' }}>
            <ListSubheader style={{ ...subheaderStyle, paddingRight: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '96%' }}>{label}</div>
              </div>
            </ListSubheader>
          </div>
        )}
        <div
          style={
            adjustLabelPosition
              ? { ...styles.textBox, width: '85%' }
              : styles.textBox
          }
        >
          {(disabled && !showTextField) || isView ? (
            <div style={disabledStyle}>
              <div style={{ marginTop: 15, marginBottom: 10 }}>{label}</div>
              <div style={{ padding: 10 }}>{input.value || '—'}</div>
            </div>
          ) : (
            <div style={textFieldStyle || ''}>
              <TextField
                variant="outlined"
                placeholder={hintTextLabel}
                style={
                  multiLine === true
                    ? styles.multiLineTextField
                    : styles.textField
                }
                disabled={disabled}
                multiline={multiLine}
                helperText={helperText}
                rows={rows}
                {...input}
                InputProps={{
                  classes,
                  inputProps,
                  autoComplete: 'off'
                }}
                name="some-unique-name-for-the-entity"
                onChange={(event) => {
                  input.onChange(event.target.value);
                  setLastActivityTime();
                }}
                onBlur={(event) => {
                  input.onChange(event.target.value.trim());
                  input.onBlur(event.target.value.trim());
                  if (updateData) updateData(event.target.value);
                }}
                {...custom}
              />
            </div>
          )}
          {adjustLabelPosition && (
            <div style={styles.errorText}>
              {submitFailed && error && <span>{error}</span>}
            </div>
          )}
        </div>
        {!adjustLabelPosition && (
          <div style={{ ...errorLabelStyle, ...styles.errorText }}>
            {submitFailed && error && <span>{error}</span>}
          </div>
        )}
      </div>
    );
  }
);
renderTextField.propTypes = {
  hintTextLabel: PropTypes.node,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.node,
  helpNotes: PropTypes.node,
  multiLine: PropTypes.bool,
  adjustLabelPosition: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired,
  input: PropTypes.shape({}),
  textFieldStyle: PropTypes.shape({}),
  hintStyle: PropTypes.shape({}),
  showTextField: PropTypes.bool,
  isView: PropTypes.bool
};

renderTextField.defaultProps = {
  rows: 2,
  multiLine: false,
  risks: [],
  disabled: false,
  hintTextLabel: null,
  label: null,
  helpNotes: null,
  adjustLabelPosition: false,
  style: null,
  input: {},
  textFieldStyle: {},
  hintStyle: { top: '7px' },
  showTextField: true,
  isView: false
};

export const renderCheckBoxField = ({
  label,
  risks,
  helpNotes,
  hintTextLabel,
  selected,
  onCheck,
  disabled,
  locale,
  ignoredRuleIds,
  selectedJurisdictions,
  style
}) => (
  <div style={{ ...rootStyle, ...style }}>
    {renderLabelWithRisk({
      headerLabel: label,
      helpNotes,
      risks,
      disabled,
      locale,
      ignoredRuleIds,
      selectedJurisdictions
    })}
    <FormControlLabel
      control={
        <Checkbox
          style={{
            ...styles.checkbox,
            height: '1px',
            alignSelf: 'baseline',
            position: 'relative'
          }}
          checked={selected}
          onChange={onCheck}
          disabled={disabled}
          color="primary"
        />
      }
      label={hintTextLabel}
    />
  </div>
);

renderCheckBoxField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string
  }),
  hintTextLabel: PropTypes.node,
  label: PropTypes.node,
  helpNotes: PropTypes.node,
  onCheck: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.shape({}),
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string,
  ignoredRuleIds: PropTypes.shape({}),
  selectedJurisdictions: PropTypes.arrayOf(PropTypes.string)
};

renderCheckBoxField.defaultProps = {
  selected: false,
  disabled: false,
  input: {},
  hintTextLabel: null,
  label: null,
  helpNotes: null,
  onCheck: (e) => e,
  labelStyle: {},
  risks: [],
  locale: 'en',
  ignoredRuleIds: {},
  selectedJurisdictions: []
};

export const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    control={
      <Checkbox
        style={styles.checkbox}
        checked={input.value || false}
        onChange={input.onChange}
        color="primary"
      />
    }
    label={label}
  />
);

renderCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func
  }),
  label: PropTypes.node
};

renderCheckbox.defaultProps = {
  input: {
    value: false,
    onChange: (e) => e
  },
  label: null
};

const getLabelStyle = (adjustLabelPosition) =>
  adjustLabelPosition
    ? { width: '19%', display: '', marginTop: '0px' }
    : { ...dateSubHeaderStyle, width: '100%', display: 'flex' };

const getRootStyle = (adjustLabelPosition) =>
  adjustLabelPosition
    ? { width: '75%', display: 'flex', marginTop: '10px' }
    : { width: '90%' };

const getDateBoxStyle = (adjustLabelPosition) =>
  adjustLabelPosition
    ? { width: '80%' }
    : { width: '100%', paddingBottom: '0px' };

export const renderDateField = injectIntl(
  ({
    input,
    label,
    labelDisabledStyle,
    adjustLabelPosition,
    style,
    helpNotes,
    minDate,
    maxDate,
    onSelect,
    disabled,
    showError,
    helperText,
    initialFocusedDate,
    meta: { error, submitFailed },
    ...props
  }) => (
    <div style={{ ...getRootStyle(adjustLabelPosition), ...style }}>
      {!adjustLabelPosition && (
        <ListSubheader
          style={{
            ...dateSubHeaderStyle,
            ...labelDisabledStyle,
            marginBottom: adjustLabelPosition ? '0px' : '10px',
            ...(disabled ? { opacity: 0.4 } : {})
          }}
        >
          <div style={{ width: '90%' }}>{label}</div>
          <div style={{ width: '10%' }}>
            {helpNotes && (
              <ArrowTooltip title={helpNotes}>
                <IconButton style={styles.rightIcon}>
                  <ActionHelp color="primary" />
                </IconButton>
              </ArrowTooltip>
            )}
          </div>
        </ListSubheader>
      )}
      {adjustLabelPosition && (
        <div style={getLabelStyle(adjustLabelPosition)}>
          <ListSubheader style={{ ...subheaderStyle, paddingRight: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '96%' }}>{label}</div>
            </div>
          </ListSubheader>
        </div>
      )}
      <div
        style={Object.assign(
          {},
          styles.textBox,
          getDateBoxStyle(adjustLabelPosition),
          {
            display: 'inline-flex',
            marginTop: helpNotes === '' ? '4px' : '0px'
          }
        )}
      >
        <MuiPickersUtilsProvider
          locale={localeValue[props.intl.locale]}
          utils={DateFnsUtils}
        >
          <DatePicker
            autoOk={true}
            {...(minDate && { minDate: new Date(minDate) })}
            {...(maxDate && { maxDate: new Date(maxDate) })}
            {...(initialFocusedDate && { initialFocusedDate })}
            style={{ width: adjustLabelPosition ? '99%' : '95%' }}
            cancelLabel={commonTranslations.Cancel}
            allowKeyboardControl={true}
            disabled={disabled}
            variant="inline"
            error={showError}
            helperText={helperText}
            inputVariant="outlined"
            DateTimeFormat={
              Intl.DateTimeFormat.supportedLocalesOf(props.intl.locale).length >
              0
                ? Intl.DateTimeFormat
                : IntlPolyfill.DateTimeFormat
            }
            format="dd/MM/yyyy"
            value={input.value !== '' ? new Date(input.value) : null}
            inputValue={
              input.value !== ''
                ? moment.utc(input.value).format('DD/MM/YYYY')
                : null
            }
            onChange={(value) => {
              input.onChange(value);
              if (onSelect) onSelect(value);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            placeholder="dd/mm/yyyy"
          />
        </MuiPickersUtilsProvider>
        {input.value && !disabled && (
          <FontAwesome
            name="times-circle"
            style={{ ...styles.btn_date_reset, marginLeft: '-30px' }}
            onClick={() => {
              input.onChange(null);
              if (onSelect) onSelect(null);
            }}
          />
        )}
      </div>
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  )
);

renderDateField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  }).isRequired,
  adjustLabelPosition: PropTypes.bool,
  labelDisabledStyle: PropTypes.shape({}),
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hintTextLabel: PropTypes.node,
  label: PropTypes.node,
  helpNotes: PropTypes.node,
  onSelect: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired,
  locale: PropTypes.string.isRequired
};

renderDateField.defaultProps = {
  hintTextLabel: null,
  label: null,
  disabled: false,
  adjustLabelPosition: false,
  labelDisabledStyle: {},
  maxDate: null,
  minDate: null,
  helpNotes: null,
  onSelect: (e) => e
};

export const renderTimeField = ({
  input,
  label,
  onSelect,
  meta: { error, submitFailed },
  disabled
}) => (
  <div style={rootStyle}>
    <ListSubheader style={{ ...styles.labelField, position: 'inherit' }}>
      {label}
    </ListSubheader>
    <div
      style={Object.assign({}, styles.textBox, {
        display: 'inline-flex',
        width: '100%'
      })}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          autoOk={true}
          value={input.value ? input.value : null}
          variant="inline"
          inputVariant="outlined"
          style={{ width: '95%' }}
          okLabel={commonTranslations.Ok}
          cancelLabel={commonTranslations.Cancel}
          onChange={(value) => {
            input.onChange(value);
            if (onSelect) onSelect(value);
          }}
          placeholder="hh:mm"
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </div>
    <div style={styles.errorText}>
      {submitFailed && error && <span>{error}</span>}
    </div>
  </div>
);
renderTimeField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
  }),
  hintTextLabel: PropTypes.node,
  label: PropTypes.node,
  onSelect: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired,
  disabled: PropTypes.bool
};

renderTimeField.defaultProps = {
  hintTextLabel: null,
  label: null,
  input: {},
  onSelect: (e) => e,
  disabled: false
};

export const renderSelectField = ({
  input,
  label,
  helpNotes,
  onSelect,
  disabled,
  showTextbox,
  showTextField,
  showHeader,
  id,
  textBoxStyle,
  risks,
  classes,
  meta: { error, submitFailed },
  children,
  style,
  showHint,
  MenuProps,
  isView,
  locale,
  ignoredRuleIds,
  selectedJurisdictions
}) => (
  <div style={style || rootStyle}>
    {label &&
      showHeader &&
      !isView &&
      renderLabelWithRisk({
        headerLabel: label,
        helpNotes,
        risks,
        disabled,
        locale,
        ignoredRuleIds,
        selectedJurisdictions
      })}
    {(disabled && !showTextField) || isView ? (
      <div>
        <div style={{ marginTop: 15, marginBottom: 10 }}>{label}</div>
        <div style={{ padding: 10 }}>{input.value || '—'}</div>
      </div>
    ) : (
      <div style={textBoxStyle}>
        {showTextbox && (
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Select
              classes={classes}
              displayEmpty={showHint}
              style={Object.assign({}, styles.textField, styles.textBox, {
                paddingLeft: 0,
                color: showHint && input.value === '' && '#a8a8a8'
              })}
              {...input}
              {...(id && { id })}
              disabled={disabled}
              onChange={(event) => {
                input.onChange(event.target.value);
                if (onSelect) onSelect(event.target.value);
              }}
              MenuProps={MenuProps}
            >
              {children}
            </Select>
          </FormControl>
        )}
      </div>
    )}

    <div style={styles.errorText}>
      {submitFailed && error && <span>{error}</span>}
    </div>
  </div>
);

renderSelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  hintText: PropTypes.node,
  helpNotes: PropTypes.node,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  showHeader: PropTypes.bool,
  showTextField: PropTypes.bool,
  showTextbox: PropTypes.bool,
  style: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired,
  textBoxStyle: PropTypes.shape({}),
  isView: PropTypes.bool
};

renderSelectField.defaultProps = {
  label: null,
  hintText: null,
  helpNotes: null,
  disabled: false,
  onSelect: (e) => e,
  children: null,
  showHeader: true,
  showTextField: true,
  showTextbox: true,
  style: {},
  textBoxStyle: {},
  isView: false
};

export const renderCountryList = ({
  label,
  subLabel,
  hintTextLabel,
  textFieldStyle,
  meta: { error, submitFailed },
  style,
  isView,
  ...props
}) => (
  <div>
    <div style={{ ...rootStyle, ...style }}>
      {isView ? (
        <>
          <ListSubheader style={styles.labelField}>{label}</ListSubheader>
          {props.input.value ? (
            <ItemList
              key="country"
              type="country"
              content={[props.input.value]}
              fontStyle={styles.itemListStyle}
            />
          ) : (
            <div style={{ padding: 10 }}>—</div>
          )}
        </>
      ) : (
        <CountrySelector
          {...props}
          hintTextLabel={hintTextLabel}
          label={label}
          subLabel={subLabel}
          textFieldStyle={textFieldStyle}
        />
      )}

      <div style={{ ...styles.errorText, marginTop: '10px' }}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  </div>
);

renderCountryList.propTypes = {
  style: PropTypes.shape({}),
  label: PropTypes.node,
  subLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  textFieldStyle: PropTypes.objectOf(PropTypes.string),
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired,
  isView: PropTypes.bool
};

renderCountryList.defaultProps = {
  style: {},
  label: null,
  subLabel: null,
  hintTextLabel: null,
  textFieldStyle: {},
  isView: false
};

export const renderMultipleCountriesList = ({
  label,
  subLabel,
  disabled,
  helpNotes,
  hintTextLabel,
  risks,
  meta: { error, submitFailed },
  isView,
  style,
  locale,
  ignoredRuleIds,
  selectedJurisdictions,
  ...props
}) => {
  const renderContent = () => {
    if (isView) {
      return props.fields.getAll()?.length > 0 ? (
        <ItemList
          key="country"
          type="country"
          content={props.fields.getAll()}
          fontStyle={styles.itemListStyle}
        />
      ) : (
        <div style={{ padding: 10 }}>—</div>
      );
    }
    return (
      <MultipleCountrySelector
        {...props}
        disabled={disabled}
        hintTextLabel={hintTextLabel}
      />
    );
  };

  return (
    <div style={style || rootStyle}>
      {renderLabelWithRisk({
        headerLabel: label,
        helpNotes,
        risks,
        disabled,
        locale,
        ignoredRuleIds,
        selectedJurisdictions
      })}
      {subLabel && (
        <ListSubheader style={styles.subLabelField}>{subLabel}</ListSubheader>
      )}
      {renderContent()}
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  );
};

renderMultipleCountriesList.propTypes = {
  label: PropTypes.node,
  subLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired
};

renderMultipleCountriesList.defaultProps = {
  label: null,
  subLabel: null,
  hintTextLabel: null
};

export const renderRadioGroup = ({
  input,
  label,
  style,
  valueSelected,
  onSelect,
  restrictCheck,
  radioGrpStyle,
  meta: { error, submitFailed },
  ...rest
}) => (
  <div style={style || { height: '65px' }}>
    {label && (
      <ListSubheader style={{ ...styles.labelField, position: 'inherit' }}>
        {label}
      </ListSubheader>
    )}
    <RadioGroup
      style={
        radioGrpStyle || { display: 'flex', width: '90%', paddingTop: '5px' }
      }
      {...input}
      {...rest}
      value={restrictCheck ? valueSelected : valueSelected || input.value}
      onChange={(event, value) => {
        input.onChange(value);
        if (onSelect) onSelect(value);
      }}
    />
    <div style={styles.errorText}>
      {submitFailed && error && <span>{error}</span>}
    </div>
  </div>
);

renderRadioGroup.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func
  }).isRequired,
  restrictCheck: PropTypes.bool,
  style: PropTypes.shape({}),
  radioGrpStyle: PropTypes.shape({}),
  label: PropTypes.node,
  onSelect: PropTypes.func,
  valueSelected: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired
};

renderRadioGroup.defaultProps = {
  radioGrpStyle: null,
  restrictCheck: false,
  style: null,
  label: null,
  onSelect: (e) => e,
  valueSelected: false
};

export const renderItemsCollection = injectIntl(
  ({
    label,
    itemLabel,
    labelHelpNote,
    hintTextLabel,
    meta: { error, submitFailed },
    intl: { formatMessage },
    ...props
  }) => (
    <div style={{ width: '90%' }}>
      <ListSubheader style={styles.labelField}>{label}</ListSubheader>
      <ItemCollections
        {...props}
        label={itemLabel}
        hintTextLabel={hintTextLabel && formatMessage(hintTextLabel.props)}
      />
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
      {labelHelpNote && (
        <div style={{ fontSize: '13px', marginTop: '2px', color: '#555' }}>
          {labelHelpNote}
        </div>
      )}
    </div>
  )
);

renderItemsCollection.propTypes = {
  name: PropTypes.string,
  hintTextLabel: PropTypes.node,
  label: PropTypes.node,
  itemLabel: PropTypes.node,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.shape({})
  }).isRequired
};

renderItemsCollection.defaultProps = {
  hintTextLabel: null,
  label: null,
  itemLabel: null,
  name: ''
};

export const renderEntityList = injectIntl(
  ({
    label,
    subLabel,
    isEdit,
    showFilters,
    isVendor,
    isGlobal,
    multiValue,
    isInternal,
    selectedOrgEntityId,
    meta: { error, submitFailed },
    intl: { formatMessage },
    ...props
  }) => (
    <div>
      {props.disabled ? (
        <div style={{ marginTop: '25px' }}>
          {label}: {props.selectedKey}
        </div>
      ) : (
        <div style={{ width: '90%' }}>
          <ListSubheader style={{ ...styles.labelField, position: 'inherit' }}>
            {label}
          </ListSubheader>
          {subLabel && (
            <ListSubheader style={styles.subLabelField}>
              {subLabel}
            </ListSubheader>
          )}
          <EntitySelector
            {...props}
            isEdit={isEdit}
            multiValue={multiValue}
            selectFromListMenuItem={true}
            isInternal={isInternal}
            selectedOrgEntityId={selectedOrgEntityId}
            hintTextLabel={formatMessage(props.hintTextLabel.props)}
            showFilters={showFilters}
            isVendor={isVendor}
            isGlobal={isGlobal}
          />
          <div style={styles.errorText}>
            {submitFailed && error && <span>{error}</span>}
          </div>
        </div>
      )}
    </div>
  )
);

renderEntityList.propTypes = {
  isInternal: PropTypes.bool,
  isEdit: PropTypes.bool,
  label: PropTypes.node,
  subLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectedOrgEntityId: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired
};

renderEntityList.defaultProps = {
  isInternal: undefined,
  label: null,
  subLabel: null,
  hintTextLabel: null,
  selectedOrgEntityId: null,
  isEdit: false
};

export const renderEntityImport = injectIntl(
  ({
    label,
    subLabel,
    selectedOrgEntityId,
    meta: { error, submitFailed },
    intl: { formatMessage },
    ...props
  }) => (
    <div>
      {props.disabled ? (
        <div style={{ marginTop: '25px' }}>
          {label}: {props.selectedKey}
        </div>
      ) : (
        <div style={{ width: '90%' }}>
          <ListSubheader style={{ ...styles.labelField, position: 'inherit' }}>
            {label}
          </ListSubheader>
          {subLabel && (
            <ListSubheader style={styles.subLabelField}>
              {subLabel}
            </ListSubheader>
          )}
          <EntityImport
            {...props}
            selectFromListMenuItem={true}
            hintTextLabel={formatMessage(props.hintTextLabel.props)}
          />
          <div style={styles.errorText}>
            {submitFailed && error && <span>{error}</span>}
          </div>
        </div>
      )}
    </div>
  )
);

renderEntityImport.propTypes = {
  label: PropTypes.node,
  subLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectedOrgEntityId: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired
};

renderEntityImport.defaultProps = {
  label: null,
  subLabel: null,
  hintTextLabel: null,
  selectedOrgEntityId: null
};
