import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import Button from '@material-ui/core/Button';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  ResponsiveTableWrapper,
  DataCell,
  SortHeaderCell
} from '@packages/components/responsive-table';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';

const BulkDeleteDialog = (props) => {
  const { open, onRequestClose, onSubmit, items } = props;

  const requestClose = () => {
    onRequestClose();
  };

  const handleSave = () => {
    onSubmit();
  };

  const buttonActions = [
    <Button
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      style={{ marginRight: '17px' }}
      onClick={handleSave}
    >
      {commonTranslations.confirm}
    </Button>,
    <Button
      variant="text"
      id="cancel-button"
      key="cancel-removeGroup"
      onClick={requestClose}
    >
      {commonTranslations.Cancel}
    </Button>
  ];

  return (
    <div>
      {open && (
        <CommonDialog
          customStyle={{ overflow: 'hidden' }}
          show={open}
          onCancel={onRequestClose}
          title={commonTranslations.deletePrivacyRecord}
          buttonActions={buttonActions}
        >
          <div>
            {recordTranslations.recordsSelected}: {items.length}
            <div style={{ color: 'red', marginTop: '20px' }}>
              {recordTranslations.bulkDeletionWarning}
            </div>
            <ResponsiveTableWrapper
              data={items}
              rowHeight={40}
              headerHeight={45}
              rowsCount={items.length}
            >
              <Column
                id="privacyRecordNumber"
                columnKey="privacyRecordNumber"
                header={
                  <SortHeaderCell>{recordTranslations.recordId}</SortHeaderCell>
                }
                cell={<DataCell items={items} />}
                flexGrow={2}
                width={100}
                visible={true}
              />
              <Column
                id="name"
                columnKey="name"
                header={
                  <SortHeaderCell>{commonTranslations.name}</SortHeaderCell>
                }
                cell={<DataCell items={items} />}
                flexGrow={2}
                width={100}
                visible={true}
              />
            </ResponsiveTableWrapper>
          </div>
        </CommonDialog>
      )}
    </div>
  );
};

BulkDeleteDialog.propTypes = {
  onSubmit: PropTypes.func,
  onRequestClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool
};

BulkDeleteDialog.defaultProps = {
  onSubmit: (e) => e,
  onRequestClose: (e) => e,
  items: [],
  open: false
};

export default BulkDeleteDialog;
