import React from 'react';
import { FormattedMessage } from 'react-intl';

import { removeTranslations } from '@packages/utils/actionTranslations';

export default {
  messages: <FormattedMessage
    id="MessageCenter.message"
    description="Message"
    defaultMessage="Message"
  />,
  createNewMessage: <FormattedMessage
    id="MessageCenter.createNewMessage"
    description="Create new message"
    defaultMessage="Create new message"
  />,
  subject: <FormattedMessage
    id="MessageCenter.subject"
    description="Subject"
    defaultMessage="Subject"
  />,
  to: <FormattedMessage
    id="MessageCenter.to"
    description="To"
    defaultMessage="To"
  />,
  from: <FormattedMessage
    id="MessageCenter.from"
    description="From"
    defaultMessage="From"
  />,
  sendDate: <FormattedMessage
    id="MessageCenter.sendDate"
    description="Send date"
    defaultMessage="Send date"
  />,
  recievedDate: <FormattedMessage
    id="MessageCenter.recievedDate"
    description="Recieved date"
    defaultMessage="Recieved date"
  />,
  markAsRead: <FormattedMessage
    id="MessageCenter.markAsRead"
    description="Mark as read"
    defaultMessage="Mark as read"
  />,
  markAsUnRead: <FormattedMessage
    id="MessageCenter.markAsUnRead"
    description="Mark as unread"
    defaultMessage="Mark as unread"
  />,
  forward: <FormattedMessage
    id="MessageCenter.forward"
    description="Forward"
    defaultMessage="Forward"
  />,
  reply: <FormattedMessage
    id="MessageCenter.reply"
    description="Reply"
    defaultMessage="Reply"
  />,
  deleteMessages: removeTranslations('deleteMessages'),
  subjectHintText: <FormattedMessage
    id="MessageCenter.subjectHintText"
    description="Add subject to your message"
    defaultMessage="Add subject to your message"
  />,
  contentHint: <FormattedMessage
    id="MessageCenter.contentHint"
    description="Add message content"
    defaultMessage="Add message content"
  />,
  send: <FormattedMessage
    id="MessageCenter.send"
    description="Send"
    defaultMessage="Send"
  />,
  successMessageCreation: <FormattedMessage
    id="MessageCenter.sucessMessageCreation"
    description="Message has been successfully send to the recipients"
    defaultMessage="Message has been successfully send to the recipients"
  />,
  forwardMessage: <FormattedMessage
    id="Message.forwardMessage"
    description="Forward message"
    defaultMessage="Forward message"
  />,
  replyMessage: <FormattedMessage
    id="Message.replyMessage"
    description="Reply message"
    defaultMessage="Reply message"
  />,
  viewMessage: <FormattedMessage
    id="Message.viewMessage"
    description="View message"
    defaultMessage="View message"
  />,
  confirmCancelCreate: <FormattedMessage
    id="Message.confirmCancelCreate"
    description="Your message will be lost if you move away. Are you sure you want to proceed?"
    defaultMessage="Your message will be lost if you move away. Are you sure you want to proceed?"
  />,
  maxSubjectLength: <FormattedMessage
    id="Message.maxSubjectLength"
    description="Length of subject is restricted to maximum of 150 characters"
    defaultMessage="Length of subject is restricted to maximum of 150 characters"
  />,
  linkedEntities: <FormattedMessage
    id="Message.linkedRecords"
    description="Linked records"
    defaultMessage="Linked records"
  />,
  maxLinkedRecords: <FormattedMessage
    id="Message.maxLinkedRecords"
    description="Maximum of only 5 records allowed as attachments."
    defaultMessage="Maximum of only 5 records allowed as attachments."
  />,
  bulkDeleteConfirm: <FormattedMessage
    id="MessageCenter.bulkDeleteConfirm"
    description="Do you want to delete selected messages?"
    defaultMessage="Do you want to delete selected messages"
  />,
  BulkDeleteSuccess: <FormattedMessage
    id="MessageCenter.BulkDeleteSuccess"
    description="Selected Items has been successfully deleted"
    defaultMessage="Selected Items has been successfully deleted"
  />,
  bulkStatusUpdate: <FormattedMessage
    id="MessageCenter.bulkStatusUpdate"
    description="Status of selected Items has been successfully updated"
    defaultMessage="Status of selected Items has been successfully updated"
  />,
  deleteMessage: removeTranslations('deleteMessage'),
  deleteSuccess: <FormattedMessage
    id="MessageCenter.deleteSuccess"
    description="Message has been successfully deleted"
    defaultMessage="Message has been successfully deleted"
  />,
  deleteNotifications: removeTranslations('deleteNotifications'),
  deleteNotification: removeTranslations('deleteNotification'),
  bulkDeleteNotificationConfirm: <FormattedMessage
    id="Notification.bulkDeleteConfirm"
    description="Do you want to delete selected notifications?"
    defaultMessage="Do you want to delete selected notifications"
  />,
  deleteNotificationConfirm: <FormattedMessage
    id="Notification.confirmDelete"
    defaultMessage="Do you want to delete the notification?"
  />,
  claimedByMe:
  <FormattedMessage
    id="MessageCenter.claimedByMe"
    description="Claimed by me"
    defaultMessage="Claimed by me"
  />,
  claim:
  <FormattedMessage
    id="Task.claim"
    description="Claim task"
    defaultMessage="Claim task"
  />,
  unClaim:
  <FormattedMessage
    id="Task.unClaim"
    description="Unclaim task"
    defaultMessage="Unclaim task"
  />,
  delegate:
  <FormattedMessage
    id="Task.delegate"
    description="Delegate task"
    defaultMessage="Delegate task"
  />,
  bulkDeleteTasks:
  <FormattedMessage
    id="Task.bulkDelete"
    description="Do you want to delete selected tasks?"
    defaultMessage="Do you want to delete selected taks?"
  />,
  bulkTaskDeletion: removeTranslations('bulkTaskDeletion'),
  deleteTask: removeTranslations('taskDelete'),
  createdBy:
  <FormattedMessage
    id="Record.createdBy"
    description="Created by"
    defaultMessage="Created by"
  />,
  taskStatus:
  <FormattedMessage
    id="Record.taskStatus"
    description="Task status"
    defaultMessage="Task status"
  />,
  active:
  <FormattedMessage
    id="Record.active"
    description="Active"
    defaultMessage="Active"
  />,
  completed:
  <FormattedMessage
    id="Record.completed"
    description="Completed"
    defaultMessage="Completed"
  />,
  inbox:
  <FormattedMessage
    id="MessageCenter.inbox"
    description="Inbox"
    defaultMessage="Inbox"
  />,
  unRead:
  <FormattedMessage
    id="MessageCenter.unRead"
    description="Unread"
    defaultMessage="Unread"
  />,
  sent:
  <FormattedMessage
    id="MessageCenter.sent"
    description="Sent"
    defaultMessage="Sent"
  />,
  allTasks:
  <FormattedMessage
    id="MessageCenter.allTasks"
    description="All Tasks"
    defaultMessage="All Tasks"
  />,
  unClaimedTasks:
  <FormattedMessage
    id="MessageCenter.unClaimedTasks"
    description="Unclaimed tasks"
    defaultMessage="Unclaimed tasks"
  />,
  required:
  <FormattedMessage
    id="MessageCenter.required"
    defaultMessage="Both content and subject cannot be left empty"
  />,
  refresh:
  <FormattedMessage
    id="Common.refresh"
    defaultMessage="Refresh"
  />
};
