import red from '@material-ui/core/colors/red';

const textFieldHeight = '40px';
const fontColor = '#000000';

const styles = {
  bottomNavigation: {
    height: 100,
    backgroundColor: 'transparent',
    selectedColor: '#FFF',
    unselectedColor: '#FFF',
    selectedFontSize: 18,
    unselectedFontSize: 18
  },
  mainContentWrapper: {
    maxHeight: '100%',
    overflowY: 'auto'
  },
  toolbarTitle: {
    fontSize: 28,
    color: fontColor
  },
  toolbarTitleRisk: {
    fontSize: 28,
    color: 'red'
  },
  formMargin: {
    marginLeft: '5px',
    marginTop: '10px'
  },
  autoComplete: {
    height: '40px',
    backgroundColor: '#ffffff'
  },
  textBox: {
    backgroundColor: '#ffffff'
  },
  textField: {
    width: '100%',
    height: textFieldHeight
  },
  noteHintStyle: {
    top: 10,
    left: 10,
    right: 10
  },
  textIndent: {
    textIndent: '10px'
  },
  multiLineTextField: {
    width: '100%'
  },
  rightIconStyle: {
    position: 'absolute',
    top: '12px',
    right: '10px'
  },
  linkTxtStyle: {
    marginTop: '8px',
    marginLeft: '5px',
    fontSize: '18px'
  },
  rightIcon: {
    padding: '0px',
    height: '24px',
    width: '24px'
  },
  linkMenuIcon: {
    padding: '0px',
    height: '24px',
    width: '24px',
    marginTop: '5px',
    marginLeft: '-10px'
  },
  contentTitle: {
    display: 'block',
    maxWidth: '500px',
    lineHeight: '20px',
    fontSize: '15px',
    fontWeight: '700',
    whiteSpace: 'pre-wrap'
  },
  dialogContentStyle: {
    width: '600px',
    maxWidth: 'none'
  },
  customContentStyle: {
    width: '400px',
    maxWidth: 'none'
  },
  recordContentStyle: {
    width: '800px',
    maxWidth: 'none'
  },
  labelField: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.25',
    color: '#000000',
    display: 'block',
    marginTop: '15px',
    marginBottom: '10px',
    paddingLeft: '0px'
  },
  subHeaderStyle: {
    height: '100%',
    width: '90%',
    marginTop: 5
  },
  subLabelField: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.25',
    color: '#000000',
    display: 'block',
    marginTop: '8px',
    marginBottom: '10px',
    paddingLeft: '0px'
  },
  divider: {
    height: '0px',
    border: 'solid 1px #000000'
  },
  loadingIcon: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1400, // MUI Dialog's z-index is 1300. Loader should layered on top of dialogs.
    backgroundColor: '#0000001f'
  },
  formbutton: {
    height: '45px'
  },
  multiselectAddButton: {
    marginTop: '40px'
  },
  multiselectFormButton: {
    height: '45px',
    width: '70px'
  },
  multiselectCheckbox: {
    marginBottom: '16px'
  },
  addButton: {
    marginTop: '25px' /* ,
    color: '#00ad55' */
  },
  btn_md: {
    height: '45px'
  },
  buttonLabel: {
    fontSize: '20px',
    textAlign: 'center',
    textTransform: 'none',
    lineHeight: '50px'
  },
  btn_label_md: {
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'none',
    lineHeight: '45px'
  },
  flatButtonLabel: {
    fontSize: '20px',
    textAlign: 'center',
    verticalAlign: 'inherit',
    textTransform: 'none',
    lineHeight: '50px'
  },
  goBackButtonLabel: {
    position: 'absolute',
    left: 68,
    top: 103,
    bottom: 0,
    margin: 25
  },
  uploadButton: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    height: '45px',
    width: '150px',
    textAlign: 'center',
    textTransform: 'none'
  },
  header: {
    height: '42px',
    fontSize: '28px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.17',
    color: '#000000'
  },
  subHeader: {
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    paddingLeft: '0px',
    color: '#000000',
    lineHeight: '1.17'
  },
  section: {
    marginTop: '15px',
    overflow: 'hidden'
  },
  collapseIcon: {
    paddingRight: '8px',
    fontSize: '22px'
  },
  cardText: {
    padding: '0px',
    overflow: 'auto'
  },
  cardHeader: {
    paddingLeft: '0px',
    paddingBottom: '5px'
  },
  errorText: {
    color: red[500],
    fontSize: '14px'
  },
  txtlink: {
    color: 'green',
    textDecoration: 'none',
    fontSize: '16px'
  },
  headerMargin: {
    marginLeft: '5px',
    marginTop: '15px'
  },
  errorMessage: {
    color: red[500],
    fontSize: '14px'
  },
  dialogCloseBtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '25px',
    right: '25px',
    color: 'black'
  },
  filterType: {
    lineHeight: 'unset',
    whiteSpace: 'unset',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    color: '#444',
    width: '200px',
    fontSize: 15
  },
  filterTypeActive: {
    backgroundColor: '#000',
    color: '#EEE'
  },
  filterName: {
    textAlign: 'right'
  },
  currentFilterMarker: {
    width: '0px',
    height: '0px',
    borderLeft: '20px solid black',
    borderTop: '24px solid transparent',
    borderBottom: '24px solid transparent'
  },
  actionsContainerStyle: {
    textAlign: 'left',
    marginLeft: 15,
    paddingBottom: 15
  },
  selectIconStyle: {
    height: '42px',
    width: '42px',
    top: '0px',
    fill: 'black'
  },
  actionChooserStyle: {
    width: '24px',
    height: '30px',
    padding: '0px'
  },
  dialogBodyStyle: {
    padding: '25px',
    paddingTop: '10px',
    paddingBottom: '30px'
  },
  paperStyle: {
    marginBottom: '20px',
    textAlign: 'center',
    display: 'inline-block',
    padding: '10px',
    backgroundColor: 'rgba(247, 231, 27, 0.25)'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  deleted: {
    color: 'red',
    textDecoration: 'line-through',
    marginRight: 10
  },
  added: {
    color: 'green'
  },
  noteTextStyle: {
    width: '95%'
  },
  noteHeaderStyle: {
    width: '525px'
  },
  btn_date_reset: {
    lineHeight: '1em',
    fontSize: '20px',
    marginTop: '10px',
    marginLeft: '-15px',
    color: '#bababa',
    cursor: 'pointer',
    zIndex: 1
  },
  expandCollapseLineStyle: {
    borderBottom: '1px solid',
    marginBottom: '10px',
    width: '40%'
  },
  expandCollapseRootStyle: {
    display: 'inline-flex',
    width: '100%',
    cursor: 'pointer',
    marginTop: '20px'
  },
  whiteLabellingRGBStyle: {
    width: '30%',
    display: 'flex',
    marginRight: '20px'
  },
  whiteLabellingRGBStyleLast: {
    width: '32%',
    display: 'flex'
  },
  whiteLabellingRGBLabelStyle: {
    marginRight: '5px',
    lineHeight: textFieldHeight
  },
  whiteLabellingLabelStyle: {
    width: '30%',
    minWidth: '35%'
  },
  whiteLabellingPreview: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '100px',
    marginLeft: '23px',
    marginTop: '7%',
    width: '27%'
  },
  whiteLabellingPreviewLogo: {
    display: 'flex',
    flexDirection: 'row',
    height: '46%',
    marginLeft: '27px'
  },
  whiteLabellingPreviewText: {
    lineHeight: '1.5',
    fontSize: '12px'
  },
  uploadLogoButton: {
    height: '43px',
    minWidth: '150px',
    float: 'right',
    wordWrap: 'break-word'
  },
  uploadLogoButtonLabel: {
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'none',
    height: '43px',
    paddingLeft: '0px',
    paddingRight: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popover: {
    left: '-31415px' // This positions the popover way offscreen, which is then overridden (fixes flashing)
  },
  initialPageStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    marginTop: '7%',
    marginBottom: '10%'
  },
  pageSearchField: {
    paddingLeft: '5px',
    height: '30px',
    backgroundColor: '#ffffff',
    width: '85%',
    border: '1px solid #9b9b9b',
    borderRadius: '3px'
  },
  avatarStyle: {
    marginLeft: '10px',
    borderRadius: 0,
    backgroundColor: 'transparent'
  },
  hintStyle: {
    top: '7px',
    display: 'flex',
    alignItems: 'center'
  },
  linkButtonStyle: {
    textDecoration: 'underline',
    fontSize: '15px',
    color: 'black',
    marginLeft: '-10px'
  },
  meterStyle: {
    height: '7px',
    borderRadius: '20px'
  },
  warningLevelStyle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  dialogHeaderStyle: {
    paddingLeft: '25px',
    justifyContent: 'flex-start',
    display: 'flex'
  },
  dialogButtonStyle: {
    paddingLeft: '15px',
    justifyContent: 'flex-start',
    paddingBottom: '10px'
  },
  viewNoteStyle: {
    wordWrap: 'break-word',
    maxHeight: 400,
    verflowY: 'auto',
    color: '#0009',
    fontFamily: 'ProximaNova, sans-serif'
  },
  contentStyle: {
    paddingTop: '10px',
    color: '#0009'
  },
  contentTitleStyle: {
    fontSize: '20px',
    fontWeight: 1000,
    color: '#0009'
  },
  loadingTextStyle: {
    fontSize: '18px',
    width: 410,
    top: '55%',
    position: 'absolute',
    zIndex: 1400
  },
  tableNoContentStyle: {
    position: 'absolute',
    top: 300,
    left: 400,
    width: 300
  },
  richTextEditor: {
    paddingLeft: '10px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #9b9b9b',
    borderRadius: '5px'
  },
  verticalCheckBox: {
    width: '45%',
    marginTop: '5px'
  },
  checkbox: {
    display: 'flex',
    fontSize: '15px'
  },
  riskButtonStyle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  layoutStyle: {
    position: 'absolute',
    left: 310,
    right: 25,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  headerLabel: {
    overflow: ' hidden',
    textOverflow: 'ellipsis',
    maxWidth: '350px',
    whiteSpace: 'nowrap'
  },
  sectionTableStyle: {
    display: 'table',
    width: '100%'
  },
  recordRowStyle: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  cardRootStyle: { marginTop: '0px' },
  itemListStyle: { fontSize: '15px' },
  checkboxStyle: {
    height: 1
  },
  textWithEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  tableNoDataStyle: {
    position: 'absolute',
    top: '29%',
    left: '45%',
    width: 300
  },
  mainTitle: {
    fontSize: 28,
    color: fontColor,
    minHeight: 64,
    display: 'flex',
    alignItems: 'center'
  }
};
export default styles;
