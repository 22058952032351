import { connect } from 'react-redux';
import ControllerSelector from './component';

const mapStateToProps = (state) => ({
  stakeholders: state.environment.stakeholders.get('stakeholderList'),
  position: state.environment.stakeholders.get('position')
});

const mapDispatchToProps = (dispatch) => ({
  initCustomStakeholders: (stakeholderEntityId) => {
    dispatch({ type: 'STAKEHOLDERS:LIST:REQUEST_INIT', stakeholderEntityId });
  },
  getNextData: (position, stakeholderEntityId) => {
    dispatch({
      type: 'STAKEHOLDERS:LIST:NEXT_FETCH',
      position,
      stakeholderEntityId
    });
  },
  onSearch: (searchParams, stakeholderEntityId) => {
    dispatch({
      type: 'STAKEHOLDERS:LIST:SEARCH_INIT',
      searchParams,
      stakeholderEntityId
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ControllerSelector);
