/* eslint-disable import/prefer-default-export */
import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

import styles from '@packages/ui/styles';
import { FormattedMessage } from 'react-intl';
import errortranslations, {
  customTranslations
} from '@packages/utils/errortranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';

import { recordTranslations } from '@packages/utils/commontranslations';
import getValueSelector from '@packages/features/reports/components/selectors/valueRenderers';

const rootStyle = {
  width: '98%'
};

export const renderValueSelector = ({
  label,
  meta: { submitFailed, error },
  filterType,
  onSelect,
  removeItem,
  ...props
}) => {
  const valueSelector = getValueSelector(
    null,
    null,
    null,
    props,
    filterType,
    onSelect,
    removeItem
  );
  return (
    <div style={rootStyle}>
      <ListSubheader style={styles.labelField}>{label}</ListSubheader>
      {valueSelector}
      {submitFailed && error && <span style={styles.errorText}>{error}</span>}
    </div>
  );
};
renderValueSelector.propTypes = {
  filterType: PropTypes.string,
  onSelect: PropTypes.func,
  label: PropTypes.node,
  removeItem: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    submitFailed: PropTypes.bool,
    error: PropTypes.shape({})
  }).isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
    removeAll: PropTypes.func,
    map: PropTypes.func
  })
};

renderValueSelector.defaultProps = {
  label: null,
  onSelect: (e) => e,
  removeItem: (e) => e,
  filterType: null,
  fields: {
    push: (e) => e,
    remove: (e) => e,
    removeAll: (e) => e,
    map: (e) => e
  }
};

export const handleAssessmentTypes = (recordType) => {
  switch (recordType) {
    case 'dpia_assessments':
      return 'assessment';
    case 'tia':
    case 'tia_assessments':
      return 'tia';
    case 'processing':
    case 'processing_processings':
      return 'processing';
    default:
      return '';
  }
};

// TODO: Check for correct file to place this items
export const validate = (values) => {
  const errorNameMessage = (
    <FormattedMessage
      id="AssessmentDetails.nameRequired"
      description="Providing a name for this privacy record is required"
      defaultMessage="Providing a name for this privacy record is required"
    />
  );

  const recordNameLimit = 250;

  const requiredFields = ['name', 'type'];

  const errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] =
        field === 'name' ? errorNameMessage : errortranslations.required;
    }
  });

  if (values.name?.length > recordNameLimit) {
    errors.name = customTranslations('maxLimit', recordNameLimit);
  }
  return errors;
};

export const filterByType = [
  {
    label: uppercasePlural('controllers'),
    value: 'controllers'
  },
  {
    label: uppercasePlural('executingEntities'),
    value: 'executingEntities'
  },
  {
    label: uppercasePlural('processors'),
    value: 'processors'
  },
  {
    label: uppercasePlural('dataRecipients'),
    value: 'dataRecipients'
  },
  {
    label: uppercasePlural('dataSubjectCategories'),
    value: 'dataSubjectCategories'
  },
  {
    label: recordTranslations.processingCategories,
    value: 'processingCategories'
  },
  {
    label: uppercasePlural('personalDataCategories'),
    value: 'personalDataCategories'
  },
  {
    label: uppercasePlural('personalDataItems'),
    value: 'personalDataItems'
  },
  {
    label: uppercasePlural('dataSources'),
    value: 'dataSources'
  }
  /* {
    label: uppercasePlural('retentionTermOffsets'),
    value: 'retentionTermOffsets'
 } */
];

export const advancedFilterByType = [
  {
    label: uppercasePlural('dataSubjectCategories'),
    value: 'dataSubjectCategories'
  },
  {
    label: uppercasePlural('personalDataCategories'),
    value: 'personalDataCategories'
  },
  {
    label: uppercasePlural('personalDataItems'),
    value: 'personalDataItems'
  },
  {
    label: uppercasePlural('dataSources'),
    value: 'dataSources'
  }
];
