import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import ProcessingCategory from './processingCategory';

const mapStateToProps = (state) => ({
  processingCategorydata: state.environment.processingCategories.get(
    'processingCategorydata'
  ),
  actionError: state.environment.processingCategories.get('error').toJS(),
  isJobActive: state.environmentReducer.get('isJobActive'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'PROCESSING_CATEGORIES:ITEM:FETCH', id });
  },
  updateProcessingCategories: (data, isEdit, source, isUsed, isEditWithJob) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        data,
        actionType: 'Edit',
        entityType: 'ProcessingCategory'
      });
    } else {
      dispatch({ type: 'PROCESSING_CATEGORIES:UPSERT', data, isEdit, source });
    }
  },
  resetProcessingCategories: () => {
    dispatch({ type: 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:FETCH:INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingCategory);
