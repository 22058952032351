import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import AutocompleteWithChips from '@packages/components/auto-complete-chips';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

class RecipientSelector extends React.Component {
  static filterAccessRights(selectedUsers, searchedItems) {
    const filteredData = searchedItems.filter((users) => {
      const index = selectedUsers.findIndex(selectedItem => users.id ===
        (selectedItem.value && typeof selectedItem.value === 'object' ? selectedItem.value.id : selectedItem.id));
      return (index === -1);
    });
    return filteredData;
  }

  constructor(props) {
    super(props);

    this.state = {
      multiple: false,
      selectedIndex: -1,
      userList: []
    };

    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleGetMultipleItems = this.handleGetMultipleItems.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
  }

  componentDidMount() {
    if (this.props.init && !this.props.disabled) {
      this.props.init();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.tenantusers !== nextProps.tenantusers ||
      this.props.searchResults !== nextProps.searchResults || this.props.searchText !== nextProps.searchText) {
      const userList = this.handleGetMultipleItems(nextProps.fields,
        nextProps.tenantusers, nextProps.searchResults, nextProps.searchText);
      this.setState({
        userList
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
    !(this.state === nextState) ||
    !(this.props.tenantusers === nextProps.tenantusers);
  }

  handleMultipleSelect() {
    this.setState({
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      multiple: false,
      selectedIndex: -1
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleGetMultipleItems(newFields, items, searchResults, searchText) {
    const selectedUsers = newFields.getAll();
    let list = searchText !== '' || searchResults.size > 0 ? searchResults.toJS() : items;
    if ((selectedUsers && selectedUsers.length > 0)) {
      list = searchText !== '' || searchResults.size > 0 ?
        RecipientSelector.filterAccessRights(selectedUsers, searchResults.toJS()) :
        RecipientSelector.filterAccessRights(selectedUsers, items);
    }
    return list;
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedUsers = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedUsers.splice(selectedIndex, 1);
    }

    this.props.updateRecipients(selectedUsers);
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const modifiedSelectedItems = selectedItems.map(item => ({
      ...item,
      rights: item.rights || ['Write', 'Read']
    }));
    const selectedUsers = [...items, ...modifiedSelectedItems];
    this.props.updateRecipients(selectedUsers);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem) {
    const { selectedIndex } = this.state;
    this.handleEditItem(selectedItem, selectedIndex);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex) {
    // const modifiedSelectedItem = { ...selectedItem.value, rights: ['Write', 'Read'] };
    const selectedUsers = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedUsers.push(selectedItem);
    } else {
      selectedUsers.splice(selectedIndex, 1, selectedItem);
    }
    this.props.updateRecipients(selectedUsers);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleSearch = (inputValue) => {
    this.props.userSearch(inputValue);
  }

  render() {
    const selectedUsers = this.props.fields.getAll();
    const { createNewMenuItem, selectFromListMenuItem, hintTextLabel, disabled } = this.props;

    return (
      <>
        <AutocompleteWithChips
          placeholder={hintTextLabel}
          initialValue={selectedUsers}
          dataSource={this.state.userList}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          createNewMenuItem={createNewMenuItem}
          selectFromListMenuItem={selectFromListMenuItem}
          fullWidth={true}
          disabled={disabled}
          searchText={this.props.searchText}
          handleSearch={this.handleSearch}
          handleMultipleSelect={this.handleMultipleSelect}
          handleSelectedItem={this.handleSelectedItem}
          handleRemoveItem={this.handleRemoveItem}
        />
        {this.state.multiple && <MultipleSelectorDialog
          id="multiple_selector"
          displayAsChip={true}
          title={this.props.dialogHeaderLabel}
          open={this.state.multiple}
          onScrollStop={this.handleScrollEnd}
          filteredData={this.state.userList}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />}
      </>
    );
  }
}

RecipientSelector.propTypes = {
  tenantusers: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  dialogHeaderLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectFromListMenuItem: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  init: PropTypes.func,
  disabled: PropTypes.bool,
  updateRecipients: PropTypes.func,
  searchText: PropTypes.string,
  searchResults: PropTypes.instanceOf(Immutable.List),
  userSearch: PropTypes.func
};

RecipientSelector.defaultProps = {
  tenantusers: Immutable.List(),
  position: 0,
  getNextData: e => e,
  updateRecipients: e => e,
  disabled: false,
  createNewMenuItem: false,
  dialogHeaderLabel: null,
  hintTextLabel: null,
  selectFromListMenuItem: false,
  init: e => e,
  searchText: '',
  searchResults: Immutable.List(),
  userSearch: e => e
};
export default RecipientSelector;
