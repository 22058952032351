import { connect } from 'react-redux';
import OrganisationUsersSelector from './component';

const mapStateToProps = state => ({
  createdByUsers: state.createdByUsers.get('items'),
  organisationUsers: state.organisationUser.list.get('organisationUsers'),
  position: state.organisationUser.list.get('position'),
  searchResults: state.tenantusers.get('items')
});

const mapDispatchToProps = dispatch => ({
  initUsers: () => {
    dispatch({ type: 'ORGANISATION_USERS:LIST:REQUEST', status: 'Registered' });
  },
  getNextData: (position) => {
    dispatch({ type: 'ORGANISATION_USERS:LIST:NEXT_FETCH', position, status: 'Registered' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'ACCESS_RIGHTS:LIST:SEARCH_INIT', searchParams });
  },
  getSelectedUsers: (idToFilter) => {
    dispatch({ type: 'REPORTS_CREATEDBY_USERS:LIST:REQUEST_FETCH', idToFilter });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationUsersSelector);
