import React, { Component } from 'react';
import PropTypes from 'prop-types';

import errorImage from '@packages/assets/icons/error.png';

import './styles.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static handleGoHome() {
    window.location.replace('/');
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="errorBoundaryContainer">
          <img
            src={errorImage}
            className="errorImage"
            alt="Something went wrong"
          />
          <div
            className="goHomeButton"
            onClick={ErrorBoundary.handleGoHome}
            role="presentation"
          >
            Go Home
          </div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
