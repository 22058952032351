import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* resetAndFetchUnassociatedTenants(action) {
  yield put({ type: 'TENANT:UNASSOCIATED:LIST:REQUEST:INIT' });
  yield fetchUnassociatedTenants(action);
}

export function* fetchUnassociatedTenants(action) {
  const { position = 0, rowCount = 100 } = action;
  const url = `/v1/tenants?filter=partner=null&numberOfResults=${rowCount}&offset=${position}&filter=isNonHoldingTenant=true`;
  try {
    const response = yield registry.get('request')
      .get(url, null);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          id: item.id,
          name: item.name,
          key: `${item.name}`
        }));
        yield put({ type: 'TENANT:UNASSOCIATED:LIST:REQUEST:SUCCESS', items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'TENANT:UNASSOCIATED:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* searchUnassociatedTenants(action) {
  let { searchParams } = action;

  const { searchKey, searchText } = searchParams;

  searchParams = Object.assign({}, searchParams, { searchText });

  yield put({ type: 'TENANT:UNASSOCIATED:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, action.recordType), null, {});
    const items = response.body;
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: item.name
        }));
        yield put({
          type: 'TENANT:UNASSOCIATED:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'TENANT:UNASSOCIATED:LIST:SEARCH:FAIL', error: err.message });
  }
}

const getURLWithParams = (searchParams) => {
  let url = `/v1/tenants?filter=partner=null&filter=isNonHoldingTenant=true&fields=name`;
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
