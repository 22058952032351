import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';
import Immutable from 'immutable';

const defaultState = Immutable.Map({
  isLoading: false,
  currentFilter: '',
  filters: []
});

const getModifiedSubMenu = (data, locale, tenantLocale) => ({
  ...data,
  key: `${data.id}_dashboards`,
  label: getTranslatedLabel(data?.names, locale, tenantLocale)
});

const filterMenuItems = (filters, userPermissions) =>
  filters.filter(
    (item) =>
      ({
        dashboards: userPermissions.viewDashboard,
        auditTrail: userPermissions.viewAuditTrail,
        jobs: userPermissions.viewJob
      }[item.key])
  );

export default (state = defaultState, action) => {
  const { data, locale, tenantLocale, isEdit, userPermissions } = action;

  switch (action.type) {
    case 'DASHBOARD:DEFAULT_FILTERS:INIT': {
      const filters = filterMenuItems(data, userPermissions);
      let currentFilter = state.get('currentFilter');
      if (currentFilter === '' && filters[0].key !== 'dashboards')
        currentFilter = filters[0].defaultFilter;
      return state.set('currentFilter', currentFilter).set('filters', filters);
    }

    case 'TENANT_DASHBOARD:LAYOUTS:FETCH_ALL:INIT':
      return state.set('isLoading', true);

    case 'TENANT_DASHBOARD:FILTER:INIT': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: action.data.map((subMenuItem) =>
              getModifiedSubMenu(subMenuItem, locale, tenantLocale)
            ),
            defaultFilter: `${data[0].id}_dashboards`
          };
        }
        return filter;
      });
      return state
        .set('filters', modifiedFilterData)
        .set(
          'currentFilter',
          state.get('currentFilter') || `${data[0].id}_dashboards`
        )
        .set('isLoading', false);
    }

    case 'TENANT_DASHBOARD:SAVE:SUCCESS': {
      const modifiedData = getModifiedSubMenu(data, locale, tenantLocale);
      if (isEdit) {
        const modifiedFilterData = state.get('filters').map((filter) => {
          if (filter.key === 'dashboards') {
            return {
              ...filter,
              subMenu: filter.subMenu.map((subMenuItem) => {
                if (subMenuItem.id === modifiedData.id) {
                  return modifiedData;
                }
                return subMenuItem;
              }),
              defaultFilter: `${data.id}_dashboards`
            };
          }
          return filter;
        });
        return state.set('filters', modifiedFilterData);
      } else {
        const modifiedFilterData = state.get('filters').map((filter) => {
          if (filter.key === 'dashboards') {
            return {
              ...filter,
              subMenu: [
                ...filter.subMenu,
                getModifiedSubMenu(data, locale, tenantLocale)
              ],
              defaultFilter: `${data.id}_dashboards`
            };
          }
          return filter;
        });
        return state
          .set('filters', modifiedFilterData)
          .set('currentFilter', `${data.id}_dashboards`);
      }
    }
    case 'TENANT_DASHBOARD:LAYOUT:SAVE:SUCCESS': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: filter.subMenu.map((subMenuItem) => {
              if (subMenuItem.id === data.id) {
                return getModifiedSubMenu(data, locale, tenantLocale);
              }
              return subMenuItem;
            }),
            defaultFilter: `${data.id}_dashboards`
          };
        }
        return filter;
      });
      return state
        .set('filters', modifiedFilterData)
        .set(
          'currentFilter',
          state.get('currentFilter') || `${data.id}_dashboards`
        );
    }
    case 'TENANT_DASHBOARD:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'TENANT_DASHBOARD:LAYOUT:SHARE:SUCCESS': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: filter.subMenu.map((subMenuItem) =>
              subMenuItem.id === data.id
                ? getModifiedSubMenu(data, locale, tenantLocale)
                : subMenuItem
            )
          };
        }
        return filter;
      });
      return state.set('filters', modifiedFilterData);
    }
    default:
      return state;
  }
};
