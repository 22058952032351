import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import { injectIntl } from 'react-intl';

import styles from '@packages/ui/styles';
import {
  commonTranslations,
  customTranslation,
  deleteTemplate,
  recordTranslations,
  typeTranslations,
  typeValueMapper
} from '@packages/utils/commontranslations';
import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  ActionCell
} from '@packages/components/responsive-table';
import { templateTranslations } from '@packages/features/privacy-record-detail/recordTranslations';
import {
  RECORDS_FETCH_LIMIT,
  getContainerHeight,
  getEmbedContainerHeight
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import CustomDialog from '@packages/components/custom-dialog';
import { singularTerms } from '@packages/utils/uppercaseTranslations';
import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';
import { tenantConfigurationTranslations } from '@packages/features/tenant-configuration/tenantConfigurationTranslations';

import { checkRecordPermissions } from '../../record-utils';

const actionItems = [
  {
    action: 'copyTemplate',
    primaryText: recordTranslations.copyTemplate
  }
];

const templateStatusActions = {
  Inactive: [
    {
      action: 'Active',
      primaryText: tenantConfigurationTranslations.markAsActive
    }
  ],
  Active: [
    {
      action: 'Inactive',
      primaryText: tenantConfigurationTranslations.markAsInctive
    }
  ]
};

const CustomTemplates = (props, context) => {
  const {
    onInit,
    currentFilter,
    filterMenuData,
    isFetching,
    sortKey,
    sortOrder,
    pageSearchText,
    onChooseFilter,
    filteredOn,
    filterColumn,
    locale,
    templates,
    position,
    getNextData,
    embed,
    toggleLoader,
    deleteRecordTemplate,
    tenantLocale,
    userPermissions,
    updateTemplateStatus,
    copyRecordTemplate,
    intl,
    restTemplatesFilters,
    isGlobal
  } = props;

  const {
    viewBreach,
    viewCustom,
    viewPreDpia,
    viewProcessing,
    viewTia,
    viewTemplate,
    createTemplate
  } = userPermissions.toJS();

  const { push } = context.router.history;

  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [recordType, setRecordType] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [recordTypeFilterValues, setRecordTypeFilterValues] = useState([]);

  useEffect(() => {
    if (filterMenuData?.recordLayoutId || currentFilter === 'all') {
      onInit(currentFilter, filterMenuData?.recordLayoutId, isGlobal);
    }
    return () => restTemplatesFilters(currentFilter);
  }, [filterMenuData?.recordLayoutId]);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, filterMenuData?.recordLayoutId, 1);
      setIsDelete(false);
    }
    toggleLoader(isFetching);
    setRecordTypeFilterValues(getRecordTypeFilterValues());
  }, [templates.size, isFetching]);

  const getRecordTypeFilterValues = () => {
    const recordTypes = [];
    if (viewProcessing) {
      recordTypes.push({
        label: typeTranslations.processing,
        value: 'processing'
      });
    }
    if (viewPreDpia) {
      recordTypes.push({
        label: typeTranslations.assessment,
        value: 'assessment'
      });
    }
    if (viewBreach) {
      recordTypes.push({ label: typeTranslations.breach, value: 'breach' });
    }
    if (viewTia) {
      recordTypes.push({
        label: typeTranslations.transferImpactAssessment,
        value: 'tia'
      });
    }
    if (viewCustom)
      recordTypes.push({ label: singularTerms('custom'), value: 'custom' });
    return recordTypes;
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter(
      { ...filterParams, filterKey },
      filterMenuData.recordLayoutId
    );
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleRowClick = (e, index) => {
    const { id, recordType, recordLayoutData, recordTypeData } =
      templates.get(index);
    let url = '';
    if (recordType === 'custom') {
      url = `/custom-template/${recordLayoutData.id}/${id}/view`;
    } else {
      url = `/${recordType}-template/${id}/view`;
    }

    const isViewable =
      viewTemplate &&
      checkRecordPermissions(userPermissions, recordTypeData?.uniqueId, 'view');
    if (isViewable) {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const location =
        urlParams.from === 'overview' ? 'overview' : 'privacyrecords';
      context.router.history.push(`${url}?from=${location}`);
    }
  };

  const handleCopyTemplate = (name, templateId, recordType) => {
    const copyOfName = {
      name: intl.formatMessage(customTranslation('copyOfName').props, {
        value: name
      })
    };
    copyRecordTemplate(
      copyOfName,
      templateId,
      recordType.toUpperCase(),
      locale,
      tenantLocale,
      intl.formatMessage
    );
  };

  const handleTemplateActions = (actionButton, index, action) => {
    const { id, recordType, recordLayoutData, name } = templates.get(index);
    if (action === 'copyTemplate') {
      handleCopyTemplate(name, id, recordType);
    } else {
      updateTemplateStatus(
        id,
        recordType,
        recordLayoutData.id,
        action,
        currentFilter,
        isGlobal
      );
    }
  };

  const handleEditTemplateClick = (index) => {
    const { id, recordType, recordLayoutData } = templates.get(index);
    if (recordType === 'custom') {
      push(`/custom-template/${recordLayoutData.id}/${id}/edit`);
    } else {
      push(`/${recordType}-template/${id}/edit`);
    }
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setTemplateId(-1);
  };

  const handleDeleteTemplate = () => {
    setIsDelete(true);
    deleteRecordTemplate(templateId, recordType);
    handleClose();
  };

  const handleDeleteTemplateClick = (index) => {
    const { id, recordType, name } = templates.get(index);
    setTemplateId(id);
    setRecordType(recordType);
    setTemplateName(name);
    setOpenDeleteDialog(true);
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      templates.size >= RECORDS_FETCH_LIMIT &&
      40 * (templates.size - 1) -
        (embed ? getEmbedContainerHeight() : getContainerHeight()) <=
        scrollY
    ) {
      getNextData(position, filterMenuData.recordLayoutId);
    }
  };

  const templateStatus = [
    { label: templateTranslations.active, value: 'Active' },
    { label: templateTranslations.inActive, value: 'Inactive' }
  ];

  const getModifiedRecordList = (data, locale, tenantLocale) =>
    data.map((item) =>
      item.recordType === 'custom'
        ? {
          ...item,
          recordType: getTranslatedLabel(
            item.recordTypeData?.names,
            locale,
            tenantLocale
          )
        }
        : item
    );

  const modifiedItems = getModifiedRecordList(templates, locale, tenantLocale);

  const recordLayoutMapper = (recordLayoutData) =>
    getTranslatedLabel(recordLayoutData?.names, locale, tenantLocale);

  const isPermissionHidden = (permissionType) =>
    !createTemplate ||
    !(
      currentIndex !== -1 &&
      checkRecordPermissions(
        userPermissions,
        templates.get(currentIndex)?.recordTypeData?.uniqueId,
        permissionType
      )
    );

  const isEditHidden = isPermissionHidden('edit');
  const isDeleteHidden = isPermissionHidden('delete');

  const handleActionChooserVisibility = (rowIndex) =>
    createTemplate &&
    checkRecordPermissions(
      userPermissions,
      templates.get(rowIndex)?.recordTypeData?.uniqueId,
      'edit'
    );

  // disable copying of templates under inactive layouts
  const handleMenuDisabled = (menu, rowIndex, action) =>
    action === 'copyTemplate' &&
    templates.get(rowIndex)?.recordLayoutData?.status === 'Inactive';

  return (
    <div>
      {!isFetching && templates.size === 0 && (
        <div style={styles.tableNoContentStyle}>
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="custom_responsive_table"
        actionChooserStyle={styles.actionChooserStyle}
        showActionSelector={true}
        handleActionChooserVisibility={handleActionChooserVisibility}
        rowHeight={40}
        headerHeight={45}
        rowsCount={templates.size}
        onRowClick={handleRowClick}
        actionItems={[
          ...templateStatusActions[
            templates?.get(currentIndex)?.templateInfo?.status || 'Active'
          ],
          ...actionItems
        ]}
        onActionHandler={handleTemplateActions}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        isDisabled={handleMenuDisabled}
        {...props}
      >
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('name')}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={templates} />}
          flexGrow={2}
          width={100}
        />
        <Column
          id="recordType"
          columnKey="recordType"
          visible={currentFilter === 'all'}
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              enableFilter={true}
              filterColumn={filterColumn && filterColumn.recordType}
              filterValues={recordTypeFilterValues}
              filteredOn={filteredOn}
              onChooseFilter={filterData}
            >
              {recordTranslations.type}
            </SortHeaderCell>
          }
          cell={<DataCell items={modifiedItems} mapper={typeValueMapper} />}
          width={170}
        />
        <Column
          columnKey="recordLayoutData"
          visible={currentFilter === 'all'}
          header={
            <SortHeaderCell enableSorting={false} enableFilter={false}>
              {recordTranslations.recordLayout}
            </SortHeaderCell>
          }
          cell={
            <DataCell
              items={templates}
              tooltipId="derivedFrom"
              mapper={recordLayoutMapper}
            />
          }
          flexGrow={0.4}
          width={100}
          height={35}
        />
        <Column
          id="recordTemplateStatus"
          columnKey="recordTemplateStatus"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              enableFilter={true}
              filterValues={templateStatus}
              filteredOn={filteredOn}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filterColumn={filterColumn && filterColumn.status}
              onChooseFilter={filterData}
            >
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={<DataCell items={templates} />}
          width={190}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          visible={createTemplate}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditTemplateClick}
              handleDelete={handleDeleteTemplateClick}
              isEditHidden={isEditHidden}
              isDeleteHidden={isDeleteHidden}
              items={templates}
              isGlobal={isGlobal}
            />
          }
          width={80}
        />
      </ResponsiveTableWrapper>
      <CustomDialog
        title={commonTranslations.deleteRecordTemplate}
        id="delete_template_dialog"
        show={openDeleteDialog}
        proceed={handleDeleteTemplate}
        cancel={handleClose}
        content={deleteTemplate(templateName)}
      />
    </div>
  );
};

CustomTemplates.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

CustomTemplates.propTypes = {
  onInit: PropTypes.func,
  currentFilter: PropTypes.string,
  filterMenuData: PropTypes.shape({ label: PropTypes.string }),
  isFetching: PropTypes.bool,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  onChooseFilter: PropTypes.func,
  filteredOn: PropTypes.objectOf(PropTypes.shape([])),
  filterColumn: PropTypes.shape({}),
  locale: PropTypes.string,
  templates: PropTypes.instanceOf(Immutable.List),
  position: PropTypes.number,
  getNextData: PropTypes.func,
  embed: PropTypes.bool,
  toggleLoader: PropTypes.func,
  deleteRecordTemplate: PropTypes.func,
  tenantLocale: PropTypes.string,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  updateTemplateStatus: PropTypes.func,
  copyRecordTemplate: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  restTemplatesFilters: PropTypes.func,
  isGlobal: PropTypes.bool
};

CustomTemplates.defaultProps = {
  onInit: (e) => e,
  currentFilter: '',
  filterMenuData: {},
  isFetching: false,
  sortKey: '',
  sortOrder: 'ASC',
  pageSearchText: Immutable.Map(),
  onChooseFilter: (e) => e,
  filteredOn: {},
  filterColumn: {},
  locale: PropTypes.string,
  templates: Immutable.List(),
  position: 0,
  getNextData: (e) => e,
  embed: false,
  toggleLoader: (e) => e,
  deleteRecordTemplate: (e) => e,
  tenantLocale: '',
  userPermissions: Immutable.Map(),
  updateTemplateStatus: (e) => e,
  copyRecordTemplate: (e) => e,
  intl: {
    formatMessage: (e) => e
  },
  restTemplatesFilters: (e) => e,
  isGlobal: false
};

CustomTemplates.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(CustomTemplates);
