import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { push } from 'react-router-redux';

import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  handleServiceDown,
  getModifiedRecordType
} from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { customTranslation } from '@packages/utils/commontranslations';

import {
  transformDefaultResponseData,
  transformDefaultRequestData
} from '../saga-utils';
import { getUserOrg, transformCopyRecordData } from '../common-utils';

function* getDefaultRecordData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    status: 'record_status_draft'
  };
}

export function* getRecordData(recordId, url, isTemplateMode) {
  const response = yield registry
    .get('request')
    .get(`${url}/${recordId}`, null);
  if (response.status === 200) {
    const data = response.body;
    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const modifiedData =
      recordId && !isTemplateMode
        ? transformCopyRecordData(data, currentUser, aclOrgs)
        : data;

    // Deleting record specific params
    delete modifiedData.id;
    delete modifiedData.lastChangedBy;
    delete modifiedData.createdBy;

    return modifiedData;
  }
  return {};
}

const getCustomRecordCopyUrl = (recordType, isTemplateMode) => {
  if (['assessments', 'processings', 'breaches'].includes(recordType)) {
    return `v1/records${isTemplateMode ? '/templates' : ''}/${recordType}`;
  } else {
    return `v1/custom/records${isTemplateMode ? '/templates' : ''}`;
  }
};

const getCustomRecordDetailFetchUrl = (
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId
) => {
  let url = '/v1/custom/records';
  if (isTemplateMode) url += '/templates';
  url += `/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* customRecordDataWorker(action) {
  const {
    mode = 'CREATE',
    data,
    prevLocation,
    copyOfRecordId,
    isStatusChange,
    recordType,
    promoteInitiated,
    formatMessage,
    templateId,
    isTemplateMode,
    templateFromRecord,
    isGlobal,
    isPreviewMode,
    libraryTenantId
  } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  const req = registry.get('request');
  const { layoutId, recordId } = getParameterValuesFromHash(
    '/custom-record/:layoutId/:recordId/:editOrView'
  );

  yield put({ type: `CUSTOM:RECORD:${mode}:INIT` });
  yield put({ type: 'CUSTOM_RECORD:LOADER:TOGGLE', value: true });

  try {
    let copyData;
    let copyUrl;
    if (mode === 'COPY') {
      copyUrl = getCustomRecordCopyUrl(recordType, isTemplateMode);
      copyData = yield getRecordData(
        isTemplateMode ? templateId : copyOfRecordId,
        copyUrl,
        isTemplateMode
      );
      copyData = {
        ...copyData,
        name: formatMessage(customTranslation('copyOfName').props, {
          value: copyData.name
        }),
        status: 'record_status_draft',
        ...(isGlobal ? { companyAccess: true } : {})
      };
    }

    const modifiedReqData = templateId
      ? yield getRecordData(templateId, '/v1/custom/records/templates')
      : yield getDefaultRecordData();

    const createRecordData = templateId
      ? { ...modifiedReqData, ...data }
      : transformDefaultRequestData({
        ...data,
        ...modifiedReqData
      }, isGlobal);

    const response = yield {
      CREATE: () =>
        req.post(
          `/v1/custom/records${isTemplateMode ? '/templates' : ''}`,
          createRecordData
        ),
      FETCH_ITEM: () =>
        req.get(
          getCustomRecordDetailFetchUrl(
            recordId,
            isTemplateMode,
            isPreviewMode,
            libraryTenantId
          )
        ),
      FETCH_LAYOUT: () => req.get(`/v1/custom-record-layout/${layoutId}`),
      UPDATE: () =>
        req.put(
          `/v1/custom/records${isTemplateMode ? '/templates' : ''}/${recordId}`,
          transformDefaultRequestData(data)
        ),
      DELETE: () => req.delete(`/v1/custom-record-type/${recordId}`),
      FETCH_LIST: () => req.get('/v1/custom-record-type?withUsage=true'),
      COPY: () => req.post(copyUrl, copyData)
    }[mode]();

    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    
    switch (response.status) {
      case 200: {
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;

        yield put({
          type: `CUSTOM_RECORD:${mode}:SUCCESS`,
          data: transformDefaultResponseData(response.body, currentUser)
        });
        if (isStatusChange) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.recordStatusUpdateSuccess,
              type: 'success'
            }
          });
        }
        if (mode === 'CREATE' || mode === 'COPY') {
          const routeUrl = ['assessments', 'processings', 'breaches'].includes(
            recordType
          )
            ? `${getModifiedRecordType(recordType)}${
              isTemplateMode ? '-template' : ''
            }`
            : `custom-${isTemplateMode ? 'template' : 'record'}/${
              response.body.recordLayoutData.id
            }`;
          if (templateFromRecord) {
            yield put({
              type: 'NOTIFIER:NOTIFY',
              notification: {
                content: notificationtranslations.templateCreated,
                type: 'success'
              }
            });
            yield put(replaceRouter('/privacyrecords'));
          } else if (isTemplateMode) {
            yield put(push(`/${routeUrl}/${response.body.id}/edit`));
          } else {
            yield put(push(`/${routeUrl}/${response.body.id}/edit`));
          }
        }

        if (promoteInitiated) {
          yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });
        }

        if (prevLocation) {
          yield put(replaceRouter(prevLocation));
        }
        break;
      }
      default: {
        yield put({
          type: `CUSTOM_RECORD:${mode}:FAIL`,
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
    yield put({ type: 'CUSTOM_RECORD:LOADER:TOGGLE', value: false });
  } catch (error) {
    yield put({ type: 'CUSTOM_RECORD:LOADER:TOGGLE', value: false });
    yield handleServiceDown(error, 'customservice');
  }
}

export function* checkboxOptionsFetch(action) {
  const { listId, layoutUniqueId } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/masterdata/options?itemList=${listId}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM_RECORD:CHECKBOX_OPTIONS:FETCH:SUCCESS',
          layoutUniqueId,
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: `CUSTOM_RECORD:CHECKBOX_OPTIONS:FETCH:FAIL`,
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'customservice');
  }
}

const commentNotification = {
  CREATE: notificationtranslations.commentedSuccess,
  DELETE: notificationtranslations.commentDeletedSuccess,
  EDIT: notificationtranslations.commentedSuccess
};

export function* updateCustomComment(action) {
  const { comment, id, mode } = action;
  try {
    const customState = yield select((state) => state.customRecord);
    const customComments = customState.get('comments');
    const request = registry.get('request');

    const response = yield {
      CREATE: () => request.post(`/v1/records/customs/${id}/comments`, comment),
      EDIT: () =>
        request.put(
          `/v1/records/customs/${id}/comments/${comment.id}`,
          comment
        ),
      DELETE: () =>
        request.delete(`/v1/records/customs/${id}/comments/${comment.id}`)
    }[mode]();
    switch (response.status) {
      case 200:
      case 204: {
        const modifedComments = [...customComments];

        const modifiedData = {
          CREATE: [...modifedComments, response.body],
          EDIT: modifedComments.map((item) =>
            item.id === comment.id ? response.body : item
          ),
          DELETE: modifedComments.filter((item) => item.id !== comment.id)
        }[mode];

        yield put({
          type: `CUSTOM:COMMENT:${mode}:SUCCESS`,
          data: modifiedData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: commentNotification[mode],
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchUsersForCustomRecord() {
  const { recordId } = getParameterValuesFromHash(
    '/custom-record/:layoutId/:recordId/edit?:is'
  );
  yield put({ type: 'CUSTOM:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/customs/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
