import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  isFetching: false,
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'REPORTS_CREATEDBY_USERS:LIST:REQUEST_INIT': {
      return state.set('items', defaultState.get('items'))
        .set('isFetching', true)
        .set('position', defaultState.get('position'));
    }
    case 'REPORTS_CREATEDBY_USERS:LIST:REQUEST:SUCCESS': {
      return state.set('items', Immutable.List(action.items))
        .set('isFetching', false)
        .set('position', action.items.length);
    }
    case 'REPORTS_CREATEDBY_USERS:LIST:REQUEST:FAIL':
      return state.set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
