import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import LegalGrounds from '../../legal-grounds';

const ProcessingGroundList = (props) => {
  const {
    isFetching,
    onCancel,
    selectedItems,
    processingGrounds,
    sortKey,
    sortOrder,
    searchText,
    filteredOn,
    searchItems,
    filterColumn,
    onChooseFilter,
    onSearch,
    onSave,
    initProcessingGrounds,
    selectedPersonalDataItems,
    selectedControllers,
    jurisdiction,
    filterType,
    getFilterAppplied
  } = props;

  useEffect(() => {
    if (initProcessingGrounds) {
      initProcessingGrounds(
        selectedControllers,
        selectedPersonalDataItems,
        jurisdiction,
        filterType
      );
    }
  }, []);

  useEffect(() => {
    // The legalGrounds will be filtered by current jurisdiction always. So we don't want to
    // consider that case to show remove filter button.
    if (props.filteredOn !== undefined) {
      const { jurisdiction: jurisdictionFilter, ...filteredOnSet } = {
        ...filteredOn
      };
      const items = Object.values(filteredOnSet);
      const isFilterApplied =
        items.some((item) => item.length > 0) ||
        jurisdictionFilter.join('_') !== jurisdiction;
      getFilterAppplied(isFilterApplied);
    }
  }, [props.filteredOn]);

  return (
    <LegalGrounds
      id="legal-grounds"
      selectedItems={selectedItems}
      legalGrounds={processingGrounds}
      isFetching={isFetching}
      onCancel={onCancel}
      sortKey={sortKey}
      sortOrder={sortOrder}
      searchText={searchText}
      filteredOn={filteredOn}
      searchItems={searchItems}
      filterColumn={filterColumn}
      filterData={onChooseFilter}
      searchData={onSearch}
      onSave={onSave}
    />
  );
};

ProcessingGroundList.propTypes = {
  isFetching: PropTypes.bool,
  initProcessingGrounds: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string,
  searchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.array),
  filterColumn: PropTypes.shape({}),
  searchItems: PropTypes.objectOf(PropTypes.array),
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedControllers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPersonalDataItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  processingGrounds: PropTypes.instanceOf(Immutable.List),
  getFilterAppplied: PropTypes.func,
  jurisdiction: PropTypes.string,
  filterType: PropTypes.arrayOf(PropTypes.string)
};

ProcessingGroundList.defaultProps = {
  isFetching: false,
  initProcessingGrounds: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  onChooseFilter: (e) => e,
  onSearch: (e) => e,
  sortOrder: 'ASC',
  searchText: Immutable.Map(),
  filteredOn: Immutable.Map({
    jurisdiction: ['EU']
  }),
  filterColumn: Immutable.Map({ jurisdiction: 'jurisdiction' }),
  searchItems: Immutable.Map(),
  processingGrounds: Immutable.List(),
  getFilterAppplied: (e) => e,
  jurisdiction: 'EU',
  filterType: []
};

ProcessingGroundList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default ProcessingGroundList;
