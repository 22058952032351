/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Immutable from 'immutable';
import Linkify from 'react-linkify';
import { withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage, injectIntl } from 'react-intl';
import DivWrapper from '../divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import { sortItems } from '@packages/utils/common-utils';

const getStyles = (props, context, state) => {
  const { anchorEl, width } = state;
  const { fullWidth, isParentWidth } = props;

  const styles = {
    root: {
      display: 'inline-block',
      position: 'relative',
      width: fullWidth ? '100%' : 256
    },
    menu: {
      width: '100%',
      overflowY: 'auto',
      maxHeight: 300
    },
    list: {
      width: fullWidth ? '100%' : 256
    },
    innerDiv: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: width-32 // 32 must be the padding of TextField by giving which the MenuItem width is adjusted.
    },
    themeColor: props.theme.palette.primary.main,
    menuItemStyle: {
      minWidth: width-32
    },
    addtnlStyle: {
      width: '50%'
    }
  };

  if (anchorEl && fullWidth) {
    styles.popover = {
      width: isParentWidth ? anchorEl.parentElement.parentElement.clientWidth : anchorEl.clientWidth,
      left: '-31415px' // This positions the popover way offscreen, which is then overridden (fixes flashing)
    };
  }

  return styles;
};
let searchDelayTimeout = null;
class AutoComplete extends Component {
  static get defaultProps() {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      animated: true,
      dataSourceConfig: {
        text: 'text',
        value: 'value'
      },
      additionalInfoConfig: {
        text: 'text',
        value: 'value'
      },
      disableFocusRipple: true,
      filter: (searchText, key) => searchText !== '' && key.indexOf(searchText) !== -1,
      fullWidth: false,
      open: false,
      openOnFocus: false,
      onUpdateInput: () => {},
      onNewRequest: () => {},
      searchText: '',
      menuCloseDelay: 300,
      targetOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      inputType: 'text'
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      width: 0,
      focusTextField: true,
      open: props.open,
      searchText: props.searchText
    };

    this.requestsList = [];
    this.timerTouchTapCloseId = null;
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleItemTouchTap = this.handleItemTouchTap.bind(this);
    this.chosenRequestText = this.chosenRequestText.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetSearchText || this.props.searchText !== nextProps.searchText ) {
      this.setState({
        searchText: nextProps.searchText
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerTouchTapCloseId);
  }

  close() {
    this.setState({
      open: false,
      anchorEl: null,
      width: 0

    });
  }

  handleRequestClose() {
    // Only take into account the Popover clickAway when we are
    // not focusing the TextField.
      this.close();
  }

  handleMouseDown(event) {
    // Keep the TextField focused
    event.preventDefault();
  }

  handleItemTouchTap(child) {
    const { dataSource } = this.props;
    // Index -2 and -3 are additional menuitem used to create new item/select from a list.
    let chosenRequest = dataSource.get && (child !== -3 && child !== -2)? dataSource.get(child) : dataSource[child];
    if (child === -2) {
      chosenRequest = this.state.searchText;
    } else if (child === -3) {
      /**
       * This was emptying out the input value while selecting the More... option.
       * Hence commented out.
       */
      // chosenRequest = '';
    }
    const searchText = this.chosenRequestText(chosenRequest);

    this.timerTouchTapCloseId = setTimeout(() => {
      this.timerTouchTapCloseId = null;
      this.setState({
        searchText
      });
      this.close();
      this.props.onNewRequest(chosenRequest, child);
    }, this.props.menuCloseDelay);
  }

  chosenRequestText(chosenRequest) {
    if (chosenRequest) {
      const chosenReq = (typeof chosenRequest === 'string') ? chosenRequest :
      chosenRequest[this.props.dataSourceConfig.text];

      // when the datasource requires runtime translation, the chosenReq would contain the formatMessage id.
      // in case of country translations, the formatMessage id is available in .country
      return (typeof chosenReq === 'object' ? this.props.intl.formatMessage(chosenReq.country || chosenReq) : chosenReq);
    }
    return this.state.searchText;
  }

  handleKeyDown(event) {
    if (this.props.onKeyDown) this.props.onKeyDown(event);

    switch (keycode(event)) {
      case 'enter': { 
        event.preventDefault();
        this.close();
        const { searchText } = this.state;
        if (searchText !== '' && this.props.createNewMenuItem) {
          this.props.onNewRequest(searchText, -1);
          this.setState({ searchText: '' });
        }
        break;
      }
      case 'esc': {
        this.close();
        break;
      }
      case 'down': {
        event.preventDefault();
        this.setState({
          open: true,
          focusTextField: false,
          anchorEl: event.currentTarget.parentElement,
          width: event.currentTarget.parentElement.offsetWidth
        });
        break;
      }
      default:
        break;
    }
  }

  handleChange(event) {
    const searchText = event.target.value;
    clearTimeout(searchDelayTimeout);
    if (this.props.onSearch) {
      searchDelayTimeout = setTimeout(() => {
        this.props.onSearch({ searchText });
      }, 500);
    }

    // Make sure that we have a new searchText.
    // Fix an issue with a Cordova Webview
    if (searchText === this.state.searchText) {
      return;
    }

    this.setState({
      searchText,
      open: true,
      anchorEl: event.currentTarget.parentElement,
      width: event.currentTarget.parentElement.offsetWidth
    }, () => {
      this.props.onUpdateInput(searchText, this.props.dataSource);
    });
  }

  handleBlur(event) {
    if (this.state.focusTextField && this.timerTouchTapCloseId === null) {
      this.close();
    }
    this.props.onUpdateInput(event.target.value.trim());
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  handleFocus(event) {
    this.setState({
      focusTextField: true
    });
  
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }

    if (!this.state.open && this.props.openOnFocus) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget.parentElement,
        width: event.currentTarget.parentElement.offsetWidth
      });
    }
  }

  getOrigin = (isTargetOrigin) => {
    const node = document.activeElement;
    let target = { vertical: 'top', horizontal: 'left' };
    let anchor = { vertical: 'bottom', horizontal: 'left' };
    const dataCount = this.props.dataSource.size || this.props.dataSource.length || 0;
    if (node) {
      const offsetHeight = node.getBoundingClientRect().top;
      const maxHeight = window.innerHeight;
      // elementHeight = number of dataSource * 40 + more(40) + createNew (40) + textfield (50)
      const elementHeight = ((dataCount >= 5 ? 5 : dataCount) * 40) + 130;
      if ((maxHeight - offsetHeight) < elementHeight) {
        target = { vertical: 'bottom', horizontal: 'left' };
        anchor = { horizontal: 'left', vertical: 'top' };
      }
    }
    return isTargetOrigin ? target : anchor;
  }

  createNewMenuItem(requestsList, styles, disableFocusRipple) {
    const { createNewMenuItem } = this.props;
    if (createNewMenuItem) {
      let itemText = '';
      if (requestsList.length === 1) {// requestsList would contain the 'More...' option which is added already
        itemText = this.props.intl.formatMessage({
          id: 'List.noMatchesFound',
          defaultMessage: 'No matches found - Create new...'
        });
      } else {
        itemText = this.props.intl.formatMessage({
          id: 'List.createNew',
          defaultMessage: 'Create new...'
        });
      }
      if (itemText !== '') {
        requestsList.push({
          text: itemText,
          value: (
            <MenuItem
              onClick={()=>this.handleItemTouchTap(-2)}
              style={{...styles.menuItemStyle, color: styles.themeColor}}
              id="create_new_menu_item"
              key="-2"
            >
              <div style={styles.innerDiv}>{itemText}</div>
            </MenuItem>)
        });
      }
    }
  }

  createOpenListMenuItem(requestsList, styles, disableFocusRipple) {
    if (this.props.selectFromListMenuItem) {
      let itemText = '';
      if (requestsList.length === 0) {
        itemText = this.props.intl.formatMessage({
          id: 'List.selectFromList',
          defaultMessage: 'Select from a list'
        });
      } else {
        itemText = this.props.intl.formatMessage({ id: 'List.moreResults', defaultMessage: 'More...' })
      }
      
      requestsList.push({
        text: itemText,
        value: (
          <MenuItem
            onClick={()=>this.handleItemTouchTap(-3)}
            style={{...styles.menuItemStyle, color: styles.themeColor}}
            id="multi_selector_menu_Item"
            key="-3"
          >
            <div style={styles.innerDiv}>{itemText}</div>
          </MenuItem>)
      });
    }
  } 

  render() {
    const {
      anchorOrigin,
      animated,
      animation,
      dataSource,
      dataSourceConfig, // eslint-disable-line no-unused-vars
      disableFocusRipple,
      errorStyle,
      floatingLabelText,
      filter,
      fullWidth,
      style,
      hintText,
      hintStyle,
      maxSearchResults,
      menuCloseDelay, // eslint-disable-line no-unused-vars
      textFieldStyle,
      menuStyle,
      menuProps,
      listStyle,
      targetOrigin,
      onNewRequest, // eslint-disable-line no-unused-vars
      onUpdateInput, // eslint-disable-line no-unused-vars
      openOnFocus, // eslint-disable-line no-unused-vars
      popoverProps,
      underlineShow,
      searchText: searchTextProp, // eslint-disable-line no-unused-vars
      id,
      disabled,
      sortDatasource = false,
      resetSearchText = false
    } = this.props;

    const {
      open,
      anchorEl,
      searchText,
      focusTextField
    } = this.state;

    const styles = getStyles(this.props, this.context, this.state);

    let requestsList = [];
    dataSource && dataSource.every((item, index) => {
      switch (typeof item) {
        case 'string':
          if (filter(searchText, item, item)) {
            requestsList.push({
              text: item,
              value: (
                <ArrowTooltip  key={`${item}_${index}`} title={item}>
                <MenuItem
                  onClick={()=>this.handleItemTouchTap(index)}
                  id={`${item}`}
                  value={item}
                  style={styles.menuItemStyle}
                  key={index}
                  >
                    {<div style={styles.innerDiv}>{item}</div>}
                  </MenuItem>
                </ArrowTooltip>
                )
            });
          }
          break;

        case 'object':
          if (item && typeof item[this.props.dataSourceConfig.text] === 'string') {
            const itemText = item[this.props.dataSourceConfig.text];
            const additionalInfo = item[this.props.additionalInfoConfig.text];
            const icon = item.icon;
            const addtlValue = item.addlkey || '';
            if (!this.props.filter(searchText, itemText, item)) break;
            const itemValue = item[this.props.dataSourceConfig.value];
            if (itemValue.type && (itemValue.type.muiName === MenuItem.muiName ||
               itemValue.type.muiName === Divider.muiName)) {
              requestsList.push({
                text: itemText,
                value: React.cloneElement(itemValue, {
                  key: index,
                  disableFocusRipple
                })
              });
            } else {
              requestsList.push({
                text: itemText,
                value: (
                  <ArrowTooltip key={`${itemText}_${index}`} title={itemText}>
                  <MenuItem
                    onClick={()=>this.handleItemTouchTap(index)}
                    id={itemValue.replace(/ .*/,'')}
                    style={styles.menuItemStyle}
                    key={index}
                  >
                    {<Linkify properties={{ target: '_blank', style: { color: styles.themeColor } }} >
                      <div onClick={(e) => e.preventDefault()} style={styles.innerDiv}>
                        {icon && <span>{icon}</span>}{itemText}{addtlValue && <span> ({addtlValue})</span>}
                      </div>
                    </Linkify>}
                  </MenuItem>
                  </ArrowTooltip>
                  )
                  
              });
            }
          } else {
            const itemText = item[this.props.dataSourceConfig.text];
            if (searchText !== '' && !this.props.filter(searchText, itemText)) break;

            const currentText = itemText ? this.props.intl.formatMessage(itemText.country || itemText) : '';
            if (currentText === '') break;
            requestsList.push({
              text: currentText,
              value: (
                <ArrowTooltip key={`${currentText}_${index}`} title={currentText}>
                <MenuItem
                  onClick={()=>this.handleItemTouchTap(index)}
                  id={currentText}
                  style={styles.menuItemStyle}
                  key={index}
                >
                  {<div style={styles.innerDiv}>{currentText}</div>}
                </MenuItem>
                </ArrowTooltip>
                )
            });
          }
          break;

        default:
          // Do nothing
      }
      // if sortDatasource is configured get the full requestlist else get only maxSearchResults
      return sortDatasource || !(maxSearchResults && maxSearchResults > 0 && requestsList.length === maxSearchResults);
    });
    // when sortDatasource is true, the requestList is populated for the entire datasource
    // and only then the maxSearchResults is used
    if (sortDatasource) {
      const sortedList = sortItems(requestsList, 'ASC', 'text');
      requestsList = requestsList.slice(0, (maxSearchResults || requestsList.length));
    }

    let menu = '';
    if (dataSource) {
      this.createOpenListMenuItem(requestsList, styles, disableFocusRipple);
      this.createNewMenuItem(requestsList, styles, disableFocusRipple);
      this.requestsList = requestsList;
      menu = open && requestsList.length > 0 && (
          <Menu
            open={open}
            id="autocomplete_menu"
            anchorEl={anchorEl}
            onClose={this.handleRequestClose}
            disableAutoFocus={true}
            anchorOrigin={this.getOrigin(false)}
            onMouseDown={this.handleMouseDown}
          >
          <DivWrapper id="menuItem_divWrapper" style={{outline: 'none'}} autoHeightMax={330} autoHeight={true}>
            {requestsList.map(i => i.value)}
            </DivWrapper>
          </Menu>   
      );
    }
    return (
      <div style={styles.root} >
        <TextField
          {...(id && { id })}
          {...(disabled && { disabled })}
          variant="outlined"
          type={this.props.inputType}
          value={searchText}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onClick={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          fullWidth={fullWidth}
          style={textFieldStyle}
          placeholder={hintText}
          autoComplete="unique-value"
        />
        {menu}
      </div>
    );
  }
}

AutoComplete.levenshteinDistance = (searchText, key) => {
  const current = [];
  let prev;
  let value;

  for (let i = 0; i <= key.length; i += 1) {
    for (let j = 0; j <= searchText.length; j += 1) {
      if (i && j) {
        if (searchText.charAt(j - 1) === key.charAt(i - 1)) value = prev;
        else value = Math.min(current[j], current[j - 1], prev) + 1;
      } else {
        value = i + j;
      }
      prev = current[j];
      current[j] = value;
    }
  }
  return current.pop();
};

AutoComplete.noFilter = () => true;

AutoComplete.defaultFilter = AutoComplete.caseSensitiveFilter = (searchText, key) => (
  searchText !== '' && key.indexOf(searchText) !== -1
);

AutoComplete.caseInsensitiveFilter = (searchText, key) => (
  key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
);

AutoComplete.levenshteinDistanceFilter = (distanceLessThan) => {
  if (distanceLessThan === undefined) {
    return AutoComplete.levenshteinDistance;
  } else if (typeof distanceLessThan !== 'number') {
    // throw 'Error: AutoComplete.levenshteinDistanceFilter is a filter generator, not a filter!';
  }

  return (s, k) => AutoComplete.levenshteinDistance(s, k) < distanceLessThan;
};

AutoComplete.fuzzyFilter = (searchText, key) => {
  const compareString = key.toLowerCase();
  searchText = searchText.toLowerCase();

  let searchTextIndex = 0;
  for (let index = 0; index < key.length; index += 1) {
    if (compareString[index] === searchText[searchTextIndex]) {
      searchTextIndex += 1;
    }
  }

  return searchTextIndex === searchText.length;
};

AutoComplete.Item = MenuItem;
AutoComplete.Divider = Divider;

AutoComplete.propTypes = {
  /**
   * If true, the auto complete is animated as it is toggled.
   */
  animated: PropTypes.bool,
  /**
   * Override the default animation component used.
   */
  animation: PropTypes.func,
  /**
   * Array of strings or nodes used to populate the list.
   */
  dataSource: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List).isRequired,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  /**
   * Config for objects list dataSource.
   *
   * @typedef {Object} dataSourceConfig
   *
   * @property {string} text `dataSource` element key used to find a string to be matched for search
   * and shown as a `TextField` input value after choosing the result.
   * @property {string} value `dataSource` element key used to find a string to be shown in search results.
   */
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  }),
  /**
   * Disables focus ripple when true.
   */
  disableFocusRipple: PropTypes.bool,
  /**
   * Override style prop for error.
   */
  errorStyle: PropTypes.shape({}),
  /**
   * Callback function used to filter the auto complete.
   *
   * @param {string} searchText The text to search for within `dataSource`.
   * @param {string} key `dataSource` element, or `text` property on that element if it's not a string.
   * @returns {boolean} `true` indicates the auto complete list will include `key` when the input is `searchText`.
   */
  filter: PropTypes.func,
  /**
   * The content to use for adding floating label element.
   */
  floatingLabelText: PropTypes.node,
  /**
   * If true, the field receives the property `width: 100%`.
   */
  fullWidth: PropTypes.bool,
  /**
   * The hint content to display.
   */
  hintText: PropTypes.node,
  /**
   * Override style for list.
   */
  listStyle: PropTypes.shape({}),
  /**
   * The max number of search results to be shown.
   * By default it shows all the items which matches filter.
   */
  maxSearchResults: PropTypes.number,
  /**
   * Delay for closing time of the menu.
   */
  menuCloseDelay: PropTypes.number,
  /**
   * Props to be passed to menu.
   */
  menuProps: PropTypes.shape({}),
  /**
   * Override style for menu.
   */
  menuStyle: PropTypes.shape({}),
  /** @ignore */
  onBlur: PropTypes.func,
  /** @ignore */
  onFocus: PropTypes.func,
  /** @ignore */
  onKeyDown: PropTypes.func,
  /**
   * Callback function that is fired when a list item is selected, or enter is pressed in the `TextField`.
   *
   * @param {string} chosenRequest Either the `TextField` input value, if enter is pressed in the `TextField`,
   * or the text value of the corresponding list item that was selected.
   * @param {number} index The index in `dataSource` of the list item selected,
   * `-2` for no match(create new) and '-3' for select from list  or `-1` if enter is pressed in the
   * `TextField`.
   */
  onNewRequest: PropTypes.func,
  /**
   * Callback function that is fired when the user updates the `TextField`.
   *
   * @param {string} searchText The auto-complete's `searchText` value.
   * @param {array} dataSource The auto-complete's `dataSource` array.
   */
  onUpdateInput: PropTypes.func,
  /**
   * Auto complete menu is open if true.
   */
  open: PropTypes.bool,
  /**
   * If true, the list item is showed when a focus event triggers.
   */
  openOnFocus: PropTypes.bool,
  /**
   * Props to be passed to popover.
   */
  popoverProps: PropTypes.shape({}),
  /**
   * Text being input to auto complete.
   */
  searchText: PropTypes.string,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.shape({}),
  /**
   * Override the inline-styles of AutoComplete's TextField element.
   */
  textFieldStyle: PropTypes.shape({}),
  /**
   * If true, the create new is showed when no match is found.
   */
  createNewMenuItem: PropTypes.bool,
  /**
   * If true, the select from list is showed when no match is found.
   */
  selectFromListMenuItem: PropTypes.bool,
  /**
   * If true, dataSource list is showed as a sorted list.
   */
  sortDatasource: PropTypes.bool,
  /**
   * If true, auto complete field will be disabled
   */
  disabled: PropTypes.bool,
  id: PropTypes.string,
  anchorOrigin: PropTypes.shape({}),
  hintStyle: PropTypes.shape({}),
  underlineShow: PropTypes.bool,
  targetOrigin: PropTypes.shape({}),
  inputType: PropTypes.string,
  /**
   * To force reset the searchtext value irrespective of whether there is a
   * change or not. Introduced as part of the the retention term (period + offset)
   * change. Default value is false.
   */
  resetSearchText: PropTypes.bool
};

export default withTheme(injectIntl(AutoComplete));
