import React from 'react';
import PropTypes from 'prop-types';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { addTranslations, editTranslations } from '@packages/utils/actionTranslations';
import DataSubjectCategory from '.';

const DataSubjectCategoryDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      selectedItem.key = selectedItem.name;
      props.handleSelectedItem({ value: selectedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="add-datasubject-category"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={!props.isEdit ? addTranslations('addDataSubjectCategory') : editTranslations('editDataSubjectCategory')}
    >
      <DataSubjectCategory
        id="data-subject-category"
        isEdit={props.isEdit}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        isUsed={props.isUsed}
        usage={props.usage}
        onCancel={requestClose}
        source={props.source}
        isTagEdit={props.isTagEdit}
        hideTags={props.hideTags}
      />
    </CommonDialog>
  );
};

DataSubjectCategoryDialog.propTypes = {
  isEdit: PropTypes.bool,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  dataItemId: PropTypes.string.isRequired,
  source: PropTypes.string,
  inputValue: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  isTagEdit: PropTypes.bool
};

DataSubjectCategoryDialog.defaultProps = {
  isEdit: false,
  open: false,
  onRequestClose: e => e,
  handleSelectedItem: e => e,
  source: '',
  inputValue: {},
  usage: {},
  isUsed: false,
  isTagEdit: false
};
export default DataSubjectCategoryDialog;
