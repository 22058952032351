import { takeEvery } from 'redux-saga/effects';

import {
  resetAndfetchPrivacyRecords,
  fetchPrivacyRecords,
  fetchPrivacyRecordTemplates,
  deletePrivacyRecord,
  searchPrivacyRecords,
  filterPrivacyRecords,
  privacyRecordBulkDelete,
  deletePrivacyRecordTemplate,
  updateRecordProperty,
  privacyRecordBulkExport,
  resetAndFetchPrivacyRecordTemplates,
  templateStatusUpdate
} from './sagas';
import { fetchImportersList, fetchExportersList } from './components/tia/sagas';

export default [
  // Privacy Records
  [takeEvery, 'PRIVACY_RECORDS:LIST:REQUEST_INIT', resetAndfetchPrivacyRecords],
  [takeEvery, 'PRIVACY_RECORDS:BULK:EXPORT', privacyRecordBulkExport],
  [takeEvery, 'PRIVACY_RECORDS:LIST:NEXT_FETCH', fetchPrivacyRecords],
  [
    takeEvery,
    'PRIVACY_RECORD_TEMPLATES:LIST:REQUEST_INIT',
    resetAndFetchPrivacyRecordTemplates
  ],
  [
    takeEvery,
    'PRIVACY_RECORD_TEMPLATES:LIST:NEXT_FETCH',
    fetchPrivacyRecordTemplates
  ],
  [takeEvery, 'PRIVACY_RECORD_TEMPLATES:DELETE', deletePrivacyRecordTemplate],

  [takeEvery, 'PRIVACY_RECORD:ITEM:DELETE', deletePrivacyRecord],
  [takeEvery, 'PRIVACY_RECORD:UPDATE_ITEM:NOTE', updateRecordProperty],
  [takeEvery, 'PRIVACY_RECORDS:LIST:SEARCH_INIT', searchPrivacyRecords],
  [takeEvery, 'PRIVACY_RECORDS:LIST:FILTER_INIT', filterPrivacyRecords],
  [takeEvery, 'PRIVACY_RECORDS:LIST:BULK_DELETE', privacyRecordBulkDelete],
  [takeEvery, 'RECORD_TEMPLATE:STATUS:UPDATE', templateStatusUpdate],

  [takeEvery, 'DATA_IMPORTERS:LIST:REQUEST_INIT', fetchImportersList],
  [takeEvery, 'DATA_EXPORTERS:LIST:REQUEST_INIT', fetchExportersList]
];
