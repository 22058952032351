import Immutable from 'immutable';
import { removeDuplicates, getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';
import { getCurrentBulkItems } from '../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  items: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'dataSubjectNumber',
    sortOrder: 'DESC'
  }),
  filterColumn: {},
  countrySearchText: Immutable.Map(),
  newItems: Immutable.List(),
  bulkItems: Immutable.List(),
  error: {
    message: '',
    isError: false
  },
  requesterData: {},
  dsrData: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DATAREQUESTER:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('requesterData', defaultState.get('requesterData'))
        .set('dsrData', defaultState.get('dsrData'));
    case 'DATAREQUESTER:ITEM:FETCH:SUCCESS':
      return state.set('requesterData', action.requesterData);
    case 'DATAREQUESTER:ITEM:FETCH:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'DATAREQUESTERS:LIST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('newItems', defaultState.get('newItems'))
        .set('position', 0);

    case 'DATAREQUESTERS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'DATAREQUESTERS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.requesterData) {
        newItems = newItems.unshift(action.requesterData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items').toJS(), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'DATAREQUESTER:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'DATAREQUESTER:LIST:UPDATE:SUCCESS':
      return state.set('items', action.items)
        .set('newItems', action.newItems)
        .set('position', action.position ? action.position : state.get('position'));
    case 'DATAREQUESTER:UPSERT:FAIL':
    case 'DATAREQUESTERS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'DATAREQUESTERS:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentBulkItems(action.item, items));
    }
    case 'DATAREQUESTERS:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'DATAREQUESTER:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('requesterData', action.data)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'DATAREQUESTERS:LIST:FILTER': {
      const { filterParams } = action;
      const newFilterParams = { [filterParams.filterKey]: filterParams.filterKey };
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), newFilterParams);
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'DATAREQUESTER:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'DATAREQUESTER:COUNTRY_SEARCHTEXT:SET': {
      let { searchParams } = action;
      const { searchKey } = searchParams;
      let { searchText } = searchParams;

      if (searchText === undefined) {
        const searchTextObj = state.get('countrySearchText') || Immutable.Map();
        searchText = searchTextObj.get(searchKey) || '';
        searchParams = Object.assign({}, searchParams, { searchText });
      }
      return state
        .set('countrySearchText', getSearchText(Object.assign({}, { searchKey, searchText })));
    }
    case 'DATAREQUESTER:DSR:FETCH:SUCCESS':
      return state.set('dsrData', action.dsrData);

    default:
      return state;
  }
};
