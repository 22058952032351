import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';

import { commonTranslations } from '@packages/utils/commontranslations';
import ItemList from '@packages/components/form-components/itemList';
import ItemSelector from '@packages/components/item-selector';
import PersonalItemCategoryDialog from '@packages/features//environment/components/personal-data-categories/components/personalItemCategoryDialog';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { getItemNames } from '../../common-utils';

class PersonalItemCategorySelector extends React.Component {
  static filterPersonalItemCategories(
    selectedPersonalDataCategories,
    searchedItems
  ) {
    const filteredData = searchedItems.filter((personalItemCategory) => {
      const index = selectedPersonalDataCategories.findIndex(
        (selectedPersonalDataCategory) =>
          (selectedPersonalDataCategory.value
            ? selectedPersonalDataCategory.value.name
            : selectedPersonalDataCategory.name) === personalItemCategory.name
      );
      return index === -1;
    });
    return filteredData;
  }

  static handleGetMultipleItems(newFields, items) {
    const selectedPersonalItemCategories = newFields.getAll();
    const multiplePersonalItemCategories =
      selectedPersonalItemCategories &&
      selectedPersonalItemCategories.length > 0
        ? PersonalItemCategorySelector.filterPersonalItemCategories(
          selectedPersonalItemCategories,
          items
        )
        : items;
    return multiplePersonalItemCategories;
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      openEdit: false,
      personalDataList: [],
      multiplepersonalDataList: [],
      tagSearchResult: {},
      isFetch: true
    };

    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.savePersonalItemCategory = this.savePersonalItemCategory.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.performRemove = this.performRemove.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.performEdit = this.performEdit.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleListItems = this.handleListItems.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.init) {
      this.props.init(this.props.isGlobal);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.personalItemCategories !== nextProps.personalItemCategories ||
      nextProps.searchResults !== this.props.searchResults
    ) {
      const itemList = this.handleListItems(
        nextProps.searchResults,
        nextProps.personalItemCategories,
        nextProps.fields,
        nextProps.position
      );
      const { personalDataList, multiplepersonalDataList } = itemList;
      this.setState({
        personalDataList,
        multiplepersonalDataList
      });
    }
    if (this.props.tagSearchResult !== nextProps.tagSearchResult) {
      this.setState({ tagSearchResult: nextProps.tagSearchResult });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.selectedItems === nextProps.selectedItems) ||
      !(this.state === nextState) ||
      !(
        this.props.personalItemCategories === nextProps.personalItemCategories
      ) ||
      !(this.props.prepareLinkGroup === nextProps.prepareLinkGroup)
    );
  }

  handleListItems(searchResults, personalItemCategories, fields, position) {
    const { searchText } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : personalItemCategories;
    const personalDataList =
      PersonalItemCategorySelector.handleGetMultipleItems(
        fields,
        searchedItems
      );
    const multiplepersonalDataList =
      PersonalItemCategorySelector.handleGetMultipleItems(
        fields,
        personalItemCategories
      );
    if (
      (multiplepersonalDataList.size < 15 || personalDataList.size < 15) &&
      personalItemCategories.size > 0 &&
      this.state.searchText === '' &&
      this.props.position !== position &&
      this.state.isFetch
    ) {
      this.props.getNextData(position);
    }
    const itemList = { personalDataList, multiplepersonalDataList };
    return itemList;
  }

  handleAddClick(searchText) {
    this.setState({
      open: true,
      multiple: false,
      isEdit: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText
      }
    });
  }

  handleEditClick(index) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('personalDataCategories', index);
    if (isLinkAssociated) {
      this.setState({
        openEdit: true,
        selectedIndex: index
      });
    } else {
      this.setState({
        open: true,
        multiple: false,
        selectedIndex: index,
        openEdit: false,
        isEdit: true,
        inputValue:
          this.props.fields.get(index).value || this.props.fields.get(index)
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: ''
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('personalDataCategories', selectedIndex);
    if (isLinkAssociated) {
      this.setState({
        openDelete: true,
        selectedIndex
      });
    } else {
      this.performRemove(selectedIndex);
    }
  }

  handleClose() {
    this.setState({
      openDelete: false,
      openEdit: false,
      selectedIndex: -1
    });
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const selectedPersonalDataCategories = [...items, ...selectedItems];
    this.updatePersonalDataCategories(selectedPersonalDataCategories);
  }

  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Save/update the selected items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    const selectedPersonalDataCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedPersonalDataCategories.push(selectedItem);
    } else {
      selectedPersonalDataCategories.splice(selectedIndex, 1, selectedItem);
    }

    this.updatePersonalDataCategories(selectedPersonalDataCategories);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  handleFilter(selectedItem, enableGetData) {
    const value = selectedItem ? [selectedItem.id] : [];
    this.props.onChooseFilter(
      Object.assign({}, { filterKey: 'tags', filteredOn: value })
    );
    this.setState({ isFetch: enableGetData });
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.dataItemType);
  }

  performEdit(index) {
    this.setState({
      isEdit: true,
      open: true,
      multiple: false,
      openEdit: false,
      selectedIndex: index,
      inputValue: this.props.fields.get(index).value
    });
  }

  performRemove(selectedIndex) {
    const selectedPersonalDataCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedPersonalDataCategories.splice(selectedIndex, 1);
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedPersonalDataCategories);
    } else {
      this.props.updatePersonalDataCategories(selectedPersonalDataCategories);
    }
    this.handleClose();
  }

  savePersonalItemCategory(searchText, closeDialog) {
    this.handleSelectedItem(searchText, closeDialog);
  }

  updatePersonalDataCategories(selectedPersonalDataCategories) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true, searchText: '' }); // eslint-disable-line react/no-unused-state
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedPersonalDataCategories);
    } else {
      this.props.updatePersonalDataCategories(selectedPersonalDataCategories);
    }
  }

  render() {
    const {
      personalItemCategories,
      label,
      showTextField,
      showEditIcon,
      multiValue,
      createNewMenuItem,
      selectFromListMenuItem,
      hintTextLabel,
      tags,
      userPermissions,
      disabled
    } = this.props;
    const selectedPersonalDataCategories = this.props.fields.getAll();

    const { createEditPersonalDataCategories } = userPermissions.toJS();

    const actions = [
      <Button key="btn_remove_no" onClick={this.handleClose}>
        {commonTranslations.Ok}
      </Button>
    ];

    return (
      <div>
        {!showTextField ? (
          getItemNames(selectedPersonalDataCategories)
        ) : (
          <div>
            {selectedPersonalDataCategories &&
              selectedPersonalDataCategories.length > 0 && (
                <ItemList
                  id="item_list"
                  {...this.props}
                  isNote={this.props.isNote}
                  isEditable={createEditPersonalDataCategories && showEditIcon}
                  type={label}
                  handleRemoveItem={this.handleRemoveItem}
                  handleEditClick={this.handleEditClick}
                  selectedItems={selectedPersonalDataCategories}
                  handleEditItem={this.handleEditItem}
                  handleItemClick={(index) =>
                    this.props.toggleLinkGroupItem(
                      'personalDataCategories',
                      index
                    )
                  }
                  handleUsageClick={this.handleUsageClick}
                />
            )}
            <ItemSelector
              id="item_selector"
              multiValue={multiValue}
              createNewMenuItem={
                createEditPersonalDataCategories && createNewMenuItem
              }
              selectFromListMenuItem={selectFromListMenuItem || false}
              selectedItem={this.props.selectedItems}
              dataSource={this.state.personalDataList}
              hintTextLabel={hintTextLabel}
              dataSourceConfig={{ text: 'name', value: 'name' }}
              handleSelectedItem={this.handleSelectedItem}
              handleAddClick={this.handleAddClick}
              handleMultipleSelect={this.handleMultipleSelect}
              onSearch={this.handleSearch}
              disabled={disabled}
            />
            {this.state.open && (
              <PersonalItemCategoryDialog
                id="personaldata_dialog"
                open={this.state.open}
                isEdit={this.state.isEdit}
                dataItemId={this.state.inputValue && this.state.inputValue.id}
                inputValue={this.state.inputValue}
                selectedItems={personalItemCategories}
                onRequestClose={this.handleRequestClose}
                onSavePersonalItemCategory={this.savePersonalItemCategory}
                handleSelectedItem={this.handleSelectedItem}
                hideTags={this.props.isGlobal}
                source="records"
              />
            )}
            {this.state.multiple && (
              <MultipleSelectorDialog
                id="multiple_selector"
                title={
                  <FormattedMessage
                    id="MultiplePersonalDataCategoryDialog.header"
                    description="Select personal data categories Dialog Header"
                    defaultMessage="Select personal data categories"
                  />
                }
                open={this.state.multiple}
                onScrollStop={this.handleScrollEnd}
                filteredData={this.state.multiplepersonalDataList}
                onRequestClose={this.handleRequestClose}
                handleMultipleItems={this.handleMultipleItems}
                filterTagValue={tags}
                onChooseFilter={this.handleFilter}
                showFilterByTag={!this.props.isGlobal}
                tagSearch={this.props.tagsSearch}
                tagSearchResult={this.state.tagSearchResult}
                getNextTags={this.props.getNextTags}
                tagsPosition={this.props.tagsPosition}
              />
            )}
            {this.state.openDelete && (
              <CommonDialog
                id="delete-confirm-dialog"
                show={this.state.openDelete}
                onCancel={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                buttonActions={actions}
                buttonPosition="right"
                showCloseIcon={false}
              >
                {commonTranslations.linkItemDeletefromGroup}
              </CommonDialog>
            )}
            {this.state.openEdit && (
              <CommonDialog
                id="edit-confirm-dialog"
                show={this.state.openEdit}
                onCancel={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                buttonActions={actions}
                buttonPosition="right"
                showCloseIcon={false}
              >
                {commonTranslations.linkItemUpdatefromGroup}
              </CommonDialog>
            )}
          </div>
        )}
      </div>
    );
  }
}

PersonalItemCategorySelector.propTypes = {
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  position: PropTypes.number,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  toggleLinkGroupItem: PropTypes.func,
  init: PropTypes.func,
  prepareLinkGroup: PropTypes.bool,
  updatePersonalDataCategories: PropTypes.func,
  personalItemCategories: PropTypes.instanceOf(Immutable.List).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  editPersonalItemCategory: PropTypes.func,
  addPersonalItemCategory: PropTypes.func,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  showItemList: PropTypes.bool,
  isNote: PropTypes.bool,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  onChooseFilter: PropTypes.func,
  tags: PropTypes.instanceOf(Immutable.List),
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagsSearch: PropTypes.func,
  showTextField: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  isGlobal: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  disabled: PropTypes.bool
};

PersonalItemCategorySelector.defaultProps = {
  multiValue: true,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  editPersonalItemCategory: (e) => e,
  addPersonalItemCategory: (e) => e,
  label: null,
  prepareLinkGroup: false,
  getNextData: (e) => e,
  isItemInLinkGroup: () => {},
  toggleLinkGroupItem: () => {},
  init: (e) => e,
  updatePersonalDataCategories: (e) => e,
  position: 0,
  hintTextLabel: null,
  showItemList: true,
  isNote: true,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  handleUsageClick: (e) => e,
  dataItemType: '',
  tags: Immutable.List(),
  onChooseFilter: (e) => e,
  entityType: '',
  masterDataType: '',
  tagsSearch: (e) => e,
  tagSearchResult: Immutable.List(),
  getNextTags: (e) => e,
  tagsPosition: 0,
  intl: {
    formatMessage: (e) => e
  },
  showTextField: true,
  showEditIcon: true,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  isGlobal: false,
  userPermissions: Immutable.Map(),
  disabled: false
};

export default PersonalItemCategorySelector;
