import { connect } from 'react-redux';
import ProcessorSelector from './component';

const mapStateToProps = state => ({
  processors: state.processors.get('processors'),
  error: state.processors.get('error'),
  position: state.processors.get('position'),
  searchResults: state.processors.get('items')
});

const mapDispatchToProps = dispatch => ({
  initProcessors: () => {
    dispatch({ type: 'PROCESSORS:LIST:REQUEST:INIT' });
    dispatch({ type: 'PROCESSORS:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'PROCESSORS:LIST:REQUEST', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PROCESSORS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessorSelector);
