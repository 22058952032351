/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';

import RetentionSelector from './retentionSelector';

const mapStateToProps = (state) => ({
  masterOffsets: state.environment.retentionTerms.get('offsets'),
  offsetItem: state.environment.retentionTerms.get('offsetItem'),
  position: state.environment.retentionTerms.get('position'),
  searchResults: state.retentionTerms.get('searchItems'),
  searchText: state.retentionTerms.get('searchText'),
  isGlobal: state.home?.get('isGlobal'),
  userPermissions: state.login.get('loggedUser').get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  createNewOffset: (data) => {
    dispatch({
      type: 'MASTERDATA:RETENTION_OFFSETS:CREATE:NEW',
      data,
      source: 'records'
    });
  },
  resetOffset: () => {
    dispatch({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:REQUEST:INIT' });
  },
  getNextData: (position) => {
    dispatch({
      type: 'MASTERDATA:RETENTION_OFFSETS:LIST:NEXT_FETCH',
      position,
      rowCount: 15,
      isPersistedData: true
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RetentionSelector);
