import Immutable from 'immutable';
import { getFilteredOn } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  isDownloading: false,
  items: Immutable.List(),
  bulkItems: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'dataSubjectRequestNumber',
    sortOrder: 'DESC'
  }),
  filterColumn: {},
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  showGoBack: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DATA_SUBJECT_REQUESTS:LIST:FETCH:RESET_INIT':
      return state.set('error', defaultState.get('error'))
        .set('isFetching', defaultState.get('isFetching'))
        .set('items', defaultState.get('items'))
        .set('position', defaultState.get('position'));
    case 'DATA_SUBJECT_REQUESTS:LIST:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('isFetching', true);
    case 'DATA_SUBJECT_REQUESTS:LIST:FETCH:SUCCESS': {
      return state
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(action.items));
    }
    case 'DATA_SUBJECT_REQUESTS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'DATA_SUBJECT_REQUESTS:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'DATA_SUBJECT_REQUESTS:CREATE:INIT': {
      return state
        .set('isFetching', true);
    }
    case 'DATA_SUBJECT_REQUESTS:CREATE:SUCCESS': {
      return state
        .set('isFetching', false);
    }
    case 'DATA_SUBJECT_REQUESTS:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems', updateBulkItems(action.item, items));
    }
    case 'DATA_SUBJECT_REQUESTS:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'DATA_SUBJECT_REQUESTS:LIST:FILTER': {
      const { filterParams } = action;
      const newFilterParams = { [filterParams.filterKey]: filterParams.filterKey };
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), newFilterParams);
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'DATA_SUBJECT_REQUESTS:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'DSR:BULK:EXPORT:INIT':
      return state.set('isDownloading', true);
    case 'DSR:BULK:EXPORT:SUCCESS':
    case 'DSR:BULK:EXPORT:FAIL': {
      return state.set('isDownloading', false);
    }
    case 'DSR:GOBACKLINK:SET':
      return state.set('showGoBack', action.value)
    default:
      return state;
  }
};

const updateBulkItems = (itemToUpdate, bulkCollection) => {
  const index = bulkCollection.findIndex(item => item.id === itemToUpdate.id);
  if (index !== -1) return bulkCollection.splice(index, 1);
  return bulkCollection.push(itemToUpdate);
};
