import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORDS:PERSONAL_DATA_ITEMS:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'RECORDS:PERSONAL_DATA_ITEMS:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH:RESET': {
      return state
        .set('isFetching', false)
        .set('searchText', defaultState.get('searchText'))
        .set('items', defaultState.get('items'));
    }
    default:
      return state;
  }
};
