import { takeEvery } from 'redux-saga/effects';

import {
  fetchProfile,
  updateProfile,
  updateUserLocale,
  userDetailInit,
  upsertUser,
  changePasswordInit,
  changePassword,
  logoutFlow,
  setLoggedUserFlow,
  reinviteUser,
  refreshToken
} from './sagas';

export default [
  [takeEvery, 'LOGIN:TOKEN:REFRESH', refreshToken],
  [takeEvery, 'USER:DETAIL:UPDATE', upsertUser],
  [takeEvery, 'USER:EMAIL:REINVITE', reinviteUser],
  [takeEvery, 'USER:CHANGE_PASSWORD:INIT', changePasswordInit],
  [takeEvery, 'USER:CHANGE_PASSWORD', changePassword],
  [takeEvery, 'USER:DO_LOGOUT', logoutFlow],
  [
    takeEvery,
    ['LOGIN:VERIFY:SUCCESS', 'LOGIN:DO_LOGIN:SUCCESS'],
    setLoggedUserFlow
  ],
  [takeEvery, 'USER:PROFILE:REQUEST', fetchProfile],
  [takeEvery, 'USER:PROFILE:UPDATE', updateProfile],
  [takeEvery, 'USER:PROFILE:LOCALE:UPDATE', updateUserLocale],
  [takeEvery, 'USER:DETAIL:INIT', userDetailInit]
];
