/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { commonTranslations } from '@packages/utils/commontranslations';

const Pagination = (props) => {
  const [firstPageInView, setFirstPageInView] = useState(1);
  const [numberOfVisiblePages, setNumberOfVisiblePage] = useState(props.numberOfVisiblePages);
  const [pageData, setPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsInAPage, setRowsInAPage] = useState(10);
  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    initializePage();
  }, [firstPageInView, currentPage, rowsInAPage]);

  const handleChange = (event) => {
    props.setRowsCount(event.target.value);
    setRowsInAPage(event.target.value);
    setFirstPageInView(1);
    setCurrentPage(1);
  };

  const changePage = (pageNumber) => {
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber);
      props.onPageClick((pageNumber - 1) * rowsInAPage, rowsInAPage);
    }
  };

  const nextPageSet = (direction, number) => {
    switch (direction) {
      case 'right':
      { const newFirstPage = (totalPages - numberOfVisiblePages) > 0 ? ((totalPages - numberOfVisiblePages) + 1) : 1;
        (firstPageInView + number + numberOfVisiblePages) <= totalPages ?
          setFirstPageInView(firstPageInView + number) : setFirstPageInView(newFirstPage);
        break; }
      case 'left':
      default:
        (firstPageInView - number) <= 1 ?
          setFirstPageInView(1) : setFirstPageInView(firstPageInView - number);
        break;
    }
  };

  const initializePage = () => {
    const totalPage = Math.ceil(props.totalRows / rowsInAPage);
    let validNumberOfPages = props.numberOfVisiblePages;
    setNumberOfVisiblePage(validNumberOfPages);
    setTotalPages(totalPage);
    if (totalPage < props.numberOfVisiblePages) {
      setNumberOfVisiblePage(totalPage);
      validNumberOfPages = totalPage;
    }
    const pagesToShow = new Array(validNumberOfPages);
    for (let index = firstPageInView; index < (validNumberOfPages + firstPageInView); index += 1) {
      pagesToShow[index] = (
        <li
          role="presentation"
          onClick={() => { changePage(index); }}
          style={{
            alignSelf: 'center',
            width: '23px',
            backgroundColor: 'white',
            border: 'none',
            paddingTop: '4px',
            paddingLeft: '5px',
            textDecoration: 'none',
            fontWeight: `${currentPage === index ? 'bold' : 'normal'}`
          }}
        >
          {totalPage === 0 ? '_' : index}
        </li>);
    }
    setPageData([...pagesToShow]);
  };

  const menuItemStyle = { fontSize: 13, minHeight: 30 };

  return (
    <div style={{ display: 'flex', fontSize: 13, width: '100%' }}>
      <div style={{ display: 'flex', width: '20%', marginTop: 20 }}>{commonTranslations.totalResults}:
        <div style={{ marginLeft: 10 }}>{props.totalRows}</div>
      </div>
      <div style={{ width: '80%',
        display: 'flex',
        alignItems: 'flex-end',
        paddingTop: 12,
        flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', paddingRight: '4px' }}>
          <div style={{ paddingRight: '10px', alignSelf: 'center' }}>{commonTranslations.numOfResults}:</div>
          <Select
            value={rowsInAPage}
            onChange={handleChange}
            style={{ fontSize: 13 }}
          >
            <MenuItem style={menuItemStyle} value={10}>10</MenuItem>
            <MenuItem style={menuItemStyle} value={20}>20</MenuItem>
            <MenuItem style={menuItemStyle} value={50}>50</MenuItem>
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            type="button"
            style={{ height: '28px', width: '28px', backgroundColor: 'inherit', border: 'none' }}
            onClick={() => nextPageSet('left', numberOfVisiblePages)}
          >
            {
              firstPageInView === 1 ? <FirstPageIcon fontSize="small" /> :
              <FastRewindIcon fontSize="small" />
              }
          </button>
          {
            !(firstPageInView <= 1) &&
            <button
              type="button"
              style={{ height: '28px', width: '28px', backgroundColor: 'inherit', border: 'none' }}
              onClick={() => nextPageSet('left', 1)}
            >
              <NavigateBeforeIcon fontSize="small" />
            </button>
              }
          <ul style={{ padding: '0px', margin: '0px' }}>
            <li style={{ listStyle: 'none', marginBottom: '8px', display: 'flex', cursor: 'pointer' }}>
              {totalPages === 0 ? '-' : pageData}
            </li>
          </ul>
          {
            !(firstPageInView + numberOfVisiblePages > totalPages) &&
            <button
              type="button"
              style={{ height: '28px', width: '28px', backgroundColor: 'inherit', border: 'none' }}
              onClick={() => nextPageSet('right', 1)}
            ><NavigateNextIcon fontSize="small" />
            </button>}
          <button
            type="button"
            style={{ height: '28px', width: '28px', backgroundColor: 'inherit', border: 'none' }}
            onClick={() => nextPageSet('right', numberOfVisiblePages)}
          >
            {
              (firstPageInView + numberOfVisiblePages) > totalPages ? <LastPageIcon fontSize="small" /> :
              <FastForwardIcon fontSize="small" />
              }
          </button>
        </div>
      </div>
    </div>);
};

Pagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  numberOfVisiblePages: PropTypes.number,
  setRowsCount: PropTypes.func,
  onPageClick: PropTypes.func
};
Pagination.defaultProps = {
  numberOfVisiblePages: 0,
  setRowsCount: e => e,
  onPageClick: e => e
};
export default Pagination;
