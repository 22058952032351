import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  data: [],
  position: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CUSTOM_FIELD_SELECTOR:LIST:INIT':
      return state
        .set('isFetching', defaultState.get('isFetching'))
        .set('data', defaultState.get('data'))
        .set('error', defaultState.get('error'))
        .set('position', defaultState.get('position'));
    case 'CUSTOM_FIELD_SELECTOR:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'CUSTOM_FIELD_SELECTOR:LIST:FETCH:SUCCESS':
      return state
        .set('data', state.get('data').concat(action.data))
        .set('position', state.get('position') + action.data.length);
    case 'CUSTOM_FIELD_SELECTOR:LIST:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    default:
      return state;
  }
};
