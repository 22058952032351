import { defineMessages } from 'react-intl';

export default [
  {
    text: defineMessages({
      country: {
        id: 'Country.afghanistan',
        description: 'Afghanistan',
        defaultMessage: 'Afghanistan'
      }
    }),
    value: 'AF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.aland_islands',
        description: 'Aland Islands',
        defaultMessage: 'Åland Islands'
      }
    }),
    value: 'AX'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.albania',
        description: 'Albania',
        defaultMessage: 'Albania'
      }
    }),
    value: 'AL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.algeria',
        description: 'Algeria',
        defaultMessage: 'Algeria'
      }
    }),
    value: 'DZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.american_samoa',
        description: 'American Samoa',
        defaultMessage: 'American Samoa'
      }
    }),
    value: 'AS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.andorra',
        description: 'Andorra',
        defaultMessage: 'Andorra'
      }
    }),
    value: 'AD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.angola',
        description: 'Angola',
        defaultMessage: 'Angola'
      }
    }),
    value: 'AO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.anguilla',
        description: 'Anguilla',
        defaultMessage: 'Anguilla'
      }
    }),
    value: 'AI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.antarctica',
        description: 'Antarctica',
        defaultMessage: 'Antarctica'
      }
    }),
    value: 'AQ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.argentina',
        description: 'Argentina',
        defaultMessage: 'Argentina'
      }
    }),
    value: 'AR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.armenia',
        description: 'Armenia',
        defaultMessage: 'Armenia'
      }
    }),
    value: 'AM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.antigua_barbuda',
        description: 'Antigua and Barbuda',
        defaultMessage: 'Antigua and Barbuda'
      }
    }),
    value: 'AG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.aruba',
        description: 'Aruba',
        defaultMessage: 'Aruba'
      }
    }),
    value: 'AW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.australia',
        description: 'Australia',
        defaultMessage: 'Australia'
      }
    }),
    value: 'AU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.austria',
        description: 'Austria',
        defaultMessage: 'Austria'
      }
    }),
    jurisdiction: true,
    value: 'AT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.azerbaijan',
        description: 'Azerbaijan',
        defaultMessage: 'Azerbaijan'
      }
    }),
    value: 'AZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bahamas',
        description: 'Bahamas',
        defaultMessage: 'Bahamas'
      }
    }),
    value: 'BS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bahrain',
        description: 'Bahrain',
        defaultMessage: 'Bahrain'
      }
    }),
    value: 'BH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bangladesh',
        description: 'Bangladesh',
        defaultMessage: 'Bangladesh'
      }
    }),
    value: 'BD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.barbados',
        description: 'Barbados',
        defaultMessage: 'Barbados'
      }
    }),
    value: 'BB'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.belarus',
        description: 'Belarus',
        defaultMessage: 'Belarus'
      }
    }),
    value: 'BY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.belgium',
        description: 'Belgium',
        defaultMessage: 'Belgium'
      }
    }),
    jurisdiction: true,
    value: 'BE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.belize',
        description: 'Belize',
        defaultMessage: 'Belize'
      }
    }),
    value: 'BZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.benin',
        description: 'Benin',
        defaultMessage: 'Benin'
      }
    }),
    value: 'BJ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bermuda',
        description: 'Bermuda',
        defaultMessage: 'Bermuda'
      }
    }),
    value: 'BM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bhutan',
        description: 'Bhutan',
        defaultMessage: 'Bhutan'
      }
    }),
    value: 'BT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bolivia',
        description: 'Bolivia',
        defaultMessage: 'Bolivia'
      }
    }),
    value: 'BO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bosnia_herzegovina',
        description: 'Bosnia and Herzegovina',
        defaultMessage: 'Bosnia and Herzegovina'
      }
    }),
    value: 'BA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.botswana',
        description: 'Botswana',
        defaultMessage: 'Botswana'
      }
    }),
    value: 'BW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bouvet_island',
        description: 'Bouvet Island',
        defaultMessage: 'Bouvet Island'
      }
    }),
    value: 'BV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.brazil',
        description: 'Brazil',
        defaultMessage: 'Brazil'
      }
    }),
    value: 'BR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.british_indianocean_territory',
        description: 'British Indian Ocean Territory',
        defaultMessage: 'British Indian Ocean Territory'
      }
    }),
    value: 'IO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.brunei_darussalam',
        description: 'Brunei Darussalam',
        defaultMessage: 'Brunei Darussalam'
      }
    }),
    value: 'BN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.bulgaria',
        description: 'Bulgaria',
        defaultMessage: 'Bulgaria'
      }
    }),
    jurisdiction: true,
    value: 'BG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.burkina_faso',
        description: 'Burkina Faso',
        defaultMessage: 'Burkina Faso'
      }
    }),
    value: 'BF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.burundi',
        description: 'Burundi',
        defaultMessage: 'Burundi'
      }
    }),
    value: 'BI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cambodia',
        description: 'Cambodia',
        defaultMessage: 'Cambodia'
      }
    }),
    value: 'KH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cameroon',
        description: 'Cameroon',
        defaultMessage: 'Cameroon'
      }
    }),
    value: 'CM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.canada',
        description: 'Canada',
        defaultMessage: 'Canada'
      }
    }),
    value: 'CA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cape_verde',
        description: 'Cape Verde',
        defaultMessage: 'Cape Verde'
      }
    }),
    value: 'CV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cayman_islands',
        description: 'Cayman Islands',
        defaultMessage: 'Cayman Islands'
      }
    }),
    value: 'KY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.central_african_republic',
        description: 'Central African Republic',
        defaultMessage: 'Central African Republic'
      }
    }),
    value: 'CF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.chad',
        description: 'Chad',
        defaultMessage: 'Chad'
      }
    }),
    value: 'TD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.chile',
        description: 'Chile',
        defaultMessage: 'Chile'
      }
    }),
    value: 'CL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.china',
        description: 'China',
        defaultMessage: 'China'
      }
    }),
    value: 'CN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.christmas_island',
        description: 'Christmas Island',
        defaultMessage: 'Christmas Island'
      }
    }),
    value: 'CX'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cocos_islands',
        description: 'Cocos (Keeling) Islands',
        defaultMessage: 'Cocos (Keeling) Islands'
      }
    }),
    value: 'CC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.colombia',
        description: 'Colombia',
        defaultMessage: 'Colombia'
      }
    }),
    value: 'CO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.comoros',
        description: 'Comoros',
        defaultMessage: 'Comoros'
      }
    }),
    value: 'KM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.congo',
        description: 'Congo',
        defaultMessage: 'Congo'
      }
    }),
    value: 'CG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.congo_democratic_republic',
        description: 'Congo, The Democratic Republic of the',
        defaultMessage: 'Congo, The Democratic Republic of the'
      }
    }),
    value: 'CD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cook_islands',
        description: 'Cook Islands',
        defaultMessage: 'Cook Islands'
      }
    }),
    value: 'CK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.costa_rica',
        description: 'Costa Rica',
        defaultMessage: 'Costa Rica'
      }
    }),
    value: 'CR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cote_dIvoire',
        description: 'Cote D\'Ivoire',
        defaultMessage: 'Cote D\'Ivoire'
      }
    }),
    value: 'CI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.croatia',
        description: 'Croatia',
        defaultMessage: 'Croatia'
      }
    }),
    jurisdiction: true,
    value: 'HR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cuba',
        description: 'Cuba',
        defaultMessage: 'Cuba'
      }
    }),
    value: 'CU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.cyprus',
        description: 'Cyprus',
        defaultMessage: 'Cyprus'
      }
    }),
    jurisdiction: true,
    value: 'CY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.czech_republic',
        description: 'Czech Republic',
        defaultMessage: 'Czech Republic'
      }
    }),
    jurisdiction: true,
    value: 'CZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.denmark',
        description: 'Denmark',
        defaultMessage: 'Denmark'
      }
    }),
    jurisdiction: true,
    value: 'DK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.djibouti',
        description: 'Djibouti',
        defaultMessage: 'Djibouti'
      }
    }),
    value: 'DJ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.dominica',
        description: 'Dominica',
        defaultMessage: 'Dominica'
      }
    }),
    value: 'DM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.dominican_republic',
        description: 'Dominican Republic',
        defaultMessage: 'Dominican Republic'
      }
    }),
    value: 'DO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.ecuador',
        description: 'Ecuador',
        defaultMessage: 'Ecuador'
      }
    }),
    value: 'EC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.egypt',
        description: 'Egypt',
        defaultMessage: 'Egypt'
      }
    }),
    value: 'EG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.el_salvador',
        description: 'El Salvador',
        defaultMessage: 'El Salvador'
      }
    }),
    value: 'SV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.equatorial_guinea',
        description: 'Equatorial Guinea',
        defaultMessage: 'Equatorial Guinea'
      }
    }),
    value: 'GQ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.eritrea',
        description: 'Eritrea',
        defaultMessage: 'Eritrea'
      }
    }),
    value: 'ER'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.estonia',
        description: 'Estonia',
        defaultMessage: 'Estonia'
      }
    }),
    jurisdiction: true,
    value: 'EE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.ethiopia',
        description: 'Ethiopia',
        defaultMessage: 'Ethiopia'
      }
    }),
    value: 'ET'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.europeanUnion',
        description: 'European Union',
        defaultMessage: 'European Union'
      }
    }),
    jurisdiction: true,
    value: 'EU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.europeanEconomic',
        description: 'European Economic Area',
        defaultMessage: 'European Economic Area'
      }
    }),
    jurisdiction: true,
    value: 'EN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.falkland_islands',
        description: 'Falkland Islands (Malvinas)',
        defaultMessage: 'Falkland Islands (Malvinas)'
      }
    }),
    value: 'FK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.faroe_islands',
        description: 'Faroe Islands',
        defaultMessage: 'Faroe Islands'
      }
    }),
    value: 'FO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.fiji',
        description: 'Fiji',
        defaultMessage: 'Fiji'
      }
    }),
    value: 'FJ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.finland',
        description: 'Finland',
        defaultMessage: 'Finland'
      }
    }),
    jurisdiction: true,
    value: 'FI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.france',
        description: 'France',
        defaultMessage: 'France'
      }
    }),
    jurisdiction: true,
    value: 'FR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.french_guiana',
        description: 'French Guiana',
        defaultMessage: 'French Guiana'
      }
    }),
    value: 'GF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.french_polynesia',
        description: 'French Polynesia',
        defaultMessage: 'French Polynesia'
      }
    }),
    value: 'PF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.french_south_territories',
        description: 'French Southern Territories',
        defaultMessage: 'French Southern Territories'
      }
    }),
    value: 'TF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.gabon',
        description: 'Gabon',
        defaultMessage: 'Gabon'
      }
    }),
    value: 'GA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.gambia',
        description: 'Gambia',
        defaultMessage: 'Gambia'
      }
    }),
    value: 'GM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.georgia',
        description: 'Georgia',
        defaultMessage: 'Georgia'
      }
    }),
    value: 'GE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.germany',
        description: 'Germany',
        defaultMessage: 'Germany'
      }
    }),
    jurisdiction: true,
    value: 'DE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.ghana',
        description: 'Ghana',
        defaultMessage: 'Ghana'
      }
    }),
    value: 'GH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.gibraltar',
        description: 'Gibraltar',
        defaultMessage: 'Gibraltar'
      }
    }),
    value: 'GI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.greece',
        description: 'Greece',
        defaultMessage: 'Greece'
      }
    }),
    jurisdiction: true,
    value: 'GR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.greenland',
        description: 'Greenland',
        defaultMessage: 'Greenland'
      }
    }),
    value: 'GL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.grenada',
        description: 'Grenada',
        defaultMessage: 'Grenada'
      }
    }),
    value: 'GD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guadeloupe',
        description: 'Guadeloupe',
        defaultMessage: 'Guadeloupe'
      }
    }),
    value: 'GP'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guam',
        description: 'Guam',
        defaultMessage: 'Guam'
      }
    }),
    value: 'GU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guatemala',
        description: 'Guatemala',
        defaultMessage: 'Guatemala'
      }
    }),
    value: 'GT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guernsey',
        description: 'Guernsey',
        defaultMessage: 'Guernsey'
      }
    }),
    value: 'GG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guinea',
        description: 'Guinea',
        defaultMessage: 'Guinea'
      }
    }),
    value: 'GN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guinea_bissau',
        description: 'Guinea-Bissau',
        defaultMessage: 'Guinea-Bissau'
      }
    }),
    value: 'GW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.guyana',
        description: 'Guyana',
        defaultMessage: 'Guyana'
      }
    }),
    value: 'GY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.haiti',
        description: 'Haiti',
        defaultMessage: 'Haiti'
      }
    }),
    value: 'HT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.heard_mcdonald_islands',
        description: 'Heard Island and Mcdonald Islands',
        defaultMessage: 'Heard Island and Mcdonald Islands'
      }
    }),
    value: 'HM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.vatican_city',
        description: 'Holy See (Vatican City State)',
        defaultMessage: 'Holy See (Vatican City State)'
      }
    }),
    value: 'VA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.honduras',
        description: 'Honduras',
        defaultMessage: 'Honduras'
      }
    }),
    value: 'HN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.hong_kong',
        description: 'Hong Kong',
        defaultMessage: 'Hong Kong'
      }
    }),
    value: 'HK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.hungary',
        description: 'Hungary',
        defaultMessage: 'Hungary'
      }
    }),
    jurisdiction: true,
    value: 'HU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.iceland',
        description: 'Iceland',
        defaultMessage: 'Iceland'
      }
    }),
    jurisdiction: true,
    value: 'IS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.india',
        description: 'India',
        defaultMessage: 'India'
      }
    }),
    value: 'IN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.indonesia',
        description: 'Indonesia',
        defaultMessage: 'Indonesia'
      }
    }),
    value: 'ID'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.iran',
        description: 'Iran, Islamic Republic Of',
        defaultMessage: 'Iran, Islamic Republic Of'
      }
    }),
    value: 'IR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.iraq',
        description: 'Iraq',
        defaultMessage: 'Iraq'
      }
    }),
    value: 'IQ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.ireland',
        description: 'Ireland',
        defaultMessage: 'Ireland'
      }
    }),
    jurisdiction: true,
    value: 'IE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.isle_of_man',
        description: 'Isle of Man',
        defaultMessage: 'Isle of Man'
      }
    }),
    value: 'IM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.israel',
        description: 'Israel',
        defaultMessage: 'Israel'
      }
    }),
    value: 'IL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.italy',
        description: 'Italy',
        defaultMessage: 'Italy'
      }
    }),
    jurisdiction: true,
    value: 'IT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.jamaica',
        description: 'Jamaica',
        defaultMessage: 'Jamaica'
      }
    }),
    value: 'JM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.japan',
        description: 'Japan',
        defaultMessage: 'Japan'
      }
    }),
    value: 'JP'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.jersey',
        description: 'Jersey',
        defaultMessage: 'Jersey'
      }
    }),
    value: 'JE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.jordan',
        description: 'Jordan',
        defaultMessage: 'Jordan'
      }
    }),
    value: 'JO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kazakhstan',
        description: 'Kazakhstan',
        defaultMessage: 'Kazakhstan'
      }
    }),
    value: 'KZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kenya',
        description: 'Kenya',
        defaultMessage: 'Kenya'
      }
    }),
    value: 'KE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kiribati',
        description: 'Kiribati',
        defaultMessage: 'Kiribati'
      }
    }),
    value: 'KI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.democratic_korea',
        description: 'Democratic People\'s Republic of Korea',
        defaultMessage: 'Democratic People\'s Republic of Korea'
      }
    }),
    value: 'KP'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.korea_republic',
        description: 'Republic of Korea',
        defaultMessage: 'Republic of Korea'
      }
    }),
    value: 'KR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kosovo',
        description: 'Kosovo',
        defaultMessage: 'Kosovo'
      }
    }),
    value: 'XK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kuwait',
        description: 'Kuwait',
        defaultMessage: 'Kuwait'
      }
    }),
    value: 'KW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.kyrgyzstan',
        description: 'Kyrgyzstan',
        defaultMessage: 'Kyrgyzstan'
      }
    }),
    value: 'KG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.lao',
        description: 'Lao People\'s Democratic Republic',
        defaultMessage: 'Lao People\'s Democratic Republic'
      }
    }),
    value: 'LA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.latvia',
        description: 'Latvia',
        defaultMessage: 'Latvia'
      }
    }),
    jurisdiction: true,
    value: 'LV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.lebanon',
        description: 'Lebanon',
        defaultMessage: 'Lebanon'
      }
    }),
    value: 'LB'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.lesotho',
        description: 'Lesotho',
        defaultMessage: 'Lesotho'
      }
    }),
    value: 'LS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.liberia',
        description: 'Liberia',
        defaultMessage: 'Liberia'
      }
    }),
    value: 'LR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.libyan_arab_jamahiriya',
        description: 'Libyan Arab Jamahiriya',
        defaultMessage: 'Libyan Arab Jamahiriya'
      }
    }),
    value: 'LY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.liechtenstein',
        description: 'Liech  nstein',
        defaultMessage: 'Liechtenstein'
      }
    }),
    jurisdiction: true,
    value: 'LI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.lithuania',
        description: 'Lithuania',
        defaultMessage: 'Lithuania'
      }
    }),
    jurisdiction: true,
    value: 'LT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.luxembourg',
        description: 'Luxembourg',
        defaultMessage: 'Luxembourg'
      }
    }),
    jurisdiction: true,
    value: 'LU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.macao',
        description: 'Macao',
        defaultMessage: 'Macao'
      }
    }),
    value: 'MO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.macedonia',
        description: 'Macedonia, The Former Yugoslav Republic of',
        defaultMessage: 'Macedonia, The Former Yugoslav Republic of'
      }
    }),
    value: 'MK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.madagascar',
        description: 'Madagascar',
        defaultMessage: 'Madagascar'
      }
    }),
    value: 'MG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.malawi',
        description: 'Malawi',
        defaultMessage: 'Malawi'
      }
    }),
    value: 'MW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.malaysia',
        description: 'Malaysia',
        defaultMessage: 'Malaysia'
      }
    }),
    value: 'MY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.maldives',
        description: 'Maldives',
        defaultMessage: 'Maldives'
      }
    }),
    value: 'MV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mali',
        description: 'Mali',
        defaultMessage: 'Mali'
      }
    }),
    value: 'ML'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.malta',
        description: 'Malta',
        defaultMessage: 'Malta'
      }
    }),
    jurisdiction: true,
    value: 'MT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.marshall_islands',
        description: 'Marshall Islands',
        defaultMessage: 'Marshall Islands'
      }
    }),
    value: 'MH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.martinique',
        description: 'Martinique',
        defaultMessage: 'Martinique'
      }
    }),
    value: 'MQ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mauritania',
        description: 'Mauritania',
        defaultMessage: 'Mauritania'
      }
    }),
    value: 'MR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mauritius',
        description: 'Mauritius',
        defaultMessage: 'Mauritius'
      }
    }),
    value: 'MU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mayotte',
        description: 'Mayotte',
        defaultMessage: 'Mayotte'
      }
    }),
    value: 'YT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mexico',
        description: 'Mexico',
        defaultMessage: 'Mexico'
      }
    }),
    value: 'MX'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.micronesia',
        description: 'Micronesia, Federated States of',
        defaultMessage: 'Micronesia, Federated States of'
      }
    }),
    value: 'FM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.moldova',
        description: 'Moldova, Republic of',
        defaultMessage: 'Moldova, Republic of'
      }
    }),
    value: 'MD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.monaco',
        description: 'Monaco',
        defaultMessage: 'Monaco'
      }
    }),
    value: 'MC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mongolia',
        description: 'Mongolia',
        defaultMessage: 'Mongolia'
      }
    }),
    value: 'MN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.montenegro',
        description: 'Montenegro',
        defaultMessage: 'Montenegro'
      }
    }),
    value: 'ME'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.montserrat',
        description: 'Montserrat',
        defaultMessage: 'Montserrat'
      }
    }),
    value: 'MS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.morocco',
        description: 'Morocco',
        defaultMessage: 'Morocco'
      }
    }),
    value: 'MA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mozambique',
        description: 'Mozambique',
        defaultMessage: 'Mozambique'
      }
    }),
    value: 'MZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.myanmar',
        description: 'Myanmar',
        defaultMessage: 'Myanmar'
      }
    }),
    value: 'MM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.namibia',
        description: 'Namibia',
        defaultMessage: 'Namibia'
      }
    }),
    value: 'NA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.nauru',
        description: 'Nauru',
        defaultMessage: 'Nauru'
      }
    }),
    value: 'NR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.nepal',
        description: 'Nepal',
        defaultMessage: 'Nepal'
      }
    }),
    value: 'NP'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.netherlands',
        description: 'Netherlands',
        defaultMessage: 'Netherlands'
      }
    }),
    jurisdiction: true,
    value: 'NL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.netherlands_antilles',
        description: 'Netherlands Antilles',
        defaultMessage: 'Netherlands Antilles'
      }
    }),
    value: 'AN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.new_caledonia',
        description: 'New Caledonia',
        defaultMessage: 'New Caledonia'
      }
    }),
    value: 'NC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.new_zealand',
        description: 'New Zealand',
        defaultMessage: 'New Zealand'
      }
    }),
    value: 'NZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.nicaragua',
        description: 'Nicaragua',
        defaultMessage: 'Nicaragua'
      }
    }),
    value: 'NI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.niger',
        description: 'Niger',
        defaultMessage: 'Niger'
      }
    }),
    value: 'NE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.nigeria',
        description: 'Nigeria',
        defaultMessage: 'Nigeria'
      }
    }),
    value: 'NG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.niue',
        description: 'Niue',
        defaultMessage: 'Niue'
      }
    }),
    value: 'NU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.norfolk_island',
        description: 'Norfolk Island',
        defaultMessage: 'Norfolk Island'
      }
    }),
    value: 'NF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.mariana_islands',
        description: 'Northern Mariana Islands',
        defaultMessage: 'Northern Mariana Islands'
      }
    }),
    value: 'MP'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.norway',
        description: 'Norway',
        defaultMessage: 'Norway'
      }
    }),
    jurisdiction: true,
    value: 'NO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.outsideEuropean',
        description: 'Outside European Economic Area',
        defaultMessage: 'Outside European Economic Area'
      }
    }),
    value: 'OE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.oman',
        description: 'Oman',
        defaultMessage: 'Oman'
      }
    }),
    value: 'OM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.pakistan',
        description: 'Pakistan',
        defaultMessage: 'Pakistan'
      }
    }),
    value: 'PK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.palau',
        description: 'Palau',
        defaultMessage: 'Palau'
      }
    }),
    value: 'PW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.palestinian_territory',
        description: 'Palestinian Territory, Occupied',
        defaultMessage: 'Palestinian Territory, Occupied'
      }
    }),
    value: 'PS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.panama',
        description: 'Panama',
        defaultMessage: 'Panama'
      }
    }),
    value: 'PA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.papua_new_guinea',
        description: 'Papua New Guinea',
        defaultMessage: 'Papua New Guinea'
      }
    }),
    value: 'PG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.paraguay',
        description: 'Paraguay',
        defaultMessage: 'Paraguay'
      }
    }),
    value: 'PY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.peru',
        description: 'Peru',
        defaultMessage: 'Peru'
      }
    }),
    value: 'PE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.philippines',
        description: 'Philippines',
        defaultMessage: 'Philippines'
      }
    }),
    value: 'PH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.pitcairn',
        description: 'Pitcairn',
        defaultMessage: 'Pitcairn'
      }
    }),
    value: 'PN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.poland',
        description: 'Poland',
        defaultMessage: 'Poland'
      }
    }),
    jurisdiction: true,
    value: 'PL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.portugal',
        description: 'Portugal',
        defaultMessage: 'Portugal'
      }
    }),
    jurisdiction: true,
    value: 'PT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.puerto_rico',
        description: 'Puerto Rico',
        defaultMessage: 'Puerto Rico'
      }
    }),
    value: 'PR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.qatar',
        description: 'Qatar',
        defaultMessage: 'Qatar'
      }
    }),
    value: 'QA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.reunion',
        description: 'Reunion',
        defaultMessage: 'Reunion'
      }
    }),
    value: 'RE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.romania',
        description: 'Romania',
        defaultMessage: 'Romania'
      }
    }),
    jurisdiction: true,
    value: 'RO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.russian_federation',
        description: 'Russian Federation',
        defaultMessage: 'Russian Federation'
      }
    }),
    value: 'RU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.rwanda',
        description: 'Rwanda',
        defaultMessage: 'Rwanda'
      }
    }),
    value: 'RW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.st_helena',
        description: 'Saint Helena',
        defaultMessage: 'Saint Helena'
      }
    }),
    value: 'SH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.st_kitts_nevis',
        description: 'Saint Kitts and Nevis',
        defaultMessage: 'Saint Kitts and Nevis'
      }
    }),
    value: 'KN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.st_lucia',
        description: 'Saint Lucia',
        defaultMessage: 'Saint Lucia'
      }
    }),
    value: 'LC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.st_pierre_miquelon',
        description: 'Saint Pierre and Miquelon',
        defaultMessage: 'Saint Pierre and Miquelon'
      }
    }),
    value: 'PM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.st_vincent_grenadines',
        description: 'Saint Vincent and the Grenadines',
        defaultMessage: 'Saint Vincent and the Grenadines'
      }
    }),
    value: 'VC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.samoa',
        description: 'Samoa',
        defaultMessage: 'Samoa'
      }
    }),
    value: 'WS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.san_marino',
        description: 'San Marino',
        defaultMessage: 'San Marino'
      }
    }),
    value: 'SM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.sao_tome_principe',
        description: 'Sao Tome and Principe',
        defaultMessage: 'Sao Tome and Principe'
      }
    }),
    value: 'ST'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.saudi_arabia',
        description: 'Saudi Arabia',
        defaultMessage: 'Saudi Arabia'
      }
    }),
    value: 'SA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.senegal',
        description: 'Senegal',
        defaultMessage: 'Senegal'
      }
    }),
    value: 'SN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.serbia',
        description: 'Serbia',
        defaultMessage: 'Serbia'
      }
    }),
    value: 'RS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.seychelles',
        description: 'Seychelles',
        defaultMessage: 'Seychelles'
      }
    }),
    value: 'SC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.sierra_leone',
        description: 'Sierra Leone',
        defaultMessage: 'Sierra Leone'
      }
    }),
    value: 'SL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.singapore',
        description: 'Singapore',
        defaultMessage: 'Singapore'
      }
    }),
    value: 'SG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.slovakia',
        description: 'Slovakia',
        defaultMessage: 'Slovakia'
      }
    }),
    jurisdiction: true,
    value: 'SK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.slovenia',
        description: 'Slovenia',
        defaultMessage: 'Slovenia'
      }
    }),
    jurisdiction: true,
    value: 'SI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.solomon_islands',
        description: 'Solomon Islands',
        defaultMessage: 'Solomon Islands'
      }
    }),
    value: 'SB'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.somalia',
        description: 'Somalia',
        defaultMessage: 'Somalia'
      }
    }),
    value: 'SO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.south_africa',
        description: 'South Africa',
        defaultMessage: 'South Africa'
      }
    }),
    value: 'ZA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.georgia_sandwich_islands',
        description: 'South Georgia and the South Sandwich Islands',
        defaultMessage: 'South Georgia and the South Sandwich Islands'
      }
    }),
    value: 'GS'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.spain',
        description: 'Spain',
        defaultMessage: 'Spain'
      }
    }),
    jurisdiction: true,
    value: 'ES'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.srilanka',
        description: 'Sri Lanka',
        defaultMessage: 'Sri Lanka'
      }
    }),
    value: 'LK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.sudan',
        description: 'Sudan',
        defaultMessage: 'Sudan'
      }
    }),
    value: 'SD'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.suritext',
        description: 'Suritext',
        defaultMessage: 'Suritext'
      }
    }),
    value: 'SR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.svalbard_jan_mayen',
        description: 'Svalbard and Jan Mayen',
        defaultMessage: 'Svalbard and Jan Mayen'
      }
    }),
    value: 'SJ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.swaziland',
        description: 'Swaziland',
        defaultMessage: 'Swaziland'
      }
    }),
    value: 'SZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.sweden',
        description: 'Sweden',
        defaultMessage: 'Sweden'
      }
    }),
    jurisdiction: true,
    value: 'SE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.switzerland',
        description: 'Switzerland',
        defaultMessage: 'Switzerland'
      }
    }),
    value: 'CH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.syria',
        description: 'Syrian Arab Republic',
        defaultMessage: 'Syrian Arab Republic'
      }
    }),
    value: 'SY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.taiwan',
        description: 'Taiwan',
        defaultMessage: 'Taiwan'
      }
    }),
    value: 'TW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tajikistan',
        description: 'Tajikistan',
        defaultMessage: 'Tajikistan'
      }
    }),
    value: 'TJ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tanzania',
        description: 'Tanzania, United Republic of',
        defaultMessage: 'Tanzania, United Republic of'
      }
    }),
    value: 'TZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.thailand',
        description: 'Thailand',
        defaultMessage: 'Thailand'
      }
    }),
    value: 'TH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.timorleste',
        description: 'Timor-Leste',
        defaultMessage: 'Timor-Leste'
      }
    }),
    value: 'TL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.togo',
        description: 'Togo',
        defaultMessage: 'Togo'
      }
    }),
    value: 'TG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tokelau',
        description: 'Tokelau',
        defaultMessage: 'Tokelau'
      }
    }),
    value: 'TK'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tonga',
        description: 'Tonga',
        defaultMessage: 'Tonga'
      }
    }),
    value: 'TO'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.trinidad_tobago',
        description: 'Trinidad and Tobago',
        defaultMessage: 'Trinidad and Tobago'
      }
    }),
    value: 'TT'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tunisia',
        description: 'Tunisia',
        defaultMessage: 'Tunisia'
      }
    }),
    value: 'TN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.turkey',
        description: 'Turkey',
        defaultMessage: 'Turkey'
      }
    }),
    value: 'TR'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.turkmenistan',
        description: 'Turkmenistan',
        defaultMessage: 'Turkmenistan'
      }
    }),
    value: 'TM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.turks_caicos_islands',
        description: 'Turks and Caicos Islands',
        defaultMessage: 'Turks and Caicos Islands'
      }
    }),
    value: 'TC'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.tuvalu',
        description: 'Tuvalu',
        defaultMessage: 'Tuvalu'
      }
    }),
    value: 'TV'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.uganda',
        description: 'Uganda',
        defaultMessage: 'Uganda'
      }
    }),
    value: 'UG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.ukraine',
        description: 'Ukraine',
        defaultMessage: 'Ukraine'
      }
    }),
    value: 'UA'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.uae',
        description: 'United Arab Emirates',
        defaultMessage: 'United Arab Emirates'
      }
    }),
    value: 'AE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.uk',
        description: 'United Kingdom',
        defaultMessage: 'United Kingdom'
      }
    }),
    jurisdiction: false,
    value: 'GB'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.us',
        description: 'United States',
        defaultMessage: 'United States'
      }
    }),
    value: 'US'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.us_minor_islands',
        description: 'United States Minor Outlying Islands',
        defaultMessage: 'United States Minor Outlying Islands'
      }
    }),
    value: 'UM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.uruguay',
        description: 'Uruguay',
        defaultMessage: 'Uruguay'
      }
    }),
    value: 'UY'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.uzbekistan',
        description: 'Uzbekistan',
        defaultMessage: 'Uzbekistan'
      }
    }),
    value: 'UZ'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.vanuatu',
        description: 'Vanuatu',
        defaultMessage: 'Vanuatu'
      }
    }),
    value: 'VU'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.venezuela',
        description: 'Venezuela',
        defaultMessage: 'Venezuela'
      }
    }),
    value: 'VE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.vietnam',
        description: 'Viet Nam',
        defaultMessage: 'Viet Nam'
      }
    }),
    value: 'VN'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.virgin_islands_british',
        description: 'Virgin Islands, British',
        defaultMessage: 'Virgin Islands, British'
      }
    }),
    value: 'VG'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.virgin_islands_us',
        description: 'Virgin Islands, U.S.',
        defaultMessage: 'Virgin Islands, U.S.'
      }
    }),
    value: 'VI'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.wallis_futuna',
        description: 'Wallis and Futuna',
        defaultMessage: 'Wallis and Futuna'
      }
    }),
    value: 'WF'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.western_sahara',
        description: 'Western Sahara',
        defaultMessage: 'Western Sahara'
      }
    }),
    value: 'EH'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.yemen',
        description: 'Yemen',
        defaultMessage: 'Yemen'
      }
    }),
    value: 'YE'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.zambia',
        description: 'Zambia',
        defaultMessage: 'Zambia'
      }
    }),
    value: 'ZM'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.zimbabwe',
        description: 'Zimbabwe',
        defaultMessage: 'Zimbabwe'
      }
    }),
    value: 'ZW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.Global',
        description: 'Country outside of the EEA',
        defaultMessage: 'Global'
      }
    }),
    value: 'GLOBAL'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.curaçao',
        description: 'Curaçao',
        defaultMessage: 'Curaçao'
      }
    }),
    value: 'CW'
  },
  {
    text: defineMessages({
      country: {
        id: 'Country.sint_maarten',
        description: 'Sint Maarten',
        defaultMessage: 'Sint Maarten'
      }
    }),
    value: 'SX'
  }
];
