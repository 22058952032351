import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  passwordSettings: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PASSWORDSETTINGS:FETCH':
      return state.set('passwordSettings', defaultState.get('passwordSettings'));
    case 'PASSWORDSETTINGS:FETCH:SUCCESS':
      return state.set('passwordSettings', action.data);
    case 'PASSWORDSETTINGS:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'PASSWORDSETTINGS:UPDATE:SUCCESS':
      return state.set('passwordSettings', action.data);
    case 'PASSWORDSETTINGS:UPDATE:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
