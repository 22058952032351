import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import { injectIntl } from 'react-intl';

import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell,
  InformationCell,
  ActionCell
} from '@packages/components/responsive-table';
import Note from '@packages/components/note';
import {
  statusValueMapper,
  commonTranslations,
  recordTranslations,
  deleteRecord,
  customTranslation
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import {
  getContainerHeight,
  RECORDS_FETCH_LIMIT
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import CustomDialog from '@packages/components/custom-dialog';
import { filterItem, getModifiedActions } from '@packages/utils/record-utils';

import NotificationDialogBox from '../../../privacy-record-detail/components/notificationDialog';
import {
  shouldHideMenuItem,
  recordStatusFilterValues,
  CheckBoxCell
} from '../../record-utils';

const actionItems = [
  {
    action: 'graphicalView',
    primaryText: recordTranslations.graphicalView
  },
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  },
  {
    action: 'changelog',
    primaryText: recordTranslations.viewChangeLog
  },
  {
    action: 'copyRecord',
    primaryText: recordTranslations.copyRecord
  }
];

export const BreachRecordList = (props, context) => {
  const {
    locale,
    isEditable,
    isViewable,
    isFetching,
    position,
    items,
    onMount,
    deletePrivacyRecord,
    getNextData,
    updateNote,
    onChooseFilter,
    sortKey,
    sortOrder,
    filterColumn,
    pageSearchText,
    filteredOn,
    copyBreach,
    isUpdating,
    canApprove,
    toggleLoader,
    onRecordSelection,
    intl,
    pricingPlan,
    allowPDFDownload,
    isVendor,
    isPublic,
    recordLayoutId,
    isDeletable,
    isGlobal
  } = props;

  const [recordId, setRecordId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [viewNoteOpen, setViewNoteOpen] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [content, setContent] = useState(undefined);
  const [recordName, setRecordName] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentSelectedRecords, setCurrentSelectedRecords] = useState(
    Immutable.List()
  );
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [isInSync, setIsInSync] = useState(false);

  useEffect(() => {
    if (onMount) {
      onMount(recordLayoutId);
    }
  }, []);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, 1, recordLayoutId); // rowCount=1
      setIsDelete(false);
    }

    toggleLoader(isUpdating || isFetching);
  }, [items.size, isUpdating, isFetching]);

  const handleDeleteRecord = () => {
    setIsDelete(true);
    deletePrivacyRecord(recordId);
    handleClose();
  };

  const handleRowClick = (e, index) => {
    if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      const { id } = items.get(index);
      window.open(`${location}/#/breach/${id}/view`, '_blank');
    } else if (isViewable) {
      const item = items.get(index);
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const location =
        urlParams.from === 'overview' ? 'overview' : 'privacyrecords';
      context.router.history.push(`/breach/${item.id}/view?from=${location}`);
    }
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      items.size >= RECORDS_FETCH_LIMIT &&
      40 * (items.size - 1) - getContainerHeight() <= scrollY
    ) {
      getNextData(position, 50, recordLayoutId);
    }
    setIsDelete(false);
  };

  const handleRecordActions = (actionButton, index, action) => {
    const { id: recordId, name, note } = items.get(index);
    if (action === 'edit') {
      handleEditRecordAction(index);
    } else if (action === 'delete') {
      handleDeleteRecordAction(index);
    } else if (action === 'graphicalView') {
      context.router.history.push(`/breach/${recordId}/graph`);
    } else if (action === 'changelog') {
      context.router.history.push(`/breach/${recordId}/revisions`);
    } else if (action === 'addNote') {
      handleAddNoteOpen(recordId, name);
    } else if (action === 'editNote') {
      handleEditNoteOpen(recordId, name, note);
    } else if (action === 'viewNote') {
      handleViewNoteOpen(name, note);
    } else if (action === 'removeNote') {
      handleDeleteNoteOpen(recordId, name);
    } else if (action === 'copyRecord') {
      handleCopyRecord(name, recordId);
    }
  };

  const onCheckHandler = (event, item) => {
    const selectedIndex = currentSelectedRecords.findIndex(
      (el) => el.id === item.id
    );
    const selectedRecords = [...currentSelectedRecords];
    if (event.target.checked) {
      selectedRecords.push({ id: item.id });
    } else {
      selectedRecords.splice(selectedIndex, 1);
    }
    setCurrentSelectedRecords(selectedRecords);
    onRecordSelection(selectedRecords);
  };

  const onBulkExportReset = (event) => {
    if (!event.target.checked) {
      setCurrentSelectedRecords([]);
      onRecordSelection([]);
    }
  };

  const handleEditRecordAction = (index) => {
    const hashURL = window.location.hash;
    const urlParams = getQueryStrings(hashURL);
    const { id, status, syncInfo } = items.get(index);

    if (
      status === 'record_status_requested' ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else if (isVendor) {
      const location =
        window.location.origin ||
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`;
      window.open(`${location}/#/breach/${id}/edit`, '_blank');
    } else if (urlParams.from === 'overview') {
      context.router.history.push(`/breach/${id}/edit?from=overview`);
    } else {
      context.router.history.push(`/breach/${id}/edit`);
    }
  };

  const handleDeleteRecordAction = (index) => {
    const { id, name, status, syncInfo } = items.get(index);
    if (
      (!canApprove && status === 'record_status_requested') ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else {
      handleOpen(id, name);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const handleCopyRecord = (name, recordId) => {
    const copyOfName = {
      name: intl.formatMessage(customTranslation('copyOfName').props, {
        value: name
      })
    };
    copyBreach(copyOfName, recordId, isGlobal);
  };

  const handleAddNoteOpen = (recordId, recordName) => {
    setAddNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleEditNoteOpen = (recordId, recordName, content) => {
    setEditNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
    setContent(content);
  };

  const handleViewNoteOpen = (recordName, content) => {
    setViewNoteOpen(true);
    setRecordName(recordName);
    setContent(content);
  };

  const handleViewNote = (evt, rowIndex) => {
    evt.stopPropagation();
    const { name, note } = items.get(rowIndex);
    handleViewNoteOpen(name, note);
  };

  const handleDeleteNoteOpen = (recordId, recordName) => {
    setDeleteNoteOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleEditNote = (event, note) => {
    const noteContent = note || content;
    if (
      recordId !== -1 &&
      (((addNoteOpen || editNoteOpen) && noteContent) || deleteNoteOpen)
    ) {
      updateNote(
        recordId,
        'note',
        noteContent.trim(),
        recordName,
        recordLayoutId
      );
    }
    requestNoteClose();
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const requestNoteClose = () => {
    setEditNoteOpen(false);
    setViewNoteOpen(false);
    setAddNoteOpen(false);
    setDeleteNoteOpen(false);
    setRecordId(-1);
    setContent(undefined);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter({ ...filterParams, filterKey }, recordLayoutId);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordId(-1);
  };

  const handleOpen = (recordId, recordName) => {
    setOpen(true);
    setRecordId(recordId);
    setRecordName(recordName);
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleMenuHidden = (menu, rowIndex, action) =>
    shouldHideMenuItem(
      action,
      rowIndex,
      items,
      isEditable,
      isViewable,
      isVendor
    );

  const modifiedAction = getModifiedActions(pricingPlan, actionItems);

  const vendorNoContentStyle = {
    ...styles.tableNoContentStyle,
    top: 300,
    left: '45%'
  };

  const breachFilterStatus = recordStatusFilterValues.filter(
    (item) => !filterItem?.dependant.includes(item.value)
  );

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div
          style={isVendor ? vendorNoContentStyle : styles.tableNoContentStyle}
        >
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="breach_responsive_table"
        showActionSelector={!isPublic}
        actionChooserStyle={styles.actionChooserStyle}
        rowHeight={40}
        headerHeight={45}
        rowsCount={items.size}
        onRowClick={handleRowClick}
        actionItems={modifiedAction}
        onActionHandler={handleRecordActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        height={isVendor ? window.innerHeight - 200 : window.innerHeight - 230}
        // isVendor (200) = headerHeight: 80 + tabHeight: 50 + tableHeader: 50 + whiteSpace: 20
        // recordsOverview (230) = navbar: 100 + messageBanner: 50 + header: 65 + whiteSpace: 15
        {...props}
      >
        <Column
          columnKey="bulkExport"
          visible={allowPDFDownload && !isVendor}
          header={
            <SortHeaderCell
              showFilterIcon={false}
              showBulkCheck={true}
              checkedItemsSize={currentSelectedRecords.length}
              onBulkReset={onBulkExportReset}
              enableSorting={false}
              tooltip={false}
            />
          }
          cell={
            <CheckBoxCell
              items={items}
              selectedRecordSize={currentSelectedRecords.length}
              selectedRecords={currentSelectedRecords}
              onCheck={onCheckHandler}
            />
          }
          width={50}
        />
        <Column
          id="privacyRecordNumber"
          columnKey="privacyRecordNumber"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('privacyRecordNumber')}
              onChooseFilter={filterData}
              pageSearchStyle={{ width: '100px' }}
            >
              {recordTranslations.recordId}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          width={160}
        />
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('name')}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={2}
          width={100}
        />
        <Column
          id="status"
          columnKey="status"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              enableFilter={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filteredOn={filteredOn}
              filterColumn={filterColumn && filterColumn.status}
              filterValues={breachFilterStatus}
              onChooseFilter={filterData}
            >
              {' '}
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} mapper={statusValueMapper} />}
          width={190}
        />
        <Column
          id="lastChanged"
          columnKey="lastChanged"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              {' '}
              {recordTranslations.lastChanged}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD MMM YYYY" items={items} locale={locale} />
          }
          width={180}
          visible={!isPublic}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          visible={isEditable || isDeletable}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditRecordAction}
              handleDelete={handleDeleteRecordAction}
              isEditHidden={!isEditable}
              isDeleteHidden={!isDeletable || isVendor}
            />
          }
          width={80}
        />
        <Column
          id="note"
          columnKey="note"
          align="center"
          cell={
            <InformationCell
              items={items}
              handleOnClick={handleViewNote}
              className="informationClass"
            />
          }
          width={30}
        />
      </ResponsiveTableWrapper>
      <CustomDialog
        title={commonTranslations.deletePrivacyRecord}
        id="delete_record_dialog"
        show={open}
        proceed={handleDeleteRecord}
        cancel={handleClose}
        content={deleteRecord(recordName)}
      />
      {(editNoteOpen || addNoteOpen) && (
        <Note
          id="edit-note"
          content={addNoteOpen ? null : content}
          onChange={handleChange}
          isEdit={true}
          headerLabel={
            addNoteOpen
              ? commonTranslations.addNoteHeader
              : commonTranslations.editNoteHeader
          }
          entityName={recordName}
          open={editNoteOpen || addNoteOpen}
          handleEditNote={handleEditNote}
          close={requestNoteClose}
        />
      )}
      {viewNoteOpen && (
        <Note
          id="view-note"
          content={content}
          isEdit={false}
          headerLabel={commonTranslations.viewNoteHeader}
          entityName={recordName}
          open={viewNoteOpen}
          close={requestNoteClose}
        />
      )}
      <CustomDialog
        id="delete_note_dialog"
        show={deleteNoteOpen}
        proceed={(e) => handleEditNote(e, '')}
        cancel={requestNoteClose}
        content={recordTranslations.deleteNote}
      />
      <NotificationDialogBox
        open={openNotification}
        cancel={handleCloseNotification}
        message={isInSync ? recordTranslations.itemIsInSync : ''}
      />
    </div>
  );
};

BreachRecordList.displayName = 'BreachRecordList';

BreachRecordList.propTypes = {
  locale: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  position: PropTypes.number,
  items: PropTypes.instanceOf(Immutable.List),
  isFetching: PropTypes.bool,
  onMount: PropTypes.func,
  deletePrivacyRecord: PropTypes.func,
  getNextData: PropTypes.func,
  updateNote: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  filterColumn: PropTypes.objectOf(PropTypes.string),
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  copyBreach: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  canApprove: PropTypes.bool,
  toggleLoader: PropTypes.func,
  onRecordSelection: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  allowPDFDownload: PropTypes.bool,
  isVendor: PropTypes.bool,
  isPublic: PropTypes.bool,
  recordLayoutId: PropTypes.string,
  isDeletable: PropTypes.bool,
  isGlobal: PropTypes.bool
};

BreachRecordList.defaultProps = {
  isFetching: false,
  isEditable: false,
  isViewable: false,
  position: 0,
  items: Immutable.List(),
  onMount: (e) => e,
  updateNote: (e) => e,
  deletePrivacyRecord: (e) => e,
  getNextData: (e) => e,
  onChooseFilter: (e) => e,
  onSearch: (e) => e,
  pageSearchText: Immutable.Map(),
  filteredOn: {},
  filterColumn: {},
  sortKey: '',
  sortOrder: '',
  isUpdating: false,
  canApprove: false,
  toggleLoader: (e) => e,
  onRecordSelection: (e) => e,
  selectedRecords: [],
  pricingPlan: Immutable.Map(),
  allowPDFDownload: true,
  isVendor: false,
  isPublic: false,
  recordLayoutId: '',
  isDeletable: false,
  isGlobal: false
};

BreachRecordList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(BreachRecordList);
