import Immutable from 'immutable';

export const getSearchItems = ({ searchKey, items }) => {
  const listItem = items && Array.from(new Set(items.map(item => (typeof item === 'string' ? item : item[searchKey]))));
  const searchItemObj = {};
  // array.filter used to remove any undefined that might arise when the
  // searckey is not present for some optional objects.
  // for e.g, organisation is an optional parameter for datasource.
  searchItemObj[searchKey] = listItem && listItem.filter(item => item);
  return searchItemObj && Immutable.Map(searchItemObj);
};

export const getFilteredOn = ({ filterKey, filteredOn, filterObj }) => {
  let filteredOnObj = filterObj || Immutable.Map({});
  if (filterKey && filteredOn) {
    filteredOnObj = filteredOnObj.set(filterKey, filteredOn);
  }
  return Immutable.Map(filteredOnObj);
};

export const getSearchText = ({ searchKey, searchText, searchObj }) => {
  // console.log('here getSearchText');
  const searchKeyObj = searchObj || {};
  searchKeyObj[searchKey] = searchText;
  // console.log(searchKeyObj);
  return Immutable.Map(searchKeyObj);
};

export const resetSearchText = (searchText, { filterKey }) => {
  if (searchText && searchText.get(filterKey)) {
    return searchText;
  }
  return Immutable.Map();
};

export const resetSearchItems = (searchItems, { filterKey }) => {
  if (searchItems && searchItems.get(filterKey)) {
    return searchItems;
  }
  return Immutable.Map();
};

export const removeDuplicates = (newItems, existingCollection = [], nextCollection) => {
  if (newItems && newItems.size > 0) {
    const modifiedNewCollection = nextCollection.filter(nextItem =>
      newItems.findIndex(newItem => ((newItem.value ? newItem.value.id : newItem.id) === nextItem.id)) === -1);
    const modifiedExistingCollection = existingCollection.filter(existItem =>
      newItems.findIndex(newItem => ((newItem.value ? newItem.value.id : newItem.id) === existItem.id)) === -1);
    return Immutable.List([...newItems, ...modifiedExistingCollection, ...modifiedNewCollection]);
  }
  return Immutable.List([...newItems, ...existingCollection, ...nextCollection]);
};
