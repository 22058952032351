import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';
import errortranslations from '@packages/utils/errortranslations';

export function* fetchAttachments() {
  yield put({ type: 'ATTACHMENT:LIST:REQUEST' });

  try {
    const response = yield registry.get('request')
      .get('/v1/documents?offset=0numberOfResults=15', null);
    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          id: item.id,
          name: item.name,
          key: `${item.name}`
        }));

        yield put({ type: 'ATTACHMENT:LIST:REQUEST:SUCCESS', items: modifiedItems });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'ATTACHMENT:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* downloadAttachments(action) {
  const request = registry.get('request');
  try {
    const attachment = action.data.value || action.data;
    let response;
    const config = registry.get('config');
    if (action.isVendor) {
      response = yield request.getDocument(`/v1/documents/${attachment.id}/download`,
        {
          headers: {
            [config.login.vendorToken.httpHeader]: registry.get('storage')
              .getItem(config.login.vendorToken.storage.key)
          }
        });
    } else if (action.isVendorAdmin) {
      response = yield request.getDocument(`/v1/documents/${attachment.id}/download?vendorFormId=${action.data.formId}`);
    } else {
      response = yield request.getDocument(`/v1/documents/records/${attachment.id}/download`);
    }

    yield response;
    switch (response.status) {
      case 200: {
        yield put({ type: 'ATTACHMENT:DOWNLOAD:SUCCESS', attachmentData: response.body });
        break;
      }
      case 404: {
        const error = errortranslations.attachmentUnavailable;
        yield put({ type: 'ATTACHMENT:DOWNLOAD:FAIL', error });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: errortranslations.attachmentUnavailable,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'ATTACHMENT:DOWNLOAD:FAIL', error: err.message });
  }
}

export function* searchAttachmentSelector(action) {
  const { searchParams } = action;
  const { searchKey, searchText } = searchParams;

  yield put({ type: 'ATTACHMENT:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams), null, {});
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map(item => ({
          ...item,
          id: item.id,
          name: item.name,
          key: `${item.name}`
        }));
        yield put({
          type: 'ATTACHMENT:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    registry.get('logger').error(err);
    yield put({ type: 'ATTACHMENT:LIST:SEARCH:FAIL', error: err.message });
  }
}
const getURLWithParams = (searchParams) => {
  let url = `/v1/documents?numberOfResults=15`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=name=${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
