import { combineReducers } from 'redux';

import references from './references';
import mitigatingMeasures from './mitigatingMeasures';
import qualityControls from './qualitycontrols';
import dataProtectionMeasures from './dataProtectionMeasures';
import accountabilityMeasures from './accountabilityMeasures';
import policies from './policies';
import purposes from './purposes';
import purposesOfTransfer from './purposesOfTransfer';
import findings from './findings';
import decisions from './decisions';
import riskDetails from './riskDetails';
import informationRights from './informationRights';
import consentRights from './consentRights';
import accessRights from './accessRights';
import objectionRights from './objectionRights';
import deletionRights from './deletionRights';
import dataPortabilityRights from './dataPortabilityRights';
import confidentialityThreats from './confidentialityThreats';
import confidentialityImpacts from './confidentialityImpacts';
import integrityThreats from './integrityThreats';
import integrityImpacts from './integrityImpacts';
import availabilityThreats from './availabilityThreats';
import availabilityImpacts from './availabilityImpacts';
import technicalSecurityMeasures from './technicalSecurityMeasures';
import organisationalSecurityMeasures from './organisationalSecurityMeasures';
import specialSecurityMeasures from './specialSecurityMeasures';
import contractualSecurityMeasures from './contractualSecurityMeasures';
import fairnessOfDecisions from './fairnessOfDecision';
import securityMeasures from './securityMeasures';
import tags from './tags';
import dataSourceCategories from './dataSourceCategories';
import documentRecordTypes from './documentRecordTypes';
import specialCharacteristics from './specialCharacteristics';

export default combineReducers({
  references,
  qualityControls,
  dataProtectionMeasures,
  accountabilityMeasures,
  policies,
  purposes,
  purposesOfTransfer,
  mitigatingMeasures,
  findings,
  decisions,
  riskDetails,
  informationRights,
  consentRights,
  accessRights,
  objectionRights,
  deletionRights,
  dataPortabilityRights,
  confidentialityThreats,
  confidentialityImpacts,
  integrityThreats,
  integrityImpacts,
  availabilityThreats,
  availabilityImpacts,
  technicalSecurityMeasures,
  organisationalSecurityMeasures,
  specialSecurityMeasures,
  contractualSecurityMeasures,
  securityMeasures,
  tags,
  dataSourceCategories,
  fairnessOfDecisions,
  documentRecordTypes,
  specialCharacteristics
});
