import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown, GENERAL_FETCH_LIMIT } from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';

export function* filterApiTokenList(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  yield put({ type: `API:TOKEN:LIST:FILTER`, filterParams });
  yield fetchApiTokenList(action);
}

export function* fetchApiTokenList(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  yield put({ type: 'API:TOKEN:LIST:FETCH' });
  try {
    const tokenState = yield select(state => state.apiTokens);
    const filterParams = tokenState ? tokenState.get('filterParams').toJS() : {};
    const { sortOn, sortOrder } = filterParams;
    const response = yield registry.get('request').get(getURLWithParams({ sortOn, sortOrder },
      position, rowCount), null, {});
    switch (response.status) {
      case 200: {
        yield put({ type: 'API:TOKEN:LIST:FETCH:SUCCESS', data: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* deleteApiToken(action) {
  const { itemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/users/tokens/${itemId}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.apiTokenDeleted,
            type: 'success'
          }
        });
        const apiTokenList = yield select(state => state.apiTokens.get('apiTokenList'));
        const data = updateItems(apiTokenList, response.body);
        yield put({ type: `API:TOKEN:LIST:UPDATE`, data });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

const updateItems = (apiTokenList, id) => {
  const index = apiTokenList.findIndex(item => item.id === id);
  const data = apiTokenList.remove(index);
  return data;
};

export function* validatePassword(action) {
  let userName = '';
  const loggedUserState = yield select(state => state.login);
  if (loggedUserState) {
    userName = loggedUserState.get('loggedUser').get('username');
  }
  yield put({ type: 'TOKENS:PASSWORD:VALIDATION:INIT' });
  try {
    const response = yield registry.get('request')
      .post('/v1/identity/verify', {
        email: userName, password: action.password
      });
    switch (response.status) {
      case 200:
        yield put({ type: 'TOKENS:PASSWORD:VALIDATION:SUCCESS', passwordValidated: response.body });
        break;
      default: yield put({ type: 'TOKENS:PASSWORD:VALIDATION:FAIL', error: response.body.content });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    registry.get('logger').error(err);
    yield put({ type: 'TOKENS:PASSWORD:VALIDATION:FAIL', error: err.message });
  }
}

export function* updateToken(action) {
  const { data, isRegenerate, isEdit } = action;
  const request = registry.get('request');
  let response;
  try {
    if (isRegenerate) response = yield request.put(`/v1/users/tokens/${data.id}/regenerate`, data);
    else if (isEdit) response = yield request.put(`/v1/users/tokens/${data.id}`, data);
    else response = yield request.post(`/v1/users/tokens`, data);
    switch (response.status) {
      case 200:
        if (isEdit && !isRegenerate) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.apiTokenUpdated,
              type: 'success'
            }
          });
          yield updateTokenItems({ data: response.body });
        } else {
          yield put({ type: 'API:TOKEN:GENERATION:SUCCESS', tokenData: response.body });
          yield fetchApiTokenList({});
        }
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    registry.get('logger').error(err);
    yield put({ type: 'API:TOKEN:GENERATION:FAIL', error: err.message });
  }
}

const getURLWithParams = (filterParams, position, rowCount) => {
  let url = `/v1/users/tokens?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { sortOn, sortOrder } = filterParams;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
function* updateTokenItems(action) {
  const { data } = action;
  const apiTokenList = yield select(state => state.apiTokens.get('apiTokenList'));
  const items = apiTokenList || [];
  const index = items.findIndex(item => item.id === data.id);
  const updatedItem = items && items.set(index, data);
  yield put({ type: `API:TOKEN:LIST:UPDATE`, data: updatedItem });
}

