import { connect } from 'react-redux';
import DataSubjectCategorySelector from './component';

const mapStateToProps = state => ({
  dataSubjectCategories: state.environment.dataSubjectCategories.get('items'),
  error: state.dataSubjectCategories.get('error'),
  searchResults: state.dataSubjectCategories.get('items'),
  position: state.environment.dataSubjectCategories.get('position')
});

const mapDispatchToProps = dispatch => ({
  initDataSubjectCategories: () => {
    dispatch({ type: 'DATA_SUBJECT_CATEGORIES:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'DATA_SUBJECT_CATEGORY_SELECTOR:LIST:SEARCH_INIT', searchParams });
  },
  getNextData: (position) => {
    dispatch({ type: 'DATA_SUBJECT_CATEGORIES:LIST:NEXT_FETCH', position });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSubjectCategorySelector);
