import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  privacyRecords: [],
  openRecordActionDialog: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY_RECORDS:HIERARCHY:FETCH:SUCCESS':
      return state.set('privacyRecords', action.data);
    case 'PRIVACY_RECORDS:HIERARCHY:FETCH:FAIL':
      return state.set('privacyRecords', defaultState.privacyRecords);
    case 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE':
      return state.set(
        'openRecordActionDialog',
        !state.get('openRecordActionDialog')
      );
    default:
      return state;
  }
};
