import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import ProcessingCategoryDialog from '@packages/features/environment/components/processing-categories/components/processingCategoryDialog';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

class ProcessingCategorySelector extends React.Component {
  static filterProcessingCategories(
    selectedProcessingCategories,
    searchedItems
  ) {
    const filteredData = searchedItems.filter((processingCategory) => {
      const index = selectedProcessingCategories.findIndex(
        (selectedProcessingCategory) =>
          selectedProcessingCategory.value.name === processingCategory.name
      );
      return index === -1;
    });
    return filteredData;
  }

  static handleGetMultipleItems(newFields, items) {
    const selectedProcessingCategories = newFields.getAll();
    const multipleProcessingCategoryList =
      selectedProcessingCategories && selectedProcessingCategories.length > 0
        ? ProcessingCategorySelector.filterProcessingCategories(
          selectedProcessingCategories,
          items
        )
        : items;
    return multipleProcessingCategoryList;
  }

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      open: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      processingCategoryList: props.processingCategories,
      multipleProcessingCategoryList: [],
      tagSearchResult: '',
      isFetch: true
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleListItems = this.handleListItems.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    if (this.props.initProcessingCategories) {
      this.props.initProcessingCategories(this.props.isGlobal);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.processingCategories !== nextProps.processingCategories ||
      nextProps.searchResults !== this.props.searchResults
    ) {
      const itemList = this.handleListItems(
        nextProps.searchResults,
        nextProps.processingCategories,
        nextProps.fields,
        nextProps.position
      );
      const { processingCategoryList, multipleProcessingCategoryList } =
        itemList;
      this.setState({
        processingCategoryList,
        multipleProcessingCategoryList
      });
    }
    if (this.props.tagSearchResult !== nextProps.tagSearchResult) {
      this.setState({ tagSearchResult: nextProps.tagSearchResult });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(this.props.processingCategories === nextProps.processingCategories) ||
      !(this.props.disabled === nextProps.disabled)
    );
  }

  handleListItems(searchResults, processingCategories, fields, position) {
    const { searchText } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : processingCategories;
    const processingCategoryList =
      ProcessingCategorySelector.handleGetMultipleItems(fields, searchedItems);
    const multipleProcessingCategoryList =
      ProcessingCategorySelector.handleGetMultipleItems(
        fields,
        processingCategories
      );
    if (
      (multipleProcessingCategoryList.size < 15 ||
        processingCategoryList.size < 15) &&
      processingCategories.size > 0 &&
      this.state.searchText === '' &&
      this.props.position !== position &&
      this.state.isFetch
    ) {
      this.props.getNextData(position);
    }
    const itemList = { processingCategoryList, multipleProcessingCategoryList };
    return itemList;
  }

  handleAddClick(searchText) {
    this.setState({
      open: true,
      isEdit: false,
      multiple: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText
      }
    });
  }

  handleEditClick(index) {
    this.setState({
      open: true,
      isEdit: true,
      multiple: false,
      selectedIndex: index,
      inputValue: this.props.fields.get(index).value
    });
  }

  handleMultipleSelect() {
    this.setState({
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: ''
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedProcessingCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedProcessingCategories.splice(selectedIndex, 1);
    }
    this.updateProcessingCategories(selectedProcessingCategories);
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const selectedProcessingCategories = [...items, ...selectedItems];
    this.updateProcessingCategories(selectedProcessingCategories);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    const selectedProcessingCategories = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedProcessingCategories.push(selectedItem);
    } else {
      selectedProcessingCategories.splice(selectedIndex, 1);
      selectedProcessingCategories.splice(selectedIndex, 0, selectedItem);
    }
    this.updateProcessingCategories(selectedProcessingCategories);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  handleFilter(selectedItem, enableGetData) {
    const value = selectedItem ? [selectedItem.id] : [];
    this.props.onChooseFilter(
      Object.assign({}, { filterKey: 'tags', filteredOn: value })
    );
    this.setState({ isFetch: enableGetData });
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.dataItemType);
  }

  updateProcessingCategories(selectedProcessingCategories) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true, searchText: '' }); // eslint-disable-line react/no-unused-state
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedProcessingCategories);
    } else {
      this.props.updateProcessingCategories(selectedProcessingCategories);
    }
  }

  render() {
    const { tags, userPermissions, disabled } = this.props;
    const selectedProcessingCategories = this.props.fields.getAll();

    const { createEditProcessingCategories } = userPermissions.toJS();

    return (
      <div>
        {selectedProcessingCategories &&
          selectedProcessingCategories.length > 0 && (
            <ItemList
              id="item_list"
              isEditable={createEditProcessingCategories}
              {...this.props}
              type={this.props.label}
              handleRemoveItem={this.handleRemoveItem}
              handleEditClick={this.handleEditClick}
              handleEditItem={this.handleEditItem}
              selectedItems={selectedProcessingCategories}
              handleUsageClick={this.handleUsageClick}
            />
        )}
        <ItemSelector
          id="processingCategories_selector"
          multiValue={this.props.multiValue}
          createNewMenuItem={
            createEditProcessingCategories && this.props.createNewMenuItem
          }
          selectFromListMenuItem={this.props.selectFromListMenuItem || false}
          selectedItem={this.props.selectedItems}
          dataSource={this.state.processingCategoryList}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={this.props.hintTextLabel}
          handleSelectedItem={this.handleSelectedItem}
          handleAddClick={this.handleAddClick}
          handleMultipleSelect={this.handleMultipleSelect}
          onSearch={this.handleSearch}
          disabled={disabled}
        />
        {this.state.open && (
          <ProcessingCategoryDialog
            open={this.state.open}
            isEdit={this.state.isEdit}
            dataItemId={this.state.inputValue && this.state.inputValue.id}
            inputValue={this.state.inputValue}
            selectedItems={selectedProcessingCategories}
            onRequestClose={this.handleRequestClose}
            handleSelectedItem={this.handleSelectedItem}
            source="records"
            hideTags={this.props.isGlobal}
          />
        )}
        {this.state.multiple && (
          <MultipleSelectorDialog
            id="multiple_selector"
            title={
              <FormattedMessage
                id="MultipleProcessingCategoryDialog.header"
                description="Select Processing categories dialog header"
                defaultMessage="Select processing categories"
              />
            }
            open={this.state.multiple}
            onScrollStop={this.handleScrollEnd}
            filteredData={this.state.multipleProcessingCategoryList}
            onRequestClose={this.handleRequestClose}
            handleMultipleItems={this.handleMultipleItems}
            filterTagValue={tags}
            onChooseFilter={this.handleFilter}
            showFilterByTag={!this.props.isGlobal}
            tagSearch={this.props.tagSearch}
            tagSearchResult={this.state.tagSearchResult}
            getNextTags={this.props.getNextTags}
            tagsPosition={this.props.tagsPosition}
          />
        )}
      </div>
    );
  }
}

ProcessingCategorySelector.propTypes = {
  multiValue: PropTypes.bool,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  initProcessingCategories: PropTypes.func,
  updateProcessingCategories: PropTypes.func,
  processingCategories: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  onChooseFilter: PropTypes.func,
  tags: PropTypes.instanceOf(Immutable.List),
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagSearch: PropTypes.func,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  disabled: PropTypes.bool
};

ProcessingCategorySelector.defaultProps = {
  multiValue: true,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  label: null,
  selectedItems: null,
  getNextData: (e) => e,
  position: 0,
  hintTextLabel: null,
  initProcessingCategories: null,
  updateProcessingCategories: null,
  processingCategories: Immutable.List(),
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  handleUsageClick: (e) => e,
  dataItemType: '',
  tags: Immutable.List(),
  onChooseFilter: (e) => e,
  entityType: '',
  masterDataType: '',
  tagSearchResult: Immutable.List(),
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  userPermissions: Immutable.Map(),
  disabled: false
};

export default ProcessingCategorySelector;
