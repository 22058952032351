import { takeEvery } from 'redux-saga/effects';

import searchPersonalDataItemSelector from './components/personal-data-item-selector/sagas';
import {
  resetAndfetchProcessingGrounds,
  searchProcessingGrounds,
  filterProcessingGrounds
} from './components/processing-grounds/sagas';
import {
  resetAndfetchTransferGrounds,
  searchTransferGrounds,
  filterTransferGrounds
} from './components/transfer-grounds/sagas';
import {
  resetAndfetchBreachGrounds,
  searchBreachGrounds,
  filterBreachGrounds
} from './components/breach-grounds/sagas';
import searchUsersSelector from './components/access-rights-selector/sagas';
import fetchSupervisoryAuthorities from './components/supervisory-authority-selector/sagas';
import searchDatasourcesSelector from './components/datasource-selector/sagas';
import searchDataSubjectCategorySelector from './components/datasubject-category-selector/sagas';
import searchProcessingCategoriesSelector from './components/processing-category-selector/sagas';
import {
  resetAndfetchRecordRetentionTerms,
  searchRecordRetentionOffsets
} from './components/retention-term-selector/sagas';
import searchSimpleEntityList from './components/simple-entity-selector/sagas';
import searchPersonalItemCategory from './components/personal-data-category-selector/sagas';
import {
  fetchAttachments,
  downloadAttachments,
  searchAttachmentSelector
} from './components/attachment-selector/sagas';
import {
  initializeProcessingDetail,
  upsertProcessingDetail,
  updateProcessingProperty,
  upsertProcessingRecordDetail,
  saveProcessingComment,
  createLinkGroup,
  updateLinkGroup,
  fetchUsersForProcessing,
  deleteProcessingComment
} from './processings/sagas';

import {
  initializeAssessmentDetail,
  upsertAssessmentDetail,
  fetchUsersForAssessment,
  upsertAssessmentRecordDetail,
  createAssessmentLinkGroup,
  saveAssessmentComment,
  deleteAssessmentComment,
  updateAssessmentLinkGroup
} from './assessments/sagas';
import {
  initializeBreachDetail,
  upsertBreachDetail,
  updateBreachProperty,
  upsertBreachRecordDetail,
  fetchUsersForBreach,
  saveBreachComment,
  deleteBreachComment
} from './breaches/sagas';
import resetAndfetchRecordRevisions from '../change-log/sagas';
import {
  resetAndfetchLegalEntities,
  fetchLegalEntities,
  filterLegalEntities,
  searchLegalEntities
} from '../entity-selector/sagas';
import initializeProcessingDiff from './processings/diff/sagas';
import initializeAssessmentDiff from './assessments/diff/sagas';
import initializeBreachDiff from './breaches/diff/sagas';
import initializeTiaDiff from './tia/diff/sagas';
import initializeDocumentDiff from './documents/diff/sagas';
import {
  initializeTiaDetail,
  fetchCountryAnalysis,
  updateTiaStatus,
  upsertTiaDetail,
  upsertTiaRecordDetail,
  deleteTiaComment,
  saveTiaComment,
  fetchUsersForTia,
  saveTiaAsTemplate
} from './tia/sagas';
import {
  pdfExport,
  fetchHelpNoteValue,
  fetchLayout,
  getPrivacyRecordsHierarchy,
  recordActionWorker
} from './sagas';
import {
  deleteDocumentComment,
  deleteDocumentFileContent,
  fetchUsersForDocument,
  getLinkedOrganisations,
  initializeDocumentDetail,
  saveDocumentComment,
  updateDocumentStatus,
  upsertDocumentDetail,
  upsertDocumentItem,
  upsertDocumentRecordDetail
} from './documents/sagas';
import {
  fetchRecords,
  searchRecords
} from './components/record-selector/sagas';
import { checkRecordUsage, privacyRecordBulkUpdate } from '../privacy-records';
import { searchTags } from './components/tag-selector/sagas';
import {
  checkboxOptionsFetch,
  customRecordDataWorker,
  fetchUsersForCustomRecord,
  updateCustomComment
} from './custom/sagas';
import { fetchLayoutRecords } from './components/linked-records/components/records-selector/sagas';

export default [
  // Item Selectors
  [
    takeEvery,
    'PERSONAL_ITEM_CATEGORY:LIST:SEARCH_INIT',
    searchPersonalItemCategory
  ],
  // Processing Detail
  [takeEvery, 'PROCESSING:DETAIL:INIT', initializeProcessingDetail],
  [takeEvery, 'PROCESSING:DETAIL:UPSERT', upsertProcessingDetail],
  [takeEvery, 'PROCESSING:PROPERTY:UPDATE', updateProcessingProperty],
  [takeEvery, 'PROCESSING:RECORD:DETAILS:UPSERT', upsertProcessingRecordDetail],
  [takeEvery, 'PROCESSING:DETAIL:COMMENT:SAVE', saveProcessingComment],
  [takeEvery, 'PROCESSING:USERS:FETCH_INIT', fetchUsersForProcessing],
  [takeEvery, 'PROCESSING:DETAIL:COMMENT:DELETE', deleteProcessingComment],
  [takeEvery, 'PROCESSING:LINK_GROUP:SAVE', createLinkGroup],
  [takeEvery, 'PROCESSING:LINK_GROUP:UPDATE', updateLinkGroup],

  // Access rights
  [takeEvery, 'ACCESS_RIGHTS:LIST:SEARCH_INIT', searchUsersSelector],
  // Simple entity selector
  [takeEvery, 'SIMPLE_ENTITY:LIST:SEARCH_INIT', searchSimpleEntityList],
  // Supervisory SUPERVISORY_AUTHORITIES
  [
    takeEvery,
    'SUPERVISORY_AUTHORITIES:LIST:REQUEST',
    fetchSupervisoryAuthorities
  ],

  // Assessment Detail
  [takeEvery, 'ASSESSMENT:DETAIL:INIT', initializeAssessmentDetail],
  [takeEvery, 'ASSESSMENT:DETAIL:UPSERT', upsertAssessmentDetail],
  [takeEvery, 'ASSESSMENT:RECORD:DETAILS:UPSERT', upsertAssessmentRecordDetail],
  [takeEvery, 'ASSESSMENT:LINK_GROUP:SAVE', createAssessmentLinkGroup],
  [takeEvery, 'ASSESSMENT:LINK_GROUP:UPDATE', updateAssessmentLinkGroup],
  [takeEvery, 'ASSESSMENT:DETAIL:COMMENT:SAVE', saveAssessmentComment],
  [takeEvery, 'ASSESSMENT:USERS:FETCH_INIT', fetchUsersForAssessment],
  [takeEvery, 'ASSESSMENT:DETAIL:COMMENT:DELETE', deleteAssessmentComment],

  // Breach Detail
  [takeEvery, 'BREACH:DETAIL:INIT', initializeBreachDetail],
  [takeEvery, 'BREACH:DETAIL:UPSERT', upsertBreachDetail],
  [takeEvery, 'BREACH:PROPERTY:UPDATE', updateBreachProperty],
  [takeEvery, 'BREACH:RECORD:DETAILS:UPSERT', upsertBreachRecordDetail],
  [takeEvery, 'BREACH:DETAIL:COMMENT:SAVE', saveBreachComment],
  [takeEvery, 'BREACH:USERS:FETCH_INIT', fetchUsersForBreach],
  [takeEvery, 'BREACH:DETAIL:COMMENT:DELETE', deleteBreachComment],

  // document Detail
  [takeEvery, 'DOCUMENT:DETAIL:INIT', initializeDocumentDetail],
  [takeEvery, 'DOCUMENT:DETAIL:UPSERT', upsertDocumentDetail],
  [takeEvery, 'DOCUMENT:RECORD:DETAILS:UPSERT', upsertDocumentRecordDetail],
  [takeEvery, 'DOCUMENT:USERS:FETCH_INIT', fetchUsersForDocument],
  [takeEvery, 'DOCUMENT:DETAIL:COMMENT:SAVE', saveDocumentComment],
  [takeEvery, 'DOCUMENT:DETAIL:COMMENT:DELETE', deleteDocumentComment],
  [takeEvery, 'DOCUMENT:RECORD:ATTACHMENT:UPSERT', upsertDocumentItem],
  [takeEvery, 'DOCUMENT:RECORD:FILECONTENT:DELETE', deleteDocumentFileContent],
  [takeEvery, 'DOCUMENT:IMPORT:LINKED:ORGANISAIONS', getLinkedOrganisations],
  [takeEvery, 'DOCUMENT:VIEW:STATUS:UPDATE', updateDocumentStatus],

  // custom Detail

  [takeEvery, 'CUSTOM_RECORD:ITEM', customRecordDataWorker],
  [takeEvery, 'CUSTOM_RECORD:CHECKBOX_OPTIONS:FETCH', checkboxOptionsFetch],
  [
    takeEvery,
    ['CUSTOM:DETAIL:COMMENT:SAVE', 'CUSTOM:DETAIL:COMMENT:DELETE'],
    updateCustomComment
  ],
  [takeEvery, 'CUSTOM:USERS:FETCH_INIT', fetchUsersForCustomRecord],

  [takeEvery, 'RECORD_LAYOUT:FETCH_INIT', fetchLayout],

  // Legal Entity
  [takeEvery, 'LEGAL_ENTITY:LIST:FETCH_INIT', resetAndfetchLegalEntities],
  [takeEvery, 'LEGAL_ENTITY:LIST:FETCH:NEXT', fetchLegalEntities],
  [takeEvery, 'LEGAL_ENTITY:LIST:FILTER_INIT', filterLegalEntities],
  [takeEvery, 'LEGAL_ENTITY:LIST:SEARCH_INIT', searchLegalEntities],

  // Record diff view
  [takeEvery, 'PROCESSING:DIFF:INIT', initializeProcessingDiff],
  [takeEvery, 'ASSESSMENT:DIFF:INIT', initializeAssessmentDiff],
  [takeEvery, 'BREACH:DIFF:INIT', initializeBreachDiff],
  [takeEvery, 'TIA:DIFF:INIT', initializeTiaDiff],
  [takeEvery, 'DOCUMENT:DIFF:INIT', initializeDocumentDiff],

  // Record revisions
  [
    takeEvery,
    'RECORD_REVISIONS:LIST:REQUEST_INIT',
    resetAndfetchRecordRevisions
  ],

  // Record retention terms
  [
    takeEvery,
    'RECORDS:RETENTION_TERMS:LIST:REQUEST_INIT',
    resetAndfetchRecordRetentionTerms
  ],
  [
    takeEvery,
    'RECORDS:RETENTION_OFFSETS:LIST:SEARCH_INIT',
    searchRecordRetentionOffsets
  ],
  // Data Sources
  [takeEvery, 'DATA_SOURCES:LIST:SEARCH_INIT', searchDatasourcesSelector],
  // Personal Data Item
  [
    takeEvery,
    'PERSONAL_DATA_ITEMS_SELECTOR:LIST:SEARCH_INIT',
    searchPersonalDataItemSelector
  ],

  // Transfer ground
  [takeEvery, 'TRANSFER_GROUNDS:LIST:INIT', resetAndfetchTransferGrounds],
  [takeEvery, 'TRANSFER_GROUNDS:LIST:SEARCH_INIT', searchTransferGrounds],
  [takeEvery, 'TRANSFER_GROUNDS:LIST:FILTER_INIT', filterTransferGrounds],
  // Processing ground
  [takeEvery, 'PROCESSING_GROUNDS:LIST:INIT', resetAndfetchProcessingGrounds],
  [takeEvery, 'PROCESSING_GROUNDS:LIST:SEARCH_INIT', searchProcessingGrounds],
  [takeEvery, 'PROCESSING_GROUNDS:LIST:FILTER_INIT', filterProcessingGrounds],
  // Breach ground
  [takeEvery, 'BREACH_GROUNDS:LIST:INIT', resetAndfetchBreachGrounds],
  [takeEvery, 'BREACH_GROUNDS:LIST:SEARCH_INIT', searchBreachGrounds],
  [takeEvery, 'BREACH_GROUNDS:LIST:FILTER_INIT', filterBreachGrounds],
  // Processing categories
  [
    takeEvery,
    'PROCESSING_CATEGORY:LIST:SEARCH_INIT',
    searchProcessingCategoriesSelector
  ],
  // Data Subject categories
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORY_SELECTOR:LIST:SEARCH_INIT',
    searchDataSubjectCategorySelector
  ],

  // Attachments
  [takeEvery, 'ATTACHMENT:LIST:INIT', fetchAttachments],
  [takeEvery, 'ATTACHMENT:DOWNLOAD', downloadAttachments],
  [takeEvery, 'ATTACHMENT:LIST:SEARCH_INIT', searchAttachmentSelector],

  // Transfer impact assessment
  [takeEvery, 'TIA:FETCH:COUNTRY:ANALYSIS', fetchCountryAnalysis],
  [takeEvery, 'TIA:DETAIL:INIT', initializeTiaDetail],
  [takeEvery, 'TIA:DETAIL:UPSERT', upsertTiaDetail],
  [takeEvery, 'TIA:VIEW:STATUS:UPDATE', updateTiaStatus],
  [takeEvery, 'TIA:RECORD:DETAILS:UPSERT', upsertTiaRecordDetail],
  [takeEvery, 'TIA:DETAIL:COMMENT:SAVE', saveTiaComment],
  [takeEvery, 'TIA:DETAIL:COMMENT:DELETE', deleteTiaComment],
  [takeEvery, 'TIA:USERS:FETCH_INIT', fetchUsersForTia],
  [takeEvery, 'TIA:DETAIL:TEMPLATE:SAVE', saveTiaAsTemplate],

  [takeEvery, 'RECORD_PDF:DOWNLOAD', pdfExport],
  [takeEvery, 'HELPTEXT:RECORDS:FETCH_INIT', fetchHelpNoteValue],

  // records
  [takeEvery, 'RECORD:LIST:REQUEST', fetchRecords],
  [takeEvery, 'RECORD:SEARCH_INIT', searchRecords],
  [takeEvery, 'RECORD:CHECK:IMPACT', checkRecordUsage],
  [takeEvery, 'TAGS:LIST:SEARCH:INIT', searchTags],

  [takeEvery, 'BULK_UPDATE:JOB:INIT', privacyRecordBulkUpdate],

  [takeEvery, 'PRIVACY_RECORDS:HIERARCHY:FETCH', getPrivacyRecordsHierarchy],
  [takeEvery, 'PRIVACY_RECORDS:ACTION:SUBMIT', recordActionWorker],

  [takeEvery, 'RECORDS:LIST:REQUEST', fetchLayoutRecords]
];
