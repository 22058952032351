import { connect } from 'react-redux';

import PersonalItemCategorySelector from './component';
import sagas from './sagas';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  personalItemCategories: state.environment.personalDataCategories.get('items'),
  tags: state.environment.simpleEntities.tags.get('items'),
  isGlobal: state.home?.get('isGlobal'),
  tagsPosition: state.environment.simpleEntities.tags.get('position'),
  tagSearchResult: state.simpleEntities.get('entitySelectorSearch').get('tags')
    .searchResults,
  error: state.personalItemCategories.get('error'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  searchResults: state.personalItemCategories.get('items'),
  position: state.environment.personalDataCategories.get('position')
});

const mapDispatchToProps = (dispatch) => ({
  init: (isGlobal) => {
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:REQUEST_INIT' });
    if (!isGlobal) {
      dispatch({
        type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
        masterDataType: 'tags'
      });
    }
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PERSONAL_ITEM_CATEGORY:LIST:SEARCH_INIT', searchParams });
  },
  getNextData: (position) => {
    dispatch({
      type: 'PERSONAL_DATA_CATEGORIES:LIST:NEXT_FETCH',
      position,
      isPersistedData: true
    });
  },
  onChooseFilter: (filterParams) => {
    dispatch({
      type: 'PERSONAL_DATA_CATEGORIES:LIST:FILTER_INIT',
      filterParams,
      searchParams: { searchKey: 'name', sortOrder: 'ASC', sortOn: 'name' },
      source: 'records',
      isPersistedData: true
    });
  },
  tagsSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalItemCategorySelector);
export { reducers, sagas };
