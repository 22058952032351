import Immutable from 'immutable';

import filterMenus from './filters';

const defaultState = Immutable.fromJS({
  currentFilter: '',
  filterMenus,
  menuItems: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TENANT:CONFIGURATION:FILTERS:INIT': {
      let newState = state;
      const currentFilter = state.get('currentFilter') || '';
      if (currentFilter === '') {
        newState = newState.set('currentFilter', filterMenus[0].defaultFilter);
      } else {
        newState = newState.set('currentFilter', currentFilter);
      }
      return newState.set('menuItems', filterMenus);
    }
    case 'TENANT:CONFIGURATION:FILTERS:CHANGE': {
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }
    case 'TENANT:CONFIGURATION:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    default:
      return state;
  }
};
