import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { getURLWithParams, updateDataItems } from '../saga-utils';

// TODO: Should move to common-utils.
const addKeyToDataSource = ({ name, organisation, dataStorageCountry }) => {
  if (organisation) {
    return `${name} (${organisation.name} ${organisation.country.id}) ${
      dataStorageCountry ? `(${dataStorageCountry})` : ''
    }`;
  } else {
    return `${name}${dataStorageCountry ? ` (${dataStorageCountry})` : ''}`;
  }
};

export function* resetAndfetchDatasources(action) {
  yield put({ type: 'MASTERDATA:DATASOURCES:LIST:REQUEST:INIT' });
  yield fetchDatasourcesList(action);
}

export function* fetchDatasourcesList(action) {
  yield put({ type: 'MASTERDATA:DATASOURCES:LIST:FETCH' });
  const {
    position = 0,
    rowCount = MASTERDATA_FETCH_LIMIT,
    source = ''
  } = action;

  try {
    let filterParams = {};
    let recordsFilterParams = [];
    let unused = false;
    let filteredOn = Immutable.Map();
    let recordsFilteredOn = Immutable.Map();
    let pageSearchTextObj = {};
    let searchParameters = {};
    if (action.isPersistedData) {
      const currentState = yield select(
        (state) => state.environment.dataSources
      );
      if (currentState) {
        filterParams = currentState.get('filterParams').toJS();
        filteredOn = currentState.get('filteredOn');
        recordsFilterParams = currentState.get('recordsFilterParams').toJS();
        recordsFilteredOn = currentState.get('recordsFilteredOn');
        unused = source !== 'records' && currentState.get('unused');
        pageSearchTextObj = currentState.get('pageSearchText').toJS();
        searchParameters =
          pageSearchTextObj && source !== 'records'
            ? { searchKey: 'name', searchText: pageSearchTextObj.name }
            : {};
      }
    }
    const { sortOn, sortOrder } =
      source === 'records' ? recordsFilterParams : filterParams;

    const response = yield registry.get('request').get(
      getURLWithParams(
        {
          sortOn,
          sortOrder,
          filteredOn: source === 'records' ? recordsFilteredOn : filteredOn
        },
        position,
        searchParameters,
        'datasources',
        rowCount,
        undefined,
        unused
      ),
      null,
      {}
    );

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: addKeyToDataSource(item),
          organisation: transformDataSourceOrgItem(item.organisation),
          organisationData: item.organisation,
          dataSourceCategoryNames: transformDataSourceCategories(
            item.dataSourceCategories
          )
        }));
        const modifiedDataSourceData = action.dataSourceData && {
          ...action.dataSourceData,
          dataSourceCategoryNames: transformDataSourceCategories(
            action.dataSourceData.dataSourceCategories
          )
        };
        yield put({
          type: 'MASTERDATA:DATASOURCES:LIST:FETCH:SUCCESS',
          items: modifiedItems,
          modifiedDataSourceData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({
      type: 'MASTERDATA:DATASOURCES:LIST:FETCH:FAIL',
      error: err.message
    });
    yield put({
      type: 'RECORDS:DATA_SOURCES:LIST:REQUEST:FAIL',
      error: err.message
    });
  }
}

export function* filterDatasources(action) {
  const { filterParams, appendFilter = true, source = '' } = action;
  // appendFilter is used when this is triggered from visual dashboard
  // where the filter need not be appended.
  yield put({
    type: 'MASTERDATA:DATASOURCES:LIST:FILTER',
    filterParams,
    appendFilter,
    source
  });
  if (appendFilter) yield fetchDatasourcesList(action);
}

export function* searchDatasources(action) {
  const {
    searchParams: { searchKey, isPageSearch },
    searchParams,
    rowCount = MASTERDATA_SEARCH_LIMIT
  } = action;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';
  const currentState = yield select((state) => state.environment.dataSources);

  const {
    sortOn,
    sortOrder,
    filteredOn,
    searchParameters,
    searchText,
    unused
  } = getFilterAndSearchParamsFromState(currentState, searchParams, searchMode);

  yield put({
    type: `MASTERDATA:DATASOURCES:${searchMode}`,
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position,
          searchParameters,
          'datasources',
          rowCount,
          undefined,
          unused
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: addKeyToDataSource(item),
          organisation:
            searchMode === 'PAGE_SEARCH'
              ? transformDataSourceOrgItem(item.organisation)
              : item.organisation && item.organisation.name
        }));
        yield put({
          type: `MASTERDATA:DATASOURCES:${searchMode}:SUCCESS`,
          searchKey,
          searchText,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({
      type: 'MASTERDATA:DATASOURCES:LIST:SEARCH:FAIL',
      error: err.message
    });
  }
}

export function* updateDataSources(action) {
  const { isEdit, data, source } = action;
  const request = registry.get('request');
  const requestData = Object.assign({}, data);
  let response;
  try {
    const modifiedReqData = {
      ...requestData.value,
      organisationId:
        requestData.value.organisation && requestData.value.organisation.id
    };
    if (modifiedReqData.dataStorageCountry === '')
      delete modifiedReqData.dataStorageCountry;
    if (modifiedReqData.organisationId === '')
      delete modifiedReqData.organisationId;
    delete modifiedReqData.organisation;

    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(
        `/v1/masterdata/datasources`,
        modifiedReqData
      );
    } else {
      response = yield request.put(
        `/v1/masterdata/datasources/${modifiedReqData.id}?withJob=false`,
        modifiedReqData
      );
    }
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationMessages[isEdit],
            type: 'success'
          }
        });

        const dataSourceData = response.body;

        // Handle consistency in the front end.
        if (isEdit) {
          yield put({
            type: 'MASTERDATA:DATASOURCES:UPSERT:SUCCESS',
            dataSourceData
          });
          yield updateDataSourceItems({
            type: 'edit',
            data: response.body,
            source
          });
        } else {
          yield put({
            type: 'MASTERDATA:DATASOURCES:UPSERT:SUCCESS',
            dataSourceData,
            position: 0
          });
          const modifiedDatasourceData = {
            ...dataSourceData,
            key: addKeyToDataSource(dataSourceData),
            organisation: transformDataSourceOrgItem(
              dataSourceData.organisation
            ),
            organisationData: dataSourceData.organisation
          };
          yield fetchDatasourcesList({
            dataSourceData: modifiedDatasourceData,
            isPersistedData: true
          });
        }
        break;
      }
      case 304: {
        yield put({
          type: 'MASTERDATA:DATASOURCES:UPSERT:SUCCESS',
          dataSourceData: requestData.value
        });
        break;
      }
      case 409:
      case 412: {
        yield put({
          type: 'MASTERDATA:DATASOURCES:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({
      type: 'MASTERDATA:DATASOURCES:UPSERT:FAIL',
      error: err.message
    });
  }
}

export function* fetchDataSourceItem(action) {
  yield put({ type: 'MASTERDATA:DATASOURCES:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry
        .get('request')
        .get(`/v1/masterdata/datasources/${action.id}`, null);
      const item = response.body;
      const modifiedItem = {
        ...item,
        organisation: item.organisation
          ? {
            ...item.organisation,
            key: `${item.organisation.name} (${item.organisation.country.id})`
          }
          : undefined
      };
      switch (response.status) {
        case 200: {
          yield put({
            type: 'MASTERDATA:DATASOURCES:ITEM:FETCH:SUCCESS',
            dataSourceData: modifiedItem
          });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({
        type: 'MASTERDATA:DATASOURCES:ITEM:FETCH:FAIL',
        error: err.message
      });
    }
  }
}

export function* deleteDataSources(action) {
  const { dataItemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(
      `/v1/masterdata/datasources/${dataItemId}`,
      null
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('dataSource'),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateDataSourceItems({
          type: 'delete',
          dataSourceId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* createDataSourceJob(action) {
  const { actionType, data } = action;
  const requestData = Object.assign({}, data);
  let modifiedReqData = {};
  if (actionType === 'Edit') {
    modifiedReqData = {
      ...requestData,
      dataStorageCountry: requestData.dataStorageCountry && {
        id: requestData.dataStorageCountry
      },
      organisation: requestData.organisation && {
        ...requestData.organisation,
        country: {
          id:
            requestData.organisation.country.id ||
            requestData.organisation.country
        }
      }
    };
    if (modifiedReqData.dataStorageCountry === '')
      delete modifiedReqData.dataStorageCountry;
  } else if (actionType === 'Delete') {
    modifiedReqData = {
      ...requestData,
      dataStorageCountry: requestData.dataStorageCountry && {
        id: requestData.dataStorageCountry
      },
      organisation: requestData.organisationData
    };
    delete modifiedReqData.organisationData;
  }

  yield put({
    type: 'MASTER_DATA_ITEM:CREATE:JOB',
    actionType,
    entityType: 'DataSource',
    data: modifiedReqData
  });
}

function* updateDataSourceItems(action) {
  const { type, data, dataSourceId, source } = action;
  const dataSourcesState = yield select(
    (state) => state.environment.dataSources
  );
  let position = null;
  let items = dataSourcesState
    ? dataSourcesState.get('items')
    : Immutable.List();
  let newItems = dataSourcesState
    ? dataSourcesState.get('newItems')
    : Immutable.List();
  if (type === 'delete') {
    position = dataSourcesState.get('position') - 1;
    items = updateDataItems(items, type, dataSourceId);
    newItems = updateDataItems(newItems, type, dataSourceId);
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = transformDataSourceItem(data);
    items = updateDataItems(items, type, data.id, source, modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, source, modifiedItem);
  }
  yield put({
    type: 'MASTERDATA:DATASOURCES:LIST:UPDATE:SUCCESS',
    items,
    newItems,
    position
  });
}

const transformDataSourceItem = (item) => ({
  administrator: item.administrator,
  dataStorageCountry: item.dataStorageCountry,
  organisation: transformDataSourceOrgItem(item.organisation),
  dataSourceCategoryNames: transformDataSourceCategories(
    item.dataSourceCategories
  ),
  id: item.id,
  name: item.name,
  key: addKeyToDataSource(item),
  tags: item.tags
});

// Append country of establishment to Hosted by
const transformDataSourceOrgItem = (org) =>
  org ? `${org.name} (${org.country.id})` : '';

const notificationMessages = {
  true: notificationtranslations.dataSourcesUpdateSuccess,
  false: notificationtranslations.dataSourcesCreated
};

const transformDataSourceCategories = (item) => {
  let dataCategories = item.map((category) => category.name);
  dataCategories = dataCategories.join(', ');
  return dataCategories;
};

const getFilterAndSearchParamsFromState = (
  currentState,
  searchParams,
  searchMode
) => {
  // Filter parameters
  let filterParams = {};
  let unused = false;
  let pageSearchTextObj = {};
  if (currentState) {
    filterParams = currentState.get('filterParams').toJS();
    pageSearchTextObj = currentState.get('pageSearchText').toJS();
    unused = currentState.get('unused');
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn =
    currentState && searchMode !== 'LIST:SEARCH'
      ? currentState.get('filteredOn')
      : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;

    let searchTextObj = Immutable.Map();
    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined && currentState) {
      searchTextObj =
        searchMode === 'LIST:SEARCH'
          ? currentState.get('searchText')
          : currentState.get('pageSearchText');
      searchText = searchTextObj.get(searchKey) || '';
    }
    searchParameters = Object.assign({}, searchParams, { searchText });
  } else {
    searchParameters = pageSearchTextObj
      ? { searchKey: 'name', searchText: pageSearchTextObj.name }
      : {};
  }

  return {
    sortOn,
    sortOrder,
    filteredOn,
    searchParameters,
    searchText,
    unused
  };
};
