import { connect } from 'react-redux';
import TransferGroundSelector from './component';

const mapStateToProps = state => ({
  transferGrounds: state.recordsTransfergrounds.get('transferGrounds'),
  error: state.recordsTransfergrounds.get('error')
});

const mapDispatchToProps = dispatch => ({
  initTransferGrounds: (transferGroundType) => {
    dispatch({ type: 'TRANSFER_GROUNDS:RECORDS:LIST:INIT', transferGroundType });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferGroundSelector);
