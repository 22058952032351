import { connect } from 'react-redux';
import RecordsSelector from './recordsSelector';

const mapStateToProps = state => ({
  records: state.recordsSelector.get('records'),
  error: state.recordsSelector.get('error')
});

const mapDispatchToProps = dispatch => ({
  fetchRecords: (layoutId, searchParams) => {
    dispatch({ type: 'RECORDS:LIST:REQUEST', layoutId, searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordsSelector);
