import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  position: 0,
  searchText: Immutable.Map(),
  filterParams: [],
  searchParams: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  items: Immutable.List(),
  itemSelectorSearch: Immutable.Map({
    controllers: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    processors: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    executingEntities: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    dataRecipients: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    aclOrgs: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    reportOrg: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    },
    custom: {
      searchResults: Immutable.List(),
      searchText: Immutable.Map()
    }
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LEGAL_ENTITY:LIST:FETCH_INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('error', defaultState.get('error'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'LEGAL_ENTITY:LIST:REQUEST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('error', defaultState.get('error'))
        .set('position', 0);
    case 'LEGAL_ENTITY:LIST:REQUEST:SUCCESS':
      return state
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);

    case 'LEGAL_ENTITY:LIST:REQUEST:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'LEGAL_ENTITY:LIST:SEARCH:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('searchParams', action.searchParams);
    case 'LEGAL_ENTITY:LIST:FILTER':
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('filterParams', action.filterParams)
        .set('searchParams', action.searchParams);
    case 'LEGAL_ENTITY:LIST:FILTER:RESET':
      return state
        .set('filterParams', defaultState.get('filterParams'))
        .set('searchParams', defaultState.get('searchParams'));
    case 'LEGAL_ENTITY:LIST:SEARCH': {
      const { legalEntityType } = action;
      const itemSelectorSearch = state.get('itemSelectorSearch');
      const currentTypeParams = itemSelectorSearch.get(legalEntityType);
      if (currentTypeParams) {
        currentTypeParams.searchText = getSearchText(action);
        itemSelectorSearch.set(legalEntityType, currentTypeParams);
      }
      return state
        .set('isFetching', true)
        .set('itemSelectorSearch', itemSelectorSearch)
        .set('error', defaultState.get('error'));
    }
    case 'LEGAL_ENTITY:LIST:SEARCH:SUCCESS': {
      const { legalEntityType } = action;
      const itemSelectorSearch = state.get('itemSelectorSearch').toJS();
      const currentTypeParams = { ...itemSelectorSearch[legalEntityType] };
      if (currentTypeParams) {
        currentTypeParams.searchText = getSearchText(
          Object.assign({}, action, { searchObj: currentTypeParams.searchText })
        );
        currentTypeParams.searchResults = action.searchResults;
        itemSelectorSearch[legalEntityType] = currentTypeParams;
      }
      return state
        .set('isFetching', false)
        .set('itemSelectorSearch', Immutable.Map(itemSelectorSearch));
    }
    case 'LEGAL_ENTITY:SEARCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    default:
      return state;
  }
};
