/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import Documents from '.';


const DocumentsDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      selectedItem.key = selectedItem.name;
      props.handleSelectedItem({ value: selectedItem }, closeDialog);
    }
  };

  const handleOnDelete = (selectedItem, closeDialog) => {
    if (props.handleDeletedItem) {
      props.handleDeletedItem({ value: selectedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const getDailogTitle = () => {
    let title = '';
    if (props.isReplace) {
      title = (<FormattedMessage
        id="Document.replaceDocument"
        description="Replace document in Dialog Header"
        defaultMessage="Replace a document"
      />);
    } else if (props.isEdit) {
      title = (<FormattedMessage
        id="Document.editDocument"
        description="Edit document in Dialog Header"
        defaultMessage="Edit a document"
      />);
    } else if (props.isDelete) {
      title = (<FormattedMessage
        id="Document.deleteDocument"
        description="Delete document in Dialog Header"
        defaultMessage="Delete document"
      />);
    } else {
      title = (<FormattedMessage
        id="Documents.addDocument"
        description="Add Document in Dialog Header"
        defaultMessage="Add a document"
      />);
    }
    return (title);
  };

  return (
    <CommonDialog
      id="documents"
      fullWidth={true}
      maxWidth="md"
      show={props.open}
      onCancel={requestClose}
      title={getDailogTitle()}
    >
      <Documents
        id="documents"
        isEdit={props.isEdit}
        isDelete={props.isDelete}
        isReplace={props.isReplace}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onDelete={handleOnDelete}
        onCancel={requestClose}
        source={props.source}
        isUsed={props.isUsed}
        usage={props.usage}
        {...props}
      />
    </CommonDialog>
  );
};

DocumentsDialog.propTypes = {
  dataItemId: PropTypes.string,
  open: PropTypes.bool,
  source: PropTypes.string,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  inputValue: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  isReplace: PropTypes.bool,
  usage: PropTypes.shape({})
};


DocumentsDialog.defaultProps = {
  open: false,
  isEdit: false,
  isDelete: false,
  isReplace: false,
  source: '',
  onRequestClose: e => e,
  handleSelectedItem: e => e,
  inputValue: {},
  dataItemId: '',
  isUsed: false,
  usage: {}
};

export default DocumentsDialog;
