import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';

import messageTranslations from '../../messageTranslations';
import { getClaimTaskUrl, getURLWithParams } from './task-utils';
import { getReducerType } from '../../utils';

export function* resetAndfetchTasks(action) {
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  yield put({ type: `TASKS:${reducerType}:LIST:INIT` });
  yield fetchTasks(action);
}

export function* fetchTasks(action) {
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  yield put({ type: `TASKS:${reducerType}:LIST:FETCH` });
  let currentUser = '';
  try {
    const tasksState = yield select((state) => state.tasks[currentSubmenu]);
    let filterParams = {};
    let searchParameters = {};
    if (tasksState) {
      filterParams = tasksState.get('filterParams').toJS();
      const searchTextObj = tasksState.get('searchText').toJS();
      currentUser = tasksState.get('currentUser');
      searchParameters = searchTextObj
        ? { searchKey: 'allFields', searchText: searchTextObj.allFields }
        : {};
    }
    const { sortOn, sortOrder } = filterParams;
    let filteredOn = tasksState
      ? tasksState.get('filteredOn')
      : Immutable.Map();
    if (action.fromDashboard && reducerType === 'INBOX') {
      yield put({
        type: `TASKS:INBOX:LIST:FILTER`,
        filterParams: {
          ...tasksState.get('filterParams').toJS(),
          filterKey: 'status',
          filteredOn: ['Active']
        }
      });
      filteredOn = Immutable.Map({ status: ['Active'] });
    }
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position ? action.position : 0,
          searchParameters,
          action.rowCount,
          currentUser,
          currentSubmenu
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: `TASKS:${reducerType}:LIST:FETCH:SUCCESS`,
          items: response.body,
          currentUser
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({
      type: `TASKS:${reducerType}:LIST:FETCH:FAIL`,
      error: err.message
    });
  }
}

export function* filterTasks(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  yield put({ type: `TASKS:${reducerType}:LIST:FILTER`, filterParams });
  yield fetchTasks(action);
}

export function* searchTasks(action) {
  let { searchParams } = action;
  const { currentSubmenu } = action;
  const searchResultsCount = 100;
  let currentUser = '';
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  const reducerType = getReducerType(currentSubmenu);
  const tasksState = yield select((state) => state.tasks[currentSubmenu]);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = tasksState
      ? tasksState.get('searchText')
      : Immutable.Map();
    searchText = searchTextObj.get(searchKey) || '';
    searchParams = { ...searchParams, searchText };
  }
  currentUser = tasksState && tasksState.get('currentUser');
  const filterParams = tasksState ? tasksState.get('filterParams').toJS() : {};
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = tasksState
    ? tasksState.get('filteredOn')
    : Immutable.Map();

  yield put({
    type: `TASKS:${reducerType}:LIST:SEARCH`,
    searchKey,
    searchText
  });
  try {
    const url = getURLWithParams(
      { sortOn, sortOrder, filteredOn },
      action.position ? action.position : 0,
      searchParams,
      searchResultsCount,
      currentUser,
      currentSubmenu
    );
    const response = yield registry.get('request').get(url, null, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: `TASKS:${reducerType}:LIST:SEARCH:SUCCESS`,
          searchKey,
          items: response.body,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({
      type: `TASKS:${reducerType}:LIST:SEARCH:FAIL`,
      error: err.message
    });
  }
}

export function* claimOrUnclaimTask(action) {
  try {
    let currentUser = '';
    const { taskId, taskAction, currentSubmenu } = action;
    const reducerType = getReducerType(currentSubmenu);
    const url = getClaimTaskUrl(taskId, taskAction);
    const response = yield registry.get('request').post(url, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: taskActionMessages[taskAction],
            type: 'success'
          }
        });
        if (action.callback) action.callback();

        // Update the task status
        const taskState = yield select((state) => state.tasks[currentSubmenu]);
        const userState = yield select((state) => state.user);
        currentUser = taskState.get('currentUser');
        const items = updateTaskItems(
          taskState,
          userState,
          taskId,
          taskAction,
          currentUser,
          currentSubmenu
        );
        yield put({ type: `TASKS:${reducerType}:LIST:UPDATE`, items });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteTask(action) {
  const { id } = action;
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/tasks/${id}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.taskDeleted,
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        const taskState = yield select((state) => state.tasks[currentSubmenu]);
        const taskItems = taskState.get('items');
        const items = updateItems(taskItems, id);
        yield put({ type: `TASKS:${reducerType}:LIST:UPDATE`, items });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* initTaskWorkers(action) {
  const {
    recordId,
    userPosition = 0,
    userRowCount = GENERAL_FETCH_LIMIT,
    taskSubject
  } = action;
  const request = registry.get('request');
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  try {
    let response;
    if (taskSubject === 'dsr') {
      response = yield registry
        .get('request')
        .get(
          `/v1/users?offset=${userPosition}&numberOfResults=${userRowCount}&filter=permissionGroups=dataSubjectRequests-read&filter=status=Registered`
        );
    } else {
      response = yield request.get(
        `/v1/records/${recordId}/workers?offset=${userPosition}&numberOfResults=${userRowCount}`,
        null
      );
    }
    switch (response.status) {
      case 200: {
        // Handle consistency in the front end.
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );

        yield put({
          type: `TASKS:${reducerType}:WORKERS:LIST:FETCH:SUCCESS`,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* searchTaskWorkers(action) {
  const {
    recordId,
    workersSearchText,
    taskSubject,
    userPosition = 0,
    userRowCount = GENERAL_FETCH_LIMIT
  } = action;
  const request = registry.get('request');
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  let response;
  try {
    if (taskSubject === 'dsr') {
      response = yield registry
        .get('request')
        .get(
          `/v1/users?offset=${userPosition}&numberOfResults=${userRowCount}&search=firstName%3D${encodeURIComponent(
            workersSearchText
          )}&filter=permissionGroups=dataSubjectRequests-read&filter=status=Registered`
        );
    } else {
      response = yield request.get(
        `/v1/records/${recordId}/workers?search=firstName%3D${encodeURIComponent(
          workersSearchText
        )}`,
        null
      );
    }
    switch (response.status) {
      case 200: {
        // Handle consistency in the front end.
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );

        yield put({
          type: `TASKS:${reducerType}:WORKERS:LIST:SEARCH:SUCCESS`,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
const transformUserItem = (item) => ({
  ...item,
  key: item.lastName ? `${item.firstName} ${item.lastName}` : null
});

export function* delegateTask(action) {
  try {
    const { taskId, userId, currentSubmenu } = action;
    const reducerType = getReducerType(currentSubmenu);
    const response = yield registry
      .get('request')
      .post(`v1/tasks/${taskId}/delegate`, userId);
    let currentUser = '';
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: taskActionMessages.delegate,
            type: 'success'
          }
        });
        // Update the task status
        const taskState = yield select((state) => state.tasks[currentSubmenu]);
        const tasks = taskState && taskState.get('items');
        currentUser = taskState && taskState.get('currentUser');
        const index = tasks.findIndex((task) => task.id === taskId);
        const modifiedTask = response.body;
        let items = tasks;
        if (
          (currentUser !== '' && modifiedTask.assignee.id !== currentUser) ||
          currentSubmenu === 'unclaimedTasks'
        ) {
          items = tasks.filter((item) => item.id !== taskId);
        } else {
          items = tasks.set(index, modifiedTask);
        }
        yield put({ type: `TASKS:${reducerType}:LIST:UPDATE`, items });
        yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
const updateTaskItems = (
  taskState,
  userState,
  taskId,
  taskAction,
  currentLoggedInUser,
  currentSubmenu
) => {
  const currentUser = userState ? userState.get('profile') : null;
  const tasks = [...taskState.get('items')];
  const index = tasks.findIndex((task) => task.id === taskId);
  const updatedItem = { ...tasks[index] };
  if (taskAction === 'claim') {
    if (currentSubmenu === 'unclaimedTasks') {
      tasks.splice(index, 1);
    } else {
      updatedItem.assignee = currentUser.toJS();
      tasks[index] = updatedItem;
    }
  } else if (currentLoggedInUser !== '' && taskAction === 'unclaim') {
    tasks.splice(index, 1);
  } else {
    delete updatedItem.assignee;
    tasks[index] = updatedItem;
  }

  return tasks;
};

const updateItems = (taskItems, taskId) => {
  const index = taskItems.findIndex((task) => task.id === taskId);
  const items = taskItems.remove(index);
  return items;
};
const taskActionMessages = {
  claim: notificationtranslations.taskClaimSuccess,
  unclaim: notificationtranslations.taskUnClaimSuccess,
  delegate: notificationtranslations.taskDelegateSuccess
};

export function* bulkDeleteTasks(action) {
  const { items, currentSubmenu } = action;
  const request = registry.get('request');
  try {
    const url =
      currentSubmenu !== 'sent'
        ? `/v1/tasks/remove`
        : `/v1/messages/sent/remove`;
    const taskIds = [];
    items.forEach((item) => {
      taskIds.push(item.id);
    });
    const requestData = { taskIds };
    const response = yield request.post(url, requestData);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.BulkDeleteSuccess,
            type: 'success'
          }
        });
        yield resetAndfetchTasks(action);
        yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'record');
  }
}

export function* resetAndFetchCreatedByUsers(action) {
  const { currentSubmenu } = action;
  const reducerType = getReducerType(currentSubmenu);
  yield put({ type: `TASKS:${reducerType}:USERS:LIST:INIT` });
  yield getCreatedByusers(action);
}
export function* getCreatedByusers(action) {
  const {
    rowCount = GENERAL_FETCH_LIMIT,
    position = 0,
    currentSubmenu
  } = action;
  const reducerType = getReducerType(currentSubmenu);
  yield put({ type: `TASKS:${reducerType}:USERS:LIST:FETCH` });

  try {
    const taskState = yield select((state) => state.tasks[currentSubmenu]);
    let searchTextValue = '';
    if (taskState) {
      const userObject = taskState.get('users').toJS();
      searchTextValue = userObject.searchText.createdBy || '';
    }
    const url = `/v1/users?offset=${position}&numberOfResults=${rowCount}&search=name=${encodeURIComponent(
      searchTextValue
    )}`;
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map((item) => transformCreatedByUser(item));
        yield put({
          type: `TASKS:${reducerType}:USERS:LIST:FETCH:SUCCESS`,
          items: modifiedItems.filter((item) => item.label !== '')
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({
      type: `TASKS:${reducerType}:USERS:LIST:FETCH:FAIL`,
      error: err.message
    });
  }
}

export function* searchCreatedByUsers(action) {
  const {
    rowCount = GENERAL_FETCH_LIMIT,
    position = 0,
    searchParams = undefined,
    currentSubmenu
  } = action;
  const reducerType = getReducerType(currentSubmenu);
  try {
    const taskState = yield select((state) => state.tasks[currentSubmenu]);
    let searchTextValue = '';
    if (searchParams) {
      searchTextValue = searchParams.searchText;
      yield put({
        type: `TASKS:${reducerType}:USERS:LIST:SEARCH`,
        searchText: searchTextValue
      });
    } else if (taskState) {
      const userObject = taskState.get('users').toJS();
      searchTextValue = userObject.searchText.createdBy || '';
    }
    const url = `/v1/users?offset=${position}&numberOfResults=${rowCount}&search=name=${encodeURIComponent(
      searchTextValue
    )}`;
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map((item) => transformCreatedByUser(item));
        yield put({
          type: `TASKS:${reducerType}:USERS:LIST:SEARCH:SUCCESS`,
          items: modifiedItems.filter((item) => item.label !== '')
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({
      type: `TASKS:${reducerType}:USERS:LIST:SEARCH:FAIL`,
      error: err.message
    });
  }
}

const transformCreatedByUser = (item) => ({
  label: item.firstName
    ? `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
    : '',
  value: item.id
});
