import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isImportAllowed: undefined,
  fileId: undefined,
  fileEncoding: undefined,
  isImportInitiated: false,
  cancelStreaming: false,
  importObject: Immutable.Map(),
  streamData: {},
  isProgressing: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'IMPORT:CSV:ID:INIT':
      return state
        .set('isFetching', true);
    case 'CHECK:IMPORT:ISALLOWED:SUCCESS':
      return state
        .set('isFetching', false)
        .set('cancelStreaming', false)
        .set('isImportAllowed', action.isAllowed);
    case 'CHECK:IMPORT:ISALLOWED:FAIL':
      return state
        .set('isFetching', false)
        .set('isImportAllowed', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'IMPORT:CSV:ID:SUCCESS':
      return state
        .set('fileId', action.fileId)
        .set('fileEncoding', action.fileEncoding)
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'IMPORT:INIT':
      return state
        .set('isImportInitiated', action.isSuccess);
    case 'IMPORT:FETCH:FAIL':
      return state
        .set('fileId', undefined)
        .set('fileEncoding', undefined)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'IMPORT:RESET:ERROR':
      return state
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'CHECK:IMPORT:STATUS:INIT':
      return state
        .set('streamData', defaultState.get('streamData'))
        .set('isFetching', true);
    case 'CHECK:IMPORT:STATUS:SUCCESS':
      return state
        .set('importObject', Immutable.Map(action.importObject))
        .set('isProgressing', true)
        .set('isFetching', false);
    case 'MASTERDATA:DOCUMENTS:UPSERT:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'CHECK:IMPORT:STATUS:FAIL':
      return state
        .set('isFetching', false);
    case 'IMPORT:SUCCESS':
      return state
        .set('isFetching', false)
        .set('streamData', defaultState.get('streamData'))
        .set('isImportInitiated', true)
        .set('importObject', Immutable.Map(action.importObject));
    case 'IMPORT:STREAMING:INIT':
      return state
        .set('cancelStreaming', false)
        .set('streamData', defaultState.get('streamData'))
        .set('isImportInitiated', true);
    case 'IMPORT:STREAMING:SUCCESS':
      return state
        .set('isFetching', false)
        .set('streamData', action.streamData);
    case 'IMPORT:STREAMING:FAIL':
      return state
        .set('isImportInitiated', false)
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'IMPORT:STREAMING:RESET':
      return state
        .set('streamData', defaultState.get('streamData'))
        .set('cancelStreaming', true)
        .set('isProgressing', false)
        .set('isImportInitiated', false);
    case 'BULK:IMPORT:RESET':
      return state
        .set('isFetching', false)
        .set('streamData', defaultState.get('streamData'))
        .set('importObject', defaultState.get('importObject'))
        .set('isImportAllowed', undefined)
        .set('fileId', undefined)
        .set('fileEncoding', undefined)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'RESET:IMPORT:OBJECT':
      return state
        .set('importObject', defaultState.get('importObject'));
    default:
      return state;
  }
};
