import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import PersonalDataItem from './personalDataItem';

const mapStateToProps = (state) => ({
  personalDataItemData: state.environment.personalDataItems.get(
    'personalDataItemData'
  ),
  actionError: state.environment.personalDataItems.get('error').toJS(),
  globalUsage: state.environment.personalDataItems.get('globalUsage'),
  isGlobal: state.home.get('isGlobal'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'PERSONAL_DATA_ITEM:FETCH', id });
  },
  updatePersonalDataItems: (data, isEdit, source, isUsed) => {
    if (isUsed) {
      dispatch({
        type: 'PERSONAL_DATA_ITEM:CREATE:JOB',
        operationType: 'Edit',
        entityType: 'PersonalDataItem',
        data
      });
    } else {
      dispatch({ type: 'PERSONAL_DATA_ITEMS:UPSERT', data, isEdit, source });
    }
  },
  resetPersonalDataItems: () => {
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataItem);
