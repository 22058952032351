import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { getReducerType } from '@packages/utils/common-utils';
import SimpleEntity from '.';
import { addMasterTypeTranslations, editMasterTypeTranslations } from '../../../masterDataTranslations';

const SimpleEntityDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      selectedItem.key = selectedItem.name;
      props.handleSelectedItem({ value: selectedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };
  const { masterDataType, entityType } = props;
  const reducerType = getReducerType(masterDataType, entityType);

  return (
    <CommonDialog
      id="simple-named-entity"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={!props.isEdit ? addMasterTypeTranslations(reducerType) : editMasterTypeTranslations(reducerType)}
    >
      <SimpleEntity
        id="simple-entity"
        isEdit={props.isEdit}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        source={props.source}
        limitExceeded={props.limitExceeded}
        usage={props.usage}
        isUsed={props.isUsed}
        subTypes={props.subTypes}
        isTagEdit={props.isTagEdit}
        masterDataType={masterDataType}
        entityType={entityType}
        hideTags={props.hideTags}
        allowSaveAndCreate={props.allowSaveAndCreate}
      />
    </CommonDialog>
  );
};

SimpleEntityDialog.propTypes = {
  open: PropTypes.bool,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  isEdit: PropTypes.bool,
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  inputValue: PropTypes.shape({}),
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  limitExceeded: PropTypes.bool,
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  subTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.instanceOf(Immutable.List)
  ]),
  isTagEdit: PropTypes.bool
};


SimpleEntityDialog.defaultProps = {
  open: false,
  isEdit: false,
  source: '',
  dataItemId: '',
  limitExceeded: false,
  onRequestClose: e => e,
  handleSelectedItem: e => e,
  inputValue: {},
  masterDataType: '',
  entityType: '',
  usage: {},
  isUsed: false,
  subTypes: undefined,
  isTagEdit: false
};

export default SimpleEntityDialog;
