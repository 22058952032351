import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';

import {
  commonTranslations,
  personalDataCategoryTypeMapper
} from '@packages/utils/commontranslations';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import PersonalDataItemDialog from '@packages/features//environment/components/personal-data-items/components/personalDataItemDialog';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import PersonalDataMultiselector from '../personal-data-items/components/data-item-multi-selector';
import { getItemNames } from '../../common-utils';

class PersonalItemsSelector extends React.Component {
  static filterPersonalDataItems(selectedPersonalDataItems, searchedItems) {
    const filteredData = searchedItems.filter((personalDataItem) => {
      const index = selectedPersonalDataItems.findIndex(
        (selectedPersonalDataItem) =>
          selectedPersonalDataItem.value.id === personalDataItem.id
      );
      return index === -1;
    });
    return filteredData;
  }

  static handleGetMultipleItems(newFields, items) {
    const selectedPersonalDataItems = newFields.getAll();
    const multiplePersonalItems =
      selectedPersonalDataItems && selectedPersonalDataItems.length > 0
        ? PersonalItemsSelector.filterPersonalDataItems(
          selectedPersonalDataItems,
          items
        )
        : items;
    return multiplePersonalItems;
  }

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      openEdit: false,
      personalDataItemList: []
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.savePersonalDataItem = this.savePersonalDataItem.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.performRemove = this.performRemove.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updatePersonalDataItems = this.updatePersonalDataItems.bind(this);
    this.performEdit = this.performEdit.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleListItems = this.handleListItems.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
  }

  componentDidMount() {
    if (this.props.initPersonalDataItems) {
      this.props.initPersonalDataItems();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.personalDataItems !== nextProps.personalDataItems ||
      nextProps.searchResults !== this.props.searchResults
    ) {
      const personalDataItemList = this.handleListItems(
        nextProps.searchResults,
        nextProps.personalDataItems,
        nextProps.fields,
        nextProps.position
      );
      this.setState({
        personalDataItemList
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.selectedItems === nextProps.selectedItems) ||
      !(this.state === nextState) ||
      !(this.props.personalDataItems === nextProps.personalDataItems) ||
      !(this.props.prepareLinkGroup === nextProps.prepareLinkGroup) ||
      !(this.props.disabled === nextProps.disabled)
    );
  }

  handleListItems(searchResults, personalDataItems, fields, position) {
    const { searchText } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : personalDataItems;
    this.transformItemKey(searchedItems);
    this.transformItemKey(personalDataItems);
    const personalDataItemList = PersonalItemsSelector.handleGetMultipleItems(
      fields,
      searchedItems
    );
    if (
      personalDataItemList.size < 15 &&
      personalDataItems.size > 0 &&
      this.state.searchText === '' &&
      this.props.position !== position
    ) {
      this.props.getNextData(position);
    }
    return personalDataItemList;
  }

  handleAddClick(searchText) {
    this.setState({
      isEdit: false,
      open: true,
      multiple: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText,
        categoryType: 'normal'
      }
    });
  }

  handleEditClick(index) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('personalDataItems', index);
    if (isLinkAssociated) {
      this.setState({
        openEdit: true,
        selectedIndex: index
      });
    } else {
      this.setState({
        isEdit: true,
        open: true,
        multiple: false,
        openEdit: false,
        selectedIndex: index,
        inputValue:
          this.props.fields.get(index).value || this.props.fields.get(index)
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      open: false,
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      openEdit: false,
      selectedIndex: -1,
      searchText: ''
    });
  }

  handleDialogClose() {
    this.props.resetSearchFilter();
    this.setState({ multiple: false, searchText: '' });
    if (this.props.initPersonalDataItems) {
      this.props.initPersonalDataItems();
    }
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('personalDataItems', selectedIndex);
    if (isLinkAssociated) {
      this.setState({
        openDelete: true,
        selectedIndex
      });
    } else {
      this.performRemove(selectedIndex);
    }
  }

  handleClose() {
    this.setState({
      openDelete: false,
      openEdit: false,
      open: false,
      selectedIndex: -1
    });
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const selectedPersonalDataItems = [...items, ...selectedItems];
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedPersonalDataItems);
    } else {
      this.props.updatePersonalDataItems(selectedPersonalDataItems);
    }
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    const selectedPersonalDataItems = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedPersonalDataItems.push(selectedItem);
    } else {
      selectedPersonalDataItems.splice(selectedIndex, 1, selectedItem);
    }
    this.updatePersonalDataItems(selectedPersonalDataItems);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.dataItemType);
  }

  savePersonalDataItem(searchText, closeDialog) {
    const { formatMessage } = this.props.intl;
    const modifiedSearchText = {
      value: {
        ...searchText.value,
        addlkey: formatMessage(
          personalDataCategoryTypeMapper(searchText.value.categoryType)
        )
      }
    };
    this.handleSelectedItem(modifiedSearchText, closeDialog);
  }

  performEdit(index) {
    this.setState({
      isEdit: true,
      open: true,
      multiple: false,
      openEdit: false,
      selectedIndex: index,
      inputValue: this.props.fields.get(index).value
    });
  }

  performRemove(selectedIndex) {
    const selectedPersonalDataItems = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedPersonalDataItems.splice(selectedIndex, 1);
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedPersonalDataItems);
    } else {
      this.props.updatePersonalDataItems(selectedPersonalDataItems);
    }

    this.handleClose();
  }

  updatePersonalDataItems(selectedPersonalDataItems) {
    this.setState({ searchText: '' });
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedPersonalDataItems, true);
    } else {
      this.props.updatePersonalDataItems(selectedPersonalDataItems);
    }
  }

  transformItemKey(items) {
    const { formatMessage } = this.props.intl;
    if (items && (items.length > 0 || items.size > 0)) {
      items.forEach((item) => {
        item = item.value || item;
        item.key = item.name;
        item.addlkey =
          item.categoryType &&
          formatMessage(personalDataCategoryTypeMapper(item.categoryType));
      });
    }
  }

  render() {
    const {
      label,
      multiValue,
      createNewMenuItem,
      selectFromListMenuItem,
      hintTextLabel,
      showTextField,
      showEditIcon,
      userPermissions,
      isGlobal,
      disabled
    } = this.props;
    const selectedItems = this.props.fields.getAll();
    this.transformItemKey(selectedItems);

    const { createEditPersonalDataItem } = userPermissions.toJS();

    const actions = [
      <Button key="btn_remove_no" onClick={this.handleClose}>
        {commonTranslations.Ok}
      </Button>
    ];

    return (
      <div>
        {!showTextField ? (
          getItemNames(selectedItems)
        ) : (
          <div>
            {selectedItems && selectedItems.length > 0 && (
              <ItemList
                {...this.props}
                isEditable={createEditPersonalDataItem && showEditIcon}
                type={label}
                handleRemoveItem={this.handleRemoveItem}
                handleEditClick={this.handleEditClick}
                handleEditItem={this.handleEditItem}
                handleItemClick={(index) =>
                  this.props.toggleLinkGroupItem('personalDataItems', index)
                }
                selectedItems={selectedItems}
                dataSource={this.state.personalDataItemList}
                handleUsageClick={this.handleUsageClick}
              />
            )}
            <ItemSelector
              multiValue={multiValue}
              createNewMenuItem={
                createEditPersonalDataItem && createNewMenuItem
              }
              selectFromListMenuItem={selectFromListMenuItem || false}
              selectedItem={selectedItems}
              dataSource={this.state.personalDataItemList}
              dataSourceConfig={{ text: 'key', value: 'key' }}
              hintTextLabel={hintTextLabel}
              handleSelectedItem={this.handleSelectedItem}
              handleAddClick={this.handleAddClick}
              handleMultipleSelect={this.handleMultipleSelect}
              onSearch={this.handleSearch}
              disabled={disabled}
            />
            {this.state.open && (
              <PersonalDataItemDialog
                open={this.state.open}
                dataItemId={this.state.inputValue && this.state.inputValue.id}
                source="records"
                inputValue={this.state.inputValue}
                isEdit={this.state.isEdit}
                onRequestClose={this.handleClose}
                handleMultipleSelect={this.handleMultipleSelect}
                onSavePersonalDataItem={this.savePersonalDataItem}
                isNewItem={this.state.selectedIndex === -1}
                hideTags={isGlobal}
              />
            )}
            {this.state.multiple && (
              <PersonalDataMultiselector
                title={
                  <FormattedMessage
                    id="MultiplePersonalItemsDialog.header"
                    description="Select Personal data items Dialog Header"
                    defaultMessage="Select personal data items"
                  />
                }
                open={this.state.multiple}
                selectedItems={selectedItems}
                onScrollStop={this.handleScrollEnd}
                onRequestClose={this.handleDialogClose}
                handleMultipleItems={this.handleMultipleItems}
                searchTextValue={this.state.searchText.searchText}
              />
            )}
            {this.state.openDelete && (
              <CommonDialog
                id="delete-confirm-dialog"
                show={this.state.openDelete}
                onCancel={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                buttonActions={actions}
                buttonPosition="right"
                showCloseIcon={false}
              >
                {commonTranslations.linkItemDeletefromGroup}
              </CommonDialog>
            )}
            {this.state.openEdit && (
              <CommonDialog
                id="edit-confirm-dialog"
                show={this.state.openEdit}
                onCancel={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                buttonActions={actions}
                buttonPosition="right"
                showCloseIcon={false}
              >
                {commonTranslations.linkItemUpdatefromGroup}
              </CommonDialog>
            )}
          </div>
        )}
      </div>
    );
  }
}

PersonalItemsSelector.propTypes = {
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  toggleLinkGroupItem: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  prepareLinkGroup: PropTypes.bool,
  personalDataItems: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  initPersonalDataItems: PropTypes.func,
  updatePersonalDataItems: PropTypes.func,
  addPersonalDataItem: PropTypes.func,
  editPersonalDataItem: PropTypes.func,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  resetSearchFilter: PropTypes.func,
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  showTextField: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  isGlobal: PropTypes.bool,
  disabled: PropTypes.bool
};

PersonalItemsSelector.defaultProps = {
  multiValue: true,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  selectedItems: [],
  prepareLinkGroup: false,
  personalDataItems: Immutable.List(),
  initPersonalDataItems: (e) => e,
  updatePersonalDataItems: (e) => e,
  addPersonalDataItem: (e) => e,
  editPersonalDataItem: (e) => e,
  label: null,
  getNextData: (e) => e,
  isItemInLinkGroup: () => {},
  position: 0,
  toggleLinkGroupItem: () => {},
  hintTextLabel: null,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  resetSearchFilter: (e) => e,
  handleUsageClick: (e) => e,
  dataItemType: '',
  showTextField: true,
  showEditIcon: true,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  userPermissions: Immutable.Map(),
  isGlobal: false,
  disabled: false
};

export default injectIntl(PersonalItemsSelector);
