/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import OrganisationEntity from '.';
import OrganisationImport from './components/organisation-import';

const OrganisationEntityDialog = (props) => {
  const [createNew, setCreateNew] = useState(false);

  const handleAddClick = () => {
    setCreateNew(true);
  };
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      const modifiedItem = ({ ...selectedItem, key: `${selectedItem.name} (${selectedItem.country})` });
      props.handleSelectedItem({ value: modifiedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const { showInternalFlag, showParentEntity, entityDetails, showSaveAndCreate, usage, showOnlyDPOContacts,
    isGlobal } = props;
  const isImport = props.parentTenantId && !createNew;
  return (
    <div >
      {props.open &&
      <CommonDialog
        id="organisation_entity-dialog"
        show={props.open}
        onCancel={requestClose}
        fullWidth={true}
        maxWidth={isImport ? 'sm' : 'md'}
        title={props.isEdit ? <FormattedMessage
          id="OrganisationEntityDialog.editEntity"
          description="Edit Entity Dialog Header"
          defaultMessage="Edit {entityType}"
          values={{ entityType: props.label }}
        /> : <FormattedMessage
          id="OrganisationEntityDialog.addEntity"
          description="Add Entity Dialog Header"
          defaultMessage="Add {entityType}"
          values={{ entityType: props.label }}
        />}
      >
        {isImport ?
          <OrganisationImport
            id="organisation_import"
            label={props.label}
            onSave={handleOnSubmit}
            onCancel={requestClose}
            isSubTenant={props.parentTenantId}
            handleAddClick={handleAddClick}
          /> :
          <OrganisationEntity
            id="organisation_entity"
            isEdit={props.isEdit}
            isGlobal={isGlobal}
            entityId={props.entityId}
            value={props.inputValue}
            label={props.label}
            usage={usage}
            hideTags={props.hideTags}
            showInternalFlag={showInternalFlag}
            showParentEntity={showParentEntity}
            onSave={handleOnSubmit}
            onCancel={requestClose}
            entityDetails={entityDetails}
            source={props.source}
            showSaveAndCreate={showSaveAndCreate}
            showOnlyDPOContacts={showOnlyDPOContacts}
            isTagEdit={props.isTagEdit}
            isVendor={props.isVendor}
            updateList={props.updateList}
            shouldInit={props.shouldInit}
          />
        }
      </CommonDialog>
    }
    </div>
  );
};

OrganisationEntityDialog.propTypes = {
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  showInternalFlag: PropTypes.bool,
  showParentEntity: PropTypes.bool,
  open: PropTypes.bool,
  isGlobal: PropTypes.bool,
  isEdit: PropTypes.bool,
  entityId: PropTypes.string,
  inputValue: PropTypes.shape({
    name: PropTypes.string
  }),
  label: PropTypes.node.isRequired,
  entityDetails: PropTypes.shape({}),
  usage: PropTypes.shape({}),
  source: PropTypes.string,
  showSaveAndCreate: PropTypes.bool,
  showOnlyDPOContacts: PropTypes.bool,
  isTagEdit: PropTypes.bool,
  isVendor: PropTypes.bool,
  shouldInit: PropTypes.bool,
  hideTags: PropTypes.bool,
  parentTenantId: PropTypes.string,
  updateList: PropTypes.bool
};

OrganisationEntityDialog.defaultProps = {
  open: false,
  isEdit: false,
  isGlobal: false,
  showInternalFlag: true,
  showParentEntity: false,
  inputValue: {},
  onRequestClose: e => e,
  handleSelectedItem: e => e,
  entityDetails: {},
  usage: {},
  entityId: '',
  source: '',
  showSaveAndCreate: true,
  showOnlyDPOContacts: false,
  isTagEdit: false,
  isVendor: false,
  shouldInit: true,
  hideTags: false,
  parentTenantId: '',
  updateList: true
};

export default OrganisationEntityDialog;
