import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getNameAndValue } from '../../../privacy-record-detail/common-utils';
import { transformUserName } from '../saga-utils';

export function* resetAndfetchPrivacyRecordsList(action) {
  const hashURL = window.location.hash;
  const { filter } = getQueryStrings(hashURL);
  const { value } = getNameAndValue(hashURL);
  yield fetchEntityDetails({ value, filter });

  yield put({ type: 'PRIVACY:RECORDS:LIST:REQUEST:INIT' });
  yield fetchPrivacyRecordsList(action);
}

export function* fetchPrivacyRecordsList(action) {
  yield put({ type: 'PRIVACY:RECORDS:LIST:FETCH' });
  const { position = 0, rowCount } = action;

  try {
    const hashURL = window.location.hash;
    // customName :- custom field name from url for records usage list heading
    const { filter, customName, customStakeholderEntityIds } =
      getQueryStrings(hashURL);

    let filterParams = {};
    let filteredOn = Immutable.Map();
    const recordListState = yield select(
      (state) => state.environment.privacyRecords
    );
    if (recordListState) {
      filterParams = recordListState.get('filterParams').toJS();
      filteredOn = recordListState.get('filteredOn');
    }
    const { sortOn, sortOrder } = filterParams;
    // To handle special character '&' that appears within a name, It might fail if the name contains '='
    const { value } = getNameAndValue(hashURL);
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          position || 0,
          rowCount,
          filter,
          value,
          customStakeholderEntityIds
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedResponse = response.body.map((item) => ({
          ...item,
          lastChanged: item.lastChangedBy
            ? item.lastChangedBy.changedAt
            : item.lastChanged
        }));
        const filterType =
          {
            organisation: 'organisations',
            customSimpleMasterData: customName,
            customStakeholders: customStakeholderEntityIds
          }[filter] || filter;
        yield put({
          type: 'PRIVACY:RECORDS:LIST:FETCH:SUCCESS',
          items: modifiedResponse,
          filter: { type: filterType }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'PRIVACY:RECORDS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* filterPrivacyRecordsList(action) {
  const filterParams = action.filterParams || {};
  yield put({ type: 'PRIVACY:RECORDS:LIST:FILTER', filterParams });
  yield fetchPrivacyRecordsList(action);
}

export function* fetchEntityDetails(action) {
  const { value, filter } = action;
  let response = '';
  const request = registry.get('request');
  try {
    const modifiedFilter = getModifiedFilter(filter);
    if (
      modifiedFilter === 'organisations' ||
      modifiedFilter === 'users' ||
      modifiedFilter === 'documents' ||
      modifiedFilter === 'links'
    ) {
      response = yield request.get(`/v1/${modifiedFilter}/${value}`);
    } else {
      response = yield request.get(`/v1/masterdata/${modifiedFilter}/${value}`);
    }

    switch (response.status) {
      case 200: {
        const item = response.body;
        let { name } = item;
        if (modifiedFilter === 'users') {
          name = transformUserName(item);
        } else if (modifiedFilter === 'legalgrounds') {
          name = `${item.lawAbbreviation} ${item.article}`;
        }
        yield put({ type: 'ENTITY:NAME:FETCH:SUCCESS', name });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
  }
}

const getModifiedFilter = (filter) => {
  switch (filter) {
    case 'dataRecipients':
    case 'processors':
    case 'executingEntities':
    case 'controllers':
    case 'customStakeholders':
    case 'organisation':
      return 'organisations';
    case 'user':
      return 'users';
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
      return 'securitymeasures';
    case 'customSimpleMasterData':
      return 'simplecustom';
    default:
      return filter.toLowerCase();
  }
};

const getURLWithParams = (
  filterParams,
  position,
  rowCount = 15,
  filter,
  value,
  customStakeholderEntityIds
) => {
  let url = `/v1/records?offset=${position}&numberOfResults=${rowCount}`;
  if (filter === 'organisation' || filter === 'user') {
    url = `${url}&${filter}=${encodeURIComponent(value)}`;
  } else {
    url = `${url}&filter=${filter}%3D${encodeURIComponent(value)}`;
    if (customStakeholderEntityIds)
      url += `&filter=customStakeholderEntityIds=${customStakeholderEntityIds}`;
  }
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          let filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          if (filterKey === 'recordType') {
            filterText = filterText.toLowerCase();
          }
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  const fields = ['name', 'recordType', 'status', 'lastChangedBy'];
  fields.forEach((field) => {
    url = `${url}&fields=${field}`;
  });
  url = `${url}&fields=${filter}`;
  return url;
};
