import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT
} from '@packages/utils/common-utils';

// eslint-disable-next-line import/prefer-default-export
export function* fetchCreatedByUsers(action) {
  yield put({ type: 'REPORTS_CREATEDBY_USERS:LIST:REQUEST_INIT' });
  try {
    const {
      position = 0,
      rowCount = MASTERDATA_FETCH_LIMIT,
      idToFilter = []
    } = action;
    let url = `/v1/users?offset=${position}&numberOfResults=${rowCount}&includeAll=true&filter=status%3DRegistered`;
    idToFilter.forEach((x) => {
      url = `${url}&filter=id%3D${x.id ? x.id : x}`;
    });
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );
        yield put({
          type: 'REPORTS_CREATEDBY_USERS:LIST:REQUEST:SUCCESS',
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({
      type: 'REPORTS_CREATEDBY_USERS:LIST:REQUEST:FAIL',
      error: err.message
    });
  }
}

const transformUserItem = (item) => ({
  ...item,
  key: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
});
