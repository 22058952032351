import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

export const TenantOrTagSelector = (props) => {
  const [multiple, setMultiple] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    props.getTenantsAndTags();
  }, []);

  useEffect(() => {
    setItems(props.tenantsAndTags);
  }, [props.tenantsAndTags]);

  useEffect(() => {
    if (props.resetData) {
      props.updateData([]);
    }
  }, [props.resetData]);

  const handleMultipleSelect = () => {
    setMultiple(true);
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setSelectedIndex(-1);
  };

  const handleRemoveItem = (selectedIndexValue) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndexValue !== -1) {
      selectedItems.splice(selectedIndexValue, 1);
    }
    props.updateData(selectedItems);
  };

  const handleMultipleItems = (selectedItems) => {
    const values = props.fields.getAll() || [];
    const selectedValues = [...values, ...selectedItems];
    props.updateData(selectedValues);
  };

  const handleSelectedItem = (selectedItem) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedItems.push(selectedItem);
    } else {
      selectedItems.splice(selectedIndex, 1, selectedItem);
    }

    props.updateData(selectedItems);
  };

  const filterSimpleItems = (selectedItems) => {
    const filteredData = (selectedItems && selectedItems.length > 0) ? items.filter((item) => {
      const index = selectedItems.findIndex((selectedItem) => {
        const selectedItemValue = selectedItem.value || selectedItem;
        return (item.id ? (item.id === selectedItemValue.id) : (item.data === selectedItemValue.data));
      });
      return (index === -1);
    }) : items;
    const modifiedValue = filteredData.map(item => ({
      ...item,
      icon: <FontAwesomeIcon
        icon={item.entityType === 'TenantGroup' ? faTags : faBuilding}
        style={{ marginRight: 5, color: props.theme.palette.primary.main }}
      />,
      key: item.name,
      data: item.id
    }));
    return modifiedValue;
  };

  const selectedItems = props.fields.getAll();
  const selectedItemList = filterSimpleItems(selectedItems);
  return (
    <div>
      <ItemList
        id="item-list"
        isEditable={false}
        isNoteEditable={false}
        selectedItems={selectedItems}
        handleRemoveItem={handleRemoveItem}
        type={props.label}
        {...props}
      />
      <ItemSelector
        id="item-selector"
        multiValue={true}
        disabled={props.disabled}
        createNewMenuItem={false}
        selectFromListMenuItem={true}
        dataSource={selectedItemList}
        dataSourceConfig={{ text: 'name', value: 'id' }}
        hintTextLabel={props.hintTextLabel}
        handleSelectedItem={handleSelectedItem}
        handleMultipleSelect={handleMultipleSelect}
      />
      {multiple && <MultipleSelectorDialog
        title={props.dialogHeaderLabel}
        open={multiple}
        filteredData={selectedItemList}
        onRequestClose={handleRequestClose}
        handleMultipleItems={handleMultipleItems}
      />}
    </div>
  );
};

TenantOrTagSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    removeAll: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  dialogHeaderLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  updateData: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  getTenantsAndTags: PropTypes.func,
  tenantsAndTags: PropTypes.arrayOf(PropTypes.shape({})),
  resetData: PropTypes.bool
};

TenantOrTagSelector.defaultProps = {
  disabled: false,
  items: [],
  theme: {},
  label: null,
  dialogHeaderLabel: null,
  hintTextLabel: null,
  updateData: e => e,
  getTenantsAndTags: e => e,
  tenantsAndTags: [],
  resetData: false
};

export default injectIntl(TenantOrTagSelector);
