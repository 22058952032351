import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { filterEmptySearchItems } from '@packages/utils/common-utils';
import { getCurrentMergeItems } from '../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  personalDataItems: Immutable.List(),
  newItems: Immutable.List(),
  personalDataCategories: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  pageSearchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  personalDataItemData: {},
  recordsFilterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  recordsFilteredOn: Immutable.Map(),
  usage: undefined,
  bulkItems: Immutable.List(),
  unused: false,
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('personalDataItemData', defaultState.get('personalDataItemData'));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('personalDataItems', defaultState.get('personalDataItems'))
        .set('newItems', defaultState.get('newItems'))
        .set('personalDataCategories', defaultState.get('personalDataCategories'))
        .set('searchText', defaultState.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('position', 0);
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.personalDataItemData) {
        newItems = newItems.unshift(action.personalDataItemData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('personalDataItems'), action.items);
      return newState
        .set('isFetching', false)
        .set('personalDataItems', Immutable.List(items))
        .set('position', state.get('position') + action.items.length)
        .set('personalDataCategories', Immutable.List(action.personalDataCategories));
    }
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FILTER': {
      if (action.source === 'records') {
        return state
          .set('personalDataItems', defaultState.get('personalDataItems'))
          .set('position', 0)
          .set('recordsFilterParams', Immutable.Map(action.filterParams))
          .set('recordsFilteredOn', getFilteredOn(Object.assign({}, action.filterParams,
            { filterObj: state.get('recordsFilteredOn') })))
          .set('filterParams', Immutable.Map(action.filterParams));
      }
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('personalDataItems', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:PAGE_SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('pageSearchText', getSearchText(action));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', getSearchItems(action));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:PAGE_SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('pageSearchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('pageSearchText').toJS() })))
        .set('personalDataItems', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH_FILTER:RESET':
      return state
        .set('isFetching', true)
        .set('searchText', defaultState.get('searchText'))
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('recordsFilteredOn', defaultState.get('recordsFilteredOn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('personalDataItems', defaultState.get('personalDataItems'));
      }
      return newState
        .set('isFetching', false)
        .set('personalDataItemData', action.personalDataItem)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:FAIL':
    case 'MASTERDATA:PERSONALDATAITEM:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:UPDATE:SUCCESS':
      return state.set('personalDataItems', action.items)
        .set('newItems', action.newItems)
        .set('position', action.position ? action.position : state.get('position'));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH':
      return state.set('personalDataItemData', defaultState.get('personalDataItemData'))
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:SUCCESS':
      return state.set('personalDataItemData', action.personalDataItemData);
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:PERSONALDATAITEMS:USAGE:RESET':
      return state
        .set('usage', defaultState.get('usage'));
    case 'MASTERDATA:PERSONALDATAITEMS:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:PERSONALDATAITEMS:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId, action.unused));
    }
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);
    case 'MASTERDATA:PERSONAL_DATA_ITEMS:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    case 'MASTERDATA:PERSONALDATAITEMS:VIEW:SIMILAR:SUCCESS':
      return state
        .set('personalDataItems', Immutable.List(action.items));
    default:
      return state;
  }
};

export const getSearchItems = ({ searchKey, items }) => {
  let listItem = [];
  const values = [];
  switch (searchKey) {
    case 'categories':
      items.map(item => (item.categories ?
        (item.categories.map(value => values.push(value.name))) : listItem.push('')));
      listItem = Array.from(new Set(values.map(item => item)));
      break;
    default:
      listItem = Array.from(new Set(items.map(item => item[searchKey])));
      break;
  }
  const searchItems = filterEmptySearchItems(listItem, searchKey);
  return searchItems;
};
