import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  supervisoryAuthorities: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SUPERVISORY_AUTHORITIES:LIST:REQUEST': {
      return state.set('supervisoryAuthorities', defaultState.get('supervisoryAuthorities'))
        .set('error', defaultState.get('error'));
    }
    case 'SUPERVISORY_AUTHORITIES:LIST:REQUEST:SUCCESS':
      return state.set('supervisoryAuthorities', Immutable.List(action.items));
    case 'SUPERVISORY_AUTHORITIES:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    default:
      return state;
  }
};
