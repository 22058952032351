import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class DataRecipientsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initDataRecipients) {
      this.props.initDataRecipients();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields === nextProps.fields) ||
    !(this.props.dataRecipients === nextProps.dataRecipients) ||
    !(this.state === nextState);
  }

  handleSearch() {
    if (this.props.onSearch) {
      this.props.onSearch();
    }
  }

  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render() {
    const { searchResults } = this.state;
    const { hintTextLabel, dataRecipients } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="name"
          dataItems={dataRecipients}
          searchResults={searchResults}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          onScrollStop={this.handleNextData}
          onSearch={this.handleSearch}
          title={
            <FormattedMessage
              id="MultipleDatarecipientDialog.header"
              description="Select datarecipient Header"
              defaultMessage="Select data recipients"
            />}
          {...this.props}
        />
      </div>
    );
  }
}

DataRecipientsSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initDataRecipients: PropTypes.func,
  dataRecipients: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func
};

DataRecipientsSelector.defaultProps = {
  hintTextLabel: null,
  fields: {},
  getNextData: e => e,
  position: 0,
  initDataRecipients: e => e,
  onSearch: e => e,
  searchResults: Immutable.List(),
  dataRecipients: Immutable.List()
};

export default DataRecipientsSelector;
