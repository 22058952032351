import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { FixedSizeList as List } from 'react-window';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ContentLink from '@material-ui/icons/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DivWrapper from '@packages/components/divWrapper';
import styles from '@packages/ui/styles';
import ArrowTooltip from '@packages/components/tooltip';

const rowHeight = 40;
const linkStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};
class GroupSelector extends React.Component {
  listRef = React.createRef();
  
  static renderLink(input) {
    const inputValue = input.value ? input.value : input;
    const value = inputValue.key ? inputValue.key : inputValue;
    return (
      <div style={linkStyle}>
        <ArrowTooltip title={value}>
          <span>{value}</span>
        </ArrowTooltip>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      showLinkGroupIcon: false
    };
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.props.prepareLinkGroup === nextProps.prepareLinkGroup);
  }

  getListItemStyle = (index) => {
    const itemIndex = index;
    return (itemIndex % 2) === 0 ? { backgroundColor: '#efefef' } : {};
  }

  getItemActionButtonStyle = index => (rowHeight * index) + 20

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };

  handleRowClick = (index) => {
    if (this.props.prepareLinkGroup) {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        showLinkGroupIcon: !this.state.showLinkGroupIcon
      });
    }
    this.props.toggleLinkGroupItem(this.props.entityType, index);
  }

  render() {
    const { dataItemType, fields } = this.props;
    const selectedItems = fields.getAll();

    // eslint-disable-next-line react/no-unstable-nested-components
    const Row = (props) => {
      const { index, style } = props;
      const data = fields.get(index) || {};
      let linkItemselected = false;
      const selectedItemValue = data.value ? data.value : data;
      if (data) {
        linkItemselected = selectedItemValue.selected;
      }
      const listItemStyle = { ...style, ...this.getListItemStyle(index), height: '40px', display: 'flex' };
      return (
        <div className="item-row-icons">
          <ListItem
            button={true}
            key={`${index}_${this.state.showLinkGroupIcon}_${dataItemType}`}
            onClick={() => this.handleRowClick(index)}
            style={listItemStyle}
            ContainerComponent="li"
            ContainerProps={{
              style: { display: 'flex' }
            }}
          >
            <ListItemText
              disableTypography={true}
              primary={
                <Field
                  name={`${dataItemType}[${index}]`}
                  key={`${index}_${this.state.showLinkGroupIcon}`}
                  component={() => GroupSelector.renderLink(selectedItemValue)}
                  data={selectedItemValue}
                />}
            />
            <ListItemSecondaryAction style={{ top: this.getItemActionButtonStyle(index) }}>
              <div>
                <div className="item-action-icons">
                  <div style={{ display: 'flex' }}>
                    {linkItemselected &&
                    <IconButton
                      style={styles.rightIcon}
                    >
                      <ContentLink
                        style={{ marginRight: '6px', color: '#00ad55' }}
                      />
                    </IconButton>
                }
                  </div>
                </div>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      );
    };

    const calculatedHeight = selectedItems && selectedItems.length <= 5 ? selectedItems.length * 40 : 200;

    return (
      <div>
        {selectedItems && selectedItems.length > 0 &&
        <DivWrapper
          autoHeight={false}
          autoHeightMax={calculatedHeight}
          onScroll={this.handleScroll}
          style={{ height: calculatedHeight, maxHeight: calculatedHeight }}
        >
          <List
            className="List"
            height={calculatedHeight}
            itemCount={selectedItems.length}
            itemSize={40}
            ref={this.listRef}
            style={{ overflow: 'none' }}
          >
            {Row}
          </List>
        </DivWrapper>}
        {(!selectedItems || (selectedItems && selectedItems.length <= 0)) &&
        <div>
          <FormattedMessage
            id="ProcessedData.noSelectedItems"
            defaultMessage="No items selected to display"
          />

        </div>
          }
      </div>
    );
  }
}

GroupSelector.propTypes = {
  dataItemType: PropTypes.string,
  entityType: PropTypes.string,
  prepareLinkGroup: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  toggleLinkGroupItem: PropTypes.func
};

GroupSelector.defaultProps = {
  dataItemType: '',
  entityType: '',
  prepareLinkGroup: true,
  toggleLinkGroupItem: () => {}
};

export default GroupSelector;
