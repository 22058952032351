import React, { useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import MultipleSelector from './multipleSelector';

const MultipleSelectorDialog = (props) => {
  useEffect(() => () => {
    props.onChooseFilter();
    if (props.showFilterByTag) props.tagSearch('');
  }, []);

  const handleOnSubmit = (selectedItems) => {
    if (props.handleMultipleItems) {
      if (props.displayAsChip) props.handleMultipleItems(selectedItems);
      else {
        const modifiedItems = selectedItems.map(item => ({ value: item }));
        props.handleMultipleItems(modifiedItems);
      }
    }
    requestClose();
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const { multiValue, onSearch, isSearchEnabled, filterTagValue, onChooseFilter, searchTextValue } = props;
  return (
    <CommonDialog
      id="multiple-selector-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={props.title}
    >
      <MultipleSelector
        id="multiple-selector"
        key={props.key}
        onScrollStop={props.onScrollStop}
        multiValue={multiValue}
        maxCount={props.maxCount}
        filteredData={props.filteredData}
        filterTags={filterTagValue}
        selectedEntitiesSize={props.selectedEntitiesSize}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        onSearch={onSearch}
        isSearchEnabled={isSearchEnabled}
        onChooseFilter={onChooseFilter}
        showFilterByTag={props.showFilterByTag}
        tagSearch={props.tagSearch}
        tagSearchResult={props.tagSearchResult}
        getNextTags={props.getNextTags}
        tagsPosition={props.tagsPosition}
        customContent={props.customContent}
        searchTextValue={searchTextValue}
      />
    </CommonDialog>
  );
};

MultipleSelectorDialog.propTypes = {
  key: PropTypes.string,
  title: PropTypes.node,
  multiValue: PropTypes.bool,
  displayAsChip: PropTypes.bool,
  filteredData: PropTypes.instanceOf(Immutable.List),
  onRequestClose: PropTypes.func,
  onScrollStop: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  open: PropTypes.bool,
  maxCount: PropTypes.number,
  selectedEntitiesSize: PropTypes.number,
  onSearch: PropTypes.func,
  isSearchEnabled: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  showFilterByTag: PropTypes.bool,
  filterTagValue: PropTypes.instanceOf(Immutable.List),
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagSearch: PropTypes.func,
  customContent: PropTypes.node,
  searchTextValue: PropTypes.string
};

MultipleSelectorDialog.defaultProps = {
  title: null,
  key: '',
  displayAsChip: false,
  open: false,
  multiValue: true,
  filteredData: Immutable.List(),
  onRequestClose: e => e,
  handleMultipleItems: e => e,
  onScrollStop: e => e,
  maxCount: undefined,
  selectedEntitiesSize: 0,
  onSearch: e => e,
  onChooseFilter: e => e,
  filterTagValue: Immutable.List(),
  showFilterByTag: false,
  isSearchEnabled: false,
  tagSearchResult: Immutable.List(),
  getNextTags: e => e,
  tagsPosition: 0,
  tagSearch: e => e,
  customContent: null,
  searchTextValue: ''
};

export default MultipleSelectorDialog;
