import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class SubProcessorsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initSubProcessors) {
      this.props.initSubProcessors();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.subProcessors === nextProps.subProcessors) || !(this.state === nextState);
  }

  handleSearch() {
    if (this.props.onSearch) {
      this.props.onSearch();
    }
  }

  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render () {
    const { searchResults } = this.state;
    const { hintTextLabel, subProcessors } = this.props;
    return (
      <div>
        <ValueSelector
          valueAttr="name"
          dataItems={subProcessors}
          searchResults={searchResults}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          onScrollStop={this.handleNextData}
          onSearch={this.handleSearch}
          title={
            <h3>
              <FormattedMessage
                id="MultipleSubProcessorsDialog.header"
                description="Select Subprocessors Dialog Header"
                defaultMessage="Select subprocessors"
              />
            </h3>}
          {...this.props}
        />
      </div>
    );
  }
}

SubProcessorsSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  initSubProcessors: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  subProcessors: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func
};

SubProcessorsSelector.defaultProps = {
  hintTextLabel: null,
  initSubProcessors: e => e,
  onSearch: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  subProcessors: Immutable.List()
};

export default SubProcessorsSelector;
