import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Cell } from 'fixed-data-table';
import { injectIntl } from 'react-intl';

import ArrowTooltip from '@packages/components/tooltip';

// DataArray cell
export const DataArrayCell = injectIntl(
  ({
    items,
    rowIndex,
    width,
    columnKey,
    mapper,
    defaultContent,
    intl,
    ...props
  }) => {
    const dataObj = items.get(rowIndex)[columnKey] || [];
    const contentArray = [];

    if (dataObj) {
      dataObj.forEach((data) => {
        const dataItem = data.value ? data.value.name : data;
        const item = mapper ? mapper(dataItem) : dataItem;
        contentArray.push(item);
      });
    }
    let content = [];
    contentArray.map((item) =>
      content.push(item.props ? intl.formatMessage(item.props) : item)
    );
    content = content.join(', ');

    return (
      <Cell {...props}>
        {contentArray.length === 0 ? (
          <ArrowTooltip title={defaultContent}>
            <div
              style={{
                width: width - 20,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span> {defaultContent} </span>
            </div>
          </ArrowTooltip>
        ) : (
          <ArrowTooltip title={content}>
            <div
              style={{
                width: width - 20,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span> {content} </span>
            </div>
          </ArrowTooltip>
        )}
      </Cell>
    );
  }
);

DataArrayCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number,
  columnKey: PropTypes.string,
  mapper: PropTypes.func,
  defaultContent: PropTypes.node,
  width: PropTypes.number
};

DataArrayCell.defaultProps = {
  items: Immutable.List(),
  rowIndex: -1,
  columnKey: '',
  mapper: (e) => e,
  width: 250,
  defaultContent: null
};
