import React from 'react';

import { FormattedMessage } from 'react-intl';
import threatTranslations from './threatTranslations';

export const singularTerms = (key) => {
  switch (key) {
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="masterdata.specialCharacteristics"
          defaultMessage="Special characteristics"
        />
      );
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="masterData.fairnessOfDecision"
          defaultMessage="Fairness of decisions"
        />
      );
    case 'maxSubTenants':
      return (
        <FormattedMessage
          id="Tenant.maxSubTenants"
          defaultMessage="Maximum sub-tenants"
        />
      );
    case 'holding':
      return <FormattedMessage id="Tenant.holding" defaultMessage="Holding" />;
    case 'holdingTenant':
      return (
        <FormattedMessage
          id="Module.holdingTenant"
          defaultMessage="Holding tenant"
        />
      );
    case 'SalesRep':
      return (
        <FormattedMessage
          id="PartnerType.SalesRep"
          defaultMessage="Sales rep"
        />
      );
    case 'taxNumber':
      return (
        <FormattedMessage
          id="Subscription.taxNumber"
          defaultMessage="Tax number"
        />
      );
    case 'import':
      return <FormattedMessage id="Service.import" defaultMessage="Import" />;
    case 'houseNumber':
      return (
        <FormattedMessage
          id="Subscription.houseNumber"
          defaultMessage="House number"
        />
      );
    case 'houseName':
      return (
        <FormattedMessage
          id="Subscription.houseName"
          defaultMessage="Address addendum"
        />
      );
    case 'street':
      return (
        <FormattedMessage id="Subscription.street" defaultMessage="Street" />
      );
    case 'apiIntegration':
      return (
        <FormattedMessage
          id="Pricing.apiIntegration"
          defaultMessage="API integration"
        />
      );
    case 'isms':
      return <FormattedMessage id="Pricing.isms" defaultMessage="ISMS" />;
    case 'gdpr':
      return <FormattedMessage id="Jurisdiction.gdpr" defaultMessage="GDPR" />;
    case 'ukgdpr':
      return (
        <FormattedMessage id="Jurisdiction.ukgdpr" defaultMessage="UK GDPR" />
      );
    case 'ccpa':
      return <FormattedMessage id="Jurisdiction.ccpa" defaultMessage="CCPA" />;
    case 'appi':
      return <FormattedMessage id="Jurisdiction.appi" defaultMessage="APPI" />;
    case 'pdpa':
      return (
        <FormattedMessage id="Jurisdiction.pdpa" defaultMessage="PDPA (TW)" />
      );
    case 'pipl':
      return <FormattedMessage id="Jurisdiction.pipl" defaultMessage="PIPL" />;
    case 'lgpd':
      return <FormattedMessage id="Jurisdiction.lgpd" defaultMessage="LGPD" />;
    case 'pdp':
      return <FormattedMessage id="Jurisdiction.pdp" defaultMessage="PDP" />;
    case 'fdpl':
      return <FormattedMessage id="Jurisdiction.fdpl" defaultMessage="FDPL" />;
    case 'sgpdpa':
      return (
        <FormattedMessage id="Jurisdiction.sgpdpa" defaultMessage="PDPA (SG)" />
      );
    case 'idpr':
      return (
        <FormattedMessage id="Jurisdiction.idpr" defaultMessage="EU IDPR" />
      );
    case 'tenantID':
      return <FormattedMessage id="Tenant.id" defaultMessage="Tenant ID" />;
    case 'tenantName':
      return <FormattedMessage id="Tenant.name" defaultMessage="Tenant name" />;
    case 'new':
      return <FormattedMessage id="Common.new" defaultMessage="New" />;
    case 'none':
      return <FormattedMessage id="Common.none" defaultMessage="None" />;
    case 'vendorForms':
      return (
        <FormattedMessage
          id="Vendor.vendorManagement"
          defaultMessage="Vendor management"
        />
      );
    case 'read':
      return <FormattedMessage id="Common.read" defaultMessage="Read" />;
    case 'write':
      return <FormattedMessage id="Common.write" defaultMessage="Write" />;
    case 'url':
      return <FormattedMessage id="Common.url" defaultMessage="URL" />;
    case 'dsr':
      return <FormattedMessage id="DSR.dsr" defaultMessage="DSR" />;
    case 'createdDate':
      return (
        <FormattedMessage id="DSR.createdDate" defaultMessage="Created Date" />
      );
    case 'lastUsed':
      return (
        <FormattedMessage id="common.lastUsed" defaultMessage="Last used" />
      );
    case 'messages':
      return (
        <FormattedMessage
          id="MessageCenter.message"
          description="Message"
          defaultMessage="Message"
        />
      );
    case 'dataRecipientCategory':
      return (
        <FormattedMessage
          id="Records.dataRecipientCategory"
          description="Data recipient category"
          defaultMessage="Data recipient category"
        />
      );
    case 'contactType':
      return (
        <FormattedMessage
          id="Organisation.contactType"
          description="Contact type"
          defaultMessage="Contact type"
        />
      );
    case 'tenant':
      return (
        <FormattedMessage
          id="Servicename.tenant"
          description="Tenant service name"
          defaultMessage="Tenant"
        />
      );
    case 'organisation':
      return (
        <FormattedMessage
          id="Servicename.organisation"
          description="Organisation service name"
          defaultMessage="Organisation"
        />
      );
    case 'records':
      return (
        <FormattedMessage
          id="Servicename.record"
          description="Privacy Records service name"
          defaultMessage="Record"
        />
      );
    case 'custom':
      return (
        <FormattedMessage id="Servicename.custom" defaultMessage="Custom" />
      );
    case 'user':
      return (
        <FormattedMessage
          id="Servicename.user"
          description="User service name"
          defaultMessage="User"
        />
      );
    case 'report':
      return (
        <FormattedMessage
          id="Servicename.report"
          description="Report service name"
          defaultMessage="Report"
        />
      );
    case 'document':
      return (
        <FormattedMessage
          id="Servicename.document"
          description="Document service name"
          defaultMessage="Document"
        />
      );
    case 'notification':
      return (
        <FormattedMessage
          id="Servicename.mail"
          description="Notification service name"
          defaultMessage="Notification"
        />
      );
    case 'task':
      return (
        <FormattedMessage
          id="Servicename.task"
          description="Task service name"
          defaultMessage="Task"
        />
      );
    case 'masterData':
      return (
        <FormattedMessage
          id="Servicename.masterDataService"
          description="Master Data service name"
          defaultMessage="Master Data"
        />
      );
    case 'audit':
      return (
        <FormattedMessage
          id="Servicename.auditService"
          description="Audit service name"
          defaultMessage="Audit"
        />
      );
    case 'jobs':
      return (
        <FormattedMessage
          id="Servicename.jobService"
          description="Job service name"
          defaultMessage="Job"
        />
      );
    case 'dataSubjectCategory':
      return (
        <FormattedMessage
          id="RecordDetails.DataSubjectCategory"
          description="Data subject category"
          defaultMessage="data subject category"
        />
      );
    case 'personalDataItem':
      return (
        <FormattedMessage
          id="Record.personalDataItem"
          description="Personal data Item"
          defaultMessage="Personal data item"
        />
      );
    case 'personalDataCategory':
      return (
        <FormattedMessage
          id="RecordDetails.personalDataCategory"
          description="Personal data category"
          defaultMessage="personal data category"
        />
      );
    case 'processingCategory':
      return (
        <FormattedMessage
          id="RecordDetails.processingCategory"
          description="Processing Category"
          defaultMessage="Processing category"
        />
      );
    case 'securityMeasure':
      return (
        <FormattedMessage
          id="RecordDetails.securityMeasure"
          description="Security measure"
          defaultMessage="Security measure"
        />
      );
    case 'technicalSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.technicalMeasure"
          description="Technical security measure"
          defaultMessage="Technical security measure"
        />
      );
    case 'specialSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.specialMeasure"
          defaultMessage="Special security measure"
        />
      );
    case 'organisationalSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.organisationalMeasure"
          description="Organisational security measure"
          defaultMessage="Organisational security measure"
        />
      );
    case 'contractualSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.contractualSecurityMeasure"
          defaultMessage="Contractual security measure"
        />
      );
    case 'securityMeasureDescription':
      return (
        <FormattedMessage
          id="RecordDetails.securityMeasure1"
          description="Security measure description"
          defaultMessage="Security measure description"
        />
      );
    case 'retentionTermOffset':
      return (
        <FormattedMessage
          id="RecordDetails.retentionTermOffset"
          description="Retention term offset"
          defaultMessage="Retention term offset"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="Record.reference"
          description="Reference"
          defaultMessage="Reference"
        />
      );
    case 'entity':
      return (
        <FormattedMessage
          id="Common.entity"
          description="Entity"
          defaultMessage="Entity"
        />
      );
    case 'measure':
      return (
        <FormattedMessage
          id="Common.measure"
          description="Measure"
          defaultMessage="Measure"
        />
      );
    case 'note':
      return (
        <FormattedMessage
          id="Common.note"
          description="Note"
          defaultMessage="Note"
        />
      );
    case 'unused':
      return (
        <FormattedMessage
          id="MasterData.unused"
          description="Unused"
          defaultMessage="Unused"
        />
      );
    case 'banner':
      return <FormattedMessage id="Admin.banner" defaultMessage="Banner" />;
    case 'incident':
      return (
        <FormattedMessage id="Message.incident" defaultMessage="Incident" />
      );
    case 'warning':
      return <FormattedMessage id="Message.warning" defaultMessage="Warning" />;
    case 'alert':
      return <FormattedMessage id="Message.alert" defaultMessage="Alert" />;
    case 'information':
      return (
        <FormattedMessage
          id="Message.information"
          defaultMessage="Information"
        />
      );
    case 'all':
      return (
        <FormattedMessage
          id="PrivacyRecordsFilter.all"
          description="Privacy Records Filter - All"
          defaultMessage="All"
        />
      );
    case 'companyAccess':
      return (
        <FormattedMessage
          id="Records.companyAccess"
          description="Privacy Record field company access"
          defaultMessage="Company access"
        />
      );
    case 'general':
      return (
        <FormattedMessage
          id="Records.general"
          description="General"
          defaultMessage="General"
        />
      );
    case 'lastChanged':
      return (
        <FormattedMessage
          id="PrivacyRecordList.lastChanged"
          description="Privacy Record List Column LastChanged"
          defaultMessage="Last Changed"
        />
      );
    case 'preAssessment':
      return (
        <FormattedMessage
          id="Record.preAssessment"
          description="Stakeholders title"
          defaultMessage="Pre-assessment"
        />
      );
    case 'fullAssessment':
      return (
        <FormattedMessage
          id="Record.fullAssessment"
          description="full aseessment title"
          defaultMessage="Full-assessment"
        />
      );
    case 'risk':
      return (
        <FormattedMessage
          id="Record.risk"
          description="risk"
          defaultMessage="Risk"
        />
      );
    case 'noRiskLevel':
      return (
        <FormattedMessage
          id="Record.noRiskLevel"
          description="no risk"
          defaultMessage="None"
        />
      );
    case 'lowRiskLevel':
      return (
        <FormattedMessage
          id="Record.lowRiskLevel"
          description="low risk"
          defaultMessage="Low"
        />
      );
    case 'mediumRiskLevel':
      return (
        <FormattedMessage
          id="Record.mediumRiskLevel"
          description="medium risk"
          defaultMessage="Medium"
        />
      );
    case 'highRiskLevel':
      return (
        <FormattedMessage
          id="Record.highRiskLevel"
          description="high risk"
          defaultMessage="High"
        />
      );
    case 'highRiskText':
      return (
        <FormattedMessage
          id="Record.highRiskText"
          description="High risk"
          defaultMessage="High risk"
        />
      );
    case 'mediumRiskText':
      return (
        <FormattedMessage
          id="Record.mediumRiskText"
          description="Medium risk"
          defaultMessage="Medium risk"
        />
      );
    case 'mitigated':
      return (
        <FormattedMessage
          id="Record.mitigated"
          description="mitigated"
          defaultMessage="Mitigated"
        />
      );
    case 'unMitigated':
      return (
        <FormattedMessage
          id="Record.unMitigated"
          defaultMessage="Unmitigated"
        />
      );
    case 'riskEstimate':
      return (
        <FormattedMessage
          id="Record.riskEstimate"
          description="Risk estimate"
          defaultMessage="Risk estimate"
        />
      );
    case 'item':
      return (
        <FormattedMessage
          id="Record.item"
          description="Item"
          defaultMessage="Item"
        />
      );
    case 'csvFull':
      return (
        <FormattedMessage
          id="Record.csvFull"
          description="CSV (Full)"
          defaultMessage="CSV (Full)"
        />
      );
    case 'Datarecipient':
    case 'Data recipient':
      return (
        <FormattedMessage
          id="Record.Datarecipient"
          description="Data recipient"
          defaultMessage="Data recipient"
        />
      );
    case 'legitimatetransferPurposeHint':
      return (
        <FormattedMessage
          id="RecordDetails.legitimatetransferPurposeHint"
          description="Hint text for Legitimate transfer"
          defaultMessage="Purpose for the transfer..."
        />
      );
    case 'transferPurpose':
      return (
        <FormattedMessage
          id="AssessmentDetails.transferPurpose"
          description="Purpose for the transfer"
          defaultMessage="Transfer purpose"
        />
      );
    case 'executingEntityType':
    case 'Executing entity':
      return (
        <FormattedMessage
          id="RecordDetails.executingEntityType"
          description="Executing entity"
          defaultMessage="Executing entity"
        />
      );
    case 'processedData':
      return (
        <FormattedMessage
          id="RecordDetails.processedDataTitle"
          description="Processed data"
          defaultMessage="Processed data"
        />
      );
    case 'legalQualificationsJurisdiction':
      return (
        <FormattedMessage
          id="RecordDetails.legalQualificationsJurisdiction"
          description="Legal qualifications Jurisdiction"
          defaultMessage="Legal qualifications jurisdiction"
        />
      );
    case 'legalQualificationsLaw':
      return (
        <FormattedMessage
          id="RecordDetails.legalQualificationsLaw"
          description="Legal qualifications law"
          defaultMessage="Legal qualifications law"
        />
      );
    case 'type':
      return (
        <FormattedMessage
          id="Record.type"
          description="Type"
          defaultMessage="Type"
        />
      );
    case 'recordId':
      return (
        <FormattedMessage
          id="RecordList.id"
          description="ID"
          defaultMessage="ID"
        />
      );
    case 'dataItemType':
      return (
        <FormattedMessage
          id="Jobs.dataItemType"
          description="Data item type"
          defaultMessage="Data item type"
        />
      );
    case 'dataItemName':
      return (
        <FormattedMessage
          id="Jobs.dataItemName"
          description="Data item name"
          defaultMessage="Data item name"
        />
      );
    case 'operation':
      return (
        <FormattedMessage
          id="Jobs.operation"
          description="Operation"
          defaultMessage="Operation"
        />
      );
    case 'Controller':
      return (
        <FormattedMessage
          id="Record.Controller"
          description="Controller"
          defaultMessage="Controller"
        />
      );
    case 'Processor':
      return (
        <FormattedMessage
          id="Record.Processor"
          description="Processor"
          defaultMessage="Processor"
        />
      );
    case 'status':
      return (
        <FormattedMessage
          id="Record.status"
          description="Status"
          defaultMessage="Status"
        />
      );
    case 'dataSource':
      return (
        <FormattedMessage
          id="Record.dataSource"
          description="Data source"
          defaultMessage="Data source"
        />
      );
    case 'dataSourceCategory':
      return (
        <FormattedMessage
          id="Record.dataSourceCategory"
          description="Data source category"
          defaultMessage="Data source category"
        />
      );
    case 'retentionTerm':
      return (
        <FormattedMessage
          id="PersonalDataItem.retentionTerm"
          description="Personal DataItem List Column Retention Term"
          defaultMessage="Retention Term"
        />
      );
    case 'hostedBy':
      return (
        <FormattedMessage
          id="DataSource.organisationHost"
          description="Data source hosted by"
          defaultMessage="Hosted by"
        />
      );
    case 'contactPerson':
      return (
        <FormattedMessage
          id="DataSource.administrator"
          description="Data Source administrator"
          defaultMessage="Contact person"
        />
      );
    case 'dataStorageCountry':
      return (
        <FormattedMessage
          id="DataSource.country"
          description="Country of data storage"
          defaultMessage="Country of data storage"
        />
      );
    case 'requestApproval':
      return (
        <FormattedMessage
          id="Record.requestApproval"
          description="Request Approval"
          defaultMessage="Request Approval"
        />
      );
    case 'markProvisional':
      return (
        <FormattedMessage
          id="Record.markProvisional"
          description="Mark Provisional"
          defaultMessage="Mark Provisional"
        />
      );
    case 'subProcessor':
      return (
        <FormattedMessage
          id="Record.subProcessor"
          description="Subprocessor"
          defaultMessage="Subprocessor"
        />
      );
    case 'startDate':
      return (
        <FormattedMessage
          id="Record.startDate"
          description="Start Date"
          defaultMessage="Start Date"
        />
      );
    case 'endDate':
      return (
        <FormattedMessage
          id="Record.endDate"
          description="End date"
          defaultMessage="End date"
        />
      );
    case 'necessities':
      return (
        <FormattedMessage
          id="Record.necessities"
          description="Necessity"
          defaultMessage="Necessity"
        />
      );
    case 'name':
      return (
        <FormattedMessage
          id="Common.name"
          description="Name"
          defaultMessage="Name"
        />
      );
    case 'description':
      return (
        <FormattedMessage
          id="Record.description"
          description="Description"
          defaultMessage="Description"
        />
      );
    case 'outcome':
      return (
        <FormattedMessage
          id="Record.outcome"
          description="Outcome of pre-assessment"
          defaultMessage="Outcome of pre-assessment"
        />
      );
    case 'outcomeHeader':
      return (
        <FormattedMessage
          id="Record.outcomeHeader"
          description="Outcome"
          defaultMessage="Outcome"
        />
      );
    case 'sectionOutcome':
      return (
        <FormattedMessage
          id="Record.sectionOutcome"
          description="Outcome of this section"
          defaultMessage="Outcome of this section"
        />
      );
    case 'chapterOutcome':
      return (
        <FormattedMessage
          id="Record.chapterOutcome"
          description="Outcome of this chapter"
          defaultMessage="Outcome of this chapter"
        />
      );
    case 'assessmentPurpose':
      return (
        <FormattedMessage
          id="Record.assessmentPurpose"
          description="assessmentPurpose"
          defaultMessage="Purpose for the assessment project"
        />
      );
    case 'processingPurpose':
      return (
        <FormattedMessage
          id="ProcessingDetails.legitimatePurpose"
          description="Purpose for the processing"
          defaultMessage="Purpose for the processing"
        />
      );
    case 'purpose':
      return (
        <FormattedMessage
          id="Record.purpose"
          description="Purpose"
          defaultMessage="Purpose"
        />
      );
    case 'purposeOfTransfer':
      return (
        <FormattedMessage
          id="Record.purposeOfTransfer"
          description="Purpose of the transfer"
          defaultMessage="Purpose of the transfer"
        />
      );
    case 'natureOfBreach':
      return (
        <FormattedMessage
          id="Record.breachNature"
          description="Select nature of breach"
          defaultMessage="Nature of the breach"
        />
      );
    case 'supervisoryNotification':
      return (
        <FormattedMessage
          id="Record.supervisoryNotification"
          description="Supervisory authority notification"
          defaultMessage="Supervisory authority notification"
        />
      );
    case 'dataSubjectNotification':
      return (
        <FormattedMessage
          id="Record.dataSubjectNotification"
          description="data subject authority notification"
          defaultMessage="Data subject notification"
        />
      );
    case 'awareness':
      return (
        <FormattedMessage
          id="Record.awareness"
          description="Awareness"
          defaultMessage="Awareness"
        />
      );
    case 'awarenessDate':
      return (
        <FormattedMessage
          id="Breach.awarenessDate"
          description="Awareness Date"
          defaultMessage="Awareness Date"
        />
      );
    case 'awarenessTime':
      return (
        <FormattedMessage
          id="Record.awarenessTime"
          description="Awareness time"
          defaultMessage="Awareness time"
        />
      );
    case 'attachmentsPermission':
      return (
        <FormattedMessage
          id="Settings.attachmentsPermission"
          description="Attachments/Permissions"
          defaultMessage="Attachments/Permissions"
        />
      );
    case 'public':
      return (
        <FormattedMessage
          id="Common.public"
          description="Public"
          defaultMessage="Public"
        />
      );
    case 'private':
      return (
        <FormattedMessage
          id="Common.private"
          description="Private"
          defaultMessage="Private"
        />
      );
    case 'externalLink':
      return (
        <FormattedMessage
          id="Common.externalLink"
          description="External link"
          defaultMessage="External link"
        />
      );
    case 'owner':
      return (
        <FormattedMessage
          id="Common.owner"
          description="Owner"
          defaultMessage="Owner"
        />
      );
    case 'next':
      return (
        <FormattedMessage
          id="Common.next"
          description="Next"
          defaultMessage="Next"
        />
      );
    case 'previous':
      return (
        <FormattedMessage id="Common.previous" defaultMessage="Previous" />
      );
    case 'other':
      return (
        <FormattedMessage
          id="Common.other"
          description="Other"
          defaultMessage="Other"
        />
      );
    case 'add':
      return (
        <FormattedMessage
          id="Common.add"
          description="Add"
          defaultMessage="Add"
        />
      );
    case 'emptyFields':
      return (
        <FormattedMessage
          id="Common.emptyFields"
          description="Empt fields"
          defaultMessage="Empty fields"
        />
      );
    case 'replace':
      return (
        <FormattedMessage
          id="Common.replace"
          description="Replace"
          defaultMessage="Replace"
        />
      );
    case 'privacyRecordNumber':
      return (
        <FormattedMessage
          id="RecordList.id"
          description="ID"
          defaultMessage="ID"
        />
      );
    case 'informationRights':
      return (
        <FormattedMessage
          id="masterData.information"
          description="Rights to information"
          defaultMessage="Rights to information"
        />
      );
    case 'consentRights':
      return (
        <FormattedMessage
          id="masterData.consentRights"
          description="Validities of consent"
          defaultMessage="Validities of consent"
        />
      );
    case 'accessRights':
      return (
        <FormattedMessage
          id="masterData.accessRights"
          defaultMessage="Elements of the right to access and rectification"
        />
      );
    case 'objectionRights':
      return (
        <FormattedMessage
          id="masterData.objectionRights"
          description="Rights to objection"
          defaultMessage="Rights to objection"
        />
      );
    case 'deletionRights':
      return (
        <FormattedMessage
          id="masterData.deletionRights"
          defaultMessage="Elements of the rights to erasure"
        />
      );
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="masterData.dataPortabilityRights"
          defaultMessage="Elements of the right to data portability"
        />
      );
    case 'confidentialityThreats':
      return (
        <FormattedMessage
          id="Environment.confidentialityThreat"
          description="Threats to confidentiality"
          defaultMessage="Threats to confidentiality"
        />
      );
    case 'integrityThreats':
      return (
        <FormattedMessage
          id="Environment.integrityThreat"
          description="Threats to integrity"
          defaultMessage="Threats to integrity"
        />
      );
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="Environment.availabilityThreat"
          description="Threats to availability"
          defaultMessage="Threats to availability"
        />
      );
    case 'confidentialityImpacts':
      return (
        <FormattedMessage
          id="Environment.confidentialityImpact"
          description="ID"
          defaultMessage="ID"
        />
      );
    case 'integrityImpacts':
      return (
        <FormattedMessage
          id="Environment.integrityImpact"
          description="Impacts to integrity"
          defaultMessage="Impacts to integrity"
        />
      );
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="Environment.availabilityImpact"
          description="Impacts to availability"
          defaultMessage="Impacts to availability"
        />
      );
    case 'confidentialityMitigatingMeasures':
      return (
        <FormattedMessage
          id="masterData.confidentialityMitigatingMeasures"
          description="Confidentiality mitigating measures"
          defaultMessage="Confidentiality mitigating measures"
        />
      );
    case 'integrityMitigatingMeasures':
      return (
        <FormattedMessage
          id="Environment.availabilityImpact"
          description="Impacts to availability"
          defaultMessage="Impacts to availability"
        />
      );
    case 'availabilityMitigatingMeasures':
      return (
        <FormattedMessage
          id="masterData.availabilityMitigatingMeasures"
          description="Availability mitigating measures"
          defaultMessage="Availability mitigating measures"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Environment.policies"
          description="Policy"
          defaultMessage="Policy"
        />
      );
    case 'unknown':
      return (
        <FormattedMessage
          id="Common.unknown"
          description="Unknown"
          defaultMessage="Unknown"
        />
      );
    case 'indefinite':
      return (
        <FormattedMessage id="Common.indefinite" defaultMessage="Indefinite" />
      );
    case 'country':
      return (
        <FormattedMessage
          id="Common.country"
          description="Country"
          defaultMessage="Country"
        />
      );
    case 'submit':
      return (
        <FormattedMessage
          id="Common.Submit"
          description="Submit"
          defaultMessage="Submit"
        />
      );
    case 'Yes':
      return (
        <FormattedMessage
          id="Common.Yes"
          description="Yes"
          defaultMessage="Yes"
        />
      );
    case 'No':
      return (
        <FormattedMessage id="Common.No" description="No" defaultMessage="No" />
      );
    case 'Cancel':
      return (
        <FormattedMessage
          id="Common.cancel"
          description="Cancel"
          defaultMessage="Cancel"
        />
      );
    case 'Off':
      return (
        <FormattedMessage
          id="MFASettings.off"
          description="Off"
          defaultMessage="Off"
        />
      );
    case 'Test':
      return (
        <FormattedMessage
          id="Common.test"
          description="Test"
          defaultMessage="Test"
        />
      );
    case 'Live':
      return (
        <FormattedMessage
          id="Common.live"
          description="Live"
          defaultMessage="Live"
        />
      );
    case 'SPinitiated':
      return (
        <FormattedMessage
          id="Common.SPinitiated"
          description="SP initiated"
          defaultMessage="SP initiated"
        />
      );
    case 'IdpInitiated':
      return (
        <FormattedMessage
          id="Common.IdpInitiated"
          description="Idp initiated"
          defaultMessage="Idp initiated"
        />
      );
    case 'minute':
      return (
        <FormattedMessage
          id="Common.Minute"
          description="Minute"
          defaultMessage="Minute"
        />
      );
    case 'hour':
      return (
        <FormattedMessage
          id="Common.Hour"
          description="Hour"
          defaultMessage="Hour"
        />
      );
    case 'day':
      return (
        <FormattedMessage
          id="Common.Day"
          description="Day"
          defaultMessage="Day"
        />
      );
    case 'week':
      return (
        <FormattedMessage
          id="Common.Week"
          description="Week"
          defaultMessage="Week"
        />
      );
    case 'month':
      return (
        <FormattedMessage
          id="Common.Month"
          description="Month"
          defaultMessage="Month"
        />
      );
    case 'year':
      return (
        <FormattedMessage
          id="Common.Year"
          description="Year"
          defaultMessage="Year"
        />
      );
    case 'emailLabel':
      return (
        <FormattedMessage
          id="Common.emailLabel"
          description="Email"
          defaultMessage="Email"
        />
      );
    case 'phonenumber':
    case 'workPhone':
      return (
        <FormattedMessage
          id="Common.phonenumber"
          description="Phone number"
          defaultMessage="Phone number"
        />
      );
    case 'search':
      return (
        <FormattedMessage
          id="Common.Search"
          description="Search"
          defaultMessage="Search"
        />
      );
    case 'category':
      return (
        <FormattedMessage
          id="Common.category"
          description="Category"
          defaultMessage="Category"
        />
      );
    case 'firstname':
      return (
        <FormattedMessage
          id="Common.firstname"
          description="First name"
          defaultMessage="First name"
        />
      );
    case 'lastname':
      return (
        <FormattedMessage id="Profile.lastName" defaultMessage="Last name" />
      );
    case 'Ok':
      return (
        <FormattedMessage id="Common.Ok" description="Ok" defaultMessage="Ok" />
      );
    case 'graphicalView':
      return (
        <FormattedMessage
          id="Records.graphicalView"
          description="Graphical view"
          defaultMessage="Graphical view"
        />
      );
    case 'formView':
      return (
        <FormattedMessage
          id="Records.formView"
          description="Form view"
          defaultMessage="Form view"
        />
      );
    case 'showRiskHint':
      return (
        <FormattedMessage
          id="Records.showRisks"
          description="Show risks"
          defaultMessage="Show risks"
        />
      );
    case 'hideRiskHint':
      return (
        <FormattedMessage
          id="Records.hideRisks"
          description="Hide risks"
          defaultMessage="Hide risks"
        />
      );
    case 'copyRecord':
      return (
        <FormattedMessage
          id="PrivacyRecord.copyRecord"
          description="Copy record"
          defaultMessage="Copy record"
        />
      );
    case 'viewChangeLog':
      return (
        <FormattedMessage
          id="PrivacyRecord.changelog"
          description="View changelog"
          defaultMessage="View changelog"
        />
      );
    case 'acceptRecord':
      return (
        <FormattedMessage
          id="ProcessingDetails.acceptRecord"
          description="Accept Record"
          defaultMessage="Accept Record"
        />
      );
    case 'rejectRecord':
      return (
        <FormattedMessage
          id="ProcessingDetails.rejectRecord"
          description="Reject Record"
          defaultMessage="Reject Record"
        />
      );
    case 'promoteToProcessing':
      return (
        <FormattedMessage
          id="AssessmentDetails.promoteButton"
          description="Promote to processing"
          defaultMessage="Promote to processing"
        />
      );
    case 'promoteToAssessment':
      return (
        <FormattedMessage
          id="ProcessingDetails.promoteButton"
          description="Promote to assessment"
          defaultMessage="Promote to assessment"
        />
      );
    case 'promoteToBreach':
      return (
        <FormattedMessage
          id="ProcessingDetails.promoteToBreach"
          description="Promote to breach"
          defaultMessage="Promote to breach"
        />
      );
    case 'reference':
    case 'notificationReferences':
      return (
        <FormattedMessage
          id="Record.reference"
          description="Reference"
          defaultMessage="Reference"
        />
      );
    case 'assessmentType':
      return (
        <FormattedMessage
          id="recordTranslations.assessmentType"
          description="Assessment Type"
          defaultMessage="Assessment Type"
        />
      );
    case 'jurisdiction':
      return (
        <FormattedMessage
          id="Legalground.jurisdiction"
          description="Jurisdiction"
          defaultMessage="Jurisdiction"
        />
      );
    case 'configuration':
      return (
        <FormattedMessage
          id="Assessments.Configuration"
          description="Configuration"
          defaultMessage="Configuration"
        />
      );
    case 'processingDescription':
      return (
        <FormattedMessage
          id="Assessments.processingDescription"
          description="Processing description"
          defaultMessage="Processing description"
        />
      );
    case 'necessityProportionality':
    case 'necessityAndProportionality':
      return (
        <FormattedMessage
          id="Assessments.necessityProportionality"
          description="Necessity and proportionality"
          defaultMessage="Necessity and proportionality"
        />
      );
    case 'accountability':
      return (
        <FormattedMessage
          id="Assessments.accountability"
          description="Accountability for assessment"
          defaultMessage="Accountability"
        />
      );
    case 'summary':
      return (
        <FormattedMessage
          id="Record.summaryHeader"
          description="Summary"
          defaultMessage="Summary"
        />
      );
    case 'commentary':
      return (
        <FormattedMessage
          id="AssessmentDetails.commentary"
          description="Commentary label"
          defaultMessage="Commentary"
        />
      );
    case 'threatImpact':
    case 'impactAssessment':
      return (
        <FormattedMessage
          id="Assessments.threatImpact"
          description="Threat/impacts assessment"
          defaultMessage="Threat/impacts assessment"
        />
      );
    case 'rights':
      return (
        <FormattedMessage
          id="MasterDataItem.right"
          description="right"
          defaultMessage="right"
        />
      );
    case 'generalSection':
      return (
        <FormattedMessage
          id="Settings.generalSection"
          description="General Section"
          defaultMessage="General Section"
        />
      );
    case 'Activated':
      return (
        <FormattedMessage
          id="TenantStatus.activated"
          description="Activated"
          defaultMessage="Activated"
        />
      );
    case 'Created':
      return (
        <FormattedMessage
          id="TenantStatus.created"
          description="Created"
          defaultMessage="Created"
        />
      );
    case 'Failed':
      return (
        <FormattedMessage
          id="TenantStatus.failed"
          description="Failed"
          defaultMessage="Failed"
        />
      );
    case 'Expired':
      return (
        <FormattedMessage
          id="TenantStatus.expired"
          description="Expired"
          defaultMessage="Expired"
        />
      );
    case 'Deleted':
      return (
        <FormattedMessage id="TenantStatus.deleted" defaultMessage="Deleted" />
      );
    case 'breachStatus':
      return (
        <FormattedMessage
          id="Breach.status"
          description="Breach Status"
          defaultMessage="Breach status"
        />
      );
    case 'scope':
      return (
        <FormattedMessage
          id="Breach.scope"
          description="Breach scope"
          defaultMessage="Breach scope"
        />
      );
    case 'awarenessMethod':
      return (
        <FormattedMessage
          id="Breach.awarenessMethod"
          description="Awareness method"
          defaultMessage="Awareness method"
        />
      );
    case 'notificationDate':
      return (
        <FormattedMessage
          id="Breach.notificationDate"
          description="Hint text for breach notification date"
          defaultMessage="Notification date"
        />
      );
    case 'notificationText':
      return (
        <FormattedMessage
          id="Breach.notificationText"
          description="Notification text"
          defaultMessage="Notification text"
        />
      );
    case 'record_status_provisional':
      return (
        <FormattedMessage
          id="Status.Provisional"
          description="Status value Provisional"
          defaultMessage="Provisional"
        />
      );
    case 'record_status_requested':
      return (
        <FormattedMessage
          id="Status.Requested"
          description="Status value Requested"
          defaultMessage="Requested"
        />
      );
    case 'record_status_approved':
      return (
        <FormattedMessage
          id="Status.Approved"
          description="Status value Approved"
          defaultMessage="Approved"
        />
      );
    case 'record_status_disapproved':
      return (
        <FormattedMessage
          id="Status.Disapproved"
          description="Status value Disapproved"
          defaultMessage="Disapproved"
        />
      );
    case 'record_status_withdrawn':
      return (
        <FormattedMessage
          id="Status.Withdrawn"
          description="Status value Withdrawn"
          defaultMessage="Withdrawn"
        />
      );
    case 'record_status_redundant':
      return (
        <FormattedMessage
          id="Status.Redundant"
          description="Status value Redundant"
          defaultMessage="Redundant"
        />
      );
    case 'record_status_draft':
      return (
        <FormattedMessage
          id="Status.Draft"
          description="Status value Draft"
          defaultMessage="Draft"
        />
      );
    case 'record_status_review_soon':
      return (
        <FormattedMessage id="Status.ReviewSoon" defaultMessage="Review Soon" />
      );
    case 'record_status_review_overdue':
      return (
        <FormattedMessage
          id="Status.ReviewOverdue"
          defaultMessage="Review Overdue"
        />
      );
    case 'completed':
      return (
        <FormattedMessage
          id="Jobs.completed"
          description="Completed"
          defaultMessage="Completed"
        />
      );
    case 'failed':
      return (
        <FormattedMessage
          id="Jobs.failed"
          description="Failed"
          defaultMessage="Failed"
        />
      );
    case 'in_progress':
      return (
        <FormattedMessage
          id="Jobs.inProgress"
          description="In Progress"
          defaultMessage="In Progress"
        />
      );
    case 'submitted':
      return (
        <FormattedMessage
          id="Jobs.submitted"
          description="Submitted"
          defaultMessage="Submitted"
        />
      );
    case 'terminated':
      return (
        <FormattedMessage
          id="Jobs.terminated"
          description="Terminated"
          defaultMessage="Terminated"
        />
      );
    case 'completedWithErrors':
      return (
        <FormattedMessage
          id="BulkImport.completedWithError"
          defaultMessage="Completed with errors"
        />
      );
    case 'notStarted':
      return (
        <FormattedMessage id="Status.notStarted" defaultMessage="Not started" />
      );
    case 'normal':
      return (
        <FormattedMessage
          id="PersonalDataItem.Type.normal"
          description="Personal Data Item Category - Normal"
          defaultMessage="Normal"
        />
      );
    case 'special':
      return (
        <FormattedMessage
          id="PersonalDataItem.Type.special"
          description="Personal Data Item Category - Special"
          defaultMessage="Special"
        />
      );
    case 'personal_number':
      return (
        <FormattedMessage
          id="PersonalDataItem.Type.Idnumber"
          description="Personal Data Item Category - Personal Number"
          defaultMessage="ID Number"
        />
      );
    case 'crime_related':
      return (
        <FormattedMessage
          id="PersonalDataItem.Type.crimeRelated"
          description="Personal Data Item Category - Crime related"
          defaultMessage="Crime_related"
        />
      );
    case 'other_sensitive_data':
      return (
        <FormattedMessage
          id="PersonalDataItem.Type.otherSensitiveData"
          defaultMessage="Other sensitive data (CCPA)"
        />
      );
    case 'internal':
      return (
        <FormattedMessage
          id="Organisation.internal"
          description="Organisation Type"
          defaultMessage="Internal"
        />
      );
    case 'external':
      return (
        <FormattedMessage
          id="Organisation.external"
          description="Organisation Type"
          defaultMessage="External"
        />
      );
    case 'entityType':
      return (
        <FormattedMessage
          id="Organisation.entityType"
          description="Organisation Entity Type"
          defaultMessage="Entity type"
        />
      );
    case 'internalOrExternal':
      return (
        <FormattedMessage
          id="Organisation.internalOrExternal"
          description="Organisation Internal/external"
          defaultMessage="Int/Ext"
        />
      );
    case 'entityName':
      return (
        <FormattedMessage
          id="OrganisationEntity.nameHint"
          description="Organisation entity name"
          defaultMessage="Entity name"
        />
      );
    case 'isPartOf':
      return (
        <FormattedMessage
          id="Organisation.isPartOf"
          description="Entity is part of"
          defaultMessage="Part of"
        />
      );
    case 'email':
      return (
        <FormattedMessage
          id="Common.emailLabel"
          description="Email"
          defaultMessage="Email"
        />
      );
    case 'address':
      return (
        <FormattedMessage
          id="Common.address"
          description="Address"
          defaultMessage="Address"
        />
      );
    case 'Reseller':
      return (
        <FormattedMessage
          id="Partners.Reseller"
          description="Reseller"
          defaultMessage="Reseller"
        />
      );
    case 'Referrer':
      return (
        <FormattedMessage
          id="Partners.Referrer"
          description="Referrer"
          defaultMessage="Referrer"
        />
      );
    case 'Consultant':
      return (
        <FormattedMessage
          id="Partners.Consultant"
          description="Consultant"
          defaultMessage="Consultant"
        />
      );
    case 'DPO':
      return (
        <FormattedMessage
          id="Partners.DPO"
          description="DPO"
          defaultMessage="DPO"
        />
      );
    case 'Integrator':
      return (
        <FormattedMessage
          id="Partners.Integrator"
          description="Integrator"
          defaultMessage="Integrator"
        />
      );
    case 'transferImpactAssessment':
    case 'tia':
      return (
        <FormattedMessage
          id="Type.transferImpactAssessment"
          defaultMessage="TIA"
        />
      );
    case 'dpia':
      return <FormattedMessage id="Type.dpia" defaultMessage="DPIA" />;
    case 'processing':
      return (
        <FormattedMessage
          id="Type.Processing"
          description="Type value Processing"
          defaultMessage="Processing"
        />
      );
    case 'assessment':
      return (
        <FormattedMessage
          id="Type.Assessment"
          description="Type value Assessment"
          defaultMessage="Assessment"
        />
      );
    case 'breach':
      return (
        <FormattedMessage
          id="Type.Breach"
          description="Type value Breach"
          defaultMessage="Breach"
        />
      );
    case 'transfer':
      return (
        <FormattedMessage
          id="Type.Transfer"
          description="Type value Transfer"
          defaultMessage="Transfer"
        />
      );
    case 'consultation':
      return (
        <FormattedMessage
          id="Type.Consultation"
          description="Type value Consultation"
          defaultMessage="Consultation"
        />
      );
    case 'PartnerAdministrator':
      return (
        <FormattedMessage
          id="Role.PartnerAdministrator"
          description="Partner administrator"
          defaultMessage="Partner administrator"
        />
      );
    case 'role':
      return (
        <FormattedMessage
          id="Role.role"
          description="Role"
          defaultMessage="Role"
        />
      );
    case 'administrator':
      return (
        <FormattedMessage
          id="Role.Administrator"
          description="Administrator"
          defaultMessage="Administrator"
        />
      );
    case 'Supervisor':
      return (
        <FormattedMessage
          id="Role.ReadOnlyUser"
          description="ReadOnlyUser"
          defaultMessage="Read-only user"
        />
      );
    case 'PrivacyOfficer':
      return (
        <FormattedMessage
          id="Role.PrivacyOfficer"
          description="Privacy Officer"
          defaultMessage="Privacy Officer"
        />
      );
    case 'DSRAdministrator':
      return (
        <FormattedMessage
          id="Role.DSRAdministrator"
          defaultMessage="DSR Administrator"
        />
      );
    case 'ChiefPrivacyOfficer':
      return (
        <FormattedMessage
          id="Role.ChiefPrivacyOfficer"
          description="Chief Privacy Officer"
          defaultMessage="Chief privacy officer"
        />
      );
    case 'BusinessUser':
      return (
        <FormattedMessage
          id="Role.BusinessUser"
          description="Business User"
          defaultMessage="Business User"
        />
      );
    case 'SuperAdministrator':
      return (
        <FormattedMessage
          id="Role.superAdministrator"
          description="Super administrator"
          defaultMessage="Super administrator"
        />
      );
    case 'NextChapter':
      return (
        <FormattedMessage
          id="Assessments.nextChapter"
          description="Previous chapter button"
          defaultMessage="Next chapter >"
        />
      );
    case 'PreviousChapter':
      return (
        <FormattedMessage
          id="Assessments.previousChapter"
          description="Previous chapter button"
          defaultMessage="< Previous"
        />
      );
    case 'necessityAndProportionalityOutcome':
      return (
        <FormattedMessage
          id="Reports.necessityAndProportionalityOutcome"
          description="Necessity and proportionality outcome"
          defaultMessage="Necessity and proportionality outcome"
        />
      );
    case 'dataSubjectRightsOutcome':
      return (
        <FormattedMessage
          id="Reports.dataSubjectRightsOutcome"
          description="Data subject rights outcome"
          defaultMessage="Data subject rights outcome"
        />
      );
    case 'securityControlsOutcome':
      return (
        <FormattedMessage
          id="Reports.securityControlsOutcome"
          description="Security controls outcome"
          defaultMessage="Security controls outcome"
        />
      );
    case 'impactAssessmentOutcome':
      return (
        <FormattedMessage
          id="Reports.impactAssessmentOutcome"
          description="Impact assessment outcome"
          defaultMessage="Impact assessment outcome"
        />
      );
    case 'accountabilityOutcome':
      return (
        <FormattedMessage
          id="Reports.accountabilityOutcome"
          description="Accountability outcome"
          defaultMessage="Accountability outcome"
        />
      );
    case 'preAssessmentOutcome':
      return (
        <FormattedMessage
          id="Reports.preAssessmentOutcome"
          description="Pre-assessment outcome"
          defaultMessage="Pre-assessment outcome"
        />
      );
    case 'summaryOutcome':
      return (
        <FormattedMessage
          id="Reports.summaryOutcome"
          description="Summary outcome"
          defaultMessage="Summary outcome"
        />
      );
    case 'confidentialityThreatTotalLikelihood':
      return (
        <FormattedMessage
          id="Reports.confidentialityThreatTotalLikelihood"
          description="Confidentiality threat total likelihood"
          defaultMessage="Confidentiality threat total likelihood"
        />
      );
    case 'confidentialityThreatTotalSeverity':
      return (
        <FormattedMessage
          id="Reports.confidentialityThreatTotalSeverity"
          description="Confidentiality threat total severity"
          defaultMessage="Confidentiality threat total severity"
        />
      );
    case 'confidentialityThreatResultingRisk':
      return (
        <FormattedMessage
          id="Reports.confidentialityThreatResultingRisk"
          description="Confidentiality threat resulting risk"
          defaultMessage="Confidentiality threat resulting risk"
        />
      );
    case 'confidentialityThreatResidualRisk':
      return (
        <FormattedMessage
          id="Reports.confidentialityThreatResidualRisk"
          description="Confidentiality threat residual risk"
          defaultMessage="Confidentiality threat residual risk"
        />
      );
    case 'integrityThreatTotalLikelihood':
      return (
        <FormattedMessage
          id="Reports.integrityThreatTotalLikelihood"
          description="Integrity threat total likelihood"
          defaultMessage="Integrity threat total likelihood"
        />
      );
    case 'integrityThreatTotalSeverity':
      return (
        <FormattedMessage
          id="Reports.integrityThreatTotalSeverity"
          description="Integrity threat resulting risk"
          defaultMessage="Integrity threat resulting risk"
        />
      );
    case 'integrityThreatResultingRisk':
      return (
        <FormattedMessage
          id="Reports.integrityThreatResultingRisk"
          description="Integrity threat resulting risk"
          defaultMessage="Integrity threat resulting risk"
        />
      );
    case 'integrityThreatResidualRisk':
      return (
        <FormattedMessage
          id="Reports.integrityThreatResidualRisk"
          description="Integrity threat residual risk"
          defaultMessage="Integrity threat residual risk"
        />
      );
    case 'availabilityThreatTotalLikelihood':
      return (
        <FormattedMessage
          id="Reports.availabilityThreatTotalLikelihood"
          description="Availability threat total likelihood"
          defaultMessage="Availability threat total likelihood"
        />
      );
    case 'availabilityThreatTotalSeverity':
      return (
        <FormattedMessage
          id="Reports.availabilityThreatTotalSeverity"
          description="Availability threat total severity"
          defaultMessage="Availability threat total severity"
        />
      );
    case 'availabilityThreatResultingRisk':
      return (
        <FormattedMessage
          id="Reports.availabilityThreatResultingRisk"
          description="Availability threat resulting risk"
          defaultMessage="Availability threat resulting risk"
        />
      );
    case 'availabilityThreatResidualRisk':
      return (
        <FormattedMessage
          id="Reports.availabilityThreatResultingRisk"
          description="Availability threat residual risk"
          defaultMessage="Availability threat residual risk"
        />
      );
    case 'privacyRecordId':
      return (
        <FormattedMessage
          id="Records.privacyRecordId"
          description="Record ID"
          defaultMessage="Record ID"
        />
      );
    case 'customHelpTexts':
      return (
        <FormattedMessage
          id="Common.customHelpTexts"
          description="Custom help texts"
          defaultMessage="Custom help texts"
        />
      );
    case 'dpo':
    case 'dataProtectionOfficer':
      return (
        <FormattedMessage
          id="common.dpo"
          description="Data Protection Officer"
          defaultMessage="Data protection officer"
        />
      );
    case 'representative':
      return (
        <FormattedMessage
          id="Common.representative"
          description="Representative"
          defaultMessage="Representative"
        />
      );
    case 'auditTrail':
      return (
        <FormattedMessage
          id="Pricing.auditTrail"
          defaultMessage="Audit trail"
        />
      );
    case 'pdfExport':
      return (
        <FormattedMessage id="Pricing.pdfExport" defaultMessage="PDF export" />
      );
    case 'importExport':
      return (
        <FormattedMessage
          id="Pricing.importExport"
          defaultMessage="Master data import/export"
        />
      );
    case 'templates':
      return (
        <FormattedMessage id="Pricing.templates" defaultMessage="Templates" />
      );
    case 'advancedRoleManagement':
      return (
        <FormattedMessage
          id="Pricing.advancedRoleManagement"
          defaultMessage="Advanced role management"
        />
      );
    case 'fieldVisibility':
      return (
        <FormattedMessage
          id="Common.fieldsVisibilitySetting"
          defaultMessage="Field visibility"
        />
      );
    case 'singleSignOn':
      return (
        <FormattedMessage id="Settings.sso" defaultMessage="Single sign on" />
      );
    case 'mfa':
      return <FormattedMessage id="Settings.mfa" defaultMessage="MFA" />;
    case 'documentStorage':
      return (
        <FormattedMessage
          id="Pricing.documentStorage"
          defaultMessage="Document storage"
        />
      );
    case 'whiteLabelling':
      return (
        <FormattedMessage
          id="Pricing.whiteLabelling"
          defaultMessage="White labelling"
        />
      );
    case 'api':
      return <FormattedMessage id="Pricing.api" defaultMessage="Api" />;
    case 'advancedAnalytics':
      return (
        <FormattedMessage
          id="Pricing.advancedAnalytics"
          defaultMessage="Advanced analytics"
        />
      );
    case 'Basic':
      return <FormattedMessage id="Pricing.Basic" defaultMessage="Starter" />;
    case 'Basicplus':
      return (
        <FormattedMessage id="Pricing.Basicplus" defaultMessage="StarterPlus" />
      );
    case 'Pro':
      return <FormattedMessage id="Pricing.Pro" defaultMessage="Pro" />;
    case 'Enterprise':
      return (
        <FormattedMessage id="Pricing.Enterprise" defaultMessage="Enterprise" />
      );
    case 'module':
      return <FormattedMessage id="Pricing.module" defaultMessage="Module" />;
    case 'minValue':
      return (
        <FormattedMessage
          id="Pricing.minValue"
          defaultMessage="Provide a value greater than"
        />
      );
    case 'defaultModule':
      return (
        <FormattedMessage
          id="Pricing.defaultModule"
          defaultMessage="Default module package"
        />
      );
    case 'partner':
      return (
        <FormattedMessage id="PartnerList.name" defaultMessage="Partner" />
      );
    case 'web_application':
      return (
        <FormattedMessage
          id="AuditTrail.web_application"
          defaultMessage="Web application"
        />
      );
    case 'direct_api':
      return (
        <FormattedMessage
          id="AuditTrail.direct_api"
          defaultMessage="Direct API"
        />
      );
    case 'userAgent':
      return (
        <FormattedMessage
          id="AuditTrail.userAgent"
          defaultMessage="User agent"
        />
      );
    case 'accountabilitySecurityMeasure':
      return (
        <FormattedMessage
          id="masterData.accountabilitySecurityMeasure"
          defaultMessage="Accountability security measure"
        />
      );
    case 'webView':
      return (
        <FormattedMessage id="Masterdata.webView" defaultMessage="Web view" />
      );
    case 'qualityControl':
      return (
        <FormattedMessage
          id="QualityControls.name"
          defaultMessage="Element of accuracy"
        />
      );
    case 'legalGround':
      return (
        <FormattedMessage
          id="Masterdata.legalGround"
          defaultMessage="Legal ground"
        />
      );
    case 'supervisoryAuthority':
      return (
        <FormattedMessage
          id="Masterdata.supervisoryAuthority"
          defaultMessage="Supervisory authority"
        />
      );
    case 'global':
      return <FormattedMessage id="Jobs.global" defaultMessage="Global" />;
    case 'local':
      return <FormattedMessage id="Jobs.local" defaultMessage="Local" />;
    case 'origin':
      return <FormattedMessage id="Jobs.origin" defaultMessage="Origin" />;
    case 'subTenant':
      return (
        <FormattedMessage id="Jobs.subTenant" defaultMessage="Sub tenant" />
      );
    case 'knowledgeHub':
      return (
        <FormattedMessage
          id="NavBarUserMenu.knowledgeHub"
          defaultMessage="Knowledge hub"
        />
      );
    case 'help':
      return (
        <FormattedMessage id="NavBarUserMenu.help" defaultMessage="Help" />
      );
    case 'documentType':
      return (
        <FormattedMessage
          id="Records.documentType"
          defaultMessage="Document type"
        />
      );
    case 'customSimpleMasterData':
      return (
        <FormattedMessage
          id="Common.customSimpleMasterData"
          defaultMessage="Custom simple master data"
        />
      );
    case 'vatNumber':
      return (
        <FormattedMessage id="Vendor.vatNumber" defaultMessage="VAT number" />
      );
    case 'noRole':
      return <FormattedMessage id="Document.noRole" defaultMessage="No role" />;
    case 'organisationHierarchy':
      return (
        <FormattedMessage
          id="OrganisationHierarchy.organisationHierarchy"
          defaultMessage="Organisation hierarchy"
        />
      );
    case 'noLimit':
      return (
        <FormattedMessage
          id="OrganisationHierarchy.NoLimit"
          defaultMessage="No limitation"
        />
      );
    case 'topDown':
      return (
        <FormattedMessage
          id="OrganisationHierarchy.TopDown"
          defaultMessage="Top down limitation"
        />
      );
    case 'flat':
      return (
        <FormattedMessage
          id="OrganisationHierarchy.Flat"
          defaultMessage="Flat limitation"
        />
      );
    case 'unavailable':
      return (
        <FormattedMessage
          id="dataLibraryStatus.unavailable"
          defaultMessage="Unavailable"
        />
      );
    case 'available':
      return (
        <FormattedMessage
          id="dataLibraryStatus.available"
          defaultMessage="Available"
        />
      );
    case 'inSync':
      return (
        <FormattedMessage
          id="dataLibraryStatus.inSync"
          defaultMessage="In Sync"
        />
      );
    case 'upToDate':
      return (
        <FormattedMessage
          id="dataLibraryStatus.upToDate"
          defaultMessage="Up to date"
        />
      );
    case 'updateAvailable':
      return (
        <FormattedMessage
          id="dataLibraryStatus.updateAvailable"
          defaultMessage="Update available"
        />
      );
    case 'edited':
      return (
        <FormattedMessage
          id="dataLibraryStatus.edited"
          defaultMessage="Edited"
        />
      );
    default:
      return key;
  }
};

export const pluralTerms = (key) => {
  switch (key) {
    case 'maxSupervisorUsers':
      return (
        <FormattedMessage
          id="Pricing.maxSupervisorUsers"
          defaultMessage="Maximum read-only users"
        />
      );
    case 'jobs':
      return <FormattedMessage id="Jobs.jobs" defaultMessage="Jobs" />;
    case 'DPOConsultation':
      return (
        <FormattedMessage
          id="masterData.dpoConsultationHeader"
          defaultMessage="DPO consultation"
        />
      );
    case 'maxRecords':
      return (
        <FormattedMessage
          id="Pricing.maxRecords"
          defaultMessage="Maximum records"
        />
      );
    case 'maxDocuments':
      return (
        <FormattedMessage
          id="Pricing.maxDocuments"
          defaultMessage="Maximum uploads"
        />
      );
    case 'maxReports':
      return (
        <FormattedMessage
          id="Pricing.maxReports"
          defaultMessage="Maximum reports"
        />
      );
    case 'customHelpNotes':
      return (
        <FormattedMessage
          id="Pricing.customHelpNotes"
          defaultMessage="Custom help notes"
        />
      );
    case 'activeUsers':
      return (
        <FormattedMessage
          id="Pricing.activeUsers"
          defaultMessage="Active users"
        />
      );
    case 'activeReadOnlyUsers':
      return (
        <FormattedMessage
          id="Pricing.activeReadOnlyUsers"
          defaultMessage="Active read-only users"
        />
      );
    case 'privacyOfficer':
      return (
        <FormattedMessage
          id="Role.privacyOfficer"
          description="Privacy Officers"
          defaultMessage="Privacy Officers"
        />
      );
    case 'tenants':
      return <FormattedMessage id="Tenants.tenants" defaultMessage="Tenants" />;
    case 'modules':
      return <FormattedMessage id="Common.modules" defaultMessage="Modules" />;
    case 'partners':
      return (
        <FormattedMessage id="Partners.partners" defaultMessage="Partners" />
      );
    case 'dpo':
      return <FormattedMessage id="Role.dpo" defaultMessage="DPO" />;
    case 'businessUser':
      return (
        <FormattedMessage
          id="Role.businessUser"
          description="Business Users"
          defaultMessage="Business Users"
        />
      );
    case 'users':
    case 'user':
      return (
        <FormattedMessage
          id="Common.users"
          description="Users"
          defaultMessage="Users"
        />
      );
    case 'measures':
      return (
        <FormattedMessage
          id="Common.measures"
          description="Measures"
          defaultMessage="Measures"
        />
      );
    case 'notifications':
      return (
        <FormattedMessage
          id="Common.notifications"
          description="Notifications"
          defaultMessage="Notifications"
        />
      );
    case 'tasks':
      return (
        <FormattedMessage
          id="Common.tasks"
          description="Tasks"
          defaultMessage="Tasks"
        />
      );
    case 'organisations':
    case 'permissionsOrgHeader':
      return (
        <FormattedMessage
          id="LibraryFilter.organisations"
          description="Organisations"
          defaultMessage="Organisations"
        />
      );
    case 'processors':
      return (
        <FormattedMessage
          id="LibraryFilter.processors"
          description="Processors"
          defaultMessage="Processors"
        />
      );
    case 'controllers':
      return (
        <FormattedMessage
          id="LibraryFilter.controllers"
          description="Controllers"
          defaultMessage="Controllers"
        />
      );
    case 'dataSubjectCategories':
      return (
        <FormattedMessage
          id="LibraryFilter.dataSubjectCategories"
          description="Data subject categories"
          defaultMessage="Data subject categories"
        />
      );
    case 'executingEntities':
      return (
        <FormattedMessage
          id="LibraryFilter.executingEntities"
          description="Executing entities"
          defaultMessage="Executing entities"
        />
      );
    case 'dataRecipients':
      return (
        <FormattedMessage
          id="LibraryFilter.dataRecipients"
          description="Data recipients"
          defaultMessage="Data recipients"
        />
      );
    case 'personalDataItems':
      return (
        <FormattedMessage
          id="LibraryFilter.personalDataItems"
          description="Personal data items"
          defaultMessage="Personal data items"
        />
      );
    case 'personalDataCategories':
      return (
        <FormattedMessage
          id="LibraryFilter.personalDataCategories"
          description="Personal data categories"
          defaultMessage="Personal data categories"
        />
      );
    case 'dataSources':
      return (
        <FormattedMessage
          id="LibraryFilter.dataSources"
          description="Data sources"
          defaultMessage="Data sources"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="LibraryFilter.dataSourceCategories"
          description="Data source categories"
          defaultMessage="Data source categories"
        />
      );
    case 'retentionTermOffsets':
    case 'retentionTermOffset':
      return (
        <FormattedMessage
          id="LibraryFilter.retentionTermOffsets"
          description="Retention term offsets"
          defaultMessage="Retention term offsets"
        />
      );
    case 'personalDataLinks':
      return (
        <FormattedMessage
          id="LibraryFilter.personaldatalinks"
          description="Links"
          defaultMessage="Links"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="Record.references"
          description="References"
          defaultMessage="References"
        />
      );
    case 'processingCategories':
      return (
        <FormattedMessage
          id="Record.processingCategories"
          description="Processing categories"
          defaultMessage="Processing categories"
        />
      );
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="MitigatingMeasures.security_measures_title"
          description="Security measures description"
          defaultMessage="Security measures"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="Record.purposes"
          description="Purposes"
          defaultMessage="Purposes"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="Record.riskDetails"
          description="Risk details"
          defaultMessage="Risk details"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="Record.mitigatingMeasures"
          description="Mitigating measures"
          defaultMessage="Mitigating measures"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'deletionRights':
    case 'objectionRights':
    case 'dataSubjectRights':
      return (
        <FormattedMessage
          id="Assessments.dataSubjectRights"
          description="Data subject rights"
          defaultMessage="Data subject rights"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="Record.threats"
          description="Threats"
          defaultMessage="Threats"
        />
      );
    case 'confidentialityImpacts':
    case 'availabilityImpacts':
    case 'integrityImpacts':
      return (
        <FormattedMessage
          id="Record.impacts"
          description="Impacts"
          defaultMessage="Impacts"
        />
      );
    case 'availabilityThreat':
      return threatTranslations.availabilityHeader;
    case 'integrityThreat':
      return threatTranslations.integrityHeader;
    case 'confidentialityThreat':
      return threatTranslations.confidentialityHeader;
    case 'policies':
      return (
        <FormattedMessage
          id="masterData.policies"
          description="Policies etc"
          defaultMessage="Policies etc"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="Record.dataProtectionMeasures"
          description="dataProtectionMeasures"
          defaultMessage="Data protection measures"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="Record.accountabilityMeasures"
          description="accountabilityMeasures"
          defaultMessage="Accountability measures"
        />
      );
    case 'dpoFinding':
    case 'supervisoryAuthoritiesFinding':
    case 'dataSubjectCategoriesFinding':
      return (
        <FormattedMessage
          id="Record.findings"
          description="findings"
          defaultMessage="Findings"
        />
      );
    case 'dpoDecision':
    case 'supervisoryAuthoritiesDecision':
    case 'dataSubjectCategoriesDecision':
      return (
        <FormattedMessage
          id="Record.decisions"
          description="decisions"
          defaultMessage="Decisions"
        />
      );
    case 'defaultEntity':
      return (
        <FormattedMessage
          id="PrivacyRecordList.NoEntities"
          description="No entities"
          defaultMessage="No entities"
        />
      );
    case 'defaultController':
      return (
        <FormattedMessage
          id="PrivacyRecordList.NoControllers"
          description="No controllers"
          defaultMessage="No controllers"
        />
      );
    case 'Stakeholders':
      return (
        <FormattedMessage
          id="Record.Stakeholders"
          description="Stakeholders title"
          defaultMessage="Stakeholders"
        />
      );
    case 'riskDetail':
      return (
        <FormattedMessage
          id="Record.riskDetails"
          description="Risk details"
          defaultMessage="Risk details"
        />
      );
    case 'Permission':
      return (
        <FormattedMessage
          id="Legalground.permissions"
          description="Permissions title"
          defaultMessage="Permissions"
        />
      );
    case 'attachments':
      return (
        <FormattedMessage
          id="RecordDetails.attachments"
          description="Attachments"
          defaultMessage="Attachments"
        />
      );
    case 'documents':
      return (
        <FormattedMessage
          id="RecordDetails.Documents"
          description="Documents"
          defaultMessage="Documents"
        />
      );
    case 'legalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.legalQualifications"
          description="Legal qualifications"
          defaultMessage="Legal qualifications"
        />
      );
    case 'gdprLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.gdprLegalQualifications"
          defaultMessage="Legal qualifications (GDPR)"
        />
      );
    case 'ccpaLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.ccpaLegalQualifications"
          defaultMessage="Legal qualifications (CCPA)"
        />
      );
    case 'appiLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.appiLegalQualifications"
          defaultMessage="Legal qualifications (APPI)"
        />
      );
    case 'pdpaLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.pdpaLegalQualifications"
          defaultMessage="Legal qualifications (PDPA - TW)"
        />
      );
    case 'piplLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.piplLegalQualifications"
          defaultMessage="Legal qualifications (PIPL)"
        />
      );
    case 'lgpdLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.lgpdLegalQualifications"
          defaultMessage="Legal qualifications (LGPD)"
        />
      );
    case 'pdpLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.pdpLegalQualifications"
          defaultMessage="Legal qualifications (PDP)"
        />
      );
    case 'fdplLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.fdplLegalQualifications"
          defaultMessage="Legal qualifications (FDPL)"
        />
      );
    case 'sgpdpaLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.sgpdpaLegalQualifications"
          defaultMessage="Legal qualifications (PDPA - SG)"
        />
      );
    case 'ukgdprLegalQualifications':
      return (
        <FormattedMessage
          id="RecordDetails.ukgdprLegalQualifications"
          defaultMessage="Legal qualifications (UK GDPR)"
        />
      );
    case 'internationalTransfers':
      return (
        <FormattedMessage
          id="RecordDetails.internationalTransfers"
          description="International transfers"
          defaultMessage="International transfers"
        />
      );
    case 'internationalTransfer':
      return (
        <FormattedMessage
          id="RecordDetails.internationalTransfer"
          defaultMessage="Does data travel outside the EEA"
        />
      );
    case 'preAssessmentRisk':
      return (
        <FormattedMessage
          id="Record.preAssessmentRisk"
          defaultMessage="Risk estimation based on preAssessment"
        />
      );
    case 'startDate':
      return (
        <FormattedMessage
          id="Record.public.startDate"
          defaultMessage="Start of assessment"
        />
      );
    case 'endDate':
      return (
        <FormattedMessage
          id="Record.public.endDate"
          defaultMessage="End of assessment"
        />
      );
    case 'policyLabel':
      return (
        <FormattedMessage
          id="Record.public.policy"
          defaultMessage="Policies, codes of conduct and certification schemes"
        />
      );

    case 'noOfRecords':
      return (
        <FormattedMessage
          id="Jobs.noOfRecords"
          description="Number of records"
          defaultMessage="Number of records"
        />
      );
    case 'dataItemGroups':
      return (
        <FormattedMessage
          id="Record.dataItemGroups"
          description="Data item groups"
          defaultMessage="Data item groups"
        />
      );
    case 'retentionTerms':
      return (
        <FormattedMessage
          id="Record.retentionTerms"
          description="Retention terms"
          defaultMessage="Retention terms"
        />
      );
    case 'subProcessors':
      return (
        <FormattedMessage
          id="Record.subProcessors"
          description="Subprocessors"
          defaultMessage="Subprocessors"
        />
      );
    case 'transferGrounds':
      return (
        <FormattedMessage
          id="Record.transferGrounds"
          description="Transfer grounds"
          defaultMessage="Transfer grounds"
        />
      );
    case 'processingGrounds':
      return (
        <FormattedMessage
          id="Record.processingGrounds"
          description="Processing Grounds"
          defaultMessage="Processing Grounds"
        />
      );
    case 'numOfDataSubjects':
      return (
        <FormattedMessage
          id="Record.numOfDataSubjects"
          description="Number of data subjects"
          defaultMessage="Number of data subjects"
        />
      );
    case 'processingCharacteristics':
      return (
        <FormattedMessage
          id="Record.processingCharacteristics"
          description="Processing characteristics"
          defaultMessage="Processing characteristics"
        />
      );
    case 'processingRisks':
      return (
        <FormattedMessage
          id="Record.processingRisks"
          description="Processing risks"
          defaultMessage="Processing risks"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="Record.purposesOfTransfer"
          defaultMessage="Purposes of transfer"
        />
      );
    case 'technicalSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.technicalMeasure"
          description="Technical security measures"
          defaultMessage="Technical security measures"
        />
      );
    case 'organisationalSecurityMeasures':
      return (
        <FormattedMessage
          id="masterData.organisationalMeasure"
          description="Organisational security measures"
          defaultMessage="Organisational security measures"
        />
      );
    case 'template':
      return (
        <FormattedMessage
          id="Common.Templates"
          description="Templates"
          defaultMessage="Templates"
        />
      );
    case 'linkedBreaches':
      return (
        <FormattedMessage
          id="Record.linkedBreaches"
          description="Linked breaches"
          defaultMessage="Linked breaches"
        />
      );
    case 'breachConsequences':
      return (
        <FormattedMessage
          id="Record.breachConsequences"
          description="Breach consequences"
          defaultMessage="Likely breach consequences"
        />
      );
    case 'supervisoryAuthorities':
      return (
        <FormattedMessage
          id="Record.supervisoryAuthorities"
          description="Supervisory authorities"
          defaultMessage="Supervisory authorities"
        />
      );
    case 'breachGrounds':
      return (
        <FormattedMessage
          id="Record.breachGrounds"
          description="Breach grounds"
          defaultMessage="Breach grounds"
        />
      );
    case 'purposeCommentary':
      return (
        <FormattedMessage
          id="Settings.purposeCommentaries"
          description="Purpose Commentaries"
          defaultMessage="Purpose Commentaries"
        />
      );
    case 'tags':
      return (
        <FormattedMessage
          id="Common.tags"
          description="Tags"
          defaultMessage="Tags"
        />
      );
    case 'noTags':
      return <FormattedMessage id="Common.noTags" defaultMessage="No tags" />;
    case 'purposesCommentaries':
      return (
        <FormattedMessage
          id="masterData.purposesCommentaries"
          description="Purposes commentaries"
          defaultMessage="Purposes commentaries"
        />
      );
    case 'purposesOfTransferCommentaries':
      return (
        <FormattedMessage
          id="masterData.purposesOfTransferCommentaries"
          description="Purposes of transfer commentaries"
          defaultMessage="Purposes of transfer commentaries"
        />
      );
    case 'defaultProcessor':
      return (
        <FormattedMessage
          id="PrivacyRecordList.NoProcessors"
          description="No processors"
          defaultMessage="No processors"
        />
      );
    case 'securityMeasuresDescription':
      return (
        <FormattedMessage
          id="Record.securityMeasuresDescription"
          description="Security measures description"
          defaultMessage="Technical and organisational security measures"
        />
      );
    case 'risks':
      return (
        <FormattedMessage
          id="Record.risks"
          description="Legal risks"
          defaultMessage="Legal risks"
        />
      );
    case 'linkBoxInstructionHeader':
      return (
        <FormattedMessage
          id="Record.linkBoxInstructionHeader"
          description="The group editor"
          defaultMessage="The group editor"
        />
      );
    case 'createdBy':
      return (
        <FormattedMessage
          id="Record.createdBy"
          description="Created by"
          defaultMessage="Created by"
        />
      );
    case 'permittedUsers':
      return (
        <FormattedMessage
          id="Record.permittedUsers"
          description="Permitted users"
          defaultMessage="Permitted users"
        />
      );
    case 'permittedOrganisations':
      return (
        <FormattedMessage
          id="Record.permittedOrganisations"
          description="Permitted organisations"
          defaultMessage="Permitted organisations"
        />
      );
    case 'qualitycontrols':
    case 'qualityControls':
      return (
        <FormattedMessage
          id="Record.qualityControls"
          defaultMessage="Elements of accuracy"
        />
      );
    case 'optionalChapters':
      return (
        <FormattedMessage
          id="Assessments.optionalChapters"
          description="Assessment optional chapters"
          defaultMessage="Assessment optional chapters"
        />
      );
    case 'securityControls':
      return (
        <FormattedMessage
          id="Assessments.securityControls"
          description="Security Controls"
          defaultMessage="Security Controls"
        />
      );
    case 'generalCharacteristics':
      return (
        <FormattedMessage
          id="AssessmentDetails.generalCharacteristics"
          defaultMessage="General information"
        />
      );
    case 'ICOCharacteristics':
      return (
        <FormattedMessage
          id="AssessmentDetails.icoCharacteristics"
          description="Assessment Details ICO characteristics header"
          defaultMessage="ICO characteristics"
        />
      );
    case 'PIPLCharacteristics':
      return (
        <FormattedMessage
          id="AssessmentDetails.piplCharacteristics"
          defaultMessage="PIPL characteristics"
        />
      );
    case 'APCharacteristics':
      return (
        <FormattedMessage
          id="ProcessingCharacteristics.apCharacteristics"
          defaultMessage="AP characteristics"
        />
      );
    case 'GBACharacteristics':
      return (
        <FormattedMessage
          id="ProcessingCharacteristics.gbaCharacteristics"
          defaultMessage="GBA characteristics"
        />
      );
    case 'CNILCharacteristics':
      return (
        <FormattedMessage
          id="ProcessingCharacteristics.cnilCharacteristics"
          defaultMessage="CNIL characteristics"
        />
      );
    case 'AEPDCharacteristics':
      return (
        <FormattedMessage
          id="ProcessingCharacteristics.aepdCharacteristics"
          defaultMessage="AEPD characteristics"
        />
      );
    case 'EDPBCharacteristics':
      return (
        <FormattedMessage
          id="AssessmentDetails.edpbCharacteristics"
          description="Assessment Details ICO characteristics header"
          defaultMessage="EDPB characteristics"
        />
      );
    case 'DSKCharacteristics':
      return (
        <FormattedMessage
          id="AssessmentDetails.DSKCharacteristics"
          defaultMessage="DSK characteristics"
        />
      );
    case 'years':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.years"
          description="Years"
          defaultMessage="Years"
        />
      );
    case 'months':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.months"
          description="Months"
          defaultMessage="Months"
        />
      );
    case 'weeks':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.weeks"
          description="weeks"
          defaultMessage="Weeks"
        />
      );
    case 'days':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.days"
          description="Days"
          defaultMessage="Days"
        />
      );
    case 'hours':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.hours"
          description="Hours"
          defaultMessage="Hours"
        />
      );
    case 'minutes':
      return (
        <FormattedMessage
          id="RetentionTermPeriod.minutes"
          description="Minutes"
          defaultMessage="Minutes"
        />
      );
    case 'environmentOrganisation':
      return (
        <FormattedMessage
          id="Environment.organisation.filter"
          description="Organisation and Users"
          defaultMessage="Organisation and Users"
        />
      );
    case 'admins':
      return (
        <FormattedMessage
          id="Tenant.admins"
          description="Admins"
          defaultMessage="Admins"
        />
      );
    case 'characteristics':
      return (
        <FormattedMessage
          id="Breach.characteristics"
          description="Breach characteristics"
          defaultMessage="Breach characteristics"
        />
      );
    case 'processing':
      return (
        <FormattedMessage
          id="Type.processing"
          description="Processing records"
          defaultMessage="Processing records"
        />
      );
    case 'assessment':
      return (
        <FormattedMessage
          id="Type.assessment"
          description="Assessment records"
          defaultMessage="Assessment records"
        />
      );
    case 'dpiaRecords':
      return (
        <FormattedMessage id="Type.dpiaRecords" defaultMessage="DPIA records" />
      );
    case 'tiaRecords':
      return (
        <FormattedMessage id="Type.tiaRecords" defaultMessage="Tia records" />
      );
    case 'breach':
      return (
        <FormattedMessage
          id="Type.breach"
          description="Breach records"
          defaultMessage="Breach records"
        />
      );
    case 'custom':
      return (
        <FormattedMessage
          id="Type.custom"
          description="Custom records"
          defaultMessage="Custom records"
        />
      );
    case 'processings':
      return (
        <FormattedMessage
          id="Type.Processings"
          description="Type value Processings"
          defaultMessage="Processings"
        />
      );
    case 'assessments':
      return (
        <FormattedMessage
          id="Type.Assessments"
          description="Type value Assessments"
          defaultMessage="Assessments"
        />
      );
    case 'breaches':
      return (
        <FormattedMessage
          id="Type.Breaches"
          description="Type value Breaches"
          defaultMessage="Breaches"
        />
      );
    case 'dpias':
      return <FormattedMessage id="Type.Dpias" defaultMessage="DPIAS" />;
    case 'tias':
      return <FormattedMessage id="Type.Tias" defaultMessage="Tias" />;
    case 'tia':
      return <FormattedMessage id="Type.Tia" defaultMessage="TIA" />;
    case 'Administrator':
      return (
        <FormattedMessage
          id="Role.administrator"
          description="Administrators"
          defaultMessage="Administrators"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="Common.dataRecipientCategories"
          description="Data recipient categories"
          defaultMessage="Data recipient categories"
        />
      );
    case 'dpoHeader':
      return (
        <FormattedMessage
          id="Records.dpoHeader"
          description="Data Protection Officer(s)"
          defaultMessage="Data Protection Officer(s)"
        />
      );
    case 'rightsToInformation':
      return (
        <FormattedMessage
          id="masterData.information"
          defaultMessage="Rights to information"
        />
      );
    case 'validitiesOfConsent':
      return (
        <FormattedMessage
          id="masterData.consentRights"
          defaultMessage="Validities of consent"
        />
      );
    case 'rightsToAccess':
      return (
        <FormattedMessage
          id="masterData.accessRights"
          defaultMessage="Elements of the right to access and rectification"
        />
      );
    case 'rightsToObjection':
      return (
        <FormattedMessage
          id="masterData.objectionRights"
          defaultMessage="Rights to objection"
        />
      );
    case 'rightsToErasure':
      return (
        <FormattedMessage
          id="masterData.deletionRights"
          defaultMessage="Elements of the rights to erasure"
        />
      );
    case 'rightsToDataPortability':
      return (
        <FormattedMessage
          id="masterData.dataPortabilityRights"
          defaultMessage="Elements of the right to data portability"
        />
      );
    case 'dataQualityControls':
      return (
        <FormattedMessage
          id="Environment.dataQualityMeasures"
          defaultMessage="Data quality controls"
        />
      );
    case 'legalGrounds':
      return (
        <FormattedMessage
          id="Graph.LegalGround"
          defaultMessage="Legal grounds"
        />
      );
    case 'entityCount':
      return (
        <FormattedMessage
          id="Jobs.entityCount"
          defaultMessage="Number of entities"
        />
      );
    case 'countries':
      return (
        <FormattedMessage id="Common.countries" defaultMessage="Countries" />
      );
    case 'updatesAndReleaseNotes':
      return (
        <FormattedMessage
          id="Admin.updatesAndReleaseNotes"
          defaultMessage="Updates & Release notes"
        />
      );
    default:
      return key;
  }
};
