import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchPricing() {
  yield put({ type: 'PRICING:PLAN:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get('v1/billing-plan');
    switch (response.status) {
      case 200: {
        yield put({ type: 'PRICING:PLAN:FETCH:SUCCESS', data: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
export function* updatePricing(action) {
  const { pricingDetails, packageName } = action;
  try {
    const request = registry.get('request');
    const response = yield request.put(`/v1/billing-plan/${packageName}`, pricingDetails);
    switch (response.status) {
      case 200: {
        yield put({ type: 'PRICING:PLAN:UPDATE:SUCCESS', data: response.body });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.pricingUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
