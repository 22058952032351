/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import RetentionTermSelectorDefault from './component';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  retentionTerms: state.retentionTerms.get('retentionTerms'),
  tags: state.environment.simpleEntities.tags.get('items'),
  tagsPosition: state.environment.simpleEntities.tags.get('position'),
  searchResults: state.simpleEntities.get('entitySelectorSearch').get('tags')
    .searchResults,
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  initRetentionTermOffsets: (isGlobal) => {
    dispatch({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:REQUEST_INIT' });
    if (!isGlobal) {
      dispatch({
        type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
        masterDataType: 'tags'
      });
    }
  },
  initAllRetentionTerms: () => {
    dispatch({ type: 'RECORDS:RETENTION_TERMS:LIST:REQUEST_INIT' });
  },
  onOffsetSearch: (searchParams) => {
    dispatch({
      type: 'RECORDS:RETENTION_OFFSETS:LIST:SEARCH_INIT',
      searchParams
    });
  },
  onChooseFilter: (filterParams) => {
    dispatch({
      type: 'MASTERDATA:RETENTION_OFFSETS:LIST:FILTER_INIT',
      filterParams,
      searchParams: { searchKey: 'name' },
      source: 'records',
      isPersistedData: true
    });
  },
  tagSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetentionTermSelectorDefault);
export { reducers };
