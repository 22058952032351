/*
Some utility functions to work with URL's

*/
export const encodeUrl = (url) => {
  let initialStr = url
    .replace(/%20/g, ' ')
    .replace(/%3C/g, '<')
    .replace(/%3E/g, '>');
  const encodedStr = encodeURIComponent(initialStr);
  // if there are any encoded strings...
  if (encodedStr.indexOf('%') !== -1) {
    // if this is not equal to the original string, ...
    if (encodedStr !== initialStr) {
      // ... that means there was something to encode
      initialStr = encodedStr;
    }
  }
  return initialStr;
};

export const updateQueryStringParameter = (uri, key, value) => {
  const encodedKey = encodeURIComponent(key);
  const encodedValue = encodeURIComponent(value);
  const re = new RegExp(`([?&])${encodedKey}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, `$1${encodedKey}=${encodedValue}$2`);
  }

  return `${uri}${separator}${encodedKey}=${encodedValue}`;
};

export const updateURLPathWithVariable = (uri, key, value) => {
  const encodedKey = encodeURIComponent(key);
  const encodedValue = encodeURIComponent(value);
  const re = new RegExp(`[//\]:${encodedKey}([//\]?)`, 'i');
  if (uri.match(re)) {
    return uri.replace(re, `/${encodedValue}$1`);
  }
  return uri;
};

export const getQueryStrings = (hashURL) => {
  const query = {};
  hashURL = hashURL || window.location.hash;
  const hash = hashURL.substring(hashURL.indexOf('?') + 1);
  const keyValues = hash.split('&');
  const decode = function(s) {
    return decodeURIComponent(encodeUrl(s));
  };

  // TODO : Implement this using ES6 Constructs
  for (let i = 0; i < keyValues.length; i += 1) {
    const key = keyValues[i].split(/=(.+)/); // Split the first occurence of '=''
    if (key.length > 1) {
      query[decode(key[0])] = decode(key[1]);
    }
  }
  return query;
};

export const getParameterValuesFromHash = (url) => {
  if (url) {
    let { hash } = window.location;
    hash = hash.indexOf('?') > 0 ? hash.substring(0, hash.indexOf('?')) : hash;
    const splitHash = hash.split('/');
    const splitUrl = url.split('/');
    const parameterValues = {};

    // TODO : Implement this using ES6 Constructs
    for (let index = 1; index < splitUrl.length; index += 1) {
      let parameter = splitUrl[index];
      if (parameter.indexOf(':') >= 0) {
        parameter = parameter.substring(1);
        parameterValues[parameter] = splitHash[index];
      }
    }
    return parameterValues;
  }
  return null;
};
