import Immutable from 'immutable';

const defaultState = Immutable.fromJS([
  {
    label: 'Bulk Export',
    key: 'bulkExport',
    visible: true
  },
  {
    label: 'Name',
    key: 'name',
    visible: true
  },
  {
    label: 'Jurisdictions',
    key: 'jurisdictions',
    visible: true
  },
  {
    label: 'Type',
    key: 'type',
    visible: true
  },
  {
    label: 'Sub Type',
    key: 'subType',
    visible: true
  },
  {
    label: 'Status',
    key: 'status',
    visible: true
  },
  {
    label: 'Last Changed',
    key: 'lastChanged',
    visible: true
  },
  {
    label: 'Risks',
    key: 'risks',
    visible: false
  },
  {
    label: 'Controllers',
    key: 'controllers',
    visible: true
  },
  {
    label: 'Processors',
    key: 'processorList',
    visible: true
  },
  {
    label: 'Executing entities',
    key: 'executingEntities',
    visible: true
  }
]);

const visibleColumnCount = state => state.count(item => item.get('visible'));

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY_RECORDS:COLUMNS:TOGGLE_VISIBILITY': {
      let newState = state;
      action.columnName.forEach((column) => {
        const index = newState.findIndex(item => item.get('key') === column);

        // Unluckily the ImmutableJS list implementation will accept negative numbers and update unwanted columns
        // if this is not checked
        if (index === -1) {
          throw new Error(`Wrong column name passed [${column}]`);
        }

        newState = newState.update(
          index,
          (foundColumn) => {
            const count = visibleColumnCount(state);

            if (foundColumn.get('visible') && count === 1) {
              return foundColumn;
            }

            return foundColumn.set('visible', !foundColumn.get('visible'));
          }
        );
      });
      return newState;
    }

    case 'PRIVACY_RECORDS:COLUMNS:SHOW_RISKS': {
      let newState = state;
      state.forEach((column, index) => {
        if (column.get('key') === 'controllers' || column.get('key') === 'executingEntities') {
          newState = newState.update(index, foundColumn => foundColumn.set('visible', false));
        } else if (column.get('key') === 'risks') {
          newState = newState.update(index, foundColumn => foundColumn.set('visible', true));
        }
      });
      return newState;
    }

    case 'PRIVACY_RECORDS:COLUMNS:SHOW_HIDE': {
      let newState = state;
      const index = newState.findIndex(item => item.get('key') === action.columnName);
      newState = newState.update(index, foundColumn => foundColumn.set('visible', action.isVisible));

      return newState;
    }

    default:
      return state;
  }
};
