import Immutable from 'immutable';
import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  stakeholderList: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  pageSearchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'STAKEHOLDERS:LIST:REQUEST:INIT':
      return state
        .set('error', defaultState.get('error'))
        .set('stakeholderList', defaultState.get('stakeholderList'))
        .set('searchText', defaultState.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('position', 0);
    case 'STAKEHOLDERS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'STAKEHOLDERS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set(
          'stakeholderList',
          state.get('stakeholderList').concat(Immutable.List(action.items))
        )
        .set('position', state.get('position') + action.items.length);
    case 'STAKEHOLDERS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'STAKEHOLDERS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), {
        [filterParams.filterKey]: filterParams.filterKey
      });
      return state
        .set('stakeholderList', Immutable.List(action.items))
        .set('position', 0)
        .set('filterColumn', Immutable.Map(filterColumn))
        .set(
          'filteredOn',
          getFilteredOn(
            Object.assign({}, action.filterParams, {
              filterObj: state.get('filteredOn')
            })
          )
        )
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'STAKEHOLDERS:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'STAKEHOLDERS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'STAKEHOLDERS:PAGE_SEARCH':
      return state
        .set('isFetching', true)
        .set('pageSearchText', getSearchText(action));
    case 'STAKEHOLDERS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set(
          'searchText',
          getSearchText(
            Object.assign({}, action, {
              searchObj: state.get('searchText').toJS()
            })
          )
        )
        .set('searchItems', action.items);
    case 'STAKEHOLDERS:PAGE_SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set(
          'pageSearchText',
          getSearchText(
            Object.assign({}, action, {
              searchObj: state.get('pageSearchText').toJS()
            })
          )
        )
        .set('stakeholderList', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'STAKEHOLDERS:LIST:SEARCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    default:
      return state;
  }
};
