import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';


class TransferGroundSelector extends React.Component {
  componentWillMount() {
    if (this.props.initTransferGrounds) {
      this.props.initTransferGrounds(this.getTransferGroundType(this.props.type));
    }
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.transferGrounds === nextProps.transferGrounds) ||
    !(this.props.subField === nextProps.subField);
  }

  getTransferGroundType = (type) => {
    switch (true) {
      case type.includes('appi'):
        return 'appi_transfer_ground';
      case type.includes('lgpd'):
        return 'lgpd_transfer_ground';
      case type.includes('sgpdpa'):
        return 'sgpdpa_transfer_ground';
      default:
        return 'transfer_ground';
    }
  }

  render () {
    const { hintTextLabel, transferGrounds, attribute } = this.props;

    const getAttributeValues = (attribute) => {
      const uniqueValues = new Set();
      return transferGrounds.reduce((result, item) => {
        if (!uniqueValues.has(item[attribute])) {
          uniqueValues.add(item[attribute]);
          result.push({
            id: item[attribute],
            key: item[attribute]
          });
        }
        return result;
      }, []);
    };

    return (
      <div>
        {transferGrounds.size > 0 &&
        <ValueSelector
          dataItems={
            attribute ? getAttributeValues(attribute) : transferGrounds
          }
          liveAutocomplete={false}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          valueAttr="key"
          title={
            <h3>
              <FormattedMessage
                id="MultipleTransfergroundDialog.header"
                description="Select Transfer ground Dialog Header"
                defaultMessage="Select transfer grounds"
              />
            </h3>}
          {...this.props}
        />}
      </div>
    );
  }
}

TransferGroundSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  initTransferGrounds: PropTypes.func,
  transferGrounds: PropTypes.instanceOf(Immutable.List),
  subField: PropTypes.string.isRequired,
  type: PropTypes.string,
  attribute: PropTypes.string
};

TransferGroundSelector.defaultProps = {
  hintTextLabel: null,
  initTransferGrounds: e => e,
  transferGrounds: Immutable.List(),
  type: '',
  attribute: undefined
};

export default TransferGroundSelector;
