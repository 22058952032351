import moment from 'moment';
import translator from 'counterpart';
import timeAgo from 'damals';
import { zhCN, zhTW, enGB, fr, nl, de, es, ja, pt } from 'date-fns/locale';
import translationNL from '@packages/translation/locales/nl_dateTime.json';
import translationES from '@packages/translation/locales/es_dateTime.json';
import translationFR from '@packages/translation/locales/fr_dateTime.json';

translator.registerTranslations('nl', translationNL);
translator.registerTranslations('es', translationES);
translator.registerTranslations('fr', translationFR);

export const getUTCDateTime = (utcDate, dateFormat) => {
  if (!utcDate) return '';
  return moment(utcDate)
    .utc()
    .format(dateFormat || 'YYYY-MM-DD hh:mm:ss.SSS');
};

export const getLocalDateTime = (utcDate, dateFormat, locale) => {
  if (!utcDate) return '';
  return moment(moment.utc(utcDate).toDate())
    .locale(locale)
    .format(dateFormat || 'YYYY-MM-DD hh:mm:ss.SSS');
};

export const getLocalTime = (utcDate) => {
  if (!utcDate) return '';
  return moment.utc(utcDate).local().format('hh:mm a');
};

export const getEndOfDayDate = (utcDate) => {
  if (!utcDate) return '';
  return moment(moment.utc(utcDate).toDate()).endOf('day');
};

export const getLocalDateObj = (utcDate) => {
  if (!utcDate) return '';
  return moment.utc(utcDate).toDate();
};

export const getElapsedTime = (utcDate, locale) => {
  if (!utcDate) return '';
  translator.setLocale(locale);
  translator.setFallbackLocale('en');
  return timeAgo(utcDate);
};

export const getTimeRemaining = (dueAt) => {
  if (dueAt === '') {
    return {
      rem: '',
      ms: ''
    };
  }

  const now = moment();
  const dueTime = moment(dueAt);
  const ms = dueTime.diff(now);
  const duration = moment.duration(Math.abs(ms));
  let timeRemaining = Math.floor(duration.asHours());
  if (timeRemaining > 48) {
    const fullDays = Math.floor(timeRemaining / 24);
    timeRemaining = `${fullDays} days, ${timeRemaining % 24}h`;
  } else {
    timeRemaining = `${timeRemaining}h`;
  }

  timeRemaining += moment.utc(Math.floor(Math.abs(ms))).format(' mm');
  timeRemaining += 'm';
  if (ms <= 0 && timeRemaining !== '0:00') {
    timeRemaining = `${timeRemaining} overdue`;
  }
  return {
    rem: timeRemaining,
    ms,
    readableFormat: duration.humanize()
  };
};

export const localeValue = {
  'zh-CN': zhCN,
  'zh-TW': zhTW,
  en: enGB,
  fr,
  nl,
  de,
  es,
  ja,
  pt
};
