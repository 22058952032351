import Immutable from 'immutable';
import { requestTypes } from '../dsr-detail/filterValues';

const defaultState = Immutable.fromJS({
  isFetching: false,
  isSubmitted: false,
  data: Immutable.Map(),
  isInactive: false,
  requestTypes: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PUBLIC:DSR:FORM:FETCH': {
      return state.set('isFetching', true)
        .set('isInactive', false)
        .set('data', defaultState.get('data'));
    }
    case 'PUBLIC:DSR:FORM:FETCH:SUCCESS': {
      return state.set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('requestTypes', requestTypes[action.data.jurisdictions[0]])
    }
    case 'PUBLIC:DSR:FORM:FETCH:FAIL': {
      return state.set('isFetching', false)
        .set('isInactive', true);
    }

    case 'PUBLIC:DSR:FORM:SUBMIT': {
      return state.set('isFetching', true);
    }
    case 'PUBLIC:DSR:FORM:SUBMIT:SUCCESS': {
      return state.set('isFetching', false)
        .set('data', defaultState.get('data'))
        .set('isSubmitted', true);
    }
    case 'PUBLIC:DSR:FORM:SUBMIT:FAIL': {
      return state.set('isFetching', false)
        .set('isSubmitted', false);
    }

    default:
      return state;
  }
};
