import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/nl';
import nlMessages from './locales/nl.json';
import frMessages from './locales/fr.json';
import deMessages from './locales/de.json';
import esMessages from './locales/es.json';
import enMessages from './locales/en.json';
import jaMessages from './locales/ja.json';
import ptMessages from './locales/pt.json';
import twMessages from './locales/zh-TW.json';
import zhMessages from './locales/zh-CN.json';

export { default as reducers } from './reducers';

const localeMessages = {
  nl: nlMessages,
  fr: frMessages,
  de: deMessages,
  es: esMessages,
  en: enMessages,
  ja: jaMessages,
  pt: ptMessages,
  'zh-TW': twMessages,
  'zh-CN': zhMessages
};

export function getLocaleMessages(locale) {
  return localeMessages[locale];
}
