import { connect } from 'react-redux';
import BreachGroundSelector from './component';

const mapStateToProps = state => ({
  breachGrounds: state.recordsBreachgrounds.get('breachGrounds'),
  error: state.recordsBreachgrounds.get('error')
});

const mapDispatchToProps = dispatch => ({
  initBreachGrounds: () => {
    dispatch({ type: 'BREACH_GROUNDS:RECORDS:LIST:INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BreachGroundSelector);
