import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  qrCode: '',
  isMFAConfigured: false,
  actionMessage: undefined,
  tokenExpired: false,
  isRestoreQr: false,
  isFetching: true,
  error: ''
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'USER:QRCODE:REQUEST:SUCCESS': {
      return state
        .set('qrCode', action.qrCode)
        .set('isRestoreQr', action.isRestoreQr)
        .set('isFetching', false)
        .set('error', defaultState.get('error'));
    }
    case 'OTP:VALIDATION:FAIL':
      return state.set(
        'actionMessage',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'OTP:OPTIONAL':
      return state
        .set('isFetching', false)
        .set('isMFAConfigured', action.isMFAConfigured);
    case 'RESTORE:QRCODE:REQUEST:SUCCESS':
      return state.set(
        'actionMessage',
        Immutable.Map({ message: action.success, isError: false })
      );
    case 'USER:TOKEN:VALIDATION:FAIL':
      return state
        .set('tokenExpired', action.tokenExpired)
        .set('isFetching', false)
        .set('isRestoreQr', action.isRestoreQr);
    default:
      return state;
  }
};
