import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from 'react-loader';

const dsrPublicLayout = lazy(() =>
  import('@packages/features/dsr-public-form')
);
const NoHeaderLayout = lazy(() => import('./components/no-header-layout'));
const ProtectedLayout = lazy(() => import('./components/protectedLayout'));
const WhiteLabelledLayout = lazy(() =>
  import('./components/whitelabelledLayout')
);

export const ProtectedRoute = ({ children }) => (
  <Route>
    <Suspense fallback={<Loader loaded={false} />}>
      <Switch>
        <Route path="/login" component={NoHeaderLayout} />
        <Route path="/logout" component={NoHeaderLayout} />
        <Route path="/tenantCreationInProgress" component={NoHeaderLayout} />
        <Route path="/subscription" component={NoHeaderLayout} />
        <Route path="/SSOlogout" component={NoHeaderLayout} />
        <Route path="/user/reinvited" component={NoHeaderLayout} />
        <Route path="/user/reinvite-success" component={NoHeaderLayout} />
        <Route path="/user/reinvite" component={NoHeaderLayout} />
        <Route path="/user" component={WhiteLabelledLayout} />
        <Route path="/validateotp" component={WhiteLabelledLayout} />
        <Route path="/dsr-public-form" component={dsrPublicLayout} />
        <Route
          path="*"
          render={(props) => (
            <ProtectedLayout {...props}>{children}</ProtectedLayout>
          )}
        />
      </Switch>
    </Suspense>
  </Route>
);
