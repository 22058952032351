import { put } from 'redux-saga/effects';
import registry from 'app-registry';
import { initialize } from 'redux-form';
import { handleServiceDown } from '@packages/utils/common-utils';
import { documentInfected } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getQueryStrings } from '@packages/utils/query-parameters';

export function* fetchTenantEmailPreference() {
  try {
    const response = yield registry
      .get('request')
      .get('/v1/settings/emailPreferences');
    switch (response.status) {
      case 200: {
        const data = response.body;
        yield put({ type: 'TENANT_EMAIL_PREFERENCE:FETCH:SUCCESS', data });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: 'TENANT_EMAIL_PREFERENCE:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* fetchTenantSettings(action) {
  yield put({ type: 'TENANT:SETTINGS:FETCH' });

  try {
    const request = registry.get('request');
    const { token, tokenType, isPublic = false } = action;
    const hashURL = window.location.hash;
    const isPreview = getQueryStrings(hashURL).isPreview === 'true';
    const url = isPublic
      ? '/v1/public/settings/whitelabelling'
      : '/v1/settings/whitelabelling';

    // TODO Checks for the token and fire the request with the token if it is present
    const tenantRequest =
      token && !isPublic
        ? request.get(
          `/v1/settings/whitelabelling?tokenType=${tokenType}`,
          null,
          { headers: { token } },
          false
        )
        : request.get(url, null, {}, !isPublic || isPreview);

    const response = yield tenantRequest;

    switch (response.status) {
      case 200: {
        const data = response.body.value || response.body;

        if (data.companyLogo) {
          yield fetchLogo({ logoId: data.companyLogo.id, token, tokenType });
        }
        yield put({
          type: 'TENANT:SETTINGS:FETCH:SUCCESS',
          data,
          // eslint-disable-next-line no-underscore-dangle
          revision: response.body._rev
        });

        yield put(initialize('WhiteLabelling', data));
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    registry.get('logger').error(err);
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'TENANT:SETTINGS:FETCH:FAIL', error: err.message });
  }
}

export function* uploadTenantLogo(action) {
  const request = registry.get('request');
  try {
    yield put({ type: 'TENANT:SETTINGS:LOGO:UPLOAD:INIT' });
    const modifiedTenantLogo = {
      file: action.file,
      name: action.file.name,
      description: '',
      tags: [],
      public: true,
      documentType: 'logo'
    };
    const response = yield request.postDocument(
      '/v1/documents/upload',
      modifiedTenantLogo
    );
    yield response;

    switch (response.status) {
      case 200:
      case 202: {
        const modifiedItems = {
          ...action.data,
          companyLogo: {
            ...response.body,
            downloadPath: ''
          }
        };
        yield put({
          type: 'TENANT:SETTINGS:LOGO:UPLOAD:SUCCESS',
          item: modifiedItems
        });
        yield put(initialize('WhiteLabelling', modifiedItems));
        break;
      }
      default: {
        yield put({
          type: 'TENANT:SETTINGS:LOGO:UPLOAD:FAIL',
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    if (err.status === 502 && err.message === 'Document seems to be infected') {
      yield put({
        type: 'NOTIFIER:NOTIFY',
        notification: {
          content: documentInfected,
          type: 'error'
        }
      });
    } else yield handleServiceDown(err, 'document');
    yield put({ type: 'TENANT:SETTINGS:LOGO:UPLOAD:FAIL', error: err.message });
  }
}

export function* fetchLogo(action) {
  const request = registry.get('request');
  try {
    const { logoId, formData, token, tokenType } = action;
    const url = `/v1/documents/logo/${logoId}`;

    // TODO checks for the token and fires the request
    const logoRequest = token
      ? request.getImage(
        `${url}?tokenType=${tokenType}`,
        { headers: { token } },
        false
      )
      : request.getImage(`${url}`, {});

    const response = yield logoRequest;
    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'TENANT:SETTINGS:LOGO:FETCH:SUCCESS',
          logoUrl: response.body,
          formData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'TENANT:SETTINGS:LOGO:FETCH:FAIL', error: err.message });
  }
}

export function* updateTenantEmailPreference(action) {
  try {
    const response = yield registry
      .get('request')
      .put(`/v1/settings/emailPreferences`, action.data);
    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'TENANT_EMAIL_PREFERENCE:UPDATE:SUCCESS',
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.tenantEmailPreferenceUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: 'TENANT_EMAIL_PREFERENCE:UPDATE:FAIL',
      error: err.message
    });
  }
}

export function* updateTenantSettings(action) {
  const { data } = action;
  const request = registry.get('request');

  try {
    const response = yield request.put('/v1/settings/whitelabelling', data);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.whiteLabellingUpdated,
            type: 'success'
          }
        });

        yield put({
          type: 'TENANT:SETTINGS:UPDATE:SUCCESS',
          item: response.body.value,
          // eslint-disable-next-line no-underscore-dangle
          revision: response.body._rev
        });
        // yield put(initialize('WhiteLabelling', response.body.value));
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'TENANT:SETTINGS:UPDATE:FAIL', error: err.message });
  }
}
