/* eslint-disable default-param-last */
import Immutable from 'immutable';
import { filterExistingFieldItems } from '../tenantConfigUtils';

const updateRuleSuccess = (state, action, ruleType) => {
  const newRules = state
    .get('rules')
    .toJS()
    .filter((rule) => rule.id !== action.data.id);

  return state
    .set(
      ruleType,
      state.get(ruleType).map((rule) => {
        if (rule.id === action.data.id) {
          return action.data;
        }
        return rule;
      })
    )
    .set('rules', Immutable.List([...newRules, action.data]));
};

const deleteRuleSuccess = (state, action, ruleType) =>
  state
    .set(
      ruleType,
      state.get(ruleType).filter((rule) => rule.id !== action.id)
    )
    .set(
      'rules',
      state.get('rules').filter((rule) => rule.id !== action.id)
    );

const defaultState = Immutable.fromJS({
  currentFilter: undefined,
  newFields: Immutable.List([]),
  availableFields: undefined,
  existingFields: [],
  currentChapterInfo: [],
  isFetching: false,
  unSavedData: false,
  prevLocation: '/tenant-configuration',
  recordName: undefined,
  derivedFrom: undefined,
  position: 0,
  isLoading: false,
  rules: Immutable.List([]),
  isRuleFetching: Immutable.Map({}),
  layoutFields: [],
  fieldRules: [],
  actionFields: [],
  riskRules: [],
  editorTabIndex: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PICK_FIELDS:ITEM:FETCH:SUCCESS': {
      if (action.data.length === 0) return state.set('isLoading', false);
      else {
        const updatedItems = state.get('newFields').concat(action.data);
        return state
          .set('newFields', updatedItems)
          .set(
            'availableFields',
            filterExistingFieldItems(updatedItems, [
              ...state.get('existingFields')
            ])
          )
          .set('position', state.get('position') + action.data.length)
          .set('isLoading', false);
      }
    }
    case 'PICK_FIELDS:FETCH:SET:LOADER':
      return state.set('isLoading', true);
    case 'LAYOUT:EDITOR:UPDATE:AVAILABLE:PICKFIELDS':
      return state.set('availableFields', action.availableFields);
    case 'PICK_FIELDS:ITEM:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'LAYOUT:EDITOR:UPDATE:PICKFIELD:ITEMS':
      return state
        .set(
          'availableFields',
          Immutable.List(
            filterExistingFieldItems(
              [...state.get('newFields')],
              action.existingIds
            )
          )
        )
        .set('existingFields', action.existingIds);
    case 'LAYOUT:EDITOR:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'PICK_FIELDS:FETCH:RESET':
      return state
        .set('position', 0)
        .set('newFields', defaultState.get('newFields'))
        .set('availableFields', defaultState.get('availableFields'));
    case 'LAYOUT:EDITOR:RESET':
      return state
        .set('currentFilter', defaultState.get('currentFilter'))
        .set('currentChapterInfo', defaultState.get('currentChapterInfo'));

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:INIT':
      return state.set('isFetching', true);
    case 'LAYOUT:EDITOR:UPDATE:FILTER:MENU': {
      return state
        .set('currentChapterInfo', {
          ...state.get('currentChapterInfo'),
          chapters: action.chapters
        })
        .set('currentFilter', action.filterName);
    }

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:SUCCESS':
      return state
        .set('currentChapterInfo', action.data)
        .set('recordName', action.data.names)
        .set('derivedFrom', action.data.derivedFrom.names)
        .set('isFetching', false);

    case 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:FAIL':
      return state.set('isFetching', false);

    case 'LAYOUT:EDITOR:UNSAVED_DATA:SET':
      return state.set('unSavedData', action.value);
    case 'LAYOUT:EDITOR:UPDATE:DATA': {
      return state.set('currentChapterInfo', action.data);
    }
    case 'LAYOUT_EDITOR:DETAILS:SET': {
      const { fields, chapters, ...recordDetails } = action.data;
      return state
        .set('currentChapterInfo', {
          ...state.get('currentChapterInfo'),
          ...recordDetails
        })
        .set('recordName', action.data.names);
    }
    case 'LAYOUT:EDITOR:FIELD:RULES:INIT':
      return state.set('isFetching', true);
    case 'LAYOUT:EDITOR:FIELD:RULES:FETCH:SUCCESS':
      return state.set('fieldRules', action.data).set('isFetching', false);
    case 'LAYOUT:EDITOR:FIELD:RULES:FETCH:FAIL':
      return state.set('isFetching', false);

    case 'LAYOUT:EDITOR:RISK:RULES:INIT':
      return state.set('isFetching', true);
    case 'LAYOUT:EDITOR:RISK:RULES:FETCH:SUCCESS':
      return state.set('riskRules', action.data).set('isFetching', false);
    case 'LAYOUT:EDITOR:RISK:RULES:FETCH:FAIL':
      return state.set('isFetching', false);

    case 'LAYOUT:EDITOR:RULE:ITEM:INIT':
      return state.set(
        'isRuleFetching',
        Immutable.Map({
          ...state.get('isRuleFetching').toJS(),
          [action.id]: true
        })
      );
    case 'LAYOUT:EDITOR:RULE:ITEM:FETCH:SUCCESS': {
      const existingRules = state.get('rules');
      const newRules = existingRules?.some((rule) => rule.id === action.data.id)
        ? existingRules
        : existingRules.push(action.data);

      return state.set('rules', newRules).set(
        'isRuleFetching',
        Immutable.Map({
          ...state.get('isRuleFetching').toJS(),
          [action.data.id]: false
        })
      );
    }
    case 'LAYOUT:EDITOR:RULE:ITEM:FETCH:FAIL':
      return state.set(
        'isRuleFetching',
        Immutable.Map({
          ...state.get('isRuleFetching').toJS(),
          [action.id]: false
        })
      );
    case 'LAYOUT:TRIGGER:FIELDS:REQUEST:SUCCESS':
      return state.set('layoutFields', action.data);

    case 'LAYOUT:ACTION:FIELDS:REQUEST:SUCCESS':
      return state.set('actionFields', action.data);

    case 'LAYOUT:FIELD_RULE:SAVE:SUCCESS':
      return state.set('fieldRules', [action.data, ...state.get('fieldRules')]);
    case 'LAYOUT:RISK_RULE:SAVE:SUCCESS':
      return state.set('riskRules', [action.data, ...state.get('riskRules')]);

    case 'LAYOUT:FIELD_RULE:UPDATE:SUCCESS':
      return updateRuleSuccess(state, action, 'fieldRules');

    case 'LAYOUT:RISK_RULE:UPDATE:SUCCESS':
      return updateRuleSuccess(state, action, 'riskRules');

    case 'LAYOUT:FIELD_RULE:DELETE:SUCCESS':
      return deleteRuleSuccess(state, action, 'fieldRules');

    case 'LAYOUT:RISK_RULE:DELETE:SUCCESS':
      return deleteRuleSuccess(state, action, 'riskRules');

    case 'LAYOUT:EDITOR:TAB_INDEX:SET':
      return state.set('editorTabIndex', action.value);
    default:
      return state;
  }
};
