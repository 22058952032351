import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';
import filterMenus from './defaults';
import { filterMenuItems } from './utils';

const defaultState = Immutable.fromJS({
  currentFilter: '',
  filterMenus,
  menuItems: [],
  unSavedData: false,
  auditSettings: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SETTINGS:FILTERS:INIT': {
      const {
        roles,
        userPermissions,
        enabledSSO,
        showchangePassword,
        ssoSettings,
        bypassSSO,
        isGlobal
      } = action;
      // Filter menu items based on role/subrole
      const menuItems = filterMenuItems(
        roles,
        userPermissions,
        filterMenus,
        showchangePassword,
        enabledSSO,
        ssoSettings,
        bypassSSO,
        isGlobal
      );

      // If login from the link of reset Qr code, append the token with url '/validateotp'
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const { type } = urlParams;
      // If currentFilter is not in the menu items, set the first item in filter menus as current filter
      let currentFilter = type || state.get('currentFilter');
      if (currentFilter === undefined) {
        currentFilter = 'userdetails_profile';
      }
      let newState = state;
      if (currentFilter === '') {
        newState = newState.set(
          'currentFilter',
          menuItems[0].subMenu ? menuItems[0].subMenu[0].key : menuItems[0].key
        );
      } else {
        newState = newState.set('currentFilter', currentFilter);
      }

      return newState.set('menuItems', menuItems);
    }
    case 'SETTINGS:FILTERS:CHANGE': {
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }
    case 'SETTINGS:UNSAVED:DATA':
      return state.set('unSavedData', action.value);
    case 'SETTINGS:AUDIT_TRAIL:FETCH:SUCCESS':
    case 'SETTINGS:AUDIT_TRAIL:UPDATE:SUCCESS':
      return state.set('auditSettings', action.data);
    default:
      return state;
  }
};
