import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import notificationtranslations from '@packages/utils/notificationtranslations';
import { getFilteredOn } from '@packages/utils/reducer-utils';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT,
  modifyUserProfile
} from '@packages/utils/common-utils';

import {
  updateDataItems,
  transformUserName,
  roleAccessGroupMap
} from '../saga-utils';

export function* resetAndFetchOrganisationUsers(action) {
  yield put({ type: 'ORGANISATION_USERS:LIST:REQUEST_INIT' });
  yield fetchOrganisationUsers(action);
}

export function* fetchOrganisationUsers(action) {
  yield put({ type: 'ORGANISATION_USERS:LIST:FETCH' });
  try {
    const isGlobal = yield select((state) => state.home.get('isGlobal'));
    const {
      status = '',
      position = 0,
      rowCount = MASTERDATA_FETCH_LIMIT,
      roles = '',
      userHierarchy = ''
    } = action;
    let filterParams = {};
    let filteredOn = Immutable.Map();
    let searchParameters = Immutable.Map();
    if (action.isPersistedData) {
      const organisationUserState = yield select(
        (state) => state.organisationUser.list
      );
      if (organisationUserState) {
        filterParams = organisationUserState.get('filterParams').toJS();
        filteredOn = organisationUserState.get('filteredOn');
        filteredOn = filteredOn === 'name' ? 'lastName' : filteredOn;
        searchParameters = organisationUserState.get('pageSearchText');
      }
    }
    const { sortOrder } = filterParams;
    let { sortOn } = filterParams;
    sortOn = sortOn === 'name' ? 'lastName' : sortOn;
    if (status !== '') {
      filteredOn = getFilteredOn({
        filterKey: 'status',
        filteredOn: [status],
        filterObj: filteredOn
      });
    }
    if (roles !== '') {
      const filterUser = isGlobal
        ? ['HoldingAdministrator', 'Coordinator']
        : roles;
      filteredOn = getFilteredOn({
        filterKey: 'roles',
        filteredOn: filterUser,
        filterObj: filteredOn
      });
    }
    if (userHierarchy !== '') {
      filteredOn = getFilteredOn({
        filterKey: 'organisationHierarchy',
        filteredOn: userHierarchy,
        filterObj: filteredOn
      });
    }
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          position,
          searchParameters,
          rowCount
        ),
        null,
        {}
      );

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );
        yield put({
          type: 'ORGANISATION_USERS:LIST:REQUEST:SUCCESS',
          items: modifiedItems,
          organisationUser: action.organisationUser
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({
      type: 'ORGANISATION_USERS:LIST:REQUEST:FAIL',
      error: err.message
    });
    yield put({
      type: 'RECORDS:ACCESS_RIGHTS:LIST:REQUEST:FAIL',
      error: err.message
    });
  }
}

export function* upsertOrganisationUserDetail(action) {
  const { isEdit, data, refreshToken = false } = action;
  const request = registry.get('request');
  let updateLoggedUser = false;
  const requestData = { ...data };
  const modifiedOrganisations = requestData.organisations?.map((item) => {
    const itemValue = item.value || item;
    return {
      id: itemValue.id,
      name: itemValue.name,
      key: itemValue.key
    };
  });
  const pmsAccessGroups = requestData.roles.map((role) => ({
    id: roleAccessGroupMap[role]
  }));
  const ismsAccessGroups =
    requestData.accessGroups?.filter(
      (accessGroup) =>
        !Object.values(roleAccessGroupMap).includes(accessGroup.id)
    ) || [];
  const modifiedReqdata = {
    ...requestData,
    organisations: modifiedOrganisations || [],
    roles: requestData.roles || [],
    accessGroups: [...pmsAccessGroups, ...ismsAccessGroups]
  };
  delete modifiedReqdata.organisationIds;
  delete modifiedReqdata.belongsTo;

  yield put({ type: 'ORGANISATION:USER:DETAIL:INIT' });

  let response;
  try {
    if (isEdit) {
      const userId = requestData.id;
      response = yield request.put(`/v1/users/${userId}`, modifiedReqdata);
      const loggedUserState = yield select((state) => state.user);
      if (loggedUserState) {
        updateLoggedUser = loggedUserState.get('profile').get('id') === userId;
      }
      if (refreshToken) yield put({ type: 'LOGIN:TOKEN:REFRESH' });
    } else {
      const userState = yield select((state) => state.login);
      if (userState) {
        const roles = userState
          ? userState.get('loggedUser').get('roles')
          : Immutable.List();
        if (roles.includes('PartnerAdministrator')) {
          requestData.roles = ['PartnerAdministrator'];
          requestData.organisationIds = [];
        }
      }

      response = yield request.post(`/v1/users/invite`, modifiedReqdata);
    }

    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: userMessages[isEdit],
            type: 'success'
          }
        });
        yield put({ type: 'ORGANISATION:USER:DETAIL:UPSERT:SUCCESS' });

        // Handle consistency in the front end.
        if (isEdit) {
          yield put({
            type: 'ORGANISATION_USERS:UPSERT:SUCCESS',
            organisationUser: response.body
          });
          delete data.SSOUserPasswordStatus;
          yield updateUserItems({
            type: 'edit',
            data: { ...data, ...response.body }
          });
          if (updateLoggedUser) {
            yield put({
              type: 'USER:PROFILE:REQUEST:SUCCESS',
              data: modifyUserProfile(response.body)
            });
          }
        } else {
          yield put({
            type: 'ORGANISATION_USERS:UPSERT:SUCCESS',
            position: 0
          });
          yield fetchOrganisationUsers({
            organisationUser: transformUserItem(response.body),
            isPersistedData: true,
            rowCount: MASTERDATA_FETCH_LIMIT
          });
        }

        break;
      }
      case 409:
        yield put({
          type: 'ORGANISATION:USER:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      case 423: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({
      type: 'ORGANISATION:USER:DETAIL:UPSERT:FAIL',
      error: err.message
    });
  }
}

export function* deleteOrganisationUser(action) {
  const { userId } = action;
  const request = registry.get('request');

  try {
    const response = yield request.delete(`/v1/users/${userId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.userDeleteSuccess,
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateUserItems({
          type: 'delete',
          userId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
  }
}

function* updateUserItems(action) {
  const { type, data, userId } = action;
  const organisationUserState = yield select((state) => state.organisationUser);
  let position = null;
  let items = organisationUserState
    ? organisationUserState.list.get('organisationUsers')
    : [];
  let newItems = organisationUserState
    ? organisationUserState.list.get('newItems')
    : [];
  if (type === 'delete') {
    position = organisationUserState.list.get('position') - 1;
    items = updateDataItems(items, type, userId);
    newItems = updateDataItems(newItems, type, userId);
  } else if (type === 'edit') {
    const modifiedItem = transformUserItem(data);

    items = updateDataItems(items, type, data.id, '', modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, '', modifiedItem);
  }
  yield put({
    type: 'ORGANISATION_USERS:LIST:UPDATE:SUCCESS',
    items,
    newItems,
    position
  });
}

export function* filterOrganisationUser(action) {
  const { filterParams } = action;
  yield put({ type: 'ORGANISATION_USERS:LIST:FILTER', filterParams });
  yield fetchOrganisationUsers(action);
}

export function* searchOrganisationUser(action) {
  const {
    searchParams: { isPageSearch },
    searchParams
  } = action;
  const searchResultsCount = MASTERDATA_SEARCH_LIMIT;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';

  const organisationUserState = yield select(
    (state) => state.organisationUser.list
  );

  const { sortOn, sortOrder, filteredOn, searchParameters } =
    getFilterAndSearchParamsFromState(
      organisationUserState,
      searchParams,
      searchMode
    );

  yield put({ type: `ORGANISATION_USERS:${searchMode}`, searchParameters });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position || 0,
          searchParameters,
          searchResultsCount
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );
        yield put({
          type: `ORGANISATION_USERS:${searchMode}:SUCCESS`,
          searchParameters,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
  }
}

export function* sendBulkInvitation(action) {
  const { selectedUsers } = action;
  const request = registry.get('request');

  try {
    const selectedUsersIds = selectedUsers.map((item) => item.id);
    const response = yield request.post(
      `/v1/users/set-password-invite`,
      selectedUsersIds
    );
    switch (response.status) {
      case 204: {
        yield put({ type: 'ORGANISATION_USERS:LIST:REQUEST' });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.bulkInviteSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

// getURLWithParams uses the parameters filterParams, position, searchParams
const getURLWithParams = (filterParams, position, searchParams, rowCount) => {
  let url = `/v1/users?offset=${position}&numberOfResults=${rowCount}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          url = `${url}&filter=${getModifiedKey(
            filterKey,
            'filter'
          )}%3D${encodeURIComponent(filterText)}`;
        });
      }
    });
    url = sortOn
      ? `${url}&sortOn=${getModifiedKey(sortOn)}&sortOrder=${sortOrder}`
      : url;
  }
  if (searchParams) {
    searchParams.keySeq().forEach((searchParameters) => {
      const searchItem = searchParams.get(searchParameters);
      const searchKey = searchItem.get('searchKey');
      const searchText = searchItem.get('searchText');
      url = searchText
        ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}`
        : url;
    });
    const sortOn = searchParams.get('sortOn');
    const sortOrder = searchParams.get('sortOrder');

    url = sortOn
      ? `${url}&sortOn=${getModifiedKey(sortOn)}&sortOrder=${sortOrder}`
      : url;
  }
  return url;
};

export function* resetAndFetchAdminUsers(action) {
  yield put({ type: 'ADMIN_USERS:LIST:REQUEST_INIT' });
  yield fetchAdminUsers(action);
}

export function* fetchAdminUsers(action) {
  yield put({ type: 'ADMIN_USERS:LIST:FETCH_INIT' });
  try {
    const { position = 0, rowCount = 500 } = action;
    let url = `/v1/users?offset=${position}&numberOfResults=${rowCount}`;
    url = `${url}&filter=roles=Administrator&filter=status=Registered`;
    const response = yield registry.get('request').get(url);

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );
        yield put({
          type: 'ADMIN_USERS:LIST:REQUEST:SUCCESS',
          items: modifiedItems,
          organisationUser: action.organisationUser
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({ type: 'ADMIN_USERS:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* searchAdminUser(action) {
  const { searchParams, position } = action;
  const searchResultsCount = 500;
  const searchMode = 'LIST:SEARCH';

  let url = `/v1/users?offset=${position}&numberOfResults=${searchResultsCount}`;
  url = `${url}&filter=roles=Administrator&filter=status=Registered`;
  url = `${url}&search=name=${searchParams.searchText}`;

  yield put({ type: `ADMIN_USERS:${searchMode}`, searchParams });
  try {
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformUserItem(item)
        );
        yield put({
          type: `ADMIN_USERS:${searchMode}:SUCCESS`,
          searchParams,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
  }
}

const transformUserItem = (item) => ({
  ...item,
  name: transformUserName(item),
  key: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`,
  role:
    item.roles &&
    item.roles.length &&
    item.roles.filter((role) => role !== 'SuperAdministrator')
});

const userMessages = {
  true: notificationtranslations.userUpdateSuccess,
  false: notificationtranslations.userInvited
};

const getModifiedKey = (key, type) => {
  if (type === 'filter' && key === 'organisation') return 'organisationId';
  if (type === 'filter' && key === 'accessGroups') return 'accessGroupIds';
  return key === 'name' ? 'lastName' : key;
};

const getFilterAndSearchParamsFromState = (
  organisationUserState,
  searchParams,
  searchMode
) => {
  // Filter parameters
  let filterParams = {};
  let pageSearchTextObj = Immutable.Map();
  if (organisationUserState) {
    filterParams = organisationUserState.get('filterParams').toJS();
    pageSearchTextObj = organisationUserState.get('pageSearchText');
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn =
    organisationUserState && searchMode !== 'LIST:SEARCH'
      ? organisationUserState.get('filteredOn')
      : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;

    let searchTextObj = Immutable.Map();
    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined) {
      searchTextObj =
        organisationUserState && searchMode === 'LIST:SEARCH'
          ? organisationUserState.get('searchText')
          : organisationUserState.get('pageSearchText');
      searchText = searchTextObj.get(searchKey) || '';
    } else {
      const searchObj = {
        ...searchParams,
        [searchParams.searchKey]: searchParams.searchText
      };
      searchParameters =
        searchParams.searchKey === 'name'
          ? pageSearchTextObj.set('name', Immutable.Map(searchObj))
          : pageSearchTextObj.set('email', Immutable.Map(searchObj));
    }
  } else {
    searchParameters = pageSearchTextObj || Immutable.Map();
  }

  return { sortOn, sortOrder, filteredOn, searchParameters, searchText };
};

export function* exportUsers(action) {
  const { signal, controller } = action;
  yield put({ type: `ORGANISATION:USERS:EXPORT` });
  const request = registry.get('request');
  try {
    const url = `/v1/entities/user/export`;
    if (controller) {
      yield request.exportCSVbyGet(url, null, null, controller);
    } else {
      const response = yield request.exportCSVbyGet(url, null, signal);

      yield response;
      switch (response.status) {
        case 200: {
          yield put({ type: `ORGANISATION:USERS:EXPORT:SUCCESS` });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({ type: `ORGANISATION:USERS:EXPORT:FAIL` });
          break;
      }
    }
  } catch (err) {
    if (!action.signal.aborted) yield handleServiceDown(err, 'organisation');
    yield put({ type: `ORGANISATION:USERS:EXPORT:FAIL` });
  }
}
