import Immutable from 'immutable';
import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';


const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  hasCreateTenantPermission: true,
  filteredOn: Immutable.Map(),
  filterColumn: {},
  sortKey: 'name',
  sortOrder: 'ASC',
  filterParams: Immutable.Map(),
  pageSearchText: Immutable.Map(),
  partner: {},
  items: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  data: {},
  isEdit: false,
  tenantId: undefined,
  unSavedData: false,
  subTenants: [],
  actionError: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'HOLDING:TENANTS:LIST:INIT':
      return state
        .set('partnerName', defaultState.get('partnerName'))
        .set('items', Immutable.List())
        .set('filterParams', defaultState.get('filterParams'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('position', 0);
    case 'HOLDING:TENANTS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'HOLDING:TENANTS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'HOLDING:TENANTS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'HOLDING:TENANTS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('sortOrder', filterParams.sortOrder)
        .set('sortKey', filterParams.sortOn)
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'HOLDING:TENANT:LIST:PAGE_SEARCH': {
      const pageSearchText = getSearchText(action);
      return state
        .set('isFetching', true)
        .set('pageSearchText', pageSearchText)
        .set('error', defaultState.get('error'));
    }
    case 'HOLDING:TENANT:LIST:PAGE_SEARCH:SUCCESS': {
      const pageSearchText = getSearchText(Object.assign({}, action,
        { searchObj: state.get('pageSearchText') }));
      return state
        .set('isFetching', false)
        .set('pageSearchText', pageSearchText)
        .set('items', Immutable.List(action.items));
    }
    case 'HOLDING:TENANT:LIST:PAGE_SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'HOLDING:TENANT:DETAIL:INIT':
      return state.set('tenantId', defaultState.get('tenantId'))
        .set('isEdit', defaultState.get('isEdit'))
        .set('data', defaultState.get('data'))
        .set('actionError', defaultState.get('actionError'))
        .set('unSavedData', defaultState.get('unSavedData'));

    case 'HOLDING:TENANT:DETAIL:FETCH':
      return state.set('tenantId', action.tenantId).set('isEdit', true)
        .set('isFetching', true);
    case 'HOLDING:TENANT:DETAIL:FETCH:SUCCESS':
      return state.set('data', action.data)
        .set('isFetching', false);
    case 'HOLDING:TENANT:DETAIL:FETCH:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }))
        .set('isFetching', false);
    case 'HOLDING:TENANT:DETAIL:UNSAVED:SET':
      return state.set('unSavedData', action.value);
    case 'HOLDING:TENANT:DETAIL:UPSERT':
      return state.set('actionError', defaultState.get('actionError'));
    case 'HOLDING:TENANT:FETCH:SUB_TENANTS:FAIL':
    case 'HOLDING:TENANT:DETAIL:UPSERT:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));
    case 'HOLDING:TENANT:FETCH:SUB_TENANTS:SUCCESS':
      return state.set('subTenants', action.data);
    default:
      return state;
  }
};
