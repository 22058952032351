import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { replace as replaceRouter } from 'connected-react-router';
import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT,
  getErrorMessage
} from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import countryTranslations from '@packages/utils/countryTranslations';
import { requestTypes } from '../../../dsr-detail/filterValues';
import dsrTranslations from '../../dsrTranslations';

export function* resetAndfetchDsrForms(action) {
  yield put({ type: `DSR:FORMS:LIST:INIT` });
  yield fetchDsrForms(action);
}

export function* fetchDsrForms(action) {
  yield put({ type: `DSR:FORMS:LIST:FETCH` });
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  try {
    const dsrFormsState = yield select((state) => state.dsrForms);
    const filterParams = dsrFormsState
      ? dsrFormsState.get('filterParams').toJS()
      : {};
    const { sortOn, sortOrder } = filterParams;
    const filteredOn = dsrFormsState
      ? dsrFormsState.get('filteredOn')
      : Immutable.Map();
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams({ sortOn, sortOrder, filteredOn }, position, rowCount),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: `DSR:FORMS:LIST:FETCH:SUCCESS`,
          items: response.body,
          requesterData: action.requesterData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    registry.get('logger').error(err);
    yield put({ type: `DSR:FORMS:LIST:FETCH:FAIL`, error: err.message });
  }
}

export function* filterDsrForms(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  yield put({ type: `DSR:FORMS:LIST:FILTER`, filterParams });
  yield fetchDsrForms(action);
}

const getURLWithParams = (filterParams, position, rowCount) => {
  let url = `v1/dsrmanagement/dsrforms?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOrder } = filterParams;
    const { sortOn } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((item) => {
          const filterText = typeof item === 'object' ? item.name : item;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

export function* getFormData(id) {
  const response = yield registry
    .get('request')
    .get(`/v1/dsrmanagement/dsrforms/${id}`);

  if (response.status === 200) {
    const data = response.body;
    delete data.id;
    delete data.name;
    return Object.assign({}, data, { status: 'Inactive' });
  }
  return {};
}

export function* createDSRForms(action) {
  const request = registry.get('request');
  const { copyFormId, data, formatMessage } = action;
  const { name, jurisdictions } = data;
  let requestData = { ...getDefaultData(formatMessage), name, jurisdictions };
  if (copyFormId) {
    const copyData = yield getFormData(copyFormId);
    requestData = { ...requestData, ...copyData };
  }

  try {
    const response = yield request.post(
      '/v1/dsrmanagement/dsrforms',
      requestData
    );

    switch (response.status) {
      case 200: {
        const { id } = response.body;
        yield put({ type: 'DSR:FORMS:ITEM:CREATE:SUCCESS' });
        yield put(replaceRouter(`/dsr/form/${id}/edit`));
        break;
      }
      case 409: {
        yield put({
          type: 'DSR:FORMS:ITEM:CREATE:FAIL',
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:FORMS:ITEM:CREATE:FAIL', error });
  }
}

export function* initializeDSRForm(action) {
  const request = registry.get('request');
  const { recordId } = getParameterValuesFromHash('/dsr/form/:recordId/edit');
  yield put({ type: 'DSR:FORMS:ITEM:FETCH', recordId });
  try {
    const requestUrl = `/v1/dsrmanagement/dsrforms/${recordId}`;
    const response = yield request.get(requestUrl);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'DSR:FORMS:ITEM:FETCH:SUCCESS',
          data: transformResponseData({ ...response.body }, action.intl)
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:FORMS:ITEM:FETCH:FAIL', error });
  }
}

export function* upsertDSRForm(action) {
  const request = registry.get('request');
  const { isStatusChange } = action;
  try {
    yield put({ type: 'DSR:FORMS:ITEM:UPSERT:INIT' });
    const { data, prevLocation = '' } = action;
    const { id } = action.data;
    const reqData = isStatusChange ? data : transformRequestData(data);
    const response = yield request.put(
      `/v1/dsrmanagement/dsrforms/${id}`,
      reqData
    );

    switch (response.status) {
      case 200: {
        yield put({ type: 'DSR:FORMS:ITEM:UPSERT:SUCCESS' });
        // List data is refetched after changing Active or Inactive.
        if (isStatusChange) {
          yield put({ type: 'DSR:FORMS:LIST:REQUEST_INIT' });
        }
        if (prevLocation !== '') yield put(replaceRouter(action.prevLocation));
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'DSR:FORMS:ITEM:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:FORMS:ITEM:UPSERT:FAIL', error });
  }
}

const getDefaultData = () => {
  const defatulAdditionalFieldState = { included: false, mandatory: false };
  const defaultMandatoryFieldState = { included: true, mandatory: true };
  return {
    fields: {
      address: defatulAdditionalFieldState,
      postCode: defatulAdditionalFieldState,
      city: defatulAdditionalFieldState,
      country: defatulAdditionalFieldState,
      additionalInfo: defaultMandatoryFieldState,
      firstName: defaultMandatoryFieldState,
      lastName: defaultMandatoryFieldState,
      phoneNumber: defatulAdditionalFieldState,
      email: defaultMandatoryFieldState,
      dataSubjectCategories: defaultMandatoryFieldState,
      requestTypes: defaultMandatoryFieldState
    },
    status: 'Inactive',
    defaultRoute: {
      dataSubjectCategories: [],
      approvers: [],
      assignedUsers: [],
      assignedOrganisations: [],
      requestTypes: []
    }
  };
};

const transformRoutingResData = (
  data,
  formatMessage,
  isCustomRoute,
  jurisdiction
) => {
  const defaultData = [
    {
      value: {
        key: dsrTranslations.All,
        label: dsrTranslations.All,
        data: 'All',
        value: 'All'
      }
    }
  ];
  const resData = data;

  if (resData.requestTypes) {
    resData.requestTypes = requestTypes[jurisdiction].filter((item) =>
      resData.requestTypes.includes(item.data)
    );
    if (isCustomRoute && !resData.requestTypes?.length) {
      resData.requestTypes = defaultData;
    }
  }

  if (resData.dataSubjectCategories) {
    resData.dataSubjectCategories = resData.dataSubjectCategories.map(
      (item) => ({
        value: {
          ...item,
          key: item.name
        }
      })
    );
    if (isCustomRoute && !resData.dataSubjectCategories?.length) {
      resData.dataSubjectCategories = defaultData;
    }
  }

  if (resData.assignedOrganisations?.length > 0) {
    resData.assignedOrganisations = resData.assignedOrganisations.map(
      (item) => ({ value: { ...item, key: item.name } })
    );
  }

  if (resData.assignedUsers?.length > 0) {
    resData.assignedUsers = getModifiedResponseUser(resData.assignedUsers);
  }
  if (resData.approvers?.length > 0) {
    resData.approvers = getModifiedResponseUser(resData.approvers);
  }
  if (isCustomRoute) {
    resData.countries = resData.countries?.length
      ? resData.countries?.map((country) => ({
        value: {
          ...country,
          countryCode: country.id,
          key: formatMessage(countryTranslations[country.id].props)
        }
      }))
      : defaultData;
  }
  return resData;
};

const transformResponseData = (data, intl) => {
  const resData = data;
  const jurisdiction = data.jurisdictions[0];
  if (resData.defaultRoute) {
    resData.defaultRoute = transformRoutingResData(
      resData.defaultRoute,
      intl.formatMessage,
      false,
      jurisdiction
    );
  }
  if (resData.customRoutes) {
    resData.customRoutes = resData.customRoutes.map((item) =>
      transformRoutingResData(item, intl.formatMessage, true, jurisdiction)
    );
  }

  resData.additionalFields = {
    address: resData.fields.address,
    phoneNumber: resData.fields.phoneNumber,
    postCode: resData.fields.postCode,
    city: resData.fields.city,
    country: resData.fields.country,
    additionalInfo: resData.fields.additionalInfo
  };

  // sets language version to users current locale

  resData.languageVersion = intl.locale;
  return resData;
};

const getModifiedResponseUser = (users) => {
  const modifiedUsers = users.map((item) => ({
    ...item,
    value: {
      ...item.value,
      key: `${item.value.firstName}${
        item.value.lastName ? ` ${item.value.lastName}` : ''
      }`
    }
  }));
  return modifiedUsers;
};

export const transformRoutingReqData = (data) => {
  const reqData = data;

  if (reqData.requestTypes) {
    reqData.requestTypes = reqData.requestTypes
      .map((item) => item.data || item.value?.data)
      .filter((value) => value !== 'All');
  }
  if (reqData.assignedOrganisations) {
    reqData.assignedOrganisations = reqData.assignedOrganisations.map(
      (item) => ({ id: item.id || item.value.id })
    );
  }
  if (reqData.assignedUsers) {
    reqData.assignedUsers = reqData.assignedUsers.map((item) => ({
      value: { id: item.id || item.value.id },
      messagesInfo: []
    }));
  }
  if (reqData.approvers) {
    reqData.approvers = reqData.approvers.map((item) => ({
      value: { id: item.id || item.value.id }
    }));
  }
  if (reqData.dataSubjectCategories) {
    reqData.dataSubjectCategories = reqData.dataSubjectCategories
      .map(({ value }) => ({ ...value }))
      .filter((value) => value.data !== 'All');
  }
  if (reqData.countries) {
    reqData.countries = reqData.countries
      .filter((country) => country.value.data !== 'All')
      .map((item) => ({
        id: item.countryCode || item.value.id || item.value.countryCode
      }));
  }

  return reqData;
};

export const transformRequestData = (data) => {
  const reqData = data;

  reqData.defaultRoute = transformRoutingReqData(reqData.defaultRoute);
  reqData.customRoutes = reqData.customRoutes.map((item) =>
    transformRoutingReqData(item)
  );
  reqData.fields = { ...reqData.fields, ...reqData.additionalFields };
  return reqData;
};

export function* deleteDsrForm(action) {
  const { item } = action;
  const request = registry.get('request');
  try {
    const response = yield request.post(`/v1/dsrmanagement/dsrforms/remove`, {
      entityIds: [item]
    });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dsrTranslations.formDeleted,
            type: 'success'
          }
        });
        const dsrFormsState = yield select((state) => state.dsrForms);
        const formItems = dsrFormsState.get('items');
        const itemsList = updateDataItems(formItems, item);
        let newItems = dsrFormsState
          ? dsrFormsState.get('newItems')
          : Immutable.List();
        newItems = updateDataItems(newItems, item);
        yield put({
          type: `DSR:FORMS:LIST:UPDATE`,
          items: itemsList,
          newItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
  }
}

export const updateDataItems = (items, currentId, type, modifiedItem) => {
  const index = items.findIndex((item) => item.id === currentId);
  if (index !== -1) {
    const updatedItems =
      type === 'edit' ? items.set(index, modifiedItem) : items.remove(index);
    return updatedItems;
  }
  return items;
};
