import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown } from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';

import { getURLWithParams } from '../../utils';

export function* resetAndFetchDataLibraryJobs(action) {
  yield put({ type: 'DATA_LIBRARY:JOBS:INIT' });
  yield fetchDataLibraryJobs(action);
}

export function* filterDataLibraryJobs(action) {
  const { filterParams } = action;
  const { filteredOn, filterKey } = filterParams;

  const modifiedFilteredOn = {
    ...action.filteredOn,
    [filterKey]: filteredOn
  };
  yield put({
    type: 'DATA_LIBRARY:JOBS:FILTER',
    filterParams,
    filteredOn: modifiedFilteredOn
  });
  yield fetchDataLibraryJobs({
    ...action,
    filterParams: { ...filterParams, filteredOn: modifiedFilteredOn }
  });
}

export function* fetchDataLibraryJobs(action) {
  yield put({ type: 'DATA_LIBRARY:JOBS:FETCH' });
  const { filterParams = {}, position = 0, rowCount = 15 } = action;

  try {
    const baseUrl = '/v1/jobs/data-library';
    const { sortOn, sortOrder, filteredOn } = filterParams;
    const response = yield registry.get('request').get(
      getURLWithParams({
        baseUrl,
        filterParams: { sortOn, sortOrder, filteredOn },
        position,
        rowCount
      }),
      null,
      {}
    );
    const modifiedResponse = transformResponseData(response.body);
    switch (response.status) {
      case 200:
        yield put({
          type: 'DATA_LIBRARY:JOBS:FETCH:SUCCESS',
          items: modifiedResponse
        });
        break;
      default:
        yield put({
          type: 'DATA_LIBRARY:JOBS:FETCH:FAIL',
          error: 'Jobs fetch failed'
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: 'DATA_LIBRARY:JOBS:FETCH:FAIL', error: err.message });
  }
}

const updateJobItems = (jobItems, jobId, modifiedItem, actionType) => {
  const items = jobItems.map((item) => {
    if (item.id === jobId) {
      return {
        ...modifiedItem,
        status: actionType === 'restart' ? 'In Progress' : modifiedItem.status
      };
    }
    return item;
  });
  return items;
};

export function* retryOrTerminateDataLibraryJob(action) {
  const { id, actionType, jobItems } = action;
  try {
    const url = `/v1/dataLibraryJobs/${id}/${actionType}`;
    const response = yield registry.get('request').post(url, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content:
              actionType === 'restart'
                ? notificationtranslations.jobRestartedSuccess
                : notificationtranslations.jobTerminatedSuccess,
            type: 'success'
          }
        });
        const items = updateJobItems(jobItems, id, response.body, actionType);
        const modifiedItems = transformResponseData(items);
        yield put({ type: 'DATA_LIBRARY:JOBS:UPDATE', items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: 'DATA_LIBRARY:JOB:UPDATE:FAIL', error: err.message });
  }
}
export const transformUserName = ({ lastName, firstName }) => {
  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  }
  return lastName || firstName || '';
};

export const transformResponseData = (data) => {
  const modifiedData = data.map((item) => ({
    ...item,
    createdBy: item.createdBy ? transformUserName(item.createdBy) : ''
  }));
  return modifiedData;
};
