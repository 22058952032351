import { takeEvery } from 'redux-saga/effects';

import {
  resetAndfetchPrivacyRecordsList,
  filterPrivacyRecordsList,
  fetchPrivacyRecordsList
} from './components/privacy-records/sagas';

import {
  resetAndfetchOrganisations,
  fetchOrganisations,
  updateOrganisation,
  deleteOrganisation,
  searchOrganisations,
  filterOrganisations,
  organisationCheckUsage,
  upsertListOrganisation,
  exportOrganisations
} from './components/organisations/components/list/sagas';

import {
  resetAndfetchRetentionTermsOffsets,
  fetchRetentionTermsOffsets,
  filterRetentionOffsets,
  searchRetentionOffsets,
  fetchRetentionOffsetItem,
  updateRetentionOffsetItem,
  deleteRetentionOffsetItem
} from './components/retention-terms/sagas';
import {
  resetfetchLinkGroupList,
  fetchLinkGroupList,
  exportLinkGroup,
  createMasterdataGroup,
  updateMasterdataGroup,
  deleteLinkGroup,
  bulkDeleteLinkGroup,
  searchLinkGroup
} from './components/links/sagas';
import {
  fetchTenantOrganisations,
  updateTenantOrganisations
} from './components/organisations/components/tenant-organisations/sagas';
import {
  fetchExternalOrganisations,
  updateExternalOrganisations
} from './components/organisations/components/external-organisations/sagas';

import {
  resetAndFetchOrganisationUsers,
  fetchOrganisationUsers,
  resetAndFetchAdminUsers,
  searchAdminUser,
  upsertOrganisationUserDetail,
  deleteOrganisationUser,
  filterOrganisationUser,
  searchOrganisationUser,
  sendBulkInvitation,
  exportUsers
} from './components/organisation-users/sagas';
import {
  resetAndfetchPersonalDataItemsList,
  fetchPersonalDataItems,
  filterPersonalDataItems,
  searchPersonalDataItems,
  upsertPersonalDataItems,
  fetchPersonalDataItem,
  deletePersonalDataItem,
  createPersonalDataItemJob
} from './components/personal-data-items/sagas';
import {
  resetAndfetchDatasources,
  fetchDatasourcesList,
  filterDatasources,
  searchDatasources,
  updateDataSources,
  fetchDataSourceItem,
  deleteDataSources,
  createDataSourceJob
} from './components/data-sources/sagas';

import {
  resetAndfetchDataRecipientCategories,
  fetchDataRecipientCategoryList,
  filterDataRecipientCategories,
  searchDataRecipientCategories,
  updateDataRecipientCategories,
  fetchDataRecipientCategoryItem,
  deleteDataRecipientCategories
} from './components/data-recipient-categories/sagas';
import {
  resetAndfetchPersonalDataCategories,
  fetchPersonalDataCategories,
  filterPersonalDataCategories,
  searchPersonalDataCategories,
  upsertPersonalDataCategories,
  fetchPersonalDataCategory,
  deletePersonalDataCategories
} from './components/personal-data-categories/sagas';
import {
  resetAndfetchDataSourceCategories,
  fetchDataSourceCategories,
  filterDataSourceCategories,
  searchDataSourceCategories,
  upsertDataSourceCategories,
  fetchDataSourceCategory,
  deleteDataSourceCategories
} from './components/data-source-category/sagas';
import {
  resetAndfetchDataSubjectCategories,
  fetchDataSubjectCategoriesList,
  filterDataSubjectCategories,
  searchDataSubjectCategories,
  updateDataSubjectCategories,
  fetchDataSubjectCategory,
  deleteDataSubjectCategories
} from './components/data-subject-categories/sagas';
import {
  resetAndfetchProcessingCategories,
  fetchProcessingCategoriesList,
  filterProcessingCategories,
  searchProcessingCategories,
  updateProcessingCategories,
  fetchProcessingCategory,
  deleteProcessingCategory
} from './components/processing-categories/sagas';
import {
  resetAndfetchSimpleEntities,
  fetchSimpleEntitiesList,
  fetchSimpleEnitity,
  updateSimpleEntity,
  filterSimpleEntities,
  searchSimpleEntities,
  deleteSimpleEntities
} from './components/simple-named-entity/sagas';

import {
  resetAndFetchDocuments,
  fetchDocuments,
  searchDocuments,
  deleteDocument,
  filterDocuments,
  updateDocuments,
  fetchDocumentData,
  downloadDocument
} from './components/documents/sagas';

import {
  checkImportStatus,
  getFileId,
  importMasterData,
  getMaterDataImport,
  downloadErrorFile
} from './master-data-import/sagas';

import {
  checkmasterDataItemImpact,
  createMasterDataEntityJob,
  checkMasterDataEntityJob,
  masterDataViewSimilarItems,
  checkmasterDataMergeItemImpact,
  bulkDeleteEntities,
  masterDataItemsBulkExport,
  checkGlobalmasterDataItemImpact,
  getCustomMasterDataItems,
  changeEnvironmentFilter,
  getStakeholders
} from './sagas';
import { getGraphViewItems } from './master-data-graph-view/sagas';
import fetchOrganisationDetails from '../privacy-record-detail/components/legal-entity-selector/sagas';
import {
  customMasterDataViewSimilarItems,
  deleteSimpleMasterData,
  fetchCustomMasterDataList,
  fetchCustomMasterDataValue,
  filterCustomMasterDataItems,
  resetAndfetchCustomMasterDataList,
  searchCustomMasterData,
  upsertCustomMasterData
} from './components/custom-master-data/sagas';
import {
  fetchStakeholderList,
  filterStakeholders,
  resetAndfetchStakeholderList,
  searchStakeholders
} from './components/stakeholders/sagas';

export default [
  // Organisations
  [takeEvery, 'ORGANISATIONS:LIST:REQUEST_INIT', resetAndfetchOrganisations],
  [takeEvery, 'ORGANISATIONS:LIST:UPSERT', upsertListOrganisation],
  [takeEvery, 'ORGANISATIONS:LIST:NEXT_FETCH', fetchOrganisations],

  [takeEvery, 'ORGANISATIONS:LIST:ITEM:DELETE', deleteOrganisation],
  [takeEvery, 'ORGANISATIONS:LIST:ITEM:UPSERT', updateOrganisation],

  [takeEvery, 'ORGANISATIONS:LIST:SEARCH_INIT', searchOrganisations],
  [takeEvery, 'ORGANISATIONS:LIST:FILTER_INIT', filterOrganisations],

  [takeEvery, 'ORGANISATION_ENTITY:DETAIL:INIT', fetchOrganisationDetails],
  [takeEvery, 'LEGAL_ENTITIES:USAGE_CHECK', organisationCheckUsage],
  [takeEvery, 'ORGANISATIONS:LIST:EXPORT:INIT', exportOrganisations],

  // Environment
  [takeEvery, 'TENANT_ORGANISATION:LIST:REQUEST', fetchTenantOrganisations],
  [takeEvery, 'TENANT_ORGANISATIONS:UPDATE', updateTenantOrganisations],
  [takeEvery, 'EXTERNAL_ORGANISATION:LIST:REQUEST', fetchExternalOrganisations],
  [takeEvery, 'EXTERNAL_ORGANISATIONS:UPDATE', updateExternalOrganisations],

  [takeEvery, 'MASTER_DATA_ITEM:CHECK:IMPACT', checkmasterDataItemImpact],
  [
    takeEvery,
    'GLOBAL:MASTER_DATA_ITEM:CHECK:IMPACT',
    checkGlobalmasterDataItemImpact
  ],
  [takeEvery, 'MASTER_DATA_ITEM:CREATE:JOB', createMasterDataEntityJob],
  [takeEvery, 'MASTER_DATA_ITEM:EDIT:JOB:UNCHECK', checkMasterDataEntityJob],
  [
    takeEvery,
    'MASTER_DATA_ITEM:MERGE:CHECK:USAGE',
    checkmasterDataMergeItemImpact
  ],
  [takeEvery, 'MASTERDATA:BULK_EXPORT:INIT', masterDataItemsBulkExport],
  [takeEvery, 'IMPORT:STATUS:CHECK', checkImportStatus],
  [takeEvery, 'DOWNLOAD:IMPORT:ERROR:FILE', downloadErrorFile],

  [takeEvery, 'ADMIN_USERS:LIST:FETCH', resetAndFetchAdminUsers],
  [takeEvery, 'ADMIN_USER:LIST:SEARCH_INIT', searchAdminUser],
  [
    takeEvery,
    'ORGANISATION_USERS:LIST:REQUEST',
    resetAndFetchOrganisationUsers
  ],
  [takeEvery, 'ORGANISATION_USERS:LIST:NEXT_FETCH', fetchOrganisationUsers],
  [takeEvery, 'ORGANISATION:USER:DETAIL:UPSERT', upsertOrganisationUserDetail],
  [takeEvery, 'ORGANISATION_USER:ITEM:DELETE', deleteOrganisationUser],
  [takeEvery, 'ORGANISATION_USER:LIST:FILTER_INIT', filterOrganisationUser],
  [takeEvery, 'ORGANISATION_USER:LIST:SEARCH_INIT', searchOrganisationUser],
  [takeEvery, 'ORGANISATION:USERS:EXPORT:INIT', exportUsers],
  [takeEvery, 'USERS:SSO_BYPASS:SET_PASSWORD', sendBulkInvitation],
  [takeEvery, 'DOCUMENTS:CSV:FILEID', getFileId],
  [takeEvery, 'DOCUMENTS:CSV:UPLOAD', importMasterData],
  [takeEvery, 'IMPORT:FETCH:INIT', getMaterDataImport],
  [takeEvery, 'MASTER:DATA:GRAPH:VIEW:ITEMS', getGraphViewItems],

  // Personal data items
  [
    takeEvery,
    'PERSONAL_DATA_ITEMS:LIST:REQUEST_INIT',
    resetAndfetchPersonalDataItemsList
  ],
  [takeEvery, 'PERSONAL_DATA_ITEMS:LIST:NEXT_FETCH', fetchPersonalDataItems],
  [takeEvery, 'PERSONAL_DATA_ITEMS:LIST:FILTER_INIT', filterPersonalDataItems],
  [takeEvery, 'PERSONAL_DATA_ITEMS:LIST:SEARCH_INIT', searchPersonalDataItems],
  [takeEvery, 'PERSONAL_DATA_ITEMS:UPSERT', upsertPersonalDataItems],
  [takeEvery, 'PERSONAL_DATA_ITEM:FETCH', fetchPersonalDataItem],
  [takeEvery, 'PERSONAL_DATA_ITEMS:ITEM:DELETE', deletePersonalDataItem],
  [takeEvery, 'PERSONAL_DATA_ITEM:CREATE:JOB', createPersonalDataItemJob],

  // Datasources
  [
    takeEvery,
    'MASTERDATA:DATASOURCES:LIST:REQUEST_INIT',
    resetAndfetchDatasources
  ],
  [takeEvery, 'DATASOURCES:LIST:NEXT_FETCH', fetchDatasourcesList],
  [takeEvery, 'DATASOURCES:LIST:FILTER_INIT', filterDatasources],
  [takeEvery, 'DATASOURCES:LIST:SEARCH_INIT', searchDatasources],
  [takeEvery, 'DATASOURCES:LIST:UPSERT', updateDataSources],
  [takeEvery, 'DATASOURCES:ITEM:FETCH', fetchDataSourceItem],
  [takeEvery, 'DATASOURCES:ITEM:DELETE', deleteDataSources],
  [takeEvery, 'DATASOURCES:ITEM:CREATE:JOB', createDataSourceJob],

  // Data recipient category
  [
    takeEvery,
    'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:REQUEST_INIT',
    resetAndfetchDataRecipientCategories
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:LIST:NEXT_FETCH',
    fetchDataRecipientCategoryList
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:LIST:FILTER_INIT',
    filterDataRecipientCategories
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:LIST:SEARCH_INIT',
    searchDataRecipientCategories
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:LIST:UPSERT',
    updateDataRecipientCategories
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:ITEM:FETCH',
    fetchDataRecipientCategoryItem
  ],
  [
    takeEvery,
    'DATARECIPIENTCATEGORIES:ITEM:DELETE',
    deleteDataRecipientCategories
  ],

  // Personal data categories
  [
    takeEvery,
    'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:REQUEST_INIT',
    resetAndfetchPersonalDataCategories
  ],
  [
    takeEvery,
    'PERSONAL_DATA_CATEGORIES:LIST:NEXT_FETCH',
    fetchPersonalDataCategories
  ],
  [
    takeEvery,
    'PERSONAL_DATA_CATEGORIES:LIST:FILTER_INIT',
    filterPersonalDataCategories
  ],
  [
    takeEvery,
    'PERSONAL_DATA_CATEGORIES:LIST:SEARCH_INIT',
    searchPersonalDataCategories
  ],
  [takeEvery, 'PERSONAL_DATA_CATEGORIES:UPSERT', upsertPersonalDataCategories],
  [takeEvery, 'PERSONAL_DATA_CATEGORIES:ITEM:FETCH', fetchPersonalDataCategory],
  [
    takeEvery,
    'PERSONAL_DATA_CATEGORIES:ITEM:DELETE',
    deletePersonalDataCategories
  ],

  [
    takeEvery,
    'MASTERDATA:DATA_SOURCE_CATEGORIES:LIST:REQUEST_INIT',
    resetAndfetchDataSourceCategories
  ],
  [
    takeEvery,
    'DATA_SOURCE_CATEGORIES:LIST:NEXT_FETCH',
    fetchDataSourceCategories
  ],
  [
    takeEvery,
    'DATA_SOURCE_CATEGORIES:LIST:FILTER_INIT',
    filterDataSourceCategories
  ],
  [
    takeEvery,
    'DATA_SOURCE_CATEGORIES:LIST:SEARCH_INIT',
    searchDataSourceCategories
  ],
  [takeEvery, 'DATA_SOURCE_CATEGORIES:UPSERT', upsertDataSourceCategories],
  [takeEvery, 'DATA_SOURCE_CATEGORIES:ITEM:FETCH', fetchDataSourceCategory],
  [takeEvery, 'DATA_SOURCE_CATEGORIES:ITEM:DELETE', deleteDataSourceCategories],

  // Data subject categories
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORIES:LIST:REQUEST_INIT',
    resetAndfetchDataSubjectCategories
  ],
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORIES:LIST:NEXT_FETCH',
    fetchDataSubjectCategoriesList
  ],
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORIES:LIST:FILTER_INIT',
    filterDataSubjectCategories
  ],
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORIES:LIST:SEARCH_INIT',
    searchDataSubjectCategories
  ],
  [takeEvery, 'DATA_SUBJECT_CATEGORIES:UPSERT', updateDataSubjectCategories],
  [takeEvery, 'DATA_SUBJECT_CATEGORIES:ITEM:FETCH', fetchDataSubjectCategory],
  [
    takeEvery,
    'DATA_SUBJECT_CATEGORIES:ITEM:DELETE',
    deleteDataSubjectCategories
  ],

  // Processing categories
  [
    takeEvery,
    'PROCESSING_CATEGORIES:LIST:REQUEST_INIT',
    resetAndfetchProcessingCategories
  ],
  [
    takeEvery,
    'PROCESSING_CATEGORIES:LIST:NEXT_FETCH',
    fetchProcessingCategoriesList
  ],
  [
    takeEvery,
    'PROCESSING_CATEGORIES:LIST:FILTER_INIT',
    filterProcessingCategories
  ],
  [
    takeEvery,
    'PROCESSING_CATEGORIES:LIST:SEARCH_INIT',
    searchProcessingCategories
  ],
  [takeEvery, 'PROCESSING_CATEGORIES:UPSERT', updateProcessingCategories],
  [takeEvery, 'PROCESSING_CATEGORIES:ITEM:FETCH', fetchProcessingCategory],
  [takeEvery, 'PROCESSING_CATEGORIES:ITEM:DELETE', deleteProcessingCategory],

  // Simple named entities
  [takeEvery, 'SIMPLE_ENTITY:LIST:REQUEST_INIT', resetAndfetchSimpleEntities],
  [takeEvery, 'SIMPLE_ENTITY:LIST:NEXT_FETCH', fetchSimpleEntitiesList],
  [takeEvery, 'SIMPLE_ENTITY:ITEM:FETCH', fetchSimpleEnitity],
  [takeEvery, 'SIMPLE_ENTITY:ITEM:FILTER_INIT', filterSimpleEntities],
  [takeEvery, 'SIMPLE_ENTITY:ITEM:SEARCH_INIT', searchSimpleEntities],
  [takeEvery, 'SIMPLE_ENTITY:ITEM:DELETE', deleteSimpleEntities],

  [takeEvery, 'SIMPLE_ENTITY:UPSERT', updateSimpleEntity],
  [takeEvery, 'MASTERDATA:ITEM:BULK_DELETE', bulkDeleteEntities],

  [takeEvery, 'MASTER:DATA:VIEW:SIMILAR:ITEMS', masterDataViewSimilarItems],

  // Link group
  [takeEvery, 'MASTERDATA:LINK_GROUP:UPSERT', createMasterdataGroup],
  [takeEvery, 'MASTERDATA:LINK_GROUP:UPDATE', updateMasterdataGroup],
  [takeEvery, 'LINK_GROUP:LIST:REQUEST_INIT', resetfetchLinkGroupList],
  [takeEvery, 'LINK_GROUP:LIST:NEXT_FETCH', fetchLinkGroupList],
  [takeEvery, 'LINK_GROUP:LIST:DELETE', deleteLinkGroup],
  [takeEvery, 'LINK_GROUP:BULK_DELETE', bulkDeleteLinkGroup],
  [takeEvery, 'LINK_GROUP:EXPORT', exportLinkGroup],
  [takeEvery, 'LINK_GROUP:LIST:SEARCH', searchLinkGroup],

  // Retention terms
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:LIST:REQUEST_INIT',
    resetAndfetchRetentionTermsOffsets
  ],
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:CREATE:NEW',
    updateRetentionOffsetItem
  ],
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:LIST:NEXT_FETCH',
    fetchRetentionTermsOffsets
  ],
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH_INIT',
    searchRetentionOffsets
  ],
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:LIST:FILTER_INIT',
    filterRetentionOffsets
  ],
  [
    takeEvery,
    'MASTERDATA:RETENTION_OFFSETS:ITEM:DELETE',
    deleteRetentionOffsetItem
  ],
  [takeEvery, 'RETENTION_OFFSET:ITEM:FETCH', fetchRetentionOffsetItem],
  [takeEvery, 'RETENTION_OFFSET:ITEM:UPDATE', updateRetentionOffsetItem],

  // Docuemnts
  [takeEvery, 'MASTERDATA:DOCUMENTS:LIST:REQUEST_INIT', resetAndFetchDocuments],
  [takeEvery, 'DOCUMENTS:LIST:NEXT_FETCH', fetchDocuments],
  [takeEvery, 'DOCUMENTS:ITEM:FETCH', fetchDocumentData],
  [takeEvery, 'DOCUMENTS:LIST:FILTER_INIT', filterDocuments],
  [takeEvery, 'DOCUMENTS:LIST:SEARCH_INIT', searchDocuments],
  [takeEvery, 'DOCUMENTS:UPSERT', updateDocuments],
  [takeEvery, 'DOCUMENTS:ITEM:DELETE', deleteDocument],
  [takeEvery, 'VENDOR:DOCUMENTS:ITEM:DELETE', deleteDocument],
  [takeEvery, 'DOCUMENTS:ITEM:DOWNLOAD', downloadDocument],

  // Privacy records
  [
    takeEvery,
    'PRIVACY:RECORDS:LIST:REQUEST_INIT',
    resetAndfetchPrivacyRecordsList
  ],
  [takeEvery, 'PRIVACY:RECORDS:LIST:NEXT_FETCH', fetchPrivacyRecordsList],
  [takeEvery, 'PRIVACY:RECORDS:LIST:FILTER_INIT', filterPrivacyRecordsList],
  [
    takeEvery,
    'ENVIRONMENT:CUSTOM_SIMPLE_DATA:FILTERS:INIT',
    getCustomMasterDataItems
  ],
  [takeEvery, 'ENVIRONMENT:STAKEHOLDERS:FILTERS:INIT', getStakeholders],

  // Custom masterdata
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:REQUEST_INIT',
    resetAndfetchCustomMasterDataList
  ],
  [takeEvery, 'CUSOM:MASTER_DATA:LIST:UPSERT', upsertCustomMasterData],
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH',
    fetchCustomMasterDataValue
  ],
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:VALUE:ITEM:DELETE',
    deleteSimpleMasterData
  ],
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:FILTER_INIT',
    filterCustomMasterDataItems
  ],
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:SEARCH_INIT',
    searchCustomMasterData
  ],
  [
    takeEvery,
    'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:NEXT_FETCH',
    fetchCustomMasterDataList
  ],
  [
    takeEvery,
    'MASTER:CUSTOM:SIMPLE:DATA:VIEW:SIMILAR:ITEMS',
    customMasterDataViewSimilarItems
  ],
  [takeEvery, 'ENVIRONMENT:CUSTOM_FILTER:CHANGE', changeEnvironmentFilter],

  // Stakeholders
  [takeEvery, 'STAKEHOLDERS:LIST:REQUEST_INIT', resetAndfetchStakeholderList],
  [takeEvery, 'STAKEHOLDERS:LIST:NEXT_FETCH', fetchStakeholderList],
  [takeEvery, 'STAKEHOLDERS:LIST:FILTER_INIT', filterStakeholders],
  [takeEvery, 'STAKEHOLDERS:LIST:SEARCH_INIT', searchStakeholders]
];
