import Immutable from 'immutable';
import { getSearchText, removeDuplicates, getFilteredOn } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  offsets: Immutable.List(),
  newItems: Immutable.List(),
  searchText: Immutable.Map(),
  searchItems: Immutable.List(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  offsetItem: undefined,
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  usage: undefined,
  bulkItems: Immutable.List(),
  unused: false,
  filteredOn: Immutable.Map(),
  filterColumn: {},
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('offsetItem', defaultState.get('offsetItem'));
    case 'MASTERDATA:RETENTION_OFFSETS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('isFetching', true)
        .set('offsets', defaultState.get('offsets'))
        .set('newItems', defaultState.get('newItems'))
        .set('offsetItem', defaultState.get('offsetItem'))
        .set('position', 0);

    case 'MASTERDATA:RETENTION_OFFSETS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.offsetItem) {
        newItems = newItems.unshift(action.offsetItem);
        newState = newState.set('newItems', newItems);
      }
      const offsets = removeDuplicates(newItems, state.get('offsets'), action.offsets);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.offsets.length)
        .set('offsets', Immutable.List(offsets));
    }
    case 'MASTERDATA:RETENTION_OFFSETS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'MASTERDATA:RETENTION_OFFSETS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('offsets', defaultState.get('offsets'));
      }

      return newState.set('error', defaultState.get('error'))
        .set('isFetching', false)
        .set('offsetItem', action.offsetItem);
    }
    case 'MASTERDATA:RETENTION_OFFSETS:UPSERT:FAIL':
    case 'MASTERDATA:RETENTIONTERMOFFSET:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'MASTERDATA:RETENTION_OFFSETS:LIST:UPDATE:SUCCESS':
      return state
        .set('offsets', action.offsets)
        .set('newItems', action.newItems)
        .set('position', action.position ? action.position : state.get('position'));

    case 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('position', action.items.length)
        .set('offsets', Immutable.List(action.items));
    case 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MASTERDATA:RETENTION_OFFSETS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('offsets', Immutable.List(action.offsets))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:RETENTION_OFFSETS:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:RETENTIONTERMOFFSETS:USAGE:RESET':
      return state
        .set('usage', defaultState.get('usage'));
    case 'MASTERDATA:RETENTIONTERMOFFSETS:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:RETENTIONTERMOFFSETS:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:RETENTION_OFFSETS:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:SUCCESS':
      return state.set('offsetItem', action.offsetItem)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:RETENTION_OFFSETS:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId, action.unused));
    }
    case 'MASTERDATA:RETENTION_OFFSETS:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:RETENTION_OFFSETS:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);
    case 'MASTERDATA:RETENTION_OFFSETS:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    case 'MASTERDATA:RETENTIONTERMOFFSETS:VIEW:SIMILAR:SUCCESS':
      return state
        .set('offsets', Immutable.List(action.items));
    default:
      return state;
  }
};
