import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  items: Immutable.List([]),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOrder: 'ASC',
    sortOn: 'name'
  }),
  isFetching: false,
  filterColumn: {},
  showNotification: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DATA_LIBRARY:RECORDS:INIT':
      return state
        .set('filteredOn', state.get('filteredOn'))
        .set('filterParams', state.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'DATA_LIBRARY:RECORDS:FETCH':
      return state
        .set('error', defaultState.get('error'))
        .set('isFetching', true);
    case 'DATA_LIBRARY:RECORDS:FETCH:SUCCESS': {
      const { items } = action;
      const newItems = state.get('items').concat(items);
      const newPosition = state.get('position') + items.length;
      return state
        .set('items', newItems)
        .set('position', newPosition)
        .set('isFetching', false);
    }
    case 'DATA_LIBRARY:RECORDS:FETCH:FAIL': {
      const { error } = action;
      return state
        .set('error', Immutable.Map({ message: error, isError: true }))
        .set('isFetching', false);
    }
    case 'DATA_LIBRARY:RECORDS:FILTER': {
      const { filterParams, filteredOn } = action;
      const { filterKey } = filterParams;
      const filterColumn = {
        ...state.get('filterColumn'),
        [filterKey]: filterKey
      };
      return state
        .set('position', 0)
        .set('items', Immutable.List())
        .set('filterParams', Immutable.Map(filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', Immutable.Map(filteredOn));
    }
    case 'DATA_LIBRARY:RECORDS:FILTER:RESET':
      return state
        .set('filterParams', defaultState.get('filterParams'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterColumn', defaultState.get('filterColumn'));
    case 'DATA_LIBRARY:RECORDS:PAGE_SEARCH': {
      const { searchParams } = action;
      return state
        .set('isFetching', true)
        .set('pageSearchText', Immutable.Map(searchParams))
        .set('items', Immutable.List())
        .set('error', defaultState.get('error'));
    }
    case 'DATA_LIBRARY:RECORDS:PAGE_SEARCH:RESET':
      return state.set('pageSearchText', defaultState.get('pageSearchText'));
    case 'DATA_LIBRARY:DISCONNECT_SYNC:RECORD:SUCCESS': {
      const { recordId, updatedRecordData } = action;
      const items = state
        .get('items')
        .map((item) => (item.id === recordId ? updatedRecordData : item));
      return state.set('items', items);
    }
    default:
      return state;
  }
};
