import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import ItemSelector from '../item-selector';

import countryTranslations from './country';
import ItemList from '../form-components/itemList';
import MultipleSelectorDialog from '../multiple-selector/multipleSelectorDialog';

const modifiedCountryList = countryTranslations.map(item => ({
  ...item,
  countryCode: item.value
}));
const MultipleCountrySelector = (props) => {
  const [open, setOpen] = useState(false);

  const filterCountries = (selectedCountries, countryList) => {
    const filteredData = countryList?.filter((country) => {
      const index = selectedCountries?.findIndex(selectedCountry =>
        (selectedCountry.value.countryCode || selectedCountry.countryCode) === country.countryCode);
      return (index === -1);
    });
    const modifiedValue = filteredData.map(item => ({
      ...item,
      countryCode: item.value,
      key: props.intl.formatMessage(item.text.country),
      value: props.intl.formatMessage(item.text.country)
    }));
    return modifiedValue;
  };
  const caseInsensitiveFilter = (searchText, key) => {
    const { formatMessage } = props.intl;
    const translatedCountry = formatMessage(key.country).toLowerCase();
    const formattedSearchText = typeof searchText === 'object' ? formatMessage(searchText.country) : searchText;
    return (translatedCountry.startsWith(formattedSearchText.toLowerCase()) === true);
  };

  const handleSelectedItem = (selectedItem) => {
    const items = props.fields.getAll() || [];
    const selectedCountries = [...items, selectedItem];

    if (props.updateCountries) {
      props.updateCountries(selectedCountries);
    }
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleMultipleSelect = () => {
    setOpen(true);
  };

  const handleMultipleItems = (selectedItems) => {
    const items = props.fields.getAll() || [];
    const modifiedItems = selectedItems.map(item => ({
      ...item.value
    }));
    const selectedCountries = [...modifiedItems, ...items];
    if (props.updateCountries) {
      props.updateCountries(selectedCountries);
    }
  };

  // Handler to remove item
  const handleRemoveItem = (selectedIndex) => {
    const selectedCountries = [...props.fields.getAll()];
    if (selectedIndex !== -1) {
      selectedCountries.splice(selectedIndex, 1);
    }
    if (props.updateCountries) {
      props.updateCountries(selectedCountries);
    }
  };

  const { hintTextLabel, theme } = props;
  const { formatMessage } = props.intl;
  const selectedCountries = props.fields.getAll() || [];
  const mutipleCountryList = filterCountries(selectedCountries,
    modifiedCountryList, formatMessage);
  return (
    <div>
      <ItemList
        id="country-list"
        isEditable={false}
        isNote={false}
        muiTheme={theme}
        {...props}
        type="country"
        handleRemoveItem={handleRemoveItem}
      />
      <ItemSelector
        id="country-selector"
        multiValue={true}
        createNewMenuItem={false}
        selectFromListMenuItem={true}
        dataSource={mutipleCountryList}
        dataSourceFilter={caseInsensitiveFilter}
        dataSourceConfig={{ text: 'text', value: 'countryCode' }}
        hintTextLabel={formatMessage(hintTextLabel.props)}
        handleSelectedItem={handleSelectedItem}
        handleMultipleSelect={handleMultipleSelect}
        disabled={props.disabled}
      />
      {open && <MultipleSelectorDialog
        title={
          <h3>
            <FormattedMessage
              id="MultipleCountryDialog.header"
              description="Select country Header"
              defaultMessage="Select countries"
            />
          </h3>}
        id="country-multiselector"
        open={open}
        filteredData={mutipleCountryList}
        onRequestClose={handleRequestClose}
        handleMultipleItems={handleMultipleItems}
        isSearchEnabled={false}
      />}
    </div>
  );
};

MultipleCountrySelector.propTypes = {
  hintTextLabel: PropTypes.node,
  disabled: PropTypes.bool,
  updateCountries: PropTypes.func,
  textFieldStyle: PropTypes.objectOf(PropTypes.string),
  listStyle: PropTypes.objectOf(PropTypes.string),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: ''
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  fields: PropTypes.shape({
    map: PropTypes.func,
    getAll: PropTypes.func,
    get: PropTypes.func,
    length: PropTypes.number
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ])
};

MultipleCountrySelector.defaultProps = {
  hintTextLabel: null,
  disabled: false,
  defaultValue: {},
  updateCountries: e => e,
  textFieldStyle: {},
  listStyle: {},
  input: {},
  theme: {},
  onChange: e => e
};

export default withTheme(injectIntl(MultipleCountrySelector));
