import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import { getURLWithParams } from '../../saga-utils';

export default function* searchPersonalDataItemSelector(action) {
  const { searchParams } = action;
  const { searchKey, searchText } = searchParams;

  yield put({ type: 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(action.position, searchParams, 'personaldataitems', MASTERDATA_SEARCH_LIMIT), null, {});
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'RECORDS:PERSONAL_DATA_ITEMS:SEARCH:FAIL', error: err.message });
  }
}

