import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import { getErrorMessage, handleServiceDown } from '@packages/utils/common-utils';
import { recordTranslations } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';

export function* resetfetchLinkGroupList(action) {
  yield put({ type: 'LINK_GROUP:LIST:FETCH' });
  yield fetchLinkGroupList(action);
}

export function* createMasterdataGroup(action) {
  const request = registry.get('request');
  try {
    const response = yield request.post(`/v1/links`, action.data);
    switch (response.status) {
      case 200: {
        yield put({ type: 'MASTERDATA:LINK_GROUP:UPSERT:SUCCESS' });
        yield resetfetchLinkGroupList({ unused: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupCreated,
            type: 'success'
          }
        });
        break;
      }
      case 409: yield put({
        type: 'MASTERDATA:LINK_GROUP:UPSERT:FAIL',
        error: `${response.body.msg} - ${response.body.details.duplicateEntity} `
      });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* updateMasterdataGroup(action) {
  const request = registry.get('request');
  const updatedData = action.data;
  try {
    const response = yield request.put(`/v1/links/${action.groupId}`, updatedData);
    switch (response.status) {
      case 200: {
        yield put({ type: 'MASTERDATA:LINK_GROUP:UPSERT:SUCCESS' });
        yield resetfetchLinkGroupList({ unused: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupUpdated,
            type: 'success'
          }
        });
        break;
      }
      case 409: yield put({
        type: 'MASTERDATA:LINK_GROUP:UPSERT:FAIL',
        error: `${response.body.msg} - ${response.body.details.duplicateEntity} `
      });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchLinkGroupList(action) {
  try {
    const { unused = false, position = 0, rowCount = 500 } = action;
    let url = unused === true ? `unused=true` : `unused=false`;
    let searchParams = {};
    const groupListState = yield select(state => state.environment.linkGroup);
    searchParams = action.source ? groupListState.get(`${action.source}SearchParams`) : {};
    if (searchParams.searchKey) {
      url = `${url}&search=${searchParams.searchKey}=${encodeURIComponent(searchParams.searchText)}`;
    }
    const response = yield registry.get('request')
      .get(`/v1/links?${url}&numberOfResults=${rowCount}&offset=${position}`, null);
    switch (response.status) {
      case 200: {
        yield put({ type: 'LINK_GROUP:LIST:FETCH:SUCCESS', items: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'LINK_GROUP:LIST:FETCH:FAIL', error: err.message });
    registry.get('logger').error(err);
  }
}

export function* exportLinkGroup() {
  const request = registry.get('request');

  try {
    const response = yield request.exportCSVbyGet('/v1/entities/link/export', null);

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    const error = getErrorMessage(err);
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: error,
        type: 'error'
      }
    });
  }
}

export function* deleteLinkGroup(action) {
  const { groupId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/links/${groupId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.linkGroupDeleted,
            type: 'success'
          }
        });
        const groupList = yield select(state => state.environment.linkGroup);
        const reqItems = groupList.get('items');
        const newItems = updateGroupItems(reqItems, groupId);
        yield put({ type: `LINK_GROUP:LIST:UPDATE`, items: newItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'LINK_GROUP:LIST:FETCH:FAIL', error: err.message });
    registry.get('logger').error(err);
  }
}

export function* bulkDeleteLinkGroup(action) {
  const linkIds = action.bulkItems.map(item => (item.id));
  const request = registry.get('request');
  try {
    const response = yield request.post(`/v1/links/remove`, { linkIds });
    switch (response.status) {
      case 204: {
        yield put({ type: `LINK_GROUP:BULK:DELETE:SUCCESS` });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.linkGroupDeleted,
            type: 'success'
          }
        });
        yield resetfetchLinkGroupList(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'LINK_GROUP:LIST:FETCH:FAIL', error: err.message });
    registry.get('logger').error(err);
  }
}

export function* searchLinkGroup(action) {
  yield put({ type: `LINK_GROUP:SEARCH`, source: action.source, searchParams: action.searchParams });
  yield resetfetchLinkGroupList({ source: action.source });
}

export const updateGroupItems = (items, currentId) => {
  const index = items.findIndex(item => item.id === currentId);
  if (index !== -1) {
    items.splice(index, 1);
    return items;
  }
  return items;
};
