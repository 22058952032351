/* eslint-disable default-param-last */
import Immutable from 'immutable';
import {
  getParameterValuesFromHash,
  getQueryStrings
} from '@packages/utils/query-parameters';
import { getNameAndValue } from '../common-utils';

const defaultState = Immutable.fromJS({
  isEditable: true,
  isFetching: undefined,
  isUpdating: false,
  unSavedData: false,
  isDownloading: false,
  layout: undefined,
  data: Immutable.Map(),
  prevLocation: '/privacyrecords',
  recordId: undefined,
  recordName: '',
  showConfirmDialog: true,
  actionError: undefined,
  isUpdateLocked: false,
  currentFilter: 'general',
  openPromoteDialog: false,
  links: Immutable.Map({
    personalDataCategories: [],
    dataSubjectCategories: [],
    personalDataItems: [],
    dataSources: [],
    retentionTerms: []
  }),
  ccpaLegalQualifications: Immutable.Map({
    personalDataSaleCompensation: '',
    underSixteenOptInMeasures: '',
    optOutWebsiteLink: '',
    userRight: '',
    dsrMethods: Immutable.Map({
      link: '',
      mailAddress: '',
      phoneNumber: '',
      emailAddress: '',
      physicalAddress: '',
      other: ''
    })
  }),
  users: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PROCESSING:DETAIL:INIT':
    case 'PROCESSING:DETAIL:RESET':
      return defaultState;
    case 'PROCESSING:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/processing/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'reports') {
        prevLocation = '/reportsearch/results';
      } else if (queryParams.from === 'environment/links') {
        prevLocation = '/environment';
      } else if (
        queryParams.from === 'environment' ||
        queryParams.from === 'edit'
      ) {
        const environmentQueryParams = getNameAndValue(); // To handle query parameters with special characters.
        const queryParamsUrl = `filter=${queryParams.filter}&value=${
          environmentQueryParams.value
        }${
          environmentQueryParams.name
            ? `&name=${environmentQueryParams.name}`
            : ''
        }`;
        prevLocation = `/records?${queryParamsUrl}`;
      } else if (queryParams.from === 'publicList') {
        const { registryId } = getParameterValuesFromHash('/:registryId');
        prevLocation = `/${registryId}?embed=${queryParams.embed}&isPreview=${queryParams.isPreview}`;
      }
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isFetching', true)
        .set('prevLocation', prevLocation)
        .set('recordId', action.recordId)
        .set('isLoading', true);
    }
    case 'PROCESSING:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);
    case 'PROCESSING:DETAIL:FETCH:SUCCESS':
      return state
        .set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('recordName', action.recordName)
        .set('isLoading', false);
    case 'PROCESSING:DETAIL:FETCH:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false)
        .set('isLoading', false);
    case 'PROCESSING:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'PROCESSING:DETAIL:UPSERT':
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isUpdating', true);
    case 'PROCESSING:DETAIL:UPSERT:SUCCESS':
      return state
        .set('isUpdating', false)
        .set('data', Immutable.Map(action.data))
        .set('unSavedData', false);
    case 'PROCESSING:DETAIL:UPSERT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false);
    case 'PROCESSING:DETAIL:ERROR:RESET':
      return state.set('actionError', defaultState.get('actionError'));
    case 'PROCESSING:DETAIL:TEMPLATE:SAVE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'PROCESSING:PROPERTY:UPDATE':
      return state.set('actionError', defaultState.get('actionError'));
    case 'PROCESSING:PROPERTY:UPDATE:SUCCESS':
      return state
        .set('data', Immutable.Map(action.data))
        .set('unSavedData', true);
    case 'PROCESSING:PROPERTY:UPDATE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'PROCESSING:DETAIL:UPSERT:LOCKED':
      return state.set('isUpdateLocked', true);
    case 'PROCESSING:PROPERTY:LINK:TOGGLE_UPDATE': {
      return state.setIn(['data', action.key], [...action.items]);
    }
    case 'PROCESSING:UNSAVED:DATA:SET':
      return state.set('unSavedData', true);
    case 'PROCESSING:UNSAVED:DATA:RESET':
    case 'RECORDS:UNSAVED_DATA:RESET':
      return state.set('unSavedData', defaultState.get('unSavedData'));
    case 'PROCESSING:UNSAVED:PROPERTY:RESET':
      return state
        .set('isUpdating', true)
        .set('unSavedData', defaultState.get('unSavedData'));
    case 'PROCESSING:TOGGLE_PROMOTION_DIALOG':
      return state.set('openPromoteDialog', !state.get('openPromoteDialog'));
    case 'PROCESSING:SHOW:CONFIRM:DIALOG:RESET': {
      return state.set('showConfirmDialog', false);
    }
    case 'PROCESSING:BULK:UPDATE:INIT':
      return state.set('isUpdating', true);
    case 'PROCESSING:BULK:UPDATE:FAIL':
    case 'PROCESSING:BULK:UPDATE:SUCCESS':
      return state.set('isUpdating', false);
    case 'PROCESSING:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'PROCESSING:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'PROCESSING:COMMENT:DELETE:SUCCESS':
    case 'PROCESSING:COMMENT:SAVE:SUCCESS': {
      return state.set('data', Immutable.Map(action.data));
    }
    case 'RECORD_PDF:DOWNLOAD:INIT':
      return state.set('isDownloading', true);
    case 'RECORD_PDF:DOWNLOAD:FAIL':
    case 'RECORD_PDF:DOWNLOAD:SUCCESS':
      return state.set('isDownloading', false);
    case 'PROCESSING:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'PROCESSING:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);
    default:
      return state;
  }
};
