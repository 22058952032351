import { connect } from 'react-redux';
import SimpleNamedSelector from './component';

const mapStateToProps = state => ({
  simpleEntities: state.environment.simpleEntities,
  searchResults: state.simpleEntities.get('entitySelectorSearch')
});

const mapDispatchToProps = dispatch => ({
  initialiseEntities: (masterDataType, entityType) => {
    dispatch({ type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`, masterDataType, entityType });
  },
  getNextData: (position, masterDataType, entityType) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      position,
      masterDataType,
      entityType });
  },
  onSearch: (searchParams, masterDataType, entityType) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams,
      masterDataType,
      entityType });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleNamedSelector);
