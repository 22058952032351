import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown, MESSAGES_SEARCH_LIMIT } from '@packages/utils/common-utils';

export function* searchMessageRecipients(action) {
  const { rowCount = MESSAGES_SEARCH_LIMIT, position = 0, searchText } = action;
  try {
    let url = `/v1/messages/recipients?offset=${position}&numberOfResults=${rowCount}`;
    url = `${url}&search=name=${encodeURIComponent(searchText)}`;
    const response = yield registry.get('request')
      .get(url, null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => transformUserItem(item));
        yield put({
          type: `MESSAGES:USERS:SELECTOR:LIST:SEARCH:SUCCESS`,
          items: modifiedItems.filter(item => item.label !== ''),
          searchText
        });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: `MESSAGES:USERS:SELECTOR:LIST:SEARCH:FAIL`, error: err.message });
  }
}

export const transformUserItem = item => ({
  ...item,
  label: item.firstName ?
    `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}` : '',
  key: item.firstName ?
    `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}` : '',
  value: item.id
});
