/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import FontAwesome from 'react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import { withTheme } from '@material-ui/core/styles';
import { FixedSizeList as List } from 'react-window';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AutoComplete from '@packages/components/auto-complete';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import DivWrapper from '@packages/components/divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import CountrySelector from '@packages/components/country-selector';
import styles from '@packages/components/styles';
import { commonTranslations, orgTypesTranslations } from '@packages/utils/commontranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';

const subHeaderStyle = {
  color: 'black',
  lineHeight: '20px',
  paddingLeft: 0,
  fontSize: '16px'
};

const legalEntityStyle = {
  height: 10, marginTop: -4
};

const externalOrgStyle = {
  height: 0.5, marginTop: -4
};
const multiselectorStyle = {
  width: '70%',
  display: 'flex',
  paddingBottom: '20px'
};
const textFielsStyle = { width: '97%', backgroundColor: '#ffffff' };

const labelStyle = { width: '80%', paddingBottom: '10px' };

const countrySelectorStyle = { display: 'flex', width: '100%' };

const crossIconStyle = { lineHeight: '1em',
  fontSize: '20px',
  marginTop: 10,
  marginLeft: 15,
  color: 'black',
  cursor: 'pointer' };

const checkboxStyle = { display: 'flex', flexDirection: 'row', paddingBottom: 15, flexWrap: 'wrap' };

const filterStyle = { width: '49%', paddingBottom: 10 };


const multipleOptionStyle = { overflow: 'none', width: '95%' };

const labelWithEllipsis = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px'
};

let searchDelayTimeout = null;
export class MultipleSelectorForm extends React.Component {
  listRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      filterSelectAll: false,
      filterValues: this.props.filteredData,
      checkLegalEntities: true,
      checkDepartments: true,
      checkInternal: true,
      checkExternal: !this.props.isInternal,
      selectedItems: Immutable.List(),
      country: '',
      showAdvancedSearch: false,
      searchText: props.searchText,
      tagSearchText: '',
      tagsList: [],
      tags: undefined,
      multiple: false
    };

    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleCheckLegalEntities = this.handleCheckLegalEntities.bind(this);
    this.handleCheckDepartments = this.handleCheckDepartments.bind(this);
    this.handleCheckInternal = this.handleCheckInternal.bind(this);
    this.handleCheckExternal = this.handleCheckExternal.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleScroll = this.handleScroll.bind();
    this.handleShowAdvancedSearch = this.handleShowAdvancedSearch.bind(this);
    this.handleCheckBoxSelection = this.handleCheckBoxSelection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filteredData !== nextProps.filteredData) {
      this.setState({
        filterValues: nextProps.filteredData,
        filterSelectAll: false,
        selectedItems: this.state.selectedItems
      });
    }
    if (this.props.searchText !== nextProps.searchText) {
      this.setState({
        searchText: nextProps.searchText
      });
    }
    if (this.props.filterTags !== nextProps.filterTags || this.props.searchResults !== nextProps.searchResults) {
      const tagsList = this.state.tagSearchText !== '' ? nextProps.searchResults : nextProps.filterTags;
      this.setState({
        tagsList
      });
    }
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };

  handleSubmit() {
    this.props.handleSubmit(this.state.selectedItems);
  }

  handleSelectAll(event, checked) {
    let selectedItems = Immutable.List();
    if (checked) {
      selectedItems = this.state.filterValues;
    }

    this.setState({
      filterSelectAll: checked,
      selectedItems
    });
  }

  handleChecked(selectedItem, checked) {
    let { selectedItems } = this.state;
    if (checked) {
      if (!this.props.multiValue) selectedItems = Immutable.List();
      selectedItems = selectedItems.push(selectedItem);
    } else {
      const index = selectedItems.findIndex(item => (item.key ? item.key : item) ===
        (selectedItem.key ? selectedItem.key : selectedItem));
      selectedItems = selectedItems.splice(index, 1);
    }

    this.setState({
      filterSelectAll: (selectedItems.size === this.state.filterValues.size),
      selectedItems
    });
  }

  handleChange(event, item) {
    let { selectedItems } = this.state;
    selectedItems = Immutable.List();
    selectedItems = selectedItems.push(item);
    this.setState({
      selectedItems
    });
  }


  handleTagchange = (item, index) => {
    if (index === -3) {
      this.setState({
        multiple: true
      });
    } else {
      this.prepareFilterParameters({
        tags: (item && item.id) || ''
      });
      this.setState({
        tagSearchText: (item && item.name) || ''
      });
      this.props.tagsSearch('');
    }
  }

  handleMultipleItems = (selectedItem) => {
    const item = selectedItem.size > 0 ? selectedItem.get(0).value : undefined;
    this.setState({
      tagSearchText: item && item.name
    });
    if (selectedItem.size > 0) {
      this.prepareFilterParameters({
        tags: (item && item.id) || ''
      });
      this.setState({
        tagSearchText: (item && item.name) || ''
      });
      this.props.tagsSearch('');
    }
  }

  handleRequestClose = () => {
    this.setState({
      multiple: false
    });
  }

  handleScrollEnd = () => {
    this.props.getNextTags(this.props.tagsPosition);
  }

  handleCountryChange(selectedItem) {
    this.prepareFilterParameters({
      country: selectedItem || ''
    });
  }

  handleCheckLegalEntities(checked) {
    this.prepareFilterParameters({
      checkLegalEntities: checked
    });
  }

  handleCheckDepartments(checked) {
    this.prepareFilterParameters({
      checkDepartments: checked
    });
  }

  handleCheckInternal(checked) {
    this.prepareFilterParameters({
      checkInternal: checked
    });
  }

  handleCheckExternal(checked) {
    this.prepareFilterParameters({
      checkExternal: checked
    });
  }

  handleShowAdvancedSearch() {
    this.setState({
      showAdvancedSearch: !this.state.showAdvancedSearch
    });
  }

  handleSearch(event, clearText) {
    const currentSearchText = clearText ? '' : event.currentTarget.value;

    clearTimeout(searchDelayTimeout);
    searchDelayTimeout = setTimeout(() => {
      this.checkFilters(null, currentSearchText);
    }, 500);

    this.setState({
      searchText: currentSearchText
    });
  }

  handleCheckBoxSelection = (item) => {
    const index = this.state.selectedItems.findIndex((x) => {
      let indexValue = -1;
      if (typeof (x) === 'object' && typeof (item) === 'object') {
        indexValue = x.id === item.id;
      } else {
        indexValue = x === item;
      }
      return indexValue;
    });
    return index;
  }

  getParams = (country, tags) => {
    const filterArray = [];
    if (country !== '') {
      filterArray.push({ country });
    } if (tags !== '' && tags !== undefined) {
      filterArray.push({ tags });
    }
    return filterArray;
  }


  getValueToDisplay = (item) => {
    let value = '';
    value = item.key ? item.key : item;
    value = item.addlkey ? `${value} (${item.addlkey})` : value;
    return value;
  };

  searchTag = (item) => {
    this.setState({
      tagSearchText: item.searchText
    });
    this.props.tagsSearch(item.searchText);
  }


  prepareFilterParameters(filterParams) {
    this.setState(filterParams);
    const { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country, tags } = this.state;
    this.checkFilters(Object.assign({},
      { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country, tags },
      filterParams));
  }

  checkFilters(filters, searchText) {
    const { checkLegalEntities, checkDepartments, checkInternal, checkExternal, country, tags } = filters || this.state;
    let orgType = 'legalentity';
    if (checkLegalEntities === checkDepartments) { // when both are unchecked/checked all records to be fetched
      orgType = 'all';
    } else if (!checkLegalEntities) {
      orgType = 'department';
    }

    let isInternal = 'true';
    if (checkInternal === checkExternal) { // when both are unchecked/checked all records to be fetched
      isInternal = 'all';
    } else if (!checkInternal) {
      isInternal = 'false';
    }

    this.filterLegalEntities(orgType, isInternal, country, searchText, tags);
  }

  filterLegalEntities(orgType, isInternal, country, searchText = this.state.searchText, tags) {
    const filterParams = this.getParams(country, tags);
    if (orgType !== 'all' && isInternal !== 'all') {
      filterParams.push({ orgType }, { isInternal });
      this.props.filterItems(filterParams, searchText);
    } else if (orgType === 'all' && isInternal === 'all') {
      this.props.filterItems(filterParams, searchText);
    } else if (orgType === 'all') {
      filterParams.push({ isInternal });
      this.props.filterItems(filterParams, searchText);
    } else if (isInternal === 'all') {
      filterParams.push({ orgType });
      this.props.filterItems(filterParams, searchText);
    }
  }

  render () {
    const { onCancel, multiValue, showOrganisationFilter, intl, maxCount, selectedEntitiesSize } = this.props;
    const TABLE_MAXHEIGHT = 300;
    const WINDOW_INNERHEIGHT = 794;
    const { filterValues } = this.state;

    const MultiOptionRow = (props) => {
      const { index } = props;
      const item = filterValues.toJS()[index];
      const itemCount = this.state.selectedItems.size + selectedEntitiesSize;
      return (
        <div style={multipleOptionStyle}>
          <FormControlLabel
            id="formcontrol"
            control={
              <Checkbox
                id="checkbox"
                disabled={itemCount >= maxCount && this.handleCheckBoxSelection(item) === -1}
                key={item.key ? item.key : item}
                checked={this.handleCheckBoxSelection(item) !== -1}
                style={{ ...styles.checkboxStyle, marginTop: 0 }}
                onChange={(evt, isChecked) => this.handleChecked(item, isChecked)}
                color="primary"
              />}
            style={{ ...styles.multiselectCheckbox, ...props.style }}
            label={
              <ArrowTooltip title={(item.key || item)}>
                <div style={labelWithEllipsis}>{item.key || item}</div>
              </ArrowTooltip>}
          />
        </div>);
    };


    const SingleOptionRow = (props) => {
      const { index } = props;
      const item = filterValues.toJS()[index];
      const valueToDisplay = this.getValueToDisplay(item);
      return (
        <RadioGroup
          name="radio-group"
          value={this.state.selectedItems.toJS()[0] ? this.state.selectedItems.toJS()[0].key : ''}
          onChange={event => this.handleChange(event, item)}
          style={Object.assign({}, { fontSize: '16px' }, props.style)}
        >
          <FormControlLabel
            value={item.key}
            key={item.key}
            control={<Radio
              style={styles.checkboxStyle}
            />}
            label={
              <ArrowTooltip title={valueToDisplay}><div style={labelWithEllipsis}>{valueToDisplay}</div></ArrowTooltip>
            }
          />
        </RadioGroup>
      );
    };

    return (
      <div>
        <div id="orgByName" style={multiselectorStyle}>
          <TextField
            autoFocus={true}
            placeholder={intl.formatMessage({ id: 'Common.searchByName', defaultMessage: 'Search by name' })}
            style={textFielsStyle}
            variant="outlined"
            value={this.state.searchText}
            onChange={event => this.handleSearch(event)}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="start">
                    {(this.state.searchText && this.state.searchText !== '') ?
                      <Clear
                        style={{ cursor: 'pointer' }}
                        onClick={event => this.handleSearch(event, true)}
                      />
                      :
                      <Search />}
                  </InputAdornment>
              )
            }}
          />
          { this.props.showFilter &&
          <ArrowTooltip
            title={intl.formatMessage({ id: 'Common.advancedSearch', defaultMessage: 'Advanced search' })}
          >
            <FontAwesome
              style={{ marginLeft: '10px',
                marginTop: '10px',
                cursor: 'pointer',
                color: this.props.theme.palette.primary.main }}
              name="filter"
              size="lg"
              onClick={this.handleShowAdvancedSearch}
            />
          </ArrowTooltip>
          }
        </div>
        { this.state.showAdvancedSearch &&
        <>
          <div style={{ width: '70%', paddingBottom: '15px', display: 'flex' }}>
            <AutoComplete
              underlineShow={false}
              openOnFocus={true}
              inputType={this.props.inputType}
              selectFromListMenuItem={true}
              fullWidth={true}
              hintText={intl.formatMessage(commonTranslations.filterByTag.props)}
              maxSearchResults={5}
              searchText={this.state.tagSearchText}
              filter={AutoComplete.caseInsensitiveFilter}
              hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
              textFieldStyle={styles.autoComplete}
              dataSource={this.state.tagsList || []}
              onNewRequest={this.handleTagchange}
              dataSourceConfig={{ text: 'key', value: 'id' }}
              onSearch={this.searchTag}
            />
            <FontAwesome
              id="cross-button"
              name="times-circle"
              style={crossIconStyle}
              onClick={this.handleTagchange}
            />
          </div>
          <div style={labelStyle}>
            <ListSubheader style={subHeaderStyle}>
              <FormattedMessage
                id="LegalEntities.filterByCountry"
                description="select country to filter"
                defaultMessage="Show organisation entities from the following country"
              />
            </ListSubheader>
            <div style={countrySelectorStyle}>
              <div style={{ width: '80%' }} >
                <CountrySelector
                  id="country-selector"
                  defaultValue={this.state.country}
                  hintTextLabel={commonTranslations.selectCountry}
                  onChange={this.handleCountryChange}
                />
              </div>
              <FontAwesome
                id="font-awesome"
                name="times-circle"
                style={crossIconStyle}
                onClick={() => this.handleCountryChange()}
              />
            </div>
          </div>
          <div style={checkboxStyle} >
            <FormControlLabel
              id="legal"
              control={
                <Checkbox
                  id="legal_entities"
                  key="legal_entities"
                  style={legalEntityStyle}
                  checked={this.state.checkLegalEntities}
                  onChange={(evt, isChecked) => this.handleCheckLegalEntities(isChecked)}
                  color="primary"
                />}
              style={filterStyle}
              label={<FormattedMessage
                id="LegalEntities.legalEntitiesLabel"
                description="Legal entities label"
                defaultMessage="Legal entities"
              />}
            />
            <FormControlLabel
              id="departments_form"
              control={
                <Checkbox
                  key="departments"
                  id="departments"
                  style={legalEntityStyle}
                  checked={this.state.checkDepartments}
                  onChange={(evt, isChecked) => this.handleCheckDepartments(isChecked)}
                  color="primary"
                />}
              style={filterStyle}
              label={<FormattedMessage
                id="LegalEntities.departmentsLabel"
                description="Departments label"
                defaultMessage="Departments"
              />}
            />
            {showOrganisationFilter &&
            <FormControlLabel
              id="internal_entities_form"
              control={<Checkbox
                id="internal_entities"
                key="internal_entities"
                style={externalOrgStyle}
                checked={this.state.checkInternal}
                onChange={(evt, isChecked) => this.handleCheckInternal(isChecked)}
                color="primary"
              />}
              style={{ width: '49%' }}
              label={orgTypesTranslations.internalOrg}
            />
                }
            {showOrganisationFilter &&
            <FormControlLabel
              control={<Checkbox
                key="external_entities"
                id="external_entities"
                checked={this.state.checkExternal}
                onChange={(evt, isChecked) => this.handleCheckExternal(isChecked)}
                color="primary"
                style={externalOrgStyle}
              />}
              style={{ width: '49%' }}
              label={orgTypesTranslations.externalOrg}
            />
                }
          </div>
        </>
          }
        <DivWrapper
          id="div-wrapper"
          autoHeight={true}
          onScrollStop={this.props.onScrollStop}
          onScroll={this.handleScroll}
          autoHeightMax={getDialogContentHeight(WINDOW_INNERHEIGHT, TABLE_MAXHEIGHT)}
          style={{ paddingTop: 15 }}
        >
          {multiValue && maxCount === undefined &&
            <FormControlLabel
              control={<Checkbox
                id="all"
                key="all"
                checked={this.state.filterSelectAll}
                onChange={this.handleSelectAll}
                color="primary"
                style={legalEntityStyle}
              />}
              style={{ paddingBottom: 20 }}
              label={commonTranslations.selectAll}
            />
            }
          <List
              style={{ overflow: 'none' }}
              height={200}
              itemCount={filterValues.size}
              itemSize={40}
              ref={this.listRef}
          >
              {multiValue ? MultiOptionRow : SingleOptionRow}
            </List>
        </DivWrapper>
        <div style={styles.multiselectAddButton}>
          <Button
            id="submit"
            onClick={this.handleSubmit}
          >
            {commonTranslations.saveAndClose}
          </Button>
          <Button
            variant="text"
            id="cancel"
            onClick={onCancel}
            style={styles.multiselectFormButton}
          >
            {commonTranslations.Cancel}
          </Button>
        </div>
        {this.state.multiple && <MultipleSelectorDialog
          id="multiple_selector"
          multiValue={false}
          title={
            <FormattedMessage
              id="EntitySelector.select.tag.hintText"
              defaultMessage="Filter by tags"
            />}
          open={this.state.multiple}
          filteredData={this.state.tagsList || []}
          onScrollStop={this.handleScrollEnd}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />}
      </div>
    );
  }
}

MultipleSelectorForm.propTypes = {
  showOrganisationFilter: PropTypes.bool,
  onScrollStop: PropTypes.func,
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  filterItems: PropTypes.func,
  multiValue: PropTypes.bool,
  inputType: PropTypes.string,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  filteredData: PropTypes.instanceOf(Immutable.List),
  filterTags: PropTypes.instanceOf(Immutable.List),
  searchText: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  searchResults: PropTypes.instanceOf(Immutable.List),
  tagsSearch: PropTypes.func,
  maxCount: PropTypes.number,
  selectedEntitiesSize: PropTypes.number,
  theme: PropTypes.objectOf(PropTypes.any),
  showFilter: PropTypes.bool,
  isInternal: PropTypes.bool
};

MultipleSelectorForm.defaultProps = {
  multiValue: true,
  onScrollStop: e => e,
  inputType: 'text',
  getNextTags: e => e,
  tagsPosition: 0,
  filterTags: Immutable.List(),
  showOrganisationFilter: true,
  onCancel: e => e,
  filterItems: e => e,
  handleSubmit: e => e,
  searchResults: Immutable.List(),
  filteredData: Immutable.List(),
  tagsSearch: e => e,
  searchText: '',
  maxCount: undefined,
  selectedEntitiesSize: undefined,
  theme: {},
  showFilter: false,
  isInternal: false
};
export default withTheme(injectIntl(MultipleSelectorForm));
