import jwtDecode from 'jwt-decode';
import { put } from 'redux-saga/effects';
import { getQueryStrings } from '@packages/utils/query-parameters';

export default function* initApp() {
  const isPublic = window.location.pathname.includes('public') || window.location.hash.includes('dsr-public-form') ;
  const publicRegistryLanguage = localStorage.getItem('publicRegistryLanguage');
  if (isPublic && publicRegistryLanguage) {
    // In the case of public registry if the language is set locally then use it.
    yield put({ type: 'TRANSLATION:INIT', locale: publicRegistryLanguage });
  } else {
    const locale = getLocaleFromToken();
    yield put({ type: 'TRANSLATION:INIT', locale });
  }
}
// get user locale from token if exist
function getLocaleFromToken() {
  const { token } = getQueryStrings();
  const locale =
    (token && jwtDecode(token).language_code) ||
    localStorage.getItem('locale') ||
    'en';
  return locale;
}
