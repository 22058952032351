import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';
import PersonalDataMultiselectorDefault from './multipleSelector';

const mapStateToProps = state => ({
  tags: state.environment.simpleEntities.tags.get('items'),
  documents: state.environment.documents.get('items'),
  searchResults: state.simpleEntities.get('entitySelectorSearch').get('tags').searchResults,
  searchText: state.simpleEntities.get('searchText'),
  position: state.environment.documents.get('position')
});

const mapDispatchToProps = dispatch => ({
  initDocuments: (searchText) => {
    const searchParams = { searchText, searchKey: 'name' };
    dispatch({ type: 'MASTERDATA:DOCUMENTS:LIST:REQUEST_INIT', searchParams, source: 'records' });
  },
  initTags: () => {
    dispatch({ type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
      masterDataType: 'tags' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT', searchParams, masterDataType: 'tags' });
  },
  getNextData: (position, searchText) => {
    const searchParams = { searchText, searchKey: 'name' };
    dispatch({ type: 'DOCUMENTS:LIST:NEXT_FETCH',
      position,
      searchParams,
      isPersistedData: true,
      source: 'records' });
  },
  onChooseFilter: (filterParams, searchText) => {
    dispatch({ type: 'DOCUMENTS:LIST:FILTER_INIT',
      filterParams,
      searchParams: { searchText, searchKey: 'name' },
      source: 'records',
      isPersistedData: true });
  }
});


export default withTheme(connect(mapStateToProps, mapDispatchToProps)(PersonalDataMultiselectorDefault));
