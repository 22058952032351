import { connect } from 'react-redux';
import SupervisoryAuthoritySelector from './component';

const mapStateToProps = state => ({
  supervisoryAuthorities: state.supervisoryAuthorities.get('supervisoryAuthorities'),
  error: state.supervisoryAuthorities.get('error')
});

const mapDispatchToProps = dispatch => ({
  initSupervisoryAuthorities: () => {
    dispatch({ type: 'SUPERVISORY_AUTHORITIES:LIST:REQUEST' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SupervisoryAuthoritySelector);
