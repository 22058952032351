import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import {
  customFieldTranslations,
  deleteSuccessError
} from '@packages/utils/commontranslations';
import {
  handleServiceDown,
  getEntityType,
  getReducerType,
  MASTERDATA_FETCH_LIMIT,
  getGlobalMasterdataType,
  addKeyToDataSource
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';

import {
  getModifiedType,
  getModifiedEnitityType,
  getActionType,
  getUsageKeys
} from './utils';
import { getTranslatedLabel } from '../tenant-configuration/tenantConfigUtils';

export function* checkGlobalmasterDataItemImpact(action) {
  const { entityType, id, masterDataType, isMerge } = action;
  const type = getReducerType(masterDataType, entityType);
  const modifiedEntityType = type.toUpperCase();
  const modifiedMasterdata = getGlobalMasterdataType(masterDataType);
  let response = {};
  try {
    response = yield registry
      .get('request')
      .post(
        `/v1/masterdata/${modifiedMasterdata || entityType}/subtenants-usage`,
        { entityIds: Array.isArray(id) ? id : [id] }
      );
    switch (response.status) {
      case 200: {
        if (isMerge)
          yield put({
            type: `GLOBAL:MASTERDATA:MERGE:SUB_TENANT:USAGE:SUCCESS`,
            globalUsage: response.body
          });
        else
          yield put({
            type: `MASTERDATA:${modifiedEntityType}:SUB_TENANT:USAGE:SUCCESS`,
            globalUsage: response.body
          });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
  }
}

export function* checkmasterDataItemImpact(action) {
  const {
    masterDataType,
    entityType,
    id,
    source = '',
    operationType = ''
  } = action;
  let response = {};
  const type = getReducerType(masterDataType, entityType);
  const modifiedEntityType = getModifiedType(type);
  const jobEntityType = getEntityType(masterDataType);
  yield put({ type: `MASTERDATA:${modifiedEntityType}:USAGE:FETCH:INIT` });
  yield put({ type: `MASTERDATA:${modifiedEntityType}:ITEM:FETCH:INIT` });
  try {
    const isGlobal = yield select((state) => state.home.get('isGlobal'));
    if (Array.isArray(id)) {
      response = yield registry
        .get('request')
        .post(
          `/v1/masterdataentities/${
            jobEntityType || entityType
          }/usage?distinct=false`,
          { entities: id }
        );
    } else if (isGlobal) {
      response = yield registry
        .get('request')
        .get(
          `/v1/masterdataentities/${
            jobEntityType || entityType
          }/${id}/tenants-usage`,
          null
        );
    } else {
      response = yield registry
        .get('request')
        .get(
          `/v1/masterdataentities/${jobEntityType || entityType}/${id}/usage`,
          source === 'jobs' ? { distinct: 'false' } : null
        );
    }

    switch (response.status) {
      case 200: {
        const modifiedUsage =
          !isGlobal &&
          removeEntityTypeFromUsage(
            response.body.usages,
            jobEntityType || entityType
          );
        let modifiedItem = isGlobal ? response.body : modifiedUsage[0].usage;
        if (source === 'jobs') {
          if (operationType === 'Merge') {
            modifiedItem = getMergeUsageCount(
              response.body.usages
            ).updatedUsageArray;
          }
          yield put({
            type: `JOBS:USAGE_STATUS:FETCH:SUCCESS`,
            usage: modifiedItem
          });
        }
        yield put({
          type: `MASTERDATA:${modifiedEntityType}:USAGE:SUCCESS`,
          usage: modifiedItem
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: `MASTERDATA:${modifiedEntityType}:USAGE:FAIL`,
          error: response.body.msg
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({
      type: `MASTERDATA:${modifiedEntityType}:USAGE:FAIL`,
      error: err.message
    });
  }
}

export function* checkmasterDataMergeItemImpact(action) {
  const { entityType, entities, masterDataType } = action;
  const isGlobal = yield select((state) => state.home.get('isGlobal'));
  if (!isGlobal) {
    yield put({ type: 'MASTERDATA:MERGE:USAGE:FETCH' });
    yield put({ type: `MASTERDATA:MERGE:ITEM:USAGE:INIT` });
  }
  try {
    const modifiedEntityType = getEntityType(masterDataType || entityType);
    const usageUrl = `/v1/masterdataentities/${modifiedEntityType}/${
      isGlobal ? 'tenants-' : ''
    }usage`;
    const response = yield registry.get('request').post(usageUrl, { entities });
    switch (response.status) {
      case 200: {
        if (isGlobal) {
          yield put({
            type: `GLOBAL:MASTERDATA:MERGE:ITEM:USAGE:SUCCESS`,
            usage: response.body
          });
        } else {
          const modifiedItem = removeEntityTypeFromUsage(
            response.body.usages,
            modifiedEntityType
          );
          yield put({
            type: `MASTERDATA:MERGE:ITEM:USAGE:SUCCESS`,
            usage: modifiedItem
          });
        }
        yield put({
          type: 'MASTER_DATA_ITEM:MERGE:CHECK:USAGE:FETCH:RESET'
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: `MASTERDATA:MERGE:ITEM:USAGE:FAIL`, error: err.message });
  }
}

export function* checkMasterDataEntityJob(action) {
  const { id } = action;
  try {
    const response = yield registry.get('request').get(`/v1/jobs/entity/${id}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: `MASTER_DATA_ITEM:EDIT:JOB:STATUS`,
          status: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({
      type: `MASTER_DATA_ITEM:EDIT:JOB:STATUS:FAIL`,
      error: err.message
    });
  }
}

export function* createMasterDataEntityJob(action) {
  const store = registry.get('store');
  const {
    data,
    actionType,
    entityType,
    masterDataType,
    removeGroupEntitiesFromRecord,
    linkNumber
  } = action;
  const request = registry.get('request');
  const type = getReducerType(masterDataType, entityType);
  try {
    const isGlobal = yield select((state) => state.home.get('isGlobal'));
    let jobRequest;
    if (actionType === 'Merge') {
      const mergeEntityIndex = data.findIndex(
        (item) => item.id === action.mergeTarget
      );
      const entity = data.get(mergeEntityIndex);
      const mergeItems = data.delete(mergeEntityIndex);
      const oldEntityId = mergeItems.map((item) => item.id);
      let modifiedEntity = entity;
      if (entityType === 'DataSource') {
        modifiedEntity = {
          ...entity,
          dataStorageCountry: entity.dataStorageCountry && {
            id: entity.dataStorageCountry
          },
          organisation: entity.organisationData
        };
      } else if (entityType === 'DataRecipientCategory') {
        if (entity.countries) {
          modifiedEntity = {
            ...entity,
            countries: entity.countries.map((item) => ({ id: item }))
          };
        }
      }
      jobRequest = {
        name: entity.name,
        operationType: actionType,
        entityType: getModifiedEnitityType(entityType),
        entity: JSON.stringify(modifiedEntity),
        oldEntityId: [...oldEntityId],
        newEntityId: action.mergeTarget
      };
    } else if (entityType === 'DataItemGroup') {
      jobRequest = {
        name: linkNumber,
        operationType: actionType,
        entityType: getEntityType(masterDataType || entityType),
        entity: JSON.stringify(data),
        oldEntityId: [data.id],
        jobSettings: {
          removeGroupEntitiesFromRecord
        }
      };
    } else {
      jobRequest = {
        name: data.name,
        operationType: actionType,
        entityType: getEntityType(masterDataType || entityType),
        entity: JSON.stringify(data),
        newEntityId: action.newEntityId
      };
    }
    const response = yield request.post(`/v1/jobs`, jobRequest);

    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.jobSubmittedSuccess,
            type: 'success'
          }
        });

        // After submitting job redirected to job list page.
        if (isGlobal) {
          yield put({
            type: 'GLOBAL:SOLUTION:FILTERS:CHANGE',
            filterName: 'jobs'
          });
          store.dispatch(replaceRouter('/home'));
        } else {
          yield put({
            type: 'TENANT_DASHBOARD:FILTERS:CHANGE',
            filterName: 'jobs'
          });
          store.dispatch(replaceRouter('/dashboard'));
        }
        break;
      }
      case 304: {
        yield put({
          type: `MASTERDATA:${type.toUpperCase()}:UPSERT:SUCCESS`,
          simpleEntity: data
        });
        break;
      }
      case 409:
        yield put({
          type: `MASTERDATA:${type.toUpperCase()}:UPSERT:FAIL`,
          error:
            entityType === 'DataItemGroup'
              ? `${response.body.msg} - ${response.body.details.duplicateEntity}`
              : response.body.msg
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({
      type: `MASTERDATA:${type.toUpperCase()}:UPSERT:FAIL`,
      error: err.message
    });
  }
}

export function* bulkDeleteEntities(action) {
  const { itemsToDelete, masterDataType, entityType } = action;
  const request = registry.get('request');
  try {
    const entityIds = [];
    itemsToDelete.forEach((item) => {
      entityIds.push(item.id);
    });
    const requestData = { entityIds };
    // const requestData = { entityIds: itemsToDelete };
    let response;
    if (masterDataType === 'customSimpleMasterData') {
      response = yield request.post(
        `/v1/masterdata/simplecustom/${entityType}/unused/remove`,
        requestData
      );
    } else {
      response = yield request.post(
        `/v1/masterdata/${
          masterDataType === 'retentionTermOffset'
            ? 'retentiontermoffsets'
            : masterDataType.toLowerCase()
        }/unused/remove`,
        requestData
      );
    }
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError(masterDataType, entityType, true),
            type: 'success'
          }
        });
        const actionType =
          masterDataType === 'riskDetails' && entityType === 'personalDataItems'
            ? 'SIMPLE_ENTITY'
            : getActionType(entityType, masterDataType);
        yield put({
          type: `${actionType}:LIST:REQUEST_INIT`,
          position: 0,
          isPersistedData: true,
          masterDataType,
          entityType,
          unused: true
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

const getMergeUsageCount = (modifiedItem) => {
  const usageMapValues = [];
  modifiedItem.forEach((item) => usageMapValues.push({ ...item.usage }));
  const modifiedUsageKeys = getUsageKeys(usageMapValues);
  const usageArray = [];
  modifiedUsageKeys.forEach((key) => {
    let count = 0;
    usageMapValues.forEach((value) => {
      if (value[key]) {
        count += value[key];
      }
    });
    usageArray.push({ [key]: count });
  });
  const updatedUsageArray = Object.assign({}, ...usageArray);
  return { updatedUsageArray, modifiedUsageKeys };
};

const removeEntityTypeFromUsage = (usages, entityType) => {
  const modifiedItems = [];
  usages.forEach((item) => {
    const itemUsage = item.usage;
    delete itemUsage[entityType];
    modifiedItems.push({
      masterDataEntityId: item.masterDataEntityId,
      usage: itemUsage
    });
  });
  return modifiedItems;
};

export function* masterDataItemsBulkExport(action) {
  const { entityType = null, masterDataType, signal, controller } = action;
  yield put({ type: `MASTERDATA:BULK:EXPORT:INIT` });
  const request = registry.get('request');
  try {
    let url;
    if (masterDataType === 'customSimpleMasterData') {
      url = `v1/masterdata/simplecustom/${entityType}/export`;
    } else {
      url = `v1/masterdata/${masterDataType.toLowerCase()}/export`;
      if (entityType) {
        url = `${url}?filter=entityType=${getEntityType(entityType)}`;
      }
    }
    if (controller) {
      yield request.exportCSVbyGet(url, null, null, controller);
    } else {
      const response = yield request.exportCSVbyGet(url, null, signal);

      yield response;
      switch (response.status) {
        case 200: {
          yield put({ type: `MASTERDATA:BULK_EXPORT:SUCCESS` });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({ type: `MASTERDATA:BULK_EXPORT:FAIL` });
          break;
      }
    }
  } catch (err) {
    if (!action.signal.aborted) yield handleServiceDown(err, 'masterData');
    yield put({ type: `MASTERDATA:BULK_EXPORT:FAIL` });
  }
}

export function* masterDataViewSimilarItems(action) {
  const { entityType = null, masterDataType, item } = action;
  const type = getReducerType(masterDataType, entityType);
  const modifiedEntityType = getModifiedType(type);
  try {
    // eslint-disable-next-line max-len
    const url = `v1/masterdata/${masterDataType.toLowerCase()}/similar/${
      item.id
    }?numberOfResults=${MASTERDATA_FETCH_LIMIT}&offset=0`;
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        let items = response.body;
        if (masterDataType === 'datasources') {
          items = response.body.map((dataSource) => ({
            ...dataSource,
            key: addKeyToDataSource(dataSource),
            organisation: transformDataSourceOrgItem(dataSource.organisation),
            organisationData: dataSource.organisation
          }));
        }
        yield put({
          type: `MASTERDATA:${modifiedEntityType}:VIEW:SIMILAR:SUCCESS`,
          items
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({
      type: `MASTERDATA:${modifiedEntityType}:VIEW:SIMILAR:FAIL`,
      error: err.message
    });
  }
}
const transformDataSourceOrgItem = (org) =>
  org ? `${org.name} (${org.country.id})` : '';

export function* getCustomMasterDataItems({ locale, tenantLocale }) {
  try {
    const url = `/v1/custom/item-list?filter=fieldType=simpleMasterData`;

    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        const modifiedFilter = response.body.length
          ? getTransformedFilter(
            'simplemasterdata',
            response.body,
            locale,
            tenantLocale
          )
          : {};
        yield put({
          type: `ENVIRONMENT:CUSTOM_SIMPLE_DATA:FILTERS:FETCH:SUCCESS`,
          items: modifiedFilter
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* getStakeholders({ locale, tenantLocale }) {
  try {
    const url = 'v1/custom-fields/stakeholder';
    const response = yield registry.get('request').get(url);

    const customStakeholders = response.body.filter(
      (item) => !item.uniqueId.startsWith('pp')
    );

    switch (response.status) {
      case 200: {
        const modifiedFilter =
          customStakeholders.length &&
          getTransformedFilter(
            'stakeholder',
            customStakeholders,
            locale,
            tenantLocale
          );
        if (modifiedFilter)
          yield put({
            type: 'ENVIRONMENT:STAKEHOLDERS:FILTERS:FETCH:SUCCESS',
            items: modifiedFilter
          });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* changeEnvironmentFilter(action) {
  const { change, uniqueId, newUniqueId } = action;
  const currentFilter = yield select((state) =>
    state.environment.filters.get('currentFilter')
  );
  const filter = currentFilter.split('_');
  if (filter[1] === 'simplemasterdata' && filter[0] === uniqueId) {
    const customFields = yield select((state) =>
      state.customFields.get('data')
    );
    let filterName = '';
    if (change === 'DELETE') {
      if (customFields.length)
        filterName = `${customFields[0].uniqueId}_simplemasterdata`;
    } else filterName = `${newUniqueId}_simplemasterdata`;
    yield put({
      type: 'ENVIRONMENT:FILTER:CHANGE:CUSTOM',
      filterName
    });
  }
}

const getTransformedFilter = (parent, data, locale, tenantLocale) => {
  const modifiedData = {
    label: customFieldTranslations[parent],
    key: parent,
    enable: true,
    defaultFilter: `${data[0].uniqueId}_${parent}`,
    subMenu: []
  };

  data.forEach((item) => {
    modifiedData.subMenu.push({
      key: `${item.uniqueId}_${parent}`,
      label: getTranslatedLabel(item.names, locale, tenantLocale),
      id: item.id,
      entityType: item.uniqueId
    });
  });
  return modifiedData;
};
