import Immutable from 'immutable';

const defaultState = Immutable.Map({
  widgets: [],
  unsavedData: false,
  refetch: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARD:FETCH:WIDGETS:SUCCESS': {
      return state.set('widgets', action.data);
    }
    case 'DASHBOARD:LAYOUT:UNSAVED_DATA:SET':
      return state.set('unsavedData', action.value);
    case 'DASHBOARD:DATA:FETCH:TOGGLE':
      return state.set('refetch', !state.get('refetch'));
    default:
      return state;
  }
};
