import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown } from '@packages/utils/common-utils';

export default function* getMessageCenterUnReadCount() {
  yield put({ type: 'MESSAGES:UNREAD:COUNT:FETCH:INIT' });

  try {
    const url = '/v1/messagecentre/count';
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        yield put({
          type: 'MESSAGES:UNREAD:COUNT:FETCH:SUCCESS',
          count: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: 'MESSAGES:UNREAD:COUNT:FETCH:FAIL', error: err.message });
  }
}
