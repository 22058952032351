import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { FLAT, NO_LIMIT, TOP_DOWN } from '@packages/utils/common-utils';

const AccessRightsSelector = (props) => {
  const {
    dataItemType,
    selectedOrganisations,
    jurisdictions,
    getNextUsers,
    position,
    userPosition,
    fields,
    searchUsers,
    dsrUsers,
    tenantusers,
    userSearchResults,
    searchResults,
    filterAdmin
  } = props;

  const [multiple, setMultiple] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [userList, setUserList] = useState([]);

  const isDsr =
    dataItemType === 'approvers' || dataItemType === 'assignedUsers';

  const notValidDataItem =
    dataItemType === 'assignedUsers' && selectedOrganisations.length === 0;

  useEffect(() => {
    if (searchText)
      setUserList(
        isDsr
          ? userSearchResults.get(dataItemType).searchResults
          : searchResults
      );
    else setUserList(isDsr ? dsrUsers : tenantusers);
  }, [
    dataItemType,
    dsrUsers,
    tenantusers,
    userSearchResults,
    searchResults,
    searchText
  ]);

  const filterAccessRights = (selectedUsers, searchedItems) => {
    const filteredData =
      searchedItems &&
      searchedItems.filter((users) => {
        const index = selectedUsers.findIndex(
          (selectedItem) =>
            users.id ===
            (selectedItem.value ? selectedItem.value.id : selectedItem.id)
        );
        return index === -1;
      });
    return filteredData;
  };

  const userHierarchy = filterAdmin ? [NO_LIMIT, TOP_DOWN, FLAT] : '';

  const getUsers = () => {
    // userList state is reset while using in dsr registration page to
    // clear the data shown in autoComplete
    if (dataItemType === 'assignedUsers') {
      setUserList(undefined);
    }
    if (!notValidDataItem && !props.isFetching) {
      props.getUsers(
        isDsr,
        userHierarchy,
        dataItemType,
        selectedOrganisations,
        jurisdictions
      );
    }
  };

  const handleMultipleSelect = () => {
    setMultiple(true);
    setSearchText('');
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setSelectedIndex(-1);
    setSearchText('');
  };

  const handleGetMultipleItems = (newFields, items) => {
    const selectedUsers = newFields.getAll();
    if (selectedUsers && selectedUsers.length > 0) {
      return filterAccessRights(selectedUsers, items);
    }
    return items;
  };

  // Handler to remove item
  const handleRemoveItem = (selectedIndex) => {
    const selectedUsers = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedUsers.splice(selectedIndex, 1);
    }
    updateUsers(selectedUsers);
  };

  // Handler to add/edit multiple items
  const handleMultipleItems = (selectedItems) => {
    const items = fields.getAll() || [];
    const modifiedSelectedItems = selectedItems.map((item) => ({
      ...item.value,
      rights: item.value.rights || ['Write', 'Read']
    }));
    const selectedUsers = [...items, ...modifiedSelectedItems];
    updateUsers(selectedUsers);
  };

  // Update the changed items.
  const handleEditItem = (selectedItem) => {
    const modifiedSelectedItem = {
      ...selectedItem.value,
      rights: ['Write', 'Read']
    };
    const selectedUsers = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedUsers.push(modifiedSelectedItem);
    } else {
      selectedUsers.splice(selectedIndex, 1, modifiedSelectedItem);
    }
    updateUsers(selectedUsers);
    setSearchText('');
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const searchParams = { ...searchText, searchKey: 'lastName' };
    if (!notValidDataItem) {
      searchUsers(
        isDsr,
        searchParams,
        userHierarchy,
        dataItemType,
        selectedOrganisations,
        jurisdictions
      );
    }
  };

  const updateUsers = (selectedUsers) => {
    props.updateAcls(selectedUsers);
    setSearchText('');
  };

  const handleScrollEnd = () => {
    if (!notValidDataItem) {
      getNextUsers(
        isDsr,
        isDsr ? userPosition : position,
        userHierarchy,
        dataItemType,
        selectedOrganisations,
        jurisdictions
      );
    }
  };

  const selectedUsers = fields.getAll();
  return (
    <div>
      {selectedUsers && selectedUsers.length > 0 && !props.hideItemList && (
        <ItemList
          id="item_list"
          isEditable={false}
          isNote={false}
          selectedItems={selectedUsers}
          handleRemoveItem={handleRemoveItem}
          type={props.label}
          {...props}
        />
      )}
      {!props.disabled && (
        <ItemSelector
          id="item_selector"
          multiValue={props.multiValue}
          createNewMenuItem={props.createNewMenuItem || false}
          selectFromListMenuItem={props.selectFromListMenuItem || false}
          selectedItem={props.selectedItems}
          dataSource={handleGetMultipleItems(fields, userList)}
          onFocus={getUsers}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={props.hintTextLabel}
          handleSelectedItem={handleEditItem}
          handleMultipleSelect={handleMultipleSelect}
          onSearch={handleSearch}
        />
      )}
      {multiple && (
        <MultipleSelectorDialog
          id="multiple_selector"
          title={props.dialogHeaderLabel}
          open={multiple}
          onScrollStop={handleScrollEnd}
          filteredData={handleGetMultipleItems(fields, userList)}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
        />
      )}
    </div>
  );
};

AccessRightsSelector.propTypes = {
  tenantusers: PropTypes.instanceOf(Immutable.List),
  dsrUsers: PropTypes.instanceOf(Immutable.List),
  userSearchResults: PropTypes.shape({
    get: PropTypes.func
  }),
  userPosition: PropTypes.number,
  disabled: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  dataItemType: PropTypes.string,
  selectedOrganisations: PropTypes.arrayOf(PropTypes.shape({})),
  getUsers: PropTypes.func,
  position: PropTypes.number,
  isFetching: PropTypes.bool,
  getNextUsers: PropTypes.func,
  searchUsers: PropTypes.func,
  label: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectFromListMenuItem: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  multiValue: PropTypes.bool,
  initAccessRights: PropTypes.func,
  updateAcls: PropTypes.func,
  currentUser: PropTypes.string,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  hideItemList: PropTypes.bool,
  jurisdictions: PropTypes.shape([]),
  filterAdmin: PropTypes.bool
};

AccessRightsSelector.defaultProps = {
  tenantusers: Immutable.List(),
  selectedItems: [],
  position: 0,
  dataItemType: '',
  disabled: false,
  isFetching: false,
  selectedOrganisations: [],
  dsrUsers: undefined,
  userSearchResults: {},
  searchUsers: (e) => e,
  userPosition: 0,
  getUsers: (e) => e,
  getNextUsers: (e) => e,
  multiValue: true,
  createNewMenuItem: false,
  label: null,
  currentUser: '',
  dialogHeaderLabel: null,
  hintTextLabel: null,
  selectFromListMenuItem: false,
  initAccessRights: (e) => e,
  updateAcls: (e) => e,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  hideItemList: false,
  jurisdictions: [],
  filterAdmin: false
};
export default AccessRightsSelector;
