/* eslint-disable no-case-declarations */
import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  isFetching: false,
  unSavedData: false,
  layout: {},
  data: Immutable.Map(),
  prevLocation: '/privacyrecords',
  recordId: undefined,
  recordName: '',
  actionError: undefined,
  filterMenu: [],
  currentFilter: 'general',
  isEditable: true,
  checkboxOptions: {},
  comments: Immutable.List(),
  users: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CUSTOM_RECORD:RESET:STATE':
      return defaultState;
    case 'CUSTOM_RECORD:FILTER:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'CUSTOM_RECORD:PROPERTY:UPDATE':
      const modifiedData = { ...state.get('data').toJS() };
      const [type, name] = action.property.split('.');
      if (type && name) {
        modifiedData[type][name] = action.data;
      } else {
        modifiedData[name || type] = action.data;
      }
      return state.set('data', Immutable.Map(modifiedData));
    case 'CUSTOM_RECORD:PREV_LOCATION:SET':
      return state.set('prevLocation', action.path);
    case 'CUSTOM_RECORD:UNSAVED_DATA:SET':
      return state.set('unSavedData', action.value);

    // UNIFIED REDUCERS
    case 'CUSTOM_RECORD:SET:PREV_LOCATION': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/processing/${action.recordId}/view`;
      }
      return state.set('prevLocation', prevLocation);
    }
    case 'CUSTOM_RECORD:LOADER:TOGGLE':
      return state.set('isFetching', action.value);
    case 'CUSTOM_RECORD:FETCH_ITEM:SUCCESS':
      return state
        .set('data', Immutable.Map(action.data))
        .set('isEditable', action.data.status !== 'record_status_requested')
        .set('comments', action.data.comments);
    case 'CUSTOM_RECORD:UPDATE:SUCCESS': {
      return state
        .set('data', Immutable.Map(action.data))
        .set('unSavedData', false)
        .set('comments', action.data.comments);
    }
    case 'CUSTOM_RECORD:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);

    case 'CUSTOM_RECORD:FETCH_ITEM:FAIL':
    case 'CUSTOM_RECORD:FETCH_LAYOUT:FAIL':
    case 'CUSTOM_RECORD:DELETE:FAIL':
    case 'CUSTOM_RECORD:UPDATE:FAIL':
    case 'CUSTOM_RECORD:FETCH_LIST:FAIL':
    case 'CUSTOM_RECORD:CREATE:FAIL':
    case 'CUSTOM_RECORD:CHECKBOX_OPTIONS:FETCH:FAIL': {
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', defaultState.get('isUpdating'));
    }
    case 'CUSTOM_RECORD:DETAIL:ERROR:RESET':
      return state.set('actionError', defaultState.get('actionError'));
    case 'CUSTOM_RECORD:CHECKBOX_OPTIONS:FETCH:SUCCESS': {
      const { layoutUniqueId, data } = action;
      return state.set('checkboxOptions', {
        ...state.get('checkboxOptions'),
        [layoutUniqueId]: data
      });
    }
    case 'CUSTOM:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'CUSTOM:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'CUSTOM:COMMENT:DELETE:SUCCESS':
    case 'CUSTOM:COMMENT:CREATE:SUCCESS':
    case 'CUSTOM:COMMENT:EDIT:SUCCESS': {
      return state.set('comments', action.data);
    }
    case 'CUSTOM:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'CUSTOM:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);

    default:
      return state;
  }
};
