import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  graphData: undefined,
  isLoading: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTER:DATA:GRAPH:FETCH:INIT':
      return state.set('isLoading', true)
        .set('graphData', defaultState.get('graphData'));
    case 'MASTER:DATA:GRAPH:FETCH:SUCCESS':
      return state.set('graphData', action.graphData)
        .set('isLoading', false);
    case 'MASTER:DATA:GRAPH:RESET:ITEMS':
    case 'MASTER:DATA:GRAPH:FETCH:FAIL':
      return state.set('graphData', defaultState.get('graphData'))
        .set('isLoading', false);
    default:
      return state;
  }
};
