import { connect } from 'react-redux';
import TenantDetailDialog from './tenantDialog';

const mapStateToProps = state => ({
  locale: state.intl.locale
});

const mapDispatchToProps = dispatch => ({
  deleteTenant: (tenantId) => {
    dispatch({ type: 'TENANT:DELETE', tenantId });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantDetailDialog);
