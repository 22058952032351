import { takeEvery } from 'redux-saga/effects';
import {
  copyReport,
  createCustomReport,
  deleteReportSearch,
  downloadReport,
  fetchReportSchema,
  fetchReportSearches,
  getReportSearchRunCount,
  resetAndFetchReportDetail,
  resetAndrunReportSearch,
  runReportSearch,
  updateCustomReport
} from './sagas';
import {
  fetchControllers,
  searchControllersSelector
} from './components/selectors/controller/sagas';
import {
  fetchDataExporters,
  searchDataExportersSelector
} from './components/selectors/data-exporters/sagas';
import {
  fetchDataImporters,
  searchDataImportersSelector
} from './components/selectors/data-importers/sagas';
import {
  fetchOnwardTransfers,
  searchOnwardTransfersSelector
} from './components/selectors/onward-transfers/sagas';
import {
  fetchLinkedRecords,
  resetAndFectchLinkedRecords
} from './components/selectors/linkedRecords/sagas';
import {
  fetchProcessors,
  searchProcessorsSelector
} from './components/selectors/processor/sagas';
import {
  fetchExecutingEntities,
  searchExecutingEntitiesSelector
} from './components/selectors/executing-entity/sagas';
import {
  fetchDataRecipients,
  searchDataRecipientsSelector
} from './components/selectors/data-recipients/sagas';
import {
  fetchSubProcessors,
  searchSubProcessors
} from './components/selectors/sub-processors/sagas';
import fetchAssociatedProcessinggrounds from './components/selectors/processing-ground/sagas';
import fetchAssociatedTransfergrounds from './components/selectors/transfer-ground/sagas';
import fetchAssociatedBreachgrounds from './components/selectors/breach-ground/sagas';
import fetchRetentionTerms from './components/selectors/retention-term/sagas';
import {
  fetchDpo,
  resetAndFetchDpo,
  searchDpoSelector
} from './components/selectors/data-protection-officer/sagas';
import { fetchCreatedByUsers } from './components/selectors/organisation-user/sagas';
import {
  fetchDpoOrganisations,
  resetAndFetchDpoOrganisations,
  searchDpoOrganisationsSelector
} from './components/selectors/dpo-organisation/sagas';
import {
  fetchThirdPartyOrganisations,
  resetAndFetchThirdPartyOrganisations,
  searchThirdPartyOrganisationsSelector
} from './components/selectors/third-party-data-organisations/sagas';
import { searchLinkGroupSelector } from './components/selectors/links/sagas';

export default [
  [takeEvery, 'REPORTS:LIST:REQUEST', fetchReportSearches],
  [takeEvery, 'REPORT:DETAIL:INIT', resetAndFetchReportDetail],
  [takeEvery, 'REPORT:SCHEMA:INIT', fetchReportSchema],
  [takeEvery, 'CUSTOM:REPORT:CREATE', createCustomReport],

  [takeEvery, 'REPORTS:ITEM:DELETE', deleteReportSearch],

  [takeEvery, 'REPORT:CREATE:COPY', copyReport],
  [takeEvery, 'CUSTOM:REPORT:DETAIL:UPDATE', updateCustomReport],

  [takeEvery, 'REPORT:DETAIL:RUN:INIT', resetAndrunReportSearch],
  [takeEvery, 'REPORT:RUN:NEXT_FETCH', runReportSearch],
  [takeEvery, 'REPORT:GET:RUN:COUNT', getReportSearchRunCount],
  [takeEvery, 'REPORT:DOWNLOAD', downloadReport],

  [takeEvery, 'CONTROLLERS:LIST:REQUEST', fetchControllers],
  [
    takeEvery,
    'CONTROLLERS_SELECTOR:LIST:SEARCH_INIT',
    searchControllersSelector
  ],
  [takeEvery, 'DATA_EXPORTERS:LIST:REQUEST', fetchDataExporters],
  [
    takeEvery,
    'DATA_EXPORTERS_SELECTOR:LIST:SEARCH_INIT',
    searchDataExportersSelector
  ],
  [takeEvery, 'DATA_IMPORTERS:LIST:REQUEST', fetchDataImporters],
  [
    takeEvery,
    'DATA_IMPORTERS_SELECTOR:LIST:SEARCH_INIT',
    searchDataImportersSelector
  ],
  [takeEvery, 'ONWARD_TRANSFERS:LIST:REQUEST', fetchOnwardTransfers],
  [
    takeEvery,
    'ONWARD_TRASFERS_SELECTOR:LIST:SEARCH_INIT',
    searchOnwardTransfersSelector
  ],
  [takeEvery, 'LINKED_RECORD:LIST:REQUEST:INIT', resetAndFectchLinkedRecords],
  [takeEvery, 'LINKED_RECORD:LIST:REQUEST', fetchLinkedRecords],
  [takeEvery, 'PROCESSORS:LIST:REQUEST', fetchProcessors],
  [takeEvery, 'PROCESSORS_SELECTOR:LIST:SEARCH_INIT', searchProcessorsSelector],
  [takeEvery, 'EXECUTING_ENTITES:LIST:REQUEST', fetchExecutingEntities],
  [
    takeEvery,
    'EXECUTING_ENTITES_SELECTOR:LIST:SEARCH_INIT',
    searchExecutingEntitiesSelector
  ],
  [takeEvery, 'DATA_RECIPIENTS:LIST:REQUEST', fetchDataRecipients],
  [
    takeEvery,
    'DATA_RECIPIENTS_SELECTOR:LIST:SEARCH_INIT',
    searchDataRecipientsSelector
  ],
  [takeEvery, 'SUBPROCESSORS:LIST:REQUEST', fetchSubProcessors],
  [takeEvery, 'SUBPROCESSORS_SELECTOR:LIST:SEARCH_INIT', searchSubProcessors],
  [
    takeEvery,
    'PROCESSING_GROUNDS:RECORDS:LIST:INIT',
    fetchAssociatedProcessinggrounds
  ],
  [
    takeEvery,
    'TRANSFER_GROUNDS:RECORDS:LIST:INIT',
    fetchAssociatedTransfergrounds
  ],
  [takeEvery, 'BREACH_GROUNDS:RECORDS:LIST:INIT', fetchAssociatedBreachgrounds],
  [takeEvery, 'RETENTION_TERMS:RECORDS:LIST:INIT', fetchRetentionTerms],
  [takeEvery, 'DPO:LIST:REQUEST', resetAndFetchDpo],
  [takeEvery, 'DPO:LIST:REQUEST:NEXT_FETCH', fetchDpo],
  [takeEvery, 'DPO_SELECTOR:LIST:SEARCH_INIT', searchDpoSelector],
  [
    takeEvery,
    'REPORTS_CREATEDBY_USERS:LIST:REQUEST_FETCH',
    fetchCreatedByUsers
  ],
  [
    takeEvery,
    'DPO_ORGANISATIONS:LIST:REQUEST_INIT',
    resetAndFetchDpoOrganisations
  ],
  [
    takeEvery,
    'DPO_ORGANISATIONS:LIST:REQUEST:NEXT_FETCH',
    fetchDpoOrganisations
  ],
  [
    takeEvery,
    'DPO_ORGANISATIONS_SELECTOR:LIST:SEARCH_INIT',
    searchDpoOrganisationsSelector
  ],
  [
    takeEvery,
    'THIRD_PARTY_DATA_ORGANISATIONS:LIST:REQUEST_INIT',
    resetAndFetchThirdPartyOrganisations
  ],
  [
    takeEvery,
    'THIRD_PARTY_DATA_ORGANISATIONS:LIST:REQUEST:NEXT_FETCH',
    fetchThirdPartyOrganisations
  ],
  [
    takeEvery,
    'THIRD_PARTY_DATA_ORGANISATIONS_SELECTOR:LIST:SEARCH_INIT',
    searchThirdPartyOrganisationsSelector
  ],
  [takeEvery, 'LINK_GROUP:LIST:SEARCH_INIT', searchLinkGroupSelector]
];
