import { connect } from 'react-redux';
import DpoSelector from './component';

const mapStateToProps = state => ({
  name: state.dpo.get('name'),
  email: state.dpo.get('email'),
  workPhone: state.dpo.get('workPhone'),
  position: state.dpo.get('position'),
  searchItememail: state.dpo.get('searchItememail'),
  searchItemworkPhone: state.dpo.get('searchItemworkPhone'),
  searchItemname: state.dpo.get('searchItemname')
});

const mapDispatchToProps = dispatch => ({
  initDpo: (dpoType) => {
    dispatch({ type: 'DPO:LIST:REQUEST', dpoType });
  },
  getNextData: (position, dpoType) => {
    dispatch({ type: 'DPO:LIST:REQUEST:NEXT_FETCH', position, dpoType });
  },
  onSearch: (searchParams, dpoType) => {
    dispatch({ type: 'DPO_SELECTOR:LIST:SEARCH_INIT', searchParams, dpoType });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DpoSelector);
