/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  renderTextField,
  renderCountryList
} from '@packages/components/form-components';
import LegalEntitySelector from '@packages/features/entity-selector';
import {
  commonTranslations,
  environmentHeaderTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import errortranslations from '@packages/utils/errortranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import styles from '@packages/ui/styles';
import { isMasterDataEditFormVisible } from '@packages/features/environment/utils';
import ImpactAnalysisPage from '@packages/features/environment/impact-analysis';
import {
  renderItemSelector,
  renderChipSelector
} from '@packages/features/privacy-record-detail/renderers/renderers';
import ItemList from '@packages/features/privacy-record-detail/components/item-list';
import DivWrapper from '@packages/components/divWrapper';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });

  return errors;
};

const renderDataSelector = injectIntl(
  ({
    headerLabel,
    itemLabel,
    entityType,
    searchResults,
    hintTextLabel,
    dialogHeaderLabel,
    hideTags,
    meta: { error, submitFailed },
    isView,
    ...props
  }) => (
    <div style={{ width: '90%' }}>
      <ListSubheader style={styles.labelField}>{headerLabel}</ListSubheader>
      {isView &&
        (props.input.value ? (
          <ItemList
            key="organisation"
            type="organisation"
            content={[props.input.value]}
            fontStyle={styles.itemListStyle}
          />
        ) : (
          <div style={{ padding: 10 }}>—</div>
        ))}
      {!isView && (
        <LegalEntitySelector
          {...props}
          name="dataSourceOrganisation"
          multiValue={false}
          label={itemLabel}
          dialogHeaderLabel={dialogHeaderLabel}
          entityType={entityType}
          hintTextLabel={props.intl.formatMessage(hintTextLabel.props)}
          createNewMenuItem={true}
          selectFromListMenuItem={true}
          showResetButton={false}
          searchResults={searchResults}
          showSaveAndCreate={false}
          hideTags={hideTags}
        />
      )}
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  )
);

renderDataSelector.propTypes = {
  headerLabel: PropTypes.node,
  itemLabel: PropTypes.node,
  entityType: PropTypes.node,
  hintTextLabel: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired,
  searchResults: PropTypes.instanceOf(Immutable.List),
  isView: PropTypes.bool
};

renderDataSelector.defaultProps = {
  headerLabel: null,
  itemLabel: null,
  entityType: null,
  hintTextLabel: null,
  dialogHeaderLabel: null,
  searchResults: Immutable.List(),
  isView: false
};

export const DataSourceForm = (props) => {
  const {
    actionError,
    change,
    dataItemId,
    dataSourceData,
    globalUsage,
    handleSubmit,
    hasNoLimit,
    init,
    initialize,
    isEdit,
    isGlobal,
    isUsed,
    onCancel,
    organisation,
    refreshList,
    resetDataSources,
    searchResults,
    source,
    submitting,
    updateDataSources,
    usage,
    value,
    hideTags,
    isTagEdit
  } = props;

  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);
  const [dataStorageCountry, setDataStorageCountry] = useState(
    props.dataStorageCountry ||
      (props.organisation ? props.organisation.country : '')
  );

  useEffect(() => {
    if (dataItemId) init(dataItemId);
    if (!isEdit) handleInitialise();
    return () => {
      resetDataSources();
    };
  }, []);

  useEffect(() => {
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    if (dataSourceData !== undefined) {
      if (showForm) window.document.getElementById('dataSourceDailog')?.focus();
      if (onAfterUpdation) props.onSave(dataSourceData, closeDialog);
      else handleInitialise(dataSourceData);
    }
  }, [dataSourceData]);

  useEffect(() => {
    change('organisation', organisation);
    change(
      'dataStorageCountry',
      organisation && organisation.country ? organisation.country : ''
    );
    setDataStorageCountry(
      organisation && organisation.country ? organisation.country : ''
    );
  }, [organisation]);

  useEffect(() => {
    change('dataStorageCountry', props.dataStorageCountry);
    setDataStorageCountry(props.dataStorageCountry);
  }, [props.dataStorageCountry]);

  const saveData = (dataSourceData, closeDialog) => {
    const modifiedData = {
      ...dataSourceData,
      name: dataSourceData.name.trim(),
      dataSourceCategories:
        dataSourceData.dataSourceCategories &&
        dataSourceData.dataSourceCategories.map(
          (category) => category.value || category
        ),
      ...(dataSourceData.dataStorageCountry
        ? {
          dataStorageCountry:
              dataSourceData.dataStorageCountry.id ||
              dataSourceData.dataStorageCountry
        }
        : {})
    };
    const isCountryChanged =
      props.dataSourceData?.dataStorageCountry !==
      dataSourceData.dataStorageCountry;
    const isJobCreationRequired =
      (isUsed || (globalUsage && isCountryChanged)) && !isView;
    updateDataSources(
      { value: modifiedData },
      isEdit,
      source,
      isJobCreationRequired
    );
    setCloseDialog(closeDialog);
    setOnAfterUpdation(true);
    if (!closeDialog) initialize({});
    if (globalUsage && isCountryChanged) {
      onCancel();
      // When job page is ready redirect to jobs instead of refreshing.
      refreshList();
    }
  };

  const handleInitialise = (dataSourceData) => {
    if (!isEdit) initialize(value);
    else {
      initialize(dataSourceData);
    }
  };

  const handleDataCategoryChange = (categories) => {
    change('dataSourceCategories', categories);
  };

  const handleTagChange = (data) => {
    change('tags', data);
  };

  const TABLE_MAXHEIGHT = 392;
  const WINDOW_INNERHEIGHT = 700;
  const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
  const isView = isEdit && isTagEdit;

  return (
    <form>
      {isUsed && (
        <ImpactAnalysisPage
          hasNoLimit={hasNoLimit}
          usage={usage}
          type="datasource"
        />
      )}
      {showForm && (
        <DivWrapper
          autoHeight={true}
          autoHeightMax={getDialogContentHeight(
            WINDOW_INNERHEIGHT,
            TABLE_MAXHEIGHT
          )}
        >
          <div>
            <Field
              id="dataSourceDailog"
              name="name"
              label={commonTranslations.name}
              hintTextLabel={
                <FormattedMessage
                  id="DataSource.nameHint"
                  description="Hint text for data source name"
                  defaultMessage="Data source name..."
                />
              }
              autoFocus={!isEdit}
              component={renderTextField}
              isView={isView}
            />
          </div>
          <div>
            <FieldArray
              id="dataSourceCategories"
              name="dataSourceCategories"
              type="dataSourceCategories"
              headerLabel={uppercasePlural('dataSourceCategories')}
              itemLabel={environmentHeaderTranslation('dataSourceCategories')}
              hintText={recordTranslations.dateSourceCategoriesHintText}
              updateEntities={(item) => handleDataCategoryChange(item)}
              isNote={false}
              component={renderItemSelector}
              showUsage={false}
              isView={isView}
            />
          </div>
          <div>
            <Field
              name="organisation"
              headerLabel={
                <FormattedMessage
                  id="DataSource.organisation"
                  description="Data Source organisation"
                  defaultMessage="Data source hosted by"
                />
              }
              itemLabel={
                <FormattedMessage
                  id="DataSource.hostedBy"
                  description="Data source host"
                  defaultMessage="data source host"
                />
              }
              entityType={
                <FormattedMessage
                  id="DataSource.HostedBy"
                  description="Data source host"
                  defaultMessage="Data source host"
                />
              }
              dialogHeaderLabel={
                <FormattedMessage
                  id="DataSource.OrgDialogHeader"
                  description="Data source organisation Dialog header"
                  defaultMessage="Select organisation"
                />
              }
              hintTextLabel={
                <FormattedMessage
                  id="DataSource.searchOrganisationHint"
                  description="Hint text for data source organisation"
                  defaultMessage="Search organisation"
                />
              }
              searchResults={searchResults}
              component={renderDataSelector}
              hideTags={isGlobal}
              showParentEntity={isGlobal}
              isView={isView}
            />
          </div>
          <div>
            <Field
              name="administrator"
              label={
                <FormattedMessage
                  id="DataSource.administrator"
                  description="Data Source administrator"
                  defaultMessage="Contact person"
                />
              }
              hintTextLabel={
                <FormattedMessage
                  id="DataSource.administratorHint"
                  description="Hint text for data source administrator"
                  defaultMessage="Type name of contact person"
                />
              }
              component={renderTextField}
              isView={isView}
            />
          </div>
          <div>
            <Field
              name="dataStorageCountry"
              label={recordTranslations.dataStorageCountry}
              subLabel={
                <FormattedMessage
                  id="DataSource.countrySubHeader"
                  description="Data Source storage Country subheader"
                  defaultMessage={
                    'Specify the country of data storage if it is different ' +
                    'from the country of establishment of the hosting organisation'
                  }
                />
              }
              hintTextLabel={
                <FormattedMessage
                  id="DataSource.countryHint"
                  description="Hint text for storage Country"
                  defaultMessage="Search country"
                />
              }
              defaultValue={dataStorageCountry}
              component={renderCountryList}
              isView={isView}
            />
          </div>
          <div>
            {!hideTags && (
              <FieldArray
                id="tags"
                name="tags"
                type="tags"
                entityType="datasource"
                disabled={isEdit && !isTagEdit}
                headerLabel={commonTranslations.tags}
                hintText={commonTranslations.addTags}
                updateTags={handleTagChange}
                chipSelectorStyle={{ width: '100%' }}
                showHeader={isTagEdit || !isEdit}
                disabledTagStyle={{ marginTop: 16 }}
                component={renderChipSelector}
              />
            )}
          </div>
        </DivWrapper>
      )}
      {actionError && actionError.isError && (
        <div
          style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}
        >
          {actionError.message}
        </div>
      )}
      <div style={{ marginTop: '20px' }}>
        {showForm && (
          <Button
            id="submit"
            disabled={submitting}
            onClick={handleSubmit((dataSourceData) =>
              saveData(dataSourceData, true)
            )}
          >
            {commonTranslations.saveAndClose}
          </Button>
        )}
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            onClick={handleSubmit((dataSourceData) =>
              saveData(dataSourceData, false)
            )}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}
        {showForm && (
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        )}
        {!hasNoLimit && isUsed && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
    </form>
  );
};

DataSourceForm.propTypes = {
  isEdit: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
  updateDataSources: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  resetDataSources: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  organisation: PropTypes.shape({
    country: PropTypes.string
  }),
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  dataStorageCountry: PropTypes.string,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  dataSourceData: PropTypes.shape({
    dataStorageCountry: PropTypes.string
  }),
  searchResults: PropTypes.instanceOf(Immutable.List),
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  change: PropTypes.func,
  globalUsage: PropTypes.bool,
  refreshList: PropTypes.func,
  isGlobal: PropTypes.bool,
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

DataSourceForm.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  handleSubmit: (e) => e,
  resetDataSources: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  organisation: null,
  dataStorageCountry: '',
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  dataSourceData: {},
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  searchResults: Immutable.List(),
  change: (e) => e,
  globalUsage: false,
  refreshList: (e) => e,
  isGlobal: false,
  isTagEdit: false,
  hideTags: false
};

const DataSourceFormWrapper = reduxForm({
  form: 'DataSourceForm', // a unique identifier for this form
  validate
})(DataSourceForm);

const selector = formValueSelector('DataSourceForm');
const DataSourceFormComponent = connect((state) =>
  selector(state, 'organisation', 'dataStorageCountry', 'categories')
)(DataSourceFormWrapper);

export default DataSourceFormComponent;
