import {
  pluralTerms as uppercasePlurals,
  singularTerms as uppercaseSingulars
} from '@packages/utils/uppercaseTranslations';

const filterMenu = [
  {
    label: uppercasePlurals('tenants'),
    key: 'tenants',
    enable: true,
    defaultFilter: 'admin_tenants'
  },
  {
    label: uppercaseSingulars('holding'),
    key: 'holding',
    enable: true,
    defaultFilter: 'admin_holding'
  },
  {
    label: uppercasePlurals('partners'),
    key: 'partners',
    enable: true,
    defaultFilter: 'admin_partners'
  },
  {
    label: uppercaseSingulars('banner'),
    key: 'banner',
    enable: true,
    defaultFilter: 'admin_banner'
  },
  {
    label: uppercasePlurals('updatesAndReleaseNotes'),
    key: 'updatesAndReleaseNotes',
    enable: true,
    defaultFilter: 'admin_updatesAndReleaseNotes'
  },
  {
    label: uppercasePlurals('modules'),
    key: 'modules',
    enable: true,
    defaultFilter: 'admin_Basic_modules',
    subMenu: [
      {
        key: 'admin_Basic_modules',
        label: 'Starter'
      },
      {
        key: 'admin_Basicplus_modules',
        label: 'StarterPlus'
      },
      {
        key: 'admin_Pro_modules',
        label: 'Pro'
      },
      {
        key: 'admin_Enterprise_modules',
        label: 'Enterprise'
      }
    ]
  }
];

export const messageTypes = [
  {
    name: uppercaseSingulars('incident'),
    value: 'Incident'
  },
  {
    name: uppercaseSingulars('warning'),
    value: 'Warning'
  },
  {
    name: uppercaseSingulars('alert'),
    value: 'Alert'
  },
  {
    name: uppercaseSingulars('information'),
    value: 'Information'
  }
];

export default filterMenu;
