import { connect } from 'react-redux';
import ThemeProvider from './ThemeProvider';

const mapStateToProps = (state) => ({
  whiteLabelling: state.generalSettings.get('whiteLabelling')
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    // dispatch({ type: 'TENANT:SETTINGS:REQUEST_INIT', isPublic: true });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeProvider);
