import theme from '@packages/ui/themes';
import styles from '@packages/ui/styles';

export default {
  ...styles,
  formbutton: {
    height: '50px',
    minWidth: '220px'
  },
  complianceButton: {
    height: '45px',
    width: '45px',
    minWidth: '45px',
    backgroundColor: 'transparent',
    marginLeft: '-50px',
    padding: '0px'
  },
  noComplianceButton: {
    height: '45px',
    width: '45px',
    minWidth: '45px',
    backgroundColor: 'black',
    padding: '0px'
  },
  complianceTriangle: {
    width: 0,
    height: 0,
    borderBottom: '50px solid',
    borderBottomColor: theme.palette.failedColor,
    borderLeft: '50px solid transparent',
    position: 'absolute'
  },
  complianceSquare: {
    width: '50px',
    height: '50px',
    position: 'absolute'
  }
};
