import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { getReducerType } from '@packages/utils/common-utils';
import { multipleSelectorTypeTranslations } from '@packages/features/environment/masterDataTranslations';
import ValueSelector from '../value-selector';

class SimpleNamedEntity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSearchResults: [],
      reducerType: getReducerType(this.props.masterDataType, this.props.entityType)
    };
    this.handleNextData = this.handleNextData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { entityType, masterDataType } = this.props;
    if (this.props.entityType || this.props.masterDataType) {
      const reducerType = getReducerType(masterDataType, entityType);
      this.setState({
        reducerType
      });
      if (nextProps.searchResults.get(reducerType).searchResults !==
      this.props.searchResults.get(reducerType).searchResults) {
        this.setState({ currentSearchResults: nextProps.searchResults.get(reducerType).searchResults });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.simpleEntities[this.state.reducerType] === nextProps.simpleEntities[this.state.reducerType]) ||
    !(this.state === nextState);
  }

  handleOnFocus = () => {
    const modifiedEntity = this.getEntityType();
    if (this.props.initialiseEntities) {
      this.props.initialiseEntities(this.props.masterDataType, modifiedEntity);
    }
  }

  handleNextData() {
    const modifiedEntity = this.getEntityType();
    const position = this.props.simpleEntities[this.state.reducerType].get('position');
    this.props.getNextData(position, this.props.masterDataType, modifiedEntity);
  }

  handleSearch(searchParams) {
    const modifiedEntity = this.getEntityType();
    this.props.onSearch(searchParams, this.props.masterDataType, modifiedEntity);
  }

  getEntityType = () => {
    if (this.props.entityType === 'dpoOrganisationsFinding') {
      return 'dpoFinding';
    } else if (this.props.entityType === 'dpoOrganisationsDecision') {
      return 'dpoDecision';
    }
    return this.props.entityType;
  }
  
  render () {
    const { currentSearchResults } = this.state;
    const { hintTextLabel, simpleEntities, masterDataType } = this.props;
    const { reducerType } = this.state;
    const simpleNamedEntities =
    (simpleEntities[reducerType] && simpleEntities[reducerType].get('items')) || undefined;
    const modifiedEntity = this.getEntityType();
    return (
      <div>
        <ValueSelector
          valueAttr="name"
          dataItems={simpleNamedEntities}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          onScrollStop={this.handleNextData}
          masterDataType={masterDataType}
          entityType={modifiedEntity}
          onFocus={this.handleOnFocus}
          title={multipleSelectorTypeTranslations(reducerType)}
          {...this.props}
          searchResults={currentSearchResults}
          onSearch={this.handleSearch}
        />
      </div>
    );
  }
}

SimpleNamedEntity.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initialiseEntities: PropTypes.func,
  simpleEntities: PropTypes.instanceOf(Immutable.List),
  entityType: PropTypes.string,
  masterDataType: PropTypes.string,
  onSearch: PropTypes.func
};

SimpleNamedEntity.defaultProps = {
  hintTextLabel: null,
  initialiseEntities: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: undefined,
  simpleEntities: Immutable.List(),
  entityType: '',
  masterDataType: '',
  onSearch: e => e
};

export default SimpleNamedEntity;
