/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Field, reduxForm } from 'redux-form';
import { FixedSizeList as List } from 'react-window';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage, injectIntl } from 'react-intl';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DivWrapper from '@packages/components/divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import CountrySelector from '@packages/components/country-selector';
import { commonTranslations } from '@packages/utils/commontranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import LegalEntitySelector from '../../../../../entity-selector';

const subHeaderStyle = {
  color: 'black',
  lineHeight: '20px',
  paddingLeft: '3px',
  marginBottom: '10px',
  fontSize: '16px'
};

const filterParams = {
  sortOn: 'name',
  sortOrder: 'ASC'
};

const labelWithEllipsis = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px'
};

const multiSelectorStyle = { width: '90%', paddingBottom: '15px' };

const countrySelectorStyle = { width: '80%' };

const divWrapperStyle = { marginTop: '20px' };

const checkBoxStyle = { paddingBottom: '30px', overflow: 'none', width: '95%' };
const listStyle = { overflow: 'none', width: '95%' };
export const renderItemSelector = injectIntl(({ input, headerLabel, itemLabel, entityType, searchResults,
  hintTextLabel, dialogHeaderLabel, handleItem, onAfterReset,
  meta: { error, submitFailed }, intl: { formatMessage }, ...props }) => (
    <div style={{ width: '90%' }}>
      <ListSubheader style={subHeaderStyle}>{headerLabel}</ListSubheader>
      <LegalEntitySelector
        {...props}
        name="multiSelectDataSourceOrg"
        multiValue={false}
        label={itemLabel}
        dialogHeaderLabel={dialogHeaderLabel}
        entityType={entityType}
        hintTextLabel={formatMessage(hintTextLabel.props)}
        createNewMenuItem={false}
        selectFromListMenuItem={true}
        handleMultipleItems={handleItem}
        input={input}
        showResetButton={false}
        handleSelectedItem={handleItem}
        onAfterReset={onAfterReset}
        searchResults={searchResults}
      />
      <div style={styles.errorText}>{submitFailed && error && <span>{error}</span>}</div>
    </div>
));

renderItemSelector.propTypes = {
  headerLabel: PropTypes.node,
  itemLabel: PropTypes.node,
  entityType: PropTypes.node,
  hintTextLabel: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  handleItem: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  onAfterReset: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired
};

renderItemSelector.defaultProps = {
  headerLabel: null,
  itemLabel: null,
  entityType: null,
  hintTextLabel: null,
  searchResults: Immutable.List(),
  dialogHeaderLabel: null,
  handleItem: e => e,
  onAfterReset: e => e
};

export const DatasourceMultiSelectorForm = (props) => {
  const [filterSelectAll, setFilterSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState(Immutable.List());
  const [dataStorageCountry, setDataStorageCountry] = useState('');
  const [selectedOrganisation, setSelectedOrganisation] = useState({});
  const [filterValues, setFilterValues] = useState(props.filteredData);


  useEffect(() => {
    setFilterValues(props.filteredData);
  }, [props.filteredData]);

  useEffect(() => () => props.onresetFilter(), []);


  const listRef = React.createRef();
  const handleOnAfterReset = () => {
    if (selectedOrganisation.id !== undefined) {
      props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'organisation',
        filteredOn: [] }));
    }
    setSelectedOrganisation({});
  };

  const handleScroll = ({ target }) => {
    const { scrollTop } = target;
    listRef.current.scrollTo(scrollTop);
  };

  const handleSelectAll = (event, checked) => {
    let selectedItemsValue = Immutable.List();
    if (checked) {
      selectedItemsValue = filterValues;
    }
    setFilterSelectAll(checked);
    setSelectedItems(selectedItemsValue);
  };

  const handleChecked = (selectedItem, checked) => {
    let selectedItemsValue = selectedItems;
    if (checked) {
      if (!props.multiValue) selectedItemsValue = Immutable.List();
      selectedItemsValue = selectedItemsValue.push(selectedItem);
    } else {
      const index = selectedItems.findIndex(item => (item.key ? item.key : item) ===
        (selectedItem.key ? selectedItem.key : selectedItem));
      selectedItemsValue = selectedItemsValue.splice(index, 1);
    }
    setSelectedItems(selectedItemsValue);
    setFilterSelectAll(selectedItemsValue.size === filterValues.size);
  };

  const handleCountryChange = (selectedItem) => {
    if (selectedItem === '' && dataStorageCountry !== '') {
      props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'dataStorageCountry',
        filteredOn: [] }));
    } else if (selectedItem !== '' && selectedItem !== dataStorageCountry) {
      props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'dataStorageCountry',
        filteredOn: [selectedItem] }));
    }
    setDataStorageCountry(selectedItem || '');
  };

  const handleItem = (selectedItem, input) => {
    const selectedOrganisationValue = selectedItem.get && selectedItem.get(0) ?
      selectedItem.get(0).value : selectedItem.value;
    setSelectedOrganisation(selectedOrganisationValue);
    if (input) input.onChange(selectedOrganisationValue.key);
    props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'organisation',
      filteredOn: selectedOrganisationValue && selectedOrganisationValue.id ? [selectedOrganisationValue.id] :
        [] }));
  };

  const handleCheckedValue = (item) => {
    const index = selectedItems.findIndex((x) => {
      let indexValue = -1;
      if (typeof (x) === 'object' && typeof (value) === 'object') {
        indexValue = x.props.defaultMessage === item.props.defaultMessage;
      } else {
        indexValue = x === item;
      }
      return indexValue;
    });
    return index;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = (propValue) => {
    const { index } = propValue;
    const item = filterValues.get(index);
    return (
      <div>
        <FormGroup column={true}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.key ? item.key : item}
                color="primary"
                checked={handleCheckedValue(item) !== -1}
                style={styles.checkboxStyle}
                onChange={(evt, isChecked) => handleChecked(item, isChecked)}
              />}
            style={Object.assign({}, propValue.style, styles.multiselectCheckbox)}
            label={
              <ArrowTooltip title={item.key || item}>
                <div style={labelWithEllipsis}>{item.key || item}</div>
              </ArrowTooltip>}
          />
        </FormGroup>
      </div>
    );
  };

  const { handleSubmit, onSave, onCancel, multiValue } = props;
  const TABLE_MAXHEIGHT = 214;
  const WINDOW_INNERHEIGHT = 707;
  return (
    <form>
      <div>
        <div style={multiSelectorStyle}>
          <Field
            id="organisation-field"
            name="organisation"
            headerLabel={<FormattedMessage
              id="DataSource.filterByOrganisation"
              description="select organisation to filter"
              defaultMessage="Show only data sources hosted by organisation entity"
            />}
            dialogHeaderLabel={
              <FormattedMessage
                id="DataSource.OrgDialogHeader"
                description="Data source organisation Dialog header"
                defaultMessage="Select organisation"
              />
              }
            hintTextLabel={<FormattedMessage
              id="DataSource.filterByOrganisation.hintTextLabel"
              description="Hint text for data source organisation filter"
              defaultMessage="Select an organisation entity"
            />}
            handleItem={handleItem}
            onAfterReset={handleOnAfterReset}
            component={renderItemSelector}
            searchResults={props.searchResults}
          />
        </div>
        <div>
          <ListSubheader style={subHeaderStyle}>
            <FormattedMessage
              id="DataSource.filterByCountry"
              description="select country to filter"
              defaultMessage="Show only datasources with country of data storage:"
            />
          </ListSubheader>
          <div style={{ display: 'flex' }}>
            <div style={countrySelectorStyle} >
              <CountrySelector
                id="country-selector"
                defaultValue={dataStorageCountry}
                hintTextLabel={commonTranslations.selectCountry}
                onChange={handleCountryChange}
              />
            </div>
          </div>
        </div>
      </div>
      <DivWrapper
        id="div-wrapper"
        autoHeight={true}
        autoHeightMax={getDialogContentHeight(WINDOW_INNERHEIGHT, TABLE_MAXHEIGHT)}
        onScroll={handleScroll}
        onScrollStop={props.onScrollStop}
        style={divWrapperStyle}
      >
        {multiValue &&
          <FormGroup id="all-formGroup" column={true}>
            <FormControlLabel
              id="all-formControl"
              control={
                <Checkbox
                  id="all"
                  key="all"
                  color="primary"
                  checked={filterSelectAll}
                  onChange={handleSelectAll}
                  style={styles.checkboxStyle}
                />}
              style={checkBoxStyle}
              label={commonTranslations.selectAll}
            />
          </FormGroup>}
        {filterValues &&
          <List
            style={listStyle}
            height={200}
            itemCount={filterValues.size}
            itemSize={40}
            ref={listRef}
          >
            {Row}
          </List>
          }
      </DivWrapper>
      <div style={Object.assign({}, styles.multiselectAddButton)}>
        <Button
          id="button"
          onClick={handleSubmit(() => onSave(selectedItems))}
        >
          {commonTranslations.saveAndClose}
        </Button>
        <Button
          id="cancel-button"
          variant="text"
          onClick={onCancel}
        >
          {commonTranslations.Cancel}
        </Button>
      </div>
    </form>
  );
};

DatasourceMultiSelectorForm.propTypes = {
  multiValue: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onresetFilter: PropTypes.func,
  filteredData: PropTypes.instanceOf(Immutable.List),
  searchResults: PropTypes.instanceOf(Immutable.List),
  onScrollStop: PropTypes.func
};

DatasourceMultiSelectorForm.defaultProps = {
  multiValue: true,
  filteredData: Immutable.List(),
  searchResults: Immutable.List(),
  onSave: e => e,
  onCancel: e => e,
  handleSubmit: e => e,
  onScrollStop: e => e,
  onChooseFilter: e => e,
  onresetFilter: e => e
};

const DatasourceMultiSelectorFormWrapper = reduxForm({
  form: 'DatasourceMultiSelectorForm' // a unique identifier for this form
})(DatasourceMultiSelectorForm);

export default DatasourceMultiSelectorFormWrapper;
