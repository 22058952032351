import Immutable from 'immutable';
import {
  getModifiedList,
  getFilteredVisibleList
} from '../../../privacy-record-detail/common-utils';

const defaultState = Immutable.fromJS({
  assessments: [],
  visibilityFields: {
    processing: undefined,
    assessment: undefined
  },
  menuVisibility: {
    processing: undefined,
    assessment: undefined
  },
  visibility: undefined,
  isUpdating: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ROLE:GROUPS:FIELDS:VISIBILITY:FETCH':
      return state
        .set('isFetchingVisibility', true)
        .set('visibility', defaultState.get('visibility'))
        .set('error', defaultState.get('error'));
    case 'ROLE:FIELDS:VISIBILITY:FETCH:SUCCESS': {
      const visibilityFields = getFilteredVisibleList(
        action.visibility.value,
        action.isViewRestrictedUser,
        action.isPublic
      );
      return state
        .set('isFetchingVisibility', false)
        .set('visibilityFields', visibilityFields)
        .set(
          'menuVisibility',
          getModifiedList(
            action.visibility.value,
            'filterMenu',
            action.isViewRestrictedUser,
            action.isPublic
          )
        )
        .set('isUpdating', false)
        .set('visibility', action.visibility);
    }
    case 'ROLE_GROUP:FIELDS:VISIBILITY':
      return state.set('isUpdating', true);

    default:
      return state;
  }
};
