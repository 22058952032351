import logMiddleware from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';
import commonReducers from './reducers';
import commonSagas from './watchers';

export const history = createHashHistory();
// Reusable function to create a store.
// returnValue : store
export const createStore = (reducers = {}, sagas = []) => {
  // args: 1. reducers , 2.sagas

  const createRootReducer = (history) =>
    combineReducers({
      ...commonReducers,
      ...reducers,
      router: connectRouter(history)
    });

  function* rootSaga() {
    yield all(
      [...commonSagas, ...sagas]
        .map(
          (saga) =>
            function* () {
              yield saga[0](saga[1], saga[2]);
            }
        )
        .map((saga) => saga.call())
    );
  }

  const middlewares = [];
  const sagaMiddleware = createSagaMiddleware();

  // eslint-disable-next-line no-undef
  if (window.ENV !== undefined && ENV.logDispatcher) {
    middlewares.push(logMiddleware());
  }

  middlewares.push(sagaMiddleware);
  middlewares.push(routerMiddleware(history));

  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: false
      }).concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production'
  });

  sagaMiddleware.run(rootSaga);

  return store;
};
