import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import { replace as replaceRouter } from 'connected-react-router';
import {
  handleServiceDown,
  getErrorMessage,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import dsrTranslations from '../../dsrTranslations';

export function* resetAndFetchDatasubjectRequests(action) {
  yield put({ type: 'DATA_SUBJECT_REQUESTS:LIST:FETCH:RESET_INIT' });
  yield fetchDatasubjectRequests(action);
}

export function* fetchDatasubjectRequests(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  yield put({ type: 'DATA_SUBJECT_REQUESTS:LIST:FETCH:INIT' });
  try {
    const dsrState = yield select((state) => state.dsrList);
    const filterParams = dsrState ? dsrState.get('filterParams').toJS() : {};
    const { sortOn, sortOrder } = filterParams;
    const filteredOn = dsrState ? dsrState.get('filteredOn') : Immutable.Map();
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams({ sortOn, sortOrder, filteredOn }, position, rowCount),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedResponse = getModifiedResponse(response.body);
        yield put({
          type: 'DATA_SUBJECT_REQUESTS:LIST:FETCH:SUCCESS',
          items: modifiedResponse
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({
      type: 'DATA_SUBJECT_REQUESTS:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* createDSR(action) {
  try {
    const request = registry.get('request');
    const response = yield request.post(
      '/v1/dsrmanagement/datasubjectrequests',
      action.data
    );
    let route = `/dsr/${response.body.id}/edit`;
    if (action.from) route = `${route}?from=${action.from}`;
    switch (response.status) {
      case 200: {
        yield put(replaceRouter(route));
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({
      type: 'DATA_SUBJECT_REQUESTS:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* filterDatasubjectRequests(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  yield put({ type: `DATA_SUBJECT_REQUESTS:LIST:FILTER`, filterParams });
  yield fetchDatasubjectRequests(action);
}

export function* deleteDatasubjectRequests(action) {
  const { items, isSingleDelete } = action;
  const request = registry.get('request');
  try {
    const entityIds = items.map((item) => item.id);
    const response = yield request.post(
      `/v1/dsrmanagement/datasubjectrequests/remove`,
      { entityIds }
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isSingleDelete
              ? dsrTranslations.dsrDeleted
              : dsrTranslations.BulkDeleteSuccess,
            type: 'success'
          }
        });
        if (isSingleDelete) {
          const dsrList = yield select((state) => state.dsrList);
          const reqItems = dsrList.get('items');
          const newItems = updateDataItems(reqItems, items[0].id);
          yield put({
            type: `DATA_SUBJECT_REQUESTS:LIST:UPDATE`,
            items: newItems
          });
        } else yield resetAndFetchDatasubjectRequests(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
  }
}

export const updateDataItems = (items, currentId) => {
  const index = items.findIndex((item) => item.id === currentId);
  if (index !== -1) {
    const updatedItems = items.remove(index);
    return updatedItems;
  }
  return items;
};

export function* updateDSRNote(action) {
  const request = registry.get('request');
  const { data } = action;
  try {
    const requestUrl = `/v1/dsrmanagement/datasubjectrequests/${data.id}`;
    const response = yield request.put(requestUrl, data);
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: data.note
              ? notificationtranslations.dsrNoteUpdateSuccess
              : notificationtranslations.dsrNoteDeleted,
            type: 'success'
          }
        });
        yield fetchDatasubjectRequests({ position: 0 });
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });
  }
}

export function* bulkExportDSR() {
  yield put({ type: `DSR:BULK:EXPORT:INIT` });
  const request = registry.get('request');
  try {
    const url = `/v1/datasubjectrequests/export`;
    const response = yield request.exportCSVbyGet(url, null);

    yield response;
    switch (response.status) {
      case 200: {
        yield put({ type: `DSR:BULK:EXPORT:SUCCESS` });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `DSR:BULK:EXPORT:FAIL` });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    yield put({ type: `DSR:BULK:EXPORT:FAIL` });
  }
}

const getModifiedResponse = (response) => {
  const modifiedResponse = response.map((res) => ({
    ...res,
    requester: res.dataSubject ? getRequester(res.dataSubject) : '',
    assignee: res.assignedUsers.map((item) =>
      item.value
        ? `${item.value.firstName} ${item.value.lastName || ''}`
        : `${item.firstName} ${item.lastName || ''}`
    ),
    assignedOrg: res.assignedOrganisations.map((item) => item.value.name)
  }));
  return modifiedResponse;
};

const getRequester = (dataSubject) => {
  if (dataSubject.value.firstName || dataSubject.value.lastName) {
    return `${dataSubject.value.firstName || ''} ${
      dataSubject.value.lastName || ''
    }`;
  } else if (!dataSubject.value.anonymise)
    return dataSubject.value.dataSubjectNumber;
  return '*****';
};

const getURLWithParams = (filterParams, position, rowCount) => {
  let url = `/v1/dsrmanagement/datasubjectrequests?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((item) => {
          const filterText = typeof item === 'object' ? item.name : item;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    // data includes status, requestTypes, requestFormat, identityVerifications, validations and deadlineDate field
    const customFields = [
      'data',
      'dataSubject',
      'assignedUsers',
      'assignedOrganisations',
      'deadlineExpiryDuration'
    ];
    customFields.forEach((field) => {
      url = `${url}&fields=${field}`;
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
