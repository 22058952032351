import { connect } from 'react-redux';
import CreateBreachComponent from './component';

const mapStateToProps = (state) => ({
  isUpdating: state.breach.get('isUpdating'),
  actionError: state.breach.get('actionError'),
  isTemplateMode: state.privacyRecords.list.get('isTemplateMode'),
  isGlobal: state.home.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'BREACH:DETAIL:RESET' });
  },
  saveBreach: (
    data,
    templateId,
    createFromEdit,
    isVendor,
    isTemplateMode,
    refetchRecords,
    isGlobal
  ) => {
    dispatch({
      type: 'BREACH:DETAIL:UPSERT',
      data,
      createFromEdit,
      isVendor,
      templateId,
      isTemplateMode,
      refetchRecords,
      isGlobal
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBreachComponent);
