import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';

import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';

import { transformOrganisationEntity } from '../../../../../organisation-entities/utils';

export function* resetAndfetchOrganisations(action) {
  const { isVendor = false } = action;
  if (isVendor) yield put({ type: 'VENDOR:ORGANISATIONS:LIST:INIT' });
  else yield put({ type: 'ORGANISATIONS:LIST:INIT' });
  yield fetchOrganisations(action);
}

export function* fetchOrganisations(action) {
  yield put({ type: 'ORGANISATIONS:LIST:FETCH' });
  const {
    position = 0,
    rowCount = MASTERDATA_FETCH_LIMIT,
    isVendor = false,
    isInternal
  } = action;
  try {
    let filterParams = {};
    let searchTextObj = {};
    let searchParameters = {};
    let filteredOn = Immutable.Map();
    if (action.isPersistedData) {
      const organisationsState = isVendor
        ? yield select((state) => state.vendorLists)
        : yield select((state) => state.environment.organisations);
      if (organisationsState) {
        filterParams = organisationsState.get('filterParams').toJS();
        filteredOn = organisationsState.get('filteredOn');
        searchTextObj = organisationsState.get('searchText').toJS();
        searchParameters = searchTextObj
          ? { searchKey: 'name', searchText: searchTextObj.name }
          : {};
      }
    }
    const { sortOn, sortOrder } = filterParams;
    let url = getURLWithParams(
      { sortOn, sortOrder, filteredOn },
      position,
      searchParameters,
      rowCount,
      isVendor,
      isInternal
    );
    if (action.hasTopDownLimit) {
      const loggedUserState = yield select((state) => state.login);
      let userId;
      if (loggedUserState) {
        userId = loggedUserState.get('loggedUser').get('userId');
      }
      url = `${url}&userId=${userId}`;
    }
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) =>
          transformOrganisationEntity(item)
        );
        if (isVendor) {
          yield put({
            type: 'VENDOR:ORGANISATIONS:LIST:FETCH:SUCCESS',
            items: modifiedItems
          });
        } else {
          yield put({
            type: 'ORGANISATIONS:LIST:FETCH:SUCCESS',
            items: modifiedItems,
            organisationData: action.organisationData
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    registry.get('logger').error(err);
    yield put({
      type: isVendor
        ? 'VENDOR:ORGANISATIONS:LIST:FETCH:FAIL'
        : 'ORGANISATIONS:LIST:FETCH:FAIL',
      error: err.message
    });
    yield put({ type: 'LEGAL_ENTITY:FETCH:FAIL', error: err.message });
  }
}

export function* filterOrganisations(action) {
  const filterParams = action.filterParams || {};
  const { isVendor = false } = action;
  if (isVendor)
    yield put({ type: 'VENDOR:ORGANISATIONS:LIST:FILTER', filterParams });
  else yield put({ type: 'ORGANISATIONS:LIST:FILTER', filterParams });
  yield fetchOrganisations(action);
}

export function* searchOrganisations(action) {
  const {
    searchParams: { searchKey },
    searchParams,
    rowCount = MASTERDATA_SEARCH_LIMIT,
    isVendor
  } = action;
  const organisationsState = isVendor
    ? yield select((state) => state.vendorLists)
    : yield select((state) => state.environment.organisations);

  const { sortOn, sortOrder, filteredOn, searchParameters, searchText } =
    getFilterAndSearchParamsFromState(organisationsState, searchParams);

  if (isVendor)
    yield put({
      type: 'VENDOR:ORGANISATIONS:LIST:SEARCH',
      searchKey,
      searchText
    });
  else yield put({ type: 'ORGANISATIONS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position || 0,
          searchParameters,
          rowCount,
          isVendor
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) =>
          transformOrganisationEntity(item)
        );
        if (isVendor) {
          yield put({
            type: 'VENDOR:ORGANISATIONS:LIST:SEARCH:SUCCESS',
            searchKey,
            items: modifiedItems,
            searchText
          });
        } else {
          yield put({
            type: 'ORGANISATIONS:LIST:SEARCH:SUCCESS',
            searchKey,
            items: modifiedItems,
            searchText
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    registry.get('logger').error(err);
    yield put({ type: 'ORGANISATIONS:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* updateOrganisation(action) {
  const { data, isEdit } = action;
  yield put({
    type: 'ORGANISATION_ENTITY:DETAIL:UPSERT',
    data,
    isEdit,
    onSuccess: 'ORGANISATIONS:LIST:UPDATE:SUCCESS'
  });
}

export function* upsertListOrganisation(action) {
  const { isEdit, selectedItem, isVendor } = action;
  if (isEdit) {
    yield put({
      type: isVendor
        ? 'VENDOR:ORGANISATIONS:LIST:UPDATE:SUCCESS'
        : 'ORGANISATIONS:LIST:UPDATE:SUCCESS',
      isEdit,
      selectedItem
    });
  } else {
    const modifiedData = transformOrganisationEntity(selectedItem);
    yield put({
      type: isVendor
        ? 'VENDOR:ORGANISATIONS:LIST:UPSERT:SUCCESS'
        : 'ORGANISATIONS:LIST:UPSERT:SUCCESS',
      position: 0,
      organisationData: selectedItem
    });
    yield fetchOrganisations({
      organisationData: modifiedData,
      isPersistedData: true,
      isVendor
    });
  }
}

export function* deleteOrganisation(action) {
  const { orgId, isVendor } = action;
  yield put({
    type: 'ORGANISATION_ENTITY:ITEM:DELETE',
    entityId: orgId,
    onSuccess: isVendor
      ? 'VENDOR:ORGANISATIONS:LIST:DELETE:SUCCESS'
      : 'ORGANISATIONS:LIST:DELETE:SUCCESS'
  });
}

export function* organisationCheckUsage(action) {
  const { isInternal } = action;
  yield put({ type: 'LEGAL_ENTITIES:ITEM:USAGE:FETCH:INIT' });
  if (!isInternal) {
    yield put({ type: 'EXTERNAL_ORGANISATIONS:ITEM:USAGE:FETCH:INIT' });
  } else {
    yield put({ type: 'TENANT_ORGANISATIONS:ITEM:USAGE:FETCH:INIT' });
  }
  try {
    const isGlobal = yield select((state) => state.home.get('isGlobal'));
    const response = !isGlobal
      ? yield registry
        .get('request')
        .get(`/v1/organisations/${action.entityId}/usage`, null)
      : yield registry
        .get('request')
        .get(
          `/v1/organisations/${action.entityId}/tenants-usage?withUsageCount=false`,
          null
        );

    switch (response.status) {
      case 200: {
        const usage = response.body;
        yield put({ type: 'LEGAL_ENTITIES:USAGE:SUCCESS', usage });
        if (!isInternal) {
          yield put({ type: 'EXTERNAL_ORGANISATION:USAGE:SUCCESS', usage });
        } else {
          yield put({ type: 'TENANT_ORGANISATION:USAGE:SUCCESS', usage });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'LEGAL_ENTITIES:USAGE:FAIL', error: err.message });
  }
}

const getURLWithParams = (
  filterParams,
  position,
  searchParams,
  rowCount = 15,
  isVendor,
  isInternal
) => {
  let url = `/v1/organisations?numberOfResults=${rowCount}&offset=${position}`;
  if (!isVendor) url = `${url}&filter=nonProspect=true`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText =
            typeof x === 'object' ? x.id || x.props.defaultMessage : x;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (isInternal) {
    url = `${url}&filter=isInternal=${isInternal}`;
  }
  return url;
};

export function* exportOrganisations(action) {
  const { signal, controller } = action;
  yield put({ type: `ORGANISATIONS:BULK:EXPORT:INIT` });
  const request = registry.get('request');
  try {
    const url = `v1/entities/organisation/export`;
    if (controller) {
      yield request.exportCSVbyGet(url, null, null, controller);
    } else {
      const response = yield request.exportCSVbyGet(url, null, signal);

      yield response;
      switch (response.status) {
        case 200: {
          yield put({ type: `ORGANISATIONS:BULK_EXPORT:SUCCESS` });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({ type: `ORGANISATIONS:BULK_EXPORT:FAIL` });
          break;
      }
    }
  } catch (err) {
    if (!action.signal.aborted) yield handleServiceDown(err, 'organisation');
    yield put({ type: `ORGANISATIONS:BULK_EXPORT:FAIL` });
  }
}
const getFilterAndSearchParamsFromState = (
  organisationsState,
  searchParams
) => {
  // Filter parameters
  let filterParams = {};
  let searchTextObj = {};
  if (organisationsState) {
    searchTextObj = organisationsState.get('searchText').toJS();
    filterParams = organisationsState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = organisationsState
    ? organisationsState.get('filteredOn')
    : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    // For first time rendering of search items, searchText will not be defined
    searchText = searchText !== undefined ? searchText : searchTextObj.name;
    searchParameters = Object.assign({}, searchParams, { searchText });
  } else {
    searchParameters = searchTextObj
      ? { searchKey: 'name', searchText: searchTextObj.name }
      : {};
  }

  return { sortOn, sortOrder, filteredOn, searchParameters, searchText };
};
