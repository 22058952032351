import React from 'react';

import { FormattedMessage } from 'react-intl';
import { commonTranslations } from '@packages/utils/commontranslations';
import {
  singularTerms as uppercaseSingular,
  pluralTerms as uppercasePlural
} from '@packages/utils/uppercaseTranslations';
import vendorTranslations from '@packages/utils/vendorTranslations';
import { tenantConfigurationTranslations } from '../tenant-configuration/tenantConfigurationTranslations';
// eslint-disable-next-line import/prefer-default-export
export const settingsTranslations = {
  integration: (
    <FormattedMessage id="Settings.integration" defaultMessage="Integration" />
  ),
  incorrectPassword: (
    <FormattedMessage
      id="Settings.incorrectPassword"
      defaultMessage="Incorrect password"
    />
  ),
  tokenWarning: (
    <FormattedMessage
      id="Settings.tokenWarning"
      defaultMessage="This token will be displayed only once. After this window is closed it will not be possible
    to recover the token."
    />
  ),
  apiTokens: (
    <FormattedMessage id="Integration.apiTokens" defaultMessage="API Tokens" />
  ),
  apiToken: (
    <FormattedMessage id="Integration.apiToken" defaultMessage="API Token" />
  ),
  deleteTokenHeader: (
    <FormattedMessage
      id="Integration.deleteTokenHeader"
      defaultMessage="Delete token"
    />
  ),
  regenerateToken: (
    <FormattedMessage
      id="Tokens.regenerate"
      defaultMessage="Regenerate token"
    />
  ),
  regenerateHint: (
    <FormattedMessage
      id="Tokens.regenerateHint"
      defaultMessage="Regenerating this token will cause all integrations that are currently using it to stop
    working until they are configured to use the new one."
    />
  ),
  regenerateConfirm: (
    <FormattedMessage
      id="Tokens.regenerateConfirm"
      defaultMessage="Are you sure you want to do this?"
    />
  ),
  copyToken: <FormattedMessage id="Tokens.copy" defaultMessage="Copy token" />,
  apiTokensDescription: (
    <FormattedMessage
      id="Integration.apiTokensDescription"
      defaultMessage="Inbound API connections require a personal API token to authenticate with. Here you can manage
    existing tokens and add new ones."
    />
  ),
  addToken: (
    <FormattedMessage
      id="Integration.addTokens"
      defaultMessage="Add new Token"
    />
  ),
  tokensPermission: (
    <FormattedMessage
      id="Integration.tokensPermission"
      defaultMessage="Token permissions"
    />
  ),
  tokensPermissionHelpNote: (
    <FormattedMessage
      id="Integration.tokensPermissionHelpNote"
      defaultMessage="Token permissions help note"
    />
  ),
  tokenCreationDisabled: (
    <FormattedMessage
      id="Integration.tokenCreationDisabled"
      defaultMessage="You can't create token. Please enable sso bypass for yourself to continue with token creation."
    />
  ),
  passwordConfirmation: (
    <FormattedMessage
      id="Integration.passwordConfirmation"
      defaultMessage="For security you are required to enter your password before creating or editing a token."
    />
  ),
  planId: <FormattedMessage id="Filter.planId" defaultMessage="Plan id" />,
  plan: <FormattedMessage id="Pricing.plan" defaultMessage="Plan" />,
  entityCount: (
    <FormattedMessage id="Pricing.entityCount" defaultMessage="Entity count" />
  ),
  maxEntityCount: (
    <FormattedMessage
      id="Pricing.maxEntityCount"
      defaultMessage="Maximum entity count"
    />
  ),
  features: (
    <FormattedMessage id="Pricing.features" defaultMessage="Features" />
  ),
  profile: (
    <FormattedMessage
      id="Filter.profile"
      description="Profile"
      defaultMessage="Profile"
    />
  ),
  userDetails: (
    <FormattedMessage
      id="Profile.userDetail"
      description="User Details"
      defaultMessage="User Details"
    />
  ),
  changePassword: (
    <FormattedMessage
      id="Profile.changePassword"
      description="Change Password"
      defaultMessage="Change Password"
    />
  ),
  setLanguage: (
    <FormattedMessage
      id="Translation.setLanguage"
      description="Apply translation"
      defaultMessage="Set Language"
    />
  ),
  userEmailPreference: (
    <FormattedMessage
      id="EmailPreference.userEmailPreference"
      defaultMessage="User Email Preference"
    />
  ),
  emailNotificationButtonDisabledInfo: (
    <FormattedMessage
      id="EmailPreference.adminSettingsInfo"
      defaultMessage="Admin has enabled to send emails for all the users of this tenant"
    />
  ),
  emailNotificationDescription: (
    <FormattedMessage
      id="EmailPreference.emailNotificationDescription"
      defaultMessage="Here you can set  which notifications from PrivacyPerfect
    you also want to be sent to your email inbox"
    />
  ),
  messagesAsEmail: (
    <FormattedMessage
      id="EmailPreference.messageAsEmail"
      defaultMessage="Recieve message as emails"
    />
  ),
  notificationsAsEmail: (
    <FormattedMessage
      id="EmailPreference.notificationAsEmail"
      defaultMessage="Receive notification as emails"
    />
  ),
  tasksAsEmail: (
    <FormattedMessage
      id="EmailPreference.taskAsEmail"
      defaultMessage="Receive tasks as emails"
    />
  ),
  vrmEmailCompanyLogo: (
    <FormattedMessage
      id="EmailPreference.vrmEmailCompanyLogo"
      defaultMessage="Show company logo in Vendor emails"
    />
  ),
  weeklyDigestDescription: (
    <FormattedMessage
      id="EmailPreference.weeklyDigestDescription"
      defaultMessage="The weekly digest will give you a brief overview
    at the end of each week of updates and changes within your tenant"
    />
  ),
  weeklyDigestAsEmail: (
    <FormattedMessage
      id="EmailPreference.weeklyDigestAsEmail"
      defaultMessage="Receive weekly digest"
    />
  ),
  dailyTodoDescription: (
    <FormattedMessage
      id="EmailPreference.dailyTodoDescription"
      defaultMessage="The daily todo will give you an email
    at the start of every workday giving a brief overview outstanding tasks"
    />
  ),
  dailyTodoAsEmail: (
    <FormattedMessage
      id="EmailPreference.dailyTodoAsEmail"
      defaultMessage="Receive daily todo"
    />
  ),
  general: (
    <FormattedMessage
      id="Records.general"
      description="General"
      defaultMessage="General"
    />
  ),
  auditTrailSettings: (
    <FormattedMessage
      id="Settings.auditTrailSettings"
      defaultMessage="Audit trail settings"
    />
  ),
  selectRetentionPeriod: (
    <FormattedMessage
      id="Settings.selectRetentionPeriod"
      defaultMessage="Select retention period"
    />
  ),
  retentionPeriodWarning: (
    <FormattedMessage
      id="Settings.retentionPeriodWarning"
      defaultMessage="Records older than the designated time frame in the audit trail 
      will be deleted. Please note that the outcomes won't be instant and will only take effect during the next update."
    />
  ),
  roleManagement: (
    <FormattedMessage
      id="Settings.roleManagement"
      description="Role Management"
      defaultMessage="Role Management"
    />
  ),
  fieldvisibility: (
    <FormattedMessage
      id="Common.fieldsVisibilitySetting"
      description="Field visibility"
      defaultMessage="Field visibility"
    />
  ),
  interfaceStyle: (
    <FormattedMessage
      id="WhiteLabelling.interfaceStyle"
      description="Interface style"
      defaultMessage="Interface style"
    />
  ),
  tenantEmailPreference: (
    <FormattedMessage
      id="tenantEmailPreference.general"
      defaultMessage="Tenant Email Preference"
    />
  ),
  security: (
    <FormattedMessage
      id="Settings.security"
      description="Security"
      defaultMessage="Security"
    />
  ),
  mfa: (
    <FormattedMessage
      id="Settings.mfa"
      description="mfa"
      defaultMessage="Multi Factor Authentication"
    />
  ),
  sso: (
    <FormattedMessage
      id="Settings.sso"
      description="sso"
      defaultMessage="Single Sign On"
    />
  ),
  custom: (
    <FormattedMessage
      id="Settings.custom"
      description="custom"
      defaultMessage="Custom"
    />
  ),
  helpTexts: (
    <FormattedMessage
      id="Settings.helpTexts"
      description="helpTexts"
      defaultMessage="Help texts"
    />
  ),
  defaultDeadlinePeriod: (
    <FormattedMessage
      id="Settings.defaultDeadlinePeriod"
      defaultMessage="Default deadline Period"
    />
  ),
  deadlineExpiryNotification: (
    <FormattedMessage
      id="Settings.deadlineEpiryNotification"
      defaultMessage="Set deadline expiry notification"
    />
  ),
  daysPriorToDeadline: (
    <FormattedMessage
      id="Settings.daysPriorToDeadline"
      defaultMessage="prior to the deadline"
    />
  ),
  deadlinereminders: (
    <FormattedMessage
      id="Settings.deadlinereminders"
      defaultMessage="Deadline expiry reminders"
    />
  ),
  dataRetentionExpiryNotification: (
    <FormattedMessage
      id="Settings.dataRetentionEpiryNotification"
      defaultMessage="Set data retention expiry notification"
    />
  ),
  daysPriorToDataRetention: (
    <FormattedMessage
      id="Settings.daysPriorToDataRetention"
      defaultMessage="prior to the data Retention"
    />
  ),
  dataRetentionreminders: (
    <FormattedMessage
      id="Settings.dataRetentionreminders"
      defaultMessage="Data retention expiry reminders"
    />
  ),
  emailReminders: (
    <FormattedMessage
      id="Settings.emailReminders"
      defaultMessage="Receive email reminders"
    />
  ),
  anonymiseDSInList: (
    <FormattedMessage
      id="Settings.anonymiseDSRList"
      defaultMessage="Anonymise requester data in list views"
    />
  ),
  anonymiseDSInEdit: (
    <FormattedMessage
      id="Settings.anonymiseRequesterList"
      defaultMessage="Anonymise requester data in edit and list views"
    />
  ),
  password: (
    <FormattedMessage
      id="settings.password"
      defaultMessage="Password Settings"
    />
  ),
  notificationWarning: (
    <FormattedMessage
      id="settings.notificationWarning"
      defaultMessage="Notification days should be less than Default deadline Period and Data retention days"
    />
  ),
  dataRetentionWarning: (
    <FormattedMessage
      id="settings.dataRetentionWarning"
      defaultMessage="Notification days should be less than Data retention days"
    />
  ),
  deadlineWarning: (
    <FormattedMessage
      id="settings.deadlineWarning"
      defaultMessage="Notification days should be less than Default deadline Period days"
    />
  ),
  subscription: (
    <FormattedMessage
      id="settings.subscription"
      defaultMessage="Subscription"
    />
  ),
  billingPlanDetails: (
    <FormattedMessage
      id="settings.billingPlanDetails"
      defaultMessage="Billing plan details"
    />
  ),
  tenantTypeChangeConfirmation: (
    <FormattedMessage
      id="settings.changeTenantType"
      defaultMessage="Are you sure that you want to change tenant type?"
    />
  ),
  upgradePlan: (
    <FormattedMessage id="settings.upgradePlan" defaultMessage="Upgrade plan" />
  ),
  subscriptionExpired: (
    <FormattedMessage
      id="settings.subscriptionExpired"
      defaultMessage="The subscription plan for this tenant has been expired."
    />
  ),
  contractExpired: (
    <FormattedMessage
      id="settings.contractExpired"
      defaultMessage="The contract period for this tenant has been expired. Please contact PrivacyPerfect."
    />
  ),
  date: <FormattedMessage id="AuditTrailView.date" defaultMessage="Date" />,
  to: <FormattedMessage id="Common.to" defaultMessage="to" />,
  status: uppercaseSingular('status'),
  item: uppercaseSingular('item'),
  subscriptionStatus: (
    <FormattedMessage
      id="TenantList.subscriptionStatus"
      defaultMessage="Subscription status"
    />
  ),
  active: <FormattedMessage id="Record.active" defaultMessage="Active" />,
  inactive: (
    <FormattedMessage
      id="subscriptionStatus.inactive"
      defaultMessage="Inactive"
    />
  ),
  cancelled: (
    <FormattedMessage id="DSR.status.cancelled" defaultMessage="Cancelled" />
  ),
  settings: (
    <FormattedMessage
      id="Filter.settings"
      description="Settings"
      defaultMessage="Settings"
    />
  ),
  publicRegistry: (
    <FormattedMessage
      id="Filter.publicRegistry"
      defaultMessage="Public registry"
    />
  ),
  publicRegistryDescription: (
    <FormattedMessage
      id="publicRegistry.description"
      defaultMessage="List of defined records that can be displayed to the public."
    />
  ),
  addRegistry: (
    <FormattedMessage
      id="publicRegistry.addRegistry"
      defaultMessage="Add new registry"
    />
  ),
  registries: (
    <FormattedMessage
      id="publicRegistry.registries"
      defaultMessage="Registries"
    />
  ),
  publicFieldVisibility: (
    <FormattedMessage
      id="publicRegistry.fieldVisibility"
      defaultMessage="Field visibility for public registry"
    />
  ),
  newRegistry: (
    <FormattedMessage id="Registry.NewRegistry" defaultMessage="New Registry" />
  ),
  editRegistry: (
    <FormattedMessage
      id="publicRegistry.EditRegistry"
      defaultMessage="Edit Registry"
    />
  ),
  rights: (
    <FormattedMessage id="publicRegistry.rights" defaultMessage="Rights" />
  ),
  belongingTo: (
    <FormattedMessage
      id="publicRegistry.belongingTo"
      defaultMessage="Belonging to"
    />
  ),
  recordTypes: (
    <FormattedMessage
      id="publicRegistry.recordTypes"
      defaultMessage="Record types"
    />
  ),
  statuses: (
    <FormattedMessage id="publicRegistry.statuses" defaultMessage="Statuses" />
  ),
  additionalFilters: (
    <FormattedMessage
      id="publicRegistry.additionalFilters"
      defaultMessage="Additional filters"
    />
  ),
  chooseRole: (
    <FormattedMessage
      id="publicRegistry.chooseRole"
      defaultMessage="Choose role"
    />
  ),
  preview: (
    <FormattedMessage id="publicRegistry.preview" defaultMessage="Preview" />
  ),
  bulkDelete: (
    <FormattedMessage
      id="publicRegistry.bulkDelete"
      defaultMessage="Bulk delete public registry"
    />
  ),
  bulkDeleteConfirm: (
    <FormattedMessage
      id="publicRegistry.bulkDeleteConfirm"
      defaultMessage="Do you want to delete selected registries?"
    />
  ),
  setFieldVisibility: (
    <FormattedMessage
      id="publicRegistry.setFieldVisibility"
      defaultMessage="Set field visibility"
    />
  ),
  directLink: (
    <FormattedMessage
      id="publicRegistry.directLink"
      defaultMessage="Direct link"
    />
  ),
  registryDelete: (
    <FormattedMessage
      id="publicRegistry.registryDelete"
      defaultMessage="Delete registry"
    />
  ),
  changeStatus: (
    <FormattedMessage
      id="publicRegistry.changeStatus"
      defaultMessage="Change registry status"
    />
  ),
  invalidRegistryMessage: (
    <FormattedMessage
      id="publicRegistry.invalidRegistryMessage"
      defaultMessage="The registry you are trying to access is either invalid or inactive."
    />
  ),
  field: <FormattedMessage id="publicRegistry.field" defaultMessage="Field" />,
  value: <FormattedMessage id="publicRegistry.value" defaultMessage="Value" />,
  mustInclude: (
    <FormattedMessage
      id="publicRegistry.mustInclude"
      defaultMessage="Must include"
    />
  ),
  publicPortal: (
    <FormattedMessage
      id="publicRegistry.publicPortal"
      defaultMessage="Public Portal"
    />
  ),
  embedCode: (
    <FormattedMessage
      id="publicRegistry.embedCode"
      defaultMessage="Embed code"
    />
  ),
  copyEmbedCode: (
    <FormattedMessage
      id="publicRegistry.copyEmbedCode"
      defaultMessage="Copy embed code"
    />
  ),
  changeField: (
    <FormattedMessage
      id="publicRegistry.changeField"
      defaultMessage="Are you sure you want to continue. This action will remove selected values of corresponding field "
    />
  ),
  downgradeNotification: (
    <FormattedMessage
      id="subscription.downgradeNotification"
      defaultMessage="Some of the features currently available will be no longer available
     since you are about to downgrade the plan "
    />
  ),
  showEstimateAmount: (
    <FormattedMessage
      id="subscription.showEstimateAmount"
      defaultMessage="Show estimated price "
    />
  ),
  chooseHierarchy: (
    <FormattedMessage
      id="publicRegistry.chooseHierarchy"
      defaultMessage="Choose hierarchy"
    />
  ),
  never: <FormattedMessage id="settings.never" defaultMessage="Never" />,
  enableMFA: (
    <FormattedMessage id="Settings.enableMFA" defaultMessage="Enable MFA" />
  ),
  mfaHeading: (
    <FormattedMessage id="Filter.mfasettings" defaultMessage="MFA settings" />
  ),
  mfaSubheading: (
    <FormattedMessage
      id="Settings.mfaHeader"
      defaultMessage="Below you can configure the settings for 'multi factor authentication' (MFA)"
    />
  ),
  enableMfaInfo: (
    <FormattedMessage
      id="Settings.enableMfaInfo"
      defaultMessage="The administrator has set MFA as obligatory"
    />
  )
};

export const billingPlanTanslations = (key) => {
  switch (key) {
    case 'auditTrail':
      return commonTranslations.auditTrail;
    case 'advancedRoleManagement':
      return settingsTranslations.roleManagement;
    case 'dsr':
      return <FormattedMessage id="Header.dsrModule" defaultMessage="DSR" />;
    case 'pdfExport':
      return (
        <FormattedMessage id="Pricing.pdfExport" defaultMessage="Pdf export" />
      );
    case 'masterDataImportExport':
      return (
        <FormattedMessage
          id="Pricing.importExport"
          defaultMessage="Master Data Import/Export"
        />
      );
    case 'processings':
      return (
        <FormattedMessage id="Type.Processings" defaultMessage="Processings" />
      );
    case 'assessments':
      return uppercasePlural('dpias');
    case 'tia':
      return uppercasePlural('tias');
    case 'sso':
      return settingsTranslations.sso;
    case 'reports':
      return (
        <FormattedMessage id="Header.reportsearch" defaultMessage="Reports" />
      );
    case 'breaches':
      return <FormattedMessage id="Type.Breaches" defaultMessage="Breaches" />;
    case 'customHelpNotes':
      return settingsTranslations.helpTexts;
    case 'whiteLabelling':
      return settingsTranslations.interfaceStyle;
    case 'fieldVisibility':
      return settingsTranslations.fieldvisibility;
    case 'documentStorage':
      return (
        <FormattedMessage
          id="RecordDetails.Documents"
          description="Documents"
          defaultMessage="Documents"
        />
      );
    case 'mfa':
      return settingsTranslations.mfa;
    case 'templates':
      return (
        <FormattedMessage
          id="Common.Templates"
          description="Templates"
          defaultMessage="Templates"
        />
      );
    case 'maxDocuments':
    case 'maxRecords':
    case 'maxReports':
    case 'maxSupervisorUsers':
      return uppercasePlural(key);
    case 'maxUsers':
      return (
        <FormattedMessage
          id="TenantDetail.maxUsers"
          defaultMessage="Maximum users"
        />
      );
    case 'Basic':
    case 'Pro':
    case 'Basicplus':
    case 'lgpd':
    case 'pdp':
    case 'appi':
    case 'pipl':
    case 'fdpl':
    case 'pdpa':
    case 'sgpdpa':
    case 'knowledgeHub':
    case 'Enterprise':
      return uppercaseSingular(key);
    case 'documents':
      return (
        <FormattedMessage
          id="RecordDetails.Documents"
          defaultMessage="Documents"
        />
      );
    case 'records':
      return commonTranslations.privacyRecords;
    case 'supervisorUsers':
      return (
        <FormattedMessage
          id="BillingPlan.readOnlyUsers"
          defaultMessage="Read-only users"
        />
      );
    case 'users':
      return (
        <FormattedMessage
          id="OrganisationUsersList.header"
          defaultMessage="Users"
        />
      );
    case 'apiIntegration':
      return (
        <FormattedMessage
          id="Pricing.apiIntegration"
          defaultMessage="API integration"
        />
      );
    case 'selectPlan':
      return (
        <FormattedMessage
          id="Pricing.selectPlan"
          defaultMessage="Select plan"
        />
      );
    case 'paymentType':
      return (
        <FormattedMessage
          id="Pricing.paymentType"
          defaultMessage="Payment type"
        />
      );
    case 'selectPaymentType':
      return (
        <FormattedMessage
          id="Pricing.selectPaymentType"
          defaultMessage="Select payment type"
        />
      );
    case 'Yearly':
      return (
        <FormattedMessage id="Pricing.Yearly" defaultMessage="Yearly (-10%)" />
      );
    case 'Quarterly':
      return (
        <FormattedMessage
          id="Pricing.Quarterly"
          defaultMessage="Quarterly (-5%)"
        />
      );
    case 'Monthly':
      return (
        <FormattedMessage id="BillingCycle.Monthly" defaultMessage="Monthly" />
      );
    case 'preDpia':
      return (
        <FormattedMessage
          id="Feature.preDpia"
          defaultMessage="Pre assessments"
        />
      );
    case 'ccpa':
      return uppercaseSingular(key);
    case 'vendorForms':
      return vendorTranslations[key];
    case 'publicRegistry':
      return settingsTranslations.publicRegistry;
    case 'isms':
      return uppercaseSingular('isms');
    case 'customFields':
      return tenantConfigurationTranslations.customFields;
    case 'ukgdpr':
      return uppercaseSingular('ukgdpr');
    case 'idpr':
      return uppercaseSingular('idpr');
    default:
      return key;
  }
};
