import { put } from 'redux-saga/effects';
import registry from 'app-registry';
import { push } from 'connected-react-router';

import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT,
  RECORDS_FETCH_LIMIT,
  getTransformedFilter
} from '@packages/utils/common-utils';

import { tenantConfigurationTranslations } from '../../tenantConfigurationTranslations';

export function* resetAndfetchRecordLayoutList(action) {
  yield put({ type: 'CUSTOM_RECORD_LAYOUTS:LIST:INIT' });
  yield fetchRecordTypes(action);
}

export function* fetchRecordTypes(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  yield put({ type: 'CUSTOM_RECORD_LAYOUTS:LIST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/custom-layout?withUsage=true&offset=${position}&numberOfResults=${rowCount}`,
        null
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM_RECORD_LAYOUTS:LIST:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
export function* resetAndfetchDynamicRecordLayoutList(action) {
  yield put({ type: 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:INIT' });
  yield fetchDynamicRecordLayoutList(action);
}

export function* fetchDynamicRecordLayoutList(action) {
  const { position = 0, rowCount = RECORDS_FETCH_LIMIT } = action;
  yield put({ type: 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-layout?withUsage=true&offset=${position}&numberOfResults=${rowCount}`,
        null
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteDynamicRecordLayout(action) {
  const { id } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(
      `v1/custom-record-layout/${id}`,
      null
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.layoutDeleted,
            type: 'success'
          }
        });
        yield put({
          type: 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:ITEM:DELETE:SUCCESS',
          id
        });
        break;
      }
      default: {
        yield put({
          type: 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:ITEM:DELETE:FAIL'
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* upsertCustomDynamicLayout(action) {
  const { data, isEdit, statusUpdate } = action;
  try {
    let response;
    if (isEdit) {
      // only handles status update from layout list page
      response = yield registry
        .get('request')
        .put(`v1/custom-record-layout/${data.get('id')}`, data.toJS());
    } else {
      response = yield registry
        .get('request')
        .post(`v1/custom-record-layout`, data);
    }
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM_DYNAMIC_LAYOUT:UPSERT:SUCCESS',
          data: response.body
        });
        yield put({
          type: 'LAYOUT_EDITOR:DETAILS:SET',
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content:
              (statusUpdate && tenantConfigurationTranslations.statusUpdate) ||
              (isEdit
                ? tenantConfigurationTranslations.updateRecordDetails
                : tenantConfigurationTranslations.layoutCreated),
            type: 'success'
          }
        });
        if (!isEdit) {
          yield put(
            push(
              `/layout-editor/${response.body.id}?derivedFrom=${response.body.derivedFrom.uniqueId}`
            )
          );
        } else {
          yield put({
            type: 'CUSTOM_DYNAMIC_LAYOUT:STATUS:UPDATE:SUCCESS',
            data: response.body
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

const getModifiedResponseData = (
  data,
  locale,
  tenantLocale,
  parent,
  isGlobal
) => {
  if (data?.length > 0) {
    return data
      .filter((item) => item?.subRecordTypes?.length > 0)
      .map((item) =>
        getTransformedFilter(item, locale, tenantLocale, parent, isGlobal)
      );
  } else return [];
};

export function* getCustomRecordTypes(action) {
  const {
    isFromEnv,
    locale,
    tenantLocale,
    filterDocument,
    isGlobal = false
  } = action;
  yield put({ type: 'CUSTOM_DYNAMIC_RECORD_TYPES:LIST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/custom-record-type/hierarchy${
          filterDocument ? '?filter=notRecordType=document-records' : ''
        }`,
        null
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM_DYNAMIC_RECORD_TYPES:LIST:FETCH:SUCCESS',
          data: response.body
        });

        if (isGlobal) {
          yield put({
            type: 'HOLDING:TEMPLATES:FILTER:INIT',
            data: getModifiedResponseData(
              response.body,
              locale,
              tenantLocale,
              'templates_dataManagement',
              true
            )
          });
          yield put({
            type: 'PRIVACY_RECORDS:CUSTOM:FILTER:INIT',
            customRecordTypes: getModifiedResponseData(
              response.body,
              locale,
              tenantLocale
            )
          });
        }
        if (isFromEnv)
          yield put({
            type: 'ENVIRONMENT:DOCUMENT:FILTERS:INIT',
            data: response.body.find(
              (item) => item.uniqueId === 'document-records'
            ),
            locale,
            tenantLocale
          });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}
