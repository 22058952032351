import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import { withTheme } from '@material-ui/core/styles';
import Linkify from 'react-linkify';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FontAwesome from 'react-fontawesome';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FixedSizeList as List } from 'react-window';
import TextField from '@material-ui/core/TextField';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import ArrowTooltip from '@packages/components/tooltip';
import DivWrapper from '@packages/components/divWrapper';
import AutoComplete from '@packages/components/auto-complete';
import { commonTranslations } from '@packages/utils/commontranslations';
import styles from '../styles';

const style = {
  textField: {
    height: '40px',
    backgroundColor: '#ffffff'
  },
  textIndent: {
    textIndent: '10px'
  },
  crossIconStyle: {
    marginLeft: '15px',
    marginTop: '10px',
    fontSize: '22px',
    cursor: 'pointer'
  },
  labelStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '500px'
  }
};

let searchDelayTimeout = null;

export class MultipleSelectorForm extends React.Component {
  listRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      filterSelectAll: false,
      selectedItems: Immutable.List(),
      filterValues: Immutable.List(props.filteredData),
      tags: this.props.filterTags,
      tagSearchText: '',
      multiple: false,
      searchText: props.searchTextValue || ''
    };

    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filteredData !== nextProps.filteredData) {
      this.setState({
        filterValues: Immutable.List(nextProps.filteredData),
        filterSelectAll: false,
        // eslint-disable-next-line react/no-access-state-in-setstate
        selectedItems: this.state.selectedItems
      });
    }
    if (this.props.filterTags !== nextProps.filterTags || this.props.tagSearchResult !== nextProps.tagSearchResult) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const tags = this.state.tagSearchText === '' ? nextProps.filterTags : nextProps.tagSearchResult;
      this.setState({
        tags
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      filterValues: Immutable.List()
    });
  }

  handleRequestClose = () => {
    this.setState({ multiple: false });
  }

  handleMultipleItems = (selectedItem) => {
    const item = selectedItem.size > 0 ? selectedItem.get(0).value : undefined;
    this.setState({ tagSearchText: (item && item.name) || '' });
    if (selectedItem.size > 0) {
      this.props.onChooseFilter(selectedItem.get(0).value, false);
      this.props.tagSearch('');
    }
  }

  handleScrollEnd = () => {
    this.props.getNextTags(this.props.tagsPosition);
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };

  handleSelectAll(event, checked) {
    let selectedItems = Immutable.List();
    if (checked) {
      selectedItems = this.state.filterValues;
    }

    this.setState({
      filterSelectAll: checked,
      selectedItems
    });
  }

  handleChecked(evt, selectedItem) {
    let { selectedItems } = this.state;
    if (evt.target.checked) {
      if (!this.props.multiValue) selectedItems = Immutable.List();
      selectedItems = selectedItems.push(selectedItem);
    } else {
      const index = this.state.selectedItems.findIndex(item => (item.id ? item.id : item) ===
        (selectedItem.id ? selectedItem.id : selectedItem));
      selectedItems = selectedItems.splice(index, 1);
    }

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      filterSelectAll: (selectedItems.size === this.state.filterValues.size),
      selectedItems
    });
  }

  handleChange(event, item) {
    let { selectedItems } = this.state;
    selectedItems = Immutable.List();
    selectedItems = selectedItems.push(item);
    this.setState({
      selectedItems
    });
  }

  handleSearch(event) {
    clearTimeout(searchDelayTimeout);
    const { value } = event.currentTarget;
    this.setState({ searchText: value });
    searchDelayTimeout = setTimeout(() => {
      this.props.onSearch({ searchText: value });
    }, 500);
  }

  getValueToDisplay = (item) => {
    let value = '';
    value = item.key ? item.key : item;
    value = item.addlkey ? `${value} (${item.addlkey})` : value;
    return value;
  };

  getSelectedItemIndex = (item) => {
    const index = this.state.selectedItems.findIndex((x) => {
      let selectedIndex = -1;
      if (typeof (x) === 'object' && typeof (item.props) === 'object') {
        selectedIndex = x.props.defaultMessage === item.props.defaultMessage;
      } else {
        selectedIndex = x.id ? x.id === item.id : x === item;
      }
      return selectedIndex;
    });
    return index;
  };

  tagChange = (selectedItem, index) => {
    if (index !== -3) {
      if (selectedItem && selectedItem.name) {
        this.setState({ tagSearchText: selectedItem.name || '' });
        this.props.onChooseFilter(selectedItem, false);
      } else {
        this.setState({ tagSearchText: '' });
        this.props.onChooseFilter(selectedItem.name ? selectedItem : undefined, false);
        this.props.tagSearch('');
      }
    } else {
      this.setState({ multiple: true });
    }
  }

  search = (item) => {
    this.setState({
      tagSearchText: item.searchText
    });
    this.props.tagSearch(item.searchText);
    if (item.searchText === '') {
      this.props.onChooseFilter(undefined, false);
    }
  }

  render () {
    const { handleSubmit, onSave, onCancel, intl, multiValue,
      selectedEntitiesSize, maxCount, showFilterByTag } = this.props;
    const themeColor = this.props.theme.palette.primary.main;

    const { filterValues } = this.state;
    const linkProperty = { target: '_blank', style: { color: themeColor } };

    // eslint-disable-next-line react/no-unstable-nested-components
    const MultiOptionRow = (props) => {
      // eslint-disable-next-line react/prop-types
      const { index } = props;
      const item = filterValues.get(index);
      const checkBoxStyle = { ...styles.multiselectCheckbox, ...props.style };
      const itemCount = this.state.selectedItems.size + selectedEntitiesSize;
      const valueToDisplay = this.getValueToDisplay(item);

      return (
        <FormGroup column={true}>
          <FormControlLabel
            control={<Checkbox
              id={item.key}
              disabled={itemCount >= maxCount && this.getSelectedItemIndex(item) === -1}
              key={item.id || item.key || item}
              style={styles.checkboxStyle}
              checked={this.getSelectedItemIndex(item) !== -1}
              onChange={evt => this.handleChecked(evt, item)}
              color="primary"
            />}
            style={checkBoxStyle}
            label={
              <Linkify properties={linkProperty}>
                <ArrowTooltip title={valueToDisplay}>
                  <div style={style.labelStyle}>
                    {item.icon && <span>{item.icon}</span>}{valueToDisplay}
                  </div>
                </ArrowTooltip>
              </Linkify>
              }
          />
        </FormGroup>);
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const SingleOptionRow = (props) => {
      // eslint-disable-next-line react/prop-types
      const { index } = props;
      const item = filterValues.get(index);
      const radioStyle = Object.assign({}, { fontSize: '16px' }, props.style);

      const valueToDisplay = this.getValueToDisplay(item);
      return (
        <RadioGroup
          name="radio-group"
          value={this.state.selectedItems.toJS()[0] ? this.state.selectedItems.toJS()[0].name ||
            this.state.selectedItems.toJS()[0].key : ''}
          onChange={event => this.handleChange(event, item)}
          style={radioStyle}
        >
          <FormControlLabel
            value={item.name || item.key}
            key={item.key}
            control={<Radio
              style={styles.checkboxStyle}
            />}
            label={
              <ArrowTooltip title={valueToDisplay}><div style={style.labelStyle}>{valueToDisplay}</div></ArrowTooltip>
            }
          />
        </RadioGroup>
      );
    };

    return (
      <form>
        {this.props.customContent && this.props.customContent}
        {this.props.isSearchEnabled &&
          <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
            <TextField
              placeholder={this.props.intl.formatMessage(commonTranslations.search.props)}
              variant="outlined"
              style={style.textField}
              onChange={this.handleSearch}
              value={this.state.searchText}
            />
          </div>
        }
        {showFilterByTag &&
        <div style={{ width: '70%', paddingBottom: '14px', display: 'flex', marginTop: '2px' }}>
          <AutoComplete
            underlineShow={false}
            openOnFocus={true}
            inputType={this.props.inputType}
            maxSearchResults={5}
            selectFromListMenuItem={true}
            fullWidth={true}
            hintText={intl.formatMessage(commonTranslations.filterByTag.props)}
            searchText={this.state.tagSearchText}
            filter={AutoComplete.caseInsensitiveFilter}
            hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
            textFieldStyle={styles.autoComplete}
            dataSource={this.state.tags || []}
            onNewRequest={this.tagChange}
            dataSourceConfig={{ text: 'key', value: 'id' }}
            onSearch={this.search}
          />
          <FontAwesome
            id="cross-button"
            name="times-circle"
            style={style.crossIconStyle}
            onClick={this.tagChange}
          />
        </div>}
        {multiValue && maxCount === undefined &&
        <FormControlLabel
          id="checkbox_form"
          control={<Checkbox
            color="primary"
            id="check-box"
            key="all"
            style={styles.checkboxStyle}
            checked={this.state.filterSelectAll}
            onChange={this.handleSelectAll}
          />}
          style={{ marginBottom: 20 }}
          label={commonTranslations.selectAll}
        />}
        {multiValue && filterValues &&
          <DivWrapper
            id="checkbox-list-wrapper"
            autoHeightMax={200}
            style={{ height: 200, maxHeight: 200 }}
            onScroll={this.handleScroll}
            onScrollStop={this.props.onScrollStop}
          >
            <List
              id="checkbox-list-item"
              style={{ overflow: 'none' }}
              height={200}
              itemCount={filterValues.size}
              itemSize={40}
              ref={this.listRef}
            >
              {MultiOptionRow}
            </List>
          </DivWrapper>}
        {!multiValue && filterValues &&
          <DivWrapper
            autoHeightMax={200}
            style={{ height: 200, maxHeight: 200 }}
            onScroll={this.handleScroll}
            onScrollStop={this.props.onScrollStop}
          >
            <List
              style={{ overflow: 'none' }}
              height={200}
              itemCount={filterValues.size}
              itemSize={40}
              ref={this.listRef}
            >
              {SingleOptionRow}
            </List>
          </DivWrapper>
        }
        <div style={Object.assign({}, styles.multiselectAddButton)}>
          <Button
            id="button"
            onClick={handleSubmit(() => onSave(this.state.selectedItems))}
          >
            {commonTranslations.saveAndClose}
          </Button>
          <Button
            variant="text"
            id="cancel-button"
            style={styles.multiselectFormButton}
            onClick={onCancel}
          >
            {commonTranslations.Cancel}
          </Button>
          {this.state.multiple && <MultipleSelectorDialog
            id="multiple_selector"
            multiValue={false}
            title={commonTranslations.filterByTag}
            open={this.state.multiple}
            filteredData={this.state.tags || []}
            onScrollStop={this.handleScrollEnd}
            onRequestClose={this.handleRequestClose}
            handleMultipleItems={this.handleMultipleItems}
          />}
        </div>
      </form>
    );
  }
}

MultipleSelectorForm.propTypes = {
  multiValue: PropTypes.bool,
  showFilterByTag: PropTypes.bool,
  onSearch: PropTypes.func,
  onSave: PropTypes.func,
  onScrollStop: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputType: PropTypes.string,
  onChooseFilter: PropTypes.func,
  tagSearch: PropTypes.func,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  filterTags: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  selectedEntitiesSize: PropTypes.number,
  maxCount: PropTypes.number,
  filteredData: PropTypes.instanceOf(Immutable.List),
  isSearchEnabled: PropTypes.bool,
  customContent: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  })
};

MultipleSelectorForm.defaultProps = {
  onSearch: e => e,
  onSave: e => e,
  onCancel: e => e,
  maxCount: undefined,
  onScrollStop: e => e,
  selectedEntitiesSize: 0,
  handleSubmit: e => e,
  multiValue: true,
  filteredData: Immutable.List(),
  isSearchEnabled: false,
  showFilterByTag: false,
  inputType: 'text',
  customContent: null,
  onChooseFilter: e => e,
  tagSearch: e => e,
  tagSearchResult: Immutable.List(),
  filterTags: Immutable.List(),
  getNextTags: e => e,
  tagsPosition: 0,
  theme: {}
};


const MultipleSelectorFormWrapper = reduxForm({
  form: 'MultipleSelectorForm' // a unique identifier for this form
})(MultipleSelectorForm);

export default withTheme(injectIntl(MultipleSelectorFormWrapper));
