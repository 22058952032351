import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';

import { selectTranslations } from '@packages/utils/actionTranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { commonTranslations } from '@packages/utils/commontranslations';

import { transformResponseData } from '../../common-utils';
import LinkGroupList from '../../processings/components/link-group-items';

let timeout = null;

const AddGroupDialog = (props) => {
  const {
    open,
    onRequestClose,
    items,
    onSave,
    intl,
    visibleFields,
    isEdit,
    onSearch,
    searchText,
    source
  } = props;

  const [currentSearchText, setCurrentSearchText] = useState('');

  useEffect(() => {
    setCurrentSearchText(searchText);
  }, [searchText]);

  const handleSmartSearch = (event, clearSearch = false) => {
    const searchText = clearSearch ? '' : event.currentTarget.value;
    setCurrentSearchText(searchText);
    clearTimeout(timeout);
    if (clearSearch) {
      onSearch({ searchKey: 'allFields', searchText });
    } else {
      timeout = setTimeout(() => {
        onSearch({ searchKey: 'allFields', searchText });
      }, 1000);
    }
  };

  const requestClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleSave = (value) => {
    if (onSave) {
      onSave(value);
    }
  };

  const links = transformResponseData(items, intl);

  return (
    <div>
      {open && (
        <CommonDialog
          id="add-group-dialog"
          show={open}
          onCancel={requestClose}
          fullWidth={true}
          maxWidth="lg"
          title={selectTranslations('selectGroups')}
          customStyle={{ overflowY: 'hidden', overflowX: 'hidden' }}
        >
          <div style={{ width: '300px' }}>
            <TextField
              autoFocus={true}
              style={{
                marginLeft: '10px',
                paddingLeft: '5px',
                height: '30px',
                backgroundColor: '#ffffff',
                width: '85%',
                border: '1px solid #9b9b9b',
                borderRadius: '3px'
              }}
              value={currentSearchText}
              onChange={handleSmartSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {currentSearchText && currentSearchText !== '' ? (
                      <Clear
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => handleSmartSearch(event, true)}
                      />
                    ) : (
                      <Search />
                    )}
                  </InputAdornment>
                )
              }}
            />
          </div>
          {items.length === 0 && (
            <div style={{ position: 'relative', left: 400, width: 300 }}>
              {commonTranslations.NoData}
            </div>
          )}
          {items.length > 0 && (
            <LinkGroupList
              content={{ links }}
              showCardTitle={false}
              isMultipleGroupSelect={true}
              showSaveButton={true}
              handleSave={handleSave}
              onClose={requestClose}
              isEditable={isEdit}
              isOnlyEdit={isEdit}
              visibleFields={visibleFields}
              tableWidth={1200} // width of dialog(1280) - padding(80)
              containerHeight={400}
              source={source}
            />
          )}
        </CommonDialog>
      )}
    </div>
  );
};

AddGroupDialog.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  onSave: PropTypes.func,
  onRequestClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool,
  visibleFields: PropTypes.arrayOf(PropTypes.string),
  isEdit: PropTypes.bool,
  onSearch: PropTypes.func,
  searchText: PropTypes.string,
  source: PropTypes.string
};

AddGroupDialog.defaultProps = {
  onSave: (e) => e,
  onRequestClose: (e) => e,
  items: [],
  open: false,
  visibleFields: [],
  isEdit: false,
  onSearch: (e) => e,
  searchText: '',
  source: ''
};

export default injectIntl(AddGroupDialog);
