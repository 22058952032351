import { singularTerms as uppercaseSingular } from '@packages/utils/uppercaseTranslations';
import Immutable from 'immutable';
import registry from 'app-registry';

export const getCurrentBulkItems = (value, items) => {
  let newItem = items;
  const index = items.findIndex((item) => item.id === value.id);
  if (index !== -1) {
    newItem = items.splice(index, 1);
  } else {
    newItem = items.push({ ...value });
  }
  return newItem;
};

export const checkForBulkItems = (items, bulkItems) => {
  const bulkItemIds = bulkItems.map((item) => item.id);
  const newItems = [];
  items.forEach((item) => {
    if (bulkItemIds.includes(item.id)) {
      newItems.push({ ...item, selected: true });
    } else if (item.selected) {
      newItems.push({ ...item, selected: false });
    } else {
      newItems.push(item);
    }
  });
  return Immutable.List(newItems);
};

export const dsrJurisdictions = [
  {
    label: uppercaseSingular('gdpr'),
    key: 'GDPR',
    value: 'GDPR',
    isAvailable: true
  },
  {
    label: uppercaseSingular('ccpa'),
    key: 'CCPA',
    value: 'CCPA',
    isAvailable: true
  }
];

export const getDsrJurisdictions = () => {
  const store = registry.exists('store') ? registry.get('store') : null;
  const userPermissions = store
    ? store.getState().login.get('loggedUser').get('userPermissions').toJS()
    : Immutable.Map();

  return dsrJurisdictions.map((item) => ({
    ...item,
    isAvailable: !!userPermissions[`${item.value.toLowerCase()}DsrAccess`]
  }));
};
