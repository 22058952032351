import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  dataImporters: Immutable.List(),
  dataExporters: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'IMPORTERS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('dataImporters', defaultState.get('dataImporters'));
    case 'IMPORTERS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('dataImporters', state.get('dataImporters').concat(Immutable.List(action.items)));
    case 'IMPORTERS:LIST:FETCH:FAIL':
      return state.set('isFetching', false);
    case 'EXPORTERS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('dataExporters', defaultState.get('dataExporters'));
    case 'EXPORTERS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('dataExporters', state.get('dataExporters').concat(Immutable.List(action.items)));
    case 'EXPORTERS:LIST:FETCH:FAIL':
      return state.set('isFetching', false);
    default:
      return state;
  }
};
