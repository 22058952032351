/* eslint-disable */
import cx from 'classnames';
import React, { Component } from 'react';

export default class UITreeNode extends Component {

  handleCollapse = (e) => {
    e.stopPropagation();
    const nodeId = this.props.index.id;

    if (this.props.onCollapse) {
      this.props.onCollapse(nodeId);
    }
  };

  handleMouseDown = (e) => {
    const nodeId = this.props.index.id;
    const dom = this.refs.inner;

    if (this.props.onDragStart) {
      this.props.onDragStart(nodeId, dom, e);
    }
  };

  renderCollapse = () => {
    const { index } = this.props;

    if (index.children && index.children.length) {
      const { collapsed } = index.node;

      return (
        <span
          className={cx('collapse', collapsed ? 'caret-right' : 'caret-down')}
          onMouseDown={e => e.stopPropagation()}
          onClick={this.handleCollapse}
        />
      );
    }

    return null;
  };

  renderChildren = () => {
    const { index, tree, dragging, showRootNode = false } = this.props;

    if (index.children && index.children.length) {
      const childrenStyles = {
        paddingLeft: this.props.paddingLeft
      };

      // If node collapsed, hide children
      if (index.node.collapsed) childrenStyles.display = 'none';

      // If showRootNode is false, move tree to left.
      if (index.top === 1 && !showRootNode) {
        childrenStyles.paddingLeft = '0px!important';
      }

      return (
        <div className="children" style={childrenStyles}>
          {index.children.map((child) => {
            const childIndex = tree.getIndex(child);

            return (
              <UITreeNode
                tree={tree}
                index={childIndex}
                key={childIndex.id}
                dragging={dragging}
                paddingLeft={this.props.paddingLeft}
                onCollapse={this.props.onCollapse}
                onDragStart={this.props.onDragStart}
              />
            );
          })}
        </div>
      );
    }

    return null;
  };

  render() {
    const { tree, index, dragging, showRootNode = false } = this.props;
    const { node, parent } = index;
    const parentNode = parent ? tree.getIndex(parent).node : undefined;
    let styles = {};

    if (index.top === 1 && (!showRootNode || !node.id)) {
      styles = { display: 'none' };
    }

    return (
      <div
        className={cx('m-node', {
          placeholder: index.id === dragging
        })}
      >
        <div className="inner" style={styles} ref="inner" onMouseDown={this.handleMouseDown}>
          {this.renderCollapse()}
          {tree.renderNode(node, parentNode)}
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}

// module.exports = UITreeNode;
