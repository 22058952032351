import Immutable from 'immutable';

const defaultState = Immutable.Map({
  tokenExpired: false,
  loggedUser: null,
  isVerifyAuth: false,
  errors: Immutable.Map(),
  isOnlyPartner: false,
  promptTokenRefresh: undefined,
  enableSSO: false,
  enableDSR: false,
  proceedWithRender: false,
  pricingPlanDetails: Immutable.Map(),
  parentTenantId: undefined,
  isHoldingTenant: false,
  isFetching: true,
  tenantLocale: 'en',
  accessGroups: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN:VERIFY':
      return state
        .set('isVerifyAuth', true)
        .set('promptTokenRefresh', defaultState.get('promptTokenRefresh'));
    case 'LOGIN:LOGIN_LOADER_INIT':
      return state.set('isFetching', true);
    case 'LOGIN:LOGIN_LOADER_STOP':
      return state.set('isFetching', false);
    case 'LOGIN:VERIFY:SUCCESS': {
      const { roles, parentTenantId, tenantLocale } = action.user;
      return state
        .set('isVerifyAuth', false)
        .set('loggedUser', Immutable.fromJS(action.user))
        .set('isOnlyPartner', checkIfOnlyPartner(roles))
        .set('parentTenantId', parentTenantId)
        .set('tenantLocale', tenantLocale);
    }
    case 'LOGIN:VERIFY:FAIL':
      return state.set('isVerifyAuth', false).set('loggedUser', null);

    case 'LOGIN:DO_LOGIN:INIT':
      return state.set('errors', Immutable.Map());

    case 'LOGIN:DO_LOGIN':
      return state.set('errors', Immutable.Map());

    case 'LOGIN:DO_LOGIN:SUCCESS': {
      const { roles, tenantLocale } = action.user;
      return state
        .set('loginMode', '')
        .set('loggedUser', Immutable.fromJS(action.user))
        .set('isOnlyPartner', checkIfOnlyPartner(roles))
        .set('tenantLocale', tenantLocale);
    }
    case 'LOGIN:DO_LOGIN:FAIL':
      return state.set('loggedUser', null).set(
        'errors',
        Immutable.Map({
          username: action.username,
          password: action.password,
          loginError: action.loginError
        })
      );

    case 'LOGIN:FORGOT_PASSWORD:INIT':
      return state.set('tokenExpired', false).set('errors', Immutable.Map());

    case 'LOGIN:FORGOT_PASSWORD:SUCCESS':
      return state
        .set('loginMode', 'forgotPassword')
        .set('errors', Immutable.Map());

    case 'LOGIN:FORGOT_PASSWORD:FAIL':
      return state.set(
        'errors',
        Immutable.Map({
          errorMessage: action.errorMessage
        })
      );

    case 'FORGOT_PASSWORD:TOKEN:VALIDATE:SUCCESS':
      return state.set('tokenExpired', false);

    case 'FORGOT_PASSWORD:TOKEN:VALIDATE:FAIL':
      return state.set('tokenExpired', true);

    case 'LOGIN:SET_LOGGED_USER':
      return state.set('loggedUser', Immutable.fromJS(action.user));

    case 'LOGIN:LOGIN_REDIRECT_SET':
      return state.set('redirectAfterLogin', action.redirect);

    case 'LOGIN:LOGIN_REDIRECT_USE':
      return state.set('redirectAfterLogin', null);
    case 'LOGIN:TOKEN:VALIDATE:INIT':
      return state.set(
        'promptTokenRefresh',
        defaultState.get('promptTokenRefresh')
      );

    case 'LOGIN:TOKEN:VALIDATE:REFRESH':
      return state.set('promptTokenRefresh', true);
    case 'SSO_DSR:ENABLED:CHECK:SUCCESS':
      return state
        .set('enableSSO', action.enableSSO)
        .set('enableDSR', action.enableDSR);
    case 'PROCEED:WITH:RENDER':
      return state.set('proceedWithRender', action.proceedWithRender);
    case 'TENANT:PRICING:PLAN:SUCCESS':
      return state.set('pricingPlanDetails', Immutable.Map(action.data));
    case 'TENANT:PRICING:PLAN:FAIL':
      return state.set('pricingPlanDetails', action.data).set(
        'errors',
        Immutable.Map({
          errorMessage: action.errorMessage
        })
      );
    case 'SET:HOLDING:TENANT':
      return state.set('isHoldingTenant', action.isHoldingTenant);
    case 'ACCESS_GROUP:FETCH:SUCCESS':
      return state.set('accessGroups', action.data);
    default:
      return state;
  }
};

const checkIfOnlyPartner = (roles) => {
  const isOnlyPartner =
    roles.indexOf('PartnerAdministrator') !== -1 &&
    roles.indexOf('Administrator') === -1 &&
    roles.indexOf('ChiefPrivacyOfficer') === -1 &&
    roles.indexOf('BusinessUser') === -1 &&
    roles.indexOf('PrivacyOfficer') === -1;
  return isOnlyPartner;
};
