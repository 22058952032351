import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchSSOSettings() {
  yield put({ type: 'SSOSETTINGS:FETCH' });

  try {
    const response = yield registry.get('request')
      .get('/v1/settings/sso', null);

    switch (response.status) {
      case 200: {
        const data = response.body;
        const modifiedData = transformData(data);
        yield put({ type: 'SSOSETTINGS:FETCH:SUCCESS', data: modifiedData });
        yield put(initialize('SSOSettings', modifiedData));
      }
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({ type: 'SSOSETTINGS:FETCH:FAIL', error: err.message });
  }
}

export function* updateSSOSettings(action) {
  try {
    const { data } = action;
    const ssoSettingState = yield select(state => state.ssosettings);
    const modifiedValue = {
      status: data.status,
      configuration: {
        automaticAccountCreation: data.automaticAccountCreation,
        automaticRedirect: data.type === 'sp',
        organisation: {
          id: (data.organisation && typeof data.organisation === 'object') ?
            data.organisation.id : data.organisation
        },
        idpUrl: data.idpUrl,
        metaData: data.metaData,
        authRequestSigned: data.authRequestSigned
      }
    };
    if (modifiedValue.configuration.organisation.id === '' || !modifiedValue.configuration.automaticAccountCreation) {
      modifiedValue.configuration.organisation = null;
    }
    const modifiedData = {
      _rev: ssoSettingState.get('ssoSettings').get('_rev'),
      id: ssoSettingState.get('ssoSettings').get('id'),
      value: modifiedValue
    };
    const response = yield registry.get('request')
      .put('/v1/settings/sso', modifiedData);

    switch (response.status) {
      case 200: {
        const updatedData = response.body;
        const modifiedUpdatedData = transformData(updatedData);
        yield put({ type: 'SSOSETTINGS:UPDATE:SUCCESS', data: modifiedUpdatedData });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.ssoSettingsUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    yield put({ type: 'SSOSETTINGS:UPDATE:FAIL', error: err.message });
  }
}

export function* downloadMetadata() {
  const request = registry.get('request');
  try {
    const response = yield request.getDocument(`/v1/sso/metadata`);
    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.metaDataDownloadSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
const transformData = (item) => {
  let type = '';
  if (item.value.configuration) {
    type = item.value.configuration.automaticRedirect ? 'sp' : 'idp';
  }
  return ({
    ...item,
    ...item.value.configuration,
    type,
    status: item.value.status,
    authRequestSigned: item.value.configuration && item.value.configuration.authRequestSigned,
    organisation: item.value.configuration && item.value.configuration.organisation ?
      item.value.configuration.organisation : ''
  });
};
