import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createCustomField,
  fetchCustomFields,
  fetchCustomFieldUsage,
  getCustomField,
  deleteCustomField,
  customRecordWorker,
  validateScoreCalculator,
  itemListWorker,
  itemListOptionWorker
} from './sagas';

import {
  resetAndfetchRecordLayoutList,
  fetchRecordTypes,
  resetAndfetchDynamicRecordLayoutList,
  deleteDynamicRecordLayout,
  fetchDynamicRecordLayoutList,
  getCustomRecordTypes,
  upsertCustomDynamicLayout
} from './components/list/sagas';

import {
  fetchCustomFieldSelector,
  resetAndfetchCustomFieldSelector
} from './components/customFieldSelector/sagas';

import {
  saveChapter,
  getFields,
  fetchLayoutChapter,
  getFilteredFields,
  fetchLayoutRules,
  fetchRule,
  fetchLayoutFieldList,
  fetchActionFieldList,
  saveRule,
  updateRule,
  deleteRule
} from './layout-editor/sagas';
import {
  fetchItemListSelector,
  resetAndfetchItemListSelector
} from './components/item-list-selector/sagas';

export default [
  [takeEvery, 'CUSTOM:FIELD:UPSERT:DATA', createCustomField],
  [takeEvery, 'CUSTOM:FIELD:LIST:FETCH', fetchCustomFields],
  [takeEvery, 'CUSTOM:ITEM_LIST', itemListWorker],
  [takeEvery, 'CUSTOM:ITEM_LIST_OPTION', itemListOptionWorker],
  [takeEvery, 'CUSTOM:FIELD:ITEM:FETCH', getCustomField],
  [takeEvery, 'CUSTOM:FIELD:ITEM:DELETE', deleteCustomField],
  [takeEvery, 'CUSTOM:FIELD:USAGE:FETCH', fetchCustomFieldUsage],
  [takeEvery, 'CUSTOM:FIELD:LIST:NEXT_FETCH', fetchCustomFields],
  [
    takeEvery,
    'CUSTOM_RECORD_LAYOUTS:LIST:REQUEST:INIT',
    resetAndfetchRecordLayoutList
  ],
  [takeEvery, 'CUSTOM_RECORD_LAYOUTS:LIST:NEXT_FETCH', fetchRecordTypes],
  [
    takeEvery,
    'CUSTOM_FIELD_SELECTOR:LIST:REQUEST:INIT',
    resetAndfetchCustomFieldSelector
  ],
  [
    takeEvery,
    'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:REQUEST:INIT',
    resetAndfetchDynamicRecordLayoutList
  ],
  [
    takeEvery,
    'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:NEXT_FETCH',
    fetchDynamicRecordLayoutList
  ],
  [
    takeEvery,
    'CUSTOM_DYNAMIC_RECORD_LAYOUTS:ITEM:DELETE',
    deleteDynamicRecordLayout
  ],
  [
    takeEvery,
    'CUSTOM_DYNAMIC_RECORD_TYPE:LIST:FETCH:INIT',
    getCustomRecordTypes
  ],
  [
    takeEvery,
    'CUSTOM:DYNAMIC_RECORD_LAYOUT:DETAIL:UPSERT',
    upsertCustomDynamicLayout
  ],
  [takeEvery, 'CUSTOM_RECORD_TYPES', customRecordWorker],
  [takeEvery, 'PICK_FIELDS:FETCH:INIT', getFields],
  [takeLatest, 'PICK_FIELDS:FETCH', getFilteredFields],
  [
    takeEvery,
    'CUSTOM_FIELD_SELECTOR:LIST:NEXT_FETCH',
    fetchCustomFieldSelector
  ],
  [takeEvery, 'LAYOUT:EDITOR:SAVE:CHAPTER', saveChapter],
  [takeEvery, 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS', fetchLayoutChapter],

  [takeEvery, 'CUSTOM:CALCULATOR:VALIDATE', validateScoreCalculator],

  [
    takeEvery,
    'ITEM_LIST_SELECTOR:LIST:REQUEST:INIT',
    resetAndfetchItemListSelector
  ],
  [takeEvery, 'ITEM_LIST_SELECTOR:LIST:NEXT_FETCH', fetchItemListSelector],

  [takeEvery, 'LAYOUT:EDITOR:RULES:REQUEST:INIT', fetchLayoutRules],
  [takeEvery, 'LAYOUT:EDITOR:RULE:ITEM:REQUEST:INIT', fetchRule],
  [takeEvery, 'LAYOUT:TRIGGER:FIELDS:REQUEST:INIT', fetchLayoutFieldList],
  [takeEvery, 'LAYOUT:ACTION:FIELDS:REQUEST:INIT', fetchActionFieldList],
  [takeEvery, 'LAYOUT:RULE:SAVE:INIT', saveRule],
  [takeEvery, 'LAYOUT:RULE:UPDATE:INIT', updateRule],
  [takeEvery, 'LAYOUT:EDITOR:RULE:DELETE', deleteRule]
];
