import React from 'react';
import PropTypes from 'prop-types';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import {
  addMasterTypeTranslations,
  editMasterTypeTranslations
} from '../../../masterDataTranslations';
import DataRecipientCategory from '.';

export const DataRecipientCategoryDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.onSave) {
      const modifiedItem = {
        ...selectedItem,
        key:
          selectedItem.countries && selectedItem.countries.length > 0
            ? `${selectedItem.name}${selectedItem.countries.map(
              (value) => ` (${value})`
            )}`
            : `${selectedItem.name}`
      };
      props.onSave({ value: modifiedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };
  return (
    <CommonDialog
      id="data-recipient-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={
        props.isEdit
          ? editMasterTypeTranslations('dataRecipientCategories')
          : addMasterTypeTranslations('dataRecipientCategories')
      }
    >
      <DataRecipientCategory
        id="data-recipient-categories"
        isEdit={props.isEdit}
        isUsed={props.isUsed}
        usage={props.usage}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        isNewItem={props.isNewItem}
        source={props.source}
        isTagEdit={props.isTagEdit}
        hideTags={props.hideTags}
      />
    </CommonDialog>
  );
};

DataRecipientCategoryDialog.propTypes = {
  isNewItem: PropTypes.bool,
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  onRequestClose: PropTypes.func,
  dataItemId: PropTypes.string,
  source: PropTypes.string,
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  inputValue: PropTypes.shape({}),
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

DataRecipientCategoryDialog.defaultProps = {
  isNewItem: false,
  open: false,
  isEdit: false,
  isUsed: false,
  onSave: (e) => e,
  onRequestClose: (e) => e,
  source: '',
  inputValue: {},
  usage: {},
  dataItemId: '',
  isTagEdit: false,
  hideTags: false
};

export default DataRecipientCategoryDialog;
