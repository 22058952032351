import { put, select } from 'redux-saga/effects';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getTransformedDiffObject, transformDefaultResponseData } from '../../saga-utils';

export default function* initializeTiaDiff() {
  const { tiaId } = getParameterValuesFromHash('/tia/:tiaId/compare');
  yield put({ type: 'TIA:DIFF:FETCH', tiaId });

  try {
    const userState = yield select(state => state.user);
    const currentUser = userState ? userState.get('profile') : null;

    // Find json diff between the versions
    const data = yield getTransformedDiffObject('tias', tiaId);
    const transformedData = yield transformDefaultResponseData(data, currentUser);
    yield put({ type: 'TIA:DIFF:FETCH:SUCCESS', data: transformedData });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'TIA:DIFF:FETCH:FAIL', error: err.message });
  }
}
