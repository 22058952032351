import React from 'react';

import { FormattedMessage } from 'react-intl';

export default {
  equals: (
    <FormattedMessage
      id="SearchOperator.equals"
      description="Exact match"
      defaultMessage="Exact match"
    />
  ),
  contains: (
    <FormattedMessage
      id="SearchOperator.contains"
      description="Word match"
      defaultMessage="Word match"
    />
  ),
  wildcard: (
    <FormattedMessage
      id="SearchOperator.wildcard"
      description="Wildcard match"
      defaultMessage="Wildcard match"
    />
  ),
  gt: (
    <FormattedMessage
      id="SearchOperator.gt"
      description="Greater than"
      defaultMessage="Greater than"
    />
  ),
  lt: (
    <FormattedMessage
      id="SearchOperator.lt"
      description="Less than"
      defaultMessage="Less than"
    />
  ),
  range: (
    <FormattedMessage
      id="SearchOperator.range"
      description="Range"
      defaultMessage="Range"
    />
  ),
  exists: (
    <FormattedMessage
      id="SearchOperator.exists"
      description="Exists"
      defaultMessage="Exists"
    />
  ),
  notExists: (
    <FormattedMessage
      id="SearchOperator.notExists"
      description="Not exists"
      defaultMessage="Not exists"
    />
  )
};