import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { injectIntl } from 'react-intl';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

export const SimpleItemSelector = (props) => {
  const [multiple, setMultiple] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleMultipleSelect = () => {
    setMultiple(true);
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setSelectedIndex(-1);
  };

  // Handler to remove item
  const handleRemoveItem = (selectedIndexValue) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndexValue !== -1) {
      selectedItems.splice(selectedIndexValue, 1);
    }
    updateItems(selectedItems);
  };

  const handleMultipleItems = (selectedItems) => {
    const items = props.fields.getAll() || [];
    const selectedValues = [...items, ...selectedItems];
    updateItems(selectedValues);
  };

  const handleSelectedItem = (selectedItem) => {
    handleEditItem(selectedItem, selectedIndex);
  };

  const handleEditItem = (selectedItem, selectedIndexValue) => {
    const selectedItems = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndexValue === -1) {
      selectedItems.push(selectedItem);
    } else {
      selectedItems.splice(selectedIndexValue, 1, selectedItem);
    }

    updateItems(selectedItems);
  };

  const filterSimpleItems = (selectedItems) => {
    const filteredData =
      selectedItems && selectedItems.length > 0
        ? props.items.filter((item) => {
          const index = selectedItems.findIndex((selectedItem) => {
            const selectedItemValue = selectedItem.value || selectedItem;
            return item.id
              ? item.id === selectedItemValue.id
              : item.data === selectedItemValue.data;
          });
          return index === -1;
        })
        : props.items;
    const modifiedValue = filteredData?.map((item) => ({
      ...item,
      key: item.key.props ? props.intl.formatMessage(item.key.props) : item.key,
      data: item.data
    }));
    return modifiedValue;
  };

  const updateItems = (selectedItems) => {
    props.updateData(selectedItems);
  };

  const selectedItems = props.fields.getAll();
  const selectedItemList = useMemo(
    () => filterSimpleItems(selectedItems),
    [selectedItems]
  );

  return (
    <div>
      {props.showItemList && (
        <ItemList
          id="item-list"
          {...props}
          isEditable={false}
          isNoteEditable={false}
          selectedItems={selectedItems}
          handleEditItem={handleEditItem}
          handleRemoveItem={handleRemoveItem}
          type={props.label}
        />
      )}
      {!props.disabled && (
        <ItemSelector
          id="item-selector"
          multiValue={props.multiValue}
          createNewMenuItem={props.createNewMenuItem || false}
          selectFromListMenuItem={props.selectFromListMenuItem || false}
          dataSource={selectedItemList}
          dataSourceConfig={
            props.dataSourceConfig || { text: 'key', value: 'data' }
          }
          hintTextLabel={props.hintTextLabel}
          handleSelectedItem={handleSelectedItem}
          handleMultipleSelect={handleMultipleSelect}
        />
      )}
      {multiple && (
        <MultipleSelectorDialog
          title={props.dialogHeaderLabel}
          open={multiple}
          filteredData={selectedItemList}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
        />
      )}
    </div>
  );
};

SimpleItemSelector.propTypes = {
  dialogHeaderLabel: PropTypes.string,
  multiValue: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  label: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  }),
  hintTextLabel: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  createNewMenuItem: PropTypes.bool,
  updateData: PropTypes.func,
  showItemList: PropTypes.bool
};

SimpleItemSelector.defaultProps = {
  multiValue: true,
  selectFromListMenuItem: false,
  disabled: false,
  label: null,
  dataSourceConfig: null,
  dialogHeaderLabel: null,
  hintTextLabel: null,
  createNewMenuItem: false,
  updateData: (e) => e,
  showItemList: true
};

export default injectIntl(SimpleItemSelector);
