import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown, MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import { getURLWithParams, updateDataItems } from '../saga-utils';

export function* resetAndfetchPersonalDataCategories(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:REQUEST:INIT' });
  yield fetchPersonalDataCategories(action);
}

export function* fetchPersonalDataCategories(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:FETCH' });
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT } = action;
  try {
    let filterParams = {};
    let unused = false;
    let searchParameters = {};
    let searchTextObj = {};
    let filteredOn = Immutable.Map();
    if (action.isPersistedData) {
      const personalDataCategoriesState = yield select(state => state.environment.personalDataCategories);
      if (personalDataCategoriesState) {
        filterParams = personalDataCategoriesState.get('filterParams').toJS();
        unused = personalDataCategoriesState.get('unused');
        searchTextObj = personalDataCategoriesState.get('searchText').toJS();
        searchParameters = searchTextObj ? { searchKey: 'name', searchText: searchTextObj.name } : {};
        filteredOn = personalDataCategoriesState.get('filteredOn');
      }
    }

    const { sortOn, sortOrder } = filterParams;

    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        position, searchParameters, 'personaldatacategories', rowCount, undefined, unused), null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:FETCH:SUCCESS',
          items: modifiedItems,
          personalItemCategory: action.personalItemCategory
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:FETCH:FAIL', error: err.message });
    yield put({ type: 'RECORDS:PERSONAL_ITEM_CATEGORY:LIST:FETCH:FAIL', error: err.message });

    registry.get('logger').error(err);
  }
}

export function* filterPersonalDataCategories(action) {
  const filterParams = action.filterParams || {};
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:FILTER', filterParams });
  yield fetchPersonalDataCategories(action);
}

export function* searchPersonalDataCategories(action) {
  let { searchParams } = action;
  const rowCount = MASTERDATA_SEARCH_LIMIT;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  let filterParams = {};
  const filteredOn = Immutable.Map();
  let unused = false;
  const personalDataCategoriesState = yield select(state => state.environment.personalDataCategories);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = personalDataCategoriesState ? personalDataCategoriesState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }
  if (personalDataCategoriesState) {
    unused = personalDataCategoriesState.get('unused');
    filterParams = personalDataCategoriesState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;

  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParams, 'personaldatacategories', rowCount, undefined, unused), null, {});
    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* upsertPersonalDataCategories(action) {
  const { isEdit, data, source } = action;
  const request = registry.get('request');
  let response;
  try {
    const requestData = isEdit ? { name: data.name } : { ...data };
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(`/v1/masterdata/personaldatacategories`, requestData);
    } else {
      response = yield request.put(`/v1/masterdata/personaldatacategories/${data.id}?withJob=false`, data);
    }
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationMessages[isEdit],
            type: 'success'
          }
        });

        const modifiedItem = {
          ...response.body,
          key: `${response.body.name}`
        };
        if (isEdit) {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:UPSERT:SUCCESS',
            personalItemCategory: modifiedItem
          });

          yield updatePersonalDataCategoryItems({
            type: 'edit',
            data: Object.assign({}, data, modifiedItem),
            source
          });
        } else {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:UPSERT:SUCCESS',
            personalItemCategory: modifiedItem,
            position: 0
          });

          yield fetchPersonalDataCategories(
            { personalItemCategory: modifiedItem, isPersistedData: true, rowCount: MASTERDATA_FETCH_LIMIT }
          );
        }
        break;
      }
      case 409: yield put({
        type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:UPSERT:FAIL',
        error: response.body.msg
      });
        break;
      case 412: {
        yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:ITEM:FETCH:FAIL', error: response.body.msg });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:UPSERT:FAIL', error: err.message });
  }
}

export function* fetchPersonalDataCategory(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/masterdata/personaldatacategories/${action.id}`, null);
      switch (response.status) {
        case 200: {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:ITEM:FETCH:SUCCESS',
            personalItemCategoryItem: response.body
          });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:ITEM:FETCH:FAIL', error: err.message });
    }
  }
}

export function* deletePersonalDataCategories(action) {
  const { dataItemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/masterdata/personaldatacategories/${dataItemId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('personalDataCategory'),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updatePersonalDataCategoryItems({
          type: 'delete',
          personalDataCategoryId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

function* updatePersonalDataCategoryItems(action) {
  const personalDataCategoriesState = yield select(state => state.environment.personalDataCategories);
  let position = null;
  let items = personalDataCategoriesState ? personalDataCategoriesState
    .get('items') : Immutable.List();
  let newItems = personalDataCategoriesState ? personalDataCategoriesState
    .get('newItems') : Immutable.List();
  const { type, data, personalDataCategoryId, source } = action;
  if (type === 'delete') {
    items = updateDataItems(items, type, personalDataCategoryId);
    newItems = updateDataItems(newItems, type, personalDataCategoryId);

    position = personalDataCategoriesState.get('position') - 1;
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = transformPersonalDataItem(data);
    items = updateDataItems(items, type, data.id, source, modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, source, modifiedItem);
  }

  yield put({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:UPDATE:SUCCESS', items, newItems, position });
}

const transformPersonalDataItem = item => ({
  ...item,
  name: item.name,
  key: `${item.name}`
});
const notificationMessages = {
  true: notificationtranslations.personalDataCategoryUpdateSuccess,
  false: notificationtranslations.personalDataCategoryCreated
};
