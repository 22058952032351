import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  tenants: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  isFetching: false,
  items: Immutable.List(),
  position: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TENANT:UNASSOCIATED:LIST:REQUEST':
      return state.set('tenants', defaultState.get('tenants'))
        .set('error', defaultState.get('error'));
    case 'TENANT:UNASSOCIATED:LIST:REQUEST:INIT':
      return state.set('tenants', defaultState.get('tenants'))
        .set('position', 0);
    case 'TENANT:UNASSOCIATED:LIST:REQUEST:SUCCESS':
      return state.set('tenants', state.get('tenants').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'TENANT:UNASSOCIATED:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'TENANT:UNASSOCIATED:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'TENANT:UNASSOCIATED:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'TENANT:UNASSOCIATED:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
