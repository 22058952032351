import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from '@packages/ui/styles';

export const GenericLoader = (props) => {
  const [showHideLoader, setShowHideLoader] = useState(props.showHideLoader);

  useEffect(() => {
    setShowHideLoader(props.showHideLoader || props.show);
  }, [props.showHideLoader]);

  const loaderContentStyle = { textAlign: 'center', fontSize: '18px' };

  return (
    <div>
      {showHideLoader && props.content && (
        <Dialog
          id="loader_component"
          fullWidth={true}
          maxWidth="sm"
          open={showHideLoader}
        >
          <DialogContent style={loaderContentStyle}>
            <Loader loaded={!showHideLoader} className="loaderClass" />
            {props.content && (
              <div style={{ marginTop: '55px' }}>
                {props.content}
                {props.handleAbort && (
                  <div>
                    <FormattedMessage
                      id="Download.abort.message"
                      defaultMessage="You may <htmlsnippet>abort</htmlsnippet> the download instead."
                      values={{
                        // eslint-disable-next-line react/no-unstable-nested-components
                        htmlsnippet: (text) => (
                          <strong
                            style={{ color: 'red', cursor: 'pointer' }}
                            role="presentation"
                            onClick={props.handleAbort}
                          >
                            {text}
                          </strong>
                        )
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
      {showHideLoader && !props.content && (
        <div style={styles.loadingIcon}>
          <Loader loaded={!showHideLoader} />
        </div>
      )}
    </div>
  );
};

GenericLoader.propTypes = {
  showHideLoader: PropTypes.bool,
  content: PropTypes.node,
  handleAbort: PropTypes.func
};

GenericLoader.defaultProps = {
  showHideLoader: false,
  content: undefined,
  handleAbort: undefined
};

export default GenericLoader;
