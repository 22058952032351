import Immutable from 'immutable';

import { getQueryStrings } from '@packages/utils/query-parameters';
import { getTransformedFilter } from '@packages/utils/common-utils';
import { sectionHeaderTranslations } from '@packages/features/environment/masterDataTranslations';

import filterMenus from '../defaults';
import { filterMenuItems, getEnvironmentFilterMenu } from '../utils';

const getPreviousLocation = () => {
  const hashURL = window.location.hash;
  const urlParams = getQueryStrings(hashURL);
  if (urlParams.from === 'overview') {
    return 'overview';
  }
  return undefined;
};

const defaultState = Immutable.fromJS({
  currentFilter: '',
  prevLocation: undefined,
  filterMenus: [],
  menuItems: [],
  documentLayoutsData: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ENVIRONMENT:FILTERS:INIT': {
      const { userPermissions, userHierarchy, isGlobal } = action;
      const environmentFilterMenuItems = getEnvironmentFilterMenu(
        filterMenus,
        isGlobal
      );
      // Filter menu items based on role/subrole
      const menuItems = filterMenuItems(
        userPermissions,
        userHierarchy,
        environmentFilterMenuItems
      );
      return state
        .set(
          'currentFilter',
          state.get('currentFilter') ||
            (menuItems[0]?.subMenu && menuItems[0].subMenu[0].key) ||
            menuItems[0].key
        )
        .set('menuItems', menuItems)
        .set('prevLocation', getPreviousLocation());
    }
    case 'ENVIRONMENT:FILTERS:CHANGE': {
      window.history.replaceState({}, document.title, '/#/environment');
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }
    case 'ENVIRONMENT:RESET:FILTERS': {
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }

    case 'ENVIRONMENT:CUSTOM_SIMPLE_DATA:FILTERS:FETCH:SUCCESS': {
      const modifiedFilter = state.get('menuItems').concat(action.items);
      return state
        .set('menuItems', Immutable.List(modifiedFilter))
        .set('customSimpleData', action.items);
    }
    case 'ENVIRONMENT:STAKEHOLDERS:FILTERS:FETCH:SUCCESS': {
      const modifiedFilter = state
        .get('menuItems')
        .map((item) =>
          item.key === 'stakeholder'
            ? { ...item, subMenu: [...item.subMenu, ...action.items.subMenu] }
            : item
        );
      return state
        .set('menuItems', Immutable.List(modifiedFilter))
        .set('stakeholders', action.items);
    }
    case 'ENVIRONMENT:FILTER:CHANGE:CUSTOM':
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    case 'ENVIRONMENT:DOCUMENT:FILTERS:INIT': {
      const { data, locale, tenantLocale } = action;
      const documentFilter = getTransformedFilter(
        data,
        locale,
        tenantLocale,
        'documents'
      );

      const updatedMenuItems = state.get('menuItems').map((item) =>
        item.key === 'documents'
          ? {
            ...item,
            defaultFilter: documentFilter.defaultFilter,
            subMenu: [
              {
                ...documentFilter,
                label: sectionHeaderTranslations.documentRecords
              },
              ...item.subMenu.slice(1)
            ]
          }
          : item
      );

      return state
        .set('menuItems', updatedMenuItems)
        .set('documentLayoutsData', documentFilter);
    }

    default:
      return state;
  }
};

// const getSearchedFilter = (menuItems, currentFilter) => {
//   let selectedFilter;
//   for (let i = 0; i < menuItems.length; i += 1) {
//     if (menuItems[i].key === currentFilter) {
//       selectedFilter = menuItems[i].key;
//       break;
//     } else {
//       const selectedSubMenu = menuItems[i].subMenu.find(item => item.key === currentFilter);
//       if (selectedSubMenu) {
//         selectedFilter = selectedSubMenu;
//         break;
//       }
//     }
//   }
//   return selectedFilter;
// };
