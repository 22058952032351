import { withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TetantOrTagSelector from './tenantOrTagSelector';

const mapStateToProps = (state) => ({
  tenantsAndTags: state.home.get('tenantsAndTags')
});

const mapDispatchToProps = (dispatch) => ({
  getTenantsAndTags: () => {
    dispatch({ type: 'GLOBAL:TENANTS_TAGS:FETCH' });
  }
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(TetantOrTagSelector)
);
