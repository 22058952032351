import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { commonTranslations } from '@packages/utils/commontranslations';

const CustomDialog = (props) => {
  const {
    show,
    proceed,
    cancel,
    content,
    title,
    isDelete,
    hideConfirmation,
    contentStyle
  } = props;

  const deleteActions = [
    <Button
      id="delete-button"
      key="delete-report"
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      onClick={proceed}
    >
      <div>{commonTranslations.Ok}</div>
    </Button>,
    <Button
      variant="text"
      id="cancel-button"
      key="cancel-report"
      onClick={cancel}
    >
      {commonTranslations.Cancel}
    </Button>
  ];

  const viewAction = [
    <Button
      key="delete-report"
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      onClick={proceed}
    >
      <div>{commonTranslations.Ok}</div>
    </Button>
  ];

  return (
    <Dialog
      id="delete-note-dialog"
      open={show}
      onClose={cancel}
      contentStyle={{ maxWidth: title ? '500px' : '450px', width: 'none' }}
    >
      {title && (
        <DialogTitle id="alert-dialog-title" className="add-dialog-header">
          {title}
        </DialogTitle>
      )}
      <DialogContent
        style={{ width: title ? '500px' : '450px', ...contentStyle }}
        className="dialog-content-style"
      >
        {content}
        {title && !hideConfirmation && (
          <div style={{ paddingTop: 10 }}>
            {commonTranslations.deleteConfirm}
          </div>
        )}
      </DialogContent>
      <DialogActions id="dialog-buttons">
        {isDelete ? deleteActions : viewAction}
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  content: PropTypes.node,
  title: PropTypes.node,
  isDelete: PropTypes.bool,
  hideConfirmation: PropTypes.bool,
  contentStyle: PropTypes.shape({})
};

CustomDialog.defaultProps = {
  show: false,
  proceed: (e) => e,
  cancel: (e) => e,
  content: null,
  title: undefined,
  isDelete: true,
  hideConfirmation: false,
  contentStyle: {}
};

export default CustomDialog;
