import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import deepmerge from 'deepmerge';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';

// eslint-disable-next-line import/prefer-default-export
export function* getGraphViewItems(action) {
  const { pathEntityType, id, isExpand = false } = action;

  if (!isExpand) yield put({ type: 'MASTER:DATA:GRAPH:FETCH:INIT' });
  const hashURL = window.location.hash;
  const urlParams = getQueryStrings(hashURL);
  const type = pathEntityType || urlParams.filter;
  const entityId = id || urlParams.value;
  const currentData = yield select(state => state.graphItems.get('graphData'));
  let response;
  try {
    if (urlParams.filter === 'hierarchy-graph') {
      let url = `/v1/organisations/hierarchy-graph?isInternal=${urlParams.isInternal}`;
      if (entityId && pathEntityType !== 'tenant') {
        url = `${url}&organisationId=${entityId}`;
      }
      response = yield registry.get('request')
        .get(url, null);
    } else {
      response = yield registry.get('request')
        .get(`/v1/${type}/${entityId}/graph`, null);
    }
    switch (response.status) {
      case 200: {
        const graphData = isExpand ? getModifiedData(response.body, currentData) : response.body;
        yield put({ type: 'MASTER:DATA:GRAPH:FETCH:SUCCESS', graphData });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
    yield put({ type: 'MASTER:DATA:GRAPH:FETCH:FAIL', error: err.message });
  }
}

const getModifiedData = (newData, currentData) => {
  const graphData = deepmerge(currentData, newData);
  const modifiedData = { ...graphData, nodes: removeDuplicates(graphData.nodes) };
  return modifiedData;
};

const removeDuplicates = (data) => {
  const newArray = data.filter((item, index) => (data.findIndex(val => val.data.id === item.data.id) >= index));
  return newArray;
};
