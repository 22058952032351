/* eslint-disable import/no-cycle */
import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import MultipleSelector from '.';


const MultipleSelectorDialog = (props) => {
  const handleOnSubmit = (selectedItems) => {
    if (props.handleMultipleItems) {
      const modifiedItems = selectedItems.map(item => ({ value: item }));
      props.handleMultipleItems(modifiedItems);
    }
    requestClose();
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const { multiValue } = props;
  return (
    <CommonDialog
      id="datasource-dialog"
      show={props.open}
      onCancel={requestClose}
      title={props.title}
      maxWidth="sm"
      fullWidth={true}
    >
      <MultipleSelector
        id="multiple-selector"
        key={props.key}
        multiValue={multiValue}
        onChooseFilter={props.onChooseFilter}
        onresetFilter={props.onresetFilter}
        filteredData={props.filteredData}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        onScrollStop={props.onScrollStop}
      />
    </CommonDialog>
  );
};

MultipleSelectorDialog.propTypes = {
  key: PropTypes.string,
  title: PropTypes.node,
  multiValue: PropTypes.bool,
  filteredData: PropTypes.instanceOf(Immutable.List),
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onresetFilter: PropTypes.func,
  open: PropTypes.bool,
  onScrollStop: PropTypes.func
};

MultipleSelectorDialog.defaultProps = {
  open: false,
  multiValue: true,
  title: null,
  key: '',
  filteredData: Immutable.List(),
  onRequestClose: e => e,
  handleMultipleItems: e => e,
  onScrollStop: e => e,
  onresetFilter: e => e,
  onChooseFilter: e => e
};

export default MultipleSelectorDialog;
