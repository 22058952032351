import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import AutocompleteWithChips from '@packages/components/auto-complete-chips';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { commonTranslations } from '@packages/utils/commontranslations';
import TagsDialog from '../tag-dialog';
import { multipleSelectorTypeTranslations } from '../../../environment/masterDataTranslations';

const TagSelector = (props) => {
  const [tagList, setTagList] = useState(undefined);
  const [multiple, setMultiple] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.init();
  }, []);

  useEffect(() => {
    const selectedItem = props.fields.getAll() || [];
    const tagsList = props.tags.map(item => ({ ...item, key: item.name, value: item.name }));
    const searchResults = props.searchResults.map(item => ({ ...item, key: item.name, value: item.name }));
    const list = props.searchText !== '' || searchResults.size > 0 ? searchResults : tagsList;
    const itemList = filterTags(selectedItem, list);
    setTagList(itemList.toJS());
  }, [props.tags, props.fields.getAll(), props.searchResults]);

  const filterTags = (selectedItems, tags) => {
    const filteredData = tags.filter((item) => {
      const index = selectedItems.findIndex(selectedItem =>
        (selectedItem.value || selectedItem.name) === item.name);
      return (index === -1);
    });
    return filteredData;
  };

  const handleSearch = (inputValue) => {
    props.tagSearch(inputValue);
  };

  const handleMultipleSelect = () => {
    setMultiple(true);
  };

  // Handler to add/edit items
  const handleSelectedItem = (selectedItem, closeDialog) => {
    const modifiedItem = typeof (selectedItem.value) === 'object' ? { ...selectedItem.value,
      value: selectedItem.value.name,
      key: selectedItem.value.name } :
      selectedItem;
    const selectedTags = props.fields.length ? [...props.fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedTags.push(modifiedItem);
    } else {
      selectedTags.splice(selectedIndex, 1, modifiedItem);
    }
    props.updateTags(selectedTags);
    if (closeDialog) setOpen(false);
  };

  // Handler to remove item
  const handleRemoveItem = (index) => {
    const selectedTags = props.fields.length ? [...props.fields.getAll()] : [];
    if (index !== -1) {
      selectedTags.splice(index, 1);
    }

    props.updateTags(selectedTags);
  };

  // Handler to add/edit multiple items
  const handleMultipleItems = (selectedItems) => {
    const items = props.fields.getAll() || [];
    const selectedTags = [...items, ...selectedItems];
    props.updateTags(selectedTags);
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setSelectedIndex(-1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScrollEnd = () => {
    props.getNextData(props.position);
  };

  const handleAddClick = () => {
    setOpen(true);
  };
  const getTagName = (item) => {
    const tagName = [];
    if (item && item.length > 0) {
      item.map((val) => tagName.push(val.name));
    }
    return tagName.join(', ');
  };
  const selectedTags = props.fields.getAll();
  const { hintTextLabel, label, disabledTagStyle, entityType, createNewMenuItem } = props;
  const disabledStyle = { marginTop: '10px' };
  return (
    <>
      {props.disabled ?
        <div style={{ ...disabledStyle, ...disabledTagStyle }}>
          <div style={{ marginTop: 15, marginBottom: 10 }}>{label}</div>
          <div style={{ padding: 10 }}>
            {getTagName(selectedTags) || commonTranslations.noTags}
          </div>
        </div> : 

        <AutocompleteWithChips
          placeholder={hintTextLabel}
          initialValue={selectedTags}
          dataSource={tagList}
          dataSourceConfig={{ text: 'name', value: 'id' }}
          createNewMenuItem={createNewMenuItem}
          selectFromListMenuItem={true}
          fullWidth={true}
          maxCount={5}
          type={entityType}
          searchText={props.searchText}
          handleSearch={handleSearch}
          handleMultipleSelect={handleMultipleSelect}
          handleSelectedItem={handleSelectedItem}
          handleRemoveItem={handleRemoveItem}
          handleAddClick={handleAddClick}
          disabled={props.disabled}
          displaySelectorOnTop={props.displaySelectorOnTop}
        />}
      {multiple && <MultipleSelectorDialog
        id="multiple_selector"
        displayAsChip={true}
        title={multipleSelectorTypeTranslations('tags')}
        open={multiple}
        maxCount={5}
        onScrollStop={handleScrollEnd}
        filteredData={tagList}
        selectedEntitiesSize={selectedTags && selectedTags.length}
        onRequestClose={handleRequestClose}
        handleMultipleItems={handleMultipleItems}
      />}

      {open && <TagsDialog
        open={open}
        limitExceeded={selectedTags && selectedTags.length === 5}
        onRequestClose={handleClose}
        handleSelectedItem={handleSelectedItem}
      />}
    </>
  );
};


TagSelector.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    getAll: PropTypes.func,
    get: PropTypes.func,
    length: PropTypes.number
  }),
  disabledTagStyle: PropTypes.shape({}),
  entityType: PropTypes.string,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  updateTags: PropTypes.func,
  init: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Immutable.List),
  searchText: PropTypes.string,
  searchResults: PropTypes.instanceOf(Immutable.List),
  tagSearch: PropTypes.func,
  disabled: PropTypes.bool,
  hintTextLabel: PropTypes.string,
  label: PropTypes.node,
  displaySelectorOnTop: PropTypes.bool,
  createNewMenuItem: PropTypes.bool
};

TagSelector.defaultProps = {
  getNextData: e => e,
  position: 0,
  fields: {
    map: e => e,
    get: e => e,
    getAll: e => e
  },
  disabledTagStyle: {},
  entityType: '',
  tags: Immutable.List(),
  searchText: '',
  searchResults: Immutable.List(),
  tagSearch: e => e,
  updateTags: e => e,
  disabled: false,
  hintTextLabel: '',
  label: null,
  displaySelectorOnTop: false,
  createNewMenuItem: true
};
export default TagSelector;

