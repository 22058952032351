/* eslint-disable react/no-multi-comp */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import moment from 'moment';
import EditIcon from '@material-ui/icons/EditOutlined';
import ContentDrafts from '@material-ui/icons/Drafts';
import Email from '@material-ui/icons/Email';
import { Cell } from 'fixed-data-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faReply,
  faShare,
  faTags,
  faDownload,
  faExternalLinkAlt,
  faFileSignature,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import Linkify from 'react-linkify';
import FontIcon from '@material-ui/core/Icon';
import { withTheme } from '@material-ui/core/styles';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import Checkbox from '@material-ui/core/Checkbox';
import ActionInfo from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  getLocalDateTime,
  getLocalDateObj,
  getTimeRemaining
} from '@packages/utils/date-utils';
import styles from '@packages/ui/styles';
import ArrowTooltip from '@packages/components/tooltip';
import { commonTranslations } from '@packages/utils/commontranslations';
import messageTranlations from '@packages/features/message-center/messageTranslations';
import { templateTranslations } from '@packages/features/privacy-record-detail/recordTranslations';
import { removeTranslations } from '@packages/utils/actionTranslations';

import ActionSelector from './actionSelector';

const contentIconStyle = {
  ...styles.rightIcon,
  marginRight: '3px',
  marginLeft: '10px'
};

const defaultCellStyle = {
  width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const deleteIconStyle = {
  color: 'red',
  height: '24px',
  marginRight: 5,
  width: '15px'
};

const messageCellStyle = {
  alignContent: 'bottom',
  marginTop: '2.5px',
  height: '24px',
  marginRight: '12px',
  cursor: 'pointer'
};

const replyIconStyle = { height: '24px', width: '1.25em', marginRight: 5 };

const readUnReadStyle = { height: '24px', marginRight: 5 };

const forwardIconStyle = { height: '24px', width: '1.25em' };

const getUpdatedStyle = (markReadOrUnRead, data) => {
  if (markReadOrUnRead) {
    return data.isRead ? {} : { fontWeight: 'bold' };
  }
  return {};
};

// Stateless cell components for Table component
// Data cell
const DataCellDefault = ({
  items,
  rowIndex,
  width,
  columnKey,
  mapper,
  customStyle,
  tooltipId,
  showAnchorLinks,
  styleMapper,
  icon,
  currentIndex,
  checkIconVisibility,
  showIconOnHover,
  ...props
}) => {
  const dataObj = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];
  const dataItem = dataObj && dataObj.value ? dataObj.value : dataObj;
  const data = mapper ? mapper(dataItem) : dataItem;
  const isIconVisible =
    checkIconVisibility(dataItem) !== false &&
    (showIconOnHover ? currentIndex === rowIndex : true);
  const customStyleValues = styleMapper ? styleMapper(dataItem) : {};
  const dataCellStyle = {
    maxWidth: width - 40,
    ...styles.textWithEllipsis,
    ...customStyleValues
  };
  const computedStyle = customStyle
    ? { ...dataCellStyle, ...customStyle }
    : dataCellStyle;
  const themeColor = props.theme ? props.theme.palette.primary.main : '';
  const dataCell = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <ArrowTooltip title={data}>
        <div style={computedStyle}>{data}</div>
      </ArrowTooltip>

      {icon && (
        <span style={{ display: isIconVisible ? 'block' : 'none' }}>
          {' '}
          {icon}{' '}
        </span>
      )}
    </div>
  );
  return (
    <Cell {...props}>
      <div>
        {showAnchorLinks ? (
          <Linkify
            properties={{
              target: '_blank',
              style: { color: themeColor },
              onClick: (e) => e.stopPropagation()
            }}
          >
            {dataCell()}
          </Linkify>
        ) : (
          dataCell()
        )}
      </div>
    </Cell>
  );
};

DataCellDefault.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  rowIndex: PropTypes.number,
  currentIndex: PropTypes.number,
  width: PropTypes.number,
  columnKey: PropTypes.string,
  mapper: PropTypes.func,
  tooltipId: PropTypes.string,
  customStyle: PropTypes.shape({}),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  showAnchorLinks: PropTypes.bool,
  styleMapper: PropTypes.shape({}),
  icon: PropTypes.element,
  checkIconVisibility: PropTypes.func,
  showIconOnHover: PropTypes.bool
};

DataCellDefault.defaultProps = {
  columnKey: null,
  rowIndex: -1,
  currentIndex: -1,
  width: 200,
  mapper: (e) => e,
  tooltipId: '',
  customStyle: {},
  theme: {
    palette: {
      primary: {
        main: ''
      }
    }
  },
  showAnchorLinks: true,
  styleMapper: undefined,
  icon: undefined,
  checkIconVisibility: (e) => e,
  showIconOnHover: true
};

// Boolean cell
const BooleanCellDefault = ({
  items,
  rowIndex,
  columnKey,
  customStyle,
  ...props
}) => {
  const dataObj = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];
  const data =
    dataObj === true ? commonTranslations.Yes : commonTranslations.No;
  // const defaultCellStyle = { width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
  const computedStyle = customStyle
    ? { ...defaultCellStyle, ...customStyle }
    : defaultCellStyle;

  return (
    <Cell {...props}>
      <div style={computedStyle}>{data}</div>
    </Cell>
  );
};

BooleanCellDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({})
};

BooleanCellDefault.defaultProps = {
  customStyle: {}
};

// Link cell
const LinkCellDefault = ({
  items,
  rowIndex,
  columnKey,
  mapper,
  handleCellClick,
  customStyle,
  ...props
}) => {
  const dataObj = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];
  const dataItem = dataObj && dataObj.value ? dataObj.value : dataObj;
  const data = mapper ? mapper(dataItem) : dataItem;
  const linkStyle = customStyle
    ? { ...defaultCellStyle, ...customStyle }
    : defaultCellStyle;
  return (
    <Cell
      id="cell"
      {...props}
      onClick={() => handleCellClick(items, columnKey, rowIndex)}
    >
      <ArrowTooltip title={data}>
        <div style={linkStyle}>{data}</div>
      </ArrowTooltip>
    </Cell>
  );
};

LinkCellDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  mapper: PropTypes.func,
  handleCellClick: PropTypes.func,
  customStyle: PropTypes.shape({})
};

LinkCellDefault.defaultProps = {
  mapper: (e) => e,
  handleCellClick: (e) => e,
  customStyle: {}
};

// Date cell
const DateCellDefault = ({
  items,
  type,
  dateFormat,
  markReadOrUnRead,
  rowIndex,
  columnKey,
  customStyle = {},
  locale,
  ...props
}) => {
  const cellData = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];
  const localDate = getLocalDateObj(cellData, dateFormat, locale);
  const updatedStyle = getUpdatedStyle(
    markReadOrUnRead,
    items.get ? items.get(rowIndex) : items[rowIndex]
  );
  let value;
  let title;
  // If date type is timeRem, set the remaining time, else set time elapsed.
  if (type === 'timeRem') {
    value = getTimeRemaining(cellData).rem;
    title = localDate;
  } else {
    value = getLocalDateTime(cellData, dateFormat, locale);
    title = moment.utc(cellData).locale(locale).fromNow();
  }
  return (
    <Cell {...props} style={{ ...customStyle, updatedStyle }}>
      <ArrowTooltip id={value} title={title}>
        <span>{value}</span>
      </ArrowTooltip>
    </Cell>
  );
};

DateCellDefault.propTypes = {
  type: PropTypes.string,
  locale: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number,
  columnKey: PropTypes.string,
  customStyle: PropTypes.shape({}),
  markReadOrUnRead: PropTypes.bool
};

DateCellDefault.defaultProps = {
  type: '',
  rowIndex: -1,
  columnKey: '',
  customStyle: {},
  markReadOrUnRead: false
};

// eslint-disable-next-line import/prefer-default-export
const BulkCheckBoxCellDefault = ({
  items,
  bulkItems,
  onCheck,
  rowIndex,
  isDisabled,
  ...props
}) => (
  <Cell {...props}>
    <FormControlLabel
      classes={{
        disabled: 'disabled_style'
      }}
      control={
        <Checkbox
          checked={getSelectedItems(items, rowIndex, bulkItems)}
          onChange={(event) => {
            onCheck(event, items.get && items.get(rowIndex));
          }}
          onClick={(event) => event.stopPropagation()}
          color="primary"
          style={{ marginTop: -10, marginLeft: 2 }}
        />
      }
      disabled={
        (bulkItems.size > 24 &&
          items.get(rowIndex) &&
          items.get(rowIndex).selected === undefined) ||
        (isDisabled && isDisabled(items.get(rowIndex)))
      }
    />
  </Cell>
);

BulkCheckBoxCellDefault.propTypes = {
  rowIndex: PropTypes.number,
  unused: PropTypes.bool,
  onCheck: PropTypes.func,
  bulkItems: PropTypes.instanceOf(Immutable.List),
  items: PropTypes.instanceOf(Immutable.List),
  isDisabled: PropTypes.func
};

BulkCheckBoxCellDefault.defaultProps = {
  rowIndex: -1,
  bulkItems: Immutable.List(),
  unused: false,
  onCheck: (e) => e,
  items: Immutable.List(),
  isDisabled: undefined
};

const getSelectedItems = (items, rowIndex, bulkItems) => {
  const itemToCheck = items.get(rowIndex);
  const index = bulkItems.findIndex((item) => item.id === itemToCheck.id);
  if (index !== -1) return true;
  return false;
};

// CheckBox cell
const CheckBoxCellDefault = ({
  items,
  disabled,
  rowIndex,
  columnKey,
  onCheck,
  ...props
}) => {
  const item = items.get ? items.get(rowIndex) : items[rowIndex];
  return (
    <Cell {...props}>
      <FormControlLabel
        control={
          <Checkbox
            style={{ marginTop: -10 }}
            color="primary"
            checked={item[columnKey]}
            onChange={(event, checked) => onCheck(event, item, checked)}
            disabled={disabled}
          />
        }
      />
    </Cell>
  );
};

CheckBoxCellDefault.propTypes = {
  disabled: PropTypes.bool,
  onCheck: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired
};

CheckBoxCellDefault.defaultProps = {
  disabled: false,
  onCheck: (e) => e
};

// ActionChooser cell
const ActionChooserCellDefault = ({
  rowIndex,
  actionChooserClass,
  ...props
}) => {
  const showActionChooser = props.handleActionChooserVisibility
    ? props.handleActionChooserVisibility(rowIndex)
    : true;

  return (
    <Cell id="cell" onClick={cancelClick} className={actionChooserClass}>
      {showActionChooser && <ActionSelector {...props} rowIndex={rowIndex} />}
    </Cell>
  );
};

ActionChooserCellDefault.propTypes = {
  rowIndex: PropTypes.number,
  actionChooserClass: PropTypes.string,
  handleActionChooserVisibility: PropTypes.func
};

ActionChooserCellDefault.defaultProps = {
  rowIndex: -1,
  actionChooserClass: '',
  handleActionChooserVisibility: (e) => e
};

const cancelClick = (e) => {
  e.stopPropagation();
};

const DocumentNameCellDefault = ({
  items,
  rowIndex,
  width,
  columnKey,
  mapper,
  customStyle,
  tooltipId,
  ...props
}) => {
  const dataObj = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];
  const descriptionObject = items.get
    ? items.get(rowIndex).description
    : items[rowIndex].description;
  const dataItem = dataObj && dataObj.value ? dataObj.value : dataObj;
  const data = mapper ? mapper(dataItem) : dataItem;
  const dataCellStyle = {
    width: descriptionObject !== '' ? width - 50 : width - 30,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: descriptionObject !== '' ? 3 : 0
  };
  const computedStyle = customStyle
    ? { ...dataCellStyle, ...customStyle }
    : dataCellStyle;

  return (
    <Cell {...props}>
      <div style={{ ...dataCellStyle, display: 'flex' }}>
        <ArrowTooltip title={data}>
          <div
            style={{
              ...computedStyle,
              width: descriptionObject !== '' ? width - 80 : width - 40
            }}
          >
            {data}
          </div>
        </ArrowTooltip>
        {descriptionObject !== '' && (
          <div
            data-tip=""
            data-for={descriptionObject}
            style={{ width: '17%' }}
          >
            <ArrowTooltip title={descriptionObject}>
              <IconButton style={contentIconStyle}>
                <ActionHelp color="primary" />
              </IconButton>
            </ArrowTooltip>
          </div>
        )}
      </div>
    </Cell>
  );
};

DocumentNameCellDefault.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  rowIndex: PropTypes.number,
  width: PropTypes.number,
  columnKey: PropTypes.string,
  mapper: PropTypes.func,
  tooltipId: PropTypes.string,
  customStyle: PropTypes.shape({}),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  })
};

DocumentNameCellDefault.defaultProps = {
  columnKey: null,
  rowIndex: -1,
  width: 200,
  mapper: (e) => e,
  tooltipId: '',
  customStyle: {},
  theme: {
    palette: {
      primary: {
        main: ''
      }
    }
  }
};
// InformationCell
const InformationCellDefault = ({
  items,
  rowIndex,
  handleOnClick,
  className,
  ...props
}) => {
  const { note } = items.get ? items.get(rowIndex) : items[rowIndex];
  const cellStyle = {
    paddingTop: '2px',
    ...props.style,
    float: 'right',
    marginRight: '2px'
  };
  return (
    <Cell {...props} style={cellStyle} className={className}>
      {note && (
        <ArrowTooltip title={note}>
          <ActionInfo onClick={(event) => handleOnClick(event, rowIndex)} />
        </ArrowTooltip>
      )}
    </Cell>
  );
};

InformationCellDefault.propTypes = {
  rowIndex: PropTypes.number,
  handleOnClick: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List),
  className: PropTypes.string,
  style: PropTypes.shape({})
};

InformationCellDefault.defaultProps = {
  rowIndex: -1,
  handleOnClick: (e) => e,
  items: Immutable.List(),
  className: '',
  style: {}
};

// ActionCell for delete and edit icon
const ActionCellDefault = withTheme(
  ({
    rowIndex,
    handleEdit,
    currentIndex,
    handleDelete,
    handleCopy,
    isCreateCopy,
    isTagEditHidden,
    isEditHidden,
    isDeleteHidden,
    items,
    handleEditTag,
    handleWebView,
    showGraphView,
    showDocuments,
    handleDocuments,
    showDownloadIcon,
    showLinkIcon,
    handleDownload,
    handleOpenLink,
    handleReject,
    showRejectIcon,
    ...props
  }) => {
    const themeColor = props.theme ? props.theme.palette.primary.main : '';
    const cellStyle = props.cellStyle || {};
    const itemValue = items.get ? items.get(rowIndex) : items[rowIndex];
    const isHoldingItem =
      itemValue?.isGlobal ||
      itemValue?.globalTenantInfo?.isGlobal ||
      itemValue?.templateInfo?.source === 'Holding' ||
      itemValue?.source === 'Holding';
    const isEditDeleteEnable =
      props.isGlobal || (!props.isGlobal && !isHoldingItem);
    const isHoldingData =
      itemValue?.isGlobal ||
      itemValue?.source === 'Holding' ||
      itemValue?.templateInfo?.source === 'Holding';
    const isEditEnable = props.isGlobal || (!props.isGlobal && !isHoldingData);
    const createCopyStyle = {
      marginLeft: 20,
      height: '24px',
      width: '15px',
      marginRight: '11px',
      color: props.theme.palette.primary.main,
      cursor: 'pointer'
    };
    return (
      <Cell style={cellStyle}>
        <div
          style={{
            display: rowIndex === currentIndex ? 'flex' : 'none',
            alignContent: 'bottom',
            marginTop: '2.5px',
            height: '24px',
            marginLeft: '-7px',
            cursor: 'pointer'
          }}
        >
          {showGraphView && (
            <ArrowTooltip
              title={
                <FormattedMessage
                  id="Masterdata.webView"
                  defaultMessage="Web view"
                />
              }
            >
              <BubbleChartIcon
                style={{ color: themeColor, marginRight: 10 }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleWebView(rowIndex);
                }}
              />
            </ArrowTooltip>
          )}
          {showDocuments && (
            <div style={{ marginRight: '11px' }}>
              <ArrowTooltip title={commonTranslations.manageDocuments}>
                <FontAwesomeIcon
                  icon={faFileSignature}
                  style={{
                    color: themeColor,
                    height: '20px',
                    fontSize: '18px'
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDocuments(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {!isTagEditHidden && (
            <div style={{ marginRight: '11px' }}>
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="Action.Tags"
                    defaultMessage="Add or remove tag"
                  />
                }
              >
                <FontAwesomeIcon
                  icon={faTags}
                  style={{
                    color: themeColor,
                    opacity: isEditDeleteEnable ? '' : '0.4',
                    ...forwardIconStyle
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isEditDeleteEnable) handleEditTag(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {isCreateCopy && (
            <div>
              <ArrowTooltip title={templateTranslations.createCopy}>
                <FontAwesomeIcon
                  icon={faClone}
                  style={createCopyStyle}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCopy(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {showDownloadIcon && (
            <div style={{ marginRight: '11px', paddingLeft: '5px' }}>
              <ArrowTooltip
                title={
                  itemValue.isBinaryDeleted
                    ? commonTranslations.downloadDisabled
                    : commonTranslations.documentDownload
                }
              >
                <div style={itemValue.isBinaryDeleted ? styles.disabled : {}}>
                  <FontAwesomeIcon
                    icon={faDownload}
                    style={{
                      color: props.theme.palette.primary.main,
                      height: '24px',
                      width: '16px'
                    }}
                    key="download-icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownload(rowIndex);
                    }}
                  />
                </div>
              </ArrowTooltip>
            </div>
          )}
          {showLinkIcon && (
            <div style={{ marginRight: '11px', paddingLeft: '5px' }}>
              <ArrowTooltip title={commonTranslations.openLink}>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{
                    color: props.theme.palette.primary.main,
                    height: '24px',
                    width: '16px'
                  }}
                  key="link-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenLink(itemValue);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}

          {!isEditHidden && (
            <div style={{ marginRight: '11px' }}>
              <ArrowTooltip
                title={
                  isEditEnable ? (
                    <FormattedMessage
                      id="Action.Edit"
                      description="Edit Records"
                      defaultMessage="Edit"
                    />
                  ) : (
                    <FormattedMessage
                      id="Action.noEdit"
                      description="Edit Records"
                      defaultMessage="You are not allowed to edit this item as it belongs to the global tenant"
                    />
                  )
                }
              >
                <EditIcon
                  style={{
                    color: themeColor,
                    height: '24px',
                    fontSize: '22px',
                    opacity: isEditEnable ? '' : '0.4'
                  }}
                  key="edit-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isEditEnable) handleEdit(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {!isDeleteHidden && (
            <div>
              <ArrowTooltip
                title={
                  isEditDeleteEnable ? (
                    <FormattedMessage
                      id="Action.Delete"
                      description="Delete action"
                      defaultMessage="Delete"
                    />
                  ) : (
                    <FormattedMessage
                      id="Action.noDelete"
                      description="Delete action"
                      defaultMessage="You are not allowed to delete this item as it belongs to the global tenant"
                    />
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    color: 'red',
                    height: '24px',
                    width: '15px',
                    opacity: isEditDeleteEnable ? '' : '0.4'
                  }}
                  key="delete-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isEditDeleteEnable) handleDelete(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {showRejectIcon && (
            <div>
              <ArrowTooltip title={removeTranslations('reject')}>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReject(rowIndex);
                  }}
                  style={{
                    color: 'red',
                    height: '20px',
                    fontSize: '22px'
                  }}
                  key="accept-icon"
                />
              </ArrowTooltip>
            </div>
          )}
        </div>
      </Cell>
    );
  }
);

ActionCellDefault.propTypes = {
  rowIndex: PropTypes.number,
  currentIndex: PropTypes.number,
  handleEdit: PropTypes.func,
  isEditHidden: PropTypes.bool,
  isDeleteHidden: PropTypes.bool,
  isTagEditHidden: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleWebView: PropTypes.func,
  showGraphView: PropTypes.bool,
  showDocuments: PropTypes.bool,
  showRejectIcon: PropTypes.bool,
  handleReject: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List)
};

ActionCellDefault.defaultProps = {
  rowIndex: -1,
  currentIndex: -1,
  handleEdit: (e) => e,
  handleDelete: (e) => e,
  isEditHidden: false,
  isDeleteHidden: false,
  isTagEditHidden: true,
  handleWebView: (e) => e,
  showGraphView: false,
  showDocuments: false,
  showRejectIcon: false,
  handleReject: (e) => e,
  items: Immutable.List()
};

// Action cell for message list
const MessageActionCellDefault = withTheme(
  ({
    rowIndex,
    handleReadOrUnread,
    currentIndex,
    handleDelete,
    handleForward,
    handleReply,
    items,
    columnKey,
    isDeleteHidden,
    showReadOrUnRead,
    showForwardAndReply,
    ...props
  }) => {
    const themeColor = props.theme ? props.theme.palette.primary.main : '';
    const cellData = items.get ? items.get(rowIndex) : items[rowIndex];
    const isRead = showForwardAndReply
      ? cellData.isRead
      : cellData.status === 'READ';
    const cellStyle = { float: 'right' };
    return (
      <Cell style={cellStyle}>
        <div
          style={{
            display: rowIndex === currentIndex ? 'flex' : 'none',
            ...messageCellStyle
          }}
        >
          {!isDeleteHidden && (
            <div>
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="Action.Delete"
                    description="Delete action"
                    defaultMessage="Delete"
                  />
                }
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={deleteIconStyle}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDelete(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {!isRead && showReadOrUnRead && (
            <div>
              <ArrowTooltip title={messageTranlations.markAsRead}>
                <ContentDrafts
                  style={{ ...readUnReadStyle, color: themeColor }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReadOrUnread(rowIndex, true);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {isRead && showReadOrUnRead && (
            <div>
              <ArrowTooltip title={messageTranlations.markAsUnRead}>
                <Email
                  style={{ ...readUnReadStyle, color: themeColor }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReadOrUnread(rowIndex, false);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {showForwardAndReply && (
            <div>
              <ArrowTooltip title={messageTranlations.reply}>
                <FontAwesomeIcon
                  icon={faReply}
                  style={{ color: themeColor, ...replyIconStyle }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleReply(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
          {showForwardAndReply && (
            <div>
              <ArrowTooltip title={messageTranlations.forward}>
                <FontAwesomeIcon
                  icon={faShare}
                  style={{ color: themeColor, ...forwardIconStyle }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleForward(rowIndex);
                  }}
                />
              </ArrowTooltip>
            </div>
          )}
        </div>
      </Cell>
    );
  }
);

MessageActionCellDefault.propTypes = {
  rowIndex: PropTypes.number,
  showForwardAndReply: PropTypes.bool,
  showReadOrUnRead: PropTypes.bool,
  currentIndex: PropTypes.number,
  handleEdit: PropTypes.func,
  isEditHidden: PropTypes.bool,
  isDeleteHidden: PropTypes.bool,
  handleDelete: PropTypes.func
};

MessageActionCellDefault.defaultProps = {
  rowIndex: -1,
  currentIndex: -1,
  showForwardAndReply: true,
  handleEdit: (e) => e,
  showReadOrUnRead: true,
  handleDelete: (e) => e,
  isEditHidden: false,
  isDeleteHidden: false
};
// Status cell
const StatusCellDefault = ({ items, rowIndex, ...props }) => {
  const cellData = items.get ? items.get(rowIndex) : items[rowIndex];
  return (
    <Cell {...props}>
      <FontIcon
        className="material-icons"
        style={cellData.viewInternalData.iconStyle}
      >
        {cellData.viewInternalData.icon}
      </FontIcon>
    </Cell>
  );
};

StatusCellDefault.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired
};

// DataArray cell
const DataArrayCellDefault = injectIntl(
  ({
    items,
    rowIndex,
    columnKey,
    mapper,
    defaultContent,
    tooltipId,
    intl,
    ...props
  }) => {
    const dataObj = items.get
      ? items.get(rowIndex)[columnKey]
      : items[rowIndex][columnKey];
    const contentArray = [];
    if (dataObj) {
      dataObj.forEach((item) => {
        const dataItem = item && item.value ? item.value : item;
        const data = mapper ? mapper(dataItem) : dataItem;
        contentArray.push(data);
      });
    }

    let content = [];
    contentArray.map((item) =>
      content.push(item.props ? intl.formatMessage(item.props) : item)
    );
    content = tooltipId && content.join(', ');

    const dataArrayCellStyle = {
      width: '104px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };
    return (
      <Cell {...props}>
        {contentArray.length === 0 ? (
          <div style={dataArrayCellStyle}>
            <ArrowTooltip title={defaultContent}>
              <span>{defaultContent}</span>
            </ArrowTooltip>
          </div>
        ) : (
          <ArrowTooltip
            title={contentArray.map((item, index) => (
              <span>
                {' '}
                {item}
                {index < contentArray.length - 1 ? ',' : null}{' '}
              </span>
            ))}
          >
            <div style={dataArrayCellStyle}>
              {contentArray.map((item, index) => (
                <span>
                  {' '}
                  {item}
                  {index < contentArray.length - 1 ? ',' : null}{' '}
                </span>
              ))}
            </div>
          </ArrowTooltip>
        )}
      </Cell>
    );
  }
);

DataArrayCellDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  mapper: PropTypes.func,
  defaultContent: PropTypes.string,
  tooltipId: PropTypes.string
};

DataArrayCellDefault.defaultProps = {
  rowIndex: -1,
  columnKey: '',
  items: Immutable.List(),
  mapper: (e) => e,
  defaultContent: '',
  tooltipId: ''
};

const DataArrayCellForGroupsDefault = injectIntl(
  ({ items, rowIndex, columnKey, fontStyle }) => {
    const itemRow = items.get ? items.get(rowIndex) : items[rowIndex];
    const dataObj = itemRow.value
      ? itemRow.value[columnKey]
      : itemRow[columnKey];
    const contentArray = [];
    if (dataObj) {
      dataObj.forEach((item) => {
        const dataItem = item && item.value ? item.value : item;
        const data = dataItem.name || dataItem.key || dataItem;
        contentArray.push(data);
      });
    }

    const cellStyle = {
      paddingTop: '10px',
      marginLeft: '5px',
      height: '20px'
    };

    const itemStyle = {
      width: '90px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };

    return (
      <div>
        {contentArray.map((item) => (
          <div key={item} style={{ ...cellStyle, ...fontStyle }}>
            <ArrowTooltip title={item}>
              <div style={itemStyle}> {item} </div>
            </ArrowTooltip>
          </div>
        ))}
      </div>
    );
  }
);

DataArrayCellForGroupsDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List),
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired
};

DataArrayCellForGroupsDefault.defaultProps = {
  rowIndex: -1,
  columnKey: '',
  items: Immutable.List()
};

// Selector cell
const SelectorCellDefault = ({
  items,
  rowIndex,
  columnKey,
  menuItems,
  customStyle,
  handleChange,
  isDisabled,
  ...props
}) => {
  const cellData = items.get ? items.get(rowIndex) : items[rowIndex];
  return (
    <Cell {...props}>
      <Select
        style={customStyle}
        value={cellData[columnKey]}
        disabled={isDisabled && isDisabled(cellData)}
        onChange={(event) => {
          handleChange(event.target.value, rowIndex);
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Cell>
  );
};

SelectorCellDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  handleChange: PropTypes.func,
  customStyle: PropTypes.shape({}),
  isDisabled: PropTypes.func
};

SelectorCellDefault.defaultProps = {
  customStyle: {},
  handleChange: (e) => e,
  menuItems: [],
  isDisabled: undefined
};

// Icon cell
const IconCellDefault = ({
  items,
  rowIndex,
  columnKey,
  icon,
  message,
  iconStyle,
  ...props
}) => {
  const showIcon = items.get
    ? items.get(rowIndex)[columnKey]
    : items[rowIndex][columnKey];

  return showIcon ? (
    <Cell {...props}>
      <ArrowTooltip title={message}>
        <FontAwesomeIcon icon={icon} style={iconStyle} />
      </ArrowTooltip>
    </Cell>
  ) : (
    <div />
  );
};

IconCellDefault.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  message: PropTypes.string.isRequired,
  iconStyle: PropTypes.shape({})
};

IconCellDefault.defaultProps = {
  iconStyle: {}
};

const ActionCell = React.memo(ActionCellDefault);
const MessageActionCell = React.memo(MessageActionCellDefault);
const DataCell = React.memo(DataCellDefault);
const DocumentNameCell = React.memo(DocumentNameCellDefault);
const BooleanCell = React.memo(BooleanCellDefault);
const LinkCell = React.memo(LinkCellDefault);
const DateCell = React.memo(DateCellDefault);
const CheckBoxCell = React.memo(CheckBoxCellDefault);
const SelectorCell = React.memo(SelectorCellDefault);
const BulkCheckBoxCell = React.memo(BulkCheckBoxCellDefault);
const ActionChooserCell = React.memo(ActionChooserCellDefault);
const InformationCell = React.memo(InformationCellDefault);
const StatusCell = React.memo(StatusCellDefault);
const DataArrayCell = React.memo(DataArrayCellDefault);
const DataArrayCellForGroups = React.memo(DataArrayCellForGroupsDefault);
const IconCell = React.memo(IconCellDefault);

export {
  DataCell,
  DateCell,
  CheckBoxCell,
  SelectorCell,
  ActionChooserCell,
  StatusCell,
  LinkCell,
  MessageActionCell,
  BulkCheckBoxCell,
  InformationCell,
  BooleanCell,
  DataArrayCell,
  DataArrayCellForGroups,
  ActionCell,
  IconCell
};

export {
  DataCellDefault,
  BooleanCellDefault,
  LinkCellDefault,
  DateCellDefault,
  CheckBoxCellDefault,
  ActionChooserCellDefault,
  InformationCellDefault,
  StatusCellDefault,
  DataArrayCellDefault,
  BulkCheckBoxCellDefault,
  DataArrayCellForGroupsDefault,
  DocumentNameCell,
  ActionCellDefault,
  SelectorCellDefault
};
