import { put, select } from 'redux-saga/effects';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getDiffContent } from '../../common-utils';
import { getTransformedDiffObject } from '../../saga-utils';
import { transformResponseData, transformBreachItems } from '../utils/breachUtils';

export default function* initializeBreachDiff() {
  const { recordId } = getParameterValuesFromHash('/breach/:recordId/compare');
  yield put({ type: 'BREACH:DIFF:FETCH', recordId });

  try {
    const userState = yield select(state => state.user);
    const currentUser = userState ? userState.get('profile') : null;

    // Find json diff between the versions
    const diffObj = yield getTransformedDiffObject('breaches', recordId);
    const transformedData = yield transformResponseData(diffObj, currentUser);
    const data = transformCustomProperties(transformedData);

    yield put({ type: 'BREACH:DIFF:FETCH:SUCCESS', data });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'BREACH:DIFF:FETCH:FAIL', error: err.message });
  }
}

const transformCustomProperties = (data) => {
  let { natureOfBreach, breachConsequences } = data;
  if (natureOfBreach) {
    natureOfBreach = getBreachItemDiff(data.natureOfBreach);
  }
  if (breachConsequences) {
    breachConsequences = getBreachItemDiff(data.breachConsequences);
  }

  const { supervisoryNotification, dataSubjectNotification } = data;
  if (supervisoryNotification && supervisoryNotification.reasonNotToNotify) {
    const supervisoryReasonNotToNotify = getBreachItemDiff(supervisoryNotification.reasonNotToNotify);
    Object.assign(supervisoryNotification, { reasonNotToNotify: supervisoryReasonNotToNotify });
  }

  if (dataSubjectNotification && dataSubjectNotification.reasonNotToNotify) {
    const dataSubjectReasonNotToNotify = getBreachItemDiff(dataSubjectNotification.reasonNotToNotify);
    Object.assign(dataSubjectNotification, { reasonNotToNotify: dataSubjectReasonNotToNotify });
  }

  return Object.assign({}, data, {
    natureOfBreach,
    breachConsequences,
    supervisoryNotification,
    dataSubjectNotification
  });
};

const getBreachItemDiff = (breachItems) => {
  const { change: globalChange, item: items } = getDiffContent(breachItems);
  const transformedBreachItems = transformBreachItems(items);
  return transformedBreachItems.map((breachItem) => {
    const { change: localChange, item } = getDiffContent(breachItem);
    const change = globalChange !== '=' ? globalChange : localChange;
    return {
      change,
      value: {
        key: item
      }
    };
  });
};
