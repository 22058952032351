import React, { useState } from 'react';
import { ListSubheader, withTheme } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { recordTranslations } from '@packages/utils/commontranslations';

const RecordTemplateSelector = (props) => {
  const {
    items,
    fields,
    updateTemplateList,
    onSearch,
    getNextData,
    position,
    initTemplates,
    label,
    theme,
    maxCount,
    styles,
    recordType,
    layoutId,
    intl: { formatMessage }
  } = props;

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const modifiedData = (selectedTemplates) => {
    const filteredData =
      selectedTemplates && selectedTemplates.length > 0
        ? items.filter((item) => {
          const index = selectedTemplates.findIndex((selectedType) => {
            const selectedTypeValue = selectedType.value || selectedType;
            return item.id
              ? item.id === selectedTypeValue.id
              : item.data === selectedTypeValue.data;
          });
          return index === -1;
        })
        : items;
    const modifiedData = filteredData.map((item) => ({
      ...item,
      key: item.name, // taken as rendering name for items
      name: item.id // taken as value for items in MultipleSelectorDialog
    }));
    return modifiedData;
  };

  const handleMultipleSelect = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
    setSearchText('');
  };

  // Handler to add/edit items
  const handleSelectedItem = (selectedItem, closeDialog) => {
    const modifiedItem = { ...selectedItem };
    handleEditItem(modifiedItem, selectedIndex, closeDialog);
  };

  // Update the changed items.
  const handleEditItem = (selectedItem, selectedIndexValue, closeDialog) => {
    const selectedEntities = fields.length ? [...fields.getAll()] : [];
    if (selectedIndexValue === -1) {
      selectedEntities.push(selectedItem);
    } else {
      selectedEntities.splice(selectedIndexValue, 1, selectedItem);
    }
    setSearchText('');
    updateTemplateList(selectedEntities);

    if (closeDialog) {
      handleRequestClose();
    }
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    onSearch(recordType, layoutId, searchText.searchText);
  };

  const handleRemoveItem = (selectedIndex) => {
    const selectedTemplates = [...fields.getAll()];
    if (selectedIndex !== -1) {
      selectedTemplates.splice(selectedIndex, 1);
    }
    if (updateTemplateList) {
      updateTemplateList(selectedTemplates);
    }
  };

  const handleMultipleItems = (selectedItems) => {
    const items = fields.length ? [...fields.getAll()] : [];
    const selectedTemplates = [...items, ...selectedItems];
    setSearchText('');
    updateTemplateList(selectedTemplates);
  };

  const handleScrollEnd = () => {
    getNextData(recordType, layoutId, position, searchText?.searchText);
  };

  const selectedTemplates = fields.getAll();

  return (
    <div style={styles}>
      <ListSubheader style={styles.labelField}>{label}</ListSubheader>
      <ItemList
        id="item_list"
        isEditable={false}
        isNoteEditable={false}
        isNote={false}
        muiTheme={theme}
        {...props}
        handleRemoveItem={handleRemoveItem}
      />
      {fields?.length < maxCount && (
        <ItemSelector
          id="item_selector"
          onFocus={() => initTemplates(recordType, layoutId)}
          createNewMenuItem={false}
          selectFromListMenuItem={true}
          dataSource={modifiedData(selectedTemplates)}
          dataSourceConfig={{ text: 'key', value: 'id' }}
          handleSelectedItem={handleSelectedItem}
          handleMultipleSelect={handleMultipleSelect}
          onSearch={handleSearch}
          hintTextLabel={formatMessage(recordTranslations.selectTemplate.props)}
        />
      )}
      <span>
        {open && (
          <MultipleSelectorDialog
            id="multiple_selector"
            title={recordTranslations.selectTemplate}
            open={open}
            selectedItems={selectedTemplates}
            searchTextValue={searchText?.searchText}
            onScrollStop={handleScrollEnd}
            onRequestClose={handleRequestClose}
            handleMultipleItems={handleMultipleItems}
            maxCount={maxCount}
            isSearchEnabled={true}
            onSearch={handleSearch}
            multiValue={false}
            filteredData={modifiedData(selectedTemplates)}
          />
        )}
      </span>
    </div>
  );
};

RecordTemplateSelector.propTypes = {
  label: PropTypes.string,
  maxCount: PropTypes.number,
  items: PropTypes.shape([]),
  fieldType: PropTypes.string,
  initTemplates: PropTypes.func,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  onSearch: PropTypes.func,
  updateTemplateList: PropTypes.func,
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    length: PropTypes.number
  }).isRequired,
  theme: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  recordType: PropTypes.string,
  layoutId: PropTypes.string,
  intl: PropTypes.shape({ formatMessage: PropTypes.func })
};

RecordTemplateSelector.defaultProps = {
  label: '',
  maxCount: 1,
  items: [],
  fieldType: '',
  initTemplates: (e) => e,
  position: 0,
  getNextData: (e) => e,
  onSearch: (e) => e,
  updateTemplateList: (e) => e,
  theme: {},
  styles: {},
  recordType: '',
  layoutId: '',
  intl: PropTypes.shape({})
};

export default withTheme(injectIntl(RecordTemplateSelector));
