import { connect } from 'react-redux';
import ExecutingEntitySelector from './component';

const mapStateToProps = state => ({
  executingEntities: state.executingEntities.get('executingEntities'),
  position: state.executingEntities.get('position'),
  error: state.executingEntities.get('error'),
  searchResults: state.executingEntities.get('items')
});

const mapDispatchToProps = dispatch => ({
  initExecutingEntities: () => {
    dispatch({ type: 'EXECUTING_ENTITES:LIST:REQUEST:INIT' });
    dispatch({ type: 'EXECUTING_ENTITES:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'EXECUTING_ENTITES:LIST:REQUEST', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'EXECUTING_ENTITES_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ExecutingEntitySelector);
