import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ValueSelector from '../value-selector';

const LinkedRecords = (props) => {
  const {
    selectedField,
    initLinkedRecords,
    linkedRecords,
    hintTextLabel,
    getNextData,
    position,
    searchLinkedRecords
  } = props;

  const recordType = {
    linkedAssessments: 'assessment',
    linkedProcessings: 'processing',
    linkedTias: 'tia',
    linkedDocumentRecords: 'document',
    linkedBreaches: 'breach'
  }[selectedField.field];

  const handleOnFocus = () => {
    if (initLinkedRecords) {
      initLinkedRecords(recordType);
    }
  };

  return (
    <div>
      <ValueSelector
        {...props}
        valueAttr="recordNumber"
        searchResults={linkedRecords}
        dataItems={linkedRecords}
        onSearch={(searchParams) =>
          searchLinkedRecords(recordType, searchParams)
        }
        onScrollStop={() => getNextData(recordType, position)}
        onFocus={handleOnFocus}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        hintTextLabel={hintTextLabel}
      />
    </div>
  );
};

LinkedRecords.propTypes = {
  selectedField: PropTypes.shape({
    field: PropTypes.string
  }),
  hintTextLabel: PropTypes.node,
  initLinkedRecords: PropTypes.func,
  linkedRecords: PropTypes.instanceOf(Immutable.List),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  searchLinkedRecords: PropTypes.func
};

LinkedRecords.defaultProps = {
  selectedField: {},
  hintTextLabel: null,
  initLinkedRecords: (e) => e,
  linkedRecords: Immutable.List(),
  getNextData: (e) => e,
  position: 0,
  searchLinkedRecords: (e) => e
};

export default LinkedRecords;
