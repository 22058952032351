import { connect } from 'react-redux';
import Immutable from 'immutable';

import ProcessingRecordListComponent from './processingRecordList';

const recordType = 'processing';
const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  tenantLocale: state.login?.get('tenantLocale'),
  items: state.privacyRecords.list.get('items'),
  pageSearchText: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('processing').pageSearchText,
  sortOrder: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('processing').sortOrder,
  sortKey: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('processing').sortKey,
  filterColumn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('processing').filterColumn,
  filteredOn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('processing')
    .filteredOn.toJS(),
  position: state.privacyRecords.list.get('position'),
  isFetching: state.privacyRecords.list.get('isFetching'),
  pricingPlan: state.login?.get('pricingPlanDetails'),
  userPermissions: state.login?.get('loggedUser')
    ? state.login?.get('loggedUser').get('userPermissions')
    : Immutable.Map(),
  error: state.privacyRecords.list.get('error').toJS(),
  registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
  stakeholders: {
    items: state.environment.stakeholders.get('stakeholderList'),
    searchItems: state.environment.stakeholders.get('searchItems'),
    searchText: state.environment.stakeholders.get('searchText'),
    position: state.environment.stakeholders.get('position')
  },
  columns: state.privacyRecords.columns,
  isUpdating: state.processing?.get('isUpdating'),
  selectedRecords: state.privacyRecords.list.get('bulkItems').get('processing')
    .selectedRecords,
  visibleFields:
    state.fieldVisibilitySettings.get('visibilityFields').processing,
  filterMenuData: state.privacyRecords.filters.get('currentFilterData')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
      recordType,
      recordLayoutId
    });
  },
  getNextData: (position, rowCount, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount,
      recordLayoutId
    });
  },
  stakeholdersInit: (stakeholderEntityId) => {
    dispatch({
      type: 'STAKEHOLDERS:LIST:REQUEST_INIT',
      stakeholderEntityId
    });
  },
  getNextStakeholders: (position, stakeholderEntityId) => {
    dispatch({
      type: 'STAKEHOLDERS:LIST:NEXT_FETCH',
      position,
      stakeholderEntityId
    });
  },
  deletePrivacyRecord: (recordId) => {
    dispatch({ type: 'PRIVACY_RECORD:ITEM:DELETE', recordId, recordType });
  },
  onChooseFilter: (filterParams, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType,
      recordLayoutId
    });
  },
  stakeholderSearch: (searchParams, stakeholderEntityId) => {
    dispatch({
      type: 'STAKEHOLDERS:LIST:SEARCH_INIT',
      searchParams,
      stakeholderEntityId
    });
  },
  updateNote: (recordId, property, data, name, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType: 'processing',
      property,
      data,
      name,
      recordLayoutId
    });
  },
  copyProcessing: (data, copyOfRecordId, isGlobal) => {
    dispatch({
      type: 'PROCESSING:DETAIL:UPSERT',
      data,
      copyOfRecordId,
      isGlobal
    });
  },
  onRecordSelection: (selectedRecords) => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType,
      selectedRecords
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  toggleColumn: (columnName, isVisible) => {
    dispatch({
      type: 'PRIVACY_RECORDS:COLUMNS:SHOW_HIDE',
      columnName,
      isVisible
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingRecordListComponent);
