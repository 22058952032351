import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown, getRowCount } from '@packages/utils/common-utils';

export function* fetchSubProcessors(action) {
  const { position = 0, rowCount = getRowCount() } = action;
  try {
    const response = yield registry.get('request')
      .get(`/v1/records/processors?subProcessors=true&numberOfResults=${rowCount}&offset=${position}`, null);
    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({ type: 'SUBPROCESSORS:LIST:FETCH:SUCCESS', items: modifiedItems });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'SUBPROCESSORS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* searchSubProcessors(action) {
  let { searchParams } = action;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  const subProcessorsSelectorState = yield select(state => state.subProcessors.get('subProcessors'));

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = subProcessorsSelectorState ? subProcessorsSelectorState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  yield put({ type: 'SUBPROCESSORS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(
        `/v1/records/processors?subProcessors=true&numberOfResults=15&offset=0&search=${encodeURIComponent(searchText)}`
      );
    switch (response.status) {
      case 200: {
        const items = response.body;
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'SUBPROCESSORS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'SUBPROCESSORS:LIST:SEARCH:FAIL', error: err.message });
  }
}
