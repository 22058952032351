import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import MultipleSelector from './multipleSelector';

class MultipleSelectorDialog extends React.Component {
  static filterLegalEntities(entityDetailsId, selectedEntities, searchedItems) {
    let filteredData = [];
    if ((typeof (selectedEntities) === 'string') || entityDetailsId !== '') {
      filteredData = searchedItems.filter(legalEntity => (legalEntity.id !== selectedEntities));
      filteredData = filteredData.filter(legalEntity => (legalEntity.id !== entityDetailsId));
    } else {
      filteredData = searchedItems.filter((legalEntity) => {
        const index = selectedEntities.findIndex(selectedItem => (
          selectedItem.value ? legalEntity.name ===
        selectedItem.value.name : legalEntity.name ===
        selectedItem.name));
        return (index === -1);
      });
    }
    return filteredData;
  }


  constructor(props) {
    super(props);
    this.state = {
      multipleLegalEntityList: Immutable.List([])
    };
    this.requestClose = this.requestClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleFilterItems = this.handleFilterItems.bind(this);
  }

  componentDidMount() {
    if (this.props.init && !this.props.isImportEntity) {
      const entityFilter = (this.props.isGlobal || this.props.isVendor || this.props.isSubTenantDepartment) 
        && 'orgType=legalentity';
      this.props.init(this.props.isInternal, entityFilter);
    } else if (this.props.isImportEntity) {
      const entityFilter = this.props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
      this.props.fetchImportList(entityFilter);
    }
  }

  componentWillReceiveProps(nextProps) {
    const isHoldingChange = this.props.isImportEntity &&
     (this.props.holdingOrgList !== nextProps.holdingOrgList || nextProps.holdingOrgList.size > 0);
    if (this.props.legalEntities !== nextProps.legalEntities || nextProps.legalEntities.size > 0 || isHoldingChange) {
      const legalEntities = this.props.isImportEntity ? nextProps.holdingOrgList : nextProps.legalEntities;
      const multipleLegalEntityList = (this.props.selectedItems && this.props.selectedItems.length > 0) ||
      this.props.selectedOrgEntityId !== '' ? MultipleSelectorDialog.filterLegalEntities(this.props.selectedOrgEntityId,
          this.props.selectedItems, legalEntities) : legalEntities;

      if (multipleLegalEntityList && multipleLegalEntityList.size < 14 && legalEntities.size > 0
        && (this.props.position !== nextProps.position)) {
        let entityFilter = '';
        entityFilter = this.props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
        if (this.props.isGlobal || this.props.isVendor|| this.props.isSubTenantDepartment) 
          entityFilter = 'orgType=legalentity';

        this.props.getNextData(nextProps.position, entityFilter, this.props.isImportEntity);
      }
      this.setState({
        multipleLegalEntityList
      });
    }
  }

  componentWillUnmount() {
    if (this.props.resetfilteredFilters) {
      this.props.resetfilteredFilters();
    }
  }

  handleOnSubmit(selectedItems) {
    if (this.props.handleMultipleItems && selectedItems.size > 0) {
      const modifiedItems = selectedItems.map(item => ({ value: item }));
      this.props.handleMultipleItems(modifiedItems);
    }
    this.requestClose();
  }

  handleScrollEnd() {
    let entityFilter = '';
    entityFilter = this.props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
    if (this.props.isGlobal || this.props.isVendor || this.props.isSubTenantDepartment) 
      entityFilter = 'orgType=legalentity';

    this.props.getNextData(this.props.position, entityFilter, this.props.isImportEntity);
  }

  handleFilterItems(filterParams) {
    let entityFilter = '';
    if (this.props.isImportEntity) {
      entityFilter = this.props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
    }
    if (this.props.isVendor) entityFilter = 'orgType=legalentity';
    this.props.filterItems(filterParams, entityFilter, this.props.isImportEntity);
  }

  requestClose() {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  }

  render() {
    const { multiValue, isInternal, showFilters, isGlobal, isSubTenantDepartment } = this.props;
    return (
      <CommonDialog
        id="dialog"
        show={this.props.open}
        onCancel={this.requestClose}
        fullWidth={true}
        maxWidth="sm"
        title={this.props.title}
      >
        <MultipleSelector
          id="multiple-selector"
          key={this.props.key}
          showDepartment={!isGlobal && !isSubTenantDepartment}
          showOrganisationFilter={this.props.showOrganisationFilter}
          multiValue={multiValue}
          filteredData={this.state.multipleLegalEntityList}
          handleSubmit={this.handleOnSubmit}
          filterItems={this.handleFilterItems}
          onScrollStop={this.handleScrollEnd}
          onCancel={this.requestClose}
          showFilters={showFilters}
          isInternal={isInternal}
        />
      </CommonDialog>
    );
  }
}

MultipleSelectorDialog.propTypes = {
  position: PropTypes.number,
  legalEntities: PropTypes.instanceOf(Immutable.List),
  showOrganisationFilter: PropTypes.bool,
  key: PropTypes.string,
  resetfilteredFilters: PropTypes.func,
  filterItems: PropTypes.func,
  title: PropTypes.node,
  selectedOrgEntityId: PropTypes.string,
  multiValue: PropTypes.bool,
  isInternal: PropTypes.bool,
  isGlobal: PropTypes.bool,
  getNextData: PropTypes.func,
  init: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Immutable.List),
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  open: PropTypes.bool,
  isVendor: PropTypes.bool,
  showFilters: PropTypes.bool,
  holdingOrgList: PropTypes.arrayOf(PropTypes.shape({})),
  isLegalEntity: PropTypes.string,
  isImportEntity: PropTypes.bool,
  fetchImportList: PropTypes.func,
  isSubTenantDepartment: PropTypes.bool
};

MultipleSelectorDialog.defaultProps = {
  open: false,
  position: 0,
  multiValue: true,
  isInternal: undefined,
  isGlobal: false,
  showOrganisationFilter: true,
  onRequestClose: e => e,
  selectedOrgEntityId: '',
  resetfilteredFilters: e => e,
  filterItems: e => e,
  handleMultipleItems: e => e,
  title: null,
  getNextData: e => e,
  init: e => e,
  isVendor: false,
  showFilters: true,
  key: '',
  legalEntities: Immutable.List(),
  selectedItems: Immutable.List(),
  holdingOrgList: [],
  isLegalEntity: 'true',
  isImportEntity: false,
  fetchImportList: e => e,
  isSubTenantDepartment: false
};
export default MultipleSelectorDialog;
