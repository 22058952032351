import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import SimpleEntity from './simpleEntity';

const mapStateToProps = (state) => ({
  simpleEntities: state.environment.simpleEntities,
  isJobActive: state.environmentReducer.get('isJobActive'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id, isEdit, entityType, masterDataType) => {
    dispatch({
      type: 'SIMPLE_ENTITY:ITEM:FETCH',
      id,
      isEdit,
      entityType,
      masterDataType
    });
  },
  updateSimpleEntityData: (
    data,
    isEdit,
    source,
    entityType,
    masterDataType,
    isUsed,
    isEditWithJob
  ) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        data,
        actionType: 'Edit',
        masterDataType,
        entityType
      });
    } else {
      dispatch({
        type: 'SIMPLE_ENTITY:UPSERT',
        data,
        isEdit,
        source,
        entityType,
        masterDataType
      });
    }
  },
  resetSimpleEntity: (entityType) => {
    const dataType = entityType.toUpperCase();
    dispatch({ type: `MASTERDATA:${dataType}:ITEM:FETCH:INIT` });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleEntity);
