import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Cell } from 'fixed-data-table';
import { injectIntl } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FixedSizeList as List } from 'react-window';
import Clear from '@material-ui/icons/Clear';
import ActionHelp from '@material-ui/icons/Help';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSortAlphaDown, faSortAlphaUp  } from '@fortawesome/free-solid-svg-icons'
import { withTheme } from '@material-ui/core/styles';
import DivWrapper from '@packages/components/divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import { commonTranslations } from '@packages/utils/commontranslations';
import { sortItems } from '@packages/utils/common-utils';
import { isType } from '@packages/features/privacy-record-detail/common-utils';

import styles from '@packages/ui/styles';
import DateFilterDefault from './dateFilter';
import { renderSortArrow, renderFilterIcon } from '../utils/tableUtils';

const style = {
  popoverStyle: {
    margin: '10px',
    display: 'flex ',
    alignItems: 'center'
  },
  textField: {
    height: '40px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #9b9b9b',
    width: '97%',
    borderRadius: '3px'
  },
  textIndent: {
    textIndent: '10px'
  },
  sortButton: {
    marginRight: '15px',
    marginBottom: '5px',
    width: '40px',
    height: '40px',
    padding: '8px'
  },
  sortStyle: {
    paddingTop: '5px',
    paddingRight: '10px'
  },
  selectAllStyle: {
    paddingBottom: '30px'
  },
  checkBoxStyle: {
    marginTop: -8,
    marginLeft: 8
  },
  labelWithEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '145px'
  }
};

let timeout = null;

export class SortHeaderCell extends React.PureComponent {
  static findIndexOfItem(collection, itemToSearch) {
    const index = collection.findIndex((x) => {
      let ind = -1;
      if (typeof (x) === 'object' && typeof (itemToSearch) === 'object') {
        if (x.props && itemToSearch.props) {
          ind = x.props.defaultMessage === itemToSearch.props.defaultMessage;
        } else {
          ind = x.id === itemToSearch.id;
        }
      } else {
        ind = x === itemToSearch;
      }
      return ind;
    });
    return index;
  }

  listRef = React.createRef();

  constructor(props) {
    const { columnKey, searchKey, searchText } = props;
    super(props);
    this.state = {
      open: false,
      filterSelectAll: false,
      filteredOn: [],
      searchKey: searchKey || columnKey,
      currentSearchText: (searchText && searchText.get(searchKey || columnKey)) || ''
    };

    this.handleSortFilter = this.handleSortFilter.bind(this);
    this.handleIconRequestClose = this.handleIconRequestClose.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleFilterCheck = this.handleFilterCheck.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterIcon = this.handleFilterIcon.bind(this);
    this.handleDateFilter = this.handleDateFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { columnKey, searchKey, filteredOn, filterColumn } = nextProps;
    let filterItems = [];

    if (this.props.filteredOn !== filteredOn) {
      filterItems = filteredOn[columnKey] || filteredOn[filterColumn] || [];

      this.setState({
        filteredOn: filterItems,
        searchKey: searchKey || columnKey
      });
    }
    if (this.props.entityType !== nextProps.entityType || this.props.searchText !== nextProps.searchText) {
      this.setState({
        currentSearchText: (typeof nextProps.searchText === 'string') ?
          // eslint-disable-next-line react/no-access-state-in-setstate
          nextProps.searchText : nextProps.searchText.get(this.state.searchKey) || ''
      });
    }
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };


  handleIconRequestClose() {
    this.setState({
      open: false
    });
  }

  handleSortFilter(isSearchEnabled, isPageSearch, event) {
    event.preventDefault();
    if (isSearchEnabled && !this.state.open) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget.parentNode
      });
    }

    // Load search items when user selects the search dialog
    if (!isPageSearch && this.props.onSearch) {
      this.props.onSearch(this.props.columnKey, { searchKey: this.state.searchKey });
    }
  }

  handleSort(sortOrder) {
    this.handleFilterChange(this.state.filteredOn, this.props.columnKey, sortOrder);
  }

  handleSearch(event, isPageSearch = false, clearSearch = false) {
    const searchText = clearSearch ? '' : event.currentTarget.value;
    this.setState({
      currentSearchText: searchText
    });
    const { searchKey } = this.state;
    clearTimeout(timeout);

    if (this.props.onSearch) {
      if (clearSearch) {
        this.props.onSearch(this.props.columnKey, { searchKey, searchText, isPageSearch });
      } else {
        timeout = setTimeout(() => {
          this.props.onSearch(this.props.columnKey, { searchKey, searchText, isPageSearch });
        }, 1000);
      }
    }
  }

  handleTimeFilter = (date, time) => {
    const [hours, minutes] = time.split(':');
    const isDateString = (typeof date === 'string');
    if (isDateString) {
      const newDate = new Date(date);
      newDate.setHours(hours, minutes);
      return (newDate);
    }
    date.setHours(hours, minutes);
    return (date);
  }

  handleDateFilter(data) {
    let filteredOn = {};
    const { fromDate, toDate, fromTime, toTime } = data;
    const startDate = (fromDate !== '' && fromDate !== null) ?
      this.handleTimeFilter(fromDate, fromTime !== '' ? fromTime : '00:00') : '';
    filteredOn = { fromDate: startDate };
    const endDate = (toDate !== '' && toDate !== null) ?
      this.handleTimeFilter(toDate, toTime !== '' ? toTime : '23:59') : '';
    filteredOn = { ...filteredOn, toDate: endDate };
    this.handleFilterChange(filteredOn);
  }

  handleSelectAll(event, checked) {
    let filteredOn = [];
    if (checked) {
      filteredOn = this.props.filterValues.map(filterValue =>
        (filterValue.value === undefined ? filterValue : filterValue.value));
    }

    this.setState({
      filterSelectAll: checked,
      filteredOn
    });

    this.handleFilterChange(filteredOn);
  }

  handleFilterCheck(event, item, searchKey) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let filteredOn = [...this.state.filteredOn];
    const { multiValue } = this.props;

    if (event.target.checked) {
      if (!multiValue) filteredOn = [];
      filteredOn = (item === this.props.filterItem?.parent) ?
        // eslint-disable-next-line no-unsafe-optional-chaining
        [...filteredOn, ...this.props.filterItem?.dependant] : filteredOn;
      filteredOn.push(item);
    } else {
      if (item === this.props.filterItem?.parent) {
        filteredOn = filteredOn.filter(value => !(this.props.filterItem?.dependant.includes(value)));
      }
      const index = SortHeaderCell.findIndexOfItem(filteredOn, item);
      filteredOn.splice(index, 1);
    }

    const { filterValues } = this.props;
    this.setState({
      filteredOn,
      filterSelectAll: (filteredOn.length === filterValues.length),
      searchKey
    });

    this.handleFilterChange(filteredOn);
  }


  handleFilterChange(filteredOn, sortOn = this.props.sortKey, sortOrder = this.props.sortOrder) {
    if (this.props.onChooseFilter) {
      this.props.onChooseFilter(this.props.columnKey, { filteredOn, sortOn, sortOrder });
    }
  }

  handleFilterIcon(show) {
    if (this.props.handleFilterIcon) {
      this.props.handleFilterIcon(show);
    }
  }

  getDisabledStatus = (value) => {
    if (this.state.filteredOn.length > 0) {
      if (this.state.filteredOn[0] === '') return value !== '';
      return value === '';
    }
    return false;
  };

  renderAdditionalInfo = additionalInfo => (
    <ArrowTooltip title={additionalInfo}>
      <ActionHelp color="primary" style={{ marginBottom: '-7px', fontSize: 20 }} />
    </ArrowTooltip>);

  render() {
    const { children, enableSorting, enableFilter, enableSearch, pageSearchStyle, headerStyle,
      enablePageSearch, filterColumn, filteredOn, sortKey, sortOrder, enableAutoFocus,
      columnKey, showFilterIcon, multiValue, title, tooltip, dateRange, locale, filterValues,
      sortFilterValues, showSelectAll, columnStyle, showMergeCheck, onMergeReset, bulkItemsSize,
      onBulkReset, showBulkCheck, checkedItemsSize, additionalInfo, customStyle } = this.props;

    const modifiedPageSearchStyle = pageSearchStyle ? { ...styles.pageSearchField, ...pageSearchStyle } :
      styles.pageSearchField;
    // sorting introduced mainly to sort the country names displayed
    // in the filterbox especially when they are translated.
    const modifiedFilterValues = sortFilterValues ? sortItems(filterValues || [], 'ASC', 'label') : filterValues;
    const isSearchEnabled = enableSorting || enableFilter || enableSearch;
    const sortSelectedStyle = { ...style.sortButton, backgroundColor: this.props.theme.palette.primary.main };
    const { currentSearchText } = this.state;
    const filterBoxHeight = window.innerHeight - 360;
    // 360 = headerHeight + footerHeigt + containerHeaderHeight + headerMarginHeight +
    // tableMarginHeight  IEBottomToolbarHeight
    const enableFilterStyle = { marginLeft: '20px',
      minWidth: '150px',
      maxHeight: filterBoxHeight < 400 ? filterBoxHeight : 400, ...columnStyle};
    const { formatMessage } = this.props.intl;

    // eslint-disable-next-line react/no-unstable-nested-components
    const Row = (props) => {
      const { index } = props;
      const filterValue = modifiedFilterValues.get ? modifiedFilterValues.toJS()[index] : modifiedFilterValues[index];
      const value = filterValue.value === undefined ? filterValue : filterValue.value;
      let label = filterValue.label || filterValue;
      let key = value;
      if (typeof (value) === 'object') {
        if (value.props) {
          key = value.props.defaultMessage;
        } else {
          ({ key } = value);
          label = value.key;
        }
      }
      if (label.props) {
        label = formatMessage(label.props);
      }
      return (
        <div style={{ display: 'flex' }}>
          <FormGroup column={true}>
            <FormControlLabel
              control={
                <Checkbox
                  key={key}
                  title={tooltip && label}
                  color="primary"
                  style={styles.checkboxStyle}
                  checked={SortHeaderCell.findIndexOfItem(this.state.filteredOn, value) !== -1}
                  onChange={event => this.handleFilterCheck(event, value, columnKey)}
                  disabled={this.getDisabledStatus(value)}
                />}
              label={<ArrowTooltip title={label}><div style={style.labelWithEllipsis}>{label}</div></ArrowTooltip>}
              style={props.style}
            />
          </FormGroup>
        </div>);
    };
    const calculatedHeight = isType(modifiedFilterValues, 'array') &&
      modifiedFilterValues.length <= 5 ? modifiedFilterValues.length * 50 : 200;
    const cellHeader = (
      <div
        className={sortKey === columnKey ?
          'fixedDataTable_Column fixedDataTable_selectedColumn' :
          'fixedDataTable_Column'}
        style={headerStyle || {}}
      >
        <ArrowTooltip title={children}>
          <span>{children}</span>
        </ArrowTooltip>
        {showFilterIcon && 
        <FontAwesomeIcon icon={faFilter} />}
        {additionalInfo && this.renderAdditionalInfo(additionalInfo)}
        {renderSortArrow(sortKey, columnKey, sortOrder)}
        {renderFilterIcon(filterColumn, columnKey, filteredOn)}
      </div>
    );
    return (
      <div style={{ display: 'flex' }}>
        <div
          id="presentation"
          role="presentation"
          style={isSearchEnabled ? { cursor: 'pointer' } : {}}
          onMouseOver={() => this.handleFilterIcon(true)}
          onFocus={() => this.handleFilterIcon(true)}
          onMouseLeave={() => this.handleFilterIcon(false)}
          onClick={evt => this.handleSortFilter(isSearchEnabled, enablePageSearch, evt)}
        >
          
          <Cell style={{ minWidth: '50px' }}>
            {title ?
              <ArrowTooltip title={title}>
                {cellHeader}
              </ArrowTooltip> :
              cellHeader
              }
          </Cell>
          {showMergeCheck &&
            <FormGroup column={true}>
              <FormControlLabel
                classes={{
                  disabled: 'disabled_style'
                }}
                control={
                  <Checkbox
                    checked={bulkItemsSize > 0}
                    color="primary"
                    indeterminate={true}
                    disabled={bulkItemsSize < 2}
                    style={{ marginLeft: 8, marginTop: -20 }}
                    onChange={(event) => { onMergeReset(event); }}
                    onClick={event => event.stopPropagation()}
                  />}
                disabled={bulkItemsSize < 2}
              />
            </FormGroup>
          }
          {showBulkCheck &&
            <FormGroup column={true}>
              <FormControlLabel
                classes={{
                  disabled: 'disabled_style'
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={checkedItemsSize > 0}
                    disabled={checkedItemsSize < 1}
                    style={({ left: 18, marginTop: -20, ...customStyle})}
                    onChange={(event) => { onBulkReset(event); }}
                    onClick={event => event.stopPropagation()}
                  />}
                disabled={checkedItemsSize < 1}
              />
            </FormGroup>
          }
        </div>
        {isSearchEnabled && this.state.open &&
        <div>
          <Popover
            id="popover"
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={this.handleIconRequestClose}
          >
            <div style={style.popoverStyle}>
              {enableSorting &&
              <div style={style.sortStyle}>
                <FontAwesomeIcon
                  id="sort-alpha-down"
                  name="sort-alpha-down"
                  icon={faSortAlphaDown}
                  style={(sortKey === columnKey && sortOrder === 'ASC') ? sortSelectedStyle : style.sortButton}
                  onClick={() => this.handleSort('ASC')}
                />
                <FontAwesomeIcon
                  id="sort-alpha-up"
                  icon={faSortAlphaUp}
                  style={(sortKey === columnKey && sortOrder === 'DESC') ?
                    ({ ...sortSelectedStyle, marginRight: '0px'}) : style.sortButton}
                  onClick={() => this.handleSort('DESC')}
                />
              </div>
                  }
              {enableSearch &&
              <span id="column-search-field">
                <TextField
                  style={styles.pageSearchField}
                  value={currentSearchText}
                  onChange={this.handleSearch}
                  InputProps={{
                    endAdornment: (
                            <InputAdornment position="start">
                              {(currentSearchText && currentSearchText !== '') ?
                                <Clear
                                  id="clear"
                                  style={{ cursor: 'pointer' }}
                                  onClick={event => this.handleSearch(event, false, true)}
                                />
                                :
                                <Search />}
                            </InputAdornment>
                    )
                  }}
                />
              </span>
                  }
            </div>
            {(enableFilter || (modifiedFilterValues && modifiedFilterValues.length > 0)) &&
            <div
              style={enableFilterStyle}
            >
              {multiValue && showSelectAll &&
              <FormGroup id="all-formGroup" column={true}>
                <FormControlLabel
                  id="all-formControl"
                  control={
                    <Checkbox
                      key="all"
                      id="all"
                      color="primary"
                      style={styles.checkboxStyle}
                      checked={this.state.filterSelectAll}
                      onChange={this.handleSelectAll}
                    />}
                  style={{ paddingBottom: 20 }}
                  label={commonTranslations.selectAll}
                />
              </FormGroup>}
              <DivWrapper
                autoHeightMax={calculatedHeight}
                style={{ height: calculatedHeight, maxHeight: calculatedHeight }}
                onScroll={this.handleScroll}
                onScrollStop={this.props.onScrollStop}
              >
                <List
                  style={{ overflow: 'none' }}
                  height={calculatedHeight}
                  itemCount={modifiedFilterValues.length || modifiedFilterValues.size}
                  itemSize={40}
                  ref={this.listRef}
                >
                  {Row}
                </List>
              </DivWrapper>
            </div>
                }
            {(enableFilter || (filterValues && filterValues.length > 0)) && dateRange &&
            <DateFilterDefault
              id="date-field"
              submitFilterValues={this.handleDateFilter}
              locale={locale}
              filteredOn={filteredOn.timestamp || filteredOn.createdDate}
            />
                }
          </Popover>
        </div>
          }
        {enablePageSearch &&
        <div id="page-search-field" style={{ width: '300px' }}>
          <TextField
            className="text-field"
            autoFocus={enableAutoFocus}
            style={modifiedPageSearchStyle}
            value={currentSearchText}
            onChange={event => this.handleSearch(event, enablePageSearch)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {(currentSearchText && currentSearchText !== '') ?
                    <Clear
                      style={{ cursor: 'pointer' }}
                      onClick={event => this.handleSearch(event, enablePageSearch, true)}
                    />
                    :
                    <Search />}
                </InputAdornment>
              )
            }}
          />
        </div>}
      </div>
    );
  }
}

SortHeaderCell.displayName = 'SortHeaderCell';
SortHeaderCell.propTypes = {
  theme: PropTypes.objectOf(PropTypes.any),
  enableSorting: PropTypes.bool,
  enableFilter: PropTypes.bool,
  enablePageSearch: PropTypes.bool,
  enableSearch: PropTypes.bool,
  multiValue: PropTypes.bool,
  showFilterIcon: PropTypes.bool,
  sortFilterValues: PropTypes.bool,
  searchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.shape({
    timestamp: PropTypes.instanceOf(Immutable.List),
    createdDate: PropTypes.instanceOf(Immutable.List)
  }),
  filterValues: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.instanceOf(Immutable.List)
  ]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  filterColumn: PropTypes.string,
  dateRange: PropTypes.bool,
  showMergeCheck: PropTypes.bool,
  bulkItemsSize: PropTypes.number,
  onMergeReset: PropTypes.func,
  showSelectAll: PropTypes.bool,
  columnKey: PropTypes.string,
  searchKey: PropTypes.string,
  sortKey: PropTypes.string,
  enableAutoFocus: PropTypes.bool,
  sortOrder: PropTypes.string,
  handleFilterIcon: PropTypes.func,
  onScrollStop: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.node,
  locale: PropTypes.string,
  tooltip: PropTypes.bool,
  additionalInfo: PropTypes.string,
  columnStyle: PropTypes.shape({}),
  pageSearchStyle: PropTypes.shape({}),
  showBulkCheck: PropTypes.bool,
  onBulkReset: PropTypes.func,
  checkedItemsSize: PropTypes.number,
  entityType: PropTypes.string,
  headerStyle: PropTypes.shape({}),
  index: PropTypes.number,
  customStyle: PropTypes.shape({}),
  filterItem: PropTypes.shape({
    parent: PropTypes.string,
    dependant: PropTypes.string
  })
};

SortHeaderCell.displayName = 'SortHeaderCell';

SortHeaderCell.defaultProps = {
  theme: {},
  multiValue: true,
  enableFilter: false,
  enablePageSearch: false,
  enableAutoFocus: true,
  enableSorting: false,
  enableSearch: false,
  showFilterIcon: false,
  dateRange: false,
  showMergeCheck: false,
  bulkItemsSize: 0,
  onMergeReset: e => e,
  sortFilterValues: false,
  showSelectAll: true,
  additionalInfo: null,
  filterColumn: '',
  columnKey: '',
  searchKey: '',
  sortKey: '',
  locale: 'en',
  sortOrder: 'ASC',
  filterValues: [],
  searchText: Immutable.Map(),
  handleFilterIcon: e => e,
  onScrollStop: e => e,
  onChooseFilter: e => e,
  onSearch: e => e,
  children: null,
  title: null,
  filteredOn: {},
  tooltip: false,
  columnStyle: {},
  pageSearchStyle: {},
  showBulkCheck: false,
  onBulkReset: e => e,
  checkedItemsSize: 0,
  entityType: '',
  headerStyle: {},
  customStyle: {},
  index: -1,
  filterItem: {}
};
export default withTheme(injectIntl(SortHeaderCell));
