import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown } from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';

export function* getAuditTrailSettings() {
  try {
    const request = registry.get('request');
    const response = yield request.get('/v1/settings/audit');
    switch (response.status) {
      case 200: {
        yield put({
          type: 'SETTINGS:AUDIT_TRAIL:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* updateAUditTrailSettings(action) {
  try {
    const request = registry.get('request');
    const response = yield request.put('/v1/settings/audit', action.data);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.auditTrialSettingsUpdated,
            type: 'success'
          }
        });
        yield put({
          type: 'SETTINGS:AUDIT_TRAIL:UPDATE:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
