/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */
import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';

import { handleServiceDown } from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import {
  getLayoutWithSectionFields,
  getSectionsAndFields,
  getUpdatedObject,
  getChildChapter,
  getFieldsUrlWithParams
} from '../tenantConfigUtils';
import { tenantConfigurationTranslations } from '../tenantConfigurationTranslations';

export function* fetchLayoutChapter(action) {
  const { chapter, mode } = action;
  const isPreviewMode = mode === 'preview';
  const { id, libraryTenantId } = getParameterValuesFromHash(
    `/layout-${isPreviewMode ? 'preview' : 'editor'}/:id/:libraryTenantId`
  );

  yield put({ type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:INIT' });

  try {
    const modifiedChapter = chapter.split('_')[0];
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-layout${
          isPreviewMode ? `/${libraryTenantId}/data-library` : ''
        }/${id}/chapter/${modifiedChapter}${isPreviewMode ? '/preview' : ''}`
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:SUCCESS',
          data: {
            ...getLayoutWithSectionFields(response.body),
            chapters: getSetDefaultFilters(
              transformReqChapters(response.body.chapters)
            )
          }
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LAYOUT:EDITOR:GET:CHAPTER:FIELDS:FAIL' });
    yield handleServiceDown(err, 'custom');
  }
}

export function* saveChapter(action) {
  const store = registry.get('store');
  const { chapter, chapterData, nextChapter, prevLocation, tabIndex } = action;
  const modifiedChapterData = {
    ...chapterData,
    ...getSectionsAndFields(chapterData.fields),
    chapters: transformResChapters(chapterData.chapters)
  };

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  const { id } = getParameterValuesFromHash('/layout-editor/:id');
  try {
    const modifiedChapter = chapter.split('_')[0];
    const response = yield registry
      .get('request')
      .put(
        `v1/custom-record-layout/${id}/chapter/${modifiedChapter}`,
        modifiedChapterData
      );

    switch (response.status) {
      case 200: {
        yield put({ type: 'LOADER:TOGGLE', toggle: false });

        if (nextChapter)
          yield put({
            type: 'LAYOUT:EDITOR:FILTERS:CHANGE',
            filterName: nextChapter
          });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.recordLayoutUpdated,
            type: 'success'
          }
        });
        yield put({
          type: 'LAYOUT:EDITOR:UNSAVED_DATA:SET',
          value: false
        });
        if (prevLocation) store.dispatch(replaceRouter(prevLocation));
        if(tabIndex) yield put({ type: 'LAYOUT:EDITOR:TAB_INDEX:SET', value: tabIndex })
        break;
      }

      default: {
        yield put({ type: 'LOADER:TOGGLE', toggle: false });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });

    yield handleServiceDown(err, 'custom');
  }
}

const getWidthByFieldType = (fieldType) => {
  const fieldTypesWithFullWidth = ['htmlText','dataItemGroup','thirdPartyOrganisation','attachedDocuments']
  if(fieldTypesWithFullWidth.includes(fieldType))
    return 2 
  return 1
}

const transformNewFields = (data) =>
  data.map((item) => ({
    field: item,
    width: getWidthByFieldType(item.fieldType),
    layoutFieldUniqueId: item.uniqueId,
    fieldLabel: item.names || {}
  }));

const getRecordTypeFilters = (recordType) => {
  const recordTypeFilters = {
    assessments: ['assessment', 'custom'],
    processings: ['processing', 'custom'],
    breaches: ['breach', 'custom'],
    'document-records': ['document', 'custom']
  };
  return recordTypeFilters[recordType] || ['custom'];
};

export function* getFields(action) {
  yield put({ type: 'PICK_FIELDS:FETCH:RESET' });
  yield getFilteredFields(action);
}

export function* getFilteredFields(action) {
  const {
    filter,
    searchValue = null,
    recordType,
    position = 0,
    rowCount = 10
  } = action;
  yield put({ type: 'PICK_FIELDS:FETCH:SET:LOADER' });

  try {
    const urlParams = getFieldsUrlWithParams({
      rowCount,
      position,
      filter,
      recordTypeFilters: getRecordTypeFilters(recordType),
      searchValue
    });
    const url = `/v1/custom-fields?${urlParams}`;
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PICK_FIELDS:ITEM:FETCH:SUCCESS',
          data: transformNewFields(response.body)
        });
        break;
      }
      default: {
        yield put({
          type: 'PICK_FIELDS:ITEM:FETCH:FAIL'
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

const transformReqChapters = (menu, parent) => {
  const modifiedItems = menu.map((item, index) => ({
    ...getUpdatedObject(item, parent),
    position: index + 1,
    layoutUniqueId: item.id || item.layoutUniqueId,
    ...(item.subChapters && {
      subMenu: transformReqChapters(
        item.subChapters,
        getUpdatedObject(item).key
      )
    })
  }));
  return modifiedItems;
};

const transformResChapters = (menu, parent) => {
  const modifiedItems = menu.map((item, index) => ({
    ...getUpdatedObject(item, parent),
    position: index + 1,
    layoutUniqueId: item.id || item.layoutUniqueId,
    ...(item.subMenu && {
      subChapters: transformResChapters(
        item.subMenu,
        getUpdatedObject(item).key
      )
    })
  }));
  return modifiedItems;
};

// set Default filter for all parents
const getSetDefaultFilters = (menu) => {
  const modifiedItems = menu.map((item) => ({
    ...item,
    defaultFilter: getChildChapter(item),
    enable: true,
    ...(item.subMenu && {
      subMenu: getSetDefaultFilters(item.subMenu)
    })
  }));
  return modifiedItems;
};

export function* fetchLayoutRules(action) {
  const { layoutId, isRiskRule } = action;
  yield put({
    type: `LAYOUT:EDITOR:${isRiskRule ? 'RISK' : 'FIELD'}:RULES:INIT`
  });

  try {
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-layout/${layoutId}/${
          isRiskRule ? 'risk-rule' : 'field-rule'
        }`
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: `LAYOUT:EDITOR:${
            isRiskRule ? 'RISK' : 'FIELD'
          }:RULES:FETCH:SUCCESS`,
          data: response.body
        });
        break;
      }

      default: {
        yield put({
          type: `LAYOUT:EDITOR:${
            isRiskRule ? 'RISK' : 'FIELD'
          }:RULES:FETCH:FAIL`
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* fetchRule(action) {
  const { ruleId, isRiskRule } = action;
  yield put({ type: 'LAYOUT:EDITOR:RULE:ITEM:INIT', id: ruleId });

  try {
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-layout/${
          isRiskRule ? 'risk-rule' : 'field-rule'
        }/${ruleId}`
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'LAYOUT:EDITOR:RULE:ITEM:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }

      default: {
        yield put({
          type: `LAYOUT:EDITOR:${
            isRiskRule ? 'RISK' : 'FIELD'
          }:ITEM:FETCH:FAIL`,
          id: ruleId
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* fetchLayoutFieldList({ layoutId }) {
  try {
    const response = yield registry
      .get('request')
      .get(`v1/custom-record-layout/${layoutId}/fields`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'LAYOUT:TRIGGER:FIELDS:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* fetchActionFieldList({ layoutId }) {
  try {
    const response = yield registry
      .get('request')
      .get(`v1/custom-record-layout/${layoutId}/action-items`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'LAYOUT:ACTION:FIELDS:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* saveRule({ layoutId, ruleData, isRiskRule }) {
  try {
    const response = yield registry
      .get('request')
      .post(
        `v1/custom-record-layout/${layoutId}/${
          isRiskRule ? 'risk-rule' : 'field-rule'
        }`,
        ruleData
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: `LAYOUT:${isRiskRule ? 'RISK' : 'FIELD'}_RULE:SAVE:SUCCESS`,
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.ruleSaved,
            type: 'success'
          }
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* updateRule({ ruleData, isRiskRule }) {
  try {
    const response = yield registry
      .get('request')
      .put(
        `v1/custom-record-layout/${isRiskRule ? 'risk-rule' : 'field-rule'}/${
          ruleData.id
        }`,
        ruleData
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: `LAYOUT:${isRiskRule ? 'RISK' : 'FIELD'}_RULE:UPDATE:SUCCESS`,
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.ruleSaved,
            type: 'success'
          }
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* deleteRule({ id, isRiskRule }) {
  try {
    const response = yield registry
      .get('request')
      .delete(
        `/v1/custom-record-layout/${
          isRiskRule ? 'risk-rule' : 'field-rule'
        }/${id}`,
        null
      );

    switch (response.status) {
      case 204: {
        yield put({
          type: `LAYOUT:${isRiskRule ? 'RISK' : 'FIELD'}_RULE:DELETE:SUCCESS`,
          id
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: tenantConfigurationTranslations.successfullyDeletedRule,
            type: 'success'
          }
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}
