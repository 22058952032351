import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import countryTranslations from '@packages/components/country-selector/country';
import ValueSelector from '../value-selector';

export class CountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.caseInsensitiveFilter = this.caseInsensitiveFilter.bind(this);
    this.addKeytoCountry = this.addKeytoCountry.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems);
  }

  caseInsensitiveFilter(searchText, key) {
    const { formatMessage } = this.props.intl;
    const translatedCountry = formatMessage(key.country).toLowerCase();
    const formattedSearchText =
      typeof searchText === 'object'
        ? formatMessage(searchText.country)
        : searchText;
    return translatedCountry.indexOf(formattedSearchText.toLowerCase()) !== -1;
  }

  addKeytoCountry(item) {
    const { formatMessage } = this.props.intl;
    const selectedCountry = countryTranslations.find(
      (country) => country.value === item
    );
    const selectedItem = {
      id: item,
      key: selectedCountry
        ? `${formatMessage(selectedCountry.text.country)} (${item})`
        : item
    };
    return selectedItem;
  }

  render() {
    const { hintTextLabel, intl } = this.props;
    const { formatMessage } = intl;
    const items = [...this.props.selectedItems];

    this.props.selectedItems.splice(0, this.props.selectedItems.length);
    items.forEach((item) => {
      if (typeof item !== 'object') {
        const countryItem = this.addKeytoCountry(item);
        const { key, id } = countryItem;
        this.props.selectedItems.push({ key, id });
      } else {
        this.props.selectedItems.push(item);
      }
    });

    // Add a key element which is used to display the object in list view
    const countryList = countryTranslations.map((item) => ({
      ...item,
      id: item.value,
      key: `${formatMessage(item.text.country)} (${item.value})`
    }));

    return (
      <div>
        <ValueSelector
          id="country_value_selector"
          valueAttr="value"
          dataItems={countryList}
          liveAutocomplete={false}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          dataSourceFilter={this.caseInsensitiveFilter}
          formatMessage={formatMessage}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          title={
            <FormattedMessage
              id="MultipleCountryDialog.header"
              description="Select country Header"
              defaultMessage="Select countries"
            />
          }
          {...this.props}
        />
      </div>
    );
  }
}

CountrySelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  hintTextLabel: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

CountrySelector.defaultProps = {
  hintTextLabel: null
};

export default injectIntl(CountrySelector);
