/* eslint-disable import/no-cycle */
import React, { Fragment, useEffect, useReducer } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage } from 'react-intl';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { renderTextField } from '@packages/components/form-components';
import CustomDialog from '@packages/components/custom-dialog';
import styles from '@packages/ui/styles';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import {
  renderCheckBoxField,
  renderDateField,
  renderRadioGroup
} from '@packages/components/form-components/components';
import { getFileIcon, validateFile } from '@packages/utils/file-utils';
import ArrowTooltip from '@packages/components/tooltip';
import vendorTranslations from '@packages/utils/vendorTranslations';
import DivWrapper from '@packages/components/divWrapper';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import { isMasterDataEditFormVisible } from '../../../utils';
import ImpactAnalysisPage from '../../../impact-analysis';
import {
  renderChipSelector,
  renderItemSelector
} from '../../../../privacy-record-detail/renderers/renderers';
import documentTranslations from '../../../../privacy-record-detail/documents/utils/documentTranslations';

const validate = (values) => {
  const errors = {};
  let requiredFields = ['name'];
  if (values.isExternalLink !== 'false') {
    requiredFields = ['name', 'externalLink'];
  }
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });
  return errors;
};

const documentStyles = {
  displayStyle: { display: 'flex', marginTop: 10 },
  sourceStyle: {
    paddingLeft: '30px',
    width: '100%',
    display: 'flex'
  },
  dropZoneStyle: {
    width: '100%',
    height: '150px',
    border: ' 2px dashed',
    backgroundColor: '#EFEFEF',
    borderRadius: '10px',
    marginTop: '10px',
    outline: 'none'
  },
  radioGrpStyle: {
    display: 'flex',
    flexDirection: 'column'
  },
  radioWidth: {
    width: '28%'
  },
  documentSource: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  sourceWidth: {
    width: '90%'
  },
  dropStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px'
  },
  iconStyle: {
    fontSize: '60px'
  },
  fieldNameStyle: {
    width: '29%'
  },
  fieldStyle: {
    width: '71%'
  },
  noteStyle: {
    marginBottom: 10
  },
  hintStyle: {
    marginLeft: 5
  },
  tagSelectorStyle: {
    width: '90%'
  },
  buttonStyle: {
    float: 'left',
    marginTop: '25px'
  },
  cancelStyle: {
    float: 'right'
  },
  uploadStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '45px'
  },
  chooseFileStyle: {
    border: '2px solid',
    width: '36%',
    textAlign: 'center',
    boxShadow: '3px 3px',
    marginTop: '-12px'
  },
  dradDropTextStyle: {
    paddingTop: '10px'
  },
  inValidStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '65px',
    color: 'red',
    fontSize: '16px'
  }
};
const initialState = {
  open: false,
  closeDialog: true,
  onAfterUpdation: false,
  validateMsg: '',
  isPublic: false,
  enableExternalLink: 'true',
  invalidFile: false,
  file: [],
  linkField: '',
  isValid: true,
  isFile: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setOpen':
      return { ...state, open: action.payload };
    case 'setCloseDialog':
      return { ...state, closeDialog: action.payload };
    case 'setOnAfterUpdation':
      return { ...state, onAfterUpdation: true };
    case 'setValidateMsg':
      return { ...state, validateMsg: action.payload };
    case 'setIsPublic':
      return { ...state, isPublic: action.payload };
    case 'setEnableExternalLink':
      return { ...state, enableExternalLink: action.payload };
    case 'setInvalidFile':
      return { ...state, invalidFile: true };
    case 'setFile':
      return { ...state, file: action.payload };
    case 'setLinkField':
      return { ...state, linkField: action.payload };
    case 'setIsValid':
      return { ...state, isValid: action.payload };
    case 'setIsfile':
      return { ...state, isFile: action.payload };
    case 'startDate':
      return { ...state, startDate: action.payload };
    case 'endDate':
      return { ...state, endDate: action.payload };
    case 'overWriteCurrentVersion':
      return { ...state, overWriteCurrentVersion: action.payload };
    default:
      return state;
  }
};

export const DocumentsForm = (props) => {
  const {
    actionError,
    change,
    currentItem,
    dataItemId,
    deleteDocument,
    documentData,
    documentType,
    entityId,
    fromDocumentRecord,
    handleSubmit,
    hasNoLimit,
    init,
    initialize,
    isDelete,
    isEdit,
    isFetching,
    isQuickAdd,
    isReplace,
    isUploading,
    isUsed,
    isVendor,
    onCancel,
    onDelete,
    overWriteCurrentVersion,
    resetDocuments,
    showDate,
    submitting,
    toggleLoader,
    updateDocuments,
    usage,
    vendorActionError,
    vendorDocumentData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (isVendor) {
      dispatch({ type: 'setEnableExternalLink', payload: 'false' });
    }
    if (dataItemId) {
      init(dataItemId, isVendor);
    }
    if (!isEdit) {
      init('', false, true);
      handleInitialise();
    }
    return () => resetDocuments();
  }, []);

  useEffect(() => {
    if (!isVendor && documentData !== undefined) {
      if (state.onAfterUpdation) props.onSave(documentData, state.closeDialog);
      else handleInitialise(documentData);
    }
  }, [documentData]);

  useEffect(() => {
    if (isVendor && vendorDocumentData !== undefined) {
      if (state.onAfterUpdation && vendorDocumentData?.isDeleted) {
        onDelete(vendorDocumentData, state.closeDialog);
      } else if (state.onAfterUpdation)
        props.onSave(vendorDocumentData, state.closeDialog);
      else handleInitialise(vendorDocumentData);
    }
  }, [vendorDocumentData]);

  useEffect(() => {
    const content = (
      <FormattedMessage
        id="UploadFile.loader"
        description="Loader icon text"
        defaultMessage="Scanning for virus and uploading the file ...."
      />
    );
    toggleLoader(isUploading, content);
  }, [isUploading]);

  useEffect(() => {
    toggleLoader(isFetching);
  }, [isFetching]);

  const onSave = (documentData, closedialog) => {
    if (
      !isEdit &&
      state.enableExternalLink === 'false' &&
      state.file.length === 0 &&
      !state.isFile
    ) {
      dispatch({ type: 'setInvalidFile' });
    } else if (state.isValid) {
      let modifiedData = {
        ...documentData,
        ...(documentType && { entityId: documentType }),
        ...(entityId && { entityId }),
        ...(fromDocumentRecord &&
          !isEdit && {
          version: documentData.overWriteCurrentVersion
            ? currentItem?.version
            : (currentItem?.version || 0) + 1
        }),
        name: documentData.name.trim(),
        public: state.isPublic ?? false
      };

      if (state.file.length > 0) {
        modifiedData = {
          ...modifiedData,
          file: state.file[0]
        };
      }
      updateDocuments(
        modifiedData,
        isEdit,
        isReplace,
        isUsed,
        isVendor,
        fromDocumentRecord,
        isQuickAdd
      );
      dispatch({ type: 'setCloseDialog', payload: closedialog });
      dispatch({ type: 'setOnAfterUpdation' });
    }
  };

  const onDrop = (acceptedFiles) => {
    dispatch({ type: 'setFile', payload: acceptedFiles });
    const files = acceptedFiles[0];
    const { isValid, validateMsg } = validateFile(files);
    dispatch({ type: 'setValidateMsg', payload: validateMsg });
    dispatch({ type: 'setIsValid', payload: isValid });
    if (isValid) change('name', acceptedFiles[0].name);
  };

  const handleInitialise = (documentItem) => {
    if (isEdit || isReplace) {
      initialize(documentItem);
      dispatch({ type: 'setIsPublic', payload: documentData.public });
      dispatch({ type: 'startDate', payload: documentItem?.startDate });
      dispatch({ type: 'endDate', payload: documentItem?.endDate });
      if (documentData.id && documentData.externalLink === undefined) {
        const { validateMsg } = getFileIcon(documentData.name);
        dispatch({ type: 'setValidateMsg', payload: validateMsg });
        dispatch({ type: 'setIsValid', payload: true });
        dispatch({ type: 'setIsfile', payload: true });
        change('isExternalLink', 'false');
        dispatch({ type: 'setEnableExternalLink', payload: 'false' });
      }
    } else {
      initialize({});
    }
  };

  const handleExternalLink = (selected) => {
    if (state.file.length > 0 || state.isFile || state.linkField !== '') {
      dispatch({ type: 'setOpen', payload: true });
    } else {
      dispatch({ type: 'setEnableExternalLink', payload: selected });
    }
  };

  const handleProceed = () => {
    if (state.isFile) dispatch({ type: 'setIsfile', payload: false });
    dispatch({
      type: 'setEnableExternalLink',
      payload: state.enableExternalLink === 'true' ? 'false' : 'true'
    });
    dispatch({ type: 'setOpen', payload: false });
    dispatch({ type: 'setFile', payload: [] });
    dispatch({ type: 'setLinkField', payload: '' });
    change('externalLink', '');
    if (!isReplace) change('name', '');
  };

  const handleChangeProperty = (property, data) => {
    change(property, data);
  };

  const handleOverWriteClick = (checked) => {
    dispatch({ type: 'overWriteCurrentVersion', payload: checked });
    handleChangeProperty('overWriteCurrentVersion', checked);
  };

  const handleDeleteDocument = (documentData, closedialog) => {
    deleteDocument(dataItemId, isVendor);
    dispatch({ type: 'setCloseDialog', payload: closedialog });
    dispatch({ type: 'setOnAfterUpdation' });
  };

  const errorStyle = Object.assign({}, styles.errorMessage, {
    marginTop: '20px',
    marginLeft: '260px'
  });
  const showForm =
    !isDelete && isMasterDataEditFormVisible(isUsed, hasNoLimit, isReplace);
  const FORM_MAXHEIGHT = 395;
  const WINDOW_INNERHEIGHT = 660;
  return (
    <form>
      <DivWrapper
        autoHeight={true}
        autoHeightMax={getDialogContentHeight(
          WINDOW_INNERHEIGHT,
          FORM_MAXHEIGHT
        )}
        style={{ width: '100%' }}
      >
        {isUsed && (
          <ImpactAnalysisPage
            hasNoLimit={hasNoLimit}
            usage={usage}
            type="Document"
          />
        )}
        {showForm && (
          <div>
            <div style={documentStyles.displayStyle}>
              {commonTranslations.source}
              {(!isVendor || !isEdit) && (
                <div style={documentStyles.sourceStyle}>
                  <Field
                    name="isExternalLink"
                    valueSelected={isVendor ? false : state.enableExternalLink}
                    onSelect={handleExternalLink}
                    component={renderRadioGroup}
                    disabled={isEdit}
                    restrictCheck={true}
                    radioGrpStyle={documentStyles.radioGrpStyle}
                    style={documentStyles.radioWidth}
                  >
                    {!isVendor ? (
                      <div>
                        <FormControlLabel
                          value="true"
                          disabled={isEdit}
                          control={
                            <Radio
                              style={{
                                ...styles.checkboxStyle,
                                marginTop: '0px'
                              }}
                            />
                          }
                          label={
                            <span style={{ fontSize: '16px' }}>
                              {commonTranslations.externalLink}
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="false"
                          disabled={isEdit}
                          control={
                            <Radio
                              style={{
                                ...styles.checkboxStyle,
                                marginTop: '20px'
                              }}
                            />
                          }
                          label={
                            <span style={{ fontSize: '16px' }}>
                              {commonTranslations.file}
                            </span>
                          }
                        />
                      </div>
                    ) : (
                      <div style={{ width: '28%' }} />
                    )}
                  </Field>
                  <div style={documentStyles.documentSource}>
                    {!isVendor && (
                      <Field
                        id="externalLink"
                        name="externalLink"
                        style={documentStyles.sourceWidth}
                        hintTextLabel={commonTranslations.addExternalLink}
                        autoFocus={!isEdit}
                        disabled={
                          state.enableExternalLink === 'false' || isEdit
                        }
                        onBlur={(event, value) =>
                          dispatch({ type: 'setLinkField', payload: value })
                        }
                        component={renderTextField}
                      />
                    )}
                    <Dropzone
                      id="file"
                      name="file"
                      onDrop={onDrop}
                      multiple={false}
                      disabled={state.enableExternalLink === 'true' || isEdit}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section style={documentStyles.sourceWidth}>
                          <div
                            {...getRootProps()}
                            style={Object.assign(
                              {},
                              documentStyles.dropZoneStyle,
                              {
                                cursor:
                                  state.enableExternalLink === 'true'
                                    ? 'default'
                                    : 'pointer'
                              }
                            )}
                          >
                            <input {...getInputProps()} />
                            {state.file.length > 0 || state.isFile ? (
                              <div
                                style={
                                  state.isValid
                                    ? documentStyles.uploadStyle
                                    : documentStyles.inValidStyle
                                }
                              >
                                {state.validateMsg}
                              </div>
                            ) : (
                              <div
                                style={
                                  state.enableExternalLink === 'true'
                                    ? Object.assign(
                                      {},
                                      documentStyles.dropStyle,
                                      { opacity: 0.4 }
                                    )
                                    : documentStyles.dropStyle
                                }
                              >
                                <CloudUploadIcon
                                  style={documentStyles.iconStyle}
                                />
                                <div style={documentStyles.chooseFileStyle}>
                                  {' '}
                                  {commonTranslations.chooseFile}{' '}
                                </div>
                                <div style={documentStyles.dradDropTextStyle}>
                                  {' '}
                                  {commonTranslations.drag}{' '}
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {state.invalidFile && (
                      <div style={styles.errorMessage}>
                        <span>{errortranslations.required}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={documentStyles.displayStyle}>
              <div style={documentStyles.fieldNameStyle}>
                {commonTranslations.fileName}
              </div>
              <div style={documentStyles.fieldStyle}>
                <Field
                  id="name"
                  name="name"
                  disabled={!isVendor && (isEdit || isReplace)}
                  hintTextLabel={commonTranslations.addFileName}
                  component={renderTextField}
                />
              </div>
            </div>
            {(!fromDocumentRecord || isQuickAdd) && (
              <div style={documentStyles.displayStyle}>
                <div style={documentStyles.fieldNameStyle}>
                  {recordTranslations.description}
                </div>
                <div style={documentStyles.fieldStyle}>
                  <Field
                    id="description"
                    name="description"
                    hintTextLabel={commonTranslations.addDescription}
                    component={renderTextField}
                  />
                </div>
              </div>
            )}
            {isQuickAdd && (
              <div style={documentStyles.displayStyle}>
                <div
                  style={{ ...documentStyles.fieldNameStyle, marginTop: '40' }}
                >
                  {recordTranslations.documentType}
                </div>
                <div style={documentStyles.fieldStyle}>
                  <FieldArray
                    name="documentRecordTypes"
                    type="documentRecordTypes"
                    showHeader={false}
                    isEditable={false}
                    showEditIcon={false}
                    isNote={false}
                    hintText={documentTranslations.documentTypeHint}
                    itemLabel={recordTranslations.documentType}
                    component={renderItemSelector}
                    allowSaveAndCreate={false}
                    createNewMenuItem={false}
                    maxCount={1}
                    updateEntities={(item) =>
                      handleChangeProperty('documentRecordTypes', item)
                    }
                  />
                </div>
              </div>
            )}
            {showDate && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <Field
                  style={{ width: '20%' }}
                  id="startDate"
                  name="startDate"
                  label={recordTranslations.startDate}
                  hintTextLabel={commonTranslations.DateFormat}
                  maxDate={state.endDate}
                  component={renderDateField}
                  onSelect={(changeData) =>
                    dispatch({ type: 'startDate', payload: changeData })
                  }
                />
                <div style={{ marginBottom: '8px' }}>
                  <Field
                    style={{ width: '20%' }}
                    id="endDate"
                    name="endDate"
                    label={recordTranslations.endDate}
                    hintTextLabel={commonTranslations.DateFormat}
                    minDate={state.startDate}
                    component={renderDateField}
                    onSelect={(changeData) =>
                      dispatch({ type: 'endDate', payload: changeData })
                    }
                  />
                </div>
              </div>
            )}
            {overWriteCurrentVersion && (
              <Field
                id="overWriteCurrentVersion"
                name="overWriteCurrentVersion"
                hintTextLabel={commonTranslations.overWriteCurrentVersion}
                rows={3}
                selected={state.overWriteCurrentVersion}
                onCheck={(event, checked) => handleOverWriteClick(checked)}
                component={renderCheckBoxField}
              />
            )}
            {!isVendor && !fromDocumentRecord && (
              <>
                <div style={documentStyles.displayStyle}>
                  <div style={documentStyles.fieldNameStyle}>
                    {commonTranslations.permission}
                  </div>
                  <div>
                    {commonTranslations.public}
                    <Switch
                      checked={state.isPublic}
                      onChange={(event) =>
                        dispatch({
                          type: 'setIsPublic',
                          payload: event.target.checked
                        })
                      }
                      color="primary"
                    />
                  </div>
                  <ArrowTooltip
                    title={commonTranslations.permissionToggleHelpNote}
                  >
                    <IconButton style={{ marginTop: '-5px' }}>
                      <ActionHelp color="primary" />
                    </IconButton>
                  </ArrowTooltip>
                </div>
                <div style={documentStyles.displayStyle}>
                  <div style={documentStyles.fieldNameStyle}>
                    {commonTranslations.tags}
                  </div>
                  <div style={documentStyles.fieldStyle}>
                    <div style={documentStyles.noteStyle}>
                      {commonTranslations.note}:
                      <span style={documentStyles.hintStyle}>
                        {commonTranslations.maximumTagLimit}
                      </span>
                    </div>
                    <FieldArray
                      id="tags"
                      name="tags"
                      type="tags"
                      entityType="document"
                      headerLabel={commonTranslations.tags}
                      hintText={commonTranslations.addTags}
                      updateTags={(item) => handleChangeProperty('tags', item)}
                      chipSelectorStyle={{ width: '98%' }}
                      showHeader={false}
                      component={renderChipSelector}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </DivWrapper>
      {actionError?.isError && (
        <div style={errorStyle}>{actionError.message}</div>
      )}
      {vendorActionError?.isError && (
        <div style={errorStyle}>{vendorActionError.message}</div>
      )}
      <div style={documentStyles.buttonStyle}>
        {showForm && (
          <div>
            <Button
              id="submit"
              type="submit"
              disabled={submitting}
              onClick={handleSubmit((documentData) =>
                onSave(documentData, true)
              )}
            >
              {commonTranslations.saveAndClose}
            </Button>
            <Button variant="text" onClick={onCancel}>
              {commonTranslations.Cancel}
            </Button>
          </div>
        )}
        {!hasNoLimit && isUsed && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
      {state.open && (
        <CustomDialog
          id="external"
          show={state.open}
          proceed={() => handleProceed()}
          cancel={() => dispatch({ type: 'setOpen', payload: false })}
          content={
            state.enableExternalLink === 'true'
              ? commonTranslations.uploadFile
              : commonTranslations.provideLink
          }
        />
      )}
      {isDelete && (
        <div>
          {vendorTranslations.confirmAttachmentDelete}
          <div style={documentStyles.buttonStyle}>
            <Button variant="text" onClick={onCancel}>
              {commonTranslations.Cancel}
            </Button>
            <Button
              disabled={submitting}
              onClick={handleSubmit((documentData) =>
                handleDeleteDocument(documentData, true)
              )}
            >
              {recordTranslations.delete}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

DocumentsForm.propTypes = {
  isEdit: PropTypes.bool,
  isReplace: PropTypes.bool,
  documentData: PropTypes.shape({
    id: '',
    externalLink: '',
    name: '',
    public: true
  }),
  submitting: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  initialize: PropTypes.func,
  resetDocuments: PropTypes.func,
  updateDocuments: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  isUploading: PropTypes.bool,
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  handleUsageClick: PropTypes.func,
  change: PropTypes.func,
  toggleLoader: PropTypes.func,
  isQuickAdd: PropTypes.bool
};

DocumentsForm.defaultProps = {
  isEdit: false,
  isReplace: false,
  initialize: (e) => e,
  onSave: (e) => e,
  resetDocuments: (e) => e,
  updateDocuments: (e) => e,
  onCancel: (e) => e,
  handleSubmit: (e) => e,
  handleUsageClick: (e) => e,
  change: (e) => e,
  isUploading: false,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  documentData: {},
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  toggleLoader: (e) => e,
  isQuickAdd: false
};

const DocumentsFormWrapper = reduxForm({
  form: 'DocumentsForm', // a unique identifier for this form
  validate
})(DocumentsForm);

export default DocumentsFormWrapper;
