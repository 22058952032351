import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { transformOrganisationEntity } from '../organisation-entities/utils';

export function* resetAndfetchLegalEntities(action) {
  yield put({ type: 'LEGAL_ENTITY:LIST:REQUEST:INIT' });
  yield fetchLegalEntities(action);
}

export function* fetchLegalEntities(action) {
  const {
    position = 0,
    rowCount = MASTERDATA_FETCH_LIMIT,
    isPersistedData = false,
    hasTopDownLimit
  } = action;
  let { searchParams } = action;
  try {
    let filterParams = [];
    if (isPersistedData) {
      // using persisted filter while invoked from environment or while firing a getNext
      const legalEntitiesState = yield select((state) => state.legalEntities);
      filterParams = legalEntitiesState.get('filterParams');
      searchParams = legalEntitiesState.get('searchParams');
    }
    let url = `/v1/organisations?numberOfResults=${rowCount}&offset=${position}&filter=nonProspect=true`;
    if (action.isInternal !== undefined) {
      url = `${url}&filter=isInternal=${action.isInternal}`;
    } else if (filterParams) {
      filterParams.forEach((item) => {
        url = `${url}&filter=${Object.keys(item)}%3D${Object.values(item)}`;
      });
    }

    if (searchParams) {
      const { searchText, sortOn, sortOrder } = searchParams;
      url = searchText
        ? `${url}&search=name=${encodeURIComponent(searchText)}`
        : url;
      url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
    }
    if (hasTopDownLimit) {
      const loggedUserState = yield select((state) => state.login);
      let userId;
      if (loggedUserState) {
        userId = loggedUserState.get('loggedUser').get('userId');
      }
      url = `${url}&userId=${userId}`;
    }

    const response = yield registry.get('request').get(url, null);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) =>
          transformOrganisationEntity(item)
        );

        yield put({
          type: 'LEGAL_ENTITY:LIST:REQUEST:SUCCESS',
          items: modifiedItems
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'LEGAL_ENTITY:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* filterLegalEntities(action) {
  const filterParams = action.filterParams || {};
  const searchParams = action.searchParams || {};
  yield put({ type: 'LEGAL_ENTITY:LIST:FILTER', filterParams, searchParams });
  yield fetchLegalEntities(action);
}
export function* searchLegalEntities(action) {
  const { searchParams, isInternal } = action;
  const { searchKey, searchText, legalEntityType } = searchParams;
  yield put({
    type: 'LEGAL_ENTITY:LIST:SEARCH',
    searchKey,
    searchText,
    legalEntityType
  });
  yield put({
    type: `LEGAL_ENTITY:${legalEntityType}:LIST:SEARCH`,
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(getURLWithParams(searchParams, isInternal), null, {});

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.name} (${item.country})`
        }));
        yield put({
          type: `LEGAL_ENTITY:LIST:SEARCH:SUCCESS`,
          searchResults: modifiedItems,
          searchText,
          legalEntityType,
          searchKey
        });
        yield put({
          type: `LEGAL_ENTITY:${legalEntityType}:LIST:SEARCH:SUCCESS`,
          searchResults: modifiedItems,
          searchText
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    registry.get('logger').error(err);
    yield put({ type: 'LEGAL_ENTITY:LIST:SEARCH:FAIL', error: err.message });
    yield put({
      type: `LEGAL_ENTITY:${legalEntityType}LIST:SEARCH:FAIL`,
      error: err.message
    });
  }
}

const getURLWithParams = (searchParams, isInternal, position = 0) => {
  let url = `/v1/organisations?numberOfResults=${MASTERDATA_SEARCH_LIMIT}&offset=${position}&filter=nonProspect=true`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=name=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (isInternal) {
    url = `${url}&filter=isInternal=${isInternal}`;
  }
  return url;
};
