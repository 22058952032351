import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ItemList from '@packages/components/form-components/itemList';
import ProcessingGroundListDialog from './components/processingGroundListDialog';

const ProcessingGroundSelector = (props) => {
  const {
    removeFilters,
    jurisdiction,
    fields,
    updateProcessingGrounds,
    itemLabel,
    filterType
  } = props;

  useEffect(
    () => () => {
      removeFilters(jurisdiction);
    },
    []
  );

  // Handler to remove item
  const handleRemoveItem = (selectedIndex) => {
    const selectedProcessingGrounds = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedProcessingGrounds.splice(selectedIndex, 1);
    }
    saveProcessingGrounds(selectedProcessingGrounds);
  };

  // Handler to add items
  const handleSelectedItems = (selectedItems) => {
    const selectedProcessingGrounds = selectedItems.map((item) => ({
      ...item,
      value: {
        ...item.value,
        key: `${item.value.jurisdiction.id || item.value.jurisdiction}
        ${item.value.lawAbbreviation} ${item.value.article} - ${
        item.value.summary
      } `,
        content: item.value.content
      }
    }));
    saveProcessingGrounds(selectedProcessingGrounds);
  };

  // Handler to edit item
  const handleEditItem = (selectedItem, selectedIndex) => {
    const selectedProcessingGrounds = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedProcessingGrounds.push(selectedItem);
    } else {
      selectedProcessingGrounds.splice(selectedIndex, 1, selectedItem);
    }
    saveProcessingGrounds(selectedProcessingGrounds);
  };

  const saveProcessingGrounds = (processingGrounds) => {
    updateProcessingGrounds(processingGrounds);
  };

  const selectedProcessingGrounds = fields.getAll();

  return (
    <div>
      {selectedProcessingGrounds && selectedProcessingGrounds.length > 0 && (
        <ItemList
          isEditable={false}
          isNoteEditable={true}
          {...props}
          type={itemLabel}
          handleRemoveItem={handleRemoveItem}
          handleEditItem={handleEditItem}
          selectedItems={selectedProcessingGrounds}
        />
      )}
      <ProcessingGroundListDialog
        handleSelectedItems={handleSelectedItems}
        selectedItems={selectedProcessingGrounds}
        {...props}
        removeFilters={() => removeFilters(jurisdiction, filterType)}
      />
    </div>
  );
};

ProcessingGroundSelector.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  itemLabel: PropTypes.node,
  updateProcessingGrounds: PropTypes.func,
  jurisdiction: PropTypes.string,
  removeFilters: PropTypes.func,
  filterType: PropTypes.arrayOf(PropTypes.string)
};

ProcessingGroundSelector.defaultProps = {
  itemLabel: null,
  updateProcessingGrounds: (e) => e,
  jurisdiction: 'EU',
  removeFilters: (e) => e,
  filterType: []
};

export default ProcessingGroundSelector;
