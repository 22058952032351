import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  searchResults: Immutable.List(),
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LINK_GROUP:LIST:SEARCH': {
      return state
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'LINK_GROUP:LIST:SEARCH:SUCCESS':
      return state
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'LINK_GROUP:SEARCH:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
