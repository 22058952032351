import { takeEvery } from 'redux-saga/effects';

import {
  resetAndfetchAuditTrailMessages,
  fetchAuditTrailMessages,
  searchAuditTrailEvent,
  filterAuditTrailEvent
} from '@packages/features/dashboard/components/audit-trail/sagas';
import {
  resetAndFetchJobs,
  filterJobs,
  fetchJobs,
  retryOrTerminateJob,
  getBulkUpdateProgress,
  fetchJobDetails,
  fetchEntityDetails,
  fetchSubTenantsJob
} from '@packages/features/dashboard/components/jobs/sagas';
import {
  removeDashboardLayout,
  fetchAllDashboards,
  fetchAllWidgets,
  saveDashboard,
  saveDashboardLayout,
  shareDashboardLayout,
  setFavouriteDashboard
} from './sagas';

export default [
  [takeEvery, 'JOBS:LIST:REQUEST:INIT', resetAndFetchJobs],
  [takeEvery, 'JOBS:FILTER_INIT', filterJobs],
  [takeEvery, 'JOBS:LIST:NEXT_FETCH', fetchJobs],
  [takeEvery, 'JOBS:ACTION:REQUEST', retryOrTerminateJob],
  [takeEvery, 'JOB:DETAILS:FETCH:INIT', fetchJobDetails],
  [takeEvery, 'JOB:OLD_DETAILS:FETCH:INIT', fetchEntityDetails],
  [takeEvery, 'JOB:BULK_UPDATE:PROGRESS', getBulkUpdateProgress],
  [takeEvery, 'SUB_TENANT:JOB:LIST:FETCH', fetchSubTenantsJob],

  [takeEvery, 'AUDIT_TRAIL:LIST:INIT', resetAndfetchAuditTrailMessages],
  [takeEvery, 'AUDIT_TRAIL:LIST:NEXT_FETCH', fetchAuditTrailMessages],
  [takeEvery, 'AUDIT_TRAIL:LIST:FILTER_INIT', filterAuditTrailEvent],
  [takeEvery, 'AUDIT_TRAIL:LIST:SEARCH_INIT', searchAuditTrailEvent],

  [takeEvery, ['TENANT_DASHBOARD:LAYOUTS:FETCH_ALL:INIT',
    'HOLDING_DASHBOARD:LAYOUTS:FETCH_ALL:INIT'], fetchAllDashboards],
  [takeEvery, 'DASHBOARD:SAVE:INIT', saveDashboard],
  [takeEvery, 'DASHBOARD:FETCH:WIDGETS', fetchAllWidgets],
  [takeEvery, 'DASHBOARD:LAYOUT:REMOVE', removeDashboardLayout],
  [takeEvery, 'DASHBOARD:LAYOUT:SAVE:INIT', saveDashboardLayout],
  [takeEvery, 'DASHBOARD:LAYOUT:SHARE', shareDashboardLayout],
  [takeEvery, 'DASHBOARD:SET:FAVOURITE', setFavouriteDashboard]
];
