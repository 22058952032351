import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* searchRequester(action) {
  let { searchParams } = action;

  const { searchKey, searchText } = searchParams;

  searchParams = Object.assign({}, searchParams, { searchText });

  yield put({ type: 'DSR:REQUESTER:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, action.recordType), null, {});
    const items = response.body;
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: (item.firstName || item.lastName) ?
            `${item.dataSubjectNumber} - ${item.firstName || ''} ${item.lastName || ''}` : item.dataSubjectNumber,
          text: (item.firstName || item.lastName) ?
            `${item.dataSubjectNumber} - ${item.firstName || ''} ${item.lastName || ''}` : item.dataSubjectNumber
        }));
        yield put({
          type: 'DSR:REQUESTER:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    registry.get('logger').error(err);
    yield put({ type: 'DSR:REQUESTER:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* getRequestCount(action) {
  yield put({ type: 'REQUEST:COUNT:FETCH:INIT' });
  const { id } = action;
  let response;
  try {
    response = yield registry.get('request')
      .get(`/v1/dsrmanagement/datasubjects/${id}/dsrcount`, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'REQUEST:COUNT:FETCH:SUCCESS',
          requestCount: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'REQUEST:COUNT:FETCH:FAIL', error: err.message });
  }
}

const getURLWithParams = (searchParams) => {
  let url = `/v1/dsrmanagement/datasubjects`;
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}?search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
