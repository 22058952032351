import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import TextField from '@material-ui/core/TextField';

import { validateEmail } from '@packages/utils/common-utils';
import styles from '@packages/ui/styles';
import errortranslations from '@packages/utils/errortranslations';
import ItemList from './itemList';


class ItemCollections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event) {
    this.setState({ content: event.target.value, error: '' });
    this.props.handleChangeItem();
  }


  handleKeyDown(event) {
    if (this.props.onKeyDown) this.props.onKeyDown(event);

    if (keycode(event) === 'enter') {
      event.preventDefault();
      this.addItem(this.state.content);
    }
  }

  handleBlur() {
    const email = this.state.content;
    if (email && email.length > 0) {
      this.addItem(email);
    }
  }

  addItem(email) {
    if (validateEmail(email)) {
      this.props.fields.push(this.props.fields.name === 'admins' ? { emailId: email } : email);
      this.setState({
        content: '',
        error: ''
      });
      this.props.handleChangeItem();
    } else {
      this.setState({
        error: errortranslations.invalidEmail
      });
    }
  }

  render () {
    const { id, customStyle } = this.props; // ID for testing
    return (
      <div>
        <ItemList
          {...this.props}
          id="items_collection"
          isNote={false}
          isEditable={false}
          type={this.props.label}
          handleRemoveItem={selectedIndex => this.props.handleRemoveItem(this.props.fields, selectedIndex)}
          customRowStyle={customStyle}
        />
        <div style={styles.textBox}>
          <TextField
            {...(id && { id })}
            variant="outlined"
            value={this.state.content}
            placeholder={this.props.hintTextLabel}
            style={{ ...styles.textField, width: '100%', ...customStyle }}
            rows={2}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
          />
        </div>
        {this.state.error &&
          <div style={Object.assign({}, styles.errorMessage, { marginTop: 5 })}>
            Error: {this.state.error}
          </div>
        }
      </div>
    );
  }
}

ItemCollections.propTypes = {
  id: PropTypes.string,
  onKeyDown: PropTypes.func,
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
    name: PropTypes.string
  }).isRequired,
  label: PropTypes.node,
  handleChangeItem: PropTypes.func,
  hintTextLabel: PropTypes.node,
  customStyle: PropTypes.shape({}),
  handleAdminUserDelete: PropTypes.func,
  handleRemoveItem: PropTypes.func
};

ItemCollections.defaultProps = {
  onKeyDown: e => e,
  label: null,
  id: '',
  handleChangeItem: e => e,
  hintTextLabel: null,
  customStyle: {},
  handleAdminUserDelete: e => e,
  handleRemoveItem: e => e
};

export default ItemCollections;
