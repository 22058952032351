import { connect } from 'react-redux';
import OrganisationImport from './organisationImport';

const mapStateToProps = state => ({
  organisationEntities: state.organisationEntities.get('organisationEntities'),
  holdingOrgList: state.organisationEntities.get('holdingOrgList'),
  parentFound: state.organisationEntities.get('parentFound')
});

const mapDispatchToProps = dispatch => ({
  fetchOrgList: (entityFilter) => {
    dispatch({ type: 'SUBTENANT:ORGANISATION:LIST:FETCH', entityFilter });
  },
  saveData: (data) => {
    dispatch({ type: 'SUBTENANT:ORGANISATION:DETAILS:IMPORT', data });
  },
  init: (isInternal, entityFilter, isImport) => {
    dispatch({ type: 'ORGANISATION_ENTITY:LIST:REQUEST', isInternal, entityFilter, isImport });
  },
  getParentData: (entityId) => {
    dispatch({ type: 'PARENT:ORGANISATION_ENTITY:DETAIL:INIT', entityId });
  },
  resetParent: () => {
    dispatch({ type: 'PARENT:ORGANISATION_ENTITY:ITEM:RESET' });
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(OrganisationImport);
