import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

const CustomMasterDataSelector = (props) => {
  const {
    hintTextLabel,
    customSimpleMasterData,
    initCustomMasterData,
    onSearch,
    getNextData,
    position,
    onSelect,
    removeItem,
    entityType
  } = props;

  const handleOnFocus = () => {
    if (initCustomMasterData) {
      initCustomMasterData(entityType);
    }
  };

  const handleSearch = (searchParams) => {
    if (onSearch) {
      onSearch(searchParams, entityType);
    }
  };

  const handleNextData = () => {
    getNextData(position, entityType);
  };

  return (
    <div>
      <ValueSelector
        {...props}
        onSelect={onSelect}
        removeItem={removeItem}
        valueAttr="name"
        dataItems={customSimpleMasterData}
        searchResults={customSimpleMasterData}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        onFocus={handleOnFocus}
        title={
          <FormattedMessage
            id="MultipleControllerDialog.header"
            description="Select controller Header"
            defaultMessage="Select controllers"
          />
        }
      />
    </div>
  );
};

CustomMasterDataSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  removeItem: PropTypes.func,
  onSelect: PropTypes.func,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  initCustomMasterData: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  customSimpleMasterData: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func,
  entityType: PropTypes.string
};

CustomMasterDataSelector.defaultProps = {
  hintTextLabel: null,
  fields: {},
  initCustomMasterData: (e) => e,
  onSelect: (e) => e,
  removeItem: (e) => e,
  getNextData: (e) => e,
  position: 0,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  customSimpleMasterData: Immutable.List(),
  entityType: ''
};

export default CustomMasterDataSelector;
