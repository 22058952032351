import React from 'react';
import { FormattedMessage } from 'react-intl';

import { singularTerms } from '@packages/utils/uppercaseTranslations';

export const tenantConfigurationTranslations = {
  picklistOptionCreated: (
    <FormattedMessage
      id="tenantConfiguration.picklistOptionCreated"
      defaultMessage="Option item created"
    />
  ),
  picklistOptionUpdated: (
    <FormattedMessage
      id="tenantConfiguration.picklistOptionUpdated"
      defaultMessage="Option item updated"
    />
  ),
  picklistOptionDeleted: (
    <FormattedMessage
      id="tenantConfiguration.picklistOptionDeleted"
      defaultMessage="Option item deleted"
    />
  ),
  picklistOptionInUse: (
    <FormattedMessage
      id="tenantConfiguration.picklistOptionInUse"
      defaultMessage="Cannot delete this option as it is being used in records"
    />
  ),
  picklistDeleteWarning: (
    <FormattedMessage
      id="tenantConfiguration.picklistDeleteWarning"
      defaultMessage="Are you sure that you want to delete the option ?"
    />
  ),
  recordTypeDeleteConfirmation: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeDeleteConfirmation"
      defaultMessage="Record type deleted"
    />
  ),
  recordTypeEditConfirmation: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeEditConfirmation"
      defaultMessage="Record type updated"
    />
  ),
  recordTypeCreateConfirmation: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeCreateConfirmation"
      defaultMessage="Record type created"
    />
  ),
  editRecordType: (
    <FormattedMessage
      id="tenantConfiguration.editRecordType"
      defaultMessage="Edit record type"
    />
  ),
  createRecordType: (
    <FormattedMessage
      id="tenantConfiguration.createRecordType"
      defaultMessage="Create record type"
    />
  ),
  chooseRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.chooseRecordLayout"
      defaultMessage="Choose record layout"
    />
  ),
  chooseRecordType: (
    <FormattedMessage
      id="tenantConfiguration.chooseRecordType"
      defaultMessage="Choose record type"
    />
  ),
  noFields: (
    <FormattedMessage
      id="tenantConfiguration.noFields"
      defaultMessage="No fields to show"
    />
  ),
  New: <FormattedMessage id="tenantConfiguration.New" defaultMessage="New" />,
  Existing: (
    <FormattedMessage
      id="tenantConfiguration.Existing"
      defaultMessage="Existing"
    />
  ),
  chapterContents: (
    <FormattedMessage
      id="tenantConfiguration.chapterContents"
      defaultMessage="Chapter contents"
    />
  ),
  derivedFrom: (
    <FormattedMessage
      id="tenantConfiguration.derivedFrom"
      defaultMessage="Derived from"
    />
  ),
  recordTypeName: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeName"
      defaultMessage="Record layout name"
    />
  ),
  recordTypeDetails: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeDetails"
      defaultMessage="Record type details"
    />
  ),
  customLayoutUpdateConfirmation: (
    <FormattedMessage
      id="tenantConfiguration.customLayoutUpdateConfirmation"
      defaultMessage="Layout updated successfully"
    />
  ),
  customFieldDeleteConfirmation: (
    <FormattedMessage
      id="tenantConfiguration.customFieldDeleteConfirmation"
      defaultMessage="Item has been successfully deleted"
    />
  ),
  customFieldDeleteMessage: (
    <FormattedMessage
      id="tenantConfiguration.customMasterdataDeleteConfirmation"
      defaultMessage="Are you sure that you want to delete the field ?"
    />
  ),
  itemListDeleteMessage: (
    <FormattedMessage
      id="tenantConfiguration.itemListDeleteMessage"
      defaultMessage="Are you sure that you want to delete this item ?"
    />
  ),
  masterDataItemInUse: (
    <FormattedMessage
      id="tenantConfiguration.customMasterdataInUse"
      defaultMessage="Cannot delete this masterdata item as it is being used in environment"
    />
  ),
  customFieldInUse: (
    <FormattedMessage
      id="tenantConfiguration.customFieldInUse"
      defaultMessage="Cannot delete this field as it is being used in layouts"
    />
  ),
  itemListInUse: (
    <FormattedMessage
      id="tenantConfiguration.itemListInUse"
      defaultMessage="Cannot delete this item as it is already in use"
    />
  ),
  fieldUid: (
    <FormattedMessage
      id="tenantConfiguration.fieldUid"
      defaultMessage="Field UID"
    />
  ),
  addNewMasterdataField: (
    <FormattedMessage
      id="tenantConfiguration.addNewMasterdataField"
      defaultMessage="Add new masterdata field"
    />
  ),
  editMasterdataField: (
    <FormattedMessage
      id="tenantConfiguration.editMasterdataField"
      defaultMessage="Edit masterdata field"
    />
  ),
  deleteMasterdataField: (
    <FormattedMessage
      id="tenantConfiguration.deleteMasterdataField"
      defaultMessage="Delete masterdata field"
    />
  ),
  deleteField: (
    <FormattedMessage
      id="tenantConfiguration.deleteField"
      defaultMessage="Delete field"
    />
  ),
  simpleMasterDataItems: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterDataItems"
      defaultMessage="Simple masterdata items"
    />
  ),
  simpleMasterDataItem: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterDataItem"
      defaultMessage="Simple masterdata item"
    />
  ),
  editSimpleMasterDataItem: (
    <FormattedMessage
      id="tenantConfiguration.editSimpleMasterDataItem"
      defaultMessage="Edit simple master data item"
    />
  ),
  addNewSimpleMasterDataItem: (
    <FormattedMessage
      id="tenantConfiguration.addNewSimpleMasterDataItem"
      defaultMessage="Add new simple master data item"
    />
  ),
  simpleMasterDataFields: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterDataFields"
      defaultMessage="Simple masterdata fields"
    />
  ),
  dropDown: (
    <FormattedMessage
      id="tenantConfiguration.dropDown"
      defaultMessage="Dropdown"
    />
  ),
  dropDownLists: (
    <FormattedMessage
      id="tenantConfiguration.dropDownLists"
      defaultMessage="Dropdown lists"
    />
  ),
  dropDownList: (
    <FormattedMessage
      id="tenantConfiguration.dropDownList"
      defaultMessage="Dropdown list"
    />
  ),
  addNewDropDownList: (
    <FormattedMessage
      id="tenantConfiguration.addDropDownList"
      defaultMessage="Add new dropdown list"
    />
  ),
  dropDownFields: (
    <FormattedMessage
      id="tenantConfiguration.dropDownFields"
      defaultMessage="Dropdown fields"
    />
  ),
  scoredDropDown: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropDown"
      defaultMessage="Scored dropdown"
    />
  ),
  scoredDropDownLists: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropDownLists"
      defaultMessage="Scored dropdown lists"
    />
  ),
  scoredDropDownList: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropDownList"
      defaultMessage="Scored dropdown list"
    />
  ),
  addNewScoredDropDownList: (
    <FormattedMessage
      id="tenantConfiguration.addNewScoredDropDownList"
      defaultMessage="Add new scored dropdown list"
    />
  ),
  scoredDropDownFields: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropDownFields"
      defaultMessage="Scored dropdown fields"
    />
  ),
  addNewDropDownField: (
    <FormattedMessage
      id="tenantConfiguration.addNewDropDownField"
      defaultMessage="Add new dropdown field"
    />
  ),
  editDropDownField: (
    <FormattedMessage
      id="tenantConfiguration.editDropDownField"
      defaultMessage="Edit dropdown field"
    />
  ),
  addNewScoredDropDownField: (
    <FormattedMessage
      id="tenantConfiguration.addNewScoredDropDownField"
      defaultMessage="Add new scored dropdown field"
    />
  ),
  editScoredDropDownField: (
    <FormattedMessage
      id="tenantConfiguration.editScoredDropDownField"
      defaultMessage="Edit scored dropdown field"
    />
  ),
  recordCount: (
    <FormattedMessage
      id="tenantConfiguration.recordCount"
      defaultMessage="Record count"
    />
  ),
  markAsActive: (
    <FormattedMessage
      id="tenantConfiguration.markAsActive"
      defaultMessage="Mark as active"
    />
  ),
  markAsInctive: (
    <FormattedMessage
      id="tenantConfiguration.markAsInctive"
      defaultMessage="Mark as inactive"
    />
  ),
  tenantConfiguration: (
    <FormattedMessage
      id="tenantConfiguration.tenantConfiguration"
      defaultMessage="Tenant Configuration "
    />
  ),
  recordTypes: (
    <FormattedMessage
      id="tenantConfiguration.recordTypes"
      defaultMessage="Record Types "
    />
  ),
  addAdditionalField: (
    <FormattedMessage
      id="tenantConfiguration.addAdditionalField"
      defaultMessage="Add additonal information"
    />
  ),
  additionalFields: (
    <FormattedMessage
      id="tenantConfiguration.additionalFields"
      defaultMessage="Additional information"
    />
  ),
  inputType: (
    <FormattedMessage
      id="tenantConfiguration.inputType"
      defaultMessage="Type of input"
    />
  ),
  simpleText: (
    <FormattedMessage
      id="tenantConfiguration.simpleText"
      defaultMessage="Simple text"
    />
  ),
  htmlText: (
    <FormattedMessage
      id="tenantConfiguration.htmlText"
      defaultMessage="Rich text"
    />
  ),
  addRecordTypes: (
    <FormattedMessage
      id="tenantConfiguration.addRecordTypes"
      defaultMessage="Add record types "
    />
  ),
  name: (
    <FormattedMessage id="tenantConfiguration.name" defaultMessage="Name " />
  ),
  idPrefix: (
    <FormattedMessage
      id="tenantConfiguration.idPrefix"
      defaultMessage="ID Prefix"
    />
  ),
  status: (
    <FormattedMessage id="tenantConfiguration.status" defaultMessage="Status" />
  ),
  addNewRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.addNewRecordLayout"
      defaultMessage="Add new record layout"
    />
  ),
  confirmCancelCreate: (
    <FormattedMessage
      id="tenantConfiguration.confirmCancelCreate"
      description="Your message will be lost if you move away. Are you sure you want to proceed?"
      defaultMessage="Your message will be lost if you move away. Are you sure you want to proceed?"
    />
  ),
  save: (
    <FormattedMessage id="tenantConfiguration.save" defaultMessage="Save" />
  ),
  recordType: (
    <FormattedMessage
      id="tenantConfiguration.recordType"
      defaultMessage="Record Type"
    />
  ),
  language: (
    <FormattedMessage
      id="tenantConfiguration.language"
      defaultMessage="Language"
    />
  ),
  layoutCreated: (
    <FormattedMessage
      id="tenantConfiguration.layoutCreated"
      defaultMessage="Layout created"
    />
  ),
  tia: <FormattedMessage id="tenantConfiguration.tia" defaultMessage="tia" />,
  assessment: (
    <FormattedMessage
      id="tenantConfiguration.assessment"
      defaultMessage="assessment"
    />
  ),
  breach: (
    <FormattedMessage id="tenantConfiguration.breach" defaultMessage="breach" />
  ),
  processing: (
    <FormattedMessage
      id="tenantConfiguration.processing"
      defaultMessage="processing"
    />
  ),
  document: (
    <FormattedMessage
      id="tenantConfiguration.document"
      defaultMessage="document"
    />
  ),
  addAdditionalLanguage: (
    <FormattedMessage
      id="tenantConfiguration.addAdditionalLanguage"
      description="Add additional language"
      defaultMessage="Add additional language"
    />
  ),
  chooseLanguage: (
    <FormattedMessage
      id="tenantConfiguration.chooseLanguage"
      description="Choose Language"
      defaultMessage="Choose Language"
    />
  ),
  chooseLayout: (
    <FormattedMessage
      id="tenantConfiguration.chooseLayout"
      description="Choose Layout"
      defaultMessage="Choose Layout"
    />
  ),
  addNewRecordType: (
    <FormattedMessage
      id="tenantConfiguration.addNewRecordType"
      defaultMessage="Add new record type"
    />
  ),
  uniqueFieldId: (
    <FormattedMessage
      id="tenantConfiguration.uniqueFieldId"
      defaultMessage="Unique field id"
    />
  ),
  uniqueId: (
    <FormattedMessage
      id="tenantConfiguration.uniqueId"
      defaultMessage="Unique id"
    />
  ),
  customFields: (
    <FormattedMessage
      id="tenantConfiguration.customFields"
      defaultMessage="Custom fields"
    />
  ),
  customFieldCreated: (
    <FormattedMessage
      id="tenantConfiguration.customFieldCreated"
      defaultMessage="Custom field created"
    />
  ),
  customFieldUpdated: (
    <FormattedMessage
      id="tenantConfiguration.customFieldUpdated"
      defaultMessage="Custom field updated"
    />
  ),
  recordLayouts: (
    <FormattedMessage
      id="tenantConfiguration.recordLayouts"
      defaultMessage="Record Layouts"
    />
  ),
  noSpecialCharacters: (
    <FormattedMessage
      id="CustomFields.noSpecialCharacters"
      defaultMessage="Unique id cannot contain special characters"
    />
  ),
  layoutDeleted: (
    <FormattedMessage
      id="tenantConfiguration.layoutDeleted"
      defaultMessage="Record Layout deleted"
    />
  ),
  deleteRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.deleteRecordLayout"
      defaultMessage="Delete record layout"
    />
  ),
  deleteRecordType: (
    <FormattedMessage
      id="tenantConfiguration.deleteRecordType"
      defaultMessage="Delete record type"
    />
  ),
  recordLayoutInUseToDelete: (
    <FormattedMessage
      id="tenantConfiguration.recordLayoutInUseToDelete"
      defaultMessage="Cannot delete this record layout as it is being used in records/templates."
    />
  ),
  recordTypeInUseToDelete: (
    <FormattedMessage
      id="tenantConfiguration.recordTypeInUseToDelete"
      description="Cannot delete this record type as it is being used in records."
      defaultMessage="Cannot delete this record type as it is being used in records."
    />
  ),
  recordLayoutInUseToEdit: (
    <FormattedMessage
      id="tenantConfiguration.recordLayoutInUseToEdit"
      description="Cannot edit this record layout as it is being used in records."
      defaultMessage="Cannot edit this record layout as it is being used in records."
    />
  ),
  editRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.editRecordLayout"
      defaultMessage="Edit record layout"
    />
  ),
  copyRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.copyRecordLayout"
      defaultMessage="Copy record layout"
    />
  ),
  editLayoutName: (
    <FormattedMessage
      id="tenantConfiguration.editLayoutName"
      defaultMessage="Edit layout name"
    />
  ),
  editRecordLayoutName: (
    <FormattedMessage
      id="tenantConfiguration.editRecordLayoutName"
      defaultMessage="Edit record layout name"
    />
  ),
  editRecordLayoutDetails: (
    <FormattedMessage
      id="tenantConfiguration.editRecordLayoutDetails"
      defaultMessage="Edit record layout details"
    />
  ),
  updateRecordDetails: (
    <FormattedMessage
      id="tenantConfiguration.updateRecordDetails"
      defaultMessage="Updated record details"
    />
  ),
  nameUpdate: (
    <FormattedMessage
      id="tenantConfiguration.nameUpdate"
      defaultMessage="Updated layout name successfuly"
    />
  ),
  simpleMasterData: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterData"
      defaultMessage="Simple master data"
    />
  ),
  simpleMasterDataType: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterDataType"
      defaultMessage="Simple master data type"
    />
  ),
  selectsimplemasterdata: (
    <FormattedMessage
      id="tenantConfiguration.selectSimpleMasterDataType"
      defaultMessage=" Select simple master data type"
    />
  ),
  addNewField: (
    <FormattedMessage
      id="tenantConfiguration.addNewField"
      defaultMessage="Add new field"
    />
  ),
  editField: (
    <FormattedMessage
      id="tenantConfiguration.editField"
      defaultMessage="Edit field"
    />
  ),
  fieldExists: (
    <FormattedMessage
      id="tenantConfiguration.fieldExists"
      defaultMessage="Field already exists"
    />
  ),
  dynamicRecordLayout: (
    <FormattedMessage
      id="tenantConfiguration.dynamicRecordLayout"
      defaultMessage="Dynamic record layouts"
    />
  ),
  recordPrefix: (
    <FormattedMessage
      id="tenantConfiguration.recordPrefix"
      defaultMessage="Record prefix"
    />
  ),
  recordPrefixHInt: (
    <FormattedMessage
      id="tenantConfiguration.recordPrefixHInt"
      defaultMessage="Enter record prefix"
    />
  ),
  statusUpdate: (
    <FormattedMessage
      id="tenantConfiguration.statusUpdate"
      defaultMessage="Updated record status"
    />
  ),
  duplicateUniqueId: (
    <FormattedMessage
      id="tenantConfiguration.duplicateUniqueId"
      defaultMessage="Layout unique id already exists"
    />
  ),
  uniqueIdWarning: (
    <FormattedMessage
      id="tenantConfiguration.uniqueIdWarning"
      defaultMessage="Please change the unique id as it is being used by another field"
    />
  ),
  editDynamicDialog: (
    <FormattedMessage
      id="tenantConfiguration.editDynamicDialog"
      defaultMessage="Edit field"
    />
  ),
  notDraggableWarning: (
    <FormattedMessage
      id="tenantConfiguration.notDraggableWarning"
      defaultMessage="Layout has usage. New fields cannot be added"
    />
  ),
  dragMeAround: (
    <FormattedMessage
      id="tenantConfiguration.dragMeAround"
      defaultMessage="Drag me around!"
    />
  ),
  recordPrefixValidation: (
    <FormattedMessage
      id="tenantConfiguration.recordPrefixValidation"
      defaultMessage="Record prefix cannot contain numbers or special characters"
    />
  ),
  recordLayoutUpdated: (
    <FormattedMessage
      id="tenantConfiguration.recordLayoutUpdated"
      defaultMessage="Record layout updated successfully"
    />
  ),
  layoutUniqueFieldId: (
    <FormattedMessage
      id="tenantConfiguration.layoutUniqueFieldId"
      defaultMessage="Layout unique field id"
    />
  ),
  sectionFieldError: (
    <FormattedMessage
      id="tenantConfiguration.sectionFieldError"
      defaultMessage="Sections should not be empty"
    />
  ),
  nestedSectionError: (
    <FormattedMessage
      id="tenantConfiguration.nestedSectionError"
      defaultMessage="Cannot add a section field inside a section"
    />
  ),
  textFields: (
    <FormattedMessage
      id="tenantConfiguration.textFields"
      defaultMessage="Text fields"
    />
  ),
  richTextFields: (
    <FormattedMessage
      id="tenantConfiguration.richTextFields"
      defaultMessage="Rich text fields"
    />
  ),
  dropdownPlaceholder: (
    <FormattedMessage
      id="tenantConfiguration.dropdownPlaceholder"
      defaultMessage="Choose an option"
    />
  ),
  dropdown: (
    <FormattedMessage
      id="tenantConfiguration.dropdown"
      defaultMessage="Dropdown"
    />
  ),
  scoredDropdown: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropdown"
      defaultMessage="Scored dropdown"
    />
  ),
  availableLanguages: (
    <FormattedMessage
      id="tenantConfiguration.availableLanguages"
      defaultMessage="Available languages"
    />
  ),
  fallbackLanguage: (
    <FormattedMessage
      id="tenantConfiguration.fallbackLanguage"
      defaultMessage="Fallback language"
    />
  ),
  addAvailableLaguages: (
    <FormattedMessage
      id="tenantConfiguration.addAvailableLaguages"
      defaultMessage="Select languages"
    />
  ),
  editNameAndUniqueId: (
    <FormattedMessage
      id="tenantConfiguration.editNameAndUniqueId"
      defaultMessage="Edit name and unique id "
    />
  ),
  addNewValue: (
    <FormattedMessage
      id="tenantConfiguration.addNewValue"
      defaultMessage="Add new value"
    />
  ),
  editValue: (
    <FormattedMessage
      id="tenantConfiguration.editValue"
      defaultMessage="Edit value"
    />
  ),
  deleteValue: (
    <FormattedMessage
      id="tenantConfiguration.deleteValue"
      defaultMessage="Delete value"
    />
  ),
  setAvailableLanguages: (
    <FormattedMessage
      id="tenantConfiguration.setAvailableLanguages"
      defaultMessage="Set available languages"
    />
  ),
  deleteDropdown: (
    <FormattedMessage
      id="tenantConfiguration.deleteDropdown"
      defaultMessage="Delete dropdown field"
    />
  ),
  menuCreation: (
    <FormattedMessage
      id="tenantConfiguration.menuCreation"
      defaultMessage="Menu"
    />
  ),
  menuCreationHint: (
    <FormattedMessage
      id="tenantConfiguration.menuCreationHint"
      defaultMessage="Create new"
    />
  ),
  groupEmptyError: (
    <FormattedMessage
      id="tenantConfiguration.groupEmptyError"
      defaultMessage="A group should contain atleast one sub chapter"
    />
  ),
  disableChapterDelete: (
    <FormattedMessage
      id="tenantConfiguration.disableChapterDelete"
      defaultMessage="Cannot delete this chapter as it contains default fields"
    />
  ),
  disableGroupDelete: (
    <FormattedMessage
      id="tenantConfiguration.disableGroupDelete"
      defaultMessage="Cannot delete this group as it contains chapters with default fields"
    />
  ),
  createSubMenu: (
    <FormattedMessage
      id="tenantConfiguration.createSubMenu"
      defaultMessage="Create sub-menu"
    />
  ),
  calculation: (
    <FormattedMessage
      id="tenantConfiguration.calculation"
      defaultMessage="Calculation"
    />
  ),
  inputFields: (
    <FormattedMessage
      id="tenantConfiguration.inputFields"
      defaultMessage="Input fields"
    />
  ),
  addAdditionalInputFields: (
    <FormattedMessage
      id="tenantConfiguration.addAdditionalInputFields"
      defaultMessage="Add additional input fields"
    />
  ),
  outcomes: (
    <FormattedMessage
      id="tenantConfiguration.outcomes"
      defaultMessage="Outcomes"
    />
  ),
  min: <FormattedMessage id="tenantConfiguration.min" defaultMessage="Min" />,
  max: <FormattedMessage id="tenantConfiguration.max" defaultMessage="Max" />,
  scoreCalculator: (
    <FormattedMessage
      id="tenantConfiguration.scoreCalculator"
      defaultMessage="Score calculator"
    />
  ),
  inputSelector: (
    <FormattedMessage
      id="tenantConfiguration.inputSelector"
      defaultMessage="Input selector"
    />
  ),
  outputSelector: (
    <FormattedMessage
      id="tenantConfiguration.outputSelector"
      defaultMessage="Output selector"
    />
  ),
  defaultMessage: (
    <FormattedMessage
      id="tenantConfiguration.defaultMessage"
      defaultMessage="Default message"
    />
  ),
  addOutcome: (
    <FormattedMessage
      id="tenantConfiguration.addOutcome"
      defaultMessage="Add outcome"
    />
  ),
  outcome: (
    <FormattedMessage
      id="tenantConfiguration.outcome"
      defaultMessage="Outcome"
    />
  ),
  addStakeholder: (
    <FormattedMessage
      id="tenantConfiguration.addStakeholder"
      defaultMessage="Add stakeholder"
    />
  ),
  checkbox: (
    <FormattedMessage
      id="tenantConfiguration.checkbox"
      defaultMessage="Checkbox"
    />
  ),
  Stakeholder: (
    <FormattedMessage
      id="tenantConfiguration.Stakeholder"
      defaultMessage="Stakeholder"
    />
  ),
  optionValidation: (
    <FormattedMessage
      id="tenantConfiguration.optionValidation"
      defaultMessage="Atleast one option must be added"
    />
  ),
  textField: (
    <FormattedMessage
      id="tenantConfiguration.textField"
      defaultMessage="Text field"
    />
  ),
  richTextField: (
    <FormattedMessage
      id="tenantConfiguration.richTextField"
      defaultMessage="Rich text field"
    />
  ),
  simpleMasterDataField: (
    <FormattedMessage
      id="tenantConfiguration.simpleMasterDataField"
      defaultMessage="Simple master data field"
    />
  ),
  dropDownField: (
    <FormattedMessage
      id="tenantConfiguration.dropDownField"
      defaultMessage="Dropdown field"
    />
  ),
  scoredDropdownField: (
    <FormattedMessage
      id="tenantConfiguration.scoredDropdownField"
      defaultMessage="Scored dropdown field"
    />
  ),
  Section: (
    <FormattedMessage
      id="tenantConfiguration.Section"
      defaultMessage="Section"
    />
  ),
  selectInputField: (
    <FormattedMessage
      id="tenantConfiguration.selectInputField"
      defaultMessage="Select Field...."
    />
  ),
  itemListCreated: (
    <FormattedMessage
      id="tenantConfiguration.itemListCreated"
      defaultMessage="Item list created"
    />
  ),
  itemListUpdated: (
    <FormattedMessage
      id="tenantConfiguration.itemListUpdated"
      defaultMessage="Item list updated"
    />
  ),
  itemListDeleted: (
    <FormattedMessage
      id="tenantConfiguration.itemListDeleted"
      defaultMessage="Item list deleted"
    />
  ),
  deleteItem: (
    <FormattedMessage
      id="tenantConfiguration.deleteItem"
      defaultMessage="Delete item"
    />
  ),
  deleteList: (
    <FormattedMessage
      id="tenantConfiguration.deleteList"
      defaultMessage="Delete list"
    />
  ),
  valueNumber: (
    <FormattedMessage
      id="tenantConfiguration.valueNumber"
      defaultMessage="Item No."
    />
  ),
  score: (
    <FormattedMessage id="tenantConfiguration.score" defaultMessage="Score" />
  ),
  preDpia: (
    <FormattedMessage
      id="tenantConfiguration.preDpia"
      defaultMessage="Pre dpia"
    />
  ),
  deactivateLayout: (
    <FormattedMessage
      id="tenantConfiguration.deactivateLayout"
      defaultMessage="Deactivate record layout"
    />
  ),
  disableMenuWarning: (
    <FormattedMessage
      id="tenantConfiguration.disableMenuWarning"
      defaultMessage="Please disable chapter creation to add fields to the record"
    />
  ),
  group: (
    <FormattedMessage id="tenantConfiguration.group" defaultMessage="Group" />
  ),
  chapter: (
    <FormattedMessage
      id="tenantConfiguration.chapter"
      defaultMessage="Chapter"
    />
  ),
  syncLayout: (
    <FormattedMessage
      id="tenantConfiguration.syncLayout"
      defaultMessage="Sync layouts"
    />
  ),
  sync: (
    <FormattedMessage id="tenantConfiguration.sync" defaultMessage="Sync" />
  ),
  selectSubtenants: (
    <FormattedMessage
      id="tenantConfiguration.selectSubtenants"
      defaultMessage="Select subtenants for syncing the layouts"
    />
  ),
  enableLayoutSync: (
    <FormattedMessage
      id="tenantConfiguration.enableLayoutSync"
      defaultMessage="Enable layout sync"
    />
  ),
  syncWarning: (
    <FormattedMessage
      id="tenantConfiguration.syncWarning"
      defaultMessage="Layout edited! Resync to reflect changes in synced subtenants"
    />
  ),
  deleteLayoutFrom: (
    <FormattedMessage
      id="tenantConfiguration.deleteLayoutFrom"
      defaultMessage="Delete layout from:"
    />
  ),
  deleteFromHolding: (
    <FormattedMessage
      id="tenantConfiguration.deleteFromHolding"
      defaultMessage="Delete layout from holding tenant"
    />
  ),
  noSyncedSubtenants: (
    <FormattedMessage
      id="tenantConfiguration.noSyncedSubtenants"
      defaultMessage="No synced subtenants"
    />
  ),
  selectForDeletion: (
    <FormattedMessage
      id="tenantConfiguration.selectForDeletion"
      defaultMessage="Select for deletion"
    />
  ),
  deleteRestricted: (
    <FormattedMessage
      id="tenantConfiguration.deleteRestricted"
      defaultMessage="Deletion is restricted for subtenants in which the layout is in use"
    />
  ),
  stakeholderFields: (
    <FormattedMessage
      id="tenantConfiguration.stakeholderFields"
      defaultMessage="Stakeholder fields"
    />
  ),
  text: (
    <FormattedMessage id="tenantConfiguration.text" defaultMessage="text" />
  ),
  simplemasterdata: (
    <FormattedMessage
      id="tenantConfiguration.simplemasterdata"
      defaultMessage="simple master data"
    />
  ),
  htmltext: (
    <FormattedMessage
      id="tenantConfiguration.htmltext"
      defaultMessage="rich text"
    />
  ),
  picklist: (
    <FormattedMessage
      id="tenantConfiguration.picklist"
      defaultMessage="dropdown"
    />
  ),
  scoredpicklist: (
    <FormattedMessage
      id="tenantConfiguration.scoredpicklist"
      defaultMessage="scored dropdown"
    />
  ),
  scorecalculator: (
    <FormattedMessage
      id="tenantConfiguration.scorecalculator"
      defaultMessage="score calculator"
    />
  ),
  section: (
    <FormattedMessage
      id="tenantConfiguration.section"
      defaultMessage="section"
    />
  ),
  stakeholder: (
    <FormattedMessage
      id="tenantConfiguration.stakeholder"
      defaultMessage="stakeholder"
    />
  ),
  templateCount: (
    <FormattedMessage
      id="tenantConfiguration.templateCount"
      defaultMessage="Template count"
    />
  ),
  layoutRules: (
    <FormattedMessage
      id="tenantConfiguration.layoutRules"
      defaultMessage="Layout rules"
    />
  ),
  fieldAndStructure: (
    <FormattedMessage
      id="tenantConfiguration.fieldAndStructure"
      defaultMessage="Field and structure"
    />
  ),
  fieldRules: (
    <FormattedMessage
      id="tenantConfiguration.fieldRules"
      defaultMessage="Field rules"
    />
  ),
  riskRules: (
    <FormattedMessage
      id="tenantConfiguration.riskRules"
      defaultMessage="Risk rules"
    />
  ),
  addTrigger: (
    <FormattedMessage
      id="LayoutRules.addTrigger"
      defaultMessage="Add new trigger condition"
    />
  ),
  addTriggers: (
    <FormattedMessage
      id="LayoutRules.addTrigger"
      defaultMessage="Add new trigger "
    />
  ),
  addAction: (
    <FormattedMessage
      id="LayoutRules.addAction"
      defaultMessage="Add new action"
    />
  ),
  editTrigger: (
    <FormattedMessage
      id="LayoutRules.editTrigger"
      defaultMessage="Edit trigger"
    />
  ),
  editAction: (
    <FormattedMessage
      id="LayoutRules.editAction"
      defaultMessage="Edit action"
    />
  ),
  editRule: (
    <FormattedMessage id="LayoutRules.editRule" defaultMessage="Edit rule" />
  ),
  deleteRule: (
    <FormattedMessage
      id="LayoutRules.deleteRule"
      defaultMessage="Delete rule"
    />
  ),
  if: <FormattedMessage id="LayoutRules.if" defaultMessage="IF" />,
  then: <FormattedMessage id="LayoutRules.then" defaultMessage="THEN" />,
  show: <FormattedMessage id="LayoutRules.show" defaultMessage="SHOW" />,
  in: <FormattedMessage id="LayoutRules.in" defaultMessage="IN" />,
  forJurisdictions: (
    <FormattedMessage
      id="LayoutRules.forJurisdictions"
      defaultMessage="For jurisdictions"
    />
  ),
  withMessage: (
    <FormattedMessage id="LayoutRules.show" defaultMessage="WITH MESSAGE" />
  ),
  fieldName: (
    <FormattedMessage id="LayoutRules.fieldName" defaultMessage="Field name" />
  ),
  operator: (
    <FormattedMessage id="LayoutRules.operator" defaultMessage="Operator" />
  ),
  addRuleDetails: (
    <FormattedMessage
      id="LayoutRules.ruleDetails"
      defaultMessage="Add rule details"
    />
  ),
  addRuleTriggers: (
    <FormattedMessage
      id="LayoutRules.ruleTriggers"
      defaultMessage="Add rule triggers"
    />
  ),
  addRuleActions: (
    <FormattedMessage
      id="LayoutRules.ruleActions"
      defaultMessage="Add rule actions"
    />
  ),
  ruleName: (
    <FormattedMessage
      id="tenantConfiguration.ruleName"
      defaultMessage="Rule name"
    />
  ),
  ruleDescription: (
    <FormattedMessage
      id="tenantConfiguration.ruleDescription"
      defaultMessage="Rule description"
    />
  ),
  ruleSaved: (
    <FormattedMessage
      id="tenantConfiguration.ruleSaved"
      defaultMessage="Rule saved successfully"
    />
  ),
  fieldRulesEmptyCardText: (
    <FormattedMessage
      id="tenantConfiguration.fieldRulesEmptyCardText"
      defaultMessage="No field rules added yet.
       Field rules manage the logic and rendering of layout elements based on dependencies.
       Create a new field rule to utilize this feature."
    />
  ),
  riskRulesEmptyCardText: (
    <FormattedMessage
      id="tenantConfiguration.fieldRulesEmptyCardText"
      defaultMessage="No risk rules added yet.
       Risk rules help identify potential risks based on specific field values.
       Create a new risk or compliance requirement to utilize this feature."
    />
  ),
  deleteFieldRule: (
    <FormattedMessage
      id="tenantConfiguration.deleteFieldRule"
      defaultMessage="Delete field rule"
    />
  ),
  successfullyDeletedRule: (
    <FormattedMessage
      id="tenantConfiguration.successfullyDeletedRule"
      defaultMessage="Successfully deleted rule"
    />
  ),
  addAttributeCheck: (
    <FormattedMessage
      id="LayoutRules.addAttributeCheck"
      defaultMessage="Add attribute check"
    />
  ),
  removeAttributeCheck: (
    <FormattedMessage
      id="LayoutRules.removeAttributeCheck"
      defaultMessage="Remove attribute check"
    />
  ),
  addField: (
    <FormattedMessage id="LayoutRules.addField" defaultMessage="Add field" />
  ),
  addOperator: (
    <FormattedMessage
      id="LayoutRules.addOperator"
      defaultMessage="Add operator"
    />
  ),
  addAttribute: (
    <FormattedMessage
      id="LayoutRules.addAttribute"
      defaultMessage="Add attribute"
    />
  ),
  addActionHeader: (
    <FormattedMessage
      id="LayoutRules.addActionHeader"
      defaultMessage="Add actions to be performed when the conditions are met"
    />
  ),
  riskMessage: (
    <FormattedMessage
      id="LayoutRules.riskMessage"
      defaultMessage="Risk message"
    />
  ),
  targetElement: (
    <FormattedMessage
      id="LayoutRules.targetElement"
      defaultMessage="Target element"
    />
  ),
  typeToSearch: (
    <FormattedMessage
      id="LayoutRules.typeToSearch"
      defaultMessage="Type to search"
    />
  ),
  noMatchesFound: (
    <FormattedMessage
      id="LayoutRules.noMatchesFound"
      defaultMessage="No matches found"
    />
  ),
  addTransaltion: (
    <FormattedMessage
      id="LayoutRules.addTransaltion"
      defaultMessage="Add translation"
    />
  ),
  ruleNamePlaceholder: (
    <FormattedMessage
      id="LayoutRules.ruleNamePlaceholder"
      defaultMessage="Enter rule name"
    />
  ),
  ruleDescriptionPlaceholder: (
    <FormattedMessage
      id="LayoutRules.ruleDescriptionPlaceholder"
      defaultMessage="Enter rule description"
    />
  ),
  chooseAction: (
    <FormattedMessage
      id="LayoutRules.chooseAction"
      defaultMessage="Choose action"
    />
  ),
  risk: singularTerms('risk'),
  compliance: (
    <FormattedMessage
      id="tenantConfiguration.compliance"
      defaultMessage="Compliance requirement"
    />
  ),
  selectCategory: (
    <FormattedMessage
      id="LayoutRules.selectCategory"
      defaultMessage="Select category"
    />
  ),
  selectJurisdiction: (
    <FormattedMessage
      id="LayoutRules.selectJurisdiction"
      defaultMessage="Select jurisdiction"
    />
  ),
  requirement: (
    <FormattedMessage
      id="tenantConfiguration.requirement"
      defaultMessage="Requirement"
    />
  ),
  complianceLabel : (
      <FormattedMessage
        id="tenantConfiguration.complianceLabel"
        defaultMessage="Compliance requirement label"
      />
  ),
  tabSwitchDisableText : (
      <FormattedMessage
        id="tenantConfiguration.tabSwitchDisableText"
        defaultMessage="Tab switching is not allowed while editing the filter menu"
      />
  )
};

export const availableLanguageTranslation = {
  en: (
    <FormattedMessage
      id="tenantConfiguration.english"
      defaultMessage="English"
    />
  ),
  fr: (
    <FormattedMessage id="tenantConfiguration.french" defaultMessage="French" />
  ),
  de: (
    <FormattedMessage id="tenantConfiguration.german" defaultMessage="German" />
  ),
  es: (
    <FormattedMessage
      id="tenantConfiguration.spanish"
      defaultMessage="Spanish"
    />
  ),
  ja: (
    <FormattedMessage
      id="tenantConfiguration.japanese"
      defaultMessage="Japanese"
    />
  ),
  nl: (
    <FormattedMessage
      id="tenantConfiguration.netherlands"
      defaultMessage="Netherlands"
    />
  ),
  'zh-TW': (
    <FormattedMessage
      id="tenantConfiguration.taiwanese"
      defaultMessage="Taiwanese"
    />
  ),
  pt: (
    <FormattedMessage
      id="tenantConfiguration.portugese"
      defaultMessage="Portugese"
    />
  ),
  'zh-CN': (
    <FormattedMessage
      id="tenantConfiguration.Chinese"
      defaultMessage="Chinese"
    />
  )
};

export const groupConditionsTextTranslations = {
  trigger: (
    <FormattedMessage
      id="LayoutRules.groupOperatorText"
      defaultMessage="of the following triggers are met"
    />
  ),
  triggerCondition: (
    <FormattedMessage
      id="LayoutRules.conditionsGroupOperatorText"
      defaultMessage="of the following trigger-conditions are met"
    />
  ),
  risk: (
    <FormattedMessage
      id="LayoutRules.riskDisplayText"
      defaultMessage="of the following risks will be present"
    />
  )
};
export const addNewCustomField = (fieldType) => (
  <FormattedMessage
    id="tenantConfiguration.addNewCustomField"
    defaultMessage="Add new {fieldType} field"
    values={{
      fieldType: tenantConfigurationTranslations[fieldType?.toLowerCase()]
    }}
  />
);

export const editCustomField = (fieldType) => (
  <FormattedMessage
    id="tenantConfiguration.editCustomField"
    defaultMessage="Edit {fieldType} field"
    values={{
      fieldType: tenantConfigurationTranslations[fieldType?.toLowerCase()]
    }}
  />
);

export const customTranslations = (id, value) => {
  switch (id) {
    case 'removeChapter':
      return (
        <FormattedMessage
          id="tenantConfiguration.removeChapter"
          defaultMessage="This chapter contains fields associated with it.
           Are you sure that you want to delete the chapter {value}?"
          values={{ value }}
        />
      );
    case 'removeGroup':
      return (
        <FormattedMessage
          id="tenantConfiguration.removeGroup"
          defaultMessage="This group contains subchapters. Are you sure that you want to delete the group {value}?"
          values={{ value }}
        />
      );
    case 'addNewMenu':
      return (
        <FormattedMessage
          id="tenantConfiguration.addNewMenu"
          defaultMessage="Add new {value}"
          values={{ value }}
        />
      );
    case 'editMenu':
      return (
        <FormattedMessage
          id="tenantConfiguration.editMenu"
          defaultMessage="Edit {value}"
          values={{ value }}
        />
      );
    case 'withAttribute':
      return (
        <FormattedMessage
          id="tenantConfiguration.withAttribute"
          defaultMessage="with {value}"
          values={{ value }}
        />
      );
    default:
      return '';
  }
};

export const deleteRecordLayoutContent = (recordLayout) => (
  <FormattedMessage
    id="tenantConfiguration.deleteRecordLayoutContent"
    description="Do you want to delete the record layout?"
    defaultMessage="Do you want to delete the record layout {recordLayout}?"
    values={{ recordLayout }}
  />
);

export const deleteRecordTypeContent = (recordType) => (
  <FormattedMessage
    id="tenantConfiguration.deleteRecordTypeContent"
    description="Do you want to delete the record type?"
    defaultMessage="Do you want to delete the record type {recordType}?"
    values={{ recordType }}
  />
);

export const ruleActionTranslations = {
  show: (
    <FormattedMessage id="tenantConfiguration.show" defaultMessage="Show" />
  ),
  hide: (
    <FormattedMessage id="tenantConfiguration.hide" defaultMessage="Hide" />
  ),
  lock: (
    <FormattedMessage id="tenantConfiguration.lock" defaultMessage="Lock" />
  ),
  unlock: (
    <FormattedMessage id="tenantConfiguration.unlock" defaultMessage="Unlock" />
  )
};

export const ruleOperatorTranslations = {
  isChecked: (
    <FormattedMessage
      id="tenantConfiguration.isChecked"
      defaultMessage="Is checked"
    />
  ),
  unChecked: (
    <FormattedMessage
      id="tenantConfiguration.unChecked"
      defaultMessage="Is unchecked"
    />
  ),
  isBlank: (
    <FormattedMessage
      id="tenantConfiguration.isBlank"
      defaultMessage="Is blank"
    />
  ),
  includes: (
    <FormattedMessage
      id="tenantConfiguration.includes"
      defaultMessage="Includes"
    />
  ),
  notIncludes: (
    <FormattedMessage
      id="tenantConfiguration.notIncludes"
      defaultMessage="Does not include"
    />
  ),
  allIncludes: (
    <FormattedMessage
      id="tenantConfiguration.allIncludes"
      defaultMessage="All includes"
    />
  ),
  anyNotIncludes: (
    <FormattedMessage
      id="tenantConfiguration.notIncludes"
      defaultMessage="Any not includes"
    />
  ),
  isEmpty: (
    <FormattedMessage
      id="tenantConfiguration.isEmpty"
      defaultMessage="Is empty"
    />
  ),
  notEmpty: (
    <FormattedMessage
      id="tenantConfiguration.notEmpty"
      defaultMessage="Is not empty"
    />
  )
};
