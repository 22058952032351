import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { handleServiceDown, getEntityType } from '@packages/utils/common-utils';
import errortranslations from '@packages/utils/errortranslations';

const defaultGetHeaders = {
  Accept: 'text/plain',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1'
};


export function* checkImportStatus(action) {
  const { masterDataType, entityType } = action;
  const jobEntityType = getEntityType(masterDataType);
  yield put({ type: `CHECK:IMPORT:STATUS:INIT` });
  try {
    let url = `/v1/import/${jobEntityType || entityType}/inprogress`;
    if (entityType) {
      url = `${url}?filter=entityType=${getEntityType(entityType)}`;
    }
    const response = yield registry.get('request')
      .get(url);
    switch (response.status) {
      case 200: {
        yield put({ type: `CHECK:IMPORT:STATUS:SUCCESS`, importObject: response.body });
        // yield masterDataProgress({ masterDataType, entityType, id: response.body.id });
        break;
      }
      case 204: {
        yield checkImportCount(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `CHECK:IMPORT:STATUS:FAIL`, error: response.body.msg });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'import');
    yield put({ type: `CHECK:IMPORT:STATUS:FAIL` });
  }
}


export function* checkImportCount(action) {
  const { masterDataType, entityType } = action;
  const jobEntityType = getEntityType(masterDataType);
  try {
    const response = yield registry.get('request')
      .get(`/v1/import/isAllowed?filter=entityType=${jobEntityType || entityType}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: `CHECK:IMPORT:ISALLOWED:SUCCESS`,
          isAllowed: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `CHECK:IMPORT:ISALLOWED:FAIL`, error: response.body.msg });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: `CHECK:IMPORT:ISALLOWED:FAIL` });
  }
}

export function* getFileId(action) {
  const { file } = action;
  yield put({ type: `IMPORT:CSV:ID:INIT` });
  try {
    const response = yield registry.get('request')
      .postCsvDocument(`/v1/documents/upload/import`, file);
    switch (response.status) {
      case 200: {
        yield put({
          type: `IMPORT:CSV:ID:SUCCESS`,
          fileId: response.body.fileId,
          fileEncoding: response.body.fileEncoding
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'MASTERDATA:DOCUMENTS:UPSERT:FAIL', error: response.body.msg });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'MASTERDATA:DOCUMENTS:UPSERT:FAIL', error: err.message });
  }
}

export function* importMasterData(action) {
  const { masterDataType, entityType, fileExtension } = action;
  const jobEntityType = getEntityType(masterDataType);
  const store = registry.get('store');
  const fileEncoding = store.getState().bulkImport.get('fileEncoding');
  let modifiedData = {
    fileId: action.fileId,
    fileName: `${action.fileId}_import`,
    fileExtension,
    fileEncoding
  };
  if (entityType) {
    modifiedData = {
      ...modifiedData,
      subEntityType: getEntityType(entityType)
    };
  }
  try {
    const response = yield registry.get('request')
      .post(`/v1/import/${jobEntityType || entityType}`, modifiedData);
    switch (response.status) {
      case 200: {
        yield put({
          type: `IMPORT:SUCCESS`, isSuccess: true, importObject: response.body
        });
        yield put({ type: `BULK:IMPORT:RESET` });
        // yield masterDataProgress({ masterDataType, entityType, id: response.body.id });
        break;
      }
      case 412: {
        yield put({
          type: `IMPORT:FETCH:FAIL`,
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `IMPORT:FETCH:FAIL`, error: response.body.msg });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'import');
    yield put({ type: `IMPORT:FETCH:FAIL` });
  }
}

export function* getMaterDataImport(action) {
  const { masterDataType, entityType, id } = action;
  const jobEntityType = getEntityType(masterDataType);
  try {
    const response = yield registry.get('request')
      .get(`/v1/import/${jobEntityType || entityType}/${id}`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: `IMPORT:SUCCESS`, isSuccess: true, importObject: response.body
        });
        // yield put({ type: `MASTERDATA:IMPORT:RESET` });
        // yield masterDataProgress({ masterDataType, entityType, id: response.body.id });
        break;
      }
      case 412: {
        yield put({
          type: `IMPORT:FETCH:FAIL`,
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `IMPORT:FETCH:FAIL`, error: response.body.msg });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'import');
    yield put({ type: `IMPORT:FETCH:FAIL` });
  }
}

export function* masterDataProgress(action) {
  const store = registry.get('store');
  const { masterDataType, entityType, id } = action;
  const jobEntityType = getEntityType(masterDataType);

  yield put({ type: `IMPORT:STREAMING:INIT` });

  const config = registry.get('config');
  const headers = Object.assign(defaultGetHeaders, {
    [config.login.token.httpHeader]: registry.get('storage')
      .getItem(config.login.token.storage.key)
  });
  try {
    fetch(`v1/masterdata/import/${jobEntityType || entityType}/${id}/progress`, {
      method: 'GET',
      headers
    })
      .then(response => new Response(
        // create and return a readable stream
        new ReadableStream({
          start(controller) {
            const reader = response.body.getReader();
            if (response.status !== 200) {
              store.dispatch({
                type: 'NOTIFIER:NOTIFY',
                notification: {
                  content: errortranslations.progressApiError,
                  type: 'error'
                }
              });
              store.dispatch({ type: `IMPORT:STREAMING:FAIL` });
            }

            read();
            // The function reads each data chunk
            function read() {
              reader.read().then(({ done, value }) => {
                const bulkImportState = store.getState().bulkImport;
                const cancelStreaming = bulkImportState.get('cancelStreaming');

                // To get the last successfull chunk of data
                const lineArray = String.fromCharCode(...value);
                const closingIndex = lineArray.lastIndexOf('}');
                if (closingIndex !== -1) {
                  const updatedLineArray = lineArray.slice(0, closingIndex + 1);
                  const openingIndex = updatedLineArray.lastIndexOf('{');
                  if (openingIndex !== -1) {
                    const finalStream = updatedLineArray.slice(openingIndex, closingIndex + 1);
                    const streamJsonObject = JSON.parse(finalStream);
                    store.dispatch({
                      type: `IMPORT:STREAMING:SUCCESS`,
                      streamData: streamJsonObject
                    });
                  }
                }
                // close the stream on end  or
                // cancel the reader on closing the dialog to show progress of import
                if (done || cancelStreaming) {
                  controller.close();
                  reader.cancel();
                  return;
                }
                read();
              }).catch((error) => {
                controller.error(error);
              });
            }
          }
        })
      )).catch((err) => {
        store.dispatch({ type: `IMPORT:STREAMING:FAIL`, error: err.message });
      });
  } catch (err) {
    yield handleServiceDown(err, 'import');
    yield put({ type: `IMPORT:STREAMING:FAIL` });
  }
}


export function* downloadErrorFile(action) {
  const request = registry.get('request');
  try {
    const { id } = action;
    const response = yield request.getDocument(`/v1/documents/import/${id}/log/download`);
    yield response;
    switch (response.status) {
      case 200: {
        break;
      }
      case 404: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: errortranslations.attachmentUnavailable,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
  }
}
