import dsrTranslations from './dsrTranslations';

const filterMenu = [
  {
    label: dsrTranslations.overview,
    key: 'overview',
    enable: true,
    defaultFilter: 'datasubjectrequests_overview',
    subMenu: [{
      key: 'datasubjectrequests_overview',
      label: dsrTranslations.overview
    },
    {
      key: 'dsrForms_overview',
      label: dsrTranslations.dsrForms
    },
    {
      key: 'requesters_overview',
      label: dsrTranslations.requesters
    }]
  }
];

export default filterMenu;
