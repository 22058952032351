import { commonTranslations } from '@packages/utils/commontranslations';

import messageTranslation from './messageTranslations';

const filterMenu = [
  {
    label: messageTranslation.messages,
    key: 'messages',
    enable: true,
    defaultFilter: 'inbox_messages',
    subMenu: [{
      key: 'inbox_messages',
      label: messageTranslation.inbox
    },
    {
      key: 'unRead_messages',
      label: messageTranslation.unRead
    },
    {
      key: 'sent_messages',
      label: messageTranslation.sent
    }]
  },
  {
    label: commonTranslations.tasks,
    key: 'tasks',
    enable: true,
    defaultFilter: 'inboxTasks_tasks',
    subMenu: [{
      key: 'inboxTasks_tasks',
      label: messageTranslation.allTasks
    },
    {
      key: 'unclaimedTasks_tasks',
      label: messageTranslation.unClaimedTasks
    }]
  },
  {
    label: commonTranslations.notifications,
    key: 'notifications',
    enable: true,
    defaultFilter: 'inboxNotifications_notifications',
    subMenu: [{
      key: 'inboxNotifications_notifications',
      label: messageTranslation.inbox
    },
    {
      key: 'unReadNotifications_notifications',
      label: messageTranslation.unRead
    }]
  }
];

export default filterMenu;
