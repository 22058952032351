import { recordTranslations } from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural,
  singularTerms as uppercaseSingular } from '@packages/utils/uppercaseTranslations';
import { sectionHeaderTranslations } from '../../../../../../environment/masterDataTranslations';
import { legalGroundTranslations } from '../../../../../../privacy-record-detail/recordTranslations';

const assessmentsFieldList = [{
  label: uppercaseSingular('preAssessment'),
  key: 'PreAssessment',
  subFields: [
    {
      key: 'StartDate',
      isCommon: false,
      label: recordTranslations.startDate
    },
    {
      key: 'EndDate',
      isCommon: false,
      label: recordTranslations.endDate
    },
    {
      key: 'Jurisdiction',
      isCommon: false,
      label: legalGroundTranslations.jurisdiction
    },
    {
      key: 'ProcessingCharacteristics',
      isCommon: false,
      label: uppercasePlural('processingCharacteristics')
    },
    {
      key: 'PreAssessmentOutcome',
      label: uppercaseSingular('outcomeHeader')
    }]
},
{
  label: recordTranslations.organisationsInvolved,
  key: 'stakeholders',
  subFields: [{
    key: 'Controllers',
    isCommon: true,
    label: uppercasePlural('controllers')
  },
  {
    key: 'Processors',
    isCommon: true,
    label: recordTranslations.processors
  },
  {
    key: 'ExecutingEntities',
    isCommon: true,
    label: recordTranslations.executingEntities
  },
  {
    key: 'DataRecipients',
    isCommon: true,
    label: uppercasePlural('dataRecipients')
  },
  {
    key: 'DataRecipientCategory',
    isCommon: true,
    label: uppercasePlural('dataRecipientCategories')
  }]
},
{
  label: recordTranslations.dataProcessed,
  key: 'processedData',
  subFields: [{
    key: 'DataSubjectCategories',
    isCommon: true,
    label: uppercasePlural('dataSubjectCategories')
  },
  {
    key: 'PersonalDataCategories',
    isCommon: true,
    label: uppercasePlural('personalDataCategories')
  },
  {
    key: 'PersonalDataItems',
    isCommon: true,
    label: uppercasePlural('personalDataItems')
  },
  {
    key: 'RetentionTerms',
    isCommon: true,
    label: recordTranslations.retentionTerms
  }]
},
{
  label: recordTranslations.storageAndProtection,
  key: 'securityMeasures',
  subFields: [
    {
      key: 'DataSources',
      isCommon: true,
      label: uppercasePlural('dataSources')
    },
    {
      key: 'DataSourceCategories',
      isCommon: true,
      label: uppercasePlural('dataSourceCategories')
    },
    {
      key: 'TechnicalSecurityMeasures',
      isCommon: true,
      label: sectionHeaderTranslations.technicalSecurityMeasuresHeader
    },
    {
      key: 'OrganisationalSecurityMeasures',
      isCommon: true,
      label: sectionHeaderTranslations.organisationalMeasuresHeader
    }]
},
{
  label: recordTranslations.applicableDataProctectionLaw,
  key: 'legalQualifications',
  subFields: [{
    key: 'ProcessingGrounds',
    isCommon: true,
    label: recordTranslations.processingGrounds
  },
  {
    key: 'InternationalTransfers',
    isCommon: true,
    label: recordTranslations.internationalTransfers
  }]
},
{
  label: recordTranslations.accountability,
  key: 'Accountability',
  subFields: [{
    key: 'Policies',
    label: sectionHeaderTranslations.policyHeader
  },
  {
    key: 'DataProtectionMeasures',
    label: sectionHeaderTranslations.dataProtectionMeasures
  },
  {
    key: 'AccountabilityMeasures',
    label: sectionHeaderTranslations.accountabilityMeasures
  },
  {
    key: 'DataSubjectCategoriesIsConsulted',
    label: recordTranslations.dataSubjectCategories
  },
  {
    key: 'DpoConsultation',
    label: uppercasePlural('DPOConsultation')
  },
  {
    key: 'SupervisoryAuthorities',
    label: uppercasePlural('supervisoryAuthorities')
  },
  {
    key: 'AccountabilityOutcome',
    label: uppercaseSingular('outcomeHeader')
  }]
},
{
  label: recordTranslations.OverallRiskSummary,
  key: 'Summary',
  subFields: [{
    key: 'NecessityAndProportionalityRiskSummary',
    label: recordTranslations.NecessityAndProportionalityRiskSummary
  },
  {
    key: 'DataSubjectRightsRiskSummary',
    label: recordTranslations.DataSubjectRightsRiskSummary
  },
  {
    key: 'SecurityMeasuresRiskSummary',
    label: recordTranslations.SecurityMeasuresRiskSummary
  },
  {
    key: 'ThreatImpactRiskSummary',
    label: recordTranslations.ThreatImpactRiskSummary
  },
  {
    key: 'Outcome',
    label: sectionHeaderTranslations.conclusionHeader
  }]
}
];

export default assessmentsFieldList;
