export const updateOrganisationEntities = (action) => {
  const { isEdit, selectedItem } = action;
  let { entities: items, newItems } = action;
  const modifiedItem = transformOrganisationEntity(selectedItem);
  if (isEdit) {
    const index = items.findIndex(entity => entity.id === selectedItem.id);
    items = items.set(index, modifiedItem);

    const newItemindex = newItems.findIndex(entity => entity.id === selectedItem.id);
    newItems = newItems.set(newItemindex, modifiedItem);
  }

  return { items, newItems };
};

export const transformOrganisationEntity = item => ({
  ...item,
  key: `${item.name} (${item.country})`,
  parentOrgEntity: item.parentOrgEntity ? (item.parentOrgEntity.id || item.parentOrgEntity) : null,
  isPartOf: item.parentOrgEntity ? (item.parentOrgEntity.name || item.isPartOf) : '',
  country: item.country
});
