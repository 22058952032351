const baseApiUrl = '/';

module.exports = {
  server: {
    port: 8090
  },
  webpack: {
    logDispatcher: true
  },
  uglify: {
    beautify: true,
    global: false,
    sourcemap: true,
    mangle: false,
    compress: false
  },
  clientConfig: {
    logger: {
      level: 6 // LEVEL_INFO
    },
    file: {
      maxFileSize: 15, // File size in MB
      maxFileLength: 72,
      bulkImportFileSize: 200 * 1024
    },
    companyName: 'PrivacyPerfect',
    supportMail: 'support@privacyperfect.com',
    supportPhone: '+31 10 310 07 40',
    manual: {
      downloadUrl:
        'https://www.manula.com/manuals/privacyperfect/privacyperfect-manual?key=' +
        'OVgTDqJY4rViiJWvYxGJnSMpTpp3iHrWsxGWOfLIwxRF7D1V1x'
    },
    ssoSetupManual:
      'https://www.manula.com/manuals/privacyperfect/privacyperfect-manual/2/' +
      'en/topic/single-sign-on-sso-setup?key=OVgTDqJY4rViiJWvYxGJnSMpTpp3iHrWsxGWOfLIwxRF7D1V1x',
    linkVideoUrl: 'https://vimeo.com/298368196/df43652665',
    userRegistrationTermsAndConditionsUrl:
    'https://privacyperfect.sharepoint.com/:b:/s/PublicContent/Edv9gTLkxS9Iljg_65DbvZIBpBY8DTarPe_YJ_NwkNiRUA?e=UsV3BQ',
    knowledgeHubUrl:'https://knowledgebase.privacyperfect.com/kb/en',
    defaultDate: '1970-01-01T00:00:00.000Z',
    baseApiUrl,
    login: {
      redirectUrl: {
        defaultSuccess: '/',
        defaultCancel: '/'
      },
      minInactiveTime: 1000 * 60 * 25, // in ms, 25 minutes minimum inactive time to check for token refresh
      maxInactiveTime: 1000 * 60 * 29, // in ms, 29 minutes maximum inactive time to check before logout
      token: {
        httpHeader: 'X-AUTH-PRIVACYPERFECT',
        ismsHttpHeader: 'Authorization',
        redirectLocationHeader: 'Location',
        storage: {
          key: 'auth-pp'
        },
        warning: 1000 * 60 * 25, // in ms, 25 minutes
        expire: 1000 * 60 * 29 // in ms, 29 minutes
      },
      user: {
        storage: {
          key: 'pp-user'
        }
      },
      login: {
        url: `${baseApiUrl}v1/identity/login`
      },
      refresh: {
        url: `${baseApiUrl}v1/identity/refresh`
      },
      vendorToken: {
        httpHeader: 'X-AUTH-PP-VENDORFORMS',
        storage: {
          key: 'auth-pp-vendorforms'
        },
        expire: 12 * 60 * 60 * 1000 // in ms, refresh vendorToken every 12 hrs.
      }
    }
  }
};
