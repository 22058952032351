import { connect } from 'react-redux';
import PersonalItemSelector from './component';
import sagas from './sagas';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  personalDataItems:
    state.environment.personalDataItems.get('personalDataItems'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  error: state.personalDataItems.get('error'),
  searchResults: state.personalDataItems.get('items'),
  position: state.environment.personalDataItems.get('position'),
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  initPersonalDataItems: () => {
    dispatch({ type: 'PERSONAL_DATA_ITEMS:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({
      type: 'PERSONAL_DATA_ITEMS_SELECTOR:LIST:SEARCH_INIT',
      searchParams
    });
  },
  getNextData: (position) => {
    dispatch({
      type: 'PERSONAL_DATA_ITEMS:LIST:NEXT_FETCH',
      position,
      source: 'records',
      isPersistedData: true
    });
  },
  resetSearchFilter: () => {
    dispatch({
      type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH_FILTER:RESET'
    });
    dispatch({ type: 'RECORDS:PERSONAL_DATA_ITEMS:LIST:SEARCH:RESET' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalItemSelector);
export { reducers, sagas };
