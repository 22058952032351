import Immutable from 'immutable';

import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { filterEmptySearchItems } from '@packages/utils/common-utils';

import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  userPosition: 0,
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOrder: 'DESC',
    sortOn: 'createdAt'
  }),
  searchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  items: Immutable.List(),
  workers: Immutable.List(),
  error: {
    message: '',
    isError: false
  },
  showGoBack: false,
  users: {
    items: [],
    searchText: Immutable.Map(),
    position: 0
  },
  bulkItems: Immutable.List(),
  currentUser: ''
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TASKS:INBOX:LIST:INIT':
      return state
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('searchText', state.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('filterParams', defaultState.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('users', state.get('users'))
        .set('currentUser', state.get('currentUser'))
        .set('workers', defaultState.get('workers'))
        .set('position', 0)
        .set('userPosition', 0);

    case 'TASKS:INBOX:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'TASKS:INBOX:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length)
        .set('currentUser', action.currentUser);
    case 'TASKS:INBOX:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'TASKS:INBOX:LIST:FILTER':
      return state
        .set('items', Immutable.List())
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filteredOn', getFilteredOn({
          ...action.filterParams,
          filterObj: state.get('filteredOn')
        }));

    case 'TASKS:INBOX:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));

    case 'TASKS:INBOX:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }

    case 'TASKS:INBOX:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'TASKS:INBOX:LIST:SEARCH:SUCCESS': {
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText({
          ...action,
          searchObj: state.get('searchText').toJS()
        }))
        .set('items', Immutable.List(action.items))
        .set('searchItems', getSearchItems(action));
    }
    case 'TASKS:INBOX:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'TASKS:INBOX:GOBACKLINK:SET':
      return state.set('showGoBack', true)
        .set('currentUser', action.currentUser)
    case 'TASKS:INBOX:GOBACKLINK:RESET': {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      if (urlParams.from === 'dashboard') {
        return state;
      }
      return state.set('showGoBack', false);
    }
    case 'TASKS:INBOX:WORKERS:LIST:FETCH:SUCCESS':
      return state.set('workers', state.get('workers').concat(action.items))
        .set('userPosition', state.get('userPosition') + action.items.length);
    case 'TASK:INBOX:WORKERS:RESET':
      return state.set('workers', defaultState.get('workers'))
        .set('userPosition', 0);
    case 'TASKS:INBOX:WORKERS:LIST:SEARCH:SUCCESS':
      return state.set('workers', action.items);
    case 'TASKS:INBOX:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId));
    }
    case 'TASKS:INBOX:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'TASKS:INBOX:USERS:LIST:INIT': {
      const modifiedUser = {
        searchText: state.get('users').get('searchText'),
        items: [],
        position: 0
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'TASKS:INBOX:USERS:LIST:SEARCH': {
      const user = state.get('users').toJS();
      const modifiedUser = {
        ...user,
        searchText: getSearchText({ searchKey: 'createdBy', searchText: action.searchText })
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'TASKS:INBOX:USERS:LIST:FETCH:SUCCESS': {
      const { items } = action;
      const user = state.get('users').toJS();
      const modifiedItems = user.items.concat(items);
      const modifiedUser = {
        searchText: state.get('users').get('searchText'),
        items: modifiedItems,
        position: user.position + items.length
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'TASKS:INBOX:USERS:LIST:SEARCH:SUCCESS': {
      const { items } = action;
      const modifiedUser = {
        searchText: state.get('users').get('searchText'),
        items,
        position: items.length
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'TASK:INBOX:SET:CURRENT:USER': {
      return state.set('currentUser', action.currentUser);
    }
    default:
      return state;
  }
};

export const getSearchItems = ({ searchKey, items }) => {
  const listItems = [];
  switch (searchKey) {
    case 'assignee': {
      items.forEach((item) => {
        const assignee = item.assignee && { label: item.assignee.lastName, value: item.assignee.id };
        if (listItems.findIndex(val => val?.value === assignee?.value) === -1) listItems.push(assignee);
      });
      break;
    }
    default:
      break;
  }
  const searchItems = filterEmptySearchItems(listItems, searchKey);
  return searchItems;
};
