import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  recordId: '',
  recordType: '',
  recordName: '',
  prevLocation: '/privacyrecords',
  items: Immutable.List(),
  error: {
    message: '',
    isError: false
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORD_REVISIONS:LIST:INIT': {
      const { recordType } = action;
      let prevLocation = state.get('prevLocation');
      const queryParams = getQueryStrings();
      if (queryParams.from === 'view') {
        prevLocation = `/${recordType}/${action.recordId}/view`;
      }
      return state
        .set('position', 0)
        .set('recordId', action.recordId)
        .set('recordType', action.recordType)
        .set('prevLocation', prevLocation)
        .set('recordName', action.recordName)
        .set('items', Immutable.List(action.items));
    }

    case 'RECORD_REVISIONS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));

    case 'RECORD_REVISIONS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);

    case 'RECORD_REVISIONS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
