import { replace as replaceRouter } from 'connected-react-router';
import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import {
  getErrorMessage,
  handleServiceDown
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getRequestParams, transformSearchDefinition } from './utils';

export function* createCustomReport(action) {
  const { data, isGlobal, revision, isCopy } = action;
  const requestData = isCopy
    ? { ...data }
    : {
      ...data,
      definition: transformSearchDefinition(data.definition),
      _rev: revision
    };
  const request = registry.get('request');
  const store = registry.get('store');
  try {
    const response = yield request.post('/v1/reportsearches', requestData);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.reportSearchCreated,
            type: 'success'
          }
        });
        yield put({
          type: 'REPORT:DETAIL:RESET'
        });
        if (isGlobal) {
          yield put({
            type: 'HOLDING:REPORT:ITEM:ID:SET',
            reportId: response.body.id
          });
          yield put({
            type: 'GLOBAL:SOLUTION:ADVANCED:REPORTS:FILTER:CHANGE',
            filter: 'details'
          });
        } else {
          store.dispatch(replaceRouter(`/report/${response.body.id}`));
        }
        break;
      }
      default: {
        const error = response.body ? response.body.msg : '';
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

// Update report search fields
export function* updateCustomReport(action) {
  const requestParams = getRequestParams(action);
  const request = registry.get('request');

  const modifiedRequestParams = {
    ...requestParams.requestData,
    _rev: action.revision,
    jobIdList: []
  };
  try {
    const response = yield request.put(
      requestParams.url,
      modifiedRequestParams
    );
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'CUSTOM:REPORT:DETAIL:UPDATE:SUCCESS',
          // eslint-disable-next-line no-underscore-dangle
          data: response.body._rev
        });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.reportSearchUpdated,
            type: 'success'
          }
        });
        break;
      }
      default:
        {
          const message = response.body ? response.body.msg : '';
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: message,
              type: 'error'
            }
          });
        }
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
  }
}

export function* deleteReportSearch(action) {
  try {
    const { searchId } = action;
    const response = yield registry
      .get('request')
      .delete(`/v1/reportsearches/${searchId}`, null);

    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.reportSearchDeleted,
            type: 'success'
          }
        });
        yield put({ type: 'REPORTS:LIST:REQUEST' });
        break;
      }
      default:
        {
          const error = response.body ? response.body.msg : '';
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: error,
              type: 'error'
            }
          });
        }
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
  }
}

export function* copyReport(action) {
  const store = registry.get('store');
  const request = registry.get('request');
  try {
    const response = yield request.get(
      `/v1/reportsearches/${action.searchId}`,
      null
    );
    switch (response.status) {
      case 200:
        {
          delete response.body.id;
          const data = {
            ...response.body,
            name: `Copy of ${response.body.name}`
          };
          store.dispatch({
            type: 'CUSTOM:REPORT:CREATE',
            data,
            isCopy: true,
            isGlobal: action.isGlobal
          });
        }
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
  }
}

export function* fetchReportSearches() {
  yield put({ type: 'SET:REPORT:FETCH', isFetching: true });
  try {
    const response = yield registry
      .get('request')
      .get('/v1/reportsearches?sortOn=name&sortOrder=ASC');
    yield put({
      type: 'REPORTS:LIST:REQUEST:SUCCESS',
      data: response.body
    });
  } catch (err) {
    yield handleServiceDown(err, 'report');
    yield put({ type: 'REPORTS:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* resetAndFetchReportDetail(action) {
  yield put({
    type: 'REPORT:DETAIL:RESET'
  });
  yield fetchReportDetail(action);
}

export function* fetchReportDetail(action) {
  const { id } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/reportsearches/${id}`, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'REPORT:SCHEMA:INIT',
          id: response.body.definition.recordLayout.id
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        if (response.body.jobIdList?.length) {
          yield put({
            type: 'JOB:DETAILS:FETCH:INIT',
            id: response.body.jobIdList[0],
            source: 'reports',
            formatMessage: action.formatMessage
          });
        }
        yield put({
          type: 'REPORT:DETAIL:INIT:SUCCESS',
          data: response.body,
          // eslint-disable-next-line no-underscore-dangle
          revision: response.body._rev
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: 'TENANT_EMAIL_PREFERENCE:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* fetchReportSchema(action) {
  yield put({ type: 'SET:REPORT:FETCH', isFetching: true });
  const { id } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/reportsearches/schema/${id}`, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'REPORT:SCHEMA:INIT:SUCCESS',
          data: response.body
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: 'TENANT_EMAIL_PREFERENCE:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* resetAndrunReportSearch(action) {
  yield put({ type: 'REPORT:RUN:SUCCESS:INIT' });

  yield runReportSearch(action);
}

export function* runReportSearch(action) {
  const { data, isGlobal } = action;
  const store = registry.get('store');
  const request = registry.get('request');

  try {
    const formData = JSON.parse(JSON.stringify(data));
    const requestData = {
      ...data,
      definition: transformSearchDefinition(data.definition)
    };
    const url = `/v1/reportsearches/run?offset=${action.position || 0}&${
      isGlobal ? 'numberOfResultsPerTenant' : 'numberOfResults'
    }=${action.rowCount}`;
    const response = yield request.post(url, requestData.definition);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'REPORT:RUN:SUCCESS',
          items: response.body,
          formData,
          requestData,
          prevLocation: action.currentLocation
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        if (isGlobal)
          yield put({
            type: 'GLOBAL:SOLUTION:ADVANCED:REPORTS:FILTER:CHANGE',
            filter: 'result'
          });
        else store.dispatch(replaceRouter('/report/results'));
        break;
      }
      case 409:
        yield put({
          type: 'REPORT:RUN:FAIL',
          error: 'Run search failed.'
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'SET:REPORT:FETCH', isFetching: false });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    const error = getErrorMessage(err);
    yield put({ type: 'REPORT:RUN:FAIL', error });
  }
}

export function* getReportSearchRunCount(action) {
  const { data } = action;
  const definition = transformSearchDefinition(data.definition);
  const request = registry.get('request');
  try {
    const url = `/v1/reportsearches/runCount`;
    const response = yield request.post(url, definition);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'REPORT:RUN:RESULT:COUNT:SUCCESS',
          count: response.body
        });

        break;
      }
      case 409:
        yield put({
          type: 'REPORT:RUN:RESULT:COUNT:FAIL',
          error: 'Run search failed.'
        });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    const error = getErrorMessage(err);
    yield put({ type: 'REPORT:RUN:FAIL', error });
  }
}

export function* downloadReport(action) {
  yield put({ type: 'REPORT:DOWNLOAD:INIT' });
  const request = registry.get('request');
  const { searchDefinition, format, signal, controller } = action;
  const requestData = { format, ...searchDefinition };
  let response = {};
  try {
    if (controller) {
      yield request.exportCSVbyPost(
        '/v1/reportsearches/run?queryType=csv',
        null,
        null,
        controller
      );
    } else {
      response = yield request.exportCSVbyPost(
        '/v1/reportsearches/run?queryType=csv',
        requestData,
        signal
      );
      yield response;
      switch (response.status) {
        case 200: {
          yield put({ type: 'REPORT:DOWNLOAD:SUCCESS' });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({ type: 'REPORT:DOWNLOAD:FAIL' });
          break;
      }
    }
  } catch (err) {
    if (!action.signal.aborted) yield handleServiceDown(err, 'report');
    yield put({ type: 'REPORT:DOWNLOAD:FAIL' });
  }
}
