import { connect } from 'react-redux';
import DocumentRecordsList from './documentRecordList';

const mapStateToProps = state => ({
  isFetching: state.documents.get('isFetching'),
  isUpdating: state.documents.get('isUpdating')
});


const mapDispatchToProps = dispatch => ({
  downloadDocument: (itemId, isDownloadFromRecords) => {
    dispatch({ type: 'DOCUMENTS:ITEM:DOWNLOAD', itemId, isDownloadFromRecords });
  },
  getDocumentDetails: (id) => {
    dispatch({ type: 'DOCUMENT:DETAIL:INIT', id });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRecordsList);
