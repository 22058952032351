import Immutable from 'immutable';
import filterMenus from './defaults';

const defaultState = Immutable.fromJS({
  currentFilter: '',
  menuItems: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ADMIN:FILTERS:INIT': {
      const currentFilter = state.get('currentFilter');
      let newState = state;
      if (currentFilter === '') {
        newState = newState.set('currentFilter', filterMenus[0].defaultFilter);
      } else {
        newState = newState.set('currentFilter', currentFilter);
      }

      return newState.set('menuItems', filterMenus);
    }
    case 'ADMIN:FILTERS:CHANGE': {
      // window.history.replaceState({}, document.title, '/#/admin');
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }

    default:
      return state;
  }
};

