import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';
import { recordTranslations } from '@packages/utils/commontranslations';

export function* fetchImportersList(action) {
  yield put({ type: 'IMPORTERS:LIST:REQUEST:INIT' });
  const url = `/v1/records/dataImportersWithCountry`;
  try {
    const response = yield registry.get('request').get(url);

    switch (response.status) {
      case 200: {
        const items = getModifiedItems(response.body, 'importers', action.formatMessage);
        yield put({ type: 'IMPORTERS:LIST:FETCH:SUCCESS', items });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'IMPORTERS:LIST:FETCH:FAIL' });
  }
}
export function* fetchExportersList(action) {
  yield put({ type: 'EXPORTERS:LIST:REQUEST:INIT' });
  const url = `/v1/records/dataExportersWithCountry`;
  try {
    const response = yield registry.get('request').get(url);

    switch (response.status) {
      case 200: {
        const items = getModifiedItems(response.body, 'exporters', action.formatMessage);
        yield put({ type: 'EXPORTERS:LIST:FETCH:SUCCESS', items });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'EXPORTERS:LIST:FETCH:FAIL' });
  }
}

const getModifiedItems = (items, type, formatMessage) => {
  const initialItem = [{
    label: type === 'exporters' ?
      `-- ${formatMessage(recordTranslations.defaultExporters.props)} --` :
      `-- ${formatMessage(recordTranslations.defaultImporters.props)} --`,
    value: ''
  }];
  const modifiedItems = items.map(item => ({
    label: `${item.name} (${item.country.id})`,
    value: `${item.id}_${item.country.id}`
  }));
  return initialItem.concat(modifiedItems);
};
