import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import FontAwesome from 'react-fontawesome';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import AutoComplete from '@packages/components/auto-complete';
import ArrowTooltip from '@packages/components/tooltip';
import MultipleSelector from '@packages/components/multiple-selector/multipleSelector';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

let searchDelayTimeout = null;
export class PersonalDataMultiselector extends React.Component {
  static handleGetFilteredItems(selectedPersonalDataItems, items) {
    const multiplePersonalItems = ((selectedPersonalDataItems &&
      selectedPersonalDataItems.length > 0) ?
      PersonalDataMultiselector.filterPersonalDataItems(selectedPersonalDataItems, items) :
      items);
    return multiplePersonalItems;
  }

  static filterPersonalDataItems(selectedPersonalDataItems, searchedItems) {
    const filteredData = searchedItems.filter((personalDataItem) => {
      const index = selectedPersonalDataItems.findIndex(selectedPersonalDataItem =>
        selectedPersonalDataItem.value.name === personalDataItem.name &&
        selectedPersonalDataItem.value.categoryType === personalDataItem.categoryType);
      return (index === -1);
    });
    return filteredData;
  }

  constructor (props) {
    super(props);

    this.state = {
      personalCategoryDatasource: props.categories,
      filterValues: props.filteredData,
      showAdvancedSearch: false,
      dataItemSearchText: props.searchTextValue
    };

    this.requestClose = this.requestClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onNewRequest = this.onNewRequest.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.handleShowAdvancedSearch = this.handleShowAdvancedSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.initPersonalDataCategory) {
      this.props.initPersonalDataCategory();
    }
    if (this.props.initPersonalDataItems) {
      this.props.initPersonalDataItems(this.props.searchTextValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.personalDataItems !== nextProps.personalDataItems || nextProps.personalDataItems.size > 0) {
      const { personalDataItems } = nextProps;
      const multipleDataItemsList = (this.props.selectedItems && this.props.selectedItems.length > 0) ?
        PersonalDataMultiselector.handleGetFilteredItems(nextProps.selectedItems, personalDataItems) :
        personalDataItems;
      if (multipleDataItemsList && multipleDataItemsList.size < 15 && nextProps.personalDataItems.size > 0
        && (this.props.position !== nextProps.position)) {
        this.props.getNextData(nextProps.position);
      }
      this.setState({
        filterValues: multipleDataItemsList
      });
    }
  }

  handleSearch(event, clearText) {
    const currentSearchText = clearText ? '' : event.currentTarget.value;

    clearTimeout(searchDelayTimeout);
    searchDelayTimeout = setTimeout(() => {
      this.filterData(null, currentSearchText);
    }, 500);

    this.setState({
      dataItemSearchText: currentSearchText
    });
  }

  handleChange(event, index, value) {
    this.setState({ selectedPersonalDataCategory: { ...value, searchText: value.key } });
    if (value !== null) {
      this.filterData(value);
    }
  }

  handleOnSubmit(selectedItems) {
    if (this.props.handleMultipleItems) {
      const modifiedItems = selectedItems.map(item => ({ value: item }));
      this.props.handleMultipleItems(modifiedItems);
    }
    this.requestClose();
  }

  handleRequestClose() {
    this.setState({
      multiple: false
    });
  }

  handleShowAdvancedSearch() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      showAdvancedSearch: !this.state.showAdvancedSearch
    });
  }

  handleMultipleItems(selectedItem) {
    this.setState({
      selectedPersonalDataCategory: selectedItem.size > 0 ? { ...selectedItem.get(0).value,
        searchText: selectedItem.get(0).value.key } : undefined
    });
    if (selectedItem.size > 0) this.filterData(selectedItem.get(0).value);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  onNewRequest(selectedItem, index) {
    if (index === -3) { // If selected index is -3, select from a list
      this.setState({
        multiple: true
      });
    } else {
      this.setState({
        selectedPersonalDataCategory: selectedItem
      });
      this.handleChange(null, null, selectedItem);
    }
  }

  onUpdateInput(selectedItem) {
    if (this.state.selectedPersonalDataCategory !== undefined && selectedItem === '') {
      this.props.onChooseFilter((Object.assign({}, { filterKey: 'categories',
        filteredOn: [] })), this.state.dataItemSearchText);
    }
    this.setState({
      selectedPersonalDataCategory: selectedItem !== '' ? { searchText: selectedItem } : undefined
    });
  }

  onSearch(searchText) {
    this.setState({
      selectedPersonalDataCategory: searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  filterData(filterParameters, searchValue) {
    const searchText = searchValue || this.state.dataItemSearchText;
    const filterParam = filterParameters || this.state.selectedPersonalDataCategory;

    if (filterParam) {
      this.props.onChooseFilter(Object.assign({}, { filterKey: 'categories',
        filteredOn: [filterParam.name] }), searchText);
    } else {
      this.props.onChooseFilter(null, searchText);
    }
  }

  requestClose() {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  }

  render () {
    const { chooseByCategory, multiValue, intl } = this.props;
    const { selectedPersonalDataCategory, showAdvancedSearch, dataItemSearchText } = this.state;
    return (
      <CommonDialog
        id="personal-data-item-dialog"
        show={this.props.open}
        onCancel={this.requestClose}
        maxWidth="sm"
        fullWidth={true}
        title={this.props.title}
      >
        <div id="itemByName" style={{ width: '70%', display: 'flex', paddingBottom: '20px', marginTop: '5px' }}>
          <TextField
            autoFocus={true}
            placeholder={intl.formatMessage({ id: 'Common.searchByName', defaultMessage: 'Search by name' })}
            style={{ width: '97%' }}
            value={dataItemSearchText}
            onChange={event => this.handleSearch(event)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                  <InputAdornment position="start">
                    {(dataItemSearchText && dataItemSearchText !== '') ?
                      <Clear
                        style={{ cursor: 'pointer' }}
                        onClick={event => this.handleSearch(event, true)}
                      />
                      :
                      <Search />}
                  </InputAdornment>
              )
            }}
          />
          <ArrowTooltip
            title={intl.formatMessage({ id: 'Common.advancedSearch', defaultMessage: 'Advanced search' })}
          >
            <FontAwesome
              style={{ marginLeft: '10px',
                marginTop: '10px',
                cursor: 'pointer',
                color: this.props.theme.palette.primary.main }}
              name="filter"
              size="lg"
              onClick={this.handleShowAdvancedSearch}
            />
          </ArrowTooltip>
        </div>
        {chooseByCategory && showAdvancedSearch &&
          <div style={{ width: '64%', paddingBottom: '15px', display: 'flex' }}>
            <AutoComplete
              underlineShow={false}
              openOnFocus={true}
              inputType={this.props.inputType}
              maxSearchResults={5}
              selectFromListMenuItem={true}
              fullWidth={true}
              hintText={intl.formatMessage({
                id: 'PersonalDataItem.select.category.hintText',
                defaultMessage: 'Filter by personal data category'
              })}
              searchText={(selectedPersonalDataCategory && (selectedPersonalDataCategory.searchText)) || ''}
              filter={AutoComplete.caseInsensitiveFilter}
              hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
              textFieldStyle={styles.autoComplete}
              dataSource={this.state.personalCategoryDatasource || []}
              onNewRequest={this.onNewRequest}
              dataSourceConfig={{ text: 'key', value: 'id' }}
              onUpdateInput={this.onUpdateInput}
              onSearch={this.onSearch}
            />
          </div>}
        {this.state.multiple && <MultipleSelectorDialog
          id="multiple_selector"
          multiValue={false}
          title={
            <FormattedMessage
              id="PersonalDataItem.select.category.hintText"
              description="Filter by personal data category"
              defaultMessage="Filter by personal data category"
            />}
          open={this.state.multiple}
          filteredData={this.props.categories}
          onScrollStop={this.handleScrollEnd}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />}
        <div>
          <MultipleSelector
            key={this.props.key}
            filteredData={this.state.filterValues}
            multiValue={multiValue}
            onSave={this.handleOnSubmit}
            onCancel={this.requestClose}
            onScrollStop={this.props.onScrollStop}
          />
        </div>
      </CommonDialog>
    );
  }
}

PersonalDataMultiselector.propTypes = {
  filteredData: PropTypes.instanceOf(Immutable.List),
  personalDataItems: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.instanceOf(Immutable.List),
  key: PropTypes.string,
  title: PropTypes.node,
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  multiValue: PropTypes.bool,
  chooseByCategory: PropTypes.bool,
  open: PropTypes.bool,
  categories: PropTypes.instanceOf(Immutable.List),
  onScrollStop: PropTypes.func,
  initPersonalDataCategory: PropTypes.func,
  searchText: PropTypes.shape({
    get: PropTypes.func
  }),
  searchResults: PropTypes.shape({
    size: PropTypes.number
  }),
  onSearch: PropTypes.func,
  inputType: PropTypes.string,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  onChooseFilter: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  searchTextValue: PropTypes.string,
  initPersonalDataItems: PropTypes.func,
  theme: PropTypes.objectOf(PropTypes.any)
};

PersonalDataMultiselector.defaultProps = {
  open: false,
  multiValue: true,
  chooseByCategory: true,
  title: null,
  key: '',
  searchText: {
    get: e => e
  },
  filteredData: Immutable.List(),
  personalDataItems: Immutable.List(),
  selectedItems: Immutable.List(),
  onRequestClose: e => e,
  handleMultipleItems: e => e,
  categories: Immutable.List(),
  onScrollStop: e => e,
  initPersonalDataCategory: () => {},
  searchResults: {
    size: 0
  },
  onSearch: e => e,
  inputType: 'text',
  position: 0,
  getNextData: e => e,
  onChooseFilter: e => e,
  searchTextValue: '',
  initPersonalDataItems: e => e,
  theme: {}
};

export default injectIntl(PersonalDataMultiselector);
