import React from 'react';
import PropTypes from 'prop-types';

import ProcessorHeirarchy from '../processor-hierarchy';
import { renderLabelWithRisk } from '../../renderers/renderers';

const defaultStyle = {
  rootStyle: {
    margin: 0
  }
};

/* Align processor tree inalign with other list items */
const getListStyle = (content) => {
  const subProcessorsExist =
    content.findIndex(
      (obj) => obj.value.subProcessors && obj.value.subProcessors.length > 0
    ) !== -1;
  return subProcessorsExist ? {} : { marginLeft: '-15px' };
};

class ProcessorList extends React.Component {
  render() {
    const {
      header,
      content,
      helpNotes,
      risks,
      locale,
      ignoredRuleIds,
      disabled,
      selectedJurisdictions
    } = this.props;
    const listStyle = content && getListStyle(content);

    return (
      <div style={defaultStyle.rootStyle}>
        {renderLabelWithRisk({
          headerLabel: header,
          helpNotes,
          risks,
          disabled,
          locale,
          ignoredRuleIds,
          selectedJurisdictions
        })}
        {content && content.length === 0 && (
          <div style={{ marginTop: 10, marginLeft: 30 }}>—</div>
        )}
        <div style={listStyle}>
          <ProcessorHeirarchy
            showAssignRole={this.props.showAssignRole}
            isView={true}
            selectedItems={content}
          />
        </div>
      </div>
    );
  }
}

ProcessorList.propTypes = {
  showAssignRole: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.shape({})),
  header: PropTypes.node,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  helpNotes: PropTypes.node,
  locale: PropTypes.string,
  ignoredRuleIds: PropTypes.shape({}),
  disabled: PropTypes.bool,
  selectedJurisdictions: PropTypes.arrayOf(PropTypes.string)
};

ProcessorList.defaultProps = {
  showAssignRole: false,
  header: null,
  content: [],
  risks: [],
  helpNotes: null,
  locale: 'en',
  ignoredRuleIds: {},
  disabled: false,
  selectedJurisdictions: []
};

export default ProcessorList;
