import { connect } from 'react-redux';

import TiaRecordListComponent from './tiaRecordList';

const recordType = 'tia';
const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  items: state.privacyRecords.list.get('items'),
  dataImporters: state.privacyRecords.tias.get('dataImporters'),
  dataExporters: state.privacyRecords.tias.get('dataExporters'),
  pageSearchText: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('tia').pageSearchText,
  sortOrder: state.privacyRecords.list.get('sortAndFilterParams').get('tia')
    .sortOrder,
  sortKey: state.privacyRecords.list.get('sortAndFilterParams').get('tia')
    .sortKey,
  filterColumn: state.privacyRecords.list.get('sortAndFilterParams').get('tia')
    .filterColumn,
  filteredOn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('tia')
    .filteredOn.toJS(),
  position: state.privacyRecords.list.get('position'),
  isFetching: state.privacyRecords.list.get('isFetching'),
  error: state.privacyRecords.list.get('error').toJS(),
  isUpdating: state.tia.get('isUpdating'),
  selectedRecords: state.privacyRecords.list.get('bulkItems').get('tia')
    .selectedRecords,
  pricingPlan: state.login.get('pricingPlanDetails'),
  registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
  filterMenuData: state.privacyRecords.filters.get('currentFilterData')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT', recordType });
  },
  getNextData: (position, rowCount) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount
    });
  },
  deletePrivacyRecord: (recordId) => {
    dispatch({ type: 'PRIVACY_RECORD:ITEM:DELETE', recordId, recordType });
  },
  onChooseFilter: (filterParams) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType
    });
  },
  onSearch: (searchParams) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:SEARCH_INIT',
      searchParams,
      recordType
    });
  },
  updateNote: (recordId, property, data, name) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType,
      property,
      data,
      name
    });
  },
  copyTia: (data, copyOfRecordId, isGlobal) => {
    dispatch({
      type: 'TIA:DETAIL:UPSERT',
      data,
      copyOfRecordId,
      isGlobal
    });
  },
  onRecordSelection: (selectedRecords) => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType,
      selectedRecords
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  importersInit: (formatMessage) => {
    dispatch({ type: 'DATA_IMPORTERS:LIST:REQUEST_INIT', formatMessage });
  },
  exportersInit: (formatMessage) => {
    dispatch({ type: 'DATA_EXPORTERS:LIST:REQUEST_INIT', formatMessage });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TiaRecordListComponent);
