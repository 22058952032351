import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import jwtDecode from 'jwt-decode';

import { getQueryStrings } from '@packages/utils/query-parameters';
import { serviceDownError } from '@packages/utils/commontranslations';
import {
  featurePermissionMap,
  handleServiceDown
} from '@packages/utils/common-utils';
import errortranslations from '@packages/utils/errortranslations';

function isPresent(value) {
  return value !== null && value !== undefined && value !== '';
}

function getFormErrors(username, password) {
  let usernameError;
  let passwordError;

  if (!isPresent(username)) {
    usernameError = 'Email may not be empty.';
  }

  if (!isPresent(password)) {
    passwordError = 'Password may not be empty.';
  }

  if (usernameError || passwordError) {
    return {
      username: usernameError,
      password: passwordError
    };
  }
  return null;
}

// TODO: Move this to a common place
export function getUserRolesAndId(token) {
  const decoded = jwtDecode(token);
  const roles = decoded.roles || [];
  const userId = decoded.user_id;
  const userPermissions = getUserPermissions(decoded.fine_grained_roles || []);
  const parentTenantId = decoded.parentTenant_id;
  return { roles, userPermissions, userId, parentTenantId };
}

function validateAndRedirect(redirectAfterLogin) {
  const store = registry.get('store');
  if (redirectAfterLogin) {
    const redirectUrl = redirectAfterLogin.pathname
      ? `${redirectAfterLogin.pathname}${redirectAfterLogin.search}`
      : redirectAfterLogin;
    store.dispatch(replaceRouter(redirectUrl));
  } else {
    store.dispatch(replaceRouter('/validateotp', {}));
  }
}

const getMfaEnabledAndConfigured = (token) => {
  const decoded = jwtDecode(token);
  const isMfaEnabled = decoded.is_mfa_enabled;
  const isMFAConfigured = decoded.is_mfa_configured;
  return { isMfaEnabled, isMFAConfigured };
};

export function* onPostLogin(action) {
  const { response } = action;
  const config = registry.get('config');
  const { httpHeader, redirectLocationHeader } = config.login.token;

  const { roles, userPermissions, userId } = getUserRolesAndId(
    response.headers.get(httpHeader)
  );
  const token = response.headers.get(httpHeader);
  const location = response.headers.get(redirectLocationHeader);
  registry.get('storage').setItem(config.login.token.storage.key, token);

  if (location) window.location.replace(location);
  const tenantLocale = (token && jwtDecode(token).tenant_language_code) || 'en';

  const userData = {
    username: action.username,
    userId,
    token,
    roles,
    tenantLocale,
    userPermissions
  };

  registry.get('storage').setItem(config.login.token.storage.key, token);
  registry
    .get('storage')
    .setItem(config.login.user.storage.key, JSON.stringify(userData));

  const locale = (token && jwtDecode(token).language_code) || 'en';
  yield put({ type: 'TRANSLATION:INIT', locale });
  if (token) {
    yield put({
      type: 'LOGIN:DO_LOGIN:SUCCESS',
      user: userData
    });
    // TODO: Fix provided to clear Unauthorised notifications
    // occurring in a failed router url access followed by a succesful login.
    yield put({ type: 'NOTIFIER:CLEAR:ALL' });
    yield checkMfaAndRedirect(roles, response);
    yield put({ type: 'LOGIN:LOGIN_REDIRECT_USE' });
  }
}

export function* loginFlow(action) {
  const config = registry.get('config');
  const errors = getFormErrors(action.username, action.password);
  if (errors) {
    yield put({
      type: 'LOGIN:DO_LOGIN:FAIL',
      username: errors.username,
      password: errors.password
    });
    return;
  }

  try {
    const response = yield registry
      .get('request')
      .post(config.login.login.url, {
        email: action.username,
        password: action.password
      });
    switch (response.status) {
      case 204:
        yield put({
          type: 'LOGIN:POST_LOGIN:SUCCESS',
          response,
          username: action.username
        });
        break;
      case 423:
        yield put({
          type: 'LOGIN:DO_LOGIN:FAIL',
          loginError: response.body.msg
        });
        break;
      case 412:
        yield put({
          type: 'LOGIN:DO_LOGIN:FAIL',
          loginError: response.body.msg
        });
        break;
      default:
        yield put({
          type: 'LOGIN:DO_LOGIN:FAIL',
          loginError: response.body.content
        });
        break;
    }
  } catch (err) {
    registry.get('logger').error(err);
    if (err.status === 401) {
      yield put({
        type: 'LOGIN:DO_LOGIN:FAIL',
        loginError: err.message
      });
    } else if (err.status === 502) {
      yield put({
        type: 'LOGIN:DO_LOGIN:FAIL',
        loginError: serviceDownError('user')
      });
    } else {
      const errorMsg =
        err.response && err.response.body && err.response.body.msg
          ? err.response.body.msg
          : errortranslations.authenticationError;
      yield put({ type: 'LOGIN:DO_LOGIN:FAIL', loginError: errorMsg });
    }
  }
}

export function* checkMfaAndRedirect(roles, response) {
  const config = registry.get('config');
  const store = registry.get('store');
  const { httpHeader } = config.login.token;
  const authToken = response.headers.get(httpHeader);
  const { isMfaEnabled } = getMfaEnabledAndConfigured(authToken);
  const { userPermissions } = getUserRolesAndId(authToken);

  // If login from the link of reset Qr code, append the token with url '/validateotp'
  const hashURL = window.location.hash;
  if (hashURL.indexOf('resetqrcode') !== -1) {
    const { token } = getQueryStrings(hashURL);
    store.dispatch(replaceRouter(`/validateotp?token=${token}`, {}));
  } else {
    // This function checks the user roles, isMfaEnabled and isMFAConfigured
    // and redirect to either dashboard page or validate otp page.
    yield validateAndCallPages(isMfaEnabled, roles, userPermissions);
  }
}

const getUserPermissions = (fineGrainedRoles) =>
  Object.fromEntries(
    Object.keys(featurePermissionMap).map((key) => [
      key,
      featurePermissionMap[key].some((permission) =>
        fineGrainedRoles.includes(permission)
      )
    ])
  );

let invokePricingUsage;

export function invokePricingPlanAPI() {
  if (invokePricingUsage === undefined) {
    invokePricingUsage = setInterval(() => {
      const store = registry.get('store');
      store.dispatch({ type: 'TENANT:PRICING:PLAN:INIT' });
    }, 60000);
  }
}

export function stopPricingPlanAPI() {
  clearInterval(invokePricingUsage);
  invokePricingUsage = null;
}

export function* getPricingPlanUsage() {
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tenants/billing-plan-usage`, null, null, true, false);
    switch (response.status) {
      case 200:
        yield put({ type: 'TENANT:PRICING:PLAN:SUCCESS', data: response.body });
        break;
      default:
        yield put({
          type: 'TENANT:PRICING:PLAN:FAIL',
          error: response.body.content
        });
        break;
    }
  } catch (err) {
    registry.get('logger').error(err);
    yield put({ type: 'TENANT:PRICING:PLAN:FAIL', error: err });
  }
}

export function* refreshFlow(action) {
  const config = registry.get('config');
  const store = registry.get('store');
  try {
    const { httpHeader } = config.login.token;
    const redirectAfterLogin = store.getState().login.get('redirectAfterLogin');
    yield put({ type: 'LOGIN:LOGIN_REDIRECT_USE' });
    const response = yield registry.get('request').post(
      config.login.refresh.url,
      null,
      {
        headers: {
          [httpHeader]: action.authToken
        }
      },
      false
    );
    document.cookie =
      'X-AUTH-PRIVACYPERFECT= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    if (response.status === 204 || response.status === 200) {
      const username = registry
        .get('storage')
        .getItem(config.login.user.storage.key)
        ? JSON.parse(
          registry.get('storage').getItem(config.login.user.storage.key)
        ).username
        : '';
      const { roles, userPermissions, userId, parentTenantId } =
        getUserRolesAndId(response.headers.get(httpHeader));

      const token = response.headers.get(httpHeader);
      const tenantLocale =
        (token && jwtDecode(token).tenant_language_code) || 'en';
      const userData = {
        token: response.headers.get(httpHeader),
        userId,
        username,
        roles,
        userPermissions,
        parentTenantId,
        tenantLocale
      };
      registry
        .get('storage')
        .setItem(
          config.login.token.storage.key,
          response.headers.get(httpHeader)
        );
      registry
        .get('storage')
        .setItem(config.login.user.storage.key, JSON.stringify(userData));

      yield put({ type: 'USER:PROFILE:REQUEST' });

      yield put({ type: 'LOGIN:VERIFY:SUCCESS', user: userData });

      store.dispatch({ type: 'TENANT:PRICING:PLAN:INIT' });
      invokePricingPlanAPI();
      // Validate the roles and navigate
      const locale = (token && jwtDecode(token).language_code) || 'en';
      yield put({ type: 'TRANSLATION:INIT', locale });
      validateAndRedirect(redirectAfterLogin);
    } else {
      registry.get('storage').removeItem(config.login.token.storage.key);
      registry.get('storage').removeItem(config.login.user.storage.key);
      yield put({ type: 'LOGIN:VERIFY:FAIL', username: response.body.content });
    }
  } catch (err) {
    registry.get('logger').error(err);
    yield put({ type: 'LOGIN:VERIFY:FAIL', error: err });
  }
}

export function* forgotPassword(action) {
  try {
    const response = yield registry
      .get('request')
      .post(`/v1/users/forgot-password`, { email: action.emailId });
    if (response.status === 202) {
      yield put({ type: 'LOGIN:FORGOT_PASSWORD:SUCCESS' });
      yield put(
        replaceRouter({
          pathname: '/login'
        })
      );
    } else {
      yield put({
        type: 'LOGIN:FORGOT_PASSWORD:FAIL',
        errorMessage: response.body.msg
      });
    }
  } catch (err) {
    yield put({
      type: 'LOGIN:FORGOT_PASSWORD:FAIL',
      errorMessage: err.message
    });
  }
}

export function* tokenRefreshFlow(action) {
  const config = registry.get('config');
  try {
    const response = yield registry.get('request').post(
      config.login.refresh.url,
      null,
      {
        headers: {
          [config.login.token.httpHeader]: action.authToken
        }
      },
      false
    );
    if (response.status === 204 || response.status === 200) {
      registry
        .get('storage')
        .setItem(
          config.login.token.storage.key,
          response.headers.get(config.login.token.httpHeader)
        );
    } else {
      registry.get('storage').removeItem(config.login.token.storage.key);
      registry.get('storage').removeItem(config.login.user.storage.key);
      yield put({ type: 'LOGIN:TOKEN_REFRESH:FAIL' });
    }
  } catch (err) {
    registry.get('logger').error(err);
    yield put({ type: 'LOGIN:TOKEN_REFRESH:FAIL', error: err });
  }
}

// let current = 0;
export function* tokenNotValidFlow(action) {
  const store = registry.get('store');
  // const newLocation = store.getState().routing.locationBeforeTransitions;
  // newLocation.query.forceRefresh = current += 1;
  const redirect = action.redirect
    ? action.redirect
    : store.getState().router.locationBeforeTransitions;
  yield put({ type: 'LOGIN:VERIFY:FAIL' });
  yield put({ type: 'LOGIN:LOGIN_REDIRECT_SET', redirect });
  stopPricingPlanAPI();
  yield put(
    replaceRouter({
      pathname: '/login'
    })
  );
}

export function validateAndCallPages(isMfaEnabled, roles, userPermissions) {
  const store = registry.get('store');
  const isSuperAdministrator = roles.includes('SuperAdministrator');

  const isPartnerAdmin = roles.includes('PartnerAdministrator');
  if (isMfaEnabled === false) {
    // Validate the roles and navigate
    if (userPermissions.tenantExpired)
      store.dispatch(replaceRouter('/subscription'));
    else if (isPartnerAdmin) store.dispatch(replaceRouter('/admin'));
    else store.dispatch(replaceRouter('/'));
  } else if (isSuperAdministrator) {
    // If user have SuperAdministrator role then can redirect directly to dashboard
    store.dispatch(replaceRouter('/'));
  } else {
    store.dispatch(replaceRouter('/validateotp', {}));
  }
}

export function* getSSOURL(action) {
  try {
    const store = registry.get('store');
    const { mode = 'live' } = action;
    const response = yield registry
      .get('request')
      .get(`/v1/sso/url?mode=${mode}`, null, null, false);

    switch (response.status) {
      case 200:
        {
          const redirectUrl = response.body;
          if (
            typeof redirectUrl === 'string' &&
            redirectUrl.indexOf('SAMLRequest') !== -1
          )
            window.location.replace(redirectUrl);
          else if (action.isFromLoginRouterHook) {
            store.dispatch({
              type: 'PROCEED:WITH:RENDER',
              proceedWithRender: true
            });
          } else store.dispatch(replaceRouter('/login'));
        }
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'SSO_URL:FETCH:FAIL', error: err.message });
  }
}

export function* checkSSOAndDSREnabled(action) {
  const config = registry.get('config');
  const userKey = config.login.user.storage.key;
  const authKey = config.login.token.storage.key;
  const { isHoldingTenant } = action;
  try {
    const token =
      registry.get('storage').getItem(userKey) && !isHoldingTenant
        ? JSON.parse(registry.get('storage').getItem(userKey)).token
        : registry.get('storage').getItem(authKey);
    const decoded = jwtDecode(token);
    const response = yield registry
      .get('request')
      .get(`/v1/tenants/${decoded.tenant_uuid}/settings`, null);

    switch (response.status) {
      case 200: {
        const { enableSSO, enableDSR } = response.body;
        yield put({
          type: 'SSO_DSR:ENABLED:CHECK:SUCCESS',
          enableSSO,
          enableDSR
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'SSO_DSR:ENABLED:CHECK:FAIL', error: err.message });
  }
}

export function* fetchAllAccessGroup() {
  yield put({ type: 'LOADER:SHOW' });

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/user-access/access-group`);

    yield put({ type: 'LOADER:HIDE' });

    switch (response.status) {
      case 200: {
        yield put({
          type: 'ACCESS_GROUP:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
  }
}
