import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  data: {},
  isEdit: false,
  partnerId: undefined,
  actionError: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PARTNER:DETAIL:INIT':
      return state.set('partnerId', defaultState.get('partnerId'))
        .set('isEdit', defaultState.get('isEdit'))
        .set('data', defaultState.get('data'))
        .set('actionError', defaultState.get('actionError'));
    case 'PARTNER:DETAIL:UPSERT:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));
    case 'PARTNER:DETAIL:FETCH':
      return state.set('partnerId', action.partnerId).set('isEdit', true);
    case 'PARTNER:DETAIL:FETCH:SUCCESS':
      return state.set('data', action.data);
    case 'PARTNER:DETAIL:FETCH:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));
    case 'PARTNER:DETAIL:UPSERT':
      return state.set('actionError', defaultState.get('actionError'));
    default:
      return state;
  }
};
