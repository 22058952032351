import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';

import {
  renderTextField,
  renderMultipleCountriesList
} from '@packages/components/form-components';
import { commonTranslations } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import styles from '@packages/ui/styles';
import countryTranslations from '@packages/components/country-selector/country';
import { renderChipSelector } from '@packages/features/privacy-record-detail/renderers/renderers';

import { isMasterDataEditFormVisible } from '../../../utils';
import ImpactAnalysisPage from '../../../impact-analysis';
import { hintTextTranslations } from '../../../masterDataTranslations';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });

  return errors;
};

export const DataRecipientCategoryForm = (props) => {
  const {
    actionError,
    change,
    dataItemId,
    dataRecipientCategoryData,
    globalUsage,
    handleSubmit,
    hasNoLimit,
    init,
    initialize,
    intl,
    isEdit,
    isUsed,
    onCancel,
    resetDataRecipientCategories,
    submitting,
    updateDataRecipientCategory,
    usage,
    value,
    hideTags,
    isTagEdit
  } = props;

  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);

  useEffect(() => {
    if (dataItemId) init(dataItemId);
    if (!isEdit) handleInitialise();
  }, []);

  useEffect(() => {
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    if (dataRecipientCategoryData !== undefined) {
      if (showForm)
        window.document.getElementById('simpleEntityDialog')?.focus();
      if (onAfterUpdation) props.onSave(dataRecipientCategoryData, closeDialog);
      else handleInitialise(dataRecipientCategoryData);
    }
  }, [dataRecipientCategoryData]);

  useEffect(
    () => () => {
      resetDataRecipientCategories();
    },
    []
  );

  const onSave = (dataRecipientCategoryData, closeDialogValue) => {
    let modifiedData = {
      ...dataRecipientCategoryData,
      name: dataRecipientCategoryData.name.trim()
    };
    if (modifiedData.countries) {
      modifiedData = {
        ...modifiedData,
        countries: modifiedData.countries.map((item) =>
          isUsed
            ? { id: item.value.countryCode || item.countryCode }
            : item.value.countryCode || item.countryCode
        )
      };
    }
    const { isEdit, source } = props;
    const isCountryChanged =
      globalUsage &&
      compareCountries(
        modifiedData.countries,
        dataRecipientCategoryData.countries
      );
    const isJobCreationRequired = (isUsed || isCountryChanged) && !isView;
    updateDataRecipientCategory(
      modifiedData,
      isEdit,
      source,
      isJobCreationRequired
    );
    setCloseDialog(closeDialogValue);
    setOnAfterUpdation(true);
    if (!closeDialogValue) initialize({});
  };

  const compareCountries = (newCountries, existingCountries) => {
    const hasSameCountries =
      newCountries.every((item) => existingCountries.includes(item)) &&
      existingCountries.every((item) => newCountries.includes(item));
    return !hasSameCountries;
  };

  const handleInitialise = (entityData) => {
    if (!isEdit) initialize(value);
    else {
      let modfiedItem = entityData;
      if (entityData.countries) {
        const countryList = countryTranslations.filter((item) =>
          entityData.countries.includes(item.value)
        );
        const modifiedCountrList = countryList.map((item) => ({
          ...item,
          countryCode: item.value,
          key: intl.formatMessage(item.text.country),
          value: intl.formatMessage(item.text.country)
        }));
        modfiedItem = {
          ...entityData,
          countries: modifiedCountrList
        };
      }
      initialize(modfiedItem);
    }
  };

  const updateCountries = (selectedCountries) => {
    change('countries', selectedCountries);
  };

  const handleTagChange = (data) => {
    change('tags', data);
  };

  const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
  const isView = isEdit && isTagEdit;

  return (
    <form>
      {isUsed && (
        <ImpactAnalysisPage
          hasNoLimit={hasNoLimit}
          usage={usage}
          type="DataRecipientCategory"
        />
      )}
      {showForm && (
        <div>
          <div>
            <div>
              <Field
                id="simpleEntityDialog"
                name="name"
                label={commonTranslations.name}
                hintTextLabel={hintTextTranslations.dataRecipientCategories}
                autoFocus={!isEdit}
                isEdit={isEdit}
                component={renderTextField}
                isView={isView}
              />
            </div>
            <div style={{ width: '100%' }}>
              <FieldArray
                name="countries"
                label={commonTranslations.dataRecipientLocation}
                multipleSelect={true}
                updateCountries={updateCountries}
                hintTextLabel={commonTranslations.selectCountry}
                component={renderMultipleCountriesList}
                isView={isView}
              />
            </div>
            <div style={{ width: '100%' }}>
              {!hideTags && (
                <FieldArray
                  id="tags"
                  name="tags"
                  type="tags"
                  entityType="datarecipientcategory"
                  disabled={isEdit && !isTagEdit}
                  headerLabel={commonTranslations.tags}
                  hintText={commonTranslations.addTags}
                  updateTags={handleTagChange}
                  chipSelectorStyle={{ width: '100%' }}
                  showHeader={isTagEdit || !isEdit}
                  component={renderChipSelector}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {actionError && actionError.isError && (
        <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
          {actionError.message}
        </div>
      )}
      <div style={styles.addButton}>
        {showForm && (
          <Button
            id="submit"
            disabled={submitting}
            type="submit"
            onClick={handleSubmit((entityData) => onSave(entityData, true))}
          >
            {commonTranslations.saveAndClose}
          </Button>
        )}
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            disabled={submitting}
            onClick={handleSubmit((entityData) => onSave(entityData, false))}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}
        {showForm && (
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        )}
        {!hasNoLimit && isUsed && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
    </form>
  );
};

DataRecipientCategoryForm.propTypes = {
  isEdit: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
  updateDataRecipientCategory: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  resetDataRecipientCategories: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  organisation: PropTypes.shape({
    country: PropTypes.string
  }),
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  dataRecipientCategoryData: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.string)
  }),
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  change: PropTypes.func,
  globalUsage: PropTypes.bool,
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

DataRecipientCategoryForm.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  handleSubmit: (e) => e,
  resetDataRecipientCategories: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  organisation: null,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  dataRecipientCategoryData: {},
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  change: (e) => e,
  globalUsage: false,
  isTagEdit: false,
  hideTags: false
};

const DataRecipientCategoryFormWrapper = reduxForm({
  form: 'DataRecipientCategoryForm', // a unique identifier for this form
  validate
})(DataRecipientCategoryForm);

export default injectIntl(DataRecipientCategoryFormWrapper);
