import { connect } from 'react-redux';
import EntityImport from './entityImport';

const mapDispatchToProps = dispatch => ({
  init: (entityFilter) => {
    dispatch({ type: 'SUBTENANT:ORGANISATION:LIST:FETCH', entityFilter, rowCount: 15 });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'SUBTENANT:ORGANISATION:LIST:SEARCH_INIT', searchParams, isImport: true, rowCount: 15 });
  }
});

const mapStateToProps = state => ({
  holdingOrgList: state.organisationEntities.get('holdingOrgList'),
  searchResults: state.organisationEntities.get('importItems')
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityImport);
