import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: '',
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MESSAGES:USERS:SELECTOR:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', action.searchText)
        .set('items', Immutable.List(action.items));
    case 'MESSAGES:USERS:SELECTOR:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
