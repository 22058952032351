import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  reportsList: {},
  isFetching: false,
  jobDetail: undefined,
  schema: Immutable.Map(),
  revision: '',
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  unSavedData: false,
  reportDetail: {},
  resultItems: Immutable.List(),
  count: 0,
  position: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'REPORTS:LIST:REQUEST':
      return state
        .set('reportsList', defaultState.get('reportsList'))
        .set('error', defaultState.get('error'));
    case 'REPORTS:LIST:REQUEST:SUCCESS':
      return state
        .set('reportsList', Immutable.List(action.data))
        .set('isFetching', false);
    case 'REPORTS:LIST:REQUEST:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'REPORT:DETAIL:RESET':
      return defaultState;
    case 'REPORT:DETAIL:INIT:SUCCESS':
      return state
        .set('reportDetail', action.data)
        .set('revision', action.revision);
    case 'REPORT:DETAIL:DATA:RESET':
      return state
        .set('reportDetail', defaultState.get('reportDetail'));
    case 'REPORT:SCHEMA:INIT:SUCCESS':
      return state.set('schema', action.data);

    case 'REPORT:DETAIL:UNSAVED:SET':
      return state.set('unSavedData', action.data);

    case 'CUSTOM:REPORT:DETAIL:UPDATE:SUCCESS':
      return state.set('revision', action.data);

    case 'REPORT:DOWNLOAD:INIT':
      return state.set('isDownloading', true);
    case 'REPORT:DOWNLOAD:FAIL':
    case 'REPORT:DOWNLOAD:SUCCESS':
      return state.set('isDownloading', false);
    case 'REPORT:RUN:SUCCESS:INIT':
      return state
        .set('resultItems', defaultState.get('resultItems'))
        .set('position', defaultState.get('position'));
    case 'REPORT:RUN:SUCCESS': {
      return state
        .set('isFetching', false)
        .set('resultItems', state.get('resultItems').concat(action.items))
        .set('requestData', action.requestData)
        .set('formData', action.formData)
        .set('prevLocation', action.prevLocation)
        .set('position', state.get('position') + action.items.length);
    }
    case 'REPORT:RUN:RESULT:COUNT:SUCCESS':
      return state.set('count', action.count);
    case 'REPORT:RUN:RESULT:COUNT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false);
    case 'REPORT:RUN:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false);
    case 'REPORT:RUN:RESET':
      return state
        .set('formData', undefined)
        .set('resultItems', defaultState.get('resultItems'))
        .set('position', 0);
    case 'SET:REPORT:FETCH':
      return state.set('isFetching', action.isFetching);
    case 'REPORT:DETAIL:JOB:FETCH:SUCCESS':
      return state.set('jobDetail', action.jobItem);
    case 'REPORT:LIST:REQUEST:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'REPORT:SCHEMA:RESET':
      return state.set('schema', defaultState.get('schema'));
    case 'HOLDING:REPORT:ITEM:ID:SET':
      return state.set('holdingReportId', action.reportId);
    default:
      return state;
  }
};
