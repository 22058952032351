import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { getQueryStrings } from '@packages/utils/query-parameters';
import {
  sortItems,
  checkIsCreatedUser,
  FLAT,
  NO_LIMIT,
  TOP_DOWN,
  addKeyToDataSource
} from '@packages/utils/common-utils';
import {
  commonTranslations,
  serviceDownError
} from '@packages/utils/commontranslations';
import {
  getDiffContent,
  getContent,
  addKeyToLegalGrounds,
  addKeyToLegalEntities,
  transformProcessorObject,
  transformLegalEntities,
  transformDiffItems,
  transformLegalGrounds
} from './common-utils';
import JsonDiff from './record-diff';

// Get default update request params.
export const getDefaultRequestParams = (action, renderData) => {
  const { property, data } = action;
  switch (property) {
    case 'processors':
    case 'dataSources':
    case 'personalDataItems':
    case 'dataSubjectCategories':
    case 'processingGrounds':
    case 'transferGrounds':
    case 'aclUsers':
    case 'aclOrgs':
    case 'endDate':
    case 'startDate':
    case 'attachments': {
      return {
        renderData
      };
    }
    case 'controllers':
    case 'executingEntities':
    case 'dataRecipients': {
      const modifiedRenderData = getRequestRenderData(data, 'renderData');
      return {
        renderData: { [property]: modifiedRenderData }
      };
    }
    default: {
      const propertyObj = {};
      propertyObj[property] = data;
      return {
        requestData: propertyObj,
        renderData
      };
    }
  }
};

export const getModifiedCustomFormData = (data) => {
  const fieldMapping = {
    text: 'customTextFields',
    htmlText: 'customHTMLTextFields',
    simpleMasterData: 'customSimpleMasterDatas'
  };
  const modifiedData = {};
  // For simple master data the form value is in the format for request data.
  Object.entries(fieldMapping).forEach(([type, map]) => {
    modifiedData[map] = Object.entries(data[type] || {}).reduce(
      (obj, [fieldId, value]) => {
        if (type === 'simpleMasterData' && value.length) {
          obj[fieldId] = value;
        } else if (type === 'simpleMasterData' && !value.length) {
          obj[fieldId] = [];
        } else {
          obj[fieldId] = { value };
        }
        return obj;
      },
      {}
    );
  });
  return modifiedData;
};

// Transform record details to request Data
export const transformDefaultRequestData = (requestData, isGlobal) => {
  const reqData = JSON.parse(JSON.stringify(requestData));

  const { text, htmlText, stakeholder, checkbox, picklist, scoredPicklist } =
    reqData;

  if (text)
    Object.keys(text).forEach((key) => {
      if (!text[key].value)
        text[key] = {
          value: text[key]
        };
    });

  if (htmlText)
    Object.keys(htmlText).forEach((key) => {
      if (!htmlText[key].value)
        htmlText[key] = {
          value: htmlText[key]
        };
    });

  if (stakeholder)
    Object.keys(stakeholder).forEach((key) => {
      stakeholder[key] = stakeholder[key].map((item) => ({
        ...item,
        value: { id: item.value.id }
      }));
    });

  if (picklist)
    Object.keys(picklist).forEach((key) => {
      if (!picklist[key].value)
        picklist[key] = picklist[key].id
          ? {
            value: picklist[key]
          }
          : null;
    });

  if (checkbox)
    Object.keys(checkbox).forEach((key) => {
      checkbox[key] = checkbox[key].map((item) =>
        item.value ? { ...item } : { value: { id: item } }
      );
    });

  if (scoredPicklist)
    Object.keys(scoredPicklist).forEach((key) => {
      if (!scoredPicklist[key].value)
        scoredPicklist[key] = scoredPicklist[key].id
          ? {
            value: scoredPicklist[key]
          }
          : null;
    });

  if (requestData.custom) {
    // Transform custom fields data
    const modifiedCustomFormData = getModifiedCustomFormData(
      requestData.custom
    );
    reqData.customTextFields = modifiedCustomFormData.customTextFields;
    reqData.customHTMLTextFields = modifiedCustomFormData.customHTMLTextFields;
    reqData.customSimpleMasterDatas =
      modifiedCustomFormData.customSimpleMasterDatas;
  }

  // Transform legal entity items
  reqData.controllers = transformLegalEntities(reqData.controllers);
  reqData.executingEntities = transformLegalEntities(reqData.executingEntities);
  reqData.dataRecipients = transformLegalEntities(reqData.dataRecipients);
  reqData.processingGrounds = transformLegalGrounds(reqData.processingGrounds);

  reqData.transferGrounds = transformLegalGrounds(reqData.transferGrounds);
  reqData.appiDomesticTransferGrounds = transformLegalGrounds(
    reqData.appiDomesticTransferGrounds
  );
  reqData.appiThirdPartyDataTransferGrounds = transformLegalGrounds(
    reqData.appiThirdPartyDataTransferGrounds
  );
  reqData.appiInternationalTransferGrounds = transformLegalGrounds(
    reqData.appiInternationalTransferGrounds
  );
  reqData.piplInternationalTransferGrounds = transformLegalGrounds(
    reqData.piplInternationalTransferGrounds
  );
  reqData.ukgdprInternationalTransferGrounds = transformLegalGrounds(
    reqData.ukgdprInternationalTransferGrounds
  );
  reqData.pdpaProcessingGrounds = transformLegalGrounds(
    reqData.pdpaProcessingGrounds
  );
  reqData.pdpaSpecialProcessingGrounds = transformLegalGrounds(
    reqData.pdpaSpecialProcessingGrounds
  );
  reqData.piplProcessingGrounds = transformLegalGrounds(
    reqData.piplProcessingGrounds
  );
  reqData.lgpdProcessingGrounds = transformLegalGrounds(
    reqData.lgpdProcessingGrounds
  );
  reqData.lgpdSpecialProcessingGrounds = transformLegalGrounds(
    reqData.lgpdSpecialProcessingGrounds
  );
  reqData.lgpdInternationalTransferGrounds = transformLegalGrounds(
    reqData.lgpdInternationalTransferGrounds
  );
  reqData.fdplProcessingGrounds = transformLegalGrounds(
    reqData.fdplProcessingGrounds
  );
  reqData.sgpdpaProcessingGrounds = transformLegalGrounds(
    reqData.sgpdpaProcessingGrounds
  );
  reqData.sgpdpaTransferGrounds = transformLegalGrounds(
    reqData.sgpdpaTransferGrounds
  );
  reqData.pdpProcessingGrounds = transformLegalGrounds(
    reqData.pdpProcessingGrounds
  );
  reqData.ukgdprProcessingGrounds = transformLegalGrounds(
    reqData.ukgdprProcessingGrounds
  );
  reqData.breachGrounds = transformLegalGrounds(reqData.breachGrounds);
  reqData.attachments =
    reqData.attachments &&
    reqData.attachments.map((attachment) => {
      const attachmentItem = attachment.value ? attachment.value : attachment;
      return {
        note: attachment.note,
        value: {
          id: attachmentItem.id,
          name: attachmentItem.name
        }
      };
    });

  if (reqData.linkedDocumentRecords) {
    reqData.linkedDocumentRecords = reqData.linkedDocumentRecords.map(
      (item) => {
        if (item.value) return item;
        return {
          ...item,
          value: {
            name: item.name,
            id: item.id
          }
        };
      }
    );
  }

  if (reqData.processors && reqData.processors.children) {
    const modifiedProcessors = transformProcessorObject(
      Object.assign({}, reqData.processors),
      false
    ).subProcessors;
    reqData.processors = modifiedProcessors;
  }

  reqData.dataSources =
    reqData.dataSources &&
    reqData.dataSources.map((dataSource) => ({
      ...dataSource,
      value: {
        // ...dataSource.value,
        id: dataSource.value.id,
        name: dataSource.value.name
      }
    }));

  if (reqData.dataRecipientCategories) {
    reqData.dataRecipientCategories = reqData.dataRecipientCategories.map(
      (dataRecipientCategory) => {
        const tempDataRecipientCategory = dataRecipientCategory; // to ignore no-param-reassign
        if (tempDataRecipientCategory.value.countries) {
          delete tempDataRecipientCategory.value.countries;
        }
        return {
          ...tempDataRecipientCategory,
          value: { ...tempDataRecipientCategory.value }
        };
      }
    );
  }
  if (reqData.appiThirdPartyDataReceivedFrom) {
    reqData.appiThirdPartyDataReceivedFrom =
      reqData.appiThirdPartyDataReceivedFrom.map((organisation) => {
        const tempOrganisation = { ...organisation };
        if (tempOrganisation.value.country) {
          delete tempOrganisation.value.country;
        }
        return { ...tempOrganisation, value: { ...tempOrganisation.value } };
      });
  }
  if (reqData.personalDataItems) {
    reqData.personalDataItems = reqData.personalDataItems.map(
      (personalDataItem) => {
        const tempPersonalDataItem = personalDataItem; // to ignore no-param-reassign
        if (tempPersonalDataItem.value.categories) {
          delete tempPersonalDataItem.value.categories;
        }
        return { ...personalDataItem, value: { ...personalDataItem.value } };
      }
    );
  }

  if (reqData.aclUsers || reqData.aclOrgs) {
    const userRights = reqData.aclUsers
      ? reqData.aclUsers.map((aclUser) => {
        const user = aclUser.value ? aclUser.value : aclUser;
        return {
          value: {
            subject: {
              id: user.id
            },
            rights: aclUser.rights || aclUser.value.rights
          }
        };
      })
      : [];

    const orgRights =
      reqData.aclOrgs && !isGlobal
        ? reqData.aclOrgs.map((aclOrg) => {
          const org = aclOrg.value ? aclOrg.value : aclOrg;
          return {
            value: {
              subject: {
                id: org.value ? org.value.id : org.id
              },
              rights: ['Write', 'Read']
            }
          };
        })
        : [];

    reqData.acl = {
      userRights,
      organisationRights: orgRights
    };
    delete reqData.aclUsers;
    delete reqData.aclOrgs;
  }

  // company access is set for all the templates created from holding.
  if (isGlobal) {
    reqData.companyAccess = true;
  }

  // Delete the params from data.
  delete reqData.id;
  delete reqData.custom;
  delete reqData.lastChangedBy;
  delete reqData.createdBy;
  return reqData;
};

const getModifiedCustomDataResponse = (respData) => {
  const modifiedData = {};
  const fieldMap = {
    customTextFields: 'text',
    customHTMLTextFields: 'htmlText',
    customSimpleMasterDatas: 'simpleMasterData'
  };
  Object.entries(fieldMap).forEach(([key, map]) => {
    modifiedData[map] = {};
    Object.entries(respData[key] || {}).forEach(([key, value]) => {
      modifiedData[map][key] = value.length ? value : value.value;
    });
  });
  return modifiedData;
};

// Transform response to Record Details
export function transformDefaultResponseData(
  responseData,
  currentUser,
  isFromTemplate
) {
  const userId = currentUser ? currentUser.get('id') : '';
  const respData = Object.assign({}, responseData);

  if (respData.checkbox)
    Object.keys(respData.checkbox).forEach((key) => {
      respData.checkbox[key] = respData.checkbox[key]?.map(
        (item) => item.value?.id
      );
    });

  if (respData.picklist)
    Object.keys(respData.picklist).forEach((key) => {
      respData.picklist[key] = respData.picklist[key]?.value;
    });

  if (respData.scoredPicklist)
    Object.keys(respData.scoredPicklist).forEach((key) => {
      respData.scoredPicklist[key] = respData.scoredPicklist[key]?.value;
    });

  if (respData.text)
    Object.keys(respData.text).forEach((key) => {
      respData.text[key] = respData.text[key]?.value;
    });

  if (respData.htmlText)
    Object.keys(respData.htmlText).forEach((key) => {
      respData.htmlText[key] = respData.htmlText[key]?.value;
    });

  // Transform custom fields data
  respData.custom = getModifiedCustomDataResponse(respData);

  // Add key to legal entities
  respData.controllers = addKeyToLegalEntities(respData.controllers);
  respData.processors = addKeyToLegalEntities(respData.processors);
  respData.dataRecipients = addKeyToLegalEntities(respData.dataRecipients);
  respData.executingEntities = addKeyToLegalEntities(
    respData.executingEntities
  );

  // Add key to legal grounds

  respData.processingGrounds = addKeyToLegalGrounds(respData.processingGrounds);

  respData.pdpaProcessingGrounds = addKeyToLegalGrounds(
    respData.pdpaProcessingGrounds
  );
  respData.piplProcessingGrounds = addKeyToLegalGrounds(
    respData.piplProcessingGrounds
  );
  respData.pdpProcessingGrounds = addKeyToLegalGrounds(
    respData.pdpProcessingGrounds
  );
  respData.pdpaSpecialProcessingGrounds = addKeyToLegalGrounds(
    respData.pdpaSpecialProcessingGrounds
  );

  respData.lgpdProcessingGrounds = addKeyToLegalGrounds(
    respData.lgpdProcessingGrounds
  );

  respData.ukgdprProcessingGrounds = addKeyToLegalGrounds(
    respData.ukgdprProcessingGrounds
  );

  respData.lgpdSpecialProcessingGrounds = addKeyToLegalGrounds(
    respData.lgpdSpecialProcessingGrounds
  );
  respData.fdplProcessingGrounds = addKeyToLegalGrounds(
    respData.fdplProcessingGrounds
  );
  respData.transferGrounds = addKeyToLegalGrounds(respData.transferGrounds);
  respData.appiDomesticTransferGrounds = addKeyToLegalGrounds(
    respData.appiDomesticTransferGrounds
  );
  respData.appiThirdPartyDataTransferGrounds = addKeyToLegalGrounds(
    respData.appiThirdPartyDataTransferGrounds
  );
  respData.appiInternationalTransferGrounds = addKeyToLegalGrounds(
    respData.appiInternationalTransferGrounds
  );
  respData.piplInternationalTransferGrounds = addKeyToLegalGrounds(
    respData.piplInternationalTransferGrounds
  );
  respData.lgpdInternationalTransferGrounds = addKeyToLegalGrounds(
    respData.lgpdInternationalTransferGrounds
  );
  respData.ukgdprInternationalTransferGrounds = addKeyToLegalGrounds(
    respData.ukgdprInternationalTransferGrounds
  );
  respData.sgpdpaProcessingGrounds = addKeyToLegalGrounds(
    respData.sgpdpaProcessingGrounds
  );
  respData.sgpdpaTransferGrounds = addKeyToLegalGrounds(
    respData.sgpdpaTransferGrounds
  );
  respData.breachGrounds = addKeyToLegalGrounds(respData.breachGrounds);

  if (respData.personalDataItems) {
    const sortedItems = sortItems(respData.personalDataItems, 'ASC', 'name');
    respData.personalDataItems = sortedItems.map((personalDataItem) => {
      const { change, item } = getDiffContent(personalDataItem);
      return {
        ...item,
        change,
        value: {
          ...item.value,
          key: getContent(item.value.name).data
        }
      };
    });
  }

  if (respData.linkedDocumentRecords) {
    respData.linkedDocumentRecords = respData.linkedDocumentRecords.map(
      (term) => {
        const { item, change } = getDiffContent(term);
        return {
          ...item,
          change
        };
      }
    );
  }

  if (respData.dataRecipientCategories) {
    const sortedItems = sortItems(
      respData.dataRecipientCategories,
      'ASC',
      'name'
    );
    respData.dataRecipientCategories = sortedItems.map(
      (dataRecipientCategory) => {
        const { change, item } = getDiffContent(dataRecipientCategory);
        const modifiedCountres =
          item.value.countries &&
          item.value.countries.map((value) => value.id || value[1].id);
        return {
          ...item,
          change,
          value: {
            ...item.value,
            key:
              item.value.countries && item.value.countries.length > 0
                ? `${item.value.name}${modifiedCountres.map(
                  (value) => ` (${value})`
                )}`
                : `${item.value.name}`
          }
        };
      }
    );
  }
  if (respData.personalDataCategories) {
    const sortedItems = sortItems(
      respData.personalDataCategories,
      'ASC',
      'name'
    );
    respData.personalDataCategories = transformDiffItems(sortedItems);
  }

  if (respData.dataSourceCategories) {
    const sortedItems = sortItems(respData.dataSourceCategories, 'ASC', 'name');
    respData.dataSourceCategories = transformDiffItems(sortedItems);
  }

  if (respData.processingCategories) {
    const sortedItems = sortItems(respData.processingCategories, 'ASC', 'name');
    respData.processingCategories = transformDiffItems(sortedItems);
  }
  if (respData.dataSubjectCategories) {
    const sortedItems = sortItems(
      respData.dataSubjectCategories,
      'ASC',
      'name'
    );
    respData.dataSubjectCategories = transformDiffItems(sortedItems);
  }
  if (respData.organisationalSecurityMeasures) {
    const sortedItems = sortItems(
      respData.organisationalSecurityMeasures,
      'ASC',
      'name'
    );
    respData.organisationalSecurityMeasures = transformDiffItems(sortedItems);
  }
  if (respData.technicalSecurityMeasures) {
    const sortedItems = sortItems(
      respData.technicalSecurityMeasures,
      'ASC',
      'name'
    );
    respData.technicalSecurityMeasures = transformDiffItems(sortedItems);
  }
  if (respData.contractualSecurityMeasures) {
    const sortedItems = sortItems(
      respData.contractualSecurityMeasures,
      'ASC',
      'name'
    );
    respData.contractualSecurityMeasures = transformDiffItems(sortedItems);
  }
  if (respData.references) {
    const sortedItems = sortItems(respData.references, 'ASC', 'name');
    respData.references = transformDiffItems(sortedItems);
  }
  if (respData.purposes) {
    const sortedItems = sortItems(respData.purposes, 'ASC', 'name');
    respData.purposes = transformDiffItems(sortedItems);
  }
  if (respData.purposesOfTransfer) {
    const sortedItems = sortItems(respData.purposesOfTransfer, 'ASC', 'name');
    respData.purposesOfTransfer = transformDiffItems(sortedItems);
  }
  if (respData.dataSources) {
    const sortedItems = sortItems(respData.dataSources, 'ASC', 'name');
    respData.dataSources = sortedItems.map((dataSource) => {
      const { change, item } = getDiffContent(dataSource);
      const { dataStorageCountry, organisation, name } = item.value;
      return {
        ...item,
        change,
        value: {
          ...item.value,
          dataStorageCountry: dataStorageCountry?.id,
          key: addKeyToDataSource({
            name,
            organisation,
            dataStorageCountry: dataStorageCountry?.id
          }),
          organisation: organisation
            ? {
              ...organisation,
              key: `${organisation.name} (${organisation.country.id})`
            }
            : undefined
        }
      };
    });
  }
  if (respData.attachments) {
    respData.attachments = respData.attachments.map((attachment) => {
      const { change, item } = getDiffContent(attachment);
      return {
        change,
        note: item.note,
        value: {
          ...item.value,
          key: getContent(item.value.name).data
        }
      };
    });
  }
  if (respData.privacyAgreementAttachments) {
    respData.privacyAgreementAttachments =
      respData.privacyAgreementAttachments.map((attachment) => {
        const { change, item } = getDiffContent(attachment);
        return {
          change,
          note: item.note,
          value: {
            ...item.value,
            key: getContent(item.value.name).data
          }
        };
      });
  }
  if (respData.securityInfoAttachments) {
    respData.securityInfoAttachments = respData.securityInfoAttachments.map(
      (attachment) => {
        const { change, item } = getDiffContent(attachment);
        return {
          change,
          note: item.note,
          value: {
            ...item.value,
            key: getContent(item.value.name).data
          }
        };
      }
    );
  }
  if (respData.acl) {
    respData.aclUsers = [];
    if (respData.acl.userRights) {
      respData.acl.userRights.forEach((user) => {
        const { item, change } = getDiffContent(user);
        const { rights, subject } = item.value;
        const value = subject.id;
        if (value.old) {
          respData.aclUsers.push({
            change: '-',
            value: value.old
          });
          respData.aclUsers.push({
            change: '+',
            value: value.new
          });
        } else {
          respData.aclUsers.push({
            change,
            value: { ...subject, rights }
          });
        }
      });
    }
    respData.aclOrgs = [];
    if (respData.acl.organisationRights) {
      respData.acl.organisationRights.forEach((org) => {
        const { item, change } = getDiffContent(org);
        const { subject } = item.value;
        const value = subject.id;
        if (value.old) {
          respData.aclOrgs.push({
            change: '-',
            value: value.old
          });
          respData.aclOrgs.push({
            change: '+',
            value: value.new
          });
        } else {
          respData.aclOrgs.push({
            change,
            value: subject
          });
        }
      });
    }
  }

  // If logged in user is privacy officer/ Chief privacy officer
  if ([NO_LIMIT, TOP_DOWN].includes(currentUser?.get('userHierarchy'))) {
    respData.isExecuteAccess = true;
  }

  const hasFlatLimit = currentUser?.get('userHierarchy') === FLAT;

  const isCreatedUser =
    respData.createdBy && checkIsCreatedUser(respData.createdBy, userId);
  if (hasFlatLimit) {
    if (isCreatedUser) {
      respData.isExecuteAccess = true;
    } else {
      respData.isExecuteAccess = false;
    }
  }

  respData.status = respData.status ? respData.status : respData.status;

  // Get details from array of id
  respData.aclUsers = transformAclUsers(
    respData.aclUsers,
    userId,
    hasFlatLimit,
    respData.aclOrgs
  );

  respData.aclOrgs = transformAclOrgs(
    respData.aclOrgs,
    currentUser,
    isFromTemplate
  );
  return respData;
}

export function transformAclUsers(
  aclUsers,
  currentUser,
  hasFlatLimit,
  aclOrgs
) {
  let transFormedAclUsers = [];
  if (aclUsers && aclUsers.length > 0) {
    transFormedAclUsers = aclUsers.map((aclUser) => ({
      change: aclUsers.find((user) => user.value.id === aclUser.value.id)
        .change,
      value: {
        ...aclUser.value,
        key: `${aclUser.value.firstName}${
          aclUser.value.lastName ? ` ${aclUser.value.lastName}` : ''
        }`,
        readOnly: hasFlatLimit && aclUser.value.id === currentUser
      },
      deleteDisabled:
        aclUser.value.id === currentUser &&
        aclUsers.length === 1 &&
        aclOrgs.length === 0
    }));
  }
  return transFormedAclUsers;
}

export function transformAclOrgs(aclOrgs, currentUser, isFromTemplate) {
  let transFormedAclOrgs = [];
  if (isFromTemplate) {
    const userOrg = currentUser.get('organisation').toJS();
    userOrg.key = `${userOrg.value.name} (${userOrg.value.country})`;
    transFormedAclOrgs.push(userOrg);
  } else if (aclOrgs && aclOrgs.length > 0) {
    transFormedAclOrgs = aclOrgs.map((aclOrg) => ({
      change: aclOrgs.find((org) => org.value === aclOrg.value).change,
      value: {
        ...aclOrg.value,
        key: `${aclOrg.value.name} (${aclOrg.value.country.id})`
      }
    }));
  }
  return transFormedAclOrgs;
}

export function* isRecordEditable(record, currentUser) {
  const { taskId } = record;
  if (!taskId) return true;

  const hasFlatLimit = currentUser?.get('userHierarchy') === FLAT;

  if (
    hasFlatLimit &&
    (record.status.value === 'record_status_provisional' ||
      record.status.value === 'record_status_requested')
  ) {
    return false;
  }

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tasks/${taskId}`, null);

    const { assignee } = response.body;
    return !assignee || assignee.id === currentUser.get('id');
  } catch (err) {
    registry.get('logger').error(err);
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('task');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
  }

  return true;
}

export function* isRecordWorkable(record, currentUser) {
  const { taskId } = record;
  if (!taskId) return true;

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tasks/${taskId}`, null);

    const { assignee } = response.body;
    return !assignee || assignee.id === currentUser.get('id');
  } catch (err) {
    registry.get('logger').error(err);
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('task');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
  }

  return true;
}

export function* getTransformedDiffObject(recordType, recordId) {
  // Get the revisions
  const { v1, v2 } = getQueryStrings();
  const getV1Url = `/v1/records/${recordType}/${recordId}/${v1}`;
  const getV2Url = getV2RecordUrl(recordType, recordId, v2);
  const version1 = yield registry.get('request').get(getV1Url);
  const version2 = yield registry.get('request').get(getV2Url);
  let TiaDiff = {};
  let data = {};

  // Get linked processing diff
  const linkedProcessingv1 = version1.body.linkedProcessings;
  const linkedProcessingv2 = version2.body.linkedProcessings;
  const linkedProcessings = JsonDiff(linkedProcessingv1, linkedProcessingv2);
  // Get linked assessment/breaches diff
  if (recordType === 'breaches') {
    const data1 = version1.body;
    const data2 = version2.body;
    const breach1 = Object.assign(data1, {
      natureOfBreach: updateBreachItems(data1.natureOfBreach),
      breachConsequences: updateBreachItems(data1.breachConsequences),
      supervisoryNotification: Object.assign(
        {},
        data1.supervisoryNotification,
        {
          reasonNotToNotify:
            data1.supervisoryNotification &&
            updateBreachItems(data1.supervisoryNotification.reasonNotToNotify)
        }
      ),
      dataSubjectNotification: Object.assign(
        {},
        data1.dataSubjectNotification,
        {
          reasonNotToNotify:
            data1.dataSubjectNotification &&
            updateBreachItems(data1.dataSubjectNotification.reasonNotToNotify)
        }
      )
    });
    const breach2 = Object.assign(data2, {
      natureOfBreach: updateBreachItems(data2.natureOfBreach),
      breachConsequences: updateBreachItems(data2.breachConsequences),
      supervisoryNotification: Object.assign(
        {},
        data2.supervisoryNotification,
        {
          reasonNotToNotify:
            data2.supervisoryNotification &&
            updateBreachItems(data2.supervisoryNotification.reasonNotToNotify)
        }
      ),
      dataSubjectNotification: Object.assign(
        {},
        data2.dataSubjectNotification,
        {
          reasonNotToNotify:
            data2.dataSubjectNotification &&
            updateBreachItems(data2.dataSubjectNotification.reasonNotToNotify)
        }
      )
    });

    const diffObj = JsonDiff(breach1, breach2);
    const linkedBreachV1 = version1.body.linkedBreaches;
    const linkedBreachV2 = version2.body.linkedBreaches;
    const linkedBreaches = JsonDiff(linkedBreachV1, linkedBreachV2);
    data = Object.assign(
      {},
      diffObj,
      { linkedBreaches },
      { linkedProcessings }
    );
  } else {
    if (recordType === 'tias') {
      const diffExpObject = {};
      const importerFields = [
        'organisation',
        'country',
        'role',
        'sector',
        'includeInCalculation',
        'contractHolder'
      ];
      const exporterFields = ['organisation', 'country', 'role', 'sector'];
      const lawAndPracticeChapters = [
        'regulationOnProcessing',
        'regulationOnAccess',
        'regulatorySupervision',
        'rightsOfRedress',
        'internationalTreaties'
      ];
      const data1 = version1.body;
      const data2 = version2.body;
      // Exporter transformations
      const dataExp1 = transformExporters(data1.dataExporters);
      const dataExp2 = transformExporters(data2.dataExporters);
      exporterFields.forEach((key) => {
        if (key === 'organisation') {
          diffExpObject[key] = getOrganisationNameDiff(
            dataExp1?.organisation || [],
            dataExp2?.organisation || []
          );
        } else {
          diffExpObject[key] = JsonDiff(dataExp1[key], dataExp2[key]);
        }
      });
      // Importers transformations
      const dataImp1 = transformImporters(data1.dataImporters);
      const dataImp2 = transformImporters(data2.dataImporters);
      const maxArray = dataImp1.length > dataImp2.length ? dataImp1 : dataImp2;
      const diffImpArray = maxArray.map((value, index) => {
        const diffImpTia = {};
        importerFields.forEach((key) => {
          if (key === 'organisation') {
            diffImpTia[key] = getOrganisationNameDiff(
              dataImp1[index]?.organisation || [],
              dataImp2[index]?.organisation || []
            );
          } else if (key === 'contractHolder') {
            const relationShip = JsonDiff(
              dataImp1[index]?.contractHolder?.relationShip,
              dataImp2[index]?.contractHolder?.relationShip
            );
            const organisation = getOrganisationNameDiff(
              dataImp1[index]?.contractHolder?.organisation || [],
              dataImp2[index]?.contractHolder?.organisation || []
            );
            const contractor = {
              relationShip,
              organisation
            };
            diffImpTia[key] = contractor;
          } else {
            diffImpTia[key] = JsonDiff(
              dataImp1[index]?.[key],
              dataImp2[index]?.[key]
            );
          }
        });
        return diffImpTia;
      });

      const dataLaw1 = data1.lawAndPractices;
      const dataLaw2 = data2.lawAndPractices;
      const lawAndPractices = {};
      const maxLawArray =
        dataLaw1.length > dataLaw2.length ? dataLaw1 : dataLaw2;

      lawAndPracticeChapters.forEach((item) => {
        const chapterData = [];
        maxLawArray.forEach((value, index) => {
          const country = JsonDiff(
            dataLaw1[index]?.country?.id,
            dataLaw2[index]?.country?.id
          );
          const score = JsonDiff(
            dataLaw1[index]?.[item]?.score || commonTranslations.NA,
            dataLaw2[index]?.[item]?.score || commonTranslations.NA
          );
          const analysis = JsonDiff(
            dataLaw1[index]?.[item]?.analysis,
            dataLaw2[index]?.[item]?.analysis
          );
          chapterData.push({ country, analysis, score });
        });
        lawAndPractices[item] = chapterData.length > 0 ? chapterData : [{}];
      });

      const startDateWithPrefix = JsonDiff(
        data1.startDateWithPrefix || {},
        data2.startDateWithPrefix || {}
      );
      const endDateWithPrefix = JsonDiff(
        data1.endDateWithPrefix || {},
        data2.endDateWithPrefix || {}
      );

      TiaDiff = {
        dataExporters: diffExpObject,
        dataImporters: diffImpArray.length > 0 ? diffImpArray : [{}],
        lawAndPractices,
        startDateWithPrefix,
        endDateWithPrefix
      };
    }
    delete version2.body.dpo;
    delete version1.body.dpo;
    const diffObj = JsonDiff(
      {
        ...version1.body,
        jurisdiction: version1.body.jurisdiction
          ? version1.body.jurisdiction.value.id
          : ''
      },
      {
        ...version2.body,
        jurisdiction: version2.body.jurisdiction
          ? version2.body.jurisdiction.value.id
          : ''
      }
    );

    data = Object.assign({}, diffObj, TiaDiff);
  }

  if (recordType === 'document-records') {
    const fields = [
      'organisations',
      'documentRecordTypes',
      'specialCharacteristics',
      'documents'
    ];
    fields.forEach((field) => {
      if (data[field]) {
        data[field] = data[field]?.map((term) => {
          const { item, change } = getDiffContent(term);
          return {
            ...item,
            change
          };
        });
      }
    });
  }
  const acl = JsonDiff(version1.body.acl, version2.body.acl);
  return Object.assign({}, data, { acl });
}
const getOrganisationNameDiff = (data1, data2) => {
  const orgIdDiff = JsonDiff(data1[0]?.value?.id, data2[0]?.value?.id);
  if (orgIdDiff?.old || orgIdDiff?.new)
    return { old: data1[0]?.value?.name, new: data2[0]?.value?.name };
  return data1[0]?.value?.name;
};
const transformExporters = (data) => {
  const result = data.map((item) => ({
    ...item,
    organisation: [{ value: { ...item.organisation }, note: item.note }],
    country: item.country?.value.id
  }));
  return result[0] || {};
};

const transformImporters = (data) => {
  const result = data.map((item) => ({
    ...item,
    organisation: [{ value: { ...item?.organisation }, note: item.note }],
    country: item.country?.value.id,
    enableDetails: true,
    contractHolder: {
      ...item.contractHolder[0],
      organisation: [
        {
          value: { ...item.contractHolder[0]?.organisation },
          note: item.contractHolder[0]?.note
        }
      ]
    },
    showContractHolder: item.contractHolder?.length ? 'false' : 'true'
  }));
  return result;
};

// Get record details based on the version.
const getV2RecordUrl = (recordType, recordId, version) => {
  if (version.indexOf('current') !== -1) {
    // If current record is selected.
    return `/v1/records/${recordType}/${recordId}`;
  }
  // For all record revisions.
  return `/v1/records/${recordType}/${recordId}/${version}`;
};

// For deriving diff, we add other data to items list.
const updateBreachItems = (items) => {
  if (!items) return items;
  const index = items.values.indexOf('Others');
  if (index !== -1) {
    const otherValue = items.other;
    if (otherValue) {
      items.values.splice(index, 1, otherValue);
    } else {
      items.values.splice(index, 1);
    }
  }
  return items;
};

// Get copy of record.
export function* getRecordData(record, recordType) {
  const response = record.copyOfRecordId
    ? yield registry
      .get('request')
      .get(`/v1/records/${recordType}/${record.copyOfRecordId}`)
    : yield registry
      .get('request')
      .get(`/v1/records/templates/${recordType}/${record.templateId}`, null);
  if (response.status === 200) {
    const data = response.body;
    delete data.id;
    return Object.assign({}, data, { status: 'record_status_draft' });
  }
  return {};
}

const getRequestRenderData = (data, method) => {
  let modifiedData = {};
  return data.map((entity) => {
    const entityItem = entity.value ? entity.value : entity;
    modifiedData = {
      value: method === 'requestData' ? entityItem.id : entity.value,
      note: entity.note || (entity.note !== '' ? entityItem.note : '')
    };
    return modifiedData;
  });
};

export const getURLWithParams = (
  position = 0,
  searchParams,
  requestType,
  rowCount = 15,
  entityType
) => {
  let url = `/v1/masterdata/${requestType}?numberOfResults=${rowCount}&offset=${position}`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=name=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  url =
    entityType && entityType !== ''
      ? `${url}&filter=entityType%3D${entityType}`
      : url;
  return url;
};

export const getSimpleCustomURLWithParams = (
  searchParams,
  position = 0,
  rowCount = 15,
  entityType
) => {
  let url = `/v1/masterdata/simplecustom/${entityType}?numberOfResults=${rowCount}&offset=${position}`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=name=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
