import { connect } from 'react-redux';

import BreachRecordListComponent from './breachRecordList';

const recordType = 'breach';
const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  items: state.privacyRecords.list.get('items'),
  pageSearchText: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('breach').pageSearchText,
  sortOrder: state.privacyRecords.list.get('sortAndFilterParams').get('breach')
    .sortOrder,
  sortKey: state.privacyRecords.list.get('sortAndFilterParams').get('breach')
    .sortKey,
  filterColumn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('breach').filterColumn,
  filteredOn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('breach')
    .filteredOn.toJS(),
  position: state.privacyRecords.list.get('position'),
  isFetching: state.privacyRecords.list.get('isFetching'),
  error: state.privacyRecords.list.get('error').toJS(),
  isUpdating: state.breach.get('isUpdating'),
  pricingPlan: state.login.get('pricingPlanDetails'),
  selectedRecords: state.privacyRecords.list.get('bulkItems').get('breach')
    .selectedRecords
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
      recordType,
      recordLayoutId
    });
  },
  getNextData: (position, rowCount, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount,
      recordLayoutId
    });
  },
  deletePrivacyRecord: (recordId) => {
    dispatch({ type: 'PRIVACY_RECORD:ITEM:DELETE', recordId, recordType });
  },
  onChooseFilter: (filterParams, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType,
      recordLayoutId
    });
  },
  onSearch: (searchParams) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:SEARCH_INIT',
      searchParams,
      recordType
    });
  },
  updateNote: (recordId, property, data, name, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType: 'breach',
      property,
      data,
      name,
      recordLayoutId
    });
  },
  copyBreach: (data, copyOfRecordId, isGlobal) => {
    dispatch({ type: 'BREACH:DETAIL:UPSERT', data, copyOfRecordId, isGlobal });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  onRecordSelection: (selectedRecords) => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType,
      selectedRecords
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreachRecordListComponent);
