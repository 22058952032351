/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';

import AttachmentSelector from './component';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  isFileUploading: state.attachments.get('isFileUploading'),
  attachments: state.attachments.get('attachments'),
  attachmentData: state.attachments.get('attachmentData'),
  isUploadSuccess: state.attachments.get('isUploadSuccess'),
  error: state.attachments.get('error'),
  searchResults: state.attachments.get('items'),
  pricingPlan: state.login.get('pricingPlanDetails'),
  userPermissions: state.login.get('loggedUser')?.get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  initDocuments: () => {
    dispatch({ type: 'ATTACHMENT:LIST:INIT' });
  },
  downloadDocuments: (data, isVendor) => {
    dispatch({ type: 'ATTACHMENT:DOWNLOAD', data, isVendor });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'ATTACHMENT:LIST:SEARCH_INIT', searchParams });
  },
  resetFilterSearch: () => {
    dispatch({ type: 'MASTERDATA:DOCUMENTS:LIST:SEARCH_FILTER:RESET' });
    dispatch({ type: 'ATTACHMENT:LIST:SEARCH:RESET' });
  },
  toggleLoader: (toggle, content) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle, content });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentSelector);
export { reducers };
