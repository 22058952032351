import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import LegalGrounds from '../../legal-grounds';

const TransferGroundList = (props) => {
  const {
    initTransferGrounds,
    jurisdiction,
    filteredOn,
    isFetching,
    onCancel,
    selectedItems,
    transferGrounds,
    sortKey,
    sortOrder,
    searchItems,
    filterColumn,
    onChooseFilter,
    onSave,
    getFilterAppplied,
    onSearch,
    searchText
  } = props;

  useEffect(() => {
    if (initTransferGrounds) {
      initTransferGrounds(jurisdiction);
    }
  }, []);

  useEffect(() => {
    // The legalGrounds will be filtered by current jurisdiction always. So we don't want to
    // consider that case to show remove filter button.
    const { jurisdiction: jurisdictionFilter, ...filteredOnSet } = {
      ...filteredOn
    };
    const items = Object.values(filteredOnSet);
    const modifiedJurisdiction = jurisdiction === 'all' ? 'EU' : jurisdiction;
    const isFilterApplied =
      items.some((item) => item.length > 0) ||
      jurisdictionFilter.join('_') !== modifiedJurisdiction;
    getFilterAppplied(isFilterApplied);
  }, [filteredOn]);

  return (
    <LegalGrounds
      id="legal-grounds"
      selectedItems={selectedItems}
      legalGrounds={transferGrounds}
      isFetching={isFetching}
      onCancel={onCancel}
      sortKey={sortKey}
      sortOrder={sortOrder}
      searchText={searchText}
      filteredOn={filteredOn}
      searchItems={searchItems}
      filterColumn={filterColumn}
      filterData={onChooseFilter}
      searchData={onSearch}
      onSave={onSave}
    />
  );
};

TransferGroundList.propTypes = {
  transferGrounds: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.arrayOf({}),
  isFetching: PropTypes.bool,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string,
  searchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf([]),
  filterColumn: PropTypes.shape({}),
  searchItems: PropTypes.objectOf([]),
  initTransferGrounds: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  getFilterAppplied: PropTypes.func,
  jurisdiction: PropTypes.string
};

TransferGroundList.defaultProps = {
  isFetching: false,
  transferGrounds: Immutable.List(),
  selectedItems: Immutable.Map(),
  sortOrder: 'ASC',
  searchText: Immutable.Map(),
  filteredOn: Immutable.Map({
    jurisdiction: ['EU']
  }),
  filterColumn: Immutable.Map({ jurisdiction: 'jurisdiction' }),
  searchItems: Immutable.Map(),
  initTransferGrounds: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  onChooseFilter: (e) => e,
  onSearch: (e) => e,
  getFilterAppplied: (e) => e,
  jurisdiction: 'EU'
};

TransferGroundList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default TransferGroundList;
