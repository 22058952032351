import { connect } from 'react-redux';
import MultipleSelectorDialog from './multipleSelectorDialog';

function mapStateToProps(state) {
  return {
    legalEntities: state.organisationEntities.get('organisationEntities'),
    position: state.organisationEntities.get('position'),
    holdingOrgList: state.organisationEntities.get('holdingOrgList')
  };
}
const mapDispatchToProps = dispatch => ({
  init: (isInternal, entityFilter, isImportField) => {
    dispatch({ type: 'ORGANISATION_ENTITY:LIST:REQUEST', isInternal, entityFilter, isImportField });
  },
  getNextData: (position, entityFilter, isImport) => {
    const type = isImport ? 'SUBTENANT:ORGANISATION_ENTITY:LIST:NEXT_FETCH' : 'ORGANISATION_ENTITY:LIST:NEXT_FETCH';
    dispatch({ type,
      position,
      isPersistedData: true,
      entityFilter });
  },
  filterItems: (filterParams, entityFilter, isImportField) => {
    dispatch({ type: 'ORGANISATIONS_ENTITY:LIST:FILTER_INIT',
      filterParams,
      isPersistedData: true,
      entityFilter,
      isImportField });
  },
  resetfilteredFilters: () => {
    dispatch({ type: 'ORGANISATIONS_ENTITY:LIST:FILTER:RESET' });
  },
  fetchImportList: (entityFilter) => {
    dispatch({ type: 'SUBTENANT:ORGANISATION:LIST:FETCH', entityFilter });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MultipleSelectorDialog);
