/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

import { addKeyToRetentionTerm } from '../../../common-utils';
import RetentionTermDialog from './components/retentionTermDialog';

const RetentionSelector = (props) => {
  const {
    createNewMenuItem,
    itemToEdit,
    masterOffsets,
    recordsRetentionTerms,
    searchResults,
    searchText,
    intl,
    selectedItems,
    handleClose,
    onSaveRetentionTerm,
    position,
    getNextData,
    dataSourceFilter,
    resetOffset,
    multiValue,
    hintTextLabel,
    onOffsetSearch,
    updateRetentionTerms,
    onChooseFilter,
    tagSearch,
    tagSearchResult,
    getNextTags,
    tagsPosition,
    filterTagValue,
    isGlobal,
    userPermissions,
    disabled
  } = props;

  const [inputValue, setInputValue] = useState({});
  const [periodDatasource, setPeriodDatasource] = useState([]);
  const [offsetDatasource, setOffsetDatasource] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [retentionMultipleSelect, setRetentionMultipleSelect] = useState(false);

  useEffect(() => {
    if (props.isEdit === true) {
      setInputValue(itemToEdit);
      setOpen(true);
      setIsEdit(true);
    }
    return () => resetOffset && resetOffset();
  }, []);

  useEffect(() => {
    if (props.isEdit === true && itemToEdit !== undefined) {
      setInputValue(itemToEdit);
      setOpen(true);
      setIsEdit(true);
    }
  }, [itemToEdit]);

  useEffect(() => {
    setOffsetDatasource(searchText.get('name') ? searchResults : masterOffsets);
  }, [masterOffsets, searchText]);

  useEffect(() => {
    setPeriodDatasource(recordsRetentionTerms);
  }, [recordsRetentionTerms]);

  const filterOutExistingRetentionTerms = (
    selectedRetentionTerms,
    allRetentionTerms
  ) => {
    const filteredData = allRetentionTerms.filter((term) => {
      const index = selectedRetentionTerms.findIndex((selectedRetention) => {
        let found = false;
        if (selectedRetention.period && selectedRetention.offset) {
          found =
            term.period === selectedRetention.period &&
            term.offset &&
            term.offset.name === selectedRetention.offset.name;
        } else if (!selectedRetention.period && selectedRetention.offset) {
          found =
            !term.period &&
            term.offset &&
            term.offset.name === selectedRetention.offset.name;
        } else if (selectedRetention.period && !selectedRetention.offset) {
          found = term.period === selectedRetention.period && !term.offset;
        }
        return found;
      });
      return index === -1;
    });
    return filteredData;
  };

  const handleAddClick = () => {
    setOpen(true);
    setIsEdit(false);
    setRetentionMultipleSelect(false);
  };

  const handleMultipleSelect = () => {
    setRetentionMultipleSelect(true);
  };

  const handleMultipleItems = (selectedItems) => {
    const modifiedItems = selectedItems.map((item) => item.value);
    updateRetentionTerms(modifiedItems);
  };

  const handleRequestClose = () => {
    setRetentionMultipleSelect(false);
    setOpen(false);
    setInputValue(null);
    handleClose();
  };

  const handleScrollEnd = () => {
    getNextData(position);
  };

  const saveRetentionTermAndCreate = (filteredData = undefined) => {
    const currentOffsetSearchText =
      (filteredData && filteredData.value) || null;
    const modifiedRetentionTerm = addKeyToRetentionTerm(
      currentOffsetSearchText,
      intl.formatMessage
    );

    onSaveRetentionTerm(modifiedRetentionTerm);
  };

  const { createEditRetentionOffsets } = userPermissions.toJS();

  const filteredRetentionTerms = selectedItems
    ? filterOutExistingRetentionTerms(selectedItems, periodDatasource)
    : [];

  return (
    <div>
      <div>
        <ItemSelector
          id="item_selector"
          multiValue={multiValue}
          createNewMenuItem={createNewMenuItem}
          selectFromListMenuItem={true}
          selectedItem={selectedItems}
          dataSource={[...filteredRetentionTerms]}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={hintTextLabel}
          handleSelectedItem={saveRetentionTermAndCreate}
          handleAddClick={handleAddClick}
          handleMultipleSelect={handleMultipleSelect}
          disabled={disabled}
        />
      </div>
      {open && (
        <RetentionTermDialog
          open={open}
          isEdit={isEdit}
          onSaveRetentionTerm={(data, closeDialog, isEdit) => {
            onSaveRetentionTerm(data, isEdit);
            if (closeDialog) handleRequestClose();
          }}
          onOffsetSearch={onOffsetSearch}
          dataSourceFilter={dataSourceFilter}
          filteredRetentionTerms={[...filteredRetentionTerms, ...selectedItems]}
          inputValue={inputValue}
          onScrollStop={handleScrollEnd}
          masterOffsets={masterOffsets}
          offsetDatasource={offsetDatasource || []}
          onRequestClose={handleRequestClose}
          handleSelectedItem={saveRetentionTermAndCreate}
          source="records"
          filterTagValue={filterTagValue}
          onChooseFilter={onChooseFilter}
          tagSearch={tagSearch}
          tagSearchResult={tagSearchResult}
          getNextTags={getNextTags}
          tagsPosition={tagsPosition}
          isGlobal={isGlobal}
          createNewMenuItem={createEditRetentionOffsets}
        />
      )}
      {retentionMultipleSelect && (
        <MultipleSelectorDialog
          id="multiple_selector"
          title={
            <FormattedMessage
              id="MultipleRetentionTerm.header"
              description="Select retention term"
              defaultMessage="Select retention term"
            />
          }
          open={retentionMultipleSelect}
          filteredData={[...filteredRetentionTerms]}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
        />
      )}
    </div>
  );
};

RetentionSelector.propTypes = {
  createNewMenuItem: PropTypes.bool,
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  }),
  itemToEdit: PropTypes.shape({
    period: PropTypes.string,
    periodKey: PropTypes.string,
    offset: PropTypes.shape({})
  }),
  masterOffsets: PropTypes.shape({}),
  recordsRetentionTerms: PropTypes.shape({}),
  offsetItem: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  searchResults: PropTypes.shape({
    size: PropTypes.number
  }),
  searchText: PropTypes.shape({
    get: PropTypes.func
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  selectedItems: PropTypes.shape([]),
  isEdit: PropTypes.bool,
  handleClose: PropTypes.func,
  onSaveRetentionTerm: PropTypes.func,
  position: PropTypes.number,
  getNextData: PropTypes.func,
  dataSourceFilter: PropTypes.func,
  formatMessage: PropTypes.func,
  resetOffset: PropTypes.func,
  multiValue: PropTypes.bool,
  hintTextLabel: PropTypes.node,
  onOffsetSearch: PropTypes.func,
  updateRetentionTerms: PropTypes.func,
  onChooseFilter: PropTypes.func,
  tagSearch: PropTypes.func,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  filterTagValue: PropTypes.instanceOf(Immutable.List),
  isGlobal: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  disabled: PropTypes.bool
};

RetentionSelector.defaultProps = {
  createNewMenuItem: false,
  isEdit: false,
  multiValue: true,
  handleClose: (e) => e,
  itemToEdit: undefined,
  masterOffsets: {},
  recordsRetentionTerms: {},
  position: 0,
  offsetItem: {
    id: '',
    name: ''
  },
  searchResults: {
    size: 0
  },
  searchText: {
    get: (e) => e
  },
  intl: {
    formatMessage: (e) => e
  },
  updateRetentionTerms: (e) => e,
  selectedItems: [],
  onOffsetSearch: (e) => e,
  getNextData: (e) => e,
  onSaveRetentionTerm: (e) => e,
  resetOffset: (e) => e,
  dataSourceConfig: {},
  dataSourceFilter: null,
  formatMessage: (e) => e,
  hintTextLabel: null,
  filterTagValue: Immutable.List(),
  tagSearchResult: Immutable.List(),
  onChooseFilter: (e) => e,
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  isGlobal: false,
  userPermissions: Immutable.Map(),
  disabled: false
};

export default injectIntl(RetentionSelector);
