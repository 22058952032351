import { connect } from 'react-redux';
import PersonalDataItemSelector from './component';

const mapStateToProps = state => ({
  personalDataItems: state.environment.personalDataItems.get('personalDataItems'),
  error: state.personalDataItems.get('error'),
  position: state.environment.personalDataItems.get('position'),
  searchResults: state.personalDataItems.get('items')
});

const mapDispatchToProps = dispatch => ({
  initPersonalDataItems: () => {
    dispatch({ type: 'PERSONAL_DATA_ITEMS:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'PERSONAL_DATA_ITEMS:LIST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PERSONAL_DATA_ITEMS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataItemSelector);
