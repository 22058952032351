import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import styles from '@packages/ui/styles';
import DivWrapper from '@packages/components/divWrapper';

const defaultButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '0px 17px 17px 17px',
  paddingLeft: '25px',
  marginLeft: '6px'
};

const buttonPositionRight = {
  justifyContent: 'flex-end',
  padding: '13px'
};

const CommonDialog = (props) => {
  const {
    id,
    show,
    fullWidth,
    maxWidth,
    showCloseIcon,
    buttonActions,
    buttonPosition,
    subTitle,
    onCancel,
    title,
    actionError,
    children,
    customStyle
  } = props;

  return (
    <Dialog
      id={id}
      open={show}
      onClose={onCancel}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {title && (
        <DialogTitle
          id="alert-dialog-title"
          className="add-dialog-header"
          style={{
            paddingLeft: '35px',
            paddingRight: '48px',
            paddingTop: '30px'
          }}
        >
          <DivWrapper autoHeightMax={300} autoHeight={true}>
            {title}
          </DivWrapper>
          {subTitle && subTitle}
        </DialogTitle>
      )}
      <DialogContent
        style={{
          ...customStyle,
          padding: '24px 35px',
          paddingTop: title ? '0px' : '24px'
        }}
      >
        {children}
        {actionError && actionError.isError && (
          <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
            {actionError.message}
          </div>
        )}
      </DialogContent>
      {buttonActions && (
        <DialogActions
          id="dialog-buttons"
          style={
            buttonPosition === 'left' ? defaultButtonStyle : buttonPositionRight
          }
        >
          <div>{buttonActions}</div>
        </DialogActions>
      )}
      {showCloseIcon && (
        <div style={styles.dialogCloseBtn}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ lineHeight: '1em', fontSize: '25px' }}
            onClick={onCancel}
          />
        </div>
      )}
    </Dialog>
  );
};

CommonDialog.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  onCancel: PropTypes.func,
  title: PropTypes.node,
  contentStyle: PropTypes.shape({}),
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  showCloseIcon: PropTypes.bool,
  buttonPosition: PropTypes.string,
  customStyle: PropTypes.shape({}),
  buttonActions: PropTypes.node,
  subTitle: PropTypes.node
};

CommonDialog.defaultProps = {
  show: false,
  id: '',
  children: null,
  contentStyle: null,
  fullWidth: false,
  maxWidth: '',
  onCancel: (e) => e,
  showCloseIcon: true,
  title: undefined,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  buttonPosition: 'left',
  customStyle: {},
  buttonActions: null,
  subTitle: null
};

export default CommonDialog;
