import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown, getRowCount } from '@packages/utils/common-utils';
import { getURLWithParams } from '../../../utils';

export function* fetchDataExporters(action) {
  const { position = 0, rowCount = getRowCount() } = action;
  try {
    const response = yield registry.get('request')
      .get(`/v1/records/dataExporters?numberOfResults=${rowCount}&offset=${position}`, null);
    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));

        yield put({ type: 'DATA_EXPORTERS:LIST:FETCH:SUCCESS', items: modifiedItems });

        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'DATA_EXPORTERS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* searchDataExportersSelector(action) {
  let { searchParams } = action;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  const dataExportersSelectorState = yield select(state => state.dataExporters.get('dataExporters'));

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = dataExportersSelectorState ? dataExportersSelectorState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  yield put({ type: 'DATA_EXPORTERS_SELECTOR:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(action.position, searchParams, 'dataExporters', action.rowCount), null, {});
    const items = response.body;

    // Add a key element which is used to display the object in list view
    const modifiedItems = items.map(item => ({
      ...item,
      key: `${item.name}`
    }));
    yield put({
      type: 'DATA_EXPORTERS_SELECTOR:LIST:SEARCH:SUCCESS',
      searchKey,
      items: modifiedItems,
      searchText
    });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'DATA_EXPORTERS_SELECTOR:LIST:SEARCH:FAIL', error: err.message });
  }
}
