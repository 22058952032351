import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class SecurityMeasureSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initSecurityMeasures) {
      this.props.initSecurityMeasures();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.securityMeasures === nextProps.securityMeasures) ||
    !(this.state === nextState);
  }

  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render () {
    const { searchResults } = this.state;
    const { hintTextLabel, securityMeasures } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="name"
          searchResults={searchResults}
          dataItems={securityMeasures}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          onScrollStop={this.handleNextData}
          title={
            <FormattedMessage
              id="MultipleMeasuresDialog.header"
              description="Select security measures dialog header"
              defaultMessage="Select security measures"
            />
          }
          {...this.props}
        />
      </div>
    );
  }
}

SecurityMeasureSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initSecurityMeasures: PropTypes.func,
  securityMeasures: PropTypes.instanceOf(Immutable.List)
};

SecurityMeasureSelector.defaultProps = {
  hintTextLabel: null,
  initSecurityMeasures: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  securityMeasures: Immutable.List()
};

export default SecurityMeasureSelector;
