import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styles from '@packages/ui/styles';

class CheckBoxSelector extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems);
  }

  handleChecked(selectedItem, checked) {
    if (checked) {
      this.props.fields.push(selectedItem.value);
    } else {
      const index = this.props.selectedItems.findIndex(item => item === selectedItem.value);
      this.props.fields.remove(index);
    }
  }

  render () {
    const { items, selectedItems, ignoreItems } = this.props;
    const requiredItems = items.filter((item) => {
      const index = ignoreItems.findIndex(ignoreItem => ignoreItem === item.value);
      return index === -1;
    });
    return (
      <div style={{ width: '90%' }}>
        {requiredItems.map(item => (
          <FormGroup id="checkbox_form" key={`${item.label}_${item.value}_form`} column={true}>
            <FormControlLabel
              id="checkbox_form_group"
              control={
                <Checkbox
                  id="checkBox"
                  key={`${item.label}_${item.value}`}
                  color="primary"
                  style={{ height: 1 }}
                  checked={selectedItems && selectedItems.findIndex(selectedItem =>
                    selectedItem === item.value) !== -1}
                  onChange={(event, isInputChecked) => this.handleChecked(item, isInputChecked)}
                />}
              style={{ ...styles.checkbox, alignItems: 'start' }}
              label={item.label}
            />
          </FormGroup>
        ))}
      </div>
    );
  }
}

CheckBoxSelector.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func
  }),
  ignoreItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

CheckBoxSelector.defaultProps = {
  fields: {
    push: e => e,
    remove: e => e
  },
  ignoreItems: []
};

export default CheckBoxSelector;
