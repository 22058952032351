const parentNode = {
  name: 'Hierarchy',
  country: '',
  id: 'root_id',
  children: []
};

// Append/Update the org entity to the org list.
export const getUpdatedOrgData = (data, selectedItem, isEdit) => {
  const orgEntityItem = selectedItem.value || selectedItem;
  let result = {};
  if (isEdit === false) {
    orgEntityItem.children = [];
    if (orgEntityItem.parentOrgEntity) {
      const parentId = orgEntityItem.parentOrgEntity.id;
      result = addNode(parentId, data, orgEntityItem);
    } else {
      data.children.unshift(orgEntityItem);
      result = data;
    }
  } else {
    result = updateNode(orgEntityItem, data);
  }
  return result;
};

// recursive to find and add the node
export const addNode = (parentId, element, orgEntityItem) => {
  if (element.children != null && element.children.length !== 0) {
    return {
      ...element,
      children: element.children.reduce((arr, item) => {
        if (item.id !== parentId) {
          const node = addNode(parentId, item, orgEntityItem);
          arr.push(node);
        } else {
          if (item.children) {
            item.children.push(orgEntityItem);
          } else {
            item = { ...item,
              children: [orgEntityItem] };
          }
          arr.push(item);
        }
        return arr;
      }, [])
    };
  }
  return element;
};

// recursive to find and update the node.
export const updateNode = (selectedItem, element) => {
  if (element.children != null && element.children.length !== 0) {
    return {
      ...element,
      children: element.children.reduce((arr, item) => {
        if (item.id !== selectedItem.id) {
          const node = updateNode(selectedItem, item);
          arr.push(node);
        } else {
          arr.push(selectedItem);
        }
        return arr;
      }, [])
    };
  }
  return element;
};

// recursive to find and remove the node.
export const removeNode = (foundID, element) => {
  if (element.children != null && element.children.length !== 0) {
    return {
      ...element,
      children: element.children.reduce((arr, item) => {
        if (item.id !== foundID) {
          const node = removeNode(foundID, item);
          arr.push(node);
        }
        return arr;
      }, [])
    };
  }
  return element;
};

export const transformTreeObject = (organisationHierarchy, organisationData, rootNode) => {
  let transformedNode = Object.assign({}, rootNode || parentNode);
  if (organisationData) {
    transformedNode = Object.assign({}, organisationData);
  } else if (organisationHierarchy) {
    transformedNode.children = organisationHierarchy;
    const dataString = JSON.stringify(Object.assign([], organisationHierarchy));
    const organisations = JSON.parse(dataString.split('"organisations":').join('"children":'));
    if (organisations) {
      transformedNode.children = organisations;
    }
  }
  return transformedNode;
};
