/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { FormattedMessage } from 'react-intl';
import { VariableSizeList as List } from 'react-window';
import ShowMoreText from 'react-show-more-text';
import IconButton from '@material-ui/core/IconButton';
import FontAwesome from 'react-fontawesome';
import ActionInfo from '@material-ui/icons/Info';
import ActionHelp from '@material-ui/icons/Help';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactHtmlParser from 'react-html-parser';
import Error from '@material-ui/icons/Error';
import ArrowTooltip from '@packages/components/tooltip';
import Note from '@packages/components/note';
import DivWrapper from '@packages/components/divWrapper';
import {
  commonTranslations,
  categoryTypeTranslations
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import { renderCustomRisk } from '../../renderers/renderers';
import {
  getContent,
  isType,
  getContentStyle,
  getNoteValue
} from '../../common-utils';

const defaultStyle = {
  rootStyle: {
    margin: '12px 0'
  },
  headerStyle: {
    color: '#807e7e'
  },
  contentStyle: {
    marginTop: 15,
    paddingLeft: 10,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  },
  link: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer'
  },
  iconStyle: {
    padding: '0px',
    height: '24px',
    width: '24px',
    marginTop: '-5px'
  },
  noteStyle: {
    float: 'right',
    marginRight: '10px',
    width: '10%',
    display: 'flex'
  },
  fullWidthStyle: {
    width: '100%',
    marginTop: 5
  },
  checkBoxStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px'
  },
  rowStyle: {
    padding: '10px',
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center'
  }
};

const DEFAULT_ITEM_HEIGHT = 40;
class ItemList extends React.Component {
  listRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      viewNoteOpen: false,
      selectedValue: '',
      selectedNote: '',
      categoryType: '',
      isExpanded: false,
      expandedIndex: -1,
      rowSizes:
        props.content &&
        new Array(props.content.length).fill(DEFAULT_ITEM_HEIGHT)
    };

    this.handleViewNoteOpen = this.handleViewNoteOpen.bind(this);
    this.requestViewNoteClose = this.requestViewNoteClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.content !== nextProps.content) {
      this.setState({
        rowSizes: new Array(nextProps.content.length).fill(DEFAULT_ITEM_HEIGHT)
      });
    }
    if (this.props.showSidebar !== nextProps.showSidebar) {
      this.setState((prevState) => ({
        rowSizes: {
          ...prevState.rowSizes,
          // eslint-disable-next-line react/no-access-state-in-setstate
          [this.state.expandedIndex]: DEFAULT_ITEM_HEIGHT
        },
        expandedIndex: -1,
        isExpanded: false
      }));
      setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
      if (this.listRef.current) {
        this.listRef.current.resetAfterIndex(0, true);
      }
    }
  }

  handleScroll = ({ target }) => {
    const { scrollTop } = target;
    this.listRef.current.scrollTo(scrollTop);
  };

  handleViewNoteOpen(value, note, categoryType) {
    this.setState({
      viewNoteOpen: true,
      selectedValue: value,
      selectedNote: note,
      categoryType
    });
  }

  handleItemClick = (selectedItem) => {
    if (selectedItem.change !== '-') {
      if (selectedItem.value && selectedItem.value.externalLink) {
        const { externalLink } = selectedItem.value;
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: /^((https?):\/\/)/.test(externalLink)
            ? externalLink
            : `//${externalLink}`
        }).click();
      } else {
        this.props.downloadDocuments(selectedItem);
      }
    }
  };

  executeOnClick = (isExpanded, index, type) => {
    const currentElement = document.getElementById(`${type}_${index}`);
    const height = currentElement
      ? currentElement.offsetHeight
      : DEFAULT_ITEM_HEIGHT;
    this.setState((prevState) => ({
      rowSizes: {
        ...prevState.rowSizes,
        // eslint-disable-next-line react/no-access-state-in-setstate
        [this.state.expandedIndex]: DEFAULT_ITEM_HEIGHT,
        [index]:
          prevState.rowSizes[index] === DEFAULT_ITEM_HEIGHT
            ? height
            : DEFAULT_ITEM_HEIGHT
      },
      expandedIndex: index,
      isExpanded
    }));

    if (this.listRef.current) {
      this.listRef.current.resetAfterIndex(0, true);
    }
  };

  isChecked = (content, item) => {
    if (content) {
      const select =
        content &&
        (content.value || content).findIndex(
          (selectedItem) => selectedItem === item.value
        ) !== -1;
      return select;
    }
    return false;
  };

  getExpandedState = (currentIndex) => {
    if (this.state.expandedIndex === -1) return false;
    else if (this.state.expandedIndex === currentIndex)
      return this.state.isExpanded;
    return false;
  };

  getItemSize = (i) => this.state.rowSizes[i];

  getItemCount = () => {
    const { content, maxContentSize } = this.props;
    if (content.length !== 0) {
      const maxValue =
        content.length < maxContentSize && maxContentSize <= 5
          ? content.length
          : maxContentSize;
      return maxValue;
    }
    return 0;
  };

  requestViewNoteClose() {
    this.setState({
      viewNoteOpen: false
    });
  }

  render() {
    const {
      header,
      content,
      mapper,
      locale,
      label,
      risks,
      isHtml,
      fontStyle,
      additionalInfoConfig,
      headerStyle,
      type,
      isChangelog,
      items,
      align,
      isFullWidth,
      helpNotes,
      helpNoteList,
      helpNoteListStyle,
      rootStyle,
      showDisclaimer,
      disclaimerText,
      onChange,
      showCheckBox,
      isDisableCheckbox,
      otherStyles,
      subHeader,
      ignoredRuleIds,
      selectedJurisdictions
    } = this.props;
    const themeColor = this.props.theme?.palette?.primary.main;

    // eslint-disable-next-line react/no-unstable-nested-components
    const Row = (props) => {
      const { index, style } = props;
      const data = this.props.content[index];
      const currentStyle =
        type === 'attachments'
          ? getContentStyle(data, defaultStyle.link)
          : getContentStyle(data);
      const ob = data.value || data;
      const additionalInfo =
        additionalInfoConfig && ob[additionalInfoConfig.text];
      const value = ob.key || ob.name || ob;
      const legalGroundContent = ob.legalGroundContent || '';
      const note = getNoteValue(data);
      return (
        <div style={{ ...style }}>
          <Linkify
            properties={{ target: '_blank', style: { color: themeColor } }}
          >
            <li
              key={value}
              id={`${type}_${index}`}
              style={{
                ...defaultStyle.rowStyle,
                ...currentStyle,
                ...fontStyle
              }}
            >
              <div
                {...(type === 'attachments' && {
                  onClick: (e) =>
                    e.target.getAttribute('data-elm-type') !==
                      'show-more-text' && this.handleItemClick(data),
                  onKeyDown: () => this.handleItemClick(data)
                })}
                style={{
                  width: additionalInfo ? '50%' : '60%',
                  whiteSpace: 'pre-wrap'
                }}
              >
                {this.props.enableShowMore ? (
                  <ShowMoreText
                    lines={2}
                    more={
                      <span
                        style={{
                          color: themeColor,
                          textDecoration: 'underline white'
                        }}
                        data-elm-type="show-more-text"
                      >
                        <FormattedMessage
                          id="ItemList.ShowMore"
                          description="Show more"
                          defaultMessage="Show more"
                        />
                      </span>
                    }
                    less={
                      <span
                        style={{
                          color: themeColor,
                          textDecoration: 'underline white'
                        }}
                        data-elm-type="show-more-text"
                      >
                        <FormattedMessage
                          id="ItemList.ShowLess"
                          description="Show less"
                          defaultMessage="Show less"
                        />
                      </span>
                    }
                    onClick={(isExpanded) =>
                      this.executeOnClick(isExpanded, index, type)
                    }
                    expanded={this.getExpandedState(index)}
                  >
                    {(mapper && mapper[value]) || value}{' '}
                    {ob.categoryType && (
                      <span>({categoryTypeTranslations[ob.categoryType]})</span>
                    )}
                  </ShowMoreText>
                ) : (
                  <ArrowTooltip
                    title={value}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {value}
                  </ArrowTooltip>
                )}
              </div>
              {showCheckBox && (
                <FormControlLabel
                  control={
                    <Checkbox
                      key={value}
                      color="primary"
                      style={{
                        height: 3,
                        alignSelf: 'baseline',
                        position: 'relative',
                        marginTop: 3
                      }}
                      checked={data.isPrimary}
                      disabled={isDisableCheckbox}
                      onChange={(event, checked) => {
                        onChange(checked, ob.id);
                      }}
                    />
                  }
                  style={{ ...styles.checkbox, width: '35%' }}
                  label={
                    <span style={{ color: 'black', whiteSpace: 'nowrap' }}>
                      {label}
                    </span>
                  }
                />
              )}
              <div
                id="note-div"
                style={{ ...defaultStyle.noteStyle, marginTop: '-5px' }}
              >
                {legalGroundContent && (
                  <ArrowTooltip title={legalGroundContent}>
                    <IconButton
                      style={Object.assign({}, styles.rightIcon, {
                        marginRight: '3px'
                      })}
                    >
                      <ActionHelp color="primary" />
                    </IconButton>
                  </ArrowTooltip>
                )}
                {note && (
                  <ArrowTooltip title={note}>
                    <IconButton
                      style={
                        type === 'attachments'
                          ? defaultStyle.iconStyle
                          : styles.rightIcon
                      }
                      onClick={() =>
                        this.handleViewNoteOpen(ob.key, note, ob.categoryType)
                      }
                    >
                      <ActionInfo />
                    </IconButton>
                  </ArrowTooltip>
                )}
              </div>
              {additionalInfo && (
                <div
                  style={{
                    fontWeight: 'bold',
                    color: themeColor,
                    marginLeft: -50
                  }}
                >
                  {additionalInfo}
                </div>
              )}
            </li>
          </Linkify>
        </div>
      );
    };

    const title = renderCustomRisk(
      risks,
      locale,
      ignoredRuleIds,
      selectedJurisdictions
    );
    const hasCustomRisk = !!risks?.length && title;

    const calculatedHeight = isType(content, 'array')
      ? this.getItemCount() * 40
      : 200;
    const helpNoteStyle = helpNoteList
      ? { display: 'flex', alignItems: 'center', ...helpNoteListStyle }
      : '';

    return (
      <div style={{ ...defaultStyle.rootStyle, ...rootStyle }}>
        <div style={{ ...defaultStyle.headerStyle, ...headerStyle }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: align === 'vertical' ? '95%' : '100%',
                justifyContent: 'space-between'
              }}
            >
              {header}
            </div>
            {hasCustomRisk && (
              <ArrowTooltip title={title}>
                <div style={{ paddingTop: '2px' }}>
                  <Error color="error" style={{ marginTop: '-6px' }} />
                </div>
              </ArrowTooltip>
            )}
            {showDisclaimer && (
              <ArrowTooltip title={disclaimerText}>
                <FontAwesome
                  name="warning"
                  size="md"
                  style={{ lineHeight: '1em', color: 'red' }}
                />
              </ArrowTooltip>
            )}
            {helpNotes && (
              <ArrowTooltip title={helpNotes}>
                <IconButton style={{ ...styles.rightIcon, paddingBottom: 10 }}>
                  <ActionHelp color="primary" />
                </IconButton>
              </ArrowTooltip>
            )}
          </div>
        </div>
        {subHeader && <div style={styles.labelField}>{subHeader}</div>}
        {((isType(content, 'array') &&
          content.length === 0 &&
          !(items && items.length > 0)) ||
          (typeof content === 'string' && content === '') ||
          content === undefined ||
          content === null) &&
          this.props.showTextField && (
            <div style={{ paddingLeft: 30, paddingTop: 10 }}>—</div>
        )}
        {(typeof content === 'string' ||
          typeof content === 'boolean' ||
          typeof content === 'number' ||
          isType((content && content.value) || content, 'object')) &&
          !isHtml && (
            <div style={{ ...defaultStyle.contentStyle, ...fontStyle }}>
              {
                getContent(
                  content,
                  mapper,
                  locale,
                  themeColor,
                  this.props.enableShowMore
                ).data
              }
            </div>
        )}
        {isHtml && (
          <div style={{ padding: '10px' }}> {ReactHtmlParser(content)} </div>
        )}
        {!isChangelog && items.length > 0 && (
          <div
            style={
              align === 'vertical'
                ? defaultStyle.checkBoxStyle
                : { marginTop: 20 }
            }
          >
            {items.map((item, index) => (
              <div
                style={
                  align === 'vertical' && index % 2 === 0
                    ? { width: '52%', marginTop: '5px', ...helpNoteStyle }
                    : Object.assign(
                      {},
                      isFullWidth
                        ? { ...defaultStyle.fullWidthStyle, ...helpNoteStyle }
                        : { ...styles.verticalCheckBox, ...helpNoteStyle }
                    )
                }
              >
                <FormGroup column={true}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={`${item.label}_${item.value}_${type}`}
                        color="primary"
                        style={{
                          height: 3,
                          alignSelf: 'baseline',
                          position: 'relative',
                          marginTop: 3
                        }}
                        disabled={true}
                        checked={this.isChecked(content, item)}
                      />
                    }
                    style={styles.checkbox}
                    label={
                      <span style={{ color: 'black' }}>{item.label} </span>
                    }
                  />
                </FormGroup>
                {helpNoteList && (
                  <div style={{ marginRight: 3 }}>
                    <ArrowTooltip title={helpNoteList[item.helpNote]}>
                      <IconButton style={{ marginTop: -3 }}>
                        <ActionHelp color="primary" />
                      </IconButton>
                    </ArrowTooltip>
                  </div>
                )}
              </div>
            ))}
            {content.other && content.other !== '' && (
              <div
                style={{
                  ...defaultStyle.fullWidthStyle,
                  marginLeft: 40,
                  ...otherStyles
                }}
              >
                {content.other}
              </div>
            )}
          </div>
        )}
        {isType(content, 'array') && isChangelog && (
          <DivWrapper
            autoHeightMax={calculatedHeight}
            style={{ height: calculatedHeight, maxHeight: calculatedHeight }}
            onScroll={this.handleScroll}
          >
            <List
              style={{ overflow: 'none' }}
              height={calculatedHeight}
              itemCount={content.length}
              itemSize={this.getItemSize}
              ref={this.listRef}
            >
              {Row}
            </List>
          </DivWrapper>
        )}
        {this.state.viewNoteOpen && (
          <Note
            id="view-note"
            content={this.state.selectedNote}
            isEdit={false}
            headerLabel={commonTranslations.viewNoteHeader}
            entityName={label || header}
            addtlTitle={
              this.state.categoryType &&
              categoryTypeTranslations[this.state.categoryType]
            }
            title={this.state.selectedValue}
            open={this.state.viewNoteOpen}
            close={this.requestViewNoteClose}
          />
        )}
      </div>
    );
  }
}

ItemList.propTypes = {
  header: PropTypes.node,
  showTextField: PropTypes.bool,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.bool
  ]),
  items: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number
  }),
  align: PropTypes.string,
  isChangelog: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  mapper: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  height: PropTypes.number,
  isPartOf: PropTypes.string,
  locale: PropTypes.string,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  label: PropTypes.node,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  ignoredRuleIds: PropTypes.shape({}),
  downloadDocuments: PropTypes.func,
  fontStyle: PropTypes.shape({}),
  headerStyle: PropTypes.shape({}),
  isHtml: PropTypes.bool,
  type: PropTypes.string,
  maxContentSize: PropTypes.number,
  additionalInfoConfig: PropTypes.shape({
    text: PropTypes.string
  }),
  helpNotes: PropTypes.node,
  showSidebar: PropTypes.bool,
  enableShowMore: PropTypes.bool,
  helpNoteList: PropTypes.shape({}),
  helpNoteListStyle: PropTypes.shape({}),
  rootStyle: PropTypes.shape({}),
  showDisclaimer: PropTypes.bool,
  disclaimerText: PropTypes.string,
  onChange: PropTypes.func,
  showCheckBox: PropTypes.bool,
  isDisableCheckbox: PropTypes.bool,
  otherStyles: PropTypes.shape({}),
  subHeader: PropTypes.string
};

ItemList.defaultProps = {
  maxContentSize: 5,
  showTextField: true,
  header: null,
  content: [],
  mapper: null,
  height: 0,
  isPartOf: '',
  align: '',
  isFullWidth: false,
  isChangelog: true,
  items: {},
  theme: {},
  locale: null,
  risks: [],
  ignoredRuleIds: {},
  label: null,
  downloadDocuments: (e) => e,
  fontStyle: {},
  headerStyle: {},
  isHtml: false,
  type: '',
  additionalInfoConfig: {},
  helpNotes: null,
  showSidebar: false,
  enableShowMore: true,
  helpNoteList: {},
  helpNoteListStyle: {},
  rootStyle: {},
  showDisclaimer: false,
  disclaimerText: '',
  onChange: (e) => e,
  showCheckBox: false,
  isDisableCheckbox: false,
  otherStyles: {},
  subHeader: ''
};

export default ItemList;
