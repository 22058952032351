import { reducer as formReducer } from 'redux-form';

import intl from '@packages/translation/reducers';
import generalSettings from '@packages/features/settings/components/general/reducers';
import user from '@packages/features/user/reducers';
import notifier from '@packages/features/notifier/reducers';
import navBar from '@packages/features/navbar/reducers';
import loader from '@packages/features/loader/reducers';

export default {
  form: formReducer,
  intl,
  generalSettings,
  notifier,
  navBar,
  user,
  loader
};
