import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage } from 'react-intl';

import { renderTextField } from '@packages/components/form-components';
import { commonTranslations } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import styles from '@packages/ui/styles';
import { renderChipSelector } from '@packages/features/privacy-record-detail/renderers/renderers';

import { isMasterDataEditFormVisible } from '../../../utils';
import ImpactAnalysisPage from '../../../impact-analysis';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });
  return errors;
};

export const CustomMasterData = (props) => {
  const {
    actionError,
    change,
    customDataValue,
    dataItemId,
    entityItem,
    entityLabel,
    handleSubmit,
    hasNoLimit,
    hideTags,
    init,
    initialize,
    isEdit,
    isJobActive,
    isTagEdit,
    isUsed,
    onCancel,
    resetCustomSimpleData,
    submitting,
    updateCustomMasterData,
    usage
  } = props;

  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);
  const [isEditWithJob, setIsEditWithJob] = useState(true);

  useEffect(() => {
    if (dataItemId) init(dataItemId, entityItem);
    if (!isEdit) handleInitialise({});
    return () => resetCustomSimpleData();
  }, []);

  useEffect(() => {
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    if (customDataValue !== undefined) {
      if (showForm) {
        window.document.getElementById('simpleEntityDialog')?.focus();
      }
      if (onAfterUpdation) props.onSave(customDataValue, closeDialog);
      else handleInitialise(customDataValue);
    }
  }, [customDataValue]);

  const onSave = (customDataValue, closeDialogValue) => {
    const modifiedData = {
      ...customDataValue,
      name: customDataValue.name.trim()
    };
    const {
      isEdit,
      source,
      entityItem: { entityType, label }
    } = props;
    updateCustomMasterData(
      modifiedData,
      isEdit,
      source,
      isUsed,
      entityType,
      label,
      isEditWithJob
    );
    setCloseDialog(closeDialogValue);
    setOnAfterUpdation(true);
    if (!closeDialogValue) initialize({});
  };

  const handleInitialise = (entityData) => {
    if (!isEdit) initialize({});
    else {
      initialize(entityData);
    }
  };

  const handleTagChange = (data) => {
    change('tags', data);
  };

  const handleEditWithJob = (isChecked) => {
    setIsEditWithJob(isChecked);
  };

  const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);

  return (
    <form>
      {isUsed && (
        <ImpactAnalysisPage
          hasNoLimit={hasNoLimit}
          usage={usage}
          handleEditWithJob={handleEditWithJob}
          isJobActive={isJobActive}
          label={entityLabel}
          type="customSimpleMasterData"
        />
      )}
      {showForm && (
        <div>
          <div>
            <div>
              <Field
                id="simpleEntityDialog"
                name="name"
                label={commonTranslations.name}
                hintTextLabel={
                  <FormattedMessage
                    id="masterData.customSimple"
                    defaultMessage={entityItem.label}
                  />
                }
                autoFocus={!isEdit}
                isEdit={isEdit}
                component={renderTextField}
                disabled={isTagEdit}
                showTextField={!isTagEdit}
              />
            </div>
            {!hideTags && (
              <div style={{ width: '100%' }}>
                <FieldArray
                  id="tags"
                  name="tags"
                  type="tags"
                  entityType="customMasterData"
                  disabled={isEdit && !isTagEdit}
                  headerLabel={commonTranslations.tags}
                  hintText={commonTranslations.addTags}
                  updateTags={handleTagChange}
                  chipSelectorStyle={{ width: '98%' }}
                  showHeader={isTagEdit || !isEdit}
                  component={renderChipSelector}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {actionError && actionError.isError && (
        <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
          {actionError.message}
        </div>
      )}
      <div style={styles.addButton}>
        {showForm && (
          <Button
            id="submit"
            disabled={submitting}
            type="submit"
            onClick={handleSubmit((entityData) => onSave(entityData, true))}
          >
            {commonTranslations.saveAndClose}
          </Button>
        )}
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            disabled={submitting}
            onClick={handleSubmit((entityData) => onSave(entityData, false))}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}
        {showForm && (
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        )}
        {!hasNoLimit && isUsed && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
    </form>
  );
};

CustomMasterData.propTypes = {
  submitting: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  change: PropTypes.func,
  hasNoLimit: PropTypes.bool
};

CustomMasterData.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  handleSubmit: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  usage: {},
  isUsed: false,
  change: (e) => e,
  hasNoLimit: false
};

const CustomMasterDataWrapper = reduxForm({
  form: 'CustomMasterData', // a unique identifier for this form
  validate
})(CustomMasterData);

export default injectIntl(CustomMasterDataWrapper);
