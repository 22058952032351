import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';
import PersonalDataMultiselectorDefault from './data-item-multiselector';


const mapStateToProps = state => ({
  categories: state.environment.personalDataCategories.get('items'),
  personalDataItems: state.environment.personalDataItems.get('personalDataItems'),
  searchResults: state.personalItemCategories.get('items'),
  searchText: state.personalItemCategories.get('searchText'),
  position: state.environment.personalDataCategories.get('position')
});

const mapDispatchToProps = dispatch => ({
  initPersonalDataItems: (searchText) => {
    const searchParams = { searchText, searchKey: 'name' };
    dispatch({ type: 'PERSONAL_DATA_ITEMS:LIST:REQUEST_INIT', searchParams });
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH', searchKey: 'name', searchText });
  },
  initPersonalDataCategory: () => {
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PERSONAL_ITEM_CATEGORY:LIST:SEARCH_INIT', searchParams });
  },
  getNextData: (position) => {
    dispatch({ type: 'PERSONAL_DATA_CATEGORIES:LIST:NEXT_FETCH',
      position,
      isPersistedData: true,
      source: 'records' });
  },
  onChooseFilter: (filterParams, searchText) => {
    dispatch({ type: 'PERSONAL_DATA_ITEMS:LIST:FILTER_INIT',
      filterParams,
      searchParams: { searchText, searchKey: 'name' },
      source: 'records',
      isPersistedData: true });
  }
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(PersonalDataMultiselectorDefault));
