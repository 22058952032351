import Immutable from 'immutable';

import { deepCopy } from '@packages/utils/common-utils';

import processingfieldsList from './components/fieldVisibility/defaultFields/processingDefaults';
import assessmentfieldsList from './components/fieldVisibility/defaultFields/assessmentDefaults';
import publicProcessingFields from './components/integration/public-registry/fieldVisibility/default/processingDefaults';
import publicAssessmentFields from './components/integration/public-registry/fieldVisibility/default/assessmentDefaults';

const DEFAULT_LOGO_ID = 'c26987fc-5cbd-4a96-b39a-b1ed507e60e3';
const DEFAULT_HOLDING_LOGO_ID = '005df34a-d32f-4fc5-9842-564b61da5967';

export const filterMenuItems = (
  roles,
  userPermissions,
  filterMenus,
  showchangePassword,
  enabledSSO,
  ssoSettings,
  bypassSSO,
  isGlobal
) => {
  let menuItems = deepCopy(filterMenus);
  const isAdmin = roles.indexOf('Administrator') !== -1;
  const isOnlyAdmin = roles.size === 1 && roles.indexOf('Administrator') === 0;
  // Filter out general and security settings from filter menu for users
  // without Administrator or SuperAdminstrator role.
  const {
    createEditAccessToken,
    createEditPublicRegistry,
    createEditHelpNotes,
    viewBreach,
    viewProcessing,
    viewPreDpia,
    createEditSettings,
    editWhitelabelling,
    createEditDsr,
    editFieldVisibility,
    createEditSSO
  } = userPermissions;

  const isSSOEnabled =
    enabledSSO && ssoSettings && ssoSettings.value.status !== 'Off';

  // general
  if (!createEditSettings) {
    menuItems = menuItems.filter((item) => item.key !== 'general');
  } else {
    const generalIndex = menuItems.findIndex((item) => item.key === 'general');
    if (!editWhitelabelling) {
      menuItems[generalIndex].subMenu = menuItems[generalIndex].subMenu.filter(
        (item) => item.key !== 'interfacestyle_general'
      );
    }
    if (isGlobal) {
      menuItems[generalIndex].subMenu = menuItems[generalIndex].subMenu.filter(
        (item) => item.key !== 'tenantEmailPreference_general'
      );
    }
    menuItems[generalIndex].defaultFilter =
      menuItems[generalIndex].subMenu[0].key;
  }

  if (!createEditSettings || isGlobal) {
    menuItems = menuItems.filter((item) => item.key !== 'auditTrailSettings');
  }

  // fieldvisibility
  if (!editFieldVisibility || (!viewProcessing && !viewPreDpia)) {
    menuItems = menuItems.filter((item) => item.key !== 'fieldVisibility');
  } else {
    const fieldVisibilityIndex = menuItems.findIndex(
      (item) => item.key === 'fieldVisibility'
    );
    if (!viewProcessing) {
      menuItems[fieldVisibilityIndex].subMenu = menuItems[
        fieldVisibilityIndex
      ].subMenu.filter((item) => item.key !== 'processing_fieldVisibility');
    } else if (!viewPreDpia) {
      menuItems[fieldVisibilityIndex].subMenu = menuItems[
        fieldVisibilityIndex
      ].subMenu.filter((item) => item.key !== 'assessment_fieldVisibility');
    }
    menuItems[fieldVisibilityIndex].defaultFilter =
      menuItems[fieldVisibilityIndex].subMenu[0].key;
  }

  // security
  if (!createEditSettings) {
    menuItems = menuItems.filter((item) => item.key !== 'security');
  } else {
    const securityIndex = menuItems.findIndex(
      (item) => item.key === 'security'
    );
    if (!createEditSSO) {
      menuItems[securityIndex].subMenu = menuItems[
        securityIndex
      ].subMenu.filter((item) => item.key !== 'sso_security');
    }
    menuItems[securityIndex].defaultFilter =
      menuItems[securityIndex].subMenu[0].key;
  }

  // Filter out dsr settings from filter menu for user without createEditDsr
  if (!createEditDsr) {
    menuItems = menuItems.filter((item) => item.key !== 'dsr');
  }

  // custom
  if (
    !createEditHelpNotes ||
    (!viewProcessing && !viewBreach && !viewPreDpia)
  ) {
    menuItems = menuItems.filter((item) => item.key !== 'custom');
  } else {
    const helpNotesIndex = menuItems.findIndex((item) => item.key === 'custom');
    if (!viewPreDpia) {
      menuItems[helpNotesIndex].subMenu[0].subMenu = menuItems[
        helpNotesIndex
      ].subMenu[0].subMenu.filter(
        (item) => item.key !== 'assessment_helpTexts_custom'
      );
    }
    if (!viewProcessing) {
      menuItems[helpNotesIndex].subMenu[0].subMenu = menuItems[
        helpNotesIndex
      ].subMenu[0].subMenu.filter(
        (item) => item.key !== 'processing_helpTexts_custom'
      );
    }
    if (!viewBreach) {
      menuItems[helpNotesIndex].subMenu[0].subMenu = menuItems[
        helpNotesIndex
      ].subMenu[0].subMenu.filter(
        (item) => item.key !== 'breach_helpTexts_custom'
      );
    }
    menuItems[helpNotesIndex].defaultFilter =
      menuItems[helpNotesIndex].subMenu[0].subMenu[0].key;
  }

  // Filter out subscription from filter menu for a non-admin user
  if (!isAdmin) {
    menuItems = menuItems.filter((item) => item.key !== 'subscription');
  }

  // profile
  const profileIndex = menuItems.findIndex((item) => item.key === 'profile');
  // Filter out change password from filter menu
  if (!showchangePassword) {
    menuItems[profileIndex].subMenu = menuItems[profileIndex].subMenu.filter(
      (item) => item.key !== 'changepassword_profile'
    );
  }
  // Filter out userEmailPreference_profile if role is only admin
  if (isOnlyAdmin || isGlobal) {
    menuItems[profileIndex].subMenu = menuItems[profileIndex].subMenu.filter(
      (item) => item.key !== 'userEmailPreference_profile'
    );
  }

  // integration
  const showPublicRegister =
    createEditPublicRegistry && (viewPreDpia || viewProcessing);
  const showApiTokens =
    createEditAccessToken && (!isSSOEnabled || (isSSOEnabled && bypassSSO));
  if (!showApiTokens && !showPublicRegister) {
    menuItems = menuItems.filter((item) => item.key !== 'integration');
  } else {
    const integrationIndex = menuItems.findIndex(
      (item) => item.key === 'integration'
    );
    if (!showApiTokens) {
      menuItems[integrationIndex].subMenu = menuItems[
        integrationIndex
      ].subMenu.filter((item) => item.key !== 'apiTokens_integration');
    } else if (!showPublicRegister) {
      menuItems[integrationIndex].subMenu = menuItems[
        integrationIndex
      ].subMenu.filter((item) => item.key !== 'publicRegistry_integration');
    }
    menuItems[integrationIndex].defaultFilter =
      menuItems[integrationIndex].subMenu[0].key;
  }
  return menuItems;
};

export const checkForBulkItems = (items, bulkItems) => {
  const bulkItemIds = bulkItems.map((item) => item.id);
  const newItems = [];
  items.forEach((item) => {
    if (bulkItemIds.includes(item.id)) {
      newItems.push({ ...item, selected: true });
    } else {
      newItems.push(item);
    }
  });
  return Immutable.List(newItems);
};

export const reverseAllFilteredFields = (
  response,
  isRequest = false,
  visibiltyType
) => {
  const modifiedValue = { ...response };
  const recordTypes = ['processing', 'assessment', 'breach'];
  recordTypes.forEach((type) => {
    const fieldsToNegate =
      modifiedValue.value[type].length > 0
        ? modifiedValue.value[type][0].fields || modifiedValue.value[type]
        : [];
    const reversedFields = reverseFieldsByRecordType(
      fieldsToNegate,
      type,
      visibiltyType
    );
    if (isRequest) modifiedValue.value[type] = reversedFields;
    else {
      // As we are using common component to display fieldvisibilty, transform response to required format.
      modifiedValue.value[type] = [
        {
          fields: reversedFields
        }
      ];
    }
  });
  return modifiedValue;
};

export const reverseFieldsByRecordType = (
  fieldsToNegate,
  recordType,
  visibiltyType
) => {
  // extracting only the keys from default list
  let fullfieldsList = [];

  switch (recordType) {
    case 'assessment':
      fullfieldsList =
        visibiltyType === 'publicFieldVisibility'
          ? publicAssessmentFields
          : assessmentfieldsList;
      break;
    case 'processing':
      fullfieldsList =
        visibiltyType === 'publicFieldVisibility'
          ? publicProcessingFields
          : processingfieldsList;
      break;
    default:
      fullfieldsList = [];
  }
  const fullfieldsArray = [].concat(
    ...fullfieldsList
      .map((item) => item.subFields)
      .map((item) => item.map((c) => c.key))
  );
  const negatedFieldList = fullfieldsArray.filter(
    (x) => !fieldsToNegate.includes(x)
  );
  return negatedFieldList;
};

export const getPublicRegistryRecordList = (permissions) => {
  const recordPermissions = ['viewProcessing', 'viewPreDpia'];
  return recordPermissions
    .filter((permission) => permissions.get(permission))
    .map(
      (permission) =>
        ({ viewProcessing: 'processing', viewPreDpia: 'assessment' }[
          permission
        ])
    );
};

export const checkForLogoAndModify = (whiteLabellingData) => {
  let modifyWhiteLabellingData = { ...whiteLabellingData };
  const { companyLogo } = modifyWhiteLabellingData;
  if (!companyLogo) {
    modifyWhiteLabellingData = {
      ...modifyWhiteLabellingData,
      companyLogo: { name: 'Default company logo' }
    };
  }

  if (
    companyLogo &&
    (companyLogo.id === DEFAULT_LOGO_ID ||
      companyLogo.id === DEFAULT_HOLDING_LOGO_ID)
  ) {
    modifyWhiteLabellingData = {
      ...modifyWhiteLabellingData,
      companyLogo: {
        ...companyLogo,
        name: 'Default company logo'
      }
    };
  }
  return modifyWhiteLabellingData;
};
