import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import registry from 'app-registry';
import { handleServiceDown, MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import { deleteSuccessError, masterDataTranslations } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getURLWithParams, updateDataItems } from '../saga-utils';

export function* resetAndfetchRetentionTermsOffsets(action) {
  yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:REQUEST:INIT' });
  yield fetchRetentionTermsOffsets(action);
}

export function* fetchRetentionTermsOffsets(action) {
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT } = action;
  try {
    let filterParams = {};
    let searchParameters = {};
    let searchTextObj = {};
    let filteredOn = Immutable.Map();
    let unused = false;
    if (action.isPersistedData) {
      const retentionTermsState = yield select(state => state.environment.retentionTerms);
      if (retentionTermsState) {
        filterParams = retentionTermsState.get('filterParams').toJS();
        unused = retentionTermsState.get('unused');
        filteredOn = retentionTermsState.get('filteredOn');
        searchTextObj = retentionTermsState.get('searchText').toJS();
        searchParameters = searchTextObj ? { searchKey: 'name', searchText: searchTextObj.name } : {};
      }
    }

    const { sortOn, sortOrder } = filterParams;
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        position, searchParameters, 'retentiontermoffsets', rowCount, undefined, unused), null);
    switch (response.status) {
      case 200: {
        const modifiedData = response.body.map(item => ({ ...item, key: item.name }));
        yield put({
          type: 'MASTERDATA:RETENTION_OFFSETS:LIST:FETCH:SUCCESS',
          offsets: modifiedData,
          offsetItem: action.offsetItem
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:FETCH:FAIL', error: err.message });
    registry.get('logger').error(err);
  }
}

export function* searchRetentionOffsets(action) {
  let { searchParams } = action;
  const rowCount = MASTERDATA_SEARCH_LIMIT;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  let filterParams = {};
  let unused = false;
  const filteredOn = Immutable.Map();

  const retentionTermsState = yield select(state => state.environment.retentionTerms);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = retentionTermsState ? retentionTermsState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  if (retentionTermsState) {
    unused = retentionTermsState.get('unused');
    filterParams = retentionTermsState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;

  yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParams, 'retentiontermoffsets', rowCount, undefined, unused), null, {});
    switch (response.status) {
      case 200: {
        const modifiedData = response.body.map(item => ({ ...item, key: item.name }));
        yield put({
          type: 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedData,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* filterRetentionOffsets(action) {
  const filterParams = action.filterParams || {};
  yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:FILTER', filterParams });
  yield fetchRetentionTermsOffsets(action);
}

export function* fetchRetentionOffsetItem(action) {
  yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/masterdata/retentiontermoffsets/${action.id}`, null);
      switch (response.status) {
        case 200: {
          yield put({
            type: 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:SUCCESS',
            offsetItem: response.body
          });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:ITEM:FETCH:FAIL', error: err.message });
    }
  }
}

export function* updateRetentionOffsetItem(action) {
  const { isEdit = false, data, source } = action;
  const request = registry.get('request');

  let response;
  try {
    const requestData = isEdit ? { name: data.name } : { ...data };
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(`/v1/masterdata/retentiontermoffsets`, requestData);
    } else {
      response = yield request.put(`/v1/masterdata/retentiontermoffsets/${data.id}?withJob=false`, data);
    }
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: NotificationMessages[isEdit],
            type: 'success'
          }
        });

        // Handle consistency in the front end.
        const modifiedData = { ...response.body, key: response.body.name };
        if (isEdit) {
          yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:UPSERT:SUCCESS', offsetItem: modifiedData });
          yield updateDisplayListItems({
            type: 'edit',
            data: Object.assign({}, data, response.body),
            source
          });
        } else {
          yield put({
            type: 'MASTERDATA:RETENTION_OFFSETS:UPSERT:SUCCESS',
            offsetItem: modifiedData,
            position: 0
          });
          yield fetchRetentionTermsOffsets({
            offsetItem: modifiedData,
            isPersistedData: true,
            rowCount: MASTERDATA_FETCH_LIMIT
          });
        }

        break;
      }
      case 304: {
        yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:UPSERT:SUCCESS', offsetItem: requestData });
        break;
      }
      case 409: yield put({
        type: 'MASTERDATA:RETENTION_OFFSETS:UPSERT:FAIL',
        error: response.body.msg
      });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:UPSERT:FAIL', error: err.message });
  }
}

export function* deleteRetentionOffsetItem(action) {
  const { dataItemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/masterdata/retentiontermoffsets/${dataItemId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('retentionTermOffset'),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateDisplayListItems({
          type: 'delete',
          offsetItemId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

function* updateDisplayListItems(action) {
  const { type, data, source, offsetItemId } = action;
  const retentionOffsetState = yield select(state => state.environment.retentionTerms);
  let position = null;
  let offsets = retentionOffsetState ? retentionOffsetState.get('offsets') : Immutable.List();
  let newItems = retentionOffsetState ? retentionOffsetState.get('newItems') : Immutable.List();
  if (type === 'delete') {
    position = retentionOffsetState.get('position') - 1;
    offsets = updateDataItems(offsets, type, offsetItemId);
    newItems = updateDataItems(newItems, type, offsetItemId);
  } else if (type === 'edit' && source !== 'records') {
    offsets = updateDataItems(offsets, type, data.id, source, data);
    newItems = updateDataItems(newItems, type, data.id, source, data);
  }
  yield put({ type: 'MASTERDATA:RETENTION_OFFSETS:LIST:UPDATE:SUCCESS', offsets, newItems, position });
}

const NotificationMessages = {
  true: notificationtranslations.retentionOffsetUpdateSuccess,
  false: masterDataTranslations.offsetItemCreated
};
