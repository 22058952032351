import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import Immutable from 'immutable';
import {
  handleServiceDown,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { getFilteredOn } from '@packages/utils/reducer-utils';

export default function* searchUsersSelector(action) {
  const { searchParams, userHierarchy } = action;
  const { searchKey, searchText } = searchParams;
  let filteredOn = Immutable.Map();
  filteredOn = getFilteredOn({
    filterKey: 'organisationHierarchy',
    filteredOn: userHierarchy,
    filterObj: filteredOn
  });
  yield put({
    type: 'RECORDS:ACCESS_RIGHTS:LIST:SEARCH',
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(getURLWithParams(searchParams, { filteredOn }), null, {});
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
        }));
        yield put({
          type: 'RECORDS:ACCESS_RIGHTS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    registry.get('logger').error(err);
    yield put({ type: 'ACCESS_RIGHTS:LIST:SEARCH:FAIL', error: err.message });
  }
}

const getURLWithParams = (searchParams, filterParams) => {
  let url = `/v1/users?filter=status=Registered&numberOfResults=${MASTERDATA_SEARCH_LIMIT}&offset=0`;
  const { sortOn, sortOrder, filteredOn } = filterParams;
  filteredOn.keySeq().forEach((filterKey) => {
    const filteredOnItem = filteredOn.get(filterKey);
    if (filteredOnItem && filteredOnItem.length > 0) {
      filteredOnItem.forEach((x) => {
        url = `${url}&filter=${filterKey}%3D${encodeURIComponent(x)}`;
      });
    }
  });
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=name=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  return url;
};
