import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { filterMenu, requestTypes } from './filterValues';
import { getModifiedFilterMenu, setModifiedStatus, updateSubmenus } from './utils';

const defaultState = Immutable.fromJS({
  prevLocation: '/dsr',
  currentFilter: 'Registration_Registration',
  filterMenu: [],
  processingsList: Immutable.List(),
  data: Immutable.Map(),
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  isProcessingFetching: false,
  isFetching: false,
  unSavedData: false,
  users: undefined,
  userSearchText: Immutable.Map(),
  userSearchResults: Immutable.Map({
    assignedUsers: { searchResults: Immutable.List() },
    approvers: { searchResults: Immutable.List() }
  }),
  userPosition: 0,
  isUploading: false,
  dsrAttachment: undefined,
  message: {},
  position: 0,
  comment: {},
  isCommentDeleted: false,
  isReopendCompletedDSR: false,
  organisationUsers: Immutable.List(),
  isUpdating: false,
  requestTypes: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DSR:FILTERMENU:STATUS:UPDATE': {
      const { value, chapterMenu, isJustified } = action;
      const menu = state.get('filterMenu');
      const filterValue = chapterMenu || state.get('currentFilter');
      const modifiedStatus = setModifiedStatus(menu, value, filterValue, isJustified);
      return state
        .set('filterMenu', modifiedStatus);
    }
    case 'DSR:FILTERMENU:SUBMENU:UPDATE': {
      const { parentMenu, subMenus } = action;
      const modifiedFilterMenu = updateSubmenus(state.get('filterMenu'), parentMenu, subMenus);
      return state
        .set('filterMenu', modifiedFilterMenu);
    }
    case 'DSR:PROCESSING_LIST:INIT':
      return state
        .set('processingsList', defaultState.get('processingsList'))
        .set('isProcessingFetching', true)
        .set('position', defaultState.get('position'));
    case 'DSR:PROCESSING_LIST:FETCH:SUCCESS':
      return state
        .set('processingsList', state.get('processingsList').concat(action.processingsList))
        .set('isProcessingFetching', false)
        .set('position', state.get('position') + action.processingsList.length);
    case 'DSR:DETAIL:INIT':
    case 'DSR:DETAIL:RESET': {
      return state
        .set('prevLocation', defaultState.get('prevLocation'))
        .set('currentFilter', defaultState.get('currentFilter'))
        .set('data', defaultState.get('data'))
        .set('filterMenu', filterMenu)
        .set('isUploading', false)
        .set('unSavedData', defaultState.get('unSavedData'))
        .set('dsrAttachment', defaultState.get('dsrAttachment'));
    }
    case 'DSR:FILTER:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'DSR:REOPEN:SUCCESS': {
      const { isReopendCompletedDSR = false } = action;
      return state.set('isReopendCompletedDSR', isReopendCompletedDSR);
    }
    case 'DSR:SUBMENU:CHANGE':
      return state.set('currentSubMenu', action.currentSubMenu);


    case 'DSR:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (['messagecenter', 'dashboard'].includes(queryParams.from)) {
        prevLocation = `/${queryParams.from}`;
      }
      return state.set('actionError', defaultState.get('actionError'))
        .set('data', defaultState.get('data'))
        .set('prevLocation', prevLocation)
        .set('isFetching', true);
    }

    case 'DSR:DETAIL:FETCH:SUCCESS':
      return state.set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('currentFilter', action.currentFilter)
        .set('isReopendCompletedDSR', false)
        .set('data', Immutable.Map(action.data))
        .set('requestTypes', requestTypes[action.data.jurisdiction])

    case 'DSR:DETAIL:FETCH:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }))
        .set('isFetching', false);
    case 'DSR:DETAIL:PROPERTY:UPDATE': {
      const modifiedData = { ...state.get('data').toJS(), [action.property]: action.data };
      return state.set('actionError', defaultState.get('actionError'))
        .set('data', Immutable.Map(modifiedData));
    }
    case 'DSR:UNSAVED:DATA:SET':
      return state.set('isUpdating', false)
        .set('unSavedData', true);
    case 'DSR:UNSAVED:DATA:RESET':
      return state.set('unSavedData', defaultState.get('unSavedData'))
        .set('isUpdating', true);
    case 'DSR:DETAIL:UPSERT:SUCCESS':
      return state.set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('isUpdating', false);
    case 'DSR:DETAIL:UPSERT:FAIL':
      return state.set('isUpdating', false);
    case 'DSR:USERS:LIST:SEARCH': {
      return state
        .set('userSearchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'DSR:USERS:LIST:SEARCH:SUCCESS': {
      const { dataItemType } = action;
      let userSearchResults = state.get('userSearchResults').toJS();
      const currentTypeParams = { ...userSearchResults[dataItemType] };
      if (currentTypeParams) {
        currentTypeParams.searchResults = action.items;
        userSearchResults = { ...userSearchResults, [dataItemType]: currentTypeParams };
      }
      return state
        .set('userSearchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('userSearchText').toJS() })))
        .set('userSearchResults', Immutable.Map(userSearchResults));
    }
    case 'DSR:USERS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'DSR:USERS:LIST:INIT': {
      return state
        .set('userPosition', defaultState.get('userPosition'))
        .set('users', defaultState.get('users'))
        .set('error', defaultState.get('error'));
    }
    case 'DSR:USERS:LIST:FETCH': {
      return state.set('isFetching', true);
    }
    case 'DSR:USERS:LIST:FETCH:FAIL': {
      return state.set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    }
    case 'DSR:USERS:LIST:FETCH:SUCCESS': {
      const { items } = action;
      const user = state.get('users') || [];
      const modifiedItems = user.concat(items);
      return state
        .set('users', Immutable.List(modifiedItems))
        .set('userPosition', state.get('userPosition') + items.length)
        .set('isFetching', false);
    }
    case 'DSR:FILTERMENU:UPDATE': {
      const { value, nextFilter } = action;
      const currentFilterMenu = state.get('filterMenu');
      const modifiedFilterMenu = getModifiedFilterMenu(currentFilterMenu, nextFilter, value);
      return state
        .set('filterMenu', modifiedFilterMenu);
    }
    case 'DSR:ATTACHMENT:UPLOAD:INIT':
      return state
        .set('isUploading', true);
    case 'DSR:ATTACHMENT:DOWNLOAD:SUCCESS':
      return state
        .set('isUploading', false);
    case 'DSR:UPLOAD:DOCUMENT:SUCCESS':
      return state
        .set('isUploading', false)
        .set('dsrAttachment', action.attachment);
    case 'RESET:DOCUMENT:IS_UPLOADING':
      return state
        .set('isUploading', false);
    case 'DSR:MESSAGE:SENT:SUCCESS':
      return state
        .set('message', action.data);
    case 'DSR:RESET:MESSAGE':
      return state
        .set('message', defaultState.get('message'));
    case 'DSR:RESET:ATTATCHMENT':
      return state
        .set('dsrAttachment', defaultState.get('dsrAttachment'));
    case 'DSR:ATTACHMENT:UPLOAD:FAIL':
      return state
        .set('isUploading', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'DSR:COMMENT:DELETE:INIT':
      return state
        .set('isCommentDeleted', false);
    case 'DSR:COMMENT:DELETE:SUCCESS':
      return state
        .set('isCommentDeleted', true);
    case 'DSR:DETAIL:COMMENT:RESET':
    case 'DSR:COMMENT:SAVE:INIT':
      return state
        .set('comment', defaultState.get('comment'));
    case 'DSR:COMMENT:SAVE:SUCCESS':
      return state
        .set('comment', action.comment);
    case 'DSR:COMMENT:SAVE:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'DSR:COMMENT:DELETE:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'DSR:UPDATE:FILTER:MENU':
      return state
        .set('currentFilter', 'Review_Review');
    case 'DSR:RESET:PROCESSINGSLIST':
      return state
        .set('processingsList', defaultState.get('processingsList'));
    case 'DSR:ORGANISATION_USERS:LIST:REQUEST:FETCH':
      return state
        .set('organisationUsers', defaultState.get('organisationUsers'));
    case 'DSR:ORGANISATION_USERS:LIST:REQUEST:SUCCESS': {
      return state
        .set('organisationUsers', Immutable.List(action.data));
    }
    default:
      return state;
  }
};
