import { recordTranslations } from '@packages/utils/commontranslations';
import { commonTranslations, natureOfBreachTranslations,
  breachConsequenceTranslations, reasonTypeTranslations } from '../../recordTranslations';


export const breachNatureTypes = [
  {
    label: natureOfBreachTranslations.breach_nature_reading,
    value: 'breach_nature_reading'
  },
  {
    label: natureOfBreachTranslations.breach_nature_copying,
    value: 'breach_nature_copying'
  },
  {
    label: natureOfBreachTranslations.breach_nature_alteration,
    value: 'breach_nature_alteration'
  },
  {
    label: natureOfBreachTranslations.breach_nature_deletion,
    value: 'breach_nature_deletion'
  },
  {
    label: natureOfBreachTranslations.breach_nature_unavailability,
    value: 'breach_nature_unavailability'
  },
  {
    label: natureOfBreachTranslations.breach_nature_unauthorised_disclosure,
    value: 'breach_nature_unauthorised_disclosure'
  },
  {
    label: natureOfBreachTranslations.breach_nature_theft,
    value: 'breach_nature_theft'
  },
  {
    label: natureOfBreachTranslations.breach_nature_others,
    value: 'breach_nature_others'
  }
];

export const breachConsequenceTypes = [
  {
    label: breachConsequenceTranslations.breach_consequences_damage_of_reputation,
    value: 'breach_consequences_damage_of_reputation'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_discrimination,
    value: 'breach_consequences_discrimination'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_financial_loss,
    value: 'breach_consequences_financial_loss'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_fraud,
    value: 'breach_consequences_fraud'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_health_damage,
    value: 'breach_consequences_health_damage'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_identity_theft,
    value: 'breach_consequences_identity_theft'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_limitation_of_rights,
    value: 'breach_consequences_limitation_of_rights'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_loss_of_confidentiality,
    value: 'breach_consequences_loss_of_confidentiality'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_loss_of_control_over_personal_data,
    value: 'breach_consequences_loss_of_control_over_personal_data'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_significant_economic_disadvantage,
    value: 'breach_consequences_significant_economic_disadvantage'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_significant_social_disadvantage,
    value: 'breach_consequences_significant_social_disadvantage'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_spam_or_phishing,
    value: 'breach_consequences_spam_or_phishing'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_stigma_or_exclusion,
    value: 'breach_consequences_stigma_or_exclusion'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_unauthorised_reversal_of_pseudonymisation,
    value: 'breach_consequences_unauthorised_reversal_of_pseudonymisation'
  },
  {
    label: breachConsequenceTranslations.breach_consequences_others,
    value: 'breach_consequences_others'
  }
];

export const supervisoryReasonType = [
  {
    label: recordTranslations.NoLikelyRiskForDataSubject,
    value: 'NoHighRiskForDataSubject'
  },
  {
    label: commonTranslations.other,
    value: 'Others'
  }
];

export const dataSubjectReasonType = [
  {
    label: reasonTypeTranslations.NoHighRiskForDataSubject,
    value: 'NoHighRiskForDataSubject'
  },
  {
    label: reasonTypeTranslations.AppropriateTechnicalAndOrganisationMeasures,
    value: 'AppropriateTechnicalAndOrganisationMeasures'
  },
  {
    label: reasonTypeTranslations.DisproportionalEffort,
    value: 'DisproportionalEffort'
  },
  {
    label: commonTranslations.otherReason,
    value: 'Others'
  }
];
