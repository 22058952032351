import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class ProcessingGroundSelector extends React.Component {
  componentWillMount() {
    if (this.props.initProcessingGrounds) {
      this.props.initProcessingGrounds(this.getTypeAndFilter(this.props.type));
    }
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.processingGrounds === nextProps.processingGrounds) ||
    !(this.props.subField === nextProps.subField);
  }

  getTypeAndFilter = (type) => {
    switch (true) {
      case type.includes('sgpdpa'):
        return 'sgpdpa_processing_ground&filter=jurisdiction=SG';
      case type.includes('pdpaSpecial'):
        return 'pdpa_processing_ground&filter=jurisdiction=TW&filter=category=special';
      case type.includes('pdpa'):
        return 'pdpa_processing_ground&filter=jurisdiction=TW&filter=category=government&filter=category=non_government';
      case type.includes('pdp'):
        return 'pdp_processing_ground&filter=jurisdiction=IN';
      case type.includes('fdpl'):
        return 'fdpl_processing_ground&filter=jurisdiction=MX';
      case type.includes('piplInternational'):
        return 'pipl_transfer_ground&filter=jurisdiction=CN';
      case type.includes('pipl'):
        return 'pipl_processing_ground&filter=jurisdiction=CN';
      case type.includes('lgpdSpecial'):
        return 'lgpd_processing_ground&filter=jurisdiction=BR&filter=category=special';
      case type.includes('lgpdInternational'):
        return 'lgpd_transfer_ground&filter=jurisdiction=BR';
      case type.includes('lgpd'):
        return 'lgpd_processing_ground&filter=jurisdiction=BR';
      default:
        return 'processing_ground';
    }
  };

  render() {
    const { hintTextLabel, processingGrounds, attribute } = this.props;

    const getAttributeValues = (attribute) => {
      const uniqueValues = new Set();
      return processingGrounds.reduce((result, item) => {
        if (!uniqueValues.has(item[attribute])) {
          uniqueValues.add(item[attribute]);
          result.push({
            id: item[attribute],
            key: item[attribute]
          });
        }
        return result;
      }, []);
    };

    return (
      <div>
        {processingGrounds.size > 0 && (
          <ValueSelector
            dataItems={
              attribute ? getAttributeValues(attribute) : processingGrounds
            }
            liveAutocomplete={false}
            dataSourceConfig={{ text: 'key', value: 'key' }}
            selectFromListMenuItem={true}
            hintTextLabel={hintTextLabel}
            valueAttr="key"
            title={
              <FormattedMessage
                id="MultipleProcessingGroundDialog.header"
                description="Select Processing ground Dialog Header"
                defaultMessage="Select processing grounds"
              />
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}

ProcessingGroundSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  initProcessingGrounds: PropTypes.func,
  processingGrounds: PropTypes.instanceOf(Immutable.List),
  subField: PropTypes.string.isRequired,
  type: PropTypes.string,
  attribute: PropTypes.string
};

ProcessingGroundSelector.defaultProps = {
  hintTextLabel: null,
  initProcessingGrounds: (e) => e,
  processingGrounds: Immutable.List(),
  type: '',
  attribute: undefined
};

export default ProcessingGroundSelector;
