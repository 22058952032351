import { takeEvery } from 'redux-saga/effects';

import {
  fetchTenantsAndTags,
  createTag,
  createSubTenant,
  fetchSubTenantHierarchy,
  tenantLogin,
  resetAndfetchDataUpdates,
  fetchDataUpdates,
  filterDataUpdates,
  saveDataUpdates,
  retrySyncJob,
  fetchSubTenantDetail,
  fetchTagDetail,
  fetchTenantDetails,
  globalOrganisationCheckUsage,
  exportQuickReport,
  deleteSubTenantOrGroup,
  downloadSyncJobLog,
  runQuickSearch
} from './sagas';

export default [
  // Global Solutions
  [takeEvery, 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH', fetchTenantDetails],
  [takeEvery, 'GLOBAL:TENANTS_TAGS:FETCH', fetchTenantsAndTags],
  [takeEvery, 'GLOBAL:CREATE:TAG', createTag],
  [takeEvery, 'GLOBAL:CREATE:SUB_TENANT', createSubTenant],
  [takeEvery, 'GLOBAL:SUB_TENANTS:STRUCTURE:FETCH', fetchSubTenantHierarchy],
  [takeEvery, 'GLOBAL:SUB_TENANT:DETAILS:FETCH', fetchSubTenantDetail],
  [takeEvery, 'GLOBAL:TAG:DETAILS:FETCH', fetchTagDetail],
  [
    takeEvery,
    'GLOBAL:LEGAL_ENTITIES:USAGE_CHECK',
    globalOrganisationCheckUsage
  ],
  [takeEvery, 'GLOBAL:SOLUTION:TENANT:SILENT:LOGIN', tenantLogin],
  [
    takeEvery,
    'GLOBAL:SUB_TENANTS:DATA_UPDATES:LIST:FETCH',
    resetAndfetchDataUpdates
  ],
  [takeEvery, 'GLOBAL:SUB_TENANTS:DATA_UPDATES:FILTER_INIT', filterDataUpdates],
  [
    takeEvery,
    'GLOBAL:SUB_TENANTS:DATA_UPDATES:LIST:NEXT_FETCH',
    fetchDataUpdates
  ],
  [takeEvery, 'GLOBAL:SUB_TENANT:SYNC_JOB:RETRY', retrySyncJob],
  [
    takeEvery,
    'GLOBAL:SUB_TENANT:SYNC_JOB:DOWNLOAD:ERROR:LOG',
    downloadSyncJobLog
  ],
  [takeEvery, 'GLOBAL:SUB_TENANTS:DATA_UPDATES:SAVE', saveDataUpdates],
  [takeEvery, 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:INIT', runQuickSearch],
  [takeEvery, 'GLOBAL:SUBTENANT_GROUP:DELETE', deleteSubTenantOrGroup],
  [takeEvery, 'GLOBAL:SOLUTION:EXPORT:INIT', exportQuickReport]
];
