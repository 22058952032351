import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ValueSelector from '../value-selector';
import { multipleSelectorTypeTranslations } from '../../../../environment/masterDataTranslations';

const GroupSelector = (props) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (props.initlinkGroups) {
      props.initlinkGroups();
    }
  }, []);

  useEffect(() => {
    setSearchResults(props.searchResults);
  }, [props.searchResults]);


  const getModifiedItems = (items) => {
    const modifiedItems = items.map(item => ({ ...item, key: item.linkNumber }));
    return modifiedItems;
  };

  const handleNextData = () => {
    props.getNextData(props.position);
  };

  const { hintTextLabel } = props;
  const linkGroups = getModifiedItems(props.linkGroups);
  return (
    <div>
      <ValueSelector
        {...props}
        valueAttr="key"
        searchResults={searchResults}
        dataItems={linkGroups}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        title={multipleSelectorTypeTranslations('linkGroups')}
      />
    </div>
  );
};

GroupSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initlinkGroups: PropTypes.func,
  linkGroups: PropTypes.instanceOf(Immutable.List)
};

GroupSelector.defaultProps = {
  hintTextLabel: null,
  initlinkGroups: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  fields: {},
  linkGroups: Immutable.List()
};

export default GroupSelector;
