import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { deleteSuccessError, documentInfected } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import { handleServiceDown, MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getURLWithParams, updateDataItems } from '../saga-utils';

export function* resetAndFetchDocuments(action) {
  yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:REQUEST:INIT' });
  yield fetchDocuments(action);
}

export function* fetchDocuments(action) {
  yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:FETCH' });
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT, source = '', searchParams = undefined } = action;
  try {
    let filterParams = {};
    let unused = false;
    let searchParameters = {};
    let searchTextObj = {};
    let filteredOn = Immutable.Map();
    let recordsFilterParams = [];
    let recordsFilteredOn = Immutable.Map();
    if (action.isPersistedData) {
      const documentState = yield select(state => state.environment.documents);
      if (documentState) {
        recordsFilterParams = documentState.get('recordsFilterParams').toJS();
        recordsFilteredOn = documentState.get('recordsFilteredOn');
        filterParams = documentState.get('filterParams').toJS();
        unused = documentState.get('unused');
        filteredOn = documentState.get('filteredOn');
        searchTextObj = documentState.get('searchText').toJS();
        searchParameters = searchTextObj ? { searchKey: 'name', searchText: searchTextObj.name } : {};
      }
    }
    if (source === 'records' && searchParams) {
      searchParameters = searchParams;
    }
    const { sortOn = 'name', sortOrder = 'ASC' } = source === 'records' ? recordsFilterParams : filterParams;

    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn: source === 'records' ? recordsFilteredOn : filteredOn },
        position, searchParameters, 'documents', rowCount, undefined, unused), null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map(item => (transformDocumentItem(item)));
        yield put({
          type: 'MASTERDATA:DOCUMENTS:LIST:FETCH:SUCCESS',
          items: modifiedItems,
          documentData: action.documentData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:FETCH:FAIL', error: err.message });
    yield put({ type: 'RECORDS:DOCUMENTS:LIST:FETCH:FAIL', error: err.message });

    registry.get('logger').error(err);
  }
}

export function* filterDocuments(action) {
  const { filterParams = {}, source = '' } = action;
  yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:FILTER', filterParams, source });
  yield fetchDocuments(action);
}

export function* searchDocuments(action) {
  let { searchParams } = action;
  const rowCount = MASTERDATA_SEARCH_LIMIT;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  let filterParams = {};
  let filteredOn = Immutable.Map();
  let unused = false;
  const documentsState = yield select(state => state.environment.documents);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = documentsState ? documentsState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }
  if (documentsState) {
    unused = documentsState.get('unused');
    filteredOn = documentsState.get('filteredOn');
    filterParams = documentsState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;

  yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParams, 'documents', rowCount, undefined, unused), null, {});
    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map(item => (transformDocumentItem(item)));
        yield put({
          type: 'MASTERDATA:DOCUMENTS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    registry.get('logger').error(err);
    yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* updateDocuments(action) {
  const { isEdit, isReplace, data, source, isUsed, isFromDSR = false, isVendor } = action;
  const reducerType = isVendor ? 'VENDOR' : 'MASTERDATA';
  const request = registry.get('request');
  let modifiedData = {
    ...data,
    description: data.description || '',
    tags: data.tags || []
  };
  if (!modifiedData.documentType) delete modifiedData.documentType;
  yield put({ type: `${reducerType}:DOCUMENTS:UPSERT` });
  let response;
  const config = registry.get('config');
  try {
    if (isEdit) {
      response = yield request.put(`/v1/documents/${data.id}`, modifiedData,
        isVendor && {
          headers: {
            [config.login.vendorToken.httpHeader]: registry.get('storage')
              .getItem(config.login.vendorToken.storage.key)
          }
        }, !isVendor);
    } else if (isReplace) {
      if (data.file) {
        response = yield request.postDocument(`/v1/documents/${data.id}/replace-upload?unused=${!isUsed}`,
          modifiedData);
      } else response = yield request.post(`/v1/documents/${data.id}/replace-document?unused=${!isUsed}`, modifiedData);
    } else if (data.file || isFromDSR) {
      response = yield request.postDocument(`/v1/documents/upload`, modifiedData, isVendor &&
      {
        headers: {
          [config.login.vendorToken.httpHeader]: registry.get('storage')
            .getItem(config.login.vendorToken.storage.key)
        }
      }, !isVendor);
    } else response = yield request.post(`/v1/documents/add-document`, modifiedData);
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        if (!isReplace) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: NotificationMessages[isEdit],
              type: 'success'
            }
          });
        }
        const items = response.body;
        const modifiedItem = transformDocumentItem(items);
        // Handle consistency in the front end.
        if (isFromDSR) {
          yield put({ type: 'DSR:UPLOAD:DOCUMENT:SUCCESS', attachment: modifiedItem });
        } else if (isEdit) {
          yield put({ type: `${reducerType}:DOCUMENTS:UPSERT:SUCCESS`, documentData: modifiedItem });
          yield updateDocumentItems({
            type: 'edit',
            data: Object.assign({}, data, modifiedItem),
            source
          });
        } else if (isReplace) {
          modifiedData = {
            ...modifiedData,
            contentType: 'unknown'
          };
          yield put({ type: `${reducerType}:DOCUMENTS:UPSERT:SUCCESS`, documentData: modifiedItem });
          if (isUsed) {
            yield put({
              type: 'MASTER_DATA_ITEM:CREATE:JOB',
              data: modifiedData,
              actionType: 'Edit',
              entityType: 'Document',
              newEntityId: response.body.id
            });
          } else {
            yield updateDocumentItems({
              type: 'replace',
              data: Object.assign({}, data, modifiedItem),
              id: data.id,
              source
            });
          }
        } else {
          yield put({
            type: `${reducerType}:DOCUMENTS:UPSERT:SUCCESS`,
            documentData: modifiedItem,
            position: 0
          });
          if (!isVendor) {
            yield fetchDocuments(
              {
                documentData: modifiedItem,
                isPersistedData: true
              }
            );
          }
        }

        break;
      }
      case 409: yield put({
        type: `${reducerType}:DOCUMENTS:UPSERT:FAIL`,
        error: response.body.msg
      });
        break;
      case 423: yield put({
        type: 'NOTIFIER:NOTIFY',
        notification: {
          content: response.body.msg,
          type: 'warning'
        }
      });
        yield put({
          type: `${reducerType}:DOCUMENTS:UPSERT:FAIL`,
          error: response.body.msg
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      default: {
        yield put({
          type: `${reducerType}:DOCUMENTS:UPSERT:FAIL`,
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    if (err.status === 502 && err.message === 'Document seems to be infected') {
      yield put({
        type: 'NOTIFIER:NOTIFY',
        notification: {
          content: documentInfected,
          type: 'error'
        }
      });
    } else {
      yield handleServiceDown(err, 'document');
    }
    yield put({ type: `${reducerType}:DOCUMENTS:UPSERT:FAIL`, error: err.message });
  }
}


export function* fetchDocumentData(action) {
  const config = registry.get('config');
  const reducerType = action.isVendor ? 'VENDOR' : 'MASTERDATA';
  yield put({ type: `${reducerType}:DOCUMENTS:ITEM:FETCH:INIT` });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/documents/${action.id}`, null, action.isVendor && {
          headers: {
            [config.login.vendorToken.httpHeader]: registry.get('storage')
              .getItem(config.login.vendorToken.storage.key)
          }
        }, !action.isVendor);
      switch (response.status) {
        case 200: {
          yield put({
            type: `${reducerType}:DOCUMENTS:ITEM:FETCH:SUCCESS`,
            documentData: response.body
          });
          break;
        }
        case 404: {
          yield put({ type: `${reducerType}:DOCUMENTS:ITEM:FETCH:FAIL`, error: response.body.msg });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'documents');
      yield put({ type: `${reducerType}:DOCUMENTS:ITEM:FETCH:FAIL`, error: err.message });
    }
  }
}

export function* deleteDocument(action) {
  const config = registry.get('config');
  const { dataItemId, source = '', isVendor } = action;
  const reducerType = isVendor ? 'VENDOR' : 'MASTERDATA';
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/documents/${dataItemId}`, isVendor && {
      headers: {
        [config.login.vendorToken.httpHeader]: registry.get('storage')
          .getItem(config.login.vendorToken.storage.key)
      }
    }, !action.isVendor);
    switch (response.status) {
      case 204: {
        if (source !== 'settings') {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: deleteSuccessError('document'),
              type: 'success'
            }
          });
        }
        yield put({ type: `${reducerType}:DOCUMENTS:ITEM:DELETE:SUCCESS` });
        // Handle consistency in the front end.
        yield updateDocumentItems({
          type: 'delete',
          documentId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'MASTERDATA:DOCUMENTS:DELETE:FAIL', error: err.message });
  }
}

export function* downloadDocument(action) {
  const request = registry.get('request');
  const { isDownloadFromRecords = false } = action;
  try {
    const response = isDownloadFromRecords ?
      yield request.getDocument(`/v1/documents/records/${action.itemId}/download`) :
      yield request.getDocument(`/v1/documents/${action.itemId}/download`);
    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.attachmentDownloaded,
            type: 'success'
          }
        });
        break;
      }
      case 404: {
        const error = errortranslations.attachmentUnavailable;
        yield put({ type: 'DOCUMENT:DOWNLOAD:FAIL', error });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: errortranslations.attachmentUnavailable,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'DOCUMENT:DOWNLOAD:FAIL', error: err.message });
  }
}
const NotificationMessages = {
  true: notificationtranslations.documentsUpdateSuccess,
  false: notificationtranslations.documentsCreated
};

function* updateDocumentItems(action) {
  const documentState = yield select(state => state.environment.documents);
  let position = null;
  let items = documentState ? documentState
    .get('items') : Immutable.List();
  let newItems = documentState ? documentState
    .get('newItems') : Immutable.List();
  const { type, data, documentId, id } = action;
  if (type === 'delete') {
    items = updateDataItems(items, type, documentId);
    newItems = updateDataItems(newItems, type, documentId);

    position = documentState.get('position') - 1;
  } else if (type === 'edit') {
    const modifiedItem = transformDocumentItem(data);
    items = updateDataItems(items, type, data.id, '', modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, '', modifiedItem);
  } else if (type === 'replace') {
    const modifiedItem = transformDocumentItem(data);
    items = updateData(items, id, modifiedItem);
    newItems = updateData(newItems, data.id, modifiedItem);
  }

  yield put({ type: 'MASTERDATA:DOCUMENTS:LIST:UPDATE:SUCCESS', items, newItems, position });
}

const transformDocumentItem = item => ({
  ...item,
  name: item.name,
  key: `${item.name}`
});

const updateData = (items, currentId, modifiedItem) => {
  const index = items.findIndex(item => item.id === currentId);
  if (index !== -1) {
    const updatedItems = items.set(index, modifiedItem);
    return updatedItems;
  }
  return items;
};
