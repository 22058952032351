import Immutable from 'immutable';

const getLocationFromURL = (index) => {
  const url = window.location.hash;
  const startIndex = index || url.lastIndexOf('/') + 1;
  const lastURLIndex = url.indexOf('?');
  return url.substring(
    startIndex,
    lastURLIndex === -1 ? url.length : lastURLIndex
  );
};

const defaultState = Immutable.fromJS({
  currentNavItem: getLocationFromURL() || 'dashboard',
  navItems: [
    {
      id: 'messagecenter',
      routes: ['/messagecenter']
    },
    {
      id: 'dashboard',
      routes: ['/dashboard', '/']
    },
    {
      id: 'environment',
      routes: ['/environment']
    },
    {
      id: 'privacyrecords',
      routes: ['/privacyrecords']
    },
    {
      id: 'reports',
      routes: ['/reports']
    },
    {
      id: 'dsr',
      routes: ['/dsr']
    },
    {
      id: 'admin',
      routes: ['/admin']
    },
    {
      id: 'partner',
      routes: ['/partner']
    },
    {
      id: 'home',
      routes: ['/home']
    },
    {
      id: 'tenantconfiguration',
      routes: ['/tenant-configuration']
    }
  ]
});

export default (state = defaultState, action) => {
  let searchedNavItem = null;
  let pathname = null;
  let index = -1;
  const currentURL = getLocationFromURL(1);

  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      if (!action.payload) {
        throw new Error(`action.payload is missing`);
      }
      if (!action.payload.location.pathname) {
        throw new Error(`action.payload.pathname is missing`);
      }

      // When a redirect is fired for an invalid url, location_change for the
      // invalid url is also fired which results in highlighting the wrong navItem.
      if (currentURL !== action.payload.location.pathname) {
        return state;
      }

      // Highlight the navbar items for inner pages as well
      index = action.payload.location.pathname.indexOf('/', 1);
      if (index !== -1) {
        pathname = action.payload.location.pathname.substring(0, index);
      } else {
        ({ pathname } = action.payload.location);
      }
      searchedNavItem = state
        .get('navItems')
        .findEntry(
          (item) => item.get('routes').indexOf(pathname.toLowerCase()) > -1
        );
      searchedNavItem = searchedNavItem && searchedNavItem.pop();
      if (searchedNavItem) {
        return state.set('currentNavItem', searchedNavItem.get('id'));
      } else if (pathname === '/settings' || pathname === '/data-library') {
        return state.set('currentNavItem', '');
      }
      return state;
    }
    default:
      return state;
  }
};
