import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';

import { renderLinkGroup } from '../../../renderers/renderers';

const defaultStyle = {
  rootStyle: {
    margin: 10
  },
  headerStyle: {
    color: '#9b9b9b',
    fontSize: '13px',
    marginLeft: '16px'
  }
};

const LinkGroupList = (props) => {
  const { header, fontStyle, content, initialize } = props;

  useEffect(() => {
    initialize(content);
  }, [content]);

  return (
    <div style={defaultStyle.rootStyle}>
      <div style={defaultStyle.headerStyle}>{header}</div>
      <form>
        <div>
          <FieldArray
            name="links"
            type="links"
            isCardExpanded={true}
            component={renderLinkGroup}
            fontStyle={fontStyle}
            selectedItems={content.links}
            {...props}
          />
        </div>
      </form>
    </div>
  );
};

LinkGroupList.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  header: PropTypes.node,
  showCardTitle: PropTypes.bool,
  handleRowClick: PropTypes.func,
  fontStyle: PropTypes.shape({}),
  initialize: PropTypes.func.isRequired
};

LinkGroupList.defaultProps = {
  header: null,
  showCardTitle: true,
  fontStyle: {},
  handleRowClick: (e) => e,
  content: []
};

const LinkGroupListFormWrapper = reduxForm({
  form: 'ViewLinksForm'
})(LinkGroupList);

export default LinkGroupListFormWrapper;
