import {
  getDefaultRequestParams,
  transformDefaultRequestData,
  transformDefaultResponseData
} from '../../saga-utils';
import {
  numOfDataItemsMapping,
  transformDiffItems,
  getDefaultRenderData
} from '../../common-utils';

const getNotificationRenderData = (data, key) => {
  const transformedData = data.sent
    ? {
      ...data,
      reasonNotToNotify: undefined
    }
    : {
      ...data,
      date: undefined,
      reference: '',
      [key]: [],
      text: ''
    };

  return transformedData;
};

export const getUpdatedProperty = (action, recordId, userId) => {
  const { data, property } = action;

  const renderData = getDefaultRenderData(action);

  switch (property) {
    case 'awareness':
    case 'breachGrounds': {
      return {
        renderData
      };
    }
    case 'numOfDataSubjects':
    case 'numOfPersonalDataRecords': {
      const requestData = Object.assign({}, renderData);
      requestData[property] = numOfDataItemsMapping[data];
      return {
        renderData
      };
    }
    case 'supervisoryNotification': {
      const transformedData = getNotificationRenderData(
        data,
        'supervisoryAuthorities'
      );
      return {
        renderData: {
          supervisoryNotification: transformedData
        },
        url: `/records/breaches/${recordId}/ChangeSupervisoryNotification`
      };
    }
    case 'dataSubjectNotification': {
      const transformedData = getNotificationRenderData(
        data,
        'dataSubjectCategories'
      );
      return {
        renderData: {
          dataSubjectNotification: transformedData
        },
        url: `/records/breaches/${recordId}/ChangeDataSubjectNotification`
      };
    }

    default:
      return getDefaultRequestParams(
        action,
        renderData,
        recordId,
        'breaches',
        userId
      );
  }
};

export const transformRequestData = (requestData, isGlobal) => {
  const reqData = Object.assign({}, requestData);
  if (
    requestData.specifiNumOfDataSubjects &&
    reqData.numOfDataSubjects === -2
  ) {
    reqData.numOfDataSubjects = {
      min: reqData.specifiNumOfDataSubjects,
      max: reqData.specifiNumOfDataSubjects
    };
    delete reqData.specifiNumOfDataSubjects;
  } else {
    reqData.numOfDataSubjects =
      reqData.numOfDataSubjects && reqData.numOfDataSubjects.min !== undefined
        ? reqData.numOfDataSubjects
        : numOfDataItemsMapping[reqData.numOfDataSubjects];
  }
  reqData.numOfPersonalDataRecords =
    numOfDataItemsMapping[reqData.numOfPersonalDataRecords];
  delete reqData.links;
  delete reqData.retentionTerms;

  return transformDefaultRequestData(reqData, isGlobal); // Transform records request data;
};

export function* transformResponseData(responseData, currentUser) {
  const respData = Object.assign({}, responseData);
  respData.numOfPersonalDataRecords =
    (respData.numOfPersonalDataRecords &&
      respData.numOfPersonalDataRecords.min) ||
    0;
  if (respData.supervisoryNotification) {
    const {
      text = '',
      reference = '',
      date = null,
      supervisoryAuthorities = []
    } = respData.supervisoryNotification;
    const transformedSupervisoryAuthorities = transformDiffItems(
      supervisoryAuthorities
    );
    respData.supervisoryNotification = {
      ...respData.supervisoryNotification,
      text,
      reference,
      date,
      supervisoryAuthorities: transformedSupervisoryAuthorities
    };
  }

  if (respData.dataSubjectNotification) {
    const {
      text = '',
      reference = '',
      date = null,
      dataSubjectCategories = []
    } = respData.dataSubjectNotification;
    const transformedDataSubjectCategories = transformDiffItems(
      dataSubjectCategories
    );
    respData.dataSubjectNotification = {
      ...respData.dataSubjectNotification,
      text,
      reference,
      date,
      dataSubjectCategories: transformedDataSubjectCategories
    };
  }

  delete respData.tasks;
  return yield transformDefaultResponseData(respData, currentUser, null); // Transform records response data
}

// Remove others from item values and add value of other to 'values'
export const transformBreachItems = (data = { values: [] }) => {
  const { values, other } = data;
  const transformedValues = [...values];

  const index = transformedValues.findIndex(
    (item) =>
      item === 'Others' ||
      item === 'breach_consequences_others' ||
      item === 'breach_nature_others'
  );

  if (index !== -1) {
    transformedValues.splice(index, 1, other);
  }

  return transformedValues;
};
