/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  addTranslations,
  editTranslations
} from '@packages/utils/actionTranslations';
import { addKeyToDataSource } from '@packages/utils/common-utils';

import DataSource from '.';

export const DataSourceDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.onSaveDataSource) {
      const modifiedItem = {
        ...selectedItem,
        key: addKeyToDataSource({
          name: selectedItem.name,
          organisation: selectedItem?.organisation,
          dataStorageCountry: selectedItem?.dataStorageCountry
        })
      };
      props.onSaveDataSource({ value: modifiedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };
  return (
    <CommonDialog
      id="data-source-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={
        props.isEdit
          ? editTranslations('editDataSource')
          : addTranslations('addDataSource')
      }
    >
      <DataSource
        id="data-source"
        isEdit={props.isEdit}
        isUsed={props.isUsed}
        usage={props.usage}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        isNewItem={props.isNewItem}
        refreshList={props.refreshList}
        isTagEdit={props.isTagEdit}
        source={props.source}
        hideTags={props.hideTags}
      />
    </CommonDialog>
  );
};

DataSourceDialog.propTypes = {
  isNewItem: PropTypes.bool,
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSaveDataSource: PropTypes.func,
  onRequestClose: PropTypes.func,
  dataItemId: PropTypes.string,
  source: PropTypes.string,
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  inputValue: PropTypes.shape({}),
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

DataSourceDialog.defaultProps = {
  isNewItem: false,
  open: false,
  isEdit: false,
  isUsed: false,
  onSaveDataSource: (e) => e,
  onRequestClose: (e) => e,
  source: '',
  inputValue: {},
  usage: {},
  dataItemId: '',
  isTagEdit: false,
  hideTags: false
};

export default DataSourceDialog;
