import dsrTranslations from '../dsr/dsrTranslations';
import { commonTranslations } from '../privacy-record-detail/recordTranslations';


export const filterMenu = [
  {
    label: dsrTranslations.dsrRegistration,
    key: 'Registration',
    enable: true,
    showIcon: false,
    defaultFilter: 'Registration_Registration'
  },
  {
    label: dsrTranslations.validateRequest,
    key: 'ValidateRequest',
    enable: false,
    subMenu: [],
    showIcon: false
  },
  {
    label: dsrTranslations.requestAction,
    key: 'RequestAction',
    enable: false,
    showIcon: false,
    defaultFilter: 'RequestAction_RequestAction'
  },
  {
    label: dsrTranslations.approval,
    key: 'Review',
    enable: false,
    showIcon: false,
    defaultFilter: 'Review_Review'
  }
];

export const validationSubmenus = {
  GDPR: [
    {
      key: 'Access_ValidateRequest',
      value: 'Access',
      label: dsrTranslations.Access,
      showIcon: false
    },
    {
      key: 'Rectification_ValidateRequest',
      value: 'Rectification',
      label: dsrTranslations.Rectification,
      showIcon: false
    },
    {
      key: 'Erasure_ValidateRequest',
      value: 'Erasure',
      label: dsrTranslations.Erasure,
      showIcon: false
    },
    {
      key: 'Restriction_ValidateRequest',
      value: 'Restriction',
      label: dsrTranslations.Restriction,
      showIcon: false
    },
    {
      key: 'DataPortability_ValidateRequest',
      value: 'DataPortability',
      label: dsrTranslations.DataPortability,
      showIcon: false
    },
    {
      key: 'Objection_ValidateRequest',
      value: 'Objection',
      label: dsrTranslations.Objection,
      showIcon: false
    },
    {
      key: 'AutomatedDecisionMaking_ValidateRequest',
      value: 'AutomatedDecisionMaking',
      label: dsrTranslations.AutomatedDecisionMaking,
      showIcon: false
    }
  ],
  CCPA: [
    {
      showIcon: false,
      key: 'RightToDeletePersonalInformation_ValidateRequest',
      label: dsrTranslations.RightToDeletePersonalInformation,
      value: 'RightToDeletePersonalInformation'
    },
    {
      showIcon: false,
      key: 'RightToCorrectInaccuratePersonalInformation_ValidateRequest',
      label: dsrTranslations.RightToCorrectInaccuratePersonalInformation,
      value: 'RightToCorrectInaccuratePersonalInformation'
    },
    {
      showIcon: false,
      key: 'RightToAccessPersonalInformation_ValidateRequest',
      label: dsrTranslations.RightToAccessPersonalInformation,
      value: 'RightToAccessPersonalInformation'
    },
    {
      showIcon: false,
      key: 'RightToKnowSaleAndSharingOfPersonalInformation_ValidateRequest',
      label: dsrTranslations.RightToKnowSaleAndSharingOfPersonalInformation,
      value: 'RightToKnowSaleAndSharingOfPersonalInformation'
    },
    {
      showIcon: false,
      key: 'RightToOptoutFromSaleAndSharingOfPersonalInformation_ValidateRequest',
      label:
        dsrTranslations.RightToOptoutFromSaleAndSharingOfPersonalInformation,
      value: 'RightToOptoutFromSaleAndSharingOfPersonalInformation'
    },
    {
      showIcon: false,
      key: 'RightToLimitUseAndDisclosureOfSensitivePersonalInformation_ValidateRequest',
      label:
        dsrTranslations.RightToLimitUseAndDisclosureOfSensitivePersonalInformation,
      value: 'RightToLimitUseAndDisclosureOfSensitivePersonalInformation'
    }
  ]
};

export const requestTypes = {
  GDPR: [
    {
      key: dsrTranslations.Access,
      label: dsrTranslations.Access,
      data: 'Access'
    },
    {
      key: dsrTranslations.Rectification,
      label: dsrTranslations.Rectification,
      data: 'Rectification'
    },
    {
      key: dsrTranslations.Erasure,
      label: dsrTranslations.Erasure,
      data: 'Erasure'
    },
    {
      key: dsrTranslations.Restriction,
      label: dsrTranslations.Restriction,
      data: 'Restriction'
    },
    {
      key: dsrTranslations.DataPortability,
      label: dsrTranslations.DataPortability,
      data: 'DataPortability'
    },
    {
      key: dsrTranslations.Objection,
      label: dsrTranslations.Objection,
      data: 'Objection'
    },
    {
      key: dsrTranslations.AutomatedDecisionMaking,
      label: dsrTranslations.AutomatedDecisionMaking,
      data: 'AutomatedDecisionMaking'
    }
  ],
  CCPA: [
    {
      key: dsrTranslations.RightToDeletePersonalInformation,
      label: dsrTranslations.RightToDeletePersonalInformation,
      data: 'RightToDeletePersonalInformation'
    },
    {
      key: dsrTranslations.RightToCorrectInaccuratePersonalInformation,
      label: dsrTranslations.RightToCorrectInaccuratePersonalInformation,
      data: 'RightToCorrectInaccuratePersonalInformation'
    },
    {
      key: dsrTranslations.RightToAccessPersonalInformation,
      label: dsrTranslations.RightToAccessPersonalInformation,
      data: 'RightToAccessPersonalInformation'
    },
    {
      key: dsrTranslations.RightToKnowSaleAndSharingOfPersonalInformation,
      label: dsrTranslations.RightToKnowSaleAndSharingOfPersonalInformation,
      data: 'RightToKnowSaleAndSharingOfPersonalInformation'
    },
    {
      key: dsrTranslations.RightToOptoutFromSaleAndSharingOfPersonalInformation,
      label:
        dsrTranslations.RightToOptoutFromSaleAndSharingOfPersonalInformation,
      data: 'RightToOptoutFromSaleAndSharingOfPersonalInformation'
    },
    {
      key: dsrTranslations.RightToLimitUseAndDisclosureOfSensitivePersonalInformation,
      label:
        dsrTranslations.RightToLimitUseAndDisclosureOfSensitivePersonalInformation,
      data: 'RightToLimitUseAndDisclosureOfSensitivePersonalInformation'
    }
  ]
};

export const dsrStatusTypes = [
  {
    label: dsrTranslations.dsr_status_created,
    value: 'dsr_status_created'
  },
  {
    label: dsrTranslations.dsr_status_in_progress,
    value: 'dsr_status_in_progress'
  },
  {
    label: dsrTranslations.dsr_status_waiting_on_action,
    value: 'dsr_status_waiting_on_action'
  },
  {
    label: dsrTranslations.dsr_status_waiting_on_approval,
    value: 'dsr_status_waiting_on_approval'
  },
  {
    label: dsrTranslations.dsr_status_completed,
    value: 'dsr_status_completed'
  },
  {
    label: dsrTranslations.dsr_status_waiting_on_cancel_approval,
    value: 'dsr_status_waiting_on_cancel_approval'
  },
  {
    label: dsrTranslations.dsr_status_cancelled,
    value: 'dsr_status_cancelled'
  },
  {
    label:
      dsrTranslations.dsr_status_waiting_requester_to_be_informed_for_complete,
    value: 'dsr_status_waiting_requester_to_be_informed_for_complete'
  }
];

export const requestFormat = [
  {
    label: dsrTranslations.email,
    value: 'dsr_format_email'
  },
  {
    label: dsrTranslations.phone,
    value: 'dsr_format_phone'
  },
  {
    label: dsrTranslations.post,
    value: 'dsr_format_post'
  },
  {
    label: dsrTranslations.inPerson,
    value: 'dsr_format_in_person'
  },
  {
    label: dsrTranslations.fax,
    value: 'dsr_format_fax'
  },
  {
    label: dsrTranslations.onlineChat,
    value: 'dsr_online_chat'
  },
  {
    label: dsrTranslations.onlineForm,
    value: 'dsr_online_form'
  }
];

export const identityVerificationMethods = [
  {
    label: dsrTranslations.dsr_verification_method_by_login,
    value: 'dsr_verification_method_by_login'
  },
  {
    label: dsrTranslations.dsr_verification_method_mfa,
    value: 'dsr_verification_method_mfa'
  },
  {
    label: dsrTranslations.dsr_verification_method_showing_id,
    value: 'dsr_verification_method_showing_id'
  },
  {
    label: commonTranslations.other,
    value: 'other'
  }
];

export const getTranslationForRequestorDetails = (key) => dsrTranslations[key];

const erasureRequirements = [
  {
    label: dsrTranslations.erasure_req_data_not_necessary,
    value: 'dsr_erasure_requirement_personal_data_no_longer_necessary'
  },
  {
    label: dsrTranslations.erasure_req_withdraws_consent,
    value: 'dsr_erasure_requirement_withdraws_consent'
  },
  {
    label: dsrTranslations.erasure_req_objects_on_legal_ground,
    value: 'dsr_erasure_requirement_objects_on_legal_ground'
  },
  {
    label: dsrTranslations.erasure_requirement_direct_marketing,
    value: 'dsr_erasure_requirement_direct_marketing'
  },
  {
    label: dsrTranslations.erasure_req_unlawful_data_processing,
    value: 'dsr_erasure_requirement_unlawful_personal_data_processing'
  },
  {
    label: dsrTranslations.erasure_req_legal_obligation_for_erasure,
    value: 'dsr_erasure_requirement_legal_obligation_for_erasure'
  },
  {
    label: dsrTranslations.erasure_req_child_data_without_parent_consent,
    value: 'dsr_erasure_requirement_child_data_without_parent_consent'
  }
];

const dataProtabilityRequirements = [
  {
    label: dsrTranslations.dataportability_req_knowingly_actively_supplied_data,
    value: 'dsr_data_portability_requirement_knowingly_actively_supplied_data'
  },
  {
    label: dsrTranslations.dataportability_req_consent_or_contract_legal_ground,
    value: 'dsr_data_portability_requirement_consent_or_contract_legal_ground'
  },
  {
    label: dsrTranslations.dataportability_req_automated_processing,
    value: 'dsr_data_portability_requirement_automated_processing'
  }
];

const accessRequirements = [
  {
    label: dsrTranslations.access_requirement_personal_data_processed,
    value: 'dsr_access_requirement_personal_data_processed'
  },
  {
    label: dsrTranslations.access_requirement_access_to_personal_data,
    value: 'dsr_access_requirement_access_to_personal_data'
  },
  {
    label: dsrTranslations.access_requirement_copy_of_personal_data,
    value: 'dsr_access_requirement_copy_of_personal_data'
  },
  {
    label: dsrTranslations.access_requirement_purpose_of_processing,
    value: 'dsr_access_requirement_purpose_of_processing'
  },
  {
    label: dsrTranslations.access_requirement_categories_of_personal_data,
    value: 'dsr_access_requirement_categories_of_personal_data'
  },
  {
    label:
      dsrTranslations.access_requirement_recipient_categories_of_personal_data,
    value: 'dsr_access_requirement_recipient_categories_of_personal_data'
  },
  {
    label: dsrTranslations.access_requirement_retention_period_of_personal_data,
    value: 'dsr_access_requirement_retention_period_of_personal_data'
  },
  {
    label:
      dsrTranslations.access_requirement_rectification_erasure_restriction_objection,
    value: 'dsr_access_requirement_rectification_erasure_restriction_objection'
  },
  {
    label:
      dsrTranslations.access_requirement_complaint_to_supervisory_authority,
    value: 'dsr_access_requirement_complaint_to_supervisory_authority'
  },
  {
    label: dsrTranslations.access_requirement_source_of_personal_data,
    value: 'dsr_access_requirement_source_of_personal_data'
  },
  {
    label:
      dsrTranslations.access_requirement_automated_decision_making_and_profiling,
    value: 'dsr_access_requirement_automated_decision_making_and_profiling'
  },
  {
    label: dsrTranslations.access_requirement_transfer_outside_eea,
    value: 'dsr_access_requirement_transfer_outside_eea'
  }
];

const restrictionRequirements = [
  {
    label: dsrTranslations.restriction_req_personal_data_accuracy_verified,
    value: 'dsr_restriction_requirement_personal_data_accuracy_verified'
  },
  {
    label: dsrTranslations.restriction_req_unlawful_personal_data_processing,
    value: 'dsr_restriction_requirement_unlawful_personal_data_processing'
  },
  {
    label:
      dsrTranslations.restriction_req_restrict_personal_data_for_legal_claim,
    value: 'dsr_restriction_requirement_restrict_personal_data_for_legal_claim'
  },
  {
    label:
      dsrTranslations.restriction_req_object_to_processing_on_legal_grounds,
    value: 'dsr_restriction_requirement_object_to_processing_on_legal_grounds'
  }
];

const objectionRequirements = [
  {
    label: dsrTranslations.objection_req_direct_marketing,
    value: 'dsr_objection_requirement_direct_marketing'
  },
  {
    label: dsrTranslations.objection_req_public_task_or_official_authority,
    value: 'dsr_objection_requirement_public_task_or_official_authority'
  },
  {
    label:
      dsrTranslations.objection_req_archiving_research_statistics_legal_ground,
    value:
      'dsr_objection_requirement_archiving_research_statistics_legal_ground'
  }
];

const automatedRequirements = [
  {
    label:
      dsrTranslations.automated_decision_requirement_asks_controller_explanation,
    value: 'dsr_automated_decision_requirement_asks_controller_explanation'
  },
  {
    label:
      dsrTranslations.automated_decision_requirement_requests_human_intervention,
    value: 'dsr_automated_decision_requirement_requests_human_intervention'
  },
  {
    label:
      dsrTranslations.automated_decision_requirement_requests_to_express_their_view,
    value: 'dsr_automated_decision_requirement_requests_to_express_their_view'
  },
  {
    label:
      dsrTranslations.automated_decision_requirement_challenges_the_decision,
    value: 'dsr_automated_decision_requirement_challenges_the_decision'
  }
];

const rectificationRequirements = [
  {
    label: dsrTranslations.rectification_requirement_personal_data_inaccurate,
    value: 'dsr_rectification_requirement_personal_data_inaccurate'
  },
  {
    label: dsrTranslations.rectification_requirement_personal_data_incomplete,
    value: 'dsr_rectification_requirement_personal_data_incomplete'
  }
];

export const getRequirementsByType = (requestType) => {
  switch (requestType) {
    case 'Erasure':
      return erasureRequirements;
    case 'DataPortability':
      return dataProtabilityRequirements;
    case 'Access':
      return accessRequirements;
    case 'Restriction':
      return restrictionRequirements;
    case 'Objection':
      return objectionRequirements;
    case 'AutomatedDecisionMaking':
      return automatedRequirements;
    case 'Rectification':
      return rectificationRequirements;
    default:
      return [];
  }
};

// GDPR Exceptions

const erasureExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_erasure_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_erasure_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.erasure_exc_right_to_expression_freedom,
    value: 'dsr_erasure_exception_right_to_expression_freedom'
  },
  {
    label: dsrTranslations.erasure_exc_legal_obligation_compliance,
    value: 'dsr_erasure_exception_legal_obligation_compliance'
  },
  {
    label: dsrTranslations.erasure_exception_perform_public_task,
    value: 'dsr_erasure_exception_perform_public_task'
  },
  {
    label: dsrTranslations.erasure_exc_public_health_legal_ground,
    value: 'dsr_erasure_exception_public_health_legal_ground'
  },
  {
    label:
      dsrTranslations.erasure_exc_archiving_research_statistics_legal_ground,
    value: 'dsr_erasure_exception_archiving_research_statistics_legal_ground'
  },
  {
    label: dsrTranslations.erasure_exc_handling_legal_claims_legal_ground,
    value: 'dsr_erasure_exception_handling_legal_claims_legal_ground'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const dataPortabilityExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_data_portability_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_data_portability_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.dataportability_exc_impact_the_rights_of_others,
    value: 'dsr_data_portability_exception_impact_the_rights_of_others'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const accessExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_access_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_access_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.access_exc_impact_the_rights_of_others,
    value: 'dsr_access_exception_impact_the_rights_of_others'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const restrictionExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_restriction_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_restriction_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.restriction_exp_concerning_personal_data_storage,
    value: 'dsr_restriction_exception_concerning_personal_data_storage'
  },
  {
    label: dsrTranslations.restriction_exc_already_consented,
    value: 'dsr_restriction_exception_already_consented'
  },
  {
    label: dsrTranslations.restriction_exc_handling_legal_claims_legal_ground,
    value: 'dsr_restriction_exception_handling_legal_claims_legal_ground'
  },
  {
    label: dsrTranslations.restriction_exc_protection_of_others_right,
    value: 'dsr_restriction_exception_protection_of_others_right'
  },
  {
    label: dsrTranslations.restriction_exception_public_interest_of_eu,
    value: 'dsr_restriction_exception_public_interest_of_eu'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const objectionExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_objection_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_objection_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.objection_exc_legitimate_grounds_outweigh_freedom,
    value: 'dsr_objection_exception_legitimate_grounds_outweigh_freedom'
  },
  {
    label:
      dsrTranslations.objection_exception_necessary_for_public_interest_task,
    value: 'dsr_objection_exception_necessary_for_public_interest_task'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const automatedExeptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_automated_decision_exception_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_automated_decision_exception_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

const rectificationExceptions = [
  {
    label: dsrTranslations.exc_harassing_organisation,
    value: 'dsr_rectification_harassing_organisation'
  },
  {
    label: dsrTranslations.exc_repetition_of_previous_request,
    value: 'dsr_rectification_repetition_of_previous_request'
  },
  {
    label: dsrTranslations.other,
    value: 'other'
  }
];

// CCPA exceptions

const commonExceptions = [
  {
    label: dsrTranslations.ccpa_exc_comply_with_law_agencies_or_court_order,
    value: 'ccpa_dsr_common_comply_with_law_agencies_or_court_order'
  },
  {
    label:
      dsrTranslations.ccpa_exc_comply_to_inquiry_investigation_subpoena_or_summons,
    value: 'ccpa_dsr_common_comply_to_inquiry_investigation_subpoena_or_summons'
  },
  {
    label: dsrTranslations.ccpa_exc_cooperate_with_law_enforcement_agency,
    value: 'ccpa_dsr_common_cooperate_with_law_enforcement_agency'
  },
  {
    label: dsrTranslations.ccpa_exc_cooperate_with_government_agency,
    value: 'ccpa_dsr_common_cooperate_with_government_agency'
  },
  {
    label: dsrTranslations.ccpa_exc_exercise_or_defend_legal_claims,
    value: 'ccpa_dsr_common_exercise_or_defend_legal_claims'
  },
  {
    label: dsrTranslations.ccpa_exc_deidentified_or_aggregated_information,
    value: 'ccpa_dsr_common_deidentified_or_aggregated_information'
  },
  {
    label: dsrTranslations.ccpa_exc_commercial_conduct_outside_california,
    value: 'ccpa_dsr_common_commercial_conduct_outside_california'
  }
];

const rightToDeletePersonalInformationExceptions = [
  {
    label:
      dsrTranslations.ccpa_exc_personal_information_complete_transaction_contract,
    value:
      'ccpa_dsr_right_to_delete_personal_information_complete_transaction_contract'
  },
  {
    label:
      dsrTranslations.ccpa_exc_personal_information_ensure_security_integrity,
    value:
      'ccpa_dsr_right_to_delete_personal_information_ensure_security_integrity'
  },
  {
    label: dsrTranslations.ccpa_exc_personal_information_comply_calecpa,
    value: 'ccpa_dsr_right_to_delete_personal_information_comply_calecpa'
  },
  {
    label: dsrTranslations.ccpa_exc_delete_personal_impair_research,
    value: 'ccpa_dsr_right_to_delete_personal_impair_research'
  },
  {
    label: dsrTranslations.ccpa_exc_delete_solely_internal_use,
    value: 'ccpa_dsr_right_to_delete_solely_internal_use'
  },
  {
    label: dsrTranslations.ccpa_exc_delete_comply_legal_obligation,
    value: 'ccpa_dsr_right_to_delete_comply_legal_obligation'
  },
  ...commonExceptions
];

const rightToCorrectInaccuratePersonalInformationExceptions = [
  {
    label: dsrTranslations.ccpa_exc_personal_information_unverifiable_request,
    value:
      'ccpa_dsr_right_to_correct_inaccurate_personal_information_unverifiable_request'
  },
  ...commonExceptions
];
const rightToAccessPersonalInformationExceptions = [
  {
    label: dsrTranslations.ccpa_exc_access_personal_information_provided_twice,
    value: 'ccpa_dsr_right_to_access_personal_information_provided_twice'
  },
  {
    label:
      dsrTranslations.ccpa_exc_access_personal_information_unverifiable_request,
    value: 'ccpa_dsr_right_to_access_personal_information_unverifiable_request'
  },
  ...commonExceptions
];
const rightToKnowSaleAndSharingOfPersonalInformationExceptions = [
  {
    label: dsrTranslations.ccpa_exc_sharing_personal_information_provided_twice,
    value:
      'ccpa_dsr_right_to_know_sale_and_sharing_of_personal_information_provided_twice'
  },
  {
    label:
      dsrTranslations.ccpa_exc_sharing_personal_information_unverifiable_request,
    value:
      'ccpa_dsr_right_to_know_sale_and_sharing_of_personal_information_unverifiable_request'
  },
  ...commonExceptions
];
const rightToOptoutFromSaleAndSharingOfPersonalInformationExceptions = [
  {
    label: dsrTranslations.ccpa_exc_personal_information_no_sale_or_sharing,
    value:
      'ccpa_dsr_right_to_optout_from_sale_and_sharing_of_personal_information_no_sale_or_sharing'
  },
  {
    label:
      dsrTranslations.ccpa_exc_personal_information_intentional_use_or_disclosure,
    value:
      'ccpa_dsr_right_to_optout_from_sale_and_sharing_of_personal_information_intentional_use_or_disclosure'
  },
  {
    label:
      dsrTranslations.ccpa_exc_personal_information_altering_third_parties_of_optout,
    value:
      'ccpa_dsr_right_to_optout_from_sale_and_sharing_of_personal_information_altering_third_parties_of_optout'
  },
  {
    label: dsrTranslations.ccpa_exc_personal_information_change_of_control,
    value:
      'ccpa_dsr_right_to_optout_from_sale_and_sharing_of_personal_information_change_of_control'
  },
  ...commonExceptions
];
const rightToLimitUseAndDisclosureOfSensitivePersonalInformationExceptions = [
  {
    label:
      dsrTranslations.ccpa_exc_sensitive_personal_information_no_sale_or_sharing,
    value:
      'ccpa_dsr_right_to_limit_use_and_disclosure_of_sensitive_personal_information_no_sale_or_sharing'
  },
  {
    label:
      dsrTranslations.ccpa_exc_sensitive_personal_information_privileged_under_california_law,
    value:
      'ccpa_dsr_right_to_limit_use_and_disclosure_of_sensitive_personal_information_privileged_under_california_law'
  },
  ...commonExceptions
];



export const getExceptionsByType = (requestType) => {
  switch (requestType) {
    // GDPR requestTypes
    case 'Erasure':
      return erasureExceptions;
    case 'DataPortability':
      return dataPortabilityExceptions;
    case 'Access':
      return accessExceptions;
    case 'Restriction':
      return restrictionExceptions;
    case 'Objection':
      return objectionExceptions;
    case 'AutomatedDecisionMaking':
      return automatedExeptions;
    case 'Rectification':
      return rectificationExceptions;
    // CCPA requestTypes
    case 'RightToDeletePersonalInformation':
      return rightToDeletePersonalInformationExceptions;
    case 'RightToCorrectInaccuratePersonalInformation':
      return rightToCorrectInaccuratePersonalInformationExceptions;
    case 'RightToAccessPersonalInformation':
      return rightToAccessPersonalInformationExceptions;
    case 'RightToKnowSaleAndSharingOfPersonalInformation':
      return rightToKnowSaleAndSharingOfPersonalInformationExceptions;
    case 'RightToOptoutFromSaleAndSharingOfPersonalInformation':
      return rightToOptoutFromSaleAndSharingOfPersonalInformationExceptions;
    case 'RightToLimitUseAndDisclosureOfSensitivePersonalInformation':
      return rightToLimitUseAndDisclosureOfSensitivePersonalInformationExceptions;
    default:
      return [];
  }
};
export const exportQueryDefinition = {
  format: 'csv',
  selectedFields: [
    {
      id: 'privacyRecordNumber',
      label: 'privacy_record_number',
      name: 'privacyRecordNumber',
      recordTypes: ['privacyrecord'],
      valueMapper: {},
      valueType: 'STRING',
      key: {
        id: 'RecordList.id',
        defaultMessage: 'ID'
      }
    },
    {
      id: 'name',
      name: 'name',
      label: 'record_name',
      valueType: 'STRING',
      valueMapper: {},
      key: {
        id: 'Record.name',
        defaultMessage: 'Record name'
      }
    },
    {
      id: 'controllers',
      name: 'controllers',
      label: 'controllers',
      valueType: 'ARRAY',
      valueMapper: {
        jsonPath: '$.value.name'
      },
      key: {
        id: 'Record.controllers',
        defaultMessage: 'Controllers'
      }
    },
    {
      id: 'processors',
      label: 'processors',
      name: 'processors',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        jsonPath: '$.value.name'
      },
      valueType: 'ARRAY',
      key: {
        id: 'Record.processors',
        defaultMessage: 'Processors'
      }
    },
    {
      id: 'purposes',
      label: 'purposes',
      name: 'purposes',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        jsonPath: '$.value.name'
      },
      valueType: 'ARRAY',
      key: {
        id: 'Record.purposes',
        defaultMessage: 'Purposes'
      }
    },
    {
      id: 'processingGrounds',
      name: 'processingGrounds',
      label: 'processing_grounds',
      valueType: 'ARRAY',
      valueMapper: {
        template: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${JURISDICTION} ${LAW} ${ARTICLE}',
          placeHolders: [
            {
              jsonPath: '$.value.article',
              key: 'ARTICLE'
            },
            {
              jsonPath: '$.value.jurisdiction.id',
              key: 'JURISDICTION'
            },
            {
              jsonPath: '$.value.lawAbbreviation',
              key: 'LAW'
            }
          ],
          traverseBy: 'LIST'
        }
      },
      key: {
        id: 'Record.processingGrounds',
        defaultMessage: 'Processing grounds'
      }
    },
    {
      id: 'dataRecipients',
      label: 'data_recipients',
      name: 'dataRecipients',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        jsonPath: '$.value.name'
      },
      valueType: 'ARRAY',
      key: {
        id: 'Records.dataRecipients',
        defaultMessage: 'Data recipients'
      }
    },
    {
      id: 'dataRecipientCategories',
      label: 'data_recipient_categories',
      name: 'dataRecipientCategories',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        jsonPath: '$.value.name'
      },
      valueType: 'ARRAY',
      key: {
        id: 'Records.dataRecipientCategories',
        defaultMessage: 'Data recipient categories'
      }
    },
    {
      id: 'transferGrounds',
      label: 'transfer_grounds',
      name: 'transferGrounds',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        template: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${JURISDICTION} ${LAW} ${ARTICLE}',
          placeHolders: [
            {
              jsonPath: '$.value.article',
              key: 'ARTICLE'
            },
            {
              jsonPath: '$.value.jurisdiction.id',
              key: 'JURISDICTION'
            },
            {
              jsonPath: '$.value.lawAbbreviation',
              key: 'LAW'
            }
          ],
          traverseBy: 'LIST'
        }
      },
      valueType: 'ARRAY',
      key: {
        id: 'Records.transferGrounds',
        defaultMessage: 'Transfer grounds'
      }
    },
    {
      id: 'retentionTerms',
      label: 'retention_terms',
      name: 'retentionTerms',
      recordTypes: ['privacyrecord'],
      valueMapper: {},
      transformType: 'period',
      valueType: 'ARRAY',
      key: {
        id: 'Record.retentionTerms',
        defaultMessage: 'retention terms'
      }
    },
    {
      id: 'dataSources',
      label: 'data_sources',
      name: 'dataSources',
      recordTypes: ['privacyrecord'],
      valueMapper: {
        jsonPath: '$.value.name'
      },
      valueType: 'ARRAY',
      key: {
        id: 'Record.dataSources',
        defaultMessage: 'Data sources'
      }
    }
  ],
  recordType: 'processing',
  queryDefinition: {
    condition: 'AND',
    filters: [
      {
        field: 'privacyRecordNumber',
        operation: 'equals',
        value: [],
        dataType: 'text',
        fieldType: 'identifier'
      }
    ]
  }
};
