import { connect } from 'react-redux';
import ReferenceSelector from './component';

const mapStateToProps = state => ({
  references: state.environment.simpleEntities.references.get('items'),
  position: state.environment.simpleEntities.references.get('position'),
  error: state.environment.simpleEntities.references.get('error'),
  searchResults: state.simpleEntities.get('entitySelectorSearch').get('references').searchResults
});

const mapDispatchToProps = dispatch => ({
  initReferences: () => {
    dispatch({ type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`, masterDataType: 'references' });
  },
  getNextData: (position) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      position,
      masterDataType: 'references' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT', searchParams, masterDataType: 'references' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceSelector);
