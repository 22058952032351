import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Column, Cell } from 'fixed-data-table';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faExternalLinkAlt, faTrashAlt, faPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import { pluralTerms, singularTerms } from '@packages/utils/uppercaseTranslations';
import { commonTranslations, recordTranslations } from '@packages/utils/commontranslations';
import { ResponsiveTableWrapper, DataCell, CheckBoxCell, SelectorCell } from '@packages/components/responsive-table';
import { removeTranslations, saveTranslations } from '@packages/utils/actionTranslations';
import CustomDialog from '@packages/components/custom-dialog';
import { getModifiedUrl } from '@packages/utils/common-utils';
import { stakeHolderRole, stakeHolderRoleMapper } from '../../../privacy-record-detail/documents/utils/documentUtils';


const subheaderStyle = { ...styles.labelField, paddingRight: '0px', position: 'inherit' };

const customStyle = { marginLeft: -6, fontSize: 14 };

export const DpaCell = withTheme(({ items, rowIndex, columnKey, onCheck, showNoDpa, ...props }) => {
  const themecolor = props.theme.palette.primary.main;
  const isEditable = items[rowIndex]?.isEditableItem;
  const item = items.get ? items.get(rowIndex) : items[rowIndex];

  return (
    isEditable ?
      <CheckBoxCell
        items={items}
        columnKey={columnKey}
        rowIndex={rowIndex}
        onCheck={(event, value, checked) => onCheck(rowIndex, value, checked)}
        {...props}
      /> :
      <Cell {...props}>
        {item?.isDpa &&
        <FontAwesomeIcon
          id="dpa-icon"
          icon={faCheckCircle}
          style={{ color: themecolor, fontSize: 18 }}
        />}
        {showNoDpa && !item?.isDpa &&
          <FontAwesomeIcon
            id="dpa-icon"
            icon={faBan}
            style={{ color: 'gray', fontSize: 18, transform: 'rotate(135deg)' }}
          />}
      </Cell>);
});

DpaCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired
};

DpaCell.defaultProps = {
};

const RoleCell = ({ items, rowIndex, columnKey, handleChange, mapper, ...props }) => {
  const isEditable = items[rowIndex]?.isEditableItem;

  return (
    !isEditable ?
      <DataCell
        items={items}
        columnKey={columnKey}
        rowIndex={rowIndex}
        mapper={mapper}
        {...props}
      /> :
      <SelectorCell
        items={items}
        columnKey={columnKey}
        rowIndex={rowIndex}
        customStyle={customStyle}
        menuItems={stakeHolderRole}
        handleChange={value => handleChange(value, rowIndex)}
        {...props}
      />);
};

RoleCell.propTypes = {
  items: PropTypes.instanceOf(Immutable.List).isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  mapper: PropTypes.func,
  handleChange: PropTypes.func
};

RoleCell.defaultProps = {
  handleChange: e => e,
  mapper: e => e
};

const ActionCell = withTheme(({ items, rowIndex, columnKey, currentIndex, handleDownload,
  handleOpenLink, handleRemove, isEditable, handleSave, ...props }) => {
  const item = items[rowIndex];
  const { isEditableItem } = item;
  const themecolor = props.theme.palette.primary.main;

  return (
    <Cell
      {...props}
      style={{ float: 'right', textTransform: 'uppercase' }}
    >
      <div>
        {isEditableItem ?
          <div style={{ display: 'flex' }}>
            <Button
              key="save-button"
              style={{ maxHeight: '25px', marginRight: 15 }}
              onClick={() => { handleSave(rowIndex, item); }}
            >
              {saveTranslations('save')}
            </Button>
            <ArrowTooltip title={removeTranslations('remove')} >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginTop: 3,
                  fontSize: '20px',
                  color: 'red',
                  transform: 'rotate(45deg)' }}
                onClick={() => handleRemove(rowIndex)}
              />
            </ArrowTooltip>
          </div> :
          <div style={{ display: rowIndex === currentIndex ? 'flex' : 'none' }}>
            {item.externalLink ?
              <ArrowTooltip title={commonTranslations.openLink} >
                <div>
                  <FontAwesomeIcon
                    id="link-button"
                    icon={faExternalLinkAlt}
                    onClick={() => { handleOpenLink(item); }}
                    style={{ marginRight: '15px', color: themecolor }}
                  />
                </div>
              </ArrowTooltip> :
              <ArrowTooltip title={item.isBinaryDeleted ? commonTranslations.documentNotAvailable :
                recordTranslations.downloadDocument}
              >
                <div style={item.isBinaryDeleted ? styles.disabled : {}}>
                  <FontAwesomeIcon
                    id="download-button"
                    icon={faDownload}
                    onClick={() => { handleDownload(item); }}
                    style={{ marginRight: '15px', color: themecolor }}
                  />
                </div>
              </ArrowTooltip>
          }
            {isEditable &&
            <ArrowTooltip title={removeTranslations('remove')} >
              <div>
                <FontAwesomeIcon
                  id="remove-button"
                  icon={faTrashAlt}
                  onClick={() => handleRemove(rowIndex)}
                  style={{ color: 'red' }}
                />
              </div>
            </ArrowTooltip>}
          </div>}
      </div>
    </Cell>);
});

ActionCell.propTypes = {
  rowIndex: PropTypes.number,
  columnKey: PropTypes.string,
  items: PropTypes.instanceOf(Immutable.List),
  className: PropTypes.string
};

ActionCell.defaultProps = {
  rowIndex: -1,
  columnKey: null,
  items: Immutable.List(),
  className: ''
};

const DocumentRecordsList = (props) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [index, setIndex] = useState(-1);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    props.toggleLoader(props.isFetching || props.isUpdating);
  }, [props.isFetching, props.isUpdating]);

  const documentRecords = props.fields ? props.fields.getAll() : props.documentRecords;

  const modifiedItems = documentRecords?.map((item) => {
    const { value } = item;

    return ({ ...item,
      type: value ? value.documentRecordType?.name :
        (item?.documentRecordType?.name || item.documentRecordTypes?.[0]?.value?.name),
      name: value ? value.name : item.name,
      externalLink: value ? value.externalLink : item.externalLink,
      isBinaryDeleted: value ? (!value?.isBinaryAvailable)
        : ((item.isBinaryAvailable !== undefined && !item.isBinaryAvailable) || item?.isBinaryDeleted) });
  });

  const handleMouseHover = (event, indexValue) => {
    setCurrentIndex(indexValue);
  };

  const handleRemoveDocument = (selectedIndex) => {
    const linkedRecords = [...documentRecords];
    if (linkedRecords[selectedIndex].isEditableItem || props.isAttachment) {
      linkedRecords.splice(selectedIndex, 1);
      props.deleteDocumentRecord(linkedRecords);
    } else {
      props.getDocumentDetails(linkedRecords[selectedIndex]?.id);
      setShowNotification(true);
      setIndex(selectedIndex);
    }
  };

  const handleConfirmDelete = () => {
    const linkedRecords = [...documentRecords];
    linkedRecords.splice(index, 1);
    props.deleteDocumentRecord(linkedRecords, true);
    onNotificationClose();
  };

  const onNotificationClose = () => {
    setIndex(-1);
    setShowNotification(false);
  };

  const handleDownload = (item) => {
    props.downloadDocument(item.id || item.value.id, props.isDownloadFromRecords);
  };

  const handleOpenLink = (item) => {
    window.open(getModifiedUrl(item.externalLink), '_blank', 'noopener,noreferrer');
  };

  const handleCheckDpa = (rowIndex, item, checked) => {
    const updatedItem = { ...item, isDpa: checked };
    handleUpdate(rowIndex, updatedItem);
  };

  const handleRoleSelect = (value, rowIndex) => {
    const updatedItem = { ...documentRecords[rowIndex], stakeHolderRole: value };
    handleUpdate(rowIndex, updatedItem);
  };

  const handleUpdate = (selectedIndex, updatedItem) => {
    const updatedItems = [...documentRecords];
    updatedItems.splice(selectedIndex, 1, updatedItem);
    props.handleUpdate(updatedItems);
  };

  const renderDocumentItems = () => (
    <div>
      <ResponsiveTableWrapper
        id="vendor-responsive-table"
        rowHeight={40}
        headerHeight={25}
        containerMaxHeight={250}
        rowsCount={modifiedItems?.length}
        onRowMouseEnter={handleMouseHover}
        {...props}
      >
        <Column
          id="name"
          columnKey="name"
          header={singularTerms('name')}
          cell={<DataCell items={modifiedItems} />}
          flexGrow={1}
          width={40}
        />
        <Column
          id="stakeHolderRole"
          columnKey="stakeHolderRole"
          header={recordTranslations.roleInDocument}
          cell={<RoleCell
            items={modifiedItems}
            handleChange={handleRoleSelect}
            mapper={stakeHolderRoleMapper}
          />}
          flexGrow={1}
          width={70}
          visible={!props.isAttachment}
        />
        <Column
          id="type"
          columnKey="type"
          header={recordTranslations.documentType}
          cell={<DataCell items={modifiedItems} />}
          flexGrow={1}
          width={40}
        />
        <Column
          id="isDpa"
          columnKey="isDpa"
          header={recordTranslations.dpa}
          cell={<DpaCell items={modifiedItems} onCheck={handleCheckDpa} />}
          flexGrow={1}
          width={70}
          visible={!props.isAttachment}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          cell={<ActionCell
            items={modifiedItems}
            currentIndex={currentIndex}
            handleDownload={handleDownload}
            handleRemove={handleRemoveDocument}
            handleOpenLink={handleOpenLink}
            handleSave={props.handleSave}
            isEditable={props.isEditable}
          />}
          flexGrow={0.5}
          width={90}
        />
      </ResponsiveTableWrapper>
      {modifiedItems?.length === 0 &&
      <div style={{ paddingLeft: 180, margin: 25 }}>
        {commonTranslations.NoDataToDisplay}
      </div>
          }
    </div>);

  return (
    <div>
      <div style={{ width: '20%' }}>
        {props.showHeader &&
        <ListSubheader style={{ ...subheaderStyle, paddingRight: '20px' }} >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {pluralTerms('documents')}
          </div>
        </ListSubheader>}
      </div>
      {documentRecords?.length || props.showEmptyTable ?
        <div>
          {renderDocumentItems()}
          {showNotification &&
          <CustomDialog
            id="document-delete"
            show={showNotification}
            proceed={handleConfirmDelete}
            title={removeTranslations('documentRemove')}
            cancel={onNotificationClose}
            content={recordTranslations.documentDeleteConfirmation}
          />}
        </div> :
        <div style={{ fontSize: 14, paddingLeft: 20 }}>
          {commonTranslations.NoDataToDisplay}
        </div>}
    </div>
  );
};

DocumentRecordsList.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    name: PropTypes.string,
    getAll: PropTypes.func
  }).isRequired,
  updateItems: PropTypes.func,
  downloadDocument: PropTypes.func,
  documentRecords: PropTypes.shape({}),
  isEditable: PropTypes.bool,
  showHeader: PropTypes.bool,
  deleteDocumentRecord: PropTypes.func,
  handleUpdate: PropTypes.func,
  showEmptyTable: PropTypes.bool,
  isAttachment: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isFetching: PropTypes.bool,
  isUpdating: PropTypes.bool,
  toggleLoader: PropTypes.func,
  handleSave: PropTypes.func,
  getDocumentDetails: PropTypes.func,
  isDownloadFromRecords: PropTypes.bool
};

DocumentRecordsList.defaultProps = {
  updateItems: e => e,
  downloadDocument: e => e,
  documentRecords: null,
  isEditable: false,
  showHeader: true,
  deleteDocumentRecord: e => e,
  handleUpdate: e => e,
  showEmptyTable: false,
  isAttachment: false,
  isFullWidth: false,
  isFetching: false,
  isUpdating: false,
  toggleLoader: e => e,
  handleSave: e => e,
  getDocumentDetails: e => e,
  isDownloadFromRecords: false
};

export default DocumentRecordsList;
