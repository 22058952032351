import { getLocation } from '@packages/utils/common-utils';

import filterMenus from './filters';

const getFilteredMenus = (features) =>
  filterMenus.filter((menu) =>
    menu.feature.some((feature) => features.includes(feature))
  );

export const getKeyLabeledResponse = (recordTypes, parentKey) =>
  recordTypes.map((item) => ({
    ...item,
    key: parentKey ? `${item.id}_${parentKey}` : item.id,
    label: item.name || item.translatedName
  }));

export const generateModifiedFilterMenu = (data) => {
  const { features, recordTypes } = data;
  const modifiedMenus = getFilteredMenus(features).map((menu) => {
    if (
      ['layoutsLibrary', 'recordsLibrary', 'templatesLibrary'].includes(
        menu.key
      )
    ) {
      const subMenuItems = getKeyLabeledResponse(recordTypes, menu.key);
      return {
        ...menu,
        subMenu: subMenuItems,
        defaultFilter: subMenuItems[0]?.key
      };
    } else {
      return menu;
    }
  });

  return modifiedMenus;
};

export const getDefaultFilter = (
  defaultFilter,
  recordTypes,
  selectedFilter
) => {
  if (!selectedFilter) {
    return defaultFilter;
  } else {
    const [parentFilter, subFilter] = selectedFilter.split('_').reverse();
    const hasCurrentFilter = recordTypes.some((item) => item.id === subFilter);
    return hasCurrentFilter
      ? selectedFilter
      : `${recordTypes[0].id}_${parentFilter}`;
  }
};

const getModifiedUrlWithFilterParams = (url, filterParams) => {
  let modifiedUrl = url;
  const { sortOn, sortOrder, filteredOn = {} } = filterParams;
  Object.entries(filteredOn).forEach(([key, value]) => {
    if (value.length > 0) {
      value.forEach((x) => {
        const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
        modifiedUrl = `${modifiedUrl}&filter=${key}%3D${encodeURIComponent(
          filterText
        )}`;
      });
    }
  });
  modifiedUrl = sortOn
    ? `${modifiedUrl}&sortOn=${sortOn}&sortOrder=${sortOrder}`
    : modifiedUrl;

  return modifiedUrl;
};

const getModifiedUrlWithSearchParams = (url, searchParams) => {
  let modifiedUrl = url;
  Object.entries(searchParams).forEach(([searchKey, searchText]) => {
    modifiedUrl = searchText
      ? `${modifiedUrl}&search=${searchKey}%3D${encodeURIComponent(searchText)}`
      : modifiedUrl;
  });

  return modifiedUrl;
};

export const getURLWithParams = ({
  baseUrl,
  filterParams,
  position,
  searchParams,
  recordId,
  rowCount = 15
}) => {
  let url = `${baseUrl}?${
    recordId ? `filter=recordTypeId=${recordId}&` : ''
  }numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    url = getModifiedUrlWithFilterParams(url, filterParams);
  }
  if (searchParams) {
    url = getModifiedUrlWithSearchParams(url, searchParams);
  }
  return url;
};

export const getBaseUrl = (libraryType) => {
  const libraryListsUrlMapper = {
    LAYOUTS: '/v1/custom-record-layout/data-library',
    RECORDS: '/v1/records/data-library',
    TEMPLATES: '/v1/records/templates/data-library'
  };
  return libraryListsUrlMapper[libraryType];
};

export const dataLibraryLayoutNameMapper = (layout) => layout.name;

export const handlePreviewItem = (index, items, itemType, libraryTenantId) => {
  const {
    id,
    recordType,
    layout: { layoutId }
  } = items.get(index);
  const isTemplate = itemType === 'template';
  const location = getLocation();
  if (recordType === 'custom') {
    window.open(
      `${location}/#/custom-${itemType}/${layoutId}/${id}/preview?libraryTenantId=${libraryTenantId}`,
      '_blank'
    );
  } else {
    window.open(
      `${location}/#/${recordType}${
        isTemplate ? '-template' : ''
      }/${id}/preview?libraryTenantId=${libraryTenantId}`,
      '_blank'
    );
  }
};
