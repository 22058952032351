import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { recordTranslations } from '@packages/utils/commontranslations';
import ValueSelector from '../value-selector';

const DpoOrganisationsSelector = (props) => {
  useEffect(() => {
    if (props.initDpo) {
      props.initDpo();
    }
  }, []);

  const handleNextData = () => {
    props.getNextData(props.position);
  };

  const handleSearch = (searchParams) => {
    props.onSearch({ ...searchParams });
  };

  const { hintTextLabel } = props;
  return (
    <div>
      <ValueSelector
        {...props}
        valueAttr="name"
        searchResults={props.searchItem}
        dataItems={props.item}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        title={recordTranslations.dataProtectionOfficer}
      />
    </div>
  );
};

DpoOrganisationsSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  initDpo: PropTypes.func,
  onSearch: PropTypes.func
};

DpoOrganisationsSelector.defaultProps = {
  hintTextLabel: null,
  initDpo: e => e,
  getNextData: e => e,
  position: 0,
  onSearch: e => e
};

export default DpoOrganisationsSelector;
