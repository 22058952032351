import { connect } from 'react-redux';

import CreateProcessingComponent from './component';

const mapStateToProps = (state) => ({
  isUpdating: state.processing.get('isUpdating'),
  actionError: state.processing.get('actionError'),
  isTemplateMode: state.privacyRecords.list.get('isTemplateMode'),
  isGlobal: state.home.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'PROCESSING:DETAIL:RESET' });
  },
  saveProcessing: (
    data,
    templateId,
    createFromEdit,
    isVendor,
    isTemplateMode,
    refetchRecords,
    isGlobal
  ) => {
    dispatch({
      type: 'PROCESSING:DETAIL:UPSERT',
      data,
      templateId,
      createFromEdit,
      isVendor,
      isTemplateMode,
      refetchRecords,
      isGlobal
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  onUnMount: () => {
    dispatch({ type: 'PROCESSING:DETAIL:ERROR:RESET' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProcessingComponent);
