import { connect } from 'react-redux';

import CreateCustomRecord from './component';

const mapStateToProps = (state) => ({
  isUpdating: state.customRecord.get('isUpdating'),
  actionError: state.customRecord.get('actionError'),
  locale: state.intl.locale,
  tenantLocale: state.login.get('tenantLocale'),
  isTemplateMode: state.privacyRecords.list.get('isTemplateMode'),
  isGlobal: state.home.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'CUSTOM_RECORD:DETAIL:RESET' });
  },
  saveCustomRecord: (
    data,
    templateId,
    locale,
    tenantLocale,
    isTemplateMode,
    isGlobal
  ) => {
    dispatch({
      type: 'CUSTOM_RECORD:ITEM',
      data,
      templateId,
      mode: 'CREATE',
      locale,
      tenantLocale,
      isTemplateMode,
      isGlobal
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  onUnMount: () => {
    dispatch({ type: 'CUSTOM_RECORD:DETAIL:ERROR:RESET' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomRecord);
