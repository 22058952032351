import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { handleServiceDown, GENERAL_FETCH_LIMIT } from '@packages/utils/common-utils';
import dsrTranslations from '../../dsrTranslations';

export function* resetAndfetchRequesters(action) {
  yield put({ type: `DATAREQUESTERS:LIST:INIT` });
  yield fetchRequesters(action);
}

export function* fetchRequesters(action) {
  yield put({ type: `DATAREQUESTERS:LIST:FETCH` });
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  try {
    const requestersState = yield select(state => state.requesterList);
    const filterParams = requestersState ? requestersState.get('filterParams').toJS() : {};
    const { sortOn, sortOrder } = filterParams;
    const filteredOn = requestersState ? requestersState.get('filteredOn') : Immutable.Map();
    const response = yield registry.get('request').get(getURLWithParams({ sortOn, sortOrder, filteredOn },
      position, rowCount), null, {});
    switch (response.status) {
      case 200: {
        const modifiedResponse = getModifiedResponse(response.body);
        yield put({
          type: `DATAREQUESTERS:LIST:FETCH:SUCCESS`,
          items: modifiedResponse,
          requesterData: action.requesterData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    registry.get('logger').error(err);
    yield put({ type: `DATAREQUESTERS:LIST:FETCH:FAIL`, error: err.message });
  }
}
export function* updateRequester(action) {
  const { data, isEdit, unmask } = action;
  const request = registry.get('request');
  const requestData = unmask ? modifyRequestData({ ...data, anonymise: false }) : modifyRequestData(data);
  let response;
  try {
    if (!isEdit) response = yield request.post(`/v1/dsrmanagement/datasubjects`, requestData);
    else response = yield request.put(`/v1/dsrmanagement/datasubjects/${requestData.id}`, requestData);
    switch (response.status) {
      case 200: {
        const modifiedItem = modifyResponseData(response.body);
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationMessages[isEdit],
            type: 'success'
          }
        });
        if (isEdit) {
          yield put({ type: 'DATAREQUESTER:UPSERT:SUCCESS', data: modifiedItem });
          yield updateRequesterItems({
            type: 'edit',
            data: modifiedItem
          });
        } else {
          yield put({
            type: `DATAREQUESTER:UPSERT:SUCCESS`, data: modifiedItem, position: 0
          });
          yield fetchRequesters({ requesterData: modifiedItem });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'DATAREQUESTER:UPSERT:FAIL', error: err.message });
  }
}

export function* fetchRequesterItem(action) {
  yield put({ type: 'DATAREQUESTER:ITEM:FETCH:INIT' });
  const { id } = action;
  let response;
  try {
    response = yield registry.get('request')
      .get(`/v1/dsrmanagement/datasubjects/${id}`, null);
    switch (response.status) {
      case 200: {
        const modifiedItem = modifyResponseData(response.body);
        yield put({
          type: 'DATAREQUESTER:ITEM:FETCH:SUCCESS',
          requesterData: modifiedItem
        });
        yield fetchDsrList(id);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'DATAREQUESTER:ITEM:FETCH:FAIL', error: err.message });
  }
}

function* fetchDsrList(id) {
  try {
    const response = yield registry.get('request')
      .get(`/v1/dsrmanagement/datasubjectrequests?filter=requester%3D${id}`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATAREQUESTER:DSR:FETCH:SUCCESS',
          dsrData: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
  }
}

export function* deleteRequester(action) {
  const { items, isSingleDelete } = action;
  const request = registry.get('request');
  try {
    const entityIds = items.map(item => (item.id));
    const response = yield request.post(`/v1/dsrmanagement/datasubjects/remove`, { entityIds });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isSingleDelete ? dsrTranslations.requesterDeleted : dsrTranslations.BulkDeleteSuccess,
            type: 'success'
          }
        });
        if (isSingleDelete) {
          const requesterState = yield select(state => state.requesterList);
          const reqItems = requesterState.get('items');
          const itemsList = updateDataItems(reqItems, items[0].id);
          let newItems = requesterState ? requesterState.get('newItems') : Immutable.List();
          newItems = updateDataItems(newItems, items[0].id);
          yield put({ type: `DATAREQUESTER:LIST:UPDATE`, items: itemsList, newItems });
        } else yield resetAndfetchRequesters(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
  }
}

export function* filterRequesters(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  yield put({ type: `DATAREQUESTERS:LIST:FILTER`, filterParams });
  yield fetchRequesters(action);
}

export function* unmaskAndUpdate(action) {
  const { id, note } = action.data;
  const requesterDetails = yield unmaskRequester({ id, updateMaskValue: false });
  const modifiedData = { ...requesterDetails, note };
  yield updateRequester({ data: modifiedData, isEdit: true });
}

export function* unmaskRequester(action) {
  yield put({ type: 'DATAREQUESTER:ITEM:FETCH:INIT' });
  const { id, updateMaskValue = true } = action;
  let response;
  try {
    response = yield registry.get('request')
      .get(`/v1/dsrmanagement/datasubjects/${id}?anonymised-view=false`, null);
    switch (response.status) {
      case 200: {
        const modifiedItem = modifyResponseData(response.body);
        yield put({
          type: 'DATAREQUESTER:ITEM:FETCH:SUCCESS',
          requesterData: modifiedItem
        });
        if (updateMaskValue) {
          // When the requester details need to be updated with anonymise=true
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: dsrTranslations.unAnonymised,
              type: 'success'
            }
          });
          yield updateRequester({ data: modifiedItem, unmask: true, isEdit: true });
        }
        return modifiedItem;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'DATAREQUESTER:ITEM:FETCH:FAIL', error: err.message });
  }
  return response;
}


function* updateRequesterItems(action) {
  const { type, data } = action;
  const requesterState = yield select(state => state.requesterList);
  let items = requesterState ? requesterState.get('items') : Immutable.List();
  let newItems = requesterState ? requesterState.get('newItems') : Immutable.List();
  items = updateDataItems(items, data.id, type, data);
  newItems = updateDataItems(newItems, data.id, type, data);
  yield put({ type: 'DATAREQUESTER:LIST:UPDATE:SUCCESS', items, newItems });
}

export const updateDataItems = (items, currentId, type, modifiedItem) => {
  const index = items.findIndex(item => item.id === currentId);
  if (index !== -1) {
    const updatedItems = (type === 'edit')
      ? items.set(index, modifiedItem) : items.remove(index);
    return updatedItems;
  }
  return items;
};

const notificationMessages = {
  true: dsrTranslations.requesterUpdateSuccess,
  false: dsrTranslations.requesterCreated
};

const modifyResponseData = (data) => {
  const respData = Object.assign({}, data);
  respData.dataSubjectCategories = data.dataSubjectCategories.map(item => ({ value: { ...item, key: item.name } }));
  respData.name = `${data.firstName || ''} ${data.lastName || ''}`;
  return (respData);
};

const getURLWithParams = (filterParams, position, rowCount) => {
  let url = `v1/dsrmanagement/datasubjects?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOrder } = filterParams;
    let { sortOn } = filterParams;
    sortOn = (sortOn === 'name' ? 'firstName' : sortOn);
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((item) => {
          const filterText = (typeof (item) === 'object') ? item.name : item;
          (url = `${url}&filter=${filterKey}%3D${encodeURIComponent(filterText)}`);
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

const getModifiedResponse = (response) => {
  const modifiedResponse = response.map((res) => {
    const lastName = res.lastName || '';
    const name = `${res.firstName || ''} ${lastName}`;
    return ({ ...res, name });
  });
  return modifiedResponse;
};

const modifyRequestData = (data) => {
  const reqData = Object.assign({}, data);
  reqData.dataSubjectCategories = data.dataSubjectCategories.map((item) => {
    const value = item.value ? item.value : item;
    return value;
  });
  return (reqData);
};
