import React from 'react';
import PropTypes from 'prop-types';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import CustomMasterData from '.';

export const CustomMasterDataDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      selectedItem.key = selectedItem.name;
      props.handleSelectedItem({ value: selectedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="custom-master-data-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={props.title}
    >
      <CustomMasterData
        id="custom-master-data"
        isEdit={props.isEdit}
        isUsed={props.isUsed}
        usage={props.usage}
        dataItemId={props.dataItemId}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        isNewItem={props.isNewItem}
        source={props.source}
        entityItem={props.entityItem}
        isTagEdit={props.isTagEdit}
        hideTags={props.hideTags}
        entityLabel={props.entityLabel}
      />
    </CommonDialog>
  );
};

CustomMasterDataDialog.propTypes = {
  isNewItem: PropTypes.bool,
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  onRequestClose: PropTypes.func,
  dataItemId: PropTypes.string,
  source: PropTypes.string,
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  inputValue: PropTypes.shape({})
};

CustomMasterDataDialog.defaultProps = {
  isNewItem: false,
  open: false,
  isEdit: false,
  isUsed: false,
  onRequestClose: (e) => e,
  source: '',
  inputValue: {},
  usage: {},
  dataItemId: ''
};

export default CustomMasterDataDialog;
