import { connect } from 'react-redux';
import ProcessingCategorySelector from './component';

const mapStateToProps = state => ({
  processingCategories: state.environment.processingCategories.get('items'),
  position: state.environment.processingCategories.get('position'),
  error: state.processingCategories.get('error'),
  searchResults: state.processingCategories.get('items')
});

const mapDispatchToProps = dispatch => ({
  initProcessingCategories: () => {
    dispatch({ type: 'PROCESSING_CATEGORIES:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'PROCESSING_CATEGORIES:LIST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PROCESSING_CATEGORY:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingCategorySelector);
