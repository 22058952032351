import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class ReferenceSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initReferences) {
      this.props.initReferences();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields === nextProps.fields) ||
    !(this.props.references === nextProps.references) ||
    !(this.state === nextState);
  }

  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render () {
    const { searchResults } = this.state;
    const { hintTextLabel, references } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="name"
          searchResults={searchResults}
          dataItems={references}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          onScrollStop={this.handleNextData}
          title={
            <FormattedMessage
              id="MultipleReferenceDialog.header"
              description="Select References dialog header"
              defaultMessage="Select references"
            />}
          {...this.props}
        />
      </div>
    );
  }
}

ReferenceSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  hintTextLabel: PropTypes.node,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initReferences: PropTypes.func,
  references: PropTypes.instanceOf(Immutable.List)
};

ReferenceSelector.defaultProps = {
  hintTextLabel: null,
  initReferences: e => e,
  fields: {},
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  references: Immutable.List()
};

export default ReferenceSelector;
