/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';
import ItemList from './itemList';

const mapDispatchToProps = dispatch => ({
  downloadDocuments: (data) => {
    dispatch({ type: 'ATTACHMENT:DOWNLOAD', data });
  }
});
export default withTheme(connect(null, mapDispatchToProps)(ItemList));
