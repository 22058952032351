import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import SimpleEntiyDialog from '@packages/features/environment/components/simple-named-entity/components/simpleEntityDialog';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { getReducerType, getIsEditable } from '@packages/utils/common-utils';
import {
  multipleSelectorTypeTranslations,
  policyTypeTranslations
} from '@packages/features/environment/masterDataTranslations';
import styles from '@packages/ui/styles';
import { commonTranslations } from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import { getItemNames } from '../../common-utils';

class SimpleEntitySelector extends React.Component {
  static filterSimpleEntities(selectedEntities, searchedItems, entityType) {
    if (entityType === 'policies') {
      const filteredData =
        searchedItems &&
        searchedItems.filter((simpleEntity) => {
          const index = selectedEntities.findIndex(
            (selectedEntity) =>
              selectedEntity.value.name === simpleEntity.name &&
              selectedEntity.value.entityType === simpleEntity.entityType
          );
          return index === -1;
        });
      return filteredData;
    }
    const filteredData =
      searchedItems &&
      searchedItems.filter((simpleEntity) => {
        const index = selectedEntities.findIndex(
          (selectedEntity) =>
            ((selectedEntity.value && selectedEntity.value.name) ||
              selectedEntity.name) === simpleEntity.name
        );
        return index === -1;
      });
    return filteredData;
  }

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      open: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1,
      searchResults: Immutable.List(),
      searchText: '',
      reducerType: getReducerType(
        this.props.masterDataType,
        this.props.entityType
      )
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.getModifiedItems = this.getModifiedItems.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { entityType, masterDataType } = this.props;
    if (this.props.entityType || this.props.masterDataType) {
      const reducerType = getReducerType(masterDataType, entityType);
      this.setState({
        reducerType
      });
      if (this.props.searchResults.get(reducerType)) {
        const { searchResults: currentSearchResults = {} } =
          this.props.searchResults.get(reducerType) &&
          this.props.searchResults.get(reducerType);
        const { searchResults: nextSearchResults = {} } =
          this.props.searchResults.get(reducerType) &&
          nextProps.searchResults.get(reducerType);
        if (currentSearchResults !== nextSearchResults)
          this.setState({ searchResults: nextSearchResults });
      }

      if (
        this.props.simpleEntities[reducerType].get('items') !==
        nextProps.simpleEntities[reducerType].get('items')
      ) {
        this.setState({
          currentSimpleEntities:
            nextProps.simpleEntities[reducerType].toJS().items
        });
      }
    }
    if (this.props.isEdit !== nextProps.isEdit && nextProps.editIndex !== -1) {
      this.handleEditClick(nextProps.editIndex);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { simpleEntities } = this.props;
    const nextData = nextProps.simpleEntities[this.state.reducerType];
    const currentData = simpleEntities[this.state.reducerType];
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(currentData === nextData)
    );
  }

  handleAddClick(searchText) {
    this.setState({
      open: true,
      isEdit: false,
      multiple: false,
      selectedIndex: -1,
      inputValue: {
        name: searchText
      }
    });
  }

  handleEditClick(index) {
    const isLinkAssociated = this.props.isItemInLinkGroup(
      this.props.entityType || this.props.masterDataType,
      index
    );
    if (isLinkAssociated) {
      this.setState({
        openNotification: true,
        isEdit: true,
        selectedIndex: index
      });
    } else {
      this.setState({
        open: true,
        isEdit: true,
        multiple: false,
        selectedIndex: index,
        inputValue:
          this.props.fields.get(index).value || this.props.fields.get(index)
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      open: false,
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      selectedIndex: -1,
      searchText: '',
      searchResults: []
    });
    if (this.props.isEdit) this.props.requestMenuClose();
  }

  handleClose = () => {
    this.setState({
      openNotification: false,
      selectedIndex: -1,
      isEdit: false
    });
    if (this.props.masterDataType === 'securityMeasures')
      this.props.requestMenuClose();
  };

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const isLinkAssociated = this.props.isItemInLinkGroup(
      this.props.entityType || this.props.masterDataType,
      selectedIndex
    );
    if (isLinkAssociated) {
      this.setState({
        openNotification: true,
        selectedIndex
      });
    } else {
      const selectedEntities = this.props.fields.length
        ? [...this.props.fields.getAll()]
        : [];
      if (selectedIndex !== -1) {
        selectedEntities.splice(selectedIndex, 1);
      }
      this.updateEntities(selectedEntities);
    }
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() ? this.props.fields.getAll() : [];
    const selectedEntities = [...items, ...selectedItems];
    this.updateEntities(selectedEntities);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    const selectedEntities = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedEntities.push(selectedItem);
    } else {
      selectedEntities.splice(selectedIndex, 1, selectedItem);
    }
    this.updateEntities(selectedEntities);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(
      searchParams,
      this.props.masterDataType,
      this.props.entityType
    );
  }

  handleScrollEnd() {
    this.props.getNextData(
      this.props.masterDataType,
      this.props.entityType,
      this.props.simpleEntities[this.state.reducerType].get('position')
    );
  }

  handleFilter(selectedItem) {
    const value = selectedItem ? [selectedItem.id] : [];
    this.props.onChooseFilter(
      Object.assign({}, { filterKey: 'tags', filteredOn: value }),
      this.props.masterDataType,
      this.props.entityType
    );
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.masterDataType);
  }

  getModifiedItems = (data) => {
    let modifiedData = {};
    const { formatMessage } = this.props;
    modifiedData =
      data &&
      data.map((item) => ({
        ...item,
        addlkey: formatMessage(policyTypeTranslations[item.entityType].props)
      }));
    return modifiedData;
  };

  updateEntities(selectedEntities) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true }); // eslint-disable-line react/no-unused-state
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedEntities);
    } else {
      this.props.updateEntities(
        selectedEntities,
        this.props.type,
        this.props.itemIndex
      );
    }
    this.setState({
      searchText: '',
      searchResults: []
    });
  }

  render() {
    const {
      entityType,
      showItemList,
      subTypes,
      maxCount,
      masterDataType,
      fieldType,
      userPermissions,
      isNote,
      showTextField,
      showEditIcon,
      isGlobal,
      disabled
    } = this.props;
    const tags = this.props.simpleEntities.tags.get('items');
    const selectedEntities = this.props.fields.getAll();
    const tagSearchResult = this.props.searchResults.get('tags').searchResults;
    const tagsPosition = this.props.simpleEntities.tags.get('position');
    const { reducerType, currentSimpleEntities } = this.state;
    const { searchText, searchResults } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.size > 0
        ? searchResults
        : currentSimpleEntities;
    const simpleEntityList =
      selectedEntities && selectedEntities.length > 0
        ? SimpleEntitySelector.filterSimpleEntities(
          selectedEntities,
          searchedItems,
          this.props.masterDataType
        )
        : searchedItems;
    const modifiedEntities =
      this.props.masterDataType === 'policies'
        ? this.getModifiedItems(simpleEntityList)
        : simpleEntityList;
    const isEditable = getIsEditable(masterDataType, userPermissions);
    const multipleSimpleEntityList =
      selectedEntities && selectedEntities.length > 0
        ? SimpleEntitySelector.filterSimpleEntities(
          selectedEntities,
          currentSimpleEntities,
          this.props.masterDataType
        )
        : currentSimpleEntities;
    const modifiedmultipleEntities =
      this.props.masterDataType === 'policies'
        ? Immutable.List(this.getModifiedItems(multipleSimpleEntityList))
        : Immutable.List(multipleSimpleEntityList);
    const itemSelectorStyle =
      selectedEntities && selectedEntities.length >= maxCount
        ? styles.disabled
        : {};
    const actions = [
      <Button id="btn_remove_no" key="btn_remove_no" onClick={this.handleClose}>
        {commonTranslations.Ok}
      </Button>
    ];

    return (
      <div>
        {!showTextField ? (
          getItemNames(selectedEntities)
        ) : (
          <div>
            {showItemList &&
              selectedEntities &&
              selectedEntities.length > 0 && (
                <ItemList
                  id="item_list"
                  {...this.props}
                  isEditable={isEditable && showEditIcon}
                  type={this.props.label}
                  isNote={isNote !== false}
                  dataItemType={fieldType || reducerType}
                  handleRemoveItem={this.handleRemoveItem}
                  handleEditClick={this.handleEditClick}
                  handleUsageClick={this.handleUsageClick}
                  handleEditItem={this.handleEditItem}
                  selectedItems={selectedEntities}
                />
            )}
            <div style={itemSelectorStyle}>
              <ItemSelector
                id={this.props.id}
                multiValue={this.props.multiValue}
                onFocus={() =>
                  this.props.initSimpleEntities(
                    this.props.masterDataType,
                    this.props.entityType
                  )
                }
                createNewMenuItem={isEditable && this.props.createNewMenuItem}
                selectFromListMenuItem={
                  this.props.selectFromListMenuItem || false
                }
                dataSource={modifiedEntities}
                dataSourceConfig={{ text: 'key', value: 'key' }}
                hintTextLabel={this.props.hintTextLabel}
                handleSelectedItem={this.handleSelectedItem}
                handleAddClick={this.handleAddClick}
                handleMultipleSelect={this.handleMultipleSelect}
                onSearch={this.handleSearch}
                disabled={disabled}
              />
            </div>
            {this.state.open && (
              <SimpleEntiyDialog
                open={this.state.open}
                isEdit={this.state.isEdit}
                limitExceeded={
                  selectedEntities && selectedEntities.length > maxCount
                }
                dataItemId={this.state.inputValue && this.state.inputValue.id}
                inputValue={this.state.inputValue}
                selectedItems={selectedEntities}
                onRequestClose={this.handleRequestClose}
                handleSelectedItem={this.handleSelectedItem}
                source="records"
                masterDataType={this.props.masterDataType}
                entityType={entityType}
                subTypes={subTypes}
                hideTags={isGlobal}
                allowSaveAndCreate={this.props.allowSaveAndCreate}
              />
            )}
            {this.state.multiple && (
              <MultipleSelectorDialog
                id="multiple_selector"
                maxCount={maxCount}
                title={multipleSelectorTypeTranslations(reducerType)}
                onScrollStop={this.handleScrollEnd}
                open={this.state.multiple}
                selectedEntitiesSize={
                  selectedEntities && selectedEntities.length
                }
                filteredData={modifiedmultipleEntities}
                onRequestClose={this.handleRequestClose}
                handleMultipleItems={this.handleMultipleItems}
                filterTagValue={tags}
                onChooseFilter={this.handleFilter}
                showFilterByTag={!this.props.isGlobal}
                tagSearch={this.props.tagsSearch}
                tagSearchResult={tagSearchResult}
                getNextTags={this.props.getNextTags}
                tagsPosition={tagsPosition}
              />
            )}
            {this.state.openNotification && (
              <CommonDialog
                id="item-in-group-notification"
                show={this.state.openNotification}
                onCancel={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                buttonActions={actions}
              >
                {this.state.isEdit
                  ? commonTranslations.linkItemUpdatefromGroup
                  : commonTranslations.linkItemDeletefromGroup}
              </CommonDialog>
            )}
          </div>
        )}
      </div>
    );
  }
}

SimpleEntitySelector.propTypes = {
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  isDpiaEditable: PropTypes.bool,
  isReferenceEditable: PropTypes.bool,
  isMeasuresEditable: PropTypes.bool,
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  editIndex: PropTypes.number,
  onChooseFilter: PropTypes.func,
  tagsSearch: PropTypes.func,
  isEdit: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  label: PropTypes.node,
  itemIndex: PropTypes.number,
  hintTextLabel: PropTypes.node,
  initSimpleEntities: PropTypes.func,
  updateEntities: PropTypes.func,
  onSearch: PropTypes.func,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  requestMenuClose: PropTypes.func,
  searchResults: PropTypes.shape({
    get: PropTypes.func
  }),
  masterDataType: PropTypes.string,
  formatMessage: PropTypes.func,
  simpleEntities: PropTypes.shape({
    tags: PropTypes.instanceOf(Immutable.List),
    get: PropTypes.func
  }).isRequired,
  getNextTags: PropTypes.func,
  targetOrigin: PropTypes.shape({}),
  anchorOrigin: PropTypes.shape({}),
  showItemList: PropTypes.bool,
  entityType: PropTypes.string,
  maxCount: PropTypes.number,
  type: PropTypes.string,
  subTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.instanceOf(Immutable.List)
  ]),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  isNote: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  showTextField: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  isRegistry: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  isGlobal: PropTypes.bool,
  fieldType: PropTypes.string,
  disabled: PropTypes.bool
};

SimpleEntitySelector.defaultProps = {
  id: 'simpleEntity_selector',
  isEditable: false,
  isReferenceEditable: false,
  isMeasuresEditable: false,
  isDpiaEditable: false,
  multiValue: true,
  isEdit: false,
  isNote: true,
  editIndex: -1,
  selectFromListMenuItem: false,
  isItemInLinkGroup: () => false,
  createNewMenuItem: false,
  label: null,
  hintTextLabel: null,
  onChooseFilter: (e) => e,
  tagsSearch: (e) => e,
  getNextTags: (e) => e,
  maxCount: undefined,
  initSimpleEntities: null,
  formatMessage: (e) => e,
  requestMenuClose: (e) => e,
  updateEntities: null,
  onSearch: (e) => e,
  getNextData: (e) => e,
  itemIndex: -1,
  searchResults: Immutable.List(),
  masterDataType: '',
  type: '',
  targetOrigin: { vertical: 'top', horizontal: 'left' },
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  showItemList: true,
  entityType: '',
  subTypes: undefined,
  handleUsageClick: (e) => e,
  dataItemType: '',
  userPermissions: Immutable.Map({}),
  showTextField: true,
  showEditIcon: true,
  isRegistry: false,
  updateRegistryFilters: (e) => e,
  isGlobal: false,
  fieldType: undefined,
  disabled: false
};

export default SimpleEntitySelector;

/**
 * Used for
 *  references
 *  purposes
 *  purposesOfTransfer
 *  riskDetails
 *  mitigatingMeasures
 *  qualityControls
 *  informationRights
 *  consentRights
 *  accessRights
 *  objectionRights
 *  deletionRights
 *  dataPortabilityRights
 *  technicalSecurityMeasures
 *  organisationalSecurityMeasures
 *  confidentialityThreats
 *  integrityThreats
 *  availabilityThreats
 *  confidentialityImpacts
 *  integrityImpacts
 *  availabilityImpacts
 *  policies
 *  dataProtectionMeasures
 *  accountabilityMeasures
 *  findings
 *  decisions
 */
