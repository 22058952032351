import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';


class BreachGroundSelector extends React.Component {
  componentWillMount() {
    if (this.props.initBreachGrounds) {
      this.props.initBreachGrounds();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.breachGrounds === nextProps.breachGrounds) ||
    !(this.props.subField === nextProps.subField);
  }

  render () {
    const { hintTextLabel, breachGrounds } = this.props;
    return (
      <div>
        {breachGrounds.size > 0 &&
        <ValueSelector
          dataItems={breachGrounds}
          liveAutocomplete={false}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          valueAttr="key"
          title={
            <FormattedMessage
              id="MultipleBreachgroundDialog.header"
              description="Select Breach ground Dialog Header"
              defaultMessage="Select breach grounds"
            />}
          {...this.props}
        />}
      </div>
    );
  }
}

BreachGroundSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  initBreachGrounds: PropTypes.func,
  breachGrounds: PropTypes.instanceOf(Immutable.List),
  subField: PropTypes.string.isRequired
};

BreachGroundSelector.defaultProps = {
  hintTextLabel: null,
  initBreachGrounds: e => e,
  breachGrounds: Immutable.List()
};

export default BreachGroundSelector;
