import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import ItemList from '@packages/components/form-components/itemList';
import { recordTranslations } from '@packages/utils/commontranslations';
import { addKeyToRetentionTerm } from '../../../../privacy-record-detail/common-utils';
import RetentionItemSelector
  from '../../../../privacy-record-detail/components/retention-term-selector/retention-selector';


export const RetentionTermSelector = (props) => {
  const [offsetDatasource, setOffsetDatasource] = useState(props.retentionOffsets);
  const [recordsRetentionTerms, setRecordsRetentionTerms] = useState([]);
  const [selectedRetentionTerms, setSelectedRetentionTerms] = useState([]);

  useEffect(() => {
    if (props.initRetentionTerms) {
      props.initRetentionTerms();
    }
    if (props.initRetentionTermOffsets) {
      props.initRetentionTermOffsets();
    }
    setSelectedRetentionTerms(modifyRetentionTerm(props.fields, true));
  }, []);


  useEffect(() => {
    setOffsetDatasource(props.retentionOffsets);
  }, [props.retentionOffsets]);

  useEffect(() => {
    setRecordsRetentionTerms(modifyRetentionTerm(props.retentionTerms));
  }, [props.retentionTerms]);

  const modifyRetentionTerm = (fields = [], updateFields = false) => {
    const items = (updateFields && [...fields.getAll()]) || fields;
    if (updateFields) fields.removeAll();
    return items.map((item) => {
      const modifiedItem = addKeyToRetentionTerm(item, props.intl.formatMessage);
      if (updateFields) fields.push(modifiedItem);
      return modifiedItem;
    });
  };

  const updateRetentionTerm = (retentionTermObj) => {
    props.fields.push(retentionTermObj);
    const modifedSelectedRetentionTerms = [...selectedRetentionTerms];
    modifedSelectedRetentionTerms.push(retentionTermObj);
    setSelectedRetentionTerms(modifedSelectedRetentionTerms);
  };

  const handleMultipleItems = (selectedItems) => {
    selectedItems.forEach(item => updateRetentionTerm(item));
  };
  const handleRemoveItem = (selectedIndex) => {
    props.fields.remove(selectedIndex);
    const modifedSelectedRetentionTerms = [...selectedRetentionTerms];
    modifedSelectedRetentionTerms.splice(selectedIndex, 1);
    setSelectedRetentionTerms(modifedSelectedRetentionTerms);
  };

  const { ...rest } = props;
  return (
    <div>
      <ItemList
        isEditable={false}
        isNote={false}
        theme={props.theme}
        selectedItems={selectedRetentionTerms}
        handleRemoveItem={handleRemoveItem}
        {...rest}
      />
      <RetentionItemSelector
        hintTextLabel={props.intl.formatMessage(recordTranslations.retentionTermHintText.props)}
        createNewMenuItem={false}
        selectedItems={selectedRetentionTerms}
        masterOffsets={offsetDatasource}
        recordsRetentionTerms={recordsRetentionTerms}
        updateRetentionTerms={handleMultipleItems}
        onSaveRetentionTerm={updateRetentionTerm}
      />
    </div>
  );
};

RetentionTermSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  initRetentionTerms: PropTypes.func,
  retentionTerms: PropTypes.instanceOf(Immutable.List),
  initRetentionTermOffsets: PropTypes.func,
  theme: PropTypes.shape({}).isRequired,
  retentionOffsets: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    push: PropTypes.func,
    insert: PropTypes.func,
    remove: PropTypes.func,
    getAll: PropTypes.func
  }),
  subField: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

RetentionTermSelector.defaultProps = {
  hintTextLabel: null,
  initRetentionTerms: e => e,
  retentionTerms: Immutable.List(),
  initRetentionTermOffsets: e => e,
  retentionOffsets: Immutable.List(),
  fields: {}
};
export default injectIntl(withTheme(RetentionTermSelector));
