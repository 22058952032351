import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

import { renderTextField } from '@packages/components/form-components';
import styles from '@packages/ui/styles';
import { commonTranslations } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';

import { isMasterDataEditFormVisible } from '../../../utils';
import ImpactAnalysisPage from '../../../impact-analysis';
import { renderChipSelector } from '../../../../privacy-record-detail/renderers/renderers';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });
  return errors;
};

export const ProcessingCategoryForm = (props) => {
  const {
    actionError,
    change,
    dataItemId,
    handleSubmit,
    hasNoLimit,
    hideTags,
    init,
    initialize,
    isEdit,
    isJobActive,
    isTagEdit,
    isUsed,
    onCancel,
    processingCategorydata,
    resetProcessingCategories,
    source,
    submitting,
    updateProcessingCategories,
    usage,
    value
  } = props;

  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);
  const [isEditWithJob, setIsEditWithJob] = useState(true);

  useEffect(() => {
    if (dataItemId) init(dataItemId);
    if (!isEdit) handleInitialise();
    return () => {
      resetProcessingCategories();
    };
  }, []);

  useEffect(() => {
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    if (processingCategorydata !== undefined) {
      if (showForm && !isJobActive) {
        const textFieldElement = window.document.getElementById(
          'processingCategoryDialog'
        );
        if (textFieldElement) textFieldElement.focus();
      }
      if (onAfterUpdation) props.onSave(processingCategorydata, closeDialog);
      else handleInitialise(processingCategorydata);
    }
  }, [processingCategorydata]);

  const onSave = (processingCategorydata, closeDialog) => {
    const modifiedData = {
      ...processingCategorydata,
      name: processingCategorydata.name.trim()
    };
    updateProcessingCategories(
      modifiedData,
      isEdit,
      source,
      isUsed,
      isEditWithJob
    );
    setCloseDialog(closeDialog);
    setOnAfterUpdation(true);
    if (!closeDialog) initialize({});
  };

  const handleInitialise = (processingCategoryItem) => {
    if (!isEdit) initialize(value);
    else {
      initialize(processingCategoryItem);
    }
  };

  const handleEditWithJob = (isChecked) => {
    setIsEditWithJob(isChecked);
  };

  const handleTagChange = (data) => {
    change('tags', data);
  };

  const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);

  return (
    <form>
      {isUsed && (
        <ImpactAnalysisPage
          hasNoLimit={hasNoLimit}
          usage={usage}
          handleEditWithJob={handleEditWithJob}
          type="processingCategoryHeader"
          isJobActive={isJobActive}
        />
      )}
      {showForm && !isJobActive && (
        <div>
          <Field
            id="processingCategoryDialog"
            name="name"
            label={commonTranslations.name}
            hintTextLabel={
              <FormattedMessage
                id="ProcessingCategory.nameHint"
                description="Hint text for processing category name"
                defaultMessage="Category name..."
              />
            }
            disabled={isTagEdit}
            autoFocus={!isEdit}
            component={renderTextField}
            showTextField={!isTagEdit}
          />
          {!hideTags && (
            <FieldArray
              id="tags"
              name="tags"
              type="tags"
              entityType="processingCategory"
              disabled={isEdit && !isTagEdit}
              headerLabel={commonTranslations.tags}
              hintText={commonTranslations.addTags}
              updateTags={handleTagChange}
              chipSelectorStyle={{ width: '98%' }}
              showHeader={isTagEdit || !isEdit}
              component={renderChipSelector}
            />
          )}
        </div>
      )}
      {actionError && actionError.isError && (
        <div
          style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}
        >
          {actionError.message}
        </div>
      )}
      <div style={styles.addButton}>
        {showForm && !isJobActive && (
          <Button
            id="submit"
            type="submit"
            disabled={submitting}
            onClick={handleSubmit((processingCategoryData) =>
              onSave(processingCategoryData, true)
            )}
          >
            {commonTranslations.saveAndClose}
          </Button>
        )}
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            onClick={handleSubmit((processingCategoryData) =>
              onSave(processingCategoryData, false)
            )}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}
        {showForm && !isJobActive && (
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        )}
        {((!hasNoLimit && isUsed) || isJobActive) && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
    </form>
  );
};

ProcessingCategoryForm.propTypes = {
  isEdit: PropTypes.bool,
  processingCategorydata: PropTypes.shape({}),
  submitting: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  initialize: PropTypes.func,
  resetProcessingCategories: PropTypes.func,
  updateProcessingCategories: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  isJobActive: PropTypes.bool,
  change: PropTypes.func,
  isTagEdit: PropTypes.bool
};

ProcessingCategoryForm.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  onSave: (e) => e,
  resetProcessingCategories: (e) => e,
  updateProcessingCategories: (e) => e,
  onCancel: (e) => e,
  handleSubmit: (e) => e,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  processingCategorydata: {},
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  isJobActive: false,
  change: (e) => e,
  isTagEdit: false
};

const ProcessingCategoryFormWrapper = reduxForm({
  form: 'ProcessingCategoryForm', // a unique identifier for this form
  validate
})(ProcessingCategoryForm);

export default ProcessingCategoryFormWrapper;
