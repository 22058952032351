/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import { commonTranslations } from '@packages/utils/commontranslations';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import DatasourceDialog from '../../../environment/components/data-sources/components/dataSourceDialog';
import MultipleSelectorDialog from './components/multiple-selector/multipleSelectorDialog';
import { EEACountries, getItemNames } from '../../common-utils';

class DatasourceSelector extends React.Component {
  static filterDatasources(selectedDataSources, searchedItems) {
    const filteredData = searchedItems.filter((dataSource) => {
      const index = selectedDataSources.findIndex(
        (selectedDataSource) => selectedDataSource.value.id === dataSource.id
      );
      return index === -1;
    });
    return filteredData;
  }

  static handleGetMultipleItems(newFields, items) {
    const selectedDataSources = newFields.getAll();
    const multipleDataSourceList =
      selectedDataSources && selectedDataSources.length > 0
        ? DatasourceSelector.filterDatasources(selectedDataSources, items)
        : items;
    return multipleDataSourceList;
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      isEdit: false,
      selectedIndex: -1,
      searchText: '',
      openEdit: false,
      dataSourceList: [],
      multipleDataSourceList: []
    };

    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.saveDataSource = this.saveDataSource.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.performRemove = this.performRemove.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleListItems = this.handleListItems.bind(this);
    this.handleUsageClick = this.handleUsageClick.bind(this);
  }

  componentDidMount() {
    if (this.props.init()) {
      this.props.init();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.getAll() !== this.props.fields.getAll() ||
      this.props.dataSources !== nextProps.dataSources ||
      nextProps.searchResults !== this.props.searchResults
    ) {
      const itemList = this.handleListItems(
        nextProps.searchResults,
        nextProps.dataSources,
        nextProps.fields,
        nextProps.position
      );
      const { dataSourceList, multipleDataSourceList } = itemList;
      this.setState({
        dataSourceList,
        multipleDataSourceList
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(this.props.dataSources === nextProps.dataSources) ||
      !(this.props.prepareLinkGroup === nextProps.prepareLinkGroup)
    );
  }

  handleListItems(searchResults, dataSources, fields, position) {
    const { searchText } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.length > 0
        ? searchResults
        : dataSources;
    const dataSourceList = DatasourceSelector.handleGetMultipleItems(
      fields,
      searchedItems
    );
    const multipleDataSourceList = DatasourceSelector.handleGetMultipleItems(
      fields,
      dataSources
    );
    if (
      (multipleDataSourceList.size < 15 || dataSourceList.size < 15) &&
      dataSources.size > 0 &&
      this.state.searchText === '' &&
      this.props.position !== position
    ) {
      this.props.getNextData(position);
    }
    const itemList = { dataSourceList, multipleDataSourceList };
    return itemList;
  }

  handleAddClick(searchText) {
    this.setState({
      open: true,
      multiple: false,
      selectedIndex: -1,
      isEdit: false,
      inputValue: {
        name: searchText
      }
    });
  }

  handleEditClick(index) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('dataSources', index);
    if (isLinkAssociated) {
      this.setState({
        openEdit: true,
        selectedIndex: index
      });
    } else {
      this.setState({
        open: true,
        multiple: false,
        isEdit: true,
        openEdit: false,
        selectedIndex: index,
        inputValue: this.props.fields.get(index).value
      });
    }
  }

  handleMultipleSelect() {
    this.setState({
      open: false,
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      multiple: false,
      openEdit: false,
      selectedIndex: -1,
      searchText: ''
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const isLinkAssociated =
      this.props.isItemInLinkGroup &&
      this.props.isItemInLinkGroup('dataSources', selectedIndex);
    if (isLinkAssociated) {
      this.setState({
        openDelete: true,
        selectedIndex
      });
    } else {
      this.performRemove(selectedIndex);
    }
  }

  handleClose() {
    this.setState({
      openDelete: false,
      openEdit: false,
      selectedIndex: -1
    });
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    let intlChecked = false;
    const items = this.props.fields.getAll() || [];
    if (selectedItems.size && selectedItems.size > 0) {
      const filteredData = selectedItems.filter(
        (item) =>
          item.value.dataStorageCountry &&
          !EEACountries.includes(item.value.dataStorageCountry)
      );
      if (filteredData.size > 0) {
        intlChecked = true;
      }
    }
    const selectedDataSources = [...items, ...selectedItems];
    this.updateDataSources(selectedDataSources, intlChecked);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem, closeDialog) {
    const modifiedItem = { ...selectedItem };
    const { selectedIndex } = this.state;
    if (selectedIndex !== -1) {
      modifiedItem.note = this.props.fields.get(selectedIndex).note;
    }
    this.handleEditItem(modifiedItem, selectedIndex, closeDialog);
  }

  // Save/update the selected items.
  handleEditItem(selectedItem, selectedIndex, closeDialog) {
    let intlChecked = false;
    const selectedDataSources = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex === -1) {
      selectedDataSources.push(selectedItem);
    } else {
      selectedDataSources.splice(selectedIndex, 1, selectedItem);
    }
    if (
      selectedItem.value.dataStorageCountry &&
      !EEACountries.includes(selectedItem.value.dataStorageCountry)
    ) {
      intlChecked = true;
    }
    this.updateDataSources(selectedDataSources, intlChecked);

    if (closeDialog) {
      this.handleRequestClose();
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position);
  }

  handleUsageClick(index) {
    const { id } = this.props.fields.get(index).value;
    this.props.handleUsageClick(id, this.props.dataItemType);
  }

  saveDataSource(searchText, closeDialog) {
    this.handleSelectedItem(searchText, closeDialog);
  }

  updateDataSources(selectedDataSources, intlChecked) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true, searchText: '' }); // eslint-disable-line react/no-unused-state
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedDataSources);
    } else {
      this.props.updateDataSources(selectedDataSources, intlChecked);
    }
  }

  performRemove(selectedIndex) {
    const selectedDataSources = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedDataSources.splice(selectedIndex, 1);
    }
    if (this.props.isRegistry) {
      this.props.updateRegistryFilters(selectedDataSources);
    } else {
      this.props.updateDataSources(selectedDataSources);
    }

    this.handleClose();
  }

  render() {
    const { showEditIcon, showTextField, userPermissions, isGlobal, disabled } =
      this.props;
    const selectedDataSources = this.props.fields.getAll();

    const { createEditDataSources } = userPermissions.toJS();

    const actions = [
      <Button key="btn_remove_no" onClick={this.handleClose}>
        {commonTranslations.Ok}
      </Button>
    ];

    return (
      <div>
        {!showTextField ? (
          getItemNames(selectedDataSources)
        ) : (
          <div>
            {selectedDataSources && selectedDataSources.length > 0 && (
              <ItemList
                id="item_list"
                {...this.props}
                isEditable={createEditDataSources && showEditIcon}
                type={this.props.label}
                handleRemoveItem={this.handleRemoveItem}
                handleEditClick={this.handleEditClick}
                handleEditItem={this.handleEditItem}
                selectedItems={selectedDataSources}
                handleItemClick={(index) =>
                  this.props.toggleLinkGroupItem('dataSources', index)
                }
                handleUsageClick={this.handleUsageClick}
              />
            )}
            <ItemSelector
              id="item_selector"
              multiValue={this.props.multiValue}
              createNewMenuItem={
                createEditDataSources && this.props.createNewMenuItem
              }
              selectFromListMenuItem={
                this.props.selectFromListMenuItem || false
              }
              selectedItem={this.props.selectedItems}
              dataSource={this.state.dataSourceList}
              dataSourceConfig={{ text: 'key', value: 'key' }}
              hintTextLabel={this.props.hintTextLabel}
              handleSelectedItem={this.handleSelectedItem}
              handleAddClick={this.handleAddClick}
              handleMultipleSelect={this.handleMultipleSelect}
              onSearch={this.handleSearch}
              disabled={disabled}
            />
            <span>
              {this.state.open && (
                <DatasourceDialog
                  id="dataSource_dialog"
                  open={this.state.open}
                  dataItemId={this.state.inputValue && this.state.inputValue.id}
                  inputValue={this.state.inputValue}
                  isNewItem={this.state.selectedIndex === -1}
                  selectedItems={selectedDataSources}
                  isEdit={this.state.isEdit}
                  onRequestClose={this.handleRequestClose}
                  onSaveDataSource={this.saveDataSource}
                  source="records"
                  hideTags={isGlobal}
                />
              )}
              {this.state.multiple && (
                <MultipleSelectorDialog
                  id="multiple_selector"
                  title={
                    <FormattedMessage
                      id="MultipleDatasourceDialog.header"
                      description="Select Data Source Dialog Header"
                      defaultMessage="Select data sources"
                    />
                  }
                  open={this.state.multiple}
                  onScrollStop={this.handleScrollEnd}
                  onChooseFilter={this.props.onChooseFilter}
                  onresetFilter={this.props.init}
                  filteredData={this.state.multipleDataSourceList}
                  onRequestClose={this.handleRequestClose}
                  handleMultipleItems={this.handleMultipleItems}
                />
              )}
              {this.state.openDelete && (
                <CommonDialog
                  id="delete-confirm-dialog"
                  show={this.state.openDelete}
                  onCancel={this.handleClose}
                  maxWidth="sm"
                  fullWidth={true}
                  buttonActions={actions}
                  showCloseIcon={false}
                  buttonPosition="right"
                >
                  {commonTranslations.linkItemDeletefromGroup}
                </CommonDialog>
              )}
              {this.state.openEdit && (
                <CommonDialog
                  id="edit-confirm-dialog"
                  show={this.state.openEdit}
                  onCancel={this.handleClose}
                  maxWidth="sm"
                  fullWidth={true}
                  buttonActions={actions}
                  showCloseIcon={false}
                  buttonPosition="right"
                >
                  {commonTranslations.linkItemUpdatefromGroup}
                </CommonDialog>
              )}
            </span>
          </div>
        )}
      </div>
    );
  }
}

DatasourceSelector.propTypes = {
  dataSources: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  init: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  position: PropTypes.number,
  getNextData: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  toggleLinkGroupItem: PropTypes.func,
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  addDataSources: PropTypes.func,
  editDataSources: PropTypes.func,
  onChooseFilter: PropTypes.func,
  updateDataSources: PropTypes.func,
  prepareLinkGroup: PropTypes.bool,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  handleUsageClick: PropTypes.func,
  dataItemType: PropTypes.string,
  showTextField: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  updateRegistryFilters: PropTypes.func,
  isRegistry: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  isGlobal: PropTypes.bool,
  disabled: PropTypes.bool
};

DatasourceSelector.defaultProps = {
  multiValue: true,
  dataSources: Immutable.List(),
  createNewMenuItem: false,
  addDataSources: (e) => e,
  editDataSources: (e) => e,
  updateDataSources: (e) => e,
  label: null,
  selectedItems: null,
  hintTextLabel: null,
  getNextData: (e) => e,
  position: 0,
  init: (e) => e,
  isItemInLinkGroup: () => {},
  prepareLinkGroup: false,
  toggleLinkGroupItem: () => {},
  selectFromListMenuItem: false,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  onChooseFilter: (e) => e,
  handleUsageClick: (e) => e,
  dataItemType: '',
  showTextField: false,
  showEditIcon: true,
  updateRegistryFilters: (e) => e,
  isRegistry: false,
  userPermissions: Immutable.Map(),
  isGlobal: false,
  disabled: false
};

export default DatasourceSelector;
