import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchHelpNotes() {
  yield put({ type: 'HELPTEXT:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get('/v1/settings/helpnotes');
    switch (response.status) {
      case 200: {
        yield put({ type: 'HELPTEXT:FETCH:SUCCESS', data: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* updateHelpNotes(action) {
  const { helpNotes } = action;
  try {
    const request = registry.get('request');
    const response = yield request.put('/v1/settings/helpnotes', helpNotes);
    switch (response.status) {
      case 200: {
        yield put({ type: 'HELPTEXT:VALUE:UPDATE', data: response.body });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.helpNotesUpdateSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
