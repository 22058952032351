import { takeEvery } from 'redux-saga/effects';

import {
  createDataLibraryJob,
  disconnectRecordSync,
  disconnectTemplateSync,
  fetchDataLibraryListItems,
  filterDataLibraryListItems,
  getAllLibraryTenants,
  getDataLibraryFeatures,
  resetAndFetchDataLibraryListItems,
  searchDataLibraryListItems
} from './sagas';
import {
  fetchDataLibraryJobs,
  filterDataLibraryJobs,
  resetAndFetchDataLibraryJobs,
  retryOrTerminateDataLibraryJob
} from './components/jobs/sagas';
import {
  importLibraryMasterData,
  sendPurchaseMailData
} from './components/master-data-library/sagas';

export default [
  [takeEvery, 'DATA_LIBRARY:LIBRARY_TENANTS:FETCH', getAllLibraryTenants],
  [takeEvery, 'DATA_LIBRARY:ACCESSIBLE_LIBRARY:FETCH', getDataLibraryFeatures],
  [takeEvery, 'DATA_LIBRARY:SAMPLE_DATA:IMPORT', importLibraryMasterData],

  [takeEvery, 'DATA_LIBRARY:JOBS:REQUEST:INIT', resetAndFetchDataLibraryJobs],
  [takeEvery, 'DATA_LIBRARY:JOBS:FILTER_INIT', filterDataLibraryJobs],
  [
    takeEvery,
    'DATA_LIBRARY:JOBS:ACTION:REQUEST',
    retryOrTerminateDataLibraryJob
  ],
  [takeEvery, 'DATA_LIBRARY:JOBS:NEXT_FETCH', fetchDataLibraryJobs],

  [
    takeEvery,
    'DATA_LIBRARY:LIST_ITEMS:REQUEST:INIT',
    resetAndFetchDataLibraryListItems
  ],
  [
    takeEvery,
    'DATA_LIBRARY:LIST_ITEMS:FILTER_INIT',
    filterDataLibraryListItems
  ],
  [takeEvery, 'DATA_LIBRARY:LIST_ITEMS:NEXT_FETCH', fetchDataLibraryListItems],
  [
    takeEvery,
    'DATA_LIBRARY:LIST_ITEMS:SEARCH_INIT',
    searchDataLibraryListItems
  ],
  [takeEvery, 'DATA_LIBRARY:JOB:CREATE', createDataLibraryJob],
  [takeEvery, 'DATA_LIBRARY:PURCHASE_MAIL:SEND', sendPurchaseMailData],
  [takeEvery, 'DATA_LIBRARY:DISCONNECT_SYNC:RECORD', disconnectRecordSync],
  [takeEvery, 'DATA_LIBRARY:DISCONNECT_SYNC:TEMPLATE', disconnectTemplateSync]
];
