import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table';
import { injectIntl } from 'react-intl';

import styles from '@packages/ui/styles';
import vendorTranslations from '@packages/utils/vendorTranslations';
import { recordTranslations } from '@packages/utils/commontranslations';

export const DataRiskArrayCell = injectIntl(
  ({ rowIndex, items, intl: { formatMessage }, ...props }) => {
    const { dpiaRisks } = items.get(rowIndex);
    const dataCellStyle = {
      ...styles.textWithEllipsis,
      width: props.width - 20,
      display: 'flex',
      marginTop: 5
    };

    const renderRisks = () => (
      <div style={dataCellStyle}>
        {dpiaRisks.high > 0 && (
          <div>
            <span style={{ color: 'red' }}>{`${dpiaRisks.high} ${formatMessage(
              vendorTranslations.high.props
            )}`}</span>
            {(dpiaRisks.medium > 0 || dpiaRisks.low > 0) && <span>,</span>}
            &nbsp;
          </div>
        )}
        {dpiaRisks.medium > 0 && (
          <div>
            <span style={{ color: 'orange' }}>
              {` ${dpiaRisks.medium} ${formatMessage(
                vendorTranslations.medium.props
              )}`}
            </span>
            {dpiaRisks.low > 0 && <span>,</span>}
            &nbsp;
          </div>
        )}
        {dpiaRisks.low > 0 && (
          <span style={{ color: 'blue' }}>
            {` ${dpiaRisks.low} ${formatMessage(vendorTranslations.low.props)}`}
          </span>
        )}
        {!dpiaRisks.high && !dpiaRisks.medium && !dpiaRisks.low && (
          <span style={{ color: 'green' }}>{recordTranslations.none}</span>
        )}
      </div>
    );
    return <Cell>{dpiaRisks && renderRisks()}</Cell>;
  }
);

DataRiskArrayCell.propTypes = {
  rowIndex: PropTypes.number,
  items: PropTypes.instanceOf(Immutable.List),
  width: PropTypes.number
};

DataRiskArrayCell.defaultProps = {
  rowIndex: -1,
  items: Immutable.List(),
  width: 100
};
