import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

import messageTranslations from '../../messageTranslations';
import { getReducerTypeForNotifications } from '../../utils';

export function* resetAndfetchNotifications(action) {
  const { currentSubmenu } = action;
  yield put({ type: `NOTIFICATIONS:${getReducerTypeForNotifications(currentSubmenu)}:LIST:INIT` });
  yield fetchNotifications(action);
}

export function* fetchNotifications(action) {
  const { currentSubmenu } = action;
  const reducerType = getReducerTypeForNotifications(currentSubmenu);
  const position = action && action.position ? action.position : 0;
  const rowCount = action && action.rowCount ? action.rowCount : 15;
  yield put({ type: `NOTIFICATIONS:${reducerType}:LIST:FETCH` });
  try {
    const notificationState = yield select(state => state.notifications[currentSubmenu]);
    const filterParams = notificationState ? notificationState.get('filterParams').toJS() : {};
    const searchTextObj = notificationState && notificationState.get('searchText').toJS();
    const searchParameters = searchTextObj ?
      { searchKey: 'allFields', searchText: searchTextObj.allFields } : {};
    const filteredOn = notificationState ? notificationState.get('filteredOn') : Immutable.Map();
    const { sortOn, sortOrder } = filterParams;

    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        position, searchParameters, rowCount, currentSubmenu), null, {});
    switch (response.status) {
      case 200: {
        const modifiedResponse = transformResponseData(response.body);
        yield put({
          type: `NOTIFICATIONS:${reducerType}:LIST:FETCH:SUCCESS`,
          items: modifiedResponse
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'notification');
    yield put({ type: `NOTIFICATIONS:${reducerType}:LIST:FETCH:FAIL`, error: err.message });
  }
}

export function* filterNotifications(action) {
  const { currentSubmenu } = action;
  const filterParams = action.filterParams ? action.filterParams : {};

  yield put({ type: `NOTIFICATIONS:${getReducerTypeForNotifications(currentSubmenu)}:LIST:FILTER`, filterParams });
  yield fetchNotifications(action);
}

export function* searchNotifications(action) {
  let { searchParams } = action;
  const { currentSubmenu } = action;
  const searchResultsCount = 100;
  const reducerType = getReducerTypeForNotifications(currentSubmenu);
  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  const notificationState = yield select(state => state.notifications[currentSubmenu]);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = notificationState ? notificationState.get('searchText') : Immutable.Map();
    searchText = searchTextObj.get(searchKey) || '';
    searchParams = { ...searchParams, searchText };
  }
  const filterParams = notificationState ? notificationState.get('filterParams').toJS() : {};
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = notificationState ? notificationState.get('filteredOn') : Immutable.Map();

  yield put({ type: `NOTIFICATIONS:${reducerType}:LIST:SEARCH`, searchKey, searchText });
  try {
    const url = getURLWithParams({ sortOn, sortOrder, filteredOn }, action.position
      ? action.position : 0, searchParams, searchResultsCount, currentSubmenu);
    const response = yield registry.get('request')
      .get(url, null, {});
    switch (response.status) {
      case 200: {
        const modifiedResponse = transformResponseData(response.body);
        yield put({
          type: `NOTIFICATIONS:${reducerType}:LIST:SEARCH:SUCCESS`,
          searchKey,
          items: modifiedResponse,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'notification');
    registry.get('logger').error(err);
    yield put({ type: `NOTIFICATIONS:${reducerType}:LIST:SEARCH:FAIL`, error: err.message });
  }
}

export function* markAllNotificationRead(action) {
  const { currentSubmenu } = action;
  const reducerType = getReducerTypeForNotifications(currentSubmenu);
  try {
    const url = '/v1/notifications/mark-read';
    const response = yield registry.get('request')
      .post(url, null);

    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.notificationStatusUpdate,
            type: 'success'
          }
        });

        const notificationState = yield (select(state => state.notifications[currentSubmenu]));
        const notificationItems = notificationState.get('items');
        const items = markAllItemsRead(notificationItems);
        yield put({ type: `NOTIFICATIONS:${reducerType}:LIST:UPDATE`, items });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'notification');
    yield put({ type: `NOTIFICATION:${reducerType}:UPDATE:FAIL`, error: err.message });
  }
}

function markAllItemsRead(items) {
  return items.map(item => ({
    ...item,
    status: 'READ'
  }));
}

export function* deleteNotification(action) {
  const { id, currentSubmenu } = action;
  const request = registry.get('request');

  try {
    const response = yield request.post(`/v1/notifications/remove`, { notificationIds: [id] });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.notificationDeleted,
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        const notificationState = yield select(state => state.notifications[currentSubmenu]);
        const notificationItems = notificationState.get('items');
        const items = updateItems(notificationItems, id);
        yield put({ type: `NOTIFICATIONS:${getReducerTypeForNotifications(currentSubmenu)}:LIST:UPDATE`, items });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'notification');
  }
}


const updateNotificationItems = (notificationItems, notificationId, modifiedItem) => {
  const items = notificationItems.map((item) => {
    if (item.id === notificationId) {
      return modifiedItem;
    }
    return item;
  });
  return items;
};

const updateItems = (notificationItems, notificationId) => {
  const index = notificationItems.findIndex(notification => notification.id === notificationId);
  const items = notificationItems.remove(index);
  return items;
};

function transformResponseData(items) {
  return items.map(item => ({
    ...item,
    createdAt: item.creationDate
  }));
}

export function* bulkDeleteNotifications(action) {
  const { items } = action;
  const request = registry.get('request');
  try {
    const notificationIds = items.map(item => (item.id));
    const response = yield request.post(`/v1/notifications/remove`, { notificationIds });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.BulkDeleteSuccess,
            type: 'success'
          }
        });
        yield resetAndfetchNotifications(action);
        yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
  }
}

export function* markReadOrUnreadNotifications(action) {
  const { items = undefined, markAsRead, selectedItemId, currentSubmenu } = action;
  const reducerType = getReducerTypeForNotifications(currentSubmenu);
  const request = registry.get('request');
  try {
    let entityIds = [];
    if (items) {
      entityIds = items.map(item => (item.id));
    } else {
      entityIds.push(selectedItemId);
    }
    const requestData = { entityIds, readStatus: markAsRead };
    const isunReadStatusUpdate = currentSubmenu === 'unReadNotifications' && markAsRead;
    const notificationState = yield select(state => state.notifications[currentSubmenu]);
    const notificationItems = notificationState.get('items');
    const response = yield request.post(`/v1/notifications/update/status`, requestData);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.notificationStatusUpdate,
            type: 'success'
          }
        });
        if (items) {
          if (isunReadStatusUpdate) {
            yield put({ type: `NOTIFICATIONS:${reducerType}:BULK:ITEMS:RESET` });
          }
          yield resetAndfetchNotifications(action);
          yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
        } else if (isunReadStatusUpdate) {
          const updatedItems = updateItems(
            notificationItems,
            selectedItemId
          );
          yield put({
            type: `NOTIFICATIONS:${reducerType}:LIST:UPDATE`,
            items: updatedItems
          });
          yield put({
            type: `NOTIFICATION:${reducerType}:ITEM:CHECK`,
            dataItemId: selectedItemId
          });
        } else {
          const updatedNotification = response.body[0];
          const updatedItems = updateNotificationItems(
            notificationItems,
            selectedItemId,
            { ...updatedNotification, createdAt: updatedNotification.creationDate }
          );
          yield put({
            type: `NOTIFICATIONS:${reducerType}:LIST:UPDATE`,
            items: updatedItems
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
  }
}

export const getURLWithParams = (filterParams, position, searchParams, rowCount = 15, currentSubmenu) => {
  let url = `/v1/notifications?filter=registerModuleNotifications=true&offset=${position}&numberOfResults=${rowCount}`;
  if (currentSubmenu === 'unReadNotifications') {
    url = `${url}&filter=status=NEW`;
  }
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText = (typeof (x) === 'object') ? x.props.defaultMessage : x;
          (url = `${url}&filter=${filterKey}%3D${encodeURIComponent(filterText)}`);
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

