import Immutable from 'immutable';

import { generateModifiedFilterMenu, getDefaultFilter } from '../utils';

const defaultState = Immutable.fromJS({
  allLibraryTenants: [],
  dataLibraryFilterMenu: [],
  currentFilter: '',
  openStayOrNavigate: false,
  dataLibraryFeatures: [],
  openSuccessDialog: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DATA_LIBRARY:LIBRARY_TENANTS:FETCH:SUCCESS': {
      return state.set('allLibraryTenants', action.data);
    }
    case 'DATA_LIBRARY:ACCESSIBLE_LIBRARY:FETCH:SUCCESS': {
      const modifiedFilterMenu = generateModifiedFilterMenu(action.data);
      return state
        .set('dataLibraryFilterMenu', modifiedFilterMenu)
        .set('dataLibraryFeatures', action.data.features)
        .set(
          'currentFilter',
          getDefaultFilter(
            modifiedFilterMenu[0].defaultFilter,
            action.data.recordTypes,
            action.currentFilter
          )
        );
    }
    case 'DATA_LIBRARY:FILTER:CHANGE': {
      return state.set('currentFilter', action.filterName);
    }
    case 'DATA_LIBRARY:JOB:CREATE:SUCCESS': {
      return state
        .set('openStayOrNavigate', action.openStayOrNavigate)
        .set('entityCreationError', null);
    }
    case 'DATA_LIBRARY:JOB:CREATE:FAIL': {
      return state.set('entityCreationError', action.error);
    }
    case 'DATA_LIBRARY:CREATE_ENTITY_ERROR:RESET': {
      return state.set('entityCreationError', null);
    }
    case 'DATA_LIBRARY:MAIL:CREATE:SUCCESS': {
      return state.set('sendPurchaseMailSuccess', action.sendPurchaseMail);
    }
    case 'PURCHASE_MAIL:SUCCESS:DIALOG_OPEN': {
      return state.set('openSuccessDialog', action.openSuccessDialog);
    }
    case 'PURCHASE_MAIL:SUCCESS:DIALOG_CLOSE': {
      return state.set('openSuccessDialog', action.openSuccessDialog);
    }
    case 'DATA_LIBRARY:CREATE_ENTITY_DIALOG:TOGGLE': {
      return state.set('openCreateEntityDialog', action.value);
    }
    default:
      return state;
  }
};
