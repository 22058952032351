import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import {
  handleServiceDown,
  getErrorMessage
} from '@packages/utils/common-utils';
import notificationTranslation from '@packages/utils/notificationtranslations';

export function* fetchReleaseNotes() {
  try {
    const request = registry.get('request');
    const response = yield request.get('/v1/admin-updates', null, {});
    switch (response.status) {
      case 200:
      case 204: {
        const items = response.body;
        yield put({
          type: 'ADMIN:RELEASENOTE:FETCH:SUCCESS',
          data: items
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'ADMIN:RELEASENOTE:FETCH:FAIL', error: err.message });
  }
}

export function* updateReleaseNotes(action) {
  const { data } = action;
  const modifiedData = { message: data.releaseNote };
  const request = registry.get('request');
  try {
    const response = yield request.post('/v1/admin-updates', modifiedData);
    switch (response.status) {
      case 200: {
        const items = response.body;
        yield put({
          type: 'ADMIN:RELEASENOTE:UPSERT:SUCCESS',
          data: items
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationTranslation.releaseNotesUpdateSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        const error = response.body ? response.body.msg : '';
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'ADMIN:RELEASENOTE:UPSERT:FAIL', error });
  }
}
