import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import { NO_LIMIT, getModifiedRecordType } from '@packages/utils/common-utils';

import PrivacyRecordsHeaderComponent from './privacyRecordsHeader';

function mapStateToProps(state) {
  const currentFilter = getModifiedRecordType(
    state.privacyRecords.filters.get('currentFilter')
  );
  return {
    listItems: state.privacyRecords.list.get('items'),
    currentFilter: state.privacyRecords.filters.get('currentFilter'),
    recordType: state.privacyRecords.list.get('recordType'),
    prevLocation: state.privacyRecords.filters.get('prevLocation'),
    filteredOn: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(`${currentFilter}`).filteredOn,
    filterParams: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(`${currentFilter}`).filterParams,
    selectedRecords: state.privacyRecords.list
      .get('bulkItems')
      .get(`${currentFilter}`).selectedRecords,
    isDownloading: state.privacyRecords.list.get('isDownloading'),
    searchText: state.privacyRecords.list.get('sortAndFilterParams'),
    pricingPlan: state.login?.get('pricingPlanDetails'),
    visibility:
      state.fieldVisibilitySettings.get('visibilityFields').processing,
    showValidationErrors: state.privacyRecords.bulkUpdate.get(
      'showValidationErrors'
    ),
    tenantLocale: state.login?.get('tenantLocale'),
    locale: state.intl.locale,
    userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
    hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT,
    isTemplateMode: state.privacyRecords.list.get('isTemplateMode')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleColumn: (columnName) => {
      dispatch({
        type: 'PRIVACY_RECORDS:COLUMNS:TOGGLE_VISIBILITY',
        columnName
      });
    },
    removeFilters: (recordType, recordLayoutId, isTemplateMode) => {
      dispatch({ type: 'PRIVACY_RECORDS:FILTER:RESET', recordType });
      if (recordType === 'all') {
        dispatch({
          type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
          recordType: 'all',
          isTemplateMode
        });
      } else {
        dispatch({
          type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
          recordType,
          recordLayoutId,
          isTemplateMode
        });
      }
    },
    recordsBulkExport: (
      recordType,
      selectedRecords,
      exportType,
      signal,
      controller
    ) => {
      dispatch({
        type: 'PRIVACY_RECORDS:BULK:EXPORT',
        recordType,
        selectedRecords,
        exportType,
        signal,
        controller
      });
    },
    toggleLoader: (toggle, content, handleAbort) => {
      dispatch({ type: 'LOADER:TOGGLE', toggle, content, handleAbort });
    },
    onSearch: (searchParams, recordType, recordLayoutId, isTemplateMode) => {
      dispatch({
        type: 'PRIVACY_RECORDS:LIST:SEARCH_INIT',
        searchParams,
        recordType,
        recordLayoutId,
        isTemplateMode
      });
    },
    bulkDelete: (data) => {
      dispatch({
        type: 'PRIVACY_RECORDS:LIST:BULK_DELETE',
        data,
        recordType: 'processing'
      });
    },
    resetBulkItems: () => {
      dispatch({
        type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
        recordType: 'processing',
        selectedRecords: []
      });
    },
    resetValidationErrors: () => {
      dispatch({ type: 'RESET:VALIDATION:ERRORS' });
    },
    resetCheckedFields: (property, data) => {
      dispatch({ type: 'BULK:UPDATE_CHECK:PROPERTY:UPDATE', property, data });
    },
    toggleMode: (checked, recordType) => {
      dispatch({ type: 'PRIVACY_RECORDS:FILTER:RESET', recordType });
      dispatch({ type: 'RECORDS:MODE:TOGGLE', checked });
    }
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PrivacyRecordsHeaderComponent)
);
