import Immutable from 'immutable';

const defaultState = Immutable.Map({
  subtenantActions: []
});

export default (state = defaultState, action) => {
  const { subtenantActions } = action;

  switch (action.type) {
    case 'DASHBOARD:SUBTENANT:POST_LOGIN:ACTIONS:SET':
      return state.set('subtenantActions', subtenantActions);
    default:
      return state;
  }
};
