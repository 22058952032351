import Immutable from 'immutable';

import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';
import { filterEmptySearchItems } from '@packages/utils/common-utils';
import { eventCategoryTranslations } from '@packages/utils/commontranslations';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'timestamp',
    sortOrder: 'DESC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  searchItems: Immutable.Map()
});

const PPSystemUserId = '555738b1-3857-4226-b09b-acd9674da555';

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'AUDIT_TRAILS:LIST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('error', defaultState.get('error'))
        .set('position', 0);
    case 'AUDIT_TRAIL:LIST:FETCH:SUCCESS':
      return state
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'AUDIT_TRAIL:LIST:FETCH:FAIL':
      return state
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'AUDIT_TRAIL:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('position', 0)
        .set('items', Immutable.List())
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('initialItems', Immutable.List(action.items))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'AUDIT_TRAIL:LIST:SEARCH:SUCCESS':
      return state
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', getSearchItems(action))
        .set('error', defaultState.get('error'));
    default:
      return state;
  }
};

export const getSearchItems = ({ searchKey, searchText, items }) => {
  let listItems = [];
  switch (searchKey) {
    case 'name': {
      listItems = Array.from(new Set(items.map(item => (item.firstName || item.lastName ?
        { label: `${item.firstName || ''} ${item.lastName || ''}`, value: item.id } : ''))));
      if (searchText === '' || ('system'.indexOf(searchText.toLowerCase()) >= 0)) {
        listItems.push({ label: 'System', value: PPSystemUserId });
      }
      break;
    }
    case 'event':
      listItems = Array.from(new Set(items.map(item => (item.eventCode ? item.eventCode : ''))));
      break;
    case 'eventCategory':
      listItems = Array.from(new Set(items.map(item => (item.eventCategory ?
        { label: eventCategoryTranslations(item.eventCategory), value: item.eventCategory } : ''))));
      break;
    default:
      listItems = Array.from(new Set(items.map(item => item[searchKey])));
      break;
  }
  const updatedItems = checkForDuplicate(listItems, searchKey);
  const searchItems = filterEmptySearchItems(updatedItems, searchKey);
  return searchItems;
};

const checkForDuplicate = (listItems, searchKey) => {
  if (searchKey === 'eventCategory') {
    const items = [];
    listItems.forEach((item) => {
      let index = -1;
      index = items.findIndex(value => (value.value === item.value));
      if (index === -1) {
        items.push(item);
      }
    });
    return items;
  }
  return listItems;
};
