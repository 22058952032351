import React from 'react';
import { FormattedMessage } from 'react-intl';

export const commonTranslations = {
  other: (
    <FormattedMessage
      id="CommonType.others"
      description="Other, please specify"
      defaultMessage="Other, please specify:"
    />
  ),
  otherReason: (
    <FormattedMessage
      id="CommonType.otherReasons"
      description="Other, including restrictions by national legislative measures; please specify:"
      defaultMessage="Other, including restrictions by national legislative measures; please specify:"
    />
  ),
  uploadFile: (
    <FormattedMessage
      id="Common.uploadFile"
      description="Upload file"
      defaultMessage="Upload file..."
    />
  )
};

export const legalGroundTranslations = {
  helpNote: (
    <FormattedMessage
      id="Legalground.helpNote"
      description="Help note for legal grounds"
      defaultMessage="Click on the column headers to apply filters to the displayed grounds or to sort them.
     Then select one or more grounds and click Save and close."
    />
  ),
  permissions: (
    <FormattedMessage
      id="Legalground.permissions"
      description="Permissions"
      defaultMessage="Permissions"
    />
  ),
  jurisdiction: (
    <FormattedMessage
      id="Legalground.jurisdiction"
      description="Legal Ground List Column Jurisdiction"
      defaultMessage="Jurisdiction"
    />
  ),
  law: (
    <FormattedMessage
      id="Legalground.law"
      description="Legal Ground List Column Law"
      defaultMessage="Law"
    />
  ),
  article: (
    <FormattedMessage
      id="Legalground.article"
      description="Legal Ground List Column Article"
      defaultMessage="Article"
    />
  )
};

export const natureOfBreachTranslations = {
  breach_nature_reading: (
    <FormattedMessage
      id="BreachNature.reading"
      description="Reading"
      defaultMessage="Reading"
    />
  ),
  breach_nature_copying: (
    <FormattedMessage
      id="BreachNature.copying"
      description="Copying"
      defaultMessage="Copying"
    />
  ),
  breach_nature_alteration: (
    <FormattedMessage
      id="BreachNature.alteration"
      description="Alteration"
      defaultMessage="Alteration"
    />
  ),
  breach_nature_deletion: (
    <FormattedMessage
      id="BreachNature.deletion"
      description="Deletion"
      defaultMessage="Deletion"
    />
  ),
  breach_nature_unavailability: (
    <FormattedMessage
      id="BreachNature.unavailability"
      description="Unavailability"
      defaultMessage="Unavailability"
    />
  ),
  breach_nature_unauthorised_disclosure: (
    <FormattedMessage
      id="BreachNature.unauthorisedDisclosure"
      description="Unauthorised disclosure"
      defaultMessage="Unauthorised disclosure"
    />
  ),
  breach_nature_theft: (
    <FormattedMessage
      id="BreachNature.theft"
      description="Theft"
      defaultMessage="Theft"
    />
  ),
  breach_nature_others: commonTranslations.other
};

export const breachConsequenceTranslations = {
  breach_consequences_damage_of_reputation: (
    <FormattedMessage
      id="BreachConsequence.damageOfReputation"
      description="Damage to reputation"
      defaultMessage="Damage to reputation"
    />
  ),
  breach_consequences_discrimination: (
    <FormattedMessage
      id="BreachConsequence.discrimination"
      description="Discrimination"
      defaultMessage="Discrimination"
    />
  ),
  breach_consequences_financial_loss: (
    <FormattedMessage
      id="BreachConsequence.financialLoss"
      description="Financial loss"
      defaultMessage="Financial loss"
    />
  ),
  breach_consequences_fraud: (
    <FormattedMessage
      id="BreachConsequence.fraud"
      description="Fraud"
      defaultMessage="Fraud"
    />
  ),
  breach_consequences_health_damage: (
    <FormattedMessage
      id="BreachConsequence.healthDamage"
      description="Health damage"
      defaultMessage="Health damage"
    />
  ),
  breach_consequences_identity_theft: (
    <FormattedMessage
      id="BreachConsequence.identityTheft"
      description="Identity theft"
      defaultMessage="Identity theft"
    />
  ),
  breach_consequences_limitation_of_rights: (
    <FormattedMessage
      id="BreachConsequence.limitationOfRights"
      description="Limitation Of Rights"
      defaultMessage="Limitation of rights"
    />
  ),
  breach_consequences_loss_of_confidentiality: (
    <FormattedMessage
      id="BreachConsequence.lossOfConfidentiality"
      description="Loss of confidentiality"
      defaultMessage="Loss of confidentiality"
    />
  ),
  breach_consequences_loss_of_control_over_personal_data: (
    <FormattedMessage
      id="BreachConsequence.lossOfControlOverPersonalData"
      description="Loss of control over personal data"
      defaultMessage="Loss of control over personal data"
    />
  ),
  breach_consequences_significant_economic_disadvantage: (
    <FormattedMessage
      id="BreachConsequence.significantEconomicDisadvantage"
      description="Significant economic disadvantage"
      defaultMessage="Significant economic disadvantage"
    />
  ),
  breach_consequences_significant_social_disadvantage: (
    <FormattedMessage
      id="BreachConsequence.significantSocialDisadvantage"
      description="Significant social disadvantage"
      defaultMessage="Significant social disadvantage"
    />
  ),
  breach_consequences_spam_or_phishing: (
    <FormattedMessage
      id="BreachConsequence.spamOrPhishing"
      description="Spam or phishing"
      defaultMessage="Spam or phishing"
    />
  ),
  breach_consequences_stigma_or_exclusion: (
    <FormattedMessage
      id="BreachConsequence.stigmaOrExclusion"
      description="Stigma or exclusion"
      defaultMessage="Stigma or exclusion"
    />
  ),
  breach_consequences_unauthorised_reversal_of_pseudonymisation: (
    <FormattedMessage
      id="BreachConsequence.unauthorisedReversalOfPseudonymisation"
      description="Unauthorised reversal of pseudonymisation"
      defaultMessage="Unauthorised reversal of pseudonymisation"
    />
  ),
  breach_consequences_others: commonTranslations.other
};

export const awarenessTypeTranslations = {
  defaultMethod: (
    <FormattedMessage
      id="AwarenessTranslation.defaultAwarenessType"
      description="How did you become aware..."
      defaultMessage="How did you become aware..."
    />
  ),
  awareness_method_audit: (
    <FormattedMessage
      id="AwarenessTranslation.audit"
      description="Audit"
      defaultMessage="Audit"
    />
  ),
  awareness_method_internal: (
    <FormattedMessage
      id="AwarenessTranslation.internal"
      description="Internal"
      defaultMessage="Internal"
    />
  ),
  awareness_method_responsible_disclosure: (
    <FormattedMessage
      id="AwarenessTranslation.responsibleDisclosure"
      description="Responsible Disclosure"
      defaultMessage="Responsible disclosure"
    />
  ),
  awareness_method_unknown: (
    <FormattedMessage
      id="Common.unknown"
      description="Unknown"
      defaultMessage="Unknown"
    />
  ),
  awareness_method_others: (
    <FormattedMessage
      id="Common.other"
      description="Other"
      defaultMessage="Other"
    />
  )
};

export const awarenessValueMapper = (awareness) =>
  awarenessTypeTranslations[awareness] || awareness;

export const necessityTranslations = {
  no: (
    <FormattedMessage
      id="Necessity.no"
      description="No personal data"
      defaultMessage="No personal data"
    />
  ),
  fewer: (
    <FormattedMessage
      id="Necessity.fewer"
      description="Fewer personal data"
      defaultMessage="Fewer personal data"
    />
  ),
  pseudonymised: (
    <FormattedMessage
      id="Necessity.pseudonymised"
      description="Pseudonymised personal data"
      defaultMessage="Pseudonymised personal data"
    />
  ),
  anonymised: (
    <FormattedMessage
      id="Necessity.anonymised"
      description="Anonymised personal data"
      defaultMessage="Anonymised personal data"
    />
  )
};

export const processingCharacteristicsTranslations = {
  processing_characteristics_automated_processing: (
    <FormattedMessage
      id="ProcessingCharacteristics.automated_processing"
      description="Automated processing including profiling"
      defaultMessage="Automated decision making with legal/similarly significant effect"
    />
  ),
  processing_characteristics_largescale_processing: (
    <FormattedMessage
      id="ProcessingCharacteristics.largescale_processing"
      description="Data processed on a large scale"
      defaultMessage="Data processed on a large scale"
    />
  ),
  processing_characteristics_sensitive_personal_data_with_special_categories: (
    <FormattedMessage
      id="ProcessingCharacteristics.sensitive_personal_data_with_special_categories"
      description="Processing crime-related personal data"
      defaultMessage="Sensitive data or data of a highly personal nature,
    including special categories of data or personal data relating to criminal convictions of offences"
    />
  ),
  processing_characteristics_public_places_systematic_monitoring: (
    <FormattedMessage
      id="ProcessingCharacteristics.public_places_systematic_monitoring"
      description="Systematic monitoring of public places"
      defaultMessage="Systematic monitoring (including of publicly accessible area)"
    />
  ),
  processing_characteristics_national_supervisory_authority: (
    <FormattedMessage
      id="ProcessingCharacteristics.national_supervisory_authority"
      description="A processing category on the list of the national supervisory authority"
      defaultMessage="A processing category on the list of the national supervisory authority"
    />
  ),
  processing_characteristics_matching_or_combining_datasets: (
    <FormattedMessage
      id="ProcessingCharacteristics.matching_or_combining_datasets"
      description="Matching or combining data sets"
      defaultMessage="Matching/ Combining data sets"
    />
  ),
  processing_characteristics_prevents_from_exercising_rights: (
    <FormattedMessage
      id="ProcessingCharacteristics.prevents_from_exercising_rights"
      description="Processing prevents data subjects from exercising a right"
      defaultMessage="Processing prevents data subjects from exercising a right/using a service/ contract"
    />
  ),
  processing_characteristics_vulnerable_data_subjects: (
    <FormattedMessage
      id="ProcessingCharacteristics.vulnerable_data_subjects"
      description="Data concerning vulnerable subjects"
      defaultMessage="Data concerning vulnerable subjects"
    />
  ),
  processing_characteristics_new_technology_or_innovative_use: (
    <FormattedMessage
      id="ProcessingCharacteristics.new_technology_or_innovative_use"
      description="Innovative use of new technological/organisational solution"
      defaultMessage="Innovative use/ applying new technological/organisational solution"
    />
  ),
  processing_characteristics_evaluation_or_scoring: (
    <FormattedMessage
      id="ProcessingCharacteristics.evaluation_or_scoring"
      description="Evaluation or Scoring"
      defaultMessage="Evaluation/Scoring (including profiling and predicting)"
    />
  ),
  processing_characteristics_sensitive_personal_data: (
    <FormattedMessage
      id="ProcessingCharacteristics.sensitive_personal_data"
      description="Sensitive personal data"
      defaultMessage="Sensitive data or data of a highly personal nature"
    />
  ),
  processing_characteristics_innovative_technology: (
    <FormattedMessage
      id="ProcessingCharacteristics.innovative_technology"
      description="Innovative technology"
      defaultMessage="Innovative technology"
    />
  ),
  processing_characteristics_denial_of_service: (
    <FormattedMessage
      id="ProcessingCharacteristics.denial_of_service"
      description="Denial of service"
      defaultMessage="Denial of service"
    />
  ),
  processing_characteristics_large_scale_profiling: (
    <FormattedMessage
      id="ProcessingCharacteristics.large_scale_profiling"
      description="Large scale profiling"
      defaultMessage="Large scale profiling"
    />
  ),
  processing_characteristics_biometric_data: (
    <FormattedMessage
      id="ProcessingCharacteristics.biometric_data"
      description="Biometric data"
      defaultMessage="Biometric data"
    />
  ),
  processing_characteristics_genetic_data: (
    <FormattedMessage
      id="ProcessingCharacteristics.genetic_data"
      description="Genetic data"
      defaultMessage="Genetic data"
    />
  ),
  processing_characteristics_data_matching: (
    <FormattedMessage
      id="ProcessingCharacteristics.data_matching"
      description="Data matching"
      defaultMessage="Data matching"
    />
  ),
  processing_characteristics_invisible_processing: (
    <FormattedMessage
      id="ProcessingCharacteristics.invisible_processing"
      description="Invisible processing"
      defaultMessage="Invisible processing"
    />
  ),
  processing_characteristics_tracking: (
    <FormattedMessage
      id="ProcessingCharacteristics.tracking"
      description="Tracking"
      defaultMessage="Tracking"
    />
  ),
  processing_characteristics_target_vulnerable_persons: (
    <FormattedMessage
      id="ProcessingCharacteristics.target_vulnerable_persons"
      description="Target children/vulnerable persons"
      defaultMessage="Target children/vulnerable persons"
    />
  ),
  processing_characteristics_risk_of_physical_harm: (
    <FormattedMessage
      id="ProcessingCharacteristics.risk_of_physical_harm"
      description="Risk of physical harm"
      defaultMessage="Risk of physical harm"
    />
  ),
  processing_characteristics_covert_investigation: (
    <FormattedMessage
      id="processing_characteristics_covert_investigation"
      defaultMessage="Covert investigation"
    />
  ),
  processing_characteristics_black_lists: (
    <FormattedMessage
      id="processing_characteristics_black_lists"
      defaultMessage="Black lists"
    />
  ),
  processing_characteristics_fight_against_fraud: (
    <FormattedMessage
      id="processing_characteristics_fight_against_fraud"
      defaultMessage="Fight against fraud"
    />
  ),
  processing_characteristics_credit_scores: (
    <FormattedMessage
      id="processing_characteristics_credit_scores"
      defaultMessage="Credit scores"
    />
  ),
  processing_characteristics_financial_situation: (
    <FormattedMessage
      id="processing_characteristics_financial_situation"
      defaultMessage="Financial situation"
    />
  ),
  processing_characteristics_health_data: (
    <FormattedMessage
      id="processing_characteristics_health_data"
      defaultMessage="Health data"
    />
  ),
  processing_characteristics_collaborative_partnerships: (
    <FormattedMessage
      id="processing_characteristics_collaborative_partnerships"
      defaultMessage="Collaborative partnerships"
    />
  ),
  processing_characteristics_camera_surveillance: (
    <FormattedMessage
      id="processing_characteristics_camera_surveillance"
      defaultMessage="Camera surveillance"
    />
  ),
  processing_characteristics_flexible_camera_surveillance: (
    <FormattedMessage
      id="processing_characteristics_flexible_camera_surveillance"
      defaultMessage="Flexible camera surveillance"
    />
  ),
  processing_characteristics_control_of_employees: (
    <FormattedMessage
      id="processing_characteristics_control_of_employees"
      defaultMessage="Control of employees"
    />
  ),
  processing_characteristics_location_data: (
    <FormattedMessage
      id="processing_characteristics_location_data"
      defaultMessage="Location data"
    />
  ),
  processing_characteristics_communication_data: (
    <FormattedMessage
      id="processing_characteristics_communication_data"
      defaultMessage="Communication data"
    />
  ),
  processing_characteristics_internet_of_things: (
    <FormattedMessage
      id="processing_characteristics_internet_of_things"
      defaultMessage="Internet of things"
    />
  ),
  processing_characteristics_profiling: (
    <FormattedMessage
      id="processing_characteristics_profiling"
      defaultMessage="Profiling"
    />
  ),
  processing_characteristics_monitoring_and_influencing_of_behaviour: (
    <FormattedMessage
      id="processing_characteristics_monitoring_and_influencing_of_behaviour"
      defaultMessage="Monitoring and influencing of behaviour"
    />
  ),
  processing_characteristics_health_data_for_health_care_establishments: (
    <FormattedMessage
      id="processing_characteristics_health_data_for_health_care_establishments"
      defaultMessage="Processing of health data carried out by healthcare
      establishments or medico-social establishments for the care of individuals"
    />
  ),
  processing_characteristics_vulnerable_ds_genetic_data: (
    <FormattedMessage
      id="processing_characteristics_vulnerable_ds_genetic_data"
      defaultMessage="Processing of genetic data of vulnerable data subjects (patients, employees, children, etc.)"
    />
  ),
  processing_characteristics_profiling_for_hr_purposes: (
    <FormattedMessage
      id="processing_characteristics_profiling_for_hr_purposes"
      defaultMessage="Processing carried out for the profiling of data subjects for human resources purposes"
    />
  ),
  processing_characteristics_systematic_employee_monitoring: (
    <FormattedMessage
      id="processing_characteristics_systematic_employee_monitoring"
      defaultMessage="Processing carried out for the purpose of systematic monitoring of employees’ activities"
    />
  ),
  processing_characteristics_social_and_health_alerts_and_reports: (
    <FormattedMessage
      id="processing_characteristics_social_and_health_alerts_and_reports"
      defaultMessage="Processing carried out for the management of alerts and reports
      (whistleblowing) in the field of social and health matters"
    />
  ),
  processing_characteristics_professional_alerts_and_reports: (
    <FormattedMessage
      id="processing_characteristics_professional_alerts_and_reports"
      defaultMessage="Processing for the purpose of alerts and reporting in p
      rofessional matters (e.g. collection of professional alerts for the
        concerned private or public organization as well as devices for collecting
        alerts about traffic of influence or corruption committed within the
        organization and alert devices implemented as part of the duty of care.)"
    />
  ),
  processing_characteristics_health_data_for_data_warehouse_or_register: (
    <FormattedMessage
      id="processing_characteristics_health_data_for_data_warehouse_or_register"
      defaultMessage="Processing of health data that is necessary for establishing a data warehouse or a register"
    />
  ),
  processing_characteristics_profiling_resulting_in_contract_termination: (
    <FormattedMessage
      id="processing_characteristics_profiling_resulting_in_contract_termination"
      defaultMessage="Processing involving the profiling of persons that may lead to their exclusion
      from a contract or to its suspension or even termination"
    />
  ),
  processing_characteristics_personal_data_in_verified_contractual_breaches: (
    <FormattedMessage
      id="processing_characteristics_personal_data_in_verified_contractual_breaches"
      defaultMessage="Mutualized processing of personal data related to verified contractual breaches,
      likely to result in a decision to exclude a data subject from the benefit of a contract,
        or to suspend or terminate it"
    />
  ),
  processing_characteristics_profiling_data_from_external: (
    <FormattedMessage
      id="processing_characteristics_profiling_data_from_external"
      defaultMessage="Profiling processing using data coming from external sources"
    />
  ),
  processing_characteristics_vulnerable_ds_biometric_data: (
    <FormattedMessage
      id="processing_characteristics_vulnerable_ds_biometric_data"
      defaultMessage="Processing of biometric data of vulnerable data subjects (patients, employees, children, etc.)
      for the purpose of recognizing the data subject"
    />
  ),
  processing_characteristics_social_housing_application_evaluation: (
    <FormattedMessage
      id="processing_characteristics_social_housing_application_evaluation"
      defaultMessage="Processing aiming at evaluating applications for social housing and for their management"
    />
  ),
  processing_characteristics_medico_social_care: (
    <FormattedMessage
      id="processing_characteristics_medico_social_care"
      defaultMessage="Processing carried out for the purpose of social or medico-social care"
    />
  ),
  processing_characteristics_large_scale_location_data: (
    <FormattedMessage
      id="processing_characteristics_large_scale_location_data"
      defaultMessage="Processing of location data on a large scale"
    />
  ),
  processing_characteristics_hr_purposes_without_profiling: (
    <FormattedMessage
      id="processing_characteristics_hr_purposes_without_profiling"
      defaultMessage="Processing operations, implemented under the conditions
    laid down by the applicable texts, solely for human resources purposes by
    employers with fewer than 250 people, except when profiling is used"
    />
  ),
  processing_characteristics_supplier_relationship_management: (
    <FormattedMessage
      id="processing_characteristics_supplier_relationship_management"
      defaultMessage="Processing carried out for the management of the relationship with suppliers"
    />
  ),
  processing_characteristics_municipality_electoral_register_management: (
    <FormattedMessage
      id="processing_characteristics_municipality_electoral_register_management"
      defaultMessage="Processing implemented under the conditions provided by the law
    relating to the management of the electoral register of municipalities"
    />
  ),
  processing_characteristics_working_committee_and_council_management: (
    <FormattedMessage
      id="processing_characteristics_working_committee_and_council_management"
      defaultMessage="Processing carried out for the management of working committees and councils"
    />
  ),
  processing_characteristics_non_sensitive_member_and_donor_data: (
    <FormattedMessage
      id="processing_characteristics_non_sensitive_member_and_donor_data"
      defaultMessage="Processing carried out by an association, foundation or any other non-profit institution
    for the management of its members and donors in the framework of
    its regular activities as long as the data is not sensitive"
    />
  ),
  processing_characteristics_health_data_for_individual_health_care_professional:
    (
      <FormattedMessage
        id="processing_characteristics_health_data_for_individual_health_care_professional"
        defaultMessage="Processing of health data necessary for the care of a patient by an individual
    healthcare professional in a medical practice, a pharmacy or a medical biology laboratory"
      />
    ),
  processing_characteristics_lawyers_for_individual_practice: (
    <FormattedMessage
      id="processing_characteristics_lawyers_for_individual_practice"
      defaultMessage="Processing carried out by lawyers in the individual practice of their profession"
    />
  ),
  processing_characteristics_clerks_of_commercial_courts_for_their_activity: (
    <FormattedMessage
      id="processing_characteristics_clerks_of_commercial_courts_for_their_activity"
      defaultMessage="Processing carried out by the clerks of commercial courts for
    the purpose of carrying out their activity"
    />
  ),
  processing_characteristics_notaries_for_notarial_activity: (
    <FormattedMessage
      id="processing_characteristics_notaries_for_notarial_activity"
      defaultMessage="Processing carried out by notaries for the purpose of carrying out
    their notarial activity and the drafting of notarial office documents"
    />
  ),
  processing_characteristics_school_management_and_childhood_services: (
    <FormattedMessage
      id="processing_characteristics_school_management_and_childhood_services"
      defaultMessage="Processing carried out by local authorities, as well as legal
    persons covered by public and private law, for the management of schools,
    as well as extracurricular and early childhood services
    "
    />
  ),
  processing_characteristics_non_sensitive_data_for_physical_access_control: (
    <FormattedMessage
      id="processing_characteristics_non_sensitive_data_for_physical_access_control"
      defaultMessage="Processing carried out solely for the purpose of managing physical
    access controls and schedules for calculating of working times, excluding any biometric device.
    The processing does not reveal sensitive data or data of a highly personal nature"
    />
  ),
  processing_characteristics_breathalyser_test_data_for_transport_law: (
    <FormattedMessage
      id="processing_characteristics_breathalyser_test_data_for_transport_law"
      defaultMessage="Processing relating to breathalyser tests, implemented in the framework of transport activities, mandatory by law and restricted to the sole purpose of preventing drivers from operating vehicles while under the influence of alcohol or narcotics"
    />
  ),
  processing_characteristics_contract_decisions_using_third_party_data: (
    <FormattedMessage
      id="processing_characteristics_contract_decisions_using_third_party_data"
      defaultMessage="Contract decisions using third party data"
    />
  ),
  processing_characteristics_collection_of_health_data_through_implant: (
    <FormattedMessage
      id="processing_characteristics_collection_of_health_data_through_implant"
      defaultMessage="Collection of health data through implant"
    />
  ),
  processing_characteristics_analysation_or_prediction_using_third_party_data: (
    <FormattedMessage
      id="processing_characteristics_analysation_or_prediction_using_third_party_data"
      defaultMessage="Analysation or prediction using third party data"
    />
  ),
  processing_characteristics_sharing_of_sensitive_data: (
    <FormattedMessage
      id="processing_characteristics_sharing_of_sensitive_data"
      defaultMessage="Sharing of sensitive data"
    />
  ),
  processing_characteristics_communication_metadata_or_localization_data_when_not_strictly_necessary:
    (
      <FormattedMessage
        id="processing_characteristics_communication_metadata_or_localization_data_when_not_strictly_necessary"
        defaultMessage="Communications-, meta- or localization data when not strictly necessary"
      />
    ),
  processing_characteristics_automated_observance_and_influencing_of_behaviour:
    (
      <FormattedMessage
        id="processing_characteristics_automated_observance_and_influencing_of_behaviour"
        defaultMessage="Automated observance and influencing of behaviour"
      />
    ),
  processing_characteristics_private_organisation_inline_with_legal_obligation:
    (
      <FormattedMessage
        id="processing_characteristics_private_organisation_inline_with_legal_obligation"
        defaultMessage="Processing by private organisations in line with legal obligation"
      />
    ),
  processing_characteristics_salary_purposes: (
    <FormattedMessage
      id="processing_characteristics_salary_purposes"
      defaultMessage="Processing for salary purposes"
    />
  ),
  processing_characteristics_personal_data_exclusively_administrative_in_nature:
    (
      <FormattedMessage
        id="processing_characteristics_personal_data_exclusively_administrative_in_nature"
        defaultMessage="Processing of personal data exclusively administrative in nature"
      />
    ),
  processing_characteristics_exclusively_for_the_controllers_accountancy_practices:
    (
      <FormattedMessage
        id="processing_characteristics_exclusively_for_the_controllers_accountancy_practices"
        defaultMessage="Processing exclusively for the controller’s accountancy practices"
      />
    ),
  processing_characteristics_administration_of_shareholders_and_associates: (
    <FormattedMessage
      id="processing_characteristics_administration_of_shareholders_and_associates"
      defaultMessage="Processing for the administration of shareholders and associates"
    />
  ),
  processing_characteristics_nonprofit_organisation_in_its_ordinary_activities:
    (
      <FormattedMessage
        id="processing_characteristics_nonprofit_organisation_in_its_ordinary_activities"
        defaultMessage="Processing by a non-profit organisation in its ordinary activities"
      />
    ),
  processing_characteristics_exclusively_to_visitor_registration_during_access_control:
    (
      <FormattedMessage
        id="processing_characteristics_exclusively_to_visitor_registration_during_access_control"
        defaultMessage="Processing relating exclusively to visitor registration during access control"
      />
    ),
  processing_characteristics_educational_institutions_for_student_management: (
    <FormattedMessage
      id="processing_characteristics_educational_institutions_for_student_management"
      defaultMessage="Processing by educational institutions for student management"
    />
  ),
  processing_characteristics_exclusively_for_client_or_supplier_management: (
    <FormattedMessage
      id="processing_characteristics_exclusively_for_client_or_supplier_management"
      defaultMessage="Processing exclusively for client or supplier management"
    />
  ),
  processing_characteristics_profiling_or_evaluation: (
    <FormattedMessage
      id="processing_characteristics_profiling_or_evaluation"
      defaultMessage="Profiling or evaluation"
    />
  ),
  processing_characteristics_automated_decision_making: (
    <FormattedMessage
      id="processing_characteristics_automated_decision_making"
      defaultMessage="Automated decision making"
    />
  ),
  processing_characteristics_observation_monitoring_supervision_related: (
    <FormattedMessage
      id="processing_characteristics_observation_monitoring_supervision_related"
      defaultMessage="Systematic and exhaustive observation, monitoring, supervision, geolocation or control"
    />
  ),
  processing_characteristics_special_category_criminal_financial_data: (
    <FormattedMessage
      id="processing_characteristics_special_category_criminal_financial_data"
      defaultMessage="Special category, criminal or financial data"
    />
  ),
  processing_characteristics_use_of_large_scale_data: (
    <FormattedMessage
      id="processing_characteristics_use_of_large_scale_data"
      defaultMessage="Use of data on a large scale"
    />
  ),
  processing_characteristics_matching_data_for_different_purposes_or_by_different_controllers:
    (
      <FormattedMessage
        id="processing_characteristics_matching_data_for_different_purposes_or_by_different_controllers"
        defaultMessage="Matching data collected for different purposes or by different controllers"
      />
    ),
  processing_characteristics_vulnerable_individuals_or_social_exclusion_risk: (
    <FormattedMessage
      id="processing_characteristics_vulnerable_individuals_or_social_exclusion_risk"
      defaultMessage="Vulnerable individuals or risk of social exclusion"
    />
  ),
  processing_characteristics_new_technologies_or_innovative_use_of_established_technologies:
    (
      <FormattedMessage
        id="processing_characteristics_new_technologies_or_innovative_use_of_established_technologies"
        defaultMessage="New technologies or innovative use of established technologies"
      />
    ),
  processing_characteristics_processing_authorized_by_supervisory_body_guideline_or_decision:
    (
      <FormattedMessage
        id="processing_characteristics_processing_authorized_by_supervisory_body_guideline_or_decision"
        defaultMessage="Processing authorized by supervisory bodies in guidelines or decisions"
      />
    ),
  processing_characteristics_approved_codes_of_conduct_dpia_measures_implemented:
    (
      <FormattedMessage
        id="processing_characteristics_approved_codes_of_conduct_dpia_measures_implemented"
        defaultMessage="Approved codes of conduct applicable, DPIA has been carried out and its measures implemented"
      />
    ),
  processing_characteristics_legal_task_public_interest_dpia_performed: (
    <FormattedMessage
      id="processing_characteristics_legal_task_public_interest_dpia_performed"
      defaultMessage="Legal task in the public interest or official authority,
    no legal duty to perform DPIA and a DPIA has been performed already"
    />
  ),
  processing_characteristics_processing_by_self_employed_personnel: (
    <FormattedMessage
      id="processing_characteristics_processing_by_self_employed_personnel"
      defaultMessage="Processing by self-employed personnel (physicians, lawyers, etc.)"
    />
  ),
  processing_characteristics_processing_for_sme_internal_administration_personnel:
    (
      <FormattedMessage
        id="processing_characteristics_processing_for_sme_internal_administration_personnel"
        defaultMessage="Processing for internal administration of personnel working at SMEs"
      />
    ),
  processing_characteristics_processing_by_multi_occupancy_property_owners_associations:
    (
      <FormattedMessage
        id="processing_characteristics_processing_by_multi_occupancy_property_owners_associations"
        defaultMessage="Processing by owners’ associations in multi occupancy properties"
      />
    ),
  processing_characteristics_processing_of_associates_members_data_by_colleges_and_npos:
    (
      <FormattedMessage
        id="processing_characteristics_processing_of_associates_members_data_by_colleges_and_npos"
        defaultMessage="Processing of (non-sensitive) associates members' data by colleges and NPOs"
      />
    ),
  processing_characteristics_biometric_data_subject_identify: (
    <FormattedMessage
      id="processing_characteristics_biometric_data_subject_identify"
      defaultMessage="Biometric data for data subject identification"
    />
  ),
  processing_characteristics_data_subject_social_official_secrecy: (
    <FormattedMessage
      id="processing_characteristics_data_subject_social_official_secrecy"
      defaultMessage="Data subject to social, professional or official secrecy"
    />
  ),
  processing_characteristics_electronic_recording_personal_data_in_public: (
    <FormattedMessage
      id="processing_characteristics_electronic_recording_personal_data_in_public"
      defaultMessage="Mobile optical-electronic recording of personal data in public areas"
    />
  ),
  processing_characteristics_transfer_personal_data_to_evaluate: (
    <FormattedMessage
      id="processing_characteristics_transfer_personal_data_to_evaluate"
      defaultMessage="Collection and publication or transfer of personal data used to evaluate the
     behaviour and other personal aspects of individuals with legal or similar significant effect"
    />
  ),
  processing_characteristics_processing_personal_data_evaluate_work_activity: (
    <FormattedMessage
      id="processing_characteristics_processing_personal_data_evaluate_work_activity"
      defaultMessage="Processing of personal data on the conduct of employees, which can be used
     to evaluate their work activities with legal or similar significant effect"
    />
  ),
  processing_characteristics_profile_network_of_personal_relationships: (
    <FormattedMessage
      id="processing_characteristics_profile_network_of_personal_relationships"
      defaultMessage="Creation of comprehensive profiles on the interests, the network of personal relationships or the personality of data subjects"
    />
  ),
  processing_characteristics_discovering_previously_unknown_connections: (
    <FormattedMessage
      id="processing_characteristics_discovering_previously_unknown_connections"
      defaultMessage="Discovering previously unknown connections inside data with purposes that are not predetermined"
    />
  ),
  processing_characteristics_artificial_intelligence_to_process_personal_data: (
    <FormattedMessage
      id="processing_characteristics_artificial_intelligence_to_process_personal_data"
      defaultMessage="Use of artificial intelligence to process personal data to control interaction with the data subject or to evaluate personal aspects of the data subject"
    />
  ),
  processing_characteristics_signals_to_determine_movement_person: (
    <FormattedMessage
      id="processing_characteristics_signals_to_determine_movement_person"
      defaultMessage="Unintended use of sensors of a mobile device or of radio signals transmitted by such devices to determine the whereabouts or movement of persons over a substantial period of time"
    />
  ),
  processing_characteristics_automated_evaluation_of_recordings: (
    <FormattedMessage
      id="processing_characteristics_automated_evaluation_of_recordings"
      defaultMessage="Automated evaluation of video or audio recordings"
    />
  ),
  processing_characteristics_creation_of_profiles_on_movement: (
    <FormattedMessage
      id="processing_characteristics_creation_of_profiles_on_movement"
      defaultMessage="Creation of comprehensive profiles on movement and purchasing behaviour"
    />
  ),
  processing_characteristics_anonymisation_of_special_personal_data: (
    <FormattedMessage
      id="processing_characteristics_anonymisation_of_special_personal_data"
      defaultMessage="Anonymisation of special personal data, not only in individual cases for the purpose of transmission to third parties"
    />
  ),
  processing_characteristics_processing_of_special_personal_data_non_recurring:
    (
      <FormattedMessage
        id="processing_characteristics_processing_of_special_personal_data_non_recurring"
        defaultMessage="Processing of special personal data (incl criminal data) - even if it is not to be regarded as large scale - provided that non-recurring data collection takes place by means
     of the innovative use of sensors or mobile applications and these data are received and processed by a central office"
      />
    ),
  processing_characteristics_processing_of_performance_person: (
    <FormattedMessage
      id="processing_characteristics_processing_of_performance_person"
      defaultMessage="Processing of special personal data (incl criminal data) - even if it is not to be regarded as large scale
     - provided that the data is used by the providers of new technologies to determine the performance of the persons."
    />
  ),
  processing_characteristics_sensitive_personal_info: (
    <FormattedMessage
      id="processing_characteristics_sensitive_personal_info"
      defaultMessage="Handling sensitive personal information"
    />
  ),
  processing_characteristics_personal_info_conduct_automated_decision_making: (
    <FormattedMessage
      id="processing_characteristics_personal_info_conduct_automated_decision_making"
      defaultMessage="Using personal information to conduct automated decision-making"
    />
  ),
  processing_characteristics_personal_info_abroad: (
    <FormattedMessage
      id="processing_characteristics_personal_info_abroad"
      defaultMessage="Entrusting personal information handling, providing personal information
     to other personal information handlers, or disclosing personal information"
    />
  ),
  processing_characteristics_other_personal_info_influence_individual: (
    <FormattedMessage
      id="processing_characteristics_other_personal_info_influence_individual"
      defaultMessage="Providing personal information abroad"
    />
  ),
  processing_characteristics_disclosing_personal_info: (
    <FormattedMessage
      id="processing_characteristics_disclosing_personal_info"
      defaultMessage="Other personal information handling activities with a major influence on individuals"
    />
  )
};

export const chapterTranslations = {
  NecessityAndProportionality: (
    <FormattedMessage
      id="Assessments.necessityProportionality"
      description="Necessity And Proportionality"
      defaultMessage="Necessity and proportionality"
    />
  ),
  SecurityControls: (
    <FormattedMessage
      id="Assessments.securityControls"
      description="Security controls"
      defaultMessage="Security controls"
    />
  ),
  ImpactAssessment: (
    <FormattedMessage
      id="Assessments.threatImpact"
      description="Threat/impacts assessment"
      defaultMessage="Threat/impacts assessment"
    />
  ),
  DataSubjectRights: (
    <FormattedMessage
      id="Assessments.dataSubjectRights"
      description="Data subject rights"
      defaultMessage="Data subject rights"
    />
  )
};

export const reasonTypeTranslations = {
  NoHighRiskForDataSubject: (
    <FormattedMessage
      id="DataSubjectReasonType.noHighRiskForDataSubject"
      description="No high risk for data subject"
      defaultMessage="No high risk for the data subject"
    />
  ),
  AppropriateTechnicalAndOrganisationMeasures: (
    <FormattedMessage
      id="DataSubjectReasonType.appropriateTechnicalAndOrganisationMeasures"
      description="Appropriate technical and organisation measures"
      defaultMessage="Appropriate technical and organisation measures"
    />
  ),
  DisproportionalEffort: (
    <FormattedMessage
      id="DataSubjectReasonType.disProportionalEffort"
      description="Disproportional effort"
      defaultMessage="Disproportional effort"
    />
  )
};

export const retentionTermTranslation = {
  year: (
    <FormattedMessage
      id="Record.Retention.year"
      description="Year"
      defaultMessage="year(s)"
    />
  ),
  month: (
    <FormattedMessage
      id="Record.Retention.month"
      description="Month"
      defaultMessage="month(s)"
    />
  ),
  week: (
    <FormattedMessage
      id="Record.Retention.week"
      description="Week"
      defaultMessage="week(s)"
    />
  ),
  day: (
    <FormattedMessage
      id="Record.Retention.day"
      description="Day"
      defaultMessage="day(s)"
    />
  ),
  hour: (
    <FormattedMessage
      id="Record.Retention.hour"
      description="Hour"
      defaultMessage="hour(s)"
    />
  ),
  minute: (
    <FormattedMessage
      id="Record.Retention.minute"
      description="Minute"
      defaultMessage="minute(s)"
    />
  )
};

export const retentionTermMapper = (type) => retentionTermTranslation[type];

export const templateTranslations = {
  newTemplate: (
    <FormattedMessage
      id="CreateTemplate.header"
      defaultMessage="New template"
    />
  ),
  name: (
    <FormattedMessage
      id="CreateTemplate.toPromote"
      defaultMessage="Please enter a name for the template"
    />
  ),
  nameHint: (
    <FormattedMessage
      id="CreateTemplate.nameHint"
      defaultMessage="Template name..."
    />
  ),
  emptyTemplate: (
    <FormattedMessage
      id="CreateTemplate.emptyRecord"
      defaultMessage="Empty Template"
    />
  ),
  delete: (
    <FormattedMessage
      id="ProcessingTemplate.remove.confirmMessage"
      defaultMessage="Do you want to delete the template?"
    />
  ),
  saveAsNewTemplate: (
    <FormattedMessage
      id="CreateTemplate.saveAsNewTemplate"
      defaultMessage="Save as new template"
    />
  ),
  createCopy: (
    <FormattedMessage
      id="CreateTemplate.createCopy"
      defaultMessage="Create copy"
    />
  ),
  active: <FormattedMessage id="template.active" defaultMessage="Active" />,
  inActive: (
    <FormattedMessage id="template.inActive" defaultMessage="Inactive" />
  )
};
