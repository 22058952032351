import { connect } from 'react-redux';
import OrganisationSelector from './component';

const mapStateToProps = state => ({
  organisations: state.environment.organisations.get('items'),
  position: state.environment.organisations.get('position'),
  error: state.legalEntities.get('error'),
  searchResults: state.legalEntities.get('itemSelectorSearch').get('reportOrg').searchResults
});

const mapDispatchToProps = dispatch => ({
  initOrganisations: () => {
    dispatch({ type: 'ORGANISATIONS:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'ORGANISATIONS:LIST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSelector);
