import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Cell } from 'fixed-data-table';
import Checkbox from '@material-ui/core/Checkbox';
import {
  getEditRecordTypes,
  getRecordType
} from '@packages/utils/common-utils';
import {
  recordTranslations,
  statusTranslations
} from '@packages/utils/commontranslations';
import { getTranslatedLabel } from '../tenant-configuration/tenantConfigUtils';

export const getDeleteRecordUrl = (recordId, recordParam, isTemplateMode) => {
  const recordTypeFromParam = recordParam.split('_')[1] || recordParam;
  if (!defaultRecordLayouts.includes(recordTypeFromParam))
    return `/v1/custom/records${isTemplateMode ? '/templates' : ''}/${recordId}`;
  const recordType = getRecordType(recordTypeFromParam);
  return `/v1/records${isTemplateMode ? '/templates' : ''}/${recordType}/${recordId}`;
};

export const getSearchItems = (
  { searchKey, items, controllers, executingEntities, searchText, searchObj },
  isPageSearch
) => {
  let listItem = [];
  switch (searchKey) {
    case 'processingGrounds':
      listItem = Array.from(
        new Set(
          items.map(
            (item) => `${item.jurisdiction} ${item.law} ${item.article}`
          )
        )
      );
      break;
    case 'controllers':
      listItem = Array.from(
        new Set(
          controllers
            .filter((item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((i) => i.name)
        )
      );
      break;
    case 'executingEntities':
      listItem = Array.from(
        new Set(
          executingEntities
            .filter((item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((i) => i.name)
        )
      );
      break;
    default:
      listItem = Array.from(
        new Set(items.map((item) => (isPageSearch ? item : item.name)))
      );
  }
  const searchItemObj = searchObj || {};
  searchItemObj[searchKey] = listItem;
  return Immutable.Map(searchItemObj);
};

// Hide records menu item based.
export const shouldHideMenuItem = (
  action,
  rowIndex,
  items,
  isEditable,
  isViewable,
  isVendor
) => {
  if (
    (action === 'edit' ||
      action === 'delete' ||
      action === 'addNote' ||
      action === 'editNote' ||
      action === 'removeNote' ||
      action === 'copyRecord') &&
    !isEditable
  )
    return true;
  if ((action === 'view' || action === 'graphicalView') && !isViewable)
    return true;

  if (
    (action === 'copyRecord' ||
      action === 'view' ||
      action === 'graphicalView' ||
      action === 'changelog') &&
    isVendor
  )
    return true;

  if (action === 'addNote') {
    const item = items.get(rowIndex);
    return !(item.note === undefined || item.note === '');
  }
  if (
    action === 'editNote' ||
    action === 'removeNote' ||
    action === 'viewNote'
  ) {
    const item = items.get(rowIndex);
    return item.note === undefined || item.note === '';
  }

  return false;
};

export const recordStatusFilterValues = [
  {
    label: statusTranslations.record_status_approved,
    value: 'record_status_approved'
  },
  {
    label: statusTranslations.record_status_disapproved,
    value: 'record_status_disapproved'
  },
  {
    label: statusTranslations.record_status_draft,
    value: 'record_status_draft'
  },
  {
    label: statusTranslations.record_status_provisional,
    value: 'record_status_provisional'
  },
  {
    label: statusTranslations.record_status_redundant,
    value: 'record_status_redundant'
  },
  {
    label: statusTranslations.record_status_requested,
    value: 'record_status_requested'
  },
  {
    label: statusTranslations.record_status_review_overdue,
    value: 'record_status_review_overdue'
  },
  {
    label: statusTranslations.record_status_review_soon,
    value: 'record_status_review_soon'
  },
  {
    label: statusTranslations.record_status_withdrawn,
    value: 'record_status_withdrawn'
  }
];

export const recordRiskFilterValues = [
  { label: recordTranslations.noRisks, value: '' },
  { label: recordTranslations.hasRisks, value: 'risks' }
];

export const dpiaRiskFilterValues = [
  { label: recordTranslations.highRisks, value: 'high' },
  { label: recordTranslations.mediumRisks, value: 'medium' },
  { label: recordTranslations.lowRisks, value: 'low' },
  { label: recordTranslations.noRisks, value: '' }
];

export const CheckBoxCell = React.memo(
  ({ items, onCheck, rowIndex, selectedRecords, ...props }) => {
    const selectedRecordIds = selectedRecords.map((item) => item.id);
    return (
      <Cell {...props}>
        <Checkbox
          style={{ bottom: 10 }}
          color="primary"
          onChange={(event) => {
            onCheck(event, items.get && items.get(rowIndex));
          }}
          onClick={(event) => event.stopPropagation()}
          checked={
            items.get(rowIndex) &&
            selectedRecordIds.includes(items.get(rowIndex).id)
          }
          disabled={
            selectedRecords.length >=
              (process.env.REACT_APP_BULK_UPDATE_EXPORT_LIMIT || 25) &&
            !selectedRecordIds.includes(items.get(rowIndex).id)
          }
        />
      </Cell>
    );
  }
);

CheckBoxCell.propTypes = {
  rowIndex: PropTypes.number,
  bulkItemsSize: PropTypes.number,
  unused: PropTypes.bool,
  onCheck: PropTypes.func,
  selectedRecords: PropTypes.shape([]),
  items: PropTypes.instanceOf(Immutable.List)
};

CheckBoxCell.defaultProps = {
  rowIndex: -1,
  bulkItemsSize: 0,
  unused: false,
  onCheck: (e) => e,
  selectedRecords: [],
  items: Immutable.List()
};

export const checkRecordTypeVisibility = (recordType, registryDetails) => {
  const isPublic = window.location.pathname.includes('public');
  const publicRecordTypes =
    isPublic && registryDetails && registryDetails.get('recordTypes');
  return recordType === 'all'
    ? publicRecordTypes && publicRecordTypes.length > 1
    : publicRecordTypes && publicRecordTypes.includes(recordType);
};

export const getRecordStatusFilterValues = (registryDetails) => {
  const isPublic = window.location.pathname.includes('public');
  if (isPublic) {
    const registryData = registryDetails.toJS();
    const modifiedStatusValues = recordStatusFilterValues.filter(
      (item) =>
        registryData.recordStatuses &&
        registryData.recordStatuses.includes(item.value)
    );
    return modifiedStatusValues;
  }
  return recordStatusFilterValues;
};

// property attribute of custom fields are in doted notation eg: custom.simplemasterdata.customPurpose
// the function will return modified record data with updated custom field property values

export const getRecordDataWithUpdatedCustomFieldValues = (
  property,
  value,
  recordData
) => {
  property.split('.').reduce((Object, currentKey, index) => {
    if (index === property.split('.').length - 1) {
      // eslint-disable-next-line no-param-reassign
      Object[currentKey] = value;
    }
    return Object[currentKey];
  }, recordData);
  return recordData;
};

export const defaultRecordLayouts = [
  'assessment',
  'processing',
  'documentRecord',
  'document-records',
  'document_records',
  'document',
  'breach',
  'dpia_assessments',
  'breaches',
  'processings',
  'assessments',
  'tia_assessments',
  'tias',
  'tia',
  'all'
];

export const checkRecordPermissions = (
  userPermissions,
  parentType,
  permissionType
) => {
  const {
    viewPreDpia,
    viewProcessing,
    viewBreach,
    viewDocumentRecord,
    viewCustom,
    createPreDpia,
    createProcessing,
    createBreach,
    createEditDocumentRecord,
    createCustom,
    approveAssessment,
    approveProcessing,
    approveBreach,
    approveDocument,
    approveCustom,
    deleteAssessment,
    deleteProcessing,
    deleteBreach,
    deleteDocument,
    deleteCustom,
    viewTia,
    createTia,
    deleteTia
  } = userPermissions.toJS();

  const permissions = {
    assessments: {
      edit: createPreDpia,
      view: viewPreDpia,
      approve: approveAssessment,
      delete: deleteAssessment
    },
    processings: {
      edit: createProcessing,
      view: viewProcessing,
      approve: approveProcessing,
      delete: deleteProcessing
    },
    breaches: {
      edit: createBreach,
      view: viewBreach,
      approve: approveBreach,
      delete: deleteBreach
    },
    'document-records': {
      edit: createEditDocumentRecord,
      view: viewDocumentRecord,
      approve: approveDocument,
      delete: deleteDocument
    },
    tias: {
      edit: createTia,
      view: viewTia,
      approve: approveAssessment,
      delete: deleteTia
    },
    custom: {
      edit: createCustom,
      view: viewCustom,
      approve: approveCustom,
      delete: deleteCustom
    }
  };
  return permissions[parentType]
    ? permissions[parentType][permissionType]
    : permissions.custom[permissionType];
};

export const getModifiedRecordType = (recordType) => {
  switch (recordType) {
    case 'processing':
      return 'processings';
    case 'assessment':
      return 'assessments';
    case 'breach':
      return 'breaches';
    case 'tia':
      return 'tias';
    case 'document':
    case 'document-records':
      return 'document-records';
    default:
      return recordType;
  }
};

export const getAccessibleRecordTypes = (
  userPermissions,
  recordTypeList,
  locale,
  tenantLocale
) => {
  const actionMap = (uniqueId) =>
    ({
      assessments: 'assessment',
      processings: 'processing',
      breaches: 'breach'
    }[uniqueId] || uniqueId);

  let accessibleRecords = getEditRecordTypes(recordTypeList, userPermissions);
  accessibleRecords = accessibleRecords.filter(
    (item) =>
      !(
        !defaultRecordLayouts.includes(item.uniqueId) &&
        item?.subRecordTypes?.length === 0
      )
  );

  accessibleRecords = accessibleRecords.map((item) => ({
    action: actionMap(item.uniqueId),
    primaryText: getTranslatedLabel(item.names, locale, tenantLocale),
    recordTypeData: {
      ...item,
      recordLayouts: item.subRecordTypes
        .filter((item) => item.status === 'Active')
        .map((value) => ({
          ...value,
          name: getTranslatedLabel(value.names, locale, tenantLocale)
        }))
    }
  }));
  return accessibleRecords;
};
