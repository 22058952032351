import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: undefined,
  simpleEntityData: undefined,
  filteredOn: Immutable.Map(),
  position: 0,
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isUsed: undefined,
  newItems: Immutable.List(),
  bulkItems: Immutable.List(),
  unused: false,
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:REFERENCES:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('isUsed', defaultState.get('isUsed'))
        .set('simpleEntityData', defaultState.get('simpleEntityData'));
    case 'MASTERDATA:REFERENCES:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('newItems', defaultState.get('newItems'))
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'MASTERDATA:REFERENCES:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:REFERENCES:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.simpleEntityData) {
        newItems = newItems.unshift(action.simpleEntityData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'MASTERDATA:REFERENCES:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:REFERENCES:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:REFERENCES:PAGE_SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:REFERENCES:PAGE_SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'MASTERDATA:REFERENCES:PAGE_SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:REFERENCES:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('simpleEntityData', action.simpleEntity)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:REFERENCES:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:REFERENCES:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'))
        .set('newItems', action.newItems);
    case 'MASTERDATA:REFERENCES:ITEM:FETCH:SUCCESS':
      return state.set('simpleEntityData', action.simpleEntityData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:REFERENCES:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:REFERENCES:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:REFERENCES:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:REFERENCES:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:REFERENCES:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:REFERENCES:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId, action.unused));
    }
    case 'MASTERDATA:REFERENCES:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:REFERENCES:ITEM:UNUSED:CHECK': {
      return state.set('unused', action.checked || false);
    }
    case 'MASTERDATA:REFERENCES:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    case 'MASTERDATA:REFERENCES:VIEW:SIMILAR:SUCCESS':
      return state
        .set('items', Immutable.List(action.items));
    default:
      return state;
  }
};
