import { connect } from 'react-redux';

import createRecord from './createRecord';

const mapStateToProps = (state) => ({
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  isTemplateMode: state.privacyRecords.list.get('isTemplateMode')
});

function mapDispatchToProps(dispatch) {
  return {
    toggleLoader: (toggle) => {
      dispatch({ type: 'LOADER:TOGGLE', toggle });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(createRecord);
