import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FontAwesome from 'react-fontawesome';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import AutoComplete from '@packages/components/auto-complete';
import MultipleSelector from '@packages/components/multiple-selector/multipleSelector';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

let searchDelayTimeout = null;
export class DocumentsMultiSelector extends React.Component {
  static handleGetFilteredItems(selectedDocuments, items) {
    const multipleDocuments = ((selectedDocuments &&
      selectedDocuments.length > 0) ?
      DocumentsMultiSelector.filterDocuments(selectedDocuments, items) :
      items);
    return multipleDocuments;
  }

  static filterDocuments(selectedDocuments, searchedItems) {
    const filteredData = searchedItems.filter((document) => {
      const index = selectedDocuments.findIndex(selectedDocument =>
        selectedDocument.value.name === document.name &&
        selectedDocument.value.categoryType === document.categoryType);
      return (index === -1);
    });
    return filteredData;
  }

  constructor (props) {
    super(props);

    this.state = {
      tags: props.tags,
      filterValues: props.filteredData,
      showAdvancedSearch: false,
      dataItemSearchText: props.searchTextValue
    };

    this.requestClose = this.requestClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onNewRequest = this.onNewRequest.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onUpdateInput = this.onUpdateInput.bind(this);
    this.handleShowAdvancedSearch = this.handleShowAdvancedSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.initTags) {
      this.props.initTags();
    }
    if (this.props.initDocuments) {
      this.props.initDocuments(this.props.searchTextValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.documents !== nextProps.documents || nextProps.documents.size > 0) {
      const { documents } = nextProps;
      const multipleDataItemsList = (this.props.selectedItems && this.props.selectedItems.length > 0) ?
        DocumentsMultiSelector.handleGetFilteredItems(nextProps.selectedItems, documents) :
        documents;
      if (multipleDataItemsList && multipleDataItemsList.size < 15 && nextProps.documents.size > 0
        && (this.props.position !== nextProps.position)) {
        this.props.getNextData(nextProps.position, this.state.dataItemSearchText);
      }
      this.setState({
        filterValues: multipleDataItemsList
      });
    }
    if (this.props.tags !== nextProps.tags) {
      this.setState({
        tags: nextProps.tags
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.state === nextState) ||
    !(this.props.documents === nextProps.documents) ||
    !(this.props.tags === nextProps.tags);
  }

  handleSearch(event, clearText) {
    const currentSearchText = clearText ? '' : event.currentTarget.value;

    clearTimeout(searchDelayTimeout);
    searchDelayTimeout = setTimeout(() => {
      this.filterData(null, currentSearchText);
    }, 500);

    this.setState({
      dataItemSearchText: currentSearchText
    });
  }

  handleChange(event, index, value) {
    this.setState({ selectedTag: value });
    if (value !== null) {
      this.filterData(value);
    }
  }

  handleOnSubmit(selectedItems) {
    if (this.props.handleMultipleItems) {
      const modifiedItems = selectedItems.map(item => ({ value: item }));
      this.props.handleMultipleItems(modifiedItems);
    }
    this.requestClose();
  }

  handleRequestClose() {
    this.setState({
      multiple: false
    });
  }

  handleShowAdvancedSearch() {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      showAdvancedSearch: !this.state.showAdvancedSearch
    });
  }

  handleMultipleItems(selectedItem) {
    this.setState({
      selectedTag: selectedItem.size > 0 ? selectedItem.get(0).value : undefined
    });
    if (selectedItem.size > 0) this.filterData(selectedItem.get(0).value);
  }

  handleScrollEnd() {
    this.props.getNextData(this.props.position, this.props.searchTextValue);
  }

  onNewRequest(selectedItem, index) {
    if (index === -3) { // If selected index is -3, select from a list
      this.setState({
        multiple: true
      });
    } else {
      this.setState({
        selectedTag: selectedItem
      });
      this.handleChange(null, null, selectedItem);
    }
  }

  onUpdateInput(selectedItem) {
    if (this.state.selectedTag !== undefined && selectedItem === '') {
      this.props.onChooseFilter((Object.assign({}, { filterKey: 'tags',
        filteredOn: [] })), this.state.dataItemSearchText);
    }
    this.setState({
      selectedTag: selectedItem !== '' ? { searchText: selectedItem } : undefined
    });
  }

  onSearch(searchText) {
    this.setState({
      selectedTag: searchText
    });
    const searchParams = { ...searchText, searchKey: 'name' };
    this.props.onSearch(searchParams);
  }

  filterData(filterParameters, searchValue) {
    const searchText = searchValue || this.state.dataItemSearchText;
    const filterParam = filterParameters || this.state.selectedTag;

    if (filterParam) {
      this.props.onChooseFilter(Object.assign({}, { filterKey: 'tags',
        filteredOn: [filterParam.id] }), searchText);
    } else {
      this.props.onChooseFilter(null, searchText);
    }
  }

  requestClose() {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  }

  render () {
    const { multiValue, intl } = this.props;
    const { selectedTag, showAdvancedSearch, dataItemSearchText } = this.state;

    return (
      <CommonDialog
        id="attachment-selector-dialog"
        show={this.props.open}
        onCancel={this.requestClose}
        title={this.props.title}
        maxWidth="sm"
        fullWidth={true}
      >
        <div id="itemByName" style={{ width: '70%', display: 'flex', paddingBottom: '20px', marginTop: '5px' }}>
          <TextField
            autoFocus={true}
            placeholder={intl.formatMessage({ id: 'Common.searchByName', defaultMessage: 'Search by name' })}
            style={{ width: '97%' }}
            value={dataItemSearchText}
            onChange={event => this.handleSearch(event)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                  <InputAdornment position="start">
                    {(dataItemSearchText && dataItemSearchText !== '') ?
                      <Clear
                        style={{ cursor: 'pointer' }}
                        onClick={event => this.handleSearch(event, true)}
                      />
                      :
                      <Search />}
                  </InputAdornment>
              )
            }}
          />
          <Tooltip title={intl.formatMessage({ id: 'Common.advancedSearch', defaultMessage: 'Advanced search' })}>
            <div style={
              { marginLeft: '10px',
                marginTop: '10px',
                cursor: 'pointer',
                color: this.props.theme.palette.primary.main }}
            >
              <FontAwesome
                name="filter"
                size="lg"
                onClick={this.handleShowAdvancedSearch}
              />
            </div>
          </Tooltip>
        </div>
        {showAdvancedSearch &&
          <div style={{ width: '64%', paddingBottom: '15px', display: 'flex' }}>
            <AutoComplete
              underlineShow={false}
              openOnFocus={true}
              inputType={this.props.inputType}
              maxSearchResults={5}
              selectFromListMenuItem={true}
              fullWidth={true}
              hintText={intl.formatMessage({
                id: 'EntitySelector.select.tag.hintText',
                defaultMessage: 'Filter by tag'
              })}
              searchText={(selectedTag && (selectedTag.searchText || selectedTag.name)) || ''}
              filter={AutoComplete.caseInsensitiveFilter}
              hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
              textFieldStyle={styles.autoComplete}
              dataSource={this.state.tags || []}
              onNewRequest={this.onNewRequest}
              dataSourceConfig={{ text: 'key', value: 'id' }}
              onUpdateInput={this.onUpdateInput}
              onSearch={this.onSearch}
            />
          </div>}
        {this.state.multiple && <MultipleSelectorDialog
          id="multiple_selector"
          multiValue={false}
          title={
            <FormattedMessage
              id="EntitySelector.select.tag.hintText"
              description="Filter by tag"
              defaultMessage="Filter by tag"
            />}
          open={this.state.multiple}
          filteredData={this.props.tags}
          onScrollStop={this.handleScrollEnd}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />}
        <MultipleSelector
          key={this.props.key}
          filteredData={this.state.filterValues}
          multiValue={multiValue}
          onSave={this.handleOnSubmit}
          onCancel={this.requestClose}
          onScrollStop={this.props.onScrollStop}
        />
      </CommonDialog>
    );
  }
}

DocumentsMultiSelector.propTypes = {
  filteredData: PropTypes.instanceOf(Immutable.List),
  documents: PropTypes.instanceOf(Immutable.List),
  selectedItems: PropTypes.instanceOf(Immutable.List),
  key: PropTypes.string,
  title: PropTypes.node,
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  multiValue: PropTypes.bool,
  open: PropTypes.bool,
  tags: PropTypes.instanceOf(Immutable.List),
  onScrollStop: PropTypes.func,
  initTags: PropTypes.func,
  searchText: PropTypes.shape({
    get: PropTypes.func
  }),
  searchResults: PropTypes.shape({
    size: PropTypes.number
  }),
  onSearch: PropTypes.func,
  inputType: PropTypes.string,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  onChooseFilter: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  searchTextValue: PropTypes.string,
  initDocuments: PropTypes.func,
  theme: PropTypes.objectOf(PropTypes.any)

};

DocumentsMultiSelector.defaultProps = {
  open: false,
  multiValue: true,
  title: null,
  key: '',
  searchText: {
    get: e => e
  },
  filteredData: Immutable.List(),
  documents: Immutable.List(),
  selectedItems: Immutable.List(),
  onRequestClose: e => e,
  handleMultipleItems: e => e,
  tags: Immutable.List(),
  onScrollStop: e => e,
  initTags: () => {},
  searchResults: {
    size: 0
  },
  onSearch: e => e,
  inputType: 'text',
  position: 0,
  getNextData: e => e,
  onChooseFilter: e => e,
  searchTextValue: '',
  initDocuments: e => e,
  theme: {}
};

export default injectIntl(DocumentsMultiSelector);
