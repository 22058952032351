import { connect } from 'react-redux';
import ProcessingGroundSelector from './component';
import reducers from './reducers';

const mapStateToProps = state => ({
  filteredOn: state.processingGrounds.get('filteredOn').toJS()
});

const mapDispatchToProps = dispatch => ({
  removeFilters: (jurisdiction, filterType) => {
    dispatch({ type: 'PROCESSING_GROUNDS:LIST:FILTER:REMOVE' });
    dispatch({ type: 'PROCESSING_GROUNDS:LIST:INIT', jurisdiction, filterType });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ProcessingGroundSelector);
export { reducers };
