import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ItemSelector from '@packages/components/item-selector';
import { recordTranslations } from '@packages/utils/commontranslations';
import MultipleSelectorDialog from '../multi-selector';


const defaultStyle = {
  autoComplete: {
    height: '40px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #9b9b9b'
  }
};
const EntityImport = (props) => {
  const [multiple, setMultiple] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedKey, setSelectedKey] = useState('');

  useEffect(() => {
    setSelectedKey('');
    const entityFilter = props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
    props.init(entityFilter);
  }, [props.isLegalEntity]);


  const handleSelectedItem = (searchValue) => {
    setSelectedKey(searchValue.value.key);
    props.input.onChange(searchValue.value);
  };

  const handleMultipleItems = (selectedItems) => {
    setSelectedKey(selectedItems.get(0).value.key);
    props.input.onChange(selectedItems.get(0)?.value || '');
    setMultiple(false);
  };

  const handleSearch = (searchValue) => {
    const entityFilter = props.isLegalEntity === 'true' ? 'orgType=legalentity' : 'orgType=department';
    setSearchText(searchValue.searchText);
    const searchParams = { ...searchValue,
      searchKey: 'name',
      entityFilter };

    props.onSearch(searchParams);
    if (searchValue.searchText === '') props.input.onChange('');
  };
  const getSearchText = (searchedItems) => {
    const selectedEntity = searchedItems.find(item => item.id === props.input.value);
    const searchValue = (selectedEntity === undefined ? selectedKey : selectedEntity.key);
    return searchValue;
  };

  const searchedItems = searchText !== '' || props.searchResults.size > 0 ? props.searchResults : props.holdingOrgList;
  const searchValue = getSearchText(searchedItems);
  return (
    <div><ItemSelector
      id="item_selector"
      multiValue={false}
      selectFromListMenuItem={true}
      selectedItem={searchValue}
      dataSource={searchedItems}
      handleSelectedItem={handleSelectedItem}
      dataSourceConfig={{ text: 'key', value: 'key' }}
      textFieldStyle={Object.assign({}, defaultStyle.autoComplete)}
      handleMultipleSelect={() => setMultiple(true)}
      onAfterReset={props.onAfterReset}
      onSearch={handleSearch}
      input={props.input}
      disabled={props.disabled}
      createNewMenuItem={props.createNewMenuItem}
      handleAddClick={props.handleAddClick}
    />
      {multiple && <MultipleSelectorDialog
        id="multi_selector_dialog"
        title={recordTranslations.orgDialogHeader}
        open={multiple}
        multiValue={false}
        isGlobal={props.isGlobal}
        showOrganisationFilter={false}
        selectedOrgEntityId={props.selectedOrgEntityId}
        onRequestClose={() => setMultiple(false)}
        handleMultipleItems={handleMultipleItems}
        showFilters={false}
        isLegalEntity={props.isLegalEntity}
        isImportEntity={props.isImportEntity}
      />}
    </div>);
};

EntityImport.propTypes = {
  init: PropTypes.func,
  selectedOrgEntityId: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  }),
  onAfterReset: PropTypes.func,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  isGlobal: PropTypes.bool,
  createNewMenuItem: PropTypes.func,
  handleAddClick: PropTypes.func,
  isLegalEntity: PropTypes.bool,
  searchResults: PropTypes.instanceOf(Immutable.List),
  holdingOrgList: PropTypes.instanceOf(Immutable.List),
  isImportEntity: PropTypes.bool
};
EntityImport.defaultProps = {
  init: e => e,
  input: {},
  onAfterReset: e => e,
  selectedOrgEntityId: null,
  onSearch: e => e,
  disabled: false,
  isGlobal: false,
  createNewMenuItem: false,
  handleAddClick: e => e,
  isLegalEntity: true,
  searchResults: Immutable.List(),
  holdingOrgList: Immutable.List(),
  isImportEntity: false
};
export default EntityImport;
