import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: undefined,
  simpleEntityData: undefined,
  position: 0,
  sortAndSearchParams: Immutable.Map({
    purposes: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    purposesOfTransfer: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map()
    },
    processingGrounds: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    personalDataItems: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    retentionTerms: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    transferGrounds: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    qualityControls: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    informationRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    consentRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    accessRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    objectionRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    deletionRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    dataPortabilityRights: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    technicalSecurityMeasures: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    organisationalSecurityMeasures: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    contractualSecurityMeasures: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    }
  }),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isUsed: undefined,
  newItems: Immutable.List(),
  mergeItems: Immutable.List(),
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:RISKDETAILS:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('isUsed', defaultState.get('isUsed'))
        .set('simpleEntityData', defaultState.get('simpleEntityData'));
    case 'MASTERDATA:RISKDETAILS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('newItems', defaultState.get('newItems'))
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'MASTERDATA:RISKDETAILS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:RISKDETAILS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.simpleEntityData) {
        newItems = newItems.unshift(action.simpleEntityData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'MASTERDATA:RISKDETAILS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:RISKDETAILS:LIST:FILTER': {
      const { filterParams, entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.filterColumn = Immutable.Map(Object.assign({},
          currrentSortAndSearchParams.filterColumn,
          { [filterParams.filterKey]: filterParams.filterKey }));
        currrentSortAndSearchParams.filteredOn = getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: currrentSortAndSearchParams.filteredOn }));
        currrentSortAndSearchParams.filterParams = Immutable.Map(action.filterParams);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:RISKDETAILS:PAGE_SEARCH': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.searchText = getSearchText(action);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:RISKDETAILS:PAGE_SEARCH:SUCCESS': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.searchText = getSearchText(Object.assign({}, action,
          { searchObj: currrentSortAndSearchParams.searchText.toJS() }));
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('isFetching', false)
        .set('sortAndSearchParams', sortAndSearchParams)
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    }
    case 'MASTERDATA:RISKDETAILS:PAGE_SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:RISKDETAILS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('simpleEntityData', action.simpleEntity)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:RISKDETAILS:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:RISKDETAILS:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'))
        .set('newItems', action.newItems);
    case 'MASTERDATA:RISKDETAILS:ITEM:FETCH:SUCCESS':
      return state.set('simpleEntityData', action.simpleEntityData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:RISKDETAILS:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:RISKDETAILS:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:RISKDETAILS:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:RISKDETAILS:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:RISKDETAILS:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:RISKDETAILS:ITEM:MERGE:CHECK': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      const items = currentItems.bulkItems;
      if (currentItems) {
        currentItems.bulkItems = getCurrentMergeItems(action.item, items, action.dataItemId, action.unused);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      }
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:RISKDETAILS:MERGE:ITEM:RESET': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      currentItems.bulkItems = Immutable.List();
      sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:RISKDETAILS:ITEM:UNUSED:CHECK': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      currentItems.unused = action.checked || false;
      sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:RISKDETAILS:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    default:
      return state;
  }
};
