import React from 'react';

import {
  processingCharacteristicTypes, GermanProcessingCharacteristicsTypes, UKProcessingCharacteristicsTypes,
  BelgianProcessingCharacteristicsWhitelist, BelgianProcessingCharacteristicsBlacklist,
  NetherlandsProcessingCharacteristicsTypes, FrenchProcessingCharacteristicsBlacklist,
  FrenchProcessingCharacteristicsWhitelist, SpanishProcessingCharacteristicsBlacklist,
  SpanishProcessingCharacteristicsWhitelist, ChineseProcessingCharacteristicsTypes
} from
  '@packages/features/privacy-record-detail/assessments/utils/defaults';
import { breachNatureTypes, breachConsequenceTypes } from
  '@packages/features/privacy-record-detail/breaches/utils/defaults';
import { statusItems, categoryTypes, orgTypes, booleanTypes, tiaScoreRangeTypes } from '@packages/utils/record-utils';
import { getAllowedJurisdiction } from '@packages/utils/common-utils';
import TextFieldSelector from './textField';
import DateFieldSelector from './date';
import DropDownSelector from './dropDown';
import CheckBoxSelector from './checkBox';
import ControllerSelector from './controller';
import ProcessorSelector from './processor';
import OrganisationSelector from './organisation';
import ExecutingEntitySelector from './executing-entity';
import DataRecipientsSelector from './data-recipients';
import SubProcessorsSelector from './sub-processors';
import CountrySelector from './country';
import ProcessingCategorySelector from './processing-category';
import DatasourceSelector from './datasource';
import PersonalItemSelector from './personal-data-item';
import PersonalDataCategorySelector from './personal-data-category';
import DataSubjectCategorySelector from './datasubject-category';
import ProcessingGroundSelector from './processing-ground';
import TransferGroundSelector from './transfer-ground';
import BreachGroundSelector from './breach-ground';
import SupervisoryAuthoritySelector from './supervisory-authority';
import ReferenceSelector from './reference';
import RetentionTermSelector from './retention-term';
import SecurityMeasureSelector from './security-measures';
import SimpleNamedSelector from './simple-named-entity';
import OrganisationUserSelector from './organisation-user';
import DataRecipientCategorySelector from './data-recipient-category';
import DpoSelector from './data-protection-officer';
import DpoSelectorOrganisations from './dpo-organisation';
import GroupSelector from './links';
import LinkedRecords from './linkedRecords';
import ThirdPartyDataOrganisationsSelector from './third-party-data-organisations';
import {
  appiPersonalInfoTypeList, notificationMethods, processingActivityRole,
  regulatoryProcessStatus
} from '../../../privacy-record-detail/processings/utils/processingUtils';
import { organisationListFields } from '../../utils';
import DataExportersSelector from './data-exporters';
import DataImportersSelector from './data-importers';
import OnwardTransfers from './onward-transfers';
import CustomMasterDataSelector from './custom-master-data';
import CustomDropdownSelector from './custom-dropdown';
import CustomStakeholderSelector from './custom-stakeholder';


export default (selectedField, schema, selectedOperation, props, filterType, onSelect, removeItem) => {
  if (selectedOperation === 'wildcard' || selectedOperation === 'contains') {
    return (<TextFieldSelector {...props} />);
  }
  if (props.showAllStakeholders && organisationListFields.includes(selectedField?.valueType || filterType)) {
    return (<OrganisationSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
  }
  const [fieldType, entityType] = selectedField?.valueType.split('.') || [];
  const modifiedFieldType = [
    'stakeholder',
    'simpleMasterData',
    'picklist',
    'scoredPicklist'
  ].includes(fieldType)
    ? fieldType
    : selectedField?.valueType;

  switch (modifiedFieldType || filterType) {
    case 'controllers': return (<ControllerSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'processors': return (<ProcessorSelector onSelect={onSelect} removeItem={removeItem}{...props} />);
    case 'organisations': return (<OrganisationSelector {...props} />);
    case 'executingEntities':
      return (<ExecutingEntitySelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'dataRecipients': return (<DataRecipientsSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'subProcessors': return (<SubProcessorsSelector {...props} />);
    case 'processingCategories':
      return (<ProcessingCategorySelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'dataSubjectCategories':
      return (<DataSubjectCategorySelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'references': return (<ReferenceSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'retentionTerms': return (<RetentionTermSelector {...props} />);
    case 'retentionTermOffsets':
      return (<RetentionTermSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'securityMeasuresDescription': return (<SecurityMeasureSelector {...props} />);
    case 'piplDisclosedPersonalDataItems':
    case 'personalDataItems': return (<PersonalItemSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'createdBy': return (<OrganisationUserSelector {...props} />);
    case 'personalDataItemCategories': return (<PersonalDataCategorySelector {...props} />);
    case 'personalDataCategories':
      return (<PersonalDataCategorySelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'dataSources': return (<DatasourceSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'pdpaProcessingGrounds':
    case 'pdpaSpecialProcessingGrounds':
    case 'pdpProcessingGrounds':
    case 'piplProcessingGrounds':
    case 'piplInternationalTransferGrounds':
    case 'lgpdProcessingGrounds':
    case 'lgpdSpecialProcessingGrounds':
    case 'fdplProcessingGrounds':
    case 'sgpdpaProcessingGrounds':
    case 'ukgdprProcessingGrounds':
    case 'processingGrounds': return (<ProcessingGroundSelector
      {...props}
      subField={selectedField.subField}
      type={selectedField.valueType}
    />);
    case 'appiThirdPartyDataTransferGrounds':
    case 'appiDomesticTransferGrounds':
    case 'appiInternationalTransferGrounds':
    case 'sgpdpaTransferGrounds':
    case 'lgpdInternationalTransferGrounds':
    case 'ukgdprInternationalTransferGrounds':
    case 'transferGrounds': return (<TransferGroundSelector
      {...props}
      subField={selectedField.subField}
      type={selectedField.valueType}
    />);
    case 'piplInternationalTransferCountries':
    case 'appiInternationalTransferCountries':
    case 'jurisdiction':
    case 'country': return (<CountrySelector {...props} />);
    case 'categoryType': return (<DropDownSelector {...props} items={categoryTypes} />);
    case 'pdpLegalQualifications.includeMinorsData':
    case 'piplLegalQualifications.piplInternationTransfer.onwardTransferByThirdParty':
    case 'piplLegalQualifications.dataPubliclyDisclosed':
    case 'piplLegalQualifications.specialDataType.isDataSubjectUnderFourteen':
    case 'piplLegalQualifications.specialDataType.isSensitiveDataUsed':
    case 'pdpaLegalQualifications.internationalTransfer':
    case 'boolean': return (<DropDownSelector {...props} items={booleanTypes} />);
    case 'orgType': return (<DropDownSelector {...props} items={orgTypes} />);
    case 'status': return (<DropDownSelector {...props} items={statusItems} />);
    case 'piplLegalQualifications.dataSubjectNotificationMethod':
    case 'piplLegalQualifications.automatedDecisionMaking.dataSubjectOptOutMethod':
    case 'piplLegalQualifications.automatedDecisionMaking.dataSubjectDecisionMethod':
    case 'piplLegalQualifications.dataSubjectAuthorityMethod':
    case 'piplLegalQualifications.piplInternationTransfer.dataSubjectAuthorityMethod':
    case 'pdpaLegalQualifications.notificationMethod':
      return (<DropDownSelector{...props} items={notificationMethods} />);
    case 'piplLegalQualifications.processingActivityRole':
      return (<DropDownSelector{...props} items={processingActivityRole} />);
    case 'piplLegalQualifications.piplInternationTransfer.regulatoryProcessStatus':
      return (<DropDownSelector{...props} items={regulatoryProcessStatus} />);
    case 'processingCharacteristics.EDPB':
      return (<CheckBoxSelector {...props} items={processingCharacteristicTypes} />);
    case 'processingCharacteristics.DE':
      return (<CheckBoxSelector {...props} items={GermanProcessingCharacteristicsTypes} />);
    case 'processingCharacteristics.PIPL':
      return (<CheckBoxSelector {...props} items={ChineseProcessingCharacteristicsTypes} />);
    case 'processingCharacteristics.GB':
      return (<CheckBoxSelector {...props} items={UKProcessingCharacteristicsTypes} />);
    case 'processingCharacteristics.NL':
      return (<CheckBoxSelector {...props} items={NetherlandsProcessingCharacteristicsTypes} />);
    case 'processingCharacteristics.BE': return (<CheckBoxSelector
      {...props}
      items={[...BelgianProcessingCharacteristicsWhitelist, ...BelgianProcessingCharacteristicsBlacklist]}
    />);
    case 'processingCharacteristics.FR': return (<CheckBoxSelector
      {...props}
      items={[...FrenchProcessingCharacteristicsWhitelist, ...FrenchProcessingCharacteristicsBlacklist]}
    />);
    case 'processingCharacteristics.ES': return (<CheckBoxSelector
      {...props}
      items={[...SpanishProcessingCharacteristicsWhitelist, ...SpanishProcessingCharacteristicsBlacklist]}
    />);
    case 'breachNature': return (<CheckBoxSelector {...props} items={breachNatureTypes} ignoreItems={['Others']} />);
    case 'processingJurisdictions': return (<CheckBoxSelector {...props} items={getAllowedJurisdiction()} />);
    case 'appiLegalQualifications.personalInformations': return (
      <CheckBoxSelector {...props} items={appiPersonalInfoTypeList} />);
    case 'piplSpecialHandlingRules': return (<SimpleNamedSelector
      masterDataType="securityMeasures"
      entityType="specialSecurityMeasures"
      {...props}
    />);
    case 'technicalSecurityMeasures': return (<SecurityMeasureSelector {...props} />);
    case 'organisationalSecurityMeasures': return (<SimpleNamedSelector
      entityType="organisationalSecurityMeasures"
      masterDataType="securityMeasures"
      {...props}
    />);
    case 'tags':
    case 'policies':
    case 'qualityControls':
    case 'dataSourceCategories':
    case 'mitigatingMeasures': return (<SimpleNamedSelector
      masterDataType={selectedField.valueType}
      {...props}
    />);
    case 'riskDetails':
    case 'impacts':
    case 'threats': return (<SimpleNamedSelector
      masterDataType={selectedField.valueType}
      entityType={selectedField.field}
      {...props}
    />);
    case 'accountabilitySecurityMeasures': return (<SimpleNamedSelector
      masterDataType="accountabilitysecuritymeasures"
      entityType={selectedField.field}
      {...props}
    />);
    case 'dataSubjectRights': return (<SimpleNamedSelector
      masterDataType="datasubjectrights"
      entityType={selectedField.field}
      {...props}
    />);
    case 'accountabilities': return (<SimpleNamedSelector
      masterDataType="accountabilities"
      entityType={selectedField.name.substring(0, selectedField.name.length - 1)}
      {...props}
    />);
    case 'piplFairnessDecisions': return (<SimpleNamedSelector masterDataType="fairnessOfDecisions" {...props} />);
    case 'appiDomesticTransferPurposes':
    case 'appiInternationalTransferPurposes':
    case 'fdplInternationalTransferPurposes':
    case 'ukgdprInternationalTransferPurposes':
    case 'piplDisclosurePurposes':
    case 'purposes':
    case 'purpose':
    case 'fdplPrimaryPurpose':
    case 'purposesOfTransfer': return (<SimpleNamedSelector masterDataType="purposes" {...props} />);
    case 'dataRecipientCategories': return (<DataRecipientCategorySelector
      onSelect={onSelect}
      removeItem={removeItem}
      {...props}
    />);
    case 'dpoOrganisations': return (<DpoSelectorOrganisations
      {...props}
    />);
    case 'dpo': return (<DpoSelector
      onSelect={onSelect}
      removeItem={removeItem}
      dpoType={selectedField.nestedPath}
      {...props}
    />);
    case 'breachConsequences': return (<CheckBoxSelector
      {...props}
      items={breachConsequenceTypes}
      ignoreItems={['breach_consequences_others']}
    />);
    case 'supervisoryAuthorities': return (<SupervisoryAuthoritySelector {...props} />);
    case 'breachGrounds': return (<BreachGroundSelector {...props} subField={selectedField.subField} />);

    case 'date': {
      if (selectedOperation === 'range') return (<DateFieldSelector {...props} multiValue={true} />);
      return (<DateFieldSelector {...props} multiValue={false} />);
    }
    case 'text': {
      if (selectedOperation === 'range') return (<TextFieldSelector {...props} multiValue={true} />);
      return (<TextFieldSelector {...props} multiValue={false} />);
    }
    case 'links': return (<GroupSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'privacyrecord': return (<LinkedRecords selectedField={selectedField} {...props} />);
    case 'appiThirdPartyDataReceivedFrom':
      return (<ThirdPartyDataOrganisationsSelector selectedField={selectedField} {...props} />);
    case 'tiaRiskScoreRange': return (<DropDownSelector {...props} items={tiaScoreRangeTypes} />);
    case 'contractualSecurityMeasures': return (<SimpleNamedSelector
      entityType="contractualSecurityMeasures"
      masterDataType="securityMeasures"
      {...props}
    />);
    case 'dataExporters': return (<DataExportersSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'dataImporters': return (<DataImportersSelector onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'onwardDataTransfers':
      return (<OnwardTransfers onSelect={onSelect} removeItem={removeItem} {...props} />);
    case 'simpleMasterData': return <CustomMasterDataSelector entityType={entityType} {...props} />
    case 'picklist':
    case 'scoredPicklist':
      return <CustomDropdownSelector listId={entityType} {...props} />
    case 'stakeholder': 
      return <CustomStakeholderSelector stakeholderEntityId={entityType} {...props} />

    default: return (<TextFieldSelector {...props} multiValue={false} />);
  }
};
