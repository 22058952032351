import Immutable from 'immutable';

const defaultState = Immutable.Map({
  current: 0,
  notifications: Immutable.List()
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* eslint-disable no-case-declarations */
    case 'NOTIFIER:SHOW':
      const newMessage = {
        message: action.notification.content,
        key: state.get('current') + 1,
        level: action.notification.type,
        action: 'X',
        dismissAfter: action.dismissAfter || 5000
      };

      return state
        .update('notifications', notifications => notifications.push(newMessage))
        .update('current', current => current + 1);
    case 'NOTIFIER:HIDE':
      return state
        .update('notifications', notifications => notifications.filter(item => action.key !== item.key));
    case 'NOTIFIER:CLEAR:ALL':
      return state.set('notifications', defaultState.get('notifications'));
    default:
      return state;
  }
};

export default reducer;
