import { connect } from 'react-redux';
import ProcessingGroundSelector from './component';

const mapStateToProps = state => ({
  processingGrounds: state.recordsProcessinggrounds.get('processingGrounds'),
  error: state.recordsProcessinggrounds.get('error')
});

const mapDispatchToProps = dispatch => ({
  initProcessingGrounds: (filterType) => {
    dispatch({ type: 'PROCESSING_GROUNDS:RECORDS:LIST:INIT', filterType });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingGroundSelector);
