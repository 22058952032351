import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  helpNotes: Immutable.Map({}),
  recordHelpNotes: Immutable.Map({}),
  isUpdating: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'HELPTEXT:FETCH': {
      return state
        .set('helpNotes', state.get('helpNotes'));
    }
    case 'HELPTEXT:FETCH:SUCCESS': {
      return state
        .set('helpNotes', Immutable.Map(action.data));
    }
    case 'HELPTEXT:VALUE:UPDATE': {
      return state
        .set('helpNotes', Immutable.Map(action.data))
        .set('isUpdating', false);
    }
    case 'HELPTEXT:UPDATE': return state.set('isUpdating', true);
    case 'HELPTEXT:RECORDS:FETCH': {
      return state.set('recordHelpNotes', state.get('recordHelpNotes'));
    }
    case 'HELPTEXT:RECORDS:FETCH:SUCCESS': {
      return state.set('recordHelpNotes', action.data);
    }
    default:
      return state;
  }
};
