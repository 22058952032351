import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import {
  customTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';
import actionTranslations from '@packages/utils/actionTranslations';

import CreateRecordDialog from '../../../privacy-records/components/header/component/createRecordDialog';
import {
  getCreateDialogHintTextLabel,
  getCreateDialogSubTitle
} from '../../common-utils';

const CreateProcessings = (props) => {
  const {
    open,
    onRequestClose,
    isUpdating,
    data,
    createFromEdit,
    toggleLoader,
    isVendor,
    currentRecordType,
    subRecordTypes,
    actionError,
    filterMenuData,
    layoutId,
    onMount,
    onUnMount,
    vendorId,
    handleCloseCreateDialog,
    saveProcessing,
    isTemplateMode,
    refetchRecords,
    isGlobal
  } = props;

  const shouldShowCustomLabel =
    currentRecordType !== 'all' && filterMenuData?.label;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      onUnMount();
    };
  }, []);

  const createProcessing = (data, templateId, _, subRecordId) => {
    const modifiedData = {
      ...data,
      name: data.name.trim(),
      ...(subRecordId && { layoutId: subRecordId }),
      ...(isVendor && {
        processors: [{ value: { id: vendorId, subProcessors: [] } }]
      })
    };

    if (isVendor) {
      handleCloseCreateDialog();
    }
    saveProcessing(
      modifiedData,
      templateId,
      createFromEdit,
      isVendor,
      isTemplateMode,
      refetchRecords,
      isGlobal
    );
  };

  const getCreateDialogTitle = () =>
    actionTranslations(
      isTemplateMode ? 'createProcessingTemplate' : 'createProcessing'
    );

  return (
    <CreateRecordDialog
      id="create-processing"
      open={open}
      createFromEdit={createFromEdit}
      title={getCreateDialogTitle()}
      subTitle={getCreateDialogSubTitle(
        shouldShowCustomLabel,
        isTemplateMode,
        filterMenuData?.label
      )}
      hintTextLabel={getCreateDialogHintTextLabel(
        shouldShowCustomLabel,
        isTemplateMode
      )}
      label={
        currentRecordType !== 'all' && filterMenuData?.label
          ? customTranslation('emptyRecord', filterMenuData?.label)
          : recordTranslations.emptyRecord
      }
      hintTextCustomValue={filterMenuData?.label}
      data={data}
      recordType={
        currentRecordType === 'all' ? 'processings' : currentRecordType
      }
      currentFilter={currentRecordType}
      createRecord={createProcessing}
      onRequestClose={onRequestClose}
      isUpdating={isUpdating}
      actionError={actionError}
      toggleLoader={toggleLoader}
      isVendor={isVendor}
      subRecordTypes={subRecordTypes}
      layoutId={layoutId}
    />
  );
};

CreateProcessings.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({}),
  isUpdating: PropTypes.bool,
  createFromEdit: PropTypes.bool,
  onMount: PropTypes.func,
  onRequestClose: PropTypes.func,
  saveProcessing: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  toggleLoader: PropTypes.func,
  isVendor: PropTypes.bool,
  vendorId: PropTypes.string,
  handleCloseCreateDialog: PropTypes.func,
  currentRecordType: PropTypes.string,
  subRecordTypes: PropTypes.arrayOf(PropTypes.string),
  layoutId: PropTypes.string,
  onUnMount: PropTypes.func,
  filterMenuData: PropTypes.shape({
    label: PropTypes.string
  }),
  isTemplateMode: PropTypes.bool,
  refetchRecords: PropTypes.func,
  isGlobal: PropTypes.bool
};

CreateProcessings.defaultProps = {
  open: false,
  createFromEdit: false,
  isUpdating: false,
  data: {},
  onMount: (e) => e,
  onRequestClose: (e) => e,
  saveProcessing: (e) => e,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  toggleLoader: (e) => e,
  isVendor: false,
  vendorId: '',
  handleCloseCreateDialog: (e) => e,
  currentRecordType: 'processing_processings',
  subRecordTypes: [],
  layoutId: '',
  onUnMount: (e) => e,
  filterMenuData: {},
  isTemplateMode: false,
  refetchRecords: (e) => e,
  isGlobal: false
};

export default CreateProcessings;
