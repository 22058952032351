import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getTenantAdmins } from '../tenants';

export function* initializeHoldingTenantDetail() {
  const isEdit = (window.location.hash.indexOf('/holdingTenant/create') === -1);
  if (isEdit) {
    const { tenantId } = getParameterValuesFromHash('/holdingTenant/:tenantId/edit?:is');
    yield put({ type: 'HOLDING:TENANT:DETAIL:FETCH', tenantId });

    try {
      const response = yield registry.get('request')
        .get(`/v1/tenants/${tenantId}`, null);
      let adminList = [];
      let subTenantList = [];
      if (tenantId) {
        adminList = yield getTenantAdmins(tenantId);
        subTenantList = yield getSubTenants(tenantId);
      }
      switch (response.status) {
        case 200: {
          const responseData = response.body;
          const modifiedResponseData = { ...responseData, admins: adminList, subTenants: subTenantList };
          yield put({ type: 'HOLDING:TENANT:DETAIL:FETCH:SUCCESS', data: modifiedResponseData });
        }
          break;
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
      }
    } catch (err) {
      yield handleServiceDown(err, 'tenant');
      registry.get('logger').error(err);
      yield put({ type: 'HOLDING:TENANT:DETAIL:FETCH:FAIL', error: err.message });
    }
  }
}

export function* getSubTenants(tenantId) {
  let responseData = [];
  try {
    const response = yield registry.get('request')
      .get(`/v1/tenants/${tenantId}/subTenants`, null);
    switch (response.status) {
      case 200: {
        responseData = response.body;
        yield put({ type: 'HOLDING:TENANT:FETCH:SUB_TENANTS:SUCCESS', data: response.body });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'HOLDING:TENANT:FETCH:SUB_TENANTS:FAIL', error: err.message });
  }
  return responseData;
}
