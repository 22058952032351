import { connect } from 'react-redux';
import { PrivacyRecordListLayout } from './privacyRecordListLayout';

export * from './sagas';

function mapStateToProps(state) {
  const riskColumn = state.privacyRecords.columns.find(
    (item) => item.get('key') === 'risks'
  );
  return {
    currentFilter: state.privacyRecords.filters.get('currentFilter'),
    currentFilterData: state.privacyRecords.filters.get('currentFilterData'),
    showRisks: riskColumn.get('visible'),
    isFetching: state.privacyRecords.list.get('isFetching'),
    registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
    recordTypeList: state.customFields?.get('recordTypeList'),
    locale: state.intl.locale,
    tenantLocale: state.login?.get('tenantLocale'),
    userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
    isTemplateMode: state.privacyRecords.list.get('isTemplateMode'),
    isGlobal: state.home?.get('isGlobal')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch({ type: 'PRIVACY_RECORDS:FILTERS:INIT' });
      dispatch({
        type: 'ASSESSMENT:FILTERS:CHANGE',
        filterName: 'PreAssessment'
      });
      dispatch({ type: 'PROCESSING:FILTERS:CHANGE', filterName: 'general' });
      dispatch({ type: 'BREACH:FILTERS:CHANGE', filterName: 'general' });
      dispatch({
        type: 'CUSTOM_DYNAMIC_RECORD_TYPE:LIST:FETCH:INIT',
        filterDocument: true
      });
    },
    onChangeFilter: (filterName, data) => {
      dispatch({
        type: 'PRIVACY_RECORDS:FILTERS:CHANGE',
        filterName,
        data
      });
    },
    toggleLoader: (toggle) => {
      dispatch({ type: 'LOADER:TOGGLE', toggle });
    },
    setFiltersForCustomList: (customRecordTypes) => {
      dispatch({
        type: 'PRIVACY_RECORDS:CUSTOM:FILTER:INIT',
        customRecordTypes
      });
    },
    toggleMode: () => {
      dispatch({ type: 'RECORDS:MODE:TOGGLE', checked: false });
    }
  };
}

export const PrivacyRecords = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyRecordListLayout);
