import { connect } from 'react-redux';
import ProcessingCategorySelector from './component';
import sagas from './sagas';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  processingCategories: state.environment.processingCategories.get('items'),
  tags: state.environment.simpleEntities.tags.get('items'),
  tagsPosition: state.environment.simpleEntities.tags.get('position'),
  tagSearchResult: state.simpleEntities.get('entitySelectorSearch').get('tags')
    .searchResults,
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  position: state.environment.processingCategories.get('position'),
  error: state.processingCategories.get('error'),
  searchResults: state.processingCategories.get('items'),
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  initProcessingCategories: (isGlobal) => {
    dispatch({ type: 'PROCESSING_CATEGORIES:LIST:REQUEST_INIT' });
    if (!isGlobal) {
      dispatch({
        type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
        masterDataType: 'tags'
      });
    }
  },
  getNextData: (position) => {
    dispatch({
      type: 'PROCESSING_CATEGORIES:LIST:NEXT_FETCH',
      position,
      isPersistedData: true
    });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PROCESSING_CATEGORY:LIST:SEARCH_INIT', searchParams });
  },
  onChooseFilter: (filterParams) => {
    dispatch({
      type: 'PROCESSING_CATEGORIES:LIST:FILTER_INIT',
      filterParams,
      searchParams: { searchKey: 'name' },
      source: 'records',
      isPersistedData: true
    });
  },
  tagSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingCategorySelector);
export { reducers, sagas };
