import { connect } from 'react-redux';
import Loader from './loader';
import reducers from './reducers';

const mapStateToProps = state => ({
  showHideLoader: state.loader.get('showHideLoader'),
  content: state.loader.get('content'),
  contentStyle: state.loader.get('contentStyle'),
  handleAbort: state.loader.get('handleAbort')
});

export default connect(mapStateToProps, null)(Loader);
export { reducers };
