import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';
import { filterSelectedItems } from '../../legalGroundUtils';

export function* resetAndfetchBreachGrounds(action) {
  yield put({ type: 'BREACH_GROUNDS:LIST:REQUEST:INIT' });
  yield fetchBreachGrounds(action);
}

export function* fetchBreachGrounds(action) {
  yield put({ type: 'BREACH_GROUNDS:LIST:FETCH' });

  try {
    const intlSelector = yield select(state => state.intl);
    const locale = intlSelector?.locale || '';
    const filterParam = (action.isEuJurisdiction === true) ? '&filter=jurisdiction%3DEU' : '';
    const response = yield registry.get('request')
      .get(`/v1/masterdata/legalgrounds?filter=groundType=breach_ground${filterParam}&filter=language%3D${locale}`, null);
    switch (response.status) {
      case 200: {
        const responseData = response.body;
        const breachGroundsState = yield select(state => state.breachGrounds);
        const modifiedGrounds = responseData.map(item => ({
          ...item,
          jurisdiction: item.jurisdiction.id
        }));
        const items = filterSelectedItems(modifiedGrounds, breachGroundsState); // Filter breach grounds

        yield put({ type: 'BREACH_GROUNDS:LIST:FETCH:SUCCESS', items, initialItems: modifiedGrounds });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'BREACH_GROUNDS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* filterBreachGrounds(action) {
  const { filterParams } = action;
  yield put({ type: 'BREACH_GROUNDS:LIST:FILTER', filterParams });

  const breachGroundsState = yield select(state => state.breachGrounds);
  const breachGrounds = breachGroundsState ? breachGroundsState.get('initialItems') : [];

  // Filter breach grounds
  const items = filterSelectedItems(breachGrounds, breachGroundsState);
  yield put({ type: 'BREACH_GROUNDS:LIST:FILTER:SUCCESS', items });
}

export function* searchBreachGrounds(action) {
  const { searchParams } = action;

  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  // For first time rendering of search items, searchText will not be defined
  const breachGroundsState = yield select(state => state.breachGrounds);
  const breachGrounds = breachGroundsState ? breachGroundsState.get('initialItems') : [];
  if (searchText === undefined) {
    const searchTextObj = breachGroundsState ? breachGroundsState.get('searchText') : Immutable.Map();
    searchText = searchTextObj.get(searchKey) || '';
  }

  const searchTextValue = searchText.toLowerCase();
  const items = breachGrounds.filter((breachGround) => {
    const index = breachGround[searchKey].toLowerCase().indexOf(searchTextValue);
    return (index !== -1);
  });

  yield put({ type: 'BREACH_GROUNDS:LIST:SEARCH:SUCCESS', searchKey, searchText, items });
}
