import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  entityDetails: undefined,
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ORGANISATION_ENTITY:DETAIL:FETCH:SUCCESS':
      return state.set('isFetching', false)
        .set('entityDetails', action.items)
        .set('error', defaultState.get('error'));
    case 'ORGANISATION_ENTITY:DETAIL:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
