import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import {
  recordTranslations,
  commonTranslations
} from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import ProcessingGroundList from '.';
import { legalGroundTranslations } from '../../../recordTranslations';

const ProcessingGroundListDialog = (props) => {
  const {
    handleSelectedItems,
    removeFilters,
    selectedItems,
    customStyle,
    selectedControllers,
    isEdit,
    selectedPersonalDataItems,
    jurisdiction,
    filterType,
    disabled
  } = props;

  const [open, setOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const getFilterAppplied = (filterApplied) => {
    setIsFilterApplied(filterApplied);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const requestClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = (selectedItems) => {
    if (handleSelectedItems) {
      handleSelectedItems(selectedItems);
    }
    requestClose();
  };

  const handleClick = () => {
    removeFilters();
  };

  const selectedGrounds = Object.assign([], selectedItems);

  return (
    <div style={{ paddingTop: '10px', ...customStyle }}>
      <Button
        id="procssingGroundbutton"
        classes={{
          root: disabled ? 'button_grey' : 'button_black',
          label: 'buttonLabel_legalgrounds'
        }}
        disabled={disabled}
        onClick={handleOpen}
      >
        {recordTranslations.processinggrounds}
      </Button>
      {open && (
        <CommonDialog
          id="Processing-ground-dialog"
          show={open}
          onCancel={requestClose}
          maxWidth="md"
          fullWidth={true}
          title={recordTranslations.processinggrounds}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%' }}>
              {legalGroundTranslations.helpNote}
            </div>
            <div className="legal-ground-header">
              {isFilterApplied && (
                <Button id="text-button" variant="text" onClick={handleClick}>
                  {commonTranslations.removeFilters}
                </Button>
              )}
            </div>
          </div>
          <ProcessingGroundList
            id="processing-ground-list"
            isEdit={isEdit}
            selectedItems={selectedGrounds}
            selectedControllers={selectedControllers}
            selectedPersonalDataItems={selectedPersonalDataItems}
            onSave={handleOnSubmit}
            onCancel={requestClose}
            getFilterAppplied={getFilterAppplied}
            jurisdiction={jurisdiction}
            filterType={filterType}
          />
        </CommonDialog>
      )}
    </div>
  );
};

ProcessingGroundListDialog.propTypes = {
  handleSelectedItems: PropTypes.func,
  isEdit: PropTypes.bool,
  selectedControllers: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPersonalDataItems: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  removeFilters: PropTypes.func,
  customStyle: PropTypes.shape({}),
  jurisdiction: PropTypes.string,
  filterType: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool
};

ProcessingGroundListDialog.defaultProps = {
  isEdit: false,
  selectedControllers: [],
  selectedPersonalDataItems: [],
  selectedItems: [],
  handleSelectedItems: (e) => e,
  removeFilters: (e) => e,
  customStyle: {},
  jurisdiction: 'EU',
  filterType: [],
  disabled: false
};

export default ProcessingGroundListDialog;
