import Immutable from 'immutable';
import { getCurrentMergeItems } from '../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: [],
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  bulkItems: Immutable.List(),
  usage: undefined,
  isUpdateSuccess: null,
  assessmentSearchParams: {},
  processingSearchParams: {},
  environmentSearchParams: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LINK_GROUP:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'))
        .set('items', defaultState.get('items'))
        .set('isUpdateSuccess', defaultState.get('isUpdateSuccess'))
        .set('bulkItems', defaultState.get('bulkItems'))
        .set('position', 0);
    case 'LINK_GROUP:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', [...state.get('items'), ...action.items])
        .set('position', state.get('position') + action.items.length);
    case 'LINK_GROUP:LIST:UPDATE':
      return state
        .set('items', action.items)
        .set('position', action.items.length);
    case 'MASTERDATA:DATAITEMGROUP:UPSERT:FAIL':
    case 'LINK_GROUP:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'LINK_GROUP:LIST:ITEM:BULK:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems', getCurrentMergeItems(action.item, items, action.dataItemId, true));
    }
    case 'LINK_GROUP:LIST:ITEM:BULK:CHECK:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:DATAITEMGROUP:USAGE:SUCCESS': {
      return state
        .set('usage', action.usage); }
    case 'ASSESSMENT:LINK_GROUP:CREATE:SUCCESS':
    case 'PROCESSING:LINK_GROUP:CREATE:SUCCESS':
    case 'MASTERDATA:LINK_GROUP:UPSERT:SUCCESS': {
      return state
        .set('isUpdateSuccess', true)
        .set('environmentSearchParams', {});
    }
    case 'ASSESSMENT:LINK_GROUP:UPSERT:FAIL':
    case 'PROCESSING:LINK_GROUP:UPSERT:FAIL':
    case 'MASTERDATA:LINK_GROUP:UPSERT:FAIL': {
      return state
        .set('isUpdateSuccess', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    }
    case 'LINK_GROUP:RESET': {
      return state
        .set('error', defaultState.get('error'))
        .set('isUpdateSuccess', defaultState.get('isUpdateSuccess'));
    }
    case 'LINK_GROUP:SEARCH': {
      const { searchParams, source } = action;
      return state
        .set(`${source}SearchParams`, searchParams);
    }
    case 'LINK_GROUP:BULK:DELETE:SUCCESS': {
      return state
        .set('environmentSearchParams', {});
    }
    default:
      return state;
  }
};
