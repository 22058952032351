import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchPasswordSettings() {
  yield put({ type: 'PASSWORDSETTINGS:FETCH' });
  try {
    const response = yield registry.get('request')
      .get(' /v1/settings/passwordExpiry');

    switch (response.status) {
      case 200: {
        const item = response.body;
        yield put({ type: 'PASSWORDSETTINGS:FETCH:SUCCESS', data: item });
        break;
      }

      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'PASSWORDSETTINGS:FETCH:FAIL', error: err.message });
  }
}
export function* updatePasswordSettings(action) {
  const { passwordSettingDetail } = action;
  try {
    const request = registry.get('request');
    const response = yield request
      .put(' /v1/settings/passwordExpiry', passwordSettingDetail);

    switch (response.status) {
      case 200: {
        const item = response.body;
        yield put({ type: 'PASSWORDSETTINGS:UPDATE:SUCCESS', data: item });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.passwordSettingsUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'PASSWORDSETTINGS:UPDATE:FAIL', error: err.message });
  }
}
