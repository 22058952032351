import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  isUserUpdated: false,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  searchResults: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ORGANISATION:USER:DETAIL:INIT':
      return state.set('actionError', defaultState.get('actionError'))
        .set('isUserUpdated', defaultState.get('isUserUpdated'));

    case 'ORGANISATION:USER:DETAIL:UPSERT:SUCCESS':
      return state.set('isUserUpdated', true);

    case 'ORGANISATION:USER:DETAIL:UPSERT:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));

    case 'ORGANISATION_USER:DATA:RESET':
      return state.set('isUserUpdated', defaultState.get('isUserUpdated'));
    case 'LEGAL_ENTITY:USERORGANISATION:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'LEGAL_ENTITY:USERORGANISATION:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchResults', Immutable.List(action.searchResults));
    case 'LEGAL_ENTITY:USERORGANISATION:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('actionError', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
