import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';

import DropDownSelector from '../dropDown/component';

const CustomDropdownSelector = (props) => {
  const { listId, picklistOptions, fetchOptions, locale, tenantLocale } = props;

  useEffect(() => {
    fetchOptions(listId);
  }, [listId]);

  const getModifiedOptions = (options) =>
    options?.map((item) => ({
      ...item,
      value: item.id,
      label: getTranslatedLabel(item.names, locale, tenantLocale)
    })) || [];

  return (
    <DropDownSelector
      {...props}
      items={getModifiedOptions(picklistOptions?.[listId])}
    />
  );
};

CustomDropdownSelector.propTypes = {
  listId: PropTypes.string,
  picklistOptions: PropTypes.shape({}),
  fetchOptions: PropTypes.func,
  locale: PropTypes.string,
  tenantLocale: PropTypes.string
};

CustomDropdownSelector.defaultProps = {
  listId: '',
  picklistOptions: {},
  fetchOptions: (e) => e,
  locale: '',
  tenantLocale: ''
};

export default CustomDropdownSelector;
