import {
  recordTranslations,
  commonTranslations
} from '@packages/utils/commontranslations';
import {
  pluralTerms as uppercasePlural,
  singularTerms as uppercaseSingular
} from '@packages/utils/uppercaseTranslations';
import { sectionHeaderTranslations } from '../../../../environment/masterDataTranslations';
import {
  chapterTranslations,
  legalGroundTranslations
} from '../../../../privacy-record-detail/recordTranslations';

const assessmentsFieldList = [
  {
    label: uppercaseSingular('preAssessment'),
    key: 'PreAssessment',
    subFields: [
      {
        key: 'ProcessingCategories',
        isCommon: true,
        label: recordTranslations.processingCategories
      },
      {
        key: 'LinkedRecords',
        label: recordTranslations.linkedRecords
      },
      {
        key: 'References',
        isCommon: true,
        label: uppercasePlural('references')
      },
      {
        key: 'Purposes',
        isCommon: true,
        label: recordTranslations.assessmentPurpose
      },
      {
        key: 'PreAssessmentOutcome',
        label: uppercaseSingular('outcomeHeader')
      },
      {
        key: 'AssessmentChapters',
        label: uppercasePlural('optionalChapters')
      }
    ]
  },
  {
    label: recordTranslations.processingDescription,
    key: 'processingDescription',
    subFields: [
      {
        key: 'Controllers',
        isCommon: true,
        label: uppercasePlural('controllers')
      },
      {
        key: 'Processors',
        isCommon: true,
        label: uppercasePlural('processors')
      },
      {
        key: 'ExecutingEntities',
        isCommon: true,
        label: uppercasePlural('executingEntities')
      },
      {
        key: 'DataRecipients',
        isCommon: true,
        label: uppercasePlural('dataRecipients')
      },
      {
        key: 'DataRecipientCategory',
        isCommon: true,
        label: uppercasePlural('dataRecipientCategories')
      },
      {
        key: 'NumberOfDataSubjects',
        label: recordTranslations.numOfDataSubjects
      },
      {
        key: 'DataSubjectCategories',
        isCommon: true,
        label: recordTranslations.dataSubjectCategories
      },
      {
        key: 'PersonalDataCategories',
        isCommon: true,
        label: uppercasePlural('personalDataCategories')
      },
      {
        key: 'PersonalDataItems',
        isCommon: true,
        label: uppercasePlural('personalDataItems')
      },
      {
        key: 'DataSourceCategories',
        isCommon: true,
        label: uppercasePlural('dataSourceCategories')
      },
      {
        key: 'DataSources',
        isCommon: true,
        label: uppercasePlural('dataSources')
      },
      {
        key: 'RetentionTerms',
        isCommon: true,
        label: recordTranslations.retentionTerms
      },
      {
        key: 'LinksComment',
        label: recordTranslations.LinksComment
      }
    ]
  },

  {
    label: recordTranslations.legalQualifications,
    key: 'LegalQualifications',
    subFields: [
      {
        key: 'Commentaries',
        label: recordTranslations.purposeCommentary
      },
      {
        key: 'ProcessingGrounds',
        isCommon: true,
        label: recordTranslations.processingGrounds
      },
      {
        key: 'InternationalTransfers',
        isCommon: true,
        label: recordTranslations.internationalTransfers
      }
    ]
  },
  {
    label: chapterTranslations.NecessityAndProportionality,
    key: 'NecessityAndProportionality',
    subFields: [
      {
        key: 'NecessityPurposes',
        label: recordTranslations.assessmentPurpose
      },
      {
        key: 'PurposesRiskAndIsMitigated',
        dependentOn: 'NecessityPurposes',
        label: 'purposes-RiskAndIsMitigated'
      },
      {
        key: 'PurposesRisksAndMeasures',
        dependentOn: 'NecessityPurposes',
        label: 'purposes-RisksAndMeasures'
      },
      {
        key: 'PurposesComment',
        label: recordTranslations.PurposesComment
      },
      {
        key: 'NecessityProcessingGrounds',
        label: recordTranslations.processingGrounds
      },
      {
        key: 'ProcessingGroundsRiskAndIsMitigated',
        dependentOn: 'NecessityProcessingGrounds',
        label: 'processingGrounds-RiskAndIsMitigated'
      },
      {
        key: 'ProcessingGroundsRisksAndMeasures',
        dependentOn: 'NecessityProcessingGrounds',
        label: 'processingGrounds-RisksAndMeasures'
      },
      {
        key: 'ProcessingGroundsComment',
        label: recordTranslations.ProcessingGroundsComment
      },
      {
        key: 'NecessityPersonalDataItems',
        label: uppercasePlural('personalDataItems')
      },
      {
        key: 'PersonalDataItemsRiskAndIsMitigated',
        dependentOn: 'NecessityPersonalDataItems',
        label: 'personalDataItems-RiskAndIsMitigated'
      },
      {
        key: 'PersonalDataItemsRisksAndMeasures',
        dependentOn: 'NecessityPersonalDataItems',
        label: 'personalDataItems-RisksAndMeasures'
      },
      {
        key: 'PersonalDataItemsComment',
        label: recordTranslations.PersonalDataItemsComment
      },
      {
        key: 'NecessityRetentionTerms',
        label: recordTranslations.retentionTerms
      },
      {
        key: 'RetentionTermsRiskAndIsMitigated',
        dependentOn: 'NecessityRetentionTerms',
        label: 'retentionTerms-RiskAndIsMitigated'
      },
      {
        key: 'RetentionTermsRisksAndMeasures',
        dependentOn: 'NecessityRetentionTerms',
        label: 'retentionTerms-RisksAndMeasures'
      },
      {
        key: 'RetentionTermsComment',
        label: recordTranslations.RetentionTermsComment
      },
      {
        key: 'NecessityPurposesOfTransfer',
        label: uppercaseSingular('purposeOfTransfer')
      },
      {
        key: 'TransferPurposesRiskAndIsMitigated',
        dependentOn: 'NecessityPurposesOfTransfer',
        label: 'purposesOfTransfer-RiskAndIsMitigated'
      },
      {
        key: 'TransferPurposesRisksAndMeasures',
        dependentOn: 'NecessityPurposesOfTransfer',
        label: 'purposesOfTransfer-RisksAndMeasures'
      },
      {
        key: 'PurposesOfTransferComment',
        label: recordTranslations.PurposesOfTransferComment
      },
      {
        key: 'NecessityTransferGrounds',
        label: uppercasePlural('transferGrounds')
      },
      {
        key: 'TransferGroundsRiskAndIsMitigated',
        dependentOn: 'NecessityTransferGrounds',
        label: 'transferGrounds-RiskAndIsMitigated'
      },
      {
        key: 'TransferGroundsRisksAndMeasures',
        dependentOn: 'NecessityTransferGrounds',
        label: 'transferGrounds-RisksAndMeasures'
      },
      {
        key: 'TransferGroundsComment',
        label: recordTranslations.TransferGroundsComment
      },
      {
        key: 'QualityControls',
        label: sectionHeaderTranslations.dataQualityMeasures
      },
      {
        key: 'QualityControlsRiskAndIsMitigated',
        dependentOn: 'QualityControls',
        label: 'dataQualityControls-RiskAndIsMitigated'
      },
      {
        key: 'QualityControlsRisksAndMeasures',
        dependentOn: 'QualityControls',
        label: 'dataQualityControls-RisksAndMeasures'
      },
      {
        key: 'QualityControlsComment',
        label: recordTranslations.QualityControlsComment
      },
      {
        key: 'NecessityAndProportionalityOutcome',
        label: uppercaseSingular('outcomeHeader')
      }
    ]
  },
  {
    label: recordTranslations.dataSubjectRights,
    key: 'DataSubjectRights',
    subFields: [
      {
        key: 'InformationRights',
        label: sectionHeaderTranslations.informationRights
      },
      {
        key: 'InformationRightsRiskAndIsMitigated',
        dependentOn: 'InformationRights',
        label: 'rightsToInformation-RiskAndIsMitigated'
      },
      {
        key: 'InformationRightsRisksAndMeasures',
        dependentOn: 'InformationRights',
        label: 'rightsToInformation-RisksAndMeasures'
      },
      {
        key: 'InformationRightsComment',
        label: recordTranslations.InformationRightsComment
      },
      {
        key: 'ConsentRights',
        label: sectionHeaderTranslations.consentRights
      },
      {
        key: 'ConsentRightsRiskAndIsMitigated',
        dependentOn: 'ConsentRights',
        label: 'validitiesOfConsent-RiskAndIsMitigated'
      },
      {
        key: 'ConsentRightsRisksAndMeasures',
        dependentOn: 'ConsentRights',
        label: 'validitiesOfConsent-RisksAndMeasures'
      },
      {
        key: 'ConsentRightsComment',
        label: recordTranslations.ConsentRightsComment
      },
      {
        key: 'AccessRights',
        label: sectionHeaderTranslations.accessRights
      },
      {
        key: 'AccessRightsRiskAndIsMitigated',
        dependentOn: 'AccessRights',
        label: 'rightsToAccess-RiskAndIsMitigated'
      },
      {
        key: 'AccessRightsRisksAndMeasures',
        dependentOn: 'AccessRights',
        label: 'rightsToAccess-RisksAndMeasures'
      },
      {
        key: 'AccessRightsComment',
        label: recordTranslations.AccessRightsComment
      },
      {
        key: 'ObjectionRights',
        label: sectionHeaderTranslations.objectionRights
      },
      {
        key: 'ObjectionRightsRiskAndIsMitigated',
        dependentOn: 'ObjectionRights',
        label: 'rightsToObjection-RiskAndIsMitigated'
      },
      {
        key: 'ObjectionRightsRisksAndMeasures',
        dependentOn: 'ObjectionRights',
        label: 'rightsToObjection-RisksAndMeasures'
      },
      {
        key: 'ObjectionRightsComment',
        label: recordTranslations.ObjectionRightsComment
      },
      {
        key: 'DeletionRights',
        label: sectionHeaderTranslations.deletionRights
      },
      {
        key: 'DeletionRightsRiskAndIsMitigated',
        dependentOn: 'DeletionRights',
        label: 'rightsToErasure-RiskAndIsMitigated'
      },
      {
        key: 'DeletionRightsRisksAndMeasures',
        dependentOn: 'DeletionRights',
        label: 'rightsToErasure-RisksAndMeasures'
      },
      {
        key: 'DeletionRightsComment',
        label: recordTranslations.DeletionRightsComment
      },
      {
        key: 'DataPortabilityRights',
        label: sectionHeaderTranslations.dataPortabilityRights
      },
      {
        key: 'DataPortabilityRightsRiskAndIsMitigated',
        dependentOn: 'DataPortabilityRights',
        label: 'rightsToDataPortability-RiskAndIsMitigated'
      },
      {
        key: 'DataPortabilityRightsRisksAndMeasures',
        dependentOn: 'DataPortabilityRights',
        label: 'rightsToDataPortability-RisksAndMeasures'
      },
      {
        key: 'DataPortabilityRightsComment',
        label: recordTranslations.DataPortabilityRightsComment
      },
      {
        key: 'DataSubjectRightsOutcome',
        label: uppercaseSingular('outcomeHeader')
      }
    ]
  },
  {
    label: recordTranslations.securityControls,
    key: 'SecurityControls',
    subFields: [
      {
        key: 'TechnicalSecurityMeasures',
        isCommon: true,
        label: sectionHeaderTranslations.technicalSecurityMeasuresHeader
      },
      {
        key: 'TechnicalSecurityMeasuresRiskAndIsMitigated',
        dependentOn: 'TechnicalSecurityMeasures',
        label: 'technicalSecurityMeasures-RiskAndIsMitigated'
      },
      {
        key: 'TechnicalSecurityMeasuresRisksAndMeasures',
        dependentOn: 'TechnicalSecurityMeasures',
        label: 'technicalSecurityMeasures-RisksAndMeasures'
      },
      {
        key: 'TechnicalSecurityMeasuresComment',
        label: recordTranslations.TechnicalSecurityMeasuresComment
      },
      {
        key: 'OrganisationalSecurityMeasures',
        isCommon: true,
        label: sectionHeaderTranslations.organisationalMeasuresHeader
      },
      {
        key: 'OrganisationalSecurityMeasuresRiskAndIsMitigated',
        dependentOn: 'OrganisationalSecurityMeasures',
        label: 'organisationalSecurityMeasures-RiskAndIsMitigated'
      },
      {
        key: 'OrganisationalSecurityMeasuresRisksAndMeasures',
        dependentOn: 'OrganisationalSecurityMeasures',
        label: 'organisationalSecurityMeasures-RisksAndMeasures'
      },
      {
        key: 'OrganisationalSecurityMeasuresComment',
        label: recordTranslations.OrganisationalSecurityMeasuresComment
      },
      {
        key: 'SecurityMeasuresOutcome',
        label: uppercaseSingular('outcomeHeader')
      }
    ]
  },
  {
    label: recordTranslations.threatImpact,
    key: 'ImpactAssessment',
    subFields: [
      {
        key: 'ConfidentialityThreats',
        label: sectionHeaderTranslations.confidentialityThreats
      },
      {
        key: 'ConfidentialityRiskAnalysis',
        dependentOn: 'ConfidentialityThreats',
        label: 'confidentialityThreat-riskAnalysis'
      },
      {
        key: 'ConfidentialityMitigatingMeasures',
        dependentOn: 'ConfidentialityThreats',
        label: 'confidentialityThreat-mitigatingMeasures'
      },
      {
        key: 'ConfidentialityThreatsComment',
        label: recordTranslations.ConfidentialityThreatsComment
      },
      {
        key: 'IntegrityThreats',
        label: sectionHeaderTranslations.integrityThreats
      },
      {
        key: 'IntegrityRiskAnalysis',
        dependentOn: 'IntegrityThreats',
        label: 'integrityThreat-riskAnalysis'
      },
      {
        key: 'IntegrityMitigatingMeasures',
        dependentOn: 'IntegrityThreats',
        label: 'integrityThreat-mitigatingMeasures'
      },
      {
        key: 'IntegrityThreatsComment',
        label: recordTranslations.IntegrityThreatsComment
      },
      {
        key: 'AvailabilityThreats',
        label: sectionHeaderTranslations.availabilityThreats
      },
      {
        key: 'AvailabilityRiskAnalysis',
        dependentOn: 'AvailabilityThreats',
        label: 'availabilityThreat-riskAnalysis'
      },
      {
        key: 'AvailabilityMitigatingMeasures',
        dependentOn: 'AvailabilityThreats',
        label: 'availabilityThreat-mitigatingMeasures'
      },
      {
        key: 'AvailabilityThreatsComment',
        label: recordTranslations.AvailabilityThreatsComment
      },
      {
        key: 'ThreatAndImpactOutcome',
        label: uppercaseSingular('outcomeHeader')
      }
    ]
  },
  {
    label: recordTranslations.accountability,
    key: 'Accountability',
    subFields: [
      {
        key: 'Policies',
        label: sectionHeaderTranslations.policyHeader
      },
      {
        key: 'PoliciesComment',
        label: recordTranslations.PoliciesComment
      },
      {
        key: 'DataProtectionMeasures',
        label: sectionHeaderTranslations.dataProtectionMeasures
      },
      {
        key: 'DataProtectionMeasuresComment',
        label: recordTranslations.DataProtectionMeasuresComment
      },
      {
        key: 'AccountabilityMeasures',
        label: sectionHeaderTranslations.accountabilityMeasures
      },
      {
        key: 'AccountabilityMeasuresComment',
        label: recordTranslations.AccountabilityMeasuresComment
      },
      {
        key: 'DataSubjectCategoriesIsConsulted',
        dependentOn: 'DataSubjectCategories',
        label: 'dataSubjectCategories-IsConsulted'
      },
      {
        key: 'DataSubjectCategoriesFindingsAndDecisions',
        dependentOn: 'DataSubjectCategories',
        label: 'dataSubjectCategories-FindingsAndDecisions'
      },
      {
        key: 'DataSubjectCategoriesComment',
        label: recordTranslations.DataSubjectCategoriesComment
      },
      {
        key: 'DpoConsultation',
        label: uppercasePlural('DPOConsultation')
      },
      {
        key: 'DPOIsConsulted',
        dependentOn: 'DpoConsultation',
        label: 'dpo-IsConsulted'
      },
      {
        key: 'DPOFindingsAndDecisions',
        dependentOn: 'DpoConsultation',
        label: 'dpo-FindingsAndDecisions'
      },
      {
        key: 'DPOComment',
        label: recordTranslations.DPOComment
      },
      {
        key: 'SupervisoryAuthorities',
        label: uppercasePlural('supervisoryAuthorities')
      },
      {
        key: 'SupervisoryAuthoritiesIsConsulted',
        dependentOn: 'SupervisoryAuthorities',
        label: 'supervisoryAuthorities-IsConsulted'
      },
      {
        key: 'SupervisoryAuthoritiesFindingsAndDecisions',
        dependentOn: 'SupervisoryAuthorities',
        label: 'supervisoryAuthorities-FindingsAndDecisions'
      },
      {
        key: 'SupervisoryAuthoritiesComment',
        label: recordTranslations.SupervisoryAuthoritiesComment
      },
      {
        key: 'AccountabilityOutcome',
        label: uppercaseSingular('outcomeHeader')
      }
    ]
  },
  {
    label: uppercaseSingular('summary'),
    key: 'Summary',
    subFields: [
      {
        key: 'NecessityAndProportionalityRiskSummary',
        label: recordTranslations.NecessityAndProportionalityRiskSummary
      },
      {
        key: 'DataSubjectRightsRiskSummary',
        label: recordTranslations.DataSubjectRightsRiskSummary
      },
      {
        key: 'SecurityMeasuresRiskSummary',
        label: recordTranslations.SecurityMeasuresRiskSummary
      },
      {
        key: 'ThreatImpactRiskSummary',
        label: recordTranslations.ThreatImpactRiskSummary
      },
      {
        key: 'Outcome',
        label: sectionHeaderTranslations.conclusionHeader
      },
      {
        key: 'OverallRiskSummary',
        label: recordTranslations.OverallRiskSummary
      }
    ]
  },
  {
    label: recordTranslations.attachments,
    key: 'attachments',
    subFields: [
      {
        key: 'Attachments',
        isCommon: true,
        label: recordTranslations.attachments
      }
    ]
  },
  {
    label: legalGroundTranslations.permissions,
    key: 'permissions',
    subFields: [
      {
        key: 'UserPermissions',
        isCommon: true,
        label: commonTranslations.users
      },
      {
        key: 'OrganisationPermissions',
        isCommon: true,
        label: uppercasePlural('organisations')
      },
      {
        key: 'CompanyAccess',
        isCommon: true,
        label: recordTranslations.companyAccess
      }
    ]
  }
];

export default assessmentsFieldList;
