import React from 'react';

import { FormattedMessage } from 'react-intl';

export default {
  AF: (
    <FormattedMessage
      id="Country.afghanistan"
      description="Afghanistan"
      defaultMessage="Afghanistan"
    />
  ),
  AX: (
    <FormattedMessage
      id="Country.aland_islands"
      description="Aland Islands"
      defaultMessage="Aland Islands"
    />
  ),
  AL: (
    <FormattedMessage
      id="Country.albania"
      description="Albania"
      defaultMessage="Albania"
    />
  ),
  DZ: (
    <FormattedMessage
      id="Country.algeria"
      description="Algeria"
      defaultMessage="Algeria"
    />
  ),
  AS: (
    <FormattedMessage
      id="Country.american_samoa"
      description="American Samoa"
      defaultMessage="American Samoa"
    />
  ),
  AD: (
    <FormattedMessage
      id="Country.andorra"
      description="Andorra"
      defaultMessage="Andorra"
    />
  ),
  AO: (
    <FormattedMessage
      id="Country.angola"
      description="Angola"
      defaultMessage="Angola"
    />
  ),
  AI: (
    <FormattedMessage
      id="Country.anguilla"
      description="Anguilla"
      defaultMessage="Anguilla"
    />
  ),
  AQ: (
    <FormattedMessage
      id="Country.antarctica"
      description="Antarctica"
      defaultMessage="Antarctica"
    />
  ),
  AR: (
    <FormattedMessage
      id="Country.argentina"
      description="Argentina"
      defaultMessage="Argentina"
    />
  ),
  AM: (
    <FormattedMessage
      id="Country.armenia"
      description="Armenia"
      defaultMessage="Armenia"
    />
  ),
  AG: (
    <FormattedMessage
      id="Country.antigua_barbuda"
      description="Antigua and Barbuda"
      defaultMessage="Antigua and Barbuda"
    />
  ),
  AW: (
    <FormattedMessage
      id="Country.aruba"
      description="Aruba"
      defaultMessage="Aruba"
    />
  ),
  AU: (
    <FormattedMessage
      id="Country.australia"
      description="Australia"
      defaultMessage="Australia"
    />
  ),
  AT: (
    <FormattedMessage
      id="Country.austria"
      description="Austria"
      defaultMessage="Austria"
    />
  ),
  AZ: (
    <FormattedMessage
      id="Country.azerbaijan"
      description="Azerbaijan"
      defaultMessage="Azerbaijan"
    />
  ),
  BS: (
    <FormattedMessage
      id="Country.bahamas"
      description="Bahamas"
      defaultMessage="Bahamas"
    />
  ),
  BH: (
    <FormattedMessage
      id="Country.bahrain"
      description="Bahrain"
      defaultMessage="Bahrain"
    />
  ),
  BD: (
    <FormattedMessage
      id="Country.bangladesh"
      description="Bangladesh"
      defaultMessage="Bangladesh"
    />
  ),
  BB: (
    <FormattedMessage
      id="Country.barbados"
      description="Barbados"
      defaultMessage="Barbados"
    />
  ),
  BY: (
    <FormattedMessage
      id="Country.belarus"
      description="Belarus"
      defaultMessage="Belarus"
    />
  ),
  BE: (
    <FormattedMessage
      id="Country.belgium"
      description="Belgium"
      defaultMessage="Belgium"
    />
  ),
  BZ: (
    <FormattedMessage
      id="Country.belize"
      description="Belize"
      defaultMessage="Belize"
    />
  ),
  BJ: (
    <FormattedMessage
      id="Country.benin"
      description="Benin"
      defaultMessage="Benin"
    />
  ),
  BM: (
    <FormattedMessage
      id="Country.bermuda"
      description="Bermuda"
      defaultMessage="Bermuda"
    />
  ),
  BT: (
    <FormattedMessage
      id="Country.bhutan"
      description="Bhutan"
      defaultMessage="Bhutan"
    />
  ),
  BO: (
    <FormattedMessage
      id="Country.bolivia"
      description="Bolivia"
      defaultMessage="Bolivia"
    />
  ),
  BA: (
    <FormattedMessage
      id="Country.bosnia_herzegovina"
      description="Bosnia and Herzegovina"
      defaultMessage="Bosnia and Herzegovina"
    />
  ),
  BW: (
    <FormattedMessage
      id="Country.botswana"
      description="Botswana"
      defaultMessage="Botswana"
    />
  ),
  BV: (
    <FormattedMessage
      id="Country.bouvet_island"
      description="Bouvet Island"
      defaultMessage="Bouvet Island"
    />
  ),
  BR: (
    <FormattedMessage
      id="Country.brazil"
      description="Brazil"
      defaultMessage="Brazil"
    />
  ),
  IO: (
    <FormattedMessage
      id="Country.british_indianocean_territory"
      description="British Indian Ocean Territory"
      defaultMessage="British Indian Ocean Territory"
    />
  ),
  BN: (
    <FormattedMessage
      id="Country.brunei_darussalam"
      description="Brunei Darussalam"
      defaultMessage="Brunei Darussalam"
    />
  ),
  BG: (
    <FormattedMessage
      id="Country.bulgaria"
      description="Bulgaria"
      defaultMessage="Bulgaria"
    />
  ),
  BF: (
    <FormattedMessage
      id="Country.burkina_faso"
      description="Burkina Faso"
      defaultMessage="Burkina Faso"
    />
  ),
  BI: (
    <FormattedMessage
      id="Country.burundi"
      description="Burundi"
      defaultMessage="Burundi"
    />
  ),

  KH: (
    <FormattedMessage
      id="Country.cambodia"
      description="Cambodia"
      defaultMessage="Cambodia"
    />
  ),
  CM: (
    <FormattedMessage
      id="Country.cameroon"
      description="Cameroon"
      defaultMessage="Cameroon"
    />
  ),
  CA: (
    <FormattedMessage
      id="Country.canada"
      description="Canada"
      defaultMessage="Canada"
    />
  ),
  CV: (
    <FormattedMessage
      id="Country.cape_verde"
      description="Cape Verde"
      defaultMessage="Cape Verde"
    />
  ),
  KY: (
    <FormattedMessage
      id="Country.cayman_islands"
      description="Cayman Islands"
      defaultMessage="Cayman Islands"
    />
  ),
  CF: (
    <FormattedMessage
      id="Country.central_african_republic"
      description="Central African Republic"
      defaultMessage="Central African Republic"
    />
  ),
  TD: (
    <FormattedMessage
      id="Country.chad"
      description="Chad"
      defaultMessage="Chad"
    />
  ),
  CL: (
    <FormattedMessage
      id="Country.chile"
      description="Chile"
      defaultMessage="Chile"
    />
  ),
  CN: (
    <FormattedMessage
      id="Country.china"
      description="China"
      defaultMessage="China"
    />
  ),
  CX: (
    <FormattedMessage
      id="Country.christmas_island"
      description="Christmas Island"
      defaultMessage="Christmas Island"
    />
  ),
  CC: (
    <FormattedMessage
      id="Country.cocos_islands"
      description="Cocos (Keeling) Islands"
      defaultMessage="Cocos (Keeling) Islands"
    />
  ),
  CO: (
    <FormattedMessage
      id="Country.colombia"
      description="Colombia"
      defaultMessage="Colombia"
    />
  ),
  KM: (
    <FormattedMessage
      id="Country.comoros"
      description="Comoros"
      defaultMessage="Comoros"
    />
  ),
  CG: (
    <FormattedMessage
      id="Country.congo"
      description="Congo"
      defaultMessage="Congo"
    />
  ),
  CD: (
    <FormattedMessage
      id="Country.congo_democratic_republic"
      description="Congo, The Democratic Republic of the"
      defaultMessage="Congo, The Democratic Republic of the"
    />
  ),
  CK: (
    <FormattedMessage
      id="Country.cook_islands"
      description="Cook Islands"
      defaultMessage="Cook Islands"
    />
  ),
  CR: (
    <FormattedMessage
      id="Country.costa_rica"
      description="Costa Rica"
      defaultMessage="Costa Rica"
    />
  ),
  CI: (
    <FormattedMessage
      id="Country.cote_dIvoire"
      description="Cote D\'Ivoire"
      defaultMessage="Cote D\'Ivoire"
    />
  ),
  HR: (
    <FormattedMessage
      id="Country.croatia"
      description="Croatia"
      defaultMessage="Croatia"
    />
  ),
  CU: (
    <FormattedMessage
      id="Country.cuba"
      description="Cuba"
      defaultMessage="Cuba"
    />
  ),
  CY: (
    <FormattedMessage
      id="Country.cyprus"
      description="Cyprus"
      defaultMessage="Cyprus"
    />
  ),
  CZ: (
    <FormattedMessage
      id="Country.czech_republic"
      description="Czech Republic"
      defaultMessage="Czech Republic"
    />
  ),
  DK: (
    <FormattedMessage
      id="Country.denmark"
      description="Denmark"
      defaultMessage="Denmark"
    />
  ),
  DJ: (
    <FormattedMessage
      id="Country.djibouti"
      description="Djibouti"
      defaultMessage="Djibouti"
    />
  ),
  DM: (
    <FormattedMessage
      id="Country.dominica"
      description="Dominica"
      defaultMessage="Dominica"
    />
  ),
  DO: (
    <FormattedMessage
      id="Country.dominican_republic"
      description="Dominican Republic"
      defaultMessage="Dominican Republic"
    />
  ),
  EC: (
    <FormattedMessage
      id="Country.ecuador"
      description="Ecuador"
      defaultMessage="Ecuador"
    />
  ),
  EG: (
    <FormattedMessage
      id="Country.egypt"
      description="Egypt"
      defaultMessage="Egypt"
    />
  ),
  SV: (
    <FormattedMessage
      id="Country.el_salvador"
      description="El Salvador"
      defaultMessage="El Salvador"
    />
  ),
  GQ: (
    <FormattedMessage
      id="Country.equatorial_guinea"
      description="Equatorial Guinea"
      defaultMessage="Equatorial Guinea"
    />
  ),
  ER: (
    <FormattedMessage
      id="Country.eritrea"
      description="Eritrea"
      defaultMessage="Eritrea"
    />
  ),
  EE: (
    <FormattedMessage
      id="Country.estonia"
      description="Estonia"
      defaultMessage="Estonia"
    />
  ),
  ET: (
    <FormattedMessage
      id="Country.ethiopia"
      description="Ethiopia"
      defaultMessage="Ethiopia"
    />
  ),
  EU: (
    <FormattedMessage
      id="Country.europeanUnion"
      description="European Union"
      defaultMessage="European Union"
    />
  ),
  EN: (
    <FormattedMessage
      id="Country.europeanEconomic"
      description="European Economic Area"
      defaultMessage="European Economic Area"
    />
  ),
  FK: (
    <FormattedMessage
      id="Country.falkland_islands"
      description="Falkland Islands (Malvinas)"
      defaultMessage="Falkland Islands (Malvinas)"
    />
  ),
  FO: (
    <FormattedMessage
      id="Country.faroe_islands"
      description="Faroe Islands"
      defaultMessage="Faroe Islands"
    />
  ),
  FJ: (
    <FormattedMessage
      id="Country.fiji"
      description="Fiji"
      defaultMessage="Fiji"
    />
  ),
  FI: (
    <FormattedMessage
      id="Country.finland"
      description="Finland"
      defaultMessage="Finland"
    />
  ),
  FR: (
    <FormattedMessage
      id="Country.france"
      description="France"
      defaultMessage="France"
    />
  ),
  GF: (
    <FormattedMessage
      id="Country.french_guiana"
      description="French Guiana"
      defaultMessage="French Guiana"
    />
  ),

  PF: (
    <FormattedMessage
      id="Country.french_polynesia"
      description="French Polynesia"
      defaultMessage="French Polynesia"
    />
  ),
  TF: (
    <FormattedMessage
      id="Country.french_south_territories"
      description="French Southern Territories"
      defaultMessage="French Southern Territories"
    />
  ),
  GA: (
    <FormattedMessage
      id="Country.gabon"
      description="Gabon"
      defaultMessage="Gabon"
    />
  ),
  GM: (
    <FormattedMessage
      id="Country.gambia"
      description="Gambia"
      defaultMessage="Gambia"
    />
  ),
  GE: (
    <FormattedMessage
      id="Country.georgia"
      description="Georgia"
      defaultMessage="Georgia"
    />
  ),
  DE: (
    <FormattedMessage
      id="Country.germany"
      description="Germany"
      defaultMessage="Germany"
    />
  ),
  GH: (
    <FormattedMessage
      id="Country.ghana"
      description="Ghana"
      defaultMessage="Ghana"
    />
  ),
  GI: (
    <FormattedMessage
      id="Country.gibraltar"
      description="Gibraltar"
      defaultMessage="Gibraltar"
    />
  ),
  GR: (
    <FormattedMessage
      id="Country.greece"
      description="Greece"
      defaultMessage="Greece"
    />
  ),
  GL: (
    <FormattedMessage
      id="Country.greenland"
      description="Greenland"
      defaultMessage="Greenland"
    />
  ),
  GD: (
    <FormattedMessage
      id="Country.grenada"
      description="Grenada"
      defaultMessage="Grenada"
    />
  ),
  GP: (
    <FormattedMessage
      id="Country.guadeloupe"
      description="Guadeloupe"
      defaultMessage="Guadeloupe"
    />
  ),
  GU: (
    <FormattedMessage
      id="Country.guam"
      description="Guam"
      defaultMessage="Guam"
    />
  ),
  GT: (
    <FormattedMessage
      id="Country.guatemala"
      description="Guatemala"
      defaultMessage="Guatemala"
    />
  ),
  GG: (
    <FormattedMessage
      id="Country.guernsey"
      description="Guernsey"
      defaultMessage="Guernsey"
    />
  ),
  GN: (
    <FormattedMessage
      id="Country.guinea"
      description="Guinea"
      defaultMessage="Guinea"
    />
  ),
  GW: (
    <FormattedMessage
      id="Country.guinea_bissau"
      description="Guinea-Bissau"
      defaultMessage="Guinea-Bissau"
    />
  ),
  GY: (
    <FormattedMessage
      id="Country.guyana"
      description="Guyana"
      defaultMessage="Guyana"
    />
  ),
  HT: (
    <FormattedMessage
      id="Country.haiti"
      description="Haiti"
      defaultMessage="Haiti"
    />
  ),
  HM: (
    <FormattedMessage
      id="Country.heard_mcdonald_islands"
      description="Heard Island and Mcdonald Islands"
      defaultMessage="Heard Island and Mcdonald Islands"
    />
  ),
  VA: (
    <FormattedMessage
      id="Country.vatican_city"
      description="Holy See (Vatican City State)"
      defaultMessage="Holy See (Vatican City State)"
    />
  ),
  HN: (
    <FormattedMessage
      id="Country.honduras"
      description="Honduras"
      defaultMessage="Honduras"
    />
  ),
  HK: (
    <FormattedMessage
      id="Country.hong_kong"
      description="Hong Kong"
      defaultMessage="Hong Kong"
    />
  ),
  HU: (
    <FormattedMessage
      id="Country.hungary"
      description="Hungary"
      defaultMessage="Hungary"
    />
  ),
  IS: (
    <FormattedMessage
      id="Country.iceland"
      description="Iceland"
      defaultMessage="Iceland"
    />
  ),
  IN: (
    <FormattedMessage
      id="Country.india"
      description="India"
      defaultMessage="India"
    />
  ),
  ID: (
    <FormattedMessage
      id="Country.indonesia"
      description="Indonesia"
      defaultMessage="Indonesia"
    />
  ),
  IR: (
    <FormattedMessage
      id="Country.iran"
      description="Iran, Islamic Republic Of"
      defaultMessage="Iran, Islamic Republic Of"
    />
  ),
  IQ: (
    <FormattedMessage
      id="Country.iraq"
      description="Iraq"
      defaultMessage="Iraq"
    />
  ),
  IE: (
    <FormattedMessage
      id="Country.ireland"
      description="Ireland"
      defaultMessage="Ireland"
    />
  ),
  IM: (
    <FormattedMessage
      id="Country.isle_of_man"
      description="Isle of Man"
      defaultMessage="Isle of Man"
    />
  ),
  IL: (
    <FormattedMessage
      id="Country.israel"
      description="Israel"
      defaultMessage="Israel"
    />
  ),
  IT: (
    <FormattedMessage
      id="Country.italy"
      description="Italy"
      defaultMessage="Italy"
    />
  ),
  JM: (
    <FormattedMessage
      id="Country.jamaica"
      description="Jamaica"
      defaultMessage="Jamaica"
    />
  ),
  JP: (
    <FormattedMessage
      id="Country.japan"
      description="Japan"
      defaultMessage="Japan"
    />
  ),
  JE: (
    <FormattedMessage
      id="Country.jersey"
      description="Jersey"
      defaultMessage="Jersey"
    />
  ),
  JO: (
    <FormattedMessage
      id="Country.jordan"
      description="Jordan"
      defaultMessage="Jordan"
    />
  ),
  KZ: (
    <FormattedMessage
      id="Country.kazakhstan"
      description="Kazakhstan"
      defaultMessage="Kazakhstan"
    />
  ),
  KE: (
    <FormattedMessage
      id="Country.kenya"
      description="Kenya"
      defaultMessage="Kenya"
    />
  ),
  KI: (
    <FormattedMessage
      id="Country.kiribati"
      description="Kiribati"
      defaultMessage="Kiribati"
    />
  ),
  KP: (
    <FormattedMessage
      id="Country.democratic_korea"
      description="Democratic People\'s Republic of Korea"
      defaultMessage="Democratic People\'s Republic of Korea"
    />
  ),
  KR: (
    <FormattedMessage
      id="Country.korea_republic"
      description="Korea, Republic of"
      defaultMessage="Korea, Republic of"
    />
  ),
  XK: (
    <FormattedMessage
      id="Country.kosovo"
      description="Kosovo"
      defaultMessage="Kosovo"
    />
  ),
  KW: (
    <FormattedMessage
      id="Country.kuwait"
      description="Kuwait"
      defaultMessage="Kuwait"
    />
  ),
  KG: (
    <FormattedMessage
      id="Country.kyrgyzstan"
      description="Kyrgyzstan"
      defaultMessage="Kyrgyzstan"
    />
  ),
  LA: (
    <FormattedMessage
      id="Country.lao"
      description="Lao People\'s Democratic Republic"
      defaultMessage="Lao People\'s Democratic Republic"
    />
  ),
  LV: (
    <FormattedMessage
      id="Country.latvia"
      description="Latvia"
      defaultMessage="Latvia"
    />
  ),
  LB: (
    <FormattedMessage
      id="Country.lebanon"
      description="Lebanon"
      defaultMessage="Lebanon"
    />
  ),
  LS: (
    <FormattedMessage
      id="Country.lesotho"
      description="Lesotho"
      defaultMessage="Lesotho"
    />
  ),
  LR: (
    <FormattedMessage
      id="Country.liberia"
      description="Liberia"
      defaultMessage="Liberia"
    />
  ),
  LY: (
    <FormattedMessage
      id="Country.libyan_arab_jamahiriya"
      description="Libyan Arab Jamahiriya"
      defaultMessage="Libyan Arab Jamahiriya"
    />
  ),
  LI: (
    <FormattedMessage
      id="Country.liechtenstein"
      description="Liech  nstein"
      defaultMessage="Liechtenstein"
    />
  ),
  LT: (
    <FormattedMessage
      id="Country.lithuania"
      description="Lithuania"
      defaultMessage="Lithuania"
    />
  ),
  LU: (
    <FormattedMessage
      id="Country.luxembourg"
      description="Luxembourg"
      defaultMessage="Luxembourg"
    />
  ),
  MO: (
    <FormattedMessage
      id="Country.macao"
      description="Macao"
      defaultMessage="Macao"
    />
  ),
  MK: (
    <FormattedMessage
      id="Country.macedonia"
      description="Macedonia, The Former Yugoslav Republic of"
      defaultMessage="Macedonia, The Former Yugoslav Republic of"
    />
  ),
  MG: (
    <FormattedMessage
      id="Country.madagascar"
      description="Madagascar"
      defaultMessage="Madagascar"
    />
  ),
  MW: (
    <FormattedMessage
      id="Country.malawi"
      description="Malawi"
      defaultMessage="Malawi"
    />
  ),
  MY: (
    <FormattedMessage
      id="Country.malaysia"
      description="Malaysia"
      defaultMessage="Malaysia"
    />
  ),
  MV: (
    <FormattedMessage
      id="Country.maldives"
      description="Maldives"
      defaultMessage="Maldives"
    />
  ),
  ML: (
    <FormattedMessage
      id="Country.mali"
      description="Mali"
      defaultMessage="Mali"
    />
  ),
  MT: (
    <FormattedMessage
      id="Country.malta"
      description="Malta"
      defaultMessage="Malta"
    />
  ),
  MH: (
    <FormattedMessage
      id="Country.marshall_islands"
      description="Marshall Islands"
      defaultMessage="Marshall Islands"
    />
  ),
  MQ: (
    <FormattedMessage
      id="Country.martinique"
      description="Martinique"
      defaultMessage="Martinique"
    />
  ),
  MR: (
    <FormattedMessage
      id="Country.mauritania"
      description="Mauritania"
      defaultMessage="Mauritania"
    />
  ),
  MU: (
    <FormattedMessage
      id="Country.mauritius"
      description="Mauritius"
      defaultMessage="Mauritius"
    />
  ),
  YT: (
    <FormattedMessage
      id="Country.mayotte"
      description="Mayotte"
      defaultMessage="Mayotte"
    />
  ),
  MX: (
    <FormattedMessage
      id="Country.mexico"
      description="Mexico"
      defaultMessage="Mexico"
    />
  ),
  FM: (
    <FormattedMessage
      id="Country.micronesia"
      description="Micronesia, Federated States of"
      defaultMessage="Micronesia, Federated States of"
    />
  ),
  MD: (
    <FormattedMessage
      id="Country.moldova"
      description="Moldova, Republic of"
      defaultMessage="Moldova, Republic of"
    />
  ),
  MC: (
    <FormattedMessage
      id="Country.monaco"
      description="Monaco"
      defaultMessage="Monaco"
    />
  ),
  MN: (
    <FormattedMessage
      id="Country.mongolia"
      description="Mongolia"
      defaultMessage="Mongolia"
    />
  ),
  ME: (
    <FormattedMessage
      id="Country.montenegro"
      description="Montenegro"
      defaultMessage="Montenegro"
    />
  ),
  MS: (
    <FormattedMessage
      id="Country.montserrat"
      description="Montserrat"
      defaultMessage="Montserrat"
    />
  ),
  MA: (
    <FormattedMessage
      id="Country.morocco"
      description="Morocco"
      defaultMessage="Morocco"
    />
  ),
  MZ: (
    <FormattedMessage
      id="Country.mozambique"
      description="Mozambique"
      defaultMessage="Mozambique"
    />
  ),
  MM: (
    <FormattedMessage
      id="Country.myanmar"
      description="Myanmar"
      defaultMessage="Myanmar"
    />
  ),
  NA: (
    <FormattedMessage
      id="Country.namibia"
      description="Namibia"
      defaultMessage="Namibia"
    />
  ),
  NR: (
    <FormattedMessage
      id="Country.nauru"
      description="Nauru"
      defaultMessage="Nauru"
    />
  ),
  NP: (
    <FormattedMessage
      id="Country.nepal"
      description="Nepal"
      defaultMessage="Nepal"
    />
  ),
  NL: (
    <FormattedMessage
      id="Country.netherlands"
      description="Netherlands"
      defaultMessage="Netherlands"
    />
  ),
  AN: (
    <FormattedMessage
      id="Country.netherlands_antilles"
      description="Netherlands Antilles"
      defaultMessage="Netherlands Antilles"
    />
  ),
  NC: (
    <FormattedMessage
      id="Country.new_caledonia"
      description="New Caledonia"
      defaultMessage="New Caledonia"
    />
  ),
  NZ: (
    <FormattedMessage
      id="Country.new_zealand"
      description="New Zealand"
      defaultMessage="New Zealand"
    />
  ),
  NI: (
    <FormattedMessage
      id="Country.nicaragua"
      description="Nicaragua"
      defaultMessage="Nicaragua"
    />
  ),
  NE: (
    <FormattedMessage
      id="Country.niger"
      description="Niger"
      defaultMessage="Niger"
    />
  ),
  NG: (
    <FormattedMessage
      id="Country.nigeria"
      description="Nigeria"
      defaultMessage="Nigeria"
    />
  ),
  NU: (
    <FormattedMessage
      id="Country.niue"
      description="Niue"
      defaultMessage="Niue"
    />
  ),
  NF: (
    <FormattedMessage
      id="Country.norfolk_island"
      description="Norfolk Island"
      defaultMessage="Norfolk Island"
    />
  ),
  MP: (
    <FormattedMessage
      id="Country.mariana_islands"
      description="Northern Mariana Islands"
      defaultMessage="Northern Mariana Islands"
    />
  ),
  NO: (
    <FormattedMessage
      id="Country.norway"
      description="Norway"
      defaultMessage="Norway"
    />
  ),
  OE: (
    <FormattedMessage
      id="Country.outsideEuropean"
      description="Outside European Economic Area"
      defaultMessage="Outside European Economic Area"
    />
  ),
  OM: (
    <FormattedMessage
      id="Country.oman"
      description="Oman"
      defaultMessage="Oman"
    />
  ),
  PK: (
    <FormattedMessage
      id="Country.pakistan"
      description="Pakistan"
      defaultMessage="Pakistan"
    />
  ),
  PW: (
    <FormattedMessage
      id="Country.palau"
      description="Palau"
      defaultMessage="Palau"
    />
  ),
  PS: (
    <FormattedMessage
      id="Country.palestinian_territory"
      description="Palestinian Territory, Occupied"
      defaultMessage="Palestinian Territory, Occupied"
    />
  ),
  PA: (
    <FormattedMessage
      id="Country.panama"
      description="Panama"
      defaultMessage="Panama"
    />
  ),
  PG: (
    <FormattedMessage
      id="Country.papua_new_guinea"
      description="Papua New Guinea"
      defaultMessage="Papua New Guinea"
    />
  ),
  PY: (
    <FormattedMessage
      id="Country.paraguay"
      description="Paraguay"
      defaultMessage="Paraguay"
    />
  ),
  PE: (
    <FormattedMessage
      id="Country.peru"
      description="Peru"
      defaultMessage="Peru"
    />
  ),
  PH: (
    <FormattedMessage
      id="Country.philippines"
      description="Philippines"
      defaultMessage="Philippines"
    />
  ),
  PN: (
    <FormattedMessage
      id="Country.pitcairn"
      description="Pitcairn"
      defaultMessage="Pitcairn"
    />
  ),
  PL: (
    <FormattedMessage
      id="Country.poland"
      description="Poland"
      defaultMessage="Poland"
    />
  ),
  PT: (
    <FormattedMessage
      id="Country.portugal"
      description="Portugal"
      defaultMessage="Portugal"
    />
  ),
  PR: (
    <FormattedMessage
      id="Country.puerto_rico"
      description="Puerto Rico"
      defaultMessage="Puerto Rico"
    />
  ),
  QA: (
    <FormattedMessage
      id="Country.qatar"
      description="Qatar"
      defaultMessage="Qatar"
    />
  ),
  RE: (
    <FormattedMessage
      id="Country.reunion"
      description="Reunion"
      defaultMessage="Reunion"
    />
  ),
  RO: (
    <FormattedMessage
      id="Country.romania"
      description="Romania"
      defaultMessage="Romania"
    />
  ),
  RU: (
    <FormattedMessage
      id="Country.russian_federation"
      description="Russian Federation"
      defaultMessage="Russian Federation"
    />
  ),
  RW: (
    <FormattedMessage
      id="Country.rwanda"
      description="Rwanda"
      defaultMessage="Rwanda"
    />
  ),
  SH: (
    <FormattedMessage
      id="Country.st_helena"
      description="Saint Helena"
      defaultMessage="Saint Helena"
    />
  ),
  KN: (
    <FormattedMessage
      id="Country.st_kitts_nevis"
      description="Saint Kitts and Nevis"
      defaultMessage="Saint Kitts and Nevis"
    />
  ),
  LC: (
    <FormattedMessage
      id="Country.st_lucia"
      description="Saint Lucia"
      defaultMessage="Saint Lucia"
    />
  ),
  PM: (
    <FormattedMessage
      id="Country.st_pierre_miquelon"
      description="Saint Pierre and Miquelon"
      defaultMessage="Saint Pierre and Miquelon"
    />
  ),
  VC: (
    <FormattedMessage
      id="Country.st_vincent_grenadines"
      description="Saint Vincent and the Grenadines"
      defaultMessage="Saint Vincent and the Grenadines"
    />
  ),
  WS: (
    <FormattedMessage
      id="Country.samoa"
      description="Samoa"
      defaultMessage="Samoa"
    />
  ),
  SM: (
    <FormattedMessage
      id="Country.san_marino"
      description="San Marino"
      defaultMessage="San Marino"
    />
  ),
  ST: (
    <FormattedMessage
      id="Country.sao_tome_principe"
      description="Sao Tome and Principe"
      defaultMessage="Sao Tome and Principe"
    />
  ),
  SA: (
    <FormattedMessage
      id="Country.saudi_arabia"
      description="Saudi Arabia"
      defaultMessage="Saudi Arabia"
    />
  ),
  SN: (
    <FormattedMessage
      id="Country.senegal"
      description="Senegal"
      defaultMessage="Senegal"
    />
  ),
  RS: (
    <FormattedMessage
      id="Country.serbia"
      description="Serbia"
      defaultMessage="Serbia"
    />
  ),
  SC: (
    <FormattedMessage
      id="Country.seychelles"
      description="Seychelles"
      defaultMessage="Seychelles"
    />
  ),
  SL: (
    <FormattedMessage
      id="Country.sierra_leone"
      description="Sierra Leone"
      defaultMessage="Sierra Leone"
    />
  ),
  SG: (
    <FormattedMessage
      id="Country.singapore"
      description="Singapore"
      defaultMessage="Singapore"
    />
  ),
  SK: (
    <FormattedMessage
      id="Country.slovakia"
      description="Slovakia"
      defaultMessage="Slovakia"
    />
  ),
  SI: (
    <FormattedMessage
      id="Country.slovenia"
      description="Slovenia"
      defaultMessage="Slovenia"
    />
  ),
  SB: (
    <FormattedMessage
      id="Country.solomon_islands"
      description="Solomon Islands"
      defaultMessage="Solomon Islands"
    />
  ),
  SO: (
    <FormattedMessage
      id="Country.somalia"
      description="Somalia"
      defaultMessage="Somalia"
    />
  ),
  ZA: (
    <FormattedMessage
      id="Country.south_africa"
      description="South Africa"
      defaultMessage="South Africa"
    />
  ),
  GS: (
    <FormattedMessage
      id="Country.georgia_sandwich_islands"
      description="South Georgia and the South Sandwich Islands"
      defaultMessage="South Georgia and the South Sandwich Islands"
    />
  ),
  ES: (
    <FormattedMessage
      id="Country.spain"
      description="Spain"
      defaultMessage="Spain"
    />
  ),
  LK: (
    <FormattedMessage
      id="Country.srilanka"
      description="Sri Lanka"
      defaultMessage="Sri Lanka"
    />
  ),
  SD: (
    <FormattedMessage
      id="Country.sudan"
      description="Sudan"
      defaultMessage="Sudan"
    />
  ),
  SR: (
    <FormattedMessage
      id="Country.suritext"
      description="Suritext"
      defaultMessage="Suritext"
    />
  ),
  SJ: (
    <FormattedMessage
      id="Country.svalbard_jan_mayen"
      description="Svalbard and Jan Mayen"
      defaultMessage="Svalbard and Jan Mayen"
    />
  ),
  SZ: (
    <FormattedMessage
      id="Country.swaziland"
      description="Swaziland"
      defaultMessage="Swaziland"
    />
  ),
  SE: (
    <FormattedMessage
      id="Country.sweden"
      description="Sweden"
      defaultMessage="Sweden"
    />
  ),
  CH: (
    <FormattedMessage
      id="Country.switzerland"
      description="Switzerland"
      defaultMessage="Switzerland"
    />
  ),
  SY: (
    <FormattedMessage
      id="Country.syria"
      description="Syrian Arab Republic"
      defaultMessage="Syrian Arab Republic"
    />
  ),
  TW: (
    <FormattedMessage
      id="Country.taiwan"
      description="Taiwan"
      defaultMessage="Taiwan"
    />
  ),
  TJ: (
    <FormattedMessage
      id="Country.tajikistan"
      description="Tajikistan"
      defaultMessage="Tajikistan"
    />
  ),
  TZ: (
    <FormattedMessage
      id="Country.tanzania"
      description="Tanzania, United Republic of"
      defaultMessage="Tanzania, United Republic of"
    />
  ),
  TH: (
    <FormattedMessage
      id="Country.thailand"
      description="Thailand"
      defaultMessage="Thailand"
    />
  ),
  TL: (
    <FormattedMessage
      id="Country.timorleste"
      description="Timor-Leste"
      defaultMessage="Timor-Leste"
    />
  ),
  TG: (
    <FormattedMessage
      id="Country.togo"
      description="Togo"
      defaultMessage="Togo"
    />
  ),
  TK: (
    <FormattedMessage
      id="Country.tokelau"
      description="Tokelau"
      defaultMessage="Tokelau"
    />
  ),
  TO: (
    <FormattedMessage
      id="Country.tonga"
      description="Tonga"
      defaultMessage="Tonga"
    />
  ),
  TT: (
    <FormattedMessage
      id="Country.trinidad_tobago"
      description="Trinidad and Tobago"
      defaultMessage="Trinidad and Tobago"
    />
  ),
  TN: (
    <FormattedMessage
      id="Country.tunisia"
      description="Tunisia"
      defaultMessage="Tunisia"
    />
  ),
  TR: (
    <FormattedMessage
      id="Country.turkey"
      description="Turkey"
      defaultMessage="Turkey"
    />
  ),
  TM: (
    <FormattedMessage
      id="Country.turkmenistan"
      description="Turkmenistan"
      defaultMessage="Turkmenistan"
    />
  ),
  TC: (
    <FormattedMessage
      id="Country.turks_caicos_islands"
      description="Turks and Caicos Islands"
      defaultMessage="Turks and Caicos Islands"
    />
  ),
  TV: (
    <FormattedMessage
      id="Country.tuvalu"
      description="Tuvalu"
      defaultMessage="Tuvalu"
    />
  ),
  UG: (
    <FormattedMessage
      id="Country.uganda"
      description="Uganda"
      defaultMessage="Uganda"
    />
  ),
  UA: (
    <FormattedMessage
      id="Country.ukraine"
      description="Ukraine"
      defaultMessage="Ukraine"
    />
  ),
  AE: (
    <FormattedMessage
      id="Country.uae"
      description="United Arab Emirates"
      defaultMessage="United Arab Emirates"
    />
  ),
  GB: (
    <FormattedMessage
      id="Country.uk"
      description="United Kingdom"
      defaultMessage="United Kingdom"
    />
  ),
  US: (
    <FormattedMessage
      id="Country.us"
      description="United States"
      defaultMessage="United States"
    />
  ),
  UM: (
    <FormattedMessage
      id="Country.us_minor_islands"
      description="United States Minor Outlying Islands"
      defaultMessage="United States Minor Outlying Islands"
    />
  ),
  UY: (
    <FormattedMessage
      id="Country.uruguay"
      description="Uruguay"
      defaultMessage="Uruguay"
    />
  ),
  UZ: (
    <FormattedMessage
      id="Country.uzbekistan"
      description="Uzbekistan"
      defaultMessage="Uzbekistan"
    />
  ),
  VU: (
    <FormattedMessage
      id="Country.vanuatu"
      description="Vanuatu"
      defaultMessage="Vanuatu"
    />
  ),
  VE: (
    <FormattedMessage
      id="Country.venezuela"
      description="Venezuela"
      defaultMessage="Venezuela"
    />
  ),
  VN: (
    <FormattedMessage
      id="Country.vietnam"
      description="Viet Nam"
      defaultMessage="Viet Nam"
    />
  ),
  VG: (
    <FormattedMessage
      id="Country.virgin_islands_british"
      description="Virgin Islands, British"
      defaultMessage="Virgin Islands, British"
    />
  ),
  VI: (
    <FormattedMessage
      id="Country.virgin_islands_us"
      description="Virgin Islands, U.S."
      defaultMessage="Virgin Islands, U.S."
    />
  ),
  WF: (
    <FormattedMessage
      id="Country.wallis_futuna"
      description="Wallis and Futuna"
      defaultMessage="Wallis and Futuna"
    />
  ),
  EH: (
    <FormattedMessage
      id="Country.western_sahara"
      description="Western Sahara"
      defaultMessage="Western Sahara"
    />
  ),
  YE: (
    <FormattedMessage
      id="Country.yemen"
      description="Yemen"
      defaultMessage="Yemen"
    />
  ),
  ZM: (
    <FormattedMessage
      id="Country.zambia"
      description="Zambia"
      defaultMessage="Zambia"
    />
  ),
  ZW: (
    <FormattedMessage
      id="Country.zimbabwe"
      description="Zimbabwe"
      defaultMessage="Zimbabwe"
    />
  ),
  GLOBAL: (
    <FormattedMessage
      id="Country.Global"
      description="Country outside of the EEA"
      defaultMessage="Global"
    />
  ),
  CW: (
    <FormattedMessage
      id="Country.curaçao"
      description="Curaçao"
      defaultMessage="Curaçao"
    />
  ),
  SX: (
    <FormattedMessage
      id="Country.sint_maarten"
      description="Sint Maarten"
      defaultMessage="Sint Maarten"
    />
  )
};
