const renderSortArrow = (sortKey, columnKey, sortOrder) => {
  const sortDescVal = sortOrder === 'ASC' ? '↓' : '↑';
  return sortOrder === '' || sortKey !== columnKey ? '' : sortDescVal;
};

const renderFilterIcon = (filterColumn, columnKey, filteredOn) =>
  ((filterColumn === columnKey && filteredOn &&
      filteredOn[columnKey] && filteredOn[columnKey].length > 0) ? '*' : '');

export { renderSortArrow, renderFilterIcon };
