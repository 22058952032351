import {
  recordTranslations,
  commonTranslations,
  typeTranslations
} from '@packages/utils/commontranslations';
import {
  pluralTerms as uppercasePlural,
  singularTerms
} from '@packages/utils/uppercaseTranslations';
import { sectionHeaderTranslations } from '@packages/features/environment/masterDataTranslations';

import { chapterTranslations } from '../privacy-record-detail/recordTranslations';

const filterMenu = [
  {
    label: uppercasePlural('organisations'),
    key: 'organisations',
    enable: true,
    defaultFilter: 'organisations_organisations'
  },
  {
    label: recordTranslations.environmentOrganisation,
    key: 'userOrg',
    enable: true,
    defaultFilter: 'organisations_userOrg',
    subMenu: [
      {
        key: 'organisations_userOrg',
        label: uppercasePlural('organisations')
      },
      {
        key: 'users_userOrg',
        label: commonTranslations.users
      }
    ]
  },
  {
    label: recordTranslations.Stakeholders,
    key: 'stakeholder',
    enable: true,
    defaultFilter: 'ppControllers_stakeholder',
    subMenu: [
      {
        key: 'ppControllers_stakeholder',
        label: uppercasePlural('controllers')
      },
      {
        key: 'ppProcessors_stakeholder',
        label: uppercasePlural('processors')
      },
      {
        key: 'ppExecutingEntities_stakeholder',
        label: uppercasePlural('executingEntities')
      },
      {
        key: 'ppDataRecipients_stakeholder',
        label: uppercasePlural('dataRecipients')
      },
      {
        key: 'dataRecipientCategories_stakeholder',
        label: uppercasePlural('dataRecipientCategories')
      }
    ]
  },
  {
    label: recordTranslations.processedData,
    key: 'processedData',
    enable: true,
    defaultFilter: 'processingCategories_processedData',
    subMenu: [
      {
        key: 'processingCategories_processedData',
        label: recordTranslations.processingCategories
      },
      {
        key: 'references_processedData',
        label: uppercasePlural('references')
      },
      {
        key: 'datasubjectcategories_processedData',
        label: uppercasePlural('dataSubjectCategories')
      },
      {
        key: 'personalDataCategories_processedData',
        label: uppercasePlural('personalDataCategories')
      },
      {
        key: 'personalDataItems_processedData',
        label: uppercasePlural('personalDataItems')
      },
      {
        key: 'dataSourceCategories_processedData',
        label: uppercasePlural('dataSourceCategories')
      },
      {
        key: 'datasources_processedData',
        label: uppercasePlural('dataSources')
      },
      {
        key: 'retentionTermOffsets_processedData',
        label: uppercasePlural('retentionTermOffsets')
      },
      {
        key: 'links_processedData',
        label: uppercasePlural('personalDataLinks')
      }
    ]
  },
  {
    label: recordTranslations.securityControls,
    key: 'securityMeasures',
    defaultFilter: 'technicalSecurityMeasures_securityMeasures',
    enable: true,
    subMenu: [
      {
        key: 'technicalSecurityMeasures_securityMeasures',
        label: sectionHeaderTranslations.technicalSecurityMeasuresHeader
      },
      {
        key: 'organisationalSecurityMeasures_securityMeasures',
        label: sectionHeaderTranslations.organisationalMeasuresHeader
      },
      {
        key: 'specialSecurityMeasures_securityMeasures',
        label: sectionHeaderTranslations.specialSecurityMeasures
      },
      {
        key: 'contractualSecurityMeasures_securityMeasures',
        label: sectionHeaderTranslations.contractualSecurityMeasures
      }
    ]
  },
  {
    label: recordTranslations.legalQualifications,
    key: 'legalqualification',
    defaultFilter: 'purposes_legalqualification',
    enable: true,
    subMenu: [
      {
        key: 'purposes_legalqualification',
        label: sectionHeaderTranslations.purposesAndTransferpurposes
      },
      {
        key: 'fairnessOfDecisions_legalqualification',
        label: singularTerms('fairnessOfDecisions')
      }
    ]
  },
  {
    label: typeTranslations.assessments,
    key: 'assessment',
    enable: true,
    defaultFilter: 'mitigatingMeasures_necessity_assessment',
    subMenu: [
      {
        label: chapterTranslations.NecessityAndProportionality,
        key: 'necessity_assessment',
        enable: true,
        defaultFilter: 'mitigatingMeasures_necessity_assessment',
        subMenu: [
          {
            key: 'mitigatingMeasures_necessity_assessment',
            label: sectionHeaderTranslations.mitigatingMeasures
          },
          {
            key: 'purposes_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.purposeDetails
          },
          {
            key: 'processingGrounds_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.processingGroundDetails
          },
          {
            key: 'personalDataItems_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.dataMinimization
          },
          {
            key: 'retentionTerms_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.storageLimitation
          },
          {
            key: 'transferGrounds_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.transferGroundDetails
          },
          {
            key: 'qualityControls_necessity_assessment',
            label: sectionHeaderTranslations.dataQualityMeasures
          },
          {
            key: 'qualityControls_riskDetails_necessity_assessment',
            label: sectionHeaderTranslations.dataQualityDetails
          }
        ]
      },
      {
        label: recordTranslations.dataSubjectRights,
        key: 'dataSubjectRights_assessment',
        defaultFilter: 'mitigatingMeasures_dataSubjectRights_assessment',
        enable: true,
        subMenu: [
          {
            key: 'mitigatingMeasures_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.mitigatingMeasures
          },
          {
            key: 'informationRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.informationRights
          },
          {
            key: 'informationRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToInfoDetails
          },
          {
            key: 'consentRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.consentRights
          },
          {
            key: 'consentRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToConsentDetails
          },
          {
            key: 'accessRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.accessRights
          },
          {
            key: 'accessRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToAccessDetails
          },
          {
            key: 'objectionRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.objectionRights
          },
          {
            key: 'objectionRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToObjectionDetails
          },
          {
            key: 'deletionRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.deletionRights
          },
          {
            key: 'deletionRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToDeletionDetails
          },
          {
            key: 'dataPortabilityRights_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.dataPortabilityRights
          },
          {
            key: 'dataPortabilityRights_riskDetails_dataSubjectRights_assessment',
            label: sectionHeaderTranslations.rightToDataProtabilityDetails
          }
        ]
      },
      {
        label: recordTranslations.securityControls,
        key: 'securityMeasures_assessment',
        defaultFilter: 'mitigatingMeasures_securityMeasures_assessment',
        enable: true,
        subMenu: [
          {
            key: 'mitigatingMeasures_securityMeasures_assessment',
            label: sectionHeaderTranslations.mitigatingMeasures
          },
          {
            key: 'technicalSecurityMeasures_riskDetails_securityMeasures_assessment',
            label: sectionHeaderTranslations.technicalDetails
          },
          {
            key: 'organisationalSecurityMeasures_riskDetails_securityMeasures_assessment',
            label: sectionHeaderTranslations.organisationalDetails
          },
          {
            key: 'contractualSecurityMeasures_riskDetails_securityMeasures_assessment',
            label: sectionHeaderTranslations.contractualDetails
          }
        ]
      },
      {
        label: recordTranslations.threatImpact,
        key: 'threatsImpacts_assessment',
        defaultFilter: 'mitigatingMeasures_threatsImpacts_assessment',
        enable: true,
        subMenu: [
          {
            key: 'mitigatingMeasures_threatsImpacts_assessment',
            label: sectionHeaderTranslations.mitigatingMeasures
          },
          {
            key: 'confidentialityThreats_threats_threatsImpacts_assessment',
            label: sectionHeaderTranslations.confidentialityThreats
          },
          {
            key: 'confidentialityImpacts_impacts_threatsImpacts_assessment',
            label: sectionHeaderTranslations.confidentialityImpacts
          },
          {
            key: 'integrityThreats_threats_threatsImpacts_assessment',
            label: sectionHeaderTranslations.integrityThreats
          },
          {
            key: 'integrityImpacts_impacts_threatsImpacts_assessment',
            label: sectionHeaderTranslations.integrityImpacts
          },
          {
            key: 'availabilityThreats_threats_threatsImpacts_assessment',
            label: sectionHeaderTranslations.availabilityThreats
          },
          {
            key: 'availabilityImpacts_impacts_threatsImpacts_assessment',
            label: sectionHeaderTranslations.availabilityImpacts
          }
        ]
      },
      {
        label: recordTranslations.accountability,
        key: 'accountability_assessment',
        defaultFilter: 'policies_accountability_assessment',
        enable: true,
        subMenu: [
          {
            key: 'policies_accountability_assessment',
            label: sectionHeaderTranslations.policyHeader
          },
          {
            key: 'dataProtectionMeasures_accountabilitysecuritymeasures_accountability_assessment',
            label: sectionHeaderTranslations.dataProtectionMeasures
          },
          {
            key: 'accountabilityMeasures_accountabilitysecuritymeasures_accountability_assessment',
            label: sectionHeaderTranslations.accountabilityMeasures
          },
          {
            key: 'dataSubjectCategoriesFinding_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.dataSubjectCategoriesFinding
          },
          {
            key: 'dataSubjectCategoriesDecision_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.dataSubjectCategoriesDecision
          },
          {
            key: 'dpoFinding_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.dpoFinding
          },
          {
            key: 'dpoDecision_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.dpoDecision
          },
          {
            key: 'supervisoryAuthoritiesFinding_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.supervisoryAuthoritiesFinding
          },
          {
            key: 'supervisoryAuthoritiesDecision_accountabilities_accountability_assessment',
            label: sectionHeaderTranslations.supervisoryAuthoritiesDecision
          }
        ]
      }
    ]
  },
  {
    label: recordTranslations.documents,
    key: 'documents',
    enable: true,
    defaultFilter: 'document_document-records_documents',
    subMenu: [
      {
        key: 'document-records_documents',
        label: sectionHeaderTranslations.documentRecords
      },
      {
        key: 'documentRecordTypes_documents',
        label: sectionHeaderTranslations.documentRecordTypes
      },
      {
        key: 'specialCharacteristics_documents',
        label: sectionHeaderTranslations.specialCharacteristics
      }
    ]
  }
];

export default filterMenu;
