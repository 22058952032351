import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export default function* searchPartner(action) {
  let { searchParams } = action;

  const { searchKey, searchText } = searchParams;

  searchParams = { ...searchParams, searchText };

  yield put({ type: 'TENANT_PARTNERS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, action.recordType), null, {});
    const items = response.body;
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: item.name
        }));
        yield put({
          type: 'TENANT_PARTNERS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'TENANT_PARTNERS:LIST:SEARCH:FAIL', error: err.message });
  }
}

const getURLWithParams = (searchParams) => {
  let url = `/v1/partners`;
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}?search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
