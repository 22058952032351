/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';
import ProcessorHeirarchy from './processorHierarchy';
import { getDiffContent } from '../../common-utils';

let rootNode = {
  name: 'Processors',
  country: '',
  id: 'root_id',
  children: []
};

const mapStateToProps = (state, props) => {
  const selectedItems =
    props.selectedItems === undefined
      ? []
      : JSON.parse(JSON.stringify(props.selectedItems));
  const processors = transformTreeObject(selectedItems, null);
  if (props.selectedItems && props.selectedItems.id === 'root_id') {
    rootNode = props.selectedItems;
  } else {
    rootNode.children = processors || [];
  }

  rootNode = transformProcessors(rootNode);

  return Object.assign(
    {},
    {
      processors: Object.assign({}, rootNode)
    },
    {
      userPermissions: state.login.get('loggedUser').get('userPermissions'),
      entityDetails: state.legalEntitySelector
        ? state.legalEntitySelector.get('entityDetails')
        : [],
      searchResults: state.processorHierarchy.get('searchResults'),
      isGlobal: state.home?.get('isGlobal')
    }
  );
};

function transformTreeObject(processors, parentDiff) {
  if (processors && processors.length > 0) {
    for (let i = 0; i < processors.length; i += 1) {
      const diffContent = getDiffContent(processors[i]);
      const { item } = diffContent;
      let change = processors[i].change || diffContent.change;
      if (change === '=' && parentDiff) {
        change = parentDiff;
      }
      if (item.value) {
        // eslint-disable-next-line no-param-reassign
        processors[i] = Object.assign(
          {},
          { change },
          item.value,
          processors[i].note && { note: processors[i].note },
          processors[i].processorRole && {
            processorRole: processors[i].processorRole
          },
          {
            decisions: processors[i].decisions,
            findings: processors[i].findings,
            isConsulted: processors[i].isConsulted
          }
        );
        transformTreeObject(processors[i].subProcessors, change);
      }
    }
  }
  return processors;
}

const transformProcessors = (processorData) => {
  const dataString = JSON.stringify(processorData);
  return JSON.parse(dataString.split('"subProcessors":').join('"children":'));
};

const mapDispatchToProps = (dispatch) => ({
  changeProcessors: (data) => {
    dispatch({ type: 'RECORD:PROCESSORS:CHANGE', data });
  },
  fetchDetails: (entityId) => {
    dispatch({ type: 'ORGANISATION_ENTITY:DETAIL:INIT', entityId });
  }
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(ProcessorHeirarchy)
);
