import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class OrganisationUserSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initUsers) {
      this.props.initUsers();
    }
    if (this.props.selectedItems.length > 0) this.props.getSelectedUsers(this.props.selectedItems);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
    if (this.props.createdByUsers !== nextProps.createdByUsers) {
      nextProps.fields.removeAll();
      nextProps.createdByUsers.toJS().forEach(user => nextProps.fields.push(user));
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.organisationUsers === nextProps.organisationUsers) ||
    !(this.state === nextState);
  }


  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render () {
    const { searchResults } = this.state;
    const { hintTextLabel, organisationUsers } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="id"
          searchResults={searchResults}
          legalEntityType="reportOrg"
          dataItems={organisationUsers}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          onScrollStop={this.handleNextData}
          hintTextLabel={hintTextLabel}
          title={
            <FormattedMessage
              id="RecordDetails.permissionsDialogHeader"
              description="Permissions Dialog list header"
              defaultMessage="Select Users"
            />}
          {...this.props}
        />
      </div>
    );
  }
}

OrganisationUserSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initUsers: PropTypes.func,
  organisationUsers: PropTypes.instanceOf(Immutable.List),
  getSelectedUsers: PropTypes.func,
  createdByUsers: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    removeAll: PropTypes.func,
    push: PropTypes.func,
    getAll: PropTypes.func
  })
};

OrganisationUserSelector.defaultProps = {
  hintTextLabel: null,
  initUsers: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  organisationUsers: Immutable.List(),
  getSelectedUsers: e => e,
  createdByUsers: Immutable.List(),
  fields: {
    removeAll: e => e,
    push: e => e,
    getAll: e => e
  }
};

export default OrganisationUserSelector;
