/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import RetentionOffset from './retentionOffset';

const mapStateToProps = (state) => ({
  offsetItem: state.environment.retentionTerms.get('offsetItem'),
  actionError: state.environment.retentionTerms.get('error').toJS(),
  isJobActive: state.environmentReducer.get('isJobActive'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'RETENTION_OFFSET:ITEM:FETCH', id });
  },
  updateRetentionOffsetItem: (data, isEdit, source, isUsed, isEditWithJob) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        data,
        actionType: 'Edit',
        entityType: 'RetentionTermOffset'
      });
    } else {
      dispatch({ type: 'RETENTION_OFFSET:ITEM:UPDATE', data, isEdit, source });
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RetentionOffset);
