import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  items: [],
  error: {
    message: '',
    isError: false
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY_RECORD_TEMPLATES:LIST:INIT':
      return state.set('items', Immutable.List()).set('position', 0);
    case 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'PRIVACY_RECORD_TEMPLATES:LIST:UPDATE':
      return state.set('items', action.items);
    default:
      return state;
  }
};
