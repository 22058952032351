import Immutable from 'immutable';
import filterMenus from './filters';

const defaultState = Immutable.fromJS({
  currentFilter: '',
  filterMenus,
  menuItems: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DSR:FILTERS:INIT': {
      let newState = state;
      const currentFilter = state.get('currentFilter') || '';

      if (!action.currentFilter && currentFilter === '') {
        newState = newState.set('currentFilter', filterMenus[0].subMenu[0].key);
      } else if (currentFilter !== '') {
        newState = newState.set('currentFilter', currentFilter);
      } else {
        newState = newState.set('currentFilter', action.currentFilter);
      }

      return newState.set('menuItems', filterMenus);
    }
    case 'DSR:FILTERS:CHANGE': {
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }

    default:
      return state;
  }
};
