import { connect } from 'react-redux';
import ThirdPartyDataOrganisationsSelector from './component';

const mapStateToProps = state => ({
  items: state.thirdPartyDataOrganisations.get('items'),
  position: state.thirdPartyDataOrganisations.get('position'),
  searchItem: state.thirdPartyDataOrganisations.get('searchItem')
});

const mapDispatchToProps = dispatch => ({
  initOrganisations: () => {
    dispatch({ type: 'THIRD_PARTY_DATA_ORGANISATIONS:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'THIRD_PARTY_DATA_ORGANISATIONS:LIST:REQUEST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'THIRD_PARTY_DATA_ORGANISATIONS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyDataOrganisationsSelector);
