import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  currentFilter: 'documentDetails',
  unSavedData: false,
  prevLocation: '/environment',
  isFetching: false,
  isUpdating: false,
  data: Immutable.Map({}),
  comments: Immutable.List(),
  users: Immutable.List(),
  isUpdateSuccess: false,
  showConfirmDialog: true,
  layout: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DOCUMENT:DETAIL:INIT':
    case 'DOCUMENT:DETAIL:RESET':
      return defaultState;
    case 'DOCUMENT:DETAIL:UPSERT:INIT':
      return state
        .set('isUpdateSuccess', defaultState.get('isUpdateSuccess'))
        .set('isUpdating', true);
    case 'DOCUMENT:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'DOCUMENT:UNSAVED:PROPERTY:SET':
      return state.set('unSavedData', action.value);
    case 'DOCUMENT:ISUPDAING:PROPERTY:SET':
      return state.set('isUpdating', true);
    case 'DOCUMENT:PROPERTY:UPDATE': {
      const modifiedData = {
        ...state.get('data').toJS(),
        [action.property]: action.data
      };
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('data', Immutable.Map(modifiedData))
        .set('unSavedData', true);
    }
    case 'DOCUMENT:DETAIL:UPSERT':
      return state
        .set('isUpdating', true)
        .set('actionError', defaultState.get('actionError'))
        .set('comments', Immutable.List(action.data?.comments));
    case 'DOCUMENT:DETAIL:UPSERT:SUCCESS':
      return state
        .set('data', Immutable.Map(action.data))
        .set('isUpdating', false)
        .set('isUpdateSuccess', true);
    case 'DOCUMENT:DETAIL:UPSERT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false)
        .set('isFetching', false);
    case 'DOCUMENT:SHOW:CONFIRM:DIALOG:RESET': {
      return state.set('showConfirmDialog', false);
    }
    case 'DOCUMENT:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/document/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      }
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isFetching', true)
        .set('prevLocation', prevLocation)
        .set('recordId', action.recordId);
    }
    case 'DOCUMENT:DETAIL:FETCH:SUCCESS':
      return state
        .set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('recordName', action.recordName)
        .set('comments', Immutable.List(action.data?.comments));

    case 'DOCUMENT:DETAIL:FETCH:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false)
        .set('isFetching', false);
    case 'DOCUMENT:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'DOCUMENT:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'DOCUMENT:COMMENT:DELETE:SUCCESS':
    case 'DOCUMENT:COMMENT:SAVE:SUCCESS': {
      return state.set('comments', Immutable.List(action.comments));
    }
    case 'DOCUMENT-RECORDS:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'DOCUMENT-RECORDS:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);
    case 'DOCUMENT:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);
    default:
      return state;
  }
};
