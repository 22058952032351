import { connect } from 'react-redux';
import PersonalItemCategorySelector from './component';

const mapStateToProps = state => ({
  personalItemCategories: state.environment.personalDataCategories.get('items'),
  error: state.personalItemCategories.get('error'),
  position: state.environment.personalDataCategories.get('position'),
  searchResults: state.personalItemCategories.get('items')
});

const mapDispatchToProps = dispatch => ({
  initPersonalItemCategories: () => {
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'PERSONAL_DATA_CATEGORIES:LIST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PERSONAL_ITEM_CATEGORY:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalItemCategorySelector);
