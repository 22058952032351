import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import styles from '@packages/ui/styles';

import MultipleSelectorDialog from '../../../entity-selector/components/multi-selector';

class OrganisationSelector extends React.Component {
  static filterOrganisations(selectedOrganisations, searchedItems) {
    const filteredData = searchedItems.filter((organisation) => {
      const index = selectedOrganisations.findIndex(
        (selectedItem) =>
          organisation.id ===
          (selectedItem.value ? selectedItem.value.id : selectedItem.id)
      );
      return index === -1;
    });
    return filteredData;
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedIndex: -1,
      searchResults: [],
      searchText: ''
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults)
      this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
      !(this.state === nextState) ||
      !(this.props.organisations === nextProps.organisations)
    );
  }

  handleMultipleSelect() {
    this.setState({
      open: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      selectedIndex: -1,
      searchText: '',
      searchResults: []
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedOrganisations = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    if (selectedIndex !== -1) {
      selectedOrganisations.splice(selectedIndex, 1);
    }

    this.updateOrganisations(selectedOrganisations);
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    const selectedOrganisations = [...items, ...selectedItems];
    this.updateOrganisations(selectedOrganisations);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem) {
    const { selectedIndex } = this.state;
    this.handleEditItem(selectedItem, selectedIndex);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex) {
    const modifiedItem = { ...selectedItem };
    const selectedOrganisations = this.props.fields.length
      ? [...this.props.fields.getAll()]
      : [];
    delete modifiedItem.value.note;
    if (selectedIndex === -1) {
      selectedOrganisations.push(modifiedItem.value);
    } else {
      selectedOrganisations.splice(selectedIndex, 1, modifiedItem.value);
    }

    this.updateOrganisations(selectedOrganisations);
    this.setState({
      searchText: '',
      searchResults: []
    });
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    const searchParams = {
      ...searchText,
      searchKey: 'name',
      legalEntityType: this.props.orgType || this.props.fields.name
    };
    this.props.onSearch(searchParams, this.props.isInternal);
  }

  updateOrganisations(selectedOrganisations) {
    this.props.updateData(selectedOrganisations);
    this.setState({
      searchText: ''
    });
  }

  render() {
    const {
      label,
      multiValue,
      hintTextLabel,
      disabled,
      maxCount,
      hideItemList
    } = this.props;
    const selectedOrganisations = this.props.fields.getAll();
    const { searchText, searchResults } = this.state;
    const searchedItems =
      searchText !== '' || searchResults.size > 0
        ? searchResults
        : this.props.organisations;
    const organisationList =
      selectedOrganisations && selectedOrganisations.length > 0
        ? OrganisationSelector.filterOrganisations(
          selectedOrganisations,
          searchedItems
        )
        : searchedItems;

    const itemSelectorStyle =
      selectedOrganisations && selectedOrganisations.length >= maxCount
        ? styles.disabled
        : {};

    return (
      <div>
        {selectedOrganisations &&
          selectedOrganisations.length > 0 &&
          !hideItemList && (
            <ItemList
              id="item-list"
              isEditable={false}
              isNote={false}
              type={label}
              selectedItems={selectedOrganisations}
              handleRemoveItem={this.handleRemoveItem}
              {...this.props}
            />
        )}
        {!disabled && (
          <div style={itemSelectorStyle}>
            <ItemSelector
              id="organisations_selector"
              multiValue={multiValue}
              createNewMenuItem={false}
              selectFromListMenuItem={true}
              filteredData={organisationList}
              dataSource={organisationList}
              dataSourceConfig={{ text: 'key', value: 'key' }}
              hintTextLabel={hintTextLabel}
              handleSelectedItem={this.handleSelectedItem}
              handleMultipleSelect={this.handleMultipleSelect}
              onSearch={this.handleSearch}
              disabled={disabled || selectedOrganisations?.length >= maxCount}
            />
          </div>
        )}
        {this.state.open && (
          <MultipleSelectorDialog
            id="multi-selector"
            title={this.props.dialogHeaderLabel}
            open={this.state.open}
            selectedItems={selectedOrganisations}
            selectedEntitiesSize={selectedOrganisations?.length || 0}
            onRequestClose={this.handleRequestClose}
            handleMultipleItems={this.handleMultipleItems}
            maxCount={this.props.maxCount}
            isInternal={this.props.isInternal}
          />
        )}
      </div>
    );
  }
}

OrganisationSelector.propTypes = {
  multiValue: PropTypes.bool,
  organisations: PropTypes.instanceOf(Immutable.List),
  updateData: PropTypes.func,
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    removeAll: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    name: PropTypes.string,
    length: PropTypes.number
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  hintTextLabel: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  orgType: PropTypes.string,
  maxCount: PropTypes.bool,
  hideItemList: PropTypes.bool,
  isInternal: PropTypes.bool
};

OrganisationSelector.defaultProps = {
  multiValue: true,
  organisations: Immutable.List(),
  updateData: (e) => e,
  label: null,
  hintTextLabel: null,
  dialogHeaderLabel: null,
  onSearch: (e) => e,
  disabled: false,
  searchResults: Immutable.List(),
  orgType: null,
  maxCount: undefined,
  hideItemList: false,
  isInternal: undefined
};
export default OrganisationSelector;
