import { recordTranslations, breachTranslations, commonTranslations } from '@packages/utils/commontranslations';
import { legalGroundTranslations } from '../recordTranslations';

export const filterMenu = [
  {
    label: recordTranslations.general,
    value: 'general',
    disable: false
  },
  {
    label: recordTranslations.Stakeholders,
    value: 'stakeholders',
    disable: false
  },
  {
    label: breachTranslations.scope,
    value: 'breachScope',
    disable: false
  },
  {
    label: breachTranslations.characteristics,
    value: 'breachCharacteristics',
    disable: false
  },
  {
    label: recordTranslations.legalQualifications,
    value: 'legalQualifications',
    disable: false
  },
  {
    label: commonTranslations.measures,
    value: 'measures',
    disable: false
  },
  {
    label: commonTranslations.notifications,
    value: 'notifications',
    disable: false
  },
  {
    label: recordTranslations.attachments,
    value: 'attachments',
    disable: false
  },
  {
    label: legalGroundTranslations.permissions,
    value: 'permissions',
    disable: false
  }
];

