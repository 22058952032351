import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  organisationData: undefined,
  filterParams: [],
  organisationEntities: Immutable.List(),
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  position: 0,
  searchText: Immutable.Map(),
  items: Immutable.List(),
  entityDetails: undefined,
  holdingOrgList: Immutable.List(),
  importItems: Immutable.List(),
  parentFound: true,
  holdingFilterParams: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ORGANISATION_ENTITY:LIST:REQUEST':
      return state.set('organisationEntities', defaultState.get('organisationEntities'))
        .set('actionError', defaultState.get('actionError'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'ORGANISATION_ENTITY:LIST:REQUEST:INIT':
      return state.set('organisationEntities', defaultState.get('organisationEntities'))
        .set('actionError', defaultState.get('actionError'))
        .set('position', 0);
    case 'ORGANISATION_ENTITY:LIST:REQUEST:SUCCESS':
      return state.set('organisationEntities', state.get('organisationEntities').concat(action.items))
        .set('position', state.get('position') + action.items.length)
        .set('organisationData', defaultState.get('organisationData'));

    case 'ORGANISATION_ENTITY:LIST:REQUEST:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));

    case 'ORGANISATION_ENTITY:DETAIL:INIT':
      return state.set('actionError', defaultState.get('actionError'))
        .set('organisationData', defaultState.get('organisationData'));

    case 'ORGANISATION_ENTITY:DETAIL:UPSERT:SUCCESS':
      return state.set('organisationData', action.organisationData);

    case 'ORGANISATION_ENTITY:DETAIL:UPSERT:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));
    case 'ORGANISATION_ENTITY:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'ORGANISATION_ENTITY:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'ORGANISATION_ENTITY:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('actionError', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'ORGANISATION_ENTITY:ITEM:RESET':
    case 'ORGANISATION_ENTITY:ITEM:INIT':
      return state.set('actionError', defaultState.get('actionError'))
        .set('entityDetails', defaultState.get('entityDetails'));
    case 'ORGANISATION_ENTITY:ITEM:FETCH:SUCCESS':
      return state.set('entityDetails', action.items);
    case 'ORGANISATION_ENTITY:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'ORGANISATIONS_ENTITY:LIST:FILTER':
      return state
        .set('organisationEntities', defaultState.get('organisationEntities'))
        .set('position', 0)
        .set('filterParams', action.filterParams);
    case 'ORGANISATIONS_ENTITY:LIST:FILTER:RESET':
      return state.set('filterParams', defaultState.get('filterParams'))
        .set('holdingFilterParams', defaultState.get('holdingFilterParams'));
    case 'SUBTENANT:ORGANISATION:LIST:FETCH:SUCCESS':
      return state.set('holdingOrgList', state.get('holdingOrgList').concat(action.orgList))
        .set('position', state.get('position') + action.orgList.length);
    case 'SUBTENANT:ORGANISATIONS_ENTITY:LIST:FILTER':
      return state.set('holdingOrgList', defaultState.get('holdingOrgList'))
        .set('position', 0)
        .set('holdingFilterParams', action.filterParams);
    case 'SUBTENANT:ORGANISATION:LIST:FETCH:INIT':
      return state.set('holdingOrgList', defaultState.get('holdingOrgList'))
        .set('actionError', defaultState.get('actionError'))
        .set('position', 0).set('importItems', defaultState.get('importItems'));
    case 'SUBTENANT:ORGANISATION_ENTITY:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    case 'SUBTENANT:ORGANISATION_ENTITY:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('importItems', Immutable.List(action.items));
    case 'PARENT:ORGANISATION_ENTITY:ITEM:FETCH':
      return state.set('parentFound', action.parentFound);
    case 'PARENT:ORGANISATION_ENTITY:ITEM:RESET':
      return state.set('parentFound', defaultState.get('parentFound'));
    default:
      return state;
  }
};
