import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchItem: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'THIRD_PARTY_DATA_ORGANISATIONS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('items', state.get('items'))
        .set('searchText', defaultState.get('searchText'))
        .set('searchItem', state.get('searchItem'))
        .set('position', 0);
    case 'THIRD_PARTY_DATA_ORGANISATIONS:LIST:FETCH:SUCCESS': {
      return state.set('items', Immutable.List(action.data));
    }
    case 'THIRD_PARTY_DATA_ORGANISATIONS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'THIRD_PARTY_DATA_ORGANISATIONS_SELECTOR:LIST:SEARCH':
      return state
        .set('searchText', getSearchText(action));
    case 'THIRD_PARTY_DATA_ORGANISATIONS_SELECTOR:LIST:SEARCH:SUCCESS':
      return state
        .set('searchItem', action.data);
    case 'THIRD_PARTY_DATA_ORGANISATIONS_SELECTOR:SEARCH:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
