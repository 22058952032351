import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DivWrapper from '@packages/components/divWrapper';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import { renderRadioGroup, renderEntityList, renderEntityImport } from '@packages/components/form-components';
import styles from '@packages/ui/styles';
import { orgTranslations, commonTranslations } from '@packages/utils/commontranslations';
import countryTranslations from '@packages/utils/countryTranslations';
import { orgTypes } from '@packages/utils/record-utils';
import errortranslations from '@packages/utils/errortranslations';

const orgDetailsStyle = {
  data: {
    display: 'flex',
    marginTop: '10px',
    fontSize: '14px'
  },
  title: {
    fontWeight: '600',
    fontSize: '14px'
  },
  subTitle: {
    fontWeight: '600',
    fontSize: '13px'
  },
  subData: {
    display: 'flex',
    marginLeft: '20px',
    fontSize: '13px',
    marginTop: '5px'
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.entityId) {
    errors.entityId = errortranslations.required;
  }
  return errors;
};
const OrganisationImport = (props) => {
  const [isInternal, setIsInternal] = useState('true');
  const [isLegalEntity, setIsLegalEntity] = useState('true');
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedParent, setSelectedParent] = useState('');
  const [orgDetails, setOrgDetails] = useState(undefined);

  const { handleSubmit, onCancel } = props;
  const FORM_MAXHEIGHT = 395;
  const WINDOW_MIN_INNERHEIGHT = 660;

  useEffect(() => {
    const initalValues = {
      isLegalEntity: 'true',
      isInternal: 'true'
    };
    props.initialize(initalValues);
    return (() => props.resetParent());
  }, []);

  const handleReset = () => {
    setSelectedOrg('');
    setSelectedParent('');
    setOrgDetails('');
    props.change('entityId', '');
    props.change('parentEntityId', '');
    props.resetParent();
  };

  const handleOrgTypeSelect = (evt, selected) => { // checks legalentity or department
    handleReset();
    setIsLegalEntity(selected);
  };

  const handleIsInternal = (evt, selected) => {
    setIsInternal(selected);
  };

  const handleOrgSelect = (item) => {
    setSelectedOrg(item.key);
    setOrgDetails(item);
    if (isLegalEntity === 'false') {
      props.change('parentEntityId', item.parentOrgEntity);
      setSelectedParent(item.isPartOf);
      props.getParentData(item.parentOrgEntity);
    }
    if(!item?.isInternal){
      props.change('isInternal', 'false')
      setIsInternal('false')
    }
  };

  const saveData = (data) => {
    const modifiedData = { ...data,
      entityId: data.entityId.id,
      isInternal: data.isInternal === 'true',
      isLegalEntity: data.isLegalEntity === 'true',
      parentOrgEntity: data.parentEntityId };
    if (data) props.saveData(modifiedData);
    onCancel();
  };

  const showEntityDetails = () => {
    const requiredFields = ['name', 'country', 'contactDetail'];
    const contact = ['address', 'email', 'workPhone'];
    const details = requiredFields.map((item) => {
      if (item !== 'contactDetail') {
        return (
          <div style={orgDetailsStyle.data}>
            <div style={orgDetailsStyle.title}>
              { item === 'name' ? orgTranslations.entityName : commonTranslations[item]}:
            </div>
            <div style={{ marginLeft: '10px' }}>
              { item === 'country' ? countryTranslations[orgDetails[item]] : [orgDetails[item]] }
            </div>
          </div>);
      }
      const contactData = (
        <div style={{ ...orgDetailsStyle.data, flexDirection: 'column' }}>
          <div style={orgDetailsStyle.title}>
            {commonTranslations.contactData}:
          </div>
          <div style={{ marginTop: '10px' }}>{contact.map(data => (
            <div style={orgDetailsStyle.subData}>
              <div style={orgDetailsStyle.subTitle}>{orgTranslations[data]}:</div>
              <div style={{ marginLeft: '10px' }}>{orgDetails[item] && orgDetails[item][data]}</div>
            </div>))}
          </div>
        </div>);
      return contactData;
    });
    return details;
  };

  const disableInternalEntitySelection = orgDetails && !orgDetails.isInternal;

  return (
    <div>
      <form>
        <DivWrapper
          autoHeight={true}
          id="organisationImport"
          autoHeightMax={getDialogContentHeight(WINDOW_MIN_INNERHEIGHT, FORM_MAXHEIGHT)}
        >
          <div>
            <Field
              name="isInternal"
              id="is_internal"
              label={commonTranslations.isInternalOrExternal}
              valueSelected={isInternal}
              row={true}
              onChange={handleIsInternal}
              component={renderRadioGroup}
            >
              <FormControlLabel
                control={<Radio style={styles.checkboxStyle} />}
                style={{ width: '200px' }}
                value="true"
                id="internal_organisation"
                disabled={disableInternalEntitySelection}
                label={commonTranslations.internalCompany}
              />
              <FormControlLabel
                control={<Radio style={styles.checkboxStyle} />}
                style={{ width: '200px' }}
                value="false"
                disabled={disableInternalEntitySelection}
                label={commonTranslations.externalCompany}
              />
            </Field>
          </div>
          <div>
            <Field
              name="isLegalEntity"
              id="orgType_sub"
              label={orgTranslations.entityType}
              valueSelected={isLegalEntity}
              row={true}
              onChange={handleOrgTypeSelect}
              component={renderRadioGroup}
            >
              {orgTypes.map((item => (
                <FormControlLabel
                  control={<Radio style={styles.checkboxStyle} />}
                  style={{ width: '200px' }}
                  value={(item.value === 'legalentity').toString()}
                  disabled={false}
                  label={item.label}
                />
              )))
              }
            </Field>
          </div>
          <div>
            <Field
              name="entityId"
              id="selectEntity"
              label={commonTranslations.selectEntity}
              hintTextLabel={commonTranslations.isPartOfHint}
              component={renderEntityImport}
              multiValue={false}
              selectedKey={selectedOrg}
              createNewMenuItem={true}
              handleAddClick={props.handleAddClick}
              showFilters={false}
              isImportEntity={true}
              isLegalEntity={isLegalEntity}
              onChange={handleOrgSelect}
              isInternal={isInternal}
            />
          </div>

          <div style={{ pointerEvents: isLegalEntity === 'true' ? '' : 'none' }}>
            <Field
              name="parentEntityId"
              label={commonTranslations.entityIsPartOf}
              hintTextLabel={commonTranslations.isPartOfHint}
              isImportParent={true}
              component={renderEntityList}
              disableField={true}
              multiValue={false}
              showFilters={false}
              isInternal={isInternal}
              isLegalEntity={isLegalEntity}
              selectedKey={selectedParent}
            />
          </div>
          {
            !props.parentFound &&
            <div style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}>
              {commonTranslations.parentImportWarning}
            </div>
          }
          {orgDetails &&
            <div style={{ marginTop: '30px' }}>{showEntityDetails()}</div>
            }
        </DivWrapper>
        <div style={{ marginTop: 20 }}>
          <Button
            id="submit_button"
            style={{ minWidth: '150px' }}
            disabled={false}
            type="submit"
            onClick={handleSubmit(organisationEntityData => saveData(organisationEntityData))}
          >
            {commonTranslations.saveAndClose}
          </Button>
          <Button
            id="cancel_button"
            variant="text"
            onClick={onCancel}
          >
            {commonTranslations.Cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};

OrganisationImport.propTypes = {
  handleAddClick: PropTypes.func,
  change: PropTypes.func,
  initialize: PropTypes.func,
  saveData: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  resetParent: PropTypes.func,
  parentFound: PropTypes.bool,
  getParentData: PropTypes.func
};

OrganisationImport.defaultProps = {
  handleAddClick: e => e,
  change: e => e,
  initialize: e => e,
  saveData: e => e,
  handleSubmit: e => e,
  onCancel: e => e,
  resetParent: e => e,
  parentFound: true,
  getParentData: e => e
};

const OrganisationImportWrapper = reduxForm({
  form: 'OrganisationImportForm', // a unique identifier for this form
  validate
})(withTheme(OrganisationImport));
export default injectIntl(OrganisationImportWrapper);
