import Immutable from 'immutable';

import filterMenus from './default';
import { filterMenuItems, getUpdatedCount } from './utils';

const defaultState = Immutable.fromJS({
  currentFilter: '',
  filterMenus,
  menuItems: [],
  currentSubmenu: '',
  count: Immutable.Map({}),
  message: Immutable.Map()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MESSAGE_CENTER:FILTERS:INIT': {
      const { userPermissions } = action;
      const menuItems = filterMenuItems(userPermissions, filterMenus);
      return state
        .set(
          'currentFilter',
          state.get('currentFilter') || menuItems[0].subMenu[0].key
        )
        .set('menuItems', menuItems);
    }
    case 'MESSAGE_CENTER:FILTERS:CHANGE': {
      if (action.filterName) {
        return state.set('currentFilter', action.filterName);
      }
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }
    case 'MESSAGE_CENTER:SUBMENU:CHANGE': {
      return state.set('currentSubmenu', action.currentSubmenu);
    }
    case 'MESSAGES:UNREAD:COUNT:FETCH:INIT':
      return state.set('count', defaultState.get('count'));
    case 'MESSAGES:UNREAD:COUNT:FETCH:SUCCESS': {
      const { count } = action;
      return state.set('count', Immutable.Map(count));
    }
    case 'MESSAGES:UNREAD:COUNT:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'MESSAGES:UNREAD:COUNT:UPDATE:SUCCESS': {
      const { menu, assignee, status, loggedInUser } = action;
      const count = state.get('count');
      const modifiedCount = getUpdatedCount(
        count.toJS(),
        menu,
        assignee,
        status,
        loggedInUser
      );
      return state.set('count', Immutable.Map(modifiedCount));
    }
    case 'MESSAGE:ITEM:FETCH:SUCCESS':
      return state.set('message', action.message);
    case 'MESSAGE:ITEM:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'MESSAGE:ITEM:FETCH:RESET':
      return state.set('message', defaultState.get('message'));

    default:
      return state;
  }
};
