import Immutable from 'immutable';
import { getFilteredOn, getSearchText, getSearchItems, removeDuplicates } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../utils';


const defaultState = Immutable.fromJS({
  isFetching: false,
  items: Immutable.List(),
  newItems: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  recordsFilterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  recordsFilteredOn: Immutable.Map(),
  filterColumn: {},
  searchText: Immutable.Map(),
  pageSearchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  dataRecipientCategoryData: {},
  usage: undefined,
  searchResults: Immutable.Map(),
  bulkItems: Immutable.List(),
  unused: false,
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('dataRecipientCategoryData', defaultState.get('dataRecipientCategoryData'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('items', defaultState.get('items'))
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', defaultState.get('searchText'))
        .set('pageSearchText', state.get('pageSearchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('recordsFilteredOn', defaultState.get('recordsFilteredOn'))
        .set('position', 0);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.dataRecipientCategoryData) {
        newItems = newItems.unshift(action.dataRecipientCategoryData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FILTER': {
      if (action.source === 'records') {
        return state
          .set('items', defaultState.get('items'))
          .set('position', 0)
          .set('recordsFilterParams', Immutable.Map(action.filterParams))
          .set('recordsFilteredOn', getFilteredOn(Object.assign({}, action.filterParams,
            { filterObj: state.get('recordsFilteredOn') })))
          .set('filterParams', Immutable.Map(action.filterParams));
      }
      const { filterParams, appendFilter } = action;
      const newFilterParams = { [filterParams.filterKey]: filterParams.filterKey };
      const filterColumn = appendFilter ?
        Object.assign({}, state.get('filterColumn').toJS(), newFilterParams)
        : newFilterParams;
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: appendFilter ? state.get('filteredOn') : undefined })))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:USAGE:RESET':
      return state
        .set('usage', defaultState.get('usage'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH_FILTER:RESET':
      return state
        .set('searchText', defaultState.get('searchText'))
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('recordsFilteredOn', defaultState.get('recordsFilteredOn'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:PAGE_SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('pageSearchText', getSearchText(action));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', getSearchItems(action));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:PAGE_SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('pageSearchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('pageSearchText').toJS() })))
        .set('position', action.items.length)
        .set('items', Immutable.List(action.items));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT':
      return state.set('dataRecipientCategoryData', defaultState.get('dataRecipientCategoryData'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        // return state
        .set('isFetching', false)
        .set('dataRecipientCategoryData', action.dataRecipientCategoryData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:UPDATE:SUCCESS':
      return state.set('items', action.items)
        .set('newItems', action.newItems)
        .set('position', action.position ? action.position : state.get('position'));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH':
      return state.set('dataRecipientCategoryData', defaultState.get('dataRecipientCategoryData'))
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:SUCCESS':
      return state.set('dataRecipientCategoryData', action.dataRecipientCategoryData);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId, action.unused));
    }
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);
    case 'MASTERDATA:DATARECIPIENTCATEGORIES:VIEW:SIMILAR:SUCCESS':
      return state
        .set('items', Immutable.List(action.items));
    default:
      return state;
  }
};
