import React from 'react';

import { FormattedMessage } from 'react-intl';
import { pluralTerms as smallcasePlurals } from '@packages/utils/smallcaseTranslations';
import vendorTranslations from '@packages/utils/vendorTranslations';
import {
  pluralTerms,
  singularTerms
} from '@packages/utils/uppercaseTranslations';

export const hintTextTranslations = {
  references: (
    <FormattedMessage
      id="Reference.nameHint"
      description="Hint text for reference"
      defaultMessage="Reference..."
    />
  ),
  qualityControls: (
    <FormattedMessage
      id="QualityControls.nameHint"
      description="Hint text for quality control"
      defaultMessage="Quality control..."
    />
  ),
  purposesOfTransfer: (
    <FormattedMessage
      id="PurposesOfTransfer.nameHint"
      description="Hint text for purposes of transfer"
      defaultMessage="Purposes of transfer..."
    />
  ),
  purposes: (
    <FormattedMessage
      id="Purposes.nameHint"
      description="Hint text for Purposes"
      defaultMessage="Purposes..."
    />
  ),
  mitigatingMeasures: (
    <FormattedMessage
      id="MitigationMeasuresDialog.addmitigationMeasure"
      description="Mitigating measure..."
      defaultMessage="Mitigating measure..."
    />
  ),
  riskDetails: (
    <FormattedMessage
      id="Assessment.addRiskDetailHint"
      description="Add details..."
      defaultMessage="Risk detail..."
    />
  ),
  consentRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  informationRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  accessRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  deletionRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  objectionRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  dataPortabilityRights: (
    <FormattedMessage
      id="Assessment.addRightsHint"
      description="Add rights..."
      defaultMessage="Right..."
    />
  ),
  technicalSecurityMeasures: (
    <FormattedMessage
      id="Assessment.addTechnicalMeasureHint"
      description="Add technical security measure..."
      defaultMessage="Technical security measure..."
    />
  ),
  organisationalSecurityMeasures: (
    <FormattedMessage
      id="Assessment.addOrganisationalMeasureHint"
      description="Add Organisational security measure..."
      defaultMessage="Organisational security measure..."
    />
  ),
  securityMeasures: (
    <FormattedMessage
      id="Environment.addSecurityMeasuresHint"
      description="Add security measure......"
      defaultMessage="Security measure......"
    />
  ),
  policies: (
    <FormattedMessage
      id="Environment.policies"
      description="Policies..."
      defaultMessage="Policies..."
    />
  ),
  confidentialityThreats: (
    <FormattedMessage
      id="Environment.hintText.threats"
      description="Threats..."
      defaultMessage="Threats..."
    />
  ),
  integrityThreats: (
    <FormattedMessage
      id="Environment.hintText.threats"
      description="Threats..."
      defaultMessage="Threats..."
    />
  ),
  availabilityThreats: (
    <FormattedMessage
      id="Environment.hintText.threats"
      description="Threats..."
      defaultMessage="Threats..."
    />
  ),
  confidentialityImpacts: (
    <FormattedMessage
      id="Environment.hintText.impacts"
      description="Impacts..."
      defaultMessage="Impacts..."
    />
  ),
  availabilityImpacts: (
    <FormattedMessage
      id="Environment.hintText.impacts"
      description="Impacts..."
      defaultMessage="Impacts..."
    />
  ),
  integrityImpacts: (
    <FormattedMessage
      id="Environment.hintText.impacts"
      description="Impacts..."
      defaultMessage="Impacts..."
    />
  ),
  decisions: (
    <FormattedMessage
      id="Environment.hintText.decisions"
      description="Decisions..."
      defaultMessage="Decisions..."
    />
  ),
  findings: (
    <FormattedMessage
      id="Environment.hintText.findings"
      description="Findings..."
      defaultMessage="Findings..."
    />
  ),
  dataProtectionMeasures: (
    <FormattedMessage
      id="Environment.hintText.measures"
      description="Measures..."
      defaultMessage="Measures..."
    />
  ),
  accountabilityMeasures: (
    <FormattedMessage
      id="Environment.hintText.measures"
      description="Measures..."
      defaultMessage="Measures..."
    />
  ),
  dataRecipientCategories: (
    <FormattedMessage
      id="Environment.hintText.dataRecipientCategories"
      description="Data recipient categories..."
      defaultMessage="Data recipient categories..."
    />
  ),
  requesterId: (
    <FormattedMessage
      id="create.requesterId"
      description="#ID"
      defaultMessage="#ID"
    />
  ),
  firstName: (
    <FormattedMessage
      id="firstName"
      description="First name"
      defaultMessage="First name"
    />
  ),
  lastName: (
    <FormattedMessage
      id="lastName"
      description="Last name"
      defaultMessage="Last name"
    />
  ),
  address: (
    <FormattedMessage
      id="Common.address"
      description="Address"
      defaultMessage="Address"
    />
  ),
  postCode: (
    <FormattedMessage
      id="postCode"
      description="Postcode"
      defaultMessage="Postcode"
    />
  ),
  city: <FormattedMessage id="city" description="City" defaultMessage="City" />,
  phoneNumber: (
    <FormattedMessage
      id="phoneNumber"
      description="Phone number"
      defaultMessage="Phone number"
    />
  ),
  emailAddress: (
    <FormattedMessage
      id="emailAddress"
      description="Email address"
      defaultMessage="Email address"
    />
  ),
  countryName: (
    <FormattedMessage
      id="counrtyName"
      description="Select country"
      defaultMessage="Selectcountry"
    />
  ),
  tags: (
    <FormattedMessage
      id="Document.addTags"
      description="Add tag"
      defaultMessage="Add tag"
    />
  ),
  noTags: (
    <FormattedMessage
      id="Document.noTags"
      description="No tags"
      defaultMessage="No tags"
    />
  )
};

export const addMasterTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'references':
      return (
        <FormattedMessage
          id="ReferenceDialog.addReference"
          description="Add reference in Dialog Header"
          defaultMessage="Add reference"
        />
      );
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="FairnessOfDecisionDialog.addFairnessOfDecision"
          defaultMessage="Add fairness of decision"
        />
      );
    case 'specialSecurityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasures.addSpecialSecurityMeasures"
          defaultMessage="Add special security measure"
        />
      );
    case 'contractualSecurityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasures.addContractualSecurityMeasures"
          defaultMessage="Add contractual security measure"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="AddQualityControlDialog.addheader"
          description="Add quality control"
          defaultMessage="Add quality control"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="AddPurposesOfTransferDialog.addheader"
          description="Add purpose of transfer"
          defaultMessage="Add purpose of transfer"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="AddPurposesDialog.addheader"
          description="Add purpose"
          defaultMessage="Add purpose"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="MitigationMeasuresDialog.addmitigationMeasure"
          description="Add mitigating measure"
          defaultMessage="Add mitigating measure"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="AddRiskDetailDialog.addheader"
          description="Add Risk Detail"
          defaultMessage="Add risk detail"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="AddRightsDetailDialog.addheader"
          description="Add Right"
          defaultMessage="Add right"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="AddThreatsDialog.addheader"
          description="Add threats"
          defaultMessage="Add threats"
        />
      );
    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="AddImpactsDialog.addheader"
          description="Add impacts"
          defaultMessage="Add impacts"
        />
      );
    case 'technicalSecurityMeasures':
      return (
        <FormattedMessage
          id="AddTechnicalSecurityMeasureDialog.addheader"
          description="Add technical security measure"
          defaultMessage="Add technical security measure"
        />
      );
    case 'organisationalSecurityMeasures':
      return (
        <FormattedMessage
          id="AddOrganisationalSecurityMeasureDialog.addheader"
          description="Add organisational security measure"
          defaultMessage="Add organisational security measure"
        />
      );
    case 'securityMeasures':
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="AddAccountabilityMeasureDialog.addheader"
          description="Add accountability measure"
          defaultMessage="Add accountability measure"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="AddDataProtectionMeasuresDialog.addheader"
          description="Add data protection by design and by default measure"
          defaultMessage="Add data protection by design and by default measure"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Environment.addPoliciesHint"
          description="Add policy"
          defaultMessage="Add policy"
        />
      );
    case 'findings':
      return (
        <FormattedMessage
          id="Findings.addHeader"
          description="Add finding"
          defaultMessage="Add finding"
        />
      );
    case 'decisions':
      return (
        <FormattedMessage
          id="Decisions.addHeader"
          description="Add decisions"
          defaultMessage="Add decisions"
        />
      );
    case 'measures':
      return (
        <FormattedMessage
          id="securityMeasure.addheader"
          description="Add measure"
          defaultMessage="Add measure"
        />
      );
    case 'supervisoryAuthorities':
      return (
        <FormattedMessage
          id="Accountability.supervisoryAuthoritiesHint"
          description="Add supervisory authority"
          defaultMessage="Add supervisory authority"
        />
      );
    case 'tags':
      return (
        <FormattedMessage
          id="Document.addTags"
          description="Add tag"
          defaultMessage="Add tag"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="DataRecipientCategory.addCategory"
          description="Add data recipient category"
          defaultMessage="Add data recipient category"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="DataSourceCategory.addCategory"
          defaultMessage="Add data source category"
        />
      );
    case 'documentRecordTypes':
      return (
        <FormattedMessage
          id="Documents.documentRecordTypes"
          defaultMessage="Add document record type"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Documents.specialCharacteristics"
          defaultMessage="Add special characteristics"
        />
      );

    default:
      return dataItemType;
  }
};
export const multipleSelectorTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'references':
      return (
        <FormattedMessage
          id="SelectReferenceDialog.selectReference"
          description="Select reference in Dialog Header"
          defaultMessage="Select reference"
        />
      );
    case 'documentRecordTypes':
      return (
        <FormattedMessage
          id="Record.selectDocumentRecordTypes"
          defaultMessage="Select document record type"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Record.selectSpecialCharacteristics"
          defaultMessage="Select special characteristics"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="SelectQualityControlDialog.selectHeader"
          description="Select quality control"
          defaultMessage="Select quality control"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="SelectPurposesOfTransfer.selectHeader"
          description="Select purpose of transfer"
          defaultMessage="Select purpose of transfer"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="SelectRiskDetailDialog.selectHeader"
          description="Select risk detail"
          defaultMessage="Select risk detail"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="MitigationMeasuresDialog.selectHeader"
          description="Select mitigating measure"
          defaultMessage="Select mitigating measure"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="SelectRightsDialog.selectHeader"
          description="Select right"
          defaultMessage="Select right"
        />
      );
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
      return (
        <FormattedMessage
          id="MultipleMeasuresDialog.header"
          description="Select Security Measure"
          defaultMessage="Select security measure"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="SelectPolicyDailog.selectPolicy"
          description="Select policy"
          defaultMessage="Select policy"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="SelectDataProtectionDailog.selectMeasures"
          description="Select data protection by design and by default measure"
          defaultMessage="Select data protection by design and by default measure"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="SelectAccountabilityDailog.selectMeasures"
          description="Select accountability measures"
          defaultMessage="Select accountability measures"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="SelectPurposeDailog.SelectPurpose"
          description="Select purpose"
          defaultMessage="Select purpose"
        />
      );
    case 'integrityThreats':
    case 'availabilityThreats':
    case 'confidentialityThreats':
      return (
        <FormattedMessage
          id="SelectThreatDailog.SelectThreat"
          description="Select threat"
          defaultMessage="Select threat"
        />
      );
    case 'integrityImpacts':
    case 'availabilityImpacts':
    case 'confidentialityImpacts':
      return (
        <FormattedMessage
          id="SelectimpactDailog.SelectImpact"
          description="Select impact"
          defaultMessage="Select impact"
        />
      );
    case 'supervisoryAuthorities':
      return (
        <FormattedMessage
          id="SelectAccountabilityDailog.SelectSupervisoryAuthorities"
          description="Select supervisory authority"
          defaultMessage="Select supervisory authority"
        />
      );
    case 'tags':
      return (
        <FormattedMessage
          id="Document.SelectTags"
          description="Select tags"
          defaultMessage="Select tags"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="DataRecipientCategory.selectCategory"
          description="Select data recipient category"
          defaultMessage="Select data recipient category"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="DataSourceCategory.selectCategory"
          defaultMessage="Select data source category"
        />
      );
    case 'linkGroups':
      return (
        <FormattedMessage
          id="LibraryFilter.personaldatalinks"
          defaultMessage="Groups"
        />
      );
    default:
      return dataItemType;
  }
};

export const editMasterTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'documentRecordTypes':
    case 'documentType':
      return (
        <FormattedMessage
          id="Records.editDocumentType"
          defaultMessage="Edit document type"
        />
      );
    case 'specialCharacteristics':
      return (
        <FormattedMessage
          id="Records.editSpecialCharacteristics"
          defaultMessage="Edit special characteristics"
        />
      );
    case 'references':
      return (
        <FormattedMessage
          id="ReferenceDialog.editreference"
          description="Edit reference"
          defaultMessage="Edit reference"
        />
      );
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="FairnessOfDecisionDialog.editFairnessOfDecision"
          defaultMessage="Edit fairness of decision"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="QualityControlDialog.editQualityControl"
          description="Edit quality control"
          defaultMessage="Edit quality control"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="PurposesOfTransfer.editHeader"
          description="Edit purpose of transfer"
          defaultMessage="Edit purpose of transfer"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="Purposes.editHeader"
          description="Edit purposes"
          defaultMessage="Edit purpose"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="MitigationMeasures.editmitigationMeasure"
          description="Edit mitigating measure"
          defaultMessage="Edit mitigating measure"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="RiskDetail.editHeader"
          description="Edit risk detail"
          defaultMessage="Edit risk detail"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="RightsDetail.editHeader"
          description="Edit right"
          defaultMessage="Edit right"
        />
      );
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
    case 'specialSecurityMeasures':
    case 'contractualSecurityMeasures':
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasuresDetail.editHeader"
          description="Edit Security Measure"
          defaultMessage="Edit security measure"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="AccountabilityMeasuresDetail.editHeader"
          description="Edit accountability measure"
          defaultMessage="Edit accountability measure"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="DataProtectionDetail.editHeader"
          description="Edit data protection by design and by default measure"
          defaultMessage="Edit data protection by design and by default measure"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="Threats.editHeader"
          description="Edit threat"
          defaultMessage="Edit threat"
        />
      );

    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="Impacts.editHeader"
          description="Edit impact"
          defaultMessage="Edit impact"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Policies.editHeader"
          description="Edit policy"
          defaultMessage="Edit policy"
        />
      );
    case 'decisions':
      return (
        <FormattedMessage
          id="Decisions.editHeader"
          description="Edit decision"
          defaultMessage="Edit decision"
        />
      );
    case 'findings':
      return (
        <FormattedMessage
          id="Findings.editHeader"
          description="Edit finding"
          defaultMessage="Edit finding"
        />
      );
    case 'tags':
      return (
        <FormattedMessage
          id="Document.editTags"
          description="Edit Tag"
          defaultMessage="Edit tag"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="DataRecipientCategory.editCategory"
          description="Edit data recipient category"
          defaultMessage="Edit data recipient category"
        />
      );
    case 'dataSourceCategories':
      return (
        <FormattedMessage
          id="DataSourceCategory.editCategory"
          defaultMessage="Edit data source category"
        />
      );
    default:
      return '';
  }
};

export const removeMasterTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'references':
      return (
        <FormattedMessage
          id="Records.removeReference"
          description="Reference Remove label"
          defaultMessage="Remove reference"
        />
      );
    case 'fairnessOfDecisions':
      return (
        <FormattedMessage
          id="Records.fairnessOfDecision"
          defaultMessage="Remove fairness of decision"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="Records.qualityControl"
          description="Remove quality control context menu"
          defaultMessage="Remove quality control"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="PurposesOfTransfer.removeHeader"
          description="Remove purpose of transfer"
          defaultMessage="Remove purpose of transfer"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="Purposes.removeHeader"
          description="Remove purposes"
          defaultMessage="Remove purpose"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="Records.removeMitigatingMeasure"
          description="Remove mitigating measure"
          defaultMessage="Remove mitigating measure"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="RiskDetails.removeHeader"
          description="Remove risk detail"
          defaultMessage="Remove risk detail"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage
          id="Rights.removeHeader"
          description="Remove right"
          defaultMessage="Remove right"
        />
      );
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
    case 'contractualSecurityMeasures':
    case 'specialSecurityMeasures':
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasuresDetail.removeHeader"
          description="Remove Security Measure"
          defaultMessage="Remove security measure"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="AccountabilityMeasures.removeHeader"
          description="Remove accountability measure"
          defaultMessage="Remove accountability measure"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="MeasuresDetail.removeHeader"
          description="Remove data protection by design and by default measure"
          defaultMessage="Remove data protection by design and by default measure"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="Threats.removeHeader"
          description="Remove threat"
          defaultMessage="Remove threat"
        />
      );

    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="Impacts.removeHeader"
          description="Remove impact"
          defaultMessage="Remove impact"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="Policies.removeHeader"
          description="Remove policy"
          defaultMessage="Remove policy"
        />
      );
    case 'decisions':
      return (
        <FormattedMessage
          id="Decisions.removeHeader"
          description="Remove decision"
          defaultMessage="Remove decision"
        />
      );
    case 'findings':
      return (
        <FormattedMessage
          id="Findings.removeHeader"
          description="Remove finding"
          defaultMessage="Remove finding"
        />
      );
    case 'tags':
      return (
        <FormattedMessage
          id="Document.removeTags"
          description="Remove tags"
          defaultMessage="Remove tag"
        />
      );
    case 'dataRecipientCategories':
      return (
        <FormattedMessage
          id="DataRecipientCategory.removeCategory"
          description="Remove data recipient category"
          defaultMessage="Remove data recipient category"
        />
      );
    default:
      return '';
  }
};

export const sectionHeaderTranslations = {
  informationRights: (
    <FormattedMessage
      id="masterData.information"
      description="Information"
      defaultMessage="Rights to information"
    />
  ),
  informationHeader: (
    <FormattedMessage
      id="masterData.informationHeader"
      description="Information"
      defaultMessage="Information"
    />
  ),
  consentRights: (
    <FormattedMessage
      id="masterData.consentRights"
      description="consent"
      defaultMessage="Validities of consent"
    />
  ),
  consentHeader: (
    <FormattedMessage
      id="masterData.consentRightsHeader"
      description="consent"
      defaultMessage="Consent"
    />
  ),
  accessRights: (
    <FormattedMessage
      id="masterData.accessRights"
      defaultMessage="Elements of the right to access and rectification"
    />
  ),
  accessHeader: (
    <FormattedMessage
      id="masterData.accessHeader"
      description="Access"
      defaultMessage="Access"
    />
  ),
  objectionRights: (
    <FormattedMessage
      id="masterData.objectionRights"
      defaultMessage="Elements of the rights to objection and restriction"
    />
  ),
  objectionHeader: (
    <FormattedMessage
      id="masterData.objectionHeader"
      description="objection"
      defaultMessage="Objection"
    />
  ),
  deletionRights: (
    <FormattedMessage
      id="masterData.deletionRights"
      defaultMessage="Elements of the rights to erasure"
    />
  ),
  deletionHeader: (
    <FormattedMessage
      id="masterData.deletionHeader"
      description="deletion"
      defaultMessage="Deletion"
    />
  ),
  dataPortabilityRights: (
    <FormattedMessage
      id="masterData.dataPortabilityRights"
      defaultMessage="Elements of the right to data portability"
    />
  ),
  dataPortabilityHeader: (
    <FormattedMessage
      id="masterData.dataPortabilityHeader"
      description="Data portability"
      defaultMessage="Data portability"
    />
  ),
  qualityControls: (
    <FormattedMessage
      id="Record.qualityControls"
      defaultMessage="Elements of accuracy"
    />
  ),
  personalDataItems: (
    <FormattedMessage
      id="LibraryFilter.personalDataItems"
      description="Personal data items"
      defaultMessage="Personal data items"
    />
  ),
  processingGrounds: (
    <FormattedMessage
      id="Record.processingGrounds"
      description="Processing Grounds"
      defaultMessage="Processing Grounds"
    />
  ),
  purposes: (
    <FormattedMessage
      id="Record.purposes"
      description="Purposes"
      defaultMessage="Purposes"
    />
  ),
  purposesAndTransferpurposes: (
    <FormattedMessage
      id="Record.purposesAndTransferpurposes"
      description="(Transfer) Purposes"
      defaultMessage="(Transfer) Purposes"
    />
  ),
  purposesOfTransfer: (
    <FormattedMessage
      id="Record.purposesOfTransfer"
      description="Purposes of transfer"
      defaultMessage="Purposes of transfer"
    />
  ),
  technicalSecurityMeasures: (
    <FormattedMessage
      id="masterData.technicalMeasure"
      description="Technical security measure"
      defaultMessage="Technical security measure"
    />
  ),
  specialSecurityMeasures: (
    <FormattedMessage
      id="masterData.specialMeasure"
      defaultMessage="Special security measures"
    />
  ),
  contractualSecurityMeasures: (
    <FormattedMessage
      id="masterData.contractualMeasure"
      defaultMessage="Contractual security measures"
    />
  ),
  fairnessOfDecisions: (
    <FormattedMessage
      id="masterData.fairnessOfDecision"
      defaultMessage="Fairness of decisions"
    />
  ),
  measures: (
    <FormattedMessage
      id="Common.measures"
      description="Measures"
      defaultMessage="Measures"
    />
  ),
  technicalSecurityMeasuresHeader: (
    <FormattedMessage
      id="Record.technicalSecurityControls"
      description="Technical Security Controls"
      defaultMessage="Technical Security Controls"
    />
  ),
  organisationalMeasuresHeader: (
    <FormattedMessage
      id="Record.organisationalSecurityControls"
      description="Organisational Security Controls"
      defaultMessage="Organisational Security Controls"
    />
  ),
  tiaTechnicalSecurityMeasuresHeader: (
    <FormattedMessage
      id="tia.technicalSecurityControls"
      description="Technical measures"
      defaultMessage="Technical measures"
    />
  ),
  tiaOrganisationalMeasuresHeader: (
    <FormattedMessage
      id="tia.organisationalSecurityControls"
      description="Organisational measures"
      defaultMessage="Organisational measures"
    />
  ),
  organisationalSecurityMeasures: (
    <FormattedMessage
      id="masterData.organisationalMeasure"
      description="Organisational security measure"
      defaultMessage="Organisational security measure"
    />
  ),
  policies: (
    <FormattedMessage
      id="masterData.policiesHeader"
      description="Policies etc"
      defaultMessage="Policies, codes of conduct and certification schemes"
    />
  ),
  policyHeader: (
    <FormattedMessage
      id="masterData.policiesHeader"
      description="Policies header"
      defaultMessage="Policies, codes of conduct and certification schemes"
    />
  ),
  documentRecords: (
    <FormattedMessage
      id="masterData.documentRecords"
      defaultMessage="Document records"
    />
  ),
  documentRecordTypes: (
    <FormattedMessage
      id="masterData.documentRecordTypes"
      defaultMessage="Document types"
    />
  ),
  specialCharacteristics: (
    <FormattedMessage
      id="masterData.specialCharacteristics"
      defaultMessage="Special characteristics"
    />
  ),
  dataProtectionHeader: (
    <FormattedMessage
      id="masterData.dataProtectionHeader"
      description="Data protection header"
      defaultMessage="Data protection by design and by default"
    />
  ),
  accountabilityMeasuresHeader: (
    <FormattedMessage
      id="masterData.accountabilityMeasuresHeader"
      description="Accountability measures header"
      defaultMessage="Other accountability measures"
    />
  ),
  dataSubjectConsultationHeader: (
    <FormattedMessage
      id="masterData.dataSubjectConsultationHeader"
      description="Data subject consultation header"
      defaultMessage="Data subject consultation"
    />
  ),
  dpoConsultationHeader: (
    <FormattedMessage
      id="masterData.dpoConsultationHeader"
      defaultMessage="DPO consultation"
    />
  ),
  supervisoryConsultationHeader: (
    <FormattedMessage
      id="masterData.supervisoryConsultationHeader"
      description="Supervisory authority consultation header"
      defaultMessage="Supervisory authority consultation header"
    />
  ),
  summaryHeader: (
    <FormattedMessage
      id="Record.summaryHeader"
      description="Summary"
      defaultMessage="Summary"
    />
  ),
  conclusionHeader: (
    <FormattedMessage
      id="Record.conclusionHeader"
      description="Overall Outcome of Assessment"
      defaultMessage="Overall outcome of assessment"
    />
  ),
  retentionTerms: (
    <FormattedMessage
      id="Record.retentionTerms"
      description="Retention terms"
      defaultMessage="Retention terms"
    />
  ),
  transferGrounds: (
    <FormattedMessage
      id="Record.transferGrounds"
      description="Transfer grounds"
      defaultMessage="Transfer grounds"
    />
  ),
  processingPurposes: (
    <FormattedMessage
      id="Environment.processingPurposes"
      description="Processing Purpose"
      defaultMessage="Processing Purposes"
    />
  ),
  transferPurposes: (
    <FormattedMessage
      id="Environment.transferPurposes"
      description="Transfer purposes"
      defaultMessage="Transfer purposes"
    />
  ),
  purposeDetails: (
    <FormattedMessage
      id="Environment.purposeDetails"
      description="Purpose details"
      defaultMessage="Purpose risk details"
    />
  ),
  processingGroundDetails: (
    <FormattedMessage
      id="Environment.processingGroundDetails"
      description="Processing Ground Details"
      defaultMessage="Processing ground risk details"
    />
  ),
  dataMinimization: (
    <FormattedMessage
      id="Environment.dataMinimization"
      description="Data minimization"
      defaultMessage="Data minimization risk details"
    />
  ),
  storageLimitation: (
    <FormattedMessage
      id="Environment.storageLimitation"
      description="storage Limitation"
      defaultMessage="Storage limitation risk details"
    />
  ),
  transferPuposeDetails: (
    <FormattedMessage
      id="Environment.transferPuposeDetails"
      description="transfer Pupose Details"
      defaultMessage="Transfer pupose details"
    />
  ),
  transferGroundDetails: (
    <FormattedMessage
      id="Environment.transferGroundDetails"
      description="Transfer Ground Details"
      defaultMessage="Transfer ground risk details"
    />
  ),
  dataQualityMeasures: (
    <FormattedMessage
      id="Environment.dataQualityMeasures"
      description="data quality measures"
      defaultMessage="Data quality controls"
    />
  ),
  dataQualityDetails: (
    <FormattedMessage
      id="Environment.dataQualityDetails"
      description="data quality details"
      defaultMessage="Data quality control risk details"
    />
  ),
  technicalDetails: (
    <FormattedMessage
      id="Environment.technicalDetails"
      description="Technical security measure details"
      defaultMessage="Technical security measure risk details"
    />
  ),
  organisationalDetails: (
    <FormattedMessage
      id="Environment.organisationalDetails"
      description="Organisational security measure details"
      defaultMessage="Organisational security measure risk details"
    />
  ),
  contractualDetails: (
    <FormattedMessage
      id="Environment.contractualDetails"
      defaultMessage="Contractual security measure risk details"
    />
  ),
  confidentialityThreats: (
    <FormattedMessage
      id="Environment.confidentialityThreat"
      description="Threat to confidentiality"
      defaultMessage="Threats & impacts to confidentiality"
    />
  ),
  confidentialityImpacts: (
    <FormattedMessage
      id="Environment.confidentialityImpact"
      description="Impact to confidentiality"
      defaultMessage="Impacts to confidentiality"
    />
  ),
  integrityThreats: (
    <FormattedMessage
      id="Environment.integrityThreat"
      description="Threat to integrity"
      defaultMessage="Threats & impacts to integrity"
    />
  ),
  integrityImpacts: (
    <FormattedMessage
      id="Environment.integrityImpact"
      description="Impact to integrity"
      defaultMessage="Impacts to integrity"
    />
  ),
  availabilityThreats: (
    <FormattedMessage
      id="Environment.availabilityThreat"
      description="Threat to availability"
      defaultMessage="Threats & impacts to availability"
    />
  ),
  availabilityImpacts: (
    <FormattedMessage
      id="Environment.availabilityImpact"
      description="Impact to availability"
      defaultMessage="Impacts to availability"
    />
  ),
  dataProtectionMeasures: (
    <FormattedMessage
      id="Environment.dataProtection"
      description="Data protection by design"
      defaultMessage="Privacy by design and by default measures"
    />
  ),
  accountabilityMeasures: (
    <FormattedMessage
      id="Environment.accountabilityMeasures"
      description="Other accountability measures"
      defaultMessage="Other accountability measures"
    />
  ),
  dataSubjectCategoriesFinding: (
    <FormattedMessage
      id="Environment.dataSubjectFindings"
      description="Data subject consultation findings"
      defaultMessage="Data subject consultation findings"
    />
  ),
  dataSubjectCategoriesDecision: (
    <FormattedMessage
      id="Environment.dataSubjectDecisions"
      description="Data subject consultation decisions"
      defaultMessage="Data subject consultation decisions"
    />
  ),
  dpoFinding: (
    <FormattedMessage
      id="Environment.dpoFindings"
      defaultMessage="DPO consultation findings"
    />
  ),
  dpoDecision: (
    <FormattedMessage
      id="Environment.dpoDecisions"
      defaultMessage="DPO consultation decisions"
    />
  ),
  supervisoryAuthoritiesFinding: (
    <FormattedMessage
      id="Environment.supervisoryFindings"
      description="Supervisory authority consultation findings"
      defaultMessage="Supervisory authority consultation findings"
    />
  ),
  supervisoryAuthoritiesDecision: (
    <FormattedMessage
      id="Environment.supervisoryDecision"
      description="Supervisory authority consultation decisions"
      defaultMessage="Supervisory authority consultation decisions"
    />
  ),
  rightToInfoDetails: (
    <FormattedMessage
      id="Environment.rightToInfoDetails"
      description="Right to information details"
      defaultMessage="Right to information risk details"
    />
  ),
  rightToConsentDetails: (
    <FormattedMessage
      id="Environment.rightToConsentDetails"
      description="Consent details"
      defaultMessage="Validity of consent risk details"
    />
  ),
  rightToAccessDetails: (
    <FormattedMessage
      id="Environment.rightToAccessDetails"
      defaultMessage="Elements of the rights to access risk details"
    />
  ),
  rightToObjectionDetails: (
    <FormattedMessage
      id="Environment.rightToObjectionDetails"
      description="Right to object details"
      defaultMessage="Right to objection risk details"
    />
  ),
  rightToDeletionDetails: (
    <FormattedMessage
      id="Environment.rightToDeletionDetails"
      description="Right to deletion details"
      defaultMessage="Right to deletion risk details"
    />
  ),
  rightToDataProtabilityDetails: (
    <FormattedMessage
      id="Environment.rightToDataProtabilityDetails"
      description="Data portability details"
      defaultMessage="Right to data portability risk details"
    />
  ),
  mitigatingMeasures: (
    <FormattedMessage
      id="Record.mitigatingMeasures"
      description="Mitigating measures"
      defaultMessage="Mitigating measures"
    />
  ),
  policy: (
    <FormattedMessage
      id="Environment.policies"
      description="Policy"
      defaultMessage="Policy"
    />
  ),
  references: (
    <FormattedMessage
      id="Record.references"
      description="References"
      defaultMessage="References"
    />
  ),
  dataRecipientCategories: (
    <FormattedMessage
      id="Record.dataRecipientCategories"
      description="Data recipient categories"
      defaultMessage="Data recipient categories"
    />
  )
};

export const measuresTypeTranslations = {
  Technical: (
    <FormattedMessage
      id="SecurityMeasure.TechnicalType"
      description="Technical"
      defaultMessage="Technical security mesaure"
    />
  ),
  Organisational: (
    <FormattedMessage
      id="SecurityMeasure.OrganisationalType"
      description="Organisational"
      defaultMessage="Organisational security measure"
    />
  )
};

export const measuresTypeMapper = (type) => measuresTypeTranslations[type];

export const policyTypeTranslations = {
  PolicyDocument: (
    <FormattedMessage
      id="Policy.PolicyDocument"
      description="Policy document"
      defaultMessage="Policy document"
    />
  ),
  CodeOfConduct: (
    <FormattedMessage
      id="Policy.CodeOfConduct"
      description="Code of conduct"
      defaultMessage="Code of conduct"
    />
  ),
  Certification: (
    <FormattedMessage
      id="Policy.Certification"
      description="Certification"
      defaultMessage="Certification"
    />
  )
};

export const policyTypeMapper = (type) => policyTypeTranslations[type];

export const subTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'Technical':
      return measuresTypeTranslations.Technical;
    case 'Organisational':
      return measuresTypeTranslations.Organisational;
    case 'PolicyDocument':
      return policyTypeTranslations.PolicyDocument;
    case 'CodeOfConduct':
      return policyTypeTranslations.CodeOfConduct;
    case 'Certification':
      return policyTypeTranslations.Certification;
    default:
      return dataItemType;
  }
};

export const riskDetailHeaderMapper = (riskType) => {
  switch (riskType) {
    case 'purposes':
      return sectionHeaderTranslations.purposeDetails;
    case 'processingGrounds':
      return sectionHeaderTranslations.processingGroundDetails;
    case 'personalDataItems':
      return sectionHeaderTranslations.dataMinimization;
    case 'retentionTerms':
      return sectionHeaderTranslations.storageLimitation;
    case 'purposesOfTransfer':
      return sectionHeaderTranslations.transferPuposeDetails;
    case 'transferGrounds':
      return sectionHeaderTranslations.transferGroundDetails;
    case 'qualityControls':
      return sectionHeaderTranslations.dataQualityDetails;
    case 'informationRights':
      return sectionHeaderTranslations.rightToInfoDetails;
    case 'consentRights':
      return sectionHeaderTranslations.rightToConsentDetails;
    case 'accessRights':
      return sectionHeaderTranslations.rightToAccessDetails;
    case 'objectionRights':
      return sectionHeaderTranslations.rightToObjectionDetails;
    case 'deletionRights':
      return sectionHeaderTranslations.rightToDeletionDetails;
    case 'dataPortabilityRights':
      return sectionHeaderTranslations.rightToDataProtabilityDetails;
    case 'technicalSecurityMeasures':
      return sectionHeaderTranslations.technicalDetails;
    case 'organisationalSecurityMeasures':
      return sectionHeaderTranslations.organisationalDetails;
    case 'contractualSecurityMeasures':
      return sectionHeaderTranslations.contractualDetails;
    case 'dataSubjectCategoriesFinding':
      return sectionHeaderTranslations.dataSubjectCategoriesFinding;
    case 'supervisoryAuthoritiesFinding':
      return sectionHeaderTranslations.supervisoryAuthoritiesFinding;
    case 'dataSubjectCategoriesDecision':
      return sectionHeaderTranslations.dataSubjectCategoriesDecision;
    case 'supervisoryAuthoritiesDecision':
      return sectionHeaderTranslations.supervisoryAuthoritiesDecision;
    case 'dpoDecision':
      return sectionHeaderTranslations.dpoDecision;
    case 'dpoFinding':
      return sectionHeaderTranslations.dpoFinding;

    default:
      return riskType;
  }
};

export const bulkDeletetionTranslations = {
  dataRecipientCategories: (
    <FormattedMessage
      id="DataRecipientCategories.masterData"
      description="data recipient categories"
      defaultMessage="data recipient categories"
    />
  ),
  references: (
    <FormattedMessage
      id="Reference.masterData"
      description="references"
      defaultMessage="references"
    />
  ),
  qualityControls: (
    <FormattedMessage
      id="QualityControls.masterData"
      description="quality control"
      defaultMessage="quality control"
    />
  ),
  purposesOfTransfer: (
    <FormattedMessage
      id="PurposesOfTransfer.masterData"
      description="purposes of transfer"
      defaultMessage="purposes of transfer"
    />
  ),
  purposes: smallcasePlurals('purposes'),
  mitigatingMeasures: (
    <FormattedMessage
      id="MitigationMeasures.masterData"
      description="mitigating measures"
      defaultMessage="mitigating measures"
    />
  ),
  riskDetails: (
    <FormattedMessage
      id="AssessmentDetails.riskDetails"
      description="risk details"
      defaultMessage="risk details"
    />
  ),
  consentRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  informationRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  accessRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  deletionRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  objectionRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  dataPortabilityRights: (
    <FormattedMessage
      id="Rights.masterData"
      description="rights"
      defaultMessage="rights"
    />
  ),
  technicalSecurityMeasures: (
    <FormattedMessage
      id="TechnicalSecurityMeasures.masterData"
      description="technical security measures"
      defaultMessage="technical security measures"
    />
  ),
  organisationalSecurityMeasures: (
    <FormattedMessage
      id="OrganisationalSecurityMeasures.masterData"
      description="organisational security measures"
      defaultMessage="organisational security measures"
    />
  ),
  contractualSecurityMeasures: (
    <FormattedMessage
      id="ContractualSecurityMeasures.masterData"
      defaultMessage="contractual security measures"
    />
  ),
  securityMeasures: (
    <FormattedMessage
      id="SecurityMeasures.masterData"
      description="security measures"
      defaultMessage="security measures"
    />
  ),
  policies: (
    <FormattedMessage
      id="Policies.masterData"
      description="policies"
      defaultMessage="policies"
    />
  ),
  confidentialityThreats: (
    <FormattedMessage
      id="Threats.masterData"
      description="threats"
      defaultMessage="threats"
    />
  ),
  integrityThreats: (
    <FormattedMessage
      id="Threats.masterData"
      description="threats"
      defaultMessage="threats"
    />
  ),
  availabilityThreats: (
    <FormattedMessage
      id="Threats.masterData"
      description="threats"
      defaultMessage="threats"
    />
  ),
  confidentialityImpacts: (
    <FormattedMessage
      id="Impacts.masterData"
      description="impacts"
      defaultMessage="impacts"
    />
  ),
  availabilityImpacts: (
    <FormattedMessage
      id="Impacts.masterData"
      description="impacts"
      defaultMessage="impacts"
    />
  ),
  integrityImpacts: (
    <FormattedMessage
      id="Impacts.masterData"
      description="impacts"
      defaultMessage="impacts"
    />
  ),
  supervisoryAuthoritiesFinding: (
    <FormattedMessage
      id="Findings.masterData"
      description="findings"
      defaultMessage="findings"
    />
  ),
  dpoFinding: (
    <FormattedMessage id="Findings.masterData" defaultMessage="findings" />
  ),
  dpoDecision: (
    <FormattedMessage
      id="Decisions.masterData"
      description="decisions"
      defaultMessage="decisions"
    />
  ),
  dataSubjectCategoriesFinding: (
    <FormattedMessage
      id="Findings.masterData"
      description="findings"
      defaultMessage="findings"
    />
  ),
  supervisoryAuthoritiesDecision: (
    <FormattedMessage
      id="Decisions.masterData"
      description="decisions"
      defaultMessage="decisions"
    />
  ),
  dataSubjectCategoriesDecision: (
    <FormattedMessage
      id="Decisions.masterData"
      description="decisions"
      defaultMessage="decisions"
    />
  ),
  dataProtectionMeasures: (
    <FormattedMessage
      id="DataProtectionMeasures.masterData"
      description="data protection measures"
      defaultMessage="data protection measures"
    />
  ),
  accountabilityMeasures: (
    <FormattedMessage
      id="AccountabilityMeasures.masterData"
      description="accountability measures"
      defaultMessage="accountability measures"
    />
  ),
  dataSources: (
    <FormattedMessage
      id="DataSources.masterData"
      description="data sources"
      defaultMessage="data sources"
    />
  ),
  dataSourceCategories: (
    <FormattedMessage
      id="DataSourceCategories.masterData"
      description="data sources category"
      defaultMessage="data sources categories"
    />
  ),
  dataSubjectCategories: (
    <FormattedMessage
      id="DataSubjectCategories.masterData"
      description="data subject categories"
      defaultMessage="data subject categories"
    />
  ),
  personalDataCategories: (
    <FormattedMessage
      id="PersonalDataCategories.masterData"
      description="personal data categories"
      defaultMessage="personal data categories"
    />
  ),
  personalDataItems: (
    <FormattedMessage
      id="PersonalDataItems.masterData"
      description="personal data items"
      defaultMessage="personal data items"
    />
  ),
  processingCategories: (
    <FormattedMessage
      id="ProcessingCategories.masterData"
      description="processing categories"
      defaultMessage="processing categories"
    />
  ),
  retentionTermOffset: (
    <FormattedMessage
      id="RetentionTermOffset.masterData"
      description="retention term offset"
      defaultMessage="retention term offset"
    />
  ),
  processingGrounds: smallcasePlurals('processingGrounds'),
  retentionTerms: smallcasePlurals('retentionTerms'),
  transferGrounds: (
    <FormattedMessage
      id="RecordDetails.transferGrounds"
      defaultMessage="transfer ground"
    />
  ),
  dataSubjectRights: (
    <FormattedMessage
      id="dataSubjectRights.masterData"
      defaultMessage="data subject rights"
    />
  ),
  threats: (
    <FormattedMessage
      id="threats.masterData"
      description="threats"
      defaultMessage="threats"
    />
  ),
  impacts: (
    <FormattedMessage id="Impacts.masterData" defaultMessage="impacts" />
  ),
  accountabilitysecuritymeasures: (
    <FormattedMessage
      id="accountabilitysecuritymeasures.masterData"
      description="accountability measure"
      defaultMessage="accountability measure"
    />
  ),
  accountabilities: (
    <FormattedMessage
      id="accountabilities.masterData"
      description="accountabilities"
      defaultMessage="accountabilities"
    />
  ),
  dataSubjectRights_informationRights: (
    <FormattedMessage
      id="masterData.information.smallCase"
      defaultMessage="rights to information"
    />
  ),
  dataSubjectRights_consentRights: (
    <FormattedMessage
      id="masterData.consentRights.smallCase"
      defaultMessage="validities of consent"
    />
  ),
  dataSubjectRights_accessRights: (
    <FormattedMessage
      id="masterData.accessRights.smallCase"
      defaultMessage="elements of the right to access and rectification"
    />
  ),
  dataSubjectRights_objectionRights: (
    <FormattedMessage
      id="masterData.objectionRights.smallCase"
      defaultMessage="elements of the rights to objection and restriction"
    />
  ),
  dataSubjectRights_deletionRights: (
    <FormattedMessage
      id="masterData.deletionRights.smallCase"
      defaultMessage="elements of the rights to erasure"
    />
  ),
  dataSubjectRights_dataPortabilityRights: (
    <FormattedMessage
      id="masterData.qualitycontrols.smallCase"
      defaultMessage="quality controls"
    />
  ),
  supervisoryAuthorities: (
    <FormattedMessage
      id="Breach.supervisoryAuthority"
      defaultMessage="supervisory authority"
    />
  ),
  threat_confidentiality: (
    <FormattedMessage
      id="Environment.confidentialityThreat.smallCase"
      defaultMessage="threats to confidentiality"
    />
  ),
  impact_confidentiality: (
    <FormattedMessage
      id="Environment.Impact.smallCase"
      defaultMessage="potential impacts"
    />
  ),
  threat_integrity: (
    <FormattedMessage
      id="Environment.integrityThreat.smallCase"
      defaultMessage="threats to integrity"
    />
  ),
  threat_availability: (
    <FormattedMessage
      id="Environment.availabilityThreat.smallCase"
      defaultMessage="threats to availability"
    />
  ),
  impact_integrity: (
    <FormattedMessage
      id="Environment.Impact.smallCase"
      defaultMessage="potential impacts"
    />
  ),
  impact_availability: (
    <FormattedMessage
      id="Environment.Impact.smallCase"
      defaultMessage="potential impacts"
    />
  ),
  organisations: (
    <FormattedMessage
      id="Environment.organisations"
      defaultMessage="organisations"
    />
  ),
  vendorForm: vendorTranslations.form,
  vendorFormTemplate: vendorTranslations.template
};

export const globalEditMasterTypeTranslations = (dataItemType) => {
  switch (dataItemType) {
    case 'references':
      return (
        <FormattedMessage
          id="masterdata.references.singular"
          defaultMessage="reference"
        />
      );
    case 'qualityControls':
      return (
        <FormattedMessage
          id="masterData.elementOfAccuracy"
          defaultMessage="element of accuracy"
        />
      );
    case 'purposesOfTransfer':
      return (
        <FormattedMessage
          id="masterdata.purposesOfTransfer"
          defaultMessage="purpose of transfer"
        />
      );
    case 'purposes':
      return (
        <FormattedMessage
          id="masterdata.purposes.singular"
          defaultMessage="purpose"
        />
      );
    case 'mitigatingMeasures':
      return (
        <FormattedMessage
          id="masterdata.mitigationMeasures.singular"
          defaultMessage="mitigating measure"
        />
      );
    case 'riskDetails':
      return (
        <FormattedMessage
          id="Assessment.riskDetail"
          defaultMessage="risk detail"
        />
      );
    case 'informationRights':
    case 'consentRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return (
        <FormattedMessage id="MasterDataItem.right" defaultMessage="right" />
      );
    case 'technicalSecurityMeasures':
    case 'organisationalSecurityMeasures':
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="Records.securitymeasure"
          defaultMessage="security measure"
        />
      );
    case 'accountabilityMeasures':
      return (
        <FormattedMessage
          id="masterdata.accountabilityMeasuresDetail"
          defaultMessage="accountability measure"
        />
      );
    case 'dataProtectionMeasures':
      return (
        <FormattedMessage
          id="masterdata.dataProtectionDetail"
          defaultMessage="data protection by design and by default measure"
        />
      );
    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return (
        <FormattedMessage
          id="masterdata.threats.singular"
          defaultMessage="threat"
        />
      );

    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return (
        <FormattedMessage
          id="masterdata.impacts.singular"
          defaultMessage="impact"
        />
      );
    case 'policies':
      return (
        <FormattedMessage
          id="masterdata.policies.singular"
          defaultMessage="policy"
        />
      );
    case 'decisions':
      return (
        <FormattedMessage
          id="masterdata.Decisions.singular"
          defaultMessage="decision"
        />
      );
    case 'findings':
      return (
        <FormattedMessage
          id="masterdata.Findings.singular"
          defaultMessage="finding"
        />
      );
    case 'dataRecipientCategory':
      return (
        <FormattedMessage
          id="masterdata.dataRecipientCategory.singular"
          defaultMessage="data recipient category"
        />
      );
    default:
      return dataItemType;
  }
};

export const customMasterDataTranslations = (type, value, data) => {
  switch (type) {
    case 'name':
      return singularTerms(type);
    case 'tags':
      return pluralTerms(type);
    case 'deleteTitle':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.deleteTitle"
          defaultMessage="Delete {entityType}?"
          values={{ entityType: `'${value}'` }}
        />
      );
    case 'deleteContent':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.delete"
          defaultMessage="Do you want to delete the {entityName} {itemValue}?"
          values={{
            entityName: `${value}`,
            itemValue: `'${data}'`
          }}
        />
      );
    case 'editSuccess':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.editSuccess"
          defaultMessage="{entityType} edited successfully"
          values={{
            entityType: `${value}`
          }}
        />
      );
    case 'createSuccess':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.createSuccess"
          defaultMessage="{entityType} has been created"
          values={{
            entityType: `${value}`
          }}
        />
      );
    case 'editTitle':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.editTitle"
          defaultMessage="Edit {label}"
          values={{
            label: `${value}`
          }}
        />
      );
    case 'createTitle':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.createTitle"
          defaultMessage="Create {label}"
          values={{
            label: `${value}`
          }}
        />
      );
    case 'merge':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.merge"
          defaultMessage="Merge  {label}"
          values={{
            label: `${value}`
          }}
        />
      );
    case 'addHintText':
      return (
        <FormattedMessage
          id="CustomSimpleMasterData.addHintText"
          defaultMessage="Add  {label}"
          values={{
            label: `${value}`
          }}
        />
      );
    default:
      return type;
  }
};
