import {
  commonTranslations,
  statusTranslations,
  recordTranslations,
  categoryTypeTranslations,
  orgTypesTranslations
} from './commontranslations';

export const statusItems = [
  {
    value: 'record_status_approved',
    label: statusTranslations.record_status_approved
  },
  {
    value: 'record_status_disapproved',
    label: statusTranslations.record_status_disapproved
  },
  {
    value: 'record_status_draft',
    label: statusTranslations.record_status_draft
  },
  {
    value: 'record_status_provisional',
    label: statusTranslations.record_status_provisional
  },
  {
    value: 'record_status_redundant',
    label: statusTranslations.record_status_redundant
  },
  {
    value: 'record_status_requested',
    label: statusTranslations.record_status_requested
  },
  {
    value: 'record_status_review_overdue',
    label: statusTranslations.record_status_review_overdue
  },
  {
    value: 'record_status_review_soon',
    label: statusTranslations.record_status_review_soon
  },
  {
    value: 'record_status_withdrawn',
    label: statusTranslations.record_status_withdrawn
  }
];

export const filterItem = {
  parent: 'record_status_approved',
  dependant: ['record_status_review_soon', 'record_status_review_overdue']
};

export const statusColorCodes = (status) => {
  switch (status) {
    case 'record_status_review_overdue':
      return { color: 'red' };
    case 'record_status_review_soon':
      return { color: 'orange' };
    default:
      return {};
  }
};

export const categoryTypes = [
  { value: 'normal', label: categoryTypeTranslations.normal, key: 'normal' },
  { value: 'special', label: categoryTypeTranslations.special, key: 'special' },
  {
    value: 'personal_number',
    label: categoryTypeTranslations.personal_number,
    key: 'personal_number'
  },
  {
    value: 'crime_related',
    label: categoryTypeTranslations.crime_related,
    key: 'crime_related'
  },
  {
    value: 'other_sensitive_data',
    label: categoryTypeTranslations.other_sensitive_data,
    key: 'other_sensitive_data'
  }
];

export const orgTypes = [
  { value: 'legalentity', label: orgTypesTranslations.legalEntity },
  { value: 'department', label: orgTypesTranslations.department }
];

export const booleanTypes = [
  { value: 'true', label: commonTranslations.Yes },
  { value: 'false', label: commonTranslations.No }
];

export const processingPdfExportTypes = [
  {
    value: 'Processing_Full',
    label: commonTranslations.fullExport,
    key: 'Processing_Full'
  },
  {
    value: 'Processing_Article30_Section1',
    label: commonTranslations.article301,
    key: 'Processing_Article30_Section1'
  },
  {
    value: 'Processing_Article30_Section2',
    label: commonTranslations.article302,
    key: 'Processing_Article30_Section2'
  },
  {
    value: 'Processing_With_Empty_Fields',
    label: commonTranslations.with_Empty_Fields,
    key: 'Processing_With_Empty_Fields'
  }
];

export function getPDFExportTypes(jurisdiction) {
  const isGDPR = jurisdiction.includes('GDPR');
  if (isGDPR) {
    return processingPdfExportTypes;
  }
  return CCPAProcessingPdfExportTypes;
}

export const CCPAProcessingPdfExportTypes = [
  {
    value: 'Processing_Full',
    label: commonTranslations.fullExport,
    key: 'Processing_Full'
  },
  {
    value: 'Processing_With_Empty_Fields',
    label: commonTranslations.with_Empty_Fields,
    key: 'Processing_With_Empty_Fields'
  }
];

export const assessmentPdfExportTypes = [
  {
    value: 'Assessment_Full',
    label: commonTranslations.fullExport,
    key: 'Assessment_Full'
  },
  {
    value: 'Assessment_With_Empty_Fields',
    label: commonTranslations.with_Empty_Fields,
    key: 'Assessment_With_Empty_Fields'
  }
];

export const breachPdfExportTypes = [
  {
    value: 'Breach_Full',
    label: commonTranslations.fullExport,
    key: 'Breach_Full'
  },
  {
    value: 'Breach_With_Empty_Fields',
    label: commonTranslations.with_Empty_Fields,
    key: 'Breach_With_Empty_Fields'
  }
];

export const tiaPdfExportTypes = [
  { value: 'TIA_Full', label: commonTranslations.fullExport, key: 'TIA_Full' },
  {
    value: 'TIA_With_Empty_Fields',
    label: commonTranslations.with_Empty_Fields,
    key: 'TIA_With_Empty_Fields'
  }
];

export const tiaScoreRangeTypes = [
  { value: 'low', label: recordTranslations.tiaScoreRangeLow },
  { value: 'medium_low', label: recordTranslations.tiaScoreRangeMediumLow },
  { value: 'medium_high', label: recordTranslations.tiaScoreRangeMediumHigh },
  { value: 'high', label: recordTranslations.tiaScoreRangeHigh }
];

export const reportExportTypes = [
  { value: 'csv', label: 'CSV', key: 'csv' },
  { value: 'csv_full', label: recordTranslations.csvFull, key: 'csv_full' }
];

export const checkForRecordCreation = (pricingPlan) =>
  (pricingPlan.get('plan') && pricingPlan.get('plan').maxRecords) >
  (pricingPlan.get('usage') && pricingPlan.get('usage').records);

export const getModifiedActions = (pricingPlan, actionItems) => {
  const isCreatePossible = checkForRecordCreation(pricingPlan);

  return isCreatePossible
    ? actionItems
    : actionItems.filter((item) => item.action !== 'copyRecord');
};
