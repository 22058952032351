import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import {
  RECORDS_FETCH_LIMIT,
  handleServiceDown
} from '@packages/utils/common-utils';

export function* resetAndFectchLinkedRecords(action) {
  yield put({ type: 'LINKED_RECORD:LIST:REQUEST:FETCH' });
  yield fetchLinkedRecords(action);
}

export function* fetchLinkedRecords(action) {
  const { recordType, position = 0, searchParams = {} } = action;
  const { searchText } = searchParams;

  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/records?filter=${
          recordType ? `recordType=${recordType}` : `notRecordType=document`
        }&fields=name&numberOfResults=${RECORDS_FETCH_LIMIT}&offset=${position}${
          searchText ? `&search=name=${encodeURIComponent(searchText)}` : ''
        }`,
        null
      );

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          recordNumber: item.privacyRecordNumber,
          id: item.id,
          name: item.name,
          key: `${item.name}`
        }));
        yield put({
          type: 'LINKED_RECORD:LIST:SEARCH:SUCCESS',
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}
