import { pluralTerms } from '@packages/utils/uppercaseTranslations';
import { recordTranslations } from '@packages/utils/commontranslations';
import { processingCharacteristicsTranslations } from '../../recordTranslations';

export const processingCharacteristicTypes = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_automated_processing,
    value: 'processing_characteristics_automated_processing',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_largescale_processing,
    value: 'processing_characteristics_largescale_processing',
    type: 'EDPB'
  },
  // eslint-disable-next-line max-len
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_sensitive_personal_data_with_special_categories,
    value:
      'processing_characteristics_sensitive_personal_data_with_special_categories',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_public_places_systematic_monitoring,
    value: 'processing_characteristics_public_places_systematic_monitoring',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_national_supervisory_authority,
    value: 'processing_characteristics_national_supervisory_authority',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_matching_or_combining_datasets,
    value: 'processing_characteristics_matching_or_combining_datasets',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_prevents_from_exercising_rights,
    value: 'processing_characteristics_prevents_from_exercising_rights',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_vulnerable_data_subjects,
    value: 'processing_characteristics_vulnerable_data_subjects',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_new_technology_or_innovative_use,
    value: 'processing_characteristics_new_technology_or_innovative_use',
    type: 'EDPB'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_evaluation_or_scoring,
    value: 'processing_characteristics_evaluation_or_scoring',
    type: 'EDPB'
  }
];

export const UKProcessingCharacteristicsTypes = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_innovative_technology,
    value: 'processing_characteristics_innovative_technology',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_denial_of_service,
    value: 'processing_characteristics_denial_of_service',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_large_scale_profiling,
    value: 'processing_characteristics_large_scale_profiling',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_biometric_data,
    value: 'processing_characteristics_biometric_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_genetic_data,
    value: 'processing_characteristics_genetic_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_data_matching,
    value: 'processing_characteristics_data_matching',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_invisible_processing,
    value: 'processing_characteristics_invisible_processing',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_tracking,
    value: 'processing_characteristics_tracking',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_target_vulnerable_persons,
    value: 'processing_characteristics_target_vulnerable_persons',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_risk_of_physical_harm,
    value: 'processing_characteristics_risk_of_physical_harm',
    type: 'ICO'
  }
];

export const BelgianProcessingCharacteristicsWhitelist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_private_organisation_inline_with_legal_obligation,
    value:
      'processing_characteristics_private_organisation_inline_with_legal_obligation',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_salary_purposes,
    value: 'processing_characteristics_salary_purposes',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_personal_data_exclusively_administrative_in_nature,
    value:
      'processing_characteristics_personal_data_exclusively_administrative_in_nature',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_exclusively_for_the_controllers_accountancy_practices,
    value:
      'processing_characteristics_exclusively_for_the_controllers_accountancy_practices',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_administration_of_shareholders_and_associates,
    value:
      'processing_characteristics_administration_of_shareholders_and_associates',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_nonprofit_organisation_in_its_ordinary_activities,
    value:
      'processing_characteristics_nonprofit_organisation_in_its_ordinary_activities',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_exclusively_to_visitor_registration_during_access_control,
    value:
      'processing_characteristics_exclusively_to_visitor_registration_during_access_control',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_educational_institutions_for_student_management,
    value:
      'processing_characteristics_educational_institutions_for_student_management',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_exclusively_for_client_or_supplier_management,
    value:
      'processing_characteristics_exclusively_for_client_or_supplier_management',
    type: 'ICO'
  }
];

export const BelgianProcessingCharacteristicsBlacklist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_biometric_data,
    value: 'processing_characteristics_biometric_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_contract_decisions_using_third_party_data,
    value:
      'processing_characteristics_contract_decisions_using_third_party_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_collection_of_health_data_through_implant,
    value:
      'processing_characteristics_collection_of_health_data_through_implant',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_analysation_or_prediction_using_third_party_data,
    value:
      'processing_characteristics_analysation_or_prediction_using_third_party_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_sharing_of_sensitive_data,
    value: 'processing_characteristics_sharing_of_sensitive_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_internet_of_things,
    value: 'processing_characteristics_internet_of_things',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_communication_metadata_or_localization_data_when_not_strictly_necessary,
    value:
      'processing_characteristics_communication_metadata_or_localization_data_when_not_strictly_necessary',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_automated_observance_and_influencing_of_behaviour,
    value:
      'processing_characteristics_automated_observance_and_influencing_of_behaviour',
    type: 'ICO'
  }
];

export const zeroRiskCharacteristics = [
  'processing_characteristics_private_organisation_inline_with_legal_obligation',
  'processing_characteristics_salary_purposes',
  'processing_characteristics_personal_data_exclusively_administrative_in_nature',
  'processing_characteristics_exclusively_for_the_controllers_accountancy_practices',
  'processing_characteristics_administration_of_shareholders_and_associates',
  'processing_characteristics_nonprofit_organisation_in_its_ordinary_activities',
  'processing_characteristics_exclusively_to_visitor_registration_during_access_control',
  'processing_characteristics_educational_institutions_for_student_management',
  'processing_characteristics_exclusively_for_client_or_supplier_management'
];

export const NetherlandsProcessingCharacteristicsTypes = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_covert_investigation,
    value: 'processing_characteristics_covert_investigation',
    type: 'ICO',
    helpNote: 'apCovertInvestigation'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_black_lists,
    value: 'processing_characteristics_black_lists',
    type: 'ICO',
    helpNote: 'apBlackLists'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_fight_against_fraud,
    value: 'processing_characteristics_fight_against_fraud',
    type: 'ICO',
    helpNote: 'apFightAgainstFraud'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_biometric_data,
    value: 'processing_characteristics_biometric_data',
    type: 'ICO',
    helpNote: 'apBiometricData'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_genetic_data,
    value: 'processing_characteristics_genetic_data',
    type: 'ICO',
    helpNote: 'apGeneticData'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_credit_scores,
    value: 'processing_characteristics_credit_scores',
    type: 'ICO',
    helpNote: 'apCreditScores'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_financial_situation,
    value: 'processing_characteristics_financial_situation',
    type: 'ICO',
    helpNote: 'apFinancialSituation'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_health_data,
    value: 'processing_characteristics_health_data',
    type: 'ICO',
    helpNote: 'apHealthData'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_collaborative_partnerships,
    value: 'processing_characteristics_collaborative_partnerships',
    type: 'ICO',
    helpNote: 'apCollaborativePartnerships'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_camera_surveillance,
    value: 'processing_characteristics_camera_surveillance',
    type: 'ICO',
    helpNote: 'apCameraSurveillance'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_flexible_camera_surveillance,
    value: 'processing_characteristics_flexible_camera_surveillance',
    type: 'ICO',
    helpNote: 'apFlexibleCameraSurveillance'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_control_of_employees,
    value: 'processing_characteristics_control_of_employees',
    type: 'ICO',
    helpNote: 'apControlOfEmployees'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_location_data,
    value: 'processing_characteristics_location_data',
    type: 'ICO',
    helpNote: 'apLocationData'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_communication_data,
    value: 'processing_characteristics_communication_data',
    type: 'ICO',
    helpNote: 'apCommunicationData'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_internet_of_things,
    value: 'processing_characteristics_internet_of_things',
    type: 'ICO',
    helpNote: 'apInternetOfThings'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profiling,
    value: 'processing_characteristics_profiling',
    type: 'ICO',
    helpNote: 'apProfiling'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_monitoring_and_influencing_of_behaviour,
    value: 'processing_characteristics_monitoring_and_influencing_of_behaviour',
    type: 'ICO',
    helpNote: 'apMonitoringAndInfluencingOfBehaviour'
  }
];

export const FrenchProcessingCharacteristicsBlacklist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_health_data_for_health_care_establishments,
    value:
      'processing_characteristics_health_data_for_health_care_establishments',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_vulnerable_ds_genetic_data,
    value: 'processing_characteristics_vulnerable_ds_genetic_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profiling_for_hr_purposes,
    value: 'processing_characteristics_profiling_for_hr_purposes',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_systematic_employee_monitoring,
    value: 'processing_characteristics_systematic_employee_monitoring',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_social_and_health_alerts_and_reports,
    value: 'processing_characteristics_social_and_health_alerts_and_reports',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_professional_alerts_and_reports,
    value: 'processing_characteristics_professional_alerts_and_reports',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_health_data_for_data_warehouse_or_register,
    value:
      'processing_characteristics_health_data_for_data_warehouse_or_register',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profiling_resulting_in_contract_termination,
    value:
      'processing_characteristics_profiling_resulting_in_contract_termination',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_personal_data_in_verified_contractual_breaches,
    value:
      'processing_characteristics_personal_data_in_verified_contractual_breaches',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profiling_data_from_external,
    value: 'processing_characteristics_profiling_data_from_external',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_vulnerable_ds_biometric_data,
    value: 'processing_characteristics_vulnerable_ds_biometric_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_social_housing_application_evaluation,
    value: 'processing_characteristics_social_housing_application_evaluation',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_medico_social_care,
    value: 'processing_characteristics_medico_social_care',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_large_scale_location_data,
    value: 'processing_characteristics_large_scale_location_data',
    type: 'ICO'
  }
];
export const FrenchProcessingCharacteristicsWhitelist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_hr_purposes_without_profiling,
    value: 'processing_characteristics_hr_purposes_without_profiling',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_supplier_relationship_management,
    value: 'processing_characteristics_supplier_relationship_management',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_municipality_electoral_register_management,
    value:
      'processing_characteristics_municipality_electoral_register_management',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_working_committee_and_council_management,
    value:
      'processing_characteristics_working_committee_and_council_management',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_non_sensitive_member_and_donor_data,
    value: 'processing_characteristics_non_sensitive_member_and_donor_data',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_health_data_for_individual_health_care_professional,
    value:
      'processing_characteristics_health_data_for_individual_health_care_professional',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_lawyers_for_individual_practice,
    value: 'processing_characteristics_lawyers_for_individual_practice',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_clerks_of_commercial_courts_for_their_activity,
    value:
      'processing_characteristics_clerks_of_commercial_courts_for_their_activity',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_notaries_for_notarial_activity,
    value: 'processing_characteristics_notaries_for_notarial_activity',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_school_management_and_childhood_services,
    value:
      'processing_characteristics_school_management_and_childhood_services',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_non_sensitive_data_for_physical_access_control,
    value:
      'processing_characteristics_non_sensitive_data_for_physical_access_control',
    type: 'ICO'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_breathalyser_test_data_for_transport_law,
    value:
      'processing_characteristics_breathalyser_test_data_for_transport_law',
    type: 'ICO'
  }
];

export const SpanishProcessingCharacteristicsBlacklist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profiling_or_evaluation,
    value: 'processing_characteristics_profiling_or_evaluation',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_automated_decision_making,
    value: 'processing_characteristics_automated_decision_making',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_observation_monitoring_supervision_related,
    value:
      'processing_characteristics_observation_monitoring_supervision_related',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_special_category_criminal_financial_data,
    value:
      'processing_characteristics_special_category_criminal_financial_data',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_biometric_data,
    value: 'processing_characteristics_biometric_data',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_genetic_data,
    value: 'processing_characteristics_genetic_data',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_use_of_large_scale_data,
    value: 'processing_characteristics_use_of_large_scale_data',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_matching_data_for_different_purposes_or_by_different_controllers,
    value:
      'processing_characteristics_matching_data_for_different_purposes_or_by_different_controllers',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_vulnerable_individuals_or_social_exclusion_risk,
    value:
      'processing_characteristics_vulnerable_individuals_or_social_exclusion_risk',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_new_technologies_or_innovative_use_of_established_technologies,
    value:
      'processing_characteristics_new_technologies_or_innovative_use_of_established_technologies',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_denial_of_service,
    value: 'processing_characteristics_denial_of_service',
    type: 'AEPD'
  }
];

export const SpanishProcessingCharacteristicsWhitelist = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_authorized_by_supervisory_body_guideline_or_decision,
    value:
      'processing_characteristics_processing_authorized_by_supervisory_body_guideline_or_decision',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_approved_codes_of_conduct_dpia_measures_implemented,
    value:
      'processing_characteristics_approved_codes_of_conduct_dpia_measures_implemented',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_legal_task_public_interest_dpia_performed,
    value:
      'processing_characteristics_legal_task_public_interest_dpia_performed',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_by_self_employed_personnel,
    value: 'processing_characteristics_processing_by_self_employed_personnel',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_for_sme_internal_administration_personnel,
    value:
      'processing_characteristics_processing_for_sme_internal_administration_personnel',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_by_multi_occupancy_property_owners_associations,
    value:
      'processing_characteristics_processing_by_multi_occupancy_property_owners_associations',
    type: 'AEPD'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_of_associates_members_data_by_colleges_and_npos,
    value:
      'processing_characteristics_processing_of_associates_members_data_by_colleges_and_npos',
    type: 'AEPD'
  }
];

export const GermanProcessingCharacteristicsTypes = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_biometric_data_subject_identify,
    value: 'processing_characteristics_biometric_data_subject_identify',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_genetic_data,
    value: 'processing_characteristics_genetic_data',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_location_data,
    value: 'processing_characteristics_location_data',
    riskValue: 0
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_data_subject_social_official_secrecy,
    value: 'processing_characteristics_data_subject_social_official_secrecy',
    riskValue: 0
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_matching_or_combining_datasets,
    value: 'processing_characteristics_matching_or_combining_datasets',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_electronic_recording_personal_data_in_public,
    value:
      'processing_characteristics_electronic_recording_personal_data_in_public',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_transfer_personal_data_to_evaluate,
    value: 'processing_characteristics_transfer_personal_data_to_evaluate',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_personal_data_evaluate_work_activity,
    value:
      'processing_characteristics_processing_personal_data_evaluate_work_activity',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_profile_network_of_personal_relationships,
    value:
      'processing_characteristics_profile_network_of_personal_relationships',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_discovering_previously_unknown_connections,
    value:
      'processing_characteristics_discovering_previously_unknown_connections',
    riskValue: 1
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_artificial_intelligence_to_process_personal_data,
    value:
      'processing_characteristics_artificial_intelligence_to_process_personal_data',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_signals_to_determine_movement_person,
    value: 'processing_characteristics_signals_to_determine_movement_person',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_automated_evaluation_of_recordings,
    value: 'processing_characteristics_automated_evaluation_of_recordings',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_creation_of_profiles_on_movement,
    value: 'processing_characteristics_creation_of_profiles_on_movement',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_anonymisation_of_special_personal_data,
    value: 'processing_characteristics_anonymisation_of_special_personal_data',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_of_special_personal_data_non_recurring,
    value:
      'processing_characteristics_processing_of_special_personal_data_non_recurring',
    riskValue: 2
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_processing_of_performance_person,
    value: 'processing_characteristics_processing_of_performance_person',
    riskValue: 2
  }
];

export const ChineseProcessingCharacteristicsTypes = [
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_sensitive_personal_info,
    value: 'processing_characteristics_sensitive_personal_info'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_personal_info_conduct_automated_decision_making,
    value:
      'processing_characteristics_personal_info_conduct_automated_decision_making'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_personal_info_abroad,
    value: 'processing_characteristics_personal_info_abroad'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_other_personal_info_influence_individual,
    value: 'processing_characteristics_other_personal_info_influence_individual'
  },
  {
    label:
      processingCharacteristicsTranslations.processing_characteristics_disclosing_personal_info,
    value: 'processing_characteristics_disclosing_personal_info'
  }
];

export const filterMenu = [
  {
    label: recordTranslations.preAssessment,
    value: 'preAssessment',
    disable: false,
    optionalChapter: false
  },
  {
    label: recordTranslations.configuration,
    value: 'configuration',
    disable: false,
    optionalChapter: false
  },
  {
    label: pluralTerms('personalDataLinks'),
    value: 'group',
    disable: true,
    optionalChapter: false
  },
  {
    label: recordTranslations.processingDescription,
    value: 'processingDescription',
    disable: true,
    optionalChapter: false
  },
  {
    label: recordTranslations.legalQualifications,
    value: 'LegalQualifications',
    disable: true,
    optionalChapter: true,
    subMenu: []
  },
  {
    label: recordTranslations.necessityProportionality,
    value: 'NecessityAndProportionality',
    disable: true,
    optionalChapter: true
  },
  {
    label: recordTranslations.dataSubjectRights,
    value: 'DataSubjectRights',
    disable: true,
    optionalChapter: true
  },
  {
    label: recordTranslations.securityControls,
    value: 'SecurityControls',
    disable: true,
    optionalChapter: true
  },
  {
    label: recordTranslations.threatImpact,
    value: 'ImpactAssessment',
    disable: true,
    optionalChapter: true
  },
  {
    label: recordTranslations.accountability,
    value: 'Accountability',
    disable: true,
    optionalChapter: true
  },
  {
    label: recordTranslations.summary,
    value: 'Summary',
    disable: false,
    optionalChapter: false
  }
];
export const categories = {
  necessityAndProportionality: [
    'purposes',
    'purposesOfTransfer',
    'processingGrounds',
    'personalDataItems',
    'retentionTerms',
    'transferGrounds',
    'qualityControls'
  ],
  dataSubjectRights: [
    'informationRights',
    'consentRights',
    'accessRights',
    'objectionRights',
    'deletionRights',
    'dataPortabilityRights'
  ],
  securityControls: [
    'technicalSecurityMeasures',
    'organisationalSecurityMeasures',
    'contractualSecurityMeasures'
  ],
  impactAssessment: ['confidentiality', 'integrity', 'availability']
};
export const summaryCategories = [
  'personalDataItems',
  'retentionTerms',
  'processingGrounds',
  'purposes',
  'purposesOfTransfer',
  'transferGrounds',
  'qualityControls',
  'informationRights',
  'consentRights',
  'accessRights',
  'objectionRights',
  'deletionRights',
  'dataPortabilityRights',
  'technicalSecurityMeasures',
  'organisationalSecurityMeasures',
  'contractualSecurityMeasures'
];

export const chapters = [
  'preAssessment',
  'necessityAndProportionality',
  'dataSubjectRights',
  'securityControls',
  'impactAssessment',
  'accountability'
];

export const getModifiedChapterName = (item) =>
  ({
    preAssessment: 'PreAssessment',
    necessityAndProportionality: 'NecessityAndProportionality',
    dataSubjectRights: 'DataSubjectRights',
    securityControls: 'SecurityMeasures',
    impactAssessment: 'ThreatImpactAssessment',
    accountability: 'Accountability'
  }[item]);