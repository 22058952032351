
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  dataTransferAssessmentMethodology:
  <FormattedMessage
    id="TIA.dataTransferAssessmentMethodology"
    defaultMessage="Data Transfer Assessment Methodology"
  />,
  dataTransferAssessmentMethodologyDesc:
  <FormattedMessage
    id="TIA.dataTransferAssessmentMethodologyDesc"
    defaultMessage="Standardised model for assessing appropriate safeguards for
    transfers of personal data from the EEA/UK to third party countries"
  />,
  poweredBy:
  <FormattedMessage
    id="TIA.poweredBy"
    defaultMessage="Powered by"
  />,
  purposeForTheMethodology:
  <FormattedMessage
    id="TIA.purposeForTheMethodology"
    defaultMessage="Purpose for the methodology"
  />,
  purposeForTheMethodologyDesc:
  <FormattedMessage
    id="TIA.purposeForTheMethodologyDesc"
    defaultMessage="<newline>The purpose of the methodology is to help organisations identify and manage the privacy
    risks associated with the transfer of personal data regulated by the GDPR / UK GDPR  to third countries
    (i.e. those outside the EEA / UK) which do not benefit from an adequacy decision.</newline><newline>
    The methodology has been developed in response to the July 2020 CJEU Schrems II case, taking into account
    subsequent recommendations of the EDPB 01/2020 on measures that supplement transfer tools to ensure
    compliance with the EU level of protection of personal data (the “EDPB Recommendations”). In summary,
    these requirements anticipate that transfers to third countries using Standard Contractual Clauses (SCCs)
    (or other appropriate safeguards listed within Article 46 GDPR )  will only be valid if the data exporter
    can verify, on a case by case basis, that the overall level of protection for personal data associated with
    the transfer, taking into account the specific circumstances of the transfer and any supplementary
    measures provided alongside the SCCs, does not undermine the level of protection that the SCCs seek
    to guarantee.  Any such assessment is expected to specifically consider whether the laws and practices
    in the third country provide a level of protection that is essentially equivalent to that afforded in
    the EEA / UK, and that enforceable legal rights and effective legal remedies are available for data subjects.</newline>
    The methodology is intended to be used to generate assessments which provide an accurate, consistent,
    auditable and defensible basis to support a decision as to whether or not, on a case by case basis,
    sufficient safeguards are in place to be able to proceed with a proposed transfer. The assessment of
    the data transfer shall be repeated at appropriate intervals to monitor if there have been or there
    will be any developments that may affect it.
    "
    values={{
      newline: text => (
        <p>{text}</p>)
    }}
  />,
  outcomeOfTheMethodology:
  <FormattedMessage
    id="TIA.outcomeOfTheMethodology"
    defaultMessage="Outcome of the methodology"
  />,
  specificCircumstance: <FormattedMessage
    id="Records.tia.specificCircumstanceHeader"
    defaultMessage="Specific circumstance"
  />,
  outcomeOfTheMethodologyDesc:
  <FormattedMessage
    id="TIA.outcomeOfTheMethodologyDesc"
    defaultMessage="<newline>After carrying out data mapping of all transfers of personal data to third
    countries located outside of the EEA, if the transfer is not towards a third country that ensures
    an adequate level of protection as per Article 45 GDPR, the methodology provides a basis to logically
    assess the level of safeguards in place for transfers of personal data to third countries consistent
    with the CJEU decision and EPDB Recommendations. The methodology follows a step by step approach,
    similar to the 6-step process referenced in the EDPB Recommendations, taking into account
    the following key factors:</newline><newline>
    Step 1: specific circumstances of the proposed data transfer (e.g. nature and types of data
    being transferred and the relevant countries involved)</newline>
    <newline>Step 2: the extent to which the pervasive laws and practices in the destination country provide
    appropriate protection to data subjects (essentially equivalent guarantees), considering:</newline>
    <li>the safeguards offered by local data privacy laws applicable to the processing of personal data,
    including analysis of local laws and practices</li>
    <li>the risks posed by wider laws and practices authorising public authorities to access or conduct
    surveillance on private information for national security or other reasons – including whether there are
    local laws and practices setting out circumstances in which a possible interference with
    fundamental privacy rights can be justified by the importance of the public interest objective,
    in line with the principles of proportionality and necessity - recognizing that laws and practices
    in some of these areas are likely to apply to specific sectors only</li>
    <li>the ease of access to judicial process to protect personal rights</li>
    <li>the role of local regulators and supervisory authorities in protecting data as independent
    bodies providing an effective and impartial oversight system</li>
    <li>the ability of individuals to raise complaints, appeal and enforce decisions ensuring
    that in cases of any infringement of local laws applicable to the processing of personal data,
    remedies  can be effectively enforced by individuals</li>
    <li>the impact of relevant international treaties and related commitments</li>
    <newline>Step 3: any relevant contractual, technical or organisational safeguards applied to the
    proposed transfer arrangements; and</newline>

    <newline>Step 4: the severity of the residual risk of harm a data subject may be exposed to
    following the transfer, and the likelihood of that harm arising in practice in light of the
    specific circumstances of the transfer.</newline>

    <newline>It is important to understand that whilst the methodology has been designed to align
    with the CJEU Schrems II decision and take account of the EDPB Recommendations, there are some
    differences in approach. In particular, Step 4 of the methodology allows consideration of the
    likelihood of harm to a data subject arising from a particular transfer as a means to mitigate
    risks identified in Step 2 in relation to the third country’s legal regime. This is consistent
    with the EDPB Recommendations expectations that, in circumstances where assessment of the legal
    regime indicates that the laws in the third country may impinge on the transfer tool’s
    contractual guarantee of an essentially equivalent level of protection, the transfer may
    proceed if the exporter is able to document comprehensively and objectively (with appropriate
    reliance on a number of sources of information), that the laws will not be applied in practice
    to the specific circumstances of the transfer.  Step 4 accommodates consideration of these
    practical factors into the assessment, but we stress that these need to be fully and carefully
    considered and note the EDPB view that reliance on this should not be a determining factor
    in allowing a transfer to proceed. Further, the methodology takes a more graduated view on how
    to assess the level of essential equivalent guarantees (Step 2) and adequacy of supplementary
    measures (Step 3) than anticipated in the EDPB Recommendations.</newline>

    <newline>These nuances in application are intentional to provide a methodology that balances
    legally defensible decision-making with the practicalities of carrying out a risk-based impact
    assessment.</newline>"
    values={{
      newline: text => (<p>{text}</p>),
      li: text => (
        <ul style={{ paddingLeft: '0px' }}>
          <li style={{ listStylePosition: 'outside', marginLeft: '1em' }}>{text}</li>
        </ul>)
    }}
  />,
  disclaimer:
  <FormattedMessage
    id="TIA.disclaimer"
    defaultMessage="Disclaimer"
  />,
  disclaimerDesc:
  <FormattedMessage
    id="TIA.disclaimerDesc"
    defaultMessage="
    <newline>This Data Transfer Assessment tool, which implements the methodology, has been designed
    to help professionals in the field of data protection and information governance assess risk
    associated with the export of personal data from the EU/UK to third countries, consistent with
    the principles in Article 46 GDPR. It is important to assess each data transfer on a case-by-case basis.
    The tool has not been designed and should not be used for assessing bulk transfers to a
    particular country.</newline>
    <newline>
    For each data transfer assessed by the tool, a risk score will be generated at Step 5 together
    with a recommendation on measures to take before proceeding with the proposed transfer. In cases
    of a high risk score, the recommendation will be that the proposed transfer should not take place.
    In any case where a high risk score is presented, professional advice should always be obtained
    before proceeding with a proposed transfer.
    </newline>
    <newline>The methodology implemented through this tool has been prepared based on law in the EEA / UK
    as at the date of version control noted on the front of this sheet. This remains a fast-evolving
    area of regulatory compliance with a range of emerging interpretations of the legal position by
    different data protection supervisory authorities in Member States and the UK. It is important to
    stress that the EDPB Recommendations if construed narrowly take a more conservative interpretation
    of the Schrems II judgment and this should be recognised when using the tool, as there remains
    significant risk that data protection supervisory authorities in one or more EEA Member States / UK may
    disagree with the findings generated by an assessment undertaken using the tool and / or restrict or
    prohibit transfers to a third country even where the tool indicates that the safeguards available are
    essentially equivalent to those in the EEA / UK.
    It is not advisable for users to make changes to the features of the tool
    (e.g. criteria / scoring model / weightings) as this may compromise the integrity of any assessment.
    If changes are applied, we strongly recommend maintaining a documented record of the rationale and approach.
    This will help demonstrate accountability in the event of any regulatory scrutiny.</newline>
    <newline>The tool should be used with full knowledge of these limitations, recognizing it is not intended
    to provide definitive legal analysis, but rather to provide a supportive decision-making tool that may
    assist in understanding risk associated with a transfer and to support associated decisions on how to proceed,
    consistent with best practice and the accountability principle enshrined in Article 24 of the GDPR.
    The tool is not a substitute for securing professional legal advice which should always be considered
    in appropriate cases.</newline>
    "
    values={{
      newline: text => (
        <p>{text}</p>)
    }}
  />,
  contractualMeasureUsed:
  <FormattedMessage
    id="TIA.contractualMeasureUsed"
    defaultMessage="Contractual measures used"
  />,
  technicalMeasureUsed:
  <FormattedMessage
    id="TIA.technicalMeasureUsed"
    defaultMessage="Technical measures used"
  />,
  organisationalMeasures:
  <FormattedMessage
    id="TIA.organisationalMeasures"
    defaultMessage="Organisational measures"
  />,
  contractualMeasures:
  <FormattedMessage
    id="TIA.contractualMeasures"
    defaultMessage="Contractual measures"
  />,
  technicalMeasures:
  <FormattedMessage
    id="TIA.technicalMeasures"
    defaultMessage="Technical measures"
  />,
  organisationalMeasureUsed:
  <FormattedMessage
    id="TIA.organisationalMeasureUsed"
    defaultMessage="Organisational measures used"
  />,
  contractualMeasureImpact:
  <FormattedMessage
    id="TIA.contractualMeasureImpact"
    defaultMessage="Assessment of contractual measures"
  />,
  technicalMeasureImpact:
  <FormattedMessage
    id="TIA.technicalMeasureImpact"
    defaultMessage="Assessment of technical measures"
  />,
  organisationalMeasureImpact:
  <FormattedMessage
    id="TIA.organisationalMeasureImpact"
    defaultMessage="Assessment of organisational measures"
  />,
  limitedProtection:
  <FormattedMessage
    id="TIA.Impact.limitedProtection"
    defaultMessage="Additional measures provide a limited increase in protection of personal data"
  />,
  highProtection:
  <FormattedMessage
    id="TIA.Impact.highProtection"
    defaultMessage="Additional measures provide a significant increase in the protection of personal data"
  />,
  decreaseProtection:
  <FormattedMessage
    id="TIA.Impact.decreaseProtection"
    defaultMessage="Additional safeguards reduce the protection of personal data"
  />,
  noAdditionalMeasures:
  <FormattedMessage
    id="TIA.noAdditionalMeasures"
    defaultMessage="No additional measures"
  />,
  limitedProtectionShort:
  <FormattedMessage
    id="TIA.limitedProtectionShort"
    defaultMessage="Limited protection"
  />,
  highProtectionShort:
  <FormattedMessage
    id="TIA.highProtectionShort"
    defaultMessage="High protection"
  />,
  decreaseProtectionShort:
  <FormattedMessage
    id="TIA.decreaseProtectionShort"
    defaultMessage="Decrease protection"
  />,
  contractualMeasureText:
  <FormattedMessage
    id="TIA.contactualMeasureText"
    defaultMessage={`Whether the parties have agreed additional contractual commitments, including commitments
    contained within the data transfer tool in place (e.g. within the SCCs - whether legacy or new - or the BCRs),
    commitments in line with those contained in the EDPB Recommendations, which enhance
    (or in any way undermine) / the guarantees offered in the SCCs and/or mitigate the risk posed by shortfalls
    within the destination country legal regime-examples may include:<newline>(i) a process to be applied when
    there is a subpoena/legal process requiring the importer to challenge or demand individual review of an order
    to the extent possible; </newline> <newline> (ii) an obligation to inform the exporter of the subpoena / legal
    process in a manner whereby the exporter an suspend the transfer/withdraw the data before a disclosure
    is made (to the extent not prohibited by law, e.g. an anti- tipping off law);</newline> <newline>
    (iii) an obligation to routinely report to the exporter that there have been no disclosure requests made by
    authorities in the preceding period('warrant canary');</newline>   <newline> (iv) requiring or requesting
    the requesting authority- to the extent permissible by law- to use a MLAT process;</newline> (v) reinforcing
    the data exporters right to conduct audits or inspections of the data processing facilities of the importer,
    on-site and/or remotely, to verify if data was disclosed to public authorities and under which conditions;
    </newline><newline>(vi) requiring the data importer to certify that: (1) it has not purposeful created
    backdoors or similar programming that could be used to access the systems used for the processing and/or
    any personal data, (2) it has not purposefully created or changed its business processes in a manner that
    facilitates access to personal   data or systems, and (3) that national law or government policy does not
    require the data importer to create or maintain backdoors or to facilitate access to personal data or systems
    or to be in possession or to hand over the encryption keys;</newline><newline>(vii) an obligation to
    provide compensation to data subjects for any material and non-material damage suffered in circumstances
    where the data importer disclosed personal data transferred in violation of the commitments contained
    under the chosen transfer tool.</newline> <newline>Similarly where there are unhelpful contractual provisions,
    this would have a negative effect on the score.</newline>`}
    values={{
      newline: text => (
        <div>{text}</div>)
    }}
  />,
  technicalMeasureText:
  <FormattedMessage
    id="TIA.technicalMeasureText"
    defaultMessage="The extent to which:
    <newline>- privacy enhancing controls have been adopted by the parties to mitigate risk, for example by
    seeking to anonymise or pseudonymise the personal data (e.g. where the sole means of re-identification
    are held only by the data exporter outside the destination country and Outside thee reach of the laws of
    the destination country), and /or otherwise preventing the importer from being able to disclose the personal
    data, such as encryption keys being kept only by the data exporter in another jurisdiction (bearing in mind
    that any form of root / admin access that may Circumvent such measures/ controls would likely render this variable
    as ineffective in terms of mitigation);</newline>
    <newline>Similarly where the measures act to facilitate disclosure (e.g. a policy to disclose without a court order,
    a policy to permit unfettered access to stored data, etc.) this would have a negative effect on the score</newline>"
    values={{
      newline: text => (
        <div>{text}</div>)
    }}
  />,
  organisationalMeasureText:
  <FormattedMessage
    id="TIA.organisationalMeasureText"
    defaultMessage="The extent to which:
    <newline> - organisational safeguards applied contribute to ensuring consistency in the protection
    of personal data or which otherwise provide additional   protective measures for data subjects,
    including those contained in the EDPB Recommendations on supplementary measures, for example:</newline>
    <newline>(i) adoption of internal policies with clear allocation of responsibilities for data transfers,
    reporting channels and standard operating procedures for cases of formal or informal requests;</newline>
    <newline>(ii) training procedures for personnel in charge of managing requests for access to personal data
    from public authorities;</newline>
    <newline>(iii) operating procedures to control public authority access requests to data, supported by a transparent
    disclosure request policy; </newline>
    <newline>(iv) access controls and confidentiality policies in place which are monitored with audits and enforced
    through disciplinary measures; </newline>
    <newline>(v) data security and data privacy policies, based on EU certification, self/co-regulatory schemes or codes
    of conduct or international standards such as ISO norms and best practices such as those published by
    ENISA</newline>
    <newline>Note that where the safeguards act to facilitate disclosure (e.g. a policy to disclose without a court
    order, a policy to permit unfettered access to stored data, etc.) this would have a negative effect
    on the score.</newline>"
    values={{
      newline: text => (
        <div>{text}</div>)
    }}
  />,
  regulationOnProcessingHeader: <FormattedMessage
    id="TIA.regulationOnProcessingHeader"
    defaultMessage="Regulation on the processing of personal data"
  />,
  regulationOnProcessingDescription: <FormattedMessage
    id="TIA.regulationOnProcessingDescription"
    defaultMessage="The extent to which local laws offer legal clear, precise and accessible safeguards to
    the processing of personal data equivalent to the protections offered in the EEA/UK. This will include
    an analysis of the local laws, practices, and data protection law framework, including constitutional rights
    to privacy and how those laws apply both to the data importer but also third parties (e.g. requiring to disclose
    data to law enforcement/public authorities or authorizing access by such authorities) who may seek to secure access
    to the data following transfer, as well as the applicable limitations and safeguards.
    <newline>In this context, note the EEA / UK has a developed law that specifically recognises legal rights
    to protection of 'personal data' consistent with the principles of data protection set out in OECD Convention
    108+.</newline>"
    values={{
      newline: text => (
        <div>{text}</div>)
    }}
  />,
  regulationOnAccessHeader: <FormattedMessage
    id="TIA.regulationOnAccessHeader"
    defaultMessage="Regulation on the access of personal data"
  />,
  regulationOnAccessDescription: <FormattedMessage
    id="TIA.regulationOnAccessDescription"
    defaultMessage="The extent to which the level of access legally permitted and conducted in practice by public
    authorities to personal data (e.g. to secure disclosure of, or conduct surveillance on, private information
    for national security purposes or other reasons) is subject to safeguards equivalent to that within the EEA/UK.
    This will consider specifically whether the right of public authorities to access data is:
    <newline>(i) underpinned by a legal framework that is publicly available and sufficiently clear (conducted ‘in
    accordance with law’),</newline>
    <newline>(ii) carried out in pursuit of legitimate aims which are necessary in a democratic society
    (‘proportionate’) (noting that proportionality involves balancing any interference with fundamental
    privacy rights with what are necessary and important public interests), and </newline>
    <newline>(iii) subject to adequate and effective oversight from courts or other independent authorities</newline>

    The assessment will consider both pervasive surveillance activity (across the destination country as a whole)
    and whether access can in practice be exercised by public authorities in light of legislation, legal powers,
    technical, financial and human resources at their disposal and of reported precedents."
    values={{
      newline: text => (
        <div>{text}</div>)
    }}
  />,
  internationalTreatiesDescription: <FormattedMessage
    id="TIA.internationalTreatiesDescription"
    defaultMessage="The extent to which the receiving countries have concluded international treaties and related
    commitments on handling of personal data to support the safeguarding of data — this will include consideration
    of the existence of both:
    <newline> (i) international treaties that relate to the protection of data generally consistent with
    principles enshrined in EEA/UK law, and</newline>
    <newline>(ii) any specific arrangements concluded to provide safeguards in relation
    to country to country transfers (e.g. UK-U.S. Bilateral Data Access Agreement which brings into effect the
   'quashing' provisions of 18 USG § 2703(h)(2))</newline> "
    values={{
      newline: text => (
      <div>{text}</div>)
    }}
  />,
  regulatorySupervisionDescription: <FormattedMessage
    id="TIA.regulatorySupervisionDescription"
    defaultMessage="The extent to which courts, regulators and/or supervisory authorities enforce the rule of law and/or
  rights guaranteed in relation to the protection of data in an independent and effective manner, with evidence of
  meaningful resources and enforcement activity. "
  />,
  rightsOfRedressDescription: <FormattedMessage
    id="TIA.rightsOfRedressDescription"
    defaultMessage="The extent to which individuals can easily and effectively enforce rights and seek redress by
    raising complaints, claims and / or appeal and enforce decisions in relation to both data protection infringements
    and public disclosure / surveillance activity through judicial and/or administrative processes (e.g. help from local
    data protection authorities) including whether redress mechanisms can be effectively applied in practice and are
    not thwarted by local laws and/or practices. This section will also consider whether data subjects can secure self
    help remedies — e.g. right to secure access to or require erasure of personal data files, and whether the breach of
    local laws can be effectively invoked and relied on by individuals.  "
  />,
  scoring1: <FormattedMessage
    id="TIA.regulationOnProcessingScoring1"
    defaultMessage="High level of sageguards in place, essentially equivalent to the level available in the EU."
  />,
  scoring2: <FormattedMessage
    id="TIA.regulationOnProcessingScoring2"
    defaultMessage="High level of safeguards in place, but below the level of those available in the EU."
  />,
  scoring3: <FormattedMessage
    id="TIA.regulationOnProcessingScoring3"
    defaultMessage="Some safeguards in place, but materially below the level of those available in the EU."
  />,
  scoring4: <FormattedMessage
    id="TIA.regulationOnProcessingScoring4"
    defaultMessage="Very limited safeguards in place, significantly below the level of the EU."
  />,
  scoring5: <FormattedMessage
    id="TIA.regulationOnProcessingScoring5"
    defaultMessage="No safeguards in place."
  />,
  scoreBreakdown: <FormattedMessage
    id="TIA.scoreBreakdown"
    defaultMessage="Score breakdown"
  />,
  energy: <FormattedMessage
    id="TIA.sector.energy"
    defaultMessage="Energy"
  />,
  materials: <FormattedMessage
    id="TIA.sector.materials"
    defaultMessage="Materials"
  />,
  industrials: <FormattedMessage
    id="TIA.sector.industrials"
    defaultMessage="Industrials"
  />,
  hospitality: <FormattedMessage
    id="TIA.sector.hospitality"
    defaultMessage="Hospitality"
  />,
  healthCare: <FormattedMessage
    id="TIA.sector.healthCare"
    defaultMessage="Health care"
  />,
  financials: <FormattedMessage
    id="TIA.sector.financials"
    defaultMessage="Financials"
  />,
  informationTechnology: <FormattedMessage
    id="TIA.sector.informationTechnology"
    defaultMessage="Information technology"
  />,
  telecommunicationServices: <FormattedMessage
    id="TIA.sector.telecommunicationServices"
    defaultMessage="Telecommunication services"
  />,
  utilities: <FormattedMessage
    id="TIA.sector.utilities"
    defaultMessage="Utilities"
  />,
  realEstate: <FormattedMessage
    id="TIA.sector.realEstate"
    defaultMessage="Real estate"
  />,
  government: <FormattedMessage
    id="TIA.sector.government"
    defaultMessage="Government"
  />,
  logistics: <FormattedMessage
    id="TIA.sector.logistics"
    defaultMessage="Logistics"
  />,
  transferGroundWarning:
  <FormattedMessage
    id="TIA.TranferGround.warning"
    defaultMessage="NOTE: As adequacy decision is being used as a transfer ground, it is not a legal
    requirement to carry out a transfer impact assessment to
    regions that are covered by GDPR 45(1)"
  />,
  defineOnwardTransfer:
  <FormattedMessage
    id="TIA.defineOnwardTransfer"
    defaultMessage="Define onwards transfer"
  />,
  onwardTransferoccurs:
  <FormattedMessage
    id="TIA.defineOnwardTransfer"
    defaultMessage="Onward transfer occurs"
  />,
  onwardTransfers:
  <FormattedMessage
    id="TIA.OnwardTransfer"
    defaultMessage="Onward transfers"
  />,
  selectRole:
  <FormattedMessage
    id="TIA.selectRole"
    defaultMessage="Select role"
  />,
  selectYesOrNo:
  <FormattedMessage
    id="TIA.selectYesOrNo"
    defaultMessage="Select Yes/No"
  />,
  selectSector:
  <FormattedMessage
    id="TIA.selectSector"
    defaultMessage="Select sector"
  />,
  provideRelationshipCounterparty:
  <FormattedMessage
    id="TIA.provideRelationshipCounterparty"
    defaultMessage="Provide relationship of counterparty"
  />
};
