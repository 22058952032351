import { connect } from 'react-redux';
import AccessRightsSelector from './component';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  dsrUsers: state.dsrDetail.get('users'),
  isFetching: state.dsrDetail.get('isFetching'),
  userSearchResults: state.dsrDetail.get('userSearchResults'),
  userPosition: state.dsrDetail.get('userPosition'),
  tenantusers: state.organisationUser.list.get('organisationUsers'),
  position: state.organisationUser.list.get('position'),
  error: state.tenantusers.get('error'),
  currentUser: state.user.get('profile').get('id'),
  searchResults: state.tenantusers.get('items')
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (
    isDsr,
    userHierarchy,
    dataItemType,
    selectedOrganisations,
    jurisdictions
  ) => {
    if (isDsr)
      dispatch({
        type: 'DSR:USERS:LIST:REQUEST',
        dataItemType,
        selectedOrganisations,
        jurisdictions
      });
    else
      dispatch({
        type: 'ORGANISATION_USERS:LIST:REQUEST',
        userHierarchy,
        status: 'Registered'
      });
  },
  getNextUsers: (
    isDsr,
    position,
    userHierarchy,
    dataItemType,
    selectedOrganisations,
    jurisdictions
  ) => {
    if (isDsr)
      dispatch({
        type: 'DSR:USERS:LIST:NEXT:REQUEST',
        dataItemType,
        selectedOrganisations,
        position,
        jurisdictions
      });
    else
      dispatch({
        type: 'ORGANISATION_USERS:LIST:NEXT_FETCH',
        position,
        userHierarchy,
        status: 'Registered'
      });
  },
  searchUsers: (
    isDsr,
    searchParams,
    userHierarchy,
    dataItemType,
    selectedOrganisations,
    jurisdictions
  ) => {
    if (isDsr)
      dispatch({
        type: 'DSR:USERS:LIST:SEARCH:REQUEST',
        dataItemType,
        selectedOrganisations,
        searchParams,
        jurisdictions
      });
    else
      dispatch({
        type: 'ACCESS_RIGHTS:LIST:SEARCH_INIT',
        searchParams,
        userHierarchy
      });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessRightsSelector);
export { reducers };
