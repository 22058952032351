import { connect } from 'react-redux';

import CustomTemplates from './customTemplates';

let recordType;
const mapStateToProps = (state) => {
  recordType = state.privacyRecords.filters.get('currentFilter');
  return {
    templates: state.privacyRecords.list.get('items'),
    pageSearchText: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).pageSearchText,
    sortOrder: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).sortOrder,
    sortKey: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).sortKey,
    filterColumn: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).filterColumn,
    filteredOn: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType)
      .filteredOn.toJS(),
    position: state.privacyRecords.list.get('position'),
    isFetching: state.privacyRecords.list.get('isFetching'),
    error: state.privacyRecords.list.get('error').toJS(),
    locale: state.intl.locale,
    tenantLocale: state.login.get('tenantLocale'),
    userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
    isGlobal: state.home.get('isGlobal')
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInit: (recordType, recordLayoutId, isGlobal) => {
    dispatch({ type: 'PRIVACY_RECORDS:FILTER:RESET', recordType });
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
      recordType,
      recordLayoutId,
      isCustom: true,
      isTemplateMode: true,
      isGlobal
    });
  },
  getNextData: (position, recordLayoutId, rowCount) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount,
      recordLayoutId,
      isTemplateMode: true
    });
  },
  onChooseFilter: (filterParams, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType,
      recordLayoutId,
      isTemplateMode: true
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  deleteRecordTemplate: (recordId, recordType) => {
    dispatch({
      type: 'PRIVACY_RECORD:ITEM:DELETE',
      recordId,
      recordType,
      isTemplateMode: true
    });
  },
  updateTemplateStatus: (
    templateId,
    recordType,
    recordLayoutId,
    status,
    currentFilter,
    isGlobal
  ) => {
    dispatch({
      type: 'RECORD_TEMPLATE:STATUS:UPDATE',
      templateId,
      recordType,
      recordLayoutId,
      status,
      currentFilter,
      isGlobal
    });
  },
  copyRecordTemplate: (
    data,
    templateId,
    recordType,
    locale,
    tenantLocale,
    formatMessage
  ) => {
    if (recordType === 'CUSTOM') {
      dispatch({
        type: 'CUSTOM_RECORD:ITEM',
        templateId,
        mode: 'COPY',
        locale,
        tenantLocale,
        formatMessage,
        isTemplateMode: true
      });
    } else
      dispatch({
        type: `${recordType}:DETAIL:UPSERT`,
        data,
        templateId,
        isTemplateMode: true
      });
  },
  restTemplatesFilters: (recordType) => {
    dispatch({ type: 'PRIVACY_RECORDS:FILTER:RESET', recordType });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplates);
