import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NavigationMoreVert from '@material-ui/icons/MoreVert';

import ArrowTooltip from '@packages/components/tooltip';

const ActionSelector = (props) => {
  const {
    actionChooserStyle,
    actionItems,
    isDisabled,
    isHidden,
    onActionHandler,
    rowIndex,
    currentActiveRow,
    actionChooserIcon
  } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const getMenuItems = () => {
    const items = actionItems.map((item) => {
      let customMenuStyle = '';
      const hidden = isHidden ? isHidden(props, rowIndex, item.action) : false;
      customMenuStyle = hidden === true ? 'menuItemHidden' : '';

      const disabled = isDisabled
        ? isDisabled(props, rowIndex, item.action)
        : false;
      customMenuStyle += disabled === true ? ' menuItemDisabled' : '';

      return (
        <ArrowTooltip
          title={typeof disabled === 'boolean' ? item.primaryText : disabled}
        >
          <MenuItem
            id="menu-item"
            key={`${item.action}`}
            className={customMenuStyle}
            disabled={disabled}
            onClick={() => handleMenuItemTouchTap(item.action)}
          >
            {item.primaryText}
          </MenuItem>
        </ArrowTooltip>
      );
    });
    return items;
  };

  const getAptActionChooserIcon = () => {
    if (actionChooserIcon) {
      if (currentActiveRow === rowIndex) {
        return actionChooserIcon;
      } else if (currentActiveRow === -1) {
        return <NavigationMoreVert />;
      }
      return '';
    }
    return <NavigationMoreVert />;
  };

  const handleTouchTap = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleMenuItemTouchTap = (action) => {
    handleRequestClose();
    onActionHandler(this, rowIndex, action);
  };

  const menuItems = open ? getMenuItems() : [];

  return (
    <div>
      <IconButton
        id="button"
        style={actionChooserStyle}
        onClick={handleTouchTap}
      >
        {getAptActionChooserIcon()}
      </IconButton>
      {open && (
        <Menu
          id="popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleRequestClose}
        >
          {menuItems}
        </Menu>
      )}
    </div>
  );
};

ActionSelector.propTypes = {
  actionChooserStyle: PropTypes.shape({}),
  actionItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDisabled: PropTypes.func,
  isHidden: PropTypes.func,
  onActionHandler: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  currentActiveRow: PropTypes.number,
  actionChooserIcon: PropTypes.shape({})
};

ActionSelector.defaultProps = {
  actionChooserStyle: {},
  isHidden: (e) => e,
  isDisabled: null,
  actionChooserIcon: undefined,
  currentActiveRow: 0
};

export default ActionSelector;
