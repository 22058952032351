import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TenantList from './components/list';
import TenantsHeader from './components/header';

export const TenantListLayout = (props) => {
  useEffect(() => {
    if (props.onMount) {
      props.onMount(props.isOnlyPartner);
    }
  }, []);

  return (
    <div style={{ position: 'relative', height: '90%' }}>
      <div
        style={{
          position: 'absolute',
          left: 25,
          right: 25,
          top: 10,
          bottom: 0,
          margin: '0px 25px'
        }}
      >
        <div>
          <TenantsHeader
            hasCreateTenantPermission={props.hasCreateTenantPermission}
            isSuperAdmin={props.isSuperAdmin}
          />
        </div>
        <div style={{ height: '90%' }}>
          <TenantList
            hasCreateTenantPermission={props.hasCreateTenantPermission}
            isOnlyPartner={props.isOnlyPartner}
            isSuperAdmin={props.isSuperAdmin}
          />
        </div>
      </div>
    </div>
  );
};

TenantListLayout.propTypes = {
  isOnlyPartner: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onMount: PropTypes.func,
  hasCreateTenantPermission: PropTypes.bool
};

TenantListLayout.defaultProps = {
  onMount: (e) => e,
  isOnlyPartner: false,
  isSuperAdmin: true,
  hasCreateTenantPermission: true
};

TenantListLayout.contextTypes = {
  router: PropTypes.object.isRequired
};

TenantListLayout.displayName = 'TenantListLayout';
TenantListLayout.path = 'tenants';
TenantListLayout.title = 'Tenants';

export default TenantListLayout;
