import registry from 'app-registry';
import { createMuiTheme } from '@material-ui/core/styles';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

export const getCurrentRGB = (whiteLabelling = {}) => {
  if (whiteLabelling.get('rgb')) {
    const rgb = whiteLabelling.get('rgb');
    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  }
  return registry.exists('theme').palette.accent2Color;
};

export const getV0WhiteLabelledTheme = (whiteLabelling) => {
  let currentTheme = registry.exists('theme') ? registry.get('theme') : {};
  if (whiteLabelling && whiteLabelling.get('rgb')) {
    const rgb = whiteLabelling.get('rgb');
    const rgbColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    currentTheme = {
      ...currentTheme,
      primaryColor: rgbColor,
      palette: {
        ...currentTheme.palette,
        accent2Color: rgbColor,
        passedColor: rgbColor
      },
      appBar: {
        ...currentTheme.appBar,
        backgroundColor: rgbColor,
        color: rgbColor
      },
      checkbox: {
        ...currentTheme.checkbox,
        checkedColor: rgbColor
      },
      radioButton: {
        ...currentTheme.radioButton,
        checkedColor: rgbColor
      },
      datePicker: {
        ...currentTheme.datePicker,
        selectColor: rgbColor,
        headerColor: rgbColor
      },
      timePicker: {
        ...currentTheme.timePicker,
        accentColor: rgbColor,
        headerColor: rgbColor
      },
      raisedButton: {
        ...currentTheme.raisedButton,
        primaryColor: rgbColor
      },
      flatButton: {
        ...currentTheme.flatButton,
        primaryTextColor: rgbColor
      },
      svgIcon: {
        ...currentTheme.svgIcon,
        color: rgbColor
      },
      icon: {
        ...currentTheme.icon,
        backgroundColor: rgbColor
      },
      card: {
        ...currentTheme.card,
        titleColor: rgbColor
      }
    };
  }
  return getMuiTheme(currentTheme);
};

export const getV1WhiteLabelledTheme = (whiteLabelling) => {
  let currentTheme = registry.exists('v1theme') ? registry.get('v1theme') : {};
  if (whiteLabelling && whiteLabelling.get('rgb')) {
    const rgb = whiteLabelling.get('rgb');
    const rgbColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    const getColorWithAlpha = (alpha) =>
      `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`;
    currentTheme = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        primary: {
          ...currentTheme.palette.primary,
          main: rgbColor,
          dark: rgbColor
        }
      },
      utils: {
        getColorWithAlpha
      }
    };
  }
  return createMuiTheme(currentTheme);
};

export const getDefaultTheme = () =>
  registry.exists('theme') ? registry.get('theme') : {};
