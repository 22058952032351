import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';
import { transformGroundResponse } from '../../../utils';

export default function* fetchAssociatedTransfergrounds(action) {
  const { transferGroundType } = action;
  const locale = yield select(state => state.intl.locale);
  try {
    const response = yield registry.get('request')
      .get(`/v1/masterdata/legalgrounds?filter=groundType=${transferGroundType}&filter=language%3D${locale}`, null);

    const items = transformGroundResponse(response.body);

    yield put({ type: 'TRANSFER_GROUNDS:RECORDS:LIST:FETCH:SUCCESS', items });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'TRANSFER_GROUNDS:RECORDS:LIST:FETCH:FAIL', error: err.message });
  }
}
