import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

const CustomStakeholderSelector = (props) => {
  const {
    hintTextLabel,
    stakeholders,
    initCustomStakeholders,
    onSearch,
    getNextData,
    position,
    onSelect,
    removeItem,
    stakeholderEntityId
  } = props;

  const handleOnFocus = () => {
    if (initCustomStakeholders) {
      initCustomStakeholders(stakeholderEntityId);
    }
  };

  const handleSearch = (searchParams) => {
    if (onSearch) {
      onSearch(searchParams, stakeholderEntityId);
    }
  };

  const handleNextData = () => {
    getNextData(position, stakeholderEntityId);
  };

  return (
    <div>
      <ValueSelector
        {...props}
        onSelect={onSelect}
        removeItem={removeItem}
        valueAttr="name"
        dataItems={stakeholders}
        searchResults={stakeholders}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        onFocus={handleOnFocus}
        title={
          <FormattedMessage
            id="MultipleControllerDialog.header"
            description="Select controller Header"
            defaultMessage="Select controllers"
          />
        }
      />
    </div>
  );
};

CustomStakeholderSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  removeItem: PropTypes.func,
  onSelect: PropTypes.func,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  initCustomStakeholders: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  stakeholders: PropTypes.instanceOf(Immutable.List),
  onSearch: PropTypes.func,
  stakeholderEntityId: PropTypes.string
};

CustomStakeholderSelector.defaultProps = {
  hintTextLabel: null,
  fields: {},
  initCustomStakeholders: (e) => e,
  onSelect: (e) => e,
  removeItem: (e) => e,
  getNextData: (e) => e,
  position: 0,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  stakeholders: Immutable.List(),
  stakeholderEntityId: ''
};

export default CustomStakeholderSelector;
