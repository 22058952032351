import React from 'react';
import registry from 'app-registry';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';

export const validFileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg',
  'bmp', 'png', 'gif', 'ppt', 'pptx', 'pps', 'txt', 'rtf', 'odt', 'zip', 'msg'];

export const validateFile = (file) => {
  const config = registry.get('config');
  const fileName = file.name;
  const ext = fileName.match(/\.([^.]+)$/)[1];
  if (!validFileExtensions.find(fileExt => (fileExt === ext.toLowerCase()))) {
    return { isValid: false,
      validateMsg: <FormattedMessage
        id="FileUtils.unsupportedType"
        description="Unsupported file type"
        defaultMessage="File type is not supported."
      /> };
  }

  const { maxFileLength, maxFileSize } = config.file;

  // Limit length of file name
  if (fileName.length > maxFileLength) {
    return { isValid: false,
      validateMsg: <FormattedMessage
        id="FileUtils.maxFileNameLength"
        description="Maximum file name length"
        defaultMessage="File name exceeds the {maxFileLength} characters limit."
        values={{
          maxFileLength
        }}
      /> };
  }

  // Limit size of file
  if (file.size > (maxFileSize * 1024 * 1024)) {
    return { isValid: false,
      validateMsg: <FormattedMessage
        id="FileUtils.maxFileSize"
        description="Maximum file size"
        defaultMessage="File size exceeds the maximum allowed limit of {maxFileSize} MB."
        values={{
          maxFileSize
        }}
      /> };
  }
  return getFileIcon(fileName);
};

export const getFileIcon = (fileName) => {
  const ext = fileName.match(/\.([^.]+)$/)[1];
  if (validFileExtensions.find(fileExt => (fileExt === ext.toLowerCase()))) {
    let name;
    let color;
    switch (ext.toLowerCase()) {
      case 'pdf':
        name = 'file-pdf-o';
        color = 'red';
        break;
      case 'txt':
        name = 'file-text-o';
        color = '#000080';
        break;
      case 'doc':
      case 'docx':
      case 'rtf':
      case 'odt':
      case 'msg':
        name = 'file-o';
        color = '#000080';
        break;
      case 'xls':
      case 'xlsx':
        name = 'file-excel-o';
        color = 'green';
        break;
      case 'gif':
      case 'bmp':
      case 'jpg':
      case 'jpeg':
      case 'png':
        name = 'file-image-o';
        break;
      case 'ppt':
      case 'pptx':
      case 'pps':
        name = 'file-powerpoint-o';
        color = '#000080';
        break;
      case 'zip':
        name = 'file-archive-o';
        color = '#000080';
        break;
      default:
        return { validateMsg: <FormattedMessage
          id="Documents.fileUploaded"
          description="File uploaded "
          defaultMessage="File uploaded successfully"
        /> };
    }
    return { isValid: true,
      validateMsg:
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <FontAwesome
      name={name}
      style={{ fontSize: '65px', color }}
    />
    {fileName}
  </div> };
  }
  return { isValid: true, validateMsg: '' };
};

export const fileTranslations = {
  UploadFail:
  <FormattedMessage
    id="FileUtils.uploadFail"
    description="Upload failed"
    defaultMessage="Upload failed."
  />
};
