import { combineReducers } from 'redux';

import inbox from './inboxMessages';
import sent from './sentMessages';
import unRead from './unReadMessages';

export default combineReducers({
  inbox,
  sent,
  unRead
});
