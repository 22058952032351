/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import EditGroup from './editGroup';

const mapStateToProps = state => ({
  actionError: state.environment.linkGroup.get('error').toJS(),
  isUpdateSuccess: state.environment.linkGroup.get('isUpdateSuccess')
});

const mapDispatchToProps = dispatch => ({
  createLink: (data) => {
    dispatch({ type: 'MASTERDATA:LINK_GROUP:UPSERT', data });
  },
  createLinkProcessing: (links, formatMessage, linkIdToRemove) => {
    dispatch({ type: 'PROCESSING:LINK_GROUP:SAVE', links, formatMessage, linkIdToRemove });
  },
  createLinkAssessment: (links, formatMessage, linkIdToRemove) => {
    dispatch({ type: 'ASSESSMENT:LINK_GROUP:SAVE', links, formatMessage, linkIdToRemove });
  },
  editLink: (data, groupId) => {
    dispatch({ type: 'MASTERDATA:LINK_GROUP:UPDATE', data, groupId });
  },
  updateGroups: (data, removeGroupEntitiesFromRecord, linkNumber) => {
    dispatch({ type: 'MASTER_DATA_ITEM:CREATE:JOB',
      data,
      actionType: 'Edit',
      entityType: 'DataItemGroup',
      removeGroupEntitiesFromRecord,
      linkNumber });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
