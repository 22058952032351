import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { removeDuplicates, getFilteredOn } from '@packages/utils/reducer-utils';
import { requestTypes } from '@packages/features/dsr-detail/filterValues';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  items: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  newItems: Immutable.List(),
  error: {
    message: '',
    isError: false
  },
  requesterData: {},
  dsrData: [],
  data: Immutable.Map(),
  recordId: undefined,
  unsavedData: false,
  prevLocation: '/dsr',
  actionError: undefined,
  isUpdating: false,
  requestTypes: []
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DSR:FORMS:LIST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('newItems', defaultState.get('newItems'))
        .set('position', 0);
    case 'DSR:FORMS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'DSR:FORMS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.requesterData) {
        newItems = newItems.unshift(action.requesterData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(
        newItems,
        state.get('items').toJS(),
        action.items
      );
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'DSR:FORMS:LIST:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'DSR:FORMS:LIST:FILTER': {
      const { filterParams } = action;
      const newFilterParams = {
        [filterParams.filterKey]: filterParams.filterKey
      };
      const filterColumn = Object.assign(
        {},
        state.get('filterColumn').toJS(),
        newFilterParams
      );
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set(
          'filteredOn',
          getFilteredOn(
            Object.assign({}, action.filterParams, {
              filterObj: state.get('filteredOn')
            })
          )
        )
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'DSR:FORMS:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'DSR:FORMS:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));

    case 'DSR:FORMS:ITEM:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/dsr/form/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      }
      return state.set('isFetching', true).set('prevLocation', prevLocation);
    }
    case 'DSR:FORMS:ITEM:FETCH:SUCCESS': {
      return state
        .set('isFetching', defaultState.get('isFetching'))
        .set('data', Immutable.Map(action.data))
        .set('requestTypes', requestTypes[action.data.jurisdictions[0]]);
    }
    case 'DSR:FORMS:ITEM:PROPERTY:UPDATE': {
      const { property, field, data } = action;
      let modifiedData = { ...state.get('data').toJS() };
      if (field) {
        modifiedData = {
          ...modifiedData,
          [property]: {
            ...modifiedData[property],
            [field]: [...data]
          }
        };
      } else modifiedData = { ...modifiedData, [property]: data };
      return state.set('data', Immutable.Map(modifiedData));
    }

    case 'DSR:FORMS:SET:UNSAVEDDATA': {
      return state.set('unsavedData', action.value);
    }
    case 'DSR:FORMS:ITEM:CREATE:SUCCESS':
    case 'DSR:FORMS:CREATE:RESET':
    case 'DSR:FORMS:ITEM:UPSERT:SUCCESS':
    case 'DSR:FORMS:ITEM:UPSERT:FAIL': {
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isUpdating', defaultState.get('isUpdating'));
    }
    case 'DSR:FORMS:ITEM:CREATE:FAIL': {
      return state
        .set('actionError', action.error)
        .set('isUpdating', defaultState.get('isUpdating'));
    }
    case 'DSR:FORMS:CREATE:INIT':
    case 'DSR:FORMS:ITEM:UPSERT:INIT': {
      return state.set('isUpdating', true);
    }
    case 'DSR:FORMS:ITEM:RESET': {
      return state
        .set('data', defaultState.get('data'))
        .set('prevLocation', defaultState.get('prevLocation'));
    }

    default:
      return state;
  }
};
