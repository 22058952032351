import { takeEvery } from 'redux-saga/effects';

import {
  resetAndFetchMessages, fetchMessages, getusers, resetAndFetchUsers, searchUsers,
  createNewMessage, downloadAttachedDocument, getMessage,
  filterMessages, searchMessages, bulkDeleteMessages, markReadOrUnreadMessages, deleteMessage
}
  from '@packages/features/message-center/components/messages/sagas';
import { searchMessageRecipients } from
  '@packages/features/message-center/components/messages/components/recipient-selector/sagas';
import searchMessagesLinkedRecords from
  '@packages/features/message-center/components/messages/components/records-selector/sagas';
import getMessageCenterUnReadCount from '@packages/features/message-center/sagas';
import {
  resetAndfetchNotifications, fetchNotifications, markAllNotificationRead,
  deleteNotification, filterNotifications, searchNotifications, bulkDeleteNotifications, markReadOrUnreadNotifications
}
  from '@packages/features/message-center/components/notifications/sagas';
import {
  resetAndfetchTasks, fetchTasks, filterTasks, resetAndFetchCreatedByUsers, getCreatedByusers,
  searchTasks, claimOrUnclaimTask, deleteTask, bulkDeleteTasks, searchCreatedByUsers,
  initTaskWorkers, delegateTask, searchTaskWorkers
} from '@packages/features/message-center/components/task/sagas';

export default [
  [takeEvery, 'MESSAGES:LIST:REQUEST_INIT', resetAndFetchMessages],
  [takeEvery, 'MESSAGES:LIST:NEXT_FETCH', fetchMessages],
  [takeEvery, 'MESSAGES:LIST:FILTER_INIT', filterMessages],
  [takeEvery, 'MESSAGES:LIST:SEARCH_INIT', searchMessages],
  [takeEvery, 'MESSAGES:USER:LIST:REQUEST_INIT', resetAndFetchUsers],
  [takeEvery, 'MESSAGES:USER:LIST:NEXT:FETCH', getusers],
  [takeEvery, 'MESSAGES:USER:LIST:SEARCH', searchUsers],
  [takeEvery, 'MESSAGES:BULK:DELETE:INIT', bulkDeleteMessages],
  [takeEvery, 'MESSAGES:BULK:STATUS:UPDATE', markReadOrUnreadMessages],
  [takeEvery, 'MESSAGES:ITEM:DELETE:INIT', deleteMessage],
  [takeEvery, 'MESSAGES:CREATE:NEW', createNewMessage],
  [takeEvery, 'DOWNLOAD:ATTACHED:DOCUMENT', downloadAttachedDocument],
  [takeEvery, 'MESSAGE:ITEM:FETCH:INIT', getMessage],

  [takeEvery, 'MESSAGES:USERS:SELECTOR:LIST:SEARCH:INIT', searchMessageRecipients],
  [takeEvery, 'MESSAGES:LINKED_RECORDS_LIST:SEARCH:INIT', searchMessagesLinkedRecords],
  [takeEvery, 'MESSAGE:CENTER:UNREAD:COUNT', getMessageCenterUnReadCount],

  [takeEvery, 'NOTIFICATION:LIST:INIT', resetAndfetchNotifications],
  [takeEvery, 'NOTIFICATION:LIST:NEXT_FETCH', fetchNotifications],
  [takeEvery, 'NOTIFICATION:UPDATE:MARK_ALL_READ', markAllNotificationRead],
  [takeEvery, 'NOTIFICATION:DELETE', deleteNotification],
  [takeEvery, 'NOTIFICATION:BULK:DELETE:INIT', bulkDeleteNotifications],
  [takeEvery, 'NOTIFICATION:LIST:SEARCH_INIT', searchNotifications],
  [takeEvery, 'NOTIFICATION:LIST:FILTER_INIT', filterNotifications],
  [takeEvery, 'NOTIFICATION:BULK:STATUS:UPDATE', markReadOrUnreadNotifications],

  [takeEvery, 'TASKS:LIST:REQUEST_INIT', resetAndfetchTasks],
  [takeEvery, 'TASKS:LIST:NEXT_FETCH', fetchTasks],
  [takeEvery, 'TASK:ITEM:CLAIM_ACTION', claimOrUnclaimTask],
  [takeEvery, 'TASKS:LIST:SEARCH_INIT', searchTasks],
  [takeEvery, 'TASKS:LIST:FILTER_INIT', filterTasks],
  [takeEvery, 'TASK:DELETE', deleteTask],
  [takeEvery, 'TASK:WORKERS:INIT', initTaskWorkers],
  [takeEvery, 'TASK:ITEM:DELEGATE', delegateTask],
  [takeEvery, 'TASK:WORKERS:SEARCH', searchTaskWorkers],
  [takeEvery, 'TASK:BULK:DELETE:INIT', bulkDeleteTasks],
  [takeEvery, 'TASKS:USER:LIST:REQUEST_INIT', resetAndFetchCreatedByUsers],
  [takeEvery, 'TASKS:USER:LIST:NEXT:FETCH', getCreatedByusers],
  [takeEvery, 'TASKS:USER:LIST:SEARCH', searchCreatedByUsers]
];