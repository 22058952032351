import Immutable from 'immutable';
import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  searchText: Immutable.Map(),
  filteredOn: Immutable.Map(),
  searchItems: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  items: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PARTNERS:LIST:INIT':
      return state
        .set('filteredOn', state.get('filteredOn'))
        .set('items', Immutable.List())
        .set('searchText', defaultState.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('filterParams', state.get('filterParams'))
        .set('position', 0);
    case 'PARTNERS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'PARTNERS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'PARTNERS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'PARTNERS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'PARTNERS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', action.items);
    case 'PARTNERS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'PARTNERS:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'));
    case 'PARTNERS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    default:
      return state;
  }
};
