import { put, select } from 'redux-saga/effects';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';
import { getDiffContent } from '../../common-utils';
import { getTransformedDiffObject } from '../../saga-utils';
import { transformResponseData } from '../utils/assessmentUtils';

export default function* initializeAssessmentDiff(action) {
  const { assessmentId } = getParameterValuesFromHash('/assessment/:assessmentId/compare');
  yield put({ type: 'ASSESSMENT:DIFF:FETCH', assessmentId });

  try {
    const userState = yield select(state => state.user);
    const currentUser = userState ? userState.get('profile') : null;
    // Find json diff between the versions
    const diffObj = yield getTransformedDiffObject('assessments', assessmentId);
    const transformedData = yield transformResponseData(diffObj, currentUser, action.formatMessage);
    const data = transformCustomProperties(transformedData);
    yield put({ type: 'ASSESSMENT:DIFF:FETCH:SUCCESS', data });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'ASSESSMENT:DIFF:FETCH:FAIL', error: err.message });
  }
}

const transformCustomProperties = (data) => {
  const { processingCharacteristics = {}, jurisdictions = [] } = data;
  const transformedJurisdictions = jurisdictions.map((term) => {
    const { item, change } = getDiffContent(term);
    return ({
      value: item,
      change
    });
  });
  const keys = Object.keys(processingCharacteristics);
  const transformedCharacteristics = keys.length && keys
    ?.map(jurisdiction => processingCharacteristics[jurisdiction]?.map((term) => {
      const { change, item } = getDiffContent(term);
      return {
        value: `${jurisdiction}-${item}`,
        change
      };
    })).flat();
  return Object.assign({}, data, {
    processingCharacteristics: transformedCharacteristics || [],
    jurisdictions: transformedJurisdictions
  });
};
