import React from 'react';
import { Cell } from 'fixed-data-table';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// eslint-disable-next-line import/prefer-default-export
export const CheckBoxCell = React.memo(({ items, onCheck, rowIndex, bulkItemsSize, unused, ...props }) => (
  <Cell {...props}>
    <FormControlLabel
      classes={{
        disabled: 'disabled_style'
      }}
      control={
        <Checkbox
          disabled={!unused && bulkItemsSize > 4 &&
              (items.get(rowIndex) && items.get(rowIndex).selected === undefined)}
          checked={(getSelectedItems(items, rowIndex, unused)) || false}
          onChange={(event) => { onCheck(event, items.get && items.get(rowIndex)); }}
          onClick={event => event.stopPropagation()}
          color="primary"
          style={{ marginTop: -10, marginLeft: 2 }}
        />}
      disabled={!unused && bulkItemsSize > 4 && (items.get(rowIndex) && items.get(rowIndex).selected === undefined)}
    />
  </Cell>));

CheckBoxCell.propTypes = {
  rowIndex: PropTypes.number,
  bulkItemsSize: PropTypes.number,
  unused: PropTypes.bool,
  onCheck: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List)
};

CheckBoxCell.defaultProps = {
  rowIndex: -1,
  bulkItemsSize: 0,
  unused: false,
  onCheck: e => e,
  items: Immutable.List()
};

const getSelectedItems = (items, rowIndex, unused, showSimilarItems) => (items.get(rowIndex) &&
items.get(rowIndex).selected && (items.get(rowIndex).unused === unused || showSimilarItems));
