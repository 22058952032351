import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchLayoutRecords({ layoutId, searchParams }) {
  try {
    const response =
      yield registry.get('request')
        .get(searchParams ? getURLWithParams(searchParams, layoutId) :
          `/v1/records?filter=layoutIds=${layoutId}&fields=name`, null)

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map(item => ({
          ...item,
          id: item.id,
          name: item.name,
          key: `${item.name}`
        }));
        yield put({ type: 'RECORDS:LIST:REQUEST:SUCCESS', items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'RECORDS:LIST:REQUEST:FAIL', error: err.message });
  }
}

const getURLWithParams = (searchParams, layoutId) => {
  let url = `/v1/records?filter=layoutIds=${layoutId}&fields=name`;
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
