import Immutable from 'immutable';

import {
  getQueryStrings,
  getParameterValuesFromHash
} from '@packages/utils/query-parameters';

import { getNameAndValue, getUpdatedFilterMenu } from '../common-utils';

const defaultState = Immutable.fromJS({
  isEditable: true,
  isFetching: false,
  isUpdating: false,
  unSavedData: false,
  layout: undefined,
  data: Immutable.Map(),
  prevLocation: '/privacyrecords',
  recordId: undefined,
  recordName: '',
  actionError: undefined,
  currentFilter: 'PreAssessment',
  isUpdateLocked: false,
  filterMenu: [],
  showConfirmDialog: true,
  openPromoteDialog: false,
  helpNotes: {},
  comments: Immutable.List(),
  users: Immutable.List(),
  preDpiaRisk: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ASSESSMENT:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);
    case 'ASSESSMENT:EDIT:RESTRICTED':
      return state.set('isEditRestricted', action.isEditRestricted);
    case 'RECORD_PDF:DOWNLOAD:INIT':
      return state.set('isDownloading', true);
    case 'RECORD_PDF:DOWNLOAD:FAIL':
    case 'RECORD_PDF:DOWNLOAD:SUCCESS':
      return state.set('isDownloading', false);

    // DETAIL
    case 'ASSESSMENT:DETAIL:INIT':
    case 'ASSESSMENT:DETAIL:RESET':
      return defaultState;

    case 'ASSESSMENT:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/assessment/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      } else if (queryParams.from === 'reports') {
        prevLocation = '/reportsearch/results';
      } else if (queryParams.from === 'environment/links') {
        prevLocation = '/environment';
      } else if (
        queryParams.from === 'environment' ||
        queryParams.from === 'edit'
      ) {
        const environmentQueryParams = getNameAndValue(); // To handle query parameters with special characters.
        const queryParamsUrl = `filter=${queryParams.filter}&value=${
          environmentQueryParams.value
        }${
          environmentQueryParams.name
            ? `&name=${environmentQueryParams.name}`
            : ''
        }`;
        prevLocation = `/records?${queryParamsUrl}`;
      } else if (queryParams.from === 'publicList') {
        const { registryId } = getParameterValuesFromHash('/:registryId');
        prevLocation = `/${registryId}?embed=${queryParams.embed}&isPreview=${queryParams.isPreview}`;
      }
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isFetching', true)
        .set('prevLocation', prevLocation)
        .set('recordId', action.recordId)
        .set('isLoading', true);
    }

    case 'ASSESSMENT:DETAIL:FETCH:SUCCESS': {
      return state
        .set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('comments', Immutable.List(action.data?.comments))
        .set('recordName', action.recordName)
        .set('isLoading', false);
    }

    case 'ASSESSMENT:DETAIL:FETCH:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false)
        .set('isLoading', false);

    case 'ASSESSMENT:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);

    case 'ASSESSMENT:DETAIL:UPSERT':
      return state
        .set('isUpdating', true)
        .set('actionError', defaultState.get('actionError'));
    case 'ASSESSMENT:DETAIL:UPSERT:SUCCESS':
      return state
        .set('isUpdating', false)
        .set('data', Immutable.Map(action.data))
        .set('comments', Immutable.List(action.data?.comments))
        .set('unSavedData', false);
    case 'ASSESSMENT:UNSAVED:PROPERTY:RESET':
      return state
        .set('unSavedData', defaultState.get('unSavedData'))
        .set('isUpdating', true);
    case 'RECORDS:UNSAVED_DATA:RESET':
      return state.set('unSavedData', defaultState.get('unSavedData'));
    case 'ASSESSMENT:UNSAVED:PROPERTY:SET':
      return state.set('unSavedData', true);

    case 'ASSESSMENT:DETAIL:UPSERT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false);

    case 'ASSESSMENT:DETAIL:UPSERT:LOCKED':
      return state.set('isUpdateLocked', true).set('isUpdating', false);

    case 'ASSESSMENT:PROPERTY:UPDATE': {
      const modifiedData = {
        ...state.get('data').toJS(),
        [action.property]: action.data
      };
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('data', Immutable.Map(modifiedData));
    }

    case 'ASSESSMENT:FILTER_MENU:UPDATE':
      return state.set(
        'layout',
        getUpdatedFilterMenu(state.get('layout'), action.key, action.enable)
      );

    case 'ASSESSMENT:TOGGLE_PROMOTION_DIALOG': {
      return state.set('openPromoteDialog', !state.get('openPromoteDialog'));
    }

    case 'ASSESSMENT:PROPERTY:LINK:TOGGLE_UPDATE': {
      return state.setIn(['data', action.key], [...action.items]);
    }
    case 'ASSESSMENT:SHOW:CONFIRM:DIALOG:RESET': {
      return state.set('showConfirmDialog', false);
    }
    case 'ASSESSMENT:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'ASSESSMENT:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'ASSESSMENT:COMMENT:DELETE:SUCCESS':
    case 'ASSESSMENT:COMMENT:SAVE:SUCCESS': {
      return state.set('comments', Immutable.List(action.comments));
    }
    case 'ASSESSMENT:PREDPIA:RISK': {
      return state.set('preDpiaRisk', action.risk);
    }
    case 'ASSESSMENT:DETAIL:ERROR:RESET':
      return state.set('actionError', defaultState.get('actionError'));
    case 'ASSESSMENT:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'ASSESSMENT:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);
    default:
      return state;
  }
};
