import Immutable from 'immutable';

import { getFilteredOn } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  isFetching: false,
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'lastUpdatedTime',
    sortOrder: 'DESC'
  }),
  filterColumn: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SUB_TENANTS:DATA_UPDATES:LIST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:INIT':
      return state.set('items', defaultState.get('items'))
        .set('isFetching', true);
    case 'SUB_TENANTS:DATA_UPDATES:ITEM:RETRY:SUCCESS':{
      const modifiedData = state.get('items')
        .map(item => item.id === action.newData.id ? {...item, ...action.newData} : item)
      return state.set('items', modifiedData)
    }
    case 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:SUCCESS':
    {
      return state.set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    }
    case 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:FAIL':
      return state.set('isFetching', false);
    case 'SUB_TENANTS:DATA_UPDATES:LIST:FILTER': {
      const { filterParams } = action;
      const newFilterParams = { [filterParams.filterKey]: filterParams.filterKey };
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), newFilterParams);
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'GLOBAL:SUB_TENANTS:DATA_UPDATES:LIST:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    default:
      return state;
  }
};

