import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  threatLikelihood:
  <FormattedMessage
    id="Threat.likelihood"
    description="Likelihood of the threat"
    defaultMessage="Likelihood of the threat"
  />,
  impactSeverity:
  <FormattedMessage
    id="Impact.severity"
    description="Severity of the impact"
    defaultMessage="Severity of the impact"
  />,
  confidentialityHeader:
  <FormattedMessage
    id="Threat.confidentialityHeader"
    description="Confidentiality section header"
    defaultMessage="Confidentiality"
  />,
  confidentialityThreats:
  <FormattedMessage
    id="Threat.confidentialityThreats"
    description="Label for confidentiality threats"
    defaultMessage="Threats to confidentiality"
  />,
  integrityHeader:
  <FormattedMessage
    id="Threat.integrityHeader"
    description="Integrity section header"
    defaultMessage="Integrity"
  />,
  integrityThreats:
  <FormattedMessage
    id="Threat.integrityThreats"
    description="Label for integrity threats"
    defaultMessage="Threats to integrity"
  />,
  availabilityHeader:
  <FormattedMessage
    id="Threat.availabilityHeader"
    description="Availability section header"
    defaultMessage="Availability"
  />,
  availabilityThreats:
  <FormattedMessage
    id="Threat.availabilityThreats"
    description="Label for availability threats"
    defaultMessage="Threats to availability"
  />,
  potentialImpacts:
  <FormattedMessage
    id="Impact.potentialImpacts"
    description="Label for potential impacts"
    defaultMessage="Potential impacts"
  />,
  totalLikelihood:
  <FormattedMessage
    id="Threat.totalLikelihood"
    description="Total likelihood"
    defaultMessage="Total likelihood"
  />,
  totalSeverity:
  <FormattedMessage
    id="Threat.totalSeverity"
    description="Total severity"
    defaultMessage="Total severity"
  />,
  resultingRisk:
  <FormattedMessage
    id="Threat.resultingRisk"
    description="Resulting risk"
    defaultMessage="Resulting risk (likelihood x severity)"
  />,
  residualRisk:
  <FormattedMessage
    id="Threat.residualRisk"
    description="Residual risk"
    defaultMessage="Residual risk"
  />
};
