import { connect } from 'react-redux';

import summaryBulkUpdate from './bulkUpdateSummary';
import validationErrors from './bulkUpdateValidationErrors';

function mapStateToProps(state) {
  return {
    validationErrors: state.privacyRecords.bulkUpdate.get('validationErrors')
  };
}

const mapDispatchToProps = (dispatch) => ({
  bulkUpdate: (updateType, data, recordIds) => {
    dispatch({ type: 'BULK_UPDATE:JOB:INIT', updateType, data, recordIds });
  },
  resetBulkItems: () => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType: 'processing',
      selectedRecords: []
    });
  }
});

const BulkUpdateSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(summaryBulkUpdate);

const BulkUpdateValidationErrors = connect(
  mapStateToProps,
  mapDispatchToProps
)(validationErrors);

export { BulkUpdateSummary, BulkUpdateValidationErrors };
