import styles from '@packages/ui/styles';

export default {
  ...styles,
  row: {
    height: '40px'
  },
  multiLineRow: {
    maxHeight: '125px !important'
  },
  innerDiv: {
    paddingTop: '13px',
    paddingBottom: '13px'
  },
  evenRow: {
    backgroundColor: '#efefef'
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex'
  },
  textLength: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '360px'
  },
  multiLine: {
    display: '-webkit-box',
    overflow: ' hidden',
    textOverflow: ' ellipsis',
    lineHeight: 1.3,
    maxWidth: '80%',
    whiteSpace: 'pre-wrap'
  },
  link: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    color: 'blue'
  },
  rightIconStyle: {
    top: '8px',
    right: '10px'
  },
  formMargin: {
    margin: '30px'
  },
  buttonLabel: {
    fontSize: '16px',
    textAlign: 'center',
    textTransform: 'none',
    lineHeight: '50px'
  },
  flatButtonLabel: {
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'inherit',
    textTransform: 'none',
    lineHeight: '50px'
  },
  filterCheckBoxLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '350px'
  }
};
