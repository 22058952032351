import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Linkify from 'react-linkify';

const styles = theme => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 500,
    wordBreak: 'break-word',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    border: '.1em solid black',
    borderBottom: '1px solid black'
  }
});

const ArrowTooltip = (props) => {
  const { classes, renderAsChildren } = props;

  return (
    <>
      {props.title && renderAsChildren &&
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        PopperProps={{
          style: {
            zIndex: 2001
          }
        }}
        {...props}
        title={
          <Linkify properties={{ target: '_blank', style: { color: props.theme.palette.primary.main } }}>
            {props.title}
          </Linkify>
        }
      >
        <div>{props.children}</div>
      </Tooltip>}

      {props.title && !renderAsChildren &&
      // Dashboard map does not render when the Tooltip chidren are rendered as child components
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        PopperProps={{
          style: {
            zIndex: 2001
          }
        }}
        {...props}
        title={
          <Linkify properties={{ target: '_blank', style: { color: props.theme.palette.primary.main } }}>
            {props.title}
          </Linkify>
        }
      />}
    </>
  );
};

ArrowTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  renderAsChildren: PropTypes.bool,
  classes: PropTypes.shape({
    arrow: PropTypes.string,
    tooltip: PropTypes.string
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  })
};

ArrowTooltip.defaultProps = {
  theme: {
    palette: {
      primary: {
        main: ''
      }
    }
  },
  renderAsChildren: true,
  children: null
};

export default withTheme(withStyles(styles)(ArrowTooltip));
