import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  showValidationErrors: false,
  validationErrors: [],
  checkedFields: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'BULK_UPDATE:VALIDATION:ERROR':
      return state
        .set('showValidationErrors', true)
        .set('validationErrors', action.validationError);
    case 'RESET:VALIDATION:ERRORS':
      return state
        .set('showValidationErrors', defaultState.showValidationErrors)
        .set('validationErrors', defaultState.validationErrors);
    case 'BULK:UPDATE_CHECK:PROPERTY:UPDATE': {
      return state
        .set('checkedFields', action.data); }
    default:
      return state;
  }
};
