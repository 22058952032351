import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import { getQueryStrings } from '@packages/utils/query-parameters';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';

import { getURLWithParams } from '../saga-utils';
import { getRequestType } from '../../utils';

export function* resetAndfetchStakeholderList(action) {
  yield put({ type: 'STAKEHOLDERS:LIST:REQUEST:INIT' });
  yield fetchStakeholderList(action);
}

export function* fetchStakeholderList(action) {
  const { rowCount = MASTERDATA_FETCH_LIMIT, stakeholderEntityId } = action;
  yield put({ type: 'STAKEHOLDERS:LIST:FETCH' });

  const URL = window.location;
  const isPreview = getQueryStrings(URL.hash).isPreview === 'true';
  const isPublic = URL.pathname.includes('public');

  try {
    const stakeholdersState = yield select(
      (state) => state.environment.stakeholders
    );

    let filterParams = {};
    let pageSearchTextObj = {};
    let searchParameters = {};
    let filteredOn = Immutable.Map();
    if (action.isPersistedData) {
      if (stakeholdersState) {
        filterParams = stakeholdersState.get('filterParams').toJS();
        filteredOn = stakeholdersState.get('filteredOn');
        pageSearchTextObj = stakeholdersState.get('pageSearchText').toJS();
        searchParameters = pageSearchTextObj
          ? { searchKey: 'name', searchText: pageSearchTextObj.name }
          : {};
      }
    }

    const { sortOn, sortOrder } = filterParams;

    let requestType = getRequestType(stakeholderEntityId);
    if (requestType === 'customStakeholders')
      requestType += `?filter=customStakeholderEntityIds=${stakeholderEntityId}`;

    const isStakeHolderType = true;
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position,
          searchParameters,
          requestType,
          rowCount,
          isStakeHolderType
        ),
        null,
        {},
        !isPublic || isPreview
      );

    switch (response.status) {
      case 200: {
        const items = response.body;
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map((item) => ({
          ...item,
          key: item.name,
          country: item.country && item.country.name,
          isPartOf: `${
            item.parentOrgEntity ? `${item.parentOrgEntity.name}` : ''
          }`
        }));
        yield put({
          type: 'STAKEHOLDERS:LIST:FETCH:SUCCESS',
          items: modifiedItems
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({
      type: 'STAKEHOLDERS:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* filterStakeholders(action) {
  const { filterParams } = action;
  yield put({ type: 'STAKEHOLDERS:LIST:FILTER', filterParams });
  yield fetchStakeholderList(action);
}

export function* searchStakeholders(action) {
  const {
    searchParams: { isPageSearch = false, searchKey },
    searchParams,
    rowCount = MASTERDATA_SEARCH_LIMIT,
    stakeholderEntityId
  } = action;

  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';
  const URL = window.location;
  const isPreview = getQueryStrings(URL.hash).isPreview === 'true';
  const isPublic = URL.pathname.includes('public');

  const stakeholdersState = yield select(
    (state) => state.environment.stakeholders
  );

  const { sortOn, sortOrder, filteredOn, searchParameters, searchText } =
    getFilterAndSearchParamsFromState(
      stakeholdersState,
      searchParams,
      searchMode
    );

  yield put({
    type: `STAKEHOLDERS:${searchMode}`,
    searchKey,
    searchText
  });

  let requestType = getRequestType(stakeholderEntityId);
  if (requestType === 'customStakeholders')
    requestType += `?filter=customStakeholderEntityIds=${stakeholderEntityId}`;

  try {
    const isStakeHolderType = true;
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position,
          searchParameters,
          requestType,
          rowCount,
          isStakeHolderType
        ),
        null,
        {},
        !isPublic || isPreview
      );

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: item.name,
          country: item.country && item.country.name,
          isPartOf: `${
            item.parentOrgEntity ? `${item.parentOrgEntity.name}` : ''
          }`
        }));
        yield put({
          type: `STAKEHOLDERS:${searchMode}:SUCCESS`,
          searchKey,
          searchText,
          items: modifiedItems
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({
      type: 'STAKEHOLDERS:LIST:SEARCH:FAIL',
      error: err.message
    });
  }
}

const getFilterAndSearchParamsFromState = (
  stakeholdersState,
  searchParams,
  searchMode
) => {
  // Filter parameters
  let filterParams = {};
  let pageSearchTextObj = {};
  if (stakeholdersState) {
    filterParams = stakeholdersState.get('filterParams').toJS();
    pageSearchTextObj = stakeholdersState.get('pageSearchText').toJS();
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn =
    stakeholdersState && searchMode !== 'LIST:SEARCH'
      ? stakeholdersState.get('filteredOn')
      : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;
    let searchTextObj = Immutable.Map();
    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined && stakeholdersState) {
      searchTextObj =
        searchMode === 'LIST:SEARCH'
          ? stakeholdersState.get('searchText')
          : stakeholdersState.get('pageSearchText');
      searchText = searchTextObj.get(searchKey) || '';
    }
    searchParameters = Object.assign({}, searchParams, { searchText });
  } else {
    searchParameters = pageSearchTextObj
      ? { searchKey: 'name', searchText: pageSearchTextObj.name }
      : {};
  }

  return { sortOn, sortOrder, filteredOn, searchParameters, searchText };
};
