import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

class PersonalDataItemSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: []
    };
    this.handleNextData = this.handleNextData.bind(this);
  }

  componentDidMount() {
    if (this.props.initPersonalDataItems) {
      this.props.initPersonalDataItems();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields === nextProps.fields) ||
    !(this.props.personalDataItems === nextProps.personalDataItems) ||
    !(this.state === nextState);
  }

  handleNextData() {
    this.props.getNextData(this.props.position);
  }

  render () {
    const { searchResults } = this.state;
    const { hintTextLabel, personalDataItems } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="name"
          searchResults={searchResults}
          dataItems={personalDataItems}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          onScrollStop={this.handleNextData}
          hintTextLabel={hintTextLabel}
          title={
            <FormattedMessage
              id="MultiplePersonaldataitemDialog.header"
              description="Select Personal data item Dialog Header"
              defaultMessage="Select personal data items"
            />}
          {...this.props}
        />
      </div>
    );
  }
}

PersonalDataItemSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initPersonalDataItems: PropTypes.func,
  personalDataItems: PropTypes.instanceOf(Immutable.List)
};

PersonalDataItemSelector.defaultProps = {
  hintTextLabel: null,
  fields: {},
  getNextData: e => e,
  position: 0,
  initPersonalDataItems: e => e,
  searchResults: Immutable.List(),
  personalDataItems: Immutable.List()
};

export default PersonalDataItemSelector;
