import { connect } from 'react-redux';
import CustomMasterDataSelector from './component';

const mapStateToProps = (state) => ({
  customSimpleMasterData: state.customMasterDataList.get('items'),
  position: state.customMasterDataList.get('position')
});

const mapDispatchToProps = (dispatch) => ({
  initCustomMasterData: (entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:REQUEST_INIT',
      entityType
    });
  },
  getNextData: (position, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:NEXT_FETCH',
      position,
      entityType
    });
  },
  onSearch: (searchParams, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:SEARCH_INIT',
      searchParams,
      entityType
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomMasterDataSelector);
