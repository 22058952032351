import { connect } from 'react-redux';
import DataRecipientCategorySelector from './component';

const mapStateToProps = state => ({
  dataRecipientCategories: state.environment.dataRecipientCategories.get('items'),
  error: state.dataRecipientCategories.get('error'),
  searchResults: state.dataRecipientCategories.get('items'),
  position: state.environment.dataRecipientCategories.get('position')
});

const mapDispatchToProps = dispatch => ({
  initDataRecipientCategories: () => {
    dispatch({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'DATA_RECIPIENT_CATEGORIES:LIST:SEARCH_INIT', searchParams });
  },
  getNextData: (position) => {
    dispatch({ type: 'DATARECIPIENTCATEGORIES:LIST:NEXT_FETCH', position });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataRecipientCategorySelector);
