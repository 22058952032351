import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';

import { commonTranslations } from '@packages/utils/commontranslations';
import ItemList from '@packages/components/form-components/itemList';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import RetentionItemSelector from './retention-selector';
import { addKeyToRetentionTerm, getItemNames } from '../../common-utils';

const RetentionTermSelector = (props) => {
  const {
    isEditable = true,
    fields,
    label,
    intl,
    initAllRetentionTerms,
    initRetentionTermOffsets,
    retentionTerms,
    isItemInLinkGroup,
    onOffsetSearch,
    hintTextLabel,
    createNewMenuItem,
    onChooseFilter,
    tags,
    isGlobal,
    searchResults,
    getNextTags,
    tagsPosition,
    tagSearch,
    showTextField
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [recordsRetentionTerms, setRecordsRetentionTerms] = useState([]);
  const [tagSearchResult, setTagSearchResult] = useState({});
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [itemToEdit, setItemToEdit] = useState(undefined);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (initRetentionTermOffsets) {
      initRetentionTermOffsets(isGlobal);
    }
    if (initAllRetentionTerms) {
      initAllRetentionTerms();
    }
  }, []);

  useEffect(() => {
    if (retentionTerms) {
      setRecordsRetentionTerms(modifyRetentionTerm(retentionTerms));
    }
  }, [retentionTerms]);

  useEffect(() => {
    if (searchResults) {
      setTagSearchResult(searchResults);
    }
  }, [searchResults]);

  useEffect(() => {
    setSelectedTerms(fields.getAll());
  }, [fields.getAll()]);

  const handleEditClick = (index) => {
    const isLinkAssociated = isItemInLinkGroup('retentionTerms', index);
    if (isLinkAssociated) {
      setOpenEdit(true);
      setSelectedIndex(index);
    } else {
      setIsEdit(true);
      setSelectedIndex(index);
      performEdit(index);
    }
  };

  const handleEditItem = (selectedItem, selectedIndex, closeDialog) => {
    const selectedRetentionTerms = fields && modifyRetentionTerm(fields, true);
    if (selectedIndex === -1) {
      selectedRetentionTerms.push(selectedItem);
    } else {
      selectedRetentionTerms.splice(selectedIndex, 1, selectedItem);
    }

    updateRetentionTermItem(selectedRetentionTerms);

    if (closeDialog) {
      handleClose();
    }
  };

  const handleRemoveItem = (selectedIndex) => {
    const isLinkAssociated = isItemInLinkGroup('retentionTerms', selectedIndex);
    if (isLinkAssociated) {
      setOpenDelete(true);
      setSelectedIndex(selectedIndex);
    } else {
      performRemove(selectedIndex);
    }
  };

  const handleFilter = (selectedItem) => {
    const value = selectedItem ? [selectedItem.id] : [];
    onChooseFilter(Object.assign({}, { filterKey: 'tags', filteredOn: value }));
  };
  const handleClose = () => {
    setOpenDelete(false);
    setSelectedIndex(-1);
    setOpenEdit(false);
    setIsEdit(false);
  };

  const handleOnOffsetSearch = (searchText) => {
    const searchParams = { ...searchText, searchKey: 'name' };
    onOffsetSearch(searchParams);
  };

  const onCancelEdit = () => {
    const selectedRetentionTerms = modifyRetentionTerm(fields, true);
    selectedRetentionTerms.splice(selectedIndex, 0, itemToEdit);
    setIsEdit(false);
    setItemToEdit(undefined);
    props.updateRetentionTerm(selectedRetentionTerms);
  };

  const modifyRetentionTerm = (fields = [], updateFields = false) => {
    const items =
      (updateFields && fields.length && [...fields.getAll()]) || fields;
    if (updateFields) fields.removeAll();
    return items.map((item) => {
      const modifiedItem = addKeyToRetentionTerm(item, intl.formatMessage);
      if (updateFields) fields.push(modifiedItem);
      return modifiedItem;
    });
  };

  const updateMultipleTerms = (retentionTermObj) => {
    const items = fields.getAll() || [];
    const selectedTerms = [...items, ...retentionTermObj];
    props.updateRetentionTerm(selectedTerms);
  };

  const updateRetentionTerm = (retentionTermObj) => {
    const selectedRetentionTerms = modifyRetentionTerm(fields, true);
    if (!isEdit) {
      selectedRetentionTerms.push(retentionTermObj);
    } else if (isEdit) {
      const modifiedRetentionTerm = { ...itemToEdit, ...retentionTermObj };
      selectedRetentionTerms.splice(selectedIndex, 1);
      selectedRetentionTerms.splice(selectedIndex, 0, modifiedRetentionTerm);
    }
    updateRetentionTermItem(selectedRetentionTerms);
  };

  const updateRetentionTermItem = (selectedRetentionTerms) => {
    props.updateRetentionTerm(selectedRetentionTerms);
    setIsEdit(false);
  };

  const performEdit = (selectedIndex) => {
    const selectedRetentionTerms = modifyRetentionTerm(fields, true);
    if (selectedIndex !== -1) {
      setOpenEdit(false);
      setIsEdit(true);
      setItemToEdit(selectedRetentionTerms[selectedIndex]);
    }
  };

  const performRemove = (selectedIndex) => {
    const selectedRetentionTerms = modifyRetentionTerm(fields, true);
    if (selectedIndex !== -1) {
      selectedRetentionTerms.splice(selectedIndex, 1);
    }
    props.updateRetentionTerm(selectedRetentionTerms);
    handleClose();
  };

  const actions = [
    <Button id="btn_remove_no" key="btn_remove_no" onClick={handleClose}>
      {commonTranslations.Ok}
    </Button>
  ];

  return (
    <div>
      {!showTextField ? (
        getItemNames(selectedTerms)
      ) : (
        <div>
          <ItemList
            id="item-list"
            {...props}
            isEditable={isEditable}
            type={label}
            handleRemoveItem={handleRemoveItem}
            handleEditClick={handleEditClick}
            selectedItems={selectedTerms}
            handleEditItem={handleEditItem}
          />
          <RetentionItemSelector
            {...props}
            id="retentionItemSelector"
            hintTextLabel={hintTextLabel}
            isEdit={isEdit}
            createNewMenuItem={createNewMenuItem}
            selectedItems={selectedTerms}
            itemToEdit={itemToEdit}
            handleClose={handleClose}
            recordsRetentionTerms={recordsRetentionTerms}
            onSaveRetentionTerm={updateRetentionTerm}
            updateRetentionTerms={updateMultipleTerms}
            onCancelEdit={onCancelEdit}
            onOffsetSearch={handleOnOffsetSearch}
            filterTagValue={tags}
            onChooseFilter={handleFilter}
            tagSearch={tagSearch}
            tagSearchResult={tagSearchResult}
            getNextTags={getNextTags}
            tagsPosition={tagsPosition}
          />

          {openDelete && (
            <CommonDialog
              id="delete-confirm-dialog"
              show={openDelete}
              onCancel={handleClose}
              fullWidth={true}
              maxWidth="sm"
              buttonActions={actions}
            >
              {commonTranslations.linkItemDeletefromGroup}
            </CommonDialog>
          )}
          {openEdit && (
            <CommonDialog
              id="edit_confirm-dialog"
              show={openEdit}
              onCancel={handleClose}
              fullWidth={true}
              maxWidth="sm"
              buttonActions={actions}
            >
              {commonTranslations.linkItemUpdatefromGroup}
            </CommonDialog>
          )}
        </div>
      )}
    </div>
  );
};

RetentionTermSelector.propTypes = {
  isEditable: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  label: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  initAllRetentionTerms: PropTypes.func,
  initRetentionTermOffsets: PropTypes.func,
  retentionTerms: PropTypes.shape({}),
  updateRetentionTerm: PropTypes.func,
  isItemInLinkGroup: PropTypes.func,
  toggleLinkGroupItem: PropTypes.func,
  onOffsetSearch: PropTypes.func,
  hintTextLabel: PropTypes.node,
  createNewMenuItem: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  tags: PropTypes.instanceOf(Immutable.List),
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  searchResults: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagSearch: PropTypes.func,
  showTextField: PropTypes.bool,
  isGlobal: PropTypes.bool
};

RetentionTermSelector.defaultProps = {
  isEditable: true,
  label: null,
  intl: {
    formatMessage: (e) => e
  },
  initAllRetentionTerms: null,
  initRetentionTermOffsets: null,
  retentionTerms: {},
  updateRetentionTerm: (e) => e,
  isItemInLinkGroup: () => false,
  toggleLinkGroupItem: (e) => e,
  onOffsetSearch: (e) => e,
  hintTextLabel: null,
  createNewMenuItem: false,
  tags: Immutable.List(),
  onChooseFilter: (e) => e,
  entityType: '',
  masterDataType: '',
  searchResults: Immutable.List(),
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  showTextField: true,
  isGlobal: false
};

export default injectIntl(RetentionTermSelector);
