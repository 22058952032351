import { tenantConfigurationTranslations } from './tenantConfigurationTranslations';

const filterMenu = [
  {
    label: tenantConfigurationTranslations.recordTypes,
    key: 'recordTypes',
    enable: true,
    defaultFilter: 'recordTypes'
  },
  {
    label: tenantConfigurationTranslations.dynamicRecordLayout,
    key: 'dynamicRecordLayout',
    enable: true,
    defaultFilter: 'dynamicRecordLayout'
  },
  {
    label: tenantConfigurationTranslations.simpleMasterData,
    key: 'simpleMasterData',
    enable: true,
    defaultFilter: 'simpleMasterDataItems_simpleMasterData',
    subMenu: [
      {
        label: tenantConfigurationTranslations.simpleMasterDataItems,
        key: 'simpleMasterDataItems_simpleMasterData',
        enable: true
      },
      {
        label: tenantConfigurationTranslations.simpleMasterDataFields,
        key: 'simpleMasterDataFields_simpleMasterData',
        enable: true
      }
    ]
  },
  {
    label: tenantConfigurationTranslations.dropDown,
    key: 'dropDown',
    enable: true,
    defaultFilter: 'dropDownLists_dropDown',
    subMenu: [
      {
        label: tenantConfigurationTranslations.dropDownLists,
        key: 'dropDownLists_dropDown',
        enable: true
      },
      {
        label: tenantConfigurationTranslations.dropDownFields,
        key: 'dropDownFields_dropDown',
        enable: true
      }
    ]
  },
  {
    label: tenantConfigurationTranslations.scoredDropDown,
    key: 'scoredDropDown',
    enable: true,
    defaultFilter: 'scoredDropDownLists_scoredDropDown',
    subMenu: [
      {
        label: tenantConfigurationTranslations.scoredDropDownLists,
        key: 'scoredDropDownLists_scoredDropDown',
        enable: true
      },
      {
        label: tenantConfigurationTranslations.scoredDropDownFields,
        key: 'scoredDropDownFields_scoredDropDown',
        enable: true
      }
    ]
  },
  {
    label: tenantConfigurationTranslations.stakeholderFields,
    key: 'stakeholderFields',
    enable: true,
    defaultFilter: 'stakeholderFields'
  },
  {
    label: tenantConfigurationTranslations.textFields,
    key: 'textFields',
    enable: true,
    defaultFilter: 'textFields'
  },
  {
    label: tenantConfigurationTranslations.richTextFields,
    key: 'richTextFields',
    enable: true,
    defaultFilter: 'richTextFields'
  }
];

export default filterMenu;
