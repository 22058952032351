import { connect } from 'react-redux';
import CustomDropdownSelector from './component';

const mapStateToProps = (state) => ({
  picklistOptions: state.customFields.get('options'),
  locale: state.intl.locale,
  tenantLocale: state.login.get('tenantLocale')
});

const mapDispatchToProps = (dispatch) => ({
  fetchOptions: (listId) => {
    dispatch({
      type: 'CUSTOM:ITEM_LIST_OPTION',
      mode: 'GET_ALL',
      listId,
      layoutFieldUniqueId: listId
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDropdownSelector);
