import { connect } from 'react-redux';
import NotifierComponent from './notifier';

const mapStateToProps = state => ({
  notifications: state.notifier.get('notifications')
});

const mapDispatchToProps = dispatch => ({
  onDismiss: key => dispatch({ type: 'NOTIFIER:HIDE', key })
});

export default (connect(mapStateToProps, mapDispatchToProps)(NotifierComponent));
