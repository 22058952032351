/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import GroupListLayout from './group-list';

const mapStateToProps = (state) => ({
  bulkItems: state.environment.linkGroup.get('bulkItems'),
  usage: state.environment.linkGroup.get('usage'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT,
  position: state.environment.linkGroup.get('position')
});
const mapDispatchToProps = (dispatch) => ({
  deleteGroup: (groupId) => {
    dispatch({ type: 'LINK_GROUP:LIST:DELETE', groupId });
  },
  onItemCheck: (item) => {
    dispatch({ type: 'LINK_GROUP:LIST:ITEM:BULK:CHECK', item });
  },
  onCheckReset: () => {
    dispatch({ type: 'LINK_GROUP:LIST:ITEM:BULK:CHECK:RESET' });
  },
  removeGroupData: (data, removeGroupEntitiesFromRecord, linkNumber) => {
    dispatch({
      type: 'MASTER_DATA_ITEM:CREATE:JOB',
      data,
      actionType: 'Delete',
      entityType: 'DataItemGroup',
      removeGroupEntitiesFromRecord,
      linkNumber
    });
  },
  checkUsage: (dataItemId) => {
    dispatch({
      type: 'MASTER_DATA_ITEM:CHECK:IMPACT',
      entityType: 'DataItemGroup',
      id: dataItemId
    });
  },
  getNextData: (position, source) => {
    dispatch({ type: 'LINK_GROUP:LIST:NEXT_FETCH', position, source });
  },
  resetLinkGroup: () => {
    dispatch({ type: 'LINK_GROUP:RESET' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupListLayout);
