import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import FontAwesome from 'react-fontawesome';
import styles from '@packages/ui/styles';
import AutoComplete from '../auto-complete';


class ItemSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };

    this.onNewRequest = this.onNewRequest.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.resetSearchText = this.resetSearchText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if ((this.state.searchText === '' && nextProps.selectedItem !== '') ||
      this.props.selectedItem !== nextProps.selectedItem) {
      if (this.props.multiValue === false) {
        this.setState({
          searchText: nextProps.selectedItem || ''
        });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const res = !(this.props.dataSource === nextProps.dataSource) ||
      !(this.state === nextState) || !(this.props.selectedItem === nextProps.selectedItem) ||
      !(this.props.hintTextLabel === nextProps.hintTextLabel) ||
      !(this.props.disabled === nextProps.disabled) ||
      !(this.props.onFocus === nextProps.onFocus);
    return res;
  }

  handleBlur(event) {
    if (event.target.value === '' && this.props.onAfterReset) {
      this.props.onAfterReset();
    }
  }

  onNewRequest(selectedItem, index) {
    this.setSearchText(' ');

    // If selected index is -2, create new item
    if (index === -2) {
      if (this.props.handleAddClick) {
        this.props.handleAddClick(selectedItem);
      }
    } else if (index === -3) { // If selected index is -3, select from a list
      if (this.props.handleMultipleSelect) {
        this.props.handleMultipleSelect();
      }
    } else if (index === -1) {
      const value = this.props.dataSource.find(x => x.key === selectedItem);
      if (!this.props.dataSource || !value) {
        this.props.handleAddClick(selectedItem);
      } else {
        this.props.handleSelectedItem({ value });
      }
    } else if (index === undefined && selectedItem === '') {
      // when searchtext is reset to empty
      this.props.handleSelectedItem({ value: selectedItem });
    } else if (index !== -1 && this.props.dataSource.findIndex(item =>
      item.key === selectedItem.key) !== -1) {
      this.props.handleSelectedItem({ value: selectedItem });
    } // Prevent multiple data items selection by clicking rapidly.

    this.setSearchText('');
  }

  setSearchText(searchText) {
    if (this.props.multiValue !== false) {
      this.setState({
        searchText
      });
    }
  }

  resetSearchText() {
    this.onNewRequest('');
    if (this.props.onAfterReset) {
      this.props.onAfterReset();
    }
  }

  render () {
    const { dataSource, dataSourceConfig, hintTextLabel, additionalInfoConfig,
      id, dataSourceFilter, onSearch, showResetButton = false } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <AutoComplete
          {...(id && { id })}
          underlineShow={false}
          openOnFocus={true}
          onFocus={this.props.onFocus}
          inputType={this.props.inputType}
          maxSearchResults={5}
          createNewMenuItem={this.props.createNewMenuItem || false}
          selectFromListMenuItem={this.props.selectFromListMenuItem || false}
          fullWidth={true}
          hintText={hintTextLabel}
          searchText={this.state.searchText}
          filter={dataSourceFilter || AutoComplete.caseInsensitiveFilter}
          hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
          textFieldStyle={styles.autoComplete}
          onBlur={this.handleBlur}
          onNewRequest={this.onNewRequest}
          dataSource={dataSource}
          dataSourceConfig={dataSourceConfig || {}}
          additionalInfoConfig={additionalInfoConfig || {}}
          onSearch={onSearch}
          targetOrigin={this.props.targetOrigin}
          anchorOrigin={this.props.anchorOrigin}
          disabled={this.props.disabled}
        />
        {showResetButton && <FontAwesome
          name="times"
          style={{ lineHeight: '1em',
            fontSize: '25px',
            marginTop: 10,
            marginLeft: 15,
            color: 'red',
            cursor: 'pointer' }}
          onClick={this.resetSearchText}
        />}
      </div>
    );
  }
}

ItemSelector.propTypes = {
  id: PropTypes.string,
  createNewMenuItem: PropTypes.bool,
  showResetButton: PropTypes.bool,
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  }),
  dataSource: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.array
  ]).isRequired,
  dataSourceFilter: PropTypes.func,
  handleAddClick: PropTypes.func,
  handleMultipleSelect: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  onAfterReset: PropTypes.func,
  multiValue: PropTypes.bool,
  onFocus: PropTypes.func,
  selectFromListMenuItem: PropTypes.bool,
  hintTextLabel: PropTypes.node,
  selectedItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
  targetOrigin: PropTypes.shape({}),
  anchorOrigin: PropTypes.shape({}),
  additionalInfoConfig: PropTypes.shape({})
};

ItemSelector.defaultProps = {
  selectedItem: [],
  createNewMenuItem: false,
  showResetButton: false,
  onFocus: e => e,
  multiValue: true,
  selectFromListMenuItem: false,
  dataSourceConfig: {},
  id: null,
  dataSourceFilter: null,
  handleAddClick: e => e,
  handleMultipleSelect: e => e,
  handleSelectedItem: e => e,
  onAfterReset: e => e,
  onSearch: e => e,
  hintTextLabel: null,
  inputType: 'text',
  additionalInfoConfig: {},
  targetOrigin: { vertical: 'top', horizontal: 'left' },
  disabled: false,
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
};


export default ItemSelector;
