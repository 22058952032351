import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

import ThemeProvider from '@packages/ui/hocs/themeProvider';
import ProtectedRoute from '@packages/features/protected-route';
import { history } from '@packages/store/src/store';
import '@packages/ui/styles/styles.scss';
import Loader from '@packages/features/loader';

const GlobalSolutionLayout = lazy(() => import('./layouts/global-solution'));
const MainLayout = lazy(() => import('./layouts/mainLayout'));

function App({ roles }) {
  const isHolding =
    roles.includes('HoldingAdministrator') || roles.includes('Coordinator');

  return (
    <div className="App">
      <ThemeProvider>
        <ConnectedRouter history={history}>
          <Switch>
            {/* Redirection for old public-registry urls */}
            <Route
              path="/public/:registryId"
              render={({ match }) =>
                window.location.replace(`/public/#/${match.params.registryId}`)
              }
            />
            {/* Redirection for old vendorforms urls */}
            <Route
              path="/vendorforms"
              render={() =>
                window.location.replace(`/vendormodule/${window.location.hash}`)
              }
            />
            <ProtectedRoute>
              <Route
                path="/"
                render={(_props) =>
                  isHolding ? (
                    <Suspense fallback={<Loader show={true} />}>
                      <GlobalSolutionLayout {..._props} />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Loader show={true} />}>
                      <MainLayout {..._props} />
                    </Suspense>
                  )
                }
              />
            </ProtectedRoute>
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    </div>
  );
}

App.propTypes = {
  roles: PropTypes.shape([])
};

App.defaultProps = {
  roles: []
};

export default connect(
  (state) => ({
    roles: state.login.get('loggedUser')
      ? state.login.get('loggedUser').get('roles')
      : []
  }),
  null
)(App);
