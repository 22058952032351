import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { filterEmptySearchItems } from '@packages/utils/common-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  initialItems: Immutable.List(),
  adminUsers: Immutable.List(),
  adminUsersPosition: 0,
  position: 0,
  organisationUsers: Immutable.List(),
  newItems: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  sortOrder: '',
  searchText: Immutable.Map(),
  pageSearchText: Immutable.Map({
    name: Immutable.Map(),
    email: Immutable.Map()
  }),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ORGANISATION_USERS:LIST:REQUEST_INIT': {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const { type, filter } = urlParams;
      let newState = state;
      if (type) {
        const filterOnValue = filter.split('=')[1];
        const filterParameters = Object.assign({}, { sortOn: '', sortOrder: '' },
          { filteredOn: [filterOnValue] });
        newState = newState.set('filterParams', Immutable.Map(filterParameters))
          .set('filteredOn',
            getFilteredOn(Object.assign({}, { filteredOn: [filterOnValue] }, { filterKey: 'lastName' })));
      }
      return newState.set('isFetching', true)
        .set('initialItems', defaultState.get('initialItems'))
        .set('organisationUsers', defaultState.get('organisationUsers'))
        .set('newItems', defaultState.get('newItems'))
        .set('error', defaultState.get('error'))
        .set('searchText', defaultState.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('position', 0);
    }
    case 'ORGANISATION_USERS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'ORGANISATION_USERS:LIST:REQUEST:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.organisationUser) {
        newItems = newItems.unshift(action.organisationUser);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('organisationUsers'), action.items);
      return newState.set('isFetching', false)
        .set('initialItems', Immutable.List(action.items))
        .set('organisationUsers', Immutable.List(items))
        .set('position', state.get('position') + action.items.length);
    }
    case 'ORGANISATION_USERS:LIST:REQUEST:FAIL':
      return state.set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'ORGANISATION_USERS:LIST:UPDATE:SUCCESS':
      return state.set('organisationUsers', action.items)
        .set('position', action.position ? action.position : state.get('position'))
        .set('newItems', action.newItems);
    case 'ORGANISATION_USERS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', state.get('position') + 1)
          .set('organisationUsers', defaultState.get('organisationUsers'));
      }
      return newState.set('isFetching', false)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'ORGANISATION_USERS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('organisationUsers', Immutable.List(action.items))
        .set('newItems', defaultState.get('newItems'))
        .set('initialItems', Immutable.List(action.items))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'ORGANISATION_USER:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));

    case 'ORGANISATION_USERS:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('error', defaultState.get('error'));
    }
    case 'ORGANISATION_USERS:PAGE_SEARCH': {
      const { searchParameters } = action;
      return state
        .set('isFetching', true)
        .set('pageSearchText', searchParameters)
        .set('newItems', defaultState.get('newItems'))
        .set('error', defaultState.get('error'));
    }
    case 'ORGANISATION_USERS:LIST:SEARCH:SUCCESS':
      return state
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', getSearchItems(action))
        .set('error', defaultState.get('error'));
    case 'ORGANISATION_USERS:PAGE_SEARCH:SUCCESS': {
      const { searchParameters } = action;
      return state
        .set('isFetching', false)
        .set('pageSearchText', searchParameters)
        .set('organisationUsers', Immutable.List(action.items))
        .set('position', action.items.length);
    }

    case 'ADMIN_USERS:LIST:REQUEST_INIT':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'))
        .set('adminUsers', defaultState.get('adminUsers'))
        .set('adminUsersPosition', defaultState.get('adminUsersPosition'));
    case 'ADMIN_USERS:LIST:FETCH_INIT':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'ADMIN_USERS:LIST:REQUEST:SUCCESS':
      return state.set('isFetching', false)
        .set('adminUsers', state.get('adminUsers').concat(Immutable.List(action.items)))
        .set('adminUsersPosition', state.get('adminUsersPosition') + action.items.length);
    case 'ADMIN_USERS:LIST:REQUEST:FAIL':
      return state.set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'ADMIN_USERS:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    }
    case 'ADMIN_USERS:LIST:SEARCH:SUCCESS': {
      const { searchParameters } = action;
      return state
        .set('isFetching', false)
        .set('searchAdminText', searchParameters)
        .set('adminUsers', Immutable.List(action.items))
        .set('searchPosition', action.items.length);
    }
    default:
      return state;
  }
};

export const getSearchItems = ({ searchKey, items }) => {
  const listItems = Array.from(new Set(items.map(item => item[searchKey])));
  const searchItems = filterEmptySearchItems(listItems, searchKey);
  return searchItems;
};
