import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-grid-system';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  commonTranslations,
  recordTranslations,
  statusValueMapper
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import Card from '@packages/components/card';
import {
  pluralTerms as uppercasePlurals,
  singularTerms as uppercaseSingulars
} from '@packages/utils/uppercaseTranslations';
import DivWrapper from '@packages/components/divWrapper';
import {
  removeTranslations,
  navigationTranslations
} from '@packages/utils/actionTranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import CustomDialog from '@packages/components/custom-dialog';

import ItemList from '../../../../privacy-record-detail/components/item-list';
import LinkGroupList from '../../../../privacy-record-detail/processings/components/link-group-items';

const generalFields = [
  'processingCategories',
  'status',
  'references',
  'purposes',
  'jurisdictions'
];

const stakeholdersFields = [
  'controllers',
  'processors',
  'executingEntities',
  'dataRecipients',
  'dataRecipientCategories'
];

const processedDataFields = [
  'dataSubjectCategories',
  'personalDataCategories',
  'personalDataItems',
  'dataSources',
  'dataSourceCategories',
  'retentionTerms',
  'technicalSecurityMeasures',
  'organisationalSecurityMeasures'
];

const legalQualificationsFields = [
  'processingGrounds',
  'internationalTransfer',
  'purposesOfTransfer',
  'transferGrounds'
];

const attachmentsAndPermissionsFields = [
  'attachments',
  'userRights',
  'organisationRights',
  'companyAccess'
];

const DIALOG_MAX_HEIGHT = 400;
const WINDOW_INNER_HEIGHT = window.innerHeight;

export const BulkUpdateSummary = (props) => {
  const {
    open,
    selectedRecords,
    updateItems,
    visibleFields,
    requestClose,
    handleGoBack,
    checkedFields,
    updateType,
    bulkUpdate,
    resetBulkItems
  } = props;

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const getOddEvenClassName = (index) =>
    index % 2 === 0 ? 'table-row even-row' : 'table-row';

  const renderItemList = (field) => (
    <Col md={6} lg={4}>
      <ItemList
        header={recordTranslations[field]}
        content={updateItems[field]}
        fontStyle={styles.itemListStyle}
        mapper={field === 'status' && statusValueMapper}
        showTextField={updateType !== 'Bulk_Clear_Fields'}
      />
    </Col>
  );

  const getUpdateType = () => {
    switch (updateType) {
      case 'Bulk_Remove':
        return removeTranslations('remove');
      case 'Bulk_Replace':
        return uppercaseSingulars('replace');
      case 'Bulk_Update':
        return uppercaseSingulars('add');
      case 'Bulk_Clear_Fields':
        return uppercaseSingulars('emptyFields');
      default:
        return '';
    }
  };

  const getDialogTitle = () => (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 200 }}>{recordTranslations.bulkUpdate}</div>
      <div style={{ display: 'flex', fontSize: 17 }}>
        <div style={{ marginRight: 5 }}>{recordTranslations.updateType}:</div>
        {getUpdateType()}
      </div>
    </div>
  );

  const handleBulkUpdate = () => {
    const recordIds = selectedRecords.map((item) => item.id);
    let modifiedItems = {};
    if (updateType === 'Bulk_Clear_Fields') {
      Object.keys(checkedFields).forEach((item) => {
        if (checkedFields[item] === true) {
          modifiedItems[item] = [];
        }
      });
    } else modifiedItems = updateItems;
    bulkUpdate(updateType, modifiedItems, recordIds);
    requestClose();
  };

  const handleCloseConfirmation = () => {
    setOpenCancelDialog(true);
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
    requestClose();
    resetBulkItems();
  };

  const handleCancel = () => {
    setOpenCancelDialog(false);
  };

  const showFields = (fields) =>
    fields.some(
      (field) =>
        updateItems[field] !== undefined ||
        (updateType === 'Bulk_Clear_Fields' && checkedFields[field])
    );

  return (
    <div>
      {open && (
        <CommonDialog
          id="bulk-update-summary-dialog"
          show={open}
          onCancel={requestClose}
          title={getDialogTitle()}
          fullWidth={true}
          subTitle={
            <div style={{ fontSize: 14, marginTop: -12 }}>
              {recordTranslations.recordsSelected}: {selectedRecords.length}
            </div>
          }
        >
          <DivWrapper
            autoHeight={true}
            autoHeightMax={getDialogContentHeight(
              WINDOW_INNER_HEIGHT,
              DIALOG_MAX_HEIGHT
            )}
          >
            <div style={{ marginRight: '15px' }}>
              <div style={{ marginTop: '25px', marginBottom: 20 }}>
                {recordTranslations.bulkUpdateDescription}
              </div>
              <DivWrapper autoHeight={true} autoHeightMax={300}>
                <Table>
                  <TableRow style={{ backgroundColor: '#c8c9cc' }}>
                    <TableCell>{recordTranslations.recordId}</TableCell>
                    <TableCell>{commonTranslations.name}</TableCell>
                  </TableRow>
                  <TableBody>
                    {selectedRecords.map((item, index) => (
                      <TableRow
                        className={getOddEvenClassName(index)}
                        style={{ height: 10 }}
                      >
                        <TableCell>{item.privacyRecordNumber}</TableCell>
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DivWrapper>
              <div style={{ marginTop: '25px', marginBottom: 20 }}>
                {updateType === 'Bulk_Clear_Fields'
                  ? recordTranslations.bulkEmptyMasterdata
                  : recordTranslations.bulkUpdateMasterdata}
              </div>
              <div>
                {showFields(generalFields) && (
                  <Card
                    title={uppercaseSingulars('general')}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    <Row>
                      {generalFields.map(
                        (field) =>
                          (updateItems[field] || checkedFields[field]) &&
                          renderItemList(field)
                      )}
                    </Row>
                  </Card>
                )}
                {showFields(stakeholdersFields) && (
                  <Card
                    title={uppercasePlurals('Stakeholders')}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    <Row>
                      {stakeholdersFields.map(
                        (field) =>
                          (updateItems[field] || checkedFields[field]) &&
                          renderItemList(field)
                      )}
                    </Row>
                  </Card>
                )}
                {showFields(['links']) && (
                  <Card
                    title={recordTranslations.groups}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    {updateType !== 'Bulk_Clear_Fields' ? (
                      <LinkGroupList
                        key="links"
                        containerHeight={400}
                        fontStyle={styles.itemListStyle}
                        visibleFields={visibleFields}
                        showCardTitle={false}
                        source="processing"
                        selectedItems={updateItems.links}
                        isEditable={false}
                        isOnlyEdit={false}
                        isOnlyDelete={false}
                        tableWidth={window.innerWidth - 210}
                      />
                    ) : (
                      <Row>
                        {updateType === 'Bulk_Clear_Fields' && (
                          <div style={{ marginLeft: '27px', color: '#807E7E' }}>
                            {recordTranslations.groups}
                          </div>
                        )}
                      </Row>
                    )}
                  </Card>
                )}
                {showFields(processedDataFields) && (
                  <Card
                    title={uppercaseSingulars('processedData')}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    <Row>
                      {processedDataFields.map(
                        (field) =>
                          (updateItems[field] || checkedFields[field]) &&
                          renderItemList(field)
                      )}
                    </Row>
                  </Card>
                )}
                {showFields(legalQualificationsFields) && (
                  <Card
                    title={uppercasePlurals('legalQualifications')}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    <Row>
                      {legalQualificationsFields.map(
                        (field) =>
                          (updateItems[field] || checkedFields[field]) &&
                          renderItemList(field)
                      )}
                    </Row>
                  </Card>
                )}
                {showFields(attachmentsAndPermissionsFields) && (
                  <Card
                    title={recordTranslations.attachmentsAndPermissions}
                    expanded={true}
                    headerStyle={{ marginTop: -35 }}
                  >
                    <>
                      <Row>
                        {updateItems.attachments &&
                          renderItemList('attachments')}
                      </Row>
                      <Row>
                        {attachmentsAndPermissionsFields.map(
                          (field) =>
                            ((updateItems[field] && field !== 'attachments') ||
                              checkedFields[field]) &&
                            renderItemList(field)
                        )}
                      </Row>
                    </>
                  </Card>
                )}
              </div>
            </div>
          </DivWrapper>
          <div style={{ width: '100%', display: 'flex', marginTop: 12 }}>
            <div style={{ width: '80%' }}>
              <Button id="bulkUpdate_goBack" onClick={handleGoBack}>
                {navigationTranslations('goBack')}
              </Button>
            </div>
            <Button
              id="confirm_bulkUpdate"
              style={{ marginRight: 17 }}
              onClick={handleBulkUpdate}
            >
              {commonTranslations.confirm}
            </Button>
            <Button
              id="cancel_bulkUpdate"
              variant="text"
              onClick={handleCloseConfirmation}
              style={{ marginRight: 17 }}
            >
              {commonTranslations.Cancel}
            </Button>
          </div>
        </CommonDialog>
      )}
      {openCancelDialog && (
        <CustomDialog
          show={openCancelDialog}
          proceed={handleClose}
          title={recordTranslations.bulkUpdate}
          cancel={handleCancel}
          content={recordTranslations.bulkUpdateCancelConfirmation}
        />
      )}
    </div>
  );
};

BulkUpdateSummary.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  selectedRecords: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool,
  updateItems: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    links: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  checkedFields: PropTypes.shape(),
  visibleFields: PropTypes.arrayOf(PropTypes.string),
  updateType: PropTypes.string,
  requestClose: PropTypes.func,
  handleGoBack: PropTypes.func,
  bulkUpdate: PropTypes.func,
  resetBulkItems: PropTypes.func
};

BulkUpdateSummary.defaultProps = {
  selectedRecords: [],
  open: false,
  updateType: '',
  updateItems: {},
  checkedFields: {},
  visibleFields: [],
  requestClose: (e) => e,
  handleGoBack: (e) => e,
  bulkUpdate: (e) => e,
  resetBulkItems: (e) => e
};

export default injectIntl(BulkUpdateSummary);
