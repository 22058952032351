import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  currentFilter: 'all',
  currentFilterData: {}
});

const getPreviousLocation = () => {
  const hashURL = window.location.hash;
  const urlParams = getQueryStrings(hashURL);
  if (urlParams.from === 'overview') {
    return 'overview';
  }
  return undefined;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY_RECORDS:FILTERS:INIT': {
      return state.set('prevLocation', getPreviousLocation());
    }
    case 'PRIVACY_RECORDS:FILTERS:RESET': {
      return state.set('currentFilter', defaultState.get('currentFilter'));
    }
    case 'PRIVACY_RECORDS:FILTERS:CHANGE': {
      return state
        .set('currentFilter', action.filterName)
        .set('currentFilterData', action.data);
    }
    default:
      return state;
  }
};
