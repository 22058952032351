import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import PersonalItemCategory from './personalItemCategory';

const mapStateToProps = (state) => ({
  personalItemCategorydata: state.environment.personalDataCategories.get(
    'personalItemCategorydata'
  ),
  isGlobal: state.home.get('isGlobal'),
  actionError: state.environment.personalDataCategories.get('error').toJS(),
  isJobActive: state.environmentReducer.get('isJobActive'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'PERSONAL_DATA_CATEGORIES:ITEM:FETCH', id });
  },
  updatePersonalItemCategories: (
    data,
    isEdit,
    source,
    isUsed,
    isEditWithJob
  ) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        actionType: 'Edit',
        entityType: 'PersonalDataCategory',
        data
      });
    } else {
      dispatch({
        type: 'PERSONAL_DATA_CATEGORIES:UPSERT',
        data,
        isEdit,
        source,
        isEditWithJob
      });
    }
  },
  resetPersonalItemCategories: () => {
    dispatch({ type: 'MASTERDATA:PERSONAL_DATA_CATEGORIES:ITEM:FETCH:INIT' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalItemCategory);
