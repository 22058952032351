import React from 'react';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import DLALogo from '@packages/assets/icons/dla-logo.png';
import countryTranslations from '@packages/utils/countryTranslations';
import { singularTerms } from '@packages/utils/uppercaseTranslations';

import { booleanMapper } from '../../common-utils';
import {
  transformDefaultRequestData,
  transformDefaultResponseData
} from '../../saga-utils';
import tiaTranslations from './tiaTranslations';
import { processingActivityRole } from '../../processings/utils/processingUtils';

export const severityOfHarmOptions = [
  { value: 'LowSeverityOfHarm', label: recordTranslations.severityOfHarmLow },
  {
    value: 'MediumSeverityOfHarm',
    label: recordTranslations.severityOfHarmMedium
  },
  { value: 'HighSeverityOfHarm', label: recordTranslations.severityOfHarmHigh }
];

export const likelihoodOfHarmOptions = [
  {
    value: 'LowProbabilityOfHarm',
    label: recordTranslations.likelihoodOfHarmLow
  },
  {
    value: 'MediumProbabilityOfHarm',
    label: recordTranslations.likelihoodOfHarmMedium
  },
  {
    value: 'HighProbabilityOfHarm',
    label: recordTranslations.likelihoodOfHarmHigh
  }
];

export const securityMeasureImpacts = [
  { label: tiaTranslations.noAdditionalMeasures, value: 'NoAdditionalMeasure' },
  { label: tiaTranslations.limitedProtection, value: 'LimitedProtection' },
  { label: tiaTranslations.highProtection, value: 'HighProtection' },
  { label: tiaTranslations.decreaseProtection, value: 'DecreaseProtection' }
];

export const lawsAndPracticeMessages = {
  regulationOnProcessing: {
    header: tiaTranslations.regulationOnProcessingHeader,
    description: tiaTranslations.regulationOnProcessingDescription
  },
  regulationOnAccess: {
    header: tiaTranslations.regulationOnAccessHeader,
    description: tiaTranslations.regulationOnAccessDescription
  },
  regulatorySupervision: {
    header: recordTranslations.regulatorySupervision,
    description: tiaTranslations.regulatorySupervisionDescription
  },
  rightsOfRedress: {
    header: recordTranslations.rightsOfRedress,
    description: tiaTranslations.rightsOfRedressDescription
  },
  internationalTreaties: {
    header: recordTranslations.internationalTreaties,
    description: tiaTranslations.internationalTreatiesDescription
  }
};

export const severityOfHarmRoleMapper = (value) =>
  severityOfHarmOptions.find((data) => data.value === value).label;

export const likelihoodOfHarmRoleMapper = (value) =>
  likelihoodOfHarmOptions.find((data) => data.value === value).label;

export const lawsAndPracticeScoringList = [
  {
    key: 1,
    description: tiaTranslations.scoring1
  },
  {
    key: 2,
    description: tiaTranslations.scoring2
  },
  {
    key: 3,
    description: tiaTranslations.scoring3
  },
  {
    key: 4,
    description: tiaTranslations.scoring4
  },
  {
    key: 5,
    description: tiaTranslations.scoring5
  }
];

// labels of sector to be updated
export const sector = [
  { label: tiaTranslations.energy, value: 'Energy' },
  { label: tiaTranslations.materials, value: 'Materials' },
  { label: tiaTranslations.industrials, value: 'Industrials' },
  { label: tiaTranslations.hospitality, value: 'Hospitality' },
  { label: tiaTranslations.healthCare, value: 'HealthCare' },
  { label: tiaTranslations.financials, value: 'Financials' },
  {
    label: tiaTranslations.informationTechnology,
    value: 'InformationTechnology'
  },
  {
    label: tiaTranslations.telecommunicationServices,
    value: 'TelecommunicationServices'
  },
  { label: tiaTranslations.utilities, value: 'Utilities' },
  { label: tiaTranslations.realEstate, value: 'RealEstate' },
  { label: tiaTranslations.government, value: 'Government' },
  { label: tiaTranslations.logistics, value: 'Logistics' },
  { label: singularTerms('Other'), value: 'Other' }
];

export const sectorMapper = (value) =>
  sector.find((data) => data.value === value)?.label;

export const getLawsAndPracticeScoreColor = (score) => {
  switch (score) {
    case 0:
      return '';
    case 1:
      return '#00ad55';
    case 2:
      return '#b7dd29';
    case 3:
      return '#ffa900';
    case 4:
      return '#ff7100';
    case 5:
      return '#ff0000';
    default:
      return '';
  }
};

export const includeInCalculationMapper = (value) =>
  typeof value === 'boolean' ? booleanMapper(value) : value;
export const securityMeasureImpactMapper = (value) =>
  securityMeasureImpacts.find((item) => item.value === value).label;

export const transformRequestData = (requestData, isGlobal) => {
  const getModifiedCountries = (countries) =>
    countries.map((el) => ({ value: { id: el.value.countryCode } }));
  const reqData = Object.assign({}, requestData);
  if (reqData.dataExporters) {
    reqData.dataExporters = reqData.dataExporters
      .filter((item) => item.enableDetails)
      .map((item) => ({
        ...item,
        countries: getModifiedCountries(item.countries),
        organisation: { id: item.organisation[0].value?.id },
        note: item.organisation[0].note,
        onwardTransfers: {
          hasOnwardTransfer: false,
          onwardDataTransfers: []
        }
      }));
  }
  if (reqData.dataImporters) {
    reqData.dataImporters = reqData.dataImporters
      .filter((item) => item.enableDetails)
      .map((item) =>
        Object.keys(item).length
          ? {
            ...item,
            countries: getModifiedCountries(item.countries),
            organisation: { id: item.organisation[0]?.value?.id },
            onwardTransfers: {
              onwardDataTransfers: item.onwardTransfers?.filter(
                (val) => val.organisation
              )?.length
                ? item.onwardTransfers.map((value) => ({
                  ...value,
                  organisation: { id: value.organisation[0]?.value?.id },
                  countries: getModifiedCountries(value.countries),
                  note: value.organisation[0].note
                }))
                : [],
              hasOnwardTransfer: item.enableOnwardTransfer || false
            },
            note: item.organisation[0].note,
            contractHolder:
                item.showContractHolder === 'true'
                  ? []
                  : [
                    {
                      ...item.contractHolder,
                      organisation: {
                        id: item.contractHolder?.organisation[0]?.value?.id
                      },
                      note: item.contractHolder?.organisation[0]?.note
                    }
                  ]
          }
          : {}
      );
  }
  return transformDefaultRequestData(reqData, isGlobal); // Transform records request data
};

export const transformResponseData = (
  responseData,
  currentUser,
  isFromTemplate,
  isUseDefault = true
) => {
  const getModifiedCountries = (countries) =>
    countries.map((el) => {
      const country = countryTranslations[el.value.id];
      return {
        value: { countryCode: el.value.id, key: country, value: country }
      };
    });
  const respData = Object.assign({}, responseData);
  if (respData.dataExporters) {
    respData.dataExporters =
      respData.dataExporters.length > 0
        ? respData.dataExporters.map((item) => ({
          ...item,
          countries: getModifiedCountries(item.countries),
          organisation: [
            { value: { ...item.organisation }, note: item.note }
          ],
          onwardTransfers: [],
          enableDetails: true
        }))
        : [{ enableDetails: false }];
  }
  if (respData.dataImporters) {
    respData.dataImporters =
      respData.dataImporters.length > 0
        ? respData.dataImporters.map((item) => ({
          ...item,
          organisation: [
            { value: { ...item.organisation }, note: item.note }
          ],
          countries: getModifiedCountries(item.countries),
          enableDetails: true,
          onwardTransfers:
              item.onwardTransfers?.onwardDataTransfers?.length > 0
                ? item.onwardTransfers?.onwardDataTransfers.map((data) => ({
                  ...data,
                  enableDetails: true,
                  organisation: [
                    { value: { ...data.organisation }, note: data.note }
                  ],
                  countries: getModifiedCountries(data.countries)
                }))
                : [{ enableDetails: false }],
          enableOnwardTransfer: item?.onwardTransfers?.hasOnwardTransfer,
          contractHolder: {
            ...item.contractHolder[0],
            organisation: [
              {
                value: { ...item.contractHolder[0]?.organisation },
                note: item.contractHolder[0]?.note
              }
            ]
          },
          showContractHolder: item.contractHolder?.length ? 'false' : 'true'
        }))
        : [{ enableDetails: false }];
  }
  if (isUseDefault)
    return transformDefaultResponseData(respData, currentUser, isFromTemplate);
  // Transform records response data
  else return respData;
};

export const lawsAndPracticeTypes = [
  'regulationOnProcessing',
  'regulationOnAccess',
  'regulatorySupervision',
  'rightsOfRedress',
  'internationalTreaties'
];

export const colorMap = {
  lawsPractice: (value) => {
    switch (value) {
      case 1:
        return { background: '#00ad55' };
      case 2:
        return { background: '#b7dd29' };
      case 3:
        return { background: '#ffa900' };
      case 4:
        return { background: '#ff7100' };
      case 5:
        return { background: '#ff0000' };
      default:
        return { background: '#fd5c63' };
    }
  },
  secMeasure: {
    HighProtection: { background: '#00ad55' },
    LimitedProtection: { background: '#ffa900' },
    NoAdditionalMeasure: { background: '#ff7100' },
    DecreaseProtection: { background: '#ff0000' }
  },
  specificCircum: {
    High: { background: '#ff0000' },
    Medium: { background: '#ffa900' },
    Low: { background: '#00ad55' }
  },
  outcome: (score) => {
    switch (true) {
      case score <= 6:
        return { background: '#00ad55' };
      case score > 6 && score < 13:
        return { background: '#ffa900' };
      case score >= 13 && score < 19:
        return { background: '#ff7100' };
      default:
        return { background: '#ff0000' };
    }
  },
  score: (score) => {
    switch (true) {
      case score <= 5:
        return { background: '#00ad55' };
      case score > 5 && score < 10:
        return { background: '#ffa900' };
      default:
        return { background: '#ff0000' };
    }
  },
  // eslint-disable-next-line object-shorthand
  get: function (type, val) {
    if (!val || type === 'unstyled')
      return { background: '#e1e1e1', color: '#000' };
    if (typeof this[type] === 'object') {
      return this[type][val] ?? this[type].default;
    }
    return this[type](val);
  }
};

const getScore = (chapter, type, val) =>
  ({
    impacts: {
      technical: {
        NoAdditionalMeasure: 1,
        LimitedProtection: 0.5,
        HighProtection: -0.25,
        DecreaseProtection: 1.56
      },
      organisational: {
        NoAdditionalMeasure: 1,
        LimitedProtection: 0.5,
        HighProtection: 0.25,
        DecreaseProtection: 1.25
      },
      contractual: {
        NoAdditionalMeasure: 1,
        LimitedProtection: 0.15,
        HighProtection: 0.05,
        DecreaseProtection: 1.25
      }
    },
    specificCircumstance: {
      severityOfHarm: {
        LowSeverityOfHarm: 0.75,
        MediumSeverityOfHarm: 1,
        HighSeverityOfHarm: 1.25
      },
      likelihoodOfHarm: {
        LowProbabilityOfHarm: 0.75,
        MediumProbabilityOfHarm: 1,
        HighProbabilityOfHarm: 1.25
      }
    }
  }[chapter][type][val]);

export const getLevel = (type) =>
  ({
    LowSeverityOfHarm: 'Low',
    LowProbabilityOfHarm: 'Low',
    MediumSeverityOfHarm: 'Medium',
    MediumProbabilityOfHarm: 'Medium',
    HighSeverityOfHarm: 'High',
    HighProbabilityOfHarm: 'High'
  }[type]);

export const getSecMeasureLabel = (val) =>
  ({
    NoAdditionalMeasure: tiaTranslations.noAdditionalMeasures,
    LimitedProtection: tiaTranslations.limitedProtectionShort,
    HighProtection: tiaTranslations.highProtectionShort,
    DecreaseProtection: tiaTranslations.decreaseProtectionShort
  }[val]);

export const getRiskScore = (lawAndPractices = [], type, scoreType) => {
  let score = 0;
  const scores = lawAndPractices
    .filter((item) => item[type].score > 0)
    .map((item) => item[type].score);
  if (scores.length) {
    score =
      scoreType.toLowerCase() === 'highest'
        ? Math.max(...scores)
        : Math.ceil(
          scores.reduce((score1, score2) => score1 + score2, 0) /
              scores.length
        );
  }
  return score;
};

export const getInherentRiskScore = (
  lawAndPractices = [],
  lawsAndPracticeScore
) =>
  lawsAndPracticeTypes.reduce(
    (acc, curr) =>
      acc + getRiskScore(lawAndPractices, curr, lawsAndPracticeScore),
    0
  );

export const getCalculatedModifier = (impacts) =>
  Object.entries(impacts)
    .reduce((acc, curr) => acc + getScore('impacts', curr[0], curr[1]) / 3, 0)
    .toFixed(2);

export const getResidualScore = (calculatedModifier, inherentRiskScore) =>
  (calculatedModifier * inherentRiskScore).toFixed(2);

export const getSpecificCircumModifier = (severityOfHarm, likelihoodOfHarm) =>
  (
    (getScore('specificCircumstance', 'severityOfHarm', severityOfHarm) +
      getScore('specificCircumstance', 'likelihoodOfHarm', likelihoodOfHarm)) /
    2
  ).toFixed(2);

export const getCaliberatedRisk = (specificCircumModifier, residualScore) =>
  (specificCircumModifier * residualScore).toFixed(2);

export const getFinalOutcome = (score) => {
  switch (true) {
    case score <= 6:
      return {
        riskLevel: recordTranslations.riskLevelLow,
        conclusion: recordTranslations.riskLevelLowGuidance,
        riskValue: recordTranslations.lowRiskLevel
      };
    case score > 6 && score < 13:
      return {
        riskLevel: recordTranslations.riskLevelMediumLow,
        conclusion: recordTranslations.riskLevelMediumLowGuidance,
        riskValue: recordTranslations.mediumRiskLevel
      };
    case score >= 13 && score < 19:
      return {
        riskLevel: recordTranslations.riskLevelMediumHigh,
        conclusion: recordTranslations.riskLevelMediumHighGuidance,
        riskValue: recordTranslations.highRiskLevel
      };
    default: // > 19
      return {
        riskLevel: recordTranslations.riskLevelHigh,
        conclusion: recordTranslations.riskLevelHighGuidance,
        riskValue: recordTranslations.highRiskLevel
      };
  }
};

export const dataExportersMapper = (row) => [
  row.organisation?.[0]?.value.name,
  row.countries
    ?.map(({ value }) => value.value)
    ?.reduce((prev, curr) => prev ? [prev, ', ', curr] : curr, null),
  processingActivityRole.find((item) => item.value === row.role)?.label,
  sector.find((data) => data.value === row.sector)?.label
];

export const dataImportersMapper = (row) => [
  row.organisation?.[0]?.value.name,
  row.countries
    ?.map(({ value }) => value.value)
    .reduce((prev, curr) => prev ? [prev, ', ', curr] : curr, null),
  processingActivityRole.find((item) => item.value === row.role)?.label,
  sector.find((data) => data.value === row.sector)?.label,
  { true: commonTranslations.Yes, false: commonTranslations.No }[
    row.includeInCalculation
  ]
];

export const getDLALogo = () => (
  <img alt="dla logo" src={DLALogo} style={{ height: '80px' }} />
);

export const getTiaVendorData = (defaultReqData, data) => {
  const getModifiedCountries = (countries) =>
    countries.map((el) => ({ value: { id: el.value.countryCode } }));

  const vendorData = data.dataImporters?.map((item) => ({
    ...item,
    organisation: { id: item.organisation[0]?.value?.id },
    countries: getModifiedCountries(item.countries)
  }));

  const modifiedDataImporters =
    defaultReqData.dataImporters?.length < 5
      ? [...vendorData, ...defaultReqData.dataImporters]
      : [...vendorData, ...defaultReqData.dataImporters.slice(0, 4)];
  return {
    ...defaultReqData,
    name: data.name,
    dataImporters: modifiedDataImporters
  };
};
