import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export default function* fetchSupervisoryAuthorities() {
  try {
    const response = yield registry.get('request')
      .get(`/v1/masterdata/supervisoryauthorities`, null);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: item.name
        }));
        yield put({ type: 'SUPERVISORY_AUTHORITIES:LIST:REQUEST:SUCCESS', items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'SUPERVISORY_AUTHORITIES:LIST:REQUEST:FAIL', error: err.message });
  }
}
