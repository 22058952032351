import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import { settingsTranslations } from './settingsTranslations';
import dsrTranslations from '../dsr/dsrTranslations';

const filterMenu = [
  {
    label: settingsTranslations.general,
    key: 'general',
    enable: true,
    defaultFilter: 'interfacestyle_general',
    subMenu: [
      {
        key: 'interfacestyle_general',
        label: settingsTranslations.interfaceStyle
      },
      {
        key: 'tenantEmailPreference_general',
        label: settingsTranslations.tenantEmailPreference
      }
    ]
  },
  {
    label: settingsTranslations.auditTrailSettings,
    key: 'auditTrailSettings',
    enable: true,
    defaultFilter: 'auditTrailSettings'
  },
  {
    label: settingsTranslations.fieldvisibility,
    key: 'fieldVisibility',
    enable: true,
    defaultFilter: 'processing_fieldVisibility',
    subMenu: [
      {
        key: 'processing_fieldVisibility',
        label: uppercasePlural('processings')
      },
      {
        key: 'assessment_fieldVisibility',
        label: uppercasePlural('dpias')
      }
    ]
  },
  {
    label: settingsTranslations.security,
    key: 'security',
    enable: true,
    defaultFilter: 'passwordsettings__security',
    subMenu: [
      {
        key: 'passwordsettings__security',
        label: settingsTranslations.password
      },
      {
        key: 'sso_security',
        label: settingsTranslations.sso
      }
    ]
  },
  {
    label: dsrTranslations.settings,
    key: 'dsr',
    enable: true,
    defaultFilter: 'dsr_dsr'
  },
  {
    label: settingsTranslations.custom,
    key: 'custom',
    enable: true,
    defaultFilter: 'processing_helpTexts_custom',
    subMenu: [
      {
        key: 'helpTexts_custom',
        label: settingsTranslations.helpTexts,
        enable: true,
        defaultFilter: 'processing_helpTexts_custom',
        subMenu: [
          {
            key: 'processing_helpTexts_custom',
            label: uppercasePlural('processings')
          },
          {
            key: 'breach_helpTexts_custom',
            label: uppercasePlural('breaches')
          },
          {
            key: 'assessment_helpTexts_custom',
            label: uppercasePlural('dpias')
          }
        ]
      }
    ]
  },
  {
    label: settingsTranslations.subscription,
    key: 'subscription',
    enable: true,
    defaultFilter: 'subscription_subscription'
  },
  {
    label: settingsTranslations.profile,
    key: 'profile',
    enable: true,
    defaultFilter: 'userdetails_profile',
    subMenu: [
      {
        key: 'userdetails_profile',
        label: settingsTranslations.userDetails
      },
      {
        key: 'changepassword_profile',
        label: settingsTranslations.changePassword
      },
      {
        key: 'setlanguage_profile',
        label: settingsTranslations.setLanguage
      },
      {
        key: 'userEmailPreference_profile',
        label: settingsTranslations.userEmailPreference
      },
      {
        key: 'mfa_profile',
        label: settingsTranslations.mfa
      }
    ]
  },
  {
    label: settingsTranslations.integration,
    key: 'integration',
    enable: true,
    defaultFilter: 'apiTokens_integration',
    subMenu: [
      {
        key: 'apiTokens_integration',
        label: settingsTranslations.apiTokens
      },
      {
        key: 'publicRegistry_integration',
        label: settingsTranslations.publicRegistry
      }
    ]
  }
];

export default filterMenu;
