import { commonTranslations } from '@packages/utils/commontranslations';
import {
  singularTerms,
  pluralTerms
} from '@packages/utils/uppercaseTranslations';

const filterMenu = [
  {
    label: singularTerms('masterData'),
    key: 'masterDataLibrary',
    enable: true,
    defaultFilter: 'masterDataLibrary',
    feature: ['dataLibrary']
  },
  {
    label: commonTranslations.layouts,
    key: 'layoutsLibrary',
    enable: true,
    defaultFilter: 'layoutsLibrary',
    subMenu: [],
    feature: ['layoutsLibrary']
  },
  {
    label: pluralTerms('template'),
    key: 'templatesLibrary',
    enable: true,
    defaultFilter: 'templatesLibrary',
    subMenu: [],
    feature: ['templatesLibrary']
  },
  {
    label: commonTranslations.records,
    key: 'recordsLibrary',
    enable: true,
    defaultFilter: 'recordsLibrary',
    subMenu: [],
    feature: ['recordsLibrary']
  },
  {
    label: pluralTerms('jobs'),
    key: 'jobs',
    enable: true,
    defaultFilter: 'jobs',
    feature: [
      'layoutsLibrary',
      'templatesLibrary',
      'recordsLibrary',
      'dataLibrary'
    ]
  }
];

export default filterMenu;
