import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { getFilteredOn } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  filter: {
    type: ''
  },
  position: 0,
  items: Immutable.List([]),
  title: '',
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  prevLocation: '/environment',
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'lastChanged',
    sortOrder: 'DESC'
  }),
  filterColumn: {}
});

const getPreviousLocation = (
  recordId,
  recordType,
  isTemplateMode,
  isView,
  layoutId
) => {
  const hashURL = window.location.hash;
  const urlParams = getQueryStrings(hashURL);
  if (recordType === 'custom') {
    return `custom-${
      isTemplateMode ? 'template' : 'record'
    }/${layoutId}/${recordId}/${isView ? `view` : `edit`}`;
  } else if (recordType && recordId) {
    return `/${recordType}${isTemplateMode ? '-template' : ''}/${recordId}/${
      isView ? `view` : `edit`
    }`;
  } else if (
    urlParams.from === 'overview' ||
    urlParams.from === 'environment'
  ) {
    return urlParams.from;
  } else if (hashURL.includes('graph')) {
    const prevLoc = hashURL.replace('#', '.');
    return prevLoc;
  }
  return 'environment';
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRIVACY:RECORDS:LIST:REQUEST:INIT':
      return state
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'PRIVACY:RECORDS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'PRIVACY:RECORDS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length)
        .set('filter', action.filter);
    case 'PRIVACY:RECORDS:LIST:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'PRIVACY:RECORDS:LIST:FILTER': {
      const { filterParams, appendFilter = true } = action;
      const newFilterParams = {
        [filterParams.filterKey]: filterParams.filterKey
      };
      const filterColumn = appendFilter
        ? Object.assign({}, state.get('filterColumn').toJS(), {
          [filterParams.filterKey]: filterParams.filterKey
        })
        : newFilterParams;
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set(
          'filteredOn',
          getFilteredOn(
            Object.assign({}, action.filterParams, {
              filterObj: state.get('filteredOn')
            })
          )
        );
    }
    case 'PRIVACY:RECORDS:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'ENTITY:NAME:FETCH:SUCCESS':
      return state.set('title', action.name);
    case 'ENVIRONMENT:PREV_LOCATION:CHANGE': {
      const modifiedPrevLocation = getPreviousLocation(
        action.recordId,
        action.recordType
      );
      return state.set('prevLocation', modifiedPrevLocation);
    }
    case 'ENVIRONMENT:PREV_LOCATION:RESET': {
      return state.set('prevLocation', defaultState.get('prevLocation'));
    }
    case 'PRIVACY:RECORDS:PREVIOUS_LOCATION:SET': {
      const modifiedPrevLocation = getPreviousLocation(
        action.recordId,
        action.recordType,
        action.isTemplateMode,
        action.isView,
        action.layoutId
      );
      return state.set('prevLocation', modifiedPrevLocation);
    }
    default:
      return state;
  }
};
