import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  entitySelectorSearch: Immutable.Map({
    references: {
      searchResults: Immutable.List()
    },
    dataSourceCategories: {
      searchResults: Immutable.List()
    },
    qualityControls: {
      searchResults: Immutable.List()
    },
    accountabilityMeasures: {
      searchResults: Immutable.List()
    },
    dataProtectionMeasures: {
      searchResults: Immutable.List()
    },
    policies: {
      searchResults: Immutable.List()
    },
    purposes: {
      searchResults: Immutable.List()
    },
    mitigatingMeasures: {
      searchResults: Immutable.List()
    },
    purposesOfTransfer: {
      searchResults: Immutable.List()
    },
    riskDetails: {
      searchResults: Immutable.List()
    },
    findings: {
      searchResults: Immutable.List()
    },
    decisions: {
      searchResults: Immutable.List()
    },
    confidentialityThreats: {
      searchResults: Immutable.List()
    },
    integrityThreats: {
      searchResults: Immutable.List()
    },
    availabilityThreats: {
      searchResults: Immutable.List()
    },
    confidentialityImpacts: {
      searchResults: Immutable.List()
    },
    integrityImpacts: {
      searchResults: Immutable.List()
    },
    availabilityImpacts: {
      searchResults: Immutable.List()
    },
    informationRights: {
      searchResults: Immutable.List()
    },
    consentRights: {
      searchResults: Immutable.List()
    },
    accessRights: {
      searchResults: Immutable.List()
    },
    objectionRights: {
      searchResults: Immutable.List()
    },
    deletionRights: {
      searchResults: Immutable.List()
    },
    dataPortabilityRights: {
      searchResults: Immutable.List()
    },
    technicalSecurityMeasures: {
      searchResults: Immutable.List()
    },
    organisationalSecurityMeasures: {
      searchResults: Immutable.List()
    },
    tags: {
      searchResults: Immutable.List()
    },
    fairnessOfDecisions: {
      searchResults: Immutable.List()
    },
    specialSecurityMeasures: {
      searchResults: Immutable.List()
    },
    contractualSecurityMeasures: {
      searchResults: Immutable.List()
    }
  }),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORDS:SIMPLE_ENTITY:LIST:REQUEST:FAIL':
      return state.set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'RECORDS:SIMPLE_ENTITY:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'RECORDS:SIMPLE_ENTITY:SEARCH:SUCCESS': {
      const { reducerType } = action;
      let entitySelectorSearch = state.get('entitySelectorSearch').toJS();
      const currentTypeParams = { ...entitySelectorSearch[reducerType] };
      if (currentTypeParams) {
        currentTypeParams.searchResults = action.items;
        entitySelectorSearch = { ...entitySelectorSearch, [reducerType]: currentTypeParams };
      }
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('entitySelectorSearch', Immutable.Map(entitySelectorSearch));
    }
    case 'RECORDS:SIMPLE_ENTITY:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
