import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  usage: {},
  isDownloading: false,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isJobActive: false,
  globalUsage: undefined,
  isMergeUsageFetching: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:MERGE:ITEM:USAGE:INIT':
      return state
        .set('usage', defaultState.get('usage'))
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:MERGE:ITEM:USAGE:SUCCESS':
      return state
        .set('isFetchingMergeUsage', false)
        .set('usage', action.usage);
    case 'MASTERDATA:MERGE:ITEM:USAGE:FAIL':
      return state
        .set('isFetchingMergeUsage', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:MERGE:USAGE:FETCH':
      return state.set('isFetchingMergeUsage', true);
    case 'MASTERDATA:BULK:EXPORT:INIT':
    case 'ORGANISATION:USERS:EXPORT':
      return state.set('isDownloading', true);
    case 'MASTERDATA:BULK_EXPORT:SUCCESS':
    case 'MASTERDATA:BULK_EXPORT:FAIL':
    case 'ORGANISATION:USERS:EXPORT:SUCCESS':
    case 'ORGANISATION:USERS:EXPORT:FAIL': {
      return state.set('isDownloading', false);
    }
    case 'MASTER_DATA_ITEM:EDIT:JOB:STATUS':
      return state.set('isJobActive', action.status);
    case 'MASTER_DATA_ITEM:EDIT:JOB:STATUS:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'GLOBAL:MASTERDATA:MERGE:SUB_TENANT:USAGE:SUCCESS': {
      return state.set('globalUsage', action.globalUsage);
    }
    case 'MASTERDATA:RESET:GLOBALUSAGE': {
      return state.set('globalUsage', defaultState.get('globalUsage'));
    }
    case 'MASTERDATA:RESET:USAGE': {
      return state.set('usage', defaultState.get('usage'));
    }
    case 'GLOBAL:MASTERDATA:MERGE:ITEM:USAGE:SUCCESS': {
      return state.set('usage', action.usage);
    }
    case 'MASTER_DATA_ITEM:MERGE:CHECK:USAGE':
      return state.set('isMergeUsageFetching', true);
    case 'MASTER_DATA_ITEM:MERGE:CHECK:USAGE:FETCH:RESET':
      return state.set(
        'isMergeUsageFetching',
        defaultState.get('isMergeUsageFetching')
      );
    default:
      return state;
  }
};
