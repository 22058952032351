import { connect } from 'react-redux';
import OrganisationSelector from './component';

const mapStateToProps = state => ({
  organisations: state.environment.organisations.get('items'),
  error: state.legalEntities.get('error'),
  searchResults: state.legalEntities.get('itemSelectorSearch').get('aclOrgs').searchResults
});

const mapDispatchToProps = dispatch => ({
  onSearch: (searchParams, isInternal) => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:SEARCH_INIT', searchParams, isInternal });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSelector);
