import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  name: Immutable.List(),
  email: Immutable.List(),
  workPhone: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  searchItemname: Immutable.List(),
  searchItememail: Immutable.List(),
  searchItemworkPhone: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DPO:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('name', state.get('name'))
        .set('email', state.get('email'))
        .set('workPhone', state.get('workPhone'))
        .set('searchItemname', state.get('searchItemname'))
        .set('searchItememail', state.get('searchItememail'))
        .set('searchItemworkPhone', state.get('searchItemworkPhone'))
        .set('searchText', defaultState.get('searchText'))
        .set('position', 0);
    case 'DPO:LIST:FETCH:SUCCESS': {
      // remove duplicates
      const modifiedArray = removeDuplicates(state.get(`${action.dpoType}`).concat(action.data));
      return state.set(`${action.dpoType}`, Immutable.List(modifiedArray))
        .set('position', state.get('position') + action.position);
    }
    case 'DPO:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'DPO_SELECTOR:LIST:SEARCH':
      return state
        .set('searchText', getSearchText(action));
    case 'DPO_SELECTOR:LIST:SEARCH:SUCCESS':
      return state
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set(`${action.dpoType}`, action.data);
    case 'DPO_SELECTOR:SEARCH:FAIL':
      return state
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};

const removeDuplicates = (newData) => {
  const newarry = newData.filter((item, index) => (newData.findIndex(val => val.name === item.name) >= index));
  return newarry;
};
