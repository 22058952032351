import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import styles from '@packages/ui/styles';
import { commonTranslations, typeTranslations,
  eventCategoryTranslations, tenantTranslations } from '@packages/utils/commontranslations';
import { viewTranslations, removeTranslations } from '@packages/utils/actionTranslations';
import { singularTerms as uppercaseSingular,
  pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import countryTranslations from '@packages/utils/countryTranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { settingsTranslations } from '../../../../../settings/settingsTranslations';

export const TenantDialog = (props) => {
  const { requestClose, show, data } = props;
  const handleDelete = () => {
    props.deleteTenant(data.id);
    requestClose();
  };

  const getModules = (item) => {
    const { formatMessage } = props.intl;
    switch (item) {
      case 'assessments': return formatMessage(typeTranslations.assessments.props);
      case 'processings': return formatMessage(typeTranslations.processings.props);
      case 'breaches': return formatMessage(typeTranslations.breaches.props);
      case 'sso': return formatMessage(settingsTranslations.sso.props);
      case 'reports': return formatMessage(eventCategoryTranslations('reports').props);
      case 'auditTrail':
      case 'whiteLabelling':
      case 'advancedRoleManagement':
      case 'fieldVisibility':
      case 'pdfExport':
      case 'documentStorage':
      case 'mfa':
      case 'knowledgeHub':
      case 'dsr': return formatMessage(uppercaseSingular(item).props);
      case 'masterDataImportExport':
        return formatMessage(uppercaseSingular('importExport').props);
      case 'templates':
        return formatMessage(uppercasePlural('template').props);
      case 'customHelpNotes':
        return formatMessage(uppercasePlural('customHelpNotes').props);
      default: return item;
    }
  };

  const getLabel = (key) => {
    switch (key) {
      case 'id': return commonTranslations.tenantID;
      case 'name': return commonTranslations.tenantName;
      case 'features': return commonTranslations.modules;
      case 'country': return commonTranslations.countryName;
      case 'tenantType': return tenantTranslations.tenantType;
      default: return commonTranslations[key];
    }
  };

  const getValueToRender = (key) => {
    switch (key) {
      case 'url': {
        return (`${data.name}.privacyperfect.com`);
      }
      case 'name': {
        return (data.name.toUpperCase());
      }
      case 'users': {
        return (`${data.users}/${data.billingPlan.maxUsers}`);
      }
      case 'startDate':
      case 'endDate': {
        let date = new Date(data[key]);
        date = data[key] ? date.toLocaleDateString() : '-';
        return date; }
      case 'features': {
        let features = data.billingPlan.features.map(item => (getModules(item)));
        features = features.join(', ');
        return features;
      }
      case 'country': return countryTranslations[data.country];
      default: return data[key] || data.billingPlan[key];
    }
  };

  const renderTenantInfo = (key) => {
    const valueTorender = getValueToRender(key);
    return (
      <div
        style={{ display: 'flex', padding: 10, width: key === 'startDate' ? '45%' : '' }}
        key={`${key}`}
      >
        <div style={{ fontWeight: 'bold' }}>
          {getLabel(key)}:
        </div>
        <div style={{ paddingLeft: 15 }}>
          {valueTorender}
        </div>
      </div>);
  };

  const renderDate = () => (
    <div style={{ display: 'flex' }} key="date">
      {renderTenantInfo('startDate')}
      {renderTenantInfo('endDate')}
    </div>);

  const tenantDeleteInfo = ['id', 'name', 'tenantType', 'url', 'date', 'users'];
  const tenantViewInfo = ['name', 'legalEntityName', 'tenantType', 'features', 'country', 'date',
    'maxUsers', 'maxSupervisorUsers'];
  const tenantInfo = props.isView ? tenantViewInfo : tenantDeleteInfo;
  return (
    <CommonDialog
      id="tenant-delete-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={show}
      onCancel={requestClose}
      title={props.isView ? viewTranslations('view-tenant') : removeTranslations('tenantDeleteHeader')}
    >
      {tenantInfo.map(item => (item === 'date' ? renderDate() : renderTenantInfo(item)))}
      <div style={styles.addButton}>
        {props.isView ?
          <Button
            id="cancel"
            onClick={requestClose}
          >
            {commonTranslations.close}
          </Button> :
          <div>
            <Button
              id="submit"
              type="submit"
              onClick={handleDelete}
            >
              {commonTranslations.confirm}
            </Button>
            <Button
              variant="text"
              onClick={requestClose}
            >
              {commonTranslations.Cancel}
            </Button>
          </div>}
      </div>
    </CommonDialog>
  );
};

TenantDialog.propTypes = {
  show: PropTypes.bool,
  requestClose: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.number,
    billingPlan: PropTypes.shape({
      maxUsers: PropTypes.number,
      features: PropTypes.arrayOf(PropTypes.string)
    }),
    country: PropTypes.string
  }),
  deleteTenant: PropTypes.func,
  isView: PropTypes.bool
};

TenantDialog.defaultProps = {
  show: false,
  requestClose: e => e,
  data: {},
  isView: false,
  deleteTenant: e => e
};

export default injectIntl(TenantDialog);
