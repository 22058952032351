/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
/* import ReactPlayer from 'react-player';
import registry from 'app-registry'; */
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-grid-system';
import Button from '@material-ui/core/Button';
import {
  recordTranslations,
  environmentHeaderTranslation,
  commonTranslations
} from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { linkGroupItems } from '@packages/utils/common-utils';
import { editTranslations } from '@packages/utils/actionTranslations';
import { sectionHeaderTranslations } from '../../../masterDataTranslations';
import {
  renderItemSelector,
  renderChipSelector
} from '../../../../privacy-record-detail/renderers/renderers';
import ImpactAnalysisPage from '../../../impact-analysis';

const EditGroup = (props) => {
  const [visibleFields, setVisibleFields] = useState(props.visibilitySettings);
  const [updateGroupOpen, setUpdateGroupOpen] = useState(false);
  // const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [isEditWithJob, setIsEditWithJob] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    props.checkUsage();
    props.initialize(props.link ? props.link : {});
  }, []);

  useEffect(() => {
    if (visibilitySettings !== undefined) {
      setVisibleFields(props.visibilitySettings);
    }
  }, [props.visibilitySettings]);

  useEffect(() => {
    if (props.isUpdateSuccess) {
      props.onClose();
    }
  }, [props.isUpdateSuccess]);

  const handleChangeProperty = (property, data) => {
    props.change(property, [...data]);
    setIsError(false);
  };

  const isValidGroup = (data) => {
    let itemCounter = 0;
    linkGroupItems.forEach((value) => {
      if (data[value] && data[value].length > 0) itemCounter += 1;
    });
    setIsError(itemCounter < 2);
    return itemCounter;
  };

  const handleCreateLink = (data, isEdit) => {
    const itemCounter = isValidGroup(data);
    if (itemCounter < 2) return;
    const groupData = {};

    linkGroupItems.forEach((type) => {
      const items = data[type]
        ? data[type].map((item) => (item.value ? item.value.id : item))
        : [];
      groupData[type] = items;
    });
    const modifiedGroupData = {
      ...groupData,
      tags: data.tags ? data.tags.map((item) => item.id) : []
    };
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    modifiedGroupData._rev = data._rev;

    if (recordType === 'processings') {
      props.createLinkProcessing(
        modifiedGroupData,
        props.intl.formatMessage,
        data.id
      );
    } else if (recordType === 'assessments') {
      props.createLinkAssessment(
        modifiedGroupData,
        props.intl.formatMessage,
        data.id
      );
    } else if (isEdit) {
      props.editLink(modifiedGroupData, data.id);
    } else props.createLink(modifiedGroupData);
  };

  const onUpdateClick = (data) => {
    if (isUsed && !isTagEdit && !(isValidGroup(data) < 2)) {
      setUpdateGroupOpen(true);
    } else handleCreateLink(data, true);
  };

  const updateDialogClose = () => {
    setUpdateGroupOpen(false);
  };

  const handleUpdateLink = (data) => {
    if (!props.hasNoLimit) {
      updateDialogClose();
    } else {
      const updatedData = { ...data };
      // Deleting organization field from
      // value field of dataSource as it is not needed in job request
      updatedData.dataSources.forEach((item, index) => {
        if (item.value.organisation !== undefined) {
          delete updatedData.dataSources[index].value.organisation;
        }
        if (item.value.dataStorageCountry !== undefined) {
          delete updatedData.dataSources[index].value.dataStorageCountry;
        }
      });
      props.updateGroups(updatedData, isEditWithJob, data.linkNumber);
      updateDialogClose();
    }
  };

  const buttonActions = [
    <Button
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      style={{ marginRight: '5px' }}
      onClick={props.handleSubmit((data) => handleUpdateLink(data))}
    >
      {props.hasNoLimit ? commonTranslations.continue : commonTranslations.Ok}
    </Button>,
    props.hasNoLimit && (
      <Button
        variant="text"
        id="cancel-button"
        key="cancel-updateGroup"
        onClick={updateDialogClose}
      >
        {commonTranslations.Cancel}
      </Button>
    )
  ];

  /* const config = registry.get('config');
  const linkActions = [
    <Button
      classes={{
          root: 'button_confirm_dialog',
          label: 'buttonLabel_confirm_dialog'
        }}
      onClick={() => handleVideoClose()}
    >
      {commonTranslations.Ok}
    </Button>
  ];

  const handleVideoClose = () => {
    setOpenVideoDialog(false);
  };

  const handleVideoEditorDialog = () => {
    setOpenVideoDialog(true);
  }; */

  const handleEditWithJob = (isChecked) => {
    setIsEditWithJob(isChecked);
  };

  const {
    hasNoLimit,
    isUsed,
    usage,
    visibilitySettings,
    considerFieldVisibility,
    recordType,
    isEdit,
    isTagEdit,
    actionError
  } = props;
  return (
    <form>
      <div>
        {recordTranslations.createLinkSubHeader}
        {/* <Button
          classes={{
          label: 'buttonLabel_whatsthis'
        }}
          variant="text"
          style={{ width: '150px' }}
          onClick={handleVideoEditorDialog}
        >
          {recordTranslations.whatAreGroups}
        </Button> */}
      </div>
      <Row style={{ marginTop: 10 }}>
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('DataSubjectCategories'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="dataSubjectCategories"
              type="dataSubjectCategories"
              headerLabel={recordTranslations.dataSubjectCategories}
              itemLabel={recordTranslations.dataSubjectCategory}
              hintText={recordTranslations.addDataSubjectCategoryHintText}
              updateDataSubjectCategories={(item) =>
                handleChangeProperty('dataSubjectCategories', item)
              }
              customStyle={{ maxWidth: 320 }}
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
            />
          </Col>
        )}
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('PersonalDataCategories'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="personalDataCategories"
              type="personalDataCategories"
              headerLabel={recordTranslations.personalDataCategories}
              itemLabel={environmentHeaderTranslation('personalDataCategories')}
              hintText={recordTranslations.personalDataCategoriesHintText}
              updatePersonalDataCategories={(item) =>
                handleChangeProperty('personalDataCategories', item)
              }
              multiValue={true}
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 20 }}>
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('PersonalDataItems'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="personalDataItems"
              type="personalDataItems"
              headerLabel={recordTranslations.personalDataItems}
              itemLabel={recordTranslations.personaldata}
              hintText={recordTranslations.personalDataItemsHintText}
              updatePersonalDataItems={(item) =>
                handleChangeProperty('personalDataItems', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('RetentionTerms'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="retentionTerms"
              type="retentionTerms"
              headerLabel={recordTranslations.retentionTerms}
              itemLabel={recordTranslations.retentionTerms}
              hintText={recordTranslations.retentionTermHintText}
              updateRetentionTerm={(item) =>
                handleChangeProperty('retentionTerms', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              isEditable={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 20 }}>
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('DataSources'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="dataSources"
              type="dataSources"
              headerLabel={recordTranslations.dataSources}
              itemLabel={recordTranslations.datasource}
              hintText={recordTranslations.dataSourcesHintText}
              updateDataSources={(item) =>
                handleChangeProperty('dataSources', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('DataSourceCategories'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              id="dataSourceCategories"
              name="dataSourceCategories"
              type="dataSourceCategories"
              headerLabel={recordTranslations.dataSourceCategories}
              itemLabel={recordTranslations.dataSourceCategories}
              hintText={recordTranslations.dataSourceCategoriesHintText}
              updateEntities={(item) =>
                handleChangeProperty('dataSourceCategories', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 20 }}>
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('OrganisationalSecurityMeasures'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="organisationalSecurityMeasures"
              type="organisationalSecurityMeasures"
              entityType="organisationalSecurityMeasures"
              masterDataType="securityMeasures"
              hintText={
                recordTranslations.addSecurityMeasuresDescriptionHintText
              }
              headerLabel={
                sectionHeaderTranslations.organisationalMeasuresHeader
              }
              itemLabel={sectionHeaderTranslations.measures}
              updateEntities={(item) =>
                handleChangeProperty('organisationalSecurityMeasures', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('TechnicalSecurityMeasures'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="technicalSecurityMeasures"
              type="technicalSecurityMeasures"
              entityType="technicalSecurityMeasures"
              masterDataType="securityMeasures"
              hintText={
                recordTranslations.addSecurityMeasuresDescriptionHintText
              }
              headerLabel={
                sectionHeaderTranslations.technicalSecurityMeasuresHeader
              }
              itemLabel={sectionHeaderTranslations.measures}
              updateEntities={(item) =>
                handleChangeProperty('technicalSecurityMeasures', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: 20 }}>
        {(!considerFieldVisibility ||
          (considerFieldVisibility &&
            visibleFields.includes('InternationalTransfers'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="purposesOfTransfer"
              type="purposesOfTransfer"
              masterDataType="purposes"
              entityType="purposesOfTransfer"
              headerLabel={recordTranslations.transferPurpose}
              itemLabel={recordTranslations.transferPurpose}
              hintText={recordTranslations.addTransferPurpose}
              updateEntities={(item) =>
                handleChangeProperty('purposesOfTransfer', item)
              }
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
        {(!considerFieldVisibility ||
          (considerFieldVisibility && visibleFields.includes('Purposes'))) && (
          <Col md={8} lg={6}>
            <FieldArray
              name="purposes"
              type="purposes"
              headerLabel={recordTranslations.assessmentPurpose}
              itemLabel={recordTranslations.assessmentPurpose}
              hintText={recordTranslations.addAssessmentPurpose}
              updateEntities={(item) => handleChangeProperty('purposes', item)}
              component={renderItemSelector}
              isNote={false}
              showUsage={false}
              showEditIcon={false}
              showTextField={!isTagEdit}
              customStyle={{ maxWidth: 320 }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <FieldArray
            id="tags"
            name="tags"
            type="tags"
            entityType="group"
            disabled={isEdit && !isTagEdit}
            headerLabel={commonTranslations.tags}
            hintText={commonTranslations.addTags}
            updateTags={(item) => handleChangeProperty('tags', item)}
            chipSelectorStyle={{ width: '98%' }}
            showHeader={isTagEdit || !isEdit}
            disabledTagStyle={{ marginTop: 55 }}
            displaySelectorOnTop={true}
            component={renderChipSelector}
          />
        </Col>
      </Row>
      {isError && (
        <div style={{ color: 'red' }}>
          {commonTranslations.invalidLinkError}
        </div>
      )}
      {actionError && actionError.isError && (
        <div style={{ color: 'red' }}>{actionError.message}</div>
      )}
      <div style={{ marginTop: 20 }}>
        {props.isEdit && recordType === '' && (
          <Button onClick={props.handleSubmit((data) => onUpdateClick(data))}>
            <FormattedMessage
              id="linkGroup.update"
              description="update link group"
              defaultMessage="Update"
            />
          </Button>
        )}
        {!isTagEdit && (
          <Button
            style={{ marginLeft: props.isEdit ? '5px' : '0px' }}
            onClick={props.handleSubmit((data) => handleCreateLink(data))}
          >
            {props.isEdit ? (
              <FormattedMessage
                id="Link_Group.saveAsNew"
                description="Save as new"
                defaultMessage="Save as new"
              />
            ) : (
              <FormattedMessage
                id="Link_Group.save"
                description="Save link group"
                defaultMessage="Save group"
              />
            )}
          </Button>
        )}
        <Button variant="text" onClick={() => props.onClose()}>
          {commonTranslations.Cancel}
        </Button>
      </div>
      {/* openVideoDialog &&
      <CommonDialog
        id="link_video_dialog"
        show={openVideoDialog}
        onCancel={handleVideoClose}
        title={recordTranslations.linkBoxInstructionHeader}
        buttonActions={linkActions}
        buttonPosition="right"
        maxWidth="md"
        fullWidth={true}
      >
          {recordTranslations.linkBoxInstructionSubHeader}
        <div className="player-wrapper" style={{ padding: '20px 0px 0px 45px' }}>
          <ReactPlayer
            url={config.linkVideoUrl}
            className="react-player"
            style={{ width: '750px', height: '360px' }}
          />
        </div>
      </CommonDialog>
         */}
      {updateGroupOpen && (
        <CommonDialog
          id="update-group-dialog"
          show={updateGroupOpen}
          onCancel={updateDialogClose}
          fullWidth={true}
          maxWidth="sm"
          title={editTranslations('updateGroup')}
          buttonActions={buttonActions}
        >
          {isUsed && (
            <ImpactAnalysisPage
              hasNoLimit={hasNoLimit}
              usage={usage}
              handleEditWithJob={handleEditWithJob}
              type="dataItemGroup"
            />
          )}
        </CommonDialog>
      )}
    </form>
  );
};

EditGroup.displayName = 'EditGroup';

EditGroup.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  visibilitySettings: PropTypes.arrayOf(PropTypes.string),
  initialize: PropTypes.func,
  recordType: PropTypes.string,
  change: PropTypes.func,
  createLinkProcessing: PropTypes.func,
  createLinkAssessment: PropTypes.func,
  createLink: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  editLink: PropTypes.func,
  link: PropTypes.shape(),
  considerFieldVisibility: PropTypes.bool,
  updateGroups: PropTypes.func,
  checkUsage: PropTypes.func,
  hasNoLimit: PropTypes.bool,
  isUsed: PropTypes.bool,
  usage: PropTypes.bool,
  isTagEdit: PropTypes.bool,
  isUpdateSuccess: PropTypes.bool,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  })
};

EditGroup.defaultProps = {
  visibilitySettings: [],
  initialize: (e) => e,
  recordType: '',
  change: (e) => e,
  createLinkProcessing: (e) => e,
  createLinkAssessment: (e) => e,
  createLink: (e) => e,
  handleSubmit: (e) => e,
  onClose: (e) => e,
  isEdit: false,
  editLink: (e) => e,
  link: {},
  considerFieldVisibility: false,
  updateGroups: (e) => e,
  checkUsage: (e) => e,
  hasNoLimit: false,
  isUsed: false,
  usage: false,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  isUpdateSuccess: null,
  isTagEdit: false
};

EditGroup.contextTypes = {
  router: PropTypes.object.isRequired
};

const EditGroupFormWrapper = reduxForm({
  form: 'EditGroup'
})(EditGroup);

export default injectIntl(EditGroupFormWrapper);
