import { connect } from 'react-redux';

import { NO_LIMIT } from '@packages/utils/common-utils';

import DataRecipientCategory from './dataRecipientCategory';

const mapStateToProps = (state) => ({
  dataRecipientCategoryData: state.environment.dataRecipientCategories.get(
    'dataRecipientCategoryData'
  ),
  globalUsage: state.environment.dataRecipientCategories.get('globalUsage'),
  actionError: state.environment.dataRecipientCategories.get('error').toJS(),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'DATARECIPIENTCATEGORIES:ITEM:FETCH', id });
  },
  updateDataRecipientCategory: (data, isEdit, source, isUsed) => {
    if (isUsed) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        data,
        actionType: 'Edit',
        entityType: 'DataRecipientCategory'
      });
    } else {
      dispatch({
        type: 'DATARECIPIENTCATEGORIES:LIST:UPSERT',
        data,
        isEdit,
        source
      });
    }
  },
  resetDataRecipientCategories: () => {
    dispatch({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:INIT' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataRecipientCategory);
