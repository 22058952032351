import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown } from '@packages/utils/common-utils';

export function* importLibraryMasterData(action) {
  const { libraryTenantId, dataLibraryType, operation } = action;
  try {
    const response = yield registry
      .get('request')
      .post('/v1/jobs/data-library', {
        libraryTenantId,
        dataLibraryType,
        operation
      });

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATA_LIBRARY:JOB:CREATE:SUCCESS',
          openStayOrNavigate: true
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}

export function* sendPurchaseMailData(action) {
  const { libraryTenantId, dataLibraryType, entityId } = action;
  try {
    const response = yield registry
      .get('request')
      .post('/v1/tenants/data-library/purchase-entity', {
        libraryTenantId,
        dataLibraryType,
        ...(entityId && { entityId })
      });

    switch (response.status) {
      case 200:
      case 204: {
        yield put({
          type: 'PURCHASE_MAIL:SUCCESS:DIALOG_OPEN',
          openSuccessDialog: true
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}
