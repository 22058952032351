import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';

export class ValueSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      searchText: '',
      searchResults: []
    };

    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) this.setState({ searchResults: nextProps.searchResults });
  }

  handleMultipleSelect() {
    this.setState({
      open: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      searchResults: [],
      searchText: ''
    });
  }

  handleMultipleItems(selectedItems) {
    selectedItems.forEach(item => this.handleSelectedItem(item));
  }

  handleSelectedItem(selectedItem) {
    let fieldValue = selectedItem.value;
    if (this.props.valueAttr) {
      // selectedItem.value need not be an array
      // in all the cases. For e.g., processing ground law, processing ground jurisdiction.
      fieldValue = { id: selectedItem.value[this.props.valueAttr] || selectedItem.value,
        key: selectedItem.value.key };
    }
    this.props.fields.push(fieldValue);
    if (this.props.onSelect) {
      this.props.onSelect(selectedItem.value);
    }
    this.setState({ searchText: '', searchResults: [] });
  }

  handleRemoveItem(selectedIndex) {
    this.props.fields.remove(selectedIndex);
    if (this.props.removeItem) {
      this.props.removeItem(selectedIndex);
    }
  }

  handleSearch(searchText) {
    this.setState({
      searchText
    });
    let searchParams = { ...searchText, searchKey: 'name' };
    if (this.props.legalEntityType) {
      const { legalEntityType } = this.props;
      searchParams = { ...searchParams, legalEntityType };
    }
    this.props.onSearch(searchParams);
  }

  handleOnFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  getDataItems() {
    const { dataItems, subField } = this.props;
    const modifiedDataItems = Array.from(new Set(dataItems.map(dataItem =>
      dataItem[subField].id)));
    return modifiedDataItems.map(item => ({
      id: item, key: item
    }));
  }

  filterDataItems(searchedItems) {
    const filteredData = searchedItems.filter((dataItem) => {
      const index = this.props.selectedItems.findIndex(selectedItem =>
        ((selectedItem.id || selectedItem) === (this.props.valueAttr ? dataItem[this.props.valueAttr] : dataItem)));
      return (index === -1);
    });
    return filteredData;
  }

  render () {
    const { multiValue, createNewMenuItem = false, selectFromListMenuItem = false,
      formatMessage, selectedItems, subField, dataItems, dataSourceConfig,
      dataSourceFilter, title, theme } = this.props;
    const dataFieldItems = subField ? this.getDataItems(dataItems, subField) : dataItems;
    let dataSource = [];
    if (!this.props.liveAutocomplete) {
      dataSource = (selectedItems && selectedItems.length > 0) ?
        this.filterDataItems(dataFieldItems) : dataFieldItems;
    } else {
      const { searchText, searchResults } = this.state;
      const searchedItems = searchText !== '' || searchResults.size > 0 ? searchResults : dataFieldItems;
      dataSource = (selectedItems && selectedItems.length > 0) ?
        this.filterDataItems(searchedItems) : searchedItems;
    }
    const multipleDataSource = (selectedItems && selectedItems.length > 0) ?
      this.filterDataItems(dataFieldItems) : dataFieldItems;

    return (
      <div>
        <ItemList
          isEditable={false}
          isNote={false}
          handleRemoveItem={this.handleRemoveItem}
          theme={theme}
          {...this.props}
        />
        <ItemSelector
          createNewMenuItem={createNewMenuItem}
          selectFromListMenuItem={selectFromListMenuItem}
          multiValue={multiValue}
          dataSource={dataSource}
          dataSourceConfig={dataSourceConfig}
          dataSourceFilter={dataSourceFilter}
          formatMessage={formatMessage}
          onFocus={this.handleOnFocus}
          hintTextLabel={this.props.intl.formatMessage({
            id: 'ValueSelector.hintText',
            defaultMessage: 'Add new value...'
          })}
          handleSelectedItem={this.handleSelectedItem}
          handleMultipleSelect={this.handleMultipleSelect}
          onSearch={this.handleSearch}

        />
        {this.state.open && <MultipleSelectorDialog
          open={this.state.open}
          title={title}
          filteredData={multipleDataSource}
          onScrollStop={this.props.onScrollStop}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />
        }
      </div>
    );
  }
}

ValueSelector.propTypes = {
  liveAutocomplete: PropTypes.bool,
  subField: PropTypes.string.isRequired,
  valueAttr: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  onScrollStop: PropTypes.func,
  removeItem: PropTypes.func,
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  formatMessage: PropTypes.func,
  title: PropTypes.node,
  legalEntityType: PropTypes.string,
  dataSourceFilter: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  dataItems: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.arrayOf(PropTypes.object)
  ]),
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func
  }),
  theme: PropTypes.shape({}).isRequired,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  onFocus: PropTypes.func
};

ValueSelector.defaultProps = {
  liveAutocomplete: true,
  multiValue: true,
  legalEntityType: '',
  onSelect: e => e,
  removeItem: e => e,
  onScrollStop: e => e,
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  formatMessage: e => e,
  title: null,
  dataSourceFilter: null,
  dataItems: Immutable.List(),
  fields: {
    remove: e => e,
    removeAll: e => e,
    push: e => e
  },
  onSearch: e => e,
  searchResults: Immutable.List(),
  onFocus: e => e
};

export default withTheme(injectIntl(ValueSelector));
