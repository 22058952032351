import { connect } from 'react-redux';

import RecipientSelector from './component';

const mapStateToProps = state => ({
  tenantusers: state.messages.inbox.get('users').get('items'),
  position: state.messages.inbox.get('users').get('position'),
  error: state.tenantusers.get('error'),
  searchResults: state.messageRecipients.get('items'),
  searchText: state.messageRecipients.get('searchText')
});


const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch({ type: 'MESSAGES:USER:LIST:REQUEST_INIT', currentSubmenu: 'inbox', persistSearch: false });
  },
  userSearch: (searchText) => {
    dispatch({ type: 'MESSAGES:USERS:SELECTOR:LIST:SEARCH:INIT', searchText });
  },
  getNextData: (position) => {
    dispatch({ type: 'MESSAGES:USER:LIST:NEXT:FETCH', currentSubmenu: 'inbox', position, persistSearch: false });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipientSelector);
