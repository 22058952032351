import { connect } from 'react-redux';

import AssessmentRecordListComponent from './assessmentRecordList';

const recordType = 'assessment';
const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  items: state.privacyRecords.list.get('items'),
  pageSearchText: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('assessment').pageSearchText,
  sortOrder: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('assessment').sortOrder,
  sortKey: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('assessment').sortKey,
  filterColumn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('assessment').filterColumn,
  filteredOn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('assessment')
    .filteredOn.toJS(),
  position: state.privacyRecords.list.get('position'),
  isFetching: state.privacyRecords.list.get('isFetching'),
  error: state.privacyRecords.list.get('error').toJS(),
  isUpdating: state.assessment?.get('isUpdating'),
  selectedRecords: state.privacyRecords.list.get('bulkItems').get('assessment')
    .selectedRecords,
  pricingPlan: state.login?.get('pricingPlanDetails'),
  registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
  filterMenuData: state.privacyRecords.filters.get('currentFilterData')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
      recordType,
      recordLayoutId
    });
  },
  getNextData: (position, rowCount, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount,
      recordLayoutId
    });
  },
  deletePrivacyRecord: (recordId) => {
    dispatch({ type: 'PRIVACY_RECORD:ITEM:DELETE', recordId, recordType });
  },
  onChooseFilter: (filterParams, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType,
      recordLayoutId
    });
  },
  updateNote: (recordId, property, data, name, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType,
      property,
      data,
      name,
      recordLayoutId
    });
  },
  copyAssessment: (data, copyOfRecordId, isGlobal) => {
    dispatch({
      type: 'ASSESSMENT:DETAIL:UPSERT',
      data,
      copyOfRecordId,
      isGlobal
    });
  },
  onRecordSelection: (selectedRecords) => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType,
      selectedRecords
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentRecordListComponent);
