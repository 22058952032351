import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Linkify from 'react-linkify';

import { withTheme } from '@material-ui/core/styles';
import { commonTranslations } from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

const editNoteStyle = {
  marginTop: '5px'
};

const subTitleStyle = {
  fontSize: '15px',
  textAlign: 'left',
  marginBottom: '-20px',
  marginTop: '10px'
};

const Note = (props) => {
  const {
    content,
    maxLimit,
    onChange,
    headerLabel,
    entityName,
    open,
    handleEditNote,
    close,
    isEdit,
    title,
    addtlTitle,
    confirmButton,
    cancelButton,
    subTitle,
    theme,
    placeholder
  } = props;
  const [showMaxLimitError, setShowMaxLimitError] = useState(false);

  const handleChange = (event) => {
    if (maxLimit) {
      setShowMaxLimitError(event.target.value.length > maxLimit);
    }
    if (onChange) onChange(event);
  };

  const renderViewNote = () => (
    <div style={styles.viewNoteStyle}>
      <Linkify
        properties={{
          target: '_blank',
          style: { color: theme.palette.primary.main }
        }}
      >
        {content}
      </Linkify>
    </div>
  );

  const renderEditNote = () => (
    <div style={editNoteStyle}>
      <TextField
        id="text-field-note"
        multiline={true}
        rows={12}
        variant="outlined"
        autoFocus={true}
        style={{ width: '100%' }}
        onBlur={handleChange}
        defaultValue={content}
        placeholder={placeholder}
      />
    </div>
  );

  const note = isEdit ? renderEditNote() : renderViewNote();

  const saveActions = [
    <Button
      key="save-button"
      id="save_button"
      onClick={!showMaxLimitError && handleEditNote}
    >
      {confirmButton || commonTranslations.saveNote}
    </Button>,
    <Button key="cancel-button" variant="text" onClick={close}>
      {cancelButton || commonTranslations.Cancel}
    </Button>
  ];

  const viewAction = [
    <Button onClick={close}>{commonTranslations.close}</Button>
  ];

  return (
    <CommonDialog
      id="add-note-dialog"
      show={open}
      onCancel={close}
      fullWidth={true}
      maxWidth="sm"
      title={
        <>
          {headerLabel} {entityName || ''} {title || ''}{' '}
          {addtlTitle && <span>({addtlTitle})</span>}
        </>
      }
      buttonActions={isEdit ? saveActions : viewAction}
      buttonPosition="right"
      subTitle={<div style={subTitleStyle}>{subTitle}</div>}
    >
      <div style={{ whiteSpace: 'pre-wrap' }}>{note}</div>
      {showMaxLimitError && (
        <div style={{ color: 'red' }}>
          <FormattedMessage
            id="Validation.maxLimit"
            defaultMessage="Maximum character limit is {limit}"
            values={{ limit: `${maxLimit}` }}
          />
        </div>
      )}
    </CommonDialog>
  );
};

Note.propTypes = {
  placeholder: PropTypes.string,
  content: PropTypes.string,
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  headerLabel: PropTypes.node.isRequired,
  entityName: PropTypes.node,
  title: PropTypes.string,
  addtlTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleEditNote: PropTypes.func,
  close: PropTypes.func.isRequired,
  confirmButton: PropTypes.node,
  cancelButton: PropTypes.node,
  subTitle: PropTypes.node,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  maxLimit: PropTypes.number
};

Note.defaultProps = {
  theme: {},
  placeholder: '',
  content: null,
  isEdit: false,
  entityName: null,
  handleEditNote: (e) => e,
  onChange: (e) => e,
  title: undefined,
  addtlTitle: '',
  confirmButton: null,
  cancelButton: null,
  subTitle: null,
  maxLimit: 0
};

export default injectIntl(withTheme(Note));
export { Note };
