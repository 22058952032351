import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import {
  handleServiceDown,
  checkIsViewRestrictedUser
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { reverseFieldsByRecordType } from '../../utils';

export function* fetchRoleGroupVisibilityInit() {
  yield put({ type: 'ROLE:GROUPS:FIELDS:VISIBILITY:FETCH' });

  try {
    const request = registry.get('request');
    const config = registry.get('config');
    const ppUser = registry
      .get('storage')
      .getItem(config.login.user.storage.key)
      ? JSON.parse(
        registry.get('storage').getItem(config.login.user.storage.key)
      )
      : {};
    const isViewRestrictedUser = yield checkIsViewRestrictedUser();
    if (ppUser.userPermissions?.viewFieldVisibility) {
      const response = yield request.get(
        '/v1/settings/fieldsVisibilitySetting',
        null,
        {}
      );
      switch (response.status) {
        case 200: {
          // response contains fields to be hidden. Get the visible ones and push to the component.
          const modifiedResponse = reverseAllFilteredFields(response.body);
          yield put({
            type: 'ROLE:FIELDS:VISIBILITY:FETCH:SUCCESS',
            visibility: modifiedResponse,
            isViewRestrictedUser
          }); // eslint-disable-line
          break;
        }
        case 403: {
          const error = response.body.msg;
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: error,
              type: 'error'
            }
          });
          break;
        }
        default:
          break;
      }
    } else {
      const visiblity = {
        id: 'fieldsVisibilitySetting',
        value: {
          // TODO: change field visibility model once backend is ready
          processing: [{ roleGroup: 'BusinessUser', fields: [] }],
          assessment: [{ roleGroup: 'BusinessUser', fields: [] }],
          breach: [{ roleGroup: 'BusinessUser', fields: [] }]
        }
      };
      const modifiedResponse = reverseAllFilteredFields(visiblity);
      yield put({
        type: 'ROLE:FIELDS:VISIBILITY:FETCH:SUCCESS',
        visibility: modifiedResponse,
        isViewRestrictedUser
      }); // eslint-disable-line
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: 'ROLE:GROUPS:PERMISSIONS:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* updateFieldsVisibility(action) {
  const { selectedFields, recordType } = action;
  const request = registry.get('request');
  const settingsState = yield select((state) => state.fieldVisibilitySettings);
  const visibilityRev = settingsState && settingsState.get('visibility')._rev; // eslint-disable-line

  // the state is now containing all the visible fields for each record type.
  // Get the hidden fields and send the request.
  const modifiedAllFields = settingsState
    ? reverseAllFilteredFields(settingsState.get('visibility'))
    : {};
  const isViewRestrictedUser = yield checkIsViewRestrictedUser();
  const modifiedReqData = {
    ...modifiedAllFields,
    value: {
      ...modifiedAllFields.value,
      [recordType]: [
        {
          roleGroup: 'BusinessUser',
          fields: reverseFieldsByRecordType(selectedFields, recordType)
          // reverse the fields based on the fields coming from the component for a particular record type
        }
      ]
    }
  };

  try {
    const response = yield request.put(
      '/v1/settings/fieldsVisibilitySetting',
      modifiedReqData
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.fieldVisibilitypdated,
            type: 'success'
          }
        });
        // response contains fields to be hidden. Get the visible ones and push to the component.
        const modifiedResponse = reverseAllFilteredFields(response.body);
        yield put({
          type: 'ROLE:FIELDS:VISIBILITY:FETCH:SUCCESS',
          visibility: modifiedResponse,
          isViewRestrictedUser
        }); // eslint-disable-line
        yield put({ type: 'SETTINGS:UNSAVED:DATA', value: false });
        break;
      }
      default:
        yield put({ type: 'SETTINGS:UNSAVED:DATA', value: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'TENANT:SETTINGS:UPDATE:FAIL', error: err.message });
  }
}

const reverseAllFilteredFields = (response) => {
  const recordTypes = ['processing', 'assessment', 'breach'];
  recordTypes.forEach((type) => {
    const fieldsToNegate =
      response.value[type].length > 0 ? response.value[type][0].fields : [];
    const reversedFields = reverseFieldsByRecordType(fieldsToNegate, type);
    response.value[type] = [
      {
        roleGroup: 'BusinessUser',
        fields: reversedFields
      }
    ];
  });
  return response;
};
