import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

class DivWrapper extends PureComponent {
  render () {
    const { children, ...rest } = this.props;
    return (
      <Scrollbars
        onScrollStop={this.props.onScrollStop}
        onScrollFrame={({ top }) => { if (top === 1) this.props.onScrollEnd(); }} // top = 1 at scroll end
        renderThumbVertical={({ style, ...props }) => (<div
          {...props}
          style={{ ...style,
            backgroundColor: '#c2c2c2',
            width: '7px',
            borderRadius: 'inherit',
            zIndex:1 }}
        />)}
        renderThumbHorizontal={() => (<div style={{ display: 'none' }} />)}
        {...rest}
      >{children}
      </Scrollbars>
    );
  }
}

DivWrapper.propTypes = {
  children: PropTypes.node,
  onScrollStop: PropTypes.func,
  onScrollEnd: PropTypes.func
};

DivWrapper.defaultProps = {
  children: null,
  onScrollStop: e => e,
  onScrollEnd: e => e
};

export default DivWrapper;
