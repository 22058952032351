/* eslint-disable prefer-const */
import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import {
  getErrorMessage,
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';
import { transformResponseData, transformRequestData } from './utils';
import { exportQueryDefinition } from './filterValues';
import dsrTranslations from '../dsr/dsrTranslations';
import { getDefaultLayoutId } from '../privacy-record-detail/common-utils';

export function* initializeDsrDetails() {
  const { dsrID } = getParameterValuesFromHash('/dsr/:dsrID/edit?:is');

  yield put({ type: 'DSR:DETAIL:FETCH', dsrID });

  try {
    const response = yield registry
      .get('request')
      .get(`/v1/dsrmanagement/datasubjectrequests/${dsrID}`, null);

    switch (response.status) {
      case 200:
        {
          const dsrDetail = response.body;
          const modifiedData = transformResponseData(dsrDetail);
          const dsrDetailState = yield select((state) => state.dsrDetail);
          let currentFilter = 'Registration_Registration';
          if (dsrDetailState && dsrDetailState.get('isReopendCompletedDSR')) {
            currentFilter = 'RequestAction_RequestAction';
          } else if (
            modifiedData.status === 'dsr_status_completed' ||
            modifiedData.status === 'dsr_status_cancelled'
          ) {
            currentFilter = 'Review_Review';
          }
          yield put({
            type: 'DSR:DETAIL:FETCH:SUCCESS',
            data: modifiedData,
            currentFilter
          });
        }
        break;

      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'DSR:DETAIL:FETCH:FAIL', error });

        const currentState = yield select((state) => state.dsrDetail);
        const store = registry.get('store');

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        store.dispatch(replaceRouter(currentState.get('prevLocation')));

        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertDsrDetails(action) {
  const request = registry.get('request');
  const { data } = action;
  try {
    const requestData = transformRequestData(data);
    const dsrId = requestData.id || '';
    const requestUrl = `/v1/dsrmanagement/datasubjectrequests/${dsrId}`;
    const response = yield request.put(requestUrl, requestData);
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const modifiedData = transformResponseData(response.body);
        yield put({ type: 'DSR:DETAIL:UPSERT:SUCCESS', data: modifiedData });
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });
  }
}

export function* reOpenDSR(action) {
  const request = registry.get('request');
  const { data, isFromList = false } = action;
  try {
    const requestUrl = `/v1/dsrmanagement/datasubjectrequests/${data.id}/reopen`;
    const response = yield request.post(requestUrl);
    yield response;
    const store = registry.get('store');
    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const modifiedData = transformResponseData(response.body);
        yield put({ type: 'DSR:DETAIL:UPSERT:SUCCESS', data: modifiedData });
        if (data.status === 'dsr_status_completed') {
          yield put({
            type: 'DSR:REOPEN:SUCCESS',
            isReopendCompletedDSR: true
          });
        }
        if (isFromList) {
          store.dispatch(replaceRouter(`/dsr/${data.id}/edit`));
        }
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    const error = getErrorMessage(err);
    yield put({ type: 'DSR:DETAIL:UPSERT:FAIL', error });
  }
}

export function* fetchOrganisationUsersForDSR() {
  yield put({ type: 'DSR:ORGANISATION_USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .post(`/v1/dsrmanagement/datasubjectrequests/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'DSR:ORGANISATION_USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
  }
}

export function* resetAndFetchDSRUsers(action) {
  yield put({ type: `DSR:USERS:LIST:INIT` });
  yield getDSRusers(action);
}
export function* getDSRusers(action) {
  const {
    position = 0,
    dataItemType,
    selectedOrganisations,
    jurisdictions
  } = action;
  yield put({ type: `DSR:USERS:LIST:FETCH` });

  try {
    const dsrState = yield select((state) => state.dsrDetail);
    let searchTextValue = '';
    if (dsrState) {
      const userObject = dsrState.get('userSearchText').toJS();
      searchTextValue = userObject.searchText;
    }
    let { filterParameters, url } = getUserUrl(
      dataItemType,
      selectedOrganisations,
      jurisdictions
    );
    if (searchTextValue && searchTextValue !== '') {
      url = `${url}&search=name=${encodeURIComponent(searchTextValue)}`;
    }

    let response = {};
    let requestData = {
      filterParameters,
      limit: GENERAL_FETCH_LIMIT,
      offset: position
    };
    if (dataItemType === 'approvers') {
      response = yield registry.get('request').post(url, requestData);
    } else {
      if (searchTextValue && searchTextValue !== '') {
        requestData = {
          ...requestData,
          searchParameters: {
            name: [encodeURIComponent(searchTextValue)]
          }
        };
      }

      response = yield registry.get('request').post(url, requestData);
    }
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
        }));
        yield put({
          type: `DSR:USERS:LIST:FETCH:SUCCESS`,
          items: modifiedItems.filter((item) => item.label !== '')
        });
        break;
      }
      default: {
        yield put({
          type: 'DSR:USERS:LIST:FETCH:FAIL',
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    registry.get('logger').error(err);
    yield put({ type: `DSR:USERS:LIST:FETCH:FAIL`, error: err.message });
  }
}

export function* searchDSRUsers(action) {
  const {
    position = 0,
    searchParams = undefined,
    dataItemType,
    selectedOrganisations,
    jurisdictions
  } = action;
  try {
    const dsrState = yield select((state) => state.dsrDetail);
    let searchTextValue = '';
    if (searchParams) {
      searchTextValue = searchParams.searchText;
      yield put({ type: `DSR:USERS:LIST:SEARCH`, searchText: searchTextValue });
    } else if (dsrState) {
      const userObject = dsrState.get('userSearchText').toJS();
      searchTextValue = userObject.searchText;
    }
    let { filterParameters, url } = getUserUrl(
      dataItemType,
      selectedOrganisations,
      jurisdictions
    );
    let response = {};
    const requestData = {
      filterParameters,
      limit: GENERAL_FETCH_LIMIT,
      offset: position,
      searchParameters: {
        name: [encodeURIComponent(searchTextValue)]
      }
    };
    response = yield registry.get('request').post(url, requestData);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
        }));
        yield put({
          type: `DSR:USERS:LIST:SEARCH:SUCCESS`,
          items: modifiedItems.filter((item) => item.label !== ''),
          dataItemType
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'user');
    registry.get('logger').error(err);
    yield put({ type: `DSR:USERS:LIST:SEARCH:FAIL`, error: err.message });
  }
}

export function* resetAndfetchProcessings(action) {
  const {
    dataSubjectCategories,
    assignedOrganisations,
    processingsId,
    jurisdictions,
    position = 0
  } = action;
  yield put({ type: 'DSR:PROCESSING_LIST:INIT' });
  try {
    let filterParameters = { jurisdictions };
    let url = `/v1/records/processings/filter`;
    if (dataSubjectCategories !== undefined) {
      filterParameters = {
        ...filterParameters,
        dataSubjectCategories: dataSubjectCategories.map((item) => item.id)
      };
    }
    if (assignedOrganisations !== undefined) {
      filterParameters = {
        ...filterParameters,
        dsrStakeholders: assignedOrganisations.map((item) =>
          item.value ? item.value.id : item.id
        )
      };
    }
    if (processingsId !== undefined) {
      filterParameters = {
        ...filterParameters,
        id: processingsId
      };
    }

    const requestData = {
      filterParameters,
      limit: GENERAL_FETCH_LIMIT,
      offset: position,
      fieldsParameter: [
        'ID',
        'processingName',
        'controllers',
        'processors',
        'purposes',
        'processingGrounds',
        'dataRecipients',
        'dataRecipientCategories',
        'transferGrounds',
        'retentionTerms',
        'dataSources'
      ]
    };
    const response = yield registry.get('request').post(url, requestData);
    switch (response.status) {
      case 200: {
        yield put({
          type: `DSR:PROCESSING_LIST:FETCH:SUCCESS`,
          processingsList: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'DSR:PROCESSING_LIST:FETCH:FAIL', error: err.message });
  }
}

const getUserUrl = (dataItemType, selectedOrganisations, jurisdictions) => {
  let url = `/v1/dsrmanagement/datasubjectrequests/users`;
  let filterParameters = {};
  if (dataItemType === 'approvers') {
    filterParameters = {
      ...filterParameters,
      permissionGroups: ['dataSubjectRequests-approve'],
      jurisdictions
    };
  } else {
    filterParameters = {
      ...filterParameters,
      organisationId: selectedOrganisations.map((item) =>
        item.value ? item.value.id : item.id
      ),
      permissionGroups: ['processings-read']
    };
  }
  return { url, filterParameters };
};

export function* exportDSRProcessings(action) {
  const { data, dataSubjectRequestNumber, downloadasFile = false } = action;
  const flattenData = (arr) => [].concat(...arr);
  try {
    yield put({ type: 'DSR:ATTACHMENT:UPLOAD:INIT' });
    const values = flattenData(Object.values(data));
    const idArray = values.map((item) => item.privacyRecordNumber);
    const modifiedDefinition = {
      ...exportQueryDefinition.queryDefinition.filters[0],
      value: idArray
    };
    const requestData = {
      ...exportQueryDefinition,
      queryDefinition: {
        ...exportQueryDefinition.queryDefinition,
        filters: [modifiedDefinition]
      },
      recordLayoutId: getDefaultLayoutId('processing')
    };
    const request = registry.get('request');
    let response = {};
    if (downloadasFile) {
      response = request.exportCSVbyPost(
        '/v1/reportsearches/run?queryType=csv',
        requestData
      );
    } else {
      response = yield request.exportCSVAsFile(
        '/v1/reportsearches/run?queryType=csv_inline',
        requestData,
        null,
        null
      );
    }
    switch (response.status) {
      case 200: {
        const file = new File(
          [response.body.content],
          `${dataSubjectRequestNumber}_metaData.csv`
        );
        const uploadDocumentData = {
          file,
          public: false,
          name: file.name,
          documentType: 'dsr'
        };
        yield put({
          type: 'DOCUMENTS:UPSERT',
          data: uploadDocumentData,
          isEdit: false,
          isReplace: false,
          isUsed: false,
          isFromDSR: true
        });
        break;
      }
      default: {
        if (downloadasFile) {
          yield put({ type: 'DSR:ATTACHMENT:DOWNLOAD:SUCCESS' });
        } else {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    yield put({ type: 'DSR:ATTACHMENT:UPLOAD:FAIL', error: err.message });
  }
}

export function* saveDsrComment(action) {
  const { dsrId, comment, isEdit } = action;
  try {
    yield put({ type: 'DSR:COMMENT:SAVE:INIT' });
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(
        `/v1/dsrmanagement/datasubjectrequests/${dsrId}/comments`,
        comment
      );
    } else {
      response = yield request.put(
        `/v1/dsrmanagement/datasubjectrequests/${dsrId}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        yield put({ type: 'DSR:COMMENT:SAVE:SUCCESS', comment: response.body });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dsrTranslations.commentedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'DSR:COMMENT:SAVE:FAIL', error: err.message });
  }
}

export function* deleteComment(action) {
  const { dsrId, comment } = action;
  yield put({ type: 'DSR:COMMENT:DELETE:INIT' });
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/dsrmanagement/datasubjectrequests/${dsrId}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        yield put({ type: 'DSR:COMMENT:DELETE:SUCCESS' });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: dsrTranslations.commentedDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'dsr');
    yield put({ type: 'DSR:COMMENT:DELETE:FAIL', error: err.message });
  }
}
