import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  list: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  isFetching: false,
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORD:TIA:LIST:REQUEST':
      return state.set('list', defaultState.get('list'))
        .set('error', defaultState.get('error'));
    case 'RECORD:TIA:LIST:REQUEST:SUCCESS':
      return state.set('list', Immutable.List(action.items));
    case 'RECORD:TIA:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'RECORD:TIA:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'RECORD:TIA:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'RECORD:TIA:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
