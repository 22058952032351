
import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown, GENERAL_FETCH_LIMIT } from '@packages/utils/common-utils';

export function* resetAndFetchDpo(action) {
  yield put({ type: 'DPO:LIST:REQUEST:INIT' });
  yield fetchDpo(action);
}

export function* fetchDpo(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT, dpoType } = action;
  const request = registry.get('request');
  try {
    const response = yield request.get(`/v1/records/dpo?numberOfResults=${rowCount}&offset=${position}`, null);

    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.filter(item => item[dpoType] && item[dpoType] !== '').map(item => ({
          name: item[dpoType],
          key: item[dpoType]
        }));
        yield put({
          type: 'DPO:LIST:FETCH:SUCCESS',
          data: modifiedItems,
          dpoType,
          position: response.body.length + 1
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'DPO:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* searchDpoSelector(action) {
  let { searchParams } = action;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT, dpoType } = action;

  const dpoSelectorState = yield select(state => state.dpo.get(`${dpoType}`));

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = dpoSelectorState ? dpoSelectorState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  yield put({ type: 'DPO_SELECTOR:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(`/v1/records/dpo?numberOfResults=${rowCount}&offset=${position}
      &search=${searchKey}=${encodeURIComponent(searchText)}`);
    const items = response.body;
    const modifiedItems = items.filter(item => item[dpoType] && item[dpoType] !== '').map(item => ({
      name: item[dpoType],
      key: item[dpoType]
    }));
    yield put({
      type: 'DPO_SELECTOR:LIST:SEARCH:SUCCESS',
      searchKey,
      data: modifiedItems,
      dpoType: `searchItem${dpoType}`,
      searchText
    });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'DPO_SELECTOR:LIST:SEARCH:FAIL', error: err.message });
  }
}
