import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { breachTranslations, commonTranslations } from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { addTranslations } from '@packages/utils/actionTranslations';
import { legalGroundTranslations } from '../../../recordTranslations';
import BreachGroundList from '.';

class BreachGroundListDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isFilterApplied: false
    };
    this.requestClose = this.requestClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.getFilterAppplied = this.getFilterAppplied.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleOpen() {
    this.setState({
      open: true
    });
  }

  handleOnSubmit(selectedItems) {
    if (this.props.handleSelectedItems) {
      this.props.handleSelectedItems(selectedItems);
    }
    this.requestClose();
  }

  handleClick() {
    this.props.removeFilters();
  }

  getFilterAppplied(isFilterApplied) {
    this.setState({
      isFilterApplied
    });
  }

  requestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    const selectedItems = Object.assign([], this.props.selectedItems);
    const {disabled} = this.props;
    return (
      <div style={{ paddingTop: '10px' }}>
        <Button
          id="button"
          classes={{
            root: disabled ? 'button_grey' : 'button_black',
            label: 'buttonLabel_legalgrounds'
          }}
          disabled={disabled}
          onClick={this.handleOpen}
        >
          {breachTranslations.addBreachGround}
        </Button>
        <CommonDialog
          id="breach-ground-dialog"
          show={this.state.open}
          onCancel={this.requestClose}
          title={addTranslations('addBreachGround')}
          fullWidth={true}
          maxWidth="md"
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%' }}>{legalGroundTranslations.helpNote}</div>
            <div className="legal-ground-header">
              {this.state.isFilterApplied &&
              <Button
                id="flat-button"
                variant="text"
                onClick={this.handleClick}
              >
                {commonTranslations.removeFilters}
              </Button>}
            </div>
          </div>
          <BreachGroundList
            id="breachgrounds"
            isEdit={this.props.isEdit}
            selectedItems={selectedItems}
            selectedDataRecipients={this.props.selectedDataRecipients}
            onSave={this.handleOnSubmit}
            onCancel={this.requestClose}
            getFilterAppplied={this.getFilterAppplied}
          />
        </CommonDialog>
      </div>
    );
  }
}
BreachGroundListDialog.propTypes = {
  isEdit: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  selectedDataRecipients: PropTypes.arrayOf(PropTypes.object),
  handleSelectedItems: PropTypes.func,
  removeFilters: PropTypes.func,
  disabled:PropTypes.bool
};

BreachGroundListDialog.defaultProps = {
  isEdit: false,
  selectedItems: [],
  selectedDataRecipients: [],
  handleSelectedItems: (e) => e,
  removeFilters: (e) => e,
  disabled:false
};

export default BreachGroundListDialog;
