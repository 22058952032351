import Immutable from 'immutable';
import normalizeData from './utils';
/*
records graphical view reducer sample =>
{
  recordId: "CF32AD90890BC4A79"
  isFetching: true,
  data : {
    "nodes": [
      {"id":"emailmarketing.processinggrounds", "label": "Processing Grounds", "nodeType": "root"},
      {"id":"emailmarketing.processinggrounds.6a", "label": "EU GDPR 6a", "nodeType": "content"},
      {"id":"emailmarketing.processinggrounds.9b", "label": "EU GDPR 9b", "nodeType": "content"},
      {"id":"emailmarketing.processinggrounds.12c", "label": "EU GDPR 12c", "nodeType": "content"}
    ],
    "links": [
        {"source": "emailmarketing.processinggrounds", "target": "emailmarketing.processinggrounds.6a"},
        {"source": "emailmarketing.processinggrounds", "target": "emailmarketing.processinggrounds.9b"},
        {"source": "emailmarketing.processinggrounds", "target": "emailmarketing.processinggrounds.12c"}
    ],
    "root":"emailmarketing"
  },
  schema: {
    "colorMappings": {
        "root": "#6b6b6b",
        "type": "#9b9b9b",
        "content": "#4444bb"
    },
    "colorField":"nodeType",
    "sizeMappings": {
        "root": "15",
        "type": "10",
        "content": "6"
    },
    "sizeField":"nodeType"
  }
}
*/

const defaultState = Immutable.fromJS({
  isFetching: false,
  recordId: null,
  nodeType: 'PROCESSING',
  data: {},
  schema: {
    colorMappings: {
      true: '#999999',
      false: '#cccccc',
      root: '#333333'
    },
    colorField: 'dataNode',
    sizeMappings: [20, 16, 12, 10, 8, 6, 4, 4, 4, 4],
    sizeField: 'level',
    fontField: 'dataNode',
    fontSizeMappings: {
      true: '12',
      false: '14',
      root: '18px'
    }
  },
  error: {
    message: '',
    isError: false
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORD:GRAPHICAL:VIEW:INIT':
      return state.set('recordId', action.recordId);

    case 'RECORD:GRAPHICAL:VIEW:FETCH':
      return state.set('recordId', action.recordId)
        .set('nodeType', action.nodeType)
        .set('isFetching', true);
    case 'RECORD:GRAPHICAL:VIEW:FETCH:SUCCESS':
      return state.set('isFetching', false)
        .set('data', Immutable.Map(normalizeData(action.data)));
    case 'RECORD:GRAPHICAL:VIEW:FETCH:FAIL':
      return state.set('isFetching', false)
        .set('data', Immutable.Map())
        .set('error', Immutable.Map({ message: action.error, isError: true }));

    default:
      return state;
  }
};
