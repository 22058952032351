import { takeEvery } from 'redux-saga/effects';

import { initializeHoldingTenantDetail } from './components/holding-tenants/sagas';
import {
  fetchBannerSettings,
  updateBannerSettings
} from './components/message-banner/sagas';
import {
  initializePartnerDetail,
  upsertPartnerDetail,
  resetAndfetchPartners,
  fetchPartners,
  filterPartners,
  searchPartners,
  deletePartners,
  exportPartners
} from './components/partners/sagas';
import {
  resetAndFetchUnassociatedTenants,
  fetchUnassociatedTenants,
  searchUnassociatedTenants
} from './components/partners/components/tenant-selector/sagas';
import { fetchPricing, updatePricing } from './components/pricing/sagas';
import {
  initializeTenantDetail,
  fetchTenantUsageCount,
  resetAndfetchTenants,
  fetchTenants,
  filterTenants,
  deleteTenant,
  upsertTenantDetail,
  exportTenants,
  searchTenants,
  exportTenantPartners,
  getFrameworkTemplates,
  fetchAllLibraryLayouts,
  fetchAllLibraryTemplates,
  fetchAllLibraryRecords
} from './components/tenants/sagas';
import searchPartner from './components/tenants/components/detail/components/partner-selector/sagas';
import {
  fetchReleaseNotes,
  updateReleaseNotes
} from './components/update-release/sagas';

export default [
  [takeEvery, 'HOLDING:TENANT:DETAIL:INIT', initializeHoldingTenantDetail],
  // Banner
  [takeEvery, 'ADMIN:BANNER:INIT', fetchBannerSettings],
  [takeEvery, 'ADMIN:BANNER:UPSERT', updateBannerSettings],
  // Release note
  [takeEvery, 'ADMIN:RELEASENOTE:INIT', fetchReleaseNotes],
  [takeEvery, 'ADMIN:RELEASENOTE:UPSERT', updateReleaseNotes],
  // Partner
  [takeEvery, 'PARTNER:DETAIL:INIT', initializePartnerDetail],
  [takeEvery, 'PARTNER:DETAIL:UPSERT', upsertPartnerDetail],
  [takeEvery, 'PARTNERS:LIST:REQUEST_INIT', resetAndfetchPartners],
  [takeEvery, 'PARTNERS:LIST:FETCH_INIT', fetchPartners],
  [takeEvery, 'PARTNERS:LIST:NEXT_FETCH', fetchPartners],
  [takeEvery, 'PARTNERS:LIST:SEARCH_INIT', searchPartners],
  [takeEvery, 'PARTNERS:LIST:FILTER_INIT', filterPartners],
  [takeEvery, 'PARTNERS:ITEM:DELETE', deletePartners],
  [
    takeEvery,
    'TENANT:UNASSOCIATED:LIST:REQUEST',
    resetAndFetchUnassociatedTenants
  ],
  [takeEvery, 'TENANT:UNASSOCIATED:LIST:NEXT_FETCH', fetchUnassociatedTenants],
  [takeEvery, 'TENANT:UNASSOCIATED:SEARCH_INIT', searchUnassociatedTenants],
  [takeEvery, 'REPORT_SEARCH:PARTNERS:DOWNLOAD', exportPartners],
  // Pricing
  [takeEvery, 'PRICING:PLAN:FETCH_INIT', fetchPricing],
  [takeEvery, 'PRICING:PLAN:UPDATE', updatePricing],

  // Tenants
  [takeEvery, 'TENANT_PARTNERS:LIST:SEARCH_INIT', searchPartner],
  [takeEvery, 'TENANTS:EXPORT', exportTenants],
  [takeEvery, 'TENANT:PARTNERS:EXPORT', exportTenantPartners],
  [takeEvery, 'TENANT:USAGE:COUNT:FETCH_INIT', fetchTenantUsageCount],
  [takeEvery, 'TENANTS:LIST:REQUEST_INIT', resetAndfetchTenants],
  [takeEvery, 'TENANTS:LIST:FETCH_INIT', fetchTenants],
  [takeEvery, 'TENANTS:LIST:NEXT_FETCH', fetchTenants],
  [takeEvery, 'TENANT:DETAIL:INIT', initializeTenantDetail],
  [takeEvery, 'TEMPLATES:FRAMEWORKS:INIT', getFrameworkTemplates],
  [takeEvery, 'TENANT:DETAIL:UPSERT', upsertTenantDetail],
  [takeEvery, 'TENANTS:LIST:FILTER_INIT', filterTenants],
  [takeEvery, 'TENANTS:LIST:SEARCH_INIT', searchTenants],
  [takeEvery, 'TENANT:DELETE', deleteTenant],
  [takeEvery, 'TENANT:LIBRARY_LAYOUT:FETCH', fetchAllLibraryLayouts],
  [takeEvery, 'TENANT:LIBRARY_TEMPLATE:FETCH', fetchAllLibraryTemplates],
  [takeEvery, 'TENANT:LIBRARY_RECORDS:FETCH', fetchAllLibraryRecords]
];
