import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import { commonTranslations } from '@packages/utils/commontranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

const NotificationDialog = (props) => {
  const { open, cancel, message } = props;
  const actions = [
    <Button
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      key="notification_button"
      id="notification_button"
      onClick={cancel}
    >
      {commonTranslations.Ok}
    </Button>
  ];

  return (
    <CommonDialog
      id="notification_dialog"
      show={open}
      onCancel={cancel}
      fullWidth={true}
      maxWidth="sm"
      buttonActions={actions}
      buttonPosition="right"
      showCloseIcon={false}
    >
      {message || (
        <FormattedMessage
          id="Record.editNotification"
          description="Cannot edit privacy record in requested status."
          defaultMessage="Due to the fact that this record has been marked requested,
             it cannot be edited or deleted unless a privacy officer marks it as approved or disapproved"
        />
      )}
    </CommonDialog>
  );
};

NotificationDialog.propTypes = {
  open: PropTypes.bool,
  cancel: PropTypes.func,
  message: PropTypes.string
};

NotificationDialog.defaultProps = {
  open: false,
  cancel: (e) => e,
  message: ''
};

export default NotificationDialog;
