
import { connect } from 'react-redux';
import TenantsHeader from './tenantsHeader';

const mapStateToProps = state => ({
  isSuperAdmin: (state.login.get('loggedUser') ?
    state.login.get('loggedUser').get('roles').indexOf('SuperAdministrator') !== -1 : false)
});

const mapDispatchToProps = dispatch => ({
  exportTenants: () => {
    dispatch({ type: 'TENANTS:EXPORT' });
  },
  exportTenantPartners: () => {
    dispatch({ type: 'TENANT:PARTNERS:EXPORT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TenantsHeader);
