import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';

import {
  renderTextField,
  renderSelectField
} from '@packages/components/form-components';
import { categoryTypes } from '@packages/utils/record-utils';
import {
  commonTranslations,
  recordTranslations,
  environmentHeaderTranslation
} from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import styles from '@packages/ui/styles';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import {
  renderItemSelector,
  renderChipSelector
} from '@packages/features/privacy-record-detail/renderers/renderers';

import DivWrapper from '@packages/components/divWrapper';
import ImpactAnalysisPage from '../../../impact-analysis';
import { isMasterDataEditFormVisible } from '../../../utils';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'categoryType'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });

  return errors;
};

export const PersonalDataItemForm = (props) => {
  const {
    handleSubmit,
    isEdit,
    onCancel,
    submitting,
    actionError,
    isUsed,
    hasNoLimit,
    usage,
    dataItemId,
    init,
    initialize,
    resetPersonalDataItems,
    updatePersonalDataItems,
    source,
    value,
    globalUsage,
    refreshList,
    categories,
    change,
    hideTags,
    isTagEdit
  } = props;

  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);
  const [name, setName] = useState('');
  const [categoryType, setCategoryType] = useState('normal');
  const [personalDataItemData, setPersonalDataItemData] = useState({});

  useEffect(() => {
    if (dataItemId) init(dataItemId);
    if (!isEdit) handleInitialise();
    return () => {
      resetPersonalDataItems();
    };
  }, []);

  useEffect(() => {
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    if (props.personalDataItemData !== undefined) {
      if (showForm) window.document.getElementById('pdi-name')?.focus();
      if (onAfterUpdation)
        props.onSave(props.personalDataItemData, closeDialog);
      else {
        setPersonalDataItemData(props.personalDataItemData);
        handleInitialise(props.personalDataItemData);
      }
    }
  }, [props.personalDataItemData]);

  const onSave = (personalDataItemData, closeDialog) => {
    const modifiedData = {
      ...personalDataItemData,
      name: personalDataItemData.name.trim()
    };
    const isTypeChanged =
      props.personalDataItemData?.categoryType !==
      personalDataItemData.categoryType;
    const isJobCreationRequired =
      (isUsed || (globalUsage && isTypeChanged)) && !isView;
    updatePersonalDataItems(
      modifiedData,
      isEdit,
      source,
      isJobCreationRequired
    );
    setCloseDialog(closeDialog);
    setOnAfterUpdation(true);
    if (!closeDialog) {
      initialize({
        categoryType: 'normal'
      });
    }
    if (globalUsage && isTypeChanged) {
      onCancel();
      // When job page is ready redirect to jobs instead of refreshing.
      refreshList();
    }
  };

  const handleSelectedItem = (categories) => {
    const { _rev, id } = personalDataItemData;
    initialize({ categories, name, categoryType, id, _rev });
  };

  const handleOnChange = (value, property) => {
    if (property === 'name') setName(value);
    else setCategoryType(value);
  };

  const handleInitialise = (personalDataItemData) => {
    if (!isEdit) initialize({ ...value, categoryType: 'normal' });
    else {
      setCategoryType(personalDataItemData.categoryType);
      initialize(personalDataItemData);
    }
  };

  const handleTagChange = (data) => {
    change('tags', data);
  };

  const TABLE_MAXHEIGHT = 392;
  const WINDOW_INNERHEIGHT = 700;
  const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
  const isView = isEdit && isTagEdit;

  return (
    <form>
      {isUsed && (
        <ImpactAnalysisPage
          hasNoLimit={hasNoLimit}
          usage={usage}
          type="personaldata"
        />
      )}
      {showForm && (
        <DivWrapper
          autoHeight={true}
          autoHeightMax={getDialogContentHeight(
            WINDOW_INNERHEIGHT,
            TABLE_MAXHEIGHT
          )}
        >
          <div>
            <Field
              id="pdi-name"
              name="name"
              label={
                <FormattedMessage
                  id="PersonalDataItems.name"
                  description="Personal data name"
                  defaultMessage="Personal data name"
                />
              }
              hintTextLabel={
                <FormattedMessage
                  id="PersonalDataItems.name"
                  description="Personal data name"
                  defaultMessage="Personal data name"
                />
              }
              autoFocus={true}
              onChange={(event, name) => handleOnChange(name, 'name')}
              component={renderTextField}
              isView={isView}
            />
          </div>
          <div>
            <FieldArray
              id="categories"
              name="categories"
              type="personalDataCategories"
              headerLabel={recordTranslations.personalDataCategories}
              itemLabel={environmentHeaderTranslation('personalDataCategories')}
              hintText={
                <FormattedMessage
                  id="PersonalDataItems.categoryHint"
                  description="Hint text for personal data category"
                  defaultMessage="Add or select a category..."
                />
              }
              updatePersonalDataCategories={handleSelectedItem}
              selectedItems={Object.assign(
                [],
                categories ? categories.value : []
              )}
              isNote={false}
              component={renderItemSelector}
              isView={isView}
            />
          </div>
          <div>
            <Field
              id="pdi-categoryType"
              name="categoryType"
              label={recordTranslations.type}
              defaultSelected="normal"
              component={renderSelectField}
              onChange={(event, type) => handleOnChange(type, 'categoryType')}
              style={{ width: '90%' }}
              isView={isView}
            >
              {categoryTypes.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div>
            {!hideTags && (
              <FieldArray
                id="tags"
                name="tags"
                type="tags"
                entityType="personaldataitem"
                disabled={isEdit && !isTagEdit}
                headerLabel={commonTranslations.tags}
                hintText={commonTranslations.addTags}
                updateTags={handleTagChange}
                chipSelectorStyle={{ width: '100%' }}
                showHeader={isTagEdit || !isEdit}
                disabledTagStyle={{ marginTop: 16 }}
                component={renderChipSelector}
              />
            )}
          </div>
        </DivWrapper>
      )}
      {actionError && actionError.isError && (
        <div
          style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}
        >
          {actionError.message}
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        {showForm && (
          <Button
            id="submit"
            disabled={submitting}
            onClick={handleSubmit((personalDataItemData) =>
              onSave(personalDataItemData, true)
            )}
          >
            {commonTranslations.saveAndClose}
          </Button>
        )}
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            onClick={handleSubmit((personalDataItemData) =>
              onSave(personalDataItemData, false)
            )}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}
        {showForm && (
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        )}
        {!hasNoLimit && isUsed && (
          <div style={{ float: 'right' }}>
            <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
          </div>
        )}
      </div>
    </form>
  );
};

PersonalDataItemForm.propTypes = {
  categories: PropTypes.instanceOf(Immutable.List),
  isEdit: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetPersonalDataItems: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  personalDataItemData: PropTypes.shape({
    categoryType: PropTypes.string
  }),
  updatePersonalDataItems: PropTypes.func,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  dataItemId: PropTypes.string,
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  globalUsage: PropTypes.bool,
  refreshList: PropTypes.func,
  change: PropTypes.func,
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

PersonalDataItemForm.defaultProps = {
  isEdit: false,
  categories: Immutable.List(),
  initialize: (e) => e,
  handleSubmit: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  resetPersonalDataItems: (e) => e,
  personalDataItemData: {},
  updatePersonalDataItems: (e) => e,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  globalUsage: false,
  refreshList: (e) => e,
  change: (e) => e,
  isTagEdit: false,
  hideTags: false
};

const PersonalDataItemFormWrapper = reduxForm({
  form: 'PersonalDataItemForm', // a unique identifier for this form
  validate
})(PersonalDataItemForm);

const selector = formValueSelector('PersonalDataItemForm');
const PersonalDataItemFormComponent = connect((state) => {
  const value = selector(state, 'categories');
  return {
    categories: value
  };
})(PersonalDataItemFormWrapper);

export default PersonalDataItemFormComponent;
