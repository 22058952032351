import { connect } from 'react-redux';
import TransferGroundList from './transferGroundList';

const mapStateToProps = (state) => ({
  isFetching: state.transferGrounds.get('isFetching'),
  searchText: state.transferGrounds.get('searchText'),
  searchItems: state.transferGrounds.get('searchItems').toJS(),
  sortOrder: state.transferGrounds.get('filterParams').get('sortOrder'),
  sortKey: state.transferGrounds.get('filterParams').get('sortOn'),
  filterColumn: state.transferGrounds.get('filterColumn').toJS(),
  filteredOn: state.transferGrounds.get('filteredOn').toJS(),
  transferGrounds: state.transferGrounds.get('transferGrounds'),
  error: state.transferGrounds.get('error')
});

const mapDispatchToProps = (dispatch) => ({
  initTransferGrounds: (jurisdiction) => {
    dispatch({
      type: 'TRANSFER_GROUNDS:LIST:INIT',
      jurisdiction
    });
  },
  onChooseFilter: (filterParams) => {
    dispatch({ type: 'TRANSFER_GROUNDS:LIST:FILTER_INIT', filterParams });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'TRANSFER_GROUNDS:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferGroundList);
