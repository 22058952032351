import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  data: {},
  actionError: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ADMIN:RELEASENOTE:FETCH':
      return state.set('data', defaultState.get('data'));
    case 'ADMIN:RELEASENOTE:FETCH:SUCCESS':
    case 'ADMIN:RELEASENOTE:UPSERT:SUCCESS':
      return state.set('data', action.data.message);
    case 'ADMIN:RELEASENOTE:FETCH:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    default:
      return state;
  }
};
