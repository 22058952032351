/* eslint-disable consistent-return */
import Immutable from 'immutable';

import messageTranslations from './messageTranslations';

// eslint-disable-next-line import/prefer-default-export
// TODO: make this relavant to Message Center
export const filterMenuItems = (userPermissions, filterMenus) =>
  filterMenus.filter(
    (item) =>
      ({
        messages: userPermissions.viewMessage,
        tasks: userPermissions.viewTask,
        notifications: userPermissions.viewNotification
      }[item.key])
  );

export const getCurrentMergeItems = (value, items, dataItemId) => {
  let newItem = items;
  if (dataItemId) {
    const index = items.findIndex((item) => item.id === dataItemId);
    if (index !== -1) {
      newItem = items.splice(index, 1);
    }
  } else {
    const index = items.findIndex((item) => item.id === value.id);
    if (index !== -1) {
      newItem = items.splice(index, 1);
    } else {
      newItem = items.push({ ...value });
    }
  }
  return newItem;
};

export const checkForBulkItems = (items, mergeItems) => {
  const mergeItemIds = mergeItems.map((item) => item.id);
  const newItems = [];
  items.forEach((item) => {
    if (mergeItemIds.includes(item.id)) {
      newItems.push({ ...item, selected: true });
    } else if (item.selected) {
      newItems.push({ ...item, selected: false });
    } else {
      newItems.push(item);
    }
  });
  return Immutable.List(newItems);
};

export const getReducerTypeForNotifications = (currentSubmenu) => {
  switch (currentSubmenu) {
    case 'inboxNotifications':
      return 'INBOX';
    case 'unReadNotifications':
      return 'UNREAD';
    default:
      return 'INBOX';
  }
};

export const taskStatusMapper = (type) => {
  switch (type) {
    case 'Active':
      return messageTranslations.active;
    case 'Completed':
      return messageTranslations.completed;
    default:
      return type;
  }
};

export const getReducerType = (type) => {
  switch (type) {
    case 'inboxTasks':
      return 'INBOX';
    case 'unclaimedTasks':
      return 'UNCLAIMED';
    default:
      return type;
  }
};

export const addCountToFilterMenu = (filterMenus, count, formatMessage) => {
  let modifiedValues = filterMenus;
  if (count.size > 0) {
    // eslint-disable-next-line array-callback-return
    modifiedValues = modifiedValues.map((item) => {
      if (item.key !== 'tasks') {
        const allValue = count.get(item.key).total;
        const value = count.get(item.key).unread;
        const submenuType =
          item.key === 'messages'
            ? 'unRead_messages'
            : 'unReadNotifications_notifications';
        const subMenus = getSubMenuItem(
          item,
          value,
          submenuType,
          formatMessage
        );
        return {
          ...item,
          label: `${formatMessage(item.label.props)} (${allValue})`,
          subMenu: subMenus
        };
      }
      if (item.key === 'tasks') {
        const value = count.get('tasks').unclaimed;
        const subMenus = getSubMenuItem(
          item,
          value,
          'unclaimedTasks_tasks',
          formatMessage
        );
        return {
          ...item,
          label: `${formatMessage(item.label.props)} (${
            value + count.get('tasks').claimedByMe
          })`,
          subMenu: subMenus
        };
      }
    });
  }
  return modifiedValues;
};

const getSubMenuItem = (item, value, subKey, formatMessage) => {
  const subMenus = item.subMenu.map((subMenu) => {
    if (subMenu.key === subKey) {
      return {
        ...subMenu,
        label: `${formatMessage(subMenu.label.props)} (${value})`
      };
    }
    return subMenu;
  });
  return subMenus;
};

export const getUpdatedCount = (
  count,
  menu,
  assignee,
  status,
  loggedInUser
) => {
  let modifiedCount = count;
  if (menu !== 'tasks') {
    modifiedCount = {
      ...count,
      [menu]: {
        ...count[menu],
        unread: status ? count[menu].unread - 1 : count[menu].unread + 1
      }
    };
  } else {
    let { claimedByMe, unclaimed } = count[menu];
    if (status === 'claim') {
      claimedByMe += 1;
      unclaimed = assignee && assignee.id ? unclaimed : unclaimed - 1;
    } else if (status === 'unclaim') {
      claimedByMe =
        assignee && assignee.id === loggedInUser
          ? claimedByMe - 1
          : claimedByMe;
      unclaimed += 1;
    } else {
      claimedByMe =
        assignee && assignee.id === loggedInUser
          ? claimedByMe - 1
          : claimedByMe;
      unclaimed = assignee && assignee.id ? unclaimed : unclaimed - 1;
    }
    modifiedCount = {
      ...count,
      [menu]: {
        ...count[menu],
        claimedByMe,
        unclaimed
      }
    };
  }
  return modifiedCount;
};

export const isRecordAccessible = (recordType, permissions) =>
  ({
    assessment: permissions.viewPreDpia,
    processing: permissions.viewProcessing,
    breach: permissions.viewBreach,
    tia: permissions.viewTia
  }[recordType] || true);
