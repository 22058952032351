import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import styles from '@packages/ui/styles';
import { tenantTranslations } from '@packages/utils/commontranslations';

class TenantsHeader extends React.Component {
  constructor(props) {
    super(props);

    this.createTenant = this.createTenant.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleExportTenantPartners = this.handleExportTenantPartners.bind(this);
  }

  handleExport() {
    this.props.exportTenants();
  }

  handleExportTenantPartners() {
    this.props.exportTenantPartners();
  }

  createTenant() {
    this.context.router.history.push('/tenant/create');
  }

  render() {
    const { isSuperAdmin } = this.props;
    const toolbarTitleTheme = { ...styles.toolbarTitle, flexGrow: 1 };
    return (
      <Toolbar disableGutters={true}>
        <div style={toolbarTitleTheme}>
          <FormattedMessage
            id="Tenants.tenants"
            description="Tenants"
            defaultMessage="Tenants"
          />
        </div>
        {(this.props.hasCreateTenantPermission || this.props.isSuperAdmin) &&
        <div style={{ display: 'flex' }}>
          <Button
            id="create_button"
            style={{ height: '45px', float: 'right', marginRight: 0, marginTop: '0px' }}
            onClick={this.createTenant}
          >
            {tenantTranslations.addTenant}
          </Button>
          <Button
            id="export_Button"
            style={{ height: '45px', float: 'right', marginLeft: 10, marginTop: '0px' }}
            onClick={this.handleExport}
          >
            <FormattedMessage
              id="Tenants.exportTenants"
              description="Add export label for tenant"
              defaultMessage="Export tenants"
            />
          </Button>
          {isSuperAdmin &&
          <Button
            id="export_tenant_partners"
            style={{ height: '45px', float: 'right', marginLeft: 10, marginTop: '0px' }}
            onClick={this.handleExportTenantPartners}
          >
            <FormattedMessage
              id="Tenants.exportTenantPartners"
              description="Add export label for tenant"
              defaultMessage="Export tenant partners"
            />
          </Button>}
        </div>
      }
      </Toolbar>
    );
  }
}

TenantsHeader.contextTypes = {
  router: PropTypes.object.isRequired
};

TenantsHeader.propTypes = {
  exportTenants: PropTypes.func,
  hasCreateTenantPermission: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  exportTenantPartners: PropTypes.func
};

TenantsHeader.defaultProps = {
  exportTenants: e => e,
  hasCreateTenantPermission: true,
  isSuperAdmin: true,
  exportTenantPartners: e => e
};

TenantsHeader.displayName = 'TenantsHeader';

export default TenantsHeader;
