import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { breachTranslations } from '@packages/utils/commontranslations';
import ValueSelector from '../value-selector';

class SupervisoryAuthoritySelector extends React.Component {
  componentWillMount() {
    if (this.props.initSupervisoryAuthorities) {
      this.props.initSupervisoryAuthorities();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
    !(this.props.supervisoryAuthorities === nextProps.supervisoryAuthorities);
  }

  render () {
    const { hintTextLabel, supervisoryAuthorities } = this.props;

    return (
      <div>
        <ValueSelector
          valueAttr="name"
          dataItems={supervisoryAuthorities}
          liveAutocomplete={false}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          selectFromListMenuItem={true}
          hintTextLabel={hintTextLabel}
          title={
            <h3>
              {breachTranslations.selectSupervisoryAuthorities}
            </h3>}
          {...this.props}
        />
      </div>
    );
  }
}

SupervisoryAuthoritySelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  hintTextLabel: PropTypes.node,
  initSupervisoryAuthorities: PropTypes.func,
  supervisoryAuthorities: PropTypes.instanceOf(Immutable.List)
};

SupervisoryAuthoritySelector.defaultProps = {
  hintTextLabel: null,
  initSupervisoryAuthorities: e => e,
  supervisoryAuthorities: Immutable.List()
};

export default SupervisoryAuthoritySelector;
