import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOrder: 'DESC',
    sortOn: 'createdAt'
  }),
  isFetching: false,
  filterColumn: {},
  jobDetail: undefined,
  usage: undefined,
  showNotification: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DATA_LIBRARY:JOBS:INIT':
      return state
        .set('filteredOn', state.get('filteredOn'))
        .set('filterParams', state.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('jobDetail', defaultState.get('jobDetail'))
        .set('usage', defaultState.get('usage'))
        .set('position', 0);
    case 'DATA_LIBRARY:JOBS:FETCH':
      return state
        .set('error', defaultState.get('error'))
        .set('isFetching', true);
    case 'DATA_LIBRARY:JOBS:FETCH:SUCCESS': {
      let newItems = Immutable.List();
      let newPosition = 0;
      newItems = state.get('items').concat(action.items);
      newPosition = state.get('position') + action.items.length;
      return state.set('items', newItems)
        .set('position', newPosition)
        .set('jobDetail', defaultState.get('jobDetail'))
        .set('isFetching', false);
    }
    case 'DATA_LIBRARY:JOBS:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }))
        .set('isFetching', false);
    case 'DATA_LIBRARY:JOBS:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('position', 0)
        .set('items', Immutable.List())
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('usage', defaultState.get('usage'))
        .set('filteredOn', Immutable.Map(action.filteredOn))
    }
    case 'DATA_LIBRARY:JOBS:FILTER:RESET':
      return state.set('filterParams', defaultState.get('filterParams'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterColumn', defaultState.get('filterColumn'))
    case 'DATA_LIBRARY:JOBS:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'DATA_LIBRARY:JOB:UPDATE:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
