import {
  getFilteredOn,
  getSearchText,
  removeDuplicates
} from '@packages/utils/reducer-utils';
import Immutable from 'immutable';
import { getCurrentMergeItems } from '../../utils';

const defaultState = Immutable.fromJS({
  data: Immutable.List([]),
  customMasterDataField: {},
  bulkItems: Immutable.List(),
  isFetching: false,
  items: undefined,
  newItems: Immutable.List(),
  position: 0,
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  searchText: Immutable.Map(),
  customDataValue: {},
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  usage: undefined,
  unused: false,
  filteredOn: Immutable.Map(),
  filterColumn: {},
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH:INIT': {
      return state
        .set('items', defaultState.get('items'))
        .set('position', defaultState.get('position'))
        .set('entityType', action.entityType)
        .set('usage', defaultState.get('usage'))
        .set('isFetching', false)
        .set('customDataValue', defaultState.get('customDataValue'))
        .set('newItems', defaultState.get('newItems'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filterParams', defaultState.get('filterParams'))
        .set('bulkItems', defaultState.get('bulkItems'))
        .set('searchText', defaultState.get('searchText'));
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH:SUCCESS': {
      return state.set('customMasterDataField', action.data);
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set(
        'bulkItems',
        getCurrentMergeItems(
          action.item,
          items,
          action.dataItemId,
          action.unused
        )
      );
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH:INIT':
      return state
        .set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('customDataValue', defaultState.get('customDataValue'));
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.customDataValue) {
        newItems = newItems.unshift(action.customDataValue);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(
        newItems,
        state.get('items'),
        action.items
      );
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', items);
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), {
        [filterParams.filterKey]: filterParams.filterKey
      });
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set(
          'filteredOn',
          getFilteredOn(
            Object.assign({}, action.filterParams, {
              filterObj: state.get('filteredOn')
            })
          )
        )
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set(
          'searchText',
          getSearchText(
            Object.assign({}, action, {
              searchObj: state.get('searchText').toJS()
            })
          )
        )
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState
          .set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('customDataValue', action.customDataValue)
        .set(
          'error',
          Immutable.Map({
            message: '',
            isError: false
          })
        );
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('newItems', action.newItems)
        .set(
          'position',
          action.position ? action.position : state.get('position')
        );

    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:MERGE:ITEM:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'MASTERDATA:CUSTOMSIMPLEMASTERDATA:USAGE:SUCCESS':
      return state.set('isFetching', false).set('usage', action.usage);
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:USAGE:RESET':
      return state.set('usage', defaultState.get('usage'));
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:USAGE:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'MASTERDATA:CUSTOMSIMPLEMASTERDATA:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);
    case 'MASTERDATA:CUSTOM_SIMPLE_DATA:USAGE:FETCH:INIT':
      return state.set('isFetching', true);
    case 'MASTERDATA:SIMPLECUSTOM:VIEW:SIMILAR:SUCCESS':
      return state.set('items', Immutable.List(action.items));
    case 'CUSTOM:MASTER_DATA_VALUE:ITEM:FETCH:SUCCESS':
      return state.set('customDataValue', action.item);
    case 'MASTERDATA:SIMPLECUSTOM:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:SIMPLECUSTOM:SUB_TENANT:USAGE:RESET':
      return state.set('globalUsage', defaultState.get('globalUsage'));
    default:
      return state;
  }
};
