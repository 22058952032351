import { connect } from 'react-redux';
import EntitySelector from './entitySelector';

function mapDispatchToProps(dispatch) {
  return {
    init: (isInternal, entityFilter) => {
      dispatch({ type: 'ORGANISATION_ENTITY:LIST:REQUEST', isInternal, rowCount: 15, entityFilter });
    },
    onSearch: (searchParams, isVendor) => {
      dispatch({ type: 'ORGANISATION_ENTITY:LIST:SEARCH_INIT', searchParams, isVendor });
    }
  };
}

function mapStateToProps(state) {
  return {
    organisationEntities: state.organisationEntities.get('organisationEntities'),
    searchResults: state.organisationEntities.get('items'),
    holdingOrgList: state.organisationEntities.get('holdingOrgList')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitySelector);
