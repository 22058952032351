import { connect } from 'react-redux';
import DpoOrganisationsSelector from './component';

const mapStateToProps = state => ({
  item: state.dpoOrganisations.get('name'),
  position: state.dpoOrganisations.get('position'),
  searchItem: state.dpoOrganisations.get('searchItem')
});

const mapDispatchToProps = dispatch => ({
  initDpo: () => {
    dispatch({ type: 'DPO_ORGANISATIONS:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'DPO_ORGANISATIONS:LIST:REQUEST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'DPO_ORGANISATIONS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DpoOrganisationsSelector);
