/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import keycode from 'keycode';
import TextField from '@material-ui/core/TextField';

import AutoComplete from '@packages/components/auto-complete';
import { renderSelectField } from '@packages/components/form-components';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import { setLastActivityTime } from '@packages/utils/common-utils';
import styles from '@packages/ui/styles';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import RetentionOffsetDialog from '@packages/features/environment/components/retention-terms/components/retentionOffsetDialog';
import { addKeyToRetentionTerm } from '@packages/features/privacy-record-detail/common-utils';

const rootStyle = {
  width: '90%'
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });
  return errors;
};
const validationErrorMsg = (
  <FormattedMessage
    id="RetentionTerm.required"
    description="Retention term should not be empty"
    defaultMessage="Please provide a valid time period or an offset for the retention term."
  />
);

const durations = [
  {
    value: 'Y',
    name: recordTranslations.years
  },
  {
    value: 'M',
    name: recordTranslations.months
  },
  {
    value: 'W',
    name: recordTranslations.weeks
  },
  {
    value: 'D',
    name: recordTranslations.days
  },
  {
    value: 'H',
    name: recordTranslations.hours
  },
  {
    value: 'MT',
    name: recordTranslations.minutes
  }
];

const handleKeyDown = (event) => {
  switch (keycode(event)) {
    case '0':
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case 'numpad 0':
    case 'numpad 1':
    case 'numpad 2':
    case 'numpad 3':
    case 'numpad 4':
    case 'numpad 5':
    case 'numpad 6':
    case 'numpad 7':
    case 'numpad 8':
    case 'numpad 9':
    case 'left':
    case 'right':
    case 'backspace':
    case 'delete':
      break;
    default:
      event.preventDefault();
      break;
  }
};

const renderTextField = injectIntl(
  ({
    input,
    label,
    helpNotes,
    multiLine,
    rows,
    meta: { error, submitFailed },
    disabled,
    style,
    textFieldStyle,
    hintStyle,
    risks,
    intl: { formatMessage },
    classes,
    ...custom
  }) => {
    const hintTextLabel = custom.hintTextLabel
      ? formatMessage(custom.hintTextLabel.props)
      : '';
    return (
      <div style={style || rootStyle}>
        {label && (
          <ListSubheader style={styles.labelField}>{label}</ListSubheader>
        )}
        <div style={styles.textBox}>
          <TextField
            variant="outlined"
            placeholder={hintTextLabel}
            style={
              multiLine === true ? styles.multiLineTextField : styles.textField
            }
            disabled={disabled}
            multiline={multiLine}
            rows={rows}
            {...input}
            InputProps={{
              inputProps: { min: '1' }
            }}
            name=""
            onKeyDown={(event) => handleKeyDown(event)}
            onChange={(event) => {
              input.onChange(event.target.value);
              setLastActivityTime();
            }}
            onBlur={(event) => {
              input.onChange(event.target.value.trim());
              input.onBlur(event.target.value.trim());
            }}
            {...custom}
          />
        </div>
        <div style={styles.errorText}>
          {submitFailed && error && <span>{error}</span>}
        </div>
      </div>
    );
  }
);

renderTextField.propTypes = {
  hintTextLabel: PropTypes.node,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.node,
  helpNotes: PropTypes.node,
  multiLine: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.shape({}),
    submitFailed: PropTypes.bool
  }).isRequired,
  input: PropTypes.shape({}),
  textFieldStyle: PropTypes.shape({}),
  hintStyle: PropTypes.shape({})
};

renderTextField.defaultProps = {
  rows: 2,
  multiLine: false,
  risks: [],
  disabled: false,
  hintTextLabel: null,
  label: null,
  helpNotes: null,
  style: null,
  input: {},
  textFieldStyle: {},
  hintStyle: { top: '7px' }
};

const RetentionTermForm = (props) => {
  const {
    isEdit,
    submitting,
    initialize,
    filteredRetentionTerms,
    onScrollStop,
    masterOffsets,
    offsetDatasource,
    intl,
    onSaveRetentionTerm,
    onCancel,
    handleSubmit,
    value,
    actionError,
    inputType,
    onOffsetSearch,
    dataSourceFilter,
    onChooseFilter,
    tagSearch,
    tagSearchResult,
    getNextTags,
    tagsPosition,
    filterTagValue,
    hideTags,
    createNewMenuItem
  } = props;

  const [offsetSearchText, setOffsetSearchText] = useState(
    value && value.offset ? value.offset : null
  );
  const [openRetentionOffset, setOpenRetentionOffset] = useState(false);
  const [retentionOffset, setRetentionOffset] = useState({});
  const [multiple, setMultiple] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);

  useEffect(() => {
    if (!isEdit) handleInitialise();
    else {
      const modifiedItem = {
        ...value,
        period: value?.period ? value.period.match(/(\d+)/)[0] : null,
        duration: value?.period ? getModifiedDuration(value.period) : null
      };
      setOffsetSearchText(value?.offset || null);
      setRetentionOffset(value?.offset || null);
      initialize(modifiedItem);
    }
    return () => {
      initialize({});
      setOffsetSearchText(null);
    };
  }, []);

  const handleSelectedItem = (selectedItem) => {
    setRetentionOffset(selectedItem.value);
    setOffsetSearchText(selectedItem.value);
    setOpenRetentionOffset(false);
  };

  const handleClose = () => {
    setOpenRetentionOffset(false);
    setMultiple(false);
  };

  const handleMultipleItems = (selectedItem) => {
    setOffsetSearchText(
      selectedItem.size > 0 ? selectedItem.get(0).value : null
    );
    setRetentionOffset(
      selectedItem.size > 0 ? selectedItem.get(0).value : null
    );
    setMultiple(false);
  };

  const handleInitialise = (retentionTermItem) => {
    if (!isEdit) initialize(value);
    else {
      initialize(retentionTermItem);
    }
  };

  const onSave = (retentionTermData, closeDialog) => {
    const periodValue =
      retentionTermData.period &&
      createPeriod(retentionTermData.period, retentionTermData.duration);
    const modifiedRetentionTerm = addKeyToRetentionTerm(
      {
        period: periodValue,
        offset:
          retentionOffset &&
          (!retentionOffset.name || retentionOffset.name === '')
            ? null
            : retentionOffset
      },
      intl.formatMessage
    );
    const isValid = validRetentionTerm(
      modifiedRetentionTerm,
      retentionTermData.period,
      retentionTermData.duration
    );
    if (isValid) {
      onSaveRetentionTerm(modifiedRetentionTerm, closeDialog, isEdit);
      setRetentionOffset({});
    }
    if (!closeDialog && isValid) {
      initialize({});
      setOffsetSearchText(null);
      setDuplicateError(false);
      setValidationError(false);
    }
  };

  const onOffsetNewRequest = (selectedItem, index) => {
    if (index === -2) {
      setOffsetSearchText(null);
      setOpenRetentionOffset(true);
      setValidationError(false);
      setDuplicateError(false);
    } else if (index === -3) {
      // If selected index is -3, select from a list
      setMultiple(true);
      setValidationError(false);
      setDuplicateError(false);
    } else if (index === -1) {
      const value = offsetDatasource.find(
        (x) => x.name === selectedItem.trim()
      );
      if (!((!offsetDatasource || !value) && selectedItem.trim() !== '')) {
        setOffsetSearchText(value);
      }
    } else {
      setOffsetSearchText(selectedItem);
      setRetentionOffset(selectedItem);
      setValidationError(false);
      setDuplicateError(false);
    }
  };

  const onOffsetUpdateInput = (searchText) => {
    setOffsetSearchText(searchText !== '' ? { name: searchText } : null);
  };

  const getModifiedDuration = (period) => {
    const periodValue = period.substring(period.length - 1);
    if (periodValue === 'M' && period.includes('T')) {
      return 'MT';
    }
    return periodValue;
  };

  const createPeriod = (period, duration) => {
    switch (duration) {
      case 'Y':
        return `P${period.trim()}Y`;
      case 'M':
        return `P${period.trim()}M`;
      case 'W':
        return `P${period.trim()}W`;
      case 'D':
        return `P${period.trim()}D`;
      case 'H':
        return `PT${period.trim()}H`;
      case 'MT':
        return `PT${period.trim()}M`;
      default:
        return duration;
    }
  };

  const validRetentionTerm = (
    modifiedRetentionTerm = undefined,
    retentionTermPeriod,
    retentionTermDuration
  ) => {
    const { period, offset, periodKey } = modifiedRetentionTerm;
    let isValid = true;
    if (!period && !offset) {
      setValidationError(true);
      isValid = false;
    } else if (period && offset) {
      if (!offset.id) {
        setValidationError(true);
        isValid = false;
      }

      if (
        filteredRetentionTerms.findIndex(
          (item) =>
            item.period === period &&
            item.offset &&
            item.offset.name === offset.name
        ) !== -1
      ) {
        setDuplicateError(true);
        isValid = false;
      }

      if (period === periodKey) {
        // to make sure if there is digit and word combination selected
        isValid = false;
      }
    } else if (period && !offset) {
      if (
        filteredRetentionTerms.findIndex(
          (item) => item.period === period && !item.offset
        ) !== -1
      ) {
        setDuplicateError(true);
        isValid = false;
      }
      if (period === periodKey) {
        isValid = false;
      }
    } else if (!period && offset) {
      if (!offset.id) {
        setValidationError(true);
        isValid = false;
      }
      if (!retentionTermPeriod && retentionTermDuration) {
        setValidationError(true);
        isValid = false;
      }
      if (periodKey !== '') {
        setValidationError(true);
        isValid = false;
      } else if (
        filteredRetentionTerms.findIndex(
          (item) =>
            !item.period && item.offset && item.offset.name === offset.name
        ) !== -1
      ) {
        setDuplicateError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const offsetHintText = (
    <FormattedMessage
      id="PersonalDataItem.add.retentionOffset.hintText"
      description="Add retention period offset"
      defaultMessage="Offset"
    />
  );

  return (
    <form>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Field
          name="period"
          label={
            <FormattedMessage
              id="PersonalDataItem.add.retentionPeriod.hintText"
              description="Add retentionPeriod"
              defaultMessage="Period"
            />
          }
          style={{ width: '45%' }}
          hintTextLabel={
            <FormattedMessage
              id="RetentionPeriod.hintTextlabel"
              description="Add retentionPeriod"
              defaultMessage="Add period"
            />
          }
          type="number"
          component={renderTextField}
        />
        <Field
          name="duration"
          label={
            <FormattedMessage
              id="RetentionTerm.add.durationLabel"
              description="Duration"
              defaultMessage="Duration"
            />
          }
          style={{ width: '45%', paddingLeft: 20 }}
          component={renderSelectField}
        >
          <MenuItem
            value=""
            classes={{ selected: 'menuitem-selected' }}
            disabled={true}
          >
            <FormattedMessage
              id="Retention.selectDuration"
              description="Select duration"
              defaultMessage="Select duration"
            />
          </MenuItem>
          {durations &&
            durations.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.name}
              </MenuItem>
            ))}
        </Field>
      </div>
      <div style={{ width: '90%' }}>
        <ListSubheader style={styles.labelField}>
          {offsetHintText}
        </ListSubheader>
        <AutoComplete
          id="text-field"
          underlineShow={false}
          openOnFocus={true}
          inputType={inputType}
          maxSearchResults={3}
          createNewMenuItem={createNewMenuItem}
          selectFromListMenuItem={true}
          fullWidth={true}
          hintText={intl.formatMessage(offsetHintText.props)}
          searchText={(offsetSearchText && offsetSearchText.name) || ''}
          filter={dataSourceFilter || AutoComplete.caseInsensitiveFilter}
          hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
          textFieldStyle={styles.autoComplete}
          onNewRequest={onOffsetNewRequest}
          dataSource={offsetDatasource}
          dataSourceConfig={{ text: 'key', value: 'id' }}
          onUpdateInput={onOffsetUpdateInput}
          onSearch={onOffsetSearch}
        />
      </div>
      {validationError && (
        <div style={Object.assign({}, styles.errorMessage, { marginTop: 10 })}>
          {validationErrorMsg}
        </div>
      )}
      {duplicateError && (
        <div style={Object.assign({}, styles.errorMessage, { marginTop: 10 })}>
          <FormattedMessage
            id="RetentionTerm.duplicate"
            description="Duplicate Retention term"
            defaultMessage="Similar Retention term has already been selected."
          />
        </div>
      )}
      {actionError && actionError.isError && (
        <div
          style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}
        >
          {actionError.message}
        </div>
      )}
      <div style={styles.addButton}>
        <Button
          id="submit"
          type="submit"
          disabled={submitting}
          onClick={handleSubmit((retentionTermData) =>
            onSave(retentionTermData, true)
          )}
        >
          {commonTranslations.saveAndClose}
        </Button>
        {!isEdit && (
          <Button
            id="save-create"
            variant="text"
            onClick={handleSubmit((retentionTermData) =>
              onSave(retentionTermData, false)
            )}
          >
            {commonTranslations.saveAndCreate}
          </Button>
        )}

        <Button variant="text" onClick={onCancel}>
          {commonTranslations.Cancel}
        </Button>
        {openRetentionOffset && (
          <RetentionOffsetDialog
            id="retentionOffset-dialog"
            isEdit={false}
            isUsed={false}
            open={openRetentionOffset}
            inputValue={offsetSearchText}
            onRequestClose={handleClose}
            handleSelectedItem={handleSelectedItem}
            source="record"
            hideTags={hideTags}
          />
        )}
        {multiple && (
          <MultipleSelectorDialog
            id="multiple_selector"
            multiValue={false}
            title={
              <FormattedMessage
                id="MultipleRetentiontermOffsetsDialog.header"
                description="Select retention term offsets dialog header"
                defaultMessage="Select retention term offsets"
              />
            }
            open={multiple}
            filteredData={masterOffsets}
            onScrollStop={onScrollStop}
            onRequestClose={handleClose}
            handleMultipleItems={handleMultipleItems}
            showFilterByTag={!hideTags}
            filterTagValue={filterTagValue}
            onChooseFilter={onChooseFilter}
            tagSearch={tagSearch}
            tagSearchResult={tagSearchResult}
            getNextTags={getNextTags}
            tagsPosition={tagsPosition}
          />
        )}
      </div>
    </form>
  );
};

RetentionTermForm.propTypes = {
  isEdit: PropTypes.bool,
  processingCategorydata: PropTypes.shape({}),
  submitting: PropTypes.bool.isRequired,
  initialize: PropTypes.func,
  filteredRetentionTerms: PropTypes.shape({
    findIndex: PropTypes.func
  }),
  onScrollStop: PropTypes.func,
  masterOffsets: PropTypes.shape({}),
  offsetDatasource: PropTypes.shape({}),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  onSaveRetentionTerm: PropTypes.func,
  formatMessage: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.shape({
    name: PropTypes.string,
    offset: PropTypes.shape({}),
    period: PropTypes.string
  }).isRequired,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  usage: PropTypes.shape({}),
  inputType: PropTypes.string,
  onOffsetSearch: PropTypes.func,
  dataSourceFilter: PropTypes.func,
  onChooseFilter: PropTypes.func,
  tagSearch: PropTypes.func,
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  filterTagValue: PropTypes.instanceOf(Immutable.List),
  hideTags: PropTypes.bool,
  createNewMenuItem: PropTypes.bool
};

RetentionTermForm.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  onScrollStop: (e) => e,
  onCancel: (e) => e,
  handleSubmit: (e) => e,
  masterOffsets: {},
  intl: {
    formatMessage: (e) => e
  },
  formatMessage: (e) => e,
  offsetDatasource: {},
  onOffsetSearch: (e) => e,
  inputType: '',
  filteredRetentionTerms: {
    findIndex: (e) => e
  },
  dataSourceFilter: (e) => e,
  onSaveRetentionTerm: (e) => e,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  processingCategorydata: {},
  usage: {},
  filterTagValue: Immutable.List(),
  tagSearchResult: Immutable.List(),
  onChooseFilter: (e) => e,
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  hideTags: false,
  createNewMenuItem: false
};

const RetentionTermFormWrapper = reduxForm({
  form: 'RetentionTermForm', // a unique identifier for this form
  validate
})(RetentionTermForm);

const selector = formValueSelector('RetentionTermForm');
export default connect((state) => selector(state, 'retentionTerms'))(
  injectIntl(RetentionTermFormWrapper)
);
