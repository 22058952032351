import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';


const defaultState = Immutable.fromJS({
  executingEntities: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  searchText: Immutable.Map(),
  items: Immutable.List()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'EXECUTING_ENTITES:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('position', defaultState.get('position'))
        .set('executingEntities', defaultState.get('executingEntities'));
    case 'EXECUTING_ENTITES:LIST:FETCH:SUCCESS':
      return state.set('executingEntities', state.get('executingEntities').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    case 'EXECUTING_ENTITES:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'EXECUTING_ENTITIES_SELECTOR:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'EXECUTING_ENTITIES_SELECTOR:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items));
    case 'EXECUTING_ENTITIES_SELECTOR:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
