import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import ItemList from '@packages/components/form-components/itemList';
import ItemSelector from '@packages/components/item-selector';
import MultipleSelectorDialog from '@packages/components/multiple-selector/multipleSelectorDialog';
import CustomMasterDataDialog from '@packages/features/environment/components/custom-master-data/components/customMasterDataDialog';
import { customMasterDataTranslations } from '@packages/features/environment/masterDataTranslations';

const CustomSimpleMasterDataSelector = (props) => {
  const {
    customSimpleMasterData,
    fields,
    position,
    onChangeData,
    onSearch,
    handleUsageClick,
    onInit,
    field,
    getNextData,
    isGlobal,
    multiValue,
    createNewMenuItem,
    selectFromListMenuItem,
    tags,
    tagSearch,
    tagSearchResult,
    getNextTags,
    tagsPosition,
    onChooseFilter,
    selectedItems,
    hintTextLabel,
    userPermissions,
    disabled
  } = props;

  const { createEditCustomFieldData } = userPermissions.toJS();

  const [multiple, setMultiple] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState('');

  const modifiedData = (selectedItems) => {
    const filteredData =
      selectedItems && selectedItems.length > 0 && customSimpleMasterData
        ? customSimpleMasterData.filter((item) => {
          const index = selectedItems.findIndex((selectedItem) => {
            const selectedItemValue = selectedItem.value || selectedItem;
            return item.name === selectedItemValue.name;
          });
          return index === -1;
        })
        : customSimpleMasterData;
    return filteredData;
  };

  const handleRemoveItem = (selectedIndexValue) => {
    const selectedItems = fields.length ? [...fields.getAll()] : [];
    if (selectedIndexValue !== -1) {
      selectedItems.splice(selectedIndexValue, 1);
    }
    onChangeData(selectedItems);
  };

  const entityType =
    {
      ppProcessingCategories: 'processingCategories',
      ppPurpose: 'purposes',
      ppReferences: 'references',
      ppDataSubjectCategories: 'dataSubjectCategories',
      ppPersonalDataCategories: 'personalDataCategories',
      ppDataSourceCategories: 'dataSourceCategories',
      ppTechnicalSecurityMeasures: 'securityMeasures',
      ppOrganisationalSecurityMeasures: 'securityMeasures',
      ppContractualSecurityMeasures: 'securityMeasures'
    }[field.fieldId] || 'customSimpleMasterData';

  const handleUsage = (index) => {
    const { id } = fields.get(index).value;
    handleUsageClick(id, entityType);
  };

  const handleSelectedItem = (selectedItem) => {
    const selectedRecords = fields.length ? [...fields.getAll()] : [];
    selectedRecords.push(selectedItem);
    onChangeData(selectedRecords);
  };

  const handleOnFocus = () => {
    onInit('simplecustom', field.fieldId);
  };

  const handleCreateItem = (selectedItem, closeDialog) => {
    const modifiedItem = { ...selectedItem };
    if (selectedIndex !== -1) {
      modifiedItem.note = fields.get(selectedIndex).note;
    }
    handleEditItem(modifiedItem, selectedIndex, closeDialog);
  };

  const handleEditClick = (index) => {
    setOpen(true);
    setIsEdit(true);
    setSelectedIndex(index);
    setInputValue(fields.get(index).value || fields.get(index));
  };

  const handleEditItem = (selectedItem, selectedIndex, closeDialog) => {
    const selectedSimpleMasterData = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedSimpleMasterData.push(selectedItem);
    } else {
      selectedSimpleMasterData.splice(selectedIndex, 1);
      selectedSimpleMasterData.splice(selectedIndex, 0, selectedItem);
    }
    onChangeData(selectedSimpleMasterData);

    if (closeDialog) {
      handleRequestClose();
      handleClose();
    }
  };

  const handleScrollEnd = () => {
    getNextData(position, field.fieldId);
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setSelectedIndex(-1);
  };

  const handleAddClick = () => {
    setOpen(true);
  };

  const handleMultipleSelect = () => {
    onInit('simplecustom', field.fieldId);
    setMultiple(true);
  };

  const handleMultipleItems = (selectedItems) => {
    const items = fields.getAll() || [];
    const selectedProcessingCategories = [...items, ...selectedItems];
    onChangeData(selectedProcessingCategories);
  };

  const handleFilter = (selectedItem) => {
    const value = selectedItem ? [selectedItem.id] : [];
    onChooseFilter(
      Object.assign(
        Object.assign({}, { filterKey: 'tags', filteredOn: value })
      ),
      field.fieldId
    );
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(-1);
    setInputValue('');
    setIsEdit(false);
  };

  const handleSearch = (searchText) => {
    const searchParams = { ...searchText, searchKey: 'name' };
    onSearch(searchParams, field.fieldId);
  };

  const selectedEntities = fields.getAll();

  return (
    <div>
      {selectedEntities && selectedEntities.length > 0 && (
        <ItemList
          id="item_list"
          isEditable={createEditCustomFieldData}
          type={field.fieldLabel}
          {...props}
          handleRemoveItem={handleRemoveItem}
          handleEditClick={handleEditClick}
          handleEditItem={handleEditItem}
          selectedItems={selectedEntities}
          handleUsageClick={handleUsage}
        />
      )}
      <ItemSelector
        id="item_selector"
        multiValue={multiValue}
        createNewMenuItem={createEditCustomFieldData && createNewMenuItem}
        selectFromListMenuItem={selectFromListMenuItem}
        selectedItem={selectedItems}
        dataSource={modifiedData(selectedEntities)}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        hintTextLabel={hintTextLabel}
        onFocus={handleOnFocus}
        handleSelectedItem={handleSelectedItem}
        handleAddClick={handleAddClick}
        handleMultipleSelect={handleMultipleSelect}
        onSearch={handleSearch}
        disabled={disabled}
      />
      {multiple && (
        <MultipleSelectorDialog
          id="multiple_selector"
          title={`Add ${field.fieldLabel}`}
          onScrollStop={handleScrollEnd}
          open={multiple}
          selectedEntitiesSize={selectedEntities && selectedEntities.length}
          filteredData={modifiedData(selectedEntities)}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
          filterTagValue={tags}
          onChooseFilter={handleFilter}
          showFilterByTag={!isGlobal}
          tagSearch={tagSearch}
          tagSearchResult={tagSearchResult}
          getNextTags={getNextTags}
          tagsPosition={tagsPosition}
        />
      )}
      {open && (
        <CustomMasterDataDialog
          id="customMasterdata-dialog"
          open={open}
          dataItemId={inputValue && inputValue.id}
          title={
            isEdit
              ? customMasterDataTranslations('editTitle', field.fieldLabel)
              : customMasterDataTranslations('createTitle', field.fieldLabel)
          }
          entityItem={{
            entityType: field.fieldId,
            label: field.fieldLabel
          }}
          isEdit={isEdit}
          onRequestClose={handleClose}
          isUsed={false}
          handleSelectedItem={handleCreateItem}
          source="records"
          isTagEdit={false}
          hideTags={isGlobal}
        />
      )}
    </div>
  );
};

CustomSimpleMasterDataSelector.propTypes = {
  customSimpleMasterData: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  field: PropTypes.shape({
    layoutFieldUniqueId: PropTypes.string,
    fieldLabel: PropTypes.string,
    fieldId: PropTypes.string
  }).isRequired,
  position: PropTypes.number,
  onSearch: PropTypes.func,
  onChangeData: PropTypes.func,
  handleUsageClick: PropTypes.func,
  onInit: PropTypes.func,
  getNextData: PropTypes.func,
  multiValue: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  onChooseFilter: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  hintTextLabel: PropTypes.node,
  tags: PropTypes.instanceOf(Immutable.List),
  tagSearchResult: PropTypes.instanceOf(Immutable.List),
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  tagSearch: PropTypes.func,
  isGlobal: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  disabled: PropTypes.bool
};

CustomSimpleMasterDataSelector.defaultProps = {
  customSimpleMasterData: undefined,
  position: 0,
  onChangeData: (e) => e,
  onSearch: (e) => e,
  handleUsageClick: (e) => e,
  onInit: (e) => e,
  getNextData: (e) => e,
  multiValue: true,
  createNewMenuItem: true,
  selectFromListMenuItem: false,
  selectedItems: null,
  onChooseFilter: (e) => e,
  hintTextLabel: null,
  tags: Immutable.List(),
  tagSearchResult: Immutable.List(),
  getNextTags: (e) => e,
  tagsPosition: 0,
  tagSearch: (e) => e,
  isGlobal: false,
  userPermissions: Immutable.Map(),
  disabled: false
};

export default CustomSimpleMasterDataSelector;
