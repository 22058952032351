import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown, linkGroupItems } from '@packages/utils/common-utils';
import { countryValueMapper, personalDataCategoryTypeMapper } from '@packages/utils/commontranslations';

import { transformUserName } from '../../../environment/components/saga-utils';

export function* resetAndFetchJobs(action) {
  yield put({ type: 'JOBS:LIST:INIT' });
  yield fetchJobs(action);
}

export function* fetchJobs(action) {
  yield put({ type: 'JOBS:LIST:FETCH' });
  const { position = 0, rowCount = 15 } = action;

  try {
    const jobState = yield select(state => state.dashboard.jobs);
    let filterParams = {};
    if (jobState) {
      filterParams = jobState.get('filterParams').toJS();
    }
    const { sortOn, sortOrder } = filterParams;
    const filteredOn = jobState ? jobState.get('filteredOn') : Immutable.Map();
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        position || 0, null, rowCount), null, {});
    const modifiedResponse = transformResponseData(response.body);
    switch (response.status) {
      case 200:
        yield put({ type: 'JOBS:LIST:FETCH:SUCCESS', items: modifiedResponse });
        break;
      default:
        yield put({ type: 'JOBS:LIST:FETCH:FAIL', error: 'Jobs fetch failed' });
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: 'JOBS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* filterJobs(action) {
  const filterParams = action.filterParams ? action.filterParams : {};

  yield put({ type: 'JOBS:LIST:FILTER', filterParams });
  yield fetchJobs(action);
}

export function* fetchJobDetails(action) {
  const { source = '', entityType, formatMessage } = action;
  yield put({ type: 'JOBS:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/jobs/${action.id}`, null);
      const data = JSON.parse(response.body.entity);
      const newEntityData = renderJobDetails(entityType || response.body.entityType, data, formatMessage);
      /* eslint-disable no-underscore-dangle */
      delete newEntityData._rev;
      delete newEntityData.id;
      const modifiedData = {
        ...response.body,
        entity: newEntityData,
        noOfRecords: response.body.entityCount || 0
      };
      switch (response.status) {
        case 200: {
          if (source === 'reports') {
            yield put({ type: 'REPORT:DETAIL:JOB:FETCH:SUCCESS', jobItem: modifiedData });
          } 
          else if(source === 'layout-sync') {
            const modifiedResponse = response.body.subTenantDetails.map(
              ({ subTenant, status, failureReason, tenantId }) => ({
                status,
                subTenant,
                tenantId,
                failureReason
              })
            );
            yield put({
              type: 'SUB_TENANTS:JOBS:LIST:FETCH:SUCCESS',
              items: modifiedResponse
            });
          }
          else {
            yield put({ type: 'JOBS:ITEM:FETCH:SUCCESS', jobItem: modifiedData });
          }
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'jobs');
      yield put({ type: 'JOBS:ITEM:FETCH:FAIL', error: err.message });
    }
  }
}

export function* fetchEntityDetails(action) {
  const { entityType, id, formatMessage } = action;
  yield put({ type: 'JOBS:ITEM:FETCH:INIT' });
  if (id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/jobs/${id}/oldentitydata`, null);

      const data = response.body;
      const oldEntityData = data.map(item => renderJobDetails(entityType, item, formatMessage));
      switch (response.status) {
        case 200: {
          yield put({ type: 'JOBS:OLD_ITEM:FETCH:SUCCESS', oldEntityData });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'jobs');
      yield put({ type: 'JOBS:ITEM:FETCH:FAIL', error: err.message });
    }
  }
}
export function* retryOrTerminateJob(action) {
  const { id, actionType } = action;
  try {
    const url = `/v1/jobs/${id}/${actionType}`;
    const response = yield registry.get('request').post(url, null);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: actionType === 'restart' ?
              notificationtranslations.jobRestartedSuccess : notificationtranslations.jobTerminatedSuccess,
            type: 'success'
          }
        });
        const jobsState = yield select(state => state.dashboard.jobs);
        const jobItems = jobsState.get('items');
        const items = updateJobItems(jobItems, id, response.body, actionType);
        const modifiedItems = transformResponseData(items);
        yield put({ type: 'JOBS:LIST:UPDATE', items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: 'JOB:UPDATE:FAIL', error: err.message });
  }
}

export function* getBulkUpdateProgress(action) {
  const { jobID } = action;
  const request = registry.get('request');
  try {
    const response = yield request.get(`/v1/jobs/${jobID}/progress`);

    switch (response.status) {
      case 200: {
        yield put({ type: `JOBS:USAGE_STATUS:FETCH:SUCCESS`, usage: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `JOBS:USAGE_STATUS:FETCH:FAIL`, error: response.body.msg });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({ type: `JOBS:USAGE_STATUS:FETCH:FAIL`, error: err.message });
  }
}

export function* fetchSubTenantsJob(action) {
  const { jobId } = action;
  try {
    const response = yield registry.get('request')
      .get(`/v1/jobs/${jobId}/sub-tenants`, null);

    switch (response.status) {
      case 200: {
        const modifiedResponse = response.body.map((item) => ({
          ...item.job,
          subTenant: item.tenant.name
        }));
        yield put({
          type: 'SUB_TENANTS:JOBS:LIST:FETCH:SUCCESS',
          items: modifiedResponse,
          showNotification: response.body.length === 0
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}

export const getURLWithParams = (filterParams, position, searchParams, rowCount = 15) => {
  let url = `/v1/jobs?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText = (typeof (x) === 'object') ? x.props.defaultMessage : x;
          (url = `${url}&filter=${filterKey}%3D${encodeURIComponent(filterText)}`);
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

const transformResponseData = (data) => {
  const modifiedData = data.map(item => ({
    ...item,
    createdBy: item.createdBy ? transformUserName(item.createdBy) : ''
  }));
  return modifiedData;
};

const updateJobItems = (jobItems, jobId, modifiedItem, actionType) => {
  const items = jobItems.map((item) => {
    if (item.id === jobId) {
      return { ...modifiedItem, status: actionType === 'restart' ? 'In Progress' : modifiedItem.status };
    }
    return item;
  });
  return items;
};

const renderJobDetails = (entityType, entityData, formatMessage) => {
  let modifiedEntityData = {};
  let categories = [];
  switch (entityType) {
    case 'PersonalDataItem':
      if (entityData.categories) entityData.categories.map(item => categories.push(item.name));
      categories = categories.join(', ');
      modifiedEntityData = getJobDetails(entityData, entityType, categories, formatMessage);
      break;
    case 'DataSource':
    case 'DataRecipientCategory':
    case 'ProcessingUpdate':
    case 'DataItemGroup':
      modifiedEntityData = getJobDetails(entityData, entityType);
      break;
    default:
      modifiedEntityData = {
        name: entityData.name || ''
      };
  }
  return modifiedEntityData;
};

const getJobDetails = (jobDetail, entityType, categories, formatMessage) => {
  if (entityType === 'DataSource') {
    return (
      {
        name: jobDetail.name || '',
        administrator: jobDetail.administrator || '',
        dataStorageCountry: jobDetail.dataStorageCountry ?
          countryValueMapper(jobDetail.dataStorageCountry.id) : '',
        organisation: jobDetail.organisation ?
          `${jobDetail.organisation.name}(${jobDetail.organisation.country.id})` : ''
      });
  }
  if (entityType === 'PersonalDataItem') {
    return (
      {
        name: jobDetail.name || '',
        categoryType: jobDetail.categoryType ?
          formatMessage(personalDataCategoryTypeMapper(jobDetail.categoryType)) : '',
        categories: jobDetail.categories ? `${categories}` : ''
      });
  }
  if (entityType === 'DataRecipientCategory') {
    let countries = [];
    if (jobDetail.countries) {
      countries = jobDetail.countries.map(item => (countryValueMapper(item.id)));
    }
    return (
      {
        name: jobDetail.name || '',
        countries
      });
  }
  if (entityType === 'DataItemGroup') {
    const data = {};
    linkGroupItems.forEach((type) => {
      const items = jobDetail[type] ? jobDetail[type].map(item => (item.value ? item.value.name : item)) : [];
      data[type] = items;
    });
    return data;
  }
  if (entityType === 'ProcessingUpdate') {
    const data = {};
    Object.keys(jobDetail).forEach((type) => {
      const isString = typeof (jobDetail[type]) === 'string';
      const isBoolean = typeof (jobDetail[type]) === 'boolean';
      if (isString || isBoolean) data[type] = jobDetail[type];
      else if (type === 'links') {
        let items = jobDetail[type].map(item => item.value.linkNumber);
        items = items.join(', ');
        data[type] = items;
      } else if (type === 'organisationRights' || type === 'userRights') {
        let items = jobDetail[type].map(item => item.value.subject.key);
        items = items.join(', ');
        data[type] = items;
      } else {
        let items = jobDetail[type] ? jobDetail[type].map(item => (item.value ?
          item.value.key || item.value.name : item.key || item.name || item)) : [];
        items = items.join(', ');
        data[type] = items;
      }
    });
    return data;
  }
  return null;
};
