import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import debounce from 'lodash.debounce';
import { Table, Column } from 'fixed-data-table';
import Dimensions from 'react-dimensions';
import { getContainerHeight } from '@packages/utils/common-utils';
import { ActionChooserCell, StatusCell } from './cells';
import Pagination from './pagination';

// Handles all <Table> to make it responsive
export class ResponsiveTableWrapperDefault extends React.Component {
  static attachResizeEvent(func) {
    const win = window;

    if (win.addEventListener) {
      win.addEventListener('resize', func, false);
    } else if (win.attachEvent) {
      win.attachEvent('resize', func);
    } else {
      win.onresize = func;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      tableWidth: this.props.containerWidth,
      tableHeight: this.props.containerHeight || getContainerHeight(),
      tableMaxHeight: this.props.containerMaxHeight,
      showStatusIcon: this.props.showStatusIcon
    };
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    this.handleResize = debounce(
      this.handleResize,
      this.props.refreshRate
    ).bind(this);
    ResponsiveTableWrapperDefault.attachResizeEvent(this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.containerWidth !== nextProps.containerWidth ||
      this.props.data !== nextProps.data
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    const win = window;

    if (win.removeEventListener) {
      win.removeEventListener('resize', this.handleResize, false);
    } else if (win.detachEvent) {
      win.detachEvent('resize', this.handleResize);
    } else {
      win.onresize = null;
    }
  }

  handleFetchLimit = (value) => {
    this.props.setFetchLimit(value);
  };

  handleResize() {
    this.setState({
      tableWidth: this.props.containerWidth,
      tableHeight: this.props.componentHeight || getContainerHeight()
    });
  }

  handleScrollEnd(scrollX, scrollY) {
    if (this.props.onScrollEnd) {
      this.props.onScrollEnd(scrollX, scrollY);
    }
  }

  render() {
    const columns = this.props.columns || Immutable.List();
    const columnDefinitions = [].concat(this.props.children);
    const modifiedProps = { ...this.props, onScrollEnd: this.handleScrollEnd };
    const {
      actionChooserClass,
      actionChooserIcon,
      actionChooserStyle,
      actionItems,
      handleActionChooserVisibility,
      isHidden,
      isDisabled,
      currentActiveRow,
      onActionHandler
    } = this.props;
    return (
      <div>
        {this.props.showPagination && (
          <Pagination
            onPageClick={this.props.onPageClick}
            numberOfVisiblePages={3}
            totalRows={this.props.totalRows}
            setRowsCount={this.handleFetchLimit}
          />
        )}
        <Table
          id="table"
          width={this.state.tableWidth}
          maxHeight={
            this.state.tableMaxHeight
              ? this.state.tableMaxHeight
              : this.state.tableHeight
          }
          rowClassNameGetter={
            this.props.rowClassNameGetter || (() => 'rowWithCursorPointer')
          }
          {...modifiedProps}
        >
          {this.state.showStatusIcon && (
            <Column cell={<StatusCell {...this.props} />} width={30} />
          )}

          {columnDefinitions.map((columnDefinition) => {
            let isColumnHidden = false;

            // Check if Column chooser exist or column is in visible state
            if (columns.size > 0) {
              isColumnHidden = columns.find(
                (elmt) =>
                  elmt.get('key') === columnDefinition.props.columnKey &&
                  elmt.get('visible') === false
              );
            } else {
              isColumnHidden = columnDefinition.props.visible === false;
            }

            // Do not render the column if hidden via column picker.
            if (!isColumnHidden) {
              return (
                <Column
                  key={columnDefinition.props.columnKey}
                  columnKey={columnDefinition.props.columnKey}
                  header={columnDefinition.props.header}
                  cell={columnDefinition.props.cell}
                  flexGrow={columnDefinition.props.flexGrow}
                  width={columnDefinition.props.width}
                />
              );
            }
            return '';
          })}
          {this.props.showActionSelector && (
            <Column
              cell={
                <ActionChooserCell
                  actionChooserClass={actionChooserClass}
                  actionChooserIcon={actionChooserIcon}
                  actionChooserStyle={actionChooserStyle}
                  actionItems={actionItems}
                  isHidden={isHidden}
                  isDisabled={isDisabled}
                  currentActiveRow={currentActiveRow}
                  onActionHandler={onActionHandler}
                  handleActionChooserVisibility={
                    handleActionChooserVisibility || false
                  }
                />
              }
              width={38}
            />
          )}
        </Table>
      </div>
    );
  }
}

ResponsiveTableWrapperDefault.propTypes = {
  totalRows: PropTypes.number,
  rowsInAPage: PropTypes.number,
  showPagination: PropTypes.bool,
  numberOfVisiblePages: PropTypes.number,
  onPageClick: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List),
  data: PropTypes.instanceOf(Immutable.List),
  refreshRate: PropTypes.number,
  containerWidth: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  componentHeight: PropTypes.number,
  containerMaxHeight: PropTypes.number,
  columns: PropTypes.instanceOf(Immutable.List),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  showActionSelector: PropTypes.bool,
  showStatusIcon: PropTypes.bool,
  onColumnVisibilityToggle: PropTypes.func,
  onScrollEnd: PropTypes.func,
  countrySearchText: PropTypes.shape({}),
  showFilterIcon: PropTypes.bool,
  actionChooserIcon: PropTypes.node,
  actionChooserClass: PropTypes.string.isRequired,
  actionChooserStyle: PropTypes.shape({}),
  actionItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isHidden: PropTypes.func,
  isDisabled: PropTypes.func,
  currentActiveRow: PropTypes.number,
  onActionHandler: PropTypes.func,
  rowClassNameGetter: PropTypes.func,
  handleActionChooserVisibility: PropTypes.func,
  setFetchLimit: PropTypes.func
};

ResponsiveTableWrapperDefault.defaultProps = {
  totalRows: 0,
  rowsInAPage: 0,
  showPagination: false,
  numberOfVisiblePages: 0,
  onPageClick: (e) => e,
  items: Immutable.List(),
  data: Immutable.List(),
  componentHeight: 0,
  containerMaxHeight: 1000,
  showActionSelector: false,
  showStatusIcon: false,
  columns: Immutable.List(),
  onColumnVisibilityToggle: (e) => e,
  onScrollEnd: null,
  countrySearchText: {},
  showFilterIcon: false,
  actionChooserIcon: null,
  isHidden: (e) => e,
  isDisabled: null,
  currentActiveRow: 0,
  onActionHandler: (e) => e,
  actionChooserStyle: null,
  rowClassNameGetter: (e) => e,
  handleActionChooserVisibility: (e) => e,
  setFetchLimit: (e) => e
};

ResponsiveTableWrapperDefault.defaultProps = {
  refreshRate: 200 // ms
};

export default Dimensions()(ResponsiveTableWrapperDefault);
