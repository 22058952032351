import Immutable from 'immutable';
import { getSearchText, removeDuplicates, getFilteredOn } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: Immutable.List(),
  newItems: Immutable.List(),
  processingCategorydata: {},
  position: 0,
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  searchText: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  usage: undefined,
  globalUsage: undefined,
  bulkItems: Immutable.List(),
  unused: false,
  filteredOn: Immutable.Map(),
  filterColumn: {}
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('processingCategorydata', defaultState.get('processingCategorydata'));
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('items', defaultState.get('items'))
        .set('bulkItems', state.get('bulkItems'))
        .set('newItems', defaultState.get('newItems'))
        .set('position', 0);
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.processingCategory) {
        newItems = newItems.unshift(action.processingCategory);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', getSearchText(action));
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PROCESSING_CATEGORIES:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('processingCategorydata', action.processingCategory)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:PROCESSING_CATEGORIES:UPSERT:FAIL':
    case 'MASTERDATA:PROCESSINGCATEGORY:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:PROCESSING_CATEGORIES:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('newItems', action.newItems)
        .set('position', action.position ? action.position : state.get('position'));
    case 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:FETCH:SUCCESS':
      return state.set('processingCategorydata', action.processingCategoryItem)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PROCESSING_CATEGORIES:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:PROCESSINGCATEGORIES:USAGE:RESET':
      return state
        .set('usage', defaultState.get('usage'));
    case 'MASTERDATA:PROCESSINGCATEGORIES:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:PROCESSINGCATEGORIES:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:PROCESSING_CATEGORIES:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:MERGE:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId, action.unused));
    }
    case 'MASTERDATA:PROCESSING_CATEGORIES:MERGE:ITEM:RESET': {
      return state.set('bulkItems', defaultState.get('bulkItems'));
    }
    case 'MASTERDATA:PROCESSING_CATEGORIES:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);
    case 'MASTERDATA:PROCESSING_CATEGORIES:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    case 'MASTERDATA:PROCESSINGCATEGORIES:VIEW:SIMILAR:SUCCESS':
      return state
        .set('items', Immutable.List(action.items));
    default:
      return state;
  }
};
