import { combineReducers } from 'redux';

import detail from './detail';
import list from './list';
import tenants from './tenants';

export default combineReducers({
  detail,
  list,
  tenants
});
