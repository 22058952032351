import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';

import { handleServiceDown } from '@packages/utils/common-utils';

import { getBaseUrl, getKeyLabeledResponse, getURLWithParams } from './utils';

export function* getAllLibraryTenants() {
  try {
    const response = yield registry
      .get('request')
      .get('/v1/tenants/data-library');

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATA_LIBRARY:LIBRARY_TENANTS:FETCH:SUCCESS',
          data: getKeyLabeledResponse(response.body)
        });
        break;
      }
      default: {
        const store = registry.get('store');
        store.dispatch(replaceRouter(`/`));
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* getDataLibraryFeatures(action) {
  const { librarySourceId, currentFilter } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/custom-record-type/data-library/${librarySourceId}`);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATA_LIBRARY:ACCESSIBLE_LIBRARY:FETCH:SUCCESS',
          data: response.body,
          currentFilter
        });
        break;
      }
      default: {
        const store = registry.get('store');
        store.dispatch(replaceRouter(`/`));
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

export function* resetAndFetchDataLibraryListItems(action) {
  const { libraryType } = action;
  yield put({ type: `DATA_LIBRARY:${libraryType}:INIT` });
  yield fetchDataLibraryListItems(action);
}

export function* filterDataLibraryListItems(action) {
  const { filterParams, libraryType } = action;
  const { filteredOn, filterKey } = filterParams;

  const modifiedFilteredOn = { ...action.filteredOn, [filterKey]: filteredOn };
  yield put({
    type: `DATA_LIBRARY:${libraryType}:FILTER`,
    filterParams,
    filteredOn: modifiedFilteredOn
  });
  yield fetchDataLibraryListItems({
    ...action,
    filterParams: { ...filterParams, filteredOn: modifiedFilteredOn }
  });
}

export function* searchDataLibraryListItems(action) {
  const { searchParams, pageSearchText, ...restActionData } = action;
  const { searchKey, searchText } = searchParams;
  const modifiedPageSearchText = {
    ...pageSearchText.toJS(),
    [searchKey]: searchText
  };
  yield put({
    type: `DATA_LIBRARY:${action.libraryType}:PAGE_SEARCH`,
    searchParams: modifiedPageSearchText
  });
  yield fetchDataLibraryListItems({
    ...restActionData,
    searchParams: modifiedPageSearchText
  });
}

export function* fetchDataLibraryListItems(action) {
  const {
    libraryType,
    libraryTenantId,
    recordId,
    filterParams = {},
    searchParams = {},
    position = 0,
    rowCount = 15
  } = action;
  yield put({ type: `DATA_LIBRARY:${libraryType}:FETCH` });

  try {
    const { sortOn, sortOrder, filteredOn } = filterParams;
    const response = yield registry.get('request').get(
      getURLWithParams({
        baseUrl: `${getBaseUrl(libraryType)}/${libraryTenantId}`,
        filterParams: { sortOn, sortOrder, filteredOn },
        position,
        searchParams,
        recordId,
        rowCount
      }),
      null,
      {}
    );
    switch (response.status) {
      case 200:
        yield put({
          type: `DATA_LIBRARY:${libraryType}:FETCH:SUCCESS`,
          items: response.body
        });
        break;
      default:
        yield put({
          type: `DATA_LIBRARY:${libraryType}:FETCH:FAIL`,
          error: 'Layouts fetch failed'
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
    yield put({
      type: `DATA_LIBRARY:${libraryType}:FETCH:FAIL`,
      error: err.message
    });
  }
}

export function* createDataLibraryJob(action) {
  const {
    libraryTenantId,
    dataLibraryType,
    operation,
    entityId,
    entityName,
    layoutData,
    recordData
  } = action;
  try {
    const response = yield registry
      .get('request')
      .post('/v1/jobs/data-library', {
        libraryTenantId,
        dataLibraryType,
        operation,
        entityId,
        entityName,
        ...(layoutData && { layoutData }),
        ...(recordData && { recordData })
      });

    switch (response.status) {
      case 200: {
        if (operation === 'import' || operation === 'copy')
          yield put({
            type: 'DATA_LIBRARY:CREATE_ENTITY_DIALOG:TOGGLE',
            value: false
          });
        yield put({
          type: 'DATA_LIBRARY:JOB:CREATE:SUCCESS',
          openStayOrNavigate: true
        });
        break;
      }
      default: {
        if (operation === 'import' || operation === 'copy')
          yield put({
            type: 'DATA_LIBRARY:JOB:CREATE:FAIL',
            error: { isError: true, message: response.body.msg }
          });
        else
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}

export function* disconnectRecordSync(action) {
  const { recordId } = action;
  try {
    const response = yield registry
      .get('request')
      .put(`/v1/records/${recordId}/disconnect-sync`);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATA_LIBRARY:DISCONNECT_SYNC:RECORD:SUCCESS',
          updatedRecordData: response.body,
          recordId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* disconnectTemplateSync(action) {
  const { templateId } = action;
  try {
    const response = yield registry
      .get('request')
      .put(`/v1/records/templates/${templateId}/disconnect-sync`);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'DATA_LIBRARY:DISCONNECT_SYNC:TEMPLATE:SUCCESS',
          updatedTemplateData: response.body,
          templateId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
