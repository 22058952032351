import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LINKED_RECORD:LIST:REQUEST:FETCH':
      return defaultState;
    case 'LINKED_RECORD:LIST:SEARCH:SUCCESS':
      return state
        .set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    default:
      return state;
  }
};
