import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  countrySearchText: Immutable.Map(),
  usage: undefined,
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LEGAL_ENTITIES:COUNTRY_SEARCHTEXT:SET': {
      let { searchParams } = action;
      const { searchKey } = searchParams;
      let { searchText } = searchParams;

      if (searchText === undefined) {
        const searchTextObj = state.get('countrySearchText') || Immutable.Map();
        searchText = searchTextObj.get(searchKey) || '';
        searchParams = Object.assign({}, searchParams, { searchText });
      }
      return state
        .set('countrySearchText', getSearchText(Object.assign({}, { searchKey, searchText })));
    }
    case 'LEGAL_ENTITIES:ITEM:USAGE:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'));
    case 'LEGAL_ENTITIES:USAGE:SUCCESS': {
      return state.set('usage', action.usage);
    }
    case 'LEGAL_ENTITIES:USAGE:RESET':
      return state.set('usage', defaultState.get('usage'));
    case 'LEGAL_ENTITIES:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};

