/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import { singularTerms as uppercaseSingular } from '@packages/utils/uppercaseTranslations';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import { commonTranslations } from '../../recordTranslations';
import DocumentMultiSelector from './components/multiple-selector';
import DocumentsDialog from '../../../environment/components/documents/components/documentDialog';

const filterDocuments = (selectedAttachments, searchedItems) => {
  const filteredData =
    searchedItems &&
    searchedItems.filter((attachment) => {
      const index = selectedAttachments.findIndex(
        (selectedAttachment) =>
          attachment.id ===
          (selectedAttachment.value
            ? selectedAttachment.value.id
            : selectedAttachment.id)
      );
      return index === -1;
    });
  return filteredData;
};

const AttachmentSelector = (props) => {
  const {
    searchResults,
    toggleLoader,
    isFileUploading,
    resetFilterSearch,
    fields,
    pricingPlan,
    isVendor,
    addIcon,
    itemList,
    label,
    dialogHeaderLabel,
    hintTextLabel,
    createNewMenuItem,
    selectFromListMenuItem,
    downloadDocuments,
    multiValue,
    initDocuments,
    onSearch,
    attachments,
    fieldType,
    updateAttachments,
    userPermissions
  } = props;

  const { createEditDocumentRecord } = userPermissions.toJS();

  const [multiple, setMultiple] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [currentSearchResults, setCurrentSearchResults] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [addMultipleItems, setAddMultipleItems] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    setCurrentSearchResults(searchResults);
    const content = (
      <FormattedMessage
        id="UploadFile.loader"
        description="Loader icon text"
        defaultMessage="Scanning for virus and uploading the file ...."
      />
    );
    toggleLoader(isFileUploading, content);
  }, [searchResults, isFileUploading]);

  const handleMultipleSelect = () => {
    setMultiple(true);
  };

  const handleRequestClose = () => {
    setMultiple(false);
    setOpen(false);
    setSelectedIndex(-1);
    setSearchText('');
    setInputValue({});
    setCurrentSearchResults([]);
    setIsDelete(false);
    resetFilterSearch();
  };

  const handleMultipleItems = (selectedItems) => {
    const items = fields.getAll() || [];
    const selectedAttachments = [...items, ...selectedItems];
    selectedItems.forEach((item) => {
      if (!item.value.public) {
        setOpenDialog(true);
        setAddMultipleItems(true);
      }
    });
    updateComponent(selectedAttachments);
  };

  const updateComponent = (selectedAttachments) => {
    updateAttachments(selectedAttachments);
    setSearchText('');
    setCurrentSearchResults([]);
  };

  const handleEditClick = (index) => {
    setOpen(true);
    setIsEdit(true);
    setMultiple(false);
    setSelectedIndex(index);
    setIsDelete(false);
    setInputValue(fields.get(index).value);
  };

  const handleEditItem = (selectedItem, selectedIndex) => {
    handleSelectedItem(selectedItem, false, selectedIndex);
  };

  const handleSelectedItem = (
    selectedItem,
    closeDialog,
    selectedIndexValue
  ) => {
    const selectedAttachments = fields.length ? [...fields.getAll()] : [];
    const indexValue =
      selectedIndex !== undefined ? selectedIndexValue : selectedIndex;
    if (indexValue === -1) {
      selectedAttachments.push(selectedItem);
    } else {
      selectedAttachments.splice(indexValue, 1);
      selectedAttachments.splice(indexValue, 0, selectedItem);
    }
    if (!selectedItem.value.public) {
      setOpenDialog(true);
    }
    updateComponent(selectedAttachments);
    if (closeDialog) {
      handleRequestClose();
    }
  };

  const handleRemoveItem = (selectedIndex) => {
    const selectedAttachments = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedAttachments.splice(selectedIndex, 1);
    }
    updateComponent(selectedAttachments);
  };

  const handleDeletedItem = (deletedItem, closeDialog) => {
    let attachments = fields.length ? [...fields.getAll()] : [];
    attachments = attachments.filter(
      ({ value }) => value.id !== deletedItem.value.id
    );
    updateComponent(attachments);
    if (closeDialog) {
      handleRequestClose();
    }
  };

  const handleRemoveVendorItem = (index) => {
    setOpen(true);
    setIsEdit(false);
    setMultiple(false);
    setSelectedIndex(index);
    setIsDelete(true);
    setInputValue(fields.get(index).value);
    setMultiple(false);
  };

  const handleUsageClick = (index) => {
    const { id } = fields.get(index).value;
    handleUsageClick(id, 'documents');
  };

  const handleItemClick = (selectedIndex) => {
    const selectedItem = fields.get(selectedIndex);
    if (selectedItem?.value.externalLink) {
      const { externalLink } = selectedItem.value;
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: /^((https?):\/\/)/.test(externalLink)
          ? externalLink
          : `//${externalLink}`
      }).click();
    } else {
      downloadDocuments(selectedItem, isVendor);
    }
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const searchParams = { ...searchText, searchKey: 'name' };
    onSearch(searchParams);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAddMultipleItems(false);
  };

  const selectedAttachments = fields.getAll();
  const searchedItems =
    searchText !== '' || currentSearchResults.size > 0
      ? currentSearchResults
      : attachments;
  const documentList =
    selectedAttachments && selectedAttachments.length > 0
      ? filterDocuments(selectedAttachments, searchedItems)
      : searchedItems;
  const multipleDocumentList =
    selectedAttachments && selectedAttachments.length > 0
      ? filterDocuments(selectedAttachments, attachments)
      : attachments;
  const showAddIcon =
    isVendor ||
    ((pricingPlan.get('plan') && pricingPlan.get('plan').maxDocuments) >
      (pricingPlan.get('usage') && pricingPlan.get('usage').documents) &&
      createEditDocumentRecord &&
      showAddIcon);

  const actions = [
    <Button
      id="delete-button"
      key="delete-report"
      classes={{
        root: 'button_confirm_dialog',
        label: 'buttonLabel_confirm_dialog'
      }}
      onClick={handleCloseDialog}
    >
      <div>{uppercaseSingular('Ok')}</div>
    </Button>
  ];

  return (
    <div>
      {itemList && (
        <div>
          {itemList({
            attachments: selectedAttachments,
            handleRemoveItem: handleRemoveVendorItem,
            handleEditClick,
            handleItemClick,
            handleEditItem
          })}
        </div>
      )}
      {selectedAttachments && selectedAttachments.length > 0 && !isVendor && (
        <ItemList
          isLink={true}
          {...props}
          isNoteEditable={true}
          selectedItems={selectedAttachments}
          handleRemoveItem={handleRemoveItem}
          handleEditClick={handleEditClick}
          handleItemClick={handleItemClick}
          handleEditItem={handleEditItem}
          handleUsageClick={handleUsageClick}
          dataItemType={fieldType || 'attachments'}
          type={label}
          isEditable={true}
        />
      )}
      {!isVendor && (
        <ItemSelector
          multiValue={multiValue}
          createNewMenuItem={createEditDocumentRecord && createNewMenuItem}
          selectFromListMenuItem={selectFromListMenuItem || false}
          dataSource={documentList}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={hintTextLabel}
          onFocus={() => initDocuments()}
          handleSelectedItem={handleSelectedItem}
          handleMultipleSelect={handleMultipleSelect}
          onSearch={handleSearch}
        />
      )}
      {addIcon ? (
        <div
          onClick={() => setOpen(true)}
          aria-hidden="true"
          style={{ display: 'inline-block' }}
        >
          {addIcon}
        </div>
      ) : (
        <div style={showAddIcon ? { marginTop: '15px' } : { display: 'none' }}>
          <Button
            classes={{
              root: 'button_black',
              label: 'buttonLabel_upload'
            }}
            onClick={() => setOpen(true)}
            component="label"
            htmlFor="file"
          >
            {commonTranslations.uploadFile}
          </Button>
        </div>
      )}
      {open && (
        <DocumentsDialog
          id="documents-dialog"
          open={open}
          isUsed={false}
          dataItemId={inputValue.id}
          isEdit={isEdit}
          isDelete={isDelete}
          onRequestClose={handleRequestClose}
          handleSelectedItem={handleSelectedItem}
          handleDeletedItem={handleDeletedItem}
          source="records"
          {...props}
        />
      )}
      {multiple && (
        <DocumentMultiSelector
          title={dialogHeaderLabel}
          open={multiple}
          selectedItems={selectedAttachments}
          searchTextValue={searchText !== '' ? searchText.searchText : ''}
          filteredData={multipleDocumentList}
          onRequestClose={handleRequestClose}
          handleMultipleItems={handleMultipleItems}
        />
      )}
      {openDialog && !isVendor && (
        <CommonDialog
          id="attachment-selector-notification-dialog"
          show={openDialog}
          onCancel={handleCloseDialog}
          fullWidth={true}
          maxWidth="sm"
          buttonActions={actions}
          buttonPosition="right"
          showCloseIcon={false}
        >
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {addMultipleItems ? (
              <FormattedMessage
                id="Record.multipleAttachmentNotification"
                description="Change private to public"
                defaultMessage="You are trying to add one or more restricted documents to this record.
                  Once added, it would be visible to all other users who have access to this privacy record."
              />
            ) : (
              <FormattedMessage
                id="Record.attachmentNotification"
                description="Change private to public"
                defaultMessage="You are trying to add a restricted document to this record. Once added, it would be
              visible to all other users who have access to this privacy record."
              />
            )}
          </div>
        </CommonDialog>
      )}
    </div>
  );
};

AttachmentSelector.propTypes = {
  isEditable: PropTypes.bool,
  isUploadSuccess: PropTypes.bool,
  isFileUploading: PropTypes.bool,
  attachments: PropTypes.instanceOf(Immutable.List),
  attachmentData: PropTypes.string,
  label: PropTypes.node,
  resetFilterSearch: PropTypes.func,
  dialogHeaderLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectFromListMenuItem: PropTypes.bool,
  handleUsageClick: PropTypes.func,
  createNewMenuItem: PropTypes.bool,
  multiValue: PropTypes.bool,
  downloadDocuments: PropTypes.func,
  updateAttachments: PropTypes.func,
  initDocuments: PropTypes.func,
  addIcon: PropTypes.func,
  itemList: PropTypes.func,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  isVendor: PropTypes.bool,
  onSearch: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List),
  toggleLoader: PropTypes.func,
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  showAddIcon: PropTypes.bool,
  fieldType: PropTypes.string,
  userPermissions: PropTypes.instanceOf(Immutable.Map)
};

AttachmentSelector.defaultProps = {
  isUploadSuccess: false,
  isFileUploading: undefined,
  handleUsageClick: (e) => e,
  resetFilterSearch: (e) => e,
  isEditable: false,
  isVendor: false,
  multiValue: true,
  attachments: undefined,
  attachmentData: undefined,
  label: null,
  addIcon: undefined,
  itemList: () => null,
  dialogHeaderLabel: null,
  hintTextLabel: null,
  createNewMenuItem: false,
  selectFromListMenuItem: false,
  downloadDocuments: (e) => e,
  updateAttachments: (e) => e,
  pricingPlan: Immutable.Map(),
  initDocuments: (e) => e,
  onSearch: (e) => e,
  searchResults: Immutable.List(),
  toggleLoader: (e) => e,
  showAddIcon: true,
  fieldType: '',
  userPermissions: Immutable.Map()
};

export default AttachmentSelector;
