import { connect } from 'react-redux';
import CreateDocumentComponent from './component';

const mapStateToProps = (state) => ({
  isUpdating: state.documents.get('isUpdating'),
  actionError: state.documents.get('actionError'),
  isTemplateMode: state.privacyRecords.list.get('isTemplateMode')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'DOCUMENT:DETAIL:RESET' });
  },
  saveDocument: (data, templateId, createFromEdit, isTemplateMode) => {
    dispatch({
      type: 'DOCUMENT:DETAIL:UPSERT',
      data,
      createFromEdit,
      isTemplateMode,
      templateId
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDocumentComponent);
