import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { handleServiceDown, GENERAL_FETCH_LIMIT } from '@packages/utils/common-utils';
import { getURLWithParams } from '../../../utils';

export function* fetchProcessors(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  try {
    const response = yield registry.get('request')
      .get(`/v1/records/processors?numberOfResults=${rowCount}&offset=${position}`, null);
    switch (response.status) {
      case 200: {
        const items = response.body;
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({ type: 'PROCESSORS:LIST:FETCH:SUCCESS', items: modifiedItems });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'PROCESSORS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* searchProcessorsSelector(action) {
  let { searchParams } = action;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  const processorSelectorState = yield select(state => state.processors.get('processors'));

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = processorSelectorState ? processorSelectorState.get('searchText') : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  yield put({ type: 'PROCESSORS_SELECTOR:LIST:SEARCH', searchKey, searchText });
  try {
    const url = getURLWithParams(action.position, searchParams, 'processors', action.rowCount || GENERAL_FETCH_LIMIT);
    const response = yield registry.get('request').get(url, null, {});
    switch (response.status) {
      case 200: {
        const items = response.body;
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'PROCESSORS_SELECTOR:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'PROCESSORS_SELECTOR:LIST:SEARCH:FAIL', error: err.message });
  }
}
