import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ItemSelector from '@packages/components/item-selector';
import ItemList from '@packages/components/form-components/itemList';
import MultipleSelectorDialog from './components/supervisory-multiselector';

class SupervisoryAuthoritySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false, // eslint-disable-line react/no-unused-state
      multiple: false,
      selectedIndex: -1
    };

    this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleMultipleItems = this.handleMultipleItems.bind(this);
  }

  componentWillMount() {
    if (this.props.initSupervisoryAuthorities) {
      this.props.initSupervisoryAuthorities();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
    !(this.state === nextState) ||
    !(this.props.supervisoryAuthorities === nextProps.supervisoryAuthorities);
  }

  handleMultipleSelect() {
    this.setState({
      multiple: true
    });
  }

  handleRequestClose() {
    this.setState({
      multiple: false,
      selectedIndex: -1
    });
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedAuthorities = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedAuthorities.splice(selectedIndex, 1);
    }

    this.updateAuthorities(selectedAuthorities);
  }

  // Handler to add/edit multiple items
  handleMultipleItems(selectedItems) {
    const items = this.props.fields.getAll() || [];
    const selectedAuthorities = [...items, ...selectedItems];
    this.updateAuthorities(selectedAuthorities);
  }

  // Handler to add/edit items
  handleSelectedItem(selectedItem) {
    const { selectedIndex } = this.state;
    this.handleEditItem(selectedItem, selectedIndex);
  }

  // Update the changed items.
  handleEditItem(selectedItem, selectedIndex) {
    const selectedAuthorities = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedAuthorities.push(selectedItem);
    } else {
      selectedAuthorities.splice(selectedIndex, 1, selectedItem);
    }

    this.updateAuthorities(selectedAuthorities);
  }

  filterSupervisoryAuthority(selectedAuthorities) {
    const filteredData = this.props.supervisoryAuthorities.filter((supervisoryAuthorities) => {
      const index = selectedAuthorities.findIndex(selectedItem => supervisoryAuthorities.id ===
        (selectedItem.value ? selectedItem.value.id : selectedItem.id));
      return (index === -1);
    });
    return filteredData;
  }

  updateAuthorities(selectedAuthorities) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true }); // eslint-disable-line react/no-unused-state
    this.props.updateData(selectedAuthorities);
  }

  render() {
    const selectedAuthorities = this.props.fields.getAll();
    const supervisoryAuthorityList = (selectedAuthorities && selectedAuthorities.length > 0) ?
      this.filterSupervisoryAuthority(selectedAuthorities) : this.props.supervisoryAuthorities;

    return (
      <div>
        {this.props.showItemList && <ItemList
          isEditable={false}
          isNoteEditable={true}
          selectedItems={selectedAuthorities}
          handleEditItem={this.handleEditItem}
          handleRemoveItem={this.handleRemoveItem}
          type={this.props.label}
          {...this.props}
        />}
        <ItemSelector
          multiValue={this.props.multiValue}
          createNewMenuItem={this.props.createNewMenuItem || false}
          selectFromListMenuItem={this.props.selectFromListMenuItem || false}
          dataSource={supervisoryAuthorityList}
          dataSourceConfig={{ text: 'key', value: 'key' }}
          hintTextLabel={this.props.hintTextLabel}
          handleSelectedItem={this.handleSelectedItem}
          handleMultipleSelect={this.handleMultipleSelect}
          disabled={this.props.disabled}
        />
        {this.state.multiple && <MultipleSelectorDialog
          title={this.props.dialogHeaderLabel}
          open={this.state.multiple}
          filteredData={supervisoryAuthorityList}
          onRequestClose={this.handleRequestClose}
          handleMultipleItems={this.handleMultipleItems}
        />}
      </div>
    );
  }
}

SupervisoryAuthoritySelector.propTypes = {
  supervisoryAuthorities: PropTypes.instanceOf(Immutable.List),
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    removeAll: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired
  }).isRequired,
  label: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectFromListMenuItem: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  multiValue: PropTypes.bool,
  initSupervisoryAuthorities: PropTypes.func,
  updateData: PropTypes.func,
  showItemList: PropTypes.bool,
  disabled: PropTypes.bool
};

SupervisoryAuthoritySelector.defaultProps = {
  multiValue: true,
  selectFromListMenuItem: false,
  supervisoryAuthorities: Immutable.List(),
  label: null,
  dialogHeaderLabel: null,
  hintTextLabel: null,
  createNewMenuItem: false,
  initSupervisoryAuthorities: (e) => e,
  updateData: (e) => e,
  showItemList: true,
  disabled: false
};

export default SupervisoryAuthoritySelector;
