import React from 'react';
import PropTypes from 'prop-types';
import ItemList from '@packages/components/form-components/itemList';
import BreachGroundListDialog from './components/breachGroundListDialog';

class BreachGroundSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false // eslint-disable-line react/no-unused-state
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSelectedItems = this.handleSelectedItems.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.fields.getAll() === nextProps.fields.getAll()) ||
    !(this.state === nextState);
  }

  // Handler to remove item
  handleRemoveItem(selectedIndex) {
    const selectedBreachGrounds = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedBreachGrounds.splice(selectedIndex, 1);
    }
    this.saveBreachGrounds(selectedBreachGrounds);
  }

  // Handler to add items
  handleSelectedItems(selectedItems) {
    const selectedBreachGrounds = selectedItems.map(item => ({
      ...item,
      value: {
        ...item.value,
        key: `${item.value.jurisdiction} ${item.value.lawAbbreviation} ${item.value.article} - ${item.value.summary}`,
        content: item.value.content
      }
    }));
    this.saveBreachGrounds(selectedBreachGrounds);
  }

  // Handler to edit item
  handleEditItem(selectedItem, selectedIndex) {
    const selectedBreachGrounds = this.props.fields.length ? [...this.props.fields.getAll()] : [];
    if (selectedIndex === -1) {
      selectedBreachGrounds.push(selectedItem);
    } else {
      selectedBreachGrounds.splice(selectedIndex, 1, selectedItem);
    }

    this.saveBreachGrounds(selectedBreachGrounds);
  }

  saveBreachGrounds(breachGrounds) {
    // There are cases where re-render is not invoked.
    this.setState({ updated: true }); // eslint-disable-line react/no-unused-state
    this.props.updateBreachGrounds(breachGrounds);
  }

  render () {
    const selectedBreachGrounds = this.props.fields.getAll();
    return (
      <div>
        <ItemList
          isEditable={false}
          isNoteEditable={true}
          {...this.props}
          type={this.props.itemLabel}
          handleRemoveItem={this.handleRemoveItem}
          handleEditItem={this.handleEditItem}
          selectedItems={selectedBreachGrounds}
        />
        <BreachGroundListDialog
          handleSelectedItems={this.handleSelectedItems}
          selectedItems={selectedBreachGrounds}
          {...this.props}
        />
      </div>
    );
  }
}
BreachGroundSelector.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  itemLabel: PropTypes.node,
  updateBreachGrounds: PropTypes.func
};

BreachGroundSelector.defaultProps = {
  itemLabel: null,
  updateBreachGrounds: e => e
};

export default BreachGroundSelector;
