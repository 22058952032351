import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  data: Immutable.Map({}),
  prevLocation: '/privacyrecords',
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PROCESSING:DIFF:FETCH': {
      return state
        .set('data', defaultState.get('data'))
        .set('prevLocation', `/processing/${action.processingId}/revisions`)
        .set('error', defaultState.get('error'));
    }
    case 'PROCESSING:DIFF:FETCH:SUCCESS':
      return state.set('data', Immutable.Map(action.data));
    case 'PROCESSING:DIFF:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    default:
      return state;
  }
};
