import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import { updateDataItems } from '../saga-utils';
import { customMasterDataTranslations } from '../../masterDataTranslations';

export const getURLWithParams = (
  filterParams,
  position = 0,
  searchParams,
  entityType,
  rowCount,
  isStakeHolderType,
  unused
) => {
  let url = `/v1/masterdata/simplecustom/${entityType}?offset=${position}`;
  if (rowCount) {
    url = `${url}&numberOfResults=${rowCount}`;
  }
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem && filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          // FIXME: Record types should be made lower case in BE
          const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchText, sortOn, sortOrder, searchKey } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (unused !== undefined) {
    url = unused === true ? `${url}&unused=true` : `${url}&unused=false`;
  }

  return url;
};

export function* resetAndfetchCustomMasterDataList(action) {
  const { entityType } = action;
  yield put({
    type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH:INIT',
    entityType
  });
  yield fetchCustomMasterDataList(action);
}

export function* fetchCustomMasterDataList(action) {
  const {
    entityType,
    position = 0,
    rowCount = MASTERDATA_FETCH_LIMIT
  } = action;
  try {
    let filterParams = {};
    let unused = false;
    let searchTextObj = {};
    let searchParameters = {};
    let filteredOn = Immutable.Map();
    if (action.isPersistedData) {
      const customSimpleDataState = yield select(
        (state) => state.customMasterDataList
      );
      if (customSimpleDataState) {
        filterParams = customSimpleDataState.get('filterParams').toJS();
        searchTextObj = customSimpleDataState.get('searchText').toJS();

        unused = customSimpleDataState.get('unused');
        searchParameters = searchTextObj
          ? { searchKey: 'name', searchText: searchTextObj.name }
          : {};
        filteredOn = customSimpleDataState.get('filteredOn');
      }
    }

    const { sortOn, sortOrder } = filterParams;

    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          position,
          searchParameters,
          entityType,
          rowCount,
          undefined,
          unused
        ),
        null,
        {}
      );

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FETCH:SUCCESS',
          items: modifiedItems,
          customDataValue: action.customDataValue
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* filterCustomMasterDataItems(action) {
  const { filterParams = {}, entityType } = action;
  yield put({
    type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:FILTER',
    filterParams,
    entityType
  });
  yield fetchCustomMasterDataList(action);
}

const notificationMessages = (isEdit, label) =>
  isEdit
    ? customMasterDataTranslations('editSuccess', label)
    : customMasterDataTranslations('createSuccess', label);

export function* upsertCustomMasterData(action) {
  const { isEdit, data, source, entityType, label } = action;
  const request = registry.get('request');
  const requestData = { ...data, entityType };
  let response;
  try {
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(`/v1/masterdata/simplecustom`, requestData);
    } else {
      response = yield request.put(
        `/v1/masterdata/simplecustom/${requestData.id}?withJob=false`,
        requestData
      );
    }
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        const modifiedItem = {
          ...response.body,
          key: `${response.body.name}`
        };
        // Handle consistency in the front end.
        if (isEdit) {
          yield put({
            type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:SUCCESS',
            customDataValue: modifiedItem
          });
          yield updateSimpleMasterDataItem({
            type: 'edit',
            data: Object.assign({}, data, modifiedItem),
            source
          });
        } else {
          yield put({
            type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:SUCCESS',
            customDataValue: modifiedItem,
            position: 0
          });
          yield fetchCustomMasterDataList({
            entityType,
            customDataValue: modifiedItem,
            isPersistedData: true,
            rowCount: MASTERDATA_FETCH_LIMIT
          });
        }
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationMessages(isEdit, label),
            type: 'success'
          }
        });

        break;
      }
      case 304: {
        yield put({
          type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:SUCCESS',
          customDataValue: requestData.value
        });
        break;
      }
      case 409:
      case 412: {
        yield put({
          type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:UPSERT:FAIL',
      error: err.message
    });
  }
}

export function* fetchCustomMasterDataValue(action) {
  yield put({ type: 'CUSTOM:MASTER_DATA_VALUE:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry
        .get('request')
        .get(`/v1/masterdata/simplecustom/${action.id}`, action.entityType);
      switch (response.status) {
        case 200: {
          yield put({
            type: 'CUSTOM:MASTER_DATA_VALUE:ITEM:FETCH:SUCCESS',
            item: response.body
          });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({
        type: 'CUSTOM:MASTER_DATA_VALUE:ITEM:FETCH:FAIL',
        error: err.message
      });
    }
  }
}

function* updateSimpleMasterDataItem(action) {
  const { type, data, dataItemId, source } = action;
  const customSimpleDataState = yield select(
    (state) => state.customMasterDataList
  );
  let position = null;
  let items = customSimpleDataState
    ? customSimpleDataState.get('items')
    : Immutable.List();

  let newItems = customSimpleDataState
    ? customSimpleDataState.get('newItems')
    : Immutable.List();
  if (type === 'delete') {
    position = customSimpleDataState.get('position') - 1;
    items = updateDataItems(items, type, dataItemId);
    newItems = updateDataItems(newItems, type, dataItemId);
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = data;

    items = updateDataItems(items, type, data.id, source, modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, source, modifiedItem);
  }
  yield put({
    type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:UPDATE:SUCCESS',
    items,
    newItems,
    position
  });
}

export function* deleteSimpleMasterData(action) {
  const { dataItemId } = action;
  const request = registry.get('request');

  try {
    const response = yield request.delete(
      `/v1/masterdata/simplecustom/${dataItemId}`,
      null
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('customSimpleMasterData'),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateSimpleMasterDataItem({
          type: 'delete',
          dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* searchCustomMasterData(action) {
  let { searchParams } = action;
  const rowCount = MASTERDATA_SEARCH_LIMIT;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  let filterParams = {};
  const filteredOn = Immutable.Map();
  let unused = false;
  const customMasterDataListState = yield select(
    (state) => state.environment.customMasterDataList
  );

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = customMasterDataListState
      ? customMasterDataListState.get('searchText')
      : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }
  if (customMasterDataListState) {
    filterParams = customMasterDataListState.get('filterParams').toJS();
    unused = customMasterDataListState.get('unused');
  }
  const { sortOn, sortOrder } = filterParams;

  yield put({
    type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH',
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position,
          searchParams,
          action.entityType,
          rowCount,
          undefined,
          unused
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map((item) => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:SEARCH:FAIL',
      error: err.message
    });
  }
}

export function* customMasterDataViewSimilarItems(action) {
  const { entityType = null, masterDataType, item } = action;
  try {
    const url = `v1/masterdata/${masterDataType.toLowerCase()}/${entityType}/similar/${
      item.id
    }?numberOfResults=${MASTERDATA_FETCH_LIMIT}&offset=0`;
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        const items = response.body;
        yield put({
          type: `MASTERDATA:${masterDataType.toUpperCase()}:VIEW:SIMILAR:SUCCESS`,
          items
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
    yield put({
      type: `MASTERDATA:${masterDataType.toUpperCase()}:VIEW:SIMILAR:FAIL`,
      error: err.message
    });
  }
}
