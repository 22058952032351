import Immutable from 'immutable';
import { sortItems } from '@packages/utils/common-utils';

export const EUCountryList = [
  'AL',
  'BE',
  'BU',
  'CA',
  'HR',
  'CZ',
  'DA',
  'EN',
  'FR',
  'GE',
  'GR',
  'HU',
  'IC',
  'IT',
  'LG',
  'LH',
  'LU',
  'NL',
  'NO',
  'PL',
  'PO',
  'RO',
  'LO',
  'SI',
  'ES',
  'TU',
  'UK',
  'US'
];

// Get filtered legal Grounds
const getFilteredLegalGrounds = (legalGrounds, filteredOn) => {
  let items = legalGrounds;
  filteredOn.keySeq().forEach((key) => {
    const filteredOnItem = filteredOn.get(key);
    if (filteredOnItem.length > 0) {
      items = items.filter((legalGround) => {
        const index = filteredOnItem.findIndex((filteredItem) => {
          const filterText =
            typeof filteredItem === 'object'
              ? filteredItem.props.defaultMessage
              : filteredItem;
          return filterText.toLowerCase() === legalGround[key].toLowerCase();
        });
        return index !== -1;
      });
    }
  });

  return items;
};

export const filterSelectedItems = (legalGrounds, state) => {
  let filterParams = {};
  let filteredOn = Immutable.Map();
  if (state) {
    filterParams = state.get('filterParams').toJS();
    filteredOn = state.get('filteredOn');
  }
  const { sortOn, sortOrder } = filterParams;
  let items = getFilteredLegalGrounds(legalGrounds, filteredOn);
  if (sortOn) {
    items = sortItems(items, sortOrder, sortOn);
  }

  return items;
};
