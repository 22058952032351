import React from 'react';

import { FormattedMessage } from 'react-intl';

export const singularTerms = (key) => {
  switch (key) {
    case 'riskAnalysis':
      return (<FormattedMessage
        id="Common.riskAnalysis"
        defaultMessage="risk analysis"
      />);
    case 'dataSubjectCategory':
      return (<FormattedMessage
        id="RecordDetails.dataSubjectCategory"
        description="Data subject category"
        defaultMessage="data subject category"
      />);
    case 'dataSource':
      return (<FormattedMessage
        id="Record.dataSource"
        description="Data Source"
        defaultMessage="data source"
      />);
    case 'personalDataCategory':
      return (<FormattedMessage
        id="RecordDetails.personaldataCategory"
        description="Personal data category"
        defaultMessage="personal data category"
      />);
    case 'processors':
      return (<FormattedMessage
        id="ProcessingDetails.processor"
        description="Processor"
        defaultMessage="processor"
      />);
    case 'controller':
      return (<FormattedMessage
        id="Record.controller"
        description="controller"
        defaultMessage="controller"
      />);
    case 'personaldata':
      return (<FormattedMessage
        id="Record.personaldata"
        description="personal data item"
        defaultMessage="personal data item"
      />);
    case 'processingCategoryHeader':
      return (<FormattedMessage
        id="ProcessingDetails.processingCategory"
        description="processing category"
        defaultMessage="processing category"
      />);
    case 'datasource':
      return (<FormattedMessage
        id="Record.datasource"
        description="data source"
        defaultMessage="data source"
      />);
    case 'datasourcecategory':
      return (<FormattedMessage
        id="Record.datasourcecategory"
        defaultMessage="data source category"
      />);
    case 'assessment':
      return (<FormattedMessage
        id="Record.assessment"
        description="Assessment"
        defaultMessage="assessment"
      />);
    case 'processing':
      return (<FormattedMessage
        id="Record.processing"
        description="Processing"
        defaultMessage="processing"
      />);
    case 'rights':
      return (<FormattedMessage
        id="MasterDataItem.right"
        description="right"
        defaultMessage="right"
      />);
    case 'organisationEntity':
      return (<FormattedMessage
        id="ExternalOrganisations.organisationEntity"
        description="organisation entity"
        defaultMessage="organisation entity"
      />);
    case 'riskDetails':
      return (<FormattedMessage
        id="Assessment.riskDetail"
        description="Risk detail"
        defaultMessage="risk detail"
      />);
    case 'TransferGrounds':
      return (<FormattedMessage
        id="RecordDetails.transferGrounds"
        description="Transfer Grounds"
        defaultMessage="transfer ground"
      />);
    case 'executingEntity':
      return (<FormattedMessage
        id="RecordDetails.executingEntity"
        description="Executing entity"
        defaultMessage="executing entity"
      />);
    case 'dataRecipient':
      return (<FormattedMessage
        id="RecordDetails.dataRecipients"
        description="Data Recipient"
        defaultMessage="data recipient"
      />);
    case 'securityMeasure':
      return (<FormattedMessage
        id="Records.securityMeasure"
        description="technical and organisational security measure"
        defaultMessage="technical and organisational security measure"
      />);
    case 'processingGround':
      return (<FormattedMessage
        id="RecordDetails.processingGround"
        description="Processing ground"
        defaultMessage="processing ground"
      />);
    case 'aclOrgs':
      return (<FormattedMessage
        id="RecordDetails.aclOrgs"
        description="organisations"
        defaultMessage="organisation"
      />);
    case 'User':
      return (<FormattedMessage
        id="RecordDetails.permissionsLabel"
        description="user"
        defaultMessage="user"
      />);
    case 'breach':
      return (<FormattedMessage
        id="Record.breach"
        description="breach"
        defaultMessage="breach"
      />);
    case 'supervisoryAuthority':
      return (<FormattedMessage
        id="Breach.supervisoryAuthority"
        description="supervisory authority"
        defaultMessage="supervisory authority"
      />);
    case 'Document':
      return (<FormattedMessage
        id="Records.document"
        description="document"
        defaultMessage="document"
      />);
    case 'securitymeasure':
      return (<FormattedMessage
        id="Records.securitymeasure"
        description="security measure"
        defaultMessage="security measure"
      />);
    default:
      return key;
  }
};

export const pluralTerms = (key) => {
  switch (key) {
    case 'days':
      return (<FormattedMessage
        id="ExpiryPeriod.days"
        description="days"
        defaultMessage="days"
      />);
    case 'processings':
      return (<FormattedMessage
        id="Type.processings"
        description="processings"
        defaultMessage="processings"
      />);
    case 'assessments':
      return (<FormattedMessage
        id="Type.assessments"
        description="assessments"
        defaultMessage="assessments"
      />);
    case 'breaches':
      return (<FormattedMessage
        id="Type.breaches"
        description="breaches"
        defaultMessage="breaches"
      />);
    case 'attachmentsLabel':
      return (<FormattedMessage
        id="RecordDetails.attachmentsLabel"
        description="Attachments"
        defaultMessage="attachments"
      />);
    case 'mitigatingMeasures':
      return (<FormattedMessage
        id="Common.mitigatingMeasures"
        defaultMessage="mitigating measures"
      />);
    case 'purposes':
      return (<FormattedMessage
        id="Purposes.masterData"
        description="purposes"
        defaultMessage="purposes"
      />);
    case 'processingGrounds':
      return (<FormattedMessage
        id="Record.processingGrounds.smallCase"
        defaultMessage="processing grounds"
      />);
    case 'personalDataItems':
      return (<FormattedMessage
        id="PersonalDataItems.masterData"
        defaultMessage="personal data items"
      />);
    case 'retentionTerms':
      return (<FormattedMessage
        id="Record.retentionTerms.smallCase"
        defaultMessage="retention terms"
      />);
    case 'purposeOfIntlTransfer':
      return (<FormattedMessage
        id="Record.purposeOfIntlTransfer.smallCase"
        defaultMessage="purpose for the international transfer"
      />);
    case 'transferGrounds':
      return (<FormattedMessage
        id="RecordDetails.transferGrounds.plural"
        defaultMessage="transfer grounds"
      />);
    case 'processors':
      return (<FormattedMessage
        id="ProcessingDetails.processors.smallCase"
        defaultMessage="processors"
      />);
    case 'controllers':
      return (<FormattedMessage
        id="ProcessingDetails.controllers.smallCase"
        defaultMessage="controllers"
      />);
    case 'tenants':
      return (<FormattedMessage
        id="Common.tenants"
        defaultMessage="tenants"
      />);
    default:
      return key;
  }
};
