import Immutable from 'immutable';

import { getFilteredOn } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOrder: 'DESC',
    sortOn: 'startedAt'
  }),
  isFetching: false,
  filterColumn: {},
  jobDetail: undefined,
  usage: undefined,
  subTenantJobs: Immutable.List(),
  showNotification: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'JOBS:LIST:INIT':
      return state
        .set('filteredOn', state.get('filteredOn'))
        .set('filterParams', state.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('jobDetail', defaultState.get('jobDetail'))
        .set('usage', defaultState.get('usage'))
        .set('position', 0);
    case 'JOBS:LIST:FETCH':
      return state.set('error', defaultState.get('error'))
        .set('isFetching', true);
    case 'JOBS:ITEM:FETCH:INIT':
      return state.set('jobDetail', defaultState.get('jobDetail'));
    case 'JOBS:ITEM:FETCH:SUCCESS':
      return state.set('jobDetail', action.jobItem);
    case 'JOBS:OLD_ITEM:FETCH:SUCCESS':
      return state.set('oldEntityData', action.oldEntityData);
    case 'JOBS:ITEM:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'JOBS:LIST:FETCH:SUCCESS': {
      let newItems = Immutable.List();
      let newPosition = 0;
      newItems = state.get('items').concat(action.items);
      newPosition = state.get('position') + action.items.length;
      return state.set('items', newItems)
        .set('position', newPosition)
        .set('jobDetail', defaultState.get('jobDetail'))
        .set('isFetching', false);
    }
    case 'JOBS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'JOBS:USAGE_STATUS:FETCH:SUCCESS':
      return state.set('usage', action.usage);
    case 'JOBS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('position', 0)
        .set('items', Immutable.List())
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('usage', defaultState.get('usage'))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'JOBS:FILTER:RESET':
      return state.set('filterParams', defaultState.get('filterParams'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterColumn', defaultState.get('filterColumn'))
    case 'JOBS:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'JOB:UPDATE:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'JOBS:USAGE_STATUS:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'SUB_TENANTS:JOBS:LIST:FETCH:INIT':
    case 'SUB_TENANTS:JOBS:LIST:RESET':
      return state.set('subTenantJobs', defaultState.get('subTenantJobs'))
        .set('showNotification', defaultState.get('showNotification'));
    case 'SUB_TENANTS:JOBS:LIST:FETCH:SUCCESS':
      return state.set('subTenantJobs', Immutable.List(action.items))
        .set('showNotification', action.showNotification);
    default:
      return state;
  }
};
