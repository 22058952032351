import { combineReducers } from 'redux';

import filters from './filters';
import auditTrail from './auditTrail';
import jobs from './jobs';
import layout from './layout';
import subtenant from './subtenant';

export default combineReducers({
  filters,
  auditTrail,
  jobs,
  layout,
  subtenant
});
