import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import MultipleSelector from './multipleSelector';

class MultipleSelectorDialog extends React.Component {
  static filterLegalEntities(selectedEntities, searchedItems) {
    let filteredData = [];
    if (typeof selectedEntities === 'string') {
      filteredData = searchedItems.filter(
        (legalEntity) => legalEntity.id !== selectedEntities
      );
    } else {
      filteredData = searchedItems.filter((legalEntity) => {
        const index = selectedEntities.findIndex((selectedItem) =>
          selectedItem.value
            ? legalEntity.name === selectedItem.value.name
            : legalEntity.name === selectedItem.name
        );
        return index === -1;
      });
    }
    return filteredData;
  }

  constructor(props) {
    super(props);
    this.state = {
      multipleLegalEntityList: Immutable.List([])
    };
    this.requestClose = this.requestClose.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.handleFilterItems = this.handleFilterItems.bind(this);
  }

  componentDidMount() {
    if (this.props.init) {
      this.props.init(
        this.props.searchText,
        this.props.hasTopDownLimit,
        this.props.isGlobal,
        this.props.isInternal
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.legalEntities !== nextProps.legalEntities ||
      nextProps.legalEntities.size > 0
    ) {
      const { legalEntities } = nextProps;
      const excludedEntities =
        this.props.selectedItems?.length > 0
          ? this.props.selectedItems.concat(this.props.excludeEntities)
          : this.props.excludeEntities;
      const multipleLegalEntityList =
        excludedEntities?.length > 0
          ? MultipleSelectorDialog.filterLegalEntities(
            excludedEntities,
            legalEntities
          )
          : legalEntities;
      if (
        multipleLegalEntityList &&
        multipleLegalEntityList.size < 15 &&
        nextProps.legalEntities.size > 0 &&
        this.props.position !== nextProps.position
      ) {
        this.props.getNextData(
          nextProps.position,
          this.props.hasTopDownLimit,
          this.props.isInternal
        );
      }
      this.setState({
        multipleLegalEntityList
      });
    }
  }

  componentWillUnmount() {
    if (this.props.resetfilterItems) {
      this.props.resetfilterItems();
    }
  }

  handleOnSubmit(selectedItems) {
    if (this.props.handleMultipleItems && selectedItems.size > 0) {
      const modifiedItems = selectedItems.map((item) => ({ value: item }));
      this.props.handleMultipleItems(modifiedItems);
    }
    this.requestClose();
  }

  handleScrollEnd() {
    this.props.getNextData(
      this.props.position,
      this.props.hasTopDownLimit,
      this.props.isInternal
    );
  }

  handleFilterItems(filterParams, searchText) {
    this.props.filterItems(
      filterParams,
      searchText,
      this.props.hasTopDownLimit
    );
  }

  requestClose() {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  }

  render() {
    const { multiValue, searchText, searchResults, tagsSearch, isInternal } =
      this.props;

    return (
      <CommonDialog
        id="dialog"
        fullWidth={true}
        maxWidth="sm"
        show={this.props.open}
        onCancel={this.requestClose}
        title={this.props.title}
      >
        <MultipleSelector
          id="multiple-selector"
          key={this.props.key}
          showOrganisationFilter={this.props.showOrganisationFilter}
          multiValue={multiValue}
          filteredData={this.state.multipleLegalEntityList}
          handleSubmit={this.handleOnSubmit}
          onScrollStop={this.handleScrollEnd}
          tagsPosition={this.props.tagsPosition}
          getNextTags={this.props.getNextTags}
          filterItems={this.handleFilterItems}
          filterTags={this.props.tags}
          searchResults={searchResults}
          tagsSearch={tagsSearch}
          onCancel={this.requestClose}
          searchText={searchText}
          maxCount={this.props.maxCount}
          selectedEntitiesSize={this.props.selectedEntitiesSize}
          showFilter={!this.props.isGlobal}
          isInternal={isInternal}
        />
      </CommonDialog>
    );
  }
}

MultipleSelectorDialog.propTypes = {
  position: PropTypes.number,
  legalEntities: PropTypes.instanceOf(Immutable.List),
  tags: PropTypes.instanceOf(Immutable.List),
  showOrganisationFilter: PropTypes.bool,
  key: PropTypes.string,
  title: PropTypes.node,
  multiValue: PropTypes.bool,
  getNextData: PropTypes.func,
  filterItems: PropTypes.func,
  resetfilterItems: PropTypes.func,
  init: PropTypes.func,
  selectedItems: PropTypes.instanceOf(Immutable.List),
  onRequestClose: PropTypes.func,
  handleMultipleItems: PropTypes.func,
  open: PropTypes.bool,
  getNextTags: PropTypes.func,
  tagsPosition: PropTypes.number,
  searchText: PropTypes.string,
  hasTopDownLimit: PropTypes.bool,
  searchResults: PropTypes.instanceOf(Immutable.List),
  tagsSearch: PropTypes.func,
  maxCount: PropTypes.number,
  selectedEntitiesSize: PropTypes.number,
  isGlobal: PropTypes.bool,
  excludeEntities: PropTypes.arrayOf(PropTypes.object),
  isInternal: PropTypes.bool
};

MultipleSelectorDialog.defaultProps = {
  open: false,
  position: 0,
  multiValue: true,
  showOrganisationFilter: true,
  onRequestClose: (e) => e,
  filterItems: (e) => e,
  resetfilterItems: (e) => e,
  handleMultipleItems: (e) => e,
  title: null,
  getNextData: (e) => e,
  getNextTags: (e) => e,
  tagsPosition: 0,
  init: (e) => e,
  searchText: '',
  key: '',
  tags: Immutable.List(),
  searchResults: Immutable.List(),
  tagsSearch: (e) => e,
  legalEntities: Immutable.List(),
  selectedItems: Immutable.List(),
  hasTopDownLimit: false,
  maxCount: undefined,
  selectedEntitiesSize: undefined,
  isGlobal: false,
  excludeEntities: [],
  isInternal: undefined
};
export default MultipleSelectorDialog;
