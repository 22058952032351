import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {
  renderTextField,
  renderSelectField
} from '@packages/components/form-components';
import styles from '@packages/ui/styles';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import { getReducerType } from '@packages/utils/common-utils';
import { isMasterDataEditFormVisible } from '../../../utils';
import {
  hintTextTranslations,
  subTypeTranslations
} from '../../../masterDataTranslations';
import ImpactAnalysisPage from '../../../impact-analysis';
import { renderChipSelector } from '../../../../privacy-record-detail/renderers/renderers';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'entityType'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });
  return errors;
};

export class SimpleEntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDialog: true,
      onAfterUpdation: false,
      limitExceeded: false,
      reducerType: getReducerType(
        this.props.masterDataType,
        this.props.entityType
      ),
      isEditWithJob: true
    };
    this.onSave = this.onSave.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    if (this.props.dataItemId) {
      this.props.init(
        this.props.dataItemId,
        this.props.isEdit,
        this.props.entityType,
        this.props.masterDataType
      );
    }
    if (!this.props.isEdit) this.handleInitialise();
  }

  componentWillReceiveProps(nextProps) {
    const { entityType, simpleEntities, masterDataType } = this.props;
    const reducerType = getReducerType(masterDataType, entityType);
    this.setState({
      reducerType
    });
    const nextData = nextProps.simpleEntities[reducerType];
    const currentData = simpleEntities[reducerType];
    if (
      nextData.get('simpleEntityData') !== undefined &&
      currentData.get('simpleEntityData') !== nextData.get('simpleEntityData')
    ) {
      if (this.state.onAfterUpdation)
        this.props.onSave(
          nextData.get('simpleEntityData'),
          this.state.closeDialog
        );
      else this.handleInitialise(nextData.get('simpleEntityData'));
    }
  }

  shouldComponentUpdate(nextProps) {
    const { entityType, simpleEntities, masterDataType, limitExceeded } =
      this.props;
    const reducerType = getReducerType(masterDataType, entityType);
    const nextData = nextProps.simpleEntities[reducerType];
    const currentData = simpleEntities[entityType];
    return (
      currentData !== nextData || limitExceeded !== nextProps.limitExceeded
    );
  }

  componentWillUnmount() {
    this.props.resetSimpleEntity(this.state.reducerType);
  }

  handleInitialise(simpleEntityItem) {
    if (!this.props.isEdit) this.props.initialize(this.props.value);
    else {
      this.props.initialize(simpleEntityItem);
    }
  }

  handleOnChange(value, property) {
    this.setState({
      [property]: value
    });
  }

  handleEditWithJob = (isChecked) => {
    this.setState({ isEditWithJob: isChecked });
  };

  handleTagChange = (data) => {
    this.props.change('tags', data);
  };

  onSave(simpleEntityData, closeDialog) {
    if (this.props.limitExceeded) {
      this.setState({ limitExceeded: true });
    } else {
      const modifiedData = {
        ...simpleEntityData,
        name: simpleEntityData.name.trim()
      };
      const { isEdit, source, entityType, masterDataType } = this.props;
      this.props.updateSimpleEntityData(
        modifiedData,
        isEdit,
        source,
        entityType,
        masterDataType,
        this.props.isUsed,
        this.state.isEditWithJob
      );
      this.setState({
        closeDialog,
        onAfterUpdation: true
      });
      if (!closeDialog) this.props.initialize({});
    }
  }

  render() {
    const {
      handleSubmit,
      isEdit,
      onCancel,
      submitting,
      masterDataType,
      isTagEdit,
      simpleEntities,
      subTypes,
      usage,
      hasNoLimit,
      isUsed,
      allowSaveAndCreate
    } = this.props;
    const { reducerType, limitExceeded } = this.state;
    const showForm = isMasterDataEditFormVisible(isUsed, hasNoLimit, isEdit);
    const actionError =
      simpleEntities[reducerType] &&
      simpleEntities[reducerType].get('error').toJS();

    return (
      <form>
        {isUsed && (
          <ImpactAnalysisPage
            hasNoLimit={hasNoLimit}
            usage={usage}
            handleEditWithJob={this.handleEditWithJob}
            type={masterDataType}
            isJobActive={this.props.isJobActive}
          />
        )}
        {showForm && !this.props.isJobActive && (
          <div>
            <div>
              <div>
                <Field
                  id="simpleEntityDialog"
                  name="name"
                  label={commonTranslations.name}
                  hintTextLabel={hintTextTranslations[reducerType]}
                  autoFocus={!isEdit}
                  isEdit={isEdit}
                  component={renderTextField}
                  disabled={isTagEdit}
                  showTextField={!isTagEdit}
                />
              </div>
              {subTypes && !isEdit && (
                <div style={{ width: '90%' }}>
                  <Field
                    id="entityType"
                    name="entityType"
                    label={recordTranslations.type}
                    defaultSelected="Technical"
                    component={renderSelectField}
                    onChange={(event, type) =>
                      this.handleOnChange(type, 'entityType')
                    }
                  >
                    {subTypes.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              )}
              <div>
                {!this.props.hideTags && (
                  <FieldArray
                    id="tags"
                    name="tags"
                    type="tags"
                    entityType={masterDataType}
                    disabled={isEdit && !isTagEdit}
                    headerLabel={commonTranslations.tags}
                    hintText={commonTranslations.addTags}
                    updateTags={this.handleTagChange}
                    disabledTagStyle={{ marginTop: 20 }}
                    chipSelectorStyle={{ width: '98%' }}
                    component={renderChipSelector}
                    showHeader={isTagEdit || !isEdit}
                  />
                )}
              </div>
              {isEdit && subTypes && (
                <div style={{ marginTop: 20 }}>
                  {recordTranslations.type}:{' '}
                  {simpleEntities[reducerType].get('simpleEntityData') &&
                    subTypeTranslations(
                      simpleEntities[reducerType].get('simpleEntityData')
                        .entityType
                    )}
                </div>
              )}
            </div>
          </div>
        )}
        {((actionError && actionError.isError) || limitExceeded) && (
          <div
            style={Object.assign({}, styles.errorMessage, { marginBottom: 10 })}
          >
            {limitExceeded
              ? commonTranslations.maximumTagLimit
              : actionError.message}
          </div>
        )}
        <div style={styles.addButton}>
          {showForm && !this.props.isJobActive && (
            <Button
              id="submit"
              disabled={submitting}
              type="submit"
              onClick={handleSubmit((simpleEntityData) =>
                this.onSave(simpleEntityData, true)
              )}
            >
              {commonTranslations.saveAndClose}
            </Button>
          )}
          {!isEdit && allowSaveAndCreate && (
            <Button
              id="save-create"
              variant="text"
              onClick={handleSubmit((simpleEntityData) =>
                this.onSave(simpleEntityData, false)
              )}
            >
              {commonTranslations.saveAndCreate}
            </Button>
          )}
          {showForm && !this.props.isJobActive && (
            <Button variant="text" onClick={onCancel}>
              {commonTranslations.Cancel}
            </Button>
          )}
          {((!this.props.hasNoLimit && this.props.isUsed) ||
            this.props.isJobActive) && (
            <div style={{ float: 'right' }}>
              <Button onClick={onCancel}>{commonTranslations.Ok}</Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

SimpleEntityForm.propTypes = {
  isEdit: PropTypes.bool,
  simpleEntityData: PropTypes.shape({}),
  simpleEntities: PropTypes.shape({
    toJS: PropTypes.func
  }),
  dataItemId: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  initialize: PropTypes.func,
  resetSimpleEntity: PropTypes.func,
  updateSimpleEntityData: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  limitExceeded: PropTypes.bool,
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  init: PropTypes.func.isRequired,
  source: PropTypes.string,
  allowSaveAndCreate: PropTypes.bool,
  masterDataType: PropTypes.string,
  entityType: PropTypes.string,
  usage: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  isJobActive: PropTypes.bool,
  hasNoLimit: PropTypes.bool,
  subTypes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.instanceOf(Immutable.List)
  ]),
  change: PropTypes.func,
  isTagEdit: PropTypes.bool,
  hideTags: PropTypes.bool
};

SimpleEntityForm.defaultProps = {
  isEdit: false,
  initialize: (e) => e,
  resetSimpleEntity: (e) => e,
  onSave: (e) => e,
  limitExceeded: false,
  allowSaveAndCreate: true,
  updateSimpleEntityData: (e) => e,
  onCancel: (e) => e,
  handleSubmit: (e) => e,
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  source: '',
  dataItemId: '',
  simpleEntityData: {},
  masterDataType: '',
  simpleEntities: {},
  entityType: '',
  usage: {},
  hasNoLimit: false,
  isUsed: false,
  subTypes: undefined,
  isJobActive: false,
  change: (e) => e,
  isTagEdit: false,
  hideTags: false
};

const SimpleEntityFormWrapper = reduxForm({
  form: 'SimpleEntityForm', // a unique identifier for this form
  validate
})(SimpleEntityForm);

export default SimpleEntityFormWrapper;
