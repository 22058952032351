/* eslint-disable prefer-destructuring */
import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import {
  handleServiceDown, getEntityType, getReducerType,
  MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { getFilteredOn } from '@packages/utils/reducer-utils';
import { getURLWithParams } from '../saga-utils';

const reducerTypeArray = ['riskDetails', 'decisions', 'findings'];
export function* resetAndfetchSimpleEntities(action) {
  const { entityType, masterDataType } = action;
  const reducerType = getReducerType(masterDataType, entityType).toUpperCase();
  yield put({ type: `MASTERDATA:${reducerType}:LIST:REQUEST:INIT` });
  yield fetchSimpleEntitiesList(action);
}

export function* fetchSimpleEntitiesList(action) {
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT,
    masterDataType, entityType = null, source = '' } = action;
  const reducerType = getReducerType(masterDataType, entityType);
  yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:LIST:FETCH` });
  let filteredOn = Immutable.Map();
  try {
    const simpleEntitiesState =
      yield select(state => state.environment.simpleEntities[reducerType]);

    let filterParams = {};
    let searchParameters = {};
    let searchTextObj = {};
    let unused = false;
    if (simpleEntitiesState) {
      if (reducerTypeArray.includes(reducerType)) {
        filterParams = simpleEntitiesState.get('sortAndSearchParams').get(entityType).filterParams.toJS();
        searchTextObj = simpleEntitiesState.get('sortAndSearchParams').get(entityType).searchText.toJS();
        unused = source !== 'records' && simpleEntitiesState.get('sortAndSearchParams').get(entityType).unused;
        filteredOn = simpleEntitiesState.get('sortAndSearchParams').get(entityType).filteredOn;
      } else {
        filterParams = simpleEntitiesState.get('filterParams').toJS();
        searchTextObj = simpleEntitiesState.get('searchText').toJS();
        filteredOn = simpleEntitiesState.get('filteredOn');
        unused = source !== 'records' && simpleEntitiesState.get('unused');
      }
      searchParameters = searchTextObj ? { searchKey: 'name', searchText: searchTextObj.name } : {};
    }
    const { sortOn, sortOrder } = filterParams;
    if (entityType) {
      const filteredOnEntity = getFilteredOn(Object.assign({},
        { filterKey: 'entityType', filteredOn: [getEntityType(entityType)] }));
      filteredOn = filteredOn.merge(filteredOnEntity);
    } else filteredOn = simpleEntitiesState ? simpleEntitiesState.get('filteredOn') : Immutable.Map();

    const currentUrl = getURLWithParams({ sortOn, sortOrder, filteredOn },
      position, searchParameters, `${masterDataType.toLowerCase()}`, rowCount, undefined, unused);
    const response = yield registry.get('request').get(currentUrl, null, {});

    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: `MASTERDATA:${reducerType.toUpperCase()}:LIST:FETCH:SUCCESS`,
          items: modifiedItems,
          simpleEntityData: action.simpleEntityData
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:LIST:FETCH:FAIL`, error: err.message });
    yield put({ type: `RECORDS:${reducerType.toUpperCase()}:LIST:REQUEST:FAIL`, error: err.message });
    registry.get('logger').error(err);
  }
}

export function* filterSimpleEntities(action) {
  const filterParams = action.filterParams || {};
  const { entityType, masterDataType } = action;
  const reducerType = getReducerType(masterDataType, entityType).toUpperCase();
  yield put({ type: `MASTERDATA:${reducerType}:LIST:FILTER`, filterParams, entityType });
  yield fetchSimpleEntitiesList(action);
}

export function* searchSimpleEntities(action) {
  let { searchParams } = action;
  const { masterDataType, entityType } = action;
  let filterParams = {};
  const reducerType = getReducerType(masterDataType, entityType);
  const rowCount = MASTERDATA_SEARCH_LIMIT;
  const { searchKey, isPageSearch } = searchParams;
  let { searchText } = searchParams;
  let filteredOn = Immutable.Map();
  let unused = false;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';
  const simpleEntitiesState =
    yield select(state => state.environment.simpleEntities[reducerType]);
  if (simpleEntitiesState) {
    if (reducerTypeArray.includes(reducerType)) {
      filterParams = simpleEntitiesState.get('sortAndSearchParams').get(entityType).filterParams.toJS();
      filteredOn = simpleEntitiesState.get('sortAndSearchParams').get(entityType).filteredOn;
      unused = simpleEntitiesState.get('sortAndSearchParams').get(entityType).unused;
    } else {
      filterParams = simpleEntitiesState.get('filterParams').toJS();
      filteredOn = simpleEntitiesState.get('filteredOn');
      unused = simpleEntitiesState.get('unused');
    }
  }
  const { sortOn, sortOrder } = filterParams;
  if (entityType) {
    filteredOn = getFilteredOn(Object.assign({},
      { filterKey: 'entityType', filteredOn: [getEntityType(entityType)] }));
  } else filteredOn = simpleEntitiesState ? simpleEntitiesState.get('filteredOn') : Immutable.Map();

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    let searchTextObj = Immutable.Map();
    if (reducerTypeArray.includes(reducerType)) {
      searchTextObj = simpleEntitiesState.get('sortAndSearchParams').get(entityType).searchText;
    } else {
      simpleEntitiesState.get('searchText');
    }

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = Object.assign({}, searchParams, { searchText });
  }

  yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:${searchMode}`, searchKey, searchText, entityType });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParams, `${masterDataType.toLowerCase()}`, rowCount, undefined, unused), null, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: `MASTERDATA:${reducerType.toUpperCase()}:${searchMode}:SUCCESS`,
          searchKey,
          items: response.body,
          searchText,
          entityType
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:LIST:SEARCH:FAIL`, error: err.message });
  }
}

export function* updateSimpleEntity(action) {
  const { isEdit, data, source, entityType, masterDataType = null } = action;
  const request = registry.get('request');
  const reducerType = getReducerType(masterDataType, entityType);
  let response;
  let requestData = entityType ?
    { ...data, entityType: getEntityType(entityType) } : { ...data };
  try {
    if (isEdit) {
      // when in edit mode the data object contains all the entity related details. pick only which is necessary.
      requestData = { name: data.name, entityType: data.entityType };
    }
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(`/v1/masterdata/${masterDataType.toLowerCase()}`, requestData);
    } else {
      response = yield request.put(`/v1/masterdata/${masterDataType.toLowerCase()}/${data.id}?withJob=false`, data);
    }
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: NotificationMessages[masterDataType][isEdit],
            type: 'success'
          }
        });
        const modifiedResponse = { ...response.body, key: response.body.name };
        if (masterDataType !== 'policies') delete modifiedResponse.entityType;

        // Handle consistency in the front end.
        if (isEdit) {
          yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:UPSERT:SUCCESS`, simpleEntity: modifiedResponse });
          yield updateSimpleEntities({
            type: 'edit',
            data: Object.assign({}, data, response.body),
            source,
            entityType,
            reducerType
          });
        } else {
          yield put({
            type: `MASTERDATA:${reducerType.toUpperCase()}:UPSERT:SUCCESS`,
            simpleEntity: modifiedResponse,
            position: 0
          });
          yield fetchSimpleEntitiesList({
            simpleEntityData: modifiedResponse,
            isPersistedData: true,
            rowCount: MASTERDATA_FETCH_LIMIT,
            entityType,
            masterDataType
          });
        }

        break;
      }
      case 409: yield put({
        type: `MASTERDATA:${reducerType.toUpperCase()}:UPSERT:FAIL`,
        error: response.body.msg
      });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:UPSERT:FAIL`, error: err.message });
  }
}

export function* fetchSimpleEnitity(action) {
  const { entityType, masterDataType } = action;
  const reducerType = getReducerType(masterDataType, entityType).toUpperCase();
  // const masterDataType = getMasterDataType(dataItemType);
  yield put({ type: `MASTERDATA:${reducerType}:ITEM:FETCH:INIT` });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/masterdata/${masterDataType.toLowerCase()}/${action.id}`, null);
      switch (response.status) {
        case 200: {
          yield put({ type: `MASTERDATA:${reducerType}:ITEM:FETCH:SUCCESS`, simpleEntityData: response.body });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({ type: `MASTERDATA:${reducerType}:ITEM:FETCH:FAIL`, error: err.message });
    }
  }
}

export function* deleteSimpleEntities(action) {
  const { dataItemId, masterDataType, entityType } = action;
  const request = registry.get('request');
  const reducerType = getReducerType(masterDataType, entityType);
  try {
    const response = yield request.delete(`/v1/masterdata/${masterDataType.toLowerCase()}/${dataItemId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError(masterDataType, entityType),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateSimpleEntities({
          type: 'delete',
          simpleEntityId: dataItemId,
          entityType,
          masterDataType,
          reducerType
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

function* updateSimpleEntities(action) {
  const { type, data, simpleEntityId, source, reducerType } = action;
  const simpleEntitiesState = yield select(state => state.environment.simpleEntities);
  const simpleEntityState = simpleEntitiesState[reducerType];
  let items = simpleEntityState ? simpleEntityState.get('items') : Immutable.List();
  let position = null;
  let newItems = simpleEntityState ? simpleEntityState.get('newItems') : Immutable.List();
  if (type === 'delete') {
    position = simpleEntityState.get('position') - 1;
    const index = items.findIndex(entity => entity.id === simpleEntityId);
    items = items.remove(index);

    const newItemIndex = newItems.findIndex(entity => entity.id === simpleEntityId);
    newItems = newItemIndex !== -1 ? newItems.remove(newItemIndex) : newItems;
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = transformEntityItem(data);
    const index = items.findIndex(entity => entity.id === data.id);
    items = items.set(index, modifiedItem);

    const newItemIndex = newItems.findIndex(entity => entity.id === data.id);
    newItems = newItemIndex !== -1 ? newItems.set(newItemIndex, modifiedItem) : newItems;
  } else {
    const modifiedItem = transformEntityItem(data);
    items = items && items.unshift(modifiedItem);
  }
  yield put({ type: `MASTERDATA:${reducerType.toUpperCase()}:LIST:UPDATE:SUCCESS`, items, newItems, position });
}

const transformEntityItem = item => ({
  ...item,
  key: item.name
});

const NotificationMessages = {
  purposes: {
    true: notificationtranslations.purposeUpdateSuccess,
    false: notificationtranslations.purposeCreated
  },
  riskDetails: {
    true: notificationtranslations.riskUpdateSuccess,
    false: notificationtranslations.riskCreated
  },
  qualityControls: {
    true: notificationtranslations.qualityControlsUpdateSuccess,
    false: notificationtranslations.qualityControlsCreated
  },
  mitigatingMeasures: {
    true: notificationtranslations.measureUpdateSuccess,
    false: notificationtranslations.measureCreated
  },
  references: {
    true: notificationtranslations.referenceUpdateSuccess,
    false: notificationtranslations.referenceCreated
  },
  fairnessOfDecisions: {
    true: notificationtranslations.fairnessOfDecisionUpdateSuccess,
    false: notificationtranslations.fairnessOfDecisionCreated
  },
  dataSubjectRights: {
    true: notificationtranslations.rightUpdateSuccess,
    false: notificationtranslations.rightCreated
  },
  threats: {
    true: notificationtranslations.threatUpdateSuccess,
    false: notificationtranslations.threatCreated
  },
  impacts: {
    true: notificationtranslations.impactUpdateSuccess,
    false: notificationtranslations.impactCreated
  },
  securityMeasures: {
    true: notificationtranslations.securityMeasureUpdateSuccess,
    false: notificationtranslations.securityMeasureCreated
  },
  policies: {
    true: notificationtranslations.policyUpdateSuccess,
    false: notificationtranslations.policyCreated
  },
  accountabilities: {
    true: notificationtranslations.accountabilityUpdateSuccess,
    false: notificationtranslations.accountabilityCreated
  },
  accountabilitysecuritymeasures: {
    true: notificationtranslations.accountabilityUpdateSuccess,
    false: notificationtranslations.accountabilityCreated
  },
  tags: {
    true: notificationtranslations.tagsUpdateSuccess,
    false: notificationtranslations.tagsCreated
  },
  dataSourceCategories: {
    true: notificationtranslations.dataSourceCategoryUpdateSuccess,
    false: notificationtranslations.dataSourceCategoryCreated
  },
  documentRecordTypes: {
    true: notificationtranslations.documentRecordTypesUpdateSuccess,
    false: notificationtranslations.documentRecordTypesCreated
  },
  specialCharacteristics: {
    true: notificationtranslations.specialCharacteristicsUpdateSuccess,
    false: notificationtranslations.specialCharacteristicsCreated
  }
};
