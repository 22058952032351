import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { renderDateField } from '@packages/components/form-components';
import { commonTranslations } from '@packages/utils/commontranslations';
import { getLocalDateTime } from '@packages/utils/date-utils';

import styles from '@packages/ui/styles';

const renderTimeField = ({ input, label }) => (
  <div>{label}
    <div style={{ border: '1px solid #c5c5c5', width: '100%', marginTop: '10px', borderRadius: '4px', height: '40px' }}>
      <TextField
        type="time"
        style={{ width: '80%', margin: '4px -18px 2px 3px' }}
        onChange={(event) => { input.onChange(event.target.value); }}
        InputLabelProps={{
          shrink: true
        }}
        {...input}
        inputProps={{
          step: 300
        }}
      />
      {(input.value) &&
        <FontAwesome
          name="times-circle"
          style={({ ...styles.btn_date_reset, marginTop: 11, marginLeft: 23})}
          onClick={() => {
            input.onChange('');
          }}
        />
        }
    </div>
  </div>
);

renderTimeField.propTypes = {
  label: PropTypes.node,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string
  })
};

renderTimeField.defaultProps = {
  label: null,
  input: {
    onChange: e => e,
    value: ''
  }
};

const getLocalTime = (utcDate) => {
  if (!utcDate) return '';
  return moment.utc(utcDate).local().format('HH:mm');
};

export class DateFilter extends React.Component {
  componentDidMount() {
    const startdate = this.props.filteredOn ? getLocalDateTime(this.props.filteredOn.fromDate,
      'YYYY-MM-DD hh:mm:ss.SSS', this.props.locale) : '';
    const enddate = this.props.filteredOn ? getLocalDateTime(this.props.filteredOn.toDate,
      'YYYY-MM-DD hh:mm:ss.SSS', this.props.locale) : '';
    const startTime = getLocalTime(this.props.filteredOn.fromDate) || '00:00';
    const endTime = getLocalTime(this.props.filteredOn.toDate) || '23:59';
    this.props.initialize({ fromDate: startdate, toDate: enddate, fromTime: startTime, toTime: endTime });
  }

  render() {
    const { handleSubmit, submitFilterValues, locale } = this.props;
    const dateFormStyle = { marginTop: '-26px', display: 'flex', flexDirection: 'column' };
    const fromDateStyle = { width: '45%', float: 'left', marginBottom: 20 };
    const fromTimeStyle = { width: '45%', float: 'left', marginTop: '19px', marginLeft: 20 };
    const toDateStyle = { width: '45%', float: 'left', marginBottom: 20, marginTop: -19 };
    const toTimeStyle = { width: '45%', float: 'left', marginBottom: 20, marginLeft: 20 };

    return (
      <div style={styles.mainContentWrapper}>
        <form style={{ margin: '10px 40px 20px 40px' }} >
          <div style={dateFormStyle}>
            <div style={{ width: '100%' }}>
              <div style={fromDateStyle}>
                <Field
                  name="fromDate"
                  label={<FormattedMessage
                    id="Filter.fromDate"
                    description="From date"
                    defaultMessage="From date"
                  />}
                  hintTextLabel={commonTranslations.DateFormat}
                  locale={locale}
                  component={renderDateField}
                />
              </div>
              <div style={fromTimeStyle}>
                <Field
                  name="fromTime"
                  label={<FormattedMessage
                    id="Filter.startTime"
                    description="From time"
                    defaultMessage="From time"
                  />}
                  component={renderTimeField}
                />
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div style={toDateStyle}>
                <Field
                  name="toDate"
                  label={<FormattedMessage
                    id="Filter.endDate"
                    description="To date"
                    defaultMessage="To date"
                  />}
                  locale={locale}
                  hintTextLabel={commonTranslations.DateFormat}
                  component={renderDateField}
                />
              </div>
              <div style={toTimeStyle}>
                <Field
                  name="toTime"
                  label={<FormattedMessage
                    id="Filter.endTime"
                    description="To time"
                    defaultMessage="To time"
                  />}
                  component={renderTimeField}
                />
              </div>
            </div>

            <Button
              id="button"
              style={{ width: '40%' }}
              onClick={handleSubmit(data => submitFilterValues(data))}
            >
              <FormattedMessage
                id="Filter.Filter"
                description="Filter"
                defaultMessage="Filter"
              />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}


DateFilter.contextTypes = {
  router: PropTypes.object
};

DateFilter.propTypes = {
  submitFilterValues: PropTypes.func,
  handleSubmit: PropTypes.func,
  locale: PropTypes.string.isRequired,
  filteredOn: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string
  }),
  initialize: PropTypes.func

};

DateFilter.defaultProps = {
  submitFilterValues: e => e,
  handleSubmit: e => e,
  filteredOn: {},
  initialize: e => e
};

const DateFilterFormWrapper = reduxForm({
  form: 'DateFilter' // a unique identifier for this form
})(DateFilter);


export default (DateFilterFormWrapper);
