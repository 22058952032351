import { connect } from 'react-redux';
import SubProcessorsSelector from './component';

const mapStateToProps = state => ({
  subProcessors: state.subProcessors.get('subProcessors'),
  error: state.subProcessors.get('error'),
  position: state.subProcessors.get('position'),
  searchResults: state.subProcessors.get('items')
});

const mapDispatchToProps = dispatch => ({
  initSubProcessors: () => {
    dispatch({ type: 'SUBPROCESSORS:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'SUBPROCESSORS:LIST:REQUEST', position, rowCount: 15 });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'SUBPROCESSORS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SubProcessorsSelector);
