import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';

import { tenantConfigurationTranslations } from './tenantConfigurationTranslations';
import {
  getModifiedFieldType,
  getSectionsAndFields
} from './tenantConfigUtils';

export function* createCustomField(action) {
  const { data, isEdit, handleDrop, updateData } = action;
  const { field } = data;
  const fieldType = getModifiedFieldType(field.fieldType);

  try {
    const response = isEdit
      ? yield registry
        .get('request')
        .put(`v1/custom-fields/${fieldType}/${field.id}`, field)
      : yield registry
        .get('request')
        .post(`v1/custom-fields/${fieldType}`, field);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM:FIELD:UPSERT:SUCCESS',
          data: response.body
        });

        if (!handleDrop && !updateData)
          yield put({
            type: 'CUSTOM:FIELD:LIST:FETCH',
            fieldType
          });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isEdit
              ? tenantConfigurationTranslations.customFieldUpdated
              : tenantConfigurationTranslations.customFieldCreated,
            type: 'success'
          }
        });

        if (handleDrop)
          handleDrop({
            // addition of custom field created from dynamic layout
            field: response.body,
            width: response.body.fieldType === 'htmlText' ? 2 : 1,
            layoutFieldUniqueId: field.uniqueId,
            names: response.body.names
          });

        if (updateData)
          updateData({
            field: response.body,
            names: response.body.names,
            sectionId: data.sectionId
          });

        break;
      }
      case 409:
      case 412: {
        yield put({
          type: 'CUSTOM:FIELD:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* validateScoreCalculator(action) {
  const { data, handleDrop } = action;
  try {
    const response = yield registry
      .get('request')
      .post(`v1/custom-record-layout/verify`, {
        ...data,
        ...getSectionsAndFields(data.fields)
      });

    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM:CALCULATOR:VALIDATE:SUCCESS',
          error: response.body.msg
        });
        handleDrop(data.scoreCalculatorLayoutInfo);
        break;
      }

      case 412: {
        yield put({
          type: 'CUSTOM:CALCULATOR:VALIDATE:FAIL',
          error: response.body.msg
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* getCustomField({ data }) {
  try {
    const response = yield registry
      .get('request')
      .get(`v1/custom-fields/${data.fieldType}/${data.id}`, data);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM:FIELD:ITEM:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchCustomFields(action) {
  const { fieldType, position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;

  yield put({ type: 'CUSTOM:FIELD:LIST:FETCH:INIT' });

  try {
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-fields/${getModifiedFieldType(
          fieldType
        )}?offset=${position}&numberOfResults=${rowCount}`
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'CUSTOM:FIELD:LIST:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchCustomFieldUsage({ field, callback }) {
  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/custom-fields/${getModifiedFieldType(field.fieldType)}/${
          field.id
        }/usage`
      );
    switch (response.status) {
      case 200: {
        // fire callback with usage data
        callback(response.body);
        yield put({
          type: 'CUSTOM:MASTERDATA:USAGE:FETCH:SUCCESS'
        });
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}

export function* deleteCustomField({ data }) {
  try {
    const response = yield registry
      .get('request')
      .delete(
        `v1/custom-fields/${getModifiedFieldType(data.fieldType)}/${data.id}`
      );

    switch (response.status) {
      case 200:
      case 204: {
        yield put({
          type: 'CUSTOM:FIELD:ITEM:DELETE:SUCCESS',
          data
        });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content:
              tenantConfigurationTranslations.customFieldDeleteConfirmation,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
  }
}

export function* customRecordWorker(action) {
  const { mode = 'CREATE', data, id } = action;
  const req = registry.get('request');

  if (mode === 'LIST_FETCH')
    yield put({ type: 'CUSTOM_RECORD_TYPES:LIST_FETCH:INIT' });

  try {
    const response = yield {
      CREATE: () => req.post('/v1/custom-record-type', data),
      EDIT: () => req.put(`/v1/custom-record-type/${data.id}`, data),
      DELETE: () => req.delete(`/v1/custom-record-type/${id}`),
      LIST_FETCH: () => req.get('/v1/custom-record-type?withUsage=true')
    }[mode]();

    switch (response.status) {
      case 204:
      case 200:
        yield put({
          type: `CUSTOM_RECORD_TYPES:${mode}:SUCCESS`,
          data: mode === 'DELETE' ? { id } : response.body
        });
        if (mode !== 'LIST_FETCH') {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: {
                CREATE:
                  tenantConfigurationTranslations.recordTypeCreateConfirmation,
                EDIT: tenantConfigurationTranslations.recordTypeEditConfirmation,
                DELETE:
                  tenantConfigurationTranslations.recordTypeDeleteConfirmation
              }[mode],
              type: 'success'
            }
          });
        }

        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
  }
}

export function* itemListWorker(action) {
  const {
    mode = 'CREATE',
    data, // mode = ['CREATE' || 'EDIT']
    id, // mode = ['DELETE' || 'FETCH_BY_ID']
    fieldType,
    uniqueId, // mode = ['EDIT' || 'DELETE'], fieldType = 'simpleMasterData'
    callback,
    position = 0,
    rowCount = GENERAL_FETCH_LIMIT
  } = action;
  const req = registry.get('request');

  if (mode === 'FETCH') yield put({ type: 'CUSTOM:ITEM_LIST:FETCH:INIT' });

  try {
    const response = yield {
      CREATE: () => req.post('/v1/custom/item-list', data),
      EDIT: () => req.put(`/v1/custom/item-list/${data.id}`, data),
      DELETE: () => req.delete(`/v1/custom/item-list/${id}`),
      FETCH: () =>
        req.get(
          `/v1/custom/item-list?filter=fieldType=${fieldType}&withUsage=true&offset=${position}&numberOfResults=${rowCount}`
        ),
      FETCH_BY_ID: () => req.get(`/v1/custom/item-list/${id}?withUsage=true`)
    }[mode]();

    switch (response.status) {
      case 204:
      case 200:
        if (callback) callback();
        yield put({
          type: `CUSTOM:ITEM_LIST:${mode}:SUCCESS`,
          data: mode === 'DELETE' ? { id } : response.body
        });
        if (!mode.includes('FETCH')) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: {
                CREATE: tenantConfigurationTranslations.itemListCreated,
                EDIT: tenantConfigurationTranslations.itemListUpdated,
                DELETE: tenantConfigurationTranslations.itemListDeleted
              }[mode],
              type: 'success'
            }
          });
          if (mode === 'CREATE') {
            if (fieldType !== 'simpleMasterData')
              yield put(
                push(
                  `/tenant-configuration/${response.body.id}/${data.fieldType}`
                )
              );
            else
              yield put({
                type: 'CUSTOM:ITEM_LIST',
                mode: 'FETCH',
                fieldType
              });
          }
          // environment filter change after edit of custom field
          if (
            fieldType === 'simpleMasterData' &&
            ['EDIT', 'DELETE'].includes(mode)
          ) {
            yield put({
              type: 'ENVIRONMENT:CUSTOM_FILTER:CHANGE',
              change: mode,
              uniqueId,
              newUniqueId: data?.uniqueId
            });
          }
        }
        break;
      case 409:
      case 412:
        yield put({
          type: 'CUSTOM:ITEM_LIST:ACTION:FAIL',
          error: response.body.msg
        });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
  }
}

export function* itemListOptionWorker(action) {
  const { mode = 'CREATE', data, listId, layoutFieldUniqueId } = action;

  const req = registry.get('request');

  try {
    const response = yield {
      CREATE: () => req.post('/v1/masterdata/options', data),
      EDIT: () => req.put(`/v1/masterdata/options/${data.id}`, data),
      GET_ALL: () => req.get(`/v1/masterdata/options?itemList=${listId}`),
      DELETE: () => req.delete(`/v1/masterdata/options/${data.id}`)
    }[mode]();

    switch (response.status) {
      case 204:
      case 200:
        yield put({
          type: `CUSTOM:ITEM_LIST_OPTION:${mode}:SUCCESS`,
          data: mode === 'DELETE' ? data : response.body,
          layoutFieldUniqueId
        });

        if (mode !== 'GET_ALL') {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: {
                CREATE: tenantConfigurationTranslations.picklistOptionCreated,
                EDIT: tenantConfigurationTranslations.picklistOptionUpdated,
                DELETE: tenantConfigurationTranslations.picklistOptionDeleted
              }[mode],
              type: 'success'
            }
          });
        }
        break;
      default:
        yield put({
          type: `CUSTOM:ITEM_LIST_OPTION:${mode}:FAIL`,
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterdata');
  }
}
