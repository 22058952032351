import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import {
  singularTerms,
  singularTerms as uppercaseSingular
} from '@packages/utils/uppercaseTranslations';
import {
  editTranslations,
  removeTranslations
} from '@packages/utils/actionTranslations';
import vendorTranslations from '@packages/utils/vendorTranslations';

export default {
  dsrRegistration: (
    <FormattedMessage
      id="DSR.dsrRegistration"
      defaultMessage="DSR registration"
    />
  ),
  dsrForms: <FormattedMessage id="DSR.dsrForms" defaultMessage="DSR forms" />,
  dsrFormsHeader: (
    <FormattedMessage
      id="DSR.dsrFormsHeader"
      defaultMessage="Data subject request forms"
    />
  ),
  readPolicy: (
    <FormattedMessage
      id="DSR.readPolicy"
      defaultMessage="Read our privacy policy"
    />
  ),
  emailId: uppercaseSingular('email'),
  invalidFormMessage: (
    <FormattedMessage
      id="DSR.invalidFormMessage"
      defaultMessage="The data subject request form you are trying to access is either inactive or invalid."
    />
  ),
  organisation: uppercaseSingular('organisation'),
  description: uppercaseSingular('description'),
  duplicate: <FormattedMessage id="DSR.duplicate" defaultMessage="Duplicate" />,
  addForm: <FormattedMessage id="DSR.addForm" defaultMessage="Add new form" />,
  publicFormHint: (
    <FormattedMessage
      id="DSR.publicFormHint"
      defaultMessage="You are about to submit a data subject request for the organisation. Please fill in the information
    below and we will get back to you as soon as possible."
    />
  ),
  dataSubjectHeader: (
    <FormattedMessage id="DSR.dataSubjectHeader" defaultMessage="I am a:" />
  ),
  requestType: (
    <FormattedMessage
      id="DSR.requestType"
      defaultMessage="I would like to request:"
    />
  ),
  validateRequest: (
    <FormattedMessage
      id="DSR.validateRequest"
      defaultMessage="Validate request"
    />
  ),
  Save: <FormattedMessage id="DSR.Save" defaultMessage="Save" />,
  overview: (
    <FormattedMessage id="DSR.overview" defaultMessage="DSR Overview" />
  ),
  phone: <FormattedMessage id="DSR.phone" defaultMessage="Phone" />,
  registration: (
    <FormattedMessage id="DSR.registration" defaultMessage="Registration" />
  ),
  searchRequester: (
    <FormattedMessage
      id="DSR.searchRequester"
      defaultMessage="Search or add a requester"
    />
  ),
  requestInfo: (
    <FormattedMessage id="DSR.requestInfo" defaultMessage="Request info" />
  ),
  dataSubjectInfo: (
    <FormattedMessage
      id="DSR.dataSubjectInfo"
      defaultMessage="Data subject information"
    />
  ),
  requestInfoHintText: (
    <FormattedMessage
      id="DSR.requestInfoHintText"
      defaultMessage="Provide some basic information about the request."
    />
  ),
  dataSubjectCategories: (
    <FormattedMessage
      id="DSR.categories"
      defaultMessage="Requester categories"
    />
  ),
  dataSubjectCategoriesHint: (
    <FormattedMessage
      id="DSR.dataSubjectCategoriesHint"
      defaultMessage="Select requester categories"
    />
  ),
  dataSubjectCategoriesHelpNote: (
    <FormattedMessage
      id="DSR.categoriesHelpNote"
      defaultMessage="Requester categories help note"
    />
  ),
  requesterVerification: (
    <FormattedMessage
      id="DSR.requesterVerification"
      defaultMessage="Requester verification"
    />
  ),
  requesterVerificationHint: (
    <FormattedMessage
      id="DSR.requesterVerificationHint"
      defaultMessage="You need to verify the user to proceed with actions in the third step"
    />
  ),
  responsibleAssignee: (
    <FormattedMessage
      id="DSR.responsibleAssignee"
      defaultMessage="Responsible and  assignees"
    />
  ),
  responsibleAssigneeHint: (
    <FormattedMessage
      id="DSR.responsibleAssigneeHint"
      defaultMessage="In this step you can specify which organisation is responsible for the request and who
    should be assigned to the request. You can also select people who should approve the final result."
    />
  ),
  dataSubjectRequests: (
    <FormattedMessage
      id="DSR.dataSubjectRequests"
      defaultMessage="Data subject requests"
    />
  ),
  additionalInfo: (
    <FormattedMessage
      id="DSR.addtlnNote"
      defaultMessage="Additional information"
    />
  ),
  createDSR: (
    <FormattedMessage id="DSR.createDSR" defaultMessage="Create DSR" />
  ),
  bulkDeleteDSR: (
    <FormattedMessage
      id="DSR.bulkDeleteDSR"
      defaultMessage="Bulk delete DSRs"
    />
  ),
  requirements: (
    <FormattedMessage id="DSR.requirements" defaultMessage="Requirements" />
  ),
  exceptions: (
    <FormattedMessage id="DSR.exceptions" defaultMessage="Exceptions" />
  ),
  requesterDetails: (
    <FormattedMessage
      id="DSR.requesterDetails"
      defaultMessage="Requester details"
    />
  ),
  requesters: (
    <FormattedMessage id="DSR.requesters" defaultMessage="Requesters" />
  ),
  addRequester: (
    <FormattedMessage id="DSR.addRequester" defaultMessage="Add requester" />
  ),
  deleteRequesters: (
    <FormattedMessage
      id="DSR.deleteRequesters"
      defaultMessage="Delete requesters"
    />
  ),
  deleteRequester: (
    <FormattedMessage
      id="DSR.deleteRequester"
      defaultMessage="Delete requester"
    />
  ),
  requesterId: (
    <FormattedMessage id="DSR.requesterId" defaultMessage="Requester ID" />
  ),
  city: commonTranslations.city,
  numberOfRequests: (
    <FormattedMessage
      id="DSR.numberOfRequests"
      defaultMessage="Number of requests"
    />
  ),
  settings: (
    <FormattedMessage id="DSR.settings" defaultMessage="DSR settings" />
  ),
  deadline: <FormattedMessage id="DSR.deadline" defaultMessage="Deadline" />,
  deadlineDays: (
    <FormattedMessage
      id="DSR.deadlineDays"
      defaultMessage="Days until deadline"
    />
  ),
  dataRetention: (
    <FormattedMessage id="DSR.dataRetention" defaultMessage="Data retention" />
  ),
  anonymisation: (
    <FormattedMessage id="DSR.anonymisation" defaultMessage="Anonymisation" />
  ),
  anonymise: <FormattedMessage id="DSR.anonymise" defaultMessage="Anonymise" />,
  unAnonymise: <FormattedMessage id="DSR.unMask" defaultMessage="Unmask" />,
  unAnonymised: (
    <FormattedMessage
      id="DSR.unMasked"
      defaultMessage="Requester data have been unmasked"
    />
  ),
  edit: editTranslations('edit'),
  delete: removeTranslations('delete'),
  maskData: <FormattedMessage id="DSR.maskData" defaultMessage="Mask data" />,
  generalInfo: (
    <FormattedMessage
      id="DSR.generalInfo"
      defaultMessage="General information"
    />
  ),
  contactInfo: (
    <FormattedMessage id="DSR.contactInfo" defaultMessage="Contact info" />
  ),
  systemInfo: (
    <FormattedMessage id="DSR.systemInfo" defaultMessage="System info" />
  ),
  optionalAddress: (
    <FormattedMessage
      id="DSR.optionalAddress"
      defaultMessage="Address (optional)"
    />
  ),
  number: <FormattedMessage id="DSR.number" defaultMessage="Number" />,
  requests: <FormattedMessage id="DSR.requests" defaultMessage="Requests" />,
  additionalDetails: (
    <FormattedMessage
      id="DSR.additionalInfo"
      defaultMessage="Info of the requester"
    />
  ),
  emailAddress: commonTranslations.emailAddress,
  creationDate: (
    <FormattedMessage id="DSR.creationDate" defaultMessage="Creation date" />
  ),
  postCode: commonTranslations.postCode,
  bulkDeleteConfirm: (
    <FormattedMessage
      id="DSR.bulkDeleteConfirm"
      defaultMessage="Do you want to delete selected requesters?"
    />
  ),
  deleteConfirm: (
    <FormattedMessage
      id="DSR.deleteConfirm"
      defaultMessage="The requester will be deleted, but the DSR records will remain and will be anonymised.
    Do you want to proceed?"
    />
  ),
  formDeleteConfirm: (
    <FormattedMessage
      id="DSR.formDeleteConfirm"
      defaultMessage="The data subject request form will be deleted. Do you want to proceed?"
    />
  ),
  formDeleted: (
    <FormattedMessage
      id="Notification.formDeleted"
      defaultMessage="Data subject request form deleted successfully."
    />
  ),
  bulkDeleteDSRConfirm: (
    <FormattedMessage
      id="DSR.bulkDeleteDSRConfirm"
      defaultMessage="Do you want to delete selected DSRs?"
    />
  ),
  dsrDeleted: (
    <FormattedMessage
      id="Notification.dsrDeleted"
      defaultMessage="DSR deleted successfully."
    />
  ),
  requesterDeleted: (
    <FormattedMessage
      id="Notification.requesterDeleted"
      defaultMessage="Requester deleted successfully."
    />
  ),
  BulkDeleteSuccess: (
    <FormattedMessage
      id="MessageCenter.BulkDeleteSuccess"
      defaultMessage="Selected items has been successfully deleted"
    />
  ),
  requesterAddTitle: (
    <FormattedMessage
      id="DSR.createRequester"
      defaultMessage="Create requester"
    />
  ),
  requesterEditTitle: (
    <FormattedMessage id="DSR.editRequester" defaultMessage="Edit requester" />
  ),
  requesterUpdateSuccess: (
    <FormattedMessage
      id="Notification.updateRequester"
      defaultMessage="Requester updated successfully."
    />
  ),
  requesterCreated: (
    <FormattedMessage
      id="Notification.requesterCreated"
      defaultMessage="Requester created successfully."
    />
  ),
  dataSubjectRequestNumber: (
    <FormattedMessage id="DSR.id" defaultMessage="DSR ID" />
  ),
  requestTypes: (
    <FormattedMessage id="DSR.requestTypes" defaultMessage="Request types" />
  ),
  requestTypeHelpNote: (
    <FormattedMessage
      id="DSR.requestTypes.helpNote"
      defaultMessage="Request types help note"
    />
  ),
  assignedOrganisations: (
    <FormattedMessage
      id="DSR.assignedOrganisations"
      defaultMessage="Internal organisations"
    />
  ),
  status: uppercaseSingular('status'),
  search: uppercaseSingular('search'),
  name: uppercaseSingular('name'),
  assignee: <FormattedMessage id="DSR.dataSubject" defaultMessage="Assignee" />,
  requestDate: (
    <FormattedMessage id="DSR.requestDate" defaultMessage="Request date" />
  ),
  deadlineDate: (
    <FormattedMessage id="DSR.deadlineDate" defaultMessage="Deadline date" />
  ),
  identificationDetails: (
    <FormattedMessage
      id="DSR.identificationDetails"
      defaultMessage="Identification details"
    />
  ),
  Erasure: <FormattedMessage id="DSR.Erasure" defaultMessage="Erasure" />,
  DataPortability: (
    <FormattedMessage
      id="masterData.dataPortabilityHeader"
      defaultMessage="Data portability"
    />
  ),
  Access: <FormattedMessage id="DSR.Access" defaultMessage="Access" />,
  Restriction: (
    <FormattedMessage id="DSR.Restriction" defaultMessage="Restriction" />
  ),
  Objection: <FormattedMessage id="DSR.Objection" defaultMessage="Objection" />,
  AutomatedDecisionMaking: (
    <FormattedMessage
      id="DSR.automatedDecisionMaking"
      defaultMessage="Automated decision making"
    />
  ),
  Rectification: (
    <FormattedMessage id="DSR.Rectification" defaultMessage="Rectification" />
  ),
  erasure: <FormattedMessage id="DSR.erasure" defaultMessage="erasure" />,
  dataportability: (
    <FormattedMessage
      id="DSR.dataportability"
      defaultMessage="data portability"
    />
  ),
  access: <FormattedMessage id="DSR.access" defaultMessage="access" />,
  restriction: (
    <FormattedMessage id="DSR.restriction" defaultMessage="restriction" />
  ),
  objection: <FormattedMessage id="DSR.objection" defaultMessage="objection" />,
  automateddecisionmaking: (
    <FormattedMessage
      id="DSR.automateddecisionmaking"
      defaultMessage="automated decision making"
    />
  ),
  rectification: (
    <FormattedMessage id="DSR.rectification" defaultMessage="rectification" />
  ),
  requestAction: (
    <FormattedMessage id="DSR.requestAction" defaultMessage="Request action" />
  ),
  comment: <FormattedMessage id="DSR.comment" defaultMessage="Comments" />,
  dsrId: <FormattedMessage id="DSR.dsrId" defaultMessage="DSR ID" />,
  requestId: (
    <FormattedMessage id="DSR.requestId" defaultMessage="Request ID" />
  ),
  createdDate: (
    <FormattedMessage id="DSR.createdDate" defaultMessage="Created Date" />
  ),
  assignedTo: (
    <FormattedMessage id="DSR.assignedTo" defaultMessage="Assigned To" />
  ),
  approvalBy: (
    <FormattedMessage id="DSR.approvalBy" defaultMessage="Approval By" />
  ),
  identified: (
    <FormattedMessage id="DSR.identified" defaultMessage="Identified" />
  ),
  validation: (
    <FormattedMessage id="DSR.validation" defaultMessage="Validation" />
  ),
  deadLineDate: (
    <FormattedMessage id="DSR.deadLineDate" defaultMessage="Deadline date" />
  ),
  deadLineExtension: (
    <FormattedMessage
      id="DSR.deadLineExtension"
      defaultMessage="Deadline extension"
    />
  ),
  deadLineExtensionDate: (
    <FormattedMessage
      id="DSR.deadLineExtensionDate"
      defaultMessage="Deadline extension date"
    />
  ),
  extensionDate: (
    <FormattedMessage id="DSR.extensionDate" defaultMessage="Extension Date" />
  ),
  actions: <FormattedMessage id="DSR.actions" defaultMessage="Actions" />,
  approval: <FormattedMessage id="DSR.approval" defaultMessage="Approval" />,
  requesterInfo: (
    <FormattedMessage
      id="DSR.requesterInfo"
      defaultMessage="Requester information"
    />
  ),
  finalComments: (
    <FormattedMessage id="DSR.finalComments" defaultMessage="Final Comments" />
  ),
  summary: <FormattedMessage id="DSR.summary" defaultMessage="Summary" />,
  approvedOn: (
    <FormattedMessage id="DSR.approvedOn" defaultMessage="Approved on " />
  ),
  lastChangeOn: (
    <FormattedMessage id="DSR.lastChangeOn" defaultMessage="Last change on " />
  ),
  lastChangedOn: (
    <FormattedMessage id="DSR.lastChangedOn" defaultMessage="Last changed on" />
  ),
  permission: (
    <FormattedMessage
      id="Legalground.permissions"
      defaultMessage="Permissions"
    />
  ),
  requestStatus: (
    <FormattedMessage id="DSR.requestStatus" defaultMessage="Request Status" />
  ),
  request: <FormattedMessage id="DSR.request" defaultMessage="Request" />,
  requesterName: (
    <FormattedMessage id="DSR.requesterName" defaultMessage="Requester Name" />
  ),
  requesterCategories: (
    <FormattedMessage
      id="DSR.requesterCategories"
      defaultMessage="Categories"
    />
  ),
  verification: (
    <FormattedMessage id="DSR.verification" defaultMessage="Verification" />
  ),
  cancelRequest: (
    <FormattedMessage
      id="DSR.cancelRequest"
      defaultMessage="Cancel the request"
    />
  ),
  cancelRequestHeader: (
    <FormattedMessage
      id="DSR.cancelRequestHeader"
      defaultMessage="Are you sure you want to cancel the request? Why?"
    />
  ),
  cancelRequestlabel: (
    <FormattedMessage
      id="DSR.cancelRequestlabel"
      defaultMessage="You can't undo this action"
    />
  ),
  cancelRequestValue: (
    <FormattedMessage
      id="DSR.cancelRequestValue"
      defaultMessage="Please add a comment here"
    />
  ),
  sidebarOverview: (
    <FormattedMessage id="DSR.sidebarOverview" defaultMessage="Overview" />
  ),
  requestTypesHint: (
    <FormattedMessage
      id="DSR.requestTypesHint"
      defaultMessage="Select request types"
    />
  ),
  requestFormat: (
    <FormattedMessage id="DSR.requestFormat" defaultMessage="Request format" />
  ),
  requestFormatHelpNote: (
    <FormattedMessage
      id="DSR.requestFormatHelpNote"
      defaultMessage="Request format help note"
    />
  ),
  requestFormatHint: (
    <FormattedMessage
      id="DSR.requestFormatHint"
      defaultMessage="Select request format"
    />
  ),
  email: commonTranslations.emailLabel,
  phoneNumber: uppercaseSingular('phonenumber'),
  yes: commonTranslations.Yes,
  no: commonTranslations.No,
  identityVerification: (
    <FormattedMessage
      id="DSR.identityVerification"
      defaultMessage="Verify identity"
    />
  ),
  hasIdentityVerified: (
    <FormattedMessage
      id="DSR.hasIdentityVerified"
      defaultMessage="Has the requester been identified ?"
    />
  ),
  howIdentityVerified: (
    <FormattedMessage
      id="DSR.howIdentityVerified"
      defaultMessage="How has the requester been identified?"
    />
  ),
  identityVerificationHelpNote: (
    <FormattedMessage
      id="DSR.identityVerificationHelpNote"
      defaultMessage="Identity verification help note"
    />
  ),
  yesOrNo: <FormattedMessage id="DSR.yesOrNo" defaultMessage="Yes/No" />,
  dsr_verification_method_birth_date: (
    <FormattedMessage id="DSR.postCode" defaultMessage="Post code" />
  ),
  dsr_verification_method_post_code: (
    <FormattedMessage id="DSR.birthDay" defaultMessage="Birthday" />
  ),
  additionalInfoHint: (
    <FormattedMessage
      id="DSR.additionalInfoHint"
      defaultMessage="Add additional information on identification"
    />
  ),
  firstName: commonTranslations.firstname,
  lastName: commonTranslations.lastName,
  address: commonTranslations.address,
  country: commonTranslations.countryName,
  dataSubject: commonTranslations.dataSubject,
  priority: <FormattedMessage id="DSR.priority" defaultMessage="Priority" />,
  requesterIDHint: (
    <FormattedMessage id="DSR.requesterId" defaultMessage="Requester ID" />
  ),
  requester: <FormattedMessage id="DSR.requester" defaultMessage="Requester" />,
  additionalInformation: (
    <FormattedMessage
      id="DSR.additionalInformation"
      defaultMessage="Additional information on the requester"
    />
  ),
  numberOfPreviousRequests: (
    <FormattedMessage
      id="DSR.numberofPrevReq"
      defaultMessage="Previous requests"
    />
  ),
  dsrID: <FormattedMessage id="DSR.dsrID" defaultMessage="DSR ID" />,
  post: <FormattedMessage id="DSR.post" defaultMessage="Regular Post" />,
  inPerson: <FormattedMessage id="DSR.inPerson" defaultMessage="In Person" />,
  fax: <FormattedMessage id="DSR.fax" defaultMessage="Fax" />,
  openSummaryPanel: (
    <FormattedMessage
      id="DSR.openSummaryPanel"
      defaultMessage="Open Summary Panel"
    />
  ),
  collapseSummaryPanel: (
    <FormattedMessage
      id="DSR.collapseSummaryPanel"
      defaultMessage="Collapse Summary Panel"
    />
  ),
  summaryPanel: (
    <FormattedMessage id="DSR.summaryPanel" defaultMessage="Summary panel" />
  ),
  assignedOrganisation: (
    <FormattedMessage
      id="DSR.assignedOrganisations"
      defaultMessage="What are the responsible organisation entities?"
    />
  ),
  assignedOrganisationHelpNote: (
    <FormattedMessage
      id="DSR.assignedOrganisationHelpNote"
      defaultMessage="Assigned Organisation HelpNote"
    />
  ),
  selectOrganisation: (
    <FormattedMessage
      id="DSR.selectOrganisation"
      defaultMessage="Select organisation"
    />
  ),
  organisationHintText: (
    <FormattedMessage
      id="DSR.organisationHintText"
      defaultMessage="Select organisation entity"
    />
  ),
  assignedUser: (
    <FormattedMessage
      id="DSR.assignedUser"
      defaultMessage="Who/which user is the assignee?"
    />
  ),
  assignedUserHelpNote: (
    <FormattedMessage
      id="DSR.assignedUserHelpNote"
      defaultMessage="Assigned User HelpNote"
    />
  ),
  approvers: (
    <FormattedMessage
      id="DSR.approvers"
      defaultMessage="Who should approve the data subject request?"
    />
  ),
  approversHelpNote: (
    <FormattedMessage
      id="DSR.approversHelpNote"
      defaultMessage="Approvers HelpNote"
    />
  ),
  erasure_req_data_not_necessary: (
    <FormattedMessage
      id="DSR.erasure_req_data_not_necessary"
      defaultMessage="The personal data are no longer necessary for the purposes for which they were processed"
    />
  ),
  erasure_req_withdraws_consent: (
    <FormattedMessage
      id="DSR.erasure_req_withdraws_consent"
      defaultMessage="The individual has withdrawn (explicit) consent, and no other legal ground for processing
    is available"
    />
  ),
  erasure_req_objects_on_legal_ground: (
    <FormattedMessage
      id="DSR.erasure_req_objects_on_legal_ground"
      defaultMessage="The individual objects to a processing on the legal grounds of performance of a public task or
    legitimate interests of the controller, and there are no overriding legitimate grounds for the processing"
    />
  ),
  erasure_requirement_direct_marketing: (
    <FormattedMessage
      id="DSR.dsr_erasure_requirement_direct_marketing"
      defaultMessage="The individual objects to a processing for the purpose of direct marketing"
    />
  ),
  erasure_req_unlawful_data_processing: (
    <FormattedMessage
      id="DSR.erasure_req_unlawful_data_processing"
      defaultMessage="The personal data have been processed unlawfully (e.g. because there was no legal
      ground for processing)"
    />
  ),
  erasure_req_legal_obligation_for_erasure: (
    <FormattedMessage
      id="DSR.erasure_req_legal_obligation_for_erasure"
      defaultMessage="The personal data have to be erased for compliance with a legal obligation"
    />
  ),
  erasure_req_child_data_without_parent_consent: (
    <FormattedMessage
      id="DSR.erasure_req_child_data_without_parent_consent"
      defaultMessage="The personal data of a child have been collected on the basis of consent in relation to information
    society services and, over time, the individual wants to remove such personal data"
    />
  ),
  dataportability_req_knowingly_actively_supplied_data: (
    <FormattedMessage
      id="DSR.dataportability_req_knowingly_actively_supplied_data"
      defaultMessage="The personal data is supplied by the individual actively and knowingly or
      observed through their use of services or devices (this does not include derived data created
      by the controller on the basis of this personal data, like an analysis or scoring), and"
    />
  ),
  dataportability_req_consent_or_contract_legal_ground: (
    <FormattedMessage
      id="DSR.dataportability_req_consent_or_contract_legal_ground"
      defaultMessage="The legal ground for the processing is consent or contract, and"
    />
  ),
  dataportability_req_automated_processing: (
    <FormattedMessage
      id="DSR.dataportability_req_automated_processing"
      defaultMessage="There is a legal obligation to erase the personal dataIs the processing carried
      out by automated means? The processing is carried out by automated means"
    />
  ),
  access_requirement_personal_data_processed: (
    <FormattedMessage
      id="DSR.access_requirement_personal_data_processed"
      defaultMessage="Whether personal data concerning the individual is being processed"
    />
  ),
  access_requirement_access_to_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_access_to_personal_data"
      defaultMessage="Access to the personal data concerning  the individual"
    />
  ),
  access_requirement_copy_of_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_copy_of_personal_data"
      defaultMessage="A copy of the personal data concerning the individual"
    />
  ),
  access_requirement_purpose_of_processing: (
    <FormattedMessage
      id="DSR.access_requirement_purpose_of_processing"
      defaultMessage="The purpose of processing"
    />
  ),
  access_requirement_categories_of_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_categories_of_personal_data"
      defaultMessage="The categories of personal data"
    />
  ),
  access_requirement_recipient_categories_of_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_recipient_categories_of_personal_data"
      defaultMessage="The recipients or categories of recipient of the personal data"
    />
  ),
  access_requirement_retention_period_of_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_retention_period_of_personal_data"
      defaultMessage="The retention period of the personal data"
    />
  ),
  access_requirement_rectification_erasure_restriction_objection: (
    <FormattedMessage
      id="DSR.access_requirement_rectification_erasure_restriction_objection"
      defaultMessage="The existence of the right of rectification, erasure, restriction and objection"
    />
  ),
  access_requirement_complaint_to_supervisory_authority: (
    <FormattedMessage
      id="DSR.access_requirement_complaint_to_supervisory_authority"
      defaultMessage="The right to lodge a complaint with a supervisory authority"
    />
  ),
  access_requirement_source_of_personal_data: (
    <FormattedMessage
      id="DSR.access_requirement_source_of_personal_data"
      defaultMessage="The source of the personal data when this is not the individual"
    />
  ),
  access_requirement_automated_decision_making_and_profiling: (
    <FormattedMessage
      id="DSR.access_requirement_automated_decision_making_and_profiling"
      defaultMessage="The existence of automated decision-making, including profiling and meaningful information
    about the logic involved, as well as the significance and the envisaged consequences for the individual"
    />
  ),
  access_requirement_transfer_outside_eea: (
    <FormattedMessage
      id="DSR.access_requirement_transfer_outside_eea"
      defaultMessage="The safeguards relating to the transfer of the personal data outside of the EEA or to an
    international organisation (e.g. the UN)"
    />
  ),
  restriction_req_personal_data_accuracy_verified: (
    <FormattedMessage
      id="DSR.restriction_req_personal_data_accuracy_verified"
      defaultMessage="The accuracy of the personal data is contested and
    their accuracy has to be verified"
    />
  ),
  restriction_req_unlawful_personal_data_processing: (
    <FormattedMessage
      id="DSR.restriction_req_unlawful_personal_data_processing"
      defaultMessage="The personal data have been processed unlawfully, and the individual requests the restriction of
    their processing instead of their erasure"
    />
  ),
  restriction_req_restrict_personal_data_for_legal_claim: (
    <FormattedMessage
      id="DSR.restriction_req_restrict_personal_data_for_legal_claim"
      defaultMessage="The personal data are no longer necessary for the purposes for which they were processed, but the
    individual needs them for handling a legal claim"
    />
  ),
  restriction_req_object_to_processing_on_legal_grounds: (
    <FormattedMessage
      id="DSR.restriction_req_object_to_processing_on_legal_grounds"
      defaultMessage="The individual has objected to processing based on the legal grounds of performance of a public
    task or legitimate interests of the controller pending the verification whether the legitimate grounds of the
    controller override those of the individual"
    />
  ),
  objection_req_direct_marketing: (
    <FormattedMessage
      id="DSR.objection_req_direct_marketing"
      defaultMessage="On grounds relating to his or her particular situation, the individual objects to the processing
    of personal data concerning him or her based on the legal grounds of performance of a public task or legitimate
    interests of the controller"
    />
  ),
  objection_req_public_task_or_official_authority: (
    <FormattedMessage
      id="DSR.objection_req_public_task_or_official_authority"
      defaultMessage="The individual objects to processing of personal data concerning him or her for
    direct marketing purposes"
    />
  ),
  objection_req_legitimate_interest_legal_ground: (
    <FormattedMessage
      id="DSR.objection_req_legitimate_interest_legal_ground"
      defaultMessage="The legal ground for the processing is legitimate interests"
    />
  ),
  objection_req_archiving_research_statistics_legal_ground: (
    <FormattedMessage
      id="DSR.objection_req_archiving_research_statistics_legal_ground"
      defaultMessage="The individual objects to processing of personal data concerning him or her for scientific
    or historical research or for statistical purposes on grounds relating to his or her particular situation"
    />
  ),
  automated_decision_requirement_asks_controller_explanation: (
    <FormattedMessage
      id="DSR.automated_decision_requirement_asks_controller_explanation"
      defaultMessage="The individual asks the controller to explain the reasons behind decisions made about him
    or her by automated processing and the possible consequences of the decisions"
    />
  ),
  automated_decision_requirement_requests_human_intervention: (
    <FormattedMessage
      id="DSR.automated_decision_requirement_requests_human_intervention"
      defaultMessage="The automated processing is based on the legal grounds of contract or explicit consent,
    and  the individual requests human intervention in respect of the decision based solely on the automated processing"
    />
  ),
  automated_decision_requirement_requests_to_express_their_view: (
    <FormattedMessage
      id="DSR.automated_decision_requirement_requests_to_express_their_view"
      defaultMessage="The automated processing is based on the legal grounds of contract or explicit consent, and
    the individual requests to express their view in respect of the decision based solely on the automated processing"
    />
  ),
  automated_decision_requirement_challenges_the_decision: (
    <FormattedMessage
      id="DSR.automated_decision_requirement_challenges_the_decision"
      defaultMessage="The automated processing is based on the legal grounds of contract or explicit consent, and the
    individual challenges the decision based solely on the automated processing"
    />
  ),
  rectification_requirement_personal_data_inaccurate: (
    <FormattedMessage
      id="DSR.rectification_requirement_personal_data_inaccurate"
      defaultMessage="The personal data are inaccurate"
    />
  ),
  rectification_requirement_personal_data_incomplete: (
    <FormattedMessage
      id="DSR.rectification_requirement_personal_data_incomplete"
      defaultMessage="The personal data are incomplete"
    />
  ),
  exc_harassing_organisation: (
    <FormattedMessage
      id="DSR.exc_harassing_organisation"
      defaultMessage="The individual clearly has no real intention to exercise their right, or
  the right is used to harass the organisation"
    />
  ),
  exc_repetition_of_previous_request: (
    <FormattedMessage
      id="DSR.exc_repetition_of_previous_request"
      defaultMessage="The request repeats the subject matter of previous requests and a reasonable
    interval has not passed"
    />
  ),
  restriction_exp_concerning_personal_data_storage: (
    <FormattedMessage
      id="DSR.restriction_exp_concerning_personal_data_storage"
      defaultMessage="The processing exclusively concerns storage of personal data concerning the individual"
    />
  ),
  erasure_exc_right_to_expression_freedom: (
    <FormattedMessage
      id="DSR.erasure_exc_right_to_expression_freedom"
      defaultMessage="The processing is necessary for exercising the right of freedom of expression and information"
    />
  ),
  erasure_exc_legal_obligation_compliance: (
    <FormattedMessage
      id="DSR.erasure_exc_legal_obligation_compliance"
      defaultMessage="The processing is necessary for compliance with a legal obligation"
    />
  ),
  erasure_exception_perform_public_task: (
    <FormattedMessage
      id="DSR.erasure_exception_perform_public_task"
      defaultMessage="The processing is necessary for performance of a public task"
    />
  ),
  erasure_exc_public_health_legal_ground: (
    <FormattedMessage
      id="DSR.erasure_exc_public_health_legal_ground"
      defaultMessage="The processing is necessary for reasons of public interest in the area of public health"
    />
  ),
  erasure_exc_archiving_research_statistics_legal_ground: (
    <FormattedMessage
      id="DSR.erasure_exc_archiving_research_statistics_legal_ground"
      defaultMessage=" The processing is necessary for archiving, research and statistics in the public interest and
    the request would seriously hinder achieving the goals of that processing"
    />
  ),
  erasure_exc_handling_legal_claims_legal_ground: (
    <FormattedMessage
      id="DSR.erasure_exc_handling_legal_claims_legal_ground"
      defaultMessage="The processing is necessary for handling of legal claims"
    />
  ),
  dataportability_exc_impact_the_rights_of_others: (
    <FormattedMessage
      id="DSR.dataportability_exc_impact_the_rights_of_others"
      defaultMessage="Granting the request would impact the rights and freedoms of others (including trade
      secrets and intellectual property)"
    />
  ),
  access_exc_impact_the_rights_of_others: (
    <FormattedMessage
      id="DSR.accessrights_exc_impact_the_rights_of_others"
      defaultMessage="Granting the request would adversely affect the rights and freedoms of others
    (including trade secrets and intellectual property rights)"
    />
  ),
  restriction_exc_already_consented: (
    <FormattedMessage
      id="DSR.restriction_exc_already_consented"
      defaultMessage="The individual has consented to the processing"
    />
  ),
  restriction_exc_handling_legal_claims_legal_ground: (
    <FormattedMessage
      id="DSR.restriction_exc_handling_legal_claims_legal_ground"
      defaultMessage="The processing is for the handling of legal claims"
    />
  ),
  restriction_exc_protection_of_others_right: (
    <FormattedMessage
      id="DSR.restriction_exc_protection_of_others_right"
      defaultMessage="The processing is necessary for the protection of the rights of another individual or organisation"
    />
  ),
  restriction_exception_public_interest_of_eu: (
    <FormattedMessage
      id="DSR.restriction_exception_public_interest_of_eu"
      defaultMessage="The processing is for reasons of important public interest of the EU or an EU member state"
    />
  ),
  objection_exc_legitimate_grounds_outweigh_freedom: (
    <FormattedMessage
      id="DSR.objection_exc_legitimate_grounds_outweigh_freedom"
      defaultMessage="If the first of the requirement boxes is ticked: there are compelling legitimate grounds for
    the processing which override the interests, rights and freedoms of the individual or which
    relate to the handling of legal claims"
    />
  ),
  objection_exception_necessary_for_public_interest_task: (
    <FormattedMessage
      id="DSR.objection_exception_necessary_for_public_interest_task"
      defaultMessage="If the third of the requirement boxes is ticked: if the processing is necessary
    for a task carried out in the public interest"
    />
  ),
  other: (
    <FormattedMessage
      id="DSR.other"
      defaultMessage="Other, namely  [e.g. an exception based on EU member state legislation]"
    />
  ),
  erasureRequirementsSubheader: (
    <FormattedMessage
      id="DSR.Erasure.Requirement.Subheader"
      defaultMessage="The controller has to erase the personal data of an individual and communicate this to each
    recipient to whom the personal data have been disclosed when:"
    />
  ),
  erasureExceptionsSubheader: (
    <FormattedMessage
      id="DSR.Erasure.Exception.Subheader"
      defaultMessage="The controller can refuse to comply with the request when:"
    />
  ),
  ErasureReview: (
    <FormattedMessage
      id="DSR.erasureReview"
      defaultMessage="Erasure request review"
    />
  ),
  DataPortabilityReview: (
    <FormattedMessage
      id="DSR.dataPortabilityReview"
      defaultMessage="Data portability request review"
    />
  ),
  AccessReview: (
    <FormattedMessage
      id="DSR.accessRightReview"
      defaultMessage="Access request review"
    />
  ),
  RestrictionReview: (
    <FormattedMessage
      id="DSR.restriction"
      defaultMessage="Restriction request review"
    />
  ),
  ObjectionReview: (
    <FormattedMessage
      id="DSR.objectionReview"
      defaultMessage="Objection request review"
    />
  ),
  AutomatedDecisionMakingReview: (
    <FormattedMessage
      id="DSR.automatedDecisionMakingReview"
      defaultMessage="Automated decision making request review"
    />
  ),
  RectificationReview: (
    <FormattedMessage
      id="DSR.rectificationReview"
      defaultMessage="Rectification request review"
    />
  ),
  reviewMessage: (
    <FormattedMessage
      id="DSR.approvalMessage"
      defaultMessage="All of the above mentioned review items have been carried out accordingly."
    />
  ),
  requesterTitle: (
    <FormattedMessage
      id="DSR.requesterTitle"
      defaultMessage="Has the requester been informed about the result?"
    />
  ),
  approvalTitle: (
    <FormattedMessage
      id="DSR.approvalTitle"
      defaultMessage="All requests have been carried out accordingly, but the approval is needed. Slide to approve"
    />
  ),
  finalCommentTitle: (
    <FormattedMessage
      id="DSR.finalCommentTitle"
      defaultMessage="Additional information (optional)"
    />
  ),
  approvedBy: (
    <FormattedMessage id="DSR.approvedBy" defaultMessage="Approved by:" />
  ),
  dateApproved: (
    <FormattedMessage id="DSR.dateApproved" defaultMessage="Date approved:" />
  ),
  dsr_status_in_progress: (
    <FormattedMessage id="DSR.status.inProgress" defaultMessage="In progress" />
  ),
  dsr_status_created: (
    <FormattedMessage id="DSR.status.created" defaultMessage="Created" />
  ),
  dsr_status_completed: (
    <FormattedMessage id="DSR.status.completed" defaultMessage="Completed" />
  ),
  dsr_status_waiting_on_action: (
    <FormattedMessage
      id="DSR.status.waitingOnAction"
      defaultMessage="Waiting on action"
    />
  ),
  dsr_status_waiting_on_approval: (
    <FormattedMessage
      id="DSR.status.waitingOnApproval"
      defaultMessage="Waiting on approval"
    />
  ),
  dsr_status_waiting_on_cancel_approval: (
    <FormattedMessage
      id="DSR.status.WaitingOnCancelApproval"
      defaultMessage="Waiting on cancel approval"
    />
  ),
  dsr_status_cancelled: (
    <FormattedMessage id="DSR.status.cancelled" defaultMessage="Cancelled" />
  ),
  dsr_status_waiting_requester_to_be_informed_for_complete: (
    <FormattedMessage
      id="DSR.status.waitingOnRequesterInformation"
      defaultMessage="Waiting on requester to be informed"
    />
  ),
  progress: <FormattedMessage id="DSR.progress" defaultMessage="Progress" />,
  dateExpired: (
    <FormattedMessage id="DSR.dateExpired" defaultMessage="Date expired" />
  ),
  retentionExpired: (
    <FormattedMessage
      id="DSR.retentionExpired"
      defaultMessage="Retention period expired"
    />
  ),
  dueDate: <FormattedMessage id="DSR.dueDate" defaultMessage="Due date" />,
  actionCompleted: (
    <FormattedMessage
      id="DSR.actionCompleted"
      defaultMessage="Action completed"
    />
  ),
  assignUser: (
    <FormattedMessage id="DSR.assignUser" defaultMessage="Assign user" />
  ),
  closeAction: (
    <FormattedMessage id="DSR.closeAction" defaultMessage="Close action" />
  ),
  sendMessage: (
    <FormattedMessage id="DSR.sendMessage" defaultMessage="Send message" />
  ),
  unsavedData: (
    <FormattedMessage
      id="DSR.unsavedData"
      defaultMessage="Addition of another user is allowed only after completing the current action"
    />
  ),
  uploadingAttachment: (
    <FormattedMessage
      id="DSR.uploadingAttachment"
      defaultMessage="Creation of CSV and uploading it is in progress..."
    />
  ),
  actionsSubheader: (
    <FormattedMessage
      id="DSR.actionsSubheader"
      defaultMessage="Send tasks containing summary and metadata to the users involved"
    />
  ),
  identificationExplanation: (
    <FormattedMessage
      id="DSR.identificationExplanation"
      defaultMessage="Specify how the requester has been identified"
    />
  ),
  dsr_verification_method_by_login: (
    <FormattedMessage
      id="DSR.dsr_verification_method_by_login"
      defaultMessage="By logging into our servicces"
    />
  ),
  dsr_verification_method_mfa: (
    <FormattedMessage
      id="Settings.mfa"
      defaultMessage="Multi Factor Authentication"
    />
  ),
  dsr_verification_method_showing_id: (
    <FormattedMessage
      id="DSR.dsr_verification_method_showing_id"
      defaultMessage="Physically showing their ID without making a copy"
    />
  ),
  deleteNote: recordTranslations.deleteNote,
  processingsIdentified: (
    <FormattedMessage
      id="DSR.processingsIdentified"
      defaultMessage="Processings identified"
    />
  ),
  requestNotJustified: (
    <FormattedMessage
      id="DSR.requestNotJustified"
      defaultMessage="You may choose not to proceed with the request. Please inform the individual of reasons for
    not taking action and on the possibility of lodging a complaint with a supervisory authority and seeking a
    judicial remedy."
    />
  ),
  requestNotJustifiedForObjection: (
    <FormattedMessage
      id="DSR.Objection.requestNotJustified"
      defaultMessage="You may choose not to proceed with (parts of) the request. Please inform the individual of reasons
    for not taking action and on the possibility of lodging a complaint with a supervisory authority and seeking a
    judicial remedy."
    />
  ),
  requestJustified: (
    <FormattedMessage
      id="DSR.requestJustified"
      defaultMessage="Please proceed with the request"
    />
  ),
  accessRequirement: (
    <FormattedMessage
      id="DSR.accessRightRequirement"
      defaultMessage="Upon the request of the individual, the controller has to confirm if personal data concerning an
    individual are processed and, where that is the case, provide access to these personal data and a copy. Which of
    the following information is requested?"
    />
  ),
  accessException: (
    <FormattedMessage
      id="DSR.accessRightException"
      defaultMessage="Under certain circumstances, the controller may refuse to comply with the request.
    Which of the following is applicable?"
    />
  ),
  erasureRequirement: (
    <FormattedMessage
      id="DSR.erasureRequirement"
      defaultMessage="Under certain circumstances, the controller has to erase the personal data concerning an individual
    and communicate this to each recipient to whom the personal data have been disclosed.
    Which of the following is applicable? "
    />
  ),
  dataPortabilityRequirement: (
    <FormattedMessage
      id="DSR.dataPortabilityRequirement"
      defaultMessage="Under certain circumstances, the controller has to supply the personal data concerning
    an individual in a structured, commonly used and machine-readable format to the individual, and must
    not hinder the transmission of those data to another controller. Which of the following is applicable? "
    />
  ),
  restrictionRequirement: (
    <FormattedMessage
      id="DSR.restrictionRequirement"
      defaultMessage="Under certain circumstances, the controller has to restrict the processing of the personal
    data concerning an individual and communicate this to each recipient to whom the personal data have been disclosed.
    Which of the following is applicable? "
    />
  ),
  objectionRequirement: (
    <FormattedMessage
      id="DSR.objectionRequirement"
      defaultMessage="Under certain circumstances, the controller has to cease the processing of personal data concerning
    an individual, if that individual has exercised his or her right to object.
    Which of the following is applicable?"
    />
  ),
  automationRequirement: (
    <FormattedMessage
      id="DSR.automationRequirement"
      defaultMessage="Upon the request of the individual, the controller has to comply with requests of the individual
    concerning decisions based solely on automated processing where the decision has a legal or similarly significant
    effect on the individual. Which of the following is applicable?"
    />
  ),
  rectificationRequirement: (
    <FormattedMessage
      id="DSR.rectificationRequirement"
      defaultMessage="Under certain circumstances, the controller has to rectify the personal data concerning an
    individual and communicate this to each recipient to whom the personal data have been disclosed.
    Which of the following is applicable?"
    />
  ),
  downloadMetaData: (
    <FormattedMessage
      id="DSR.downloadMetaData"
      defaultMessage="Download metadata"
    />
  ),
  identityVerified: (
    <FormattedMessage
      id="DSR.identityVerified"
      defaultMessage="Identity verified"
    />
  ),
  procssingIdentifiedHintText: (
    <FormattedMessage
      id="DSR.procssingIdentifiedHintText"
      defaultMessage="Processing identified based on requester categories"
    />
  ),
  completed: (
    <FormattedMessage id="DSR.status.completed" defaultMessage="Completed" />
  ),
  messageSent: (
    <FormattedMessage id="DSR.messageSent" defaultMessage="Message sent" />
  ),
  created: <FormattedMessage id="DSR.created" defaultMessage="Created" />,
  exportMetaData: (
    <FormattedMessage
      id="DSR.exportMetaData"
      defaultMessage="Download metadata"
    />
  ),
  Comment: commonTranslations.comment,
  commentedSuccess: (
    <FormattedMessage
      id="DSR.commentedSuccess"
      defaultMessage="Comment has been saved successfully"
    />
  ),
  commentedDeletedSuccess: (
    <FormattedMessage
      id="DSR.commentedDeletedSuccess"
      defaultMessage="Comment has been deleted successfully"
    />
  ),
  addComment: commonTranslations.addComment,
  assignedOrganisationInfo: (
    <FormattedMessage
      id="DSR.assignedOrganisationInfo"
      defaultMessage="These are organisations to which the request needs to be applied"
    />
  ),
  validationSubHeader: (
    <FormattedMessage
      id="DSR.validationSubHeader"
      defaultMessage="Please select requirements or/and exceptions to continue."
    />
  ),
  requestValidation: (
    <FormattedMessage
      id="DSR.requestValidation"
      defaultMessage="Request validation"
    />
  ),
  dsrExport: <FormattedMessage id="DSR.export" defaultMessage="Export DSRs" />,
  loaderContent: (
    <FormattedMessage
      id="MasterData.loader"
      defaultMessage="Please wait while your CSV file is being generated"
    />
  ),
  reOpen: <FormattedMessage id="DSR.reOpen" defaultMessage="Reopen" />,
  unMaskConfirm: (
    <FormattedMessage
      id="DSR.unMaskConfirm"
      defaultMessage="The requester information will be unmasked. Do you want to proceed?"
    />
  ),
  unsavedDataWarning: (
    <FormattedMessage
      id="DSR.unsavedDataWarning"
      defaultMessage="You have unsaved data in this page. Do you want to proceed ?"
    />
  ),
  reOpenCompletedDSR: (
    <FormattedMessage
      id="DSR.reOpenCompletedDSR"
      defaultMessage="You are about to reopen a completed DSR. Are you sure ?"
    />
  ),
  changeRequester: (
    <FormattedMessage
      id="DSR.changeRequester"
      defaultMessage="Changing the requester would clear the associated data subject categories of the request as well.
    Are you sure you want to continue?"
    />
  ),
  disableMessage: (
    <FormattedMessage
      id="DSR.disableMessage"
      defaultMessage="Action messages have been sent to the respective
    assignees and hence the details of the DSR cannot be edited"
    />
  ),
  disableCancelMessage: (
    <FormattedMessage
      id="DSR.disableCancelMessage"
      defaultMessage="This DSR is cancelled and
    hence the details cannot be edited"
    />
  ),
  unMaskDialog: (
    <FormattedMessage
      id="DSR.unMaskDialog"
      defaultMessage="Requester should be unmasked before viewing the details."
    />
  ),
  dataSubjectCategoryHelpNote: (
    <FormattedMessage
      id="ProcessingDetails.helpNotes.dataSubjectCategories"
      defaultMessage="Data subjects are the persons whose personal data (i.e. data that is informative about their
      life and identifies them) are being processed. Data subject categories are groups of similar data subjects.
      For instance, a company will have customers and employees. Customers and employees are both
      data subject categories."
    />
  ),
  numberOfRequestsHelpNote: (
    <FormattedMessage
      id="DSR.numberOfRequestsHelpNote"
      defaultMessage="Help note for number of requests"
    />
  ),
  descriptionHelpNote: (
    <FormattedMessage
      id="DSR.descriptionHelpNote"
      defaultMessage="Help note for additional info in requester dialog"
    />
  ),
  exceptionsHelpNote: (
    <FormattedMessage
      id="DSR.exceptionsHelpNote"
      defaultMessage="Help note for exceptions"
    />
  ),
  requirementsHelpNote: (
    <FormattedMessage
      id="DSR.requirementsHelpNote"
      defaultMessage="Help note for requirements"
    />
  ),
  inProgressToActionNotification: (
    <FormattedMessage
      id="DSR.inProgressToActionNotification"
      defaultMessage="Sending this action will set the status of this DSR record to ‘Waiting on actions’,
    and will lock the previous chapters for editing. Do you wish to proceed?"
    />
  ),
  confirmActionComplete: (
    <FormattedMessage
      id="DSR.confirmActionComplete"
      defaultMessage="Marking this action as Completed will set the status of this DSR record to ‘Waiting on approval’."
    />
  ),
  confirmApprovalToAction: (
    <FormattedMessage
      id="DSR.confirmApprovalToAction"
      defaultMessage="Creating this action will set the status of this DSR record to ‘Waiting on actions’."
    />
  ),
  extendedDeadlineDateHintText: (
    <FormattedMessage
      id="DSR.extendedDeadlineDateHintText"
      defaultMessage="The deadline may be extended by up to two further months where necessary, taking into
     account the complexity and number of the requests.
    Inform the requester of such an extension within one month of receipt of the request,
    together with the reasons for the delay"
    />
  ),
  deadlineHintText: (
    <FormattedMessage
      id="DSR.deadlineHintText"
      defaultMessage="Within the deadline, inform the requester about any action taken to
  comply with the request. When deciding not to comply with the request,
  inform the requester within the deadline of the reasons for not taking action and on the
  possibility of lodging a complaint with a supervisory authority and seeking a judicial remedy."
    />
  ),
  onlineChat: (
    <FormattedMessage id="DSR.onlineChat" defaultMessage="Online chat" />
  ),
  onlineForm: (
    <FormattedMessage id="DSR.onlineForm" defaultMessage="Online form" />
  ),
  deleteInProgressAction: (
    <FormattedMessage
      id="DSR.confirmDelete"
      defaultMessage="This action is in progress. Are you sure you want to continue?"
    />
  ),
  deleteCompletedAction: (
    <FormattedMessage
      id="DSR.deleteCompletedAction"
      defaultMessage="This action is completed. Are you sure you want to continue?"
    />
  ),
  editDSRForm: (
    <FormattedMessage
      id="DSR.editDSRForm"
      defaultMessage="Edit data subject request form"
    />
  ),
  responsibleOrganisation: (
    <FormattedMessage
      id="DSR.responsibleOrganisation"
      defaultMessage="Responsible organisation"
    />
  ),
  fieldsInfoText: (
    <FormattedMessage
      id="DSR.fieldsInfoText"
      defaultMessage="The following fields will be displayed in the form.
     Please configure the allowed data subjects and request types."
    />
  ),
  mandatoryFields: (
    <FormattedMessage
      id="DSR.mandatoryFields"
      defaultMessage="Mandatory fields."
    />
  ),
  additionalFields: (
    <FormattedMessage
      id="DSR.additionalFields"
      defaultMessage="Additional fields"
    />
  ),
  included: <FormattedMessage id="DSR.included" defaultMessage="Included" />,
  mandatory: <FormattedMessage id="DSR.mandatory" defaultMessage="Mandatory" />,
  responsibleUser: (
    <FormattedMessage
      id="DSR.responsibleUser"
      defaultMessage="Responsible users"
    />
  ),
  setDefaultAssignee: (
    <FormattedMessage
      id="DSR.setDefaultAssignee"
      defaultMessage="Set default assignees"
    />
  ),
  approversLabel: (
    <FormattedMessage id="DSR.ApproversLabel" defaultMessage="Approvers" />
  ),
  createDSRForm: (
    <FormattedMessage id="DSR.createDSRForm" defaultMessage="Create DSR form" />
  ),
  createDSRSubHeader: (
    <FormattedMessage
      id="DSR.createDSRSubHeader"
      defaultMessage="Please enter a name for the DSR form: "
    />
  ),
  dsrForm: <FormattedMessage id="DSR.dsrForm" defaultMessage="DSR form" />,
  nameRequired: (
    <FormattedMessage
      id="DSRForm.nameRequired"
      defaultMessage="Providing a name for this DSR form is required"
    />
  ),
  Active: vendorTranslations.Active,
  Inactive: vendorTranslations.Inactive,
  requesterWarning: (
    <FormattedMessage
      id="DSR.requesterUpdateWarning"
      defaultMessage="Requester’s data has been updated by a public user"
    />
  ),
  privacyPolicyLink: (
    <FormattedMessage
      id="DSR.privacyPolicyLink"
      defaultMessage="Privacy policy link"
    />
  ),
  privacyPolicyLinkHint: (
    <FormattedMessage
      id="DSR.privacyPolicyLinkHint"
      defaultMessage="Link for privacy policy"
    />
  ),
  openingDisplayText: (
    <FormattedMessage
      id="DSR.openingDisplayText"
      defaultMessage="Introduction text"
    />
  ),
  openingDisplayTextHint: (
    <FormattedMessage
      id="DSR.openingDisplayTextHint"
      defaultMessage="Enter introduction text...  "
    />
  ),
  internalDescription: (
    <FormattedMessage
      id="DSR.internalDescription"
      defaultMessage="Internal description"
    />
  ),
  additionalDisplayText: (
    <FormattedMessage
      id="DSR.additionalDisplayText"
      defaultMessage="End of form text"
    />
  ),
  additionalDisplayTextHint: (
    <FormattedMessage
      id="DSR.additionalDisplayTextHint"
      defaultMessage="Enter additional text..."
    />
  ),
  defineUserRouting: (
    <FormattedMessage
      id="DSR.defineUserRouting"
      defaultMessage="Define user routings and assignments"
    />
  ),
  routingHelperText: (
    <FormattedMessage
      id="DSR.routingHelperText"
      defaultMessage="Helper text for routing"
    />
  ),
  editLanguageVersion: (
    <FormattedMessage
      id="DSR.editLanguageVersion"
      defaultMessage="Edit language version"
    />
  ),
  customRouting: (
    <FormattedMessage id="DSR.customRouting" defaultMessage="Custom routing" />
  ),
  addNewRouting: (
    <FormattedMessage id="DSR.addNewRouting" defaultMessage="Add new routing" />
  ),
  inCountries: (
    <FormattedMessage id="DSR.inCountries" defaultMessage="in countries:" />
  ),
  forRequestTypes: (
    <FormattedMessage
      id="DSR.forRequestTypes"
      defaultMessage="for request types"
    />
  ),
  setResponsibleOrganisations: (
    <FormattedMessage
      id="DSR.setResponsibleOrganisations"
      defaultMessage="Set responsible organisation"
    />
  ),
  addAssigneeApprovers: (
    <FormattedMessage
      id="DSR.addAssigneeApprovers"
      defaultMessage="Add assignees and approvers"
    />
  ),
  setCustomRouting: (
    <FormattedMessage
      id="DSR.setCustomRouting"
      defaultMessage="Set custom routing"
    />
  ),
  default: <FormattedMessage id="DSR.default" defaultMessage="Default" />,
  deleteRoute: (
    <FormattedMessage id="DSR.deleteRoute" defaultMessage="Delete route" />
  ),
  deleteRouteWarning: (
    <FormattedMessage
      id="DSR.deleteRouteWarning"
      defaultMessage="Are you sure that you want to delete this route"
    />
  ),
  All: singularTerms('all'),
  RightToDeletePersonalInformation: (
    <FormattedMessage
      id="DSR.RightToDeletePersonalInformation"
      defaultMessage="Right to delete personal information"
    />
  ),
  RightToCorrectInaccuratePersonalInformation: (
    <FormattedMessage
      id="DSR.RightToCorrectInaccuratePersonalInformation"
      defaultMessage="Right to correct inaccurate personal information"
    />
  ),
  RightToAccessPersonalInformation: (
    <FormattedMessage
      id="DSR.RightToAccessPersonalInformation"
      defaultMessage="Right to know what personal information is being collected and right to access personal information"
    />
  ),
  RightToKnowSaleAndSharingOfPersonalInformation: (
    <FormattedMessage
      id="DSR.RightToKnowSaleAndSharingOfPersonalInformation"
      defaultMessage="Right to know what personal information is sold or shared and to whom "
    />
  ),
  RightToOptoutFromSaleAndSharingOfPersonalInformation: (
    <FormattedMessage
      id="DSR.RightToOptoutFromSaleAndSharingOfPersonalInformation"
      defaultMessage="Right to opt-out of sale or sharing of personal information "
    />
  ),
  RightToLimitUseAndDisclosureOfSensitivePersonalInformation: (
    <FormattedMessage
      id="DSR.RightToLimitUseAndDisclosureOfSensitivePersonalInformation"
      defaultMessage="Right to limit use an disclosure of sensitive personal information"
    />
  ),
  ccpa_exc_personal_information_complete_transaction_contract: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_complete_transaction_contract"
      defaultMessage="Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by the consumer, or reasonably anticipated by the consumer within the context of a business’ ongoing business relationship with the consumer, or otherwise perform a contract between the business and the consumer"
    />
  ),
  ccpa_exc_personal_information_ensure_security_integrity: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_ensure_security_integrity"
      defaultMessage="Help to ensure security and integrity to the extent the use of the consumer’s personal information is reasonably necessary and proportionate for those purposes Debug to identify and repair errors that impair existing intended functionality Exercise free speech, ensure the right of another consumer to exercise that consumer’s right of free speech, or exercise another right provided for by law"
    />
  ),
  ccpa_exc_personal_information_comply_calecpa: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_comply_calecpa"
      defaultMessage="Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 of Title 12 of Part 2 of the Penal Code"
    />
  ),
  ccpa_exc_delete_personal_impair_research: (
    <FormattedMessage
      id="DSR.ccpa_exc_delete_personal_impair_research"
      defaultMessage="Engage in public or peer-reviewed scientific, historical, or statistical research that conforms or adheres to all other applicable ethics and privacy laws, when the business’ deletion of the information is likely to render impossible or seriously impair the ability to complete such research, if the consumer has provided informed consent"
    />
  ),
  ccpa_exc_delete_solely_internal_use: (
    <FormattedMessage
      id="DSR.ccpa_exc_delete_solely_internal_use"
      defaultMessage="To enable solely internal uses that are reasonably aligned with the expectations of the consumer based on the consumer’s relationship with the business and compatible with the context in which the consumer provided the information"
    />
  ),
  ccpa_exc_delete_comply_legal_obligation: (
    <FormattedMessage
      id="DSR.ccpa_exc_delete_comply_legal_obligation"
      defaultMessage="Comply with a legal obligation"
    />
  ),
  ccpa_exc_personal_information_unverifiable_request: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_unverifiable_request"
      defaultMessage="A business may refuse the request if it does not receive a verifiable consumer request to correct inaccurate personal information"
    />
  ),
  ccpa_exc_access_personal_information_provided_twice: (
    <FormattedMessage
      id="DSR.ccpa_exc_access_personal_information_provided_twice"
      defaultMessage="It has already provided the information twice to the same consumer in a 12-month period "
    />
  ),
  ccpa_exc_access_personal_information_unverifiable_request: (
    <FormattedMessage
      id="DSR.ccpa_exc_access_personal_information_unverifiable_request"
      defaultMessage="It does not receive a verifiable consumer request"
    />
  ),
  ccpa_exc_sharing_personal_information_provided_twice: (
    <FormattedMessage
      id="DSR.ccpa_exc_sharing_personal_information_provided_twice"
      defaultMessage="It has already provided the information twice to the same consumer in a 12-month period"
    />
  ),
  ccpa_exc_sharing_personal_information_unverifiable_request: (
    <FormattedMessage
      id="DSR.ccpa_exc_sharing_personal_information_unverifiable_request"
      defaultMessage="It does not receive a verifiable consumer request"
    />
  ),
  ccpa_exc_personal_information_no_sale_or_sharing: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_no_sale_or_sharing"
      defaultMessage="There is no sale or sharing of personal information"
    />
  ),
  ccpa_exc_personal_information_intentional_use_or_disclosure: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_intentional_use_or_disclosure"
      defaultMessage="Intentional use or disclosure - If a consumer uses or directs the business to intentionally: (i) disclose personal information, or (ii) interact with one or more third parties."
    />
  ),
  ccpa_exc_personal_information_altering_third_parties_of_optout: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_altering_third_parties_of_optout"
      defaultMessage="Alerting third parties of opt-out (or limiting the use of sensitive personal data) - When the business uses or shares an identifier for a consumer who has opted out of the sale of the consumer’s personal information or limited the use of the consumer’s sensitive personal information for the purposes of alerting third parties that the consumer has opted out of the sale of the consumer’s personal information or limited the use of the consumer’s sensitive personal information."
    />
  ),
  ccpa_exc_personal_information_change_of_control: (
    <FormattedMessage
      id="DSR.ccpa_exc_personal_information_change_of_control"
      defaultMessage="Change of control - When the business transfers personal information to a third party as an asset that is part of a merger, acquisition, bankruptcy, or other business transaction, then it does not qualify as a sale or as sharing if the third party does not substantially change the way the personal information is used. If the third party does, it must inform the consumer accordingly."
    />
  ),
  ccpa_exc_sensitive_personal_information_no_sale_or_sharing: (
    <FormattedMessage
      id="DSR.ccpa_exc_sensitive_personal_information_no_sale_or_sharing"
      defaultMessage="Sensitive personal information that is collected or processed without the purpose of inferring characteristics about a consumer is not subject to this section"
    />
  ),
  ccpa_exc_sensitive_personal_information_privileged_under_california_law: (
    <FormattedMessage
      id="DSR.ccpa_exc_sensitive_personal_information_privileged_under_california_law"
      defaultMessage="This right does not prevent a business from providing the personal information of a consumer to a person covered by an evidentiary privilege under California law as part of a privileged communication"
    />
  ),
  ccpa_exc_comply_with_law_agencies_or_court_order: (
    <FormattedMessage
      id="DSR.ccpa_exc_comply_with_law_agencies_or_court_order"
      defaultMessage="Comply with federal, state, or local laws or comply with a court order or subpoena to provide information"
    />
  ),
  ccpa_exc_comply_to_inquiry_investigation_subpoena_or_summons: (
    <FormattedMessage
      id="DSR.ccpa_exc_comply_to_inquiry_investigation_subpoena_or_summons"
      defaultMessage="Comply with a civil, criminal, or regulatory inquiry, investigation, subpoena, or summons by federal, state, or local authorities"
    />
  ),
  ccpa_exc_cooperate_with_law_enforcement_agency: (
    <FormattedMessage
      id="DSR.ccpa_exc_cooperate_with_law_enforcement_agency"
      defaultMessage="Cooperate with law enforcement agencies concerning conduct or activity that the business, service provider, or third party reasonably and in good faith believes may violate federal, state, or local law"
    />
  ),
  ccpa_exc_cooperate_with_government_agency: (
    <FormattedMessage
      id="DSR.ccpa_exc_cooperate_with_government_agency"
      defaultMessage="Cooperate with a government agency request for emergency access to a consumer’s personal information if a natural person is at risk or danger of death or serious physical injury provided that"
    />
  ),
  ccpa_exc_exercise_or_defend_legal_claims: (
    <FormattedMessage
      id="DSR.ccpa_exc_exercise_or_defend_legal_claims"
      defaultMessage="Exercise or defend legal claims"
    />
  ),
  ccpa_exc_deidentified_or_aggregated_information: (
    <FormattedMessage
      id="DSR.ccpa_exc_deidentified_or_aggregated_information"
      defaultMessage="Collect, use, retain, sell, share, or disclose consumers’ personal information that is deidentified or aggregate consumer information"
    />
  ),
  ccpa_exc_commercial_conduct_outside_california: (
    <FormattedMessage
      id="DSR.ccpa_exc_commercial_conduct_outside_california"
      defaultMessage="Collect, sell, or share a consumer’s personal information if every aspect of that commercial conduct takes place wholly outside of California"
    />
  ),
  chooseJurisdiction: (
    <FormattedMessage
      id="DSR.chooseJurisdiction"
      defaultMessage="Choose jurisdiction:"
    />
  ),
  jurisdictionRequired: (
    <FormattedMessage
      id="DSR.jurisdictionRequired"
      defaultMessage="Please select a jurisdiction"
    />
  ),
  noExceptionIdentified: (
    <FormattedMessage
      id="DSR.noExceptionIdentified"
      defaultMessage="No exceptions identified in request"
    />
  )
};
