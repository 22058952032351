import Immutable from 'immutable';

import { checkForLogoAndModify } from '../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  logoUrl: undefined,
  logoUrlFormData: undefined,
  whiteLabelling: Immutable.Map({}),
  isLogoUploading: undefined,
  whiteLabellingFormData: Immutable.Map({}),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isUpdating: false,
  tenantEmailPreference: Immutable.Map({})
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TENANT_EMAIL_PREFERENCE:UPDATE:SUCCESS':
    case 'TENANT_EMAIL_PREFERENCE:FETCH:SUCCESS': {
      return state.set('tenantEmailPreference', Immutable.fromJS(action.data));
    }
    case 'TENANT_EMAIL_PREFERENCE:UPDATE:FAIL':
    case 'TENANT_EMAIL_PREFERENCE:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'ROLE:GROUPS:PERMISSIONS:UPSERT:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );

    case 'TENANT:SETTINGS:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'TENANT:SETTINGS:FETCH:SUCCESS': {
      const whiteLabellingData = checkForLogoAndModify(action.data);
      return state
        .set('isFetching', false)
        .set('whiteLabelling', Immutable.Map(whiteLabellingData))
        .set('revision', action.revision)
        .set('whiteLabellingFormData', Immutable.Map(whiteLabellingData));
    }
    case 'TENANT:SETTINGS:FETCH:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'TENANT:SETTINGS:RESET':
      return state
        .set('whiteLabelling', defaultState.get('whiteLabelling'))
        .set(
          'whiteLabellingFormData',
          defaultState.get('whiteLabellingFormData')
        );
    case 'TENANT:SETTINGS:LOGO:UPLOAD:INIT':
      return state.set('isLogoUploading', true);
    case 'TENANT:SETTINGS:LOGO:UPLOAD:SUCCESS':
      return state
        .set('whiteLabellingFormData', Immutable.Map(action.item))
        .set('isLogoUploading', false)
        .set('error', defaultState.get('error'));
    case 'TENANT:SETTINGS:UPDATE:SUCCESS': {
      const whiteLabellingData = checkForLogoAndModify(action.item);
      return state
        .set('whiteLabellingFormData', Immutable.Map(whiteLabellingData))
        .set('whiteLabelling', Immutable.Map(whiteLabellingData))
        .set('revision', action.revision)
        .set('error', defaultState.get('error'));
    }
    case 'TENANT:SETTINGS:LOGO:UPLOAD:FAIL':
      return state
        .set('isFetching', false)
        .set('isLogoUploading', false)
        .set(
          'error',
          Immutable.Map({
            message: action.error,
            isError: true
          })
        );
    case 'TENANT:SETTINGS:UPDATE:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'TENANT:SETTINGS:LOGO:FETCH:SUCCESS': {
      if (action.formData) {
        return state.set('logoUrlFormData', action.logoUrl);
      }
      return state.set('logoUrl', action.logoUrl);
    }

    case 'TENANT:SETTINGS:LOGO:FETCH:FAIL':
      return state.set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );

    case 'TENANT:SETTINGS:LOGO:REMOVE':
      return state.set('logoUrl', defaultState.get('logoUrl')).set(
        'whiteLabellingFormData',
        Immutable.Map({
          ...action.data,
          companyLogo: null
        })
      );

    default:
      return state;
  }
};
