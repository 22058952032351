import { combineReducers } from 'redux';

import filters from './filters';

import privacyRecords from './privacyRecords';
import dataSubjectCategories from './datasubjectcategories';
import personalDataItems from './personaldataitems';
import personalDataCategories from './personaldatacategories';
import dataSources from './datasources';
import dataSourceCategories from './dataSourceCategories';
import personalDataLinks from './personaldatalinks';
import processingCategories from './processingCategories';
import organisations from './organisations';
import legalEntityList from './legalEntityList';
import linkGroup from './linkGroup';
import documents from './documents';
import retentionTerms from './retentionterms';
import dataRecipientCategories from './dataRecipientCategories';
import simpleEntities from '../components/simple-named-entity/reducers';
import stakeholders from './stakeholders';

export default combineReducers({
  dataSources,
  dataSourceCategories,
  dataSubjectCategories,
  filters,
  legalEntityList,
  linkGroup,
  organisations,
  personalDataCategories,
  personalDataItems,
  personalDataLinks,
  privacyRecords,
  processingCategories,
  retentionTerms,
  simpleEntities,
  documents,
  dataRecipientCategories,
  stakeholders
});
