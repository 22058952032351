import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  currentFilter: 'transferDetails_details',
  unSavedData: false,
  prevLocation: '/privacyrecords',
  isFetching: false,
  countryAnalysis: undefined,
  data: Immutable.Map({}),
  isUpdating: false,
  actionError: undefined,
  recordId: '',
  recordName: '',
  isEditable: true,
  showConfirmDialog: true,
  isEditRestricted: false,
  comments: Immutable.List(),
  users: Immutable.List(),
  openPromoteDialog: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TIA:DETAIL:INIT':
    case 'TIA:DETAIL:RESET':
      return defaultState;
    case 'TIA:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'TIA:UNSAVED:PROPERTY:SET':
      return state
        .set('unSavedData', action.value)
        .set('isUpdating', !action.value);
    case 'TIA:ISUPDAING:PROPERTY:SET':
      return state.set('isUpdating', true);
    case 'TIA:FETCH:COUNTRY:ANALYSIS:INIT':
      return state.set('countryAnalysis', defaultState.get('countryAnalysis'));
    case 'TIA:COUNTRY:ANALYSIS:FETCH:SUCCESS':
      return state.set('countryAnalysis', action.data);
    case 'TIA:PROPERTY:UPDATE': {
      const modifiedData = {
        ...state.get('data').toJS(),
        [action.property]: action.data
      };
      return state.set('data', Immutable.Map(modifiedData));
    }
    case 'TIA:DETAIL:UPSERT':
      return state
        .set('isUpdating', true)
        .set('actionError', defaultState.get('actionError'))
        .set('comments', Immutable.List(action.data?.comments));
    case 'TIA:DETAIL:UPSERT:SUCCESS':
      return state
        .set('data', Immutable.Map(action.data))
        .set('isUpdating', false);
    case 'TIA:DETAIL:UPSERT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false);
    case 'TIA:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/tia/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      }
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isFetching', true)
        .set('prevLocation', prevLocation)
        .set('recordId', action.recordId);
    }
    case 'TIA:DETAIL:FETCH:SUCCESS':
      return state
        .set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('comments', Immutable.List(action.data?.comments))
        .set('recordName', action.recordName);

    case 'TIA:DETAIL:FETCH:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false);
    case 'TIA:TOGGLE_PROMOTION_DIALOG':
      return state.set('openPromoteDialog', !state.get('openPromoteDialog'));
    case 'TIA:UNSAVED:PROPERTY:RESET':
      return state
        .set('unSavedData', defaultState.get('unSavedData'))
        .set('isUpdating', true);
    case 'RECORDS:UNSAVED_DATA:RESET':
      return state.set('unSavedData', defaultState.get('unSavedData'));

    case 'TIA:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'TIA:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'TIA:SHOW:CONFIRM:DIALOG:RESET': {
      return state.set('showConfirmDialog', false);
    }
    case 'TIA:DETAIL:TEMPLATE:SAVE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'TIA:EDIT:RESTRICTED':
      return state.set('isEditRestricted', action.isEditRestricted);
    case 'TIA:COMMENT:DELETE:SUCCESS':
    case 'TIA:COMMENT:SAVE:SUCCESS': {
      return state.set('comments', Immutable.List(action.comments));
    }
    case 'TIA:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'TIA:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);
    case 'TIA:DETAIL:UPSERT:LOCKED':
      return state.set('isUpdating', false);
    case 'TIA:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);
    default:
      return state;
  }
};
