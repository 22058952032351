import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ItemList from '@packages/components/form-components/itemList';

import TransferGroundListDialog from './components/transferGroundListDialog';

const TransferGroundSelector = (props) => {
  const {
    removeFilters,
    jurisdiction,
    fields,
    updateTransferGrounds,
    itemLabel
  } = props;

  useEffect(
    () => () => {
      removeFilters(jurisdiction);
    },
    []
  );

  // Handler to remove item
  const handleRemoveItem = (selectedIndex) => {
    const selectedTransferGrounds = fields.length
      ? fields.getAll().filter((_, index) => selectedIndex !== index)
      : [];
    saveTransferGrounds(selectedTransferGrounds);
  };

  // Handler to add items
  const handleSelectedItems = (selectedItems) => {
    const selectedTransferGrounds = selectedItems.map((item) => ({
      ...item,
      value: {
        ...item.value,
        key: `${item.value.jurisdiction.id || item.value.jurisdiction}
        ${item.value.lawAbbreviation} ${item.value.article} - ${
        item.value.summary
      } `,
        content: item.value.content
      }
    }));
    saveTransferGrounds(selectedTransferGrounds);
  };

  // Handler to edit item
  const handleEditItem = (selectedItem, selectedIndex) => {
    const selectedTransferGrounds = fields.length ? [...fields.getAll()] : [];
    if (selectedIndex !== -1) {
      selectedTransferGrounds[selectedIndex] = selectedItem;
    } else {
      selectedTransferGrounds.push(selectedItem);
    }
    saveTransferGrounds(selectedTransferGrounds);
  };

  const saveTransferGrounds = (transferGrounds) => {
    updateTransferGrounds(transferGrounds);
  };

  const handleRemoveFilters = () => {
    removeFilters(jurisdiction);
  };

  const selectedTransferGrounds = fields.getAll();

  return (
    <div>
      {selectedTransferGrounds && selectedTransferGrounds.length > 0 && (
        <ItemList
          isEditable={false}
          isNoteEditable={true}
          type={itemLabel}
          handleRemoveItem={handleRemoveItem}
          handleEditItem={handleEditItem}
          selectedItems={selectedTransferGrounds}
          {...props}
        />
      )}
      <TransferGroundListDialog
        handleSelectedItems={handleSelectedItems}
        selectedItems={selectedTransferGrounds}
        {...props}
        removeFilters={handleRemoveFilters}
      />
    </div>
  );
};

TransferGroundSelector.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    removeAll: PropTypes.func,
    push: PropTypes.func,
    insert: PropTypes.func
  }).isRequired,
  itemLabel: PropTypes.node,
  updateTransferGrounds: PropTypes.func,
  jurisdiction: PropTypes.string,
  removeFilters: PropTypes.func
};

TransferGroundSelector.defaultProps = {
  itemLabel: null,
  updateTransferGrounds: (e) => e,
  jurisdiction: 'EU',
  removeFilters: (e) => e
};

export default TransferGroundSelector;
