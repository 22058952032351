import React from 'react';
import { FormattedMessage } from 'react-intl';
import { singularTerms } from './uppercaseTranslations';

export default {
  vendorManagementHeader: <FormattedMessage
    id="VendorManagement.header"
    defaultMessage="Vendor management"
  />,
  internalMessageDescription: <FormattedMessage
    id="VendorManagement.internalMessageDescription"
    defaultMessage="This section is for communication for your team and is not visible
    to people who do not have the password for this form"
  />,
  externalMessageDescription: <FormattedMessage
    id="VendorManagement.externalMessageDescription"
    defaultMessage="This section will send message and notify the sender of the form
    so they can answer any question that you have"
  />,
  questions: <FormattedMessage
    id="VendorManagement.questions"
    defaultMessage="Questions"
  />,
  attachmentsAndMessaging: <FormattedMessage
    id="VendorManagement.attachmentsAndMessaging"
    defaultMessage="Attachments and messaging"
  />,
  messaging: <FormattedMessage
    id="VendorManagement.Messaging"
    defaultMessage="Messaging"
  />,
  partial: <FormattedMessage
    id="VendorManagement.partial"
    defaultMessage="Partial"
  />,
  saveComment: <FormattedMessage
    id="VendorManagement.saveComment"
    defaultMessage="Save comment"
  />,
  vendorHas: <FormattedMessage
    id="VendorManagement.vendorHas"
    defaultMessage="Vendor has"
  />,
  newMessage: <FormattedMessage
    id="VendorManagement.newMessage"
    defaultMessage="New message"
  />,
  optionalName: <FormattedMessage
    id="VendorManagement.optionalName"
    defaultMessage="Name (optional)"
  />,
  resetEmail: <FormattedMessage
    id="VendorManagement.resetEmail"
    defaultMessage="Reset password email"
  />,
  password: <FormattedMessage
    id="Profile.password"
    defaultMessage="Password"
  />,
  repeatPassword: <FormattedMessage
    id="Profile.repeatPassword"
    defaultMessage="Repeat password"
  />,
  passwordDescription: <FormattedMessage
    id="VendorManagement.passwordDescription"
    defaultMessage="Due to the nature of this form we want to be sure to protect your
    data which is why we are asking for a password."
  />,
  passwordHintText: <FormattedMessage
    id="VendorManagement.passwordHintText"
    defaultMessage="We store no user accounts to minimise the risk to people that utilize our system.
    You may set an optional reset password email and we will be sure to delete it one month after the
    form is submitted back to the sender."
  />,
  login: <FormattedMessage
    id="LoginForm.loginHeader"
    defaultMessage="Log in"
  />,
  loginNow: <FormattedMessage
    id="LoginForm.loginButton"
    defaultMessage="Log in now"
  />,
  submitConfirmation: <FormattedMessage
    id="VendorManagement.submitConfirmation"
    defaultMessage="You are not allowed to edit the responses after submission.
    Are you sure want to submit the responses?"
  />,
  submitConfirmationHeader: <FormattedMessage
    id="VendorManagement.submitConfirmationHeader"
    defaultMessage="Confirm submission"
  />,
  submitSuccessful: <FormattedMessage
    id="VendorForm.submitSuccessful"
    defaultMessage="Your response have been submitted successfully"
  />,
  forgotPasswordEmailSent: <FormattedMessage
    id="Vendor.forgotPasswordEmailSent"
    defaultMessage="Instructions to reset your password have been sent to your inbox."
  />,
  overview: <FormattedMessage
    id="Common.overview"
    description="Overview"
    defaultMessage="Overview"
  />,
  vendors: <FormattedMessage
    id="Vendors.vendors"
    defaultMessage="Vendors"
  />,
  vendor: <FormattedMessage
    id="Vendors.vendor"
    defaultMessage="vendor"
  />,
  forms: <FormattedMessage
    id="Vendors.forms"
    defaultMessage="Forms"
  />,
  form: <FormattedMessage
    id="Vendors.form"
    defaultMessage="Form"
  />,
  templates: <FormattedMessage
    id="Vendors.templates"
    defaultMessage="Templates"
  />,
  template: <FormattedMessage
    id="Vendors.template"
    defaultMessage="Template"
  />,
  newVendor: <FormattedMessage
    id="Vendors.newVendor"
    defaultMessage="New Vendor"
  />,
  newForm: <FormattedMessage
    id="Vendors.newForm"
    defaultMessage="New Form"
  />,
  newTemplate: <FormattedMessage
    id="Vendors.newTemplate"
    defaultMessage="New Template"
  />,
  quickStart: <FormattedMessage
    id="Vendors.quickStart"
    defaultMessage="Quick start"
  />,
  openEnquiries: <FormattedMessage
    id="Vendors.openEnquiries"
    defaultMessage="Open enquiries"
  />,
  recentCommunication: <FormattedMessage
    id="Vendors.recentCommunication"
    defaultMessage="Recent communication"
  />,
  latestUpdates: <FormattedMessage
    id="Vendors.latestUpdates"
    defaultMessage="Latest updates"
  />,
  organisationName: <FormattedMessage
    id="Vendors.organisationName"
    defaultMessage="Organisation Name"
  />,
  formName: <FormattedMessage
    id="Vendors.formName"
    defaultMessage="Form name"
  />,
  templateName: <FormattedMessage
    id="Vendors.templateName"
    defaultMessage="Template name"
  />,
  relevantOrganisation: <FormattedMessage
    id="Vendors.relevantOrganisation"
    defaultMessage="Relevant organisation"
  />,
  chooseOrganisation: <FormattedMessage
    id="Vendor.chooseOrganisation"
    defaultMessage="Choose organisation"
  />,
  chooseTemplate: <FormattedMessage
    id="Vendor.chooseTemplate"
    defaultMessage="Choose template"
  />,
  useTemplate: <FormattedMessage
    id="Vendors.useTemplate"
    defaultMessage="Use template"
  />,
  usePreviousTemplate: <FormattedMessage
    id="Vendors.usePreviousTemplate"
    defaultMessage="Use previous template"
  />,
  rating: <FormattedMessage
    id="Vendors.rating"
    defaultMessage="Rating"
  />,
  dateOfLastUpdate: <FormattedMessage
    id="Vendors.dateOfLastUpdate"
    defaultMessage="Date of last updated"
  />,
  editVendor: <FormattedMessage
    id="Vendors.editVendor"
    defaultMessage="Edit vendor"
  />,
  deleteVendor: <FormattedMessage
    id="Vendors.deleteVendor"
    defaultMessage="Delete vendor"
  />,
  editForm: <FormattedMessage
    id="Vendors.editForm"
    defaultMessage="Edit form"
  />,
  deleteForm: <FormattedMessage
    id="Vendors.deleteForm"
    defaultMessage="Delete form"
  />,
  editTemplate: <FormattedMessage
    id="Vendors.editTemplate"
    defaultMessage="Edit Template"
  />,
  deleteTemplate: <FormattedMessage
    id="Vendors.deleteTemplate"
    defaultMessage="Delete Template"
  />,
  editQuestion: <FormattedMessage
    id="Vendors.editQuestion"
    defaultMessage="Edit question"
  />,
  deleteQuestion: <FormattedMessage
    id="Vendors.deleteQuestion"
    defaultMessage="Delete question"
  />,
  Active: <FormattedMessage
    id="Vendors.Active"
    defaultMessage="Active"
  />,
  Inactive: <FormattedMessage
    id="Vendors.Inactive"
    defaultMessage="Inactive"
  />,
  Prospect: <FormattedMessage
    id="Vendors.Prospect"
    defaultMessage="Prospect"
  />,
  rfi: <FormattedMessage
    id="Vendors.rfi"
    defaultMessage="RFI"
  />,
  editing: <FormattedMessage
    id="Vendors.editing"
    defaultMessage="Editing"
  />,
  section: <FormattedMessage
    id="Vendors.section"
    defaultMessage="Section"
  />,
  addNewSection: <FormattedMessage
    id="Vendors.addNewSection"
    defaultMessage="Add new section"
  />,
  chooseSection: <FormattedMessage
    id="Vendors.chooseSection"
    defaultMessage="Choose section"
  />,
  sectionHeading: <FormattedMessage
    id="Vendors.sectionHeading"
    defaultMessage="Section heading"
  />,
  addNewQuestion: <FormattedMessage
    id="Vendors.addNewQuestion"
    defaultMessage="Add new question"
  />,
  priority: <FormattedMessage
    id="Vendors.priority"
    defaultMessage="Priority"
  />,
  choosePriority: <FormattedMessage
    id="Vendors.choosePriority"
    defaultMessage="Choose priority"
  />,
  answerType: <FormattedMessage
    id="Vendors.answerType"
    defaultMessage="Answer type"
  />,
  chooseType: <FormattedMessage
    id="Vendors.chooseType"
    defaultMessage="Choose type"
  />,
  addQuestion: <FormattedMessage
    id="Vendors.addQuestion"
    defaultMessage="What is your question"
  />,
  question: <FormattedMessage
    id="Vendors.question"
    defaultMessage="Question"
  />,
  createNewForm: <FormattedMessage
    id="Vendors.createNewForm"
    defaultMessage="Create new form"
  />,
  completionDate: <FormattedMessage
    id="Vendors.completionDate"
    defaultMessage="Completion date"
  />,
  time: <FormattedMessage
    id="Vendors.time"
    defaultMessage="Time"
  />,
  Draft: <FormattedMessage
    id="Vendors.status.draft"
    defaultMessage="Draft"
  />,
  Sent: <FormattedMessage
    id="Vendors.status.sent"
    defaultMessage="Sent"
  />,
  InProgress: singularTerms('in_progress'),
  Submitted: singularTerms('submitted'),
  Withdrawn: singularTerms('record_status_withdrawn'),
  Expired: singularTerms('Expired'),
  DeadlineExceeded: <FormattedMessage
    id="Vendors.status.deadlineExceeded"
    defaultMessage="Deadline exceeded"
  />,
  createNewTemplate: <FormattedMessage
    id="Vendors.createNewTemplate"
    defaultMessage="Create new template"
  />,
  lastEdited: <FormattedMessage
    id="Vendors.lastEdited"
    defaultMessage="Last edited"
  />,
  vendorResponse: <FormattedMessage
    id="Vendors.vendorResponse"
    defaultMessage="Vendor response"
  />,
  sectionToggle: <FormattedMessage
    id="Vendors.sectionToggle"
    defaultMessage="Expand/Collapse"
  />,
  sendForm: <FormattedMessage
    id="Vendors.sendForm"
    defaultMessage="Send form"
  />,
  send: <FormattedMessage
    id="Vendors.send"
    defaultMessage="Send"
  />,
  responders: <FormattedMessage
    id="Vendors.responders"
    defaultMessage="Responders"
  />,
  pressEnterToAddNew: <FormattedMessage
    id="Vendors.pressEnterToAddNew"
    defaultMessage="Press enter to add new"
  />,
  vendorForms: <FormattedMessage
    id="Vendors.vendorForms"
    defaultMessage="Vendor forms"
  />,
  anonymous: <FormattedMessage
    id="Vendors.anonymous"
    defaultMessage="Anonymous"
  />,
  contactDetail: <FormattedMessage
    id="OrganisationUserDetailForm.contactdetails"
    defaultMessage="Contact Details"
  />,
  openQuestionnaires: <FormattedMessage
    id="Vendors.openQuestionnaires"
    defaultMessage="Open questionnaires"
  />,
  questionnaires: <FormattedMessage
    id="Vendors.questionnaires"
    defaultMessage="questionnaires"
  />,
  addInfo: <FormattedMessage
    id="Vendors.addInfo"
    defaultMessage="Additional Information"
  />,
  details: <FormattedMessage
    id="Vendors.details"
    defaultMessage="Details"
  />,
  activeForm: <FormattedMessage
    id="Vendors.activeForm"
    defaultMessage="Active forms"
  />,
  sectionReorder: <FormattedMessage
    id="Vendors.sectionReorder"
    defaultMessage="Section reorder"
  />,
  questionReorder: <FormattedMessage
    id="Vendors.questionReorder"
    defaultMessage="Question reorder"
  />,
  sectionName: <FormattedMessage
    id="Vendors.sectionName"
    defaultMessage="Section name"
  />,
  index: <FormattedMessage
    id="Vendors.index"
    defaultMessage="Index"
  />,
  position: <FormattedMessage
    id="Vendors.position"
    defaultMessage="Position"
  />,
  seeAll: <FormattedMessage
    id="Vendors.seeAll"
    defaultMessage="See all"
  />,
  deadlineExpired: <FormattedMessage
    id="Vendors.deadlineExpired"
    defaultMessage="Deadline Expired"
  />,
  deadline: <FormattedMessage
    id="Vendors.dealine"
    defaultMessage="Deadline"
  />,
  messages: <FormattedMessage
    id="Vendors.messages"
    defaultMessage="Messages"
  />,
  createNewMessage: <FormattedMessage
    id="Vendors.createNewMessage"
    defaultMessage="Create new message"
  />,
  waitingForResponse: <FormattedMessage
    id="Vendors.waitingForResponse"
    defaultMessage="Waiting for response from vendor"
  />,
  sendMessage: <FormattedMessage
    id="Vendors.sendMessage"
    defaultMessage="Send message"
  />,
  markInactive: <FormattedMessage
    id="Vendors.markInactive"
    defaultMessage="Mark as inactive"
  />,
  markActive: <FormattedMessage
    id="Vendors.markActive"
    defaultMessage="Mark as active"
  />,
  low: <FormattedMessage
    id="Vendors.low"
    defaultMessage="Low"
  />,
  medium: <FormattedMessage
    id="Vendors.medium"
    defaultMessage="Medium"
  />,
  high: <FormattedMessage
    id="Vendors.high"
    defaultMessage="High"
  />,
  critical: <FormattedMessage
    id="Vendors.critical"
    defaultMessage="Critical"
  />,
  normal: <FormattedMessage
    id="PersonalDataItem.Type.normal"
    defaultMessage="Normal"
  />,
  breakDown: <FormattedMessage
    id="Vendors.breakDown"
    defaultMessage="Break down"
  />,
  response: <FormattedMessage
    id="Vendors.response"
    defaultMessage="Response"
  />,
  addResponse: <FormattedMessage
    id="Vendors.addResponse"
    defaultMessage="Add your response"
  />,
  questionsEmptyError: <FormattedMessage
    id="Vendors.questionsEmptyError"
    defaultMessage="At least one question must be created in the form."
  />,
  editSection: <FormattedMessage
    id="Vendors.editSection"
    defaultMessage="Edit section"
  />,
  deleteSection: <FormattedMessage
    id="Vendors.deleteSection"
    defaultMessage="Delete section"
  />,
  sameSectionError: <FormattedMessage
    id="Vendors.sameSectionError"
    defaultMessage="Section name already exists."
  />,
  confirmQuestionDelete: <FormattedMessage
    id="vendorForm.confirmQuestionDelete"
    defaultMessage="Are you sure that you want to delete this question?"
  />,
  confirmAttachmentDelete: <FormattedMessage
    id="vendorForm.confirmAttachmentDelete"
    defaultMessage="Are you sure that you want to delete this attachment?"
  />,
  formNumber: <FormattedMessage
    id="Vendors.formNumber"
    defaultMessage="Form number"
  />,
  formInactive: <FormattedMessage
    id="Vendors.formInactive"
    defaultMessage="Form is currently inactive"
  />,
  loaderContent: <FormattedMessage
    id="Vendors.loaderContent"
    defaultMessage="Please wait while your XLSX file is being generated"
  />,
  selectResponse: <FormattedMessage
    id="Vendors.selectResponse"
    defaultMessage="Select response"
  />,
  vendorFormMessage: <FormattedMessage
    id="Vendors.vendorFormMessage"
    defaultMessage="Vendor form message"
  />,
  vendorReviewNotification: <FormattedMessage
    id="Vendors.vendorReviewNotification"
    defaultMessage="Vendor review notification"
  />,
  communications: <FormattedMessage
    id="Vendors.communications"
    defaultMessage="Communications"
  />,
  saveData: <FormattedMessage
    id="Vendors.saveData"
    defaultMessage="Save data"
  />,
  selection:
  <FormattedMessage
    id="Vendors.selection"
    defaultMessage="Selection"
  />,
  text:
  <FormattedMessage
    id="Vendors.text"
    defaultMessage="Text"
  />,
  startNewProcessing: <FormattedMessage
    id="Vendors.startNewProcessing"
    defaultMessage="Start new processing activity"
  />,
  newTabConfirmation: <FormattedMessage
    id="Vendors.newTabConfirmation"
    defaultMessage="This will open in a new tab as it is part of the core module."
  />,
  startNewBreach: <FormattedMessage
    id="Vendors.startNewBreach"
    defaultMessage="Start new breach"
  />,
  startNewAssessment: <FormattedMessage
    id="Vendors.startNewAssessment"
    defaultMessage="Start new DPIA"
  />,
  startNewTia: <FormattedMessage
    id="Vendors.startNewTia"
    defaultMessage="Start new TIA"
  />,
  lastReview: <FormattedMessage
    id="Vendors.lastReview"
    defaultMessage="Last review"
  />,
  nextReview: <FormattedMessage
    id="Vendors.nextReview"
    defaultMessage="Next review"
  />,
  activeProcessings: <FormattedMessage
    id="Vendors.activeProcessings"
    defaultMessage="Active processing activities"
  />,
  activeAssessments: <FormattedMessage
    id="Vendors.activeAssessments"
    defaultMessage="Active DPIAs"
  />,
  activeTias: <FormattedMessage
    id="Vendors.activeTias"
    defaultMessage="Active TIAs"
  />,
  registeredBreaches: <FormattedMessage
    id="Vendors.registeredBreaches"
    defaultMessage="Registered breaches"
  />,
  vendorForm: <FormattedMessage
    id="Vendors.vendorForm"
    defaultMessage="Vendor form"
  />,
  WithdrawForm: <FormattedMessage
    id="Vendors.Withdraw"
    defaultMessage="Withdraw form"
  />,
  markAsReviewed: <FormattedMessage
    id="Vendors.markAsReviewed"
    defaultMessage="Mark as reviewed"
  />,
  reviewConfirmationMessage: <FormattedMessage
    id="Vendors.reviewConfirmationMessage"
    defaultMessage="Do you want to update this organisation as being reviewed?"
  />,
  reviewConfirmation: <FormattedMessage
    id="Vendors.reviewConfirmationHeader"
    defaultMessage="Review confirmation"
  />,
  Selection:
  <FormattedMessage
    id="Vendors.selectionLabel"
    defaultMessage="Selection"
  />,
  readMore:
  <FormattedMessage
    id="Vendors.updates.readMore"
    defaultMessage="Read more..."
  />,
  sectionCategory:
  <FormattedMessage
    id="Vendors.sectionCategory"
    defaultMessage="Section category"
  />,
  sectionCategoryHint:
  <FormattedMessage
    id="Vendors.sectionCategoryHint"
    defaultMessage="Choose category"
  />,
  Reputational: <FormattedMessage
    id="Vendors.sectionCategory.Reputational"
    defaultMessage="Reputational"
  />,
  ComplianceAndLegal: <FormattedMessage
    id="Vendors.sectionCategory.ComplianceAndLegal"
    defaultMessage="Compliance and legal"
  />,
  Financial: <FormattedMessage
    id="Vendors.sectionCategory.Financial"
    defaultMessage="Financial"
  />,
  Security: <FormattedMessage
    id="Vendors.sectionCategory.Security"
    defaultMessage="Security"
  />,
  Strategic: <FormattedMessage
    id="Vendors.sectionCategory.Strategic"
    defaultMessage="Strategic"
  />,
  Operational: <FormattedMessage
    id="Vendors.sectionCategory.Operational"
    defaultMessage="Operational"
  />,
  General: <FormattedMessage
    id="Vendors.sectionCategory.General"
    defaultMessage="General"
  />,
  None: <FormattedMessage
    id="Vendors.sectionCategory.None"
    defaultMessage="None"
  />,
  category: <FormattedMessage
    id="Vendors.category"
    defaultMessage="Category"
  />,
  acceptedResponse: <FormattedMessage
    id="Vendors.acceptedResponse"
    defaultMessage="Accepted response"
  />,
  unscored: <FormattedMessage
    id="Vendors.unscored"
    defaultMessage="Unscored"
  />,
  editScore: <FormattedMessage
    id="Vendors.editScore"
    defaultMessage="Edit score"
  />,
  number: <FormattedMessage
    id="DSR.number"
    defaultMessage="Number"
  />
};
