import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  pricingDetails: Immutable.List([])
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PRICING:PLAN:FETCH': {
      return state
        .set('pricingDetails', state.get('pricingDetails'));
    }

    case 'PRICING:PLAN:FETCH:SUCCESS': {
      return state
        .set('pricingDetails', Immutable.List(action.data));
    }

    case 'PRICING:PLAN:UPDATE:SUCCESS': {
      const data = state.get('pricingDetails').toJS();
      const index = data.findIndex(item => item.id === action.data.id);
      data[index] = action.data;
      return state
        .set('pricingDetails', Immutable.List(data));
    }
    default:
      return state;
  }
};
