import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import { handleServiceDown, GENERAL_FETCH_LIMIT } from '@packages/utils/common-utils';

export default function* searchMessagesLinkedRecords(action) {
  const { searchParams } = action;
  const { searchKey, searchText } = searchParams;

  try {
    // eslint-disable-next-line max-len
    const url = `/v1/records?numberOfResults=${GENERAL_FETCH_LIMIT}&offset=0&search=name=${encodeURIComponent(searchText)}`;
    const response = yield registry.get('request').get(url, null, {});

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: `${item.privacyRecordNumber} - ${item.name}`
        }));
        yield put({
          type: 'MESSAGES:LINKED_RECORDS:SELECTOR:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'MESSAGES:LINKED_RECORDS:SELECTOR:LIST:SEARCH:FAIL', error: err.message });
  }
}

