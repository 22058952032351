import React from 'react';
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';

import { FLAT, deepCopy } from '@packages/utils/common-utils';
import {
  commonTranslations,
  eventTypeTranslations
} from '@packages/utils/commontranslations';
import {
  singularTerms as uppercaseSingular,
  pluralTerms as uppercasePlural
} from '@packages/utils/uppercaseTranslations';

export const getCurrentMergeItems = (value, items, dataItemId, unused) => {
  let newItem = items;
  if (
    items.findIndex(
      (item) => unused !== undefined && item.unused !== unused
    ) !== -1
  ) {
    newItem = items.splice(0, items.size, { ...value, unused });
  } else if (dataItemId) {
    const index = items.findIndex((item) => item.id === dataItemId);
    if (index !== -1) {
      newItem = items.splice(index, 1);
    }
  } else {
    const index = items.findIndex((item) => item.id === value.id);
    if (index !== -1) {
      newItem = items.splice(index, 1);
    } else {
      newItem = items.push({ ...value, unused });
    }
  }
  return newItem;
};

export const checkForMergeItems = (items, mergeItems) => {
  const newItems = [];
  if (!items) return Immutable.List(newItems);
  const mergeItemIds = mergeItems.map((item) => item.id);
  items.forEach((item) => {
    if (mergeItemIds.includes(item.id)) {
      newItems.push({
        ...item,
        selected: true,
        unused: mergeItems.find((value) => value.id === item.id).unused
      });
    } else if (item.selected) {
      newItems.push({
        ...item,
        selected: false,
        unused: mergeItems.find((value) => value.id === item.id).unused
      });
    } else {
      newItems.push(item);
    }
  });
  return Immutable.List(newItems);
};

export const getModifiedType = (entityType) => {
  switch (entityType) {
    case 'DataSource':
      return 'DATASOURCES';
    case 'DataSourceCategory':
      return 'DATA_SOURCE_CATEGORIES';
    case 'ProcessingCategory':
      return 'PROCESSING_CATEGORIES';
    case 'PersonalDataItem':
      return 'PERSONAL_DATA_ITEMS';
    case 'PersonalDataCategory':
      return 'PERSONAL_DATA_CATEGORIES';
    case 'DataSubjectCategory':
      return 'DATA_SUBJECT_CATEGORIES';
    case 'SecurityMeasure':
      return 'SECURITYMEASURES';
    case 'RetentionTermOffset':
      return 'RETENTION_OFFSETS';
    case 'Reference':
      return 'REFERENCES';
    case 'Document':
      return 'DOCUMENTS';
    case 'DataRecipientCategory':
      return 'DATARECIPIENTCATEGORIES';
    default:
      return entityType.toUpperCase();
  }
};

export const getModifiedEnitityType = (entityType) => {
  switch (entityType) {
    case 'dataSubjectCategories':
      return 'DataSubjectCategory';
    case 'processingCategories':
      return 'ProcessingCategory';
    case 'personalDataCategories':
      return 'PersonalDataCategory';
    case 'personalDataItems':
      return 'PersonalDataItem';
    case 'dataSourceCategories':
      return 'DataSourceCategory';
    case 'dataSources':
      return 'DataSource';
    case 'securityMeasuresDescription':
      return 'SecurityMeasure';
    case 'retentionTermOffset':
      return 'RetentionTermOffset';
    case 'references':
      return 'Reference';
    case 'dataRecipientCategories':
      return 'DataRecipientCategory';
    default:
      return entityType;
  }
};

export const getActionType = (entityType, masterdataType) => {
  if (masterdataType === 'customSimpleMasterData')
    return 'MASTERDATA:CUSTOM_SIMPLE_DATA';
  switch (entityType) {
    case 'dataSubjectCategories':
      return 'DATA_SUBJECT_CATEGORIES';
    case 'processingCategories':
      return 'PROCESSING_CATEGORIES';
    case 'personalDataCategories':
      return 'MASTERDATA:PERSONAL_DATA_CATEGORIES';
    case 'dataRecipientCategories':
      return 'MASTERDATA:DATARECIPIENTCATEGORIES';
    case 'personalDataItems':
      return 'PERSONAL_DATA_ITEMS';
    case 'dataSourceCategories':
      return 'MASTERDATA:DATA_SOURCE_CATEGORIES';
    case 'dataSources':
      return 'MASTERDATA:DATASOURCES';
    case 'retentionTermOffset':
      return 'MASTERDATA:RETENTION_OFFSETS';
    default:
      return 'SIMPLE_ENTITY';
  }
};

export const getEnvironmentFilterMenu = (defaultMenuItems, isGlobal) => {
  const filterItems = (menuItems) => {
    const keyToExclude = isGlobal
      ? ['userOrg', 'links_processedData']
      : ['organisations'];

    return menuItems
      .filter((item) => !keyToExclude.includes(item.key))
      .map((item) => ({
        ...item,
        subMenu:
          isGlobal && item.key === 'processedData'
            ? filterItems(item.subMenu || [])
            : item.subMenu
      }));
  };

  return filterItems(defaultMenuItems);
};

export const filterMenuItems = (
  userPermissions,
  userHierarchy,
  filterMenus
) => {
  let menuItems = deepCopy(filterMenus);
  const {
    viewOrganisation,
    viewUser,
    viewDocumentRecord,
    viewPrivacyRecord,
    viewAssessment,
    viewPIPLProcessing
  } = userPermissions;
  if ((!viewOrganisation && !viewUser) || userHierarchy === FLAT) {
    menuItems = menuItems.filter((item) => item.key !== 'userOrg');
  } else {
    const userOrgIndex = menuItems.findIndex((item) => item.key === 'userOrg');
    if (userOrgIndex !== -1) {
      if (!viewOrganisation) {
        menuItems[userOrgIndex].subMenu = menuItems[
          userOrgIndex
        ].subMenu.filter((item) => item.key !== 'organisations_userOrg');
      }
      if (!viewUser) {
        menuItems[userOrgIndex].subMenu = menuItems[
          userOrgIndex
        ].subMenu.filter((item) => item.key !== 'users_userOrg');
      }
      menuItems[userOrgIndex].defaultFilter =
        menuItems[userOrgIndex].subMenu[0].key;
    }
  }
  if (!viewDocumentRecord) {
    menuItems = menuItems.filter((item) => item.key !== 'documents');
  }
  if (!viewPIPLProcessing) {
    const legalQualificationIndex = menuItems.findIndex(
      (item) => item.key === 'legalqualification'
    );
    menuItems[legalQualificationIndex].subMenu = menuItems[
      legalQualificationIndex
    ].subMenu.filter(
      (item) => item.key !== 'fairnessOfDecisions_legalqualification'
    );
  }
  if (!viewAssessment) {
    menuItems = menuItems.filter((item) => item.key !== 'assessment');
  }
  if (!viewPrivacyRecord) {
    menuItems = menuItems.filter(
      (item) =>
        item.key !== 'processedData' &&
        item.key !== 'stakeholder' &&
        item.key !== 'securityMeasures' &&
        item.key !== 'legalqualification'
    );
  }
  return menuItems;
};

export const getDeleteConfirmationMessage = (entityType, entityName) => {
  switch (entityType) {
    case 'rights':
      return (
        <FormattedMessage
          id="DataSubjectRight.delete"
          description="Do you want to delete the data subject right?"
          defaultMessage="Do you want to delete the data subject right - {entityName}?"
          values={{ entityName: `'${entityName}'` }}
        />
      );
    case 'securityMeasures':
      return (
        <FormattedMessage
          id="SecurityMeasure.delete"
          description="Do you want to delete the security measure?"
          defaultMessage="Do you want to delete the security measure - {entityName}?"
          values={{ entityName: `'${entityName}'` }}
        />
      );

    default:
      return (
        <FormattedMessage
          id="MasterDataItem.delete"
          description="Do you want to delete the item?"
          defaultMessage="Do you want to delete the item {entityName}?"
          values={{ entityName: `'${entityName}'` }}
        />
      );
  }
};

export const getUsageKeys = (usageMapValues) => {
  let usageKeys = [];
  usageMapValues.forEach((item) => {
    const keys = Object.keys(item);
    usageKeys = usageKeys.concat(keys);
  });
  const modifiedUsageKeys = Array.from(new Set(usageKeys));
  return modifiedUsageKeys;
};

export const isMasterDataEditFormVisible = (isUsed, hasNoLimit, isEdit) => {
  const isEditRight = isUsed ? hasNoLimit : true;
  return !isEdit ? true : isEditRight;
};

export const getItemsWithModifiedTags = (items) => {
  const modifiedItems =
    items &&
    items.map((item) => {
      let tagsList = '';
      if (item.tags && item.tags.length > 0) {
        tagsList = item.tags.map((tag) => tag.name);
        tagsList = tagsList.join(', ');
      }
      return { ...item, tags: tagsList };
    });
  return modifiedItems;
};

export const getGraphNodeLabel = (color) => {
  switch (color) {
    case '#ff7c6e':
      return commonTranslations.privacyRecords;
    case '#ffc86e':
      return uppercaseSingular('organisation');
    case '#97c652':
      return uppercaseSingular('personalDataItem');
    case '#b2c3d1':
      return uppercaseSingular('dataSource');
    case '#f1c0ef':
      return uppercasePlural('legalGrounds');
    case '#30a872':
      return uppercaseSingular('personalDataCategory');
    case '#89a8f4':
      return uppercaseSingular('dataSubjectCategory');
    case '#29a4d2':
      return uppercaseSingular('processingCategory');
    case '#fff06e':
      return commonTranslations.countryName;
    case '#b5880b':
      return uppercaseSingular('dataRecipientCategory');
    case '#b90c3b':
      return uppercaseSingular('accountability');
    case '#8c779c':
      return uppercaseSingular('securityMeasure');
    case '#f32a5e':
      return eventTypeTranslations.Impact;
    case '#21dddd':
      return eventTypeTranslations.MitigatingMeasure;
    case '#4c4946':
      return uppercaseSingular('policies');
    case '#329595':
      return uppercaseSingular('purpose');
    case '#93128f':
      return uppercaseSingular('qualityControl');
    case '#89d7f4':
      return uppercaseSingular('references');
    case '#c24f95':
      return uppercasePlural('dataSubjectRights');
    case '#20f512':
      return eventTypeTranslations.RiskDetail;
    case '#a55415':
      return eventTypeTranslations.Threat;
    case '#a8324c':
      return uppercaseSingular('tenant');
    case '#d88299':
      return uppercaseSingular('accountabilitySecurityMeasure');
    case '#bb33ff':
      return uppercasePlural('supervisoryAuthorities');
    case '#4d6880':
      return uppercasePlural('dataSourceCategories');
    case '#620566':
      return uppercaseSingular('fairnessOfDecisions');
    case '#61481C':
      return uppercaseSingular('specialCharacteristics');
    case '#4CACBC':
      return uppercaseSingular('documentType');
    case '#66ccff':
      return uppercaseSingular('customSimpleMasterData');
    default:
      return color;
  }
};

export const transformPathEntityType = (type) => {
  switch (type) {
    case 'datasources':
      return 'dataSources';
    case 'securitymeasures':
      return 'securityMeasures';
    case 'organisations':
      return 'organisation';
    case 'personaldataitems':
      return 'personalDataItems';
    case 'legalgrounds':
      return 'legalGrounds';
    case 'personaldatacategories':
      return 'personalDataCategories';
    case 'datasubjectcategories':
      return 'dataSubjectCategories';
    case 'processingcategories':
      return 'processingCategories';
    case 'datarecipientcategories':
      return 'dataRecipientCategories';
    case 'mitigatingmeasures':
      return 'mitigatingMeasures';
    case 'qualitycontrols':
      return 'qualityControls';
    case 'datasubjectrights':
      return 'dataSubjectRights';
    case 'riskdetails':
      return 'riskDetails';
    case 'breaches':
      return 'breach';
    case 'processings':
      return 'processing';
    case 'assessments':
      return 'assessment';
    case 'supervisoryauthorities':
      return 'supervisoryAuthorities';
    case 'documentrecordtypes':
      return 'documentRecordTypes';
    case 'specialcharacteristics':
      return 'specialCharacteristics';
    default:
      return type;
  }
};

export const getRequestType = (uniqueId) =>
  ({
    ppControllers: 'controllers',
    ppProcessors: 'processors',
    ppExecutingEntities: 'executingentities',
    ppDataRecipients: 'datarecipients'
  }[uniqueId] || 'customStakeholders');
