import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown, MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import { getURLWithParams, updateDataItems } from '../saga-utils';

export function* resetAndfetchDataRecipientCategories(action) {
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:REQUEST:INIT' });
  yield fetchDataRecipientCategoryList(action);
}

export function* fetchDataRecipientCategoryList(action) {
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH' });
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT, source = '' } = action;

  try {
    let filterParams = {};
    let recordsFilterParams = [];
    let unused = false;
    let filteredOn = Immutable.Map();
    let recordsFilteredOn = Immutable.Map();
    let pageSearchTextObj = {};
    let searchParameters = {};
    if (action.isPersistedData) {
      const currentState = yield select(state => state.environment.dataRecipientCategories);
      if (currentState) {
        filterParams = currentState.get('filterParams').toJS();
        filteredOn = currentState.get('filteredOn');
        recordsFilterParams = currentState.get('recordsFilterParams').toJS();
        recordsFilteredOn = currentState.get('recordsFilteredOn');
        unused = source !== 'records' && currentState.get('unused');
        pageSearchTextObj = currentState.get('pageSearchText').toJS();
        const searchParam = currentState.get('searchText').toJS();
        const recordSearchParams = searchParam ?
          { searchKey: 'name', searchText: searchParam.name } : {};
        searchParameters = pageSearchTextObj && source !== 'records' ?
          { searchKey: 'name', searchText: pageSearchTextObj.name } : recordSearchParams;
      }
    }
    const { sortOn, sortOrder } = source === 'records' ? recordsFilterParams : filterParams;


    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn: source === 'records' ? recordsFilteredOn : filteredOn },
        position, searchParameters, 'datarecipientcategories', rowCount, undefined, unused), null, {});

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH:SUCCESS',
          items: modifiedItems,
          dataRecipientCategoryData: action.dataRecipientCategoryData });
        break;
      }
      default: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          } });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FETCH:FAIL', error: err.message });
    yield put({ type: 'RECORDS:DATARECIPIENTCATEGORIES:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* filterDataRecipientCategories(action) {
  const { filterParams, appendFilter = true, source = '', searchParams: { searchText } = {} } = action;
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:FILTER', filterParams, appendFilter, source });
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH', searchKey: 'name', searchText });
  if (appendFilter) yield fetchDataRecipientCategoryList(action);
}

export function* searchDataRecipientCategories(action) {
  const { searchParams: { searchKey, isPageSearch }, searchParams, rowCount = MASTERDATA_SEARCH_LIMIT } = action;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';
  const currentState = yield select(state => state.environment.dataRecipientCategories);

  const { sortOn, sortOrder, filteredOn, searchParameters, searchText, unused } =
  getFilterAndSearchParamsFromState(currentState, searchParams, searchMode);

  yield put({ type: `MASTERDATA:DATARECIPIENTCATEGORIES:${searchMode}`, searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParameters, 'datarecipientcategories', rowCount, undefined, unused), null, {});
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map(item => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({ type: `MASTERDATA:DATARECIPIENTCATEGORIES:${searchMode}:SUCCESS`,
          searchKey,
          searchText,
          items: modifiedItems });
        break;
      }
      default: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          } });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    registry.get('logger').error(err);
    yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* updateDataRecipientCategories(action) {
  const { isEdit, data, source } = action;
  const request = registry.get('request');
  const requestData = Object.assign({}, data);
  let response;
  try {
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      response = yield request.post(`/v1/masterdata/datarecipientcategories`, requestData);
    } else {
      response = yield request.put(`/v1/masterdata/datarecipientcategories/${requestData.id}?withJob=false`,
        requestData);
    }
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationMessages[isEdit],
            type: 'success'
          } });

        const dataRecipientCategoryData = response.body;

        // Handle consistency in the front end.
        if (isEdit) {
          yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:SUCCESS', dataRecipientCategoryData });
          yield updateDataRecipientCategoryItems({
            type: 'edit',
            data: response.body,
            source
          });
        } else {
          yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:SUCCESS',
            dataRecipientCategoryData,
            position: 0 });
          const modifiedDataRecipientCategoryData = {
            ...dataRecipientCategoryData,
            key: `${dataRecipientCategoryData.name}`
          };
          yield fetchDataRecipientCategoryList({ dataRecipientCategoryData: modifiedDataRecipientCategoryData,
            isPersistedData: true });
        }
        break;
      }
      case 304: {
        yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:SUCCESS',
          dataRecipientCategoryData: requestData.value });
        break;
      }
      case 409:
      case 412: {
        yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:FAIL', error: response.body.msg });
        break;
      }
      default: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          } });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:UPSERT:FAIL', error: err.message });
  }
}

export function* fetchDataRecipientCategoryItem(action) {
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry.get('request')
        .get(`/v1/masterdata/datarecipientcategories/${action.id}`, null);
      const item = response.body;
      const modifiedItem = {
        ...item,
        key: item.name
      };
      switch (response.status) {
        case 200: {
          yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:SUCCESS',
            dataRecipientCategoryData: modifiedItem });
          break;
        }
        default: {
          yield put({ type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            } });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:ITEM:FETCH:FAIL', error: err.message });
    }
  }
}

export function* deleteDataRecipientCategories(action) {
  const { dataItemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(`/v1/masterdata/datarecipientcategories/${dataItemId}`, null);
    switch (response.status) {
      case 204: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('dataRecipientCategories'),
            type: 'success'
          } });
        // Handle consistency in the front end.
        yield updateDataRecipientCategoryItems({
          type: 'delete',
          dataRecipientCategoryId: dataItemId
        });
        break;
      }
      default: {
        yield put({ type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          } });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

function* updateDataRecipientCategoryItems(action) {
  const { type, data, dataRecipientCategoryId, source } = action;
  const dataRecipientCategoryState = yield select(state => state.environment.dataRecipientCategories);
  let position = null;
  let items = dataRecipientCategoryState ? dataRecipientCategoryState.get('items') : Immutable.List();
  let newItems = dataRecipientCategoryState ? dataRecipientCategoryState.get('newItems') : Immutable.List();
  if (type === 'delete') {
    position = dataRecipientCategoryState.get('position') - 1;
    items = updateDataItems(items, type, dataRecipientCategoryId);
    newItems = updateDataItems(newItems, type, dataRecipientCategoryId);
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = transformDataRecipientCategoryItem(data);
    items = updateDataItems(items, type, data.id, source, modifiedItem);
    newItems = updateDataItems(newItems, type, data.id, source, modifiedItem);
  }
  yield put({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:UPDATE:SUCCESS', items, newItems, position });
}

const transformDataRecipientCategoryItem = item => ({
  ...item,
  key: `${item.name}`
});


const notificationMessages = {
  true: notificationtranslations.dataRecipientCategoryUpdateSuccess,
  false: notificationtranslations.dataRecipientCategoryCreated
};

const getFilterAndSearchParamsFromState = (currentState, searchParams, searchMode) => {
  // Filter parameters
  let filterParams = {};
  let unused = false;
  let pageSearchTextObj = {};
  if (currentState) {
    filterParams = currentState.get('filterParams').toJS();
    pageSearchTextObj = currentState.get('pageSearchText').toJS();
    unused = currentState.get('unused');
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = currentState && searchMode !== 'LIST:SEARCH' ? currentState.get('filteredOn') : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;

    let searchTextObj = Immutable.Map();
    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined && currentState) {
      searchTextObj = searchMode === 'LIST:SEARCH' ?
        currentState.get('searchText') : currentState.get('pageSearchText');
      searchText = searchTextObj.get(searchKey) || '';
    }
    searchParameters = Object.assign({}, searchParams, { searchText });
  } else {
    searchParameters = pageSearchTextObj ? { searchKey: 'name', searchText: pageSearchTextObj.name } : {};
  }

  return ({ sortOn, sortOrder, filteredOn, searchParameters, searchText, unused });
};

