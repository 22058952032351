import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';
import { filterMenu } from './defaultFilters';
import { getUpdatedFilterMenu } from '../common-utils';

const defaultState = Immutable.fromJS({
  isEditable: true,
  isFetching: false,
  isUpdating: false,
  unSavedData: false,
  data: Immutable.Map(),
  prevLocation: '/privacyrecords',
  recordId: undefined,
  recordName: '',
  actionError: undefined,
  showConfirmDialog: true,
  filterMenu: [],
  currentFilter: 'general',
  users: Immutable.List(),
  layout: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'BREACH:DETAIL:INIT':
    case 'BREACH:DETAIL:RESET':
      return state
        .set('recordId', defaultState.get('recordId'))
        .set('data', defaultState.get('data'))
        .set('unSavedData', defaultState.get('unSavedData'))
        .set('isEditable', defaultState.get('isEditable'))
        .set('prevLocation', defaultState.get('prevLocation'))
        .set('isFetching', defaultState.get('isFetching'))
        .set('actionError', defaultState.get('actionError'))
        .set('showConfirmDialog', defaultState.get('showConfirmDialog'))
        .set('currentFilter', state.get('currentFilter') || 'general')
        .set('filterMenu', filterMenu)
        .set('isUpdateLocked', defaultState.get('isUpdateLocked'));

    case 'BREACH:DETAIL:FETCH': {
      const queryParams = getQueryStrings();
      let prevLocation = state.get('prevLocation');
      if (queryParams.from === 'view') {
        prevLocation = `/breach/${action.recordId}/view`;
      } else if (queryParams.from === 'privacyrecords') {
        prevLocation = '/privacyrecords';
      } else if (queryParams.from === 'dashboard') {
        prevLocation = '/dashboard';
      } else if (queryParams.from === 'messagecenter') {
        prevLocation = '/messagecenter';
      } else if (queryParams.from === 'overview') {
        prevLocation = '/privacyrecords?from=overview';
      }
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isFetching', true)
        .set('prevLocation', prevLocation)
        .set('recordId', action.recordId);
    }

    case 'BREACH:FETCH_LAYOUT:SUCCESS':
      return state.set('layout', action.data);

    case 'BREACH:DETAIL:FETCH:SUCCESS':
      return state
        .set('isEditable', action.isEditable)
        .set('isFetching', false)
        .set('data', Immutable.Map(action.data))
        .set('recordName', action.recordName);
    case 'BREACH:DETAIL:FETCH:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isFetching', false);

    case 'BREACH:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'BREACH:FILTER_MENU:UPDATE':
      return state.set(
        'filterMenu',
        getUpdatedFilterMenu(
          state.get('filterMenu'),
          action.key,
          action.disable
        )
      );
    case 'BREACH:DETAIL:UPSERT':
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('isUpdating', true);
    case 'BREACH:UNSAVED:DATA:RESET':
    case 'BREACH:DETAIL:UPSERT:SUCCESS':
      return state
        .set('isUpdating', false)
        .set('data', Immutable.Map(action.data))
        .set('unSavedData', false);
    case 'BREACH:DETAIL:UPSERT:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false);
    case 'BREACH:DETAIL:UPSERT:LOCKED':
      return state.set('isUpdateLocked', true);
    case 'BREACH:PROPERTY:UPDATE':
      return state.set('actionError', defaultState.get('actionError'));
    case 'BREACH:PROPERTY:UPDATE:SUCCESS':
      return state
        .set('data', Immutable.Map(action.data))
        .set('unSavedData', true);
    case 'BREACH:PROPERTY:UPDATE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'BREACH:UNSAVED:PROPERTY:RESET':
      return state
        .set('unSavedData', defaultState.get('unSavedData'))
        .set('isUpdating', true);
    case 'RECORDS:UNSAVED_DATA:RESET':
      return state.set('unSavedData', defaultState.get('unSavedData'));
    case 'BREACH:UNSAVED:DATA:SET':
    case 'BREACH:UNSAVED:PROPERTY:SET':
      return state.set('isUpdating', false).set('unSavedData', true);
    case 'BREACH:SHOW:CONFIRM:DIALOG:RESET': {
      return state.set('showConfirmDialog', false);
    }
    case 'BREACH:USERS:LIST:REQUEST:FETCH':
      return state.set('users', defaultState.get('users'));
    case 'BREACH:USERS:LIST:REQUEST:SUCCESS': {
      return state.set('users', Immutable.List(action.data));
    }
    case 'BREACH:COMMENT:DELETE:INIT':
    case 'BREACH:COMMENT:SAVE:INIT':
      return state.set('data', defaultState.get('data'));

    case 'BREACH:COMMENT:DELETE:SUCCESS':
    case 'BREACH:COMMENT:SAVE:SUCCESS': {
      return state.set('data', Immutable.Map(action.data));
    }
    case 'BREACH:NOTE:CHANGE':
      return state.set('isUpdating', true);
    case 'BREACH:NOTE:CHANGE:SUCCESS':
      return state.set('isUpdating', false);
    default:
      return state;
  }
};
