import { connect } from 'react-redux';
import ProcessingGroundList from './processingGroundList';

const mapStateToProps = state => ({
  isFetching: state.processingGrounds.get('isFetching'),
  searchText: state.processingGrounds.get('searchText'),
  searchItems: state.processingGrounds.get('searchItems').toJS(),
  sortOrder: state.processingGrounds.get('filterParams').get('sortOrder'),
  sortKey: state.processingGrounds.get('filterParams').get('sortOn'),
  filterColumn: state.processingGrounds.get('filterColumn').toJS(),
  filteredOn: state.processingGrounds.get('filteredOn').toJS(),
  processingGrounds: state.processingGrounds.get('processingGrounds'),
  error: state.processingGrounds.get('error')
});

const mapDispatchToProps = dispatch => ({
  initProcessingGrounds: (selectedControllers, selectedPersonalDataItems, jurisdiction, filterType) => {
    dispatch({ type: 'PROCESSING_GROUNDS:LIST:INIT',
      selectedControllers,
      selectedPersonalDataItems,
      filterType,
      jurisdiction });
  },
  onChooseFilter: (filterParams) => {
    dispatch({ type: 'PROCESSING_GROUNDS:LIST:FILTER_INIT', filterParams });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'PROCESSING_GROUNDS:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingGroundList);
