import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import { eventCodeTranslations } from '@packages/utils/commontranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

import { transformUserName } from '../../../environment/components/saga-utils';

export function* resetAndfetchAuditTrailMessages(action) {
  yield put({ type: 'AUDIT_TRAILS:LIST:INIT' });
  yield fetchAuditTrailMessages(action);
}

export function* fetchAuditTrailMessages(action) {
  const position = action && action.position ? action.position : 0;
  const rowCount = action && action.rowCount ? action.rowCount : 15;

  try {
    const auditTrailState = yield select(state => state.dashboard.auditTrail);
    let filterParams = {};
    if (auditTrailState) {
      filterParams = auditTrailState.get('filterParams').toJS();
    }
    const { sortOn, sortOrder } = filterParams;
    const filteredOn = auditTrailState ? auditTrailState.get('filteredOn') : Immutable.Map();

    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        position || 0, null, rowCount), null, {});

    const modifiedData = transformResponseData(response.body);
    switch (response.status) {
      case 200:
        yield put({ type: 'AUDIT_TRAIL:LIST:FETCH:SUCCESS', items: modifiedData });
        break;
      default:
        yield put({ type: 'AUDIT_TRAIL:LIST:FETCH:FAIL', error: 'Audit trail message fetching failed' });
    }
  } catch (err) {
    yield handleServiceDown(err, 'audit');
    yield put({ type: 'AUDIT_TRAIL:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* filterAuditTrailEvent(action) {
  const filterParams = action.filterParams ? action.filterParams : {};

  yield put({ type: 'AUDIT_TRAIL:LIST:FILTER', filterParams });
  yield fetchAuditTrailMessages(action);
}


export function* searchAuditTrailEvent(action) {
  let { searchParams } = action;
  const searchResultsCount = 100;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;
  try {
    // For first time rendering of search items, searchText will not be defined
    const auditTrailState = yield select(state => state.dashboard.auditTrail);

    if (searchText === undefined) {
      const searchTextObj = auditTrailState ? auditTrailState.get('searchText') : Immutable.Map();
      searchText = searchTextObj.get(searchKey) || '';
      searchParams = { ...searchParams, searchText };
    }

    const response = yield registry.get('request')
      .get(getURLWithParams(null, action.position || 0, searchParams, searchResultsCount), null, {});

    switch (response.status) {
      case 200:
        yield put({ type: 'AUDIT_TRAIL:LIST:SEARCH:SUCCESS', searchKey, searchText, items: response.body });
        break;
      default:
        yield put({ type: 'AUDIT_TRAIL:LIST:FETCH:FAIL', error: 'Audit trail message fetching failed' });
    }
  } catch (err) {
    yield handleServiceDown(err, 'audit');
    yield put({ type: 'AUDIT_TRAIL:LIST:FETCH:FAIL', error: err.message });
  }
}

// getURLWithParams uses the parameters filterParams, position, searchParams
const getURLWithParams = (filterParams, position, searchParams, rowCount = 15) => {
  let url = `/v1/audit/messages?offset=${position}&numberOfResults=${rowCount}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);

      // If the filterKey is timestamp, the filtering should be done
      // seperately for fromDate and toDAte
      if (filterKey === 'timestamp') {
        if (filteredOnItem.fromDate) {
          const date = JSON.parse(JSON.stringify(filteredOnItem.fromDate));
          (url = `${url}&filter=fromDate%3D${encodeURIComponent(date)}`);
        }
        if (filteredOnItem.toDate) {
          const date = JSON.parse(JSON.stringify(filteredOnItem.toDate));
          (url = `${url}&filter=toDate%3D${encodeURIComponent(date)}`);
        }
      } else if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const modifiedFilterKey = (filterKey === 'name') ? 'user' : filterKey;
          const filterText = (typeof (x) === 'object') ? x.props.defaultMessage : x;
          (url = `${url}&filter=${modifiedFilterKey}%3D${encodeURIComponent(filterText)}`);
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }

  // If the searchKey is user the list is obtained from /users api
  // and for the others it is by /audit/messages api
  if (searchParams) {
    const { searchText, sortOn, sortOrder, searchKey } = searchParams;
    if (searchKey === 'name') {
      url = `/v1/users?offset=${position}&numberOfResults=${rowCount}`;
    }
    url = searchText ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

const transformResponseData = (responseData) => {
  const modifiedData = responseData.map(data => ({
    ...data,
    eventDescription: eventCodeTranslations[data.eventCode],
    lastName: data.user.lastName ? data.user.lastName : '',
    name: (transformUserName(data.user) || 'System')
  }));
  return modifiedData;
};
