import { put } from 'redux-saga/effects';
import registry from 'app-registry';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { getRecordType, handleServiceDown } from '@packages/utils/common-utils';

export default function* resetAndfetchRecordRevisions() {
  yield put({ type: 'RECORD_REVISIONS:LIST:FETCH' });
  try {
    // Get record type and record id from url params
    const paramValues = getParameterValuesFromHash(
      '/:recordType/:recordId/revisions'
    );
    const { recordType, recordId } = paramValues;
    const type = getRecordType(recordType); // get actual record type.

    // Get the current record details
    const recordDetailResponse = yield registry
      .get('request')
      .get(`/v1/records/${type}/${recordId}`, null);
    const currentRecord = recordDetailResponse.body || [];

    // Initialize the revisions with current version added.
    yield put({
      type: 'RECORD_REVISIONS:LIST:INIT',
      recordType,
      recordId,
      recordName: currentRecord.name,
      items: []
    });
    // Fetch all record revisions
    const currentVersions = transformResponseData(currentRecord.versions);

    yield put({
      type: 'RECORD_REVISIONS:LIST:FETCH:SUCCESS',
      items: currentVersions
    });
  } catch (err) {
    registry.get('logger').error(err);
    yield handleServiceDown(err, 'records');
    yield put({ type: 'RECORD_REVISIONS:LIST:FETCH:FAIL', error: err.message });
  }
}

const transformResponseData = (data) => {
  const modifiedData = data.map((d, index) => ({
    ...d,
    displayVersion: index === 0 ? 'Current' : d.version,
    changedByUser: d.lastChangedBy
      ? `${d.lastChangedBy.firstName} ${d.lastChangedBy.lastName}`
      : ''
  }));
  return modifiedData;
};
