import Immutable from 'immutable';
import { getUpdatedOrgData, removeNode, transformTreeObject } from '../utils';

const defaultState = Immutable.fromJS({
  data: Immutable.Map(),
  orgData: undefined,
  error: {
    message: '',
    isError: false
  },
  usage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'EXTERNAL_ORGANISATION:LIST:REQUEST':
      return state.set('data', defaultState.get('data'))
        .set('orgData', defaultState.get('orgData'))
        .set('error', defaultState.get('error'));
    case 'EXTERNAL_ORGANISATION:LIST:REQUEST:SUCCESS':
      return state.set('data', action.items);
    case 'EXTERNAL_ORGANISATION:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'EXTERNAL_ORGANISATIONS:UPDATE':
      return state.set('orgData', action.orgData);
    // Update the item in the org list items
    case 'EXTERNAL_ORGANISATIONS:UPDATE:SUCCESS': {
      const { selectedItem, isEdit } = action;
      const orgHierarchy = state.get('data');
      const orgData = state.get('orgData');
      const data = transformTreeObject(orgHierarchy, orgData, {});
      const result = getUpdatedOrgData(data, selectedItem, isEdit);
      return state.set('orgData', result);
    }
    // Remove the item from the org list items
    case 'EXTERNAL_ORGANISATIONS:DELETE:SUCCESS': {
      const orgHierarchy = state.get('data');
      const orgData = state.get('orgData');
      const data = transformTreeObject(orgHierarchy, orgData, {});
      const result = removeNode(action.orgId, data);
      return state.set('orgData', result);
    }
    case 'EXTERNAL_ORGANISATIONS:ITEM:USAGE:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'));
    case 'EXTERNAL_ORGANISATION:USAGE:SUCCESS': {
      return state.set('usage', action.usage);
    }
    default:
      return state;
  }
};
