import Immutable from 'immutable';
import { getFilteredOn, getSearchText, getSearchItems } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  initialItems: Immutable.List(),
  breachGrounds: Immutable.List(),
  filteredOn: Immutable.Map({
    jurisdiction: ['EU']
  }),
  filterParams: Immutable.Map({
    sortOn: 'article',
    sortOrder: 'ASC'
  }),
  filterColumn: Immutable.Map({ jurisdiction: 'jurisdiction' }),
  searchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'BREACH_GROUNDS:LIST:REQUEST:INIT':
      return state.set('filteredOn', state.get('filteredOn'))
        .set('searchText', state.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('filterParams', state.get('filterParams'))
        .set('breachGrounds', defaultState.get('breachGrounds'));
    case 'BREACH_GROUNDS:LIST:FETCH':
      return state.set('isFetching', true);
    case 'BREACH_GROUNDS:LIST:FETCH:SUCCESS':
      return state.set('isFetching', false)
        .set('initialItems', Immutable.List(action.initialItems))
        .set('breachGrounds', Immutable.List(action.items));
    case 'BREACH_GROUNDS:LIST:FETCH:FAIL':
      return state.set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'BREACH_GROUNDS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn'),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'BREACH_GROUNDS:LIST:FILTER:SUCCESS':
      return state.set('breachGrounds', Immutable.List(action.items));

    case 'BREACH_GROUNDS:LIST:SEARCH:SUCCESS':
      return state
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchItems', getSearchItems(action))
        .set('error', defaultState.get('error'));

    case 'BREACH_GROUNDS:LIST:FILTER:REMOVE':
      return state
        .set('filterParams', defaultState.get('filterParams'))
        .set('filteredOn', Immutable.Map({}))
        .set('searchText', defaultState.get('searchText'));
    case 'BREACH_GROUNDS:LIST:FILTER:RESET':
      return state
        .set('filterParams', defaultState.get('filterParams'))
        .set('filteredOn', Immutable.Map({ jurisdiction: ['EU'] }))
        .set('filterColumn', Immutable.Map({ jurisdiction: 'jurisdiction' }))
        .set('searchText', defaultState.get('searchText'));
    default:
      return state;
  }
};
