import React from 'react';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import AutoComplete from '../auto-complete';
import countryTranslations from './country.jsx';

class CountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
    this.caseInsensitiveFilter = this.caseInsensitiveFilter.bind(this);
    this.handleSelectedItem = this.handleSelectedItem.bind(this);
  }

  /*   componentWillReceiveProps(nextProps) {
    // Whenever the default value is changed, set it.
    if (nextProps.defaultValue !== '' && this.props.defaultValue !== nextProps.defaultValue) {
      const selectedCountry = countryTranslations.find(item => item.value === nextProps.defaultValue);
      if (selectedCountry) this.handleSelectedItem(selectedCountry, 0, this.props.input);
    }
  } */

  handleSelectedItem(selectedItem, index, input) {
    if (index !== -1) {
      if (input && input.onChange) {
        input.onChange(selectedItem.value);
      }

      if (this.props.onChange) {
        this.props.onChange(selectedItem.value);
        this.props.handleSelectCountry(selectedItem);
      }
    }
  }

  handleBlur(event) {
    const { formatMessage } = this.props.intl;
    const { value } = event.target;
    // Select user entered value from the country selector and add to respective entity.
    if (value) {
      const formattedSearchText =
        typeof value === 'object' ? formatMessage(value.country) : value;
      const selectedCountry = countryTranslations.find(
        (item) =>
          formatMessage(item.text.country).toLowerCase() ===
          formattedSearchText.toLowerCase()
      );
      if (selectedCountry) {
        this.props.input.onChange(selectedCountry.value);
      }
    } else if (value === '') {
      this.props.input.onChange(value);
    }
  }

  caseInsensitiveFilter(searchText, key) {
    const { formatMessage } = this.props.intl;
    const translatedCountry = formatMessage(key.country).toLowerCase();
    const formattedSearchText =
      typeof searchText === 'object'
        ? formatMessage(searchText.country)
        : searchText;
    return (
      translatedCountry.startsWith(formattedSearchText.toLowerCase()) === true
    );
  }

  render() {
    const {
      hintTextLabel,
      textFieldStyle,
      listStyle,
      input,
      defaultValue,
      label,
      subLabel,
      disableCountryList,
      helpNotes,
      labelStyle
    } = this.props;
    const { formatMessage } = this.props.intl;
    const selectedCountry = countryTranslations.find(
      (item) => item.value === ((input && input.value) || defaultValue)
    );
    const searchText =
      selectedCountry === undefined
        ? ''
        : formatMessage(selectedCountry.text.country);

    return (
      <div style={this.props.disabled ? styles.disabled : {}}>
        {disableCountryList ? (
          <div style={{ marginTop: '10px' }}>
            {label}: {searchText}
          </div>
        ) : (
          <div>
            <ListSubheader
              style={{
                ...styles.labelField,
                position: 'inherit',
                marginTop: '0px',
                marginBottom: '0px',
                ...labelStyle
              }}
            >
              {label}
              <div style={{ display: 'flex', float: 'right' }}>
                {helpNotes && (
                  <ArrowTooltip title={helpNotes}>
                    <IconButton style={styles.rightIcon}>
                      <ActionHelp color="primary" />
                    </IconButton>
                  </ArrowTooltip>
                )}
              </div>
            </ListSubheader>
            {subLabel && (
              <ListSubheader style={styles.subLabelField}>
                {subLabel}
              </ListSubheader>
            )}
            <AutoComplete
              id="country_selector"
              underlineShow={false}
              openOnFocus={true}
              fullWidth={true}
              maxSearchResults={10}
              hintText={formatMessage(hintTextLabel.props)}
              searchText={searchText}
              hintStyle={{ bottom: '7px', paddingLeft: '12px' }}
              textFieldStyle={{ ...styles.autoComplete, ...textFieldStyle }}
              listStyle={{ ...listStyle }}
              filter={this.caseInsensitiveFilter}
              dataSource={countryTranslations}
              sortDatasource={true}
              dataSourceConfig={{ text: 'text', value: 'value' }}
              {...input}
              onBlur={this.handleBlur}
              disabled={this.props.disabled}
              onNewRequest={(selectedItem, index) =>
                this.handleSelectedItem(selectedItem, index, input)
              }
            />
          </div>
        )}
      </div>
    );
  }
}

CountrySelector.propTypes = {
  hintTextLabel: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  subLabel: PropTypes.node,
  labelStyle: PropTypes.objectOf(PropTypes.string),
  textFieldStyle: PropTypes.objectOf(PropTypes.string),
  listStyle: PropTypes.objectOf(PropTypes.string),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: ''
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  onChange: PropTypes.func,
  handleSelectCountry: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  disableCountryList: PropTypes.bool,
  helpNotes: PropTypes.node
};

CountrySelector.defaultProps = {
  hintTextLabel: null,
  defaultValue: {},
  textFieldStyle: {},
  labelStyle: {},
  listStyle: {},
  input: {},
  disabled: false,
  label: null,
  subLabel: null,
  onChange: (e) => e,
  handleSelectCountry: (e) => e,
  disableCountryList: false,
  helpNotes: null
};

export default injectIntl(CountrySelector);
