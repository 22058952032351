import { connect } from 'react-redux';
import onwardTransfersSelector from './component';

const mapStateToProps = state => ({
  onwardTransfers: state.onwardTransfers.get('onwardTransfers'),
  position: state.onwardTransfers.get('position'),
  error: state.onwardTransfers.get('error'),
  searchResults: state.onwardTransfers.get('items')
});

const mapDispatchToProps = dispatch => ({
  initOnwardTransfers: () => {
    dispatch({ type: 'ONWARD_TRANSFERS:LIST:REQUEST:INIT' });
    dispatch({ type: 'ONWARD_TRANSFERS:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'ONWARD_TRANSFERS:LIST:REQUEST', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'ONWARD_TRANSFERS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(onwardTransfersSelector);
