
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  authenticationError:
    <FormattedMessage
      id="Login.authenticationError"
      defaultMessage="Authentication failure: A network error has occurred."
    />,
  subTenantError:
    <FormattedMessage
      id="Error.subTenantError"
      defaultMessage="At least one tenant must be selected"
    />,
  numOfDataSubjectMessage:
    <FormattedMessage
      id="RecordDetail.numberDataSubjects.error"
      description="Please provide a valid number"
      defaultMessage="Please provide a number between 0 - 2147483647"
    />,
  required:
    <FormattedMessage
      id="Error.required"
      description="Filling in this field is required"
      defaultMessage="Filling in this field is required"
    />,
  error:
    <FormattedMessage
      id="Error.error"
      description="Error"
      defaultMessage="Error"
    />,
  weakPassword:
    <FormattedMessage
      id="Error.password"
      description="Provide strong password"
      defaultMessage={'Please provide a stronger password. ' +
        'A strong password contains more characters than your current input. ' +
        'You may also use a sentence as a password.'}
    />,
  passwordMatchError:
    <FormattedMessage
      id="Error.passwordMatch"
      description="Password does not match"
      defaultMessage="Password does not match"
    />,
  identicalPasswordError:
    <FormattedMessage
      id="Error.identicalPasswordError"
      description="New password can not be the same as old password"
      defaultMessage="New password can not be the same as old password"
    />,
  invalidEmail:
    <FormattedMessage
      id="Error.email"
      description="Please provide a valid email address."
      defaultMessage="Please provide a valid email address."
    />,
  invalidPhoneNumber:
    <FormattedMessage
      id="Error.phoneNumber"
      description="Please provide a valid phone number."
      defaultMessage="Please provide a valid phone number."
    />,
  requiredFieldError:
    <FormattedMessage
      id="Error.requiredField"
      description="Please fill in the required fields."
      defaultMessage="Please fill in the required fields."
    />,
  fillAllFields:
    <FormattedMessage
      id="Error.fillAllField"
      description="Please fill all the fields."
      defaultMessage="Please fill all the fields."
    />,
  resultFieldError:
    <FormattedMessage
      id="Error.resultField"
      description="At least one result field must be selected"
      defaultMessage="At least one result field must be selected"
    />,
  changeLogError:
    <FormattedMessage
      id="Error.changeLog"
      description="Two versions must be selected to create a comparison."
      defaultMessage="Two versions must be selected to create a comparison."
    />,
  containChildEntities:
    <FormattedMessage
      id="Error.containChildEntities"
      description="Organisation entity contains child entities."
      defaultMessage="Organisation entity contains child entities."
    />,
  tokenExpired:
    <FormattedMessage
      id="UserDetail.tokenExpired"
      description="Token expired message."
      defaultMessage={'The link you attempted to use has expired.' +
        ' If you need to create a new user, please contact your administrator.'}
    />,
  rgbRangeError:
    <FormattedMessage
      id="Error.rgbRangeError"
      description="RGB range error"
      defaultMessage="Value must be between 0 and 255 inclusive."
    />,
  fileFormatError:
    <FormattedMessage
      id="Error.fileFormatError"
      description="File format error"
      defaultMessage="Only files with .png extensions allowed."
    />,
  masterDataItemInUse:
    <FormattedMessage
      id="Error.masterDataItem.inUse"
      description="Cannot edit the details of this entity as it is being used elsewhere."
      defaultMessage="Cannot edit the details of this entity as it is being used elsewhere."
    />,
  masterDataItemInUseToDelete:
    <FormattedMessage
      id="Error.masterDataItem.inUseToDelete"
      description="Cannot delete this entity as it is being used elsewhere."
      defaultMessage="Cannot delete this entity as it is being used elsewhere."
    />,
  attachmentUnavailable:
    <FormattedMessage
      id="Error.attachment.unavailable"
      description="File not yet available for download"
      defaultMessage="File not yet available for download"
    />,
  nameFieldError:
    <FormattedMessage
      id="ReportSearch.nameRequired"
      description="Providing a name for this report search is required"
      defaultMessage="Providing a name for this report search is required"
    />,
  attemptsExceeded:
    <FormattedMessage
      id="Users.attemptsExceeded"
      description="Attempts exceeded error message"
      defaultMessage={'Threshold for entering your e-mail address has exceeded. ' +
        'So please contact the administrator to receive a new invitation'}
    />,
  emailAddressMismatch:
    <FormattedMessage
      id="Users.emailAddressMismatch"
      description="Email adress entered doesn't match the original email address"
      defaultMessage="Email address entered doesn't match the original email address"
    />,
  reinvitationUsed:
    <FormattedMessage
      id="Users.reinvitationUsed"
      description="This re-invitation link has already been used"
      defaultMessage="This re-invitation link has already been used"
    />,
  progressApiError:
    <FormattedMessage
      id="Error.progressError"
      description="Error occurred while fetching progress, please try again later"
      defaultMessage="Error occurred while fetching progress, please try again later"
    />,
  minValue:
    <FormattedMessage
      id="Error.minValue"
      defaultMessage="Provide a value greater than 0"
    />,
  jurisdictionRequired:
    <FormattedMessage
      id="Error.jurisdictionRequired"
      defaultMessage="Selecting a jurisdiction for this privacy record is required"
    />,
  validIndex: <FormattedMessage
    id="Error.validIndex"
    defaultMessage="Provide a valid Index"
  />,
  sameIndicesError: <FormattedMessage
    id="Error.sameIndicesError"
    defaultMessage="No two indices must be same"
  />,
  maxSubTenantError:
    <FormattedMessage
      id="Error.maxSubTenantError"
      defaultMessage="Maximum sub-tenant limit is 10"
    />,
  unknownTiaCountry:
    <FormattedMessage
      id="Error.unknownTiaCountry"
      defaultMessage="This country is not available for assessment.
    Please contact your admin to add a new country"
    />,
  aclValidation: 
    <FormattedMessage
      id="Error.aclValidation"
      defaultMessage="Filling in users or organisations field is required"
    />,
  inValidPriority:
    <FormattedMessage
      id="Error.inValidPriority"
      defaultMessage="Enter a valid priority."
    />,
  invalidVatNumber: 
    <FormattedMessage
      id="Error.invalidVatNumber"
      defaultMessage="Enter a valid VAT number."
    />
};

export const customTranslations = (id, limitValue, data) => {
  switch (id) {
    case 'maxLimit':
      return (
        <FormattedMessage
          id="Validation.maxCharLimit"
          defaultMessage="Maximum character limit is {value}"
          values={{ value: limitValue }}
        />
      );
    case 'maxLimitMultiple':
      return (
        <FormattedMessage
          id="Validation.maxCharLimitMultiple"
          defaultMessage="Maximum character limit is {limit} and you have exceed it for {data} "
          values={{ limit: limitValue, data: data.join(', ') }}
        />
      );
    default: return ('');
  }
};
