/* eslint-disable array-callback-return */
import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { replace as replaceRouter } from 'connected-react-router';
import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  reverseAllFilteredFields,
  reverseFieldsByRecordType
} from '../../../utils.jsx';

export function* resetAndFetchRegistry(action) {
  yield put({ type: 'PUBLIC:REGISTRY:LIST:FETCH' });
  yield fetchRegistryList(action);
}

export function* fetchRegistryList(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  let filterParams = {};
  let filteredOn = Immutable.Map();
  let searchParams = {};
  if (action.isPersistedData) {
    const publicRegistryListState = yield select(
      (state) => state.publicRegistry
    );
    if (publicRegistryListState) {
      filterParams = publicRegistryListState.get('filterParams').toJS();
      filteredOn = publicRegistryListState.get('filteredOn');
      searchParams = publicRegistryListState.get('searchParams');
    }
  }
  const { sortOn, sortOrder } = filterParams;
  try {
    const request = registry.get('request');
    const response = yield request.get(
      getURLWithParams(
        { sortOn, sortOrder, filteredOn },
        position,
        rowCount,
        searchParams
      )
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PUBLIC:REGISTRY:LIST:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}

export function* upsertRegistry(action) {
  yield put({ type: 'PUBLIC:REGISTRY:UPSERT:INIT' });
  try {
    const request = registry.get('request');
    const response = yield request.post(`/v1/registry`, action.data);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.newRegistrySuccess,
            type: 'success'
          }
        });
        yield put(replaceRouter(`/settings/registry/${response.body.id}/edit`));
        break;
      }
      case 409:
        yield put({
          type: 'PUBLIC:REGISTRY:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}

export function* fetchRegistry() {
  const { id } = getParameterValuesFromHash(`/settings/registry/:id/edit`);
  yield put({ type: 'PUBLIC:REGISTRY:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get(`/v1/registry/${id}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PUBLIC:REGISTRY:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}

export function* getPublicFieldVisibility() {
  yield put({ type: 'PUBLIC:FIELDS:VISIBILITY:FETCH:INIT' });

  try {
    const request = registry.get('request');
    const response = yield request.get(
      '/v1/settings/registryFieldsVisibilitySetting',
      null,
      {}
    );
    switch (response.status) {
      case 200: {
        // response contains fields to be hidden. Get the visible ones and push to the component.
        const modifiedResponse = reverseAllFilteredFields(
          response.body,
          false,
          'publicFieldVisibility'
        );
        yield put({
          type: 'PUBLIC:FIELDS:VISIBILITY:FETCH:SUCCESS',
          visibility: modifiedResponse
        });
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      default:
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* updateRegistry(action) {
  const { fetchList = false } = action;
  const modifiedFilters = [];
  action.data.filters.map((filterData, index) => {
    // transform request data to have id list in value field
    if (
      filterData.field !== undefined ||
      (filterData.value && filterData.value.length !== 0)
    ) {
      // to remove field without valueList
      const valueList = filterData.value.map((item) =>
        item.value ? item.value.id : item.id
      );
      modifiedFilters.splice(index, 1, {
        field: filterData.field,
        value: valueList
      });
    }
  });
  try {
    const request = registry.get('request');
    const response = yield request.put(`/v1/registry/${action.data.id}`, {
      ...action.data,
      filters: modifiedFilters
    });
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PUBLIC:REGISTRY:FETCH:SUCCESS',
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.updateRegistrySuccess,
            type: 'success'
          }
        });
        if (fetchList) yield fetchRegistryList({});
        else if (action.isCloseRegistry) yield put(replaceRouter(`/settings`));
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}

export function* updatePublicFieldsVisibility(action) {
  const { selectedFields, recordType } = action;
  const modifiedSelectedFields = [...selectedFields, 'AssessmentChapters'];
  const request = registry.get('request');
  const settingsState = yield select((state) => state.publicRegistry);
  const visibilityState = settingsState && settingsState.get('visibility');
  const modifiedVisibilityState = visibilityState && {
    ...visibilityState,
    value: { ...visibilityState.value }
  };
  // the state is now containing all the visible fields for each record type.
  // Get the hidden fields and send the request.
  const modifiedAllFields = settingsState
    ? reverseAllFilteredFields(
      modifiedVisibilityState,
      true,
      'publicFieldVisibility'
    )
    : {};
  const modifiedReqData = {
    ...modifiedAllFields,
    value: {
      ...modifiedAllFields.value,
      [recordType]: reverseFieldsByRecordType(
        modifiedSelectedFields,
        recordType,
        'publicFieldVisibility'
      )
    }
  };

  try {
    const response = yield request.put(
      '/v1/settings/registryFieldsVisibilitySetting',
      modifiedReqData
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.fieldVisibilitypdated,
            type: 'success'
          }
        });
        // response contains fields to be hidden. Get the visible ones and push to the component.
        const modifiedResponse = reverseAllFilteredFields(
          response.body,
          false,
          'publicFieldVisibility'
        );
        yield put({
          type: 'PUBLIC:FIELDS:VISIBILITY:FETCH:SUCCESS',
          visibility: modifiedResponse
        }); // eslint-disable-line
        yield put({ type: 'SETTINGS:UNSAVED:DATA', value: false });
        break;
      }
      default: {
        yield put({ type: 'SETTINGS:UNSAVED:DATA', value: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}
export function* deleteRegistry(action) {
  const { items, isSingleDelete } = action;
  const request = registry.get('request');
  try {
    const registryIds = items.map((item) => item.id);
    const response = yield request.delete(`/v1/registry`, null, true, {
      registryIds
    });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isSingleDelete
              ? notificationtranslations.registryDeleted
              : notificationtranslations.registriesDeleted,
            type: 'success'
          }
        });
        if (isSingleDelete) {
          const publicRegistryState = yield select(
            (state) => state.publicRegistry
          );
          const reqItems = publicRegistryState.get('items');
          const newItems = updateDataItems(reqItems, items[0].id);
          yield put({ type: `PUBLIC:REGISTRY:LIST:UPDATE`, items: newItems });
        } else yield resetAndFetchRegistry(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
  }
}

export function* filterRegistries(action) {
  yield put({
    type: 'PUBLIC:REGISTRY:LIST:FILTER',
    filterParams: action.filterParams
  });
  yield fetchRegistryList({ ...action });
}

export function* searchRegistries(action) {
  yield put({
    type: `PUBLIC:REGISTRY:LIST:SEARCH`,
    searchParams: action.searchParams
  });
  yield fetchRegistryList({ isPersistedData: true });
}

export const updateDataItems = (items, currentId) => {
  const index = items.findIndex((item) => item.id === currentId);
  if (index !== -1) {
    const updatedItems = items.remove(index);
    return updatedItems;
  }
  return items;
};

const getURLWithParams = (filterParams, position, rowCount, searchParams) => {
  let url = `/v1/registry?offset=${position}&numberOfResults=${rowCount}`;
  const { sortOn, sortOrder, filteredOn } = filterParams;
  filteredOn.keySeq().forEach((filterKey) => {
    const filteredOnItem = filteredOn.get(filterKey);
    if (filteredOnItem && filteredOnItem.length > 0) {
      filteredOnItem.forEach((x) => {
        url = `${url}&filter=${filterKey}%3D${encodeURIComponent(x)}`;
      });
    }
  });
  if (searchParams) {
    const { searchText, searchKey } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
  }
  url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  return url;
};
