/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import DpoSelector from './component';

const mapStateToProps = state => ({
  entityDetails: state.organisationEntities.get('entityDetails'),
  isSuperAdmin: state.login.get('loggedUser') ?
    state.login.get('loggedUser').get('roles').indexOf('SuperAdministrator') !== -1 : false,
  isAdmin: state.login.get('loggedUser') ?
    state.login.get('loggedUser').get('roles').indexOf('Administrator') !== -1 : false
});

export default connect(mapStateToProps, null)(DpoSelector);
