import { connect } from 'react-redux';
import { TenantListLayout } from './tenantListLayout';

export { default as reducers } from './reducers';
export * from './sagas';

const mapStateToProps = state => ({
  hasCreateTenantPermission: state.tenants.list.get('hasCreateTenantPermission'),
  isSuperAdmin: state.login.get('loggedUser') ?
    state.login.get('loggedUser').get('roles').indexOf('SuperAdministrator') !== -1 : false,
  isOnlyPartner: state.login.get('loggedUser') ?
    state.login.get('loggedUser').get('roles').indexOf('PartnerAdministrator') !== -1 : false
});

function mapDispatchToProps(dispatch) {
  return {
    onMount: (isOnlyPartner) => {
      if (isOnlyPartner) {
        dispatch({ type: 'PARTNERS:LIST:FETCH_INIT', isOnlyPartner });
      }
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantListLayout);
