import registry from 'app-registry';
import { initialize } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown, MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT } from '@packages/utils/common-utils';
import { transformOrganisationEntity } from './utils';

export function* resetAndfetchOrganisationsEntities(action) {
  yield put({ type: 'ORGANISATION_ENTITY:LIST:REQUEST:INIT' });
  yield fetchOrganisationEntities(action);
}


export function* fetchOrganisationEntities(action) {
  const { position = 0, rowCount = MASTERDATA_FETCH_LIMIT, isPersistedData = false, isInternal,
    entityFilter } = action;
  try {
    let filterParams = [];
    if (isPersistedData) {
      const organisationsState = yield select(state => state.organisationEntities);
      filterParams = organisationsState.get('filterParams');
    }
    let url = `/v1/organisations?numberOfResults=${rowCount}&offset=${position}`;
    if (entityFilter) url = `${url}&filter=${entityFilter}`;
    else url = `${url}&filter=nonProspect=true`;
    if (isInternal !== undefined) {
      yield put({ type: 'ORGANISATIONS_ENTITY:LIST:FILTER', filterParams: [{ isInternal: isInternal.toString() }] });
      url = `${url}&filter=isInternal=${isInternal}`;
    } else if (filterParams) {
      filterParams.forEach((item) => {
        url = `${url}&filter=${Object.keys(item)}%3D${Object.values(item)}`;
      });
    }
    const response = yield registry.get('request')
      .get(url, null);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => transformOrganisationEntity(item));

        yield put({ type: 'ORGANISATION_ENTITY:LIST:REQUEST:SUCCESS', items: modifiedItems });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'ORGANISATION_ENTITY:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* filterOrganisationsEntities(action) {
  const filterParams = action.filterParams || {};
  if (action.isImportField) {
    yield put({ type: 'SUBTENANT:ORGANISATIONS_ENTITY:LIST:FILTER', filterParams });
    yield fetchHoldingOrganisations(action);
  } else {
    yield put({ type: 'ORGANISATIONS_ENTITY:LIST:FILTER', filterParams });
    yield fetchOrganisationEntities(action);
  }
}

export function* upsertOrganisationEntityDetail(action) {
  const { isEdit, data, source, updateList } = action;
  const request = registry.get('request');
  let requestData = { ...data};
  const isVendor = source === 'vendor';
  if (!requestData.parentOrgEntity || requestData.parentOrgEntity === 'root_id') {
    delete requestData.parentOrgEntity;
  } else {
    requestData = {
      ...requestData,
      parentOrgEntity: requestData.parentOrgEntity &&
        (typeof requestData.parentOrgEntity === 'string' ? requestData.parentOrgEntity : requestData.parentOrgEntity.id)
    };
  }
  if (isEdit) {
    const validatingFields = ['dataProtectionOfficer', 'contactDetail', 'representative'];
    validatingFields.forEach((field) => {
      if (requestData[field]) {
        requestData = {
          ...requestData,
          [field]: {
            ...requestData[field],
            email: requestData[field].email === '' ? null : requestData[field].email
          }
        };
      }
    });
    const additionalContactDetails = [];
    if (requestData.additionalContactDetails) {
      requestData.additionalContactDetails.forEach((item) => {
        let modifiedItem = item;
        if (item.memberDetails) {
          modifiedItem = {
            ...item,
            memberDetails: {
              ...item.memberDetails,
              email: item.memberDetails.email === '' ? null : item.memberDetails.email
            }
          };
        }
        additionalContactDetails.push(modifiedItem);
      });
      requestData = {
        ...requestData,
        additionalContactDetails
      };
    }
  }
  yield put({ type: 'ORGANISATION_ENTITY:DETAIL:INIT' });
  let response;
  try {
    if (source === 'records' || (!isEdit && source !== 'records')) {
      response = yield request.post(`/v1/organisations`, requestData);
    } else {
      const entityId = requestData.id;
      response = yield request.put(`/v1/organisations/${entityId}`, requestData);
    }

    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        const selectedItem = response.body;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messages[isEdit],
            type: 'success'
          }
        });
        yield put({ type: 'ORGANISATION_ENTITY:DETAIL:UPSERT:SUCCESS', organisationData: selectedItem });

        // If on success , call success handler.
        if (action.onSuccess) {
          yield put({
            type: action.onSuccess,
            isEdit,
            // eslint-disable-next-line no-underscore-dangle
            selectedItem: { ...requestData, _rev: selectedItem._rev }
          }); 
        } else if (source === 'vendorDetails') {
          yield put({ type: 'ORGANISATION_ENTITY:ITEM:FETCH:SUCCESS', items: response.body });
        } else if (updateList) {
          yield put({
            type: 'ORGANISATIONS:LIST:UPSERT',
            isEdit,
            selectedItem,
            isVendor
          });
        }

        // Re-render organisation entity form
        yield put(initialize('OrganisationEntityForm', { isInternal: isEdit ? selectedItem.isInternal : undefined }));
        break;
      }
      case 412:
      case 409:
        yield put({
          type: 'ORGANISATION_ENTITY:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    const errorMsg = (err.message && err.message.content) ? err.message.content
      : err.message;
    yield put({ type: 'ORGANISATION_ENTITY:DETAIL:UPSERT:FAIL', error: errorMsg });
  }
}

export function* deleteOrganisationEntity(action) {
  const { entityId } = action;
  const request = registry.get('request');
  yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:INIT' });
  try {
    const response = yield request.delete(`/v1/organisations/${entityId}`, null);

    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.organisationEntityDeleteSuccess,
            type: 'success'
          }
        });


        // If on success , call success handler.
        if (action.onSuccess) {
          yield put({ type: action.onSuccess, orgId: entityId });
        }
        yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:SUCCESS' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:FAIL' });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:FAIL' });
  }
}

const messages = {
  true: notificationtranslations.organisationEntityUpdateSuccess,
  false: notificationtranslations.organisationEntityCreated
};

export function* searchOrganisationEntities(action) {
  const { searchParams, isVendor } = action;
  const { searchKey, searchText } = searchParams;

  yield put({ type: 'ORGANISATION_ENTITY:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, isVendor), null, {});

    const items = response.body;
    const modifiedItems = items.map(item => transformOrganisationEntity(item));

    yield put({
      type: 'ORGANISATION_ENTITY:LIST:SEARCH:SUCCESS',
      searchKey,
      items: modifiedItems,
      searchText
    });
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    registry.get('logger').error(err);
    yield put({ type: 'ORGANISATION_ENTITY:LIST:SEARCH:FAIL', error: err.message });
  }
}

export const getURLWithParams = (searchParams, isVendor, isImportField, rowCount) => {
  let url = `/v1/organisations?numberOfResults=${MASTERDATA_SEARCH_LIMIT}&offset=0`;
  if (isImportField) url = `v1/organisations/parent-only?numberOfResults=${rowCount}`;
  if (!isVendor) url = `${url}&filter=nonProspect=true`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder, isInternal, entityFilter } = searchParams;
    url = isInternal !== undefined && !isImportField ? `${url}&filter=isInternal=${searchParams.isInternal}` : url;
    url = entityFilter !== undefined ? `${url}&filter=${searchParams.entityFilter}` : url;
    url = searchText ? `${url}&search=name=${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

export function* resetAndfetchImportOrganisations(action) {
  yield put({ type: 'SUBTENANT:ORGANISATION:LIST:FETCH:INIT' });
  yield fetchHoldingOrganisations(action);
}

export function* fetchHoldingOrganisations(action) {
  const { position = 0, rowCount = MASTERDATA_SEARCH_LIMIT, entityFilter, isPersistedData = false } = action;
  try {
    let url = `v1/organisations/parent-only?numberOfResults=${rowCount}&offset=${position}`;
    if (entityFilter) url = `${url}&filter=${entityFilter}`;
    else url = `${url}&filter=nonProspect=true`;
    let filterParams = [];
    if (isPersistedData) {
      const organisationsState = yield select(state => state.organisationEntities);
      filterParams = organisationsState.get('holdingFilterParams');
    }
    if (filterParams) {
      filterParams.forEach((item) => {
        url = `${url}&filter=${Object.keys(item)}%3D${Object.values(item)}`;
      });
    }
    const response = yield registry.get('request').get(url, null);
    switch (response.status) {
      case 200: {
        const orgList = response.body.map(item => transformOrganisationEntity(item));
        yield put({ type: 'SUBTENANT:ORGANISATION:LIST:FETCH:SUCCESS', orgList });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
  }
}

export function* importHoldingOrganisations(action) {
  const { data } = action;
  const request = registry.get('request');
  try {
    const response = yield request.post(`v1/organisations/${data.entityId}/import`, data);

    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.organisationImportSuccess,
            type: 'success'
          }
        });
        yield put({ type: 'ORGANISATIONS:LIST:REQUEST_INIT' });
        break;
      }
      case 412: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.duplicateParentFound,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
  }
}

export function* searchImportOrganisations(action) {
  const { searchParams, isVendor, isImport, rowCount } = action;
  const { searchKey, searchText } = searchParams;

  yield put({ type: 'SUBTENANT:ORGANISATION_ENTITY:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, isVendor, isImport, rowCount), null, {});

    const items = response.body;
    const modifiedItems = items.map(item => transformOrganisationEntity(item));

    yield put({
      type: 'SUBTENANT:ORGANISATION_ENTITY:LIST:SEARCH:SUCCESS',
      searchKey,
      items: modifiedItems,
      searchText
    });
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
  }
}

export function* fetchParentOrgDetails(action) {
  const { entityId } = action;

  const request = registry.get('request');
  try {
    if (entityId !== undefined) {
      const response = yield request.get(`/v1/organisations/${entityId}`);
      switch (response.status) {
        case 200: {
          yield put({ type: 'PARENT:ORGANISATION_ENTITY:ITEM:FETCH', parentFound: true });
          break;
        }
        case 404: {
          yield put({ type: 'PARENT:ORGANISATION_ENTITY:ITEM:FETCH', parentFound: false });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
        }
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'ORGANISATION_ENTITY:ITEM:FETCH:FAIL', error: err.message });
  }
}
