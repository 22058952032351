import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import {
  handleServiceDown,
  supportedJurisdictions
} from '@packages/utils/common-utils';
import { filterSelectedItems } from '../../legalGroundUtils';
import { EEAJurisdictions } from '../../common-utils';

export function* resetAndfetchTransferGrounds(action) {
  yield put({ type: 'TRANSFER_GROUNDS:LIST:REQUEST:INIT' });
  yield fetchTransferGrounds(action);
}

const getValidFilterData = (login) => {
  const supportedData = supportedJurisdictions.map(
    (jurisdiction) => jurisdiction.data
  );

  const pricingPlan = login.get('pricingPlanDetails').toJS();
  const { features } = pricingPlan.plan;

  const validFeatures = features.map((value) => value);
  const validJurisdictions = supportedData.filter((jurisdiction) =>
    validFeatures.includes(jurisdiction.toLowerCase())
  );

  return validJurisdictions.reduce(
    (prev, current) =>
      `${prev}filter=groundType=${current.toLowerCase()}_transfer_ground&`,
    '&'
  );
};

export function* fetchTransferGrounds(action) {
  yield put({ type: 'TRANSFER_GROUNDS:LIST:FETCH' });

  try {
    const { jurisdiction } = action;
    const { intl, login } = yield select((state) => state);
    const locale = intl?.locale || '';

    const jurisdictionFilter = EEAJurisdictions.includes(jurisdiction)
      ? 'EEACountries'
      : jurisdiction;
    const filterParam =
      jurisdiction === 'all'
        ? getValidFilterData(login)
        : `&filter=jurisdiction%3D${jurisdictionFilter}`;

    const defaultJurisdiction = jurisdiction === 'all' ? 'EU' : jurisdiction;
    const filterParams = {
      filteredOn: defaultJurisdiction.split('_'),
      filterKey: 'jurisdiction'
    };
    yield put({ type: 'TRANSFER_GROUNDS:LIST:FILTER', filterParams });

    const transferGroundType = getTransferGroundType(defaultJurisdiction);
    const url = `/v1/masterdata/legalgrounds?filter=groundType=${transferGroundType}${filterParam}&filter=language%3D${locale}`;

    const response = yield registry.get('request').get(url, null);
    switch (response.status) {
      case 200: {
        const responseData = response.body;
        const modifiedGrounds = responseData.map((item) => ({
          ...item,
          jurisdiction: item.jurisdiction.id
        }));
        const newtransferGroundsState = yield select(
          (state) => state.transferGrounds
        );
        const items = filterSelectedItems(
          modifiedGrounds,
          newtransferGroundsState
        ); // Filter transfer grounds

        yield put({
          type: 'TRANSFER_GROUNDS:LIST:FETCH:SUCCESS',
          items,
          initialItems: modifiedGrounds
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'TRANSFER_GROUNDS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* filterTransferGrounds(action) {
  const { filterParams } = action;
  yield put({ type: 'TRANSFER_GROUNDS:LIST:FILTER', filterParams });

  const transferGroundsState = yield select((state) => state.transferGrounds);
  const transferGrounds = transferGroundsState
    ? transferGroundsState.get('initialItems')
    : [];

  // Filter transfer grounds
  const items = filterSelectedItems(transferGrounds, transferGroundsState);
  yield put({ type: 'TRANSFER_GROUNDS:LIST:FILTER:SUCCESS', items });
}

export function* searchTransferGrounds(action) {
  const { searchParams } = action;

  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  // For first time rendering of search items, searchText will not be defined
  const transferGroundsState = yield select((state) => state.transferGrounds);
  const transferGrounds = transferGroundsState
    ? transferGroundsState.get('initialItems')
    : [];
  if (searchText === undefined) {
    const searchTextObj = transferGroundsState
      ? transferGroundsState.get('searchText')
      : Immutable.Map();
    searchText = searchTextObj.get(searchKey) || '';
  }

  const searchTextValue = searchText.toLowerCase();
  const items = transferGrounds.filter((transferGround) => {
    const index = transferGround[searchKey]
      .toLowerCase()
      .indexOf(searchTextValue);
    return index !== -1;
  });

  yield put({
    type: 'TRANSFER_GROUNDS:LIST:SEARCH:SUCCESS',
    searchKey,
    searchText,
    items
  });
}

const getTransferGroundType = (jurisdiction) => {
  switch (jurisdiction) {
    case 'EU':
    case 'EUI':
    case 'EU_EUI':
      return 'transfer_ground';
    case 'JP':
      return 'appi_transfer_ground';
    case 'CN':
      return 'pipl_transfer_ground';
    case 'TW':
      return 'pdpa_transfer_ground';
    case 'BR':
      return 'lgpd_transfer_ground';
    case 'SG':
      return 'sgpdpa_transfer_ground';
    case 'UK':
      return 'ukgdpr_transfer_ground';
    default:
      return 'transfer_ground';
  }
};
