import { connect } from 'react-redux';
import DatasourceSelector from './component';

const mapStateToProps = state => ({
  dataSources: state.environment.dataSources.get('items'),
  position: state.environment.dataSources.get('position'),
  error: state.dataSources.get('error'),
  searchResults: state.dataSources.get('items')
});

const mapDispatchToProps = dispatch => ({
  initDatasources: () => {
    dispatch({ type: 'MASTERDATA:DATASOURCES:LIST:REQUEST_INIT' });
  },
  getNextData: (position) => {
    dispatch({ type: 'DATASOURCES:LIST:NEXT_FETCH', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'DATA_SOURCES:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DatasourceSelector);
