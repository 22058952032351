/* eslint-disable array-callback-return */
import Immutable from 'immutable';
import { getFilteredOn } from '@packages/utils/reducer-utils';
import { getCurrentBulkItems } from '../../../../dsr/utils';

const defaultState = Immutable.fromJS({
  registryDetails: {},
  items: Immutable.List(),
  position: 0,
  visibility: undefined,
  isUpdating: false,
  bulkItems: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map()
});

const getfilterKey = (value) => {
  if (value.organisation) { // for dataSource
    return `${value.name} (${value.organisation.name} ${value.organisation.country.id})`;
  } else if (value.countries) { // for stakeholders & dataRecipientCategory
    return `${value.name} ${value.countries.map(country => (` (${country.id})`))}`;
  }
  return `${value.name}`;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PUBLIC:REGISTRY:FETCH': {
      return state.set('registryDetails', defaultState.get('registryDetails'));
    }
    case 'PUBLIC:REGISTRY:FETCH:SUCCESS': {
      const modifiedFilters = [];
      action.data.filters.map((data, index) => { // transform response data for renderItemSelector
        const modifiedValue = [...data.value];
        data.value.map((value, valueIndex) => {
          modifiedValue.splice(valueIndex, 1, {
            value: {
              ...value,
              key: getfilterKey(value),
              name: value.categoryType ? `${value.name} (${value.categoryType})` : value.name
            }
          });
          // for personalDataItems
        });
        modifiedFilters.splice(index, 1, { ...data, value: modifiedValue });
      });
      if (modifiedFilters.length < 2) { // to show 2 filter fields
        for (let i = 0; i < 2 - action.data.filters.length; i += 1) { modifiedFilters.push({}); }
      }
      return state.set('registryDetails', { ...action.data, filters: modifiedFilters });
    }
    case 'PUBLIC:REGISTRY:LIST:FETCH':
      return state
        .set('items', defaultState.get('items'));
    case 'PUBLIC:REGISTRY:LIST:FETCH:SUCCESS':
      return state
        .set('items', Immutable.List(action.data))
        .set('position', state.get('position') + action.data.length);
    case 'PUBLIC:FIELDS:VISIBILITY:FETCH:INIT':
      return state.set('visibility', defaultState.get('visibility'));
    case 'PUBLIC:FIELDS:VISIBILITY:FETCH:SUCCESS':
      return state.set('visibility', action.visibility)
        .set('isUpdating', false);
    case 'PUBLIC:FIELDS:VISIBILITY:UPDATE':
      return state.set('isUpdating', true);
    case 'PUBLIC:REGISTRY:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentBulkItems(action.item, items));
    }
    case 'PUBLIC:REGISTRY:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'PUBLIC:REGISTRY:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'PUBLIC:REGISTRY:LIST:SEARCH':
      return state.set('searchParams', action.searchParams)
        .set('searchText', Immutable.Map({ name: action.searchParams.searchText }));
    case 'PUBLIC:REGISTRY:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'PUBLIC:REGISTRY:LIST:FILTER:RESET': {
      return state.set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    }
    case 'PUBLIC:REGISTRY:CREATE:INIT':
    case 'PUBLIC:REGISTRY:UPSERT:INIT':
      return state.set('error', defaultState.get('error'));
    case 'PUBLIC:REGISTRY:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
