import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import { handleServiceDown } from '@packages/utils/common-utils';

import { filterSelectedItems } from '../../legalGroundUtils';
import { EEAJurisdictions } from '../../common-utils';

export function* resetAndfetchProcessingGrounds(action) {
  yield put({ type: 'PROCESSING_GROUNDS:LIST:REQUEST:INIT' });
  yield fetchProcessingGrounds(action);
}

export function* fetchProcessingGrounds(action) {
  yield put({ type: 'PROCESSING_GROUNDS:LIST:FETCH' });

  try {
    const { jurisdiction = 'EU', filterType } = action;
    const intlSelector = yield select((state) => state.intl);
    const locale = intlSelector?.locale || '';
    const filterParam = getFilterParam(jurisdiction, filterType);
    const type = getProcessingGroundType(jurisdiction);
    const filterParams = {
      filteredOn: jurisdiction.split('_'),
      filterKey: 'jurisdiction'
    };
    yield put({ type: 'PROCESSING_GROUNDS:LIST:FILTER', filterParams });
    const response = yield registry
      .get('request')
      .get(
        `/v1/masterdata/legalgrounds?filter=groundType=${type}${filterParam}&filter=language%3D${locale}`,
        null
      );
    switch (response.status) {
      case 200: {
        const responseData = response.body;
        const modifiedGrounds = responseData.map((item) => ({
          ...item,
          jurisdiction: item.jurisdiction.id
        }));

        const processingGroundsState = yield select(
          (state) => state.processingGrounds
        );
        const items = filterSelectedItems(
          modifiedGrounds,
          processingGroundsState
        ); // Filter processing grounds

        yield put({
          type: 'PROCESSING_GROUNDS:LIST:FETCH:SUCCESS',
          items,
          initialItems: modifiedGrounds
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({
      type: 'PROCESSING_GROUNDS:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* filterProcessingGrounds(action) {
  const { filterParams } = action;
  yield put({ type: 'PROCESSING_GROUNDS:LIST:FILTER', filterParams });

  const processingGroundsState = yield select(
    (state) => state.processingGrounds
  );
  const processingGrounds = processingGroundsState
    ? processingGroundsState.get('initialItems')
    : [];

  // Filter processing grounds
  const items = filterSelectedItems(processingGrounds, processingGroundsState);
  yield put({ type: 'PROCESSING_GROUNDS:LIST:FILTER:SUCCESS', items });
}

export function* searchProcessingGrounds(action) {
  const { searchParams } = action;

  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  // For first time rendering of search items, searchText will not be defined
  const processingGroundsState = yield select(
    (state) => state.processingGrounds
  );
  const processingGrounds = processingGroundsState
    ? processingGroundsState.get('initialItems')
    : [];
  if (searchText === undefined) {
    const searchTextObj = processingGroundsState
      ? processingGroundsState.get('searchText')
      : Immutable.Map();
    searchText = searchTextObj.get(searchKey) || '';
  }

  const searchTextValue = searchText.toLowerCase();
  const items = processingGrounds.filter((processingGround) => {
    const index = processingGround[searchKey]
      .toLowerCase()
      .indexOf(searchTextValue);
    return index !== -1;
  });

  yield put({
    type: 'PROCESSING_GROUNDS:LIST:SEARCH:SUCCESS',
    searchKey,
    searchText,
    items
  });
}

function getFilterParam(jurisdiction, filterType) {
  let filterParams = '';
  if (jurisdiction !== '') {
    filterParams += `&filter=jurisdiction%3D${
      EEAJurisdictions.includes(jurisdiction) || jurisdiction === 'UK'
        ? 'EEACountries&filter=jurisdiction%3DUK'
        : jurisdiction
    }`;
  }
  if (filterType?.length) {
    filterType.forEach((type) => {
      filterParams += `&filter=${type}`;
    });
  }
  return filterParams;
}

const getProcessingGroundType = (jurisdiction) => {
  switch (jurisdiction) {
    case 'EU':
    case 'EUI':
    case 'EU_EUI':
      return 'processing_ground';
    case 'JP':
      return 'appi_processing_ground';
    case 'TW':
      return 'pdpa_processing_ground';
    case 'CN':
      return 'pipl_processing_ground';
    case 'BR':
      return 'lgpd_processing_ground';
    case 'IN':
      return 'pdp_processing_ground';
    case 'MX':
      return 'fdpl_processing_ground';
    case 'SG':
      return 'sgpdpa_processing_ground';
    case 'UK':
      return 'ukgdpr_processing_ground';
    default:
      return 'processing_ground';
  }
};
