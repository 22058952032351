import { connect } from 'react-redux';
import TenantListComponent from './tenantList';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  items: state.tenants.list.get('items'),
  position: state.tenants.list.get('position'),
  isFetching: state.tenants.list.get('isFetching'),
  pageSearchText: state.tenants.list.get('pageSearchText'),
  sortOrder: state.tenants.list.get('sortOrder'),
  sortKey: state.tenants.list.get('sortKey'),
  filterColumn: state.tenants.list.get('filterColumn'),
  filteredOn: state.tenants.list.get('filteredOn').toJS(),
  partners: {
    items: state.partners.list.get('items'),
    searchText: state.partners.list.get('searchText'),
    searchItems: state.partners.list.get('searchItems')
  },
  error: state.tenants.list.get('error').toJS()
});

const mapDispatchToProps = (dispatch) => ({
  onMount: (rowCount) => {
    dispatch({ type: 'TENANTS:LIST:REQUEST_INIT', rowCount });
    dispatch({ type: 'PARTNERS:LIST:REQUEST_INIT', rowCount: 25 });
  },
  getNextData: (position, rowCount) => {
    dispatch({ type: 'TENANTS:LIST:NEXT_FETCH', position, rowCount });
  },
  onChooseFilter: (filterParams, rowCount) => {
    dispatch({ type: 'TENANTS:LIST:FILTER_INIT', filterParams, rowCount });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'TENANTS:LIST:SEARCH_INIT', searchParams });
  },
  partnerSearch: (searchParams) => {
    dispatch({ type: 'PARTNERS:LIST:SEARCH_INIT', searchParams });
  },
  resetPartner: () => {
    dispatch({ type: 'PARTNERS:LIST:INIT' });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantListComponent);
