import themeConstants from './themeConstants';

const headerBackgroundColor = '#00ad55';
const secondaryColor = '#2E3191';
const primaryColor = '#2B9299';
const failedColor = '#E03A18';
const canvasColor = '#FFFFFF';
const fontColor = '#000000';
const fontFamily = themeConstants.FONTFAMILY;

/**
 *  Default theme for Privacy Perfect UI. Only customizations from Material
 *  default should be specified here
 */
export default {
  fontFamily,
  palette: {
    primary1Color: primaryColor,
    accent1Color: secondaryColor,
    accent2Color: headerBackgroundColor,
    borderColor: 'lightgray',
    canvasColor,
    textColor: fontColor,
    passedColor: headerBackgroundColor,
    failedColor
  },
  app: {
    fontFamily,
    backgroundColor: canvasColor
  },
  filter: {},
  appBar: {
    height: themeConstants.APPBAR_HEIGHT,
    backgroundColor: headerBackgroundColor,
    color: headerBackgroundColor
  },
  bottomNavigation: {
    height: 100,
    backgroundColor: 'transparent',
    selectedColor: '#FFF',
    unselectedColor: '#FFF',
    selectedFontSize: 16,
    unselectedFontSize: 16
  },
  bottomNavigationItem: {},
  checkbox: {
    checkedColor: headerBackgroundColor
  },
  radioButton: {
    checkedColor: headerBackgroundColor
  },
  toolbar: {
    backgroundColor: canvasColor,
    color: fontColor,
    fontSize: 18
  },
  toolbarTitle: {
    fontSize: 28,
    color: fontColor
  },
  toolbarTitleRisk: {
    fontSize: 28,
    color: 'red'
  },
  responsiveTable: {
    fontFamily
  },
  raisedButton: {
    primaryColor: headerBackgroundColor,
    primaryTextColor: canvasColor
  },
  flatButton: {
    primaryTextColor: headerBackgroundColor
  },
  svgIcon: {
    color: headerBackgroundColor
  },
  icon: {
    color: canvasColor,
    backgroundColor: headerBackgroundColor
  },
  datePicker: {
    selectColor: headerBackgroundColor,
    headerColor: headerBackgroundColor
  },
  timePicker: {
    accentColor: headerBackgroundColor,
    headerColor: headerBackgroundColor
  },
  card: {
    titleColor: headerBackgroundColor
  },
  rgb: {
    r: 0,
    g: 173,
    b: 85
  }
};
