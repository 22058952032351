import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { recordTranslations } from '@packages/utils/commontranslations';
import { getRiskButtonBgColor } from '@packages/features/privacy-record-detail/common-utils';
import { extractRiskObjects } from '@packages/utils/common-utils';

const RiskCounter = (props) => {
  const getRiskStatus = () => {
    let mediumRiskCount = 0;
    let highRiskCount = 0;
    let mitigatedHighCount = 0;
    let mitigatedMediumCount = 0;
    const riskResults = extractRiskObjects(props);
    Object.keys(riskResults).forEach((item) => {
      const mediumRiskElements = riskResults[item].filter(el => el.severity === 'risk_estimate_type_medium');
      mediumRiskCount += mediumRiskElements.length;

      const highRiskElements = riskResults[item].filter(el => el.severity === 'risk_estimate_type_high');
      highRiskCount += highRiskElements.length;

      mitigatedMediumCount += mediumRiskElements.filter(el => el.isMitigated).length;
      mitigatedHighCount += highRiskElements.filter(el => el.isMitigated).length;
    });
    return ({ mediumRiskCount, highRiskCount, mitigatedMediumCount, mitigatedHighCount });
  };

  const riskStyle = {
    lineHeight: 2.5, borderBottom: '5px solid', borderRadius: '10px', fontSize: '14px'
  };

  const renderRiskIndicator = (riskCount, mitigatedCount, severity) => {
    const riskWidth = Math.floor((mitigatedCount / riskCount) * 100);
    const remainingWidth = 100 - riskWidth;
    if (riskCount === 0) {
      return (
        <div>
          <div><FormattedMessage
            id="PrivacyRecordList.noRisks"
            description="No risks"
            defaultMessage="No risks"
          />
          </div>
          <div style={
            Object.assign({}, riskStyle,
              { borderBottomColor: '#00ad55', width: '100%' })}
          />
        </div>
      );
    } else if (mitigatedCount === 0) {
      return (
        <div>
          <div>{riskWidth}% {recordTranslations.mitigated}</div>
          <div style={
            Object.assign({}, riskStyle,
              { borderBottomColor: getRiskButtonBgColor(severity), width: '100%' })}
          />
        </div>
      );
    } else if (riskCount === mitigatedCount) {
      return (
        <div>
          <div>{riskWidth}% {recordTranslations.mitigated}</div>
          <div style={
            Object.assign({}, riskStyle,
              { borderBottomColor: '#00ad55', width: '100%' })}
          />
        </div>
      );
    }
    return (
      <div>
        <div>{riskWidth}% {recordTranslations.mitigated}</div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={
            Object.assign({}, riskStyle,
              { borderBottomColor: '#00ad55', width: `${riskWidth}%` })}
          />
          <div style={
            Object.assign({}, riskStyle,
              { borderBottomColor: getRiskButtonBgColor(severity), width: `${remainingWidth}%` })}
          />
        </div>
      </div>
    );
  };

  const { mitigatedMediumCount, mitigatedHighCount, highRiskCount, mediumRiskCount } = getRiskStatus(props.data);

  return (
    <div style={{ marginTop: '15px' }} >
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: 'lightGrey', height: '10px' }}>
            <TableCell style={{ width: '25%', fontSize: '16px' }}>
              <FormattedMessage
                id="PrivacyRecordList.risks"
                description="Privacy Record List Column Risks"
                defaultMessage="Risks"
              />
            </TableCell>
            <TableCell style={{ width: '25%', fontSize: '16px' }}>
              <FormattedMessage
                id="PrivacyRecordList.mitigation"
                description="Mitigation Column"
                defaultMessage="Mitigation"
              />
            </TableCell>
            <TableRow />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="high">
            <TableCell style={{ width: '25%' }}>
              {recordTranslations.highRiskText}:
              <span style={{ paddingTop: '3px' }}> {highRiskCount}</span>
            </TableCell>
            <TableCell style={{ width: '25%' }}>
              {recordTranslations.mitigated}: <span style={{ paddingTop: '3px' }}> {mitigatedHighCount}</span>
            </TableCell>
            <TableCell align="left">
              {renderRiskIndicator(highRiskCount, mitigatedHighCount, 'risk_estimate_type_high')}
            </TableCell>
          </TableRow>
          <TableRow key="medium" className="even-row">
            <TableCell>
              {recordTranslations.mediumRiskText}:
              <span style={{ paddingTop: '3px' }}> {mediumRiskCount}</span>
            </TableCell>
            <TableCell>
              {recordTranslations.mitigated}: <span style={{ paddingTop: '3px' }}> {mitigatedMediumCount}</span>
            </TableCell>
            <TableCell align="left">
              {renderRiskIndicator(mediumRiskCount, mitigatedMediumCount, 'risk_estimate_type_medium')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
RiskCounter.propTypes = {
  data: PropTypes.shape({})
};
RiskCounter.defaultProps = {
  data: {}
};
export default RiskCounter;
