import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import { replace as replaceRouter } from 'connected-react-router';
import Immutable from 'immutable';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* resetAndfetchTenants(action) {
  yield put({
    type: action.isHolding ? 'HOLDING:TENANTS:LIST:INIT' : 'TENANTS:LIST:INIT'
  });
  yield fetchTenants(action);
}

export function* fetchTenants(action) {
  const { isHolding = false } = action;
  yield put({
    type: isHolding ? 'HOLDING:TENANTS:LIST:FETCH' : 'TENANTS:LIST:FETCH'
  });

  try {
    const tenantsState = isHolding
      ? yield select((state) => state.holdingTenants)
      : yield select((state) => state.tenants.list);
    const { sortOn, sortOrder, filteredOn, searchParameters } =
      getFilterAndSearchParamsFromState(tenantsState);
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position ? action.position : 0,
          searchParameters,
          action.rowCount,
          isHolding
        ),
        null,
        {}
      );

    switch (response.status) {
      case 200:
        {
          const items = response.body;
          const modifiedItems = items.map((item) => ({
            ...item,
            users: item.currentUserCount,
            partnerId: item.partner ? item.partner.id : null,
            partner: item.partner ? item.partner.name : null
          }));
          yield put({
            type: isHolding
              ? 'HOLDING:TENANTS:LIST:FETCH:SUCCESS'
              : 'TENANTS:LIST:FETCH:SUCCESS',
            items: modifiedItems
          });
        }
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: isHolding
        ? 'HOLDING:TENANTS:LIST:FETCH:FAIL'
        : 'TENANTS:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* filterTenants(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  const { isHolding = false } = action;

  yield put({
    type: isHolding ? 'HOLDING:TENANTS:LIST:FILTER' : 'TENANTS:LIST:FILTER',
    filterParams
  });
  yield fetchTenants(action);
}

export function* searchTenants(action) {
  const { searchParams, isHolding = false } = action;
  const searchResultsCount = 100;
  const { searchKey } = searchParams;

  const tenantsState = isHolding
    ? yield select((state) => state.holdingTenants)
    : yield select((state) => state.tenants.list);

  const { sortOn, sortOrder, filteredOn, searchParameters, searchText } =
    getFilterAndSearchParamsFromState(tenantsState, searchParams);

  yield put({
    type: isHolding
      ? 'HOLDING:TENANT:LIST:PAGE_SEARCH'
      : `TENANT:LIST:PAGE_SEARCH`,
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position ? action.position : 0,
          searchParameters,
          searchResultsCount,
          isHolding
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const items = response.body;
        const modifiedItems = items.map((item) => ({
          ...item,
          users: item.currentUserCount,
          partnerId: item.partner ? item.partner.id : null,
          partner: item.partner ? item.partner.name : null
        }));
        yield put({
          type: isHolding
            ? 'HOLDING:TENANT:LIST:PAGE_SEARCH:SUCCESS'
            : `TENANT:LIST:PAGE_SEARCH:SUCCESS`,
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: isHolding
        ? 'HOLDING:TENANT:LIST:PAGE_SEARCH:FAIL'
        : `TENANT:LIST:PAGE_SEARCH:FAIL`,
      error: err.message
    });
  }
}

const getFilterAndSearchParamsFromState = (tenantState, searchParams) => {
  // Filter parameters
  let filterParams = {};
  let pageSearchTextObj = {};
  if (tenantState) {
    pageSearchTextObj = tenantState.get('pageSearchText').toJS();
    filterParams = tenantState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = tenantState
    ? tenantState.get('filteredOn')
    : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    // For first time rendering of search items, searchText will not be defined
    if (searchText !== undefined) {
      searchParameters = Object.assign({}, searchParams, { searchText });
    }
  } else {
    searchParameters = pageSearchTextObj
      ? { searchKey: 'name', searchText: pageSearchTextObj.name }
      : {};
  }

  return { sortOn, sortOrder, filteredOn, searchParameters, searchText };
};

export function* initializeTenantDetail() {
  const isEdit = window.location.hash.indexOf('/tenant/create') === -1;
  if (!isEdit) {
    yield put(initialize('TenantDetail', {}));
  } else {
    const { tenantId } = getParameterValuesFromHash(
      '/tenant/:tenantId/edit?:is'
    );
    yield put({ type: 'TENANT:DETAIL:FETCH', tenantId });

    try {
      const response = yield registry
        .get('request')
        .get(`/v1/tenants/${tenantId}`, null);
      let adminList = [];
      if (tenantId) {
        adminList = yield getTenantAdmins(tenantId);
      }
      switch (response.status) {
        case 200:
          {
            const responseData = response.body;
            const modifiedResponseData = {
              ...responseData,
              admins: adminList
            };
            let modifiedData = modifiedResponseData;
            if (responseData.partner) {
              modifiedData = {
                ...modifiedResponseData,
                partner: {
                  ...modifiedResponseData.partner,
                  key:
                    modifiedResponseData.partner &&
                    modifiedResponseData.partner.name
                }
              };
            }
            yield put({
              type: 'TENANT:DETAIL:FETCH:SUCCESS',
              data: modifiedData
            });
            yield put(initialize('TenantDetail', modifiedData));
          }
          break;
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
      }
    } catch (err) {
      yield handleServiceDown(err, 'tenant');
      registry.get('logger').error(err);
      yield put({ type: 'TENANT:DETAIL:FETCH:FAIL', error: err.message });
    }
  }
}

export function* getFrameworkTemplates() {
  let responseData = [];
  try {
    const response = yield registry
      .get('request')
      .getISMSMethod('/isms/api/template/framework', null);
    switch (response.status) {
      case 200: {
        responseData = response.body;
        yield put({
          type: 'TEMPlATES:FRAMEWORKS:FETCH:SUCCESS',
          data: responseData
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'isms');
    registry.get('logger').error(err);
  }
  return responseData;
}

export function* getTenantAdmins(tenantId) {
  let responseData = [];
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tenants/${tenantId}/admins`, null);
    switch (response.status) {
      case 200: {
        responseData = response.body;
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
  }
  return responseData;
}
export function* upsertTenantDetail(action) {
  const store = registry.get('store');
  const { data, isHolding } = action;
  const tenantDetailsState = isHolding
    ? yield select((state) => state.holdingTenants)
    : yield select((state) => state.tenants.detail);
  const isEdit = tenantDetailsState.get('isEdit');
  const tenantId = tenantDetailsState.get('tenantId');
  const request = registry.get('request');
  const requestData = Object.assign({}, data, {
    maxUsers: parseInt(data.maxUsers, 10),
    maxSupervisorUsers: parseInt(data.maxSupervisorUsers, 10),
    admins: data.admins.map((admin) => admin.emailId),
    enableSSO: data.enableSSO || false,
    defaultLanguage: data.defaultLanguage
  });
  if (requestData.partner === '') {
    delete requestData.partner;
  }
  let response;
  try {
    if (isEdit) {
      response = yield request.put(`/v1/tenants/${tenantId}`, requestData);
    } else {
      response = yield request.post(`/v1/tenants`, requestData);
    }

    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
        {
          const message = tenantMessages[isEdit];
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: message,
              type: 'success'
            }
          });
          setTimeout(() => {
            store.dispatch(replaceRouter('/admin'));
          }, 3000);
        }
        break;
      case 409:
        yield put({
          type: isHolding
            ? 'HOLDING:TENANT:DETAIL:UPSERT:FAIL'
            : 'TENANT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({
      type: isHolding
        ? 'HOLDING:TENANT:DETAIL:UPSERT:FAIL'
        : 'TENANT:DETAIL:UPSERT:FAIL',
      error: err.message
    });
  }
}

export function* exportTenants() {
  const request = registry.get('request');

  try {
    const response = yield request.exportCSVbyGet(
      '/v1/entities/tenant/export',
      null
    );

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    registry.get('logger').error(err);
  }
}

export function* exportTenantPartners() {
  const request = registry.get('request');

  try {
    const response = yield request.exportCSVbyGet(
      '/v1/entities/partners/tenants/export',
      null
    );

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    registry.get('logger').error(err);
  }
}

export function* fetchTenantUsageCount(action) {
  yield put({ type: 'TENANT:PRICING:PLAN:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get(`v1/tenants/${action.tenantId}/count`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'TENANT:PRICING:PLAN:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* deleteTenant(action) {
  const { tenantId } = action;
  const request = registry.get('request');

  try {
    const response = yield request.delete(`/v1/tenants/${tenantId}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.tenantDeleted,
            type: 'success'
          }
        });
        yield resetAndfetchTenants(action);
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

const getURLWithParams = (
  filterParams,
  position,
  searchParams,
  rowCount = 15,
  isHolding
) => {
  let url = `/v1/tenants`;
  url = `${url}?numberOfResults=${rowCount}&offset=${position}&filter=includeDeleted%3Dtrue`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (isHolding) url = `${url}&filter=isHoldingTenant%3Dtrue`;
  else url = `${url}&filter=isNonHoldingTenant%3Dtrue`;
  return url;
};

const tenantMessages = {
  true: notificationtranslations.tenantDetailUpdateSuccess,
  false: notificationtranslations.tenantCreated
};

function* fetchData(type, url) {
  try {
    const request = registry.get('request');
    const response = yield request.get(url);
    switch (response.status) {
      case 200: {
        yield put({
          type: `TENANT:${type}:FETCH:SUCCESS`,
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, type);
  }
}

const getLibraryURLWithParams = (url, searchParams) => {
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}?&search=${searchKey}%3D${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

export function* fetchAllLibraryLayouts(action) {
  const type = 'LIBRARY_LAYOUT';
  const url = getLibraryURLWithParams(
    '/v1/custom-record-layout/data-library',
    action.searchParams
  );
  yield fetchData(type, url);
}

export function* fetchAllLibraryTemplates(action) {
  const type = 'LIBRARY_TEMPLATES';
  const url = getLibraryURLWithParams(
    '/v1/records/templates/data-library',
    action.searchParams
  );
  yield fetchData(type, url);
}

export function* fetchAllLibraryRecords(action) {
  const type = 'LIBRARY_RECORDS';
  const url = getLibraryURLWithParams(
    '/v1/records/data-library',
    action.searchParams
  );
  yield fetchData(type, url);
}
