import { connect } from 'react-redux';
import DataRecipientCategoryDefault from './component';
import sagas from './sagas';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  dataRecipientCategories:
    state.environment.dataRecipientCategories.get('items'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  error: state.dataRecipientCategories.get('error'),
  searchResults: state.dataRecipientCategories.get('items'),
  position: state.environment.dataRecipientCategories.get('position'),
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch({ type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:REQUEST_INIT' });
  },
  onSearch: (searchParams) => {
    dispatch({
      type: 'DATA_RECIPIENT_CATEGORIES:LIST:SEARCH_INIT',
      searchParams
    });
  },
  getNextData: (position) => {
    dispatch({
      type: 'DATARECIPIENTCATEGORIES:LIST:NEXT_FETCH',
      position,
      isPersistedData: true,
      source: 'records'
    });
  },
  onChooseFilter: (filterParams, searchParams) => {
    dispatch({
      type: 'DATARECIPIENTCATEGORIES:LIST:FILTER_INIT',
      filterParams,
      searchParams,
      isPersistedData: true,
      source: 'records'
    });
  },
  resetSearchFilter: () => {
    dispatch({
      type: 'MASTERDATA:DATARECIPIENTCATEGORIES:LIST:SEARCH_FILTER:RESET'
    });
    dispatch({ type: 'RECORDS:DATA_RECIPIENT_CATEGORIES:SEARCH:RESET' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataRecipientCategoryDefault);
export { reducers, sagas };
