
import { connect } from 'react-redux';
import BreachGroundList from './breachGroundList';

const mapStateToProps = state => ({
  isFetching: state.breachGrounds.get('isFetching'),
  searchText: state.breachGrounds.get('searchText'),
  searchItems: state.breachGrounds.get('searchItems').toJS(),
  sortOrder: state.breachGrounds.get('filterParams').get('sortOrder'),
  sortKey: state.breachGrounds.get('filterParams').get('sortOn'),
  filterColumn: state.breachGrounds.get('filterColumn').toJS(),
  filteredOn: state.breachGrounds.get('filteredOn').toJS(),
  breachGrounds: state.breachGrounds.get('breachGrounds'),
  error: state.breachGrounds.get('error')
});

const mapDispatchToProps = dispatch => ({
  initBreachGrounds: () => {
    dispatch({ type: 'BREACH_GROUNDS:LIST:INIT' });
  },
  onChooseFilter: (filterParams) => {
    dispatch({ type: 'BREACH_GROUNDS:LIST:FILTER_INIT', filterParams });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'BREACH_GROUNDS:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BreachGroundList);
