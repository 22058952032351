import React from 'react';
import PropTypes from 'prop-types';

import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import styles from '@packages/ui/styles';

import CreateRecord from './create-record';

const CreateRecordDialog = (props) => {
  const { title, subTitle, onRequestClose, open } = props;

  const requestClose = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="create-record-dialog"
      maxWidth="md"
      show={open}
      onCancel={requestClose}
      title={title}
      fullWidth={true}
    >
      <div style={{ ...styles.labelField, marginTop: 0 }}>{subTitle}</div>
      <div style={{ marginTop: 25 }}>
        <CreateRecord {...props} />
      </div>
    </CommonDialog>
  );
};

CreateRecordDialog.propTypes = {
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
  subTitle: PropTypes.node
};

CreateRecordDialog.defaultProps = {
  open: false,
  onRequestClose: (e) => e,
  title: null,
  subTitle: null
};

export default CreateRecordDialog;
