import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { renderTextField } from '@packages/components/form-components';
import { commonTranslations } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import { addMasterTypeTranslations, hintTextTranslations } from '../../../environment/masterDataTranslations';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = errortranslations.required;
    }
  });

  return errors;
};

export const TagDialog = (props) => {
  const [closeDialog, setCloseDialog] = useState(true);
  const [onAfterUpdation, setOnAfterUpdation] = useState(false);

  useEffect(() => {
    if (props.tagsData !== undefined) {
      if (onAfterUpdation) props.handleSelectedItem({ value: props.tagsData }, closeDialog);
    }
  }, [props.tagsData]);

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  const onSave = (tag, closeDialogValue) => {
    const modifiedData = {
      ...tag,
      name: tag.name.trim()
    };
    props.updateTags(modifiedData);
    setCloseDialog(closeDialogValue);
    setOnAfterUpdation(true);
    if (!closeDialogValue) props.initialize({});
  };

  const { actionError, submitting, handleSubmit } = props;

  return (
    <CommonDialog
      id="add-tag-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      showSaveAndCreate={true}
      actionError={actionError}
      title={addMasterTypeTranslations('tags')}
    >
      <Field
        id="simpleEntityDialog"
        name="name"
        label={commonTranslations.name}
        hintTextLabel={hintTextTranslations.tags}
        component={renderTextField}
        autoFocus={true}
      />
      <div style={styles.addButton}>
        <Button
          id="submit"
          disabled={submitting}
          type="submit"
          onClick={handleSubmit(entityData => onSave(entityData, true))}
        >
          {commonTranslations.saveAndClose}
        </Button>
        <Button
          id="save-create"
          variant="text"
          onClick={handleSubmit(entityData => onSave(entityData, false))}
        >
          {commonTranslations.saveAndCreate}
        </Button>
        <Button
          variant="text"
          onClick={requestClose}
        >
          {commonTranslations.Cancel}
        </Button>
      </div>
    </CommonDialog>
  );
};

TagDialog.propTypes = {
  tagsData: PropTypes.shape({}),
  actionError: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  handleSelectedItem: PropTypes.func,
  onRequestClose: PropTypes.func,
  updateTags: PropTypes.func,
  open: PropTypes.bool,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool.isRequired
};

TagDialog.defaultProps = {
  tagsData: {},
  actionError: Immutable.Map({
    isError: false,
    message: ''
  }),
  handleSelectedItem: e => e,
  onRequestClose: e => e,
  updateTags: e => e,
  open: false,
  initialize: e => e,
  handleSubmit: e => e
};

const TagsFormWrapper = reduxForm({
  form: 'TagDialog', // a unique identifier for this form
  validate
})(TagDialog);

export default TagsFormWrapper;
