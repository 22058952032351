import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import styles from '@packages/ui/styles';

class TextFieldSelector extends React.Component {
  constructor(props) {
    super(props);
    this.setSelectedValue(props.selectedItems);

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setSelectedValue(nextProps.selectedItems);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.selectedItems === nextProps.selectedItems) ||
           !(this.state === nextState) ||
           !(this.props.multiValue === nextProps.multiValue);
  }

  handleChange(index, value) {
    const { values } = this.state;
    values[index] = value;
    this.setState({ values });
  }

  setSelectedValue (selectedItems) {
    this.state = { values: [(selectedItems && selectedItems[0]) || '', (selectedItems && selectedItems[1]) || ''] };
  }

  render () {
    const { fields, id, multiValue } = this.props;
    const divStyle = multiValue ? Object.assign({}, styles.textBox, { width: '50%', marginRight: 10 }) :
      Object.assign({}, styles.textField, styles.textBox, { width: '100%' });
    return (
      <div style={{ display: 'flex' }}>
        <div style={divStyle}>
          <TextField
            id="single_line_textField"
            {...(id && { id })}
            value={this.state.values[0]}
            variant="outlined"
            style={styles.textField}
            onChange={event => this.handleChange(0, event.target.value)}
            onBlur={(event) => {
              fields.remove(0);
              if (event.target.value !== '') {
                fields.unshift(event.target.value);
              }
            }}
          />
        </div>
        {multiValue &&
          <div style={divStyle}>
            <TextField
              id="multi_line_textField"
              {...(id && { id })}
              value={this.state.values[1]}
              variant="outlined"
              style={styles.textField}
              onChange={event => this.handleChange(1, event.target.value)}
              onBlur={(event) => {
                fields.remove(1);
                if (event.target.value !== '') {
                  fields.push(event.target.value);
                }
              }}
            />
          </div> }
      </div>
    );
  }
}

TextFieldSelector.propTypes = {
  id: PropTypes.string,
  multiValue: PropTypes.bool,
  fields: PropTypes.shape({
    removeAll: PropTypes.func,
    remove: PropTypes.func,
    push: PropTypes.func,
    unshift: PropTypes.func
  }),
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired
};

TextFieldSelector.defaultProps = {
  id: null,
  multiValue: false,
  fields: {
    removeAll: e => e,
    push: e => e
  }
};

export default TextFieldSelector;
