import React, { useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getV1WhiteLabelledTheme} from '@packages/utils/theme-utils';
import Theme from '@packages/ui/themes/default_v1';

const defaultTheme = createMuiTheme({
  ...Theme
});

export default function CustomStyles({ children, whiteLabelling, init }) {
  useEffect(() => init(), []);
  const themeV1 = getV1WhiteLabelledTheme(whiteLabelling);
  const theme = {...themeV1} || defaultTheme;
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
