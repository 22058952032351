import { connect } from 'react-redux';
import ReferenceSelector from './component';

const mapStateToProps = state => ({
  securityMeasures: state.environment.simpleEntities.technicalSecurityMeasures.get('items'),
  position: state.environment.simpleEntities.technicalSecurityMeasures.get('position'),
  error: state.environment.simpleEntities.technicalSecurityMeasures.get('error'),
  searchResults: state.simpleEntities.get('entitySelectorSearch').get('technicalSecurityMeasures').searchResults
});

const mapDispatchToProps = dispatch => ({
  initSecurityMeasures: () => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:REQUEST_INIT',
      masterDataType: 'securityMeasures',
      entityType: 'technicalSecurityMeasures'
    });
  },
  getNextData: (position) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      position,
      masterDataType: 'securityMeasures',
      entityType: 'technicalSecurityMeasures' });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams,
      masterDataType: 'securityMeasures',
      entityType: 'technicalSecurityMeasures' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceSelector);
