/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import EntitySelector from './component';

export { default as reducers } from './reducers';

const mapStateToProps = (state) => ({
  legalEntities: state.environment.organisations.get('items'),
  userPermissions: state.login.get('loggedUser')?.get('userPermissions'),
  error: state.legalEntities.get('error')
});

const mapDispatchToProps = (dispatch) => ({
  onSearch: (searchParams) => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:SEARCH_INIT', searchParams });
  },
  resetSearchFilter: (legalEntityType) => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:FILTER:RESET' });
    dispatch({ type: `LEGAL_ENTITY:${legalEntityType}:LIST:SEARCH:RESET` });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EntitySelector);
