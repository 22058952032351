import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { updateOrganisationEntities } from '../../organisation-entities/utils';

const defaultState = Immutable.fromJS({
  viewMode: 'list',
  isFetching: false,
  position: 0,
  isDownloading: false,
  newItems: Immutable.List(),
  items: Immutable.List(),
  organisationData: {},
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  error: {
    message: '',
    isError: false
  }
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ORGANISATIONS:VIEW:MODE:SET':
      return state.set('viewMode', action.viewMode);

    case 'ORGANISATIONS:LIST:INIT': {
      /* const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const { type } = urlParams;
      let newState = state;
      if (type) {
        const filterParameters = Object.assign({}, { sortOn: '', sortOrder: '' },
          { filteredOn: ['Administrator'], filterKey: 'lastName' });
        newState = newState.set('filterParams', Immutable.Map(filterParameters))
          .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
            { filterObj: state.get('filteredOn') })));
      } */
      return state.set('filteredOn', state.get('filteredOn'))
        .set('searchText', state.get('searchText'))
        .set('filterParams', state.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('newItems', defaultState.get('newItems'))
        .set('organisationData', defaultState.get('organisationData'))
        .set('position', 0);
    }
    case 'ORGANISATIONS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'ORGANISATIONS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.organisationData) {
        newItems = newItems.unshift(action.organisationData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('items', Immutable.List(items))
        .set('position', state.get('position') + action.items.length);
    }
    case 'ORGANISATIONS:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'ORGANISATIONS:LIST:DELETE:SUCCESS': {
      // Remove the item from the org list items
      const items = updateOrgItems(state.get('items'), action.orgId);
      const newItems = updateOrgItems(state.get('newItems'), action.orgId);
      return state.set('items', Immutable.List(items))
        .set('newItems', Immutable.List(newItems))
        .set('position', (state.get('position') - 1));
    }

    case 'ORGANISATIONS:LIST:UPDATE:SUCCESS': {
      const { isEdit } = action;
      const selectedItem = action.selectedItem.value || action.selectedItem;
      const organisations = state.get('items');
      const { items, newItems } = updateOrganisationEntities({
        entities: organisations,
        isEdit,
        selectedItem,
        newItems: state.get('newItems')
      });
      return state.set('items', items)
        .set('position', isEdit ? state.get('position') : null)
        .set('newItems', newItems);
    }

    case 'ORGANISATIONS:LIST:UPDATE':
      return state.set('items', action.items);

    case 'ORGANISATIONS:LIST:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('organisationData', action.organisationData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'ORGANISATIONS:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', defaultState.get('items'))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })));
    }
    case 'ORGANISATIONS:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    case 'ORGANISATIONS:LIST:FILTER:SUCCESS':
      return state.set('items', Immutable.List(action.items));

    case 'ORGANISATIONS:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('newItems', defaultState.get('newItems'))
        .set('error', defaultState.get('error'));
    }
    case 'ORGANISATIONS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'ORGANISATIONS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'ORGANISATIONS:BULK:EXPORT:INIT':
      return state.set('isDownloading', true);
    case 'ORGANISATIONS:BULK_EXPORT:SUCCESS':
    case 'ORGANISATIONS:BULK_EXPORT:FAIL': {
      return state.set('isDownloading', false);
    }
    default:
      return state;
  }
};

const updateOrgItems = (items, orgId) => {
  const orgs = [...items];
  const index = orgs.findIndex(org => ((org.value ? org.value.id : org.id) === orgId));
  if (index !== -1) {
    orgs.splice(index, 1);
  }
  return orgs;
};
