import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchTenantOrganisations() {
  try {
    const response = yield registry.get('request')
      .get(`/v1/organisations/hierarchy?isInternal=true`, null);

    switch (response.status) {
      case 200: {
        const items = response.body;
        yield put({ type: 'TENANT_ORGANISATION:LIST:REQUEST:SUCCESS', items });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'TENANT_ORGANISATION:LIST:REQUEST:FAIL', error: err.message });
  }
}

export function* updateTenantOrganisations(action) {
  const { data, isEdit, onSuccess } = action;
  yield put({ type: 'ORGANISATION_ENTITY:DETAIL:UPSERT', data, isEdit, onSuccess });
}
