/* eslint-disable max-len */
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { pluralTerms as smallcasePlural } from '@packages/utils/smallcaseTranslations';
import { recordTranslations } from '@packages/utils/commontranslations';

export const dpiaTranslations = {
  questionView: (
    <FormattedMessage id="DPIA.questionView" defaultMessage="Question view" />
  ),
  ICOCharacteristics: (
    <FormattedMessage
      id="DPIA.ICOCharacteristics"
      defaultMessage="ICO criteria relevant in determining if a full DPIA is required"
    />
  ),
  APCharacteristics: (
    <FormattedMessage
      id="DPIA.APCharacteristics"
      defaultMessage="AP criteria relevant in determining if a full DPIA is required"
    />
  ),
  GBACharacteristics: (
    <FormattedMessage
      id="DPIA.GBACharacteristics"
      defaultMessage="GBA criteria relevant in determining if a full DPIA is required"
    />
  ),
  assessmentName: (
    <FormattedMessage
      id="DPIA.assessmentName"
      defaultMessage="Name of the assessment"
    />
  ),
  assessmentDescription: (
    <FormattedMessage
      id="DPIA.assessmentDescription"
      defaultMessage="Description of the planned processing activity and the assessment project."
    />
  ),
  processingCategory: (
    <FormattedMessage
      id="DPIA.light.processingCategory"
      defaultMessage="Processing activity category"
    />
  ),
  startDate: (
    <FormattedMessage
      id="DPIA.startDate"
      defaultMessage="Start date of the assessment"
    />
  ),
  dueDate: (
    <FormattedMessage
      id="DPIA.dueDate"
      defaultMessage="Due date of the assessment"
    />
  ),
  jurisdiction: (
    <FormattedMessage
      id="DPIA.jurisdiction"
      defaultMessage="Relevant country"
    />
  ),
  EDPBCriteria: (
    <FormattedMessage
      id="DPIA.EDPBCriteria"
      defaultMessage="Relevant criteria in determining if a full DPIA is required"
    />
  ),
  EDPBCriteriaSubheader: (
    <FormattedMessage
      id="DPIA.EDPBCriteriaSubheader"
      defaultMessage="Will the processing activity involve any of the following?"
    />
  ),
  processing_characteristics_automated_processing: (
    <FormattedMessage
      id="DPIA.processing_characteristics_automated_processing"
      defaultMessage="Automated decision making with legal or similar significant effect
    (e.g. automatic rejection of job applicants)"
    />
  ),
  processing_characteristics_largescale_processing: (
    <FormattedMessage
      id="DPIA.processing_characteristics_largescale_processing"
      defaultMessage="Data processed on a large scale (e.g. a large proportion of the employee or customer population)"
    />
  ),
  processing_characteristics_sensitive_personal_data_with_special_categories: (
    <FormattedMessage
      id="DPIA.sensitive_personal_data_with_special_categories"
      defaultMessage="Sensitive data or data of a highly personal nature (e.g. location data, financial data), including special categories of data (e.g. data on health, political opinions) and personal data relating to criminal convictions or offences"
    />
  ),
  processing_characteristics_public_places_systematic_monitoring: (
    <FormattedMessage
      id="DPIA.processing_characteristics_public_places_systematic_monitoring"
      defaultMessage="Systematic monitoring (including IT monitoring, or CCTV aimed at a publicly accessible area)"
    />
  ),
  processing_characteristics_matching_or_combining_datasets: (
    <FormattedMessage
      id="DPIA.processing_characteristics_matching_or_combining_datasets"
      defaultMessage="Matching or combining datasets (e.g., through data vendors, publicly accessible databases,
      joint marketing partners)"
    />
  ),
  processing_characteristics_vulnerable_data_subjects: (
    <FormattedMessage
      id="DPIA.processing_characteristics_vulnerable_data_subjects"
      defaultMessage="Data concerning vulnerable individuals (e.g. children or employees)"
    />
  ),
  processing_characteristics_new_technology_or_innovative_use: (
    <FormattedMessage
      id="DPIA.processing_characteristics_new_technology_or_innovative_use"
      defaultMessage="Innovative use or applying new technological or organisational solutions
      (e.g. IoT apps, Artificial Intelligence)"
    />
  ),
  processing_characteristics_evaluation_or_scoring: (
    <FormattedMessage
      id="DPIA.processing_characteristics_evaluation_or_scoring"
      defaultMessage="Evaluation/scoring (e.g. credit checks, anti-fraud solutions, building marketing profiles)"
    />
  ),
  processing_characteristics_sensitive_personal_data: (
    <FormattedMessage
      id="DPIA.processing_characteristics_sensitive_personal_data"
      defaultMessage="Sensitive data (e.g. health data, criminal data, biometric data) or data of a
      highly personal nature (e.g. financial data)"
    />
  ),
  processing_characteristics_prevents_from_exercising_rights: (
    <FormattedMessage
      id="DPIA.processing_characteristics_prevents_from_exercising_rights"
      defaultMessage="Processing prevents individuals from exercising a right or using a service or contract"
    />
  ),
  attachmentDescription: (
    <FormattedMessage
      id="DPIA.attachmentDescription"
      defaultMessage="Attach documents that support this assessment record"
    />
  ),
  permissionDescription: (
    <FormattedMessage
      id="DPIA.permissionDescription"
      defaultMessage="Add users below to give them access to this assessment record and to change it.
    Each added user will receive a notification."
    />
  ),
  companyWideAccess: (
    <FormattedMessage
      id="DPIA.companyWideAccess"
      defaultMessage="Company-wide access"
    />
  ),
  controllers: (
    <FormattedMessage
      id="DPIA.controllers"
      defaultMessage="Organisations responsible for the processing activity"
    />
  ),
  processors: (
    <FormattedMessage
      id="DPIA.light.processors"
      defaultMessage="Organisations processing personal data on behalf of another organisation"
    />
  ),
  executingEntities: (
    <FormattedMessage
      id="DPIA.executingEntities"
      defaultMessage="Part of the organisation doing the actual processing work"
    />
  ),
  dataRecipients: (
    <FormattedMessage
      id="DPIA.dataRecipients"
      defaultMessage="Organisations or persons with whom personal data will be shared"
    />
  ),
  dataRecipientCategories: (
    <FormattedMessage
      id="DPIA.light.dataRecipientCategories"
      defaultMessage="Categories of organisations or persons with whom personal data will be shared"
    />
  ),
  numberOfIndividuals: (
    <FormattedMessage
      id="DPIA.numberOfIndividuals"
      defaultMessage="Number of individuals whose personal data will be involved in the processing activity"
    />
  ),
  individualCategories: (
    <FormattedMessage
      id="DPIA.individualCategories"
      defaultMessage="Categories of individuals involved in the processing activity"
    />
  ),
  personalDataCategories: (
    <FormattedMessage
      id="DPIA.personalDataCategories"
      defaultMessage="Personal data categories involved in the processing activity"
    />
  ),
  personalDataItems: (
    <FormattedMessage
      id="DPIA.personalDataItems"
      defaultMessage="Personal data items involved in the processing activity"
    />
  ),
  assets: (
    <FormattedMessage
      id="DPIA.assets"
      defaultMessage="Assets used in the processing activity"
    />
  ),
  period: (
    <FormattedMessage
      id="DPIA.period"
      defaultMessage="Period for which the personal data will be stored"
    />
  ),
  purpose: (
    <FormattedMessage
      id="DPIA.purpose"
      defaultMessage="Purpose of the processing activity"
    />
  ),
  processingGrounds: (
    <FormattedMessage
      id="DPIA.processingGrounds"
      defaultMessage="Processing grounds making the processing activity lawful"
    />
  ),
  nonEEACountriesTransfer: (
    <FormattedMessage
      id="DPIA.internationalTransfer"
      defaultMessage="Will data be transferred to non-EEA countries?"
    />
  ),
  nonEEACountriesTransferPurpose: (
    <FormattedMessage
      id="DPIA.internationalTransferPurpose"
      defaultMessage="Purpose of the non-EEA transfer"
    />
  ),
  nonEEACountriesTransferGrounds: (
    <FormattedMessage
      id="DPIA.internationalTransferGrounds"
      defaultMessage="Transfer grounds making the transfer to non-EEA countries lawful"
    />
  ),
  nonEEACountriesTransferConcern: (
    <FormattedMessage
      id="DPIA.nonEEACountriesTransferConcern"
      defaultMessage="Data will be transferred to a country outside the European Economic Area (EEA)"
    />
  ),
  commentForPurpose: (
    <FormattedMessage
      id="DPIA.commentForPurpose"
      defaultMessage="Explain if and why the purposes for processing are clear, explicit and legitimate.
    Will you or your vendors process the personal data for any other purpose (reporting, marketing, etc)?
    Please explain. If yes, is the processing for the secondary purpose related or expected, is there specific
    consent for the new purpose or a law requiring/allowing the new processing? Please attach proof of these."
    />
  ),
  commentForTransferPurpose: recordTranslations.commentary,
  commentForLegalBasis: (
    <FormattedMessage
      id="DPIA.commentForLegalBasis"
      defaultMessage={`Justify your decision to rely on these processing grounds:
    <newline> - If you rely on 'consent' please explain how consent is obtained and recorded (e.g. through a tick box, wet signature, orally) and provide the consent wording.</newline>
    <newline> - If you rely on the 'performance of a contract', please explain on which contract and why the processing is necessary to perform obligations under that contract.</newline>
    <newline> - If you rely on 'compliance with a legal obligation', please explain which legal obligation and why the processing is necessary to comply with that obligation.</newline>
    <newline> - If you rely on 'vital interests', please explain what those vital interests are and why the processing is necessary for those vital interests.</newline>
    <newline> - If you rely on 'public interests', please explain what those public interests are and why the processing is necessary for those public interests.</newline>
    <newline> - If you rely 'legitimate interests', please explain the following:</newline>
    <newline> What are the legitimate interests of the organisation (or of a third party)?</newline>
    <newline> Why is the data processing necessary to achieve the purposes described earlier?</newline>
    <newline> What is the potential impact of the data processing on the rights and freedoms of the individuals? Will they reasonably expect that their data is processed in the envisaged manner?</newline>
    <newline> Why do the legitimate interests of the organisation (or of a third party) weigh heavier than the rights and freedoms of the individuals?</newline>`}
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  commentForTransferGrounds: (
    <FormattedMessage
      id="DPIA.commentForTransferGrounds"
      defaultMessage="If the transfer grounds for the international transfer are not displayed above, not displayed
    correctly or are incomplete, please make adjustments  in the chapter ‘Processing description’ (if you have access
    to that chapter). If you do not have access, use the field below to write down your adjustments."
    />
  ),
  commentForDataMinimisation: (
    <FormattedMessage
      id="DPIA.commentForDataMinimisation"
      defaultMessage="As a rule of thumb, you should not collect more personal data items than necessary to achieve your purposes (i.e. only 'must have' data should be collected and 'nice to have data' should be avoided).
    <newline>Explain which measures have been taken to comply with this principle.</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  commentForStorageLimitation: (
    <FormattedMessage
      id="DPIA.commentForRetentionTerms"
      defaultMessage="Justify your decision for retaining the personal data for this period and explain how this period was determined (e.g. is it based on a retention policy?). Is there a clear and specific process in place, so that the data are deleted, anonymised or pseudonymised after this period?"
    />
  ),
  commentForDataQuality: (
    <FormattedMessage
      id="DPIA.commentForDataQuality"
      defaultMessage="What is the process to ensure that the personal data are accurate, complete and up to date? Describe the process or attach it to this DPIA.
    <newline>Can individuals review, correct or delete their personal data?</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  policies: (
    <FormattedMessage
      id="DPIA.commentForPolicies"
      defaultMessage="List relevant policies, codes of conduct, certification scheme etc."
    />
  ),
  dataProtectionMeasures: (
    <FormattedMessage
      id="DPIA.commentForPrivacy"
      defaultMessage="List the measures that contribute to privacy by design and
    aspects of the processing activity"
    />
  ),
  measures: (
    <FormattedMessage
      id="DPIA.commentForAccountabilityMeasures"
      defaultMessage="List other measures to further comply with and demonstrate
    compliance with (privacy) legislation"
    />
  ),
  dataSubjectCategories: (
    <FormattedMessage
      id="DPIA.commentForDataSubject"
      defaultMessage="List the categories of individuals impacted by the processing
    activity that have been consulted, their findings and decisions
    based on these findings. If individuals haven't been consulted,
    justify why this hasn't been done"
    />
  ),
  supervisoryAuthorities: (
    <FormattedMessage
      id="DPIA.commentForSupervisoryAuthority"
      defaultMessage="List the supervisory authority that have been consulted, their
    findings and decisions based on these findings. This is
    obligatory when the residual risk in the previous chapter was
    medium or high."
    />
  ),
  informationCommentary: (
    <FormattedMessage
      id="DPIA.commentForInformation"
      defaultMessage="How are the individuals informed of the data processing and at which moment?
    <newline>Is this covered by a privacy notice (existing or new), please explain which privacy notice and how it is made available to individuals.</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  consentCommentary: (
    <FormattedMessage
      id="DPIA.commentForConsent"
      defaultMessage="When applicable, describe how consent for processing personal data is obtained from individuals.
    <newline>How is consent recorded? How is the consent refreshed? How can the individual refuse or later opt out of giving their consent? What are the consequences if they do so? If there is an internal process in place, please describe this process and attach it to this DPIA.</newline>"
      values={{
        newline: (text) => <div>{text}</div>
      }}
    />
  ),
  accessCommentary: (
    <FormattedMessage
      id="DPIA.commentForAccess"
      defaultMessage="Describe how individuals can exercise their right to access and rectify their personal data being processed. What measures are adopted by the organisation to ensure this? Who is responsible for these? If there is an internal process in place, please describe this process and attach it to this DPIA."
    />
  ),
  objectionRightsCommentary: (
    <FormattedMessage
      id="DPIA.commentForObjectionRights"
      defaultMessage="Describe how individuals can exercise their right to object to and restrict the processing of their personal data. What measures are adopted by the organisation to ensure this? Who is responsible for these? If there is an internal process in place, please describe this process and attach it to this DPIA."
    />
  ),
  erasureCommentary: (
    <FormattedMessage
      id="DPIA.commentForErasure"
      defaultMessage="Describe how individuals can exercise their right to erase personal data being processed. What measures are adopted by the organisation to ensure this? Who is responsible for these? If there is an internal process in place, please describe this process and attach it to this DPIA."
    />
  ),
  dataPortabilityCommentary: (
    <FormattedMessage
      id="DPIA.commentForDataPortability"
      defaultMessage="Describe how individuals can exercise their right to obtain and reuse their personal data for their own purposes across different services, in a structured, commonly used and machine readable format. What measures are adopted to ensure this? Who is responsible for these? If there is an internal process in place, please describe this process and attach it to this DPIA."
    />
  ),
  organisationalSecurityMeasures: (
    <FormattedMessage
      id="DPIA.organisationalSecurityMeasures"
      defaultMessage="List the organisational security measures applicable to the processing activity"
    />
  ),
  technicalSecurityMeasures: (
    <FormattedMessage
      id="DPIA.technicalSecurityMeasures"
      defaultMessage="List the technical security measures applicable to the processing activity"
    />
  ),
  contractualSecurityMeasures: (
    <FormattedMessage
      id="DPIA.contractualSecurityMeasures"
      defaultMessage="List the contractual security measures applicable to the processing activity"
    />
  ),
  basicTransferConcern: (
    <FormattedMessage
      id="DPIA.basicTransferConcern"
      defaultMessage="This assessment includes transfer to a third country or international organisation"
    />
  ),
  confidentialityThreats: (
    <FormattedMessage
      id="DPIA.threatToConfidentiality"
      defaultMessage="What are the threats that could lead to illigitimate access to individuals' data?"
    />
  ),
  integrityThreats: (
    <FormattedMessage
      id="DPIA.integrityThreats"
      defaultMessage="What are the threats that could lead to undesired modification of individuals' data?"
    />
  ),
  availabilityThreats: (
    <FormattedMessage
      id="DPIA.availabilityThreats"
      defaultMessage="What are the threats that could lead to disappearance of the individuals' data?"
    />
  ),
  threatLikelihood: (
    <FormattedMessage
      id="DPIA.confidentialityThreatLikelihood"
      defaultMessage="What is the likelihood of these individual threats occuring?"
    />
  ),
  confidentialityPotentialImpacts: (
    <FormattedMessage
      id="DPIA.confidentialityPotentialImpacts"
      defaultMessage="What could be impacts on individuals in case of illigitimate access to their data?"
    />
  ),
  integrityPotentialImpacts: (
    <FormattedMessage
      id="DPIA.integrityPotentialImpacts"
      defaultMessage="What could be impacts on individuals in case of undesired modification of their data?"
    />
  ),
  availabilityPotentialImpacts: (
    <FormattedMessage
      id="DPIA.availabilityPotentialImpacts"
      defaultMessage="What could be impacts on individuals in case of disappearance of their data?"
    />
  ),
  impactSeverity: (
    <FormattedMessage
      id="DPIA.impactSeverity"
      defaultMessage="What is the severity of these individual impacts?"
    />
  ),
  totalLikelihood: (
    <FormattedMessage
      id="DPIA.totalLikelihood"
      defaultMessage="What is the total combined likelihood of these threats occuring?"
    />
  ),
  totalSeverity: (
    <FormattedMessage
      id="DPIA.totalSeverity"
      defaultMessage="What is the total combined severity of these impacts?"
    />
  ),
  confidentialityResultingRisk: (
    <FormattedMessage
      id="DPIA.confidentialityResultingRisk"
      defaultMessage="What is the resulting risk of illigitimate access to data, looking at the
    total severity of the threats and impact to individuals?"
    />
  ),
  integrityResultingRisk: (
    <FormattedMessage
      id="DPIA.integrityResultingRisk"
      defaultMessage="What is the resulting risk of undesired modification of data, looking
    at the total severity of the threats and impact to individuals?"
    />
  ),
  availabilityResultingRisk: (
    <FormattedMessage
      id="DPIA.availabilityResultingRisk"
      defaultMessage="What is the resulting risk of disappearance of data, looking at the
    total severity of the threats and impact to individuals?"
    />
  ),
  confidentialityMitigation: (
    <FormattedMessage
      id="DPIA.confidentialityMitigation"
      defaultMessage="Which of the identified existing and planned measures contribute to
    addressing the risk of illegitimate acces to data?"
    />
  ),
  integrityMitigation: (
    <FormattedMessage
      id="DPIA.integrityMitigation"
      defaultMessage="Which of the identified existing and planned measures contribute to
    addressing the risk of undesired modification of data?"
    />
  ),
  availabilityMitigation: (
    <FormattedMessage
      id="DPIA.availabilityMitigation"
      defaultMessage="Which of the identified existing and planned measures contribute to
    addressing the risk of disappearance of data?"
    />
  ),
  confidentialityResidualRisk: (
    <FormattedMessage
      id="DPIA.confidentialityResidualRisk"
      defaultMessage="What is the residual risk of illigitimate access to data after these measures have been
    implemented?"
    />
  ),
  integrityResidualRisk: (
    <FormattedMessage
      id="DPIA.integrityResidualRisk"
      defaultMessage="What is the residual risk of undesired modification of data after these measures have
    been implemented?"
    />
  ),
  availabilityResidualRisk: (
    <FormattedMessage
      id="DPIA.availabilityResidualRisk"
      defaultMessage="What is the residual risk of disappearance of data after these measures have been implemented?"
    />
  ),
  mitigation: (
    <FormattedMessage
      id="DPIA.mitigation"
      defaultMessage="Mitigating measure"
    />
  ),
  preassessment: (
    <FormattedMessage
      id="DPIA.preassessment"
      defaultMessage="This chapter allows you to determine whether a full assessment (DPIA) should be done"
    />
  ),
  configuration: (
    <FormattedMessage
      id="DPIA.configuration"
      defaultMessage="This chapter allows you to add attachments and configure permissions"
    />
  ),
  processingDescription: (
    <FormattedMessage
      id="DPIA.processingDescription"
      defaultMessage="In this chapter, you will be able to identify the purpose, scope and processing grounds of the
    processing activity, along with the key stakeholders involved"
    />
  ),
  necessity: (
    <FormattedMessage
      id="DPIA.necessity"
      defaultMessage="In this chapter, you will be able to assess the necessity and proportionality of the processing
    activity, in line with privacy principles"
    />
  ),
  dataSubjectRights: (
    <FormattedMessage
      id="DPIA.dataSubjectRights"
      defaultMessage="Under the GDPR, individuals have different rights in relation to their data. In this chapter, you will be able to describe how individuals can exercise their rights and freedoms as well as demonstrate how you will deal with such requests to exercise rights."
    />
  ),
  securityMessures: (
    <FormattedMessage
      id="DPIA.securityMessures"
      defaultMessage="Under the GDPR, individuals have different rights in relation to their dataIn this chapter, you are asked to list security measures (both existing or planned) contributing to the protection of the personal data of individuals and to demonstrate compliance. In this chapter, you will be able to describe how individuals can exercise their rights and freedoms as well as demonstrate how you will deal with such requests to exercise rights."
    />
  ),
  threatImpact: (
    <FormattedMessage
      id="DPIA.threatImpact"
      defaultMessage="In this chapter, you will be able to assess the risks the the processing activity poses from the
    perspective of individuals whose data is processed. The total likelihood of the threats and the total severity of
    the impacts are calculated into a resulting risk. After considering how the measures defined in the previous chapter
    mitigate this risk,a residual risk will remain"
    />
  ),
  accountability: (
    <FormattedMessage
      id="DPIA.accountability"
      defaultMessage="In this chapter, you will be able to demonstrate accountability measures taken by your organisation"
    />
  ),
  dpoConsultation: (
    <FormattedMessage
      id="DPIA.dpoConsultation"
      defaultMessage="List the Data Protection Officers who have been consulted, and findings and decisions based
    on these findings. If DPOs haven't been consulted, justify why this hasn't been done."
    />
  )
};

export const getNecessityChapterFieldMessage = (type) => {
  if (type === 'purposeOfIntlTransfer') {
    return (
      <FormattedMessage
        id="DPIA.commentForTransferPurpose"
        defaultMessage="If the purpose for the international transfer is not displayed above, not displayed correctly or is incomplete,
    please make adjustments  in the chapter ‘Processing description’ (if you have access to that chapter). If you do not have access,
    use the field below to write down your adjustments."
      />
    );
  } else if (type === 'transferGrounds') {
    return (
      <FormattedMessage
        id="DPIA.commentForTransferGrounds"
        defaultMessage="If the transfer grounds for the international transfer are not displayed above, not displayed correctly or are incomplete,
        please make adjustments  in the chapter ‘Processing description’ (if you have access to that chapter). If you do not have access, use the
        field below to write down your adjustments."
      />
    );
  }
  return (
    <FormattedMessage
      id="Assessment.NandPFieldMessage"
      defaultMessage="If the {value} for the processing activity are not displayed above, not displayed correctly or are
    incomplete, please make adjustments in the chapter ‘Processing description’ (if you have access to that chapter).
    If you do not have access, use the field below to write down your adjustments."
      values={{ value: smallcasePlural(type) }}
    />
  );
};
