/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import { addTranslations, editTranslations } from '@packages/utils/actionTranslations';
import RetentionOffset from '.';

const RetentionOffsetDialog = (props) => {
  const handleOnSubmit = (selectedItem, closeDialog) => {
    if (props.handleSelectedItem) {
      selectedItem.key = selectedItem.name;
      props.handleSelectedItem({ value: selectedItem }, closeDialog);
    }
  };

  const requestClose = () => {
    if (props.onRequestClose) {
      props.onRequestClose();
    }
  };

  return (
    <CommonDialog
      id="retention-offset-dialog"
      fullWidth={true}
      maxWidth="sm"
      show={props.open}
      onCancel={requestClose}
      title={!props.isEdit ? addTranslations('addRetentionOffset') : editTranslations('editRetentionOffset')}
    >
      <RetentionOffset
        id="retention-offset"
        isEdit={props.isEdit}
        dataItemId={props.dataItemId}
        isUsed={props.isUsed}
        usage={props.usage}
        value={props.inputValue}
        onSave={handleOnSubmit}
        onCancel={requestClose}
        source={props.source}
        isTagEdit={props.isTagEdit}
        hideTags={props.hideTags}
      />
    </CommonDialog>
  );
};

RetentionOffsetDialog.propTypes = {
  dataItemId: PropTypes.string,
  open: PropTypes.bool,
  source: PropTypes.string,
  isEdit: PropTypes.bool,
  onRequestClose: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  inputValue: PropTypes.shape({}),
  isUsed: PropTypes.bool,
  usage: PropTypes.shape({}),
  isTagEdit: PropTypes.bool
};


RetentionOffsetDialog.defaultProps = {
  open: false,
  isEdit: false,
  source: '',
  onRequestClose: e => e,
  handleSelectedItem: e => e,
  inputValue: {},
  dataItemId: '',
  isUsed: false,
  usage: {},
  isTagEdit: false
};

export default RetentionOffsetDialog;
