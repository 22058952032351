import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { reduxForm } from 'redux-form';
import { FixedSizeList as List } from 'react-window';
import { FormattedMessage } from 'react-intl';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DivWrapper from '@packages/components/divWrapper';
import ArrowTooltip from '@packages/components/tooltip';
import styles from '@packages/ui/styles';
import CountrySelector from '@packages/components/country-selector';
import { commonTranslations } from '@packages/utils/commontranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';

const subHeaderStyle = {
  color: 'black',
  lineHeight: '20px',
  paddingLeft: '3px',
  marginBottom: '10px',
  fontSize: '16px'
};

const filterParams = {
  sortOn: 'name',
  sortOrder: 'ASC'
};

const countrySelectorStyle = { width: '80%' };

const divWrapperStyle = { marginTop: '20px' };

const checkBoxStyle = { paddingBottom: '15px', overflow: 'none', width: '95%', alignItems: 'center' };
const listStyle = { overflow: 'none', width: '95%' };

const labelWithEllipsis = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px'
};

export const MultipleSelectorForm = (props) => {
  const [filterSelectAll, setFilterSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState(Immutable.List());
  const [selectedCountries, setSelectedCountries] = useState('');
  const [filterValues, setFilterValues] = useState(props.filteredData);


  useEffect(() => {
    setFilterValues(props.filteredData);
  }, [props.filteredData]);

  useEffect(() => () => props.onresetFilter(), []);


  const listRef = React.createRef();

  const handleScroll = ({ target }) => {
    const { scrollTop } = target;
    listRef.current.scrollTo(scrollTop);
  };

  const handleSelectAll = (event, checked) => {
    let selectedItemsValue = Immutable.List();
    if (checked) {
      selectedItemsValue = filterValues;
    }
    setFilterSelectAll(checked);
    setSelectedItems(selectedItemsValue);
  };

  const handleChecked = (selectedItem, checked) => {
    let selectedItemsValue = selectedItems;
    if (checked) {
      if (!props.multiValue) selectedItemsValue = Immutable.List();
      selectedItemsValue = selectedItemsValue.push(selectedItem);
    } else {
      const index = selectedItems.findIndex(item => (item.key ? item.key : item) ===
        (selectedItem.key ? selectedItem.key : selectedItem));
      selectedItemsValue = selectedItemsValue.splice(index, 1);
    }
    setSelectedItems(selectedItemsValue);
    setFilterSelectAll(selectedItemsValue.size === filterValues.size);
  };

  const handleCountryChange = (selectedItem) => {
    if (selectedItem === '' && selectedCountries !== '') {
      props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'countries',
        filteredOn: [] }));
    } else if (selectedItem !== '' && selectedItem !== selectedCountries) {
      props.onChooseFilter(Object.assign({}, filterParams, { filterKey: 'countries',
        filteredOn: [selectedItem] }));
    }
    setSelectedCountries(selectedItem || '');
  };


  const handleCheckedValue = (item) => {
    const index = selectedItems.findIndex((x) => {
      let indexValue = -1;
      if (typeof (x) === 'object' && typeof (item.props) === 'object') {
        indexValue = x.props.defaultMessage === item.props.defaultMessage;
      } else {
        indexValue = x.id === item.id;
      }
      return indexValue;
    });
    return index;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = (propValue) => {
    const { index, style } = propValue;
    const item = filterValues.get(index);
    return (
      <div>
        <FormGroup column={true}>
          <FormControlLabel
            control={
              <Checkbox
                key={item.key ? item.key : item}
                checked={handleCheckedValue(item) !== -1}
                style={styles.checkBoxStyle}
                onChange={(evt, isChecked) => handleChecked(item, isChecked)}
                color="primary"
              />}
            label={
              <ArrowTooltip
                title={item.key || item}
              >
                <div style={labelWithEllipsis}>{item.key || item}</div>
              </ArrowTooltip>}
            style={Object.assign({}, style, { alignItems: 'center' })}
          />
        </FormGroup>
      </div>
    );
  };

  const { handleSubmit, onSave, onCancel, multiValue } = props;
  const TABLE_MAXHEIGHT = 214;
  const WINDOW_INNERHEIGHT = 707;
  return (
    <form>
      <div>
        <div>
          <ListSubheader style={subHeaderStyle}>
            <FormattedMessage
              id="DataRecipientCategory.filterByCountry"
              description="select country to filter"
              defaultMessage="Select country to filter"
            />
          </ListSubheader>
          <div style={{ display: 'flex' }}>
            <div style={countrySelectorStyle} >
              <CountrySelector
                id="country-selector"
                defaultValue={selectedCountries}
                hintTextLabel={commonTranslations.selectCountry}
                onChange={handleCountryChange}
              />
            </div>
          </div>
        </div>
      </div>
      <DivWrapper
        id="div-wrapper"
        autoHeight={true}
        autoHeightMax={getDialogContentHeight(WINDOW_INNERHEIGHT, TABLE_MAXHEIGHT)}
        onScroll={handleScroll}
        onScrollStop={props.onScrollStop}
        style={divWrapperStyle}
      >
        {multiValue &&
          <FormGroup column={true}>
            <FormControlLabel
              control={
                <Checkbox
                  id="all"
                  key="all"
                  checked={filterSelectAll}
                  onChange={handleSelectAll}
                  style={{ ...styles.checkBoxStyle, paddingBottom: 10 }}
                  color="primary"
                />}
              style={checkBoxStyle}
              label={commonTranslations.selectAll}
            />
          </FormGroup>}
        {filterValues &&
          <List
            style={listStyle}
            height={200}
            itemCount={filterValues.size}
            itemSize={40}
            ref={listRef}
          >
            {Row}
          </List>
          }
      </DivWrapper>
      <div style={Object.assign({}, styles.multiselectAddButton)}>
        <Button
          id="button"
          onClick={handleSubmit(() => onSave(selectedItems))}
        >
          {commonTranslations.saveAndClose}
        </Button>
        <Button
          id="cancel-button"
          variant="text"
          onClick={onCancel}
        >
          {commonTranslations.Cancel}
        </Button>
      </div>
    </form>
  );
};

MultipleSelectorForm.propTypes = {
  multiValue: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onresetFilter: PropTypes.func,
  filteredData: PropTypes.instanceOf(Immutable.List),
  onScrollStop: PropTypes.func
};

MultipleSelectorForm.defaultProps = {
  multiValue: true,
  filteredData: Immutable.List(),
  onSave: e => e,
  onCancel: e => e,
  handleSubmit: e => e,
  onScrollStop: e => e,
  onChooseFilter: e => e,
  onresetFilter: e => e
};

const MultipleSelectorFormWrapper = reduxForm({
  form: 'MultipleSelectorForm' // a unique identifier for this form
})(MultipleSelectorForm);

export default MultipleSelectorFormWrapper;
