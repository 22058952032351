import { connect } from 'react-redux';

import PrivacyRecordListComponent from './privacyRecordList';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  items: state.privacyRecords.list.get('items'),
  pageSearchText: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('all').pageSearchText,
  sortOrder: state.privacyRecords.list.get('sortAndFilterParams').get('all')
    .sortOrder,
  sortKey: state.privacyRecords.list.get('sortAndFilterParams').get('all')
    .sortKey,
  filterColumn: state.privacyRecords.list.get('sortAndFilterParams').get('all')
    .filterColumn,
  filteredOn: state.privacyRecords.list
    .get('sortAndFilterParams')
    .get('all')
    .filteredOn.toJS(),
  position: state.privacyRecords.list.get('position'),
  isFetching: state.privacyRecords.list.get('isFetching'),
  error: state.privacyRecords.list.get('error').toJS(),
  isUpdating:
    state.assessment?.get('isUpdating') ||
    state.breach?.get('isUpdating') ||
    state.processing?.get('isUpdating') ||
    state.tia?.get('isUpdating'),
  pricingPlan: state.login?.get('pricingPlanDetails'),
  registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
  tenantLocale: state.login?.get('tenantLocale'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch({ type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT', recordType: 'all' });
  },
  getNextData: (position, rowCount) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      rowCount,
      recordType: 'all'
    });
  },
  deletePrivacyRecord: (recordId, recordType) => {
    dispatch({ type: 'PRIVACY_RECORD:ITEM:DELETE', recordId, recordType });
  },
  onChooseFilter: (filterParams) => {
    dispatch({ type: 'PRIVACY_RECORDS:LIST:FILTER_INIT', filterParams });
  },
  updateNote: (recordId, recordType, property, data, name) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType,
      property,
      data,
      currentFilter: 'all',
      name
    });
  },
  copyRecord: (
    data,
    copyOfRecordId,
    recordType,
    locale,
    tenantLocale,
    formatMessage,
    isGlobal
  ) => {
    if (recordType === 'CUSTOM') {
      dispatch({
        type: 'CUSTOM_RECORD:ITEM',
        copyOfRecordId,
        mode: 'COPY',
        locale,
        tenantLocale,
        formatMessage
      });
    } else
      dispatch({
        type: `${recordType}:DETAIL:UPSERT`,
        data,
        copyOfRecordId,
        isGlobal
      });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyRecordListComponent);
