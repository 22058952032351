import { connect } from 'react-redux';
import ControllerSelector from './component';

const mapStateToProps = state => ({
  controllers: state.controllers.get('controllers'),
  position: state.controllers.get('position'),
  error: state.controllers.get('error'),
  searchResults: state.controllers.get('items')
});

const mapDispatchToProps = dispatch => ({
  initControllers: () => {
    dispatch({ type: 'CONTROLLERS:LIST:REQUEST:INIT' });
    dispatch({ type: 'CONTROLLERS:LIST:REQUEST' });
  },
  getNextData: (position) => {
    dispatch({ type: 'CONTROLLERS:LIST:REQUEST', position });
  },
  onSearch: (searchParams) => {
    dispatch({ type: 'CONTROLLERS_SELECTOR:LIST:SEARCH_INIT', searchParams });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ControllerSelector);
