import { connect } from 'react-redux';
import RetentionTermSelectorDefault from './component';

const mapStateToProps = state => ({
  retentionTerms: state.retentionTerms.get('retentionTerms'),
  error: state.retentionTerms.get('error')
});

const mapDispatchToProps = dispatch => ({
  initRetentionTerms: () => {
    dispatch({ type: 'RECORDS:RETENTION_TERMS:LIST:REQUEST_INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RetentionTermSelectorDefault);
