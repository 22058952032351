import styles from '@packages/ui/styles';

export default {
  ...styles,
  formMargin: {
    margin: '30px',
    marginTop: '0px'
  },
  textField: {
    width: '90%',
    height: '40px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #9b9b9b'
  },
  formbutton: {
    height: '50px',
    minWidth: '220px'
  },
  headerStyle: {
    fontSize: '18px',
    fontWeight: '600',
    marginTop: '30px',
    color: 'rgb(0, 0, 0)'
  },
  labelStyle: {
    width: '30%',
    color: 'rgb(0, 0, 0)'
  },
  subHeaderStyle: {
    fontSize: '16px',
    fontWeight: '600',
    marginTop: '40px',
    color: 'rgb(0, 0, 0)'
  },
  rootStyle: {
    display: 'flex',
    width: '80%',
    marginTop: '15px'
  },
  detailsLabelStyle: {
    width: '40%',
    color: 'rgb(0, 0, 0)'
  },
  detailsValueStyle: {
    width: '60%',
    color: 'rgb(0, 0, 0)'
  }
};
