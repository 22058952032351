import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export default function* fetchRetentionTerms() {
  try {
    const response = yield registry.get('request')
      .get(`/v1/records/retentionterms`, null);
    const items = response.body;

    yield put({ type: 'RETENTION_TERMS:RECORDS:LIST:FETCH:SUCCESS', items });
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: 'RETENTION_TERMS:RECORDS:LIST:FETCH:FAIL', error: err.message });
  }
}
