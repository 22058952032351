import { combineReducers } from 'redux';

import jobs from './jobs';
import dataLibraryLayout from './dataLibraryLayout';
import layouts from './layouts';
import records from './records';
import templates from './templates';

export default combineReducers({
  jobs,
  dataLibraryLayout,
  layouts,
  records,
  templates
});
