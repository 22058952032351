import { takeEvery } from 'redux-saga/effects';

import {
  loginFlow,
  refreshFlow,
  tokenRefreshFlow,
  tokenNotValidFlow,
  forgotPassword,
  getSSOURL,
  checkSSOAndDSREnabled,
  onPostLogin,
  getPricingPlanUsage,
  fetchAllAccessGroup
} from './sagas';

import { otpSubmission, resetQrCode,
  checkMfaConfiguration } from './components/mfa/sagas';

// Login
export default [
  [takeEvery, 'LOGIN:DO_LOGIN', loginFlow],
  [takeEvery, 'LOGIN:POST_LOGIN:SUCCESS', onPostLogin],
  [takeEvery, 'LOGIN:VERIFY', refreshFlow],
  [takeEvery, 'USER:TOKEN_REFRESH', tokenRefreshFlow],
  [takeEvery, 'LOGIN:TOKEN_REFRESH:FAIL', tokenNotValidFlow],
  [takeEvery, 'LOGIN:FORGOT_PASSWORD', forgotPassword],
  [takeEvery, 'SSO_URL:FETCH', getSSOURL],
  [takeEvery, 'TENANT:SETTINGS:SSO_DSR_ENABLED:FETCH', checkSSOAndDSREnabled],
  // Pricing plan
  [takeEvery, 'TENANT:PRICING:PLAN:INIT', getPricingPlanUsage],

  [takeEvery, 'USER:OTP:SUBMISSION:REQUEST', otpSubmission],
  [takeEvery, 'USER:QR:RESET:REQUEST', resetQrCode],
  [takeEvery, 'USER:CHECK:MFA:REQUEST', checkMfaConfiguration],
  [takeEvery, 'ACCESS_GROUP:FETCH:INIT', fetchAllAccessGroup]
];
