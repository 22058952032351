import { takeEvery } from 'redux-saga/effects';

import { createDSR, deleteDatasubjectRequests, fetchDatasubjectRequests,
  filterDatasubjectRequests, resetAndFetchDatasubjectRequests } from './components/dsr-list/sagas';
import { createDSRForms, deleteDsrForm, fetchDsrForms, filterDsrForms,
  initializeDSRForm, resetAndfetchDsrForms, upsertDSRForm } from './components/forms/sagas';
import { deleteRequester, fetchRequesterItem, fetchRequesters,
  filterRequesters, resetAndfetchRequesters, unmaskAndUpdate, unmaskRequester,
  updateRequester } from './components/requester-list/sagas';

export default [
  // DSR Requesters
  [takeEvery, 'DATAREQUESTER:LIST:REQUEST_INIT', resetAndfetchRequesters],
  [takeEvery, 'DATAREQUESTER:LIST:NEXT_FETCH', fetchRequesters],
  [takeEvery, 'DATAREQUESTER:UPSERT', updateRequester],
  [takeEvery, 'DATAREQUESTER:UNMASK_UPDATE', unmaskAndUpdate],
  [takeEvery, 'DATAREQUESTERS:DELETE', deleteRequester],
  [takeEvery, 'DATAREQUESTER:ITEM:FETCH', fetchRequesterItem],
  [takeEvery, 'DATAREQUESTER:ITEM:UNMASK', unmaskRequester],
  [takeEvery, 'DATAREQUESTER:LIST:FILTER_INIT', filterRequesters],
  [takeEvery, 'DATA_SUBJECT_REQUESTS:LIST:REQUEST_INIT', resetAndFetchDatasubjectRequests],
  [takeEvery, 'DATA_SUBJECT_REQUESTS:LIST:NEXT_FETCH', fetchDatasubjectRequests],
  [takeEvery, 'DATA_SUBJECT_REQUESTS:CREATE', createDSR],
  [takeEvery, 'DATA_SUBJECT_REQUESTS:LIST:FILTER_INIT', filterDatasubjectRequests],
  [takeEvery, 'DATA_SUBJECT_REQUESTS:DELETE', deleteDatasubjectRequests],

  [takeEvery, 'DSR:FORMS:LIST:REQUEST_INIT', resetAndfetchDsrForms],
  [takeEvery, 'DSR:FORMS:LIST:NEXT_FETCH', fetchDsrForms],
  [takeEvery, 'DSR:FORMS:LIST:FILTER_INIT', filterDsrForms],
  [takeEvery, 'DSR:FORMS:CREATE:INIT', createDSRForms],
  [takeEvery, 'DSR:FORMS:ITEM:FETCH:INIT', initializeDSRForm],
  [takeEvery, 'DSR:FORMS:ITEM:UPSERT', upsertDSRForm],
  [takeEvery, 'DSR:FORMS:DELETE', deleteDsrForm]
]
