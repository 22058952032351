import { connect } from 'react-redux';
import LinkedRecords from './component';

const mapStateToProps = (state) => ({
  linkedRecords: state.linkedRecords.get('items'),
  position: state.linkedRecords.get('position')
});

const mapDispatchToProps = (dispatch) => ({
  initLinkedRecords: (recordType) => {
    dispatch({ type: 'LINKED_RECORD:LIST:REQUEST:INIT', recordType });
  },
  searchLinkedRecords: (recordType, searchParams) => {
    dispatch({
      type: 'LINKED_RECORD:LIST:REQUEST:INIT',
      recordType,
      searchParams
    });
  },
  getNextData: (recordType, position) => {
    dispatch({ type: 'LINKED_RECORD:LIST:REQUEST', recordType, position });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkedRecords);
