import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { deleteSuccessError } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  handleServiceDown,
  MASTERDATA_FETCH_LIMIT,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import { getURLWithParams, updateDataItems } from '../saga-utils';

export function* resetAndfetchPersonalDataItemsList(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:REQUEST:INIT' });
  const personalDataCategoryState = yield select(
    (state) => state.environment.personalDataCategories
  );
  const personalDataCategories = personalDataCategoryState
    ? personalDataCategoryState.get('items')
    : [];
  yield fetchPersonalDataItems(action, personalDataCategories);
}

export function* fetchPersonalDataItems(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH' });
  try {
    const {
      position = 0,
      rowCount = MASTERDATA_FETCH_LIMIT,
      source = ''
    } = action;

    let filterParams = {};
    let recordsFilterParams = [];
    let unused = false;
    let filteredOn = Immutable.Map();
    let recordsFilteredOn = Immutable.Map();
    let pageSearchTextObj = {};
    let { searchParams } = action;
    if (action.isPersistedData) {
      const personalDataItemsState = yield select(
        (state) => state.environment.personalDataItems
      );
      if (personalDataItemsState) {
        recordsFilterParams = personalDataItemsState
          .get('recordsFilterParams')
          .toJS();
        recordsFilteredOn = personalDataItemsState.get('recordsFilteredOn');
        filterParams = personalDataItemsState.get('filterParams').toJS();
        unused = source !== 'records' && personalDataItemsState.get('unused');
        filteredOn = personalDataItemsState.get('filteredOn');
        pageSearchTextObj = personalDataItemsState.get('pageSearchText').toJS();
        const searchText = personalDataItemsState.get('searchText').toJS();
        searchParams =
          pageSearchTextObj && source !== 'records'
            ? { searchKey: 'name', searchText: pageSearchTextObj.name }
            : { searchKey: 'name', searchText: searchText.name };
      }
    }
    const { sortOn = 'name', sortOrder = 'ASC' } =
      source === 'records' ? recordsFilterParams : filterParams;

    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          {
            sortOn,
            sortOrder,
            filteredOn: source === 'records' ? recordsFilteredOn : filteredOn
          },
          position,
          searchParams,
          'personaldataitems',
          rowCount,
          undefined,
          unused
        ),
        null,
        {}
      );
    const modifiedItems = response.body.map((item) => ({
      ...item,
      key: `${item.name}`
    }));
    yield put({
      type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH:SUCCESS',
      items: modifiedItems,
      personalDataItemData: action.personalDataItemData
    });
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({
      type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FETCH:FAIL',
      error: err.message
    });
    yield put({
      type: 'RECORDS:PERSONAL_DATA_ITEMS:LIST:REQUEST:FAIL',
      error: err.message
    });
  }
}

export function* filterPersonalDataItems(action) {
  const {
    filterParams,
    source = '',
    searchParams: { searchText } = {}
  } = action;
  yield put({
    type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:FILTER',
    filterParams,
    source
  });
  yield put({
    type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH',
    searchKey: 'name',
    searchText
  });
  yield fetchPersonalDataItems(action);
}

export function* searchPersonalDataItems(action) {
  const {
    searchParams: { searchKey, isPageSearch },
    searchParams,
    rowCount = MASTERDATA_SEARCH_LIMIT
  } = action;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'LIST:SEARCH';
  const personalDataItemsState = yield select(
    (state) => state.environment.personalDataItems
  );
  const {
    sortOn,
    sortOrder,
    filteredOn,
    searchParameters,
    searchText,
    unused
  } = getFilterAndSearchParamsFromState(
    personalDataItemsState,
    searchParams,
    searchMode
  );

  const personalDataCategories = personalDataItemsState
    ? personalDataItemsState.get('personalDataCategories')
    : [];
  yield put({
    type: `MASTERDATA:PERSONAL_DATA_ITEMS:${searchMode}`,
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position,
          searchParameters,
          'personaldataitems',
          rowCount,
          undefined,
          unused
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: `${item.name}`
        }));
        yield put({
          type: `MASTERDATA:PERSONAL_DATA_ITEMS:${searchMode}:SUCCESS`,
          searchKey,
          searchText,
          items: modifiedItems,
          personalDataCategories
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({
      type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:SEARCH:FAIL',
      error: err.message
    });
  }
}

export function* upsertPersonalDataItems(action) {
  const store = registry.get('store');
  const { isEdit, data, source, isUsed } = action;
  const request = registry.get('request');
  const requestData = Object.assign({}, data);
  const modifiedRequestData = {
    ...requestData,
    categories:
      requestData.categories &&
      requestData.categories.map((category) => category.value || category)
  };
  let response;
  try {
    if (source === 'records' || (!isEdit && source !== 'records')) {
      // while invoking from records or invoking an ADD from masterdata
      // always do a post
      delete modifiedRequestData.id;
      response = yield request.post(
        `/v1/masterdata/personaldataitems`,
        modifiedRequestData
      );
    } else if (!isUsed) {
      response = yield request.put(
        `/v1/masterdata/personaldataitems/${modifiedRequestData.id}?withJob=false`,
        modifiedRequestData
      );
    }
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        if (isUsed) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.jobSubmittedSuccess,
              type: 'success'
            }
          });

          // After submitting job redirected to job list page.
          yield put({ type: 'TENANT_DASHBOARD:FILTERS:CHANGE', filterName: 'jobs' });
          store.dispatch(replaceRouter('/dashboard'));
        } else {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationMessages[isEdit],
              type: 'success'
            }
          });
        }

        const modifiedItem = {
          ...response.body,
          key: `${response.body.name}`
        };
        // Handle consistency in the front end.
        if (isEdit) {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:SUCCESS',
            personalDataItem: modifiedItem
          });
          yield updatePersonalDataItems({
            type: 'edit',
            data: Object.assign({}, modifiedItem),
            source
          });
        } else {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:SUCCESS',
            personalDataItem: modifiedItem,
            position: 0
          });
          yield fetchPersonalDataItems({
            personalDataItemData: modifiedItem,
            isPersistedData: true
          });
        }
        break;
      }
      case 409:
        yield put({
          type: 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      case 412: {
        yield put({
          type: 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
    yield put({
      type: 'MASTERDATA:PERSONAL_DATA_ITEMS:UPSERT:FAIL',
      error: err.message
    });
  }
}

export function* fetchPersonalDataItem(action) {
  yield put({ type: 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:INIT' });
  if (action.id) {
    try {
      const response = yield registry
        .get('request')
        .get(`/v1/masterdata/personaldataitems/${action.id}`, null);
      const item = response.body;
      switch (response.status) {
        case 200: {
          yield put({
            type: 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:SUCCESS',
            personalDataItemData: item
          });
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
      }
    } catch (err) {
      yield handleServiceDown(err, 'masterData');
      yield put({
        type: 'MASTERDATA:PERSONAL_DATA_ITEMS:ITEM:FETCH:FAIL',
        error: err.message
      });
    }
  }
}

export function* deletePersonalDataItem(action) {
  const { dataItemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(
      `/v1/masterdata/personaldataitems/${dataItemId}`,
      null
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: deleteSuccessError('personalDataItem'),
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updatePersonalDataItems({
          type: 'delete',
          personalDataItemId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'masterData');
  }
}

export function* createPersonalDataItemJob(action) {
  const { operationType, entityType, data } = action;

  let modifiedItem = data;
  if (operationType === 'Delete') {
    delete data.categories;
    modifiedItem = { ...data, categories: data.personalDataCategories };
    delete modifiedItem.personalDataCategories;
  } else if (operationType === 'Edit') {
    modifiedItem = {
      ...data,
      categories:
        data.categories && data.categories.map((item) => item.value || item)
    };
  }

  yield put({
    type: 'MASTER_DATA_ITEM:CREATE:JOB',
    actionType: operationType,
    entityType,
    data: modifiedItem
  });
}

function* updatePersonalDataItems(action) {
  const { type, data, personalDataItemId, source } = action;
  const personalDataItemsState = yield select(
    (state) => state.environment.personalDataItems
  );
  let position = null;
  let items = personalDataItemsState
    ? personalDataItemsState.get('personalDataItems')
    : Immutable.List();
  let newItems = personalDataItemsState
    ? personalDataItemsState.get('newItems')
    : Immutable.List();
  if (type === 'delete') {
    position = personalDataItemsState.get('position') - 1;

    items = updateDataItems(items, type, personalDataItemId);
    newItems = updateDataItems(newItems, type, personalDataItemId);
  } else if (type === 'edit' && source !== 'records') {
    const modifiedItem = transformPersonalDataItemItem(data);
    items = updateDataItems(
      items,
      type,
      data.oldEntityId || data.id,
      source,
      modifiedItem
    );
    newItems = updateDataItems(
      newItems,
      type,
      data.oldEntityId || data.id,
      source,
      modifiedItem
    );
  }
  yield put({
    type: 'MASTERDATA:PERSONAL_DATA_ITEMS:LIST:UPDATE:SUCCESS',
    items,
    newItems,
    position
  });
}

const transformPersonalDataItemItem = (item) => ({
  ...item,
  key: `${item.name}`
});
const notificationMessages = {
  true: notificationtranslations.personalDataItemUpdateSuccess,
  false: notificationtranslations.personalDataItemCreated
};

const getFilterAndSearchParamsFromState = (
  personalDataItemsState,
  searchParams,
  searchMode
) => {
  // Filter parameters
  let filterParams = {};
  let pageSearchTextObj = {};
  let unused = false;
  if (personalDataItemsState) {
    filterParams = personalDataItemsState.get('filterParams').toJS();
    unused = personalDataItemsState.get('unused');
    pageSearchTextObj = personalDataItemsState.get('pageSearchText').toJS();
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn =
    personalDataItemsState && searchMode !== 'LIST:SEARCH'
      ? personalDataItemsState.get('filteredOn')
      : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;
    let searchTextObj = Immutable.Map();

    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined) {
      searchTextObj =
        searchMode === 'LIST:SEARCH'
          ? personalDataItemsState.get('searchText')
          : personalDataItemsState.get('pageSearchText');
      searchText = searchTextObj.get(searchKey) || '';
    }
    searchParameters = Object.assign({}, searchParams, { searchText });
  } else {
    searchParameters = pageSearchTextObj
      ? { searchKey: 'name', searchText: pageSearchTextObj.name }
      : {};
  }

  return {
    sortOn,
    sortOrder,
    filteredOn,
    searchParameters,
    searchText,
    unused
  };
};
