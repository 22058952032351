import { connect } from 'react-redux';
import TransferGroundSelector from './component';
import reducers from './reducers';

const mapStateToProps = (state) => ({
  filteredOn: state.transferGrounds.get('filteredOn').toJS()
});

const mapDispatchToProps = (dispatch) => ({
  removeFilters: (jurisdiction) => {
    dispatch({ type: 'TRANSFER_GROUNDS:LIST:FILTER:REMOVE' });
    dispatch({ type: 'TRANSFER_GROUNDS:LIST:INIT', jurisdiction });
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferGroundSelector);
export { reducers };
