import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  messageDetails: Immutable.Map({}),
  actionError: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ADMIN:BANNER:FETCH':
      return state.set('messageDetails', defaultState.get('messageDetails'));
    case 'ADMIN:BANNER:FETCH:SUCCESS':
    case 'ADMIN:BANNER:UPSERT:SUCCESS':
      return state.set('messageDetails', Immutable.Map(action.messageDetails));
    case 'ADMIN:BANNER:FETCH:FAIL':
      return state.set('actionError', Immutable.Map({ message: action.error, isError: true }));
    default:
      return state;
  }
};
