import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';
import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: undefined,
  simpleEntityData: undefined,
  position: 0,
  sortAndSearchParams: Immutable.Map({
    dataSubjectCategoriesFinding: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    dpoFinding: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    },
    supervisoryAuthoritiesFinding: {
      filteredOn: Immutable.Map(),
      filterColumn: {},
      filterParams: Immutable.Map({
        sortOn: 'name',
        sortOrder: 'ASC'
      }),
      searchText: Immutable.Map(),
      bulkItems: Immutable.List(),
      unused: false
    }
  }),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isUsed: undefined,
  newItems: Immutable.List(),
  globalUsage: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:FINDINGS:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('isUsed', defaultState.get('isUsed'))
        .set('simpleEntityData', defaultState.get('simpleEntityData'));
    case 'MASTERDATA:FINDINGS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('newItems', defaultState.get('newItems'))
        .set('items', defaultState.get('items'))
        .set('position', 0);
    case 'MASTERDATA:FINDINGS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MASTERDATA:FINDINGS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.simpleEntityData) {
        newItems = newItems.unshift(action.simpleEntityData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('position', state.get('position') + action.items.length)
        .set('items', Immutable.List(items));
    }
    case 'MASTERDATA:FINDINGS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:FINDINGS:LIST:FILTER': {
      const { filterParams, entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.filterColumn = Immutable.Map(Object.assign({},
          currrentSortAndSearchParams.filterColumn,
          { [filterParams.filterKey]: filterParams.filterKey }));
        currrentSortAndSearchParams.filteredOn = getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: currrentSortAndSearchParams.filteredOn }));
        currrentSortAndSearchParams.filterParams = Immutable.Map(action.filterParams);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:FINDINGS:PAGE_SEARCH': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.searchText = getSearchText(action);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:FINDINGS:PAGE_SEARCH:SUCCESS': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currrentSortAndSearchParams = { ...sortAndSearchParams.get(entityType) };
      if (currrentSortAndSearchParams) {
        currrentSortAndSearchParams.searchText = getSearchText(Object.assign({}, action,
          { searchObj: currrentSortAndSearchParams.searchText.toJS() }));
        sortAndSearchParams = sortAndSearchParams.set(entityType, currrentSortAndSearchParams);
      }
      return state
        .set('isFetching', false)
        .set('sortAndSearchParams', sortAndSearchParams)
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    }
    case 'MASTERDATA:FINDINGS:PAGE_SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:FINDINGS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('simpleEntityData', action.simpleEntity)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }
    case 'MASTERDATA:FINDINGS:UPSERT:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MASTERDATA:FINDINGS:LIST:UPDATE:SUCCESS':
      return state
        .set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'))
        .set('newItems', action.newItems);
    case 'MASTERDATA:FINDINGS:ITEM:FETCH:SUCCESS':
      return state.set('simpleEntityData', action.simpleEntityData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    case 'MASTERDATA:FINDINGS:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:FINDINGS:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);
    case 'MASTERDATA:FINDINGS:SUB_TENANT:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('globalUsage', action.globalUsage);
    case 'MASTERDATA:FINDINGS:SUB_TENANT:USAGE:RESET':
      return state
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'MASTERDATA:FINDINGS:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MASTERDATA:FINDINGS:ITEM:MERGE:CHECK': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      const items = currentItems.bulkItems;
      if (currentItems) {
        currentItems.bulkItems = getCurrentMergeItems(action.item, items, action.dataItemId, action.unused);
        sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      }
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:FINDINGS:MERGE:ITEM:RESET': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      currentItems.bulkItems = Immutable.List();
      sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:FINDINGS:ITEM:UNUSED:CHECK': {
      const { entityType } = action;
      let sortAndSearchParams = state.get('sortAndSearchParams');
      const currentItems = { ...sortAndSearchParams.get(entityType) };
      currentItems.unused = action.checked || false;
      sortAndSearchParams = sortAndSearchParams.set(entityType, currentItems);
      return state.set('sortAndSearchParams', sortAndSearchParams);
    }
    case 'MASTERDATA:FINDINGS:USAGE:FETCH:INIT':
      return state
        .set('isFetching', true);
    default:
      return state;
  }
};
