/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Immutable from 'immutable';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ListItemIcon } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

import { ActionCell } from '@packages/components/responsive-table';
import styles from '@packages/ui/styles';
import { checkActionPermission } from '@packages/utils/common-utils';

const defaultStyle = {
  filterType: {
    lineHeight: 'unset',
    whiteSpace: 'unset',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    height: '26px',
    color: '#444',
    fontSize: 15
  },
  filterTypeActive: {
    backgroundColor: '#000',
    color: '#EEE',
    fontSize: 15
  },
  currentFilterMarker: {
    width: '0px',
    height: '0px',
    borderLeft: '20px solid black',
    borderTop: '24px solid transparent',
    borderBottom: '24px solid transparent'
  },
  parentFilterMarker: {
    width: '0px',
    height: '0px',
    borderLeft: '20px solid black',
    borderTop: '24px solid transparent',
    borderBottom: '24px solid black'
  },
  singleParentFilterMarker: {
    width: '0px',
    height: '0px',
    borderLeft: '20px solid black',
    borderTop: '24px solid transparent',
    borderBottom: '24px solid transparent'
  },
  subMenuStyle: {
    listStyleType: 'none',
    margin: 0,
    paddingLeft: '25px'
  },
  selectedMenuStyle: {
    paddingRight: '8px',
    paddingBottom: '13px'
  },
  multiLevelStyle: {
    padding: '10px 10px 10px 0',
    fontSize: '0.6rem'
  }
};

const filterTypeActiveStyle = {
  ...defaultStyle.filterType,
  ...defaultStyle.filterTypeActive
};

export const GenericFilter = (props) => {
  const [chapter, setChapter] = useState('');
  const [collapsedFilter, setCollapsedFilter] = useState(props.currentFilter);

  const themeColor = props.theme.palette.primary.main;

  useEffect(() => {
    setCollapsedFilter(props.currentFilter);
    const keyValues = currentFilter?.split('_');
    setChapter(keyValues.length >= 1 && keyValues[keyValues.length - 1]);
  }, [props.currentFilter]);

  const getFilterItemStyle = (currentFilter, menu, enable) => {
    if (chapter === menu.key) {
      return filterTypeActiveStyle;
    }
    if (!enable) {
      return { ...defaultStyle.filterType, color: newLayout && 'white' };
    }
    return { ...defaultStyle.filterType, ...styles.disabled };
  };

  const getSubMenuStyle = (currentFilter, value, enable) => {
    if (currentFilter === value) {
      return { color: themeColor, fontWeight: 'bold' };
    }
    if (enable !== undefined && !enable) {
      return { ...defaultStyle.filterType, ...styles.disabled };
    }
    return null;
  };

  const getSelectedIcon = (currentFilter, value) => {
    if (currentFilter === value) {
      return (
        <span style={{ marginTop: '5px', marginLeft: '-2px' }}>
          {' '}
          <ArrowRight />
        </span>
      );
    }
    return null;
  };

  const handleMenuClick = (menu, value) => {
    if (props.unSavedData) {
      setCollapsedFilter(collapsedFilter);
    } else if (collapsedFilter !== value) {
      setCollapsedFilter(value);
    } else setCollapsedFilter('');
    let currentData;
    if (menu?.subMenu?.length > 0)
      currentData = menu.subMenu.find(
        (item) => item.key === menu.defaultFilter
      );
    else currentData = menu;
    props.onClick(value.defaultFilter || value, currentData);
  };

  const {
    currentFilter,
    filterMenu,
    newLayout,
    style,
    showMenuButtons,
    handleEditAction,
    handleDeleteAction,
    currentUser,
    createEditTenantDashboard,
    showActionButtons,
    handleRejectAction
  } = props;

  const keyValues = collapsedFilter?.split('_');

  /* eslint-disable react/no-array-index-key */
  const menuItems =
    filterMenu &&
    filterMenu.map((menu, index) => (
      <div key={`${menu.key}_${index}`}>
        <div style={{ display: 'flex', ...props.style.collapseWidthStyle }}>
          <MenuItem
            id={menu.key}
            className={chapter === menu.key ? '' : 'menu-item'}
            style={{
              ...getFilterItemStyle(currentFilter, menu, !menu.enable),
              width: '220px',
              ...style.menuItem,
              ...props.style.menuItem
            }}
            onClick={() => handleMenuClick(menu, menu.defaultFilter)}
          >
            {menu.showIcon && (
              <ListItemIcon>
                <CheckCircleIcon
                  color={
                    menu.key === currentFilter?.split('_')[1]
                      ? 'secondary'
                      : 'primary'
                  }
                  style={{ height: '23px' }}
                />
              </ListItemIcon>
            )}
            {menu.label}
          </MenuItem>
          {menu.subMenu && chapter === menu.key ? (
            <div style={defaultStyle.parentFilterMarker} />
          ) : (
            !menu.subMenu &&
            chapter === menu.key && (
              <div style={defaultStyle.singleParentFilterMarker} />
            )
          )}
        </div>
        <Collapse
          in={
            collapsedFilter === menu.key ||
            (keyValues.length > 1 &&
              keyValues[keyValues.length - 1] === menu.key)
          }
          timeout="auto"
          unmountOnExit={true}
          style={
            newLayout
              ? {
                backgroundColor: '#D6D6D6',
                width: '190px',
                ...props.style.collapseWidthStyle
              }
              : {
                backgroundColor: '#EFEFEF',
                width: '240px',
                ...props.style.subMenuHeaderStyle
              }
          }
        >
          {menu.subMenu &&
            menu.subMenu.map((submenu) => (
              <ul
                className={!submenu.subMenu ? 'sub-menu' : ''}
                style={{
                  ...defaultStyle.subMenuStyle,
                  display: submenu.hidden ? 'none' : 'block'
                }}
                key={`${submenu.key}_${index}`}
                id={submenu.key}
              >
                <div style={{ display: 'flex', wordBreak: 'break-word' }}>
                  <li
                    className="filterSubmenu"
                    role="presentation"
                    style={{
                      ...defaultStyle.filterType,
                      fontWeight: submenu.subMenu && 'bold',
                      ...getSubMenuStyle(
                        currentFilter,
                        submenu.key,
                        submenu.enable
                      ),
                      minHeight: '40px',
                      height: 'fit-content',
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      props.onClick(
                        submenu.defaultFilter || submenu.key,
                        submenu
                      )
                    }
                  >
                    {!submenu.subMenu && !submenu.showIcon && (
                      <div
                        style={{
                          ...defaultStyle.selectedMenuStyle,
                          color: currentFilter === submenu.key && themeColor
                        }}
                      >
                        _
                      </div>
                    )}
                    {submenu.subMenu && (
                      <div style={defaultStyle.multiLevelStyle}>
                        <FontAwesomeIcon
                          icon={
                            currentFilter.includes(submenu.key)
                              ? faChevronDown
                              : faChevronRight
                          }
                        />
                      </div>
                    )}
                    {submenu.showIcon && (
                      <ListItemIcon>
                        <CheckCircleIcon
                          style={{
                            marginRight: '-9px',
                            height: '23px',
                            color: submenu.isJustified ? 'green' : 'orange'
                          }}
                        />
                      </ListItemIcon>
                    )}
                    {submenu.label}{' '}
                    {getSelectedIcon(currentFilter, submenu.key)}
                    {showActionButtons && (
                      <span
                        className="filtersubMenuActionBtn"
                        style={{ marginLeft: 'auto' }}
                      >
                        <ActionCell
                          isDeleteHidden={
                            !checkActionPermission(
                              submenu,
                              'delete',
                              currentUser,
                              createEditTenantDashboard
                            )
                          }
                          isEditHidden={
                            !checkActionPermission(
                              submenu,
                              'edit',
                              currentUser,
                              createEditTenantDashboard
                            )
                          }
                          showRejectIcon={checkActionPermission(
                            submenu,
                            'reject',
                            currentUser,
                            createEditTenantDashboard
                          )}
                          handleEdit={() => handleEditAction(submenu)}
                          handleDelete={() => handleDeleteAction(submenu)}
                          handleReject={() => handleRejectAction(submenu)}
                        />
                      </span>
                    )}
                  </li>
                </div>
                <Collapse
                  in={
                    submenu.key ===
                    `${keyValues[keyValues.length - 2]}_${
                      keyValues[keyValues.length - 1]
                    }`
                  }
                  timeout="auto"
                  unmountOnExit={true}
                  style={{
                    backgroundColor: '#EFEFEF',
                    width: '214px',
                    ...props.style.collapseBackgroundStyle
                  }}
                >
                  {submenu && submenu.subMenu && (
                    <ul
                      style={{
                        ...defaultStyle.subMenuStyle,
                        paddingLeft: '15px'
                      }}
                    >
                      {submenu.subMenu &&
                        submenu.subMenu.map((menuItem) => (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            key={`${menuItem.key}_${index}`}
                          >
                            <div
                              style={{
                                ...defaultStyle.selectedMenuStyle,
                                color:
                                  currentFilter === menuItem.key && themeColor,
                                paddingTop: '10px'
                              }}
                            >
                              _
                            </div>
                            <li
                              role="presentation"
                              style={{
                                ...defaultStyle.filterType,
                                ...getSubMenuStyle(currentFilter, menuItem.key),
                                height: 'fit-content',
                                cursor: 'pointer',
                                paddingTop: '8px',
                                paddingRight: '5px',
                                boxSizing: 'content-box'
                              }}
                              onClick={() =>
                                props.onClick(menuItem.key, menuItem)
                              }
                            >
                              {menuItem.label}{' '}
                              {getSelectedIcon(currentFilter, menuItem.key)}
                            </li>
                          </div>
                        ))}
                    </ul>
                  )}
                </Collapse>
              </ul>
            ))}
          {showMenuButtons && (
            <div
              role="presentation"
              onClick={() => menu.menuButton?.onClick(menu)}
            >
              {menu.menuButton?.component}
            </div>
          )}
        </Collapse>
      </div>
    ));

  return (
    <div style={props.style.filterMenuWidthStyle}>
      {props.header && (
        <div style={{ padding: 20, paddingLeft: 16 }}>
          <span style={{ fontSize: 22 }}>{props.header}</span>
        </div>
      )}
      <div id="menu">{menuItems}</div>
    </div>
  );
};

GenericFilter.propTypes = {
  filterMenu: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.instanceOf(Immutable.List)
  ]).isRequired,
  onClick: PropTypes.func,
  currentFilter: PropTypes.string,
  header: PropTypes.node,
  newLayout: PropTypes.bool,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  unSavedData: PropTypes.bool,
  style: PropTypes.shape({
    collapseWidthStyle: PropTypes.shape({}),
    collapseBackgroundStyle: PropTypes.shape({}),
    filterMenuWidthStyle: PropTypes.shape({}),
    subMenuHeaderStyle: PropTypes.shape({})
  }),
  showMenuButtons: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  handleEditAction: PropTypes.func,
  handleDeleteAction: PropTypes.func,
  handleRejectAction: PropTypes.func,
  currentUser: PropTypes.string,
  createEditTenantDashboard: PropTypes.bool
};

GenericFilter.defaultProps = {
  currentFilter: 'userOrg',
  onClick: (e) => e,
  header: null,
  theme: {},
  newLayout: false,
  unSavedData: false,
  style: {},
  showMenuButtons: false,
  showActionButtons: false,
  handleEditAction: (e) => e,
  handleDeleteAction: (e) => e,
  handleRejectAction: (e) => e,
  currentUser: '',
  createEditTenantDashboard: false
};

export default withTheme(GenericFilter);
