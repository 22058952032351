import { connect } from 'react-redux';
import RecordTemplateSelector from './recordTemplateSelector';

const mapStateToProps = (state) => ({
  items: state.privacyRecords.templates.get('items'),
  position: state.privacyRecords.templates.get('position')
});

const mapDispatchToProps = (dispatch) => ({
  initTemplates: (recordType, layoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD_TEMPLATES:LIST:REQUEST_INIT',
      recordType,
      layoutId
    });
  },
  getNextData: (recordType, layoutId, position, searchText) => {
    dispatch({
      type: 'PRIVACY_RECORD_TEMPLATES:LIST:NEXT_FETCH',
      recordType,
      layoutId,
      position,
      searchText
    });
  },
  onSearch: (recordType, layoutId, searchText) => {
    dispatch({
      type: 'PRIVACY_RECORD_TEMPLATES:LIST:REQUEST_INIT',
      recordType,
      layoutId,
      searchText
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordTemplateSelector);
