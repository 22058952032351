
import Immutable from 'immutable';
import { getFilteredOn, getSearchText, removeDuplicates } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  items: Immutable.List(),
  newItems: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'uploadedAt',
    sortOrder: 'DESC'
  }),
  recordsFilterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  recordsFilteredOn: Immutable.Map(),
  isUploading: false,
  filterColumn: {},
  searchText: Immutable.Map(),
  pageSearchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  documentData: {},
  usage: undefined,
  unused: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:DOCUMENTS:ITEM:FETCH:INIT':
      return state.set('error', defaultState.get('error'))
        .set('usage', defaultState.get('usage'))
        .set('documentData', defaultState.get('documentData'));

    case 'MASTERDATA:DOCUMENTS:LIST:REQUEST:INIT':
      return state.set('error', defaultState.get('error'))
        .set('items', defaultState.get('items'))
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('newItems', defaultState.get('newItems'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('position', 0);

    case 'MASTERDATA:DOCUMENTS:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));

    case 'MASTERDATA:DOCUMENTS:LIST:FETCH:SUCCESS': {
      let newState = state;
      let newItems = state.get('newItems');
      if (action.documentData) {
        newItems = newItems.unshift(action.documentData);
        newState = newState.set('newItems', newItems);
      }
      const items = removeDuplicates(newItems, state.get('items'), action.items);
      return newState
        .set('isFetching', false)
        .set('items', Immutable.List(items))
        .set('position', state.get('position') + action.items.length);
    }

    case 'MASTERDATA:DOCUMENTS:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }))
        .set('isFetching', false);

    case 'MASTERDATA:DOCUMENTS:LIST:FILTER': {
      if (action.source === 'records') {
        return state
          .set('items', defaultState.get('items'))
          .set('position', 0)
          .set('recordsFilterParams', Immutable.Map(action.filterParams))
          .set('recordsFilteredOn', getFilteredOn(Object.assign({}, action.filterParams,
            { filterObj: state.get('recordsFilteredOn') })))
          .set('filterParams', Immutable.Map(action.filterParams));
      }
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(),
        { [filterParams.filterKey]: filterParams.filterKey });
      return state
        .set('items', Immutable.List(action.items))
        .set('position', 0)
        .set('newItems', defaultState.get('newItems'))
        .set('filteredOn', getFilteredOn(Object.assign({}, action.filterParams,
          { filterObj: state.get('filteredOn') })))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filterParams', Immutable.Map(action.filterParams));
    }

    case 'MASTERDATA:DOCUMENTS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('newItems', defaultState.get('newItems'))
        .set('searchText', getSearchText(action));

    case 'MASTERDATA:DOCUMENTS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);

    case 'MASTERDATA:DOCUMENTS:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MASTERDATA:DOCUMENTS:ITEM:UNUSED:CHECK':
      return state.set('unused', action.checked);

    case 'MASTERDATA:DOCUMENTS:UPSERT:SUCCESS': {
      let newState = state;
      if (action.position === 0) {
        newState = newState.set('position', action.position)
          .set('items', defaultState.get('items'));
      }
      return newState
        .set('isFetching', false)
        .set('isUploading', false)
        .set('documentData', action.documentData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));
    }

    case 'MASTERDATA:DOCUMENTS:LIST:SEARCH_FILTER:RESET':
      return state
        .set('isFetching', true)
        .set('recordsFilterParams', defaultState.get('recordsFilterParams'))
        .set('recordsFilteredOn', defaultState.get('recordsFilteredOn'));

    case 'DOCUMENT:RECORD:ITEM:UPSERT:FAIL':
    case 'MASTERDATA:DOCUMENTS:UPSERT:FAIL':
      return state
        .set('error', Immutable.Map({ message: action.error, isError: true }))
        .set('isUploading', false);

    case 'MASTERDATA:DOCUMENTS:LIST:UPDATE:SUCCESS':
      return state.set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'))
        .set('newItems', action.newItems);

    case 'MASTERDATA:DOCUMENTS:USAGE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('usage', action.usage);

    case 'MASTERDATA:DOCUMENTS:USAGE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MASTERDATA:DOCUMENTS:UPSERT':
      return state
        .set('isUploading', true);

    case 'DOCUMENT:DOWNLOAD:FAIL':
    case 'MASTERDATA:DOCUMENTS:DELETE:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MASTERDATA:DOCUMENTS:ITEM:FETCH:SUCCESS':
      return state.set('documentData', action.documentData)
        .set('error', Immutable.Map({
          message: '',
          isError: false
        }));

    case 'MASTERDATA:DOCUMENTS:ITEM:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'DOCUMENT:RECORD:ITEM:UPSERT:INIT':
      return state.set('isUploading', true);

    case 'DOCUMENT:RECORD:ITEM:UPSERT:SUCCESS':
      return state.set('documentData', action.documentData)
        .set('isUploading', false);

    default:
      return state;
  }
};
