import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import Immutable from 'immutable';
import { initialize } from 'redux-form';
import { replace as replaceRouter } from 'connected-react-router';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* resetAndfetchPartners(action) {
  yield put({ type: 'PARTNERS:LIST:INIT' });
  yield fetchPartners(action);
}

export function* fetchPartners(action) {
  yield put({ type: 'PARTNERS:LIST:FETCH' });
  const { isOnlyPartner = false } = action;
  try {
    let filterParams = {};
    let filteredOn = Immutable.Map();
    if (action.isPersistedData) {
      const partnersState = yield select((state) => state.partners.list);
      filterParams = partnersState
        ? partnersState.get('filterParams').toJS()
        : {};
      filteredOn = partnersState
        ? partnersState.get('filteredOn')
        : Immutable.Map();
    }
    const { sortOn, sortOrder } = filterParams;

    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position || 0,
          null,
          action.rowCount
        ),
        null,
        {}
      );
    switch (response.status) {
      case 200:
        {
          const items = response.body;
          if (isOnlyPartner && items.length === 1) {
            const partner = items[0];
            yield put({
              type: 'PARTNER:DETAILS:FETCH:SUCCESS',
              hasCreateTenantPermission:
                partner.partnerType === 'DPO' ||
                partner.partnerType === 'SalesRep',
              partner
            });
          }
          const modifiedItems = response.body.map((item) =>
            transformPartners(item)
          );
          yield put({
            type: 'PARTNERS:LIST:FETCH:SUCCESS',
            items: modifiedItems
          });
        }
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'PARTNERS:LIST:FETCH:FAIL', error: err.message });
  }
}

export function* searchPartners(action) {
  let { searchParams } = action;
  const { searchKey } = searchParams;
  let { searchText } = searchParams;

  const partnersState = yield select((state) => state.partners.list);

  // For first time rendering of search items, searchText will not be defined
  if (searchText === undefined) {
    const searchTextObj = partnersState
      ? partnersState.get('searchText')
      : Immutable.Map();

    searchText = searchTextObj.get(searchKey) || '';
    searchParams = { ...searchParams, searchText };
  }

  yield put({ type: 'PARTNERS:LIST:SEARCH', searchKey, searchText });
  try {
    const response = yield registry
      .get('request')
      .get(getURLWithParams(null, action.position, searchParams), null, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PARTNERS:LIST:SEARCH:SUCCESS',
          searchKey,
          items: response.body,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'PARTNERS:LIST:SEARCH:FAIL', error: err.message });
  }
}

export function* initializePartnerDetail() {
  const isEdit = window.location.hash.indexOf('/partner/create') === -1;
  if (!isEdit) {
    yield put(initialize('PartnerDetail', {}));
  } else {
    const { partnerId } = getParameterValuesFromHash(
      '/partner/:partnerId/edit?:is'
    );
    yield put({ type: 'PARTNER:DETAIL:FETCH', partnerId });

    try {
      const response = yield registry
        .get('request')
        .get(`/v1/partners/${partnerId}`, null);

      switch (response.status) {
        case 200:
          {
            const responseData = response.body;
            yield put({
              type: 'PARTNER:DETAIL:FETCH:SUCCESS',
              data: responseData
            });
            yield put(initialize('PartnerDetail', responseData));
          }
          break;
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
      }
    } catch (err) {
      yield handleServiceDown(err, 'tenant');
      yield put({ type: 'PARTNER:DETAIL:FETCH:FAIL', error: err.message });
    }
  }
}

export function* filterPartners(action) {
  const filterParams = action.filterParams ? action.filterParams : {};

  yield put({ type: 'PARTNERS:LIST:FILTER', filterParams });
  yield fetchPartners(action);
}

export function* upsertPartnerDetail(action) {
  const store = registry.get('store');
  const { data } = action;
  const partnerDetailsState = yield select((state) => state.partners.detail);
  const isEdit = partnerDetailsState
    ? partnerDetailsState.get('isEdit')
    : undefined;
  const partnerId = partnerDetailsState
    ? partnerDetailsState.get('partnerId')
    : '';
  const request = registry.get('request');
  const requestData = { ...data, maxTenants: parseInt(data.maxTenants, 10) };
  const modifiedRequestData = {
    ...requestData,
    tenants: requestData.tenants
      ? requestData.tenants.map((tenant) => tenant.value || tenant)
      : []
  };
  if (modifiedRequestData.endDate === '') delete modifiedRequestData.endDate;

  let response;
  try {
    if (isEdit) {
      response = yield request.put(
        `/v1/partners/${partnerId}`,
        modifiedRequestData
      );
    } else {
      response = yield request.post(`/v1/partners`, modifiedRequestData);
    }

    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
        {
          const message = partnerMessages[isEdit];
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: message,
              type: 'success'
            }
          });
          store.dispatch(replaceRouter('/admin'));
        }
        break;
      case 409:
        yield put({
          type: 'PARTNER:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'PARTNER:DETAIL:UPSERT:FAIL', error: err.message });
  }
}

export function* deletePartners(action) {
  const { partnerId } = action;
  const request = registry.get('request');

  try {
    const response = yield request.delete(`/v1/partners/${partnerId}`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.partnerDeleted,
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        const partnersState = yield select((state) => state.partners.list);
        let items = partnersState
          ? partnersState.get('items')
          : Immutable.List();
        const position = partnersState.get('position') - 1;
        const index = items.findIndex((partner) => partner.id === partnerId);
        items = items.remove(index);
        yield put({ type: 'PARTNERS:LIST:UPDATE:SUCCESS', items, position });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* exportPartners() {
  const request = registry.get('request');

  try {
    const response = yield request.exportCSVbyGet(
      '/v1/entities/partners/export',
      null
    );

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    registry.get('logger').error(err);
  }
}

const getURLWithParams = (
  filterParams,
  position,
  searchParams,
  rowCount = 15
) => {
  let url = `/v1/partners?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          // FIXME: Record types should be made lower case in BE
          const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchText, sortOn, sortOrder, searchKey } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

const partnerMessages = {
  true: notificationtranslations.partnerDetailUpdateSuccess,
  false: notificationtranslations.partnerCreated
};

const transformPartners = (item) => ({
  ...item,
  key: item.name
});
