import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { NotificationStack } from 'react-notification';

function getColors(level) {
  switch (level) {
    case 'success':
      return {
        backgroundColor: '#dff0d8',
        color: '#3c763d',
        actionColor: '#3c763d'
      };
    case 'warning':
      return {
        backgroundColor: '#fcf8e3',
        color: '#8a6d3b',
        actionColor: '#8a6d3b'
      };
    case 'error':
      return {
        backgroundColor: '#f2dede',
        color: '#a94442',
        actionColor: '#a94442'
      };
    default:
      return {
        backgroundColor: '#d9edf7',
        color: '#31708f',
        actionColor: '#31708f'
      };
  }
}

const Notifier = (props) => {
  const removeNotification = (id) => {
    props.onDismiss(id);
  };

  const handleOnDismiss = (notify) => {
    props.onDismiss(notify.key);
  };

  const barStyle = {
    zIndex: 10000,
    backgroundColor: 'red'
  };

  return (
    <NotificationStack
      notifications={props.notifications.toArray().map(
        (item) => {
          const colors = getColors(item.level);
          return {
            ...item,
            message: item.message ? (item.message.msg || item.message) : 'Unidentified Error',
            onClick: () => removeNotification(item.key),
            barStyle: Object.assign({}, barStyle, {
              backgroundColor: colors.backgroundColor,
              color: colors.color
            }),
            actionStyle: Object.assign({}, {
              color: colors.actionColor
            })
          };
        }, this
      )}
      onDismiss={handleOnDismiss}
    />
  );
};


Notifier.propTypes = {
  onDismiss: PropTypes.func,
  notifications: PropTypes.instanceOf(Immutable.List)
};

Notifier.defaultProps = {
  onDismiss: e => e,
  notifications: Immutable.List()
};

Notifier.displayName = 'Notifier';

export default Notifier;
