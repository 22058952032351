import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  ssoSettings: undefined,
  error: Immutable.Map({
    message: '',
    isError: false
  })
});


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SSOSETTINGS:FETCH':
      return state.set('error', defaultState.get('error'))
        .set('ssoSettings', defaultState.get('ssoSettings'));

    case 'SSOSETTINGS:FETCH:SUCCESS':
    case 'SSOSETTINGS:UPDATE:SUCCESS': {
      return state.set('ssoSettings', Immutable.Map(action.data));
    }

    case 'SSOSETTINGS:FETCH:FAIL':
    case 'SSOSETTINGS:UPDATE:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    default:
      return state;
  }
};
