import Immutable from 'immutable';
import { getSearchText } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  searchText: Immutable.Map(),
  searchResults: Immutable.Map(),
  error: Immutable.Map({
    message: '',
    isError: false
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LEGAL_ENTITY:PROCESSORS:LIST:SEARCH':
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action));
    case 'LEGAL_ENTITY:PROCESSORS:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText(Object.assign({}, action,
          { searchObj: state.get('searchText').toJS() })))
        .set('searchResults', Immutable.List(action.searchResults));
    case 'LEGAL_ENTITY:PROCESSORS:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'LEGAL_ENTITY:PROCESSORS:LIST:SEARCH:RESET':
      return state
        .set('isFetching', false)
        .set('searchText', defaultState.get('searchText'))
        .set('searchResults', defaultState.get('searchResults'));
    default:
      return state;
  }
};
