import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  pluralTerms as uppercasePlural,
  singularTerms
} from '@packages/utils/uppercaseTranslations';

import dashboardTranslations from '../dashboard/dashboardTranslations';

export default {
  access: (
    <FormattedMessage id="GlobalSolutions.Access" defaultMessage="Access" />
  ),
  structure: (
    <FormattedMessage
      id="GlobalSolutions.Structure"
      defaultMessage="Structure"
    />
  ),
  dataManagement: (
    <FormattedMessage
      id="GlobalSolutions.dataManagement"
      defaultMessage="Data management"
    />
  ),
  environmentData: (
    <FormattedMessage
      id="GlobalSolutions.environmentData"
      defaultMessage="Environment data"
    />
  ),
  organisations: uppercasePlural('organisations'),
  masterData: singularTerms('masterData'),
  templates: uppercasePlural('template'),
  administration: (
    <FormattedMessage
      id="GlobalSolutions.administration"
      defaultMessage="Administration"
    />
  ),
  dataUpdates: (
    <FormattedMessage
      id="GlobalSolutions.dataUpdates"
      defaultMessage="Data updates"
    />
  ),
  Users: uppercasePlural('users'),
  emailHintText: (
    <FormattedMessage
      id="OrganisationUserDetailForm.emailHint"
      defaultMessage="Enter user email..."
    />
  ),
  user_invite: (
    <FormattedMessage id="EventCode.user_invite" defaultMessage="Invite user" />
  ),
  setRoles: (
    <FormattedMessage
      id="GlobalSolutions.setRoles"
      defaultMessage="Set roles"
    />
  ),
  setAsAdmin: (
    <FormattedMessage
      id="GlobalSolutions.setAsAdmin"
      defaultMessage="Set as super admin"
    />
  ),
  setAdminHintText: (
    <FormattedMessage
      id="GlobalSolutions.setAdminHintText"
      defaultMessage="Give user access to all features"
    />
  ),
  setAsCoordinator: (
    <FormattedMessage
      id="GlobalSolutions.setAsCoordinator"
      defaultMessage="Set as coordinator"
    />
  ),
  setCoordinatorHintText: (
    <FormattedMessage
      id="GlobalSolutions.setCoordinatorHintText"
      defaultMessage="Place user as administrator to all organisations under a specified tag or tenant"
    />
  ),
  selectTagOrTenant: (
    <FormattedMessage
      id="GlobalSolutions.selectTagOrTenant"
      defaultMessage="Select a tag or tenant"
    />
  ),
  lastActivity: (
    <FormattedMessage
      id="GlobalSolutions.lastActivity"
      defaultMessage="Last activity"
    />
  ),
  dataUpdatesHint: (
    <FormattedMessage
      id="GlobalSolutions.dataUpdatesHint"
      defaultMessage="From this page you can adjust which tenants will import the Global
    master data items into their tenant."
    />
  ),
  enableData: (
    <FormattedMessage
      id="GlobalSolutions.enableData"
      defaultMessage="Enable global data library"
    />
  ),
  statusOfUpdate: (
    <FormattedMessage
      id="GlobalSolutions.statusOfUpdate"
      defaultMessage="Status of last update"
    />
  ),
  updateDate: (
    <FormattedMessage
      id="GlobalSolutions.updateDate"
      defaultMessage="Last successful update"
    />
  ),
  downloadLog: (
    <FormattedMessage
      id="GlobalSolutions.downloadLog"
      defaultMessage="Download latest log"
    />
  ),
  Records: (
    <FormattedMessage id="GlobalSolutions.records" defaultMessage="Records" />
  ),
  addFirstTenant: (
    <FormattedMessage
      id="GlobalSolutions.addFirstTenant"
      defaultMessage="Add your first tenant or tag"
    />
  ),
  createNew: (
    <FormattedMessage id="List.createNew" defaultMessage="Create new" />
  ),
  createNewTenant: (
    <FormattedMessage
      id="GlobalSolutions.createNewTenant"
      defaultMessage="Create new tenant"
    />
  ),
  createNewTag: (
    <FormattedMessage
      id="GlobalSolutions.createNewTag"
      defaultMessage="Create new tag"
    />
  ),
  whatsTag: (
    <FormattedMessage
      id="GlobalSolutions.whatsTag"
      defaultMessage="What is a tag?"
    />
  ),
  whatsTenant: (
    <FormattedMessage
      id="GlobalSolutions.whatsTenant"
      defaultMessage="What is a tenant?"
    />
  ),
  tagDescription: (
    <FormattedMessage
      id="GlobalSolutions.tagDescription"
      defaultMessage="A description on what is a tag"
    />
  ),
  tenantDescription: (
    <FormattedMessage
      id="GlobalSolutions.tenantDescription"
      defaultMessage="A description on what is a tenant"
    />
  ),
  setUpNewTag: (
    <FormattedMessage
      id="GlobalSolutions.setUpNewTag"
      defaultMessage="Set up new tag"
    />
  ),
  tagName: (
    <FormattedMessage id="GlobalSolutions.tagName" defaultMessage="Tag name" />
  ),
  tagNameHint: (
    <FormattedMessage
      id="GlobalSolutions.tagNameHint"
      defaultMessage="Enter tag name"
    />
  ),
  assignParent: (
    <FormattedMessage
      id="GlobalSolutions.assignParent"
      defaultMessage="Assign parent"
    />
  ),
  assignParentHint: (
    <FormattedMessage
      id="GlobalSolutions.assignParentHint"
      defaultMessage="Choose parent tenant or tag"
    />
  ),
  these: <FormattedMessage id="GlobalSolutions.these" defaultMessage="These" />,
  this: <FormattedMessage id="GlobalSolutions.this" defaultMessage="This" />,
  newTenant: (
    <FormattedMessage
      id="GlobalSolutions.newTenant"
      defaultMessage="Set up new tenant"
    />
  ),
  parentChange: (
    <FormattedMessage
      id="GlobalSolutions.parentChange"
      defaultMessage="Changing the parent may impact the access of users, are you sure you want to continue?"
    />
  ),
  parent: (
    <FormattedMessage id="GlobalSolutions.parent" defaultMessage="Parent" />
  ),
  addNewOrganisation: (
    <FormattedMessage
      id="GlobalSolutions.addNewOrganisation"
      defaultMessage="Add new organisation"
    />
  ),
  loginUrl: (
    <FormattedMessage
      id="GlobalSolutions.loginUrl"
      defaultMessage="Login URL"
    />
  ),
  users: uppercasePlural('users'),
  processings: uppercasePlural('processings'),
  assessments: uppercasePlural('dpias'),
  breaches: uppercasePlural('breaches'),
  tias: uppercasePlural('tias'),
  unreadNotifications: dashboardTranslations.unreadNotification,
  unreadMessages: dashboardTranslations.unreadMessages,
  openTasks: (
    <FormattedMessage
      id="GlobalSolutions.openTasks"
      defaultMessage="Open tasks"
    />
  ),
  noAccess: (
    <FormattedMessage
      id="GlobalSolutions.noAccess"
      defaultMessage="You currently have no access to any tenant"
    />
  ),
  tenantSetup: (
    <FormattedMessage
      id="GlobalSolutions.tenantSetup"
      defaultMessage="Set up your tenant now"
    />
  ),
  coordinatorError: (
    <FormattedMessage
      id="GlobalSolutions.coordinatorError"
      defaultMessage="Coordinators should be associated with tenants or tags."
    />
  ),
  Coordinator: (
    <FormattedMessage
      id="GlobalSolutions.Coordinator"
      defaultMessage="Co-ordinator"
    />
  ),
  HoldingAdministrator: (
    <FormattedMessage
      id="GlobalSolutions.HoldingAdministrator"
      defaultMessage="Holding administrator"
    />
  ),
  chooseParent: (
    <FormattedMessage
      id="GlobalSolutions.chooseParent"
      defaultMessage="Choose parent"
    />
  ),
  syncMasterData: (
    <FormattedMessage
      id="GlobalSolutions.syncMasterData"
      defaultMessage="Sync master data"
    />
  ),
  syncMasterDataHint: (
    <FormattedMessage
      id="GlobalSolutions.syncMasterDataHint"
      defaultMessage="Allow master data sync"
    />
  ),
  dataUpdateSuccess: (
    <FormattedMessage
      id="GlobalSolutions.dataUpdateSuccess"
      defaultMessage="Master data sync details updated successfully"
    />
  ),
  viewDetails: (
    <FormattedMessage
      id="GlobalSolutions.viewDetails"
      defaultMessage="View details"
    />
  ),
  hideDetails: (
    <FormattedMessage
      id="GlobalSolutions.hideDetails"
      defaultMessage="Hide details"
    />
  ),
  Proceed: (
    <FormattedMessage id="GlobalSolutions.Proceed" defaultMessage="Proceed" />
  ),
  globalSourceError: (
    <FormattedMessage
      id="GlobalSolutions.globalSourceError"
      defaultMessage="The source cannot be a global entity"
    />
  ),
  viewSubTenantJob: (
    <FormattedMessage
      id="GlobalSolutions.viewSubTenantJob"
      defaultMessage="View sub tenants job list"
    />
  ),
  checkingTenants: (
    <FormattedMessage
      id="GlobalSolutions.checkingTenants"
      defaultMessage="Checking tenants"
    />
  ),
  insights: (
    <FormattedMessage id="GlobalSolutions.insights" defaultMessage="Insights" />
  ),
  advancedReporting: (
    <FormattedMessage
      id="GlobalSolutions.advancedReporting"
      defaultMessage="Advanced reporting"
    />
  ),
  quickReports: (
    <FormattedMessage
      id="GlobalSolutions.quickReports"
      defaultMessage="Quick reports"
    />
  ),
  processorsByTenant: (
    <FormattedMessage
      id="GlobalSolutions.processorsByTenant"
      defaultMessage="Processors by Tenant"
    />
  ),
  tenantsByProcessor: (
    <FormattedMessage
      id="GlobalSolutions.tenantsByProcessor"
      defaultMessage="Tenants by Processor"
    />
  ),
  risksPerTenant: (
    <FormattedMessage
      id="GlobalSolutions.risksPerTenant"
      defaultMessage="Risks per Tenant"
    />
  ),
  riskByProcessor: (
    <FormattedMessage
      id="GlobalSolutions.riskByProcessor"
      defaultMessage="Risks by Processor"
    />
  ),
  processorsByController: (
    <FormattedMessage
      id="GlobalSolutions.processorsByController"
      defaultMessage="Processors by Controller"
    />
  ),
  controllersByProcessor: (
    <FormattedMessage
      id="GlobalSolutions.controllersByProcessor"
      defaultMessage="Controllers by Processor"
    />
  ),
  riskByController: (
    <FormattedMessage
      id="GlobalSolutions.riskByController"
      defaultMessage="Risks by Controller"
    />
  ),
  internationalTransfersGDPR: (
    <FormattedMessage
      id="GlobalSolutions.internationalTransfersGDPR"
      defaultMessage="International transfers (GDPR)"
    />
  ),
  ProcessorsByTenantHeader: (
    <FormattedMessage
      id="GlobalSolutions.processorsByTenantHeader"
      defaultMessage="processors per tenant"
    />
  ),
  TenantsByProcessorHeader: (
    <FormattedMessage
      id="GlobalSolutions.tenantsByProcessorHeader"
      defaultMessage="tenants by processor"
    />
  ),
  entityNameUsed: (name) => (
    <FormattedMessage
      id="GlobalSolutions.dateNextReviewError"
      defaultMessage="{entityName} is used in"
      values={{ entityName: name }}
    />
  ),
  editEntities: (type) => (
    <FormattedMessage
      id="GlobalSolutions.editEntities"
      defaultMessage="Edit {entityType}"
      values={{ entityType: type }}
    />
  ),
  deleteEntities: (type) => (
    <FormattedMessage
      id="GlobalSolutions.deleteEntities"
      defaultMessage="Delete {entityType}"
      values={{ entityType: type }}
    />
  ),
  mergeEntities: (type) => (
    <FormattedMessage
      id="GlobalSolutions.mergeEntities"
      defaultMessage="Merge {entityType}"
      values={{ entityType: type }}
    />
  ),
  actionEntityUsage: (entityType, entityName, label, message) => (
    <FormattedMessage
      id="Entity.actionConfirm"
      defaultMessage="{message} {entityType} {entityName} is being used by other sub tenants."
      values={{
        entityName: <b>{entityName}</b>,
        entityType: entityType === 'organisation' ? entityType : label,
        message
      }}
    />
  ),
  RisksPerTenantHeader: (
    <FormattedMessage
      id="GlobalSolutions.risksPerTenantHeader"
      defaultMessage="risks per tenant"
    />
  ),
  RisksByProcessorHeader: (
    <FormattedMessage
      id="GlobalSolutions.risksByProcessorHeader"
      defaultMessage="risks by processor"
    />
  ),
  ProcessorsByControllerHeader: (
    <FormattedMessage
      id="GlobalSolutions.processorsByControllerHeader"
      defaultMessage="processors by controller"
    />
  ),
  ControllersByProcessorHeader: (
    <FormattedMessage
      id="GlobalSolutions.controllersByProcessorHeader"
      defaultMessage="controllers by processor"
    />
  ),
  RisksByControllerHeader: (
    <FormattedMessage
      id="GlobalSolutions.risksByControllerHeader"
      defaultMessage="risks by controller"
    />
  ),
  InternationalTransferRecordsPerTenantHeader: (
    <FormattedMessage
      id="GlobalSolutions.internationalTransfersHeader"
      defaultMessage="international transfers records per tenant"
    />
  ),
  or: <FormattedMessage id="GlobalSolutions.or" defaultMessage="OR" />,
  runSearch: (
    <FormattedMessage
      id="GlobalSolutions.runSearch"
      defaultMessage="Run search"
    />
  ),
  internationalTransferRecords: (
    <FormattedMessage
      id="GlobalSolutions.internationalTransferRecords"
      defaultMessage="International Transfer Records"
    />
  ),
  allTenants: (
    <FormattedMessage
      id="GlobalSolutions.allTenants"
      defaultMessage="All tenants"
    />
  )
};
