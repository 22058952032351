import registry from 'app-registry';
import {
  commonTranslations,
  recordTranslations,
  eventTypeTranslations
} from '@packages/utils/commontranslations';
import { sectionHeaderTranslations } from '@packages/features/environment/masterDataTranslations';
import threatTranslations from '@packages/utils/threatTranslations';
import countryTranslations from '@packages/utils/countryTranslations';
import { sortItems } from '@packages/utils/common-utils';
import { dpiaTranslations } from './dpiaLightTranslations';
import {
  UKProcessingCharacteristicsTypes,
  NetherlandsProcessingCharacteristicsTypes,
  zeroRiskCharacteristics,
  GermanProcessingCharacteristicsTypes,
  FrenchProcessingCharacteristicsBlacklist,
  FrenchProcessingCharacteristicsWhitelist,
  SpanishProcessingCharacteristicsBlacklist,
  SpanishProcessingCharacteristicsWhitelist,
  BelgianProcessingCharacteristicsBlacklist,
  BelgianProcessingCharacteristicsWhitelist
} from './defaults';
import {
  numOfDataItemsMapping,
  getDefaultRenderData,
  getDiffContent,
  addKeyToRetentionTerm
} from '../../common-utils';
import {
  getDefaultRequestParams,
  transformDefaultRequestData,
  transformDefaultResponseData
} from '../../saga-utils';

export const getUpdatedProperty = (action) => {
  const { property } = action;

  const renderData = getDefaultRenderData(action);
  switch (property) {
    case 'numOfDataSubjects':
    case 'processingCharacteristics': {
      return {
        renderData
      };
    }
    default:
      return getDefaultRequestParams(action, renderData);
  }
};

export const getNoRiskcharacteristics = (list) => {
  const result = list.filter(
    (item) => zeroRiskCharacteristics.findIndex((val) => val === item) === -1
  );
  return result;
};

export const getProcessingCharacteristicsRisks = (
  processingCharacteristics
) => {
  let modifiedRisk = {};
  // eslint-disable-next-line no-unused-expressions
  Object.keys(processingCharacteristics).forEach((item) => {
    switch (item) {
      case 'EDPB':
        modifiedRisk.EDPB =
          processingCharacteristics[item].length > 2
            ? 2
            : processingCharacteristics[item].length;
        break;
      case 'FR':
      case 'BE':
        if (
          getNonEDPBCharacteristics(item, false).some((data) =>
            processingCharacteristics[item].includes(data.value)
          )
        ) {
          modifiedRisk = { ...modifiedRisk, [item]: 2 };
        }
        break;
      case 'GB':
      case 'NL':
      case 'PIPL':
        if (processingCharacteristics[item].length > 0) {
          modifiedRisk = { ...modifiedRisk, [item]: 2 };
        }
        break;
      case 'DE': {
        const biometricRiskExclude = [
          'processing_characteristics_largescale_processing',
          'processing_characteristics_sensitive_personal_data_with_special_categories'
        ];
        const germanSpecialRiskType = [
          'processing_characteristics_biometric_data_subject_identify',
          'processing_characteristics_genetic_data'
        ];

        processingCharacteristics[item].forEach((data) => {
          const selectedItem = getNonEDPBCharacteristics(item).find(
            (obj) => obj.value === data
          );

          if (selectedItem) {
            modifiedRisk =
              selectedItem.riskValue === 2 ||
              (processingCharacteristics.EDPB?.some(
                (value) => !biometricRiskExclude.includes(value)
              ) &&
                germanSpecialRiskType.includes(selectedItem.value)) ||
              (!germanSpecialRiskType.includes(selectedItem.value) &&
                processingCharacteristics.EDPB?.includes(
                  'processing_characteristics_largescale_processing'
                ))
                ? { ...modifiedRisk, [item]: 2 }
                : {
                  ...modifiedRisk,
                  [item]: Math.max(
                    selectedItem.riskValue,
                    modifiedRisk[item] || 0
                  )
                };
          }
        });
        break;
      }
      default:
        modifiedRisk.ES = 0;
    }
  });
  const riskValues = Object.values(modifiedRisk);
  const maxRisk = Math.max(...riskValues);
  modifiedRisk.overallRisk = maxRisk;
  return modifiedRisk;
};

const additionalJurisdictionCountries = ['BE', 'FR', 'ES', 'GB', 'NL', 'DE'];
export const getRequiredProcessingCharacteristics = (data) => {
  const modifiedData = {};
  const { processingCharacteristics, jurisdictions, jurisdictionCountries } =
    data;
  if (
    jurisdictions?.find((item) => item !== 'PIPL') ||
    jurisdictionCountries?.find(
      (item) =>
        (item.countryCode || item.value.countryCode || item.value.id) ===
        'GLOBAL'
    )
  )
    modifiedData.EDPB = processingCharacteristics.EDPB || [];
  if (jurisdictions?.includes('PIPL'))
    modifiedData.PIPL = processingCharacteristics.PIPL || [];
  if (jurisdictions?.includes('UKGDPR'))
    modifiedData.GB = processingCharacteristics.GB || [];
  // country specific processing characteristics
  if (jurisdictions?.includes('GDPR') || jurisdictions?.includes('IDPR')) {
    // eslint-disable-next-line no-unused-expressions
    jurisdictionCountries?.forEach((item) => {
      const country =
        item.countryCode || item.value.countryCode || item.value.id;
      if (additionalJurisdictionCountries.includes(country)) {
        modifiedData[country] = processingCharacteristics[country] || [];
      }
    });
  }

  return modifiedData;
};

export const getNonEDPBCharacteristics = (type, whiteList) => {
  if (whiteList) {
    switch (type) {
      case 'BE':
        return BelgianProcessingCharacteristicsWhitelist;
      case 'FR':
        return FrenchProcessingCharacteristicsWhitelist;
      case 'ES':
        return SpanishProcessingCharacteristicsWhitelist;
      default:
        return [];
    }
  }
  switch (type) {
    case 'BE':
      return BelgianProcessingCharacteristicsBlacklist;
    case 'FR':
      return FrenchProcessingCharacteristicsBlacklist;
    case 'ES':
      return SpanishProcessingCharacteristicsBlacklist;
    case 'GB':
      return UKProcessingCharacteristicsTypes;
    case 'NL':
      return NetherlandsProcessingCharacteristicsTypes;
    case 'DE':
      return GermanProcessingCharacteristicsTypes;
    default:
      return [];
  }
};

export const getNonEDPBHeader = (value, isDpiaLight) => {
  switch (value) {
    case 'GB':
      return isDpiaLight
        ? dpiaTranslations.ICOCharacteristics
        : recordTranslations.ICOCharacteristics;
    case 'NL':
      return isDpiaLight
        ? dpiaTranslations.APCharacteristics
        : recordTranslations.APCharacteristics;
    case 'BE':
      return isDpiaLight
        ? dpiaTranslations.GBACharacteristics
        : recordTranslations.GBACharacteristics;
    case 'FR':
      return recordTranslations.CNILCharacteristics;
    case 'ES':
      return recordTranslations.AEPDCharacteristics;
    case 'DE':
      return recordTranslations.DSKCharacteristics;
    case 'PIPL':
      return recordTranslations.PIPLCharacteristics;
    default:
      return '';
  }
};
export const getNonEDPBSubHeader = (value, isDpiaLight) => {
  if (isDpiaLight) return dpiaTranslations.EDPBCriteriaSubheader;
  switch (value) {
    case 'GB':
      return recordTranslations.ICOCharacteristicsSubHeader;
    case 'NL':
      return recordTranslations.APCharacteristicsSubHeader;
    case 'BE':
      return recordTranslations.GBACharacteristicsSubHeader;
    case 'FR':
      return recordTranslations.CNILCharacteristicsSubHeader;
    case 'ES':
      return recordTranslations.AEPDCharacteristicsSubHeader;
    case 'DE':
      return recordTranslations.DSKCharacteristicsSubHeader;
    case 'PIPL':
      return recordTranslations.PIPLCharacteristicsSubHeader;
    default:
      return '';
  }
};
export const getCharacteristicsSpecificRiskLabel = (value) => {
  switch (value) {
    case 'GB':
      return recordTranslations.ICORisk;
    case 'NL':
      return recordTranslations.APRisk;
    case 'BE':
      return recordTranslations.GBARisk;
    case 'FR':
      return recordTranslations.CNILRisk;
    case 'ES':
      return recordTranslations.AEPDRisk;
    case 'DE':
      return recordTranslations.DSKRisk;
    case 'EDPB':
      return recordTranslations.EDPBRisk;
    case 'PIPL':
      return recordTranslations.PIPLRisk;
    default:
      return recordTranslations.overallCharacteristicsRisk;
  }
};
const transformProcessor = (processor, reqProcessors) => {
  const processorIndex = reqProcessors.findIndex(
    (reqProcessor) =>
      reqProcessor.value.id ===
      (processor.value ? processor.value.id : processor.id)
  );
  if (processorIndex === -1) {
    reqProcessors.forEach((item) => {
      if (item.value && item.value.subProcessors?.length > 0) {
        transformProcessor(processor, item.value.subProcessors);
      } else if (item.children) {
        transformProcessor(processor, item.children);
      }
    });
  } else {
    // eslint-disable-next-line no-param-reassign
    reqProcessors[processorIndex] = processor;
  }
};

const transformControllers = (modifiedRequestData, reqData) => {
  const modifiedControllers =
    modifiedRequestData.controllers &&
    modifiedRequestData.controllers.map((mctrlr, index) => ({
      ...reqData.controllers[index],
      value: mctrlr.value
    }));
  return modifiedControllers;
};

export const transformJurisdiction = (jurisdiction) => {
  switch (jurisdiction) {
    case 'APPI':
      return 'JP';
    case 'LGPD':
      return 'BR';
    case 'PDPA':
      return 'TW';
    case 'PIPL':
      return 'CN';
    case 'PDP':
      return 'IN';
    case 'GDPR':
    case 'IDPR':
      return 'EN';
    case 'FDPL':
      return 'MX';
    case 'SGPDPA':
      return 'SG';
    case 'UKGDPR':
      return 'GB';
    default:
      return null;
  }
};

export const transformRequestData = (requestData, isGlobal) => {
  const reqData = Object.assign({}, requestData);
  if (reqData.retentionTerms) {
    reqData.retentionTerms = reqData.retentionTerms.map((term) => ({
      period: term.period || null,
      offset: term.offset || null,
      note: term.note || null,
      mitigatingMeasures: term.mitigatingMeasures || [],
      riskDetails: term.riskDetails || [],
      isMitigated: term.isMitigated,
      severity: term.severity || null
    }));
  }

  reqData.jurisdiction = reqData.jurisdiction && {
    value: {
      id: reqData.jurisdiction.value
        ? reqData.jurisdiction.value.id
        : reqData.jurisdiction
    }
  };

  if (reqData.jurisdictionCountries?.length) {
    reqData.jurisdictionCountries = reqData.jurisdictionCountries.map(
      (country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      })
    );
  }

  if (Object.keys(reqData.processingCharacteristics).length !== 0) {
    const reqProcessingChar = getRequiredProcessingCharacteristics(reqData);
    Object.keys(reqData.processingCharacteristics).forEach(
      // eslint-disable-next-line no-return-assign
      (item) =>
        (reqData.processingCharacteristics[item] =
          reqProcessingChar[item] || [])
    );
  }

  if (reqData.links) {
    reqData.links = reqData.links.map((link) => {
      const retentionTerms = link.value.retentionTerms.map(
        (term) => term.format || term
      );
      return {
        ...link,
        value: {
          ...link.value,
          retentionTerms
        }
      };
    });
  }

  if (reqData.appiInternationalTransferCountries?.length) {
    reqData.appiInternationalTransferCountries =
      reqData.appiInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (reqData.piplInternationalTransferCountries?.length) {
    reqData.piplInternationalTransferCountries =
      reqData.piplInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (reqData.lgpdInternationalTransferCountries?.length) {
    reqData.lgpdInternationalTransferCountries =
      reqData.lgpdInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (
    requestData.specifiNumOfDataSubjects &&
    reqData.numOfDataSubjects === -2
  ) {
    reqData.numOfDataSubjects = {
      min: reqData.specifiNumOfDataSubjects,
      max: reqData.specifiNumOfDataSubjects
    };
    delete reqData.specifiNumOfDataSubjects;
  } else {
    reqData.numOfDataSubjects =
      reqData.numOfDataSubjects && reqData.numOfDataSubjects.min !== undefined
        ? reqData.numOfDataSubjects
        : numOfDataItemsMapping[reqData.numOfDataSubjects];
  }

  delete reqData.processorList;
  const modifiedRequestData = transformDefaultRequestData(reqData, isGlobal);

  if (reqData.dpo?.length && reqData.recordType === 'assessment') {
    modifiedRequestData.controllers = [
      ...reqData.dpo.filter(
        (dpoController) => dpoController.stakeholderType === 'controller'
      )
    ];

    reqData.dpo
      .filter((dpoProcessor) => dpoProcessor.stakeholderType === 'processor')
      .forEach((processor) => {
        transformProcessor(
          processor,
          modifiedRequestData.processors.children ||
            modifiedRequestData.processors
        );
      });
    delete reqData.dpo;
    delete modifiedRequestData.dpo;
  } else
    modifiedRequestData.controllers = transformControllers(
      modifiedRequestData,
      reqData
    );
  return modifiedRequestData; // Transform records request data;
};

export function* transformResponseData(
  responseData,
  currentUser,
  formatMessage
) {
  const respData = Object.assign({}, responseData);

  if (respData.retentionTerms) {
    respData.retentionTerms = respData.retentionTerms.map((term) => {
      const { item, change } = getDiffContent(term);
      return {
        ...addKeyToRetentionTerm(item, formatMessage),
        change
      };
    });
  }

  if (respData.jurisdiction && respData.jurisdiction.value) {
    respData.jurisdiction = respData.jurisdiction.value.id;
  }

  if (respData.appiInternationalTransferCountries?.length) {
    respData.appiInternationalTransferCountries =
      respData.appiInternationalTransferCountries.map((country) => ({
        value: {
          ...country.value,
          countryCode: country.value.id,
          key: formatMessage(countryTranslations[country.value.id].props)
        }
      }));
  }

  if (respData.appiThirdPartyDataReceivedFrom) {
    const sortedItems = sortItems(
      respData.appiThirdPartyDataReceivedFrom,
      'ASC',
      'name'
    );
    respData.appiThirdPartyDataReceivedFrom = sortedItems.map(
      (appiThirdPartyDataReceivedFrom) => {
        const { item, change } = getDiffContent(appiThirdPartyDataReceivedFrom);
        return {
          change,
          ...item,
          value: {
            ...item.value,
            key: `${item.value.name} (${item.value.country.id})`
          }
        };
      }
    );
  }

  if (respData.piplInternationalTransferCountries?.length) {
    respData.piplInternationalTransferCountries =
      respData.piplInternationalTransferCountries.map((country) => ({
        value: {
          ...country.value,
          countryCode: country.value.id,
          key: formatMessage(countryTranslations[country.value.id].props)
        }
      }));
  }

  if (respData.links) {
    respData.links = respData.links.map((link) => {
      const { item, change } = getDiffContent(link);
      const retentionTerms = item.value.retentionTerms.map((term) => {
        const retentionItem = getDiffContent(term).item;
        return {
          change,
          ...addKeyToRetentionTerm(retentionItem, formatMessage)
        };
      });
      return {
        change,
        ...item,
        value: {
          ...item.value,
          retentionTerms
        }
      };
    });
  }

  if (respData.jurisdictionCountries) {
    respData.jurisdictionCountries = respData.jurisdictionCountries.map(
      (term) => {
        const { item, change } = getDiffContent(term);
        return {
          ...item,
          change
        };
      }
    );
  }

  return yield transformDefaultResponseData(respData, currentUser, null); // Transform records response data
}

export const getNextChapter = (filterMenu, currentFilter) => {
  let index;
  switch (currentFilter) {
    case 'LegalQualifications': {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const legalQualifications = filterMenu[index];
      return legalQualifications.subMenu &&
        legalQualifications.subMenu.length >= 2
        ? legalQualifications.subMenu[1].data
        : filterMenu[index + 1].value;
    }
    case 'LGPD':
    case 'PDP':
    case 'APPI':
    case 'PDPA':
    case 'PIPL':
    case 'GDPR':
    case 'FDPL':
    case 'SGPDPA':
    case 'CCPA':
    case 'UKGDPR': {
      const indexOfLegalQualification = filterMenu.findIndex(
        (item) => item.value === 'LegalQualifications'
      );
      const legalQualifications = filterMenu[indexOfLegalQualification];

      const indexOfCurrentFilter = legalQualifications.subMenu.findIndex(
        (item) => item.data === currentFilter
      );
      if (legalQualifications.subMenu.length <= indexOfCurrentFilter + 1) {
        return filterMenu[indexOfLegalQualification + 1].value;
      }

      return legalQualifications.subMenu[indexOfCurrentFilter + 1].data;
    }
    default: {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const newMenuItems = filterMenu.slice(index + 1, filterMenu.size);
      const nextFilter = newMenuItems.find((item) => item.disable === false);
      return nextFilter.value;
    }
  }
};

export const getPreviousChapter = (filterMenu, currentFilter) => {
  let index;
  switch (currentFilter) {
    case 'APPI':
    case 'LGPD':
    case 'PDP':
    case 'PDPA':
    case 'CCPA':
    case 'PIPL':
    case 'FDPL':
    case 'SGPDPA':
    case 'GDPR':
    case 'UKGDPR': {
      const indexOfLegalQualification = filterMenu.findIndex(
        (item) => item.value === 'LegalQualifications'
      );
      const legalQualifications = filterMenu[indexOfLegalQualification];
      const indexOfCurrentFilter = legalQualifications.subMenu.findIndex(
        (item) => item.data === currentFilter
      );

      if (indexOfCurrentFilter === 0) {
        return filterMenu[indexOfLegalQualification - 1].value;
      }

      return filterMenu[indexOfLegalQualification].subMenu[
        indexOfCurrentFilter - 1
      ].data;
    }
    case 'NecessityAndProportionality': {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const chapterBefore = filterMenu[index - 1];
      if (
        chapterBefore.subMenu &&
        chapterBefore.subMenu.length > 0 &&
        !chapterBefore.disable
      ) {
        const subMenu = chapterBefore.subMenu[chapterBefore.subMenu.length - 1];
        return subMenu.data;
      }
      const newMenuItems = filterMenu.slice(0, index);
      const revMenuItems = newMenuItems.reverse();
      const prevFilter = revMenuItems.find((item) => !item.disable);
      return prevFilter.value;
    }
    default: {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const newMenuItems = filterMenu.slice(0, index);
      const revMenuItems = newMenuItems.reverse();
      const prevFilter = revMenuItems.find((item) => !item.disable);
      return prevFilter.value;
    }
  }
};

export const getUpdatedData = (extnType, selectedItems, item) => {
  let newItem;
  if (extnType === 'severity') {
    // whenever the severity is changed isMitigated should be reset to false
    newItem = {
      ...item,
      [extnType]: selectedItems,
      isMitigated: selectedItems === 'risk_estimate_type_none' ? null : false
    };
  } else newItem = { ...item, [extnType]: selectedItems };
  return newItem;
};

export const getFieldLabels = (dpiaLight) => {
  if (dpiaLight) {
    return {
      name: dpiaTranslations.assessmentName,
      processingCategories: dpiaTranslations.processingCategory,
      description: dpiaTranslations.assessmentDescription,
      startDate: dpiaTranslations.startDate,
      endDate: dpiaTranslations.dueDate,
      country: dpiaTranslations.jurisdiction,
      references: recordTranslations.references,
      EDPBCharacteristics: dpiaTranslations.EDPBCriteria,
      EDPBCharacteristicsSubheader: dpiaTranslations.EDPBCriteriaSubheader,
      attachmentDescription: dpiaTranslations.attachmentDescription,
      permissionDescription: dpiaTranslations.permissionDescription,
      companyAccess: dpiaTranslations.companyWideAccess,
      dataQualityCommentary: dpiaTranslations.commentForDataQuality,
      storageLimitationCommentary: dpiaTranslations.commentForStorageLimitation,
      dataMinimisationCommentary: dpiaTranslations.commentForDataMinimisation,
      legalBasisCommentary: dpiaTranslations.commentForLegalBasis,
      transferGroundsCommentary: recordTranslations.commentary,
      purposeCommentary: dpiaTranslations.commentForPurpose,
      transferPurposeCommentary: recordTranslations.commentary,
      policies: dpiaTranslations.policies,
      dataProtectionMeasures: dpiaTranslations.dataProtectionMeasures,
      measures: dpiaTranslations.measures,
      dataSubjectCategories: dpiaTranslations.dataSubjectCategories,
      supervisoryAuthorities: dpiaTranslations.supervisoryAuthorities,
      organisationalSecurityMeasures:
        dpiaTranslations.organisationalSecurityMeasures,
      technicalSecurityMeasures: dpiaTranslations.technicalSecurityMeasures,
      contractualSecurityMeasures: dpiaTranslations.contractualSecurityMeasures,
      informationCommentary: dpiaTranslations.informationCommentary,
      consentCommentary: dpiaTranslations.consentCommentary,
      accessCommentary: dpiaTranslations.accessCommentary,
      objectionRightsCommentary: dpiaTranslations.objectionRightsCommentary,
      erasureCommentary: dpiaTranslations.erasureCommentary,
      dataPortabilityCommentary: dpiaTranslations.dataPortabilityCommentary,
      controllers: dpiaTranslations.controllers,
      processors: dpiaTranslations.processors,
      executingEntities: dpiaTranslations.executingEntities,
      dataRecipients: dpiaTranslations.dataRecipients,
      dataRecipientCategories: dpiaTranslations.dataRecipientCategories,
      number: dpiaTranslations.numberOfIndividuals,
      categories: dpiaTranslations.individualCategories,
      personalDataCategories: dpiaTranslations.personalDataCategories,
      personalDataItems: dpiaTranslations.personalDataItems,
      assets: dpiaTranslations.assets,
      retentionTerms: dpiaTranslations.period,
      purpose: dpiaTranslations.purpose,
      processingGrounds: dpiaTranslations.processingGrounds,
      transferLabel: dpiaTranslations.nonEEACountriesTransfer,
      transferPurpose: dpiaTranslations.nonEEACountriesTransferPurpose,
      transferGrounds: dpiaTranslations.nonEEACountriesTransferGrounds,
      transferConcern: dpiaTranslations.nonEEACountriesTransferConcern,
      confidentialityThreats: dpiaTranslations.confidentialityThreats,
      integrityThreats: dpiaTranslations.integrityThreats,
      availabilityThreats: dpiaTranslations.availabilityThreats,
      threatLikelihood: dpiaTranslations.threatLikelihood,
      confidentialityPotentialImpacts:
        dpiaTranslations.confidentialityPotentialImpacts,
      integrityPotentialImpacts: dpiaTranslations.integrityPotentialImpacts,
      availabilityPotentialImpacts:
        dpiaTranslations.availabilityPotentialImpacts,
      impactSeverity: dpiaTranslations.impactSeverity,
      totalLikelihood: dpiaTranslations.totalLikelihood,
      totalSeverity: dpiaTranslations.totalSeverity,
      confidentialityResultingRisk:
        dpiaTranslations.confidentialityResultingRisk,
      integrityResultingRisk: dpiaTranslations.integrityResultingRisk,
      availabilityResultingRisk: dpiaTranslations.availabilityResultingRisk,
      confidentialityMitigation: dpiaTranslations.confidentialityMitigation,
      integrityMitigation: dpiaTranslations.integrityMitigation,
      availabilityMitigation: dpiaTranslations.availabilityMitigation,
      confidentialityResidualRisk: dpiaTranslations.confidentialityResidualRisk,
      integrityResidualRisk: dpiaTranslations.integrityResidualRisk,
      availabilityResidualRisk: dpiaTranslations.availabilityResidualRisk,
      PreAssessment: dpiaTranslations.preassessment,
      configuration: dpiaTranslations.configuration,
      processingDescription: dpiaTranslations.processingDescription,
      NecessityAndProportionality: dpiaTranslations.necessity,
      DataSubjectRights: dpiaTranslations.dataSubjectRights,
      SecurityControls: dpiaTranslations.securityMessures,
      ImpactAssessment: dpiaTranslations.threatImpact,
      Accountability: dpiaTranslations.accountability,
      dpoConsultation: dpiaTranslations.dpoConsultation
    };
  }
  return {
    name: commonTranslations.name,
    processingCategories: recordTranslations.processingCategories,
    description: recordTranslations.description,
    startDate: recordTranslations.startDate,
    endDate: recordTranslations.endDate,
    country: commonTranslations.countries,
    references: recordTranslations.references,
    EDPBCharacteristics: recordTranslations.EDPBCharacteristics,
    EDPBCharacteristicsSubheader:
      recordTranslations.EDPBCharacteristicsSubheader,
    attachmentDescription: recordTranslations.attachmentDescription,
    permissionDescription: recordTranslations.permissionDescription,
    users: commonTranslations.users,
    permissionsOrgHeader: recordTranslations.permissionsOrgHeader,
    companyAccess: recordTranslations.companyAccess,
    status: recordTranslations.status,
    dataQualityCommentary: recordTranslations.commentary,
    storageLimitationCommentary: recordTranslations.commentary,
    dataMinimisationCommentary: recordTranslations.commentary,
    legalBasisCommentary: recordTranslations.commentary,
    transferGroundsCommentary: recordTranslations.commentary,
    purposeCommentary: recordTranslations.commentary,
    transferPurposeCommentary: recordTranslations.commentary,
    policies: sectionHeaderTranslations.policies,
    dataProtectionMeasures: sectionHeaderTranslations.measures,
    measures: sectionHeaderTranslations.measures,
    dataSubjectCategories: recordTranslations.dataSubjectCategories,
    supervisoryAuthorities: recordTranslations.supervisoryAuthorities,
    organisationalSecurityMeasures: sectionHeaderTranslations.measures,
    technicalSecurityMeasures: sectionHeaderTranslations.measures,
    contractualSecurityMeasures: sectionHeaderTranslations.measures,
    informationCommentary: recordTranslations.commentary,
    consentCommentary: recordTranslations.commentary,
    accessCommentary: recordTranslations.commentary,
    objectionRightsCommentary: recordTranslations.commentary,
    erasureCommentary: recordTranslations.commentary,
    dataPortabilityCommentary: recordTranslations.commentary,
    controllers: recordTranslations.controllers,
    processors: recordTranslations.processors,
    executingEntities: recordTranslations.executingEntities,
    dataRecipients: recordTranslations.dataRecipients,
    dataRecipientCategories: recordTranslations.dataRecipientCategories,
    number: recordTranslations.numOfDataSubjects,
    categories: recordTranslations.dataSubjectCategories,
    personalDataCategories: recordTranslations.personalDataCategories,
    personalDataItems: recordTranslations.personalDataItems,
    assets: recordTranslations.dataSources,
    retentionTerms: recordTranslations.retentionTerms,
    purpose: recordTranslations.assessmentPurpose,
    processingGrounds: recordTranslations.processingGrounds,
    transferLabel: recordTranslations.internationalTransfers,
    transferPurpose: recordTranslations.purposesOfTransfer,
    transferGrounds: recordTranslations.transferGrounds,
    transferConcern: dpiaTranslations.basicTransferConcern,
    confidentialityThreats: threatTranslations.confidentialityThreats,
    integrityThreats: threatTranslations.integrityThreats,
    availabilityThreats: threatTranslations.availabilityThreats,
    threatLikelihood: threatTranslations.threatLikelihood,
    confidentialityPotentialImpacts: threatTranslations.potentialImpacts,
    integrityPotentialImpacts: threatTranslations.potentialImpacts,
    availabilityPotentialImpacts: threatTranslations.potentialImpacts,
    impactSeverity: threatTranslations.impactSeverity,
    totalLikelihood: threatTranslations.totalLikelihood,
    totalSeverity: threatTranslations.totalSeverity,
    confidentialityResultingRisk: threatTranslations.resultingRisk,
    integrityResultingRisk: threatTranslations.resultingRisk,
    availabilityResultingRisk: threatTranslations.resultingRisk,
    confidentialityMitigation: eventTypeTranslations.MitigatingMeasure,
    integrityMitigation: eventTypeTranslations.MitigatingMeasure,
    availabilityMitigation: eventTypeTranslations.MitigatingMeasure,
    confidentialityResidualRisk: threatTranslations.residualRisk,
    integrityResidualRisk: threatTranslations.residualRisk,
    availabilityResidualRisk: threatTranslations.residualRisk,
    preassessment: '',
    configuration: '',
    processingDescription: '',
    necessity: '',
    dataSubjectRights: '',
    securityMessures: '',
    threatImpact: '',
    accountability: '',
    dpoConsultation: recordTranslations.dpoOrg
  };
};

export const storeDPIAMode = (dpiaMode) => {
  registry.get('storage').setItem('dpiaLightMode', dpiaMode);
};

export const getVisiblityOfEachItem = (visibleFields, eachItem, isOutCome) => {
  switch (eachItem) {
    case 'preAssessment':
      return isOutCome ? visibleFields.includes('PreAssessmentOutcome') : false;
    case 'necessityAndProportionality':
      return isOutCome
        ? visibleFields.includes('NecessityAndProportionalityOutcome')
        : visibleFields.includes('NecessityAndProportionalityRiskSummary');
    case 'dataSubjectRights':
      return isOutCome
        ? visibleFields.includes('DataSubjectRightsOutcome')
        : visibleFields.includes('DataSubjectRightsRiskSummary');
    case 'securityControls':
      return isOutCome
        ? visibleFields.includes('SecurityMeasuresOutcome')
        : visibleFields.includes('SecurityMeasuresRiskSummary');
    case 'impactAssessment':
      return isOutCome
        ? visibleFields.includes('ThreatAndImpactOutcome')
        : visibleFields.includes('ThreatImpactRiskSummary');
    case 'accountability':
      return isOutCome
        ? visibleFields.includes('AccountabilityOutcome')
        : visibleFields.includes('AccountabilityRiskSummary');
    default:
      return false;
  }
};

export const defaultVisibleFields = [
  'ProcessingCategories',
  'References',
  'PreAssessmentOutcome',
  'AssessmentChapters',
  'Attachments',
  'UserPermissions',
  'DPO',
  'OrganisationPermissions',
  'CompanyAccess',
  'Controllers',
  'Processors',
  'ExecutingEntities',
  'DataRecipients',
  'DataRecipientCategory',
  'NumberOfDataSubjects',
  'DataSubjectCategories',
  'PersonalDataCategories',
  'PersonalDataItems',
  'DataSources',
  'RetentionTerms',
  'Purposes',
  'ProcessingGrounds',
  'InternationalTransfers',
  'PurposesRiskAndIsMitigated',
  'PurposesRisksAndMeasures',
  'ProcessingGroundsRiskAndIsMitigated',
  'ProcessingGroundsRisksAndMeasures',
  'PersonalDataItemsRiskAndIsMitigated',
  'PersonalDataItemsRisksAndMeasures',
  'RetentionTermsRiskAndIsMitigated',
  'RetentionTermsRisksAndMeasures',
  'TransferPurposesRiskAndIsMitigated',
  'TransferPurposesRisksAndMeasures',
  'TransferGroundsRiskAndIsMitigated',
  'TransferGroundsRisksAndMeasures',
  'QualityControls',
  'NecessityAndProportionalityOutcome',
  'InformationRights',
  'ConsentRights',
  'AccessRights',
  'ObjectionRights',
  'DeletionRights',
  'DataPortabilityRights',
  'DataSubjectRightsOutcome',
  'TechnicalSecurityMeasures',
  'TechnicalSecurityMeasuresRiskAndIsMitigated',
  'TechnicalSecurityMeasuresRisksAndMeasures',
  'ContractualSecurityMeasures',
  'ContractualSecurityMeasuresComment',
  'ContractualSecurityMeasuresRiskAndIsMitigated',
  'ContractualSecurityMeasuresRisksAndMeasures',
  'OrganisationalSecurityMeasures',
  'OrganisationalSecurityMeasuresRiskAndIsMitigated',
  'OrganisationalSecurityMeasuresRisksAndMeasures',
  'SecurityMeasuresOutcome',
  'ConfidentialityThreats',
  'IntegrityThreats',
  'AvailabilityThreats',
  'ThreatAndImpactOutcome',
  'Policies',
  'DataProtectionMeasures',
  'AccountabilityMeasures',
  'DataSubjectCategoriesIsConsulted',
  'DataSubjectCategoriesFindingsAndDecisions',
  'DPOIsConsulted',
  'DPOFindingsAndDecisions',
  'SupervisoryAuthorities',
  'SupervisoryAuthoritiesIsConsulted',
  'SupervisoryAuthoritiesFindingsAndDecisions',
  'AccountabilityOutcome',
  'NecessityAndProportionalityRiskSummary',
  'DataSubjectRightsRiskSummary',
  'SecurityMeasuresRiskSummary',
  'ThreatAndImpactRiskSummary',
  'AccountabilityRiskSummary',
  'Outcome',
  'OverallRiskSummary',
  'LinksComment',
  'PurposesComment',
  'ProcessingGroundsComment',
  'PersonalDataItemsComment',
  'RetentionTermsComment',
  'PurposesOfTransferComment',
  'TransferGroundsComment',
  'QualityControlsComment',
  'InformationRightsComment',
  'ConsentRightsComment',
  'AccessRightsComment',
  'ObjectionRightsComment',
  'DeletionRightsComment',
  'DataPortabilityRightsComment',
  'TechnicalSecurityMeasuresComment',
  'OrganisationalSecurityMeasuresComment',
  'ConfidentialityThreatsComment',
  'IntegrityThreatsComment',
  'AvailabilityThreatsComment',
  'PoliciesComment',
  'DataProtectionMeasuresComment',
  'AccountabilityMeasuresComment',
  'DataSubjectCategoriesComment',
  'SupervisoryAuthoritiesComment',
  'DPOComment',
  'ConfidentialityRiskAnalysis',
  'IntegrityRiskAnalysis',
  'AvailabilityRiskAnalysis',
  'ConfidentialityMitigatingMeasures',
  'IntegrityMitigatingMeasures',
  'AvailabilityMitigatingMeasures',
  'NecessityPersonalDataItems',
  'NecessityProcessingGrounds',
  'NecessityTransferGrounds',
  'NecessityPurposes',
  'NecessityPurposesOfTransfer',
  'NecessityRetentionTerms'
];
