import { connect } from 'react-redux';

import bulkUpdateDefault from './bulkUpdateDialog';

const mapStateToProps = (state) => ({
  linkGroupItems: state.environment.linkGroup.get('items'),
  checkedFields: state.privacyRecords.bulkUpdate.get('checkedFields'),
  visibility: state.fieldVisibilitySettings.get('visibilityFields').processing,
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  init: () => {
    dispatch({ type: 'ORGANISATIONS:LIST:REQUEST_INIT' });
    dispatch({ type: 'LINK_GROUP:LIST:REQUEST_INIT' });
  },
  resetBulkItems: () => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType: 'processing',
      selectedRecords: []
    });
  },
  updateProperty: (property, data) => {
    dispatch({ type: 'BULK:UPDATE_CHECK:PROPERTY:UPDATE', property, data });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(bulkUpdateDefault);
