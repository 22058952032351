export default {
  rootStyle: {
    marginTop: '25px',
    overflow: 'visible',
    boxShadow: 'none'
  },
  collapseIcon: {
    paddingRight: '8px',
    fontSize: '18px'
  },
  cardText: {
    padding: '0px',
    overflow: 'hidden',
    fontSize: '16px'
  },
  cardHeader: {
    paddingLeft: '0px',
    paddingBottom: '5px'
  },
  subtitle: {
    fontSize: '14px',
    paddingLeft: '8px',
    fontWeight: 'normal',
    color: '#000000'
  },
  subHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    paddingLeft: '0px',
    color: '#000000',
    lineHeight: '1.17'
  }
};
