import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import {
  customTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';
import actionTranslations from '@packages/utils/actionTranslations';

import CreateRecordDialog from '../../../privacy-records/components/header/component/createRecordDialog';
import {
  assessmentRecordTypes,
  getCreateDialogHintTextLabel,
  getCreateDialogSubTitle
} from '../../common-utils';

const CreateAssessments = (props) => {
  const {
    actionError,
    createFromEdit,
    currentRecordType,
    data,
    filterMenuData,
    handleCloseCreateDialog,
    isUpdating,
    isVendor,
    layoutId,
    userPermissions,
    onMount,
    onUnMount,
    onRequestClose,
    open,
    saveAssessment,
    saveTia,
    subRecordTypes,
    toggleLoader,
    vendorDetails,
    vendorId,
    isTemplateMode,
    refetchRecords,
    isGlobal
  } = props;

  const { createTia, createPreDpia, createCustom } = userPermissions.toJS();

  const shouldShowCustomLabel =
    currentRecordType !== 'all' && filterMenuData?.label;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
    return () => {
      onUnMount();
    };
  }, []);

  const handleCreateDpia = (data, templateId, subRecordId) => {
    const modifiedData = {
      ...data,
      ...(subRecordId && { layoutId: subRecordId }),
      name: data.name.trim(),
      chapters: [
        'NecessityAndProportionality',
        'SecurityControls',
        'Accountability',
        'DataSubjectRights',
        'LegalQualifications',
        'ImpactAssessment'
      ],
      ...(isVendor && {
        processors: [{ value: { id: vendorId, subProcessors: [] } }]
      })
    };
    if (isVendor) {
      handleCloseCreateDialog();
    }
    saveAssessment(
      modifiedData,
      templateId,
      createFromEdit,
      isVendor,
      isTemplateMode,
      refetchRecords,
      isGlobal
    );
  };

  const handleCreateTia = (data, templateId, subRecordId) => {
    let modifiedData = {
      ...data,
      name: data.name.trim(),
      ...(subRecordId && { layoutId: subRecordId })
    };
    if (isVendor) {
      modifiedData = {
        ...data,
        ...(subRecordId && { layoutId: subRecordId }),
        dataImporters: [
          {
            organisation: [{ value: { id: vendorDetails.id } }],
            countries: [{ value: { countryCode: vendorDetails.country } }],
            role: 'Processor',
            showContractHolder: 'true',
            includeInCalculation: true,
            enableDetails: true
          }
        ]
      };
    } else if (data.dataImporters) {
      modifiedData = { ...modifiedData, dataImporters: data.dataImporters };
    }
    if (isVendor) {
      handleCloseCreateDialog();
    }
    saveTia(
      modifiedData,
      templateId,
      createFromEdit,
      isVendor,
      isTemplateMode,
      refetchRecords,
      isGlobal
    );
  };

  const createAssessment = (
    data,
    templateId,
    assessmentType,
    subRecordType,
    isTemplateMode
  ) => {
    if (assessmentType.split('_')[0] === 'tia')
      handleCreateTia(data, templateId, subRecordType, isTemplateMode);
    else handleCreateDpia(data, templateId, subRecordType, isTemplateMode);
  };

  const getAccessibleRecordType = (currentRecordType) => {
    if (currentRecordType === 'all') {
      if (createTia && !createPreDpia) {
        return 'tia_assessments';
      } else if (!createTia && !createCustom) {
        return 'dpia_assessments';
      }
    }
    return currentRecordType;
  };

  const getCreateDialogTitle = () =>
    actionTranslations(
      isTemplateMode ? 'createAssessmentTemplate' : 'createAssessments'
    );

  const currentFilterType = getAccessibleRecordType(currentRecordType);

  return (
    <CreateRecordDialog
      id="create-assessment"
      open={open}
      title={getCreateDialogTitle()}
      subTitle={getCreateDialogSubTitle(
        shouldShowCustomLabel,
        isTemplateMode,
        filterMenuData?.label
      )}
      hintTextLabel={getCreateDialogHintTextLabel(
        shouldShowCustomLabel,
        isTemplateMode
      )}
      label={
        shouldShowCustomLabel
          ? customTranslation('emptyRecord', filterMenuData?.label)
          : recordTranslations.emptyRecord
      }
      hintTextCustomValue={filterMenuData?.label}
      data={data}
      isUpdating={isUpdating}
      actionError={actionError}
      createRecord={createAssessment}
      onRequestClose={onRequestClose}
      toggleLoader={toggleLoader}
      isVendor={isVendor}
      assessmentTypes={currentFilterType === 'all' ? assessmentRecordTypes : ''}
      currentFilter={currentFilterType}
      recordType={
        currentFilterType === 'all' ? 'assessments' : currentFilterType
      }
      subRecordTypes={subRecordTypes}
      layoutId={layoutId}
      hasSubRecords={true}
    />
  );
};

CreateAssessments.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

CreateAssessments.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    processors: PropTypes.arrayOf(PropTypes.shape({})),
    dataImporters: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isUpdating: PropTypes.bool,
  onMount: PropTypes.func,
  createFromEdit: PropTypes.bool,
  saveAssessment: PropTypes.func,
  onRequestClose: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  toggleLoader: PropTypes.func,
  isVendor: PropTypes.bool,
  handleCloseCreateDialog: PropTypes.func,
  vendorId: PropTypes.string,
  vendorDetails: PropTypes.shape({
    id: PropTypes.string,
    country: PropTypes.string
  }),
  currentRecordType: PropTypes.string,
  saveTia: PropTypes.func,
  subRecordTypes: PropTypes.arrayOf(PropTypes.string),
  layoutId: PropTypes.string,
  onUnMount: PropTypes.func,
  filterMenuData: PropTypes.shape({
    label: PropTypes.string
  }),
  label: PropTypes.node,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  isTemplateMode: PropTypes.bool,
  refetchRecords: PropTypes.func,
  isGlobal: PropTypes.bool
};

CreateAssessments.defaultProps = {
  open: false,
  data: {},
  createFromEdit: false,
  isUpdating: false,
  onMount: (e) => e,
  saveAssessment: (e) => e,
  onRequestClose: (e) => e,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  toggleLoader: (e) => e,
  isVendor: false,
  handleCloseCreateDialog: (e) => e,
  vendorId: '',
  vendorDetails: {},
  currentRecordType: 'dpia_assessments',
  saveTia: (e) => e,
  subRecordTypes: [],
  layoutId: '',
  onUnMount: (e) => e,
  filterMenuData: {},
  label: null,
  userPermissions: Immutable.Map(),
  isTemplateMode: false,
  refetchRecords: (e) => e,
  isGlobal: false
};

export default CreateAssessments;
