import { recordTranslations } from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import { sectionHeaderTranslations } from '../../../../../../environment/masterDataTranslations';

const visibleFieldList = [{
  label: recordTranslations.organisationsInvolved,
  key: 'stakeholders',
  subFields: [{
    key: 'Controllers',
    isCommon: true,
    label: uppercasePlural('controllers')
  },
  {
    key: 'Processors',
    isCommon: true,
    label: recordTranslations.processors
  },
  {
    key: 'ExecutingEntities',
    isCommon: true,
    label: recordTranslations.executingEntities
  },
  {
    key: 'DataRecipients',
    isCommon: true,
    label: uppercasePlural('dataRecipients')
  },
  {
    key: 'DataRecipientCategory',
    isCommon: true,
    label: uppercasePlural('dataRecipientCategories')
  }]
},
{
  label: recordTranslations.dataProcessed,
  key: 'processedData',
  subFields: [{
    key: 'DataSubjectCategories',
    isCommon: true,
    label: uppercasePlural('dataSubjectCategories')
  },
  {
    key: 'PersonalDataCategories',
    isCommon: true,
    label: uppercasePlural('personalDataCategories')
  },
  {
    key: 'PersonalDataItems',
    isCommon: true,
    label: uppercasePlural('personalDataItems')
  },
  {
    key: 'RetentionTerms',
    isCommon: true,
    label: recordTranslations.retentionTerms
  }]
},
{
  label: recordTranslations.storageAndProtection,
  key: 'securityMeasures',
  subFields: [
    {
      key: 'DataSources',
      isCommon: true,
      label: uppercasePlural('dataSources')
    },
    {
      key: 'DataSourceCategories',
      isCommon: true,
      label: uppercasePlural('dataSourceCategories')
    },
    {
      key: 'TechnicalSecurityMeasures',
      isCommon: true,
      label: sectionHeaderTranslations.technicalSecurityMeasuresHeader
    },
    {
      key: 'OrganisationalSecurityMeasures',
      isCommon: true,
      label: sectionHeaderTranslations.organisationalMeasuresHeader
    }]
},
{
  label: recordTranslations.applicableDataProctectionLaw,
  key: 'legalQualifications',
  subFields: [{
    key: 'ProcessingGrounds',
    isCommon: true,
    label: recordTranslations.processingGrounds
  },
  {
    key: 'InternationalTransfers',
    isCommon: true,
    label: recordTranslations.internationalTransfers
  }]
}
];

export const defaultAdminOpenPrivileges = [
  'create-edit-organisation',
  'create-edit-user',
  'upload-document'
];

export const BUclosedPrivileges = [
  'create-edit-user',
  'create-edit-vendor-forms'
];

export default visibleFieldList;
