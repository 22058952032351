/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import OrganisationEntity from './organisationEntity';

export { default as reducers } from './reducers';

const mapStateToProps = (state) => ({
  organisationEntities: state.organisationEntities.get('organisationEntities'),
  organisationData: state.organisationEntities.get('organisationData'),
  entityDetails: state.organisationEntities.get('entityDetails'),
  actionError: state.organisationEntities.get('actionError'),
  locale: state.intl.locale,
  isSuperAdmin: state.login.get('loggedUser')
    ? state.login
      .get('loggedUser')
      .get('roles')
      .indexOf('SuperAdministrator') !== -1
    : false,
  isAdmin: state.login.get('loggedUser')
    ? state.login.get('loggedUser').get('roles').indexOf('Administrator') !== -1
    : false,
  isHoldingAdministrator: state.login.get('loggedUser')
    ? state.login
      .get('loggedUser')
      .get('roles')
      .indexOf('HoldingAdministrator') !== -1
    : false,
  isSubTenant: state.login.get('parentTenantId'),
  isGlobal: state.home.get('isGlobal'),
  userPermissions: state.login.get('loggedUser')?.get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  init: (entityId) => {
    dispatch({ type: 'ORGANISATION_ENTITY:DETAIL:INIT', entityId });
  },
  saveData: (data, isEdit, source, updateList) => {
    dispatch({
      type: 'ORGANISATION_ENTITY:DETAIL:UPSERT',
      data,
      isEdit,
      source,
      updateList
    });
  },
  resetEntityDetails: () => {
    dispatch({ type: 'ORGANISATION_ENTITY:ITEM:INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationEntity);
