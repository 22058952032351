import { put } from 'redux-saga/effects';
import registry from 'app-registry';

import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';

export function* resetAndfetchItemListSelector(action) {
  yield put({ type: 'ITEM_LIST_SELECTOR:LIST:INIT' });
  yield fetchItemListSelector(action);
}

export function* fetchItemListSelector(action) {
  const {
    position = 0,
    rowCount = GENERAL_FETCH_LIMIT,
    fieldType,
    searchParams
  } = action;
  yield put({ type: 'ITEM_LIST_SELECTOR:LIST:FETCH' });
  try {
    let url = `/v1/custom/item-list?filter=fieldType=${fieldType}&offset=${position}&numberOfResults=${rowCount}`;
    const { searchKey, searchText } = searchParams || {};
    if (searchText) {
      url += `&search=${searchKey}=${encodeURIComponent(searchText)}`;
    }
    const response = yield registry.get('request').get(url, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'ITEM_LIST_SELECTOR:LIST:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
