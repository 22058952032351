import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IntlPolyfill from 'intl';
import 'intl/locale-data/jsonp/nn-NO';
import 'intl/locale-data/jsonp/se';
import { getEndOfDayDate, localeValue } from '@packages/utils/date-utils';
import { commonTranslations } from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';

class DateSelector extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      !(this.props.selectedItems === nextProps.selectedItems) ||
      !(this.props.multiValue === nextProps.multiValue)
    );
  }

  render() {
    const { fields, selectedItems, minDate, maxDate, multiValue, locale } =
      this.props;
    const textFieldStyle = multiValue
      ? { width: '50%', marginRight: 10 }
      : { width: '100%' };
    const divStyle = Object.assign({}, styles.textBox, textFieldStyle);
    return (
      <div style={{ display: 'flex' }}>
        <div style={divStyle}>
          <MuiPickersUtilsProvider
            locale={localeValue[locale]}
            utils={DateFnsUtils}
          >
            <DatePicker
              id="single_value_datePicker"
              autoOk={true}
              style={styles.textField}
              {...(minDate && { minDate: new Date(minDate) })}
              {...(maxDate && { maxDate: new Date(maxDate) })}
              cancelLabel={commonTranslations.Cancel}
              allowKeyboardControl={true}
              variant="inline"
              inputVariant="outlined"
              DateTimeFormat={
                Intl.DateTimeFormat.supportedLocalesOf(locale).length > 0
                  ? Intl.DateTimeFormat
                  : IntlPolyfill.DateTimeFormat
              }
              format="dd/MM/yyyy"
              value={
                selectedItems && selectedItems[0]
                  ? new Date(selectedItems[0])
                  : null
              }
              inputValue={
                selectedItems && selectedItems[0]
                  ? moment.utc(selectedItems[0]).format('DD/MM/YYYY')
                  : null
              }
              onChange={(value) => {
                fields.remove(0);
                if (value !== '') {
                  fields.unshift(value);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        {multiValue && (
          <div style={Object.assign({}, divStyle, { marginRight: 0 })}>
            <MuiPickersUtilsProvider
              locale={localeValue[locale]}
              utils={DateFnsUtils}
            >
              <DatePicker
                id="multi_value_datePicker"
                autoOk={true}
                style={styles.textField}
                {...(minDate && { minDate: new Date(minDate) })}
                {...(maxDate && { maxDate: new Date(maxDate) })}
                cancelLabel={commonTranslations.Cancel}
                allowKeyboardControl={true}
                variant="inline"
                inputVariant="outlined"
                DateTimeFormat={
                  Intl.DateTimeFormat.supportedLocalesOf(locale).length > 0
                    ? Intl.DateTimeFormat
                    : IntlPolyfill.DateTimeFormat
                }
                format="dd/MM/yyyy"
                value={
                  selectedItems && selectedItems[1]
                    ? new Date(selectedItems[1])
                    : null
                }
                inputValue={
                  selectedItems && selectedItems[1]
                    ? moment.utc(selectedItems[1]).format('DD/MM/YYYY')
                    : null
                }
                onChange={(value) => {
                  fields.remove(1);
                  if (value !== '') {
                    const endOfDayDate = getEndOfDayDate(value);
                    fields.push(endOfDayDate.toDate());
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        )}
      </div>
    );
  }
}

DateSelector.propTypes = {
  multiValue: PropTypes.bool,
  minDate: PropTypes.shape({}),
  maxDate: PropTypes.shape({}),
  fields: PropTypes.shape({
    removeAll: PropTypes.func,
    remove: PropTypes.func,
    unshift: PropTypes.func,
    push: PropTypes.func
  }),
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired
};

DateSelector.defaultProps = {
  multiValue: false,
  minDate: null,
  maxDate: null,
  fields: {
    removeAll: (e) => e,
    push: (e) => e
  }
};

export default DateSelector;
