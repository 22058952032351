import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import DataSubjectCategory from './dataSubjectCategory';

const mapStateToProps = (state) => ({
  dataSubjectCategorydata: state.environment.dataSubjectCategories.get(
    'dataSubjectCategorydata'
  ),
  actionError: state.environment.dataSubjectCategories.get('error').toJS(),
  isJobActive: state.environmentReducer.get('isJobActive'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id) => {
    dispatch({ type: 'DATA_SUBJECT_CATEGORIES:ITEM:FETCH', id });
  },
  updateDataSubjectCategories: (
    data,
    isEdit,
    source,
    isUsed,
    isEditWithJob
  ) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        actionType: 'Edit',
        entityType: 'DataSubjectCategory',
        data
      });
    } else {
      dispatch({
        type: 'DATA_SUBJECT_CATEGORIES:UPSERT',
        data,
        isEdit,
        source
      });
    }
  },
  resetDataSubjectCategories: () => {
    dispatch({ type: 'MASTERDATA:DATA_SUBJECT_CATEGORIES:ITEM:FETCH:INIT' });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSubjectCategory);
