import Immutable from 'immutable';
import { getQueryStrings } from '@packages/utils/query-parameters';

const defaultState = Immutable.fromJS({
  data: {},
  mode: 'resetPassword',
  profile: {},
  isUpdating: false,
  passwordUpdated: false,
  tokenExpired: false,
  actionError: {
    message: '',
    isError: false
  },
  isReinviteSuccessfull: undefined
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'USER:DETAIL:INIT': {
      const queryParams = getQueryStrings();
      const userType = queryParams.type === 'admin' ? 'administrator' : 'user';
      return state
        .set('data', defaultState.get('data'))
        .set('tenantName', queryParams.legalEntityName)
        .set('userType', userType)
        .set('tokenExpired', defaultState.get('tokenExpired'))
        .set('actionError', defaultState.get('actionError'));
    }
    case 'USER:DETAIL:UPDATE':
      return state.set('data', defaultState.get('data'));
    case 'USER:DETAIL:UPDATE:SUCCESS':
      return state
        .set('data', action.data)
        .set('actionError', defaultState.get('actionError'));
    case 'USER:DETAIL:UPDATE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );

    case 'USER:PROFILE:REQUEST':
      return state
        .set('actionError', defaultState.get('actionError'))
        .set('profile', defaultState.get('profile'));
    case 'USER:PROFILE:UPDATE':
      return state.set('isUpdating', true);
    case 'USER:PROFILE:REQUEST:SUCCESS':
    case 'USER:PROFILE:UPDATE:SUCCESS':
      return state
        .set('profile', Immutable.fromJS(action.data))
        .set('isUpdating', false);
    case 'USER:PROFILE:REQUEST:FAIL':
    case 'USER:PROFILE:UPDATE:FAIL':
      return state
        .set(
          'actionError',
          Immutable.Map({ message: action.error, isError: true })
        )
        .set('isUpdating', false);

    case 'USER:CHANGE_PASSWORD:INIT': {
      const queryParams = getQueryStrings();
      const mode = queryParams.token ? 'forgotPassword' : 'resetPassword';
      return state
        .set('data', defaultState.get('data'))
        .set('tokenExpired', defaultState.get('tokenExpired'))
        .set('actionError', defaultState.get('actionError'))
        .set('mode', mode);
    }

    case 'USER:CHANGE_PASSWORD':
      return state
        .set('oldPassword', action.oldPassword)
        .set('newPassword', action.newPassword);
    case 'USER:CHANGE_PASSWORD:SUCCESS':
      return state
        .set('data', action.data)
        .set('passwordUpdated', true)
        .set('actionError', Immutable.fromJS(defaultState.get('actionError')));
    case 'USER:CHANGE_PASSWORD:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );

    case 'USER:CHANGE_PASSWORD:RESET':
      return state.set('passwordUpdated', false);

    case 'USER:TOKEN:VALIDATE:SUCCESS':
      return state.set('tokenExpired', false);

    case 'USER:TOKEN:VALIDATE:FAIL':
      return state.set('tokenExpired', true);

    case 'USER:REINVITE:INIT': {
      return state
        .set('isReinviteSuccessfull', defaultState.get('isReinviteSuccessfull'))
        .set('actionError', defaultState.get('actionError'));
    }

    case 'USER:REINVITE:RESET:FAIL':
    case 'USER:REINVITE:ATTEMPT:EXCEEDED':
    case 'USER:REINVITE:FAIL':
      return state.set(
        'actionError',
        Immutable.Map({ message: action.error, isError: true })
      );

    case 'USER:REINVITE:SUCCESS':
      return state
        .set('isReinviteSuccessfull', true)
        .set('actionError', defaultState.get('actionError'));

    default:
      return state;
  }
};
