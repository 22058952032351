import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  records: Immutable.List(),
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  isFetching: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORDS:LIST:REQUEST':
      return state.set('records', defaultState.get('records'))
        .set('error', defaultState.get('error'));
    case 'RECORDS:LIST:REQUEST:SUCCESS':
      return state.set('records', Immutable.List(action.items));
    case 'RECORDS:LIST:REQUEST:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'RECORDS:RESET:DATA':
      return state.set('records', defaultState.get('records'));
    default:
      return state;
  }
};
