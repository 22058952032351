import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  dsrSettingsDetails: Immutable.Map({}),
  isUpdating: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DSR:SETTINGS:FETCH': {
      return state
        .set('dsrSettingsDetails', state.get('dsrSettingsDetails'));
    }
    case 'DSR:SETTINGS:FETCH:SUCCESS': {
      return state
        .set('dsrSettingsDetails', Immutable.Map(action.data));
    }
    case 'DSR:SETTING:UPDATE:SUCCESS': {
      return state
        .set('dsrSettingsDetails', Immutable.Map(action.data))
        .set('isUpdating', false);
    }
    case 'DSR:SETTING:UPDATE:FAIL': {
      return state
        .set('isUpdating', false);
    }
    case 'DSR:SETTINGS:UPDATE': return state.set('isUpdating', true);
    default:
      return state;
  }
};
