import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { handleServiceDown } from '@packages/utils/common-utils';

export function* fetchRecords(action) {
  const { recordType, getAllFields = false, dataType } = action;
  let url = `/v1/records?filter=recordType=${recordType}&fields=name`;
  if (getAllFields) {
    url = `/v1/records/${dataType}`;
  }
  try {
    const response = yield registry.get('request')
      .get(url, null);

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map(item => ({
          id: item.id,
          name: item.name,
          key: `${item.name}`,
          ...item
        }));
        yield put({ type: `RECORD:${recordType.toUpperCase()}:LIST:REQUEST:SUCCESS`, items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    yield put({ type: `RECORD:${recordType.toUpperCase()}:LIST:REQUEST:FAIL`, error: err.message });
  }
}

export function* searchRecords(action) {
  let { searchParams } = action;

  const { searchKey, searchText } = searchParams;

  searchParams = Object.assign({}, searchParams, { searchText });

  yield put({ type: `RECORD:${action.recordType.toUpperCase()}:LIST:SEARCH`, searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams(searchParams, action.recordType, action.getAllFields, action.dataType), null, {});
    const items = response.body;
    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => ({
          ...item,
          key: item.name
        }));
        yield put({
          type: `RECORD:${action.recordType.toUpperCase()}:LIST:SEARCH:SUCCESS`,
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: `RECORD:${action.recordType.toUpperCase()}:LIST:SEARCH:FAIL`, error: err.message });
  }
}

const getURLWithParams = (searchParams, recordType, getAllFields, dataType) => {
  let url = `/v1/records?filter=recordType=${recordType}&fields=name`;
  if (getAllFields) url = `/v1/records/${dataType}?`;
  if (searchParams) {
    const { searchKey, searchText, sortOn, sortOrder } = searchParams;
    url = searchText ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}` : url;
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};
