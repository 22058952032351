import { connect } from 'react-redux';
import TagsDialog from './tagsDialog';

const mapStateToProps = state => ({
  tagsData: state.environment.simpleEntities.tags.get('simpleEntityData'),
  actionError: state.environment.simpleEntities.tags.get('error').toJS()
});

const mapDispatchToProps = dispatch => ({
  updateTags: (data) => {
    dispatch({ type: 'SIMPLE_ENTITY:UPSERT', data, isEdit: false, source: 'master', masterDataType: 'tags' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsDialog);
