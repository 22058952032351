
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  addToAllRecords:
  <FormattedMessage
    id="Document.addToAllRecords"
    defaultMessage="Add to all records with relevant organisations"
  />,
  documentRecordNameHint:
  <FormattedMessage
    id="Document.Record.documentRecordNameHint"
    defaultMessage="Enter the name of your document record"
  />,
  documentTypeHint:
  <FormattedMessage
    id="Document.Record.documentTypeHint"
    defaultMessage="Add document type"
  />,
  specialCharacteristicsHint:
  <FormattedMessage
    id="Document.Record.specialCharacteristicsHint"
    defaultMessage="Add special characteristics"
  />,
  relevantOrgHint:
  <FormattedMessage
    id="Document.Record.relevantOrgHint"
    defaultMessage="Add relevant organisations"
  />,
  docDescriptionHint: <FormattedMessage
    id="Document.Record.descriptionHint"
    defaultMessage="Description of the document record..."
  />,
  files: <FormattedMessage
    id="Document.Record.files"
    defaultMessage="Files"
  />,
  version: <FormattedMessage
    id="Document.Record.version"
    defaultMessage="Version"
  />,
  fileName: <FormattedMessage
    id="Document.Record.fileName"
    defaultMessage="File name"
  />,
  relevantOrganisations: <FormattedMessage
    id="Document.releventOrganisations"
    defaultMessage="Relevant organisations"
  />,
  loadLinkedRecordData: <FormattedMessage
    id="Document.loadLinkedRecordData"
    defaultMessage="Load all from linked records"
  />,
  organisationName: <FormattedMessage
    id="Document.organisationName"
    defaultMessage="Organisation Name"
  />,
  involesIntlTransfer: <FormattedMessage
    id="Document.involesIntlTransfer"
    defaultMessage="The file involves details of international data transfers"
  />,
  transferMechanisms: <FormattedMessage
    id="Document.transferMechanisms"
    defaultMessage="Transfer mechanisms"
  />,
  loadLinkedRecordsWarning: <FormattedMessage
    id="Document.loadLinkedRecordsWarning"
    defaultMessage="This will import all organisations from linked records. Do you want to proceed?"
  />,
  deleteBothFileAndEntry: <FormattedMessage
    id="Document.deleteBothFileAndEntry"
    defaultMessage="Delete both file and entry"
  />,
  deleteFileOnly: <FormattedMessage
    id="Document.deleteFileOnly"
    defaultMessage="Delete file and keep the entry"
  />,
  NewDocumentRecord: <FormattedMessage
    id="Document.NewDocumentRecord"
    defaultMessage="New document record"
  />,
  NewDocumentSubHeader: <FormattedMessage
    id="Document.NewDocumentSubHeader"
    defaultMessage="Please enter a name for the document record"
  />,
  nameHint: <FormattedMessage
    id="Document.nameHint"
    defaultMessage="Name for the document record"
  />,
  current: <FormattedMessage
    id="Document.current"
    defaultMessage="Current"
  />,
  selectRelevantOrganisations: <FormattedMessage
    id="Document.selectRelevantOrganisations"
    defaultMessage="Select relevant organisations"
  />

};
