import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { recordTranslations } from '@packages/utils/commontranslations';
import ValueSelector from '../value-selector';

const DpoSelector = (props) => {
  useEffect(() => {
    if (props.initDpo) {
      props.initDpo(props.dpoType);
    }
  }, []);

  const handleNextData = () => {
    props.getNextData(props.position, props.dpoType);
  };

  const handleSearch = (searchParams) => {
    props.onSearch({ ...searchParams, searchKey: `${props.dpoType}` }, props.dpoType);
  };

  const { hintTextLabel, dpoType } = props;
  const dpo = props[dpoType];
  return (
    <div>
      <ValueSelector
        {...props}
        valueAttr="name"
        searchResults={props[`searchItem${dpoType}`]}
        dataItems={dpo}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        title={recordTranslations.dataProtectionOfficer}
      />
    </div>
  );
};

DpoSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  dpoSearch: PropTypes.instanceOf(Immutable.List),
  initDpo: PropTypes.func,
  dpo: PropTypes.instanceOf(Immutable.List),
  dpoType: PropTypes.string,
  onSearch: PropTypes.func
};

DpoSelector.defaultProps = {
  hintTextLabel: null,
  initDpo: e => e,
  getNextData: e => e,
  position: 0,
  dpoSearch: Immutable.List(),
  fields: {},
  dpo: Immutable.List(),
  dpoType: '',
  onSearch: e => e
};

export default DpoSelector;
