import { put, select, delay } from 'redux-saga/effects';
import registry from 'app-registry';
import jwtDecode from 'jwt-decode';
import { replace as replaceRouter } from 'connected-react-router';
import {
  commonTranslations,
  serviceDownError
} from '@packages/utils/commontranslations';
import { getQueryStrings } from '@packages/utils/query-parameters';

export function* checkMfaConfiguration() {
  try {
    // Check whether login from mail for reset QR code or normal login
    const config = registry.get('config');
    const hashURL = window.location.hash;
    const { token } = getQueryStrings(hashURL);
    if (!token) {
      // Get isMfaConfigured
      const userToken = registry
        .get('storage')
        .getItem(config.login.user.storage.key)
        ? JSON.parse(
          registry.get('storage').getItem(config.login.user.storage.key)
        ).token
        : '';
      const { isMFAConfigured } = getMfaConfigured(userToken);
      yield put({ type: 'OTP:OPTIONAL', isMFAConfigured });

      //  If mfa is not configured then call the function to display QR code
      if (!isMFAConfigured) {
        yield initQRcode();
      }
    } else {
      yield restoreQrCode(token);
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('tenant');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    registry.get('logger').error(err);
  }
}

const getMfaConfigured = (token) => {
  const decoded = jwtDecode(token);
  const isMFAConfigured = decoded.is_mfa_configured;
  return { isMFAConfigured };
};

export function* initQRcode() {
  const response = yield registry.get('request').get(`/v1/identity/qrcode`);
  const { qrCode } = response.body;
  yield put({
    type: 'USER:QRCODE:REQUEST:SUCCESS',
    qrCode,
    isRestoreQr: false
  });
}

export function* otpSubmission(action) {
  const config = registry.get('config');
  const { httpHeader } = config.login.token;
  const otpvalue = action.otpValue.enterOtp;
  const requestParam = {
    otp: Number(otpvalue)
  };

  // If logged in from mail for reset QR code, then for sending request for otp submission-
  // uses token provided with url
  const hashURL = window.location.hash;
  let response;
  const { token } = getQueryStrings(hashURL);
  if (token) {
    response = yield registry
      .get('request')
      .post(
        `/v1/identity/validate-otp`,
        requestParam,
        { headers: { token } },
        typeof token === 'undefined'
      );
  } else {
    response = yield registry
      .get('request')
      .post(`/v1/identity/validate-otp`, requestParam);
  }
  try {
    switch (response.status) {
      case 204:
        {
          registry
            .get('storage')
            .setItem(
              config.login.token.storage.key,
              response.headers.get(httpHeader)
            );
          let userData = JSON.parse(
            registry.get('storage').getItem(config.login.user.storage.key)
          );
          userData = {
            ...userData,
            token: response.headers.get(httpHeader)
          };
          registry
            .get('storage')
            .setItem(config.login.user.storage.key, JSON.stringify(userData));
          const userState = yield select((state) => state.user);
          const isMFAConfigured = userState
            .get('profile')
            .get('isMFAConfigured');

          // TODO Remove the delay once new architecture introduce
          if (!isMFAConfigured) {
            yield delay(3000);
          }
          const userToken = response.headers.get(httpHeader);

          const store = registry.get('store');
          const locale =
            (userToken && jwtDecode(userToken).language_code) || 'en';
          yield put({ type: 'TRANSLATION:INIT', locale });
          store.dispatch(replaceRouter('/'));
        }
        break;
      case 400:
        yield put({ type: 'OTP:VALIDATION:FAIL', error: response.body.msg });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    yield put({ type: 'OTP:VALIDATION:FAIL', error: err.message });
  }
}

export function* resetQrCode() {
  yield registry.get('request').get(`/v1/identity/reset-qrcode`);
  yield put({
    type: 'RESTORE:QRCODE:REQUEST:SUCCESS',
    success: commonTranslations.restoreOtp
  });
}

export function* restoreQrCode(token) {
  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/identity/qrcode`,
        null,
        { headers: { token } },
        typeof token === 'undefined'
      );
    switch (response.status) {
      case 200: {
        const { qrCode } = response.body;
        yield put({
          type: 'USER:QRCODE:REQUEST:SUCCESS',
          qrCode,
          isRestoreQr: true
        });
        break;
      }
      case 410: {
        yield put({
          type: 'USER:TOKEN:VALIDATION:FAIL',
          tokenExpired: true,
          isRestoreQr: true
        });
        break;
      }
      default:
        yield put({ type: 'USER:TOKEN:VALIDATION:FAIL' });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('tenant');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    yield put({ type: 'USER:TOKEN:VALIDATION:FAIL' });
  }
}
