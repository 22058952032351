/* eslint-disable no-param-reassign */
import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { replace as replaceRouter } from 'connected-react-router';
import { push } from 'react-router-redux';

import {
  getParameterValuesFromHash,
  getQueryStrings
} from '@packages/utils/query-parameters';
import {
  getErrorMessage,
  handleServiceDown
} from '@packages/utils/common-utils';
import { recordTranslations } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';

import {
  transformRequestData,
  transformResponseData
} from './utils/assessmentUtils';
import {
  addKeyToRetentionTerm,
  getModifiedRecordData,
  getUserOrg,
  transformCopyRecordData
} from '../common-utils';
import { validateLinkItems } from '../processings/utils/processingUtils';
import { isRecordEditable, getRecordData } from '../saga-utils';

function* getDefaultAssessmentData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    startDate: null,
    endDate: null,
    description: '',
    purposes: [],
    processingCharacteristics: {},
    executingEntities: [],
    controllers: [],
    aclUsers: [],
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    processors: [],
    dataRecipients: [],
    dataRecipientCategories: [],
    dataSubjectCategories: [],
    numOfDataSubjects: -1,
    personalDataItems: [],
    dataSources: [],
    necessities: [],
    processingCategories: [],
    processingGrounds: [],
    attachments: [],
    transferGrounds: [],
    isExecuteAccess: true,
    status: 'record_status_draft',
    internationalTransfer: false,
    retentionTerms: [],
    personalDataCategories: [],
    references: [],
    assessmentType: 'assessment_pre_assessment',
    outcome: '',
    purposesOfTransfer: [],
    text: {},
    htmlText: {},
    stakeholder: {},
    picklist: {},
    checkbox: {},
    scoredPicklist: {}
  };
}

const getAssessmentDetailFetchUrl = (
  isPublic,
  registryId,
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId
) => {
  let url = '/v1/records';
  if (isPublic)
    return `/v1/public/${registryId}/records/assessments/${recordId}`;
  if (isTemplateMode) url += '/templates';
  url += `/assessments/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* initializeAssessmentDetail(action) {
  const {
    locale,
    tenantLocale,
    isTemplateMode,
    isPreviewMode,
    libraryTenantId
  } = action;
  const URL = window.location;
  const isPreview = getQueryStrings(URL.hash).isPreview === 'true';
  const isPublic = URL.pathname.includes('public');
  const { recordId, registryId } = isPublic
    ? getParameterValuesFromHash('/:registryId/assessment/:recordId/view')
    : getParameterValuesFromHash('/:routeType/:recordId/:mode');

  yield put({ type: 'ASSESSMENT:DETAIL:FETCH', recordId });

  try {
    const response = yield registry
      .get('request')
      .get(
        getAssessmentDetailFetchUrl(
          isPublic,
          registryId,
          recordId,
          isTemplateMode,
          isPreviewMode,
          libraryTenantId
        ),
        null,
        {},
        !isPublic || isPreview
      );

    switch (response.status) {
      case 200:
        {
          const recordDetail = response.body;
          const userState = yield select((state) => state.user);
          const currentUser = userState ? userState.get('profile') : null;
          const isEditable = yield isRecordEditable(recordDetail, currentUser);
          const store = registry.get('store');
          const responseData = Object.assign(
            {},
            yield getDefaultAssessmentData(),
            recordDetail,
            {
              jurisdictionCountries: recordDetail.jurisdictionCountries
            }
          );
          const data = yield transformResponseData(
            responseData,
            currentUser,
            action.formatMessage
          );

          yield put({
            type: 'ASSESSMENT:DETAIL:FETCH:SUCCESS',
            isEditable,
            recordName: data.name,
            data
          });

          if (!isPublic) {
            if (recordDetail.status === 'record_status_requested') {
              store.dispatch(
                replaceRouter(`/assessment/${recordDetail.id}/view`)
              );
              yield put({
                type: 'ASSESSMENT:EDIT:RESTRICTED',
                isEditRestricted: true
              });
            }

            const { layoutId } = response.body;
            yield put({
              type: 'RECORD_LAYOUT:FETCH_INIT',
              recordType: 'ASSESSMENT',
              layoutId,
              locale,
              tenantLocale,
              libraryTenantId,
              isPreviewMode
            });
          }
        }
        break;

      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'ASSESSMENT:DETAIL:FETCH:FAIL', error });

        const currentState = yield select((state) => state.assessment);
        const store = registry.get('store');

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        if (!isPreviewMode)
          store.dispatch(replaceRouter(currentState.get('prevLocation')));
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'ASSESSMENT:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertAssessmentDetail(action) {
  const request = registry.get('request');
  const {
    copyOfRecordId,
    data,
    createFromEdit,
    isVendor = false,
    templateId,
    isTemplateMode,
    templateFromRecord,
    refetchRecords,
    isGlobal
  } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  try {
    // If assessment is a copy of an existing assessment, then get that assessment data.
    const defaultReqData =
      copyOfRecordId || templateId
        ? yield getRecordData({ copyOfRecordId, templateId }, 'assessments')
        : yield getDefaultAssessmentData();

    const modifiedData = getModifiedRecordData(defaultReqData, data);
    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const newData =
      copyOfRecordId && !isTemplateMode
        ? transformCopyRecordData(modifiedData, currentUser, aclOrgs)
        : modifiedData;
    let requestData = transformRequestData(newData, isGlobal);
    const recordId = modifiedData.id || '';
    requestData = recordId
      ? { ...requestData, assessmentType: 'assessment_full_assessment' }
      : requestData;
    const requestUrl = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/assessments`;
    const response = yield request.post(requestUrl, requestData);
    yield response;

    yield put({ type: 'LOADER:TOGGLE', toggle: false });

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:SUCCESS',
          data: response.body
        });
        if (isVendor) {
          refetchRecords();
          const location =
            window.location.origin ||
            `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ''
            }`;
          yield window.open(
            `${location}/#/assessment/${response.body.id}/edit`,
            '_blank'
          );
        } else if (templateFromRecord) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.templateCreated,
              type: 'success'
            }
          });
          yield put(replaceRouter('/privacyrecords'));
        } else if (isTemplateMode) {
          yield put(push(`assessment-template/${response.body.id}/edit`));
        } else if (createFromEdit) {
          yield put(push(`/assessment/${response.body.id}/view`));
        } else {
          yield put(push(`/assessment/${response.body.id}/edit`));
        }
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'ASSESSMENT:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        if (copyOfRecordId) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
        break;
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');

    yield put({ type: 'ASSESSMENT:DETAIL:UPSERT:FAIL', error });
  }
}

export function* upsertAssessmentRecordDetail(action) {
  const request = registry.get('request');
  const { copyOfRecordId, data, locale, tenantLocale, isTemplateMode } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  try {
    // If assessment is a copy of an existing assessment, then get that assessment data.
    const defaultReqData = copyOfRecordId
      ? yield getRecordData({ copyOfRecordId }, 'assessments')
      : yield getDefaultAssessmentData();
    let modifiedData = Object.assign({}, defaultReqData, data);

    modifiedData = {
      ...modifiedData,
      jurisdictionCountries: modifiedData.jurisdictionCountries || []
    };
    const requestData = transformRequestData(modifiedData);
    const recordId = modifiedData.id || '';
    // TODO once BE is done remove this code
    delete requestData.optionalChapter;
    delete data.dpo;
    const url = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/assessments/${recordId}`;
    const response = yield request.put(url, requestData);

    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;
        const responseData = yield transformResponseData(
          response.body,
          currentUser,
          action.formatMessage
        );
        const updatedData = {
          ...responseData,
          jurisdictionCountries: modifiedData.jurisdictionCountries || [],
          processors: modifyProcessors(response.body.processors),
          controllers: modifyControllers(response.body.controllers)
        };
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:SUCCESS',
          data: updatedData
        });

        if (data.isRefreshLayout) {
          const { layoutId } = response.body;
          yield put({
            type: 'RECORD_LAYOUT:FETCH_INIT',
            recordType: 'ASSESSMENT',
            layoutId,
            locale,
            tenantLocale
          });
        }

        if (action.promoteInitiated) {
          yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });
        }
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({ type: 'ASSESSMENT:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        if (
          response.headers.get('reason') &&
          response.headers.get('reason') === 'version-mismatch'
        ) {
          yield put({
            type: 'ASSESSMENT:DETAIL:UPSERT:LOCKED',
            data: requestData
          });
        } else {
          yield put({
            type: 'ASSESSMENT:DETAIL:UPSERT:FAIL',
            error: response.body.msg
          });
        }
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield put({ type: 'ASSESSMENT:DETAIL:UPSERT:FAIL', error });
  }
}

export function* createAssessmentLinkGroup(action) {
  const request = registry.get('request');
  try {
    const currentState = yield select((state) => state.assessment);
    const response = yield request.post(`/v1/links`, action.links);
    switch (response.status) {
      case 200: {
        yield put({ type: 'ASSESSMENT:LINK_GROUP:CREATE:SUCCESS' });
        const currentRecordData = currentState.get('data').toJS();
        const modifiedResponse = transformResponse(
          response.body,
          action.formatMessage
        );
        const currentLinks = [...currentRecordData.links];
        if (action.linkIdToRemove) {
          const indexToRemove = currentLinks.findIndex(
            (eachLink) =>
              (eachLink.value?.id || eachLink.id) === action.linkIdToRemove
          );
          if (indexToRemove !== -1) currentLinks.splice(indexToRemove, 1);
        }
        currentLinks.push({
          value: modifiedResponse,
          valueVersion: modifiedResponse.versions[0].version
        });
        let modifiedRecordData = {
          ...currentRecordData,
          links: [...currentLinks]
        };
        modifiedRecordData = getUpdatedData(
          modifiedRecordData,
          modifiedResponse
        );
        yield put(change('ProcessingDescription', 'links', currentLinks));
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:SUCCESS',
          data: modifiedRecordData
        });
        yield put({ type: 'ASSESSMENT:UNSAVED:PROPERTY:SET' });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupCreated,
            type: 'success'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'ASSESSMENT:LINK_GROUP:UPSERT:FAIL',
          error: `${response.body.msg} - ${response.body.details.duplicateEntity}`
        });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

const transformResponse = (link, formatMessage) => {
  if (link.retentionTerms) {
    const modifiedTerms = link.retentionTerms.map((term) =>
      addKeyToRetentionTerm(term, formatMessage)
    );
    return {
      ...link,
      retentionTerms: modifiedTerms
    };
  }
  return link;
};

export function* updateAssessmentLinkGroup(action) {
  const currentState = yield select((state) => state.assessment);
  const currentDataFromStatus = currentState
    ? currentState.get('data').toJS()
    : {};
  const currentRecordData = { ...currentDataFromStatus };
  let modifiedData = { ...currentRecordData };
  const request = registry.get('request');
  try {
    const response = yield request.put(
      `/v1/links/${action.linkId}`,
      action.linkData
    );
    switch (response.status) {
      case 200: {
        yield put({ type: 'ASSESSMENT:LINK_GROUP:UPDATE:SUCCESS' });
        const index = currentRecordData.links.findIndex(
          (link) => link.value.id === action.linkId
        );
        const modifiedResponse = transformResponse(
          response.body,
          action.formatMessage
        );
        const currentLinks = [...currentRecordData.links];
        if (validateLinkItems(modifiedResponse)) {
          /** Splice and add is deliberately done in separate statements to trigger re-render
           * of the responsivetablewrapper to calculate the individual row height */
          currentLinks.splice(index, 1);
          currentLinks.splice(index, 0, {
            value: modifiedResponse,
            valueVersion: modifiedResponse.versions[0].version,
            note: action.linkData.note || ''
          });
        } else {
          currentLinks.splice(index, 1);
        }

        modifiedData = { ...currentRecordData, links: currentLinks };

        yield put(change('ProcessingDescription', 'links', currentLinks));
        yield put({
          type: 'ASSESSMENT:DETAIL:UPSERT:SUCCESS',
          data: modifiedData
        });
        yield put({ type: 'ASSESSMENT:UNSAVED:PROPERTY:SET' });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: recordTranslations.linkGroupUpdated,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* fetchUsersForAssessment() {
  const { recordId } = getParameterValuesFromHash(
    '/assessment/:recordId/:mode'
  );
  yield put({ type: 'ASSESSMENT:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/assessments/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'ASSESSMENT:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveAssessmentComment(action) {
  const currentState = yield select((state) => state.assessment);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  let modifiedComments = [];
  const { comment, isEdit, id } = action;
  try {
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(
        `/v1/records/assessments/${id}/comments`,
        comment
      );
    } else {
      response = yield request.put(
        `/v1/records/assessments/${id}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        if (!isEdit) {
          modifiedComments = [...currentComments, response.body];
        } else {
          const currentIndex = currentComments.findIndex(
            (item) => item.id === response.body.id
          );
          modifiedComments = [...currentComments];
          modifiedComments[currentIndex] = response.body;
        }
        yield put({
          type: 'ASSESSMENT:COMMENT:SAVE:SUCCESS',
          comments: modifiedComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteAssessmentComment(action) {
  const { id, comment } = action;
  const currentState = yield select((state) => state.assessment);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/records/assessments/${id}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        const currentIndex = currentComments.findIndex(
          (item) => item.id === comment.id
        );
        currentComments.splice(currentIndex, 1);
        yield put({
          type: 'ASSESSMENT:COMMENT:DELETE:SUCCESS',
          comments: currentComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

const getUpdatedData = (data, links) => {
  let modifiedData = { ...data };
  const fields = ['special', 'crime_related'];
  const isSensitiveDataUsed = links.personalDataItems.some((pdi) =>
    fields.includes(pdi.value.categoryType)
  );
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('APPI')
  ) {
    modifiedData = updateAppiPersonalInfo(
      modifiedData,
      links,
      fields,
      'personal_information_special_care_required'
    );
    modifiedData = updateAppiPersonalInfo(
      modifiedData,
      links,
      ['personal_number'],
      'personal_information_my_number'
    );
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('PDPA')
  ) {
    let pdpaLegalQualifications = { ...modifiedData.pdpaLegalQualifications };
    if (isSensitiveDataUsed && !pdpaLegalQualifications?.specialTypeData) {
      pdpaLegalQualifications = {
        ...pdpaLegalQualifications,
        specialTypeData: ''
      };
      modifiedData = { ...modifiedData, pdpaLegalQualifications };
    }
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('LGPD')
  ) {
    let lgpdLegalQualifications = { ...modifiedData.lgpdLegalQualifications };
    if (isSensitiveDataUsed && !lgpdLegalQualifications?.specialData) {
      lgpdLegalQualifications = { ...lgpdLegalQualifications, specialData: '' };
      modifiedData = { ...modifiedData, lgpdLegalQualifications };
    }
  }
  if (
    links.personalDataItems?.length > 0 &&
    data.jurisdictions.includes('PIPL')
  ) {
    let piplLegalQualifications = { ...modifiedData.piplLegalQualifications };
    if (isSensitiveDataUsed && !piplLegalQualifications?.specialTypeData) {
      piplLegalQualifications = {
        ...piplLegalQualifications,
        specialDataType: {
          ...piplLegalQualifications.specialDataType,
          isSensitiveDataUsed: true
        }
      };
      modifiedData = { ...modifiedData, piplLegalQualifications };
    }
  }
  return modifiedData;
};

const updateAppiPersonalInfo = (modifiedData, links, fields, type) => {
  const isUpdaterequired = links.personalDataItems.some((pdi) =>
    fields.includes(pdi.value.categoryType)
  );
  let appiLegalQualifications = { ...modifiedData.appiLegalQualifications };
  if (
    isUpdaterequired &&
    !appiLegalQualifications?.personalInformations?.includes(type)
  ) {
    appiLegalQualifications = {
      ...appiLegalQualifications,
      personalInformations: [
        ...appiLegalQualifications.personalInformations,
        type
      ]
    };
    return { ...modifiedData, appiLegalQualifications };
  }
  return modifiedData;
};

const modifyControllers = (controllers) => {
  const modifiedControllers =
    controllers &&
    controllers.map((controller) => {
      const key = `${controller.value.name} (${controller.value.country.id})`;
      return { ...controller, value: { ...controller.value, key } };
    });
  return modifiedControllers;
};

const modifyProcessors = (processors) => {
  const modifiedProcessors = processors.map((item) => {
    const key = `${item.value.name} (${item.value.country.id})`;
    if (item.value && item.value.subProcessors.length > 0) {
      item.value.key = key;
      modifyProcessors(item.value.subProcessors);
    } else item.value.key = key;
    return item;
  });
  return modifiedProcessors;
};
