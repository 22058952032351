import { takeEvery } from 'redux-saga/effects';

import { bulkExportDSR, updateDSRNote } from '../dsr/components/dsr-list/sagas';
import { getRequestCount, searchRequester } from './components/requester-id-selector/sagas';
import {
  deleteComment, exportDSRProcessings,
  fetchOrganisationUsersForDSR, getDSRusers,
  initializeDsrDetails, reOpenDSR, resetAndFetchDSRUsers,
  resetAndfetchProcessings, saveDsrComment, searchDSRUsers, upsertDsrDetails
} from './sagas';

export default [
  [takeEvery, 'DSR:DETAIL:INIT', initializeDsrDetails],
  [takeEvery, 'DSR:REQUESTER:LIST:SEARCH_INIT', searchRequester],
  [takeEvery, 'DSR:DETAILS:UPSERT', upsertDsrDetails],
  [takeEvery, 'DSR:USERS:LIST:REQUEST', resetAndFetchDSRUsers],
  [takeEvery, 'DSR:USERS:LIST:NEXT:REQUEST', getDSRusers],
  [takeEvery, 'DSR:USERS:LIST:SEARCH:REQUEST', searchDSRUsers],
  [takeEvery, 'DSR:PROCESSING_LIST:REQUEST_INIT', resetAndfetchProcessings],
  [takeEvery, 'EXPORT:DSR:PROCESSINGS', exportDSRProcessings],
  [takeEvery, 'DSR:DETAIL:COMMENT:SAVE', saveDsrComment],
  [takeEvery, 'DSR:DETAIL:COMMENT:DELETE', deleteComment],
  [takeEvery, 'DSR:UPDATE:ITEM:NOTE', updateDSRNote],
  [takeEvery, 'DSR:REQUESTER:REQUEST:COUNT', getRequestCount],
  [takeEvery, 'DSR:BULK:EXPORT', bulkExportDSR],
  [takeEvery, 'DSR:REOPEN:INIT', reOpenDSR],
  [takeEvery, 'DSR:ORGANISATION_USERS:LIST:REQUEST', fetchOrganisationUsersForDSR]
]
