import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Column } from 'fixed-data-table';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ActionHelp from '@material-ui/icons/Help';

import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  Cell
} from '@packages/components/responsive-table';
import {
  commonTranslations,
  recordTranslations,
  legalGroundMapper
} from '@packages/utils/commontranslations';
import { getDialogContentHeight } from '@packages/utils/common-utils';
import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';

import { legalGroundTranslations } from '../../recordTranslations';

const CheckBoxCell = ({ items, onCheck, rowIndex, ...props }) => {
  const {
    jurisdiction,
    article,
    lawAbbreviation,
    content,
    selected,
    id,
    summary,
    category
  } = items.get(rowIndex);
  return (
    <Cell {...props}>
      <FormGroup column={true}>
        <FormControlLabel
          control={
            <Checkbox
              value={jurisdiction}
              style={{ height: 1 }}
              checked={selected}
              color="primary"
              onChange={(event, isInputChecked) =>
                onCheck(
                  event,
                  jurisdiction,
                  article,
                  lawAbbreviation,
                  content,
                  isInputChecked,
                  id,
                  summary,
                  category
                )
              }
            />
          }
        />
      </FormGroup>
    </Cell>
  );
};

CheckBoxCell.propTypes = {
  rowIndex: PropTypes.number,
  onCheck: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List).isRequired
};

CheckBoxCell.defaultProps = {
  rowIndex: -1,
  onCheck: (e) => e
};

const InformationCell = ({ items, onClick, rowIndex, ...props }) => {
  const { content, jurisdiction, lawAbbreviation, article } =
    items.get(rowIndex);
  const title = `${jurisdiction} ${lawAbbreviation} ${article}`;
  return content ? (
    <Cell {...props}>
      <span data-tip={content} data-for="legalGroundTitle">
        <IconButton
          style={styles.rightIcon}
          onClick={(event) => onClick(event, title, content)}
        >
          <ActionHelp color="primary" />
        </IconButton>
      </span>
    </Cell>
  ) : null;
};

InformationCell.propTypes = {
  rowIndex: PropTypes.number,
  onClick: PropTypes.func,
  items: PropTypes.instanceOf(Immutable.List).isRequired
};

InformationCell.defaultProps = {
  rowIndex: -1,
  onClick: (e) => e
};

let selectedLegalGrounds = [];
class LegalGrounds extends React.Component {
  static markSelectedLegalGrounds(legalGrounds) {
    if (legalGrounds && selectedLegalGrounds) {
      legalGrounds.forEach((legalGround) => {
        const { id } = legalGround;
        legalGround.selected =
          selectedLegalGrounds.findIndex(
            (selectedLegalGround) => selectedLegalGround.value.id === id
          ) !== -1;
      });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showFilterIcon: false
    };

    selectedLegalGrounds = props.selectedItems;
    this.requestClose = this.requestClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.searchData = this.searchData.bind(this);
    this.filterData = this.filterData.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleFilterIcon = this.handleFilterIcon.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (selectedLegalGrounds && selectedLegalGrounds.length === 0) {
      const selectedItems = Object.assign([], nextProps.selectedItems);
      selectedLegalGrounds =
        selectedItems && selectedItems.length > 0 ? selectedItems : [];
    }
  }

  // Dialog open methods
  handleOpen(event, title, content) {
    this.setState({
      open: true,
      title,
      content
    });
  }

  handleChecked(
    event,
    jurisdiction,
    article,
    lawAbbreviation,
    content,
    checked,
    id,
    summary,
    category
  ) {
    if (checked) {
      selectedLegalGrounds.push({
        value: {
          jurisdiction,
          article,
          lawAbbreviation,
          content,
          id,
          summary,
          category
        }
      });
    } else {
      const index = selectedLegalGrounds.findIndex(
        (item) => item.value.id === id
      );
      selectedLegalGrounds.splice(index, 1);
    }
    this.setState({
      selectedLegalGrounds
    });
  }

  handleFilterIcon(show) {
    this.setState({
      showFilterIcon: show
    });
  }

  filterData(filterKey, filterParams) {
    this.props.filterData(Object.assign({}, filterParams, { filterKey }));
  }

  searchData(sortOn, searchParams) {
    this.props.searchData(searchParams);
  }

  // Update legal grounds
  saveData() {
    const selectedItems = this.state.selectedLegalGrounds
      .filter((item) => item.selected === true)
      .map((item) => ({ value: item }));
    this.props.onSave(selectedItems);
  }

  // Dialog close methods
  requestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    const {
      isFetching,
      onCancel,
      legalGrounds,
      sortKey,
      sortOrder,
      searchText,
      filteredOn,
      searchItems,
      filterColumn,
      onSave
    } = this.props;
    const { showFilterIcon } = this.state;
    LegalGrounds.markSelectedLegalGrounds(legalGrounds);

    const translatedCategoryLabels =
      searchItems &&
      searchItems.category &&
      searchItems.category.map((item) => ({
        label: legalGroundMapper(item),
        value: item
      }));
    const TABLE_MAXHEIGHT_FOR_SIX_ROWS = 375;
    const WINDOW_INNERHEIGHT = 720;
    return (
      <div>
        <div>
          {!isFetching && legalGrounds.size === 0 && (
            <div
              style={{ position: 'relative', top: 100, left: 400, width: 300 }}
            >
              {commonTranslations.NoData}
            </div>
          )}
          <ResponsiveTableWrapper
            rowHeight={50}
            headerHeight={45}
            containerMaxHeight={getDialogContentHeight(
              WINDOW_INNERHEIGHT,
              TABLE_MAXHEIGHT_FOR_SIX_ROWS
            )}
            rowsCount={legalGrounds.size}
            onScrollEnd={LegalGrounds.handleScrollEnd}
            {...this.props}
          >
            <Column
              id="isRequired"
              columnKey="isRequired"
              align="center"
              cell={
                <CheckBoxCell
                  items={legalGrounds}
                  onCheck={this.handleChecked}
                />
              }
              flexGrow={0.1}
              width={40}
            />
            <Column
              id="jurisdiction"
              columnKey="jurisdiction"
              header={
                <SortHeaderCell
                  {...this.props}
                  handleFilterIcon={this.handleFilterIcon}
                  showFilterIcon={showFilterIcon}
                  enableSorting={true}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  enableSearch={false}
                  searchText={searchText}
                  filteredOn={filteredOn}
                  filterValues={searchItems ? searchItems.jurisdiction : []}
                  filterColumn={filterColumn && filterColumn.jurisdiction}
                  onSearch={this.searchData}
                  onChooseFilter={this.filterData}
                >
                  {legalGroundTranslations.jurisdiction}
                </SortHeaderCell>
              }
              cell={<DataCell items={legalGrounds} />}
              flexGrow={1}
              width={90}
            />
            <Column
              id="category"
              columnKey="category"
              header={
                <SortHeaderCell
                  {...this.props}
                  handleFilterIcon={this.handleFilterIcon}
                  showFilterIcon={showFilterIcon}
                  enableSorting={true}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  enableSearch={false}
                  searchText={searchText}
                  filteredOn={filteredOn}
                  filterValues={translatedCategoryLabels || []}
                  filterColumn={filterColumn && filterColumn.category}
                  onSearch={this.searchData}
                  onChooseFilter={this.filterData}
                >
                  {recordTranslations.type}
                </SortHeaderCell>
              }
              cell={
                <DataCell items={legalGrounds} mapper={legalGroundMapper} />
              }
              flexGrow={1}
              width={100}
            />
            <Column
              id="law"
              columnKey="law"
              header={
                <SortHeaderCell
                  {...this.props}
                  handleFilterIcon={this.handleFilterIcon}
                  showFilterIcon={showFilterIcon}
                  enableSorting={true}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  enableSearch={false}
                  searchText={searchText}
                  filteredOn={filteredOn}
                  filterValues={searchItems ? searchItems.law : []}
                  filterColumn={filterColumn && filterColumn.law}
                  onSearch={this.searchData}
                  onChooseFilter={this.filterData}
                >
                  {legalGroundTranslations.law}
                </SortHeaderCell>
              }
              cell={<DataCell items={legalGrounds} />}
              flexGrow={1}
              width={120}
            />
            <Column
              id="article"
              columnKey="article"
              header={
                <SortHeaderCell
                  {...this.props}
                  handleFilterIcon={this.handleFilterIcon}
                  showFilterIcon={showFilterIcon}
                  enableSorting={true}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  enableSearch={false}
                  searchText={searchText}
                  filteredOn={filteredOn}
                  filterValues={searchItems ? searchItems.article : []}
                  filterColumn={filterColumn && filterColumn.article}
                  onSearch={this.searchData}
                  onChooseFilter={this.filterData}
                >
                  {legalGroundTranslations.article}
                </SortHeaderCell>
              }
              cell={<DataCell items={legalGrounds} />}
              flexGrow={1}
              width={60}
            />
            <Column
              id="summary"
              columnKey="summary"
              header={
                <SortHeaderCell
                  {...this.props}
                  handleFilterIcon={this.handleFilterIcon}
                >
                  {recordTranslations.description}
                </SortHeaderCell>
              }
              cell={<DataCell items={legalGrounds} />}
              flexGrow={2}
              width={200}
            />
            <Column
              id="content"
              columnKey="content"
              align="center"
              cell={
                <InformationCell
                  items={legalGrounds}
                  onClick={this.handleOpen}
                />
              }
              flexGrow={0.1}
              width={60}
            />
          </ResponsiveTableWrapper>
        </div>
        <div style={{ paddingTop: '30px', paddingBottom: '20px' }}>
          <Button onClick={() => onSave(selectedLegalGrounds)}>
            {commonTranslations.saveAndClose}
          </Button>
          <Button variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        </div>
        {this.state.open && (
          <CommonDialog
            id="legal_ground_dialog"
            show={this.state.open}
            onCancel={this.requestClose}
            title={this.state.title}
          >
            <div style={{ whiteSpace: 'pre-wrap' }}>{this.state.content}</div>
          </CommonDialog>
        )}
      </div>
    );
  }
}

LegalGrounds.propTypes = {
  isFetching: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  filterData: PropTypes.func,
  searchData: PropTypes.func,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchText: PropTypes.instanceOf(Immutable.Map).isRequired,
  filteredOn: PropTypes.objectOf(PropTypes.array).isRequired,
  filterColumn: PropTypes.objectOf(PropTypes.string).isRequired,
  searchItems: PropTypes.objectOf(PropTypes.array).isRequired,
  legalGrounds: PropTypes.instanceOf(Immutable.List).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

LegalGrounds.defaultProps = {
  isFetching: false,
  onSave: (e) => e,
  onCancel: (e) => e,
  filterData: (e) => e,
  searchData: (e) => e
};

LegalGrounds.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default LegalGrounds;
