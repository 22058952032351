import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  apiTokenList: Immutable.List([]),
  filterParams: Immutable.Map({
    sortOn: 'createdDate',
    sortOrder: 'DESC'
  }),
  passwordValidated: undefined,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  tokenData: {},
  position: 0
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'API:TOKEN:LIST:FETCH': {
      return state
        .set('apiTokenList', state.get('apiTokenList'))
        .set('error', defaultState.get('error'));
    }
    case 'API:TOKEN:LIST:UPDATE':
    case 'API:TOKEN:LIST:FETCH:SUCCESS': {
      return state
        .set('apiTokenList', Immutable.List(action.data));
    }

    case 'API:TOKEN:LIST:FILTER': {
      const { filterParams } = action;
      return state
        .set('filterParams', Immutable.Map(filterParams))
        .set('position', 0);
    }

    case 'TOKENS:PASSWORD:VALIDATION:INIT': {
      return state
        .set('passwordValidated', defaultState.get('passwordValidated'))
        .set('error', defaultState.get('error'));
    }
    case 'TOKENS:PASSWORD:VALIDATION:SUCCESS': {
      return state
        .set('passwordValidated', action.passwordValidated);
    }
    case 'TOKENS:PASSWORD:VALIDATION:FAIL': {
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    }
    case 'API:TOKEN:GENERATION:SUCCESS': {
      return state
        .set('tokenData', action.tokenData);
    }
    case 'API:TOKEN:GENERATION:FAIL': {
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    }
    case 'API:TOKEN:RESET': {
      return state.set('tokenData', defaultState.get('tokenData'));
    }
    default:
      return state;
  }
};
