import themeConstants from './themeConstants';

export default {
  typography: {
    fontFamily: themeConstants.FONTFAMILY,
    useNextVariants: true
  },
  palette: {
    primary: {
      main: '#00ad55',
      contrastText: '#ffffff',
      dark: '#00ad55'
    },
    secondary: {
      main: '#ffffff',
      dark: '#ecebeb',
      contrastText: '#000000'
    }
  },
  props: {
    MuiInput: {
      disableUnderline: true,
      autoComplete: 'disable',
      style: { height: '100%' }
    },
    MuiAppBar: {
      color: 'primary',
      elevation: '1',
      height: themeConstants.APPBAR_HEIGHT
    },
    MuiRadio: {
      color: 'primary'
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained'
    },
    MuiDialog: {
      fullWidth: false,
      maxWidth: false
    },
    MuiSelect: {
      variant: 'outlined'
    },
    MuiMenu: {
      getContentAnchorEl: null,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      disableAutoFocusItem: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        height: '45px'
      },
      contained: {
        '&:hover': {
          opacity: 0.7
        }
      },
      containedPrimary: {
        '&:hover': {
          opacity: 0.7
        }
      },
      containedSecondary: {
        marginLeft: '20px',
        '&:hover': {
          color: '#000000'
        }
      },
      label: {
        fontSize: '16px',
        textAlign: 'center',
        textTransform: 'none'
      },
      text: {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent'
        },
        marginLeft: '5px'
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#00ad55'
      },
      barColorPrimary: {
        backgroundColor: 'red'
      },
      dashedColorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    MuiDialog: {
      root: {
        fontFamily: themeConstants.FONTFAMILY
      }
    },
    MuiInput: {
      root: {
        '& input::-webkit-clear-button': {
          display: 'none'
        }
      }
    },
    WAMuiChipInput: {
      chipContainer: {
        marginBottom: '0px',
        minHeight: '38px'
      },
      chip: {
        margin: '2px 8px 1px 0'
      }
    },

    MuiFormControlLabel: {
      root: {
        alignItems: 'end'
      },
      label: {
        fontSize: '14px',
        fontFamily: themeConstants.FONTFAMILY,
        color: '#00000'
      }
    },
    MuiOutlinedInput: {
      root: {
        height: '40px'
      },
      multiline: {
        height: 'auto'
      }
    },
    MuiSelect: {
      root: {
        border: '1px solid #c5c5c5',
        borderRadius: '4px',
        backgroundColor: '#ffffff'
      },
      outlined: {
        paddingLeft: '10px',
        backgroundColor: '#ffffff'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        maxWidth: 550
      }
    },
    MuiCollapse: {
      root: {
        fontFamily: themeConstants.FONTFAMILY
      }
    },
    MuiTable: {
      root: {
        fontFamily: themeConstants.FONTFAMILY
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none'
      },
      head: {
        fontSize: '16px',
        color: 'black'
      },
      body: {
        fontSize: '14px'
      }
    },
    MuiTableRow: {
      head: {
        height: '25px'
      }
    },
    MuiIconButton: {
      root: {
        color: 'black'
      }
    },
    MuiGridListTileBar: {
      title: {
        width: '95%'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'unset',
        fontSize: 'unset !important'
      }
    }
  }
};
