import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  recordAccessRestriction: <FormattedMessage
    id="Notification.recordAccessRestriction"
    defaultMessage="You dont have the rights to access this entity."
  />,
  planExpiredError: <FormattedMessage
    id="Notification.planExpiredError"
    defaultMessage="Your maximum limit of creating entity has been expired."
  />,
  dataRecipientCategoryUpdateSuccess:
    <FormattedMessage
      id="Notification.dataRecipientCategoryUpdated"
      description="Data recipient category updated successfully"
      defaultMessage="Data recipient category updated successfully."
    />,
  dataRecipientCategoryCreated:
    <FormattedMessage
      id="Notification.dataRecipientCategoryCreated"
      description="Data recipient category has been created."
      defaultMessage="Data recipient category has been created."
    />,
  bannerMessageUpdateSuccess:
    <FormattedMessage
      id="MessageBanner.UpdateSuccess"
      description="Banner message updated successfully"
      defaultMessage="Banner message updated successfully"
    />,
  attachmentDownloaded:
    <FormattedMessage
      id="Notification.attachmentDownloded"
      description="Document has been downloded successfully"
      defaultMessage="Document has been downloaded successfully"
    />,
  notificationStatusUpdate:
    <FormattedMessage
      id="Notification.notificationStatusUpdate"
      description="Notification status update in progress..."
      defaultMessage="Notification status update in progress..."
    />,
  roleGroupUpdateSuccess:
    <FormattedMessage
      id="Notification.roleGroup"
      description="Role group permissions updated successfully"
      defaultMessage="Role group permissions updated successfully"
    />,
  taskClaimSuccess:
    <FormattedMessage
      id="Notification.taskClaimed"
      description="Task has been claimed successfully"
      defaultMessage="Task has been claimed successfully."
    />,
  taskUnClaimSuccess:
    <FormattedMessage
      id="Notification.taskUnClaimed"
      description="Task has been unclaimed successfully"
      defaultMessage="Task has been unclaimed successfully."
    />,
  taskDelegateSuccess:
    <FormattedMessage
      id="Notification.taskDelegated"
      description="Task has been delegated successfully"
      defaultMessage="Task has been delegated successfully."
    />,
  organisationEntityDeleteSuccess:
    <FormattedMessage
      id="Notification.organisationEntityDeleted"
      description="Organisation entity successfully deleted"
      defaultMessage="Organisation entity successfully deleted."
    />,
  organisationEntityUpdateSuccess:
    <FormattedMessage
      id="Notification.organisationEntityUpdated"
      description="Organisation Entity detail updated successfully"
      defaultMessage="Organisation Entity detail updated successfully."
    />,
  organisationEntityCreated:
    <FormattedMessage
      id="Notification.organisationEntityCreated"
      description="Organisation Entity has been created"
      defaultMessage="Organisation Entity has been created."
    />,
  userUpdateSuccess:
    <FormattedMessage
      id="Notification.userUpdated"
      description="User detail updated successfully"
      defaultMessage="User detail updated successfully."
    />,
  userInvited:
    <FormattedMessage
      id="Notification.userInvited"
      description="User has been invited"
      defaultMessage="User has been invited."
    />,
  dataSubjectCategoryUpdateSuccess:
    <FormattedMessage
      id="Notification.dataSubjectCategoryUpdated"
      description="Data subject category updated successfully"
      defaultMessage="Data subject category updated successfully."
    />,
  dataSubjectCategoryCreated:
    <FormattedMessage
      id="Notification.dataSubjectCategoryCreated"
      description="Data subject category has been created"
      defaultMessage="Data subject category has been created."
    />,
  dataSubjectCategoryDeleteSuccess:
    <FormattedMessage
      id="Notification.dataSubjectCategoryDeleted"
      description="Data subject category successfully deleted"
      defaultMessage="Data subject category successfully deleted."
    />,
  dataSourceCategoryDeleteSuccess:
    <FormattedMessage
      id="Notification.dataSourceCategoryDeleteSuccess"
      defaultMessage="Data source category successfully deleted."
    />,
  securityMeasureUpdateSuccess:
    <FormattedMessage
      id="Notification.securityMeasureUpdated"
      description="Security measure updated successfully"
      defaultMessage="Security measure updated successfully."
    />,
  securityMeasureCreated:
    <FormattedMessage
      id="Notification.securityMeasureCreated"
      description="Security measure has been created"
      defaultMessage="Security measure has been created."
    />,
  purposeUpdateSuccess:
    <FormattedMessage
      id="Notification.purposeUpdated"
      description="Purpose updated successfully"
      defaultMessage="Purpose updated successfully."
    />,
  purposeCreated:
    <FormattedMessage
      id="Notification.purposeCreated"
      description="Purpose has been created"
      defaultMessage="Purpose has been created."
    />,
  processingCategoryUpdateSuccess:
    <FormattedMessage
      id="Notification.processingCategoryUpdated"
      description="Processing category updated successfully"
      defaultMessage="Processing category updated successfully."
    />,
  processingCategoryCreated:
    <FormattedMessage
      id="Notification.processingCategoryCreated"
      description="Processing category has been created"
      defaultMessage="Processing category has been created."
    />,
  retentionOffsetUpdateSuccess:
    <FormattedMessage
      id="Notification.retentionOffsetUpdated"
      description="Retention offset updated successfully"
      defaultMessage="Retention offset updated successfully."
    />,
  referenceUpdateSuccess:
    <FormattedMessage
      id="Notification.referenceUpdated"
      description="Reference updated successfully"
      defaultMessage="Reference updated successfully."
    />,
  referenceCreated:
    <FormattedMessage
      id="Notification.referenceCreated"
      description="Reference has been created"
      defaultMessage="Reference has been created."
    />,
  fairnessOfDecisionUpdateSuccess:
    <FormattedMessage
      id="Notification.fairnessOfDecisionUpdated"
      defaultMessage="Fairness of decision updated successfully."
    />,
  fairnessOfDecisionCreated:
    <FormattedMessage
      id="Notification.fairnessOfDecisionCreated"
      defaultMessage="Fairness of decision has been created."
    />,
  rightUpdateSuccess:
    <FormattedMessage
      id="Notification.rightUpdated"
      description="Data subject right updated successfully"
      defaultMessage="Data subject right updated successfully."
    />,
  rightCreated:
    <FormattedMessage
      id="Notification.rightCreated"
      description="Data subject right has been created"
      defaultMessage="Data subject right has been created."
    />,
  threatUpdateSuccess:
    <FormattedMessage
      id="Notification.threatUpdated"
      description="Threat updated successfully"
      defaultMessage="Threat updated successfully."
    />,
  threatCreated:
    <FormattedMessage
      id="Notification.threatCreated"
      description="Threat has been created"
      defaultMessage="Threat has been created."
    />,
  impactUpdateSuccess:
    <FormattedMessage
      id="Notification.impactUpdated"
      description="Impact updated successfully"
      defaultMessage="Impact updated successfully."
    />,
  impactCreated:
    <FormattedMessage
      id="Notification.impactCreated"
      description="Impact has been created"
      defaultMessage="Impact has been created."
    />,
  policyUpdateSuccess:
    <FormattedMessage
      id="Notification.policyUpdateSuccess"
      description="Policy updated successfully"
      defaultMessage="Policy updated successfully."
    />,
  policyCreated:
    <FormattedMessage
      id="Notification.policyCreated"
      description="Policy has been created"
      defaultMessage="Policy has been created."
    />,
  accountabilityUpdateSuccess:
    <FormattedMessage
      id="Notification.accountabilityUpdateSuccess"
      description="Accountability updated successfully"
      defaultMessage="Accountability updated successfully."
    />,
  accountabilityCreated:
    <FormattedMessage
      id="Notification.accountabilityCreated"
      description="Accountability has been created"
      defaultMessage="Accountability has been created."
    />,
  tagsUpdateSuccess:
    <FormattedMessage
      id="Notification.tagsUpdateSuccess"
      description="Tags updated successfully"
      defaultMessage="Tags updated successfully."
    />,
  tagsCreated:
    <FormattedMessage
      id="Notification.tagsCreated"
      description="Tag has been created"
      defaultMessage="Tag has been created."
    />,
  documentsUpdateSuccess:
    <FormattedMessage
      id="Notification.documentUpdateSuccess"
      description="Document updated successfully"
      defaultMessage="Document updated successfully."
    />,
  documentsCreated:
    <FormattedMessage
      id="Notification.documentCreated"
      description="Document has been created"
      defaultMessage="Document has been created."
    />,
  userDeleteSuccess:
    <FormattedMessage
      id="Notification.userDeleted"
      description="Organisation user successfully deleted"
      defaultMessage="Organisation user successfully deleted."
    />,
  recordStatusUpdateSuccess:
    <FormattedMessage
      id="Notification.recordStatusUpdated"
      description="Record status updated successfully."
      defaultMessage="Record status updated successfully."
    />,
  recordNoteUpdateSuccess:
    <FormattedMessage
      id="Notification.recordNoteUpdated"
      description="Record note updated successfully."
      defaultMessage="Record note updated successfully."
    />,
  recordDeleteSuccess:
    <FormattedMessage
      id="Notification.recordDeleted"
      description="Record successfully deleted"
      defaultMessage="Record successfully deleted."
    />,
  templateDeleteSuccess:
    <FormattedMessage
      id="Notification.templateDeleted"
      description="Template successfully deleted"
      defaultMessage="Template successfully deleted."
    />,
  reportSearchCreated:
    <FormattedMessage
      id="Notification.reportSearchCreated"
      description="Report search has been created"
      defaultMessage="Report search has been created."
    />,
  reportSearchUpdated:
    <FormattedMessage
      id="Notification.reportSearchUpdated"
      description="Report search updated successfully"
      defaultMessage="Report search updated successfully."
    />,
  reportSearchDeleted:
    <FormattedMessage
      id="Notification.reportSearchDeleted"
      description="Report search successfully deleted"
      defaultMessage="Report search successfully deleted."
    />,
  partnerDetailUpdateSuccess:
    <FormattedMessage
      id="Notification.partnerDetailUpdated"
      description="Partner detail updated successfully"
      defaultMessage="Partner detail updated successfully."
    />,
  partnerCreated:
    <FormattedMessage
      id="Notification.partnerCreated"
      description="Partner has been created"
      defaultMessage="Partner has been created."
    />,
  partnerDeleted:
    <FormattedMessage
      id="Notification.partnerDeleted"
      description="Partner has been deleted successfully"
      defaultMessage="Partner has been deleted successfully."
    />,
  tenantDetailUpdateSuccess:
    <FormattedMessage
      id="Notification.tenantDetailUpdated"
      description="Tenant detail updated successfully"
      defaultMessage="Tenant detail updated successfully."
    />,
  tenantEmailPreferenceUpdated:
    <FormattedMessage
      id="Notification.tenantEmailPreferenceUpdated"
      defaultMessage="Tenant email preference updated successfully."
    />,
  tenantCreated:
    <FormattedMessage
      id="Notification.tenantCreated"
      description="Tenant has been created"
      defaultMessage="Tenant has been created."
    />,
  userDetailUpdated:
    <FormattedMessage
      id="Notification.userDetailUpdated"
      description="User details updated successfully."
      defaultMessage="User details updated successfully."
    />,
  userDetailUpdateFailed:
    <FormattedMessage
      id="Notification.userDetailUpdateFailed"
      description="User details could not be updated."
      defaultMessage="User details could not be updated."
    />,
  userResentEmailSuccess:
    <FormattedMessage
      id="Notification.userResentEmailSuccess"
      description="Email for re-invitation has been sent to your inbox."
      defaultMessage="Email for re-invitation has been sent to your inbox."
    />,
  bulkInviteSuccess:
    <FormattedMessage
      id="Notification.bulkInviteSuccess"
      description="Email to set password has been successfully sent to the user(s)."
      defaultMessage="Email to set password has been successfully sent to the user(s)."
    />,
  userReinviteSuccess:
    <FormattedMessage
      id="Notification.userReinviteSuccess"
      description="Re-invitation email sent."
      defaultMessage="Re-invitation email sent."
    />,
  templateCreated:
    <FormattedMessage
      id="Notification.templateCreated"
      description="Template has been created"
      defaultMessage="Template has been created."
    />,
  userCreated:
    <FormattedMessage
      id="Notification.userCreated"
      description="User has been created"
      defaultMessage="User has been created."
    />,
  whiteLabellingUpdated:
    <FormattedMessage
      id="Notification.whiteLabellingUpdated"
      description="Whitelabelling details updated"
      defaultMessage="Whitelabelling details updated. Browser refresh recommended."
    />,
  fieldVisibilitypdated:
    <FormattedMessage
      id="Notification.fieldVisibilitypdated"
      description="Field visibility details updated"
      defaultMessage="Field visibility updated."
    />,
  pricingUpdated:
    <FormattedMessage
      id="Notification.pricingUpdated"
      defaultMessage="Pricing plan updated."
    />,
  mfaSettingsUpdated:
    <FormattedMessage
      id="Notification.mfaSettingsUpdated"
      description="MFA settings have been updated successfully."
      defaultMessage="MFA settings have been updated successfully."
    />,
  mfaSettingsUpdateFail:
    <FormattedMessage
      id="Notification.mfaSettingsUpdateFail"
      description="MFA settings could not be updated."
      defaultMessage="MFA settings could not be updated."
    />,
  ssoSettingsUpdated:
    <FormattedMessage
      id="Notification.ssoSettingsUpdated"
      description="SSO settings have been updated successfully."
      defaultMessage="SSO settings have been updated successfully."
    />,
  passwordSettingsUpdated:
    <FormattedMessage
      id="Notification.passwordSettingsUpdated"
      description="Password settings have been updated successfully."
      defaultMessage="Password settings have been updated successfully."
    />,
  metaDataDownloadSuccess:
    <FormattedMessage
      id="Notification.metaDataDownloadSuccess"
      description="PrivacyPerfect metadata has been downloaded successfully."
      defaultMessage="PrivacyPerfect metadata has been downloaded successfully."
    />,
  ssoSettingsUpdateFail:
    <FormattedMessage
      id="Notification.ssoSettingsUpdateFail"
      description="SSO settings could not be updated."
      defaultMessage="SSO settings could not be updated."
    />,
  personalDataCategoryUpdateSuccess:
    <FormattedMessage
      id="Notification.personalDataCategoryUpdated"
      description="Personal data category updated successfully"
      defaultMessage="Personal data category updated successfully."
    />,
  personalDataCategoryCreated:
    <FormattedMessage
      id="Notification.personalDataCategoryCreated"
      description="Personal data category has been created"
      defaultMessage="Personal data category has been created."
    />,
  dataSourceCategoryUpdateSuccess:
    <FormattedMessage
      id="Notification.dataSourceCategoryUpdateUpdated"
      description="Data source category updated successfully"
      defaultMessage="Data source category updated successfully."
    />,
  dataSourceCategoryCreated:
    <FormattedMessage
      id="Notification.dataSourceCategoryCreated"
      description="Data source category created successfully"
      defaultMessage="Data source category created successfully."
    />,
  dataSourcesUpdateSuccess:
    <FormattedMessage
      id="Notification.dataSourceUpdated"
      description="Data source updated successfully"
      defaultMessage="Data source updated successfully."
    />,
  dataSourcesCreated:
    <FormattedMessage
      id="Notification.dataSourceCreated"
      description="Data source has been created"
      defaultMessage="Data source has been created."
    />,
  personalDataItemUpdateSuccess:
    <FormattedMessage
      id="Notification.personalDataItemUpdated"
      description="Personal data item updated successfully"
      defaultMessage="Personal data item updated successfully."
    />,
  personalDataItemCreated:
    <FormattedMessage
      id="Notification.personalDataItemCreated"
      description="Personal data item has been created"
      defaultMessage="Personal data item has been created."
    />,
  notificationDeleted:
    <FormattedMessage
      id="Notification.notificationDeleted"
      description="Notification deleted successfully"
      defaultMessage="Notification deleted successfully."
    />,
  taskDeleted:
    <FormattedMessage
      id="Notification.taskDeleted"
      description="Task deleted successfully"
      defaultMessage="Task deleted successfully."
    />,
  jobSubmittedSuccess:
    <FormattedMessage
      id="Notification.jobSubmitted"
      description="Job submitted successfully"
      defaultMessage="Job submitted successfully."
    />,
  jobRestartedSuccess:
    <FormattedMessage
      id="Notification.jobRestarted"
      description="Job restarted successfully"
      defaultMessage="Job restarted successfully."
    />,
  jobTerminatedSuccess:
    <FormattedMessage
      id="Notification.jobTerminated"
      description="Job terminated successfully"
      defaultMessage="Job terminated successfully."
    />,
  qualityControlsUpdateSuccess:
    <FormattedMessage
      id="Notification.qualityControlsUpdated"
      description="Quality control updated successfully"
      defaultMessage="Quality control updated successfully."
    />,
  qualityControlsCreated:
    <FormattedMessage
      id="Notification.qualityControlsCreated"
      description="Quality control has been created"
      defaultMessage="Quality control has been created."
    />,
  measureUpdateSuccess:
    <FormattedMessage
      id="Notification.measureUpdated"
      description="Mitigating measure updated successfully"
      defaultMessage="Mitigating measure updated successfully."
    />,
  measureCreated:
    <FormattedMessage
      id="Notification.measureCreated"
      description="Mitigating measure has been created"
      defaultMessage="Mitigating measure has been created."
    />,
  riskUpdateSuccess:
    <FormattedMessage
      id="Notification.riskUpdated"
      description="Risk detail updated successfully"
      defaultMessage="Risk detail updated successfully."
    />,
  riskCreated:
    <FormattedMessage
      id="Notification.riskCreated"
      description="Risk detail has been created"
      defaultMessage="Risk detail has been created."
    />,
  helpNotesUpdateSuccess:
    <FormattedMessage
      id="HelpNotes.updateSuccess"
      defaultMessage="Help notes updated successfully."
    />,
  dsrSettingsUpdateSuccess:
    <FormattedMessage
      id="dsr.updateSettingsSuccess"
      defaultMessage="DSR settings updated successfully."
    />,
  dsrNoteUpdateSuccess:
    <FormattedMessage
      id="Notification.dsrNoteUpdated"
      description="DSR note updated successfully."
      defaultMessage="DSR note updated successfully."
    />,
  dsrNoteDeleted:
    <FormattedMessage
      id="Notification.dsrNoteDeleted"
      description="DSR note deleted successfully."
      defaultMessage="DSR note deleted successfully."
    />,
  tenantDeleted:
    <FormattedMessage
      id="Notification.tenantDeleted"
      defaultMessage="Tenant deleted successfully."
    />,
  apiTokenDeleted:
    <FormattedMessage
      id="Notification.apiTokenDeleted"
      defaultMessage="API token deleted successfully."
    />,
  apiTokenUpdated:
    <FormattedMessage
      id="Notification.apiTokenUpdated"
      defaultMessage="API token updated successfully."
    />,
  tokenCopied:
    <FormattedMessage
      id="Notification.apiTokenCopied"
      defaultMessage="API token copied to the clipboard."
    />,
  linkGroupDeleted:
    <FormattedMessage
      id="Notification.linkGroupDelete"
      defaultMessage="Link group deleted successfully."
    />,
  commentedSuccess: <FormattedMessage
    id="DSR.commentedSuccess"
    defaultMessage="Comment has been saved successfully"
  />,
  commentDeletedSuccess: <FormattedMessage
    id="DSR.commentedDeletedSuccess"
    defaultMessage="Comment has been deleted successfully"
  />,
  changeManagerSuccess:
    <FormattedMessage
      id="Notification.changeManagerSuccess"
      defaultMessage="Subscription manager changed successfully."
    />,
  cancelSubscriptionSuccess:
    <FormattedMessage
      id="Notification.cancelSubscriptionSuccess"
      defaultMessage="Subscription cancelled successfully."
    />,
  responseUpdated:
    <FormattedMessage
      id="Notification.responseUpdated"
      defaultMessage="Response updated successfully."
    />,
  responseSubmitted:
    <FormattedMessage
      id="Notification.responseSubmitted"
      defaultMessage="Response submitted successfully."
    />,
  newTempateSuccess:
    <FormattedMessage
      id="Notification.newTempateSuccess"
      defaultMessage="Template created successfully."
    />,
  newFormSuccess:
    <FormattedMessage
      id="Notification.newFormSuccess"
      defaultMessage="Form created successfully."
    />,
  updateFormSuccess:
    <FormattedMessage
      id="Notification.updateFormSuccess"
      defaultMessage="Form Updated successfully."
    />,
  updateTemplateSuccess:
    <FormattedMessage
      id="Notification.updateTemplateSuccess"
      defaultMessage="Template Updated successfully."
    />,
  messageSentSuccessfully:
    <FormattedMessage
      id="Notification.messageSentSuccessfully"
      defaultMessage="Message sent successfully."
    />,
  formDeleted:
    <FormattedMessage
      id="Notification.formDeleted"
      defaultMessage="Form deleted successfully."
    />,
  templateDeleted:
    <FormattedMessage
      id="Notification.templateDeleted"
      defaultMessage="Template deleted successfully."
    />,
  newRegistrySuccess:
    <FormattedMessage
      id="Notification.newRegistrySuccess"
      defaultMessage="Public registry created successfully."
    />,
  updateRegistrySuccess:
    <FormattedMessage
      id="Notification.updateRegistrySuccess"
      defaultMessage="Public registry updated successfully."
    />,
  registryDeleted:
    <FormattedMessage
      id="Notification.registryDeleted"
      defaultMessage="Registry deleted successfully."
    />,
  registriesDeleted:
    <FormattedMessage
      id="Notification.registryDeleted"
      defaultMessage="Registry deleted successfully."
    />,
  EmbedCodeCopied:
    <FormattedMessage
      id="Notification.EmbedCodeCopied"
      defaultMessage="Embed code copied to the clipboard."
    />,
  tagCreationSuccess: <FormattedMessage
    id="Notification.tagCreationSuccess"
    defaultMessage="Tag created successfully."
  />,
  tagUpdatedSuccessfully: <FormattedMessage
    id="Notification.tagUpdatedSuccessfully"
    defaultMessage="Tag updated successfully."
  />,
  retryCompleted: <FormattedMessage
    id="Notification.retryCompleted"
    defaultMessage="Retry completed successfully"
  />,
  tenantGroupDeletionSuccess: <FormattedMessage
    id="Notification.tenantGroupDeletionSuccess"
    defaultMessage="Tenant Group deleted successfully."
  />,
  organisationImportSuccess: <FormattedMessage
    id="Notification.organisationImportSucess"
    defaultMessage="Organisations Imported sucessfully."
  />,
  duplicateParentFound: <FormattedMessage
    id="Notification.duplicateParentFound"
    defaultMessage="Duplicate parent found"
  />,
  documentRecordTypesUpdateSuccess:
    <FormattedMessage
      id="Notification.documentRecordTypesUpdated"
      description="Document Record Types updated successfully"
      defaultMessage="Document Record Types updated successfully."
    />,
  documentRecordTypesCreated:
    <FormattedMessage
      id="Notification.documentRecordTypesCreated"
      description="Document Record Types has been created"
      defaultMessage="Document Record Types has been created."
    />,
  specialCharacteristicsUpdateSuccess:
    <FormattedMessage
      id="Notification.specialCharacteristicsUpdated"
      description="Special Characteristics updated successfully"
      defaultMessage="Special Characteristics updated successfully."
    />,
  specialCharacteristicsCreated:
    <FormattedMessage
      id="Notification.specialCharacteristicsCreated"
      description="Special Characteristics has been created"
      defaultMessage="Special Characteristics has been created."
    />,
  recordPromotedSuccessfully:
    <FormattedMessage
      id="Notification.recordPromotedSuccessfully"
      defaultMessage="Record promoted successfully."
    />,
  recordUpgradedSuccessfully:
    <FormattedMessage
      id="Notification.recordUpgradedSuccessfully"
      defaultMessage="Record upgraded successfully."
    />,
  releaseNotesUpdateSuccess: <FormattedMessage
    id="Notification.releaseNotesUpdateSuccess"
    defaultMessage="Updates saved successfully."
  />,
  auditTrialSettingsUpdated: <FormattedMessage
    id="Notification.auditTrialSettingsUpdated"
    defaultMessage="Updates saved successfully."
  />,
  templateStatusUpdated: <FormattedMessage
    id="Notification.templateStatusUpdated"
    defaultMessage="Template status updated successfully."
  />
};
