import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import LegalGrounds from '../../legal-grounds';

class BreachGroundList extends React.Component {
  componentDidMount() {
    if (this.props.initBreachGrounds) {
      this.props.initBreachGrounds();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.filteredOn !== undefined &&
      nextProps.filteredOn !== this.props.filteredOn
    ) {
      const items = Object.values(nextProps.filteredOn);
      const isFilterApplied = items.some((item) => item.length > 0);
      this.props.getFilterAppplied(isFilterApplied);
    }
  }

  render() {
    const {
      isFetching,
      onCancel,
      breachGrounds,
      selectedItems,
      sortKey,
      sortOrder,
      searchText,
      filteredOn,
      searchItems,
      filterColumn,
      onChooseFilter,
      onSearch,
      onSave
    } = this.props;

    return (
      <LegalGrounds
        id="legal-grounds"
        selectedItems={selectedItems}
        legalGrounds={breachGrounds}
        isFetching={isFetching}
        onCancel={onCancel}
        sortKey={sortKey}
        sortOrder={sortOrder}
        searchText={searchText}
        filteredOn={filteredOn}
        searchItems={searchItems}
        filterColumn={filterColumn}
        filterData={onChooseFilter}
        searchData={onSearch}
        onSave={onSave}
      />
    );
  }
}

BreachGroundList.propTypes = {
  breachGrounds: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  searchText: PropTypes.objectOf(PropTypes.string),
  filteredOn: PropTypes.objectOf(PropTypes.array),
  filterColumn: PropTypes.shape({}),
  searchItems: PropTypes.objectOf(PropTypes.array),
  initBreachGrounds: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  getFilterAppplied: PropTypes.func
};

BreachGroundList.defaultProps = {
  isFetching: false,
  breachGrounds: Immutable.List(),
  searchText: Immutable.Map(),
  filteredOn: Immutable.Map({
    jurisdiction: ['EU']
  }),
  filterColumn: Immutable.Map({ jurisdiction: 'jurisdiction' }),
  searchItems: Immutable.Map(),
  initBreachGrounds: (e) => e,
  onSave: (e) => e,
  onCancel: (e) => e,
  onChooseFilter: (e) => e,
  onSearch: (e) => e,
  getFilterAppplied: (e) => e
};
BreachGroundList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default BreachGroundList;
