import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { getBrowserOrFallBackLang, handleServiceDown } from '@packages/utils/common-utils';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';

export function* fetchPublicDsrForm({ isPreview }) {
  const { formId } = getParameterValuesFromHash('/dsr-public-form/:formId');
  yield put({ type: 'PUBLIC:DSR:FORM:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get(`/v1/public/dsr/forms/${formId}`, null, {}, isPreview);
    switch (response.status) {
      case 200: {
        if (localStorage.getItem('publicRegistryLanguage')) {
          yield put({ type: 'PUBLIC:DSR:FORM:FETCH:SUCCESS', data: response.body });
        }
        else {
          localStorage.setItem('publicRegistryLanguage', getBrowserOrFallBackLang(response.body.languageCode));
          yield put({ type: 'PUBLIC:DSR:FORM:FETCH:SUCCESS', data: response.body });
          yield put({ type: 'TRANSLATION:INIT', locale: getBrowserOrFallBackLang(response.body.languageCode) });
        }
        break;
      }
      case 404: {
        yield put({ type: 'PUBLIC:DSR:FORM:FETCH:FAIL' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'PUBLIC:DSR:FORM:FETCH:FAIL' });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'public');
  }
}

export function* submitPublicDsrForm(action) {
  const { data } = action;
  const { formId } = getParameterValuesFromHash('/dsr-public-form/:formId');
  yield put({ type: 'PUBLIC:DSR:FORM:SUBMIT' });
  try {
    const request = registry.get('request');
    const response = yield request.post(`/v1/public/dsr/forms/${formId}`, data);
    switch (response.status) {
      case 200: {
        yield put({ type: 'PUBLIC:DSR:FORM:SUBMIT:SUCCESS', data: response.body });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'PUBLIC:DSR:FORM:SUBMIT:FAIL' });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'public');
    yield put({ type: 'PUBLIC:DSR:FORM:SUBMIT:FAIL' });
  }
}

