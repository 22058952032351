import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { recordTranslations } from '@packages/utils/commontranslations';
import ValueSelector from '../value-selector';

const ThirdPartyDataOrganisationsSelector = (props) => {
  useEffect(() => {
    props.initOrganisations();
  }, []);

  const handleNextData = () => {
    props.getNextData(props.position);
  };

  const handleSearch = (searchParams) => {
    props.onSearch({ ...searchParams });
  };

  const { hintTextLabel } = props;
  return (
    <div>
      <ValueSelector
        {...props}
        valueAttr="name"
        searchResults={props.searchItem}
        dataItems={props.items}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        title={recordTranslations.dataFromThirdParty}
      />
    </div>
  );
};

ThirdPartyDataOrganisationsSelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  initOrganisations: PropTypes.func,
  onSearch: PropTypes.func,
  searchItem: PropTypes.instanceOf(Immutable.List),
  items: PropTypes.instanceOf(Immutable.List)
};

ThirdPartyDataOrganisationsSelector.defaultProps = {
  hintTextLabel: null,
  initOrganisations: e => e,
  getNextData: e => e,
  position: 0,
  onSearch: e => e,
  searchItem: Immutable.List(),
  items: Immutable.List()
};

export default ThirdPartyDataOrganisationsSelector;
