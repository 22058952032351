import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, withTheme } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';

const wrapperDivStyle = { width: '100%', position: 'relative' };

function renderInput (inputProps) {
  const { value, onChange, chips, ref, ...other } = inputProps;

  return (
    <ChipInput
      classes={{
        root: 'chip_root'
      }}
      clearInputValueOnChange={true}
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
      {...other}
    />
  );
}

const renderSuggestion = (suggestion, { query, isHighlighted }, theme) => {
  const matches = match(suggestion.key, query);
  const parts = parse(suggestion.key, matches);
  const color = suggestion.value === '-3' || suggestion.value === '-2' ? theme.palette.primary.main : '';
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={e => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => (
          part.highlight ? (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={String(index)}
              style={{ fontWeight: 500, color }}
            >
              {part.text}
            </span>
          ) : (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={String(index)}
              style={{ color }}
            >
              {part.text}
            </span>
          )
        ))}
      </div>
    </MenuItem>
  );
};

const getPositionRelativeToViewport = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop };
};

const renderSuggestionsContainer = (options, displaySelectorOnTop, type) => {
  const { containerProps, children } = options;
  const inputElement = document.getElementById(`${type}_autosuggest-div`);

  const width = inputElement ? inputElement.clientWidth : '';
  const relativePosition = inputElement ? getPositionRelativeToViewport(inputElement) : { top: 40 };
  const bottomPosition = window.innerHeight - relativePosition.top - 25;
  let selectorStyle = { width, pointerEvents: 'all' };
  selectorStyle = displaySelectorOnTop ? { ...selectorStyle, bottom: bottomPosition } :
    { ...selectorStyle, top: (relativePosition.top + 40) };

  return (
    <Paper {...containerProps} style={selectorStyle}>
      {children}
    </Paper>
  );
};

const getSuggestionValue = suggestion => suggestion.key;

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'fixed',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    zIndex: 2000
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '98%'
  }
});

let styleParameters = '';

class AutosuggestWithChips extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    suggestions: this.props.dataSource,
    value: this.props.initialValue,
    textFieldInput: ''
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.initialValue !== nextProps.initialValue) {
      this.setState({
        value: nextProps.initialValue
      });
    }
    if (this.props.searchText !== nextProps.searchText) {
      this.setState({
        textFieldInput: nextProps.searchText
      });
    }
    if (this.props.dataSource !== nextProps.dataSource) {
      this.handleSuggestionsFetchRequested(null, nextProps.dataSource);
    }
  }

  handleSuggestionsFetchRequested = (value, dataSource) => {
    const datasources = dataSource || this.state.suggestions;
    const count = this.props.maxCount !== 0 ? 3 : 5;
    const availableSuggestions = Array.from(datasources.slice(0, count) || []);
    if (this.props.selectFromListMenuItem &&
      availableSuggestions.findIndex(item => item.value === '-3') === -1) {
      this.appendSelectFromListMenuToSuggestions(availableSuggestions);
    }
    if (this.props.createNewMenuItem &&
      availableSuggestions.findIndex(item => item.value === '-2') === -1) {
      this.appendCreateNewMenuToSuggestions(availableSuggestions);
    }
    this.setState({
      suggestions: availableSuggestions
    });
  };

  handletextFieldInputChange = (event, { newValue }) => {
    if (event.type === 'change') {
      this.setState({
        textFieldInput: newValue
      });
      this.props.handleSearch(newValue);
    }
  };

  handleAddChip (chip) {
    const currentValueToAdd = chip.suggestion || chip.name;
    if (currentValueToAdd && currentValueToAdd.value && currentValueToAdd.value === '-2') {
      this.props.handleAddClick();
    } else if (currentValueToAdd && currentValueToAdd.value && currentValueToAdd.value === '-3') {
      this.props.handleMultipleSelect();
    } else if (currentValueToAdd && (this.props.allowDuplicates || this.state.value.indexOf(chip) < 0)
    && currentValueToAdd.value !== '-3' && currentValueToAdd.value !== '-2') {
      this.setState(({ value }) => ({
        value: currentValueToAdd.value ? [...value, currentValueToAdd] : value,
        textFieldInput: ''
      }));
      if (currentValueToAdd.value) this.props.handleSelectedItem(currentValueToAdd);
    }
    document.activeElement.blur();
  }

  handleDeleteChip (chip, index) {
    this.setState(({ value }) => {
      const temp = value.slice();
      temp.splice(index, 1);
      return {
        value: temp
      };
    });
    this.props.handleRemoveItem(index);
  }

  appendSelectFromListMenuToSuggestions = (suggestionsList) => {
    let itemText = '';
    if (suggestionsList.length === 0) { // suggestionsList would contain the 'More...' option which is added already
      itemText = this.props.intl.formatMessage({
        id: 'List.selectFromList',
        defaultMessage: 'Select from a list'
      });
    } else {
      itemText = this.props.intl.formatMessage({
        id: 'List.moreResults',
        defaultMessage: 'More...'
      });
    }
    suggestionsList.push({
      name: itemText,
      key: itemText,
      value: '-3'
    });
  }

  appendCreateNewMenuToSuggestions = (suggestionsList) => {
    let itemText = '';
    if (suggestionsList.length === 1) { // suggestionsList would contain the 'More...' option which is added already
      itemText = this.props.intl.formatMessage({
        id: 'List.noMatchesFound',
        defaultMessage: 'No matches found - Create new...'
      });
    } else {
      itemText = this.props.intl.formatMessage({
        id: 'List.createNew',
        defaultMessage: 'Create new...'
      });
    }
    suggestionsList.push({
      name: itemText,
      key: itemText,
      value: '-2'
    });
  }

  handleOnBlur = () => {
    this.setState({
      textFieldInput: ''
    });
    this.props.handleSearch('');
    const formElement = document.getElementById('autosuggest-input').form;
    formElement.firstChild.setAttribute('style', `${styleParameters}`);
  }

  handleFocus = () => {
    const formElementFirstChild = document.getElementById('autosuggest-input').form.firstChild;
    styleParameters = formElementFirstChild.getAttribute('style');
    formElementFirstChild.setAttribute('style', `${styleParameters} pointer-events: none;`);
  }

  handleSuggestions = () => {
    const { maxCount, initialValue } = this.props;
    return (maxCount !== 0 ? (initialValue && initialValue.length) < maxCount : true);
  }

  render () {
    const { classes, disabled, displaySelectorOnTop, type, ...other } = this.props;
    return (
      <div id={`${type}_autosuggest-div`} style={wrapperDivStyle}>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          shouldRenderSuggestions={this.handleSuggestions}
          renderInputComponent={renderInput}
          alwaysRenderSuggestions={false}
          focusInputOnSuggestionClick={false}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          renderSuggestionsContainer={options => renderSuggestionsContainer(options, displaySelectorOnTop, type)}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion, value) => renderSuggestion(suggestion, value, this.props.theme)}
          onSuggestionSelected={(e, suggestion) => { this.handleAddChip(suggestion); e.preventDefault(); }}
          inputProps={{
            id: 'autosuggest-input',
            variant: 'standard',
            disableUnderline: true,
            disabled,
            chips: this.state.value,
            value: this.state.textFieldInput,
            onChange: this.handletextFieldInputChange,
            onAdd: chip => this.handleAddChip(chip),
            onFocus: this.handleFocus,
            onBlur: this.handleOnBlur,
            onDelete: (chip, index) => { this.handleDeleteChip(chip, index); },
            ...other
          }}
        />
      </div>
    );
  }
}

AutosuggestWithChips.propTypes = {
  allowDuplicates: PropTypes.bool,
  selectFromListMenuItem: PropTypes.bool,
  createNewMenuItem: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  initialValue: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.shape({
    container: PropTypes.shape({}),
    suggestionsContainerOpen: PropTypes.shape({}),
    suggestionsList: PropTypes.shape({}),
    suggestion: PropTypes.shape({})
  }).isRequired,
  handleAddClick: PropTypes.func,
  handleMultipleSelect: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  handleSearch: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  disabled: PropTypes.bool,
  searchText: PropTypes.string,
  handleSelectedItem: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  maxCount: PropTypes.number,
  displaySelectorOnTop: PropTypes.bool,
  type: PropTypes.string
};

AutosuggestWithChips.defaultProps = {
  handleAddClick: e => e,
  handleMultipleSelect: e => e,
  allowDuplicates: false,
  dataSource: [],
  selectFromListMenuItem: false,
  createNewMenuItem: false,
  theme: {},
  disabled: false,
  handleSearch: e => e,
  initialValue: [],
  searchText: '',
  handleSelectedItem: e => e,
  handleRemoveItem: e => e,
  maxCount: 0,
  displaySelectorOnTop: false,
  type: ''
};

export default withTheme(withStyles(styles)(injectIntl(AutosuggestWithChips)));
