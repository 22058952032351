import dataSubjectRequestTranslations from '../dsr/dsrTranslations';

// eslint-disable-next-line import/prefer-default-export
export const transformResponseData = (data) => {
  let modifiedData = data;
  if (modifiedData.dataSubject) {
    const dataSubjectCategories = modifiedData.dataSubject.value.dataSubjectCategories.map(item => ({
      ...item,
      key: item.name
    }));
    const dataSubject = {
      ...modifiedData.dataSubject,
      value: {
        ...modifiedData.dataSubject.value,
        dataSubjectCategories
      }
    };
    modifiedData = {
      ...modifiedData,
      dataSubject
    };
  }
  if (modifiedData.requestTypes.length > 0) {
    modifiedData = {
      ...modifiedData,
      requestTypes: modifiedData.requestTypes.map(item => ({
        data: item,
        key: dataSubjectRequestTranslations[item]
      }))
    };
  }

  if (modifiedData.assignedOrganisations && modifiedData.assignedOrganisations.length > 0) {
    const assignedOrganisations = modifiedData.assignedOrganisations.map(item => (
      { ...item, value: { ...item.value, key: item.value.name } }));
    modifiedData = {
      ...modifiedData,
      assignedOrganisations
    };
  }

  if (modifiedData.assignedUsers && modifiedData.assignedUsers.length > 0) {
    const assignedUsers = getModifiedResponseUser(modifiedData.assignedUsers);
    modifiedData = {
      ...modifiedData,
      assignedUsers
    };
  }
  if (modifiedData.approvers && modifiedData.approvers.length > 0) {
    const approvers = getModifiedResponseUser(modifiedData.approvers);
    modifiedData = {
      ...modifiedData,
      approvers
    };
  }
  return modifiedData;
};

export const getNextChapter = (filterMenu, currentFilter) => {
  let nextFilter = '';
  filterMenu.forEach((item, index) => {
    if (item.subMenu) {
      const indexValue = item.subMenu.findIndex(subItem => subItem.key === currentFilter);
      if (indexValue !== -1 && indexValue + 1 === item.subMenu.length) {
        nextFilter = filterMenu[index + 1].defaultFilter;
      } else if (indexValue !== -1) {
        nextFilter = item.subMenu[indexValue + 1].key;
      }
    } else if (item.defaultFilter === currentFilter) {
      nextFilter = filterMenu[index + 1].defaultFilter;
    }
  });
  return nextFilter;
};

export const getPreviousChapter = (filterMenu, currentFilter) => {
  let prevFilter = '';
  filterMenu.forEach((item, index) => {
    if (item.subMenu) {
      const indexValue = item.subMenu.findIndex(subItem => subItem.key === currentFilter);
      if (indexValue !== -1 && indexValue - 1 === -1) {
        prevFilter = filterMenu[index - 1].defaultFilter;
      } else if (indexValue !== -1) {
        prevFilter = item.subMenu[indexValue - 1].key;
      }
    } else if (item.defaultFilter === currentFilter) {
      prevFilter = filterMenu[index - 1].defaultFilter;
    }
  });
  return prevFilter;
};

export const transformRequestData = (data) => {
  let modifiedData = data;
  if (modifiedData.requestTypes && modifiedData.requestTypes.length > 0) {
    modifiedData = {
      ...modifiedData,
      requestTypes: modifiedData.requestTypes.map(item => (item.value &&
        item.value.data ? item.value.data : item.data))
    };
  }
  if (modifiedData.identityVerification) {
    const identityVerification = {
      ...modifiedData.identityVerification,
      status: modifiedData.identityVerification.status || false
    };
    modifiedData = {
      ...modifiedData,
      identityVerification
    };
  }

  if (modifiedData.dataSubject) {
    modifiedData = {
      ...modifiedData,
      dataSubject: {
        ...modifiedData.dataSubject,
        value: { id: modifiedData.dataSubject.value.id }
      }
    };
  }

  if (modifiedData.isCancelled) {
    modifiedData = {
      ...modifiedData,
      status: 'dsr_status_cancelled'
    };
  }
  if (modifiedData.assignedOrganisations && modifiedData.assignedOrganisations.length > 0) {
    const assignedOrganisations = modifiedData.assignedOrganisations.map(item => (
      { value: { id: item.value ? item.value.id : item.id } }));
    modifiedData = {
      ...modifiedData,
      assignedOrganisations
    };
  }

  if (modifiedData.assignedUsers && modifiedData.assignedUsers.length > 0) {
    const assignedUsers = getModifiedRequestUser(modifiedData.assignedUsers);
    modifiedData = {
      ...modifiedData,
      assignedUsers
    };
  }
  if (modifiedData.approvers && modifiedData.approvers.length > 0) {
    const approvers = getModifiedRequestUser(modifiedData.approvers);
    modifiedData = {
      ...modifiedData,
      approvers
    };
  }
  if (modifiedData.validations && modifiedData.validations.length > 0) {
    const validations = modifiedData.validations.filter(item => item.status !== '');
    modifiedData = {
      ...modifiedData,
      validations
    };
  }
  return modifiedData;
};

const getModifiedResponseUser = (users) => {
  const modifiedUsers = users.map(item => ({
    ...item,
    value: { ...item.value,
      key: `${item.value.firstName}${item.value.lastName ? ` ${item.value.lastName}` : ''}` }
  }));
  return modifiedUsers;
};
const getModifiedRequestUser = (users) => {
  const modifiedUsers = users.map((item) => {
    if (item.approvalInfo) {
      return ({
        ...item,
        approvalInfo: {
          ...item.approvalInfo,
          approvalDate: (new Date(item.approvalInfo.approvalDate))
        },
        value: { id: item.value ? item.value.id : item.id },
        messagesInfo: item.messagesInfo || []
      });
    }
    return ({
      ...item,
      value: { id: item.value ? item.value.id : item.id },
      messagesInfo: item.messagesInfo || []
    });
  });
  return modifiedUsers;
};


export const getModifiedFilterMenu = (filterMenu, nextFilter, value) => {
  let modifiedFilter = filterMenu;
  modifiedFilter = filterMenu.map((item) => {
    if (item.key === nextFilter) return ({ ...item, enable: value });
    return item;
  });
  return modifiedFilter;
};

export const setModifiedStatus = (filterMenu, value, filterValue, isJustified) => {
  const newFilter = [...filterMenu];
  const menuValues = filterValue.split('_');
  const currentMenu = menuValues.length === 1 ? filterValue : menuValues[1];
  const currentSubMenu = ((menuValues.length > 1 && menuValues[1] === 'ValidateRequest') ? filterValue : '');

  const index = filterMenu && filterMenu.findIndex(item => item.key === currentMenu);
  const item = index && filterMenu[index];
  if (currentSubMenu) {
    const subIndex = item.subMenu && item.subMenu.findIndex(subItem => subItem.key === currentSubMenu);
    if (subIndex !== -1) {
      const copyOfSubmenu = { ...newFilter[index].subMenu[subIndex] };
      const modifiedSubmenu = {
        ...copyOfSubmenu,
        showIcon: value,
        isJustified
      };
      newFilter[index].subMenu.splice(subIndex, 1, modifiedSubmenu);
      const checkStatus = item.subMenu.some(subValue => subValue.showIcon === false);
      newFilter[index].showIcon = !checkStatus;
    }
  } else if (newFilter[index]) {
    newFilter[index].showIcon = value;
  }
  return newFilter;
};

export const updateSubmenus = (filterMenu, parentKey, children) => {
  const parentIndex = filterMenu.findIndex(menu => menu.key === parentKey);
  if (parentIndex !== -1) {
    const parentMenu = { ...filterMenu[parentIndex] };
    const modifiedParent = {
      ...parentMenu,
      defaultFilter: children[0].key,
      subMenu: [...children]
    };
    filterMenu.splice(parentIndex, 1, modifiedParent);
    return filterMenu;
  } return filterMenu;
};
