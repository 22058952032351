import {
  recordTranslations,
  commonTranslations
} from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import { legalGroundTranslations } from '@packages/features/privacy-record-detail/recordTranslations';

import { sectionHeaderTranslations } from '../../../../environment/masterDataTranslations';

const visibleFieldList = [
  {
    label: recordTranslations.generalSection,
    key: 'general',
    subFields: [
      {
        key: 'Purposes',
        isCommon: true,
        label: sectionHeaderTranslations.purposes
      },
      {
        key: 'ProcessingCategories',
        isCommon: true,
        label: uppercasePlural('processingCategories')
      },
      {
        key: 'LinkedRecords',
        label: recordTranslations.linkedRecords
      },
      {
        key: 'References',
        isCommon: true,
        label: uppercasePlural('references')
      }
    ]
  },
  {
    label: recordTranslations.Stakeholders,
    key: 'stakeholders',
    subFields: [
      {
        key: 'Controllers',
        isCommon: true,
        label: uppercasePlural('controllers')
      },
      {
        key: 'Processors',
        isCommon: true,
        label: recordTranslations.processors
      },
      {
        key: 'DPO',
        // isCommon: true,
        label: recordTranslations.dpoHeader
      },
      {
        key: 'ExecutingEntities',
        isCommon: true,
        label: recordTranslations.executingEntities
      },
      {
        key: 'DataRecipients',
        isCommon: true,
        label: uppercasePlural('dataRecipients')
      },
      {
        key: 'DataRecipientCategory',
        isCommon: true,
        label: uppercasePlural('dataRecipientCategories')
      },
      {
        key: 'NumberOfDataSubjects',
        label: recordTranslations.numOfDataSubjects
      }
    ]
  },
  {
    label: recordTranslations.processedData,
    key: 'processedData',
    subFields: [
      {
        key: 'DataSubjectCategories',
        isCommon: true,
        label: uppercasePlural('dataSubjectCategories')
      },
      {
        key: 'PersonalDataCategories',
        isCommon: true,
        label: uppercasePlural('personalDataCategories')
      },
      {
        key: 'PersonalDataItems',
        isCommon: true,
        label: uppercasePlural('personalDataItems')
      },
      {
        key: 'DataSourceCategories',
        isCommon: true,
        label: uppercasePlural('dataSourceCategories')
      },
      {
        key: 'DataSources',
        isCommon: true,
        label: uppercasePlural('dataSources')
      },
      {
        key: 'RetentionTerms',
        isCommon: true,
        label: recordTranslations.retentionTerms
      }
    ]
  },
  {
    label: recordTranslations.securityControls,
    key: 'securityMeasures',
    subFields: [
      {
        key: 'TechnicalSecurityMeasures',
        isCommon: true,
        label: sectionHeaderTranslations.technicalSecurityMeasuresHeader
      },
      {
        key: 'OrganisationalSecurityMeasures',
        isCommon: true,
        label: sectionHeaderTranslations.organisationalMeasuresHeader
      }
    ]
  },
  {
    label: recordTranslations.legalQualifications,
    key: 'legalQualifications',
    subFields: [
      {
        key: 'Commentaries',
        label: recordTranslations.purposeCommentary
      },
      {
        key: 'ProcessingGrounds',
        isCommon: true,
        label: recordTranslations.processingGrounds
      },
      {
        key: 'InternationalTransfers',
        isCommon: true,
        label: recordTranslations.internationalTransfers
      }
    ]
  },
  {
    label: recordTranslations.attachments,
    key: 'attachments',
    subFields: [
      {
        key: 'Attachments',
        isCommon: true,
        label: recordTranslations.attachments
      }
    ]
  },
  {
    label: legalGroundTranslations.permissions,
    key: 'attachmentsPermission',
    subFields: [
      {
        key: 'UserPermissions',
        isCommon: true,
        label: commonTranslations.users
      },
      {
        key: 'OrganisationPermissions',
        isCommon: true,
        label: uppercasePlural('organisations')
      },
      {
        key: 'CompanyAccess',
        isCommon: true,
        label: recordTranslations.companyAccess
      }
    ]
  }
];

export const defaultAdminOpenPrivileges = [
  'create-edit-organisation',
  'create-edit-user',
  'upload-document'
];

export const BUclosedPrivileges = [
  'create-edit-user',
  'create-edit-vendor-forms'
];

export default visibleFieldList;
