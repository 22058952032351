import { combineReducers } from 'redux';

import columns from './columns';
import list from './list';
import filters from './filters';
import templates from './templates';
import bulkUpdate from './bulkUpdates';
import tias from './tias';
import hierarchy from './hierarchy';

export default combineReducers({
  columns,
  list,
  filters,
  templates,
  bulkUpdate,
  tias,
  hierarchy
});
