import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { withTheme } from '@material-ui/core/styles';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  renderTextField,
  renderSelectField
} from '@packages/components/form-components';
import {
  commonTranslations,
  recordTranslations
} from '@packages/utils/commontranslations';
import { pluralTerms as uppercasePlural } from '@packages/utils/uppercaseTranslations';
import errortranslations from '@packages/utils/errortranslations';

import vendorTranslations from '@packages/utils/vendorTranslations';
import { getDefaultLayoutId } from '@packages/features/privacy-record-detail/common-utils';
import styles from '../../styles';
import { validate } from '../../utils';
import recordTemplateSelector from '../record-template-selector';

export const filterByTypeMapper = (type) => uppercasePlural(type);

const defaultStyle = {
  templateDiv: {
    height: '50px',
    cursor: 'pointer',
    backgroundColor: 'rgb(239, 239, 239)'
  },
  iconStyle: {
    padding: '0px',
    height: '24px',
    color: 'red',
    width: '15px',
    marginRight: '10px',
    cursor: 'pointer'
  },
  confirmDialogStyle: {
    width: '400px',
    maxWidth: 'none'
  },
  searchField: {
    paddingLeft: '5px',
    height: '40px',
    backgroundColor: '#ffffff',
    width: '55%',
    border: '1px solid #9b9b9b',
    borderRadius: '3px'
  },
  sortButton: {
    marginRight: '0px',
    marginBottom: '5px',
    width: '30px',
    height: '30px',
    padding: '8px'
  },
  selectTemplateStyle: {
    marginBottom: 10,
    display: 'flex'
  },
  searchBoxStyle: {
    paddingBottom: 20,
    paddingTop: 40,
    display: 'flex'
  },
  advancedSearchStyle: {
    marginLeft: 5,
    marginBottom: 5,
    fontSize: 40,
    cursor: 'pointer'
  },
  radioGrpStyle: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioWidth: {
    width: '30%'
  }
};

export const CreateRecordLayout = (props) => {
  const {
    hintTextLabel,
    label,
    isUpdating,
    handleSubmit,
    onRequestClose,
    actionError,
    createFromEdit,
    subRecordTypes,
    hintTextCustomValue,
    userPermissions,
    createRecord,
    initialize,
    toggleLoader,
    data,
    recordType,
    layoutId,
    isFetching,
    isVendor,
    hasSubRecords,
    change,
    isTemplateMode,
    currentFilter
  } = props;

  const { createEditCustomFieldData, viewTemplate } = userPermissions.toJS();

  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [currentAssessmentType, setAssessmentType] = useState(null);
  const [currentSubRecordType, setCurrentSubRecordType] = useState(null);

  useEffect(() => {
    initialize(data);
  }, []);

  useEffect(() => {
    change('subRecordTypes', subRecordTypes?.[0]);
    setCurrentSubRecordType(subRecordTypes?.[0]);
    if (['tia', 'dpia'].includes(subRecordTypes?.[0]?.uniqueId))
      setAssessmentType(subRecordTypes?.[0]?.uniqueId);
  }, [subRecordTypes]);

  useEffect(() => {
    change('template', selectedTemplate);
  }, [selectedTemplate]);

  useEffect(() => {
    const content = (
      <FormattedMessage
        id="CreateRecord.loadingMessage"
        description="Loading message while creating record"
        defaultMessage="Please wait while the privacy record is being prepared..."
      />
    );
    toggleLoader(isUpdating, content);
  }, [isUpdating]);

  useEffect(() => {
    toggleLoader(isFetching);
  }, [isFetching]);

  const handleTemplateSelect = (data) => {
    setSelectedTemplate(data);
  };

  const onCheckTemplate = (checked) => {
    setSelectedTemplate([]);
    setShowTemplate(checked);
  };

  const handleSubRecordChange = (value) => {
    change('subRecordTypes', value);
    setAssessmentType(value.uniqueId);
    setCurrentSubRecordType(value);
    onCheckTemplate(false);
  };

  const handleSubmitForm = (formData) => {
    createRecord(
      { ...data, name: formData.name },
      selectedTemplate[0]?.value?.id,
      currentAssessmentType || recordType,
      layoutId ||
        currentSubRecordType?.id ||
        getDefaultLayoutId(currentAssessmentType || recordType),
      isTemplateMode
    );
  };

  const showSubRecordTypes =
    currentFilter === 'all' && (createEditCustomFieldData || hasSubRecords);

  const modifiedRecordType =
    currentAssessmentType?.includes('tia') || recordType.includes('tia')
      ? 'tias'
      : recordType.split('_')[1] || recordType;

  return (
    <div>
      <form>
        <div style={{ display: 'table', width: '100%' }}>
          <div style={{ width: '50%', float: 'left' }}>
            <Field
              name="name"
              id="record_name"
              style={{ width: '98%' }}
              hintTextLabel={hintTextLabel}
              hintTextCustomValue={hintTextCustomValue}
              component={renderTextField}
            />
          </div>
        </div>
        {showSubRecordTypes && (
          <div style={{ width: '50%' }}>
            <Field
              id="subRecordTypes"
              name="subRecordTypes"
              label={recordTranslations.selectSubRecordType}
              component={renderSelectField}
              style={{ width: '98%' }}
              value={currentSubRecordType}
              onChange={handleSubRecordChange}
            >
              {!subRecordTypes?.length && (
                <MenuItem value="" disabled={true}>
                  <em>{recordTranslations.noActiveLayout}</em>
                </MenuItem>
              )}
              {subRecordTypes?.map((item) => (
                <MenuItem id={item.id} key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Field>
          </div>
        )}
        {viewTemplate && !createFromEdit && !isTemplateMode && (
          <div style={{ marginTop: 20 }}>
            <div style={defaultStyle.selectTemplateStyle}>
              <div style={{ width: ' 50%' }}>
                <div>
                  <FormControlLabel
                    control={<Radio style={styles.checkboxStyle} />}
                    value={false}
                    label={label}
                    labelStyle={{ fontWeight: 'bold' }}
                    checked={!showTemplate}
                    onChange={() => onCheckTemplate(false)}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <FormControlLabel
                    control={<Radio style={styles.checkboxStyle} />}
                    value={true}
                    label={recordTranslations.selectTemplate}
                    labelStyle={{ fontWeight: 'bold' }}
                    checked={showTemplate}
                    onChange={() => onCheckTemplate(true)}
                  />
                </div>
              </div>
            </div>
            {showTemplate && (
              <FieldArray
                id="template"
                name="template"
                component={recordTemplateSelector}
                maxCount={1}
                styles={{ width: '50%', marginTop: 15 }}
                recordType={modifiedRecordType}
                layoutId={layoutId || currentSubRecordType?.id}
                updateTemplateList={handleTemplateSelect}
              />
            )}
          </div>
        )}
        <div style={{ marginTop: 30 }}>
          {actionError && actionError.get('isError') && (
            <div
              style={Object.assign({}, styles.errorMessage, {
                marginBottom: 10
              })}
            >
              {errortranslations.error}: {actionError.get('message')}
            </div>
          )}
          {isVendor && (
            <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
              {vendorTranslations.newTabConfirmation}
            </div>
          )}
          <Button
            id="handle_submit_button"
            disabled={
              isUpdating || (showSubRecordTypes && !currentSubRecordType)
            }
            type="submit"
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {commonTranslations.createNow}
          </Button>
          <Button id="cancel_button" variant="text" onClick={onRequestClose}>
            {commonTranslations.Cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};

CreateRecordLayout.propTypes = {
  isUpdating: PropTypes.bool,
  isFetching: PropTypes.bool,
  createFromEdit: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.shape({ name: PropTypes.string }),
  hintTextLabel: PropTypes.string,
  initialize: PropTypes.func,
  createRecord: PropTypes.func,
  handleSubmit: PropTypes.func,
  onRequestClose: PropTypes.func,
  toggleLoader: PropTypes.func,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  isVendor: PropTypes.bool,
  recordType: PropTypes.string,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  subRecordTypes: PropTypes.arrayOf(
    PropTypes.shape({
      uniqueId: PropTypes.string
    })
  ),
  layoutId: PropTypes.string,
  hintTextCustomValue: PropTypes.string,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  change: PropTypes.func,
  hasSubRecords: PropTypes.bool,
  isTemplateMode: PropTypes.bool,
  currentFilter: PropTypes.string
};

CreateRecordLayout.defaultProps = {
  isUpdating: false,
  isFetching: false,
  theme: {},
  createFromEdit: false,
  label: null,
  data: {},
  hintTextLabel: null,
  initialize: (e) => e,
  createRecord: (e) => e,
  handleSubmit: (e) => e,
  onRequestClose: (e) => e,
  toggleLoader: (e) => e,
  isVendor: false,
  recordType: '',
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  intl: {
    formatMessage: (e) => e
  },
  subRecordTypes: [],
  layoutId: '',
  hintTextCustomValue: '',
  userPermissions: Immutable.Map({}),
  change: (e) => e,
  hasSubRecords: false,
  isTemplateMode: false,
  currentFilter: ''
};

const CreateRecordLayoutWrapper = reduxForm({
  form: 'CreateRecordLayout',
  validate
})(CreateRecordLayout);

export default withTheme(injectIntl(CreateRecordLayoutWrapper));
