import { connect } from 'react-redux';

import { NO_LIMIT } from '@packages/utils/common-utils';

import CustomMasterData from './customMasterData';

const mapStateToProps = (state) => ({
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT,
  customDataValue: state.customMasterDataList.get('customDataValue'),
  actionError: state.customMasterDataList.get('error').toJS()
});

const mapDispatchToProps = (dispatch) => ({
  init: (id, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH',
      id,
      entityType
    });
  },
  updateCustomMasterData: (
    data,
    isEdit,
    source,
    isUsed,
    entityType,
    label,
    isEditWithJob
  ) => {
    if (isUsed && isEditWithJob) {
      dispatch({
        type: 'MASTER_DATA_ITEM:CREATE:JOB',
        data,
        actionType: 'Edit',
        entityType: 'CustomSimpleMasterData'
      });
    } else {
      dispatch({
        type: 'CUSOM:MASTER_DATA:LIST:UPSERT',
        data,
        isEdit,
        source,
        entityType,
        label
      });
    }
  },
  resetCustomSimpleData: () => {
    dispatch({ type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:FETCH:INIT' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMasterData);
