import Immutable from 'immutable';

const defaultState = Immutable.Map({
  showHideLoader: false,
  content: undefined,
  handleAbort: undefined
});

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOADER:INIT':
      return state.set('showHideLoader', false);
    case 'LOADER:TOGGLE':
      return state.set('showHideLoader', action.toggle)
        .set('content', action.content)
        .set('handleAbort', action.handleAbort);
    case 'LOADER:SHOW':
      return state.set('showHideLoader', true);
    case 'LOADER:HIDE':
      return state.set('showHideLoader', false);
    default:
      return state;
  }
};

export default reducer;
