const addAptVerticesAndEdges = (data) => {
  let transformedData = {
    vertices: [],
    edges: []
  };

  // get all relations from edges
  const relations = Array.from(new Set(data && data.edges && data.edges.map(edge => edge.relation)));

  // filter out subprocessor and de-link them from PrivacyRecord
  if (relations.includes('ProcessorRelation')) {
    const subProcessorEdges = data.edges.filter(edge => edge.relation === 'ProcessorRelation');
    subProcessorEdges.forEach((subProcessor) => {
      const index = data.edges.findIndex(edge =>
        edge.relation === 'Processor' && edge.source === `PrivacyRecord/${data.id}`
        && edge.target === subProcessor.target);
      data.edges.splice(index, 1);
    });
    relations.splice(relations.findIndex(r => r === 'ProcessorRelation'), 1);
  }

  // add nodes for relations
  const addtlNodes = relations.map(relation => ({
    id: relation,
    type: relation,
    name: relation,
    dataNode: false
  }));

  // change the source and target to hold the parent subgroup
  const addtlEdges = relations.map(relation => ({
    source: `PrivacyRecord/${data.id}`,
    target: relation
  }));

  // filtering out only those nodes which has edges.
  let modifiedNodes = data && data.nodes &&
  data.nodes.filter(node =>
    data.edges.some(edge => edge.target === node.id) || node.id === `PrivacyRecord/${data.id}`);

  // add dataNode=true for existing nodes
  modifiedNodes = modifiedNodes.map(node => ({
    ...node,
    dataNode: true
  }));

  const modifiedEdges = data && data.edges && data.edges.map(edge => (
    edge.source && edge.source.includes(data.id) ?
      { source: edge.relation, target: edge.target } : { source: edge.source, target: edge.target }));

  transformedData = {
    ...data,
    vertices: [...modifiedNodes, ...addtlNodes],
    edges: [...modifiedEdges, ...addtlEdges]
  };
  return transformedData;
};

const addRootWithDepth = (data) => {
  const visualViewData = Object.assign({}, data);
  const rootNode = visualViewData.vertices &&
    visualViewData.vertices.find(vertex => vertex.id.includes(visualViewData.id));
  if (rootNode) {
    rootNode.dataNode = 'root';
    visualViewData.name = rootNode.name;
    visualViewData.root = rootNode.id;

    addDepthToConnectedVertices(visualViewData, rootNode, 1);
  }
  return visualViewData;
};

const addDepthToConnectedVertices = (data, sourceVertex, level) => {
  // breadth first traversal for assigning levels
  let tempSourceVertex = sourceVertex;
  let tempLevel = level;
  const verticesToProcess = [];
  while (tempSourceVertex) {
    if (!tempSourceVertex.level) {
      tempSourceVertex.level = tempLevel;
      /* eslint-disable no-loop-func */
      data.edges.filter(link => link.source === tempSourceVertex.id)
        /* eslint-disable no-loop-func */
        .forEach((connectedLink) => {
          const targetVertex = data.vertices
            .find(vertex => (connectedLink.source === tempSourceVertex.id ?
              vertex.id === connectedLink.target : vertex.id === connectedLink.source));
          if (!targetVertex.level) {
            verticesToProcess.push([targetVertex, tempSourceVertex.level + 1]);
          }
        });
    }
    const nextProcessing = verticesToProcess.shift();
    [tempSourceVertex, tempLevel] = nextProcessing || [null, 1];
  }
};

export default (data) => {
  let visualViewData = Object.assign({}, data);
  visualViewData = addAptVerticesAndEdges(visualViewData);
  visualViewData = addRootWithDepth(visualViewData);
  return visualViewData;
};
