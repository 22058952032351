import registry from 'app-registry';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import {
  handleServiceDown, MESSAGES_FETCH_LIMIT, MESSAGES_SEARCH_LIMIT,
  MASTERDATA_FETCH_LIMIT, MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import errortranslations from '@packages/utils/errortranslations';

import messageTranslations from '../../messageTranslations';

export function* resetAndFetchMessages(action) {
  const { currentSubmenu } = action;
  yield put({ type: `MESSAGES:${currentSubmenu.toUpperCase()}:LIST:INIT` });
  yield fetchMessages(action);
}

export function* fetchMessages(action) {
  const { rowCount = MESSAGES_FETCH_LIMIT, currentSubmenu } = action;
  const reducerType = currentSubmenu.toUpperCase();
  yield put({ type: `MESSAGES:${reducerType}LIST:FETCH` });

  try {
    const messageState = yield select(state => state.messages[currentSubmenu]);
    let filterParams = {};
    let searchTextObj = {};
    let searchParameters = {};
    if (messageState) {
      filterParams = messageState.get('filterParams').toJS();
      searchTextObj = messageState.get('searchText').toJS();
      searchParameters = searchTextObj ? { searchKey: 'allFields', searchText: searchTextObj.allFields } : {};
    }

    const { sortOn, sortOrder } = filterParams;
    const filteredOn = messageState ? messageState.get('filteredOn') : Immutable.Map();
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn },
        action.position, searchParameters, rowCount, currentSubmenu), null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = getModifiedMessages(items, currentSubmenu);
        yield put({ type: `MESSAGES:${reducerType}:LIST:FETCH:SUCCESS`, items: modifiedItems });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: `MESSAGES:${reducerType}:LIST:FETCH:FAIL`, error: err.message });
  }
}

const getModifiedMessages = items => (items.map(item => ({
  ...item,
  content: item.content?.replace(/target='_blank'/g, `target='_blank' rel='noopener noreferrer'`),
  actualSender: { ...item.sender, key: `${item.sender.firstName} ${item.sender.lastName}` },
  sender: `${item.sender.firstName} ${item.sender.lastName}`,
  actualRecipientList: item.recipients,
  recipients: getModifiedRecipients(item.recipients)
})));

const getModifiedRecipients = (recipients) => {
  const recipientUsers = [];
  // eslint-disable-next-line array-callback-return
  recipients.map((item) => {
    recipientUsers.push(`${item.firstName} ${item.lastName}`);
  });
  return recipientUsers.join(', ');
};

export function* filterMessages(action) {
  const { filterParams = {}, appendFilter = true, currentSubmenu } = action;
  const reducerType = currentSubmenu.toUpperCase();
  yield put({ type: `MESSAGES:${reducerType}:LIST:FILTER`, filterParams, appendFilter });
  if (appendFilter) yield fetchMessages(action);
}

export function* searchMessages(action) {
  const { searchParams: { searchKey },
    searchParams, rowCount = MESSAGES_SEARCH_LIMIT, currentSubmenu = '' } = action;
  const reducerType = currentSubmenu.toUpperCase();
  const messageState = yield select(state => state.messages[currentSubmenu]);

  const { sortOn, sortOrder, filteredOn, searchParameters, searchText } =
    getFilterAndSearchParamsFromState(messageState, searchParams);
  yield put({ type: `MESSAGES:${reducerType}:LIST:SEARCH`, searchKey, searchText });
  try {
    const response = yield registry.get('request')
      .get(getURLWithParams({ sortOn, sortOrder, filteredOn }, action.position, searchParameters,
        rowCount, currentSubmenu), null, {});
    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = getModifiedMessages(items, currentSubmenu);

        yield put({
          type: `MESSAGES:${reducerType}:LIST:SEARCH:SUCCESS`,
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: `MESSAGES:${reducerType}:LIST:SEARCH:FAIL`, error: err.message });
  }
}

const getFilterAndSearchParamsFromState = (messageState, searchParams) => {
  // Filter parameters
  let filterParams = {};
  let searchTextObj = {};
  if (messageState) {
    searchTextObj = messageState.get('searchText').toJS();
    filterParams = messageState.get('filterParams').toJS();
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = messageState ? messageState.get('filteredOn') : Immutable.Map();

  // Search parameters
  let searchParameters = {};
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    // For first time rendering of search items, searchText will not be defined
    searchText = searchText !== undefined ? searchText : searchTextObj.name;
    searchParameters = { ...searchParams, searchText };
  } else {
    searchParameters = searchTextObj ? { searchKey: 'name', searchText: searchTextObj.name } : {};
  }

  return ({ sortOn, sortOrder, filteredOn, searchParameters, searchText });
};

export const getURLWithParams =
  (filterParams, position = 0, searchParams, rowCount, currentSubmenu) => {
    let url = currentSubmenu !== 'sent' ? `/v1/messages?offset=${position}` : `/v1/messages/sent?offset=${position}`;
    if (rowCount) {
      url = `${url}&numberOfResults=${rowCount}`;
    }
    if (currentSubmenu === 'unRead') {
      url = `${url}&filter=isRead=false`;
    }
    if (filterParams) {
      const { filteredOn, sortOn, sortOrder } = filterParams;
      filteredOn.keySeq().forEach((filterKey) => {
        const filteredOnItem = filteredOn.get(filterKey);
        // If the filterKey is timestamp, the filtering should be done
        // seperately for fromDate and toDAte
        if (filterKey === 'createdDate') {
          if (filteredOnItem.fromDate) {
            const date = JSON.parse(JSON.stringify(filteredOnItem.fromDate));
            (url = `${url}&filter=fromDate%3D${encodeURIComponent(date)}`);
          }
          if (filteredOnItem.toDate) {
            const date = JSON.parse(JSON.stringify(filteredOnItem.toDate));
            (url = `${url}&filter=toDate%3D${encodeURIComponent(date)}`);
          }
        } else if (filteredOnItem && filteredOnItem.length > 0) {
          filteredOnItem.forEach((x) => {
            // FIXME: Record types should be made lower case in BE
            const filterText = (typeof (x) === 'object') ? x.props.defaultMessage : x;
            (url = `${url}&filter=${filterKey}%3D${encodeURIComponent(filterText)}`);
          });
        }
      });
      url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
    }
    if (searchParams) {
      const { searchText, sortOn, sortOrder, searchKey } = searchParams;
      url = searchText ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}` : url;
      url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
    }
    return url;
  };

export function* resetAndFetchUsers(action) {
  const { currentSubmenu } = action;
  const reducerType = currentSubmenu.toUpperCase();
  yield put({ type: `MESSAGES:${reducerType}:USERS:LIST:INIT` });
  yield getusers(action);
}
export function* getusers(action) {
  const { rowCount = MASTERDATA_FETCH_LIMIT, position = 0, currentSubmenu, persistSearch = true } = action;
  const reducerType = currentSubmenu.toUpperCase();
  yield put({ type: `MESSAGES:${reducerType}:USERS:LIST:FETCH` });

  try {
    const messageState = yield select(state => state.messages[currentSubmenu]);
    let searchTextValue = '';
    if (messageState) {
      const userObject = messageState.get('users').toJS();
      searchTextValue = userObject.searchText;
    }
    let url = `/v1/messages/recipients?offset=${position}&numberOfResults=${rowCount}&filter=status=Registered`;
    if (searchTextValue !== '' && persistSearch) {
      url = `${url}&search=name=${encodeURIComponent(searchTextValue)}`;
    }
    const response = yield registry.get('request')
      .get(url, null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => transformUserItem(item));
        yield put({
          type: `MESSAGES:${reducerType}:USERS:LIST:FETCH:SUCCESS`,
          items: modifiedItems.filter(item => item.label !== '')
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: `MESSAGES:${reducerType}:USERS:LIST:FETCH:FAIL`, error: err.message });
  }
}

export function* searchUsers(action) {
  const { rowCount = MASTERDATA_SEARCH_LIMIT, position = 0, searchParams = undefined, currentSubmenu } = action;
  const reducerType = currentSubmenu.toUpperCase();
  try {
    const messageState = yield select(state => state.messages[currentSubmenu]);
    let searchTextValue = '';
    if (searchParams) {
      searchTextValue = searchParams.searchText;
      yield put({ type: `MESSAGES:${reducerType}:USERS:LIST:SEARCH`, searchText: searchTextValue });
    } else if (messageState) {
      const userObject = messageState.get('users').toJS();
      searchTextValue = userObject.searchText;
    }
    let url = `/v1/messages/recipients?offset=${position}&numberOfResults=${rowCount}&filter=status=Registered`;
    url = `${url}&search=name=${encodeURIComponent(searchTextValue)}`;
    const response = yield registry.get('request')
      .get(url, null, {});

    switch (response.status) {
      case 200: {
        const items = response.body;

        // Add a key element which is used to display the object in list view
        const modifiedItems = items.map(item => transformUserItem(item));
        yield put({
          type: `MESSAGES:${reducerType}:USERS:LIST:SEARCH:SUCCESS`,
          items: modifiedItems.filter(item => item.label !== '')
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'messages');
    registry.get('logger').error(err);
    yield put({ type: `MESSAGES:${reducerType}:USERS:LIST:SEARCH:FAIL`, error: err.message });
  }
}
const transformUserItem = item => ({
  ...item,
  label: item.firstName ?
    `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}` : '',
  key: item.firstName ?
    `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}` : '',
  value: item.id
});


export function* bulkDeleteMessages(action) {
  const { items, currentSubmenu } = action;
  const request = registry.get('request');
  try {
    const url = currentSubmenu !== 'sent' ? `/v1/messages/remove` :
      `/v1/messages/sent/remove`;
    const entityIds = [];
    items.forEach((item) => {
      entityIds.push(item.id);
    });
    const requestData = { entityIds };
    const response = yield request.post(url, requestData);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.BulkDeleteSuccess,
            type: 'success'
          }
        });
        yield resetAndFetchMessages(action);
        yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
  }
}

export function* markReadOrUnreadMessages(action) {
  const { items = undefined, markAsRead, selectedItemId, currentSubmenu } = action;
  const request = registry.get('request');
  try {
    const entityIds = [];
    if (items) {
      items.forEach((item) => {
        entityIds.push(item.id);
      });
    } else {
      entityIds.push(selectedItemId);
    }
    const isReadStatusUpdate = currentSubmenu === 'unRead' && markAsRead;
    const requestData = { entityIds, readStatus: markAsRead };
    const response = yield request.post(`/v1/messages/update-status`, requestData);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.bulkStatusUpdate,
            type: 'success'
          }
        });
        if (items) {
          yield resetAndFetchMessages(action);
          yield put({ type: 'MESSAGE:CENTER:UNREAD:COUNT' });
          if (isReadStatusUpdate) {
            yield put({ type: `MESSAGES:${currentSubmenu.toUpperCase()}:BULK:ITEMS:RESET` });
          }
        } else {
          yield updateMessages({
            type: 'updateStatus',
            currentSubmenu,
            data: response.body[0]
          });
          if (isReadStatusUpdate) {
            yield put({ type: `MESSAGES:${currentSubmenu.toUpperCase()}:ITEM:CHECK`, dataItemId: selectedItemId });
          }
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
  }
}

export function* deleteMessage(action) {
  const { dataItemId, currentSubmenu } = action;
  const request = registry.get('request');
  try {
    const url = currentSubmenu !== 'sent' ? `/v1/messages/${dataItemId}` :
      `/v1/messages/sent/${dataItemId}`;
    const response = yield request.delete(url, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.deleteSuccess,
            type: 'success'
          }
        });
        // Handle consistency in the front end.
        yield updateMessages({
          type: 'delete',
          currentSubmenu,
          messageId: dataItemId
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
  }
}

function* updateMessages(action) {
  const { currentSubmenu } = action;
  const reducerType = currentSubmenu.toUpperCase();
  const messageState = yield select(state => state.messages[currentSubmenu]);
  let position = null;
  let items = messageState ? messageState
    .get('items') : Immutable.List();
  const { type, messageId, data } = action;
  if (type === 'delete') {
    items = updateMessageList(items, type, messageId);

    position = messageState.get('position') - 1;
  } else if (type === 'updateStatus' && currentSubmenu === 'unRead') {
    items = items.filter(item => item.id !== data.id);
  } else {
    const modifiedItem = {
      ...data,
      sender: `${data.sender.firstName} ${data.sender.lastName}`,
      actualSender: { ...data.sender, key: `${data.sender.firstName} ${data.sender.lastName}` },
      actualRecipientList: data.recipients,
      recipients: getModifiedRecipients(data.recipients)
    };
    items = updateMessageList(items, type, data.id, modifiedItem);
  }
  yield put({ type: `MESSAGES:${reducerType}:LIST:UPDATE:SUCCESS`, items, position });
}

export const updateMessageList = (items, type, currentId, modifiedItem) => {
  const index = items.findIndex(item => item.id === currentId);
  if (index !== -1) {
    const updatedItems = (type === 'updateStatus')
      ? items.set(index, modifiedItem) : items.remove(index);
    return updatedItems;
  }
  return items;
};

export function* createNewMessage(action) {
  const { data, currentSubmenu, actionType } = action;
  const request = registry.get('request');
  let response;
  try {
    if (actionType !== 'create') {
      response = yield request.post(`/v1/messages/${data.id}/${actionType}`, data);
    } else {
      response = yield request.post(`/v1/messages`, data);
    }
    yield response;
    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: messageTranslations.successMessageCreation,
            type: 'success'
          }
        });
        if (currentSubmenu === 'DSR') {
          yield put({ type: 'DSR:MESSAGE:SENT:SUCCESS', data: response.body });
        } else {
          yield resetAndFetchMessages(action);
        }
        break;
      }
      case 409:
      case 412: {
        yield put({ type: `MESSAGES:${currentSubmenu.toUpperCase()}:CREATION:FAIL`, error: response.body.msg });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
    yield put({ type: `MESSAGES:${currentSubmenu.toUpperCase()}:CREATION:FAIL`, error: err.message });
  }
}

export function* downloadAttachedDocument(action) {
  const request = registry.get('request');
  try {
    const { id, currentSubmenu } = action;
    let response = {};
    if (currentSubmenu === 'DSR') {
      response = yield request.getDocument(`/v1/documents/dsr/${id}/download`);
    } else {
      response = yield request.getDocument(`/v1/documents/messages/${id}/download`);
    }
    yield response;
    switch (response.status) {
      case 200: {
        break;
      }
      case 404: {
        const error = errortranslations.attachmentUnavailable;
        yield put({ type: 'ATTACHED:DOCUMENT:DOWNLOAD:FAIL', error });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: errortranslations.attachmentUnavailable,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
    yield put({ type: 'ATTACHED:DOCUMENT:DOWNLOAD:FAIL', error: err.message });
  }
}

export function* getMessage(action) {
  const request = registry.get('request');
  yield put({ type: '' });

  try {
    const { id } = action;
    const response = yield request.get(`/v1/messages/sent/${id}`);
    yield response;
    switch (response.status) {
      case 200: {
        const message = response.body;
        const lastName = message.sender.lastName ? message.sender.lastName : '';
        const senderName = message.sender.firstName ?
          `${message.sender.firstName} ${lastName}` : lastName;
        const modifiedMessage = {
          ...message,
          actualSender: {
            ...message.sender,
            key: senderName
          },
          sender: senderName,
          actualRecipientList: message.recipients,
          recipients: getModifiedRecipients(message.recipients)
        };
        yield put({ type: 'MESSAGE:ITEM:FETCH:SUCCESS', message: modifiedMessage });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'message');
    yield put({ type: 'MESSAGE:ITEM:FETCH:SUCCESS', error: err.message });
  }
}
