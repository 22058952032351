import registry from 'app-registry';
import theme from '@packages/ui/themes';
import v1theme from '@packages/ui/themes/default_v1';
import * as appConfig from '@packages/config/app.config';
import request from './request';
import storage from './storage';
import logger from './logger';

export const registerServices = (store) => {
  registry.register('request', request);
  registry.register('storage', storage);
  registry.register('logger', logger);
  registry.register('store', store);
  registry.register('theme', theme);
  registry.register('v1theme', v1theme);
  registry.register(
    'isWindows',
    window.navigator.appVersion.indexOf('Win') !== -1
  );
  const config = appConfig.clientConfig || {};
  registry.register('config', config);
  if (config.logger && config.logger.level) {
    logger.setLevel(config.logger.level);
  }
};
