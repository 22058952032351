import { connect } from 'react-redux';

import CustomRecordListComponent from './customRecordList';

let recordType;
const mapStateToProps = (state) => {
  recordType = state.privacyRecords.filters.get('currentFilter');
  return {
    locale: state.intl.locale,
    tenantLocale: state.login.get('tenantLocale'),
    items: state.privacyRecords.list.get('items'),
    pageSearchText: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).pageSearchText,
    sortOrder: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).sortOrder,
    sortKey: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).sortKey,
    filterColumn: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType).filterColumn,
    filteredOn: state.privacyRecords.list
      .get('sortAndFilterParams')
      .get(recordType)
      .filteredOn.toJS(),
    position: state.privacyRecords.list.get('position'),
    isFetching: state.privacyRecords.list.get('isFetching'),
    error: state.privacyRecords.list.get('error').toJS(),
    isUpdating:
      state.tia.get('isUpdating') || state.customRecord.get('isFetching'),
    selectedRecords: state.privacyRecords.list.get('bulkItems').get(recordType)
      .selectedRecords,
    pricingPlan: state.login.get('pricingPlanDetails'),
    registryDetails: state.publicUrlDetails?.get('publicRegistryDetails'),
    filterMenuData: state.privacyRecords.filters.get('currentFilterData')
  };
};

const mapDispatchToProps = (dispatch) => ({
  onMount: (recordType, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
      recordType,
      recordLayoutId,
      isCustom: true
    });
  },
  getNextData: (position, recordLayoutId, rowCount) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:NEXT_FETCH',
      position,
      recordType,
      rowCount,
      recordLayoutId
    });
  },
  deletePrivacyRecord: (recordId, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD:ITEM:DELETE',
      recordId,
      recordType,
      recordLayoutId
    });
  },
  onChooseFilter: (filterParams, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORDS:LIST:FILTER_INIT',
      filterParams,
      recordType,
      recordLayoutId
    });
  },
  updateNote: (recordId, property, data, name, recordLayoutId) => {
    dispatch({
      type: 'PRIVACY_RECORD:UPDATE_ITEM:NOTE',
      recordId,
      recordType,
      property,
      recordLayoutId,
      data,
      name
    });
  },
  copyRecord: (
    copyOfRecordId,
    locale,
    tenantLocale,
    recordType,
    formatMessage,
    isGlobal
  ) => {
    dispatch({
      type: 'CUSTOM_RECORD:ITEM',
      copyOfRecordId,
      mode: 'COPY',
      locale,
      tenantLocale,
      recordType,
      formatMessage,
      isGlobal
    });
  },
  onRecordSelection: (selectedRecords) => {
    dispatch({
      type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
      recordType,
      selectedRecords
    });
  },
  toggleLoader: (toggle) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle });
  },
  importersInit: (formatMessage) => {
    dispatch({ type: 'DATA_IMPORTERS:LIST:REQUEST_INIT', formatMessage });
  },
  exportersInit: (formatMessage) => {
    dispatch({ type: 'DATA_EXPORTERS:LIST:REQUEST_INIT', formatMessage });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRecordListComponent);
