import { singularTerms } from '@packages/utils/uppercaseTranslations';
import { recordTranslations } from '@packages/utils/commontranslations';

import {
  transformDefaultResponseData,
  transformDefaultRequestData
} from '../../saga-utils';
import { getDefaultLayoutId } from '../../common-utils';

export const transformRequestData = (requestData) => {
  const reqData = Object.assign({}, requestData);
  if (reqData.documents) {
    reqData.documents = reqData.documents.map((item) => {
      if (item.value) return item;
      return {
        ...item,
        value: {
          name: item.name,
          id: item.id,
          contentType: item.contentType,
          version: item.version
        }
      };
    });
    if (reqData.organisations) {
      reqData.organisations = reqData.organisations.map((item) => {
        if (!item.stakeHolderRole) delete item.stakeHolderRole;
        return {
          ...item,
          value: {
            ...item.value,
            country: { id: item.value.country.id || item.value.country }
          }
        };
      });
    }
  }
  return transformDefaultRequestData(reqData); // Transform records request data
};

export const transformResponseData = (
  responseData,
  currentUser,
  isFromTemplate,
  isUseDefault = true
) => {
  const respData = Object.assign({}, responseData);
  if (respData.documents) {
    respData.documents = respData.documents?.map((item) => ({
      ...item,
      ...item.value
    }));
  }
  if (respData.organisations) {
    respData.organisations = respData.organisations?.map((item) => ({
      ...item,
      stakeHolderRole: item.stakeHolderRole || '',
      value: {
        ...item.value,
        country: { id: item.value.country.id || item.value.country }
      }
    }));
  }
  if (isUseDefault)
    return transformDefaultResponseData(respData, currentUser, isFromTemplate);
  return respData;
};

export const stakeHolderRole = [
  { label: recordTranslations.Processor, value: 'Processor' },
  { label: singularTerms('subProcessor'), value: 'SubProcessor' },
  { label: recordTranslations.Controller, value: 'Controller' },
  { label: recordTranslations.jointController, value: 'JointController' },
  { label: singularTerms('noRole'), value: '' }
];

export const stakeHolderRoleMapper = (value) =>
  stakeHolderRole.find((data) => {
    if (value) return data.value === value;
    return false;
  })?.label;

export const transformQuickAddData = (defaultReqData, data) => ({
  ...defaultReqData,
  name: data[0].name,
  description:
    data[0].description !== 'undefined' && data[0].description.length > 0
      ? data[0].description
      : '',
  documents: data,
  documentRecordTypes: data[0].documentRecordTypes || [],
  layoutId: getDefaultLayoutId('document')
});
