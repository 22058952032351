import loginWatchers from '@packages/features/login/watchers';
import userWatchers from '@packages/features/user/watchers';
import settingsWatchers from '@packages/features/settings/watcher';
import adminWatchers from '@packages/features/admin/watchers';
import dashboardWatchers from '@packages/features/dashboard/watchers';
import privacyRecordWatchers from '@packages/features/privacy-records/watchers';
import privacyrecordDetailWatchers from '@packages/features/privacy-record-detail/watchers';
import orgEntityWatchers from '@packages/features/organisation-entities/watchers';
import environmentWatchers from '@packages/features/environment/watchers';
import messageCenterWatchers from '@packages/features/message-center/watchers';
import dsrWatchers from '@packages/features/dsr/watchers';
import dsrDetailWatchers from '@packages/features/dsr-detail/watchers';
import dsrPublicWatchers from '@packages/features/dsr-public-form/watchers';
import homeWatchers from '@packages/features/home/watchers';
import tenantConfigurationWatchers from '@packages/features/tenant-configuration/watchers';
import reportsWatchers from '@packages/features/reports/watchers';
import dataLibrary from '@packages/features/data-library/watchers';

export default [
  ...loginWatchers,
  ...userWatchers,
  ...adminWatchers,
  ...dashboardWatchers,
  ...privacyRecordWatchers,
  ...settingsWatchers,
  ...privacyrecordDetailWatchers,
  ...orgEntityWatchers,
  ...environmentWatchers,
  ...messageCenterWatchers,
  ...dsrWatchers,
  ...dsrDetailWatchers,
  ...dsrPublicWatchers,
  ...homeWatchers,
  ...tenantConfigurationWatchers,
  ...reportsWatchers,
  ...dataLibrary
];
