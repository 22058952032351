import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { handleServiceDown } from '@packages/utils/common-utils';

export default function* fetchOrganisationDetails(action) {
  const { entityId, routeToVendorDetails } = action;

  const request = registry.get('request');
  try {
    if (entityId !== undefined) {
      const response = yield request.get(`/v1/organisations/${entityId}`);

      switch (response.status) {
        case 200: {
          const items = response.body;
          yield put({ type: 'ORGANISATION_ENTITY:ITEM:FETCH:SUCCESS', items });
          if (routeToVendorDetails) {
            yield put(replaceRouter(`/vendors/${entityId}`));
            yield put({
              type: 'VENDOR_MODULE:FILTERS:CHANGE',
              filterName: 'vendors',
              from: 'messages'
            });
          }
          break;
        }
        default: {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
        }
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({
      type: 'ORGANISATION_ENTITY:ITEM:FETCH:FAIL',
      error: err.message
    });
  }
}
