/* eslint-disable no-param-reassign */
import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import { replace as replaceRouter } from 'connected-react-router';
import Immutable from 'immutable';
import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  getRecordType,
  handleServiceDown,
  RECORDS_FETCH_LIMIT,
  RECORDS_SEARCH_LIMIT
} from '@packages/utils/common-utils';
import {
  getParameterValuesFromHash,
  getQueryStrings
} from '@packages/utils/query-parameters';
import {
  defaultRecordLayouts,
  getDeleteRecordUrl,
  getModifiedRecordType
} from './record-utils';
import { getDefaultLayoutId } from '../privacy-record-detail/common-utils';

export function* resetAndfetchPrivacyRecords(action) {
  const { isVendor = false } = action;
  if (isVendor) yield put({ type: 'VENDORS:PRIVACY_RECORDS:LIST:INIT' });
  else yield put({ type: 'PRIVACY_RECORDS:LIST:INIT' });

  yield fetchPrivacyRecords(action);
}

const getListFieldItems = (recordType, isPublic) => {
  let customFields = ['name', 'status', 'recordType'];

  if (!isPublic) customFields.push('lastChangedBy');

  const typeSpecificFields = {
    processing: ['controllers', 'executingEntities', 'processorList'],
    tia: ['dataExporters', 'dataImporters']
  };

  customFields = customFields.concat(typeSpecificFields[recordType] || []);

  if (recordType && recordType.split('_')[1] === 'document-records') {
    customFields.push('documentRecordTypes');
  }

  return customFields;
};

export function* fetchPrivacyRecords(action) {
  const {
    recordType,
    recordLayoutId,
    rowCount = RECORDS_FETCH_LIMIT,
    isGlobal,
    persistFilterParams = true,
    isVendor = false,
    isTemplateMode = false
  } = action;

  const URL = window.location;
  const isPreview = getQueryStrings(URL.hash).isPreview === 'true';
  const isPublic = URL.pathname.includes('public');
  const userState = yield select((state) => state.login);
  const showCustomData =
    !isVendor &&
    !isPublic &&
    userState.get('loggedUser').get('userPermissions').get('viewCustom');
  const { registryId } = isPublic && getParameterValuesFromHash('/:registryId');
  if (isVendor)
    yield put({ type: 'VENDORS:PRIVACY_RECORDS:LIST:FETCH', recordType });
  else yield put({ type: 'PRIVACY_RECORDS:LIST:FETCH', recordType });

  try {
    const privacyRecordsState = isVendor
      ? yield select((state) => state.vendorRecordsList)
      : yield select((state) => state.privacyRecords.list);
    const { sortOn, sortOrder, filteredOn, searchParameters } =
      getFilterAndSearchParamsFromState(
        privacyRecordsState,
        recordType || 'all'
      );

    const modifiedRecordType = getModifiedRecordType(recordType);
    const customFields = getListFieldItems(recordType, isPublic);
    const response = yield registry.get('request').get(
      getURLWithParams(
        {
          sortOn,
          sortOrder,
          filteredOn: persistFilterParams ? filteredOn : Immutable.List()
        },
        action.position ? action.position : 0,
        persistFilterParams ? searchParameters : Immutable.List(),
        modifiedRecordType,
        rowCount,
        customFields,
        registryId,
        recordLayoutId,
        showCustomData,
        isTemplateMode,
        isGlobal
      ),
      null,
      {},
      !isPublic || isPreview
    );
    switch (response.status) {
      case 200: {
        const modifiedResponse = response.body.map((item) => ({
          ...item,
          key: `${item.privacyRecordNumber} - ${item.name}`,
          lastChanged: item.lastChangedBy && item.lastChangedBy.changedAt
        }));

        if (isVendor)
          yield put({
            type: 'VENDORS:PRIVACY_RECORDS:LIST:FETCH:SUCCESS',
            items: modifiedResponse
          });
        else
          yield put({
            type: 'PRIVACY_RECORDS:LIST:FETCH:SUCCESS',
            items: modifiedResponse
          });
        break;
      }
      case 404: {
        yield put({ type: 'PRIVACY_RECORDS:LIST:FETCH:FAIL' });
        const store = registry.get('store');
        const embed = getQueryStrings(URL.hash).embed === 'true';
        if (isPublic) {
          setTimeout(() => {
            store.dispatch(replaceRouter(`/inactive?embed=${embed}`));
          }, 200);
        } else {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    if (isVendor)
      yield put({
        type: 'VENDORS:PRIVACY_RECORDS:LIST:FETCH:FAIL',
        error: err.message
      });
    else
      yield put({
        type: 'PRIVACY_RECORDS:LIST:FETCH:FAIL',
        error: err.message
      });
  }
}

export function* filterPrivacyRecords(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  const { isVendor = false } = action;

  if (isVendor)
    yield put({
      type: 'VENDORS:PRIVACY_RECORDS:LIST:FILTER',
      filterParams,
      recordType: action.recordType
    });
  else
    yield put({
      type: 'PRIVACY_RECORDS:LIST:FILTER',
      filterParams,
      recordType: action.recordType
    });
  yield fetchPrivacyRecords(action);
}

export function* searchPrivacyRecords(action) {
  const {
    recordType,
    recordLayoutId,
    searchParams,
    rowCount = RECORDS_SEARCH_LIMIT,
    isVendor = false,
    isTemplateMode = false
  } = action;
  const URL = window.location;
  const isPreview = getQueryStrings(URL.hash).isPreview === 'true';
  const isPublic = URL.pathname.includes('public');
  const { registryId } = isPublic && getParameterValuesFromHash('/:registryId');
  const { searchKey, isPageSearch } = searchParams;
  const searchMode = isPageSearch ? 'PAGE_SEARCH' : 'SEARCH';

  const privacyRecordsState = isVendor
    ? yield select((state) => state.vendorRecordsList)
    : yield select((state) => state.privacyRecords.list);
  let customFields = ['name', 'status', 'recordType'];
  if (!isPublic) customFields = customFields.concat(['lastChangedBy']);
  if (isVendor) {
    customFields = customFields.concat(['controllers']);
  } else if (recordType === 'processing') {
    customFields = customFields.concat([
      'controllers',
      'executingEntities',
      'processorList'
    ]);
  } else if (recordType.split('_')[1] === 'document-records') {
    customFields = customFields.concat(['documentRecordTypes']);
  }

  const { sortOn, sortOrder, filteredOn, searchParameters } =
    getFilterAndSearchParamsFromState(
      privacyRecordsState,
      recordType || 'all',
      searchParams
    );

  const modifiedRecordType = getModifiedRecordType(action.recordType);
  if (isVendor)
    yield put({
      type: `VENDORS:PRIVACY_RECORDS:LIST:${searchMode}`,
      searchParameters
    });
  else
    yield put({ type: `PRIVACY_RECORDS:LIST:${searchMode}`, searchParameters });
  const userState = yield select((state) => state.login);
  const showCustomData =
    !isVendor &&
    !isPublic &&
    userState.get('loggedUser').get('userPermissions').get('viewCustom');
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          { sortOn, sortOrder, filteredOn },
          action.position ? action.position : 0,
          searchParameters,
          modifiedRecordType,
          rowCount,
          customFields,
          registryId,
          recordLayoutId,
          showCustomData,
          isTemplateMode
        ),
        null,
        {},
        !isPublic || isPreview
      );
    switch (response.status) {
      case 200: {
        const modifiedResponse = response.body.map((item) => ({
          ...item,
          lastChanged: item.lastChangedBy && item.lastChangedBy.changedAt
        }));
        if (isVendor) {
          yield put({
            type: `VENDORS:PRIVACY_RECORDS:LIST:${searchMode}:SUCCESS`,
            searchKey,
            items: modifiedResponse,
            searchParameters
          });
        } else {
          yield put({
            type: `PRIVACY_RECORDS:LIST:${searchMode}:SUCCESS`,
            searchKey,
            items: modifiedResponse,
            searchParameters
          });
        }
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: `PRIVACY_RECORDS:LIST:${searchMode}:FAIL` });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    if (isVendor)
      yield put({
        type: `VENDORS:PRIVACY_RECORDS:LIST:${searchMode}:FAIL`,
        error: err.message
      });
    else
      yield put({
        type: `PRIVACY_RECORDS:LIST:${searchMode}:FAIL`,
        error: err.message
      });
  }
}

const getTemplateFetchBaseUrl = (recordType) =>
  recordType === 'custom'
    ? '/v1/custom/records/templates'
    : `/v1/records/templates/${recordType}`;

export function* resetAndFetchPrivacyRecordTemplates(action) {
  yield put({ type: 'PRIVACY_RECORD_TEMPLATES:LIST:INIT' });
  yield fetchPrivacyRecordTemplates(action);
}

export function* fetchPrivacyRecordTemplates(action) {
  const {
    searchText = null,
    sortOrder = 'ASC',
    filterParams = null,
    sortOn = 'name',
    recordType,
    layoutId,
    position = 0,
    rowCount = RECORDS_FETCH_LIMIT
  } = action;
  yield put({ type: 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH' });

  try {
    let url =
      `${getTemplateFetchBaseUrl(recordType)}?filter=layoutId=${layoutId}` +
      `&offset=${position}&numberOfResults=${rowCount}&fields=name&filter=recordTemplateStatus=Active&`;
    url = searchText
      ? `${url}search=name%3D${encodeURIComponent(searchText)}&`
      : url;
    url = `${url}sortOn=${sortOn}&sortOrder=${sortOrder}`;
    if (filterParams && filterParams.items?.length > 0) {
      filterParams.items.forEach((item) => {
        url = `${url}&filter=${filterParams.filterKey}%3D${encodeURIComponent(
          item.itemId
        )}`;
      });
    }
    const response = yield registry.get('request').get(url);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH:SUCCESS',
          items: response.body,
          filterParams: filterParams || ''
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({
      type: 'PRIVACY_RECORD_TEMPLATES:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* updateRecordProperty(action) {
  try {
    const {
      recordId,
      recordType,
      property,
      data,
      currentFilter,
      name,
      recordLayoutId,
      isVendor = false
    } = action;
    const defaultRecordTypes = [
      'assessments',
      'breaches',
      'processings',
      'tias',
      'document-records'
    ];
    const modifiedRecordType = recordType.split('_')[1] || recordType;
    const isCustom = !defaultRecordTypes.includes(
      getModifiedRecordType(modifiedRecordType)
    );
    const url = isCustom
      ? `/v1/custom/records/${recordId}`
      : `/v1/records/${getModifiedRecordType(modifiedRecordType)}/${recordId}`;
    yield put({ type: `${modifiedRecordType.toUpperCase()}:NOTE:CHANGE` });
    const request = registry.get('request');
    const response = yield request.put(url, { [property]: data, name });
    switch (response.status) {
      case 200:
      case 202: {
        yield put({
          type: `${modifiedRecordType.toUpperCase()}:NOTE:CHANGE:SUCCESS`
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.recordNoteUpdateSuccess,
            type: 'success'
          }
        });
        setTimeout(() => {
          const store = registry.get('store');
          const dispatchType =
            currentFilter === 'all'
              ? { type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT', recordType: 'all' }
              : {
                type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
                recordType,
                isVendor,
                recordLayoutId,
                isCustom
              };
          store.dispatch(dispatchType);
        });
        break;
      }
      default: {
        yield put({
          type: `${modifiedRecordType.toUpperCase()}:DETAIL:UPSERT:FAIL`
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deletePrivacyRecord(action) {
  yield put({ type: 'LOADER:SHOW' });
  try {
    const { recordId, recordType, isTemplateMode } = action;
    const url = getDeleteRecordUrl(recordId, recordType, isTemplateMode);
    const response = yield registry.get('request').delete(url, null);
    yield put({ type: 'LOADER:HIDE' });
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.recordDeleteSuccess,
            type: 'success'
          }
        });
        // Remove the item from the record list items
        const privacyRecordsState = yield select(
          (state) => state.privacyRecords.list
        );
        const position = privacyRecordsState.get('position') - 1;
        const items = updateRecordItems(privacyRecordsState, recordId);
        yield put({ type: 'PRIVACY_RECORDS:LIST:UPDATE', items, position });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deletePrivacyRecordTemplate(action) {
  try {
    const { templateID, recordType } = action;
    const url =
      recordType === 'processing'
        ? '/v1/records/templates'
        : `/v1/records/${recordType}-templates`;
    const response = yield registry
      .get('request')
      .delete(`${url}/${templateID}`, null);

    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.templateDeleteSuccess,
            type: 'success'
          }
        });

        // Remove the template from the template list items
        const templatesState = yield select(
          (state) => state.privacyRecords.templates
        );
        const items = updateRecordItems(templatesState, templateID);
        yield put({ type: 'PRIVACY_RECORD_TEMPLATES:LIST:UPDATE', items });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* privacyRecordBulkExport(action) {
  const { selectedRecords, recordType, exportType, signal, controller } =
    action;
  const selectedRecordIds = selectedRecords.map((item) => item.id);
  yield put({ type: 'RECORD:BULK_PDF:DOWNLOAD:INIT' });
  const request = registry.get('request');
  try {
    if (controller) {
      yield request.exportCSVbyPost(
        `v1/records/${recordType}/export?exportType=${exportType}`,
        { entityIds: selectedRecordIds },
        null,
        controller
      );
    } else {
      const response = yield request.exportCSVbyPost(
        `v1/records/${recordType}/export?exportType=${exportType}`,
        { entityIds: selectedRecordIds },
        signal
      );

      yield response;
      switch (response.status) {
        case 200: {
          yield put({ type: 'RECORD:BULK_PDF:DOWNLOAD:SUCCESS' });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({ type: 'RECORD:BULK_PDF:DOWNLOAD:FAIL' });
          break;
      }
    }
  } catch (err) {
    if (!action.signal.aborted) yield handleServiceDown(err, 'records');
    yield put({ type: 'RECORD:BULK_PDF:DOWNLOAD:FAIL' });
  }
}

export function* privacyRecordBulkDelete(action) {
  const { data } = action;
  const request = registry.get('request');
  try {
    const response = yield request.post(`/v1/records/processings/remove`, data);

    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.recordDeleteSuccess,
            type: 'success'
          }
        });
        yield resetAndfetchPrivacyRecords({
          ...action,
          recordLayoutId: getDefaultLayoutId('processing')
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* checkRecordUsage(action) {
  const { recordType, id } = action;
  const request = registry.get('request');
  try {
    const response = yield request.get(
      `/v1/records/${getModifiedRecordType(recordType)}/${id}/usage`
    );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'PRIVACY_RECORDS:USAGE:FETCH:SUCCESS',
          usage: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

const updateRecordItems = (recordState, recordId) => {
  let records = recordState.get('items');
  const index = records.findIndex((record) => record.id === recordId);
  records = records.remove(index);
  return records;
};

const getMainUrl = (recordType, isTemplateMode, isGlobal, recordLayoutId) => {
  if (
    ['tia_assessments', 'tias'].includes(recordType) ||
    recordLayoutId === '9e803e46-4931-4026-b6c3-988b88f68ae3'
  )
    return `/v1/records${isTemplateMode ? '/templates' : ''}/tias`;

  const modifiedRecordType =
    recordType && (recordType.split('_')[1] || recordType);

  if (recordType === 'all' || recordType === undefined) {
    return `/v1/records${isTemplateMode ? '/templates' : ''}`;
  }

  if (defaultRecordLayouts.includes(modifiedRecordType)) {
    return `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/${modifiedRecordType}`;
  }

  return `/v1/custom/records${isTemplateMode ? '/templates' : ''}`;
};

const getPublicUrl = (registryId, recordType) => {
  if (recordType === 'assessments' || recordType === 'processings')
    return `/v1/public/${registryId}/records/${recordType}`;
  return `/v1/public/${registryId}/records`;
};

const getURLWithParams = (
  filterParams,
  position,
  searchParams,
  recordType,
  rowCount = 15,
  fields,
  registryId,
  recordLayoutId,
  showCustomData,
  isTemplateMode,
  isGlobal
) => {
  let isRecordTypeFilter;
  let url = registryId
    ? getPublicUrl(registryId, recordType)
    : getMainUrl(recordType, isTemplateMode, isGlobal, recordLayoutId);
  url = recordLayoutId
    ? `${url}?filter=layoutId=${recordLayoutId}&numberOfResults=${rowCount}&offset=${position}`
    : `${url}?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          // FIXME: Record types should be made lower case in BE
          let filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          if (filterKey === 'recordType') {
            isRecordTypeFilter = true;
            filterText = filterText.toLowerCase();
          }
          if (filterKey === 'processorList') {
            url = `${url}&filter=processors%3D${encodeURIComponent(
              filterText
            )}`;
          } else {
            url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
              filterText
            )}`;
          }
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    searchParams.keySeq().forEach((searchParameters) => {
      const searchItem = searchParams.get(searchParameters);
      const searchKey = searchItem.get('searchKey');
      const searchText = searchItem.get('searchText');
      url = searchText
        ? `${url}&search=${searchKey}%3D${encodeURIComponent(searchText)}`
        : url;
    });
    const sortOn = searchParams.get('sortOn');
    const sortOrder = searchParams.get('sortOrder');

    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  fields.forEach((field) => {
    url = `${url}&fields=${field}`;
  });
  if (recordType === 'all' && !isRecordTypeFilter) {
    url = `${url}&filter=recordType=assessment&filter=recordType=processing&filter=recordType=breach&filter=recordType=tia`;
    if (showCustomData) url = `${url}&filter=recordType=custom`;
  }
  return url;
};

const getFilterAndSearchParamsFromState = (
  privacyRecordsState,
  recordType,
  searchParams
) => {
  // Filter parameters
  let sortAndFilterParams = {};
  let filterParams = {};
  let pageSearchTextObj = Immutable.Map();

  if (privacyRecordsState) {
    sortAndFilterParams = privacyRecordsState
      .get('sortAndFilterParams')
      .get(recordType || 'all');
    filterParams = sortAndFilterParams.filterParams.toJS();
    pageSearchTextObj = sortAndFilterParams.pageSearchText;
  }
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = privacyRecordsState
    ? sortAndFilterParams.filteredOn
    : Immutable.Map();

  // Search parameters
  let searchParameters = [];
  let searchText = '';
  if (searchParams) {
    ({ searchText } = searchParams);
    const { searchKey } = searchParams;
    // For first time rendering of search items, searchText will not be defined
    if (searchText === undefined) {
      const searchTextObj = privacyRecordsState
        ? privacyRecordsState.get('searchText')
        : Immutable.Map();
      searchText = searchTextObj.get(searchKey) || '';
    } else {
      const searchObj = {
        ...searchParams,
        [searchParams.searchKey]: searchParams.searchText
      };
      searchParameters = pageSearchTextObj.set(
        'allFields',
        Immutable.Map(searchObj)
      );
    }
  } else {
    searchParameters = pageSearchTextObj || Immutable.Map();
  }

  return { sortOn, sortOrder, filteredOn, searchParameters };
};

export function* privacyRecordBulkUpdate(action) {
  const store = registry.get('store');
  const { data, updateType, recordIds } = action;
  const request = registry.get('request');
  const modifiedData = transformRequestData(data);
  const jobRequest = {
    operationType: updateType,
    entityType: 'ProcessingUpdate',
    name: 'Bulk updates',
    entity: JSON.stringify(modifiedData),
    oldEntityId: recordIds
  };
  yield put({ type: 'PROCESSING:BULK:UPDATE:INIT' });
  try {
    const response = yield request.post(`/v1/jobs`, jobRequest);

    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.jobSubmittedSuccess,
            type: 'success'
          }
        });
        yield put({ type: 'PROCESSING:BULK:UPDATE:SUCCESS' });
        yield put({ type: 'PRIVACY_RECORDS:BULK_UPDATE:RESET' });

        // After submitting job redirected to job list page.
        yield put({
          type: 'TENANT_DASHBOARD:FILTERS:CHANGE',
          filterName: 'jobs'
        });
        store.dispatch(replaceRouter('/dashboard'));
        break;
      }
      case 400: {
        if (response.body.length > 0) {
          yield put({
            type: 'BULK_UPDATE:VALIDATION:ERROR',
            validationError: response.body
          });
        } else {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          yield put({
            type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
            recordType: 'processing',
            selectedRecords: []
          });
        }
        yield put({ type: 'PROCESSING:BULK:UPDATE:FAIL' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'PRIVACY_RECORD:BULK_EXPORT:ITEMS:ADD',
          recordType: 'processing',
          selectedRecords: []
        });
        yield put({ type: 'PROCESSING:BULK:UPDATE:FAIL' });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'jobs');
  }
}

const transformRequestData = (reqData) => {
  const modifiedData = { ...reqData };
  if (reqData.links)
    modifiedData.links = reqData.links.map((item) => ({ value: item }));
  if (reqData.userRights) {
    modifiedData.userRights = reqData.userRights.map((item) => ({
      value: { subject: item, rights: item.rights }
    }));
  }
  if (reqData.organisationRights) {
    modifiedData.organisationRights.forEach((item) => {
      delete item.country;
    });
    modifiedData.organisationRights = reqData.organisationRights.map(
      (item) => ({
        value: {
          subject: {
            id: item.value ? item.value.id : item.id,
            key: item.value ? item.value.key : item.key
          },
          rights: ['Write', 'Read']
        }
      })
    );
  }
  if (reqData.companyAccess) {
    modifiedData.companyAccess = reqData.companyAccess === 'yes';
  }
  if (reqData.jurisdictions) {
    modifiedData.jurisdictions = reqData.jurisdictions.map(
      (item) => item.value.data
    );
  }
  if (reqData.internationalTransfer) {
    modifiedData.internationalTransfer =
      reqData.internationalTransfer === 'yes';
  }
  const propertyKeys = Object.keys(modifiedData);
  propertyKeys.forEach((key) => {
    if (typeof modifiedData[key] === 'object') {
      modifiedData[key].forEach((item) => {
        if (item.value && (item.value.country || item.value.countries)) {
          delete item.value.country;
          delete item.value.countries;
        }
        if (item.value && item.value.organisation !== undefined) {
          delete item.value.organisation;
        }
        if (item.value && item.value.jurisdiction) {
          delete item.value.jurisdiction;
        }
        if (item.value && item.value.dataStorageCountry) {
          delete item.value.dataStorageCountry;
        }
      });
    }
  });
  return modifiedData;
};

const getTemplateStatusUpdateUrl = (recordType, templateId) => {
  if (defaultRecordLayouts.includes(recordType)) {
    return `/v1/records/templates/${getRecordType(
      recordType
    )}/${templateId}/status`;
  } else return `/v1/custom/records/templates/${templateId}/status`;
};

export function* templateStatusUpdate(action) {
  const {
    templateId,
    recordType,
    recordLayoutId,
    status,
    currentFilter,
    isGlobal
  } = action;
  const request = registry.get('request');

  const url = getTemplateStatusUpdateUrl(recordType, templateId);

  try {
    const response = yield request.put(url, { status });
    switch (response.status) {
      case 200:
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.templateStatusUpdated,
            type: 'success'
          }
        });

        yield put({
          type: 'PRIVACY_RECORDS:LIST:REQUEST_INIT',
          recordType: currentFilter,
          recordLayoutId: currentFilter === 'all' ? undefined : recordLayoutId,
          isCustom: true,
          isTemplateMode: true,
          isGlobal
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
