import { put } from 'redux-saga/effects';
import registry from 'app-registry';
import { updateIntl } from 'react-intl-redux';

import notifyFailure from '@packages/features/notifier';

import { getLocaleMessages } from '.';

export default function* fetchTranslation(action) {
  let { locale } = action;
  if (!locale) {
    locale = localStorage.getItem('locale');
    if (!locale) {
      locale = 'en';
    }
  }

  yield put({ type: 'TRANSLATION:FETCH' });

  try {
    yield put(
      updateIntl({
        locale,
        messages: getLocaleMessages(locale)
      })
    );

    yield put({ type: 'TRANSLATION:FETCH:SUCCESS' });
  } catch (err) {
    registry.get('logger').error(err);
    notifyFailure(`Could not fetch translation for locale '${locale}'`);
    yield put({ type: 'TRANSLATION:FETCH:FAIL', error: err.message });
  }
}
