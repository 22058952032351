import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ValueSelector from '../value-selector';
import { multipleSelectorTypeTranslations } from '../../../../environment/masterDataTranslations';

const DataRecipientCategorySelector = (props) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (props.initDataRecipientCategories) {
      props.initDataRecipientCategories();
    }
  }, []);

  useEffect(() => {
    setSearchResults(props.searchResults);
  }, [props.searchResults]);


  const getModifiedItems = (items) => {
    const modifiedItems = items.map(item => ({ ...item, key: item.name }));
    return modifiedItems;
  };

  const handleNextData = () => {
    props.getNextData(props.position);
  };
  const { hintTextLabel } = props;
  const dataRecipientCategories = getModifiedItems(props.dataRecipientCategories);
  return (
    <div>
      <ValueSelector
        valueAttr="name"
        searchResults={searchResults}
        dataItems={dataRecipientCategories}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        title={multipleSelectorTypeTranslations('dataRecipientCategories')}
        {...props}
      />
    </div>
  );
};

DataRecipientCategorySelector.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ])).isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  searchResults: PropTypes.instanceOf(Immutable.List),
  initDataRecipientCategories: PropTypes.func,
  dataRecipientCategories: PropTypes.instanceOf(Immutable.List)
};

DataRecipientCategorySelector.defaultProps = {
  hintTextLabel: null,
  initDataRecipientCategories: e => e,
  getNextData: e => e,
  position: 0,
  searchResults: Immutable.List(),
  fields: {},
  dataRecipientCategories: Immutable.List()
};

export default DataRecipientCategorySelector;
