import Immutable from 'immutable';

import { getQueryStrings } from '@packages/utils/query-parameters';
import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';

import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  items: Immutable.List(),
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  showGoBack: false,
  filteredOn: Immutable.Map(),
  searchText: Immutable.Map(),
  searchItems: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOrder: 'DESC',
    sortOn: 'createdAt'
  }),
  bulkItems: Immutable.List()
});


export default (state = defaultState, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS:UNREAD:LIST:INIT':
      return state
        .set('items', defaultState.get('items'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('searchText', state.get('searchText'))
        .set('searchItems', defaultState.get('searchItems'))
        .set('filterParams', defaultState.get('filterParams'))
        .set('position', 0);

    case 'NOTIFICATIONS:UNREAD:LIST:FETCH':
      return state.set('error', defaultState.get('error'));
    case 'NOTIFICATIONS:UNREAD:LIST:FETCH:SUCCESS': {
      return state.set('items', state.get('items').concat(action.items))
        .set('position', state.get('position') + action.items.length);
    }
    case 'NOTIFICATIONS:UNREAD:LIST:FETCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'NOTIFICATIONS:UNREAD:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'NOTIFICATIONS:UNREAD:GOBACKLINK:SET':
      return state.set('showGoBack', true);
    case 'NOTIFICATIONS:UNREAD:GOBACKLINK:RESET': {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      if (urlParams.from === 'dashboard') {
        return state;
      }
      return state.set('showGoBack', false);
    }
    case 'NOTIFICATIONS:UNREAD:LIST:FILTER':
      return state
        .set('items', Immutable.List())
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filteredOn', getFilteredOn({
          ...action.filterParams,
          filterObj: state.get('filteredOn')
        }));
    case 'NOTIFICATIONS:UNREAD:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'NOTIFICATIONS:UNREAD:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText({
          ...action,
          searchObj: state.get('searchText').toJS()
        }))
        .set('items', Immutable.List(action.items));
    case 'NOTIFICATIONS:UNREAD:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'NOTIFICATION:UNREAD:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId));
    }
    case 'NOTIFICATIONS:UNREAD:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'NOTIFICATION:UNREAD:LIST:UPDATE:SUCCESS':
      return state.set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'));
    default:
      return state;
  }
};
