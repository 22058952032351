import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import {
  customTranslation,
  recordTranslations
} from '@packages/utils/commontranslations';
import actionTranslations from '@packages/utils/actionTranslations';

import CreateRecordDialog from '../../../privacy-records/components/header/component/createRecordDialog';
import {
  getCreateDialogHintTextLabel,
  getCreateDialogSubTitle
} from '../../common-utils';

const CreateBreaches = (props) => {
  const {
    open,
    onRequestClose,
    actionError,
    isUpdating,
    data,
    toggleLoader,
    isVendor,
    subRecordTypes,
    currentRecordType,
    filterMenuData,
    layoutId,
    vendorId,
    handleCloseCreateDialog,
    saveBreach,
    createFromEdit,
    onMount,
    isTemplateMode,
    refetchRecords,
    isGlobal
  } = props;

  const shouldShowCustomLabel =
    currentRecordType !== 'all' && filterMenuData?.label;

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  const createBreach = (data, templateId, _, subRecordId) => {
    const modifiedData = {
      ...data,
      ...(subRecordId && { layoutId: subRecordId }),
      name: data.name.trim(),
      ...(isVendor && {
        processors: [{ value: { id: vendorId, subProcessors: [] } }]
      })
    };
    if (isVendor) {
      handleCloseCreateDialog();
    }
    saveBreach(
      modifiedData,
      templateId,
      createFromEdit,
      isVendor,
      isTemplateMode,
      refetchRecords,
      isGlobal
    );
  };

  const getCreateDialogTitle = () =>
    actionTranslations(
      isTemplateMode ? 'createBreacheTemplate' : 'createBreaches'
    );

  return (
    <CreateRecordDialog
      id="create-breach"
      open={open}
      title={getCreateDialogTitle()}
      subTitle={getCreateDialogSubTitle(
        shouldShowCustomLabel,
        isTemplateMode,
        filterMenuData?.label
      )}
      hintTextLabel={getCreateDialogHintTextLabel(
        shouldShowCustomLabel,
        isTemplateMode
      )}
      label={
        currentRecordType !== 'all' && filterMenuData?.label
          ? customTranslation('emptyRecord', filterMenuData?.label)
          : recordTranslations.emptyRecord
      }
      hintTextCustomValue={filterMenuData?.label}
      data={data}
      recordType={currentRecordType === 'all' ? 'breaches' : currentRecordType}
      currentFilter={currentRecordType}
      createRecord={createBreach}
      onRequestClose={onRequestClose}
      isUpdating={isUpdating}
      actionError={actionError}
      toggleLoader={toggleLoader}
      isVendor={isVendor}
      subRecordTypes={subRecordTypes}
      layoutId={layoutId}
    />
  );
};

CreateBreaches.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    processors: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.shape({ id: PropTypes.string }) })
    )
  }),
  isUpdating: PropTypes.bool,
  onMount: PropTypes.func,
  saveBreach: PropTypes.func,
  createFromEdit: PropTypes.bool,
  onRequestClose: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  toggleLoader: PropTypes.func,
  isVendor: PropTypes.bool,
  vendorId: PropTypes.string,
  handleCloseCreateDialog: PropTypes.func,
  currentRecordType: PropTypes.string,
  subRecordTypes: PropTypes.shape({}),
  filterMenuData: PropTypes.shape({ label: PropTypes.string }),
  layoutId: PropTypes.string,
  isTemplateMode: PropTypes.bool,
  refetchRecords: PropTypes.func,
  isGlobal: PropTypes.bool
};

CreateBreaches.defaultProps = {
  data: {},
  open: false,
  isUpdating: false,
  createFromEdit: false,
  onMount: (e) => e,
  saveBreach: (e) => e,
  onRequestClose: (e) => e,
  actionError: Immutable.Map({
    message: '',
    isError: false
  }),
  toggleLoader: (e) => e,
  isVendor: false,
  vendorId: '',
  handleCloseCreateDialog: (e) => e,
  currentRecordType: 'breach_breaches',
  subRecordTypes: {},
  filterMenuData: {},
  layoutId: '',
  isTemplateMode: false,
  refetchRecords: (e) => e,
  isGlobal: false
};

export default CreateBreaches;
