/* eslint-disable */
const extendedTypeOf = (obj) => {
  const result = typeof obj;
  if (obj == null) {
    return 'null';
  } else if (result === 'object' && obj.constructor === Array) {
    return 'array';
  }

  return result;
};

const hasProp = {}.hasOwnProperty;

const findMatchingIndex = (array, obj) => {
  const index = array.findIndex(item =>  {
    const itemVal = item.value ? item.value : item;
    const objVal = obj.value ? obj.value : obj;
    if (item.title) {
      return item.title === obj.title;
    }
    if(itemVal || itemVal === '') {
      if(itemVal.id) {
        return itemVal.id === objVal.id;
      }
      if(itemVal.name) {
        return itemVal.name ===objVal.name;
      }
      if(itemVal.jurisdiction) {
        return itemVal.jurisdiction ===objVal.jurisdiction  && itemVal.law ===objVal.law && itemVal.article ===objVal.article;
      }

      /* Specifically added For acl rights */
      if(itemVal.subject && itemVal.subject.id) {
        return itemVal.subject.id === objVal.subject.id;
      }
      /* Specifically added For acl rights */

      /* START -- For comparing retention term objects */
      if (itemVal.period) {
        return itemVal.period === objVal.period;
      }
      if (itemVal.offset && itemVal.offset.id) {
        return itemVal.period === objVal.period && itemVal.offset.id === (objVal.offset && objVal.offset.id);
      }
      /* END -- For comparing retention term objects */
      
    }

    return item === obj;
  });
  return index;
};

const objectDiff = (obj1, obj2, options) => {
  if (options == null) {
    options = {};
  }
  let result = {};
  let key, value1, value2;
  for (key in obj1) {
    if (!hasProp.call(obj1, key)) continue;
    value1 = obj1[key];
    if (!(!(key in obj2))) {
      continue;
    }
    result[key] = {
      deleted: value1
    };
  }
  for (key in obj2) {
    if (!hasProp.call(obj2, key)) continue;
    value2 = obj2[key];
    if (!(!(key in obj1))) {
      continue;
    }
    result[key] = {
      added: value2
    };
  }
  for (key in obj1) {
    if (!hasProp.call(obj1, key)) continue;
    value1 = obj1[key];
    if (!(key in obj2)) {
      continue;
    }
    value2 = obj2[key];
    const change = diff(value1, value2, options);
    if (change !== undefined || change !== null) {
      result[key] = change;
    }
  }
  return result;
};

const arrayDiff = (arr1, arr2, options) => {
  let result = [];
  arr1.forEach(item => {
    const index = findMatchingIndex(arr2, item);
    if(index === -1) {
      result.push(['-', item]);
    } else {
      const change = diff(item, arr2[index], options);
      result.push(['~', change]);
    }
  })

  arr2.forEach(item => {
    const index = findMatchingIndex(arr1, item);
    if(index === -1) {
      result.push(['+', item]);
    }
  })

  return result;
}

const diff = (obj1, obj2, options) => {
  const type1 = extendedTypeOf(obj1);
  const type2 = extendedTypeOf(obj2);
  if (type1 === type2) {
    switch (type1) {
      case 'object':
        return objectDiff(obj1, obj2, options);
      case 'array':
        return arrayDiff(obj1, obj2, options);
      default:
        break;
    }
  }
  if (obj1 !== obj2) {
    return {
      old: obj1,
      new: obj2
    };
  }
  return obj1;
};

export default diff;
