import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';

import styles from '@packages/ui/styles';
import CommonDialog from '@packages/components/pp-dialog/commonDialog';
import {
  commonTranslations,
  eventCodeTranslations
} from '@packages/utils/commontranslations';

const selectFieldStyle = {
  width: '80%',
  height: '40px',
  paddingLeft: '10px',
  marginRight: '3px'
};

const ExportReportDialog = (props) => {
  const { open, onRequestClose, onSubmit, exportTypes, defaultValue } = props;

  const [value, setValue] = useState(defaultValue);

  const handleDialogClose = () => {
    onRequestClose();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value);
    handleDialogClose();
  };

  return (
    <CommonDialog
      id="export_report_dialog"
      fullWidth={true}
      maxWidth="sm"
      show={open}
      onCancel={handleDialogClose}
      title={eventCodeTranslations.exportResults}
    >
      <Select
        value={value}
        label={
          <FormattedMessage
            id="ExportDialog.fieldLabel"
            description="Field label for export dialog"
            defaultMessage="Select Format"
          />
        }
        onChange={handleChange}
        style={selectFieldStyle}
        autoWidth={false}
      >
        {exportTypes.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <div style={styles.addButton}>
        <Button id="save_button" onClick={handleSubmit}>
          {commonTranslations.export}
        </Button>
        <Button
          id="cancel-button"
          key="cancel-button"
          variant="text"
          onClick={handleDialogClose}
        >
          {commonTranslations.Cancel}
        </Button>
      </div>
    </CommonDialog>
  );
};

ExportReportDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
  exportTypes: PropTypes.arrayOf(PropTypes.shape({})),
  defaultValue: PropTypes.string
};

ExportReportDialog.defaultProps = {
  open: false,
  onRequestClose: (e) => e,
  onSubmit: (e) => e,
  exportTypes: [],
  defaultValue: ''
};

export default ExportReportDialog;
