import { connect } from 'react-redux';
import CustomSimpleMasterDataSelector from './component';

const mapStateToProps = (state) => ({
  customSimpleMasterData: state.customMasterDataList.get('items'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions'),
  position: state.customMasterDataList.get('position'),
  tags: state.environment.simpleEntities.tags.get('items'),
  tagsPosition: state.environment.simpleEntities.tags.get('position'),
  tagSearchResult: state.simpleEntities.get('entitySelectorSearch').get('tags')
    .searchResults,
  isGlobal: state.home?.get('isGlobal')
});

const mapDispatchToProps = (dispatch) => ({
  onInit: (dataType, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:LIST:REQUEST_INIT',
      dataType,
      entityType,
      isPersistedData: false
    });
  },
  getNextData: (position, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:NEXT_FETCH',
      position,
      isPersistedData: true,
      entityType
    });
  },
  onSearch: (searchParams, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:SEARCH_INIT',
      searchParams,
      entityType
    });
  },
  onChooseFilter: (filterParams, entityType) => {
    dispatch({
      type: 'MASTERDATA:CUSTOM_SIMPLE_DATA:ITEM:LIST:FILTER_INIT',
      filterParams,
      isPersistedData: true,
      entityType
    });
  },
  tagSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSimpleMasterDataSelector);
