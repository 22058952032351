import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import ValueSelector from '../value-selector';

const DataImportersSelector = (props) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    props.initDataImporters();
  }, []);

  useEffect(() => {
    setSearchResults(props.searchResults);
  }, [props.searchResults]);


  const handleSearch = () => {
    props.onSearch();
  };

  const handleNextData = () => {
    props.getNextData(props.position);
  };

  const { hintTextLabel, dataImporters } = props;

  return (
    <div>
      <ValueSelector
        onSelect={props.onSelect}
        removeItem={props.removeItem}
        valueAttr="name"
        dataItems={dataImporters}
        searchResults={searchResults}
        dataSourceConfig={{ text: 'key', value: 'key' }}
        selectFromListMenuItem={true}
        onScrollStop={handleNextData}
        hintTextLabel={hintTextLabel}
        onSearch={handleSearch}
        title={
          <FormattedMessage
            id="MultipleDataImportersDialog.header"
            defaultMessage="Select data importers"
          />}
        {...props}
      />
    </div>
  );
};

DataImportersSelector.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func
  }),
  dataImporters: PropTypes.instanceOf(Immutable.List),
  onSelect: PropTypes.func,
  removeItem: PropTypes.func,
  getNextData: PropTypes.func,
  position: PropTypes.number,
  hintTextLabel: PropTypes.node,
  onSearch: e => e,
  initDataImporters: PropTypes.func,
  searchResults: PropTypes.instanceOf(Immutable.List)
};

DataImportersSelector.defaultProps = {
  dataImporters: Immutable.List(),
  hintTextLabel: null,
  fields: {},
  onSelect: e => e,
  removeItem: e => e,
  getNextData: e => e,
  position: 0,
  onSearch: e => e,
  initDataImporters: e => e,
  searchResults: Immutable.List()
};

export default DataImportersSelector;
