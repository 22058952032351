import { connect } from 'react-redux';

import MultipleSelectorDialog from './multipleSelectorDialog';

function mapStateToProps(state) {
  return {
    legalEntities: state.legalEntities.get('items'),
    position: state.legalEntities.get('position'),
    tags: state.environment.simpleEntities.tags.get('items'),
    tagsPosition: state.environment.simpleEntities.tags.get('position'),
    searchResults: state.simpleEntities.get('entitySelectorSearch').get('tags')
      .searchResults,
    isGlobal: state.home.get('isGlobal')
  };
}
const mapDispatchToProps = (dispatch) => ({
  init: (searchText, hasTopDownLimit, isGlobal, isInternal) => {
    const searchParams = {
      searchText,
      searchKey: 'name',
      sortOrder: 'ASC',
      sortOn: 'name'
    };
    dispatch({ type: 'LEGAL_ENTITY:LIST:SEARCH:INIT', searchParams });
    dispatch({
      type: 'LEGAL_ENTITY:LIST:FETCH_INIT',
      searchParams,
      hasTopDownLimit,
      isInternal
    });
    if (!isGlobal) {
      dispatch({
        type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
        masterDataType: 'tags'
      });
    }
  },
  getNextData: (position, hasTopDownLimit, isInternal) => {
    dispatch({
      type: 'LEGAL_ENTITY:LIST:FETCH:NEXT',
      position,
      isPersistedData: true,
      hasTopDownLimit,
      isInternal
    });
  },
  filterItems: (filterParams, searchText, hasTopDownLimit, isInternal) => {
    dispatch({
      type: 'LEGAL_ENTITY:LIST:FILTER_INIT',
      filterParams,
      searchParams: {
        searchText,
        searchKey: 'name',
        sortOrder: 'ASC',
        sortOn: 'name'
      },
      isPersistedData: true,
      isInternal,
      hasTopDownLimit
    });
  },
  resetfilterItems: () => {
    dispatch({ type: 'LEGAL_ENTITY:LIST:FILTER:RESET' });
  },
  tagsSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleSelectorDialog);
