import registry from 'app-registry';
import { put } from 'redux-saga/effects';

import {
  addKeyToDataSource,
  handleServiceDown,
  MASTERDATA_SEARCH_LIMIT
} from '@packages/utils/common-utils';

import { getURLWithParams } from '../../saga-utils';

export default function* searchDatasourcesSelector(action) {
  const { searchParams } = action;
  const { searchKey, searchText } = searchParams;

  yield put({
    type: 'RECORDS:DATA_SOURCES:LIST:SEARCH',
    searchKey,
    searchText
  });
  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams(
          action.position,
          searchParams,
          'datasources',
          MASTERDATA_SEARCH_LIMIT
        ),
        null,
        {}
      );

    switch (response.status) {
      case 200: {
        // Add a key element which is used to display the object in list view
        const modifiedItems = response.body.map((item) => ({
          ...item,
          key: addKeyToDataSource({
            name: item.name,
            organisation: item.organisation,
            dataStorageCountry: item.dataStorageCountry
          }),
          ...(item.organisation && {
            organisation: {
              ...item.organisation,
              key:
                item.organisation &&
                `${item.organisation.name} (${item.organisation.country.id})`
            }
          })
        }));
        yield put({
          type: 'RECORDS:DATA_SOURCES:LIST:SEARCH:SUCCESS',
          searchKey,
          items: modifiedItems,
          searchText
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    registry.get('logger').error(err);
    yield put({ type: 'DATA_SOURCES:LIST:SEARCH:FAIL', error: err.message });
  }
}
