import Immutable from 'immutable';

import { getFilteredOn, getSearchText } from '@packages/utils/reducer-utils';

import { getCurrentMergeItems } from '../../../utils';

const defaultState = Immutable.fromJS({
  isFetching: false,
  position: 0,
  items: Immutable.List(),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'createdDate',
    sortOrder: 'DESC'
  }),
  filterColumn: {},
  searchText: Immutable.Map(),
  error: {
    message: '',
    isError: false
  },
  users: {
    items: [],
    searchText: '',
    position: 0
  },
  bulkItems: Immutable.List(),
  showGoBack: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MESSAGES:UNREAD:LIST:INIT':
      return state.set('filteredOn', state.get('filteredOn'))
        .set('searchText', state.get('searchText'))
        .set('users', defaultState.get('users'))
        .set('filterParams', state.get('filterParams'))
        .set('items', defaultState.get('items'))
        .set('position', 0);

    case 'MESSAGES:UNREAD:LIST:FETCH':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));
    case 'MESSAGES:UNREAD:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('items', state.get('items').concat(Immutable.List(action.items)))
        .set('position', state.get('position') + action.items.length);
    case 'MESSAGES:UNREAD:LIST:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));

    case 'MESSAGES:UNREAD:LIST:FILTER': {
      const { filterParams, appendFilter } = action;
      const newFilterParams = { [filterParams.filterKey]: filterParams.filterKey };
      const filterColumn = appendFilter ?
        ({ ...state.get('filterColumn').toJS(), ...newFilterParams })
        : newFilterParams;
      return state
        .set('items', defaultState.get('items'))
        .set('filterParams', Immutable.Map(action.filterParams))
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('filteredOn', getFilteredOn({
          ...action.filterParams,
          filterObj: appendFilter ? state.get('filteredOn') : undefined
        }));
    }
    case 'MESSAGES:UNREAD:GOBACKLINK:SET':
      return state.set('showGoBack', true);
    case 'MESSAGES:UNREAD:GOBACKLINK:RESET': {
      return state.set('showGoBack', false);
    }
    case 'MESSAGES:UNREAD:LIST:FILTER:RESET':
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));

    case 'MESSAGES:UNREAD:LIST:SEARCH': {
      return state
        .set('isFetching', true)
        .set('searchText', getSearchText(action))
        .set('error', defaultState.get('error'));
    }
    case 'MESSAGES:UNREAD:LIST:SEARCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('searchText', getSearchText({
          ...action,
          searchObj: state.get('searchText').toJS()
        }))
        .set('items', Immutable.List(action.items))
        .set('position', action.items.length);
    case 'MESSAGES:UNREAD:LIST:SEARCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    case 'MESSAGES:UNREAD:USERS:LIST:INIT': {
      const user = state.get('users').toJS();
      const modifiedUser = {
        ...user,
        items: [],
        position: 0
      };
      return state
        .set('users', Immutable.Map(modifiedUser))
        .set('error', defaultState.get('error'));
    }
    case 'MESSAGES:UNREAD:USERS:LIST:SEARCH': {
      const user = state.get('users').toJS();
      const modifiedUser = {
        ...user,
        searchText: action.searchText
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'MESSAGES:UNREAD:USERS:LIST:FETCH:SUCCESS': {
      const { items } = action;
      const user = state.get('users').toJS();
      const modifiedItems = user.items.concat(items);
      const modifiedUser = {
        ...user,
        items: modifiedItems,
        position: user.position + items.length
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'MESSAGES:UNREAD:USERS:LIST:SEARCH:SUCCESS': {
      const { items } = action;
      const user = state.get('users').toJS();
      const modifiedUser = {
        ...user,
        items,
        position: items.length
      };
      return state
        .set('users', Immutable.Map(modifiedUser));
    }
    case 'MESSAGES:UNREAD:USERS:LIST:SEARCH:FAIL':
      return state.set('error', Immutable.Map({ message: action.error, isError: true }));
    case 'MESSAGES:UNREAD:ITEM:CHECK': {
      const items = state.get('bulkItems');
      return state.set('bulkItems',
        getCurrentMergeItems(action.item, items, action.dataItemId));
    }
    case 'MESSAGES:UNREAD:BULK:ITEMS:RESET':
      return state.set('bulkItems', defaultState.get('bulkItems'));
    case 'MESSAGES:UNREAD:LIST:UPDATE:SUCCESS':
      return state.set('items', action.items)
        .set('position', action.position ? action.position : state.get('position'));
    case 'MESSAGES:UNREAD:CREATION:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({
          message: action.error,
          isError: true
        }));
    default:
      return state;
  }
};
