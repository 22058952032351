import { connect } from 'react-redux';
import TagSelector from './component';

const mapStateToProps = state => ({
  error: state.masterDataTags.get('error'),
  searchResults: state.masterDataTags.get('items'),
  searchText: state.masterDataTags.get('searchText'),
  position: state.environment.simpleEntities.tags.get('position'),
  tags: state.environment.simpleEntities.tags.get('items')
});


const mapDispatchToProps = dispatch => ({
  init: () => {
    dispatch({ type: `SIMPLE_ENTITY:LIST:REQUEST_INIT`,
      masterDataType: 'tags',
      isPersistedData: true });
  },
  tagSearch: (searchText) => {
    dispatch({ type: 'TAGS:LIST:SEARCH:INIT', searchText });
  },
  getNextData: (position) => {
    dispatch({ type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      position,
      isPersistedData: true,
      masterDataType: 'tags' });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TagSelector);
