import registry from 'app-registry';
import { SubmissionError } from 'redux-form';

import errortranslations from '@packages/utils/errortranslations';

import operatorsLabel from './formattedMessages';
import { addKeyToRetentionTerm } from '../privacy-record-detail/common-utils';

const getRetentionTermValue = (retentionTerm, formatMessage) =>
  retentionTerm.value.map((item) => {
    const parsedItem = JSON.parse(item);
    const modifiedItem = addKeyToRetentionTerm(
      { ...parsedItem, offset: { name: parsedItem.offset } },
      formatMessage
    );
    return modifiedItem;
  });

export const getModifiedReportDetail = (
  reportDetail,
  schema,
  formatMessage
) => {
  /*
  When returning from the result page, the 'reportDetail' is sourced from the 'reportDetail' Redux state,
  and the 'field' is assumed to be pre-transformed. To ensure the transformation,
  we validate that the type of 'filter.field' is 'object'.
  */
  const modifyReportDetail = { ...reportDetail };
  const { queryDefinition, selectedFields } = modifyReportDetail.definition;
  const { searchItems = [] } = schema;

  const modifiedFilters = queryDefinition.filters.map((filter) => ({
    ...filter,
    field:
      typeof filter.field === 'object'
        ? filter.field
        : searchItems.find((item) =>
          filter.path
            ? item.field === filter.field && item.nestedPath === filter.path
            : item.field === filter.field && !item.nestedPath
        ),
    ...(filter.field === 'retentionTerms' && {
      value: getRetentionTermValue(filter, formatMessage)
    })
  }));

  const modifiedSelectedFields = selectedFields.map((item) =>
    item?.name === 'tenantName' ? { ...item, disabled: true } : item
  );

  return {
    ...reportDetail,
    definition: {
      ...reportDetail.definition,
      selectedFields: modifiedSelectedFields,
      queryDefinition: {
        ...reportDetail.definition.queryDefinition,
        filters: modifiedFilters
      }
    }
  };
};

export const getDefaultReportDetail = (definition, resultFields, isGlobal) => {
  const defaultSelectedFields = resultFields.filter(
    (item) => item.name === 'name'
  );
  if (isGlobal)
    defaultSelectedFields.unshift({
      ...resultFields.find((item) => item.name === 'tenantName'),
      disabled: true
    });

  return {
    ...definition,
    selectedFields: defaultSelectedFields,
    queryDefinition: {
      filters: [{}]
    }
  };
};

const operators = [
  {
    label: operatorsLabel.equals,
    value: 'equals',
    types: ['text', 'keyword', 'boolean']
  },
  {
    label: operatorsLabel.contains,
    value: 'contains',
    types: ['text']
  },
  {
    label: operatorsLabel.wildcard,
    value: 'wildcard',
    types: ['text']
  },
  {
    label: operatorsLabel.gt,
    value: 'gt',
    types: ['date', 'integer']
  },
  {
    label: operatorsLabel.lt,
    value: 'lt',
    types: ['date', 'integer']
  },
  {
    label: operatorsLabel.range,
    value: 'range',
    types: ['date', 'integer']
  },
  {
    label: operatorsLabel.exists,
    value: 'exists',
    types: ['exists']
  },
  {
    label: operatorsLabel.notExists,
    value: 'not_exists',
    types: ['exists', 'text']
  }
];

// hide not_exist operator for the below fields.
const fieldsToHideNotExistOperator = [
  'name',
  'status',
  'privacyRecordNumber',
  'fdplPrimaryPurpose',
  'ccpaLegalQualifications.personalDataSaleCompensation',
  'ccpaLegalQualifications.dsrMethods.other',
  'ccpaLegalQualifications.underSixteenOptInMeasures',
  'ccpaLegalQualifications.optOutWebsiteLink',
  'ccpaLegalQualifications.dsrMethods.link',
  'ccpaLegalQualifications.optOutUserRightsLink',
  'ccpaLegalQualifications.dsrMethods.mailAddress',
  'ccpaLegalQualifications.dsrMethods.phoneNumber',
  'ccpaLegalQualifications.dsrMethods.emailAddress',
  'ccpaLegalQualifications.dsrMethods.physicalAddress'
];

export const getOperatorsByField = (field) => {
  if (!field) return operators;
  const selectedFieldType = field.type;
  let fieldOperators = operators.filter((operator) => {
    const index = operator.types.findIndex(
      (type) => type === selectedFieldType
    );
    return index !== -1;
  });

  // For tiaRiskScoreRange only equals need to be displayed
  if (field.valueType === 'tiaRiskScoreRange') {
    fieldOperators = fieldOperators.filter(
      (fieldOperator) => fieldOperator.value === 'equals'
    );
  }

  // Exact match, Word match and wildcard match is defined only for Name field.
  if (
    selectedFieldType === 'text' &&
    field.field !== 'name' &&
    field.isKeyWordEnabled
  ) {
    fieldOperators = fieldOperators.filter(
      (fieldOperator) => fieldOperator.value === 'equals'
    );
  }

  // For name, privacyRecordNumber and status fields, not_exists operator need not be displayed.
  if (
    selectedFieldType === 'text' &&
    (fieldsToHideNotExistOperator.includes(field.field) ||
      fieldsToHideNotExistOperator.includes(field.valueType))
  ) {
    fieldOperators = fieldOperators.filter(
      (fieldOperator) => fieldOperator.value !== 'not_exists'
    );
  }

  // for both natureOFBreach and breachConsequences, exact and wildcard match operator needs to be displayed.
  if (
    field.valueType === 'breachNature' ||
    field.valueType === 'breachConsequences'
  ) {
    const wildcardOperator = operators.filter(
      (operator) => operator.value === 'wildcard'
    );
    fieldOperators = fieldOperators.concat(wildcardOperator);
  }

  // for attachments, exists and notExists operator needs to be displayed.
  if (field.valueType === 'exists') {
    const existsOperator = operators.filter(
      (operator) =>
        operator.value === 'exists' || operator.value === 'not_exists'
    );
    fieldOperators = existsOperator;
  }

  if (field.valueType === 'dpo' || field.valueType === 'dpoOrganisations') {
    fieldOperators = fieldOperators.filter(
      (fieldOperator) => fieldOperator.value === 'equals'
    );
  }

  const store = registry.get('store');
  const isGlobal = store.getState().globalSolution?.get('isGlobal');
  if (isGlobal && field.field === 'name') {
    fieldOperators = fieldOperators.filter(
      (fieldOperator) => fieldOperator.value !== 'equals'
    );
  }

  return fieldOperators;
};

// These fields should display all the organisations in search field irrespective
// of the stakeholderType (Holding solution).
export const organisationListFields = [
  'controllers',
  'processors',
  'executingEntities',
  'dataRecipients',
  'dpoOrganisations',
  'appiThirdPartyDataReceivedFrom',
  'subProcessors'
];

export const getTransformedGroundValue = (value) => {
  const transformedValue = [];
  value.forEach((item) =>
    item.key ? transformedValue.push(item.key) : transformedValue.push(item)
  );

  return transformedValue;
};

export const getTransformedRetentionTerm = (value) => {
  const transformedValue = [];
  value.forEach((item) =>
    transformedValue.push(
      JSON.stringify({
        offset: item.offset && item.offset.name,
        period: item.period
      })
    )
  );

  return transformedValue;
};

export const getTransformedCreatedByValue = (value) => {
  const transformedValue = [];
  value.forEach((item) =>
    item.id ? transformedValue.push(item.id) : transformedValue.push(item)
  );

  return transformedValue;
};

export const getTransformedLinkGroups = (values) => {
  const modifiedLinkGroups = values.map((item) => item.key || item);
  return modifiedLinkGroups;
};

export const getTransformedValue = (value) => {
  const transformedValue = [];
  value.forEach((item) =>
    item.id ? transformedValue.push(item.id) : transformedValue.push(item)
  );

  return transformedValue;
};

const getValue = (filter) => {
  if (
    ['processingGrounds', 'transferGrounds', 'breachGrounds'].includes(
      filter.field.field
    )
  ) {
    return getTransformedGroundValue(filter.value);
  } else if (
    filter.field.field === 'retentionTerms' &&
    !['tags', 'riskDetails', 'mitigatingMeasures'].includes(
      filter.field.valueType
    )
  ) {
    return getTransformedRetentionTerm(filter.value);
  } else if (filter.field.field === 'createdBy') {
    return getTransformedCreatedByValue(filter.value);
  } else if (filter.field.field === 'links') {
    return getTransformedLinkGroups(filter.value);
  }
  return getTransformedValue(filter.value);
};

// Transform record search definition
export const transformSearchDefinition = (definition) => {
  const transformedDefinition = JSON.parse(JSON.stringify(definition));
  const transformedFilters = transformedDefinition.queryDefinition.filters.map(
    (filter) => ({
      ...filter,
      field:
        filter.field.type === 'text' &&
        filter.field.isKeyWordEnabled &&
        filter.operation !== 'contains'
          ? `${filter.field.field}.keyword`
          : filter.field.field,
      path: filter.field.nestedPath,
      dataType: filter.field.type,
      fieldType: filter.field.fieldType,
      value: getValue(filter),
      addlFilterProperty: filter.field.addlFilterProperty
    })
  );

  transformedDefinition.queryDefinition.filters = transformedFilters;
  transformedDefinition.selectedFields = definition.selectedFields.filter(
    (selectedField) => !!selectedField
  );
  return transformedDefinition;
};

function getDefaultRenderData(action) {
  return JSON.parse(JSON.stringify(action.data));
}

// Get default update request params.
export const getRequestParams = (action) => {
  const { data } = action;
  const renderData = getDefaultRenderData(action);
  const requestData = {
    ...data,
    definition: transformSearchDefinition(data.definition)
  };
  return {
    requestData,
    url: `/v1/reportsearches/${data.id}`,
    renderData
  };
};

export const transformGroundResponse = (response) => {
  const data = response.map((item) => ({
    ...item,
    key: `${item.jurisdiction.id} ${item.lawAbbreviation} ${item.article}`,
    jurisdiction: {
      ...item.jurisdiction,
      key: item.jurisdiction.id
    },
    law: {
      id: item.lawAbbreviation,
      key: item.lawAbbreviation
    }
  }));
  return data;
};

export const getURLWithParams = (
  position = 0,
  searchParams,
  requestType,
  rowCount = 15
) => {
  let url = `/v1/records/${requestType}?numberOfResults=${rowCount}&offset=${position}`;
  if (searchParams) {
    const { searchText, sortOn, sortOrder } = searchParams;
    url = searchText
      ? `${url}&search=name=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

export const validate = (values, isRun, isGlobal, tenantIds) => {
  let errors = {};
  if (!values.name && !isRun) {
    errors = {
      name: errortranslations.nameFieldError,
      _error: errortranslations.nameFieldError
    };
  }

  // Validate selected fields
  if (
    values.definition &&
    (!values.definition.selectedFields ||
      values.definition.selectedFields.filter((field) => field !== '')
        .length === 0)
  ) {
    errors.definition = {
      selectedFields: { _error: errortranslations.resultFieldError }
    };
  }

  if (isGlobal && !tenantIds?.length) {
    errors = {
      ...errors,
      tenantIds: errortranslations.subTenantError,
      _error: errortranslations.subTenantError
    };
  }

  // Validate filters
  if (
    values.definition &&
    (!values.definition.queryDefinition?.filters ||
      !values.definition.queryDefinition.filters?.length)
  ) {
    errors.definition = {
      queryDefinition: {
        filters: { _error: errortranslations.resultFieldError }
      }
    };
  } else if (values.definition) {
    const filterArrayErrors = [];
    values.definition.queryDefinition.filters.forEach((filter, filterIndex) => {
      const filterErrors = {};

      if (!filter.field) {
        filterErrors.field = errortranslations.required;
      }
      if (!filter.operation) {
        filterErrors.operation = errortranslations.required;
      }
      if (
        filter.field &&
        filter.field.valueType !== 'exists' &&
        filter.operation !== 'not_exists' &&
        (!filter.value || !filter.value.length)
      ) {
        filterErrors.value = { _error: errortranslations.required };
      }
      filterArrayErrors[filterIndex] = filterErrors;
    });
    if (
      filterArrayErrors.filter(
        (filterArrayError) => Object.keys(filterArrayError).length > 0
      ).length
    ) {
      errors.definition = {
        queryDefinition: {
          filters: filterArrayErrors
        }
      };
    }
  }
  if (Object.keys(errors).length > 0) {
    throw new SubmissionError(errors);
  }
};

export const svgIconPath = (uniqueId) =>
  ({
    assessments: [
      'M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z'
    ],
    processings: [
      'M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z'
    ],
    breaches: [
      'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'
    ]
  }[uniqueId] || [
    'M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z',
    'M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z'
  ]);
