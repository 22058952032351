/* eslint-disable import/no-cycle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  getFormValues,
  FieldArray,
  FormSection
} from 'redux-form';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ActionHelp from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  renderTextField,
  renderCountryList,
  renderSelectField,
  renderRadioGroup,
  renderEntityList,
  renderDateField
} from '@packages/components/form-components';
import ArrowTooltip from '@packages/components/tooltip';
import DivWrapper from '@packages/components/divWrapper';

import { orgTypes } from '@packages/utils/record-utils';
import vendorTranslations from '@packages/utils/vendorTranslations';
import { singularTerms as uppercaseSingulars } from '@packages/utils/uppercaseTranslations';
import {
  commonTranslations,
  orgTranslations,
  organisationDetailError
} from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import {
  getDialogContentHeight,
  validateEmail,
  validatePhoneNumber
} from '@packages/utils/common-utils';
import CustomDialog from '@packages/components/custom-dialog';
import { renderChipSelector } from '../privacy-record-detail/renderers/renderers';
import styles from './styles';
import ImpactAnalysisPage from '../environment/impact-analysis';
import documentRecordsList from './components/document-records-list';

const validate = (values) => {
  let errors = {};
  const requiredFields = ['name', 'country', 'orgType', 'isInternal'];
  requiredFields.forEach((field) => {
    if (values[field] === '' || values[field] === undefined) {
      errors[field] = errortranslations.required;
    }
  });
  const validatingFields = [
    'dataProtectionOfficer',
    'contactDetail',
    'representative'
  ];
  validatingFields.forEach((field) => {
    if (values[field]) {
      errors = {
        ...errors,
        [field]: {
          email:
            values[field].email && !validateEmail(values[field].email)
              ? { _error: errortranslations.invalidEmail }
              : null,
          workPhone:
            values[field].workPhone &&
            !validatePhoneNumber(values[field].workPhone)
              ? { _error: errortranslations.invalidPhoneNumber }
              : null
        }
      };
    }
  });
  if (values?.vatNumber && !/^[a-zA-Z0-9 ]+$/.test(values?.vatNumber)) {
    errors.vatNumber = errortranslations.invalidVatNumber;
  }
  if (
    (values.isGlobal || values.isSubTenant) &&
    values.orgType === 'department' &&
    !values.parentOrgEntity
  ) {
    errors.parentOrgEntity = errortranslations.required;
  }
  return errors;
};

const tableDisplayStyle = {
  display: 'table',
  width: '100%',
  marginTop: '20px'
};
const contactStyle = { ...styles.subHeaderStyle, display: 'flex' };

const tagsStyle = { display: 'flex', width: '95%', marginTop: 30 };

const crossIconStyle = {
  lineHeight: '1em',
  fontSize: '25px',
  marginTop: 10,
  marginLeft: -10,
  color: 'red',
  cursor: 'pointer'
};

const additionalContactStyle = {
  display: 'flex',
  paddingTop: '5px'
};

const addIconStyle = {
  width: '80%',
  float: 'right',
  cursor: 'pointer'
};

const addMessageStyle = {
  marginLeft: 10,
  marginTop: '35px',
  position: 'absolute'
};

const errorStyle = {
  color: 'red',
  marginTop: 5,
  fontSize: 13,
  width: '50%',
  float: 'right'
};

const renderContactField = (
  value,
  field,
  multiLine,
  isTagEdit,
  showTextField
) => (
  <div style={additionalContactStyle}>
    <div style={styles.labelStyle}>
      {orgTranslations[field]}
      {isTagEdit ? ':' : ''}
    </div>
    <div style={{ width: '50%' }}>
      <Field
        name={value}
        component={renderTextField}
        multiLine={multiLine}
        disabled={isTagEdit}
        showTextField={showTextField}
      />
    </div>
  </div>
);

const renderPhoneNumberField = (
  value,
  field,
  multiLine,
  isTagEdit,
  showTextField
) => (
  <div style={additionalContactStyle}>
    <div style={styles.labelStyle}>
      {orgTranslations[field]}
      {isTagEdit ? ':' : ''}
    </div>
    <div style={{ width: '50%', display: 'flex' }}>
      <Field
        name={value}
        component={renderTextField}
        multiLine={multiLine}
        disabled={isTagEdit}
        showTextField={showTextField}
      />
      <ArrowTooltip id={value} title={commonTranslations.phoneNumberHelpnote}>
        <IconButton style={{ ...styles.rightIcon, padding: '20px' }}>
          <ActionHelp color="primary" />
        </IconButton>
      </ArrowTooltip>
    </div>
  </div>
);

export const renderAdditionalContacts = injectIntl(
  ({
    label,
    subLabel,
    isEdit,
    isInternal,
    selectedOrgEntityId,
    fields,
    isTagEdit,
    disabled,
    showTextField,
    ...props
  }) => (
    <div style={{ width: '100%' }}>
      {fields &&
        fields.map((member, index) => (
          <div>
            <div style={contactStyle}>
              <div style={{ width: '22%' }}>
                <Field
                  id="entityTextField"
                  name={`${member}.role`}
                  hintTextLabel={
                    <FormattedMessage
                      id="OrganisationEntity.enterContact"
                      description="Enter contact type"
                      defaultMessage="Enter contact type"
                    />
                  }
                  component={renderTextField}
                  autoFocus={false}
                  disabled={disabled}
                  showTextField={showTextField}
                />
              </div>
              {!disabled && (
                <div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={crossIconStyle}
                    onClick={() => props.onRemoveClick(index)}
                  />
                </div>
              )}
            </div>
            <FormSection
              name={`${member}.memberDetails`}
              style={{ display: 'flex' }}
            >
              <div style={tableDisplayStyle}>
                {renderContactField('name', 'name', false, disabled, false)}
                {renderContactField(
                  'address',
                  'address',
                  true,
                  disabled,
                  false
                )}
                {renderContactField('email', 'email', false, disabled, false)}
                {renderPhoneNumberField(
                  'workPhone',
                  'workPhone',
                  false,
                  disabled,
                  false
                )}
              </div>
            </FormSection>
          </div>
        ))}
    </div>
  )
);

renderAdditionalContacts.propTypes = {
  isInternal: PropTypes.bool,
  isEdit: PropTypes.bool,
  label: PropTypes.node,
  subLabel: PropTypes.node,
  hintTextLabel: PropTypes.node,
  selectedOrgEntityId: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired
};

renderAdditionalContacts.defaultProps = {
  isInternal: undefined,
  label: null,
  subLabel: null,
  hintTextLabel: null,
  selectedOrgEntityId: null,
  isEdit: false
};
export class OrganisationEntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDialog: true,
      additionalContactDetailCount: 0,
      errorMessage: [],
      additionalContactError: false,
      isDepartment: false,
      selectedKey: undefined,
      openEditWarning: false,
      showParentChangeWarning: false
    };

    this.saveData = this.saveData.bind(this);
    this.isInternalChange = this.isInternalChange.bind(this);
    this.isDepartmentChange = this.isDepartmentChange.bind(this);
  }

  componentDidMount() {
    if (
      this.props.init &&
      this.props.entityId !== '' &&
      this.props.shouldInit
    ) {
      this.props.init(this.props.entityId);
    }
    if (!this.props.isEdit) {
      this.handleInitialise();
      // To scroll into view when when creating a new organisation.
      window.document.getElementById('organisationDialog').scrollIntoView();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.organisationData !== undefined &&
      this.props.organisationData !== nextProps.organisationData
    ) {
      if (!this.props.showOnlyDPOContacts) {
        const textFieldElement =
          window.document.getElementById('entityTextField');
        if (textFieldElement) textFieldElement.focus();
      }
      this.props.onSave(nextProps.organisationData, this.state.closeDialog);
    }
    if (
      nextProps.entityDetails !== undefined &&
      this.props.entityDetails !== nextProps.entityDetails
    ) {
      this.handleInitialise(nextProps.entityDetails);
      this.setState({
        isDepartment: nextProps.entityDetails.orgType === 'department',
        selectedKey:
          nextProps.entityDetails.parentOrgEntity &&
          `${nextProps.entityDetails.parentOrgEntity.name} (${nextProps.entityDetails.parentOrgEntity.country.id})`
      });
    }
    if (nextProps.actionError !== this.props.actionError) {
      this.props.onCancel();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.organisationEntities !== this.props.organisationEntities ||
      nextProps.entityDetails !== this.props.entityDetails ||
      nextProps.isInternal !== this.props.isInternal ||
      nextProps.actionError !== this.props.actionError ||
      nextState !== this.state
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.props.resetEntityDetails();
  }

  handleAddOrRemoveContact = (isAddContact, index) => {
    const additionalContactDetails = this.props.formValues
      .additionalContactDetails
      ? [...this.props.formValues.additionalContactDetails]
      : [];
    const { additionalContactDetailCount } = this.state;
    if (isAddContact) {
      additionalContactDetails.push({});
    } else {
      additionalContactDetails.splice(index, 1);
    }
    this.props.change('additionalContactDetails', additionalContactDetails);
    this.setState({
      additionalContactDetailCount: isAddContact
        ? additionalContactDetailCount + 1
        : additionalContactDetailCount - 1
    });
  };

  handleInitialise(entityDetail) {
    if (!this.props.isEdit)
      this.props.initialize({
        ...this.props.value,
        isGlobal: this.props.isGlobal,
        isSubTenant: this.props.isSubTenant
      });
    else {
      const modifiedEntityDetail = {
        ...entityDetail,
        parentOrgEntity: entityDetail.parentOrgEntity
          ? entityDetail.parentOrgEntity.id
          : '',
        isInternal: entityDetail.isInternal ? 'true' : 'false'
      };
      this.setState({
        additionalContactDetailCount:
          (entityDetail.additionalContactDetails &&
            entityDetail.additionalContactDetails.length) ||
          0,
        isInternal: entityDetail.isInternal ? 'true' : 'false'
      });
      this.props.initialize({
        ...modifiedEntityDetail,
        isGlobal: this.props.isGlobal,
        isSubTenant: this.props.isSubTenant
      });
    }
  }

  handleChangeProperty = (data) => {
    this.props.change('tags', data);
  };

  handleEditWarning = (value) => {
    this.setState({
      openEditWarning: value
    });
  };

  handleChangeParent = () => {
    if (this.props.isGlobal) {
      this.setState({
        showParentChangeWarning: this.props.isEdit
      });
    }
  };

  getStatus = () => {
    if (this.props.isVendor) {
      return 'Prospect';
    }
    return 'Active';
  };

  getStatusList = () => {
    const statusList = ['Active', 'Inactive'];
    if (
      this.props.entityDetails.vendorInfo &&
      this.props.entityDetails.vendorInfo.status === 'Prospect'
    ) {
      statusList.push('Prospect');
    }
    return statusList;
  };

  viewAdditionalContacts = (item) => (
    <div>
      <div style={styles.subHeaderStyle}>{item.role}</div>
      <div style={tableDisplayStyle}>
        {this.viewAdditionalContactField(
          item.memberDetails && item.memberDetails.name,
          'name'
        )}
        {this.viewAdditionalContactField(
          item.memberDetails && item.memberDetails.address,
          'address',
          true
        )}
        {this.viewAdditionalContactField(
          item.memberDetails && item.memberDetails.email,
          'email'
        )}
        {this.viewAdditionalContactField(
          item.memberDetails && item.memberDetails.workPhone,
          'workPhone'
        )}
      </div>
    </div>
  );

  viewAdditionalContactField = (value, type) => (
    <div style={styles.rootStyle}>
      <div style={styles.detailsLabelStyle}>{orgTranslations[type]}</div>
      <div style={styles.detailsValueStyle}>{value}</div>
    </div>
  );

  validateDetails = (data) => {
    let isValid = true;
    if (
      data.additionalContactDetails &&
      data.additionalContactDetails.length > 0
    ) {
      data.additionalContactDetails.forEach((item) => {
        const additionalContactError = !Object.keys(item).length > 0;
        this.setState({
          additionalContactError
        });
        if (additionalContactError) {
          isValid = false;
        }
        if (item.memberDetails) {
          const messages = [];
          const additionalContactTypeError =
            Object.keys(item.memberDetails).length > 0 && !item.role;
          const emailError =
            item.memberDetails.email &&
            !validateEmail(item.memberDetails.email);
          const phoneNumberError =
            item.memberDetails.workPhone &&
            !validatePhoneNumber(item.memberDetails.workPhone);
          if (additionalContactTypeError) {
            messages.push(organisationDetailError(orgTranslations.contactType));
          }
          if (emailError) {
            messages.push(organisationDetailError(orgTranslations.email));
          }
          if (phoneNumberError) {
            messages.push(organisationDetailError(orgTranslations.workPhone));
          }
          if (additionalContactTypeError || emailError || phoneNumberError) {
            isValid = false;
          }
          this.setState({
            errorMessage: messages
          });
        }
      });
    } else {
      this.setState({
        additionalContactError: false
      });
    }
    return isValid;
  };

  isDepartmentChange = (selected) => {
    this.setState({
      isDepartment: selected === 'department'
    });
    if (this.props.isGlobal && selected === 'legalentity') {
      this.props.change('parentOrgEntity', null);
      this.setState({
        selectedKey: null
      });
    }
  };

  isInternalChange(selected) {
    this.setState({
      isInternal: selected
    });
  }

  saveData(data, isEdit, closeDialog) {
    this.setState({
      closeDialog,
      openEditWarning: false
    });
    const isValid = this.validateDetails(data);
    if (isValid) {
      const modifiedData = { ...data };
      if (modifiedData.isInternal === 'false' || this.props.isVendor) {
        modifiedData.vendorInfo = {
          status: this.getStatus(),
          ...modifiedData.vendorInfo
        };
      }
      this.props.saveData(
        {
          ...modifiedData,
          name: modifiedData.name.trim(),
          orgType: modifiedData.orgType ? modifiedData.orgType : 'legalentity',
          isInternal:
            (modifiedData.isInternal === 'true' && !this.props.isVendor)
        },
        isEdit,
        this.props.isVendor ? 'vendor' : this.props.source,
        this.props.updateList
      );
      // To scroll into view when save and create another is used.
      if (!closeDialog) {
        this.handleInitialise();
        this.setState({
          isInternal: undefined
        });
        window.document.getElementById('organisationDialog').scrollIntoView();
      }
    }
  }

  renderDisplayContactField = (type, value) => (
    <div style={styles.rootStyle}>
      <div style={styles.detailsLabelStyle}>{orgTranslations[value]}</div>
      <div style={styles.detailsValueStyle}>
        {this.props.entityDetails[type] &&
          this.props.entityDetails[type][value]}
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      onCancel,
      submitting,
      showSaveAndCreate,
      isSuperAdmin,
      isAdmin,
      showOnlyDPOContacts,
      isEdit,
      showInternalFlag,
      locale,
      isHoldingAdministrator,
      userPermissions,
      showParentEntity,
      entityDetails,
      usage,
      isTagEdit,
      isVendor,
      isGlobal,
      isSubTenant
    } = this.props;
    let { isInternal } = this.props;
    if (typeof isInternal === 'string') isInternal = isInternal === 'true';
    const themeColor = this.props.theme.palette.primary.main;
    const FORM_MAXHEIGHT = 395;
    const WINDOW_INNERHEIGHT = 660;
    const statusList = this.getStatusList();
    const disableEdit =
      isTagEdit ||
      (!isAdmin &&
        !isSuperAdmin &&
        !isHoldingAdministrator &&
        isEdit &&
        this.props.source !== 'records');
    const currentDate = moment();
    const tomorrowDate = moment().add(1, 'days');
    const isProspect =
      this.props.entityDetails.vendorInfo &&
      this.props.entityDetails.vendorInfo.status === 'Prospect';
    const showEditWarning = this.props.isSubTenant;
    const disableParentField = isEdit && isSubTenant && this.state.isDepartment;
    const parentFieldStyle = {
      width: '50%',
      float: 'left',
      pointerEvents: disableParentField && 'none',
      opacity: disableParentField && 0.4
    };
    const { createEditDocumentRecord } = userPermissions.toJS();

    return (
      <form>
        <DivWrapper
          autoHeight={true}
          id="organisationDialog"
          autoHeightMax={getDialogContentHeight(
            WINDOW_INNERHEIGHT,
            FORM_MAXHEIGHT
          )}
          style={{ width: '100%' }}
        >
          {Object.keys(usage).length > 0 && (
            <ImpactAnalysisPage
              hasNoLimit={true}
              usage={usage}
              isDelete={true}
              type="organisation"
            />
          )}
          {!showOnlyDPOContacts && showInternalFlag && !isVendor && (
            <div>
              <Field
                name="isInternal"
                id="is_internal"
                label={
                  <FormattedMessage
                    id="OrganisationEntity.isInternalOrExternal"
                    description="Is internal or external organisation"
                    defaultMessage="Entity is an"
                    values={{ entityType: this.props.label }}
                  />
                }
                isEdit={isEdit}
                type={this.props.label}
                valueSelected={this.state.isInternal}
                row={true}
                onChange={(evt, selected) => this.isInternalChange(selected)}
                component={renderRadioGroup}
                disabled={isTagEdit}
              >
                <FormControlLabel
                  control={<Radio style={styles.checkboxStyle} />}
                  style={{ width: '200px' }}
                  value="true"
                  id="internal_organisation"
                  disabled={disableEdit}
                  label={
                    <FormattedMessage
                      id="OrganisationEntity.internalCompany"
                      description="Internal company"
                      defaultMessage="Internal company"
                    />
                  }
                />
                <FormControlLabel
                  control={<Radio style={styles.checkboxStyle} />}
                  style={{ width: '250px' }}
                  value="false"
                  disabled={disableEdit}
                  label={
                    <FormattedMessage
                      id="OrganisationEntity.externalCompany"
                      description="External company"
                      defaultMessage="External company"
                    />
                  }
                />
              </Field>
            </div>
          )}
          {isVendor && isEdit && (
            <>
              <div style={{ width: '50%', float: 'left' }}>
                <Field
                  disabled={isTagEdit}
                  showHeader={!isTagEdit}
                  showTextField={!isTagEdit}
                  name="vendorInfo.status"
                  label={uppercaseSingulars('status')}
                  style={{ width: '90%' }}
                  component={renderSelectField}
                >
                  <MenuItem id="" value="" disabled={true}>
                    <FormattedMessage
                      id="OrganisationEntity.statusType"
                      description="Choose vendor status"
                      defaultMessage="Choose vendor status"
                    />
                  </MenuItem>
                  {statusList.map((item) => (
                    <MenuItem id={item} key={item} value={item}>
                      {vendorTranslations[item]}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              {!isProspect && (
                <div style={{ display: 'table', width: '100%' }}>
                  <div style={{ width: '50%', float: 'left' }}>
                    <Field
                      name="vendorInfo.lastReviewAt"
                      label={vendorTranslations.lastReview}
                      component={renderDateField}
                      maxDate={currentDate}
                      locale={locale}
                      style={{ width: '95%' }}
                    />
                  </div>

                  <div style={{ width: '50%', float: 'right' }}>
                    <Field
                      name="vendorInfo.nextReviewAt"
                      label={vendorTranslations.nextReview}
                      component={renderDateField}
                      locale={locale}
                      minDate={tomorrowDate}
                      style={{ width: '95%' }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {!showOnlyDPOContacts && (
            <div>
              <div style={{ display: 'table', width: '100%' }}>
                <div style={{ width: '50%', float: 'left' }}>
                  <Field
                    id="entityTextField"
                    name="name"
                    label={
                      isVendor
                        ? commonTranslations.name
                        : orgTranslations.entityName
                    }
                    hintTextLabel={
                      isVendor
                        ? commonTranslations.name
                        : orgTranslations.entityName
                    }
                    component={renderTextField}
                    autoFocus={!isEdit}
                    disabled={disableEdit}
                    showTextField={!disableEdit}
                    disabledStyle={{ marginTop: '25px' }}
                  />
                </div>

                <div
                  style={{ width: '50%', float: 'right', marginTop: '14px' }}
                >
                  <Field
                    name="country"
                    label={commonTranslations.country}
                    hintTextLabel={commonTranslations.selectCountry}
                    component={renderCountryList}
                    disableCountryList={disableEdit}
                    textFieldStyle={{ paddingTop: '10px' }}
                  />
                </div>
              </div>
              {!isVendor && (
                <div style={{ display: 'table', width: '100%' }}>
                  <div style={{ width: '50%', float: 'left' }}>
                    <Field
                      name="orgType"
                      label={orgTranslations.entityType}
                      style={{ width: '90%' }}
                      component={renderSelectField}
                      defaultValue="legalentity"
                      disabled={disableEdit}
                      showTextField={!disableEdit}
                      showHeader={!disableEdit}
                      onChange={(evt, selected) =>
                        this.isDepartmentChange(selected)
                      }
                    >
                      <MenuItem id="" value="" disabled={true}>
                        <FormattedMessage
                          id="OrganisationEntity.chooseType"
                          description="Choose Entity Type"
                          defaultMessage="Choose entity type"
                        />
                      </MenuItem>
                      {orgTypes.map((item) => (
                        <MenuItem
                          id={item.value}
                          key={`${item.value}`}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                  {showParentEntity && (!isGlobal || this.state.isDepartment) && (
                    <div style={parentFieldStyle}>
                      <Field
                        name="parentOrgEntity"
                        label={
                          <FormattedMessage
                            id="OrganisationEntity.entityIsPartOf"
                            description="Entity is a part of"
                            defaultMessage="Entity is a part of"
                          />
                        }
                        hintTextLabel={
                          <FormattedMessage
                            id="OrganisationEntity.isPartOfHint"
                            description="Hint text for organisation entity parent"
                            defaultMessage="Select entity..."
                          />
                        }
                        selectedOrgEntityId={this.props.entityId}
                        isEdit={isEdit}
                        isInternal={isInternal}
                        selectedKey={this.state.selectedKey}
                        component={renderEntityList}
                        onChange={this.handleChangeParent}
                        isGlobal={isGlobal}
                        isSubTenantDepartment={
                          isSubTenant && this.state.isDepartment
                        }
                        multiValue={false}
                        onAfterReset={this.resetParentEntity}
                        disabled={disableEdit}
                      />
                    </div>
                  )}
                  {disableParentField && (
                    <div style={errorStyle}>
                      {commonTranslations.restrictParentEditWarning}
                    </div>
                  )}
                  {this.state.showParentChangeWarning && (
                    <div style={errorStyle}>
                      {commonTranslations.parentChangeWarning}
                    </div>
                  )}
                </div>
              )}
              {!this.props.hideTags && (
                <div style={{ marginTop: 15 }}>
                  <FieldArray
                    id="tags"
                    name="tags"
                    type="tags"
                    entityType="organisation"
                    headerLabel={commonTranslations.tags}
                    style={tagsStyle}
                    hintText={commonTranslations.addTags}
                    updateTags={this.handleChangeProperty}
                    component={renderChipSelector}
                    showHeader={isTagEdit || !isEdit}
                    disabled={!isTagEdit && isEdit}
                  />
                </div>
              )}
              {createEditDocumentRecord && isEdit && (
                <div style={{ marginRight: '5%' }}>
                  <FieldArray
                    id="linkedDocumentRecords"
                    name="linkedDocumentRecords"
                    component={documentRecordsList}
                    isEditable={false}
                  />
                </div>
              )}

              <div style={{ width: '50%', marginTop: '5%' }}>
                <Field
                  id="vatNumber"
                  name="vatNumber"
                  label={uppercaseSingulars('vatNumber')}
                  component={renderTextField}
                />
              </div>
            </div>
          )}
          {(showOnlyDPOContacts ||
            showParentEntity ||
            this.props.source === 'records') && (
            <div>
              {!showOnlyDPOContacts && (
                <div>
                  <div style={styles.headerStyle}>
                    <FormattedMessage
                      id="Common.contactDataTitle"
                      description="Contact data for"
                      defaultMessage="Contact data for {name}"
                      values={{ name: this.props.value.name }}
                      disabled={isTagEdit}
                    />
                  </div>
                  <div
                    style={{
                      display: 'table',
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                    {renderContactField(
                      'contactDetail.address',
                      'address',
                      true,
                      isTagEdit,
                      false
                    )}
                    {renderContactField(
                      'contactDetail.email',
                      'email',
                      false,
                      isTagEdit,
                      false
                    )}
                    {renderPhoneNumberField(
                      'contactDetail.workPhone',
                      'workPhone',
                      false,
                      isTagEdit,
                      false
                    )}
                  </div>
                </div>
              )}
              <div>
                <div style={styles.subHeaderStyle}>
                  <FormattedMessage
                    id="Common.dataProtectionOfficer"
                    description="Data Protection Officer"
                    defaultMessage="Data Protection Officer"
                  />
                </div>
                <div
                  style={{ display: 'table', width: '100%', marginTop: '20px' }}
                >
                  {renderContactField(
                    'dataProtectionOfficer.name',
                    'name',
                    false,
                    isTagEdit,
                    false
                  )}
                  {renderContactField(
                    'dataProtectionOfficer.address',
                    'address',
                    true,
                    isTagEdit,
                    false
                  )}
                  {renderContactField(
                    'dataProtectionOfficer.email',
                    'email',
                    false,
                    isTagEdit,
                    false
                  )}
                  {renderPhoneNumberField(
                    'dataProtectionOfficer.workPhone',
                    'workPhone',
                    false,
                    isTagEdit,
                    false
                  )}
                </div>
                {!this.props.isGlobal && (
                  <div>
                    <div style={styles.subHeaderStyle}>
                      <FormattedMessage
                        id="Common.representative"
                        description="Representative"
                        defaultMessage="Representative"
                      />
                    </div>
                    <div
                      style={{
                        display: 'table',
                        width: '100%',
                        marginTop: '20px'
                      }}
                    >
                      {renderContactField(
                        'representative.name',
                        'name',
                        false,
                        isTagEdit,
                        false
                      )}
                      {renderContactField(
                        'representative.address',
                        'address',
                        true,
                        isTagEdit,
                        false
                      )}
                      {renderContactField(
                        'representative.email',
                        'email',
                        false,
                        isTagEdit,
                        false
                      )}
                      {renderPhoneNumberField(
                        'representative.workPhone',
                        'workPhone',
                        false,
                        isTagEdit,
                        false
                      )}
                    </div>
                  </div>
                )}
                {!showOnlyDPOContacts && !this.props.isGlobal && (
                  <div>
                    <div style={{ width: '100%', float: 'left' }}>
                      <FieldArray
                        name="additionalContactDetails"
                        selectedItems={this.state.additionalContactDetails}
                        component={renderAdditionalContacts}
                        onRemoveClick={(index) =>
                          this.handleAddOrRemoveContact(false, index)
                        }
                        disabled={isTagEdit}
                        showTextField={!isTagEdit}
                      />
                    </div>
                    {this.state.additionalContactDetailCount < 2 &&
                      !isTagEdit &&
                      !isVendor && (
                        <div>
                          <div
                            style={addIconStyle}
                            role="presentation"
                            onClick={() => this.handleAddOrRemoveContact(true)}
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              style={{
                                lineHeight: '1em',
                                fontSize: '25px',
                                marginTop: 30,
                                marginLeft: 0,
                                color: themeColor,
                                cursor: 'pointer'
                              }}
                              onClick={() =>
                                this.handleAddOrRemoveContact(true)
                              }
                            />
                            <span style={addMessageStyle}>
                              <FormattedMessage
                                id="Common.addContactDetails"
                                description="Add contact details"
                                defaultMessage="Add contact details"
                              />
                            </span>
                          </div>
                        </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {isEdit &&
            this.props.source !== 'records' &&
            !showParentEntity &&
            !showOnlyDPOContacts &&
            Object.keys(entityDetails).length > 0 &&
            Object.keys(entityDetails).length !== undefined && (
              <div style={{ marginTop: '10px' }}>
                <div style={styles.headerStyle}>
                  <FormattedMessage
                    id="Common.contactDataTitle"
                    description="Contact data for"
                    defaultMessage="Contact data for {name}"
                    values={{ name: this.props.value.name }}
                  />
                </div>
                {this.renderDisplayContactField('contactDetail', 'address')}
                {this.renderDisplayContactField('contactDetail', 'email')}
                {this.renderDisplayContactField('contactDetail', 'workPhone')}
                <div style={styles.subHeaderStyle}>
                  <FormattedMessage
                    id="Common.dataProtectionOfficer"
                    description="Data Protection Officer"
                    defaultMessage="Data Protection Officer"
                  />
                </div>
                {this.renderDisplayContactField(
                  'dataProtectionOfficer',
                  'name'
                )}
                {this.renderDisplayContactField(
                  'dataProtectionOfficer',
                  'address'
                )}
                {this.renderDisplayContactField(
                  'dataProtectionOfficer',
                  'email'
                )}
                {this.renderDisplayContactField(
                  'dataProtectionOfficer',
                  'workPhone'
                )}
                <div style={styles.subHeaderStyle}>
                  <FormattedMessage
                    id="Common.representative"
                    description="Representative"
                    defaultMessage="Representative"
                  />
                </div>
                {this.renderDisplayContactField('representative', 'name')}
                {this.renderDisplayContactField('representative', 'address')}
                {this.renderDisplayContactField('representative', 'email')}
                {this.renderDisplayContactField('representative', 'workPhone')}
                {entityDetails.additionalContactDetails &&
                  entityDetails.additionalContactDetails.map((item, index) =>
                    this.viewAdditionalContacts(item, index)
                  )}
              </div>
          )}
        </DivWrapper>
        <div style={{ marginTop: 25 }}>
          {this.props.actionError && this.props.actionError.get('isError') && (
            <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
              {errortranslations.error}: {this.props.actionError.get('message')}
            </div>
          )}
          {this.state.errorMessage.length > 0 && (
            <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
              {this.state.errorMessage.map((message) => (
                <div>{message}</div>
              ))}
            </div>
          )}
          {this.state.additionalContactError && (
            <div style={{ ...styles.errorMessage, marginBottom: 10 }}>
              <FormattedMessage
                id="Error.additionalContactError"
                description="Please provide an additional contact."
                defaultMessage="Please provide an additional contact."
              />
            </div>
          )}
          <Button
            id="submit_button"
            style={{ minWidth: '150px' }}
            disabled={submitting}
            onClick={
              !(showEditWarning && isEdit)
                ? handleSubmit((organisationEntityData) =>
                  this.saveData(organisationEntityData, isEdit, true)
                )
                : () => this.handleEditWarning(true)
            }
          >
            {commonTranslations.saveAndClose}
          </Button>
          {!isEdit && showSaveAndCreate && (
            <Button
              id="save_and_create_button"
              variant="text"
              disabled={submitting}
              type="submit"
              onClick={handleSubmit((organisationEntityData) =>
                this.saveData(organisationEntityData, isEdit, false)
              )}
            >
              {commonTranslations.saveAndCreate}
            </Button>
          )}
          <Button id="cancel_button" variant="text" onClick={onCancel}>
            {commonTranslations.Cancel}
          </Button>
        </div>
        {this.state.openEditWarning && (
          <CustomDialog
            id="subtenant-edit-warning-dialog"
            title={
              <FormattedMessage
                id="OrganisationEntityDialog.editEntity"
                defaultMessage="Edit {entityType}"
                values={{ entityType: this.props.label }}
              />
            }
            show={this.state.openEditWarning}
            proceed={handleSubmit((organisationEntityData) =>
              this.saveData(organisationEntityData, isEdit, true)
            )}
            cancel={() => this.handleEditWarning(false)}
            content={commonTranslations.editWarning}
            hideConfirmation={true}
          />
        )}
      </form>
    );
  }
}

OrganisationEntityForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  organisationData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  label: PropTypes.node,
  isEdit: PropTypes.bool,
  isInternal: PropTypes.bool,
  showInternalFlag: PropTypes.bool,
  showParentEntity: PropTypes.bool,
  init: PropTypes.func,
  onSave: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveData: PropTypes.func,
  onCancel: PropTypes.func,
  actionError: PropTypes.shape({
    get: PropTypes.func
  }),
  resetEntityDetails: PropTypes.func,
  entityDetails: PropTypes.shape({
    parentOrgEntity: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      country: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    orgType: PropTypes.string,
    additionalContactDetails: PropTypes.arrayOf(PropTypes.object),
    vendorInfo: PropTypes.shape({
      status: PropTypes.string
    })
  }),
  formValues: PropTypes.shape({
    additionalContactDetails: PropTypes.arrayOf(PropTypes.object)
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  usage: PropTypes.shape({}),
  change: PropTypes.func,
  entityId: PropTypes.string,
  source: PropTypes.string,
  organisationEntities: PropTypes.instanceOf(Immutable.List),
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  showSaveAndCreate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isHoldingAdministrator: PropTypes.bool,
  showOnlyDPOContacts: PropTypes.bool,
  isTagEdit: PropTypes.bool,
  isVendor: PropTypes.bool,
  isGlobal: PropTypes.bool,
  shouldInit: PropTypes.bool,
  locale: PropTypes.string,
  hideTags: PropTypes.bool,
  isSubTenant: PropTypes.string,
  updateList: PropTypes.bool
};

OrganisationEntityForm.defaultProps = {
  value: {
    id: '',
    name: ''
  },
  label: null,
  isEdit: false,
  showInternalFlag: false,
  showParentEntity: false,
  resetEntityDetails: (e) => e,
  init: (e) => e,
  onSave: (e) => e,
  formValues: {},
  change: (e) => e,
  theme: {},
  usage: {},
  initialize: (e) => e,
  handleSubmit: (e) => e,
  saveData: (e) => e,
  onCancel: (e) => e,
  actionError: Immutable.Map({
    get: (e) => e
  }),
  entityDetails: {},
  entityId: '',
  source: '',
  isInternal: undefined,
  organisationEntities: Immutable.List(),
  userPermissions: Immutable.Map(),
  showSaveAndCreate: true,
  isAdmin: false,
  isSuperAdmin: false,
  isHoldingAdministrator: false,
  showOnlyDPOContacts: false,
  isTagEdit: false,
  isVendor: false,
  isGlobal: false,
  shouldInit: true,
  locale: 'en',
  hideTags: false,
  isSubTenant: '',
  updateList: true
};

const OrganisationEntityFormWrapper = reduxForm({
  form: 'OrganisationEntityForm', // a unique identifier for this form
  validate
})(withTheme(OrganisationEntityForm));

const selector = formValueSelector('OrganisationEntityForm');
const OrganisationEntityFormComponent = connect((state) => {
  const value = selector(state, 'isInternal');
  const formValues = getFormValues('OrganisationEntityForm')(state);

  return {
    isInternal: value,
    formValues
  };
})(OrganisationEntityFormWrapper);

export default injectIntl(OrganisationEntityFormComponent);
